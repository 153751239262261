<template>
    <div class="order layer-1">
        <div class="order-main">
            <div class="order-head">
                <div class="order-head__main">
                    <h3 class="h3">
                        <RouterLink
                            :to="{
                                name: 'account-order-item',
                                params: {
                                    uuid: order.uuid
                                }
                            }"
                            class="link-primary-hover"
                        >
                            Заказ № {{ order.number }}
                        </RouterLink>

                        <b class="text_secondary text_adaptive-big-fz ml-4">
                            от {{ order.create_date | dt }}
                        </b>
                    </h3>

                    <Status :status="dictionary.orders.obj[order.status]" class="mt-8"></Status>
                </div>

                <div class="order-head__aside">
                    <b class="d-block text_big-fz">{{ order.total_price | price }}</b>
                    <p
                        v-if="order.payment"
                        class="secondary-info"
                    >
                        Оплачен {{ order.payment.create_date | dt }}
                    </p>
                </div>
            </div>

            <div
                v-if="order.status === 'canceled_not_confirmed' || order.status === 'canceled_by_client'"
                class="order-footer"
            >
                <p
                    v-if="order.status === 'canceled_not_confirmed'"
                    class="order__actions-warning"
                >
                    Магазин не подтвердил наличие товаров, заказ автоматически перемещён в завершённые.
                    Деньги возвращены на вашу банковскую карту.
                </p>
                <p
                    v-if="order.status === 'canceled_by_client'"
                    class="order__actions-warning"
                >
                    Деньги возвращены на вашу банковскую карту.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import dictionary from "@/dictionary.js";
import Status from '@/components/Status.vue';

export default {
    name: "ArchiveOrder",

    components: {
        Status,
    },

    props: {
        initialOrder: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            dictionary,
            order: {},
        };
    },

    created() {
        this.order = Object.assign({}, this.initialOrder);
    },
};
</script>

<style scoped src="../../../../assets/css/order.css"></style>