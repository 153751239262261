<template>
    <component
        :is="tag"
        v-bind="attrs"
        :class="['cover-wrap', 'cover-wrap_' + coverCodename]"
        @click="$emit('click')"
    >
        <img
            :src="logoSrc"
            alt=""
            class="cover"
            :width="coversSizesMap[coverCodename].width"
            :height="coversSizesMap[coverCodename].height"
        >
    </component>
</template>

<script>
const coversSizesMap = {
    detail: {
        width: 120,
        height: 80,
    },
    big: {
        width: 90,
        height: 60,
    },
    medium: {
        width: 72,
        height: 48,
    },
    small: {
        width: 60,
        height: 40,
    },
    micro: {
        width: 48,
        height: 32,
    },
};
Object.freeze(coversSizesMap);

export default {
    name: 'ShopLogo',

    props: {
        shop: {
            type: Object,
            default: () => {},
        },

        coverCodename: {
            type: String,
            default: 'small',
            validator(value) {
                return ['detail', 'big', 'medium', 'small', 'micro'].includes(value);
            },
        },

        noLink: Boolean,
        target: {
            type: String,
            default: '_blank',
        },
        customEvent: Boolean,
    },

    data() {
        return {
            coversSizesMap,
        };
    },

    computed: {
        tag() {
            if (this.noLink) return 'div';
            else if (this.customEvent) return 'button';
            return 'RouterLink';
        },

        attrs() {
            if (this.tag === 'RouterLink') {
                return {
                    to: {
                        name: 'market-shop-about',
                        params: {
                            codename: this.shop.company.codename,
                            id: this.shop.id,
                        },
                    },
                    target: this.target,
                };
            }
            else {
                return {};
            }
        },

        logoSrc() {
            const imageLink = ((((this.shop || {}).company || {}).logo || {}).thumbnails || {})['company_logo_' + this.coverCodename];
            return imageLink
                ? this.$links.uploads + imageLink
                : require('../assets/images/logo-placeholder.svg');
        },
    },
};
</script>

<style scoped>
.cover-wrap {
    display: block;
    flex-shrink: 0;
}

@media (min-width: 1040px) {
    .cover-wrap_detail {
        width: 120px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .cover-wrap_detail {
        width: 80px;
    }
}
@media (max-width: 767px) {
    .cover-wrap_detail {
        width: 60px;
    }
}

@media (min-width: 768px) {
    .cover-wrap_big {
        width: 90px;
        height: 60px;
    }
}
@media (max-width: 767px) {
    .cover-wrap_big {
        width: 72px;
        height: 48px;
    }
}

.cover-wrap_medium {
    width: 72px;
    height: 48px;
}

.cover-wrap_small {
    width: 60px;
    height: 40px;
}

.cover-wrap_micro {
    width: 48px;
    height: 32px;
}

.cover {
    width: 100%;
    height: auto;
}
</style>