import pad from '@ui/utils/pad.js';

export default () => {
    return {
        startActionDateIso() {
            return new Date(this.contract.action_start_date || '');
        },

        startActionDate() {
            return new Date(this.startActionDateIso);
        },

        minLicenseExpDate() {
            const date = this.startActionDate;
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        },

        minLicenseExpDateIso() {
            const date = this.minLicenseExpDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        maxLicenseExpDateIso() {
            const date = this.maxLicenseExpDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        minBirthDateIso() {
            const date = this.minBirthDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        maxBirthDateIso() {
            const date = this.maxBirthDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        maxExperienceStartDateIso() {
            const date = this.maxExperienceStartDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        maxPrevLicenseDateIso() {
            const date = this.maxPrevLicenseDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },
    };
};