var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-gap_medium"},[_c('h1',{staticClass:"h1 h1_block"},[_vm._v("\n        Производители запчастей и автотоваров\n    ")]),_vm._v(" "),(!_vm.initialized)?_c('Spinner',{attrs:{"size":"big","center":""}}):_c('div',{ref:"listWrap",staticClass:"wrap layer-1"},[_c('Portal',{attrs:{"to":"sticky-holder","order":2}},[_c('div',{ref:"LetterListStickyHeader",staticClass:"letter-list_sticky sticky-sections-holder__section",class:{
                    'sticky-sections-holder__section_visible': _vm.showStickyHeader
                }},[_c('ol',{staticClass:"letter-list"},_vm._l((_vm.itemsByGroups),function(group,index){return _c('li',{key:'btn-' + group.letter,staticClass:"letter-list__item"},[_c('button',{staticClass:"letter-list__btn",class:{
                                'letter-list__btn_current': index === _vm.currentGroup,
                            },on:{"click":function($event){return _vm.scrollToGroup(index)}}},[_vm._v("\n                            "+_vm._s(group.letter)+"\n                        ")])])}),0)])]),_vm._v(" "),_c('Observer',{on:{"intersect":_vm.intersected}},[_c('div',{staticClass:"letter-list_outer"},[_c('ol',{staticClass:"letter-list"},_vm._l((_vm.itemsByGroups),function(group,index){return _c('li',{key:'btn-' + group.letter,staticClass:"letter-list__item"},[_c('button',{staticClass:"letter-list__btn",class:{
                                'letter-list__btn_current': index === _vm.currentGroup,
                            },on:{"click":function($event){return _vm.scrollToGroup(index)}}},[_vm._v("\n                            "+_vm._s(group.letter)+"\n                        ")])])}),0)])]),_vm._v(" "),_c('div',{staticClass:"grid"},_vm._l((_vm.itemsByGroups),function(group){return _c('div',{key:'group-' + group.letter,ref:"group",refInFor:true,staticClass:"group",attrs:{"id":'group-' + group.letter}},[_c('div',{staticClass:"h2 h2_block"},[_vm._v("\n                    "+_vm._s(group.letter)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"list"},_vm._l((group.data),function(brand){return _c('RouterLink',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],key:'brand-' + brand.id,staticClass:"brand link-primary-hover",attrs:{"to":{
                            name: 'market-brand',
                            params: {
                                codename: brand.codename,
                            },
                        }}},[_vm._v(_vm._s(brand.name))])}),1)])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }