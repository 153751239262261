export default (item) => {
    if (!item) {
        return [];
    }
    if (typeof item === 'string') {
        return item.split('.');
    }
    if (Array.isArray(item)) {
        return item;
    }
    if (typeof item === 'object') {
        return Object.values(item);
    }
    return [];
};