<script>
// utils
import equals from '@ui/utils/equals.js';
import clickOn from '@ui/utils/clickOn.js';
import names from '@ui/utils/names.js';
import capitalize from '@ui/utils/capitalize.js';
// components
import UIcon from '@ui/components/UIcon/UIcon.vue';
import ButtonText from '@ui/components/UButton/UButtonText.vue';
import ButtonBlock from '@ui/components/UButton/UButtonBlock.vue';


export default {
    name: 'UDatePicker',

    components: {
        ButtonText,
        UIcon,
        ButtonBlock,
    },

    props: {
        // state
        value: [Date, Number, String, Array],
        disabled: Boolean, // global disabled
        min: [Date, Number, String],
        max: [Date, Number, String],
        initialDate: [Date, String, Number],

        // behaviour
        range: Boolean,
        stepByStep: Boolean,
        confirmable: Boolean,
    },

    data() {
        const innerValue = this.$options.propsData.range
            ? [null, null]
            : null;

        return {
            innerValue, // date
            view: 'date',
            tempYear: null, // for step by step
            tempMonth: null,
            hoveredDate: null,
            displayedYear: null, // для отображения
            displayedMonth: null, // для отображения
        };
    },

    computed: {
        normalizedValue() {
            const value = Array.isArray(this.value) ? this.value : [this.value];

            return value.map(v => {
                if (v) {
                    const date = new Date(v);
                    return this.floorDate(date);
                }
                else {
                    return null;
                }
            });
        },

        normalizedInnerValue() {
            if (Array.isArray(this.innerValue)) return this.innerValue;

            return [this.innerValue];
        },

        normalizedMax() {
            if (!this.max) return null;

            const date = new Date(this.max);
            return this.floorDate(date);
        },

        normalizedMin() {
            if (!this.min) return null;

            const date = new Date(this.min);
            return this.floorDate(date);
        },

        isEmpty() {
            return this.normalizedInnerValue.every(value => !value);
        },

        selectableMonth() {
            const min = this.normalizedMin;
            const max = this.normalizedMax;

            if (!min || !max) return true;

            const minDate = new Date(min.getFullYear(), min.getMonth(), 1, 0, 0);
            const maxDate = new Date(max.getFullYear(), max.getMonth(), 1, 0, 0);

            return minDate.getTime() !== maxDate.getTime();
        },

        selectableYear() {
            const min = this.normalizedMin;
            const max = this.normalizedMax;

            if (!min || !max) return true;

            const minDate = new Date(min.getFullYear(), 0, 1, 0, 0);
            const maxDate = new Date(max.getFullYear(), 0, 1, 0, 0);

            return minDate.getTime() !== maxDate.getTime();
        },

        disabledToPrevDateTable() {
            const min = this.normalizedMin;

            if (!min) return false;

            const minDate = new Date(min.getFullYear(), min.getMonth(), 1, 0, 0);
            const prevDate = new Date(this.displayedYear, this.displayedMonth - 1, 1, 0, 0);
            return prevDate < minDate;
        },

        disabledToNextDateTable() {
            const max = this.normalizedMax;

            if (!max) return false;

            const maxDate = new Date(max.getFullYear(), max.getMonth(), 1, 0, 0);
            const nextDate = new Date(this.displayedYear, this.displayedMonth + 1, 1, 0, 0);
            return nextDate > maxDate;
        },

        disabledToPrevMonthTable() {
            const min = this.normalizedMin;

            if (!min) return false;

            const minDate = new Date(min.getFullYear(), 0, 1, 0, 0);
            const prevDate = new Date(this.displayedYear - 1, 0, 1, 0, 0);
            return prevDate < minDate;
        },

        disabledToNextMonthTable() {
            const max = this.normalizedMax;

            if (!max) return false;

            const maxDate = new Date(max.getFullYear(), 0, 1, 0, 0);
            const nextDate = new Date(this.displayedYear + 1, 0, 1, 0, 0);
            return nextDate > maxDate;
        },

        disabledToPrevYearTable() {
            const min = this.normalizedMin;

            if (!min) return false;

            const minDate = new Date(min.getFullYear(), 0, 1, 0, 0);
            const prevDate = new Date(this.displayedYear - 1, 0, 1, 0, 0);
            return prevDate < minDate;
        },

        disabledToNextYearTable() {
            const max = this.normalizedMax;

            if (!max) return false;

            const maxDate = new Date(max.getFullYear(), 0, 1, 0, 0);
            const nextDate = new Date(this.displayedYear + 13, 0, 1, 0, 0);
            return nextDate > maxDate;
        },
    },

    watch: {
        normalizedInnerValue(value) {
            if (!this.confirmable && !equals(value, this.normalizedValue)) {
                this.emitChange();
            }
        },

        normalizedValue: {
            handler(value) {
                if (!equals(value, this.normalizedInnerValue)) {
                    if (this.range) {
                        this.innerValue = [...value];
                    }
                    else {
                        this.innerValue = this.value;
                    }

                    const date = value[value.length - 1];

                    if (!date) return;

                    this.displayedMonth = date.getMonth();
                    this.displayedYear = date.getFullYear();
                }
            },

            immediate: true,
        },
    },

    beforeMount() {
        this.init();
    },

    mounted() {
        document.addEventListener('click', this.onClickAway);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.onClickAway);
    },

    methods: {
        init() {
            if (this.stepByStep && !this.value) {
                this.displayedYear = 2000;
                this.view = 'year';
            }
            else {
                const date = this.initialDate ? new Date(this.initialDate) : new Date();
                this.displayedMonth = date.getMonth();
                this.displayedYear = date.getFullYear();
                this.view = 'date';
            }
        },

        onClickAway($event) {
            const picker = this.$refs.picker;

            if (!picker) return;

            if (clickOn($event, picker)) return;

            this.emitClickAway();
        },

        changeView(view) {
            this.view = view;
        },

        getToday() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth();
            const day = now.getDate();
            return new Date(year, month, day, 0, 0);
        },

        getNormalizeDay(date) {
            const day = date.getDay() - 1;
            return day < 0 ? 6 : day;
        },

        isDisabled(date) {
            return (this.normalizedMin && date < this.normalizedMin)
                || (this.normalizedMax && date > this.normalizedMax);
        },

        isDisabledMonth(date) {
            const min = this.normalizedMin;
            const minDate = min ? new Date(min.getFullYear(), min.getMonth(), 1, 0, 0) : null;
            const max = this.normalizedMax;
            const maxDate = max ? new Date(max.getFullYear(), max.getMonth(), 1, 0, 0) : null;

            return (minDate && date < minDate) || (maxDate && date > maxDate);
        },

        isDisabledYear(date) {
            const min = this.normalizedMin;
            const minDate = min ? new Date(min.getFullYear(), 0, 1, 0, 0) : null;
            const max = this.normalizedMax;
            const maxDate = max ? new Date(max.getFullYear(), 0, 1, 0, 0) : null;

            return (minDate && date < minDate) || (maxDate && date > maxDate);
        },

        isSelected(date) {
            return this.normalizedInnerValue.some(d => {
                return d && date.getTime() === d.getTime();
            });
        },

        isSelectedMonth(date) {
            if (this.range || this.isEmpty) return false;

            const value = this.innerValue;
            const valueDate = new Date(value.getFullYear(), value.getMonth(), 1, 0, 0);
            return valueDate.getTime() === date.getTime();
        },

        isSelectedYear(date) {
            if (this.range || this.isEmpty) return false;

            const value = this.innerValue;
            const valueDate = new Date(value.getFullYear(), 0, 1, 0, 0);
            return valueDate.getTime() === date.getTime();
        },

        isStart(date) {
            if (!this.range) return false;

            const filteredValue = this.normalizedInnerValue.filter(_ => _);

            if (!filteredValue.length) return false;

            let fromDate = this.normalizedInnerValue[0];

            if (filteredValue.length === 1 && this.hoveredDate) {
                if (this.hoveredDate < fromDate) {
                    fromDate = this.hoveredDate;
                }
            }

            return fromDate.getTime() === date.getTime();
        },

        isEnd(date) {
            if (!this.range) return false;

            const filteredValue = this.normalizedInnerValue.filter(_ => _);

            if (!filteredValue.length) return false;

            let toDate = this.normalizedInnerValue[1] || this.normalizedInnerValue[0];

            if (filteredValue.length === 1 && this.hoveredDate) {
                if (this.hoveredDate > toDate) {
                    toDate = this.hoveredDate;
                }
            }

            return toDate.getTime() === date.getTime();
        },

        isInRange(date) {
            const filteredValue = this.normalizedInnerValue.filter(_ => _);

            if (!this.range || !filteredValue.length) return false;
            if (!this.hoveredDate && filteredValue.length === 1) return false;
            if (
                this.hoveredDate &&
                this.hoveredDate.getTime() === filteredValue[0].getTime()
            ) {
                return false;
            }

            let toDate;
            let fromDate;

            if (filteredValue.length === 1) {
                const date1 = filteredValue[0];
                const date2 = this.hoveredDate;
                fromDate = date1 < date2 ? date1 : date2;
                toDate = date1 > date2 ? date1 : date2;
            }
            else {
                fromDate = this.innerValue[0];
                toDate = this.innerValue[1];
            }

            return date > fromDate && date < toDate;
        },

        floorDate(date) {
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = date.getDate();
            return new Date(year, month, day, 0, 0);
        },

        toPrev() {
            if (this.view === 'date') {
                const month = this.displayedMonth;
                this.displayedMonth = month === 0 ? 11 : month - 1;
            }
            else if (this.view === 'month') {
                this.displayedYear -= 1;
            }
            else if (this.view === 'year') {
                this.displayedYear -= 12;
            }
        },

        toNext() {
            if (this.view === 'date') {
                const month = this.displayedMonth;
                this.displayedMonth = month === 11 ? 0 : month + 1;
            }
            else if (this.view === 'month') {
                this.displayedYear += 1;
            }
            else if (this.view === 'year') {
                this.displayedYear += 12;
            }
        },

        // handlers

        onClickConfirm() {
            this.emitChange();
        },

        onClickYear($event) {
            const { dataset } = $event.currentTarget;
            const year = Number(dataset.year);

            if (!this.isEmpty) {
                const month = this.innerValue.getMonth();
                const day = this.innerValue.getDate();
                this.innerValue = new Date(year, month, day, 0, 0);
            }

            this.displayedYear = year;

            this.changeView(this.stepByStep && this.isEmpty ? 'month' : 'date');
        },

        onClickMonth($event) {
            const { dataset } = $event.currentTarget;
            const month = Number(dataset.month);

            if (!this.isEmpty) {
                const year = this.innerValue.getFullYear();
                const day = this.innerValue.getDate();
                this.innerValue = new Date(year, month, day, 0, 0);
            }

            this.displayedMonth = month;

            this.changeView('date');
        },

        onClickDay($event) {
            const { dataset } = $event.currentTarget;
            const year = Number(dataset.year);
            const month = Number(dataset.month);
            const day = Number(dataset.day);
            const date = new Date(year, month, day, 0, 0);

            if (this.range) {
                const filteredValue = this.innerValue.filter(_ => _);

                if (!filteredValue.length || filteredValue.length === 2) {
                    this.innerValue = [date, null];
                }
                else {
                    if (date > this.innerValue[0]) {
                        this.innerValue = [this.innerValue[0], date];
                    }
                    else {
                        this.innerValue = [date, this.innerValue[0]];
                    }
                }
            }
            else {
                this.innerValue = date;
            }

            const target = this;
            this.$emit('click:day', { target });
        },

        onMouseEnterDay($event) {
            const { dataset } = $event.currentTarget;
            const year = Number(dataset.year);
            const month = Number(dataset.month);
            const day = Number(dataset.day);
            this.hoveredDate = new Date(year, month, day, 0, 0);
        },

        onMouseLeaveDay($event) {
            const { dataset } = $event.currentTarget;
            const year = Number(dataset.year);
            const month = Number(dataset.month);
            const day = Number(dataset.day);
            const date = new Date(year, month, day, 0, 0);

            if (this.hoveredDate && this.hoveredDate.getTime() === date.getTime()) {
                this.hoveredDate = null;
            }
        },

        onClickTitleButton($event) {
            const to = $event.currentTarget.dataset.toView;
            this.changeView(to);
        },

        // emits

        emitChange() {
            const target = this;
            const value = this.innerValue;
            this.$emit('change', { target, value });
        },

        emitClickAway() {
            const target = this;
            this.$emit('clickaway', { target });
        },

        // render

        genTitleButton({ label, toView }) {
            return this.$createElement('ButtonText', {
                class: ['u-date-picker__title-label', 'u-date-picker__title-button'],
                props: {
                    dashed: true,
                    underline: true,
                },
                on: {
                    click: this.onClickTitleButton,
                },
                attrs: {
                    'data-to-view': toView,
                },
            }, label);
        },

        genTitleLabel(label) {
            return this.$createElement('span', {
                class: 'u-date-picker__title-label',
            }, label);
        },

        genTitleMonth() {
            const month = this.displayedMonth;
            const label = names.month[month];

            if (this.selectableMonth) {
                return this.genTitleButton({ label, toView: 'month' });
            }
            else {
                return this.genTitleLabel(label);
            }
        },

        genTitleYear() {
            const label = this.displayedYear;

            if (this.selectableYear) {
                return this.genTitleButton({ label, toView: 'year' });
            }
            else {
                return this.genTitleLabel(label);
            }
        },

        genTitle() {
            const children = [];

            if (this.view === 'year') {
                const offset = 6;
                const fromYear = this.displayedYear - offset;
                const toYear = fromYear + 11;
                const label = `${ fromYear }-${ toYear }`;
                children.push(this.genTitleLabel(label));
            }
            else if (this.view === 'month') {
                children.push(this.genTitleYear());
            }
            else if (this.view === 'date') {
                children.push(this.genTitleMonth(), this.genTitleYear());
            }

            return this.$createElement('div', {
                class: 'u-date-picker__title-block',
            }, children);
        },

        genArrow(direction) {
            const methodNames = {
                left: 'toPrev',
                right: 'toNext',
            };

            const methodName = methodNames[direction];
            const computedValueName = 'disabled' + capitalize(methodName) + capitalize(this.view) + 'Table';

            const icon = this.$createElement('UIcon', {
                props: {
                    small: true,
                    name: 'arrow-' + direction,
                },
                class: 'u-date-picker__arrow-icon',
            });

            return this.$createElement('button', {
                class: 'u-date-picker__arrow-button',
                attrs: {
                    type: 'button',
                    disabled: this[computedValueName],
                },
                on: {

                    click: this[methodName],
                },
            }, [icon]);
        },

        genHeader() {
            const children = [
                this.genArrow('left'),
                this.genTitle(),
                this.genArrow('right'),
            ];

            return this.$createElement('div', {
                class: 'u-date-picker__header',
            }, children);
        },

        genTableValue(day) {
            return this.$createElement('span', {
                class: 'u-date-picker__table-item-value',
            }, day);
        },

        genTableButton({
            on,
            day,
            now,
            out,
            end,
            year,
            month,
            start,
            inRange,
            disabled,
            selected,
            children,
        }) {
            return this.$createElement('button', {
                class: ['u-date-picker__table-item', {
                    'u-date-picker__table-item_now': now,
                    'u-date-picker__table-item_out': out,
                    'u-date-picker__table-item_end': end,
                    'u-date-picker__table-item_start': start,
                    'u-date-picker__table-item_in-range': inRange,
                    'u-date-picker__table-item_disabled': disabled,
                    'u-date-picker__table-item_selected': selected,
                }],
                attrs: {
                    disabled: disabled,
                    'data-year': year,
                    'data-month': month,
                    'data-day': day,
                },
                on,
            }, children);
        },

        genDay(date) {
            const today = this.getToday();
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = date.getDate();

            const now = date.getTime() === today.getTime();
            const out = month !== this.displayedMonth;
            const end = this.isEnd(date);
            const start = this.isStart(date);
            const inRange = this.isInRange(date);
            const disabled = this.isDisabled(date);
            const selected = this.isSelected(date);

            const on = {
                click: this.onClickDay,
            };

            if (this.range && this.innerValue.filter(_ => _).length < 2) {
                on.mouseenter = this.onMouseEnterDay;
                on.mouseleave = this.onMouseLeaveDay;
            }
            else {
                this.hoveredDate = null;
            }

            const children = [this.genTableValue(day)];

            return this.genTableButton({
                on,
                day,
                now,
                out,
                end,
                year,
                month,
                start,
                inRange,
                disabled,
                selected,
                children,
            });
        },

        genTableItemsContainer(children, classes = []) {
            return this.$createElement('div', {
                class: ['u-date-picker__table-items-container', ...classes],
            }, children);
        },

        genDateTable() {
            const weeks = [];
            const year = this.displayedYear;
            const month = this.displayedMonth;
            const date = new Date(year, month, 1);
            const startDisplayedDay = this.getNormalizeDay(date);
            const daysInDisplayedMonth = new Date(year, month + 1, 0).getDate();

            let maxWeek = Math.floor((startDisplayedDay + daysInDisplayedMonth) / 7);
            let currentWeek = 0;
            while (currentWeek <= maxWeek) {
                weeks[currentWeek] = [];

                let maxDay = 6;
                let currentDay = 0;
                while (currentDay <= maxDay) {
                    const year = this.displayedYear;
                    const month = this.displayedMonth;
                    const day = currentWeek * 7 + currentDay - startDisplayedDay + 1;

                    weeks[currentWeek][currentDay] = new Date(year, month, day, 0, 0);

                    currentDay++;
                }

                currentWeek++;
            }

            const children = weeks.map(week => week.map(this.genDay));

            return this.genTableItemsContainer(children);
        },

        genDateHeader() {
            const children = names.shortDays.map(name => this.$createElement('div', {
                class: 'u-date-picker__weekday',
            }, name));

            return this.$createElement('div', {
                class: 'u-date-picker__table-row',
            }, children);
        },

        genMonth(label, index) {
            const year = this.displayedYear;
            const month = index;
            const day = 1;
            const date = new Date(year, month, day);

            const disabled = this.isDisabledMonth(date);
            const selected = this.isSelectedMonth(date);

            const on = {
                click: this.onClickMonth,
            };

            const children = [this.genTableValue(label)];

            return this.genTableButton({
                on,
                day,
                year,
                month,
                disabled,
                selected,
                children,
            });
        },

        genMonthTable() {
            const children = names.shortMonth.map(this.genMonth);
            const classes = ['u-date-picker__month-table'];

            return this.genTableItemsContainer(children, classes);
        },

        genYear(_, index) {
            const offset = 6;
            const year = this.displayedYear + index - offset;
            const month = 0;
            const day = 1;
            const date = new Date(year, month, day);

            const disabled = this.isDisabledYear(date);
            const selected = this.isSelectedYear(date);

            const on = {
                click: this.onClickYear,
            };

            const children = [this.genTableValue(year)];

            return this.genTableButton({
                on,
                day,
                year,
                month,
                disabled,
                selected,
                children,
            });
        },

        genYearTable() {
            const children = new Array(12).fill(0).map(this.genYear);
            const classes = ['u-date-picker__year-table'];

            return this.genTableItemsContainer(children, classes);
        },

        genBody() {
            const children = [];

            if (this.view === 'date') {
                children.push(this.genDateHeader(), this.genDateTable());
            }
            else if (this.view === 'month') {
                children.push(this.genMonthTable());
            }
            else if (this.view === 'year') {
                children.push(this.genYearTable());
            }

            return this.$createElement('div', {
                class: 'u-date-picker__body',
            }, children);
        },

        genConfirmButton() {
            return this.$createElement('ButtonBlock', {
                class: 'u-date-picker__confirm-button',
                props: {
                    low: true,
                    secondary: true,
                },
                on: {
                    click: this.onClickConfirm,
                },
            }, 'Готово');
        },

        genFooter() {
            if (this.confirmable && this.view === 'date') {
                return this.$createElement('div', {
                    class: 'u-date-picker__footer',
                }, [this.genConfirmButton()]);
            }
        },
    },

    render(h) {
        const header = this.genHeader();
        const body = this.genBody();
        const footer = this.genFooter();
        const children = [header, body, footer];

        return h('div', {
            ref: 'picker',
            class: 'u-date-picker',
        }, children);
    },
};
</script>

<style>
.u-date-picker {
    position: relative;
    width: 320px;
}

.u-date-picker__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light-c);
}

.u-date-picker__arrow-button {
    padding: 16px;
    font-size: 0;
    color: var(--font-secondary-color);
    transition: color var(--transition);
}
.u-date-picker__arrow-button:hover {
    color: var(--dark-c);
}

.u-date-picker__arrow-icon {
    fill: currentColor;
}

.u-date-picker__title-block {

}

.u-date-picker__title-label {
    font-family: var(--f-bold);
    font-size: var(--small-fz);
    letter-spacing: 0.06em;
    text-transform: uppercase;
    text-align: center;
}
.u-date-picker__title-label:not(:last-child) {
    margin-right: 8px;
}

.u-date-picker__month,
.u-date-picker__year {
    text-transform: inherit;
    letter-spacing: inherit;
    transition: color var(--transition);
}

.u-date-picker__body {
    padding: 16px;
}

.u-date-picker__table-row {
    display: flex;
    margin-bottom: 2px;
}

.u-date-picker__weekday {
    width: 40px;
    height: 36px;
    flex-grow: 1;
    font-size: var(--small-fz);
    line-height: 36px;
    font-family: var(--f-bold);
    letter-spacing: 0.06em;
    text-transform: uppercase;
    text-align: center;
}

.u-date-picker__days-week {
    display: flex;
}

.u-date-picker__days-week:not(:last-child) {
    margin-bottom: 2px;
}

.u-date-picker__table-items-container {
    display: flex;
    flex-wrap: wrap;
}

.u-date-picker__table-item {
    width: 40px;
    height: 36px;
    flex-grow: 1;
    cursor: pointer;
}
.u-date-picker__table-item.u-date-picker__table-item_out {
    color: var(--font-secondary-light-color)
}
.u-date-picker__table-item.u-date-picker__table-item_disabled {
    color: var(--font-secondary-light-color);
    cursor: default;
    pointer-events: none;
}
.u-date-picker__year-table .u-date-picker__table-item,
.u-date-picker__month-table .u-date-picker__table-item {
    width: 70px;
    cursor: pointer;
}
.u-date-picker__year-table .u-date-picker__table-item-value,
.u-date-picker__month-table .u-date-picker__table-item-value {
    display: block;
    text-align: center;
    text-transform: capitalize;
}

.u-date-picker__table-item-value {
    display: block;
    line-height: 34px;
    text-align: center;
    border: 1px solid var(--light-c);
    border-radius: var(--border-radius);
    transition: color var(--transition), background-color var(--transition), border-color var(--transition);
}
.u-date-picker__table-item.u-date-picker__table-item_in-range .u-date-picker__table-item-value {
    border-radius: 0;
    background-color: var(--lightest-bg);
    border-color: var(--lightest-bg);
}
.u-date-picker__table-item.u-date-picker__table-item_in-range.u-date-picker__table-item_out .u-date-picker__table-item-value {
    background-color: var(--bright-bg);
    border-color: var(--bright-bg);
}
.u-date-picker__table-item.u-date-picker__table-item_start .u-date-picker__table-item-value {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.u-date-picker__table-item.u-date-picker__table-item_end .u-date-picker__table-item-value {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
.u-date-picker__table-item.u-date-picker__table-item.u-date-picker__table-item_now .u-date-picker__table-item-value {
    border-color: var(--border-dark-c);
}
.u-date-picker__table-item:hover:not(.u-date-picker__table-item_selected) .u-date-picker__table-item-value {
    border-color: var(--border-light-c);
    background-color: var(--border-light-c);
}
.u-date-picker__table-item.u-date-picker__table-item_selected .u-date-picker__table-item-value {
    font-family: var(--f-semi-bold);
    color: var(--light-c);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.u-date-picker__table-item.u-date-picker__table-item_selected.u-date-picker__table-item_out .u-date-picker__table-item-value {
    background-color: var(--primary-lightest-color);
    border-color: var(--primary-lightest-color);
}

.u-date-picker__footer {
    padding: 0 16px 16px;
}

.u-date-picker__confirm-button {
    width: 100%;
}
</style>