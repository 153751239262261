<script>
export default {
    name: "Washboard",

    props: {
        noPadding: {
            type: Boolean,
            default: false,
        },
    },

    render(h) {
        return h(
            'div',
            {
                class: {
                    'washboard': true,
                    'washboard_disable-padding': this.noPadding,
                },
            },
            (this.$slots.default || []).map(el => {
                if (el.tag) {
                    return h(
                        'div',
                        {
                            class: 'edge',
                        },
                        [el],
                    );
                }
            }),
        );
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .washboard:not(.washboard_disable-padding) {
        padding: 24px 36px;
    }
}
@media (max-width: 1039px) {
    .washboard:not(.washboard_disable-padding) {
        padding: var(--base-card-padding);
    }
}

.edge:not(:first-child) {
    padding-top: var(--gap-micro);
}

.edge:not(:last-child) {
    padding-bottom: var(--gap-micro);
    border-bottom: 1px solid var(--border-light-c);
}
</style>