<template>
    <ChatPlaceholderTemplate
        :icon="{
            name: 'user',
            big: true,
        }"
    >
        <template #text>
            <p>
                Общайтесь напрямую с магазинами или задайте вопрос нашему сервис&#8209;менеджеру Ubibi.
            </p>

            <p>
                <UButtonText
                    primary
                    underline
                    @click="openSignInPopup"
                >Войти</UButtonText>
            </p>
        </template>
    </ChatPlaceholderTemplate>
</template>

<script>
import ChatPlaceholderTemplate from '@/components/chat/ChatPlaceholderTemplate.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');


export default {
    name: 'ChatPlaceholder',

    components: {
        UButtonText,
        ChatPlaceholderTemplate,
    },

    methods: {
        openSignInPopup() {
            this.$popup(SignInPopup);
        },
    },
};
</script>