<template>
    <div class="top-gap_medium">
        <h1 class="h1 h1_block">
            Каталоги оригинальных запчастей
        </h1>

        <Spinner
            v-if="!initialized"
            center
            size="big"
            class="top-gap_medium"
        ></Spinner>

        <div v-else class="grid layer-1">
            <InfoMessage
                v-if="globalSearchSelectedCar.id"
                class="select-car-info-message"
            >
                <p>
                    Подберите запчасти, совместимые с автомобилем
                    <CarName
                        :car="globalSearchSelectedCar"
                        :block="false"
                        class="select-car-info-message__car-name"
                    ></CarName>.
                    <ButtonText
                        :to="{
                            name: 'market-parts-catalogs-manufacturer-modification',
                            params: {
                                manufacturer: globalSearchSelectedCar.manufacturer.codename,
                                modification: globalSearchSelectedCar.id
                            },
                            query: Object.assign({}, carProductionDate ? {
                                production_date: carProductionDate
                            } : {})
                        }"
                        primary
                        underline
                    >
                        Перейти к схемам
                    </ButtonText>
                </p>
            </InfoMessage>

            <ul class="all-manufacturers">
                <li
                    v-for="group in itemsByGroups"
                    :key="'group-' + group.letter"
                    class="group"
                >
                    <div class="h2 h2_block letter">
                        {{ group.letter }}
                    </div>

                    <ul class="group__list">
                        <li class="group__list-item">
                            <RouterLink
                                v-for="manufacturer in group.data"
                                :key="'manufacturer-' + manufacturer.id"
                                :to="{
                                    name: 'market-parts-catalogs-manufacturer',
                                    params: {
                                        manufacturer: manufacturer.codename
                                    }
                                }"
                                class="manufacturer link-primary-hover"
                            >
                                <img
                                    :src="manufacturer.logo"
                                    alt=""
                                    class="logo"
                                >
                                <span class="name">{{ manufacturer.name }}</span>
                            </RouterLink>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <LastSee></LastSee>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import manufacturersList from '@/store/modules/manufacturersList.js';
import LastSee from "@/components/LastSee.vue";
import InfoMessage from '@/components/InfoMessage.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import Spinner from '@/components/Spinner.vue';
import CarName from '@/components/CarName.vue';

export default {
    name: "AutoPartsIndex",

    metaInfo() {
        return {
            title: this.meta.meta_title,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content: this.meta.meta_title },
                { property: 'og:description', content: this.meta.meta_description },
                { property: 'og:image', content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        CarName,
        Spinner,
        ButtonText,
        InfoMessage,
        LastSee,
    },

    serverPrefetch() {
        return this.INIT();
    },

    computed: {
        ...mapState({
            initialized: state => state.manufacturersList.initialized,
            meta: state => state.manufacturersList.meta,
            items: state => state.manufacturersList.items,
            globalSearchSelectedCar: state => state.searchWidget.car,
        }),

        ...mapGetters({
            carProductionDate: 'searchWidget/carProductionDate',
        }),

        itemsByGroups() {
            return this.items.reduce((result, manufacturer) => {
                let firstLetter = manufacturer.name[0].toLocaleUpperCase();
                let manufacturerData = Object.assign({}, manufacturer);
                const imageLink = ((manufacturer.logo || {}).thumbnails || {}).manufacturer_logo_medium;
                manufacturerData.logo = imageLink
                    ? this.$links.uploads + imageLink
                    : require('../../../../assets/images/logo-placeholder.svg');

                if (!result[firstLetter]) {
                    result[firstLetter] = { letter: firstLetter, data: [manufacturerData] };
                }
                else {
                    result[firstLetter].data.push(manufacturer);
                }

                return result;
            }, {});
        },
    },

    created() {
        this.$store.registerModule('manufacturersList', manufacturersList);
    },

    mounted() {
        this.$breadcrumbs([
            {
                to: {
                    name: 'market',
                },
                title: 'Запчасти и автотовары',
            },
            {
                to: {
                    name: 'market-parts-catalog',
                },
                title: 'Автозапчасти',
            },
            {
                to: {
                    name: 'market-parts-catalogs',
                },
                title: 'Каталоги оригинальных запчастей',
            },
        ]);
        if (!this.initialized) this.INIT();
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('manufacturersList');
    },

    methods: {
        ...mapActions({
            INIT: 'manufacturersList/init',
        }),

        ...mapMutations({
            DESTROY: 'manufacturersList/destroy',
        }),
    },
};
</script>

<style scoped>
.grid {
    padding: var(--gap-y-small);
}

@media (min-width: 1040px) {
    .all-manufacturers {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .all-manufacturers {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 414px) and (max-width: 767px) {
    .all-manufacturers {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 414px) {
    .all-manufacturers {
        display: grid;
        grid-gap: var(--gap-y-small);
    }
}
@media (max-width: 413px) {
    .group:not(:last-child) {
        margin-bottom: 20px;
    }
}

.group__list-item {
    margin-bottom: 16px;
}

.manufacturer {
    display: flex;
    align-items: center;
}

.logo {
    width: 36px;
    height: 24px;
}
@media (min-width: 768px) {
    .logo {
        margin-right: 16px;
    }
}
@media (max-width: 767px) {
    .logo {
        margin-right: 12px;
    }
}

.name {
    font-size: var(--adaptive-big-fz);
}

.select-car-info-message {
    width: max-content;
    max-width: 100%;
    margin-bottom: var(--gap-y-small);
}

.select-car-info-message__car-name,
.select-car-info-message__car-name >>> .car-name__main-wrap {
    color: inherit;
}
</style>