import { render, staticRenderFns } from "./BonusMalusPopup.vue?vue&type=template&id=7580854b&scoped=true&"
import script from "./BonusMalusPopup.vue?vue&type=script&lang=js&"
export * from "./BonusMalusPopup.vue?vue&type=script&lang=js&"
import style0 from "./BonusMalusPopup.vue?vue&type=style&index=0&id=7580854b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7580854b",
  null
  
)

export default component.exports