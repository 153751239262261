import { mapMutations } from 'vuex';
import getErrorCode from '@/lib/getErrorCode.js';


/**
 * @param { object } options
 * @param { boolean } options.isPopup
 */
export default (options = { isPopup: false }) => {
    const isPopup = options.isPopup;
    const isForm = !isPopup;

    return {
        ...mapMutations({
            handleCommonHttpError: 'handleCommonHttpError',
        }),

        async sendCode() {
            if (this.contract.confirmed_phone_status && this.phoneEqual) return;

            const errors = await this.$refs.form.validate();

            if (errors && errors.insurer_phone && errors.insurer_phone.length) return;

            this.sendingLoading = true;

            const data = {
                phone: this.formData.insurer_phone,
            };

            try {
                const {
                    confirmed_phone_status,
                    confirmed_phone_second_expire,
                } = await this.$api.insuranceContract.sendCode(this.uuid, data);

                this.update({
                    confirmed_phone_status,
                    confirmed_phone_second_expire,
                });

                this.startCountdown();
            }
            catch (error) {
                this.handleCommonHttpError(error);
            }
            finally {
                this.sendingLoading = false;
            }
        },

        startCountdown() {
            this.seconds = this.contract.confirmed_phone_second_expire || 60;

            this.intervalInstance = setInterval(() => {
                this.seconds -= 1;
            }, 1000);
        },

        async confirmCode() {
            if (!/^\d{4}$/.test(this.sms_code.value)) return;

            if (this.confirmLoading) return;

            this.confirmLoading = true;

            try {
                const data = {
                    phone: this.formData.insurer_phone,
                    sms_code: this.sms_code.value,
                };

                const {
                    confirmed_phone,
                    confirmed_phone_status,
                    confirmed_phone_second_expire,
                } = await this.$api.insuranceContract.confirmCode(this.uuid, data);

                if (isForm) {
                    this.patch('insurer_phone');
                }

                this.update({
                    confirmed_phone,
                    confirmed_phone_status,
                    confirmed_phone_second_expire,
                });
            }
            catch (error) {
                const code = getErrorCode(error);

                if (code === 400) {
                    const { sms_code } = error.response.data;

                    if (sms_code && Array.isArray(sms_code) && sms_code.length) {
                        this.sms_code.invalid = true;
                        this.sms_code.error = sms_code[0].message;
                    }
                }

                this.handleCommonHttpError(error);
            }
            finally {
                this.confirmLoading = false;
            }
        },

        async cancelConfirm() {
            this.update({
                confirmed_phone_status: '',
                confirmed_phone_second_expire: null,
            });
        },
    }
}