export default {
    namespaced: true,

    state: () => ({
        initialized: false,

        newPartOffersTotalCount: 0,
        newPartDefaultFacets: {},

        contractPartOffersTotalCount: 0,
        contractPartDefaultFacets: {},
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setNewPartOffersInfo(state, { current_count, facets }) {
            state.newPartOffersTotalCount = current_count;
            state.newPartDefaultFacets = facets;
        },

        setContractPartOffersInfo(state, { current_count, facets }) {
            state.contractPartOffersTotalCount = current_count;
            state.contractPartDefaultFacets = facets;
        },

        destroy(state) {
            state.initialized = false;

            state.newPartOffersTotalCount = 0;
            state.newPartDefaultFacets = {};

            state.contractPartOffersTotalCount = 0;
            state.contractPartDefaultFacets = {};
        },
    },

    actions: {
        async init({ commit, dispatch, rootState }, { id }) {
            try {
                const oem = rootState.marketNewPart.newPart.oem;
                const [newPartOffers] = await Promise.all(
                    [
                        dispatch('getNewPartOffers', { id }),
                        // dispatch('getContractPartOffers', { oem, id }),
                    ],
                );

                commit('setNewPartOffersInfo', newPartOffers);
                // commit('setContractPartOffersInfo', contractPartOffers);

                commit('setInitialized');
                return { newPartOffers };
            }
            catch (error) {
                commit('handleInitError', null, { root: true });
            }
        },

        async getNewPartOffers({ rootState }, { id }) {
            const params = {
                part_product: id,
                in_stock: true,
                get_facets: 'all',
                limit: 5,
                offset: 0,
                order_by: 'price',
                city: rootState.cities.currentCityId,
            };

            return await this.$api.esPartProductsOffers.get({ params });
        },

        async getContractPartOffers({ rootState }, { id }) {
            const params = {
                original_part: id,
                get_facets: 'all',
                limit: 0,
                offset: 0,
                city: rootState.cities.currentCityId,
            };

            return await this.$api.esContractParts.get({ params });
        },
    },
};