<template>
    <div class="progressbar-wrap">
        <div
            class="progressbar"
            :style="{
                width: progress + '%',
            }"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'LoaderProgressBar',

    props: {
        progress: Number,
    },
};
</script>

<style scoped>
.progressbar-wrap {
    position: relative;
    height: 2px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, .4);
}

.progressbar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 2px;
    transition: width 1s linear;
}
</style>