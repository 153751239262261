import { mapMutations } from 'vuex';
import equals from '@/lib/equals.js';
import getErrorCode from '@/lib/getErrorCode.js';
import { ownerCodenames, insurerCodenames } from '@/pages/insurance/osago/_uuid/useOwnerData.js';


export default () => {
    return {
        ...mapMutations({
            update: 'insuranceOsago/updateContract',
        }),

        onError(errors) {
            this.innerErrors = errors;
        },

        patch(...keys) {
            // В конец очереди, чтобы успела отработать валидация
            setTimeout(async () => {
                // предполагаю, что в errors не будет вложенных объектов
                const isValid = keys.every(key => !this.innerErrors[key] || !this.innerErrors[key].length);

                if (isValid) {
                    const data = keys.reduce((acc, key) => {
                        let value = this.formData[key];

                        if (value !== undefined) {
                            const patchedValue = this.patchedData[key];

                            if (!equals(patchedValue, value)) {
                                if (ownerCodenames.includes(key) && this.contract.owner_is_insurer) {
                                    const insurerCodename = insurerCodenames[ownerCodenames.indexOf(key)];
                                    acc[insurerCodename] = value;
                                }

                                acc[key] = value;
                            }
                        }

                        return acc;
                    }, {});

                    this.patchedData = Object.assign({}, this.patchedData, data);

                    if (Object.keys(data).length) {
                        try {
                            await this.$api.insuranceContract.change(this.uuid, data);
                        }
                        catch (error) {
                            const code = getErrorCode(error);

                            if (code === 400 && this.$refs.form) {
                                this.$refs.form.setErrors(error.response.data);
                            }

                            this.$store.commit('handleCommonHttpError', error);
                        }
                    }
                }
            }, 10);
        },
    };
};