export default ({ HTTP }) => ({
    async get(...args) {
        let config;
        let slug;
        if (args && args.length) {
            if (typeof args[0] === 'string') {
                slug = args[0];
                config = args[1];
            }
            else {
                config = args[0];
            }
        }

        if (!config) config = {};

        if (slug) {
            const url = `/part_categories/${ slug }/`;
            const response = await HTTP.get(url, config);
            return response.data;
        }
        else {
            const url = '/part_categories/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },
});