<template functional>
    <div
        :class="[
            'post-editor__block',
            'post-editor__block_' + props.widget.type,
        ]"
    >
        <div
            :class="[
                props.widget.marginTop ? 'indent-wrap_mt' + props.widget.marginTop : '',
                props.widget.marginBottom ? 'indent-wrap_mb' + props.widget.marginBottom : '',
                props.widget.paddingTop ? 'indent-wrap_pt' + props.widget.paddingTop : '',
                props.widget.paddingBottom ? 'indent-wrap_pb' + props.widget.paddingBottom : '',
            ]"
            class="indent-wrap"
        >
            <div class="hr" />
        </div>
    </div>
</template>

<script>
export default {
    name: "PostEditorHr",

    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style scoped>
.hr {
    border-top: 2px dashed var(--border-light-c);
}
</style>