var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopupWrap',{attrs:{"loading":_vm.initLoading,"noPadding":""},on:{"clickaway":_vm.close,"close":_vm.close}},[_c('div',{staticClass:"popup-content"},[_c('MarketItemInOffersPopup',{attrs:{"item":_vm.item,"modelName":_vm.modelName}}),_vm._v(" "),_c('div',{staticClass:"toolbar"},[_c('p',{staticClass:"toolbar-title"},[_vm._v("\n                Выберите пункт выдачи\n            ")]),_vm._v(" "),_c('RadioButtonsGroup',{staticClass:"view-button-desktop",attrs:{"value":_vm.view,"name":"offers-desktop","type":"text","options":[
                    {
                        label: 'Список',
                        value: 'list',
                    },
                    {
                        label: 'Карта',
                        value: 'map',
                    } ]},on:{"change":_vm.onChangeView}}),_vm._v(" "),(_vm.view === 'map')?_c('ButtonBlock',{staticClass:"view-button-mobile view-button",attrs:{"low":"","secondary":""},on:{"click":function($event){return _vm.onChangeView('list')}}},[_vm._v("\n                Списком\n            ")]):_vm._e(),_vm._v(" "),(_vm.view === 'list')?_c('ButtonBlock',{staticClass:"view-button-mobile view-button",attrs:{"low":"","secondary":""},on:{"click":function($event){return _vm.onChangeView('map')}}},[_vm._v("\n                На карте\n            ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"content-wrap"},[_c('Spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"absoluteCenter":"","size":"big"}}),_vm._v(" "),(_vm.view === 'list')?_c('div',{staticClass:"view_list"},[_c('ol',{staticClass:"offers-list",class:{
                        'offers-list_loading': _vm.loading,
                    }},_vm._l((_vm.offers),function(offer,index){return _c('li',{key:'offer-' + offer.id,staticClass:"offer-wrap"},[_c('MarketItemOffer',{staticClass:"list-item__offer",attrs:{"item":_vm.item,"offer":offer,"modelName":_vm.modelName},on:{"clickChat":_vm.close,"addToCart":function($event){return _vm.onChangeCart(index)},"deleteFromCart":function($event){return _vm.onDeleteFromCart(index)}}})],1)}),0),_vm._v(" "),(_vm.hasNext)?_c('ButtonText',{staticClass:"offers-more-btn",attrs:{"dashed":"","secondary":"","dark":""},on:{"click":_vm.load}},[_vm._v("\n                    Показать ещё\n                ")]):_vm._e()],1):_c('div',{ref:"map",staticClass:"map offers-map",class:{
                    'offers-map_loading': _vm.loading,
                }},[_c('yandexMap',{ref:"map",staticStyle:{"width":"100%","height":"100%"},attrs:{"coords":_vm.offers[0].coords,"zoom":"13","controls":['zoomControl'],"scrollZoom":false,"options":{ suppressMapOpenBlock: true }},on:{"map-was-initialized":_vm.setMapCenter,"actionend":_vm.handleActionEnd}},_vm._l((_vm.offers),function(offer,index){return _c('ymapMarker',{key:'offer-' + offer.id,ref:'offer-' + offer.id,refInFor:true,attrs:{"coords":offer.coords,"icon":_vm.getYMapMarkerIcon({ active: _vm.balloon.visible && _vm.balloon.index === index }),"markerId":'offer-' + offer.id},on:{"click":function($event){return _vm.showBalloon({ offer: offer, index: index })}}})}),1),_vm._v(" "),(_vm.balloon.visible)?_c('OfferBalloon',{attrs:{"offer":_vm.balloon.offer,"modelName":_vm.modelName},on:{"close":_vm.closeBalloon,"addToCart":function($event){return _vm.onChangeCart(_vm.balloon.offer.index, _vm.balloon.offer.id)},"deleteFromCart":function($event){return _vm.onDeleteFromCart(_vm.balloon.offer.index, _vm.balloon.offer.id)}}}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }