import pad from '@ui/utils/pad.js';

export default () => {
    return {
        getMinExperienceStartDateIso(index) {
            let date = new Date(1900, 0, 1);
            const value = this.formData.drivers[index].birth_date;

            if (value && /(\d{4}-\d{2}-\d{2})/.test(value)) {
                if (!isNaN(new Date(value))) {
                    date = new Date(value);
                    date = new Date(date.getFullYear() + 18, date.getMonth(), date.getDate());
                }
            }

            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        getMinPrevLicenseDateIso(index) {
            let date = new Date(1900, 0, 1);
            const value = this.formData.drivers[index].birth_date;

            if (value && /(\d{4}-\d{2}-\d{2})/.test(value)) {
                if (!isNaN(new Date(value))) {
                    date = new Date(value);
                    date = new Date(date.getFullYear() + 18, date.getMonth(), date.getDate());
                }
            }

            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        addDriver(index) {
            if (!index) index = this.formData.drivers.length;

            this.formData.drivers.splice(index, 0, {
                last_name: '',
                first_name: '',
                middle_name: '',
                birth_date: null,
                license_number: '',
                experience_start_date: null,
                prev_license: false,
                prev_last_name: '',
                prev_first_name: '',
                prev_middle_name: '',
                prev_license_number: '',
                prev_license_date: null,
            });
        },

        removeDriver(index) {
            this.formData.drivers.splice(index, 1);
        },
    };
};