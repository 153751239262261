<template>
    <li class="u-menu-item">
        <slot name="content">
            <ButtonText
                class="u-menu-item__button"
                :aria-selected="selected"
                :disabled="disabled"
                :class="{
                    'u-menu-item__button_selected': selected
                }"
                v-bind="$attrs"
                v-on="$listeners"
            >
                <slot></slot>
            </ButtonText>
        </slot>
    </li>
</template>

<script>
import ButtonText from '@ui/components/UButton/UButtonText.vue';


export default {
    name: 'UMenuItem',

    components: { ButtonText },

    inheritAttrs: false,

    props: {
        selected: Boolean,
        disabled: Boolean,
    },
};
</script>

<style>
.u-menu-item {
    width: 100%;
    display: flex;
    white-space: nowrap;
}

.u-menu-item__button {
    min-height: 36px;
    width: 100%;
    padding: 8px 12px;
    background-color: var(--light-c);
    border-radius: var(--border-radius);
    transition: background-color var(--transition);
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    text-align: left;
    white-space: normal;
}
.u-menu-item__button:hover,
.u-menu-item__button:focus {
    background-color: var(--lightest-bg);
}
.u-menu-item__button:disabled,
.u-menu-item__button.u-menu-item__button_selected {
    pointer-events: none;
    cursor: default;
    color: var(--font-secondary-color);
    background-color: var(--light-c);
}
</style>