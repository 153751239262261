<template>
    <AccountLayout>
        <Spinner
            v-if="!initialized"
            size="big"
            center
        ></Spinner>

        <div v-else>
            <BackLink
                :to="{
                    name: isActive ? 'account-orders-active' : 'account-orders-archive'
                }"
                class="mb-16"
            >
                Все заказы
            </BackLink>

            <h1 class="h1 h1_block">
                Заказ № {{ order.number }}
            </h1>

            <div class="layer-1">
                <div class="summary">
                    <Status
                        :status="dictionary.orders.obj[order.status]"
                        class="bottom-gap_mini"
                    ></Status>

                    <div class="summary__section">
                        <div class="summary__row">
                            <span class="summary__key">
                                Дата оформления:
                            </span>

                            <span class="summary__value">
                                {{ order.create_date | dt }}
                            </span>
                        </div>

                        <div class="summary__row">
                            <span class="summary__key">
                                Сумма заказа:
                            </span>

                            <span class="summary__value">
                                <b class="text_big-fz">{{ order.total_price | price }}</b><template
                                    v-if="order.payment"
                                >, оплачен {{ order.payment.create_date | dt }}</template>
                            </span>
                        </div>
                    </div>

                    <div class="summary__section">
                        <div class="summary__row">
                            <span class="summary__key">
                                Получатель:
                            </span>

                            <span class="summary__value">
                                {{ [order.last_name, order.first_name, order.patronymic].filter(_ => _).join(' ') }},
                                {{ order.phone }}, {{ order.email }}
                            </span>
                        </div>

                        <div class="summary__row">
                            <span class="summary__key">
                                Способ получения:
                            </span>

                            <span class="summary__value">
                                <template v-if="order.delivery_service.codename === 'ubibi'">
                                    Пункт выдачи Ubibi ({{ order.pickup_point.city }}, {{ order.pickup_point.address }})<PickupPointInfoTooltip
                                        :point="order.pickup_point"
                                        class="delivery-service__tooltip delivery-service__tooltip-desktop"
                                    ></PickupPointInfoTooltip><InfoIcon
                                        class="delivery-service__tooltip delivery-service__tooltip-mobile"
                                        @click="showPickupPointInfoPopup"
                                    ></InfoIcon>
                                </template>

                                <template v-else-if="order.delivery_service.codename === 'cdek'">
                                    СДЭК ({{ order.delivery_city.full_name }})
                                </template>

                                <!--                                {{ deliveryTypeLabel }}-->
                                <!--                                <UTooltip-->
                                <!--                                    v-if="order.delivery_type === 'city_delivery' && order.delivery_yandex_taxi_class"-->
                                <!--                                >-->
                                <!--                                    Тип автомобиля зависит от веса и габаритов вашего заказа,-->
                                <!--                                    он определяется менеджером магазина при комплектации заказа.-->
                                <!--                                </UTooltip>-->
                            </span>
                        </div>

                        <!--                        <div-->
                        <!--                            v-if="order.delivery_type === 'pickup_point' && order.pickup_point"-->
                        <!--                            class="summary__row"-->
                        <!--                        >-->
                        <!--                            <span class="summary__key">-->
                        <!--                                Пункт выдачи / постамат:-->
                        <!--                            </span>-->

                        <!--                            <span class="summary__value summary__value-address">-->
                        <!--                                <span>{{ order.pickup_point.city }}, {{ order.pickup_point.address }}<PickupPointInfoTooltip-->
                        <!--                                    :point="order.pickup_point"-->
                        <!--                                    class="ml-8 hidden-s"-->
                        <!--                                ></PickupPointInfoTooltip><UButtonIcon-->
                        <!--                                    :icon="{-->
                        <!--                                        name: 'info',-->
                        <!--                                        small: true,-->
                        <!--                                        secondary: true,-->
                        <!--                                    }"-->
                        <!--                                    class="pickup-point__button"-->
                        <!--                                    @click="showPickupPointInfoPopup"-->
                        <!--                                ></UButtonIcon></span>-->
                        <!--                                <UButtonText-->
                        <!--                                    v-if="['new', 'wait_confirm', 'partially_confirmed'].includes(order.status)"-->
                        <!--                                    secondary-->
                        <!--                                    dashed-->
                        <!--                                    dark-->
                        <!--                                    class="choose-pickup-point"-->
                        <!--                                    @click="choosePickupPoint"-->
                        <!--                                >-->
                        <!--                                    Изменить-->
                        <!--                                </UButtonText>-->
                        <!--                            </span>-->
                        <!--                        </div>-->
                    </div>

                    <template v-if="order.delivery_type === 'city_delivery' && order.delivery_yandex_claim ">
                        <div class="summary__section">
                            <div
                                v-if="!isYandexDeliveryCancelled"
                                class="summary__row"
                            >
                                <span class="summary__key">
                                    Стоимость доставки:
                                </span>

                                <span class="summary__value">
                                    <b class="text_big-fz">{{ order.delivery_yandex_claim.payment.amount | price }}</b>
                                    <template
                                        v-if="order.delivery_yandex_claim.payment.create_date"
                                    >, оплачена {{ order.delivery_yandex_claim.payment.create_date | dt }}</template>
                                </span>
                            </div>

                            <div
                                v-if="!isYandexDeliveryCancelled"
                                class="summary__row"
                            >
                                <span class="summary__key">
                                    Адрес доставки:
                                </span>

                                <span class="summary__value">
                                    {{ order.delivery_yandex_claim.address_to }}
                                </span>
                            </div>

                            <div
                                v-if="!isYandexDeliveryCancelled
                                    && yandexDeliveryData.user_comment"
                                class="summary__row"
                            >
                                <span class="summary__key">
                                    Комментарий для курьера:
                                </span>

                                <span class="summary__value">
                                    {{ yandexDeliveryData.user_comment }}
                                </span>
                            </div>

                            <div
                                v-if="!isYandexDeliveryCancelled
                                    && order.delivery_yandex_claim.cargo_loaders"
                                class="summary__row"
                            >
                                <span class="summary__key">
                                    Дополнительно:
                                </span>

                                <span class="summary__value">
                                    {{ yandexDeliveryCargoLoadersText }}
                                </span>
                            </div>

                            <div
                                v-if="isYandexDeliveryInWork || isYandexDeliveryFinish"
                                class="summary__row"
                            >
                                <span class="summary__key">
                                    Статус доставки:
                                </span>

                                <div class="summary__value">
                                    <div class="flex align-items-center">
                                        <Spinner
                                            v-if="renderYandexDeliveryInfo.icon
                                                && renderYandexDeliveryInfo.icon === 'spinner'"
                                            class="mr-8"
                                        ></Spinner>
                                        <UIcon
                                            v-else-if="renderYandexDeliveryInfo.icon"
                                            :name="renderYandexDeliveryInfo.icon"
                                            small
                                            class="mr-8"
                                        ></UIcon>

                                        <p class="text_bold">
                                            {{ renderYandexDeliveryInfo.title }}
                                        </p>
                                    </div>
                                    <p
                                        v-if="renderYandexDeliveryInfo.subtitle"
                                        class="mt-4 text_small-fz text_small-lh text_secondary"
                                    >
                                        {{ renderYandexDeliveryInfo.subtitle }}
                                    </p>

                                    <div
                                        v-if="!isYandexDeliveryFinish && yandexDeliveryData.performer_info"
                                        class="mt-4"
                                    >
                                        <p>
                                            <span
                                                class="capitalize"
                                            >{{ yandexDeliveryData.performer_info.car_color }}</span>
                                            {{ yandexDeliveryData.performer_info.car_model }},
                                            {{ yandexDeliveryData.performer_info.car_number | carNumber }}
                                        </p>
                                        <p>
                                            {{ yandexDeliveryData.performer_info.courier_name }},
                                            <a
                                                v-if="driverPhoneData.phone"
                                                :href="`tel:${ driverPhoneData.phone },,${ driverPhoneData.ext }`"
                                                class="underline"
                                            >{{ driverPhoneData.phone | phone }} (доб.: {{ driverPhoneData.ext }})</a>
                                        </p>
                                    </div>

                                    <div>
                                        <ButtonText
                                            v-if="canShowTrackYandexDeliveryPopup"
                                            dashed
                                            primary
                                            @click="showTrackYandexDeliveryPopup"
                                        >
                                            Показать на карте
                                        </ButtonText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="order.delivery_type === 'russian_delivery' && order.delivery_claim">
                        <div class="summary__section">
                            <div class="summary__row">
                                <span class="summary__key">
                                    Стоимость доставки:
                                </span>

                                <span class="summary__value">
                                    <b class="text_big-fz">{{ order.delivery_claim.payment.amount | price }}</b>,
                                    оплачена {{ order.delivery_claim.payment.create_date | dt }}
                                </span>
                            </div>

                            <div
                                v-if="order.delivery_claim.office_to"
                                class="summary__row"
                            >
                                <span class="summary__key">
                                    {{ order.delivery_claim.service.codename === 'pochtaru'
                                        ? 'Почтовое отделение:'
                                        : 'Пункт выдачи / постамат:' }}
                                </span>

                                <span class="summary__value">
                                    {{ order.delivery_claim.office_to.address_full }}<PickupPointInfoTooltip
                                        :point="order.delivery_claim"
                                        class="ml-8"
                                    ></PickupPointInfoTooltip>
                                </span>
                            </div>

                            <div
                                v-if="order.delivery_claim.address_to"
                                class="summary__row"
                            >
                                <span class="summary__key">
                                    Адрес доставки:
                                </span>

                                <span class="summary__value">
                                    {{ order.delivery_claim.city_to }}, {{ order.delivery_claim.address_to }}
                                </span>
                            </div>

                            <div
                                v-if="order.delivery_claim.user_comment"
                                class="summary__row"
                            >
                                <span class="summary__key">
                                    Комментарий для службы доставки:
                                </span>

                                <p class="summary__value p-textarea">{{ order.delivery_claim.user_comment }}</p>
                            </div>
                        </div>
                    </template>
                </div>

                <div
                    v-if="hasFooter"
                    class="footer"
                >
                    <InfoMessage
                        v-if="order.status === 'new' && !order.payment && order.seconds_to_pay_expiration"
                        class="order__actions-warning"
                        error
                    >
                        <p>
                            Оплатите заказ, иначе он будет автоматически отменён через:&nbsp;<BackTimerText
                                :seconds="order.seconds_to_pay_expiration"
                                @timeOver="handlePaymentTimeOver"
                            ></BackTimerText>
                        </p>
                    </InfoMessage>

                    <InfoMessage
                        v-if="order.status === 'new' && !order.payment && order.seconds_to_pay_expiration === 0"
                        class="order__actions-warning"
                        error
                    >
                        Время на оплату истекло, скоро заказ будет автоматически отменён.
                    </InfoMessage>

                    <InfoMessage
                        v-if="order.status === 'partially_confirmed'"
                        class="order__actions-warning"
                        error
                    >
                        Заказ был частично подтверждён, так как не все товары есть в наличии.
                        Вы можете отменить заказ или изменить состав заказа в рамках подтвержденных остатков.
                    </InfoMessage>

<!--                    <InfoMessage-->
<!--                        v-if="order.status === 'ready_pickup_shop'"-->
<!--                        class="order__actions-warning"-->
<!--                    >-->
<!--                        <p v-if="!order.shop.current_work.place.is_active">-->
<!--                            <template v-if="order.shop.is_delivery_yandex">-->
<!--                                Сейчас магазин закрыт, вы можете забрать заказ самостоятельно или <ButtonText-->
<!--                                    primary-->
<!--                                    dashed-->
<!--                                    @click="showDeliveryPopup"-->
<!--                                >-->
<!--                                    оформить доставку-->
<!--                                </ButtonText> в другой день в рабочее время магазина.-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                                Сейчас магазин закрыт,-->
<!--                                вы можете забрать заказ самостоятельно в другой день в рабочее время магазина.-->
<!--                            </template>-->
<!--                            Обратите внимание, заказ необходимо получить до {{ orderCancelDate | dayMonth }},-->
<!--                            иначе он будет автоматически отменён.-->
<!--                        </p>-->
<!--                        <p v-else-if="isShopCloseSoon">-->
<!--                            <template v-if="order.shop.is_delivery_yandex">-->
<!--                                Магазин скоро закроется, вы можете забрать заказ самостоятельно или <ButtonText-->
<!--                                    primary-->
<!--                                    dashed-->
<!--                                    @click="showDeliveryPopup"-->
<!--                                >-->
<!--                                    оформить доставку-->
<!--                                </ButtonText> в другой день в рабочее время магазина.-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                                Магазин скоро закроется,-->
<!--                                вы можете забрать заказ самостоятельно в другой день в рабочее время магазина.-->
<!--                            </template>-->
<!--                            Обратите внимание, заказ необходимо получить до {{ orderCancelDate | dayMonth }},-->
<!--                            иначе он будет автоматически отменён.-->
<!--                        </p>-->
<!--                        <p v-else>-->
<!--                            Вам необходимо получить заказ до {{ orderCancelDate | dayMonth }},-->
<!--                            иначе он будет автоматически отменён.-->
<!--                            <template-->
<!--                                v-if="order.shop.is_delivery_yandex-->
<!--                                    && order.shop.current_work.place.is_active-->
<!--                                    && !isShopCloseSoon"-->
<!--                            >-->
<!--                                Вы можете <ButtonText-->
<!--                                    primary-->
<!--                                    dashed-->
<!--                                    @click="showDeliveryPopup"-->
<!--                                >-->
<!--                                    оформить доставку-->
<!--                                </ButtonText> по городу, курьер привезёт заказ в течение двух часов.-->
<!--                            </template>-->
<!--                        </p>-->
<!--                    </InfoMessage>-->

<!--                    <InfoMessage-->
<!--                        v-if="order.status === 'wait_reg_delivery' && order.delivery_type === 'city_delivery'"-->
<!--                        class="order__actions-warning"-->
<!--                    >-->
<!--                        <p v-if="!order.shop.current_work.place.is_active">-->
<!--                            <template v-if="order.shop.is_delivery_pickup">-->
<!--                                Сейчас магазин закрыт, вы можете оформить доставку или <ButtonText-->
<!--                                    primary-->
<!--                                    dashed-->
<!--                                    @click="showPickupOrderPopup"-->
<!--                                >-->
<!--                                    забрать заказ самостоятельно-->
<!--                                </ButtonText> в другой день в рабочее время магазина.-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                                Сейчас магазин закрыт,-->
<!--                                вы можете оформить доставку в другой день в рабочее время магазина.-->
<!--                            </template>-->
<!--                            Обратите внимание, заказ необходимо получить до {{ orderCancelDate | dayMonth }},-->
<!--                            иначе он будет автоматически отменён.-->
<!--                        </p>-->
<!--                        <p v-else-if="isShopCloseSoon">-->
<!--                            <template v-if="order.shop.is_delivery_pickup">-->
<!--                                Магазин скоро закроется,-->
<!--                                вы можете оформить доставку в другой день в рабочее время магазина или <ButtonText-->
<!--                                    primary-->
<!--                                    dashed-->
<!--                                    @click="showPickupOrderPopup"-->
<!--                                >-->
<!--                                    забрать заказ самостоятельно-->
<!--                                </ButtonText>.-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                                Магазин скоро закроется,-->
<!--                                вы можете оформить доставку в другой день в рабочее время магазина.-->
<!--                            </template>-->
<!--                            Обратите внимание, заказ необходимо получить до {{ orderCancelDate | dayMonth }},-->
<!--                            иначе он будет автоматически отменён.-->
<!--                        </p>-->
<!--                        <p v-else>-->
<!--                            <template v-if="order.shop.is_delivery_pickup">-->
<!--                                Вам необходимо оформить доставку или <ButtonText-->
<!--                                    primary-->
<!--                                    dashed-->
<!--                                    @click="showPickupOrderPopup"-->
<!--                                >-->
<!--                                    забрать заказ самостоятельно-->
<!--                                </ButtonText> до {{ orderCancelDate | dayMonth }}, иначе он будет автоматически отменён.-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                                Вам необходимо оформить доставку до {{ orderCancelDate | dayMonth }},-->
<!--                                иначе он будет автоматически отменён.-->
<!--                            </template>-->
<!--                        </p>-->
<!--                    </InfoMessage>-->

<!--                    <InfoMessage-->
<!--                        v-if="showAsPackages-->
<!--                            && ['wait_reg_delivery', 'wait_start_country_delivery'].includes(order.status)"-->
<!--                        class="order__actions-warning"-->
<!--                    >-->
<!--                        Заказ укомплектован в несколько упаковок и будет отправлен Почтой России отдельными посылками.-->
<!--                    </InfoMessage>-->

                    <InfoMessage
                        v-if="canGetDelivery"
                        class="order__actions-warning"
                    >
                        Вам необходимо оформить доставку до {{ orderCancelDate | dayMonth }},
                        иначе заказ будет автоматически отменён.
                    </InfoMessage>

                    <InfoMessage
                        v-if="order.status === 'canceled_not_confirmed'"
                        class="order__actions-warning"
                    >
                        Магазин не подтвердил наличие товаров, заказ автоматически перемещён в завершённые.
                        Денежные средства возвращены на вашу банковскую карту.
                    </InfoMessage>

                    <div
                        v-if="hasActions"
                        class="actions"
                    >
                        <ButtonBlock
                            v-if="order.status === 'new' && !order.payment && order.seconds_to_pay_expiration > 0"
                            primary
                            low
                            class="order__action-btn order__action-btn_block"
                            :loading="paymentLoading"
                            @click="createPayment"
                        >
                            Оплатить
                        </ButtonBlock>

                        <ButtonBlock
                            v-if="order.status === 'partially_confirmed'"
                            primary
                            low
                            class="order__action-btn order__action-btn_block"
                            @click="showEditOrderPopup"
                        >
                            Изменить состав заказа
                        </ButtonBlock>

                        <ButtonBlock
                            v-if="canGetDelivery"
                            primary
                            low
                            :disabled="order.delivery_type === 'city_delivery'
                                && (isShopCloseSoon || !order.shop.current_work.place.is_active)"
                            class="order__action-btn order__action-btn_block"
                            @click="showDeliveryPopup"
                        >
                            Оформить доставку
                        </ButtonBlock>

                        <ButtonText
                            v-if="isYandexDeliveryInWork && !isYandexDeliveryFinish"
                            dashed
                            secondary
                            dark
                            class="order__action-btn order__action-btn_line"
                            @click="showCancelYandexDeliveryPopup"
                        >
                            Отменить доставку
                        </ButtonText>

                        <ButtonText
                            v-if="canCancelOrder"
                            dashed
                            secondary
                            dark
                            class="order__action-btn"
                            @click="showCancelOrderPopup"
                        >
                            Отменить заказ
                        </ButtonText>

                        <ButtonText
                            v-if="canReturn || hasActiveReturn"
                            dashed
                            secondary
                            dark
                            class="order__action-btn order__action-btn_line"
                            @click="showReturnOrderPopup"
                        >
                            {{ hasActiveReturn ? 'Переоформить возврат' : 'Оформить возврат' }}
                        </ButtonText>

                        <ButtonText
                            v-if="canReturn || hasActiveReturn"
                            dashed
                            secondary
                            dark
                            class="order__action-btn order__action-btn_line"
                            @click="showHowReturnOrderPopup"
                        >
                            Как вернуть товар?
                        </ButtonText>

                        <ButtonText
                            v-if="hasActiveReturn"
                            dashed
                            primary
                            class="order__action-btn order__action-btn_line order__action-btn_right"
                            @click="showCancelReturnOrderPopup"
                        >
                            Отменить заявку на возврат
                        </ButtonText>
                    </div>
                </div>
            </div>

            <template v-if="showAsPackages">
                <div
                    v-for="place in placePositions"
                    class="positions-wrap layer-1"
                >
                    <div class="positions-wrap__head">
                        <div class="flex align-items-center justify-content-between">
                            <h3 class="h3">
                                Отправление {{ place.number }}
                            </h3>

                            <ButtonText
                                v-if="canShowTrackWorldDeliveryPopup"
                                dashed
                                primary
                                @click="openTrackWorldDeliveryPopup(place.track_number)"
                            >
                                Отследить
                            </ButtonText>

                            <b
                                v-else
                                class="text_secondary"
                            >
                                {{ place.amount | plural(['товар', 'товара', 'товаров']) }}
                            </b>
                        </div>

                        <Chip
                            v-if="['partially_ready_pickup_point', 'partially_completed'].includes(order.status)"
                            :color="(dictionary.order_place.obj[place.delivery_order.status] || {}).color"
                            :value="(dictionary.order_place.obj[place.delivery_order.status] || {}).name"
                            outline
                            class="mt-8"
                        ></Chip>
                    </div>

                    <Washboard class="positions-wrap__content">
                        <ProfileOrderPosition
                            v-for="(marketItem, marketItemIndex) in place.positions"
                            :key="'order-market-item' + marketItemIndex"
                            :item="marketItem"
                            :orderStatus="order.status"
                        ></ProfileOrderPosition>
                    </Washboard>

                    <div
                        v-if="place.status === 'ready_pickup_point'"
                        class="positions-wrap__footer"
                    >
                        <InfoMessage class="order__actions-warning">
                            Вам необходимо забрать заказ до 30 июня, иначе он будет отправлен обратно отправителю.
                        </InfoMessage>
                    </div>
                </div>
            </template>

            <div
                v-else
                class="positions-wrap layer-1"
            >
                <div class="positions-wrap__head flex align-items-center justify-content-between">
                    <h3 class="h3">
                        Состав заказа
                    </h3>

                    <ButtonText
                        v-if="canShowTrackWorldDeliveryPopup"
                        dashed
                        primary
                        @click="openTrackWorldDeliveryPopup()"
                    >
                        Отследить
                    </ButtonText>

                    <b
                        v-else
                        class="text_secondary"
                    >{{ order.total_amount | plural(['товар', 'товара', 'товаров']) }}</b>
                </div>

                <Washboard class="positions-wrap__content">
                    <ProfileOrderPosition
                        v-for="(marketItem, marketItemIndex) in order.positions"
                        :key="'order-market-item' + marketItemIndex"
                        :item="marketItem"
                        :orderStatus="order.status"
                    ></ProfileOrderPosition>
                </Washboard>
            </div>
        </div>
    </AccountLayout>
</template>

<script>
import { plural } from '@ui/filters';
import { mapActions, mapMutations, mapState } from 'vuex';
import profileOrder from '@/store/modules/profileOrder.js';
import { DAYS_TO_RECEIVE_ORDER, HOURS_TO_SHOP_CLOSE, YANDEX_CLAIM_POLLING_TIMEOUT } from '@/settings';
import dictionary from '@/dictionary.js';
import { HTTP } from '@/http.js';
import { getErrorCode } from '@/lib/errors.js';
import { changeDays, changeHours } from '@/lib/dateTime.js';
import deepClone from '@/lib/deepClone.js';
import AccountLayout from '@/pages/account/AccountLayout.vue';
import Spinner from '@/components/Spinner.vue';
import Status from '@/components/Status.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import BackTimerText from '@/components/BackTimerText.vue';
import Washboard from '@/components/Washboard.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import PickupPointInfoTooltip from '@/components/PickupPointInfoTooltip.vue';
import BackLink from '@/components/project-buttons/BackLink.vue';
import Chip from '@/components/Chip.vue';
import ProfileOrderPosition from './components/ProfileOrderPosition.vue';
import InfoIcon from '@/components/InfoIcon.vue';
const GetYandexDeliveryPopup = () => import('./components/GetYandexDeliveryPopup.vue');
const TrackYandexDeliveryPopup = () => import('./components/TrackYandexDeliveryPopup.vue');
const CancelYandexDeliveryPopup = () => import('./components/CancelYandexDeliveryPopup.vue');
const CancelOrderPopup = () => import('./components/CancelOrderPopup.vue');
const EditOrderPopup = () => import('./components/EditOrderPopup.vue');
const PickupOrderPopup = () => import('./components/PickupOrderPopup.vue');
const ReturnOrderPopup = () => import('./components/ReturnOrderPopup.vue');
const CancelReturnOrderPopup = () => import('./components/CancelReturnOrderPopup.vue');
const HowReturnOrderPopup = () => import('@/components/popups/HowReturnOrderPopup.vue');
const GetCountryDeliveryPopup = () => import('./components/GetCountryDeliveryPopup.vue');
const CdekDeliveryPopup = () => import('./components/CdekDeliveryPopup.vue');
const TrackWorldDeliveryPopup = () => import('./components/TrackWorldDeliveryPopup.vue');
const DeliveryOfficesPopup = () => import('@/components/popups/DeliveryOfficesPopup.vue');
const PickupPointInfoPopup = () => import('@/components/popups/PickupPointInfoPopup.vue');

export default {
    name: 'ProfileOrderPage',

    components: {
        InfoIcon,
        Chip,
        BackLink,
        AccountLayout,
        Spinner,
        PickupPointInfoTooltip,
        ButtonBlock,
        ButtonText,
        UIcon,
        Washboard,
        InfoMessage,
        BackTimerText,
        Status,
        ProfileOrderPosition,
    },

    metaInfo() {
        return {
            title: 'ЛК / Мои заказы / Заказ №' + this.order.number,
        };
    },

    filters: {
        plural,
    },

    data() {
        return {
            dictionary,

            yandexDeliveryDataCancelToken: null,
            yandexDeliveryData: {},
            getDriverPhoneCancelToken: null,
            getDriverPhoneTimeout: null,
            driverPhoneData: {},

            paymentLoading: false,
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.profileOrder.initialized,
            order: state => state.profileOrder.order,
        }),

        isActive() {
            const activeStatuses = [
                'new',
                'wait_confirm',
                'partially_confirmed',
                'collecting',
                'ready_pickup_shop',
                'partially_ready_pickup_point',
                'ready_pickup_point',
                'wait_reg_delivery',
                'wait_delivery_to_pickup_point',
                'wait_start_city_delivery',
                'wait_start_country_delivery',
                'delivering',
                'delivery_error',
                'initiated_return',
            ];

            return activeStatuses.includes(this.order.status);
        },

        placePositions() {
            if (this.order.places) {
                const places = deepClone(this.order.places);

                places.forEach(place => {
                    const positionsMap = place.positions.reduce((acc, position) => {
                        if (!acc[position.offer.uuid]) {
                            acc[position.offer.uuid] = position;
                            position.place_amount = 1;
                            position.place_price = position.price;
                        }
                        else {
                            acc[position.offer.uuid].place_amount++;
                            acc[position.offer.uuid].place_price = acc[position.offer.uuid].place_amount
                                * position.price;
                        }
                        return acc;
                    }, {});

                    place.positions = Object.values(positionsMap);
                    place.amount = place.positions.reduce((acc, position) => acc + position.place_amount, 0);
                });

                return places;
            }
            return [];
        },

        deliveryTypeLabel() {
            let str = this.dictionary.delivery_type.obj[this.order.delivery_type].short;
            if (this.order.delivery_type === 'city_delivery') {
                str += ' (Яндекс Go';
                if (this.order.delivery_yandex_taxi_class) {
                    str += `, тариф «${ this.dictionary.taxi_class.obj[this.order.delivery_yandex_taxi_class].name }»`;
                }
                str += ')';
            }
            else if (this.order.delivery_type === 'russian_delivery') {
                if (this.order.delivery_claim) {
                    str += ` (${ this.order.delivery_claim.service.name })`;
                }
            }
            else if (this.order.delivery_type === 'pickup_ubibi') {
                str += ` (${ this.order.pickup_point.service.name })`;
            }
            return str;
        },

        hasFooter() {
            return this.hasActions ||
                    this.order.status === 'new' ||
                    this.order.status === 'partially_confirmed' ||
                    this.order.status === 'ready_pickup_shop' ||
                    (this.showAsPackages
                        && ['wait_reg_delivery', 'wait_start_country_delivery'].includes(this.order.status));
        },

        hasActions() {
            return this.canPay ||
                this.canCancelOrder ||
                this.order.status === 'partially_confirmed' ||
                this.canGetDelivery ||
                this.canReturn ||
                this.hasActiveReturn ||
                (this.isYandexDeliveryInWork && !this.isYandexDeliveryFinish);
        },

        canPay() {
            return this.order.status === 'new' && !this.order.payment && this.order.seconds_to_pay_expiration > 0;
        },

        canGetDelivery() {
            // if (this.order.delivery_type === 'city_delivery') {
            //     if (this.order.status === 'wait_reg_delivery') return true;
            // }
            // else if (this.order.delivery_type === 'russian_delivery') {
            //     if (this.order.status === 'wait_reg_delivery') return true;
            // }
            // return false;

            return this.order.delivery_service.codename === 'cdek' && this.order.status === 'wait_reg_delivery';
        },

        canCancelOrder() {
            const statuses = ['new', 'wait_confirm', 'partially_confirmed'];
            return statuses.includes(this.order.status);
        },

        orderCancelDate() {
            return changeDays(this.order.collected_at, DAYS_TO_RECEIVE_ORDER);
        },

        isShopCloseSoon() {
            const shopCloseTimeSplit = this.order.shop.current_work.place.text.split(':');
            // TODO: переделать на серверное время #servertime
            const shopCloseTime = new Date();
            shopCloseTime.setHours(shopCloseTimeSplit[0], shopCloseTimeSplit[1], 0, 0);

            const shopCloseTimeEarly = changeHours(shopCloseTime, -HOURS_TO_SHOP_CLOSE);

            const currentTime = new Date().getTime();

            return currentTime > shopCloseTimeEarly.getTime();
        },

        yandexDeliveryClaimId() {
            return ((this.order || {}).delivery_yandex_claim || {}).id || '';
        },

        renderYandexDeliveryInfo() {
            const state = {
                'new': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'estimating': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'estimating_failed': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'ready_for_approval': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'accepted': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'performer_lookup': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'performer_draft': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'performer_found': {
                    title: 'Курьер на пути в магазин:',
                    subtitle: '',
                    icon: '',
                },
                'performer_not_found': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'pickup_arrived': {
                    title: 'Курьер находится в точке А:',
                    subtitle: '',
                    icon: '',
                },
                'ready_for_pickup_confirmation': {
                    title: 'Курьер находится в точке А:',
                    subtitle: '',
                    icon: '',
                },
                'pickuped': {
                    title: 'Курьер на пути в точку В:',
                    subtitle: '',
                    icon: '',
                },
                'delivery_arrived': {
                    title: 'Курьер находится в точке B:',
                    subtitle: '',
                    icon: '',
                },
                'ready_for_delivery_confirmation': {
                    title: 'Курьер находится в точке B:',
                    subtitle: '',
                    icon: '',
                },
                'delivered': {
                    title: 'Заказ доставлен',
                    subtitle: '',
                    icon: 'done-circle',
                },
                'delivered_finish': {
                    title: 'Заказ доставлен',
                    subtitle: '',
                    icon: 'done-circle',
                },
                'returning': {
                    title: 'Курьер на пути в точку А (возврат товара):',
                    subtitle: '',
                    icon: '',
                },
                'return_arrived': {
                    title: 'Курьер находится в точке А (возврат товара):',
                    subtitle: '',
                    icon: '',
                },
                'ready_for_return_confirmation': {
                    title: 'Курьер находится в точке А (возврат товара):',
                    subtitle: '',
                    icon: '',
                },
                'returned': {
                    title: 'Заказ возвращён в магазин',
                    subtitle: 'Заказ уже был передан курьеру для доставки по указанному адресу, деньги за доставку не будут возвращены на вашу банковскую карту.',
                    icon: 'cancel',
                },
                'returned_finish': {
                    title: 'Заказ возвращён в магазин',
                    subtitle: 'Заказ уже был передан курьеру для доставки по указанному адресу, деньги за доставку не будут возвращены на вашу банковскую карту.',
                    icon: 'cancel',
                },
                'cancelled': {
                    title: 'Доставка была отменена',
                    subtitle: 'Деньги за оплату доставки будут возвращены на вашу банковскую карту.',
                    icon: 'cancel',
                },
                'cancelled_with_payment': {
                    title: 'Доставка была отменена',
                    subtitle: 'Заказ уже был передан курьеру для доставки по указанному адресу, деньги за доставку не будут возвращены на вашу банковскую карту.',
                    icon: 'cancel',
                },
                'cancelled_by_taxi': {
                    title: '',
                    subtitle: '',
                    icon: '',
                },
                'cancelled_with_items_on_hands': {
                    title: 'Доставка была отменена',
                    subtitle: 'Заказ уже был передан курьеру для доставки по указанному адресу, деньги за доставку не будут возвращены на вашу банковскую карту.',
                    icon: 'cancel',
                },
            };

            if (this.yandexDeliveryData.status === 'in_work') {
                state.cancelled_by_taxi = {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                };
            }
            else if (this.yandexDeliveryData.status === 'cancelled') {
                state.cancelled_by_taxi = {
                    title: 'Доставка была отменена',
                    subtitle: 'Деньги за оплату доставки будут возвращены на вашу банковскую карту.',
                    icon: 'cancel',
                };
            }

            if (this.yandexDeliveryData.status === 'cancelled_without_refund') {
                state.cancelled.subtitle = 'Заказ уже был передан курьеру для доставки по указанному адресу, деньги за доставку не будут возвращены на вашу банковскую карту.';
            }
            else if (this.yandexDeliveryData.status === 'cancelled_with_part_refund') {
                state.cancelled.subtitle = 'С вас будет удержана комиссия в размере минимальной стоимости тарифа, остаток средств будет возвращён на вашу банковскую карту.';
            }
            else if (this.yandexDeliveryData.status === 'cancelled_with_full_refund') {
                state.cancelled.subtitle = 'Деньги за оплату доставки будут возвращены на вашу банковскую карту.';
            }

            return state[this.yandexDeliveryData.claim_status] || {};
        },

        isYandexDeliveryInWork() {
            return this.yandexDeliveryData.status === 'in_work';
        },

        isYandexDeliveryFinish() {
            return this.yandexDeliveryData.status === 'delivered'
                    || this.isYandexDeliveryCancelled
                    || this.isYandexDeliveryReturned;
        },

        isYandexDeliveryCancelled() {
            const statuses = [
                'cancelled',
                'cancelled_without_refund',
                'cancelled_with_part_refund',
                'cancelled_with_full_refund',
            ];
            return statuses.includes(this.yandexDeliveryData.status);
        },

        isYandexDeliveryReturned() {
            return this.yandexDeliveryData.status === 'returned'
                    || this.yandexDeliveryData.claim_status === 'returned'
                    || this.yandexDeliveryData.claim_status === 'returned_finish';
        },

        canGetYandexDriverPhone() {
            const statuses = [
                'performer_found',
                'pickup_arrived',
                'ready_for_pickup_confirmation',
                'pickuped',
                'delivery_arrived',
                'ready_for_delivery_confirmation',
                'returning',
                'return_arrived',
                'ready_for_return_confirmation',
            ];
            return statuses.includes(this.yandexDeliveryData.claim_status);
        },

        canShowTrackYandexDeliveryPopup() {
            const statuses = [
                'performer_found',
                'pickup_arrived',
                'ready_for_pickup_confirmation',
                'pickuped',
                'delivery_arrived',
                'ready_for_delivery_confirmation',
                'delivered',
                'returning',
                'return_arrived',
                'ready_for_return_confirmation',
            ];
            return statuses.includes(this.yandexDeliveryData.claim_status);
        },

        yandexDeliveryCargoLoadersText() {
            if (this.order.delivery_yandex_claim) {
                let cargoLoadersText;
                switch (this.order.delivery_yandex_claim.cargo_loaders) {
                case 1:
                    cargoLoadersText = 'Помощь одного грузчика';
                    break;
                case 2:
                    cargoLoadersText = 'Помощь двух грузчиков';
                    break;
                case 3:
                    cargoLoadersText = 'Помощь трёх грузчиков';
                    break;
                default:
                    cargoLoadersText = '';
                }
                return cargoLoadersText;
            }
            else {
                return '';
            }
        },

        canShowTrackWorldDeliveryPopup() {
            const statuses = [
                'delivering',
                'partially_ready_pickup_point',
                'ready_pickup_point',
                'partially_completed',
            ];

            return this.order.delivery_type === 'russian_delivery'
                && this.order.delivery_claim
                && statuses.includes(this.order.status);
        },

        showAsPackages() {
            return this.order.delivery_type === 'russian_delivery'
                && this.order.delivery_claim
                && this.order.delivery_claim.service.codename === 'pochtaru'
                && this.order.places.length > 1;
        },

        canReturn() {
            // TODO: переделать на серверное время #servertime
            return this.order.return_expiration_time
                && (new Date(this.order.return_expiration_time).getTime() > new Date().getTime())
                && (this.order.status === 'completed' || this.order.status === 'partially_returned')
                && this.order.delivery_type !== 'russian_delivery';
        },

        hasActiveReturn() {
            return this.order.status === 'initiated_return'
                && this.order.returns
                && this.order.returns.some(item => item.status === 'new' || item.status === 'confirmed');
        },

        visibleReturns() {
            const statuses = ['confirmed_by_client', 'suspended', 'completed'];

            return this.order.returns
                && this.order.returns.reduce((acc, item) => {
                    if (statuses.includes(item.status)) {
                        let newItem = Object.assign({}, item);
                        newItem.positions = newItem.positions.map(position => {
                            return {
                                id: position.position.id,
                                modelName: position.position.offer.offer_type,
                                item: {
                                    offer: position.position.offer,
                                    amount: position.quantity_returned,
                                    total_price: position.quantity_returned * position.position.price,
                                },
                            };
                        });

                        acc.push(newItem);
                    }
                    return acc;
                }, []);
        },
    },

    watch: {
        'yandexDeliveryData.claim_status'(value) {
            const waitStartDeliveryStatuses = ['new', 'estimating',
                'estimating_failed', 'ready_for_approval',
                'accepted', 'performer_lookup',
                'performer_draft', 'performer_found',
                'performer_not_found', 'pickup_arrived',
                'ready_for_pickup_confirmation',
                'cancelled_by_taxi'];
            const completedStatuses = ['delivered', 'delivered_finish'];
            const deliveringStatuses = ['pickuped', 'delivery_arrived',
                'ready_for_delivery_confirmation', 'returning',
                'return_arrived', 'ready_for_return_confirmation'];

            if (waitStartDeliveryStatuses.includes(value)) this.order.status = 'wait_start_city_delivery';
            else if (deliveringStatuses.includes(value)) this.order.status = 'delivering';
            else if (completedStatuses.includes(value)) this.order.status = 'completed';
            else this.order.status = 'wait_reg_delivery';
        },
    },

    created() {
        this.$store.registerModule('profileOrder', profileOrder);
    },

    mounted() {
        this.$breadcrumbs([]);
        this.init({ uuid: this.$route.params.uuid });
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('profileOrder');
    },

    methods: {
        ...mapMutations({
            SET: 'profileOrder/set',
            CHANGE_DELIVERY_TYPE: 'profileOrders/changeOrderDeliveryType',
            CHANGE_ORDER_STATUS: 'profileOrders/changeOrderStatus',
            DESTROY: 'profileOrder/destroy',
        }),

        ...mapActions({
            init: 'profileOrder/init',
            UPDATE_CART: 'cart/getCart',
        }),

        async getYandexDeliveryData() {
            try {
                this.yandexDeliveryDataCancelToken = HTTP.CancelToken.source();
                this.yandexDeliveryData = await this.$api.delivery.yandex.getClaim({
                    id: this.yandexDeliveryClaimId,
                    cancelToken: this.yandexDeliveryDataCancelToken.token,
                });

                if (this.isYandexDeliveryFinish) {
                    clearTimeout(this.yandexDeliveryDataTimeout);
                    this.yandexDeliveryDataTimeout = null;
                    clearTimeout(this.getDriverPhoneTimeout);
                    this.getDriverPhoneTimeout = null;
                    if (this.getDriverPhoneCancelToken) this.getDriverPhoneCancelToken.cancel();

                    if (this.isYandexDeliveryCancelled) {
                        this.yandexDeliveryData.claim_status = 'cancelled';
                        this.order.status = 'wait_reg_delivery';
                    }
                }
                else {
                    if(!this.getDriverPhoneTimeout) this.getDriverPhone();
                    this.yandexDeliveryDataTimeout = setTimeout(() => {
                        this.getYandexDeliveryData();
                    }, YANDEX_CLAIM_POLLING_TIMEOUT);
                    if (this.canGetYandexDriverPhone && !this.getDriverPhoneTimeout) this.getDriverPhone();
                }
            }
            catch (error) {
                if (!this.isYandexDeliveryFinish) {
                    this.yandexDeliveryDataTimeout = setTimeout(() => {
                        this.getYandexDeliveryData();
                    }, YANDEX_CLAIM_POLLING_TIMEOUT);
                }
            }
        },

        async getDriverPhone() {
            try {
                this.getDriverPhoneCancelToken = HTTP.CancelToken.source();
                this.driverPhoneData = await this.$api.delivery.yandex.getDriverPhone({
                    id: this.yandexDeliveryClaimId,
                    cancelToken: this.getDriverPhoneCancelToken.token,
                });

                if (this.isYandexDeliveryFinish) {
                    clearTimeout(this.getDriverPhoneTimeout);
                    this.getDriverPhoneTimeout = null;
                    if (this.getDriverPhoneCancelToken) this.getDriverPhoneCancelToken.cancel();
                }
                else {
                    if (this.canGetYandexDriverPhone) {
                        this.getDriverPhoneTimeout = setTimeout(() => {
                            this.getDriverPhone();
                        }, this.driverPhoneData.ttl_seconds * 1000);
                    }
                }
            }
            catch (error) {
                this.driverPhoneData = {};
                if (this.canGetYandexDriverPhone) {
                    this.getDriverPhoneTimeout = setTimeout(() => {
                        this.getDriverPhone();
                    }, YANDEX_CLAIM_POLLING_TIMEOUT);
                }
            }
        },

        async createPayment() {
            this.paymentLoading = true;
            try {
                const { payment_uuid } = await this.$api.orders.pay(this.order.uuid);
                this.$router.push({
                    name: 'before-payment',
                    params: {
                        uuid: payment_uuid,
                    },
                });
            }
            catch (error) {
                const code = getErrorCode(error);
                const data = error.response.data;

                if (code === 400) {
                    const { non_field_errors } = data;
                    if (non_field_errors) {
                        this.$error(non_field_errors[0].message);
                    }
                    else {
                        this.$error('Не удалось получить ссылку на оплату.');
                    }
                }
                else if (code === 404) {
                    this.$error('Запрашиваемый адрес не существует.');
                }
                else if (code === 500) {
                    this.$error(data.detail);
                }
            }
            this.paymentLoading = false;
        },

        showCancelYandexDeliveryPopup() {
            const props = {
                claim: this.order.delivery_yandex_claim,
                callback: async (value) => {
                    this.yandexDeliveryData = value;
                    this.yandexDeliveryData.claim_status = 'cancelled';
                    this.order.status = 'wait_reg_delivery';
                    clearTimeout(this.yandexDeliveryDataTimeout);
                    this.yandexDeliveryDataTimeout = null;
                    clearTimeout(this.getDriverPhoneTimeout);
                    this.getDriverPhoneTimeout = null;
                    if (this.getDriverPhoneCancelToken) this.getDriverPhoneCancelToken.cancel();
                },
            };
            const options = { props };
            this.$popup(CancelYandexDeliveryPopup, options);
        },

        showCancelOrderPopup() {
            const props = {
                uuid: this.order.uuid,
                callback: response => {
                    if (response) {
                        this.SET(response);
                    }
                },
            };
            const options = { props };
            this.$popup(CancelOrderPopup, options);
        },

        showEditOrderPopup() {
            const props = {
                order: this.order,
                callback: response => {
                    if (response) {
                        this.SET(response);
                    }
                },
            };
            const options = { props };
            this.$popup(EditOrderPopup, options);
        },

        showDeliveryPopup() {
            this.order.delivery_type === 'russian_delivery'
                ? this.order.delivery_service.codename === 'cdek'
                    ? this.showCdekDeliveryPopup()
                    : this.showCountryDeliveryPopup()
                : this.showYandexDeliveryPopup();
        },

        showCdekDeliveryPopup() {
            const props = {
                order: this.order,
            };
            const options = { props };
            this.$popup(CdekDeliveryPopup, options);
        },

        showCountryDeliveryPopup() {
            const props = {
                order: this.order,
            };
            const options = { props };
            this.$popup(GetCountryDeliveryPopup, options);
        },

        showYandexDeliveryPopup() {
            const props = {
                order: this.order,
                canOrder: !this.isShopCloseSoon && this.order.shop.current_work.place.is_active,
                isShopCloseSoon: this.isShopCloseSoon,
            };
            const options = { props };
            this.$popup(GetYandexDeliveryPopup, options);
        },

        showPickupOrderPopup() {
            const props = {
                order: this.order,
                callback: () => {
                    this.order.delivery_type = 'pickup';
                    this.order.status = 'ready_pickup_shop';
                    this.order.delivery_yandex_claim = {};
                },
            };
            const options = { props };
            this.$popup(PickupOrderPopup, options);
        },

        showTrackYandexDeliveryPopup() {
            const props = {
                order: this.order,
            };
            const options = { props };
            this.$popup(TrackYandexDeliveryPopup, options);
        },

        openTrackWorldDeliveryPopup(trackNumber) {
            const props = {
                claim: this.order.delivery_claim,
                order: this.order.uuid,
                trackNumber,
            };
            const options = { props };
            this.$popup(TrackWorldDeliveryPopup, options);
        },

        handlePaymentTimeOver() {
            this.order.seconds_to_pay_expiration = 0;
        },

        showReturnOrderPopup() {
            const props = {
                order: this.order,
                callback: response => {
                    this.SET(response);
                },
            };
            const options = { props };
            this.$popup(ReturnOrderPopup, options);
        },

        showCancelReturnOrderPopup() {
            const props = {
                uuid: this.order.uuid,
                callback: response => {
                    this.SET(response);
                },
            };
            const options = { props };
            this.$popup(CancelReturnOrderPopup, options);
        },

        handleCancel() {
            this.UPDATE_CART();
        },

        handleCancelReturn() {
        },

        showHowReturnOrderPopup() {
            this.$popup(HowReturnOrderPopup);
        },

        choosePickupPoint() {
            const callback = async point => {
                if (point && point.id !== (this.order.pickup_point || {}).id) {
                    const uuid = this.order.uuid;
                    const data = {
                        pickup_point: point.id,
                    };

                    try {
                        const response = await this.$api.orders.changePickupPoint(uuid, data);

                        if (response) {
                            this.SET(response);
                        }
                    }
                    catch (error) {
                        this.$store.commit('handleCommonHttpError', error);
                    }
                }
            };

            const props = {
                callback,
                item: this.order,
                value: this.order.pickup_point,
                choosable: true,
            };
            const options = { props };
            this.$popup(DeliveryOfficesPopup, options);
        },

        showPickupPointInfoPopup() {
            const point = this.order.pickup_point;
            const props = { point };
            const options = { props };
            this.$popup(PickupPointInfoPopup, options);
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .summary {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .summary {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .summary {
        padding: 16px;
    }
}

.summary__section:not(:last-child) {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--border-light-c);
}

.footer {
    border-top: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .footer {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .footer {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .footer {
        padding: 16px;
    }
}

.summary__row:not(:last-child) {
    margin-bottom: 12px;
}
@media (min-width: 768px) {
    .summary__row {
        display: flex;
        align-items: flex-start;
    }
}

.summary__key {
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .summary__key {
        width: 220px;
        flex-shrink: 0;
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .summary__key {
        display: block;
        margin-bottom: 4px;
    }
}

@media (min-width: 768px) {
    .summary__value {
        flex-grow: 1;
    }
}
@media (max-width: 767px) {
    .summary__value {
        display: block;
    }
}

@media (min-width: 768px) {
    .summary__value-address {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.delivery-service__tooltip {
    margin-left: 8px;
}
@media (min-width: 768px) {
    .delivery-service__tooltip.delivery-service__tooltip-mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .delivery-service__tooltip.delivery-service__tooltip-desktop {
        display: none;
    }
}

@media (max-width: 767px) {
    .choose-pickup-point {
        margin-top: 12px;
        display: block;
    }
}

.order__actions-warning + .actions {
    margin-top: 12px;
}

@media (min-width: 768px) {
    .actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .order__action-btn:not(:last-child) {
        margin-right: 36px;
    }

    .order__action-btn_right {
        margin-left: auto;
    }
}
@media (max-width: 767px) {
    .order__action-btn_block {
        width: 100%;
    }

    .order__action-btn_line {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .order__action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

.positions-wrap {
    margin-top: var(--y-grid-gap);
}

.positions-wrap__head {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .positions-wrap__head {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .positions-wrap__head {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .positions-wrap__head {
        padding: 16px;
    }
}

@media (min-width: 1040px) {
    .positions-wrap__content {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .positions-wrap__content {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .positions-wrap__content {
        padding: 16px;
    }
}

.positions-wrap__footer {
    border-top: 1px solid var(--border-light-c);
}
@media (min-width: 1024px) {
    .positions-wrap__footer {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .positions-wrap__footer {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .positions-wrap__footer {
        padding: 16px;
    }
}
</style>
