<template>
    <div
        class="u-icon-round__wrap"
        :class="{
            'u-icon-round__wrap_micro': micro,
            'u-icon-round__wrap_mini': mini,
            'u-icon-round__wrap_small': small,
            'u-icon-round__wrap_base': base,
            'u-icon-round__wrap_big': big,
            'u-icon-round__wrap_primary': primary,
        }"
    >
        <slot name="icon">
            <UIcon
                v-bind="$attrs"
                :small="micro"
                :big="small"
                :extra="big"
                class="u-icon-round__icon"
            ></UIcon>
        </slot>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'UIconRound',

    components: {
        UIcon,
    },

    inheritAttrs: false,

    props: {
        micro: Boolean,
        mini: Boolean,
        small: Boolean,
        big: Boolean,
        primary: Boolean,
    },

    computed: {
        base() {
            return !this.micro && !this.mini && !this.small && !this.big;
        },
    },
};
</script>

<style>
.u-icon-round__wrap {
    position: relative;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.u-icon-round__wrap.u-icon-round__wrap_micro {
    width: 40px;
    height: 40px;
}
.u-icon-round__wrap.u-icon-round__wrap_mini {
    width: 48px;
    height: 48px;
}
.u-icon-round__wrap.u-icon-round__wrap_small {
    width: 56px;
    height: 56px;
}
.u-icon-round__wrap.u-icon-round__wrap_big {
    width: 120px;
    height: 120px;
}
.u-icon-round__wrap.u-icon-round__wrap_primary {
    fill: var(--light-c);
}

.u-icon-round__wrap::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--light-bg);
}
.u-icon-round__wrap.u-icon-round__wrap_base::before,
.u-icon-round__wrap.u-icon-round__wrap_big::before {
    background: linear-gradient(315deg, var(--font-secondary-light-color) 0%, var(--lightest-bg) 100%);
    opacity: 0.6;
}
.u-icon-round__wrap.u-icon-round__wrap_primary::before {
    background-color: var(--primary-color);
}

.u-icon-round__icon {
    fill: var(--font-secondary-color);
    z-index: var(--icon-round-z-index);
}
.u-icon-round__wrap.u-icon-round__wrap_small .u-icon-round__icon,
.u-icon-round__wrap.u-icon-round__wrap_micro .u-icon-round__icon,
.u-icon-round__wrap.u-icon-round__wrap_mini .u-icon-round__icon {
    fill: var(--font-secondary-dark-color);
}

.u-icon-round__wrap.u-icon-round__wrap_primary .u-icon-round__icon {
    fill: var(--light-c);
}
.u-icon-round__wrap.u-icon-round__wrap_base .u-icon-round__icon {
    width: 32px;
    height: 32px;
}
</style>