<template>
    <span class="u-dots"><slot></slot><span class="u-dots__dot">.</span><span class="u-dots__dot">.</span><span class="u-dots__dot">.</span></span>
</template>

<script>
export default {
    name: 'UDots',
};
</script>

<style>
.u-dots__dot:nth-child(1) {
    animation: dot1 1s linear infinite;
}

.u-dots__dot:nth-child(2) {
    animation: dot2 1s linear infinite;
}

.u-dots__dot:nth-child(3) {
    animation: dot3 1s linear infinite;
}

@keyframes dot1 {
    0%, 65% {
        opacity: 1;
    }

    66%, 100% {
        opacity: 0;
    }
}

@keyframes dot2 {
    0%, 21% {
        opacity: 0;
    }

    22% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    66%, 100% {
        opacity: 0;
    }
}

@keyframes dot3 {
    0%, 43% {
        opacity: 0;
    }

    44% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    66%, 100% {
        opacity: 0;
    }
}
</style>