var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"insurance-osago-request"},[_c('h1',{staticClass:"h1 h1_block insurance-osago__title"},[_vm._v("\n        Калькулятор стоимости ОСАГО\n    ")]),_vm._v(" "),_c('div',{staticClass:"form-container container_xs-external layer-1"},[_c('StepBar',{staticClass:"step-bar",attrs:{"step":_vm.step,"items":[
                {
                    label: 'Автомобиль',
                    to: {
                        name: 'insurance-osago-request-step-1',
                        params: _vm.$route.params,
                        query: _vm.$route.query,
                    }
                },
                {
                    label: 'Срок действия полиса',
                    to: {
                        name: 'insurance-osago-request-step-2',
                        params: _vm.$route.params,
                        query: _vm.$route.query,
                    },
                    error: !!_vm.errors.action_start_date && !!_vm.errors.action_start_date.length,
                },
                {
                    label: 'Водители',
                    to: {
                        name: 'insurance-osago-request-step-3',
                        params: _vm.$route.params,
                        query: _vm.$route.query,
                    }
                },
                'Собственник' ]}}),_vm._v(" "),_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('RouterView',{attrs:{"errors":_vm.errors},on:{"error":_vm.handleError}})],1)],1),_vm._v(" "),_c('InsuranceFactoids')],1)}
var staticRenderFns = []

export { render, staticRenderFns }