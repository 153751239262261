<template>
    <div class="chat-list-item-avatar__wrap">
        <ChatOnlineStatus
            v-if="online"
            class="chat-list-item-avatar__online"
        ></ChatOnlineStatus>

        <img
            v-if="src"
            :src="src"
            alt=" "
            class="chat-list-item-avatar__image"
        >

        <div v-else class="chat-list-item-avatar__placeholder">
            {{ name[0] }}
        </div>
    </div>
</template>

<script>
import ChatOnlineStatus from '@/components/_chat/ChatOnlineStatus.vue';


export default {
    name: 'ChatListItemAvatar',

    components: { ChatOnlineStatus },

    props: {
        src: String,
        name: String,
        online: Boolean,
    },
};
</script>

<style>
.chat-list-item-avatar__wrap {
    position: relative;
}

.chat-list-item-avatar__image,
.chat-list-item-avatar__placeholder {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
}

.chat-list-item-avatar__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
    background-color: var(--img-bg);
    color: var(--light-c);
}

.chat-list-item-avatar__online {
    position: absolute;
    right: 0;
    top: 0;
}
</style>