export default ({ HTTP }) => ({
    async categories(config) {
        const url = '/help/categories/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async articles(codename, config) {
        const url = `/help/articles/${ codename }/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },
});