var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'post-editor__block',
        'post-editor__block_' + _vm.widget.type,
        _vm.widget.align ]},[_c('div',{staticClass:"indent-wrap",class:[
            _vm.widget.marginTop ? 'indent-wrap_mt' + _vm.widget.marginTop : '',
            _vm.widget.marginBottom ? 'indent-wrap_mb' + _vm.widget.marginBottom : '',
            _vm.widget.paddingTop ? 'indent-wrap_pt' + _vm.widget.paddingTop : '',
            _vm.widget.paddingBottom ? 'indent-wrap_pb' + _vm.widget.paddingBottom : '' ]},[_c('figure',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}]},[(_vm.widget.link)?_c('a',{staticClass:"img-link-wrap",class:{
                    'img-link-wrap_hoverable': !_vm.widget.backgroundFlag
                },attrs:{"href":_vm.widget.link,"target":"_blank"}},[_c('img',{staticClass:"image",attrs:{"src":"","data-src":_vm.$links.uploads + _vm.widget.image.large_url,"alt":_vm.widget.alt || null,"title":_vm.widget.title || null}}),_vm._v(" "),(!_vm.widget.backgroundFlag)?_c('span',{staticClass:"img-link-text"},[_vm._v("\n                    Перейти\n                    "),_c('UIcon',{staticClass:"img-link-text__icon",attrs:{"name":"arrow-right","small":""}})],1):_vm._e()]):_c('img',{staticClass:"image",attrs:{"src":"","data-src":_vm.$links.uploads + _vm.widget.image.large_url,"alt":_vm.widget.alt || null,"title":_vm.widget.title || null}}),_vm._v(" "),(_vm.widget.caption)?_c('figcaption',{staticClass:"caption"},[_vm._v("\n                "+_vm._s(_vm.widget.caption)+"\n            ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }