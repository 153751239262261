export default {
    namespaced: true,

    state: () => ({
        data: {},
        initialized: false,
        loading: true,
    }),

    mutations: {
        set(state, data) {
            state.data = data;
        },

        destroy(state) {
            state.data = {};
            state.initialized = false;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        setLoading(state, value) {
            state.loading = value;
        },
    },

    actions: {
        async init({ commit }, { codename }) {
            try {
                const response = await this.$api.additionalPages.get(codename);
                commit('set', response);
                commit('clearHttpError', null, { root: true });
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },
    },
};