import sort from './sort.js';


export default {
    namespaced: true,

    modules: {
        sort,
    },

    state: () => ({
        reviews: {
            items: [],
            amounts: [],
            current_count: 0,
            hasNext: '',
        },
        initialized: false,
        loading: false,
    }),

    mutations: {
        setReviewsAmounts(state, amounts) {
            state.reviews.amounts = amounts;
        },

        setReviews(state, { current_count, results, next }) {
            state.reviews.items = results;
            state.reviews.current_count = current_count;
            state.reviews.hasNext = next;
        },

        addReviews(state, { current_count, results, next }) {
            state.reviews.items = [...state.reviews.items, ...results];
            state.reviews.current_count = current_count;
            state.reviews.hasNext = next;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        setLoading(state, value) {
            if (value) {
                state.loading = value;
            }
            else {
                setTimeout(() => {
                    state.loading = value;
                }, 500);
            }
        },

        destroy(state) {
            state.reviews = {
                items: [],
                amounts: [],
                current_count: 0,
                hasNext: '',
            };
            state.initialized = false;
            state.loading = false;
        },
    },

    actions: {
        async init({ commit }, { id, query }) {
            try {
                const params = Object.assign(
                    {},
                    {
                        limit: 20,
                        offset: 0,
                        order_by: '-create_date',
                    },
                    query,
                );

                const [reviews, reviewsAmounts] = await Promise.all(
                    [
                        this.$api.parts.reviews.get(id, { params }),
                        this.$api.parts.reviews.amounts(id),
                    ],
                );

                commit('setInitialized');
                commit('setReviews', reviews);
                commit('setReviewsAmounts', reviewsAmounts);

                commit('sort/setOptions', { type: 'rating' });
                commit('sort/parseQuery', query);

                return { reviews, reviewsAmounts };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getReviews({ commit }, { id, params, cancelToken }) {
            try {
                return await this.$api.parts.reviews.get(id, { params, cancelToken });
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getReviewsAmounts({ commit }, { id }) {
            try {
                const reviewsAmounts = await this.$api.parts.reviews.amounts(id);
                commit('setReviewsAmounts', reviewsAmounts);
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },
    },
};