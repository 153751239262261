<template>
    <PreviewComponent>
        <template #trigger="{ isOpen }">
            <component
                :is="trigger.tag"
                v-bind="trigger.attrs"
                class="preview-trigger"
                :class="{
                    'preview-trigger_active': isOpen
                }"
            >
                <UIcon
                    name="heart-stroke"
                    big
                    class="preview-trigger__icon"
                ></UIcon>

                <span v-if="!noTriggerText" class="preview-trigger__text">Избранное</span>
            </component>
        </template>

        <template v-if="!isAuthorized" #content>
            <div class="layer-2">
                <div class="placeholder">
                    <PlaceholderIcon
                        icon="heart-btn-stroke"
                        size="small"
                        class="mr-24"
                    ></PlaceholderIcon>

                    <div>
                        <h4 class="text_bold mb-4">
                            Вы не авторизованы
                        </h4>
                        <p class="text_base-lh">Чтобы перейти в Избранное, необходимо <UButtonText
                            primary
                            underline
                            @click="openSignInPopup"
                        >авторизоваться</UButtonText>.</p>
                    </div>
                </div>
            </div>
        </template>
    </PreviewComponent>
</template>

<script>
import { mapState } from 'vuex';
import PreviewComponent from '@/components/header/PreviewComponent.vue';
import PlaceholderIcon from '@/components/PlaceholderIcon.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');

export default {
    name: 'FavoritePreview',

    components: {
        UButtonText,
        UIcon,
        PreviewComponent,
        PlaceholderIcon,
    },

    props: {
        noTriggerText: Boolean,
    },

    computed: {
        ...mapState({
            isAuthorized: state => state.profile.isAuthorized,
        }),

        trigger() {
            if (!this.isAuthorized) {
                return {
                    tag: 'button',
                    attrs: {},
                };
            }
            else {
                return {
                    tag: 'RouterLink',
                    attrs: {
                        to: {
                            name: 'account-favorites',
                        },
                        'aria-label': 'Перейти в избранное',
                    },
                };
            }
        },
    },

    methods: {
        openSignInPopup() {
            this.$popup(SignInPopup);
        },
    },
};
</script>

<style scoped>
.placeholder {
    width: 380px;
    padding: 20px 24px;
    display: flex;
    align-items: center;
}

.preview-trigger {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    transition: color var(--transition);
}

.preview-trigger_active,
.preview-trigger:hover,
.preview-trigger:focus {
    color: var(--primary-color);
}

.preview-trigger__icon {
    display: block;
    fill: currentColor;
}

.preview-trigger__text {
    display: block;
    margin-left: 8px;
    font-size: var(--small-fz);
    font-family: var(--f-semi-bold);
}

.preview-trigger__counter {
    position: absolute;
    top: 0;
    left: 18px;
}
</style>