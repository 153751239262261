var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'post-editor__block',
        'post-editor__block_' + _vm.widget.type ]},[_c('div',{class:[
            'indent-wrap',
            _vm.widget.marginTop ? 'indent-wrap_mt' + _vm.widget.marginTop : '',
            _vm.widget.marginBottom ? 'indent-wrap_mb' + _vm.widget.marginBottom : '',
            _vm.widget.paddingTop ? 'indent-wrap_pt' + _vm.widget.paddingTop : '',
            _vm.widget.paddingBottom ? 'indent-wrap_pb' + _vm.widget.paddingBottom : '' ]},[_c('WysiwygView',{attrs:{"value":_vm.widget.text}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }