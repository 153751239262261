var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormTemplate',[_c('template',{slot:"body"},[_c('h2',{staticClass:"h2 h2_block"},[_vm._v("\n            Автомобиль\n        ")]),_vm._v(" "),_c('FormManager',{ref:"form",staticClass:"form-grid",attrs:{"formTag":false},on:{"error":_vm.onError},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[_c('FormField',{staticClass:"vehicle_license_plate",attrs:{"name":"vehicle_license_plate","type":"licensePlate","label":"Гос. номер","visibleMask":"","validations":_vm.formData.vehicle_doc_type_code === 'STS' ? 'required' : '',"loading":_vm.vehicle_license_plate.loading,"disabled":_vm.vehicle_id_number.loading},on:{"input":_vm.onInputVehicleLicensePlate,"change":function($event){return _vm.patch('vehicle_license_plate')}}}),_vm._v(" "),_c('div',{staticClass:"note",class:{
                    wide: _vm.vehicle_license_plate.failedGetInfo,
                }},[(_vm.vehicle_license_plate.showHint)?[(_vm.vehicle_license_plate.failedGetInfo)?_c('span',[_vm._v("Данные по номеру "),_c('b',[_vm._v(_vm._s(_vm.vehicle_license_plate.failedNumber))]),_vm._v(" не найдены. \n                        Проверьте, возможно, номер введен с ошибкой, \n                        либо заполните данные самостоятельно.")]):_c('span',[_vm._v("Введите номер и мы заполним часть данных за вас.")])]:_vm._e()],2),_vm._v(" "),_c('FormField',{staticClass:"vehicle_manufacturer",attrs:{"name":"vehicle_manufacturer","type":"select","label":"Марка","options":"/insurance_vehicle_manufacturer/","limit":null,"searchParam":"q","optionLabel":"name","validations":"required","disabled":_vm.vehicle_license_plate.loading || _vm.vehicle_id_number.loading},on:{"change":_vm.onChangeVehicleManufacturer}}),_vm._v(" "),_c('FormField',{staticClass:"vehicle_model",attrs:{"name":"vehicle_model","type":"select","label":"Модель","options":"/insurance_vehicle_model/","searchParam":"q","limit":null,"params":_vm.formData.vehicle_manufacturer ? {
                    manufacturer: _vm.formData.vehicle_manufacturer.id,
                } : {},"disabled":_vm.vehicle_license_plate.loading ||
                    _vm.vehicle_id_number.loading ||
                    (!_vm.formData.vehicle_manufacturer && !_vm.formData.vehicle_model),"optionLabel":"name","validations":"required"},on:{"change":_vm.onChangeVehicleModel}}),_vm._v(" "),_c('FormField',{staticClass:"vehicle_year",attrs:{"name":"vehicle_year","type":"select","label":"Год выпуска","options":(_vm.formData.vehicle_model || {}).year || [],"disabled":_vm.vehicle_license_plate.loading ||
                    _vm.vehicle_id_number.loading ||
                    (!_vm.formData.vehicle_model && !_vm.formData.vehicle_year),"validations":"required"},on:{"change":function($event){return _vm.patch('vehicle_year')}}}),_vm._v(" "),_c('FormField',{staticClass:"vehicle_engine_power",attrs:{"name":"vehicle_engine_power","type":"select","label":"Мощность двигателя","options":"/insurance_vehicle/load_power/","disabled":_vm.vehicle_license_plate.loading ||
                    _vm.vehicle_id_number.loading ||
                    (!_vm.formData.vehicle_year && !_vm.formData.vehicle_engine_power),"params":{
                    model: (_vm.formData.vehicle_model || {}).id,
                    year: _vm.formData.vehicle_year,
                },"getOptionLabel":function (option) { return option + ' л. с.'; },"getCurrentCount":function (response) { return response.length; },"getItems":function (response) { return response; },"validations":"required"},on:{"change":function($event){return _vm.patch('vehicle_engine_power')}}}),_vm._v(" "),_c('FormField',{staticClass:"vehicle_id_type_code",attrs:{"name":"vehicle_id_type_code","type":"tabs","options":[
                    {
                        value: 'vin',
                        label: 'VIN',
                    },
                    {
                        value: 'bodyNumber',
                        label: 'Кузов',
                    },
                    {
                        value: 'chassisNumber',
                        label: 'Шасси',
                    } ],"flex":"","disabled":_vm.vehicle_license_plate.loading || _vm.vehicle_id_number.loading,"validations":"required"},on:{"change":function($event){return _vm.patch('vehicle_id_type_code', 'vehicle_id_number')}}}),_vm._v(" "),_c('FormField',{staticClass:"vehicle_id_number",attrs:{"name":"vehicle_id_number","maxLength":_vm.formData.vehicle_id_type_code === 'vin' ? 17 : 13,"label":_vm.vehicle_id_number.labels[_vm.formData.vehicle_id_type_code],"validations":'required|' + (_vm.isVin ? 'isVin' : 'isBodyNumber'),"validationRules":{
                    isVin: function (ref) {
                        var value = ref.value;

                        // TODO: уточнить regexp
                        return !value ? false : !/^[A-z\d]{17}$/.test(value)
                    },
                    isBodyNumber: function (ref) {
                        var value = ref.value;

                        // предположение, что номер кузова и номер шасси совпадает
                        return !value ? false : !/^[A-z\d]{9,13}$/.test(value)
                    },
                },"upper":"","disabled":_vm.vehicle_license_plate.loading,"loading":_vm.vehicle_id_number.loading},on:{"blur":function($event){return _vm.patch('vehicle_id_number')},"input":_vm.onInputVehicleIdNumber}}),_vm._v(" "),_c('h4',{staticClass:"h4 vehicle-doc-title"},[_vm._v("\n                Документ на авто\n            ")]),_vm._v(" "),_c('FormField',{staticClass:"vehicle_doc_type_code",attrs:{"name":"vehicle_doc_type_code","type":"tabs","options":[
                    {
                        value: 'STS',
                        label: 'СТС',
                    },
                    {
                        value: 'PTS',
                        label: 'ПТС',
                    },
                    {
                        value: 'EPTS',
                        label: 'ЕПТС',
                    } ],"flex":"","disabled":_vm.vehicle_license_plate.loading || _vm.vehicle_id_number.loading},on:{"change":function($event){return _vm.patch('vehicle_doc_type_code', 'vehicle_doc_number', 'vehicle_doc_date')}}}),_vm._v(" "),_c('FormField',{staticClass:"vehicle_doc_number",attrs:{"name":"vehicle_doc_number","label":_vm.vehicle_doc_number.labels[_vm.formData.vehicle_doc_type_code],"mask":_vm.vehicle_doc_number.masks[_vm.formData.vehicle_doc_type_code],"validations":"required|re","validationRules":{
                    re: _vm.validateVehicleDocNumber,
                },"disabled":_vm.vehicle_license_plate.loading || _vm.vehicle_id_number.loading},on:{"blur":function($event){return _vm.patch('vehicle_doc_number')}}}),_vm._v(" "),_c('FormField',{staticClass:"vehicle_doc_date",attrs:{"name":"vehicle_doc_date","type":"date","visibleMask":"","label":_vm.vehicle_doc_date.labels[_vm.formData.vehicle_doc_type_code],"validations":("required|min:" + _vm.minVehicleDocDateIso + "|max:" + _vm.maxVehicleDocDateIso),"disabled":_vm.vehicle_license_plate.loading || _vm.vehicle_id_number.loading},on:{"blur":function($event){return _vm.patch('vehicle_doc_date')}}})],1),_vm._v(" "),_c('InfoMessage',{staticClass:"mt-20"},[_vm._v("\n            Оформление электронного полиса ОСАГО на Ubibi доступно для легковых автомобилей категории В, \n            которые не сдаются в аренду и не используются в качестве такси.\n        ")])],1),_vm._v(" "),_c('template',{slot:"footer"},[_c('ButtonBlock',{staticClass:"button-primary",attrs:{"primary":""},on:{"click":_vm.next}},[_vm._v("\n            Следующий шаг\n        ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }