<template>
    <div
        class="counter"
        :class="{
            'counter_error': innerValue < min || innerValue > max,
            'counter_disabled': disabled
        }"
    >
        <button
            type="button"
            class="counter__btn counter__btn_minus"
            :disabled="innerValue <= min || disabled"
            @click="onChange($event, -1)"
        >
            <UIcon
                name="minus"
                class="counter__btn-icon counter__btn-icon_minus"
            ></UIcon>
        </button>

        <input
            ref="input"
            :value="innerValue"
            type="number"
            name="counter"
            class="counter__input"
            :disabled="disabled || (innerValue === max && max === 1)"
            autocomplete="off"
            @input="onInput"
            @keyup.enter="onChange"
            @blur="onChange"
        >

        <button
            type="button"
            class="counter__btn counter__btn_plus"
            :disabled="innerValue >= max || disabled"
            @click="onChange($event, 1)"
        >
            <UIcon
                name="plus"
                class="counter__btn-icon counter__btn-icon_plus"
            ></UIcon>
        </button>

        <span
            class="counter__separator counter__separator_first"
            aria-hidden="true"
        ></span>
        <span
            class="counter__separator counter__separator_second"
            aria-hidden="true"
        ></span>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

const normalize = function(value, min, max) {
    if (!isNaN(min)) {
        if (value < min) {
            value = min;
        }
    }

    if (!isNaN(max)) {
        if (value > max) {
            value = max;
        }
    }

    return value;
};

export default {
    name: "Counter",

    components: { UIcon },

    props: {
        value: {
            type: [Number, String, Object],
            default: 1,
        },

        max: {
            type: Number,
            default: 1,
        },

        min: {
            type: Number,
            default: 1,
        },

        disabled: Boolean,
    },

    data() {
        return {
            innerValue: '',
            timeout: null,
        };
    },

    computed: {
        outputValue() {
            const value = this.innerValue;

            if (value) {
                return Number(value.toString().replace(/[^\d,]/gi, ''));
            }
            return this.min;
        },
    },

    created() {
        this.innerValue = this.value;
    },

    methods: {
        onInput() {
            const el = this.$refs.input;
            const oldValue = Number(el.value);
            let newValue = normalize(oldValue, this.min, this.max);

            if (newValue !== oldValue) {
                el.value = newValue;
            }

            this.innerValue = newValue;
        },

        onChange($event, payload) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }

            const el = this.$refs.input;
            const oldValue = Number(el.value);
            let newValue = normalize(oldValue, this.min, this.max);

            if (payload) {
                newValue = newValue + Number(payload);
            }

            if (newValue !== oldValue) {
                el.value = newValue;
            }

            this.innerValue = newValue;

            this.timeout = setTimeout(() => {
                this.$emit('change', this.outputValue);
            }, 300);
        },

        updateValue(newValue) {
            this.innerValue = normalize(newValue, this.min, this.max);
        },
    },
};
</script>

<style scoped>
.counter {
    position: relative;
    display: flex;
    width: 108px;
    flex-shrink: 0;
}

.counter__btn {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    height: 36px;
    border-top: 1px solid var(--border-dark-c);
    border-bottom: 1px solid var(--border-dark-c);
    transition: border-color var(--transition), background-color var(--transition);
}

.counter__btn_minus {
    border-left: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.counter__btn_plus {
    border-right: 1px solid var(--border-dark-c);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.counter__btn-icon {
    fill: var(--dark-c);
}

.counter__input {
    position: relative;
    width: calc(100% / 3);
    padding: 4px;
    color: var(--dark-c);
    text-align: center;
    border-left: none;
    border-right: none;
    border-top: 1px solid var(--border-dark-c);
    border-bottom: 1px solid var(--border-dark-c);
    transition: border-color var(--transition), background-color var(--transition);
    -webkit-box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    box-shadow: none;
    border-radius: 0;
}

.counter__input:hover,
.counter__input:focus,
.counter__btn:hover,
.counter__btn:focus {
    border-color: var(--border-dark-active-c);
}

.counter__input:hover ~ .counter__separator,
.counter__input:focus ~ .counter__separator {
    background-color: var(--border-dark-active-c);
}

.counter__btn_minus:hover ~ .counter__separator_first,
.counter__btn_minus:focus ~ .counter__separator_first {
    background-color: var(--border-dark-active-c);
}

.counter__btn_plus:hover ~ .counter__separator_second,
.counter__btn_plus:focus ~ .counter__separator_second {
    background-color: var(--border-dark-active-c);
}

.counter__separator {
    position: absolute;
    top: 0;
    z-index: 1;
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--border-dark-c);
    transition: background-color var(--transition);
}

.counter__separator_first {
    left: calc(100% / 3);
}

.counter__separator_second {
    right: calc(100% / 3);
}


.counter_error .counter__input,
.counter_error .counter__btn {
    color: var(--error-medium-color);
    background-color: var(--error-brightest-color);
    border-color: var(--error-lightest-color);
}
.counter_error .counter__btn-icon {
    fill: var(--error-medium-color);
}
.counter_error .counter__separator {
    background-color: var(--error-lightest-color);
}

.counter_error .counter__input:hover,
.counter_error .counter__input:focus,
.counter_error .counter__btn:hover,
.counter_error .counter__btn:focus {
    background-color: var(--error-brightest-color);
    border-color: var(--error-medium-color);
}

.counter_error .counter__input:hover ~ .counter__separator,
.counter_error .counter__btn_minus:hover ~ .counter__separator_first,
.counter_error .counter__btn_minus:focus ~ .counter__separator_first,
.counter_error .counter__btn_plus:hover ~ .counter__separator_second,
.counter_error .counter__btn_plus:focus ~ .counter__separator_second {
    background-color: var(--error-medium-color);
}


.counter__btn:disabled {
    z-index: 0;
    background-color: var(--bright-bg);
    border-color: var(--border-light-c);
}
.counter__btn:disabled .counter__btn-icon {
    fill: var(--font-secondary-light-color);
}
.counter__input:disabled {
    color: var(--font-secondary-light-color);
    background-color: var(--bright-bg);
    border-color: var(--border-light-c);
}
.counter__input:disabled ~ .counter__separator,
.counter_disabled .counter__separator {
    background-color: var(--border-light-c);
}
</style>


