<template>
    <div class="tag">
        <div
            :class="['ellipsis', {
                'tag_mr-8': clearable
            }]"
            :title="value"
        >
            <slot>
                {{ value }}
            </slot>
        </div>
        <button
            type="button"
            :tabindex="-1"
            class="tag__button"
            @click="$emit('clear')"
        >
            <UIcon
                name="cross"
                small
                secondary
                hovered
            ></UIcon>
        </button>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'Tag',

    components: {
        UIcon,
    },

    props: {
        value: String,

        clearable: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style>
.tag {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 6px 12px;
    background-color: var(--lightest-bg);
    border-radius: 16px;
}

.tag_mr-8 {
    margin-right: 8px;
}

.tag__button {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>