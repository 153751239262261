<template>
    <div class="info-page-layout__main">
        <InfoPageLayoutHead></InfoPageLayoutHead>

        <div class="content layer-1">
            <ul class="top-contacts-grid">
                <li class="contact_top">
                    <span class="address__icon-wrap">
                        <UIcon
                            name="office"
                            class="address__icon"
                        ></UIcon>
                    </span>

                    <div class="contact__info">
                        <h2 class="h3">
                            Главный офис
                        </h2>
                        <p class="contact__descriptor">
                            {{ settings.office_address }}, {{ settings.company_name }}
                        </p>
                    </div>
                </li>

                <li class="contact_top">
                    <span class="address__icon-wrap">
                        <UIcon
                            name="envelope-stroke"
                            class="address__icon"
                        ></UIcon>
                    </span>

                    <div class="contact__info">
                        <h2 class="h3">
                            Почтовый адрес
                        </h2>
                        <p class="contact__descriptor">
                            {{ settings.mail_address }}, {{ settings.company_name }}
                        </p>
                    </div>
                </li>
            </ul>

            <ul v-if="info.other_contacts" class="contacts-grid">
                <li
                    v-for="(item, index) in info.other_contacts"
                    :key="'department-' + index"
                    class="contact_base"
                >
                    <h3 class="h4">
                        {{ item.title }}
                    </h3>
                    <p class="contact__descriptor">
                        {{ item.descriptor }}
                    </p>

                    <div class="connections-list">
                        <div
                            v-if="item.phone"
                            class="connect"
                        >
                            <UIcon
                                name="phone"
                                small
                                primary
                                class="mr-8"
                            ></UIcon>
                            <a
                                :href="'tel:' + item.phone"
                                class="connect__text link-primary-hover"
                            >{{ item.phone }}</a>
                        </div>
                        <div
                            v-if="item.mail"
                            class="connect"
                        >
                            <UIcon
                                name="envelope-fill"
                                small
                                primary
                                class="mr-8"
                            ></UIcon>
                            <a
                                :href="'mailto:' + item.mail"
                                class="connect__text link-primary-hover"
                            >{{ item.mail }}</a>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="footer">
                <ButtonText
                    :href="$links.uploads + settings.requisites"
                    download
                    dashed
                    secondary
                    dark
                    target="_blank"
                    class="footer-btn footer-btn_download"
                >
                    Скачать реквизиты
                </ButtonText>

                <SocialsNets class="socials"></SocialsNets>

                <ButtonBlock
                    primary
                    class="footer-btn footer-btn_write-us"
                    @click="popup"
                >
                    Написать нам
                </ButtonBlock>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
const WriteUsPopup = () => import('@/components/popups/WriteUsPopup.vue');
import SocialsNets from "@/components/SocialsNets.vue";
import InfoPageLayoutHead from "@/components/InfoPageLayoutHead.vue";
import ButtonText from "@/components/_buttons/ButtonText.vue";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'ContactsPage',

    components: {
        UIcon,
        ButtonBlock,
        ButtonText,
        InfoPageLayoutHead,
        SocialsNets,
    },

    computed: {
        ...mapState({
            info: state => state.staticPages.data,
            settings: state => state.settings,
        }),
    },

    methods: {
        popup() {
            this.$popup(WriteUsPopup);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .content {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .content {
        padding: 20px;
    }
}

.top-contacts-grid {
    border-bottom: 1px solid var(--light-bg);
    margin-bottom: var(--gap-y-small);
    padding-bottom: var(--gap-y-small);
}
@media (min-width: 1040px) {
    .top-contacts-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 36px 20px;
    }
}

.contact_top {
    position: relative;
    display: flex;
    align-items: flex-start;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .contact_top:not(:last-child) {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .contact_top:not(:last-child) {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) {
    .contacts-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 36px 20px;
    }
}

.contact_base {
    position: relative;
}
.contact_base:nth-child(2n+1):not(:nth-child(1))::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    background-color: var(--light-bg);
}
@media (min-width: 1040px) {
    .contact_base {
        padding-left: 64px;
    }
}
@media (min-width: 768px) {
    .contact_base:not(:nth-child(1)):not(:nth-child(2)) {
        padding-top: 36px;
    }
    .contact_base:nth-child(2n+1):not(:nth-child(1))::after {
        width: calc(100% * 2 + 20px);
    }
}
@media (max-width: 767px) {
    .contact_base:not(:last-child) {
        margin-bottom: 16px;
    }

    .contact_base:nth-child(2n+1):not(:nth-child(1)) {
        padding-top: 20px;
        margin-top: 20px;
    }
    .contact_base:nth-child(2n+1):not(:nth-child(1))::after {
        width: 100%;
    }
}

.address__icon-wrap {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    padding: 12px;
    flex-shrink: 0;
    background-color: var(--light-bg);
    border-radius: 50%;
}

.address__icon {
    width: 24px;
    height: 24px;
    fill: var(--font-secondary-dark-color);
}

.contact__descriptor {
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .contact__descriptor {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .contact__descriptor {
        margin-top: 4px;
    }
}

@media (min-width: 768px) {
    .connections-list {
        margin-top: 16px;
    }
}
@media (max-width: 767px) {
    .connections-list {
        margin-top: 8px;
    }
}

.connect {
    display: flex;
    align-items:  center;
}

.connect:not(:last-child) {
    margin-bottom: 8px;
}

.footer {
    margin-top: var(--gap-y-small);
}
@media (min-width: 1040px) {
    .footer {
        padding-left: 64px;
    }
}
@media (max-width: 767px) {
    .footer {
        border-top: 1px solid var(--border-light-c);
        padding-top: 20px;
    }
}
@media (min-width: 414px) and (max-width: 767px) {
    .footer {
        flex-wrap: wrap;
    }
}
@media (min-width: 414px) {
    .footer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
    }
}

@media (min-width: 1281px) {
    .footer-btn {
        margin-right: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .footer-btn {
        margin-right: 36px;
    }
}
@media (min-width: 768px) {
    .footer-btn {
        order: 2;
    }
}

@media (min-width: 414px) and (max-width: 767px) {
    .footer-btn_download {
        order: 2;
    }
}

@media (max-width: 767px)  {
    .footer-btn_write-us {
        width: 100%;
        margin-top: 20px;
        order: 3;
    }
}

@media (min-width: 414px) {
    .socials {
        margin-left: auto;
        order: 1;
    }
}
@media (max-width: 413px) {
    .socials {
        margin-top: 20px;
    }
}
</style>