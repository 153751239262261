<template>
    <div class="empty layer-1">
        <PlaceholderIcon
            v-if="icon"
            :icon="icon"
            class="empty__icon"
        ></PlaceholderIcon>

        <h4
            v-if="title"
            class="h3 h3_text empty__title"
        >
            {{ title }}
        </h4>

        <div class="text-wrap">
            <slot name="text">
                <p class="empty__text">
                    {{ text }}
                </p>
            </slot>
        </div>

        <div
            v-if="hasBtn || hasResetFilters"
            class="empty__btns-wrap"
        >
            <slot name="button">
                <ButtonBlock
                    v-if="hasBtn && btnUrl"
                    :to="btnUrl"
                    primary
                    low
                    class="empty__btn"
                >
                    {{ btnText }}
                </ButtonBlock>
                <ButtonBlock
                    v-if="hasBtn && !btnUrl"
                    primary
                    low
                    class="empty__btn"
                    @click="$emit('btnClick')"
                >
                    {{ btnText }}
                </ButtonBlock>
                <ButtonBlock
                    v-if="hasResetFilters"
                    primary
                    low
                    class="empty__btn"
                    @click="$emit('resetFilters')"
                >
                    Сбросить фильтры
                </ButtonBlock>
            </slot>
        </div>
    </div>
</template>

<script>
import PlaceholderIcon from './PlaceholderIcon.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';


export default {
    name: 'EmptyBlock',

    components: { ButtonBlock, PlaceholderIcon },

    props: {
        title: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },

        hasBtn: {
            type: Boolean,
            default: true,
        },

        btnText: {
            type: String,
            default: 'Перейти в каталог',
        },

        btnUrl: {
            type: Object,
            default: () => {},
        },

        hasResetFilters: {
            type: Boolean,
            default: false,
        },

        icon: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped>
.empty {
    text-align: center;
}
@media (min-width: 768px) {
    .empty {
        padding: var(--gap-y-big);
    }
}
@media (max-width: 767px) {
    .empty {
        padding: 28px 20px;
    }
}

.empty__icon {
    margin-left: auto;
    margin-right: auto;
}
@media (min-width: 768px) {
    .empty__icon {
        margin-bottom: 36px;
    }
}
@media (max-width: 767px) {
    .empty__icon {
        margin-bottom: 28px;
    }
}

@media (min-width: 768px) {
    .empty__title {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
    }
}

.text-wrap {
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .text-wrap {
        max-width: 578px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    .text-wrap p:not(:last-child) {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .text-wrap p:not(:last-child) {
        margin-bottom: 8px;
    }
}

.empty__text {
    line-height: var(--base-lh);
}

.empty__btns-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
@media (min-width: 768px) {
    .empty__btns-wrap {
        margin-top: 36px;
    }
}
@media (max-width: 767px) {
    .empty__btns-wrap {
        margin-top: 28px;
        margin-bottom: -16px;
    }
}

@media (min-width: 768px) {
    .empty__btn {
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media (max-width: 767px) {
    .empty__btn {
        width: 100%;
        margin-bottom: 16px;
    }
}

.empty__login {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
@media (min-width: 768px) {
    .empty__login {
        margin-top: 16px;
    }
}
</style>