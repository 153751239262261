var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InputRadioBase',_vm._g({attrs:{"id":_vm.id,"options":_vm.options,"label":_vm.label,"optionKey":_vm.optionKey,"getOptionKey":_vm.getOptionKey,"optionLabel":_vm.optionLabel,"getOptionLabel":_vm.getOptionLabel,"optionComparator":_vm.optionComparator,"isOptionSelected":_vm.isOptionSelected,"reduce":_vm.reduce,"wrapClasses":"input-radio-borders__wrap","itemClasses":[
        'input-radio-borders__item',
        _vm.itemClasses,
        {
            'input-radio-borders__item_checked': _vm.valueComputed
        }
    ],"inputClasses":"input-radio-borders__input","labelClasses":"input-radio-borders__label"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
    var option = ref.option;
    var id = ref.id;
return [_c('label',{staticClass:"input-radio-borders__label",class:_vm.labelClasses,attrs:{"for":id}},[_vm._t("label",function(){return [_c('div',{staticClass:"input-radio-borders__title"},[_vm._v(_vm._s(_vm.getOptionLabel(option)))]),_vm._v(" "),_c('div',{staticClass:"input-radio-borders__description"},[_vm._v(_vm._s(_vm.getOptionDescription(option)))])]},null,{ option: option, id: id })],2)]}}],null,true),model:{value:(_vm.valueComputed),callback:function ($$v) {_vm.valueComputed=$$v},expression:"valueComputed"}},_vm.listeners))}
var staticRenderFns = []

export { render, staticRenderFns }