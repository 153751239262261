export default ({ HTTP }) => ({
    async get(...args) {
        let config;
        let codename;

        if (args && args.length) {
            if (typeof args[0] === 'string') {
                codename = args[0];
                config = args[1];
            }
            else {
                config = args[0];
            }
        }

        if (!config) config = {};

        if (codename) {
            const url = `/brands/${ codename }/`;
            const response = await HTTP.get(url, config);
            return response.data;
        }
        else {
            const url = '/brands/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },
});