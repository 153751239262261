var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"categories-list"},_vm._l((_vm.categories),function(category){return _c('li',{key:'category-' + category.id,staticClass:"category layer-1"},[_c('RouterLink',{staticClass:"category-link",attrs:{"to":{
                name: category.category_type
                    ? category.category_type === 'product_category'
                        ? 'market-products-category'
                        : 'market-parts-catalog-category'
                    : 'market-products-category',
                params: {
                    slug: category.slug
                }
            }}},[(category.cover)?_c('img',{staticClass:"cover",attrs:{"src":_vm.$links.uploads + category.cover.thumbnails.category_cover_list,"alt":""}}):_c('img',{staticClass:"cover",attrs:{"src":require("../assets/images/cover-placeholder.svg"),"alt":""}}),_vm._v(" "),_c('h2',{staticClass:"title"},[_vm._v("\n                "+_vm._s(category.name)+"\n            ")])])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }