<template>
    <div class="u-field-number">
        <UInputNumber
            :invalid="invalid"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template
                v-for="slotName in Object.keys($scopedSlots)"
                v-slot:[slotName]="payload"
            >
                <slot :name="slotName" v-bind="payload"></slot>
            </template>
        </UInputNumber>

        <UFormFieldError
            :show="invalid"
            class="u-field-text__error"
        >
            {{ error }}
        </UFormFieldError>
    </div>
</template>

<script>
import fieldMixin from '@ui/components/UForm/fields/fieldMixin.js';
import UFormFieldError from '@ui/components/UForm/UFormFieldError.vue';
import UInputNumber from '@ui/components/UInput/presets/UInputNumber.vue';


export default {
    name: 'UFieldNumber',

    components: {
        UInputNumber,
        UFormFieldError,
    },

    mixins: [
        fieldMixin,
    ],

    data() {
        return {
            initialValue: null,
        };
    },
};
</script>

<style scoped>
.u-field-number__error .u-form-field-error {
    margin-top: 8px;
}
</style>