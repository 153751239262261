<template>
    <div>
        <section
            class="page-head bottom-gap_max"
            :class="{
                'page-head_main': !hasCompany,
                'page-head_company': hasCompany,
            }"
        >
            <div>
                <div class="page-head__text-content">
                    <InsuranceCompanyLogo
                        v-if="hasCompany"
                        :logo="company.logo"
                        :name="company.page_title"
                        class="page-head__company-logo"
                    ></InsuranceCompanyLogo>

                    <h1 class="h1 h1_text">
                        <template v-if="hasCompany">
                            Оформите ОСАГО в&nbsp;компании {{ company.name }} онлайн
                        </template>
                        <template v-else>
                            Узнайте стоимость ОСАГО и&nbsp;оформите полис онлайн
                        </template>
                    </h1>

                    <p class="page-head__description">
                        Рассчитаем стоимость полиса в {{ companies.current_count }} страховых. Один раз заполните заявку и&nbsp;выберите с&nbsp;какой компанией хотите работать.
                    </p>
                </div>

                <div class="page-head__form calc-form-wrap">
                    <h2 class="calc-form-wrap__title">
                        Введите номер автомобиля и мы заполним часть данных за вас
                    </h2>

                    <FormManager
                        ref="form"
                        v-model="formData"
                        :formTag="false"
                        class="calc-form"
                        :errors="errors"
                        @error="errors = $event"
                        @enter="createOsago('osago_price_up', true)"
                    >
                        <!-- TODO: добавить фильтр опций -->
                        <UDropdown
                            :options="licensePlates"
                            :hasEmpty="false"
                            optionKey="uuid"
                            optionLabel="vehicle_license_plate"
                            @select="onSelectLicensePlate"
                        >
                            <template #default="{ handlers }">
                                <FormField
                                    name="vehicle_license_plate"
                                    type="licensePlate"
                                    visibleMask
                                    validations="required"
                                    @blur="handlers.blur"
                                    @focus="handlers.focus"
                                    @keydown="handlers.keydown"
                                ></FormField>
                            </template>
                        </UDropdown>

                        <ButtonBlock
                            submit
                            primary
                            :loading="createLoading"
                            class="calc-form__btn"
                            @click="createOsago('osago_price_up', true)"
                        >
                            Продолжить
                        </ButtonBlock>
                    </FormManager>

                    <ButtonText
                        underline
                        secondary
                        dark
                        class="calc-form__link"
                        @click="createOsago('osago_price_up')"
                    >
                        Рассчитать без гос. номера
                    </ButtonText>

                    <div
                        v-if="draft && draft.vehicle_model"
                        class="draft layer-1"
                    >
                        <div class="draft__car">
                            {{ (draft.vehicle_model || {}).fullname }}, {{ draft.vehicle_year }} ({{ draft.vehicle_engine_power }} л. с.)
                        </div>
                        <div class="draft__number">
                            <template v-if="draft.vehicle_license_plate">
                                {{ draft.vehicle_license_plate }}
                            </template><template
                                v-else
                            >
                                {{ draft.vehicle_id_number }}
                            </template>
                        </div>
                        <div
                            v-if="draftPrice"
                            class="draft__price"
                        >
                            от {{ draftPrice | price }}
                        </div>
                        <ButtonText
                            secondary
                            dark
                            underline
                            class="draft__continue-button"
                            @click="onClickContinueDraft"
                        >
                            Продолжить оформление
                        </ButtonText>
                    </div>
                </div>
            </div>
        </section>

        <Stepper
            class="bottom-gap_big"
            :steps="[
                {
                    title: 'Заполните данные',
                    text: 'об автомобиле, сроке полиса и&nbsp;водителе.',
                },
                {
                    title: 'Рассчитайте стоимость',
                    text: 'автоматически. За секунды выводим только те компании, у&nbsp;которых есть страховки.',
                },
                {
                    title: 'Выберите, оплатите <br> и получите',
                    text: 'полис по электронной почте. <br> Готово.',
                },
            ]"
        ></Stepper>

        <section
            v-if="companiesForSlider.length"
            class="companies-section layer-1 bottom-gap_big"
        >
            <h2 class="h2 h2_block text_center">
                {{ companies.current_count }} страховых компаний на Ubibi
            </h2>

            <div class="companies-wrap">
                <VueSlickCarousel
                    ref="companiesCarousel"
                    :slidesToShow="1"
                    :slidesToScroll="1"
                    dots
                    dotsClass="slider__dots"
                    class="companies-list"
                    adaptiveHeight
                    :responsive="[
                        {
                            breakpoint: 767,
                            settings: {
                                arrows: false,
                            }
                        },
                    ]"
                >
                    <div
                        v-for="(slide, index) in companiesForSlider"
                        :key="`slide-${ index }`"
                        class="company-items-wrap"
                    >
                        <div
                            v-for="item in slide"
                            :key="item.codename"
                            class="company-item"
                        >
                            <img
                                v-if="((item.logo || {}).thumbnails || {}).insurance_company_detail"
                                :src="$links.uploads + item.logo.thumbnails.insurance_company_detail"
                                :name="item.page_title"
                                class="company-logo block-center"
                            >
                            <img
                                v-else
                                src="@/assets/images/logo-placeholder.svg"
                                class="company-logo block-center"
                            >
                        </div>
                    </div>

                    <template #prevArrow>
                        <RoundArrowBtn
                            shadow
                            :size="48"
                            direction="left"
                            class="companies-carousel__arrows companies-carousel__arrow-left"
                            @click="prevCompany"
                        ></RoundArrowBtn>
                    </template>

                    <template #nextArrow>
                        <RoundArrowBtn
                            shadow
                            :size="48"
                            direction="right"
                            class="companies-carousel__arrows companies-carousel__arrow-right"
                            @click="nextCompany"
                        ></RoundArrowBtn>
                    </template>
                </VueSlickCarousel>
            </div>
        </section>

        <section class="bottom-gap_big">
            <InsuranceProfitsList></InsuranceProfitsList>
        </section>

        <div
            v-if="hasOffers"
            class="bottom-gap_big"
        >
            <section class="offers-section slider-section">
                <div class="offers-section__title-wrap slider-section__title flex align-items-center justify-content-between">
                    <h2 class="h2">
                        Последние расчёты стоимости ОСАГО<template v-if="hasCompany">
                            в&nbsp;компании {{ company.name }}
                        </template>
                    </h2>

                    <div class="offers-section__arrow-wrap flex align-items-center">
                        <RoundArrowBtn
                            v-if="offers.length > 4"
                            :disabled="disabledLeftArrowOffersCarousel"
                            direction="left"
                            shadow
                            size="36"
                            class="offers-carousel__arrow mr-12"
                            @click="prevOffer"
                        ></RoundArrowBtn>
                        <RoundArrowBtn
                            v-if="offers.length > 4"
                            :disabled="disabledRightArrowOffersCarousel"
                            direction="right"
                            shadow
                            size="36"
                            class="offers-carousel__arrow"
                            @click="nextOffer"
                        ></RoundArrowBtn>
                    </div>
                </div>

                <VueSlickCarousel
                    ref="offersCarousel"
                    class="offers-list slider"
                    :arrows="false"
                    :infinite="false"
                    :variableWidth="true"
                    :slidesToScroll="5"
                    :slidesToShow="5"
                    :responsive="[
                        {
                            breakpoint: 1280,
                            settings: {
                                slidesToScroll: 4,
                                slidesToShow: 4,
                            }
                        },
                        {
                            breakpoint: 1039,
                            settings: {
                                slidesToScroll: 3,
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToScroll: 1,
                                slidesToShow: 1,
                            }
                        },
                    ]"
                    @afterChange="onAfterChangeOffersCarousel"
                >
                    <div
                        v-for="offer in offers"
                        :key="offer.__id"
                        class="offer-item"
                    >
                        <div class="offer-card layer-1">
                            <div class="offer-card__body">
                                <div
                                    v-if="!hasCompany"
                                    class="offer-card__logo mb-24"
                                >
                                    <InsuranceCompanyLogo
                                        :logo="offer.company.logo"
                                        :name="offer.company.name"
                                    ></InsuranceCompanyLogo>
                                </div>
                                <p class="offer-card__car offer-card__text">
                                    {{ offer.vehicle.model }}, {{ offer.vehicle.year }} ({{ offer.vehicle.power }}&nbsp;л.&nbsp;с.)
                                </p>
                                <p class="offer-card__drivers offer-card__text">
                                    <span v-if="!offer.drivers_amount">
                                        Без ограничений на водителей: <b>КБМ 1</b><UTooltip
                                            class="offer-card__tooltip ml-8"
                                            noDetach
                                        >
                                            Если нет ограничения на количество водителей, КБМ будет равен 1.
                                        </UTooltip>
                                    </span>

                                    <template v-else>
                                        {{ offer.drivers_amount }}
                                        {{ offer.drivers_amount | plural(['водитель', 'водителя', 'водителей']) }}:
                                        <b>КБМ {{ Number(offer.kbm_factor) }}</b><UTooltip
                                            v-if="offer.drivers_amount > 1"
                                            class="ml-8"
                                            noDetach
                                        >
                                            Если в полис вписано несколько водителей,
                                            то при расчёте цены используется наибольший КБМ.
                                        </UTooltip>
                                    </template>
                                </p>
                                <p
                                    v-if="offer.city && offer.city.name"
                                    class="offer-card__city offer-card__text"
                                >
                                    {{ offer.city.name }}
                                </p>
                            </div>
                            <div class="offer-card__footer">
                                <div class="offer-card__price">
                                    <b>{{ offer.price | price }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>

                <p class="offers-list__note">
                    * КБМ — Коэффициент безаварийной езды («бонус-малус»). <ButtonText
                        secondary
                        dark
                        dashed
                        @click="showBonusMalusPopup"
                    >
                        Подробнее
                    </ButtonText>
                </p>
            </section>
        </div>

        <section class="bottom-gap_big">
            <h2 class="h2 h2_text about-osago-section__title">
                Что такое ОСАГО?
            </h2>

            <p class="about-osago-section__description">
                ОСАГО&nbsp;&mdash; обязательное страхование гражданской ответственности.
                <br>Это такой&nbsp;же необходимый документ для вождения, как и&nbsp;водительские права.
            </p>

            <div class="about-osago-block about-osago-block_left">
                <img
                    srcset="../../assets/images/insurance/about-osago-1.png,
                                    ../../assets/images/insurance/about-osago-1-x2.png 2x"
                    src="../../assets/images/insurance/about-osago-1-x2.png"
                    alt=""
                    class="about-osago-block_1__img"
                    width="290"
                    height="93"
                >

                <article class="about-osago__article wysiwyg-container">
                    <p class="wysiwyg-h3">
                        ОСАГО защищает вас от трат в случае ДТП:
                    </p>
                    <ul>
                        <li>если виновник вы, то страховая возмещает ущерб имуществу и здоровью пострадавших, но свой автомобиль придется ремонтировать самостоятельно;</li>
                        <li>если виновник другой человек, то ремонт вашего автомобиля оплатит его страховая;</li>
                        <li>если виноваты оба, то страховые платят обоим, обычно возвращая половину ущерба.</li>
                    </ul>
                </article>
            </div>

            <div class="about-osago-block about-osago-block_right">
                <img
                    srcset="../../assets/images/insurance/about-osago-2.png,
                                    ../../assets/images/insurance/about-osago-2-x2.png 2x"
                    src="../../assets/images/insurance/about-osago-2.png"
                    alt=""
                    class="about-osago-block_2__img"
                    width="252"
                    height="252"
                >

                <article class="about-osago__article wysiwyg-container">
                    <div class="about-osago__article_first">
                        <p class="wysiwyg-h3">
                            Страховые выплаты:
                        </p>
                        <p>Максимальный размер выплаты на&nbsp;ремонт по&nbsp;ОСАГО&nbsp;&mdash; 400&nbsp;000&nbsp;рублей, если в&nbsp;ДТП не&nbsp;пострадали люди. Если есть пострадавшие, выплата увеличивается до&nbsp;500&nbsp;000&nbsp;рублей.</p>
                        <p class="wysiwyg-h3">
                            Стоимость полиса:
                        </p>
                        <p>Каждая страховая определяет стоимость полиса в&nbsp;пределах значений, установленных Центробанком. Стоимость зависит от&nbsp;компании, типа автомобиля, мощности двигателя, региона и&nbsp;коэффициента аварийности.</p>
                    </div>
                    <div class="about-osago__article_second">
                        <div>
                            <p class="wysiwyg-h3">
                                Документы, которые понадобятся для&nbsp;оформления:
                            </p>
                            <ul>
                                <li>паспорт</li>
                                <li>водительское удостоверение</li>
                                <li>свидетельство о регистрации авто или ПТС</li>
                                <li>диагностическая карта</li>
                            </ul>
                        </div>
                    </div>
                </article>
            </div>
        </section>

        <section class="questions-section bottom-gap_big layer-1">
            <div class="questions-section__head">
                <h2 class="h2">
                    А что, если?
                </h2>
            </div>

            <Accordion
                class="questions-section__accordion"
                :questions="questions"
            ></Accordion>
        </section>

        <section class="calculate-section">
            <div class="calculate-section__form-wrap">
                <h2 class="calculate-section__text">
                    Узнайте стоимость ОСАГО и&nbsp;оформите полис онлайн
                </h2>
                <div class="calculate-section__form calc-form-wrap">
                    <FormManager
                        ref="form2"
                        v-model="formData"
                        :formTag="false"
                        class="calc-form"
                        :errors="errors"
                        @error="errors = $event"
                        @enter="createOsago('osago_price_up', true)"
                    >
                        <UDropdown
                            :options="licensePlates"
                            :hasEmpty="false"
                            optionKey="uuid"
                            optionLabel="vehicle_license_plate"
                            @select="onSelectLicensePlate"
                        >
                            <template #default="{ handlers }">
                                <FormField
                                    type="licensePlate"
                                    name="vehicle_license_plate"
                                    placeholder="Введите гос. номер"
                                    visibleMask
                                    validations="required"
                                    @blur="handlers.blur"
                                    @focus="handlers.focus"
                                    @keydown="handlers.keydown"
                                ></FormField>
                            </template>
                        </UDropdown>

                        <ButtonBlock
                            submit
                            primary
                            :loading="createLoading"
                            class="calc-form__btn"
                            @click="createOsago('osago_price_down', true)"
                        >
                            Продолжить
                        </ButtonBlock>
                    </FormManager>

                    <ButtonText
                        underline
                        secondary
                        dark
                        class="calc-form__link"
                        @click="createOsago('osago_price_down')"
                    >
                        Рассчитать без гос. номера
                    </ButtonText>
                </div>
            </div>
        </section>

        <SeoBlock v-if="hasCompany">
            <template
                v-if="company.seo_description"
                #seo
            >{{ company.seo_description }}</template>
        </SeoBlock>
    </div>
</template>

<script>
// utils
import plural from '@/lib/plural.js';
import { mapState, mapActions, mapMutations } from 'vuex';
import insuranceMain from '@/store/modules/insuranceMain.js';
import insuranceOsago from '@/store/modules/insuranceOsago.js';
import { serializeContract } from '@/store/modules/insuranceOsago.js';
import isBrowser from '@/lib/isBrowser.js';
import { getInsuranceContractRouteName } from './osago/_uuid/useCheckRouteMethods.js';
// mixins
import YandexMetrika from '@/mixins/yandex-metrika.js';
// styles
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// components
import InsuranceCompanyLogo from '@/pages/insurance/InsuranceCompanyLogo.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import Stepper from '@/components/Stepper.vue';
import Accordion from '@/components/Accordion.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import RoundArrowBtn from '@/components/project-buttons/RoundArrowBtn.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import SeoBlock from '@/components/SeoBlock.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import InsuranceProfitsList from '@/components/InsuranceProfitsList.vue';
import UDropdown from '@/components/UDropdownDeprecated/UDropdownDeprecated.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
const BonusMalusPopup = () => import('@/pages/insurance/BonusMalusPopup.vue');


export default {
    name: 'InsuranceMainPage',

    metaInfo() {
        if (this.hasCompany) {
            return {
                title: this.company.meta_title,
                meta: [
                    { name: 'description', content: this.company.meta_description },
                    { name: 'keywords', content: this.company.meta_keywords },
                    { property: 'og:title', content: this.company.meta_title },
                    { property: 'og:description', content: this.company.meta_description },
                    {
                        property: 'og:image',
                        content: this.company.og_image ? this.$links.uploads + this.company.og_image.thumbnails.og_image_default : '',
                    },
                ],
            };
        }
        else {
            return {
                title: this.meta.meta_title,
                meta: [
                    { name: 'description', content: this.meta.meta_description },
                    { name: 'keywords', content: this.meta.meta_keywords },
                    { property: 'og:title', content: this.meta.meta_title },
                    { property: 'og:description', content: this.meta.meta_description },
                    {
                        property: 'og:image',
                        content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '',
                    },
                ],
            };
        }
    },

    components: {
        UTooltip,
        UDropdown,
        InsuranceProfitsList,
        FormField,
        FormManager,
        SeoBlock,
        ButtonBlock,
        RoundArrowBtn,
        VueSlickCarousel,
        ButtonText,
        InsuranceCompanyLogo,
        Stepper,
        Accordion,
    },

    mixins: [YandexMetrika],

    serverPrefetch() {
        return this.INIT({ route: this.$route });
    },

    data() {
        return {
            questions: [
                {
                    question: 'Во время оплаты полиса на сайте страховой компании что-то пойдет не так?',
                    answer: '<p>Всегда что-то может пойти не так. Поэтому мы сохраняем заполненные данные заявки, вводить их заново не нужно. Вы можете вернуться на несколько шагов назад в истории браузера, затем повторно перейти на сайт страховой компании для оплаты полиса.</p>',
                },
                {
                    question: 'Мне понадобится консультация.',
                    answer: '<p>Напишите нам в онлайн-чате, менеджер ответит на любые вопросы по работе сервиса. Однако, детали связанные со страховкой, которые касаются страховых случаев или выплат, к сожалению, придется уточнять напрямую у страховой компании.</p>',
                },
                {
                    question: 'Боюсь предоставлять вашему сервису информацию о себе и своем авто.',
                    answer: '<p>Ваша персональная информация — конфиденциальна, мы не имеем права разглашать ее третьим лицам. Мы не показываем ваши данные страховым компаниям или рекламным агентам и не передаем ее другим сервисам.</p>',
                },
                {
                    question: 'В личном кабинете нет информации об оплаченном полисе?',
                    answer: '<p>Информация об оплате полиса поступает в Ubibi от страховой компании не сразу. Если прошло уже много времени, но информация до сих пор не обновилась, свяжитесь, пожалуйста, с нашей техподдержкой.</p>',
                },
                {
                    question: 'Вы накручиваете сумму сверху при расчёте стоимости полиса?',
                    answer: '<p>Нет, стоимость ОСАГО на Ubibi аналогична ценам на сайтах страховых компаний. Обратите внимание, что итоговая стоимость полиса может отличаться от предварительного расчета. Вы узнаете ее после того, как заполните всю необходимую информацию.</p>',
                },
                {
                    question: 'С собой есть электронный страховой полис, но сотрудник ГИБДД просит распечатку?',
                    answer: '<p>Со 2 января 2020 года начали действовать поправки ПДД, согласно которым вам достаточно иметь при себе электронный ОСАГО. Вы можете показать его на планшете или смартфоне. Но лучше взять распечатку с собой — телефон вы можете забыть, а на планшете сядет батарейка, распечатанный вариант не подведет.</p>',
                },
                {
                    question: 'Стоимость полиса при продлении выросла, хотя у меня не было ни одной аварии.',
                    answer: '<p>Скорее всего, изменились территориальные коэффициенты для вашего города. Страховые компании имеют право менять эти тарифы несколько раз в год.</p>',
                },
            ],

            offersCarouselSlideIndex: 0,

            createLoading: false,

            formData: {
                vehicle_license_plate: '',
            },
            errors: {},

            draft: null,
            fetchingDraft: false,

            licensePlates: [],
            fetchingLicensePlates: false,
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.insuranceMain.initialized,
            meta: state => state.insuranceMain.meta,
            companies: state => state.insuranceMain.companies,
            offers: state => state.insuranceMain.offers,
        }),

        companiesForSlider() {
            const size = 6;
            const companies = this.companies.results;

            if (!companies) return [];

            const count = Math.ceil(companies.length / size);
            const slides = [];

            if (companies.length >= size) {
                for (let i = 0; i < count; i++) {
                    const arr = companies.slice(i * size, (i + 1) * size);
                    slides.push(arr);
                }
            }

            return slides;
        },

        company() {
            const codename = this.$route.params.company;

            if (codename) {
                const index = this.companies.results.map(company => company.code).indexOf(codename);

                if (index !== -1) {
                    return this.companies.results[index];
                }
            }

            return null;
        },

        hasCompany() {
            return !!this.company;
        },

        hasOffers() {
            return this.offers.length > 4;
        },

        disabledRightArrowOffersCarousel() {
            let size = 4;

            if (isBrowser && window && window.matchMedia) {
                const mediaQuery = window.matchMedia('(max-width: 1280px)');

                if (mediaQuery.matches) {
                    size = 3;
                }
            }

            const index = this.offersCarouselSlideIndex;
            return size >= this.offers.slice(index).length;
        },

        disabledLeftArrowOffersCarousel() {
            return this.offersCarouselSlideIndex === 0;
        },

        draftPrice() {
            if (!this.draft) return;

            const offers = this.draft.offers || [];

            if (!offers.length) return;

            return Math.min(...offers.map(offer => offer.price));
        },
    },

    created() {
        this.$store.registerModule('insuranceMain', insuranceMain);
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago);
        }
    },

    mounted() {
        this.$breadcrumbs([]);
        this.init();
    },

    beforeDestroy() {
        this.$store.unregisterModule('insuranceMain');
    },

    methods: {
        plural,

        ...mapActions({
            INIT: 'insuranceMain/init',
        }),

        ...mapMutations({
            updateContract: 'insuranceOsago/updateContract',
            setInitialized: 'insuranceOsago/setInitialized',
        }),

        async init() {
            try {
                const requests = [
                    this.fetchDraftContract(),
                    this.fetchLicensePlates(),
                ];
                if (!this.initialized) {
                    requests.push(this.INIT({ route: this.$route }));
                }

                await Promise.all(requests);
            }
            catch (error) {
                //
            }
        },

        async fetchDraftContract() {
            this.fetchingDraft = true;

            const item = localStorage.getItem('insurance_osago_draft');
            const draft = item ? JSON.parse(item) : item;

            if (!draft) return;

            const { uuid, createDate } = draft;
            const maxDiff = 1000 * 60 * 60 * 24 * 14;
            const diff = Date.now() - Number(createDate);

            if (diff > maxDiff) {
                localStorage.removeItem('insurance_osago_draft');
                return;
            }

            try {
                const response = await this.$api.insuranceContract.get(uuid);
                const status = response.status;

                if (['wait_docs', 'get_docs', 'completed', 'canceled'].includes(status)) return;

                this.draft = response;
            }
            catch (error) {
                //
            }
            finally {
                this.fetchingDraft = false;
            }
        },

        onClickContinueDraft() {
            const contract = serializeContract(this.draft);
            const uuid = contract.uuid;
            this.updateContract(contract);
            this.setInitialized();

            const routeName = getInsuranceContractRouteName(contract);

            this.$router.push({
                name: routeName,
                params: {
                    uuid,
                },
            });
        },

        async fetchLicensePlates() {
            const item = localStorage.getItem('insurance_osago_license_plates');
            const licensePlates = item ? JSON.parse(item) : {};
            const entries = Object.entries(licensePlates).filter(([, { createDate }]) => {
                const maxDiff = 1000 * 60 * 60 * 24 * 14;
                const diff = Date.now() - Number(createDate);
                return maxDiff > diff;
            });

            if (entries.length) {
                const promises = entries.map(([, { uuid }]) => {
                    return new Promise((resolve, reject) => {
                        this.$api.insuranceContract.get(uuid).then(resolve).catch(reject);
                    });
                });

                const contracts = await Promise.all(promises);

                this.licensePlates = contracts.filter(contract => {
                    return ['new', 'pre_calc', 'final_calc', 'wait_payment'].includes(contract.status);
                });
            }
        },

        onSelectLicensePlate({ value }) {
            const contract = serializeContract(value);
            const uuid = contract.uuid;
            this.updateContract(contract);
            this.setInitialized();

            const routeName = getInsuranceContractRouteName(contract);

            this.$router.push({
                name: routeName,
                params: {
                    uuid,
                },
            });
        },

        async createOsago(goalName, withLicensePlate = false) {
            this.handleYandexMetrikaGoal(goalName);

            if (withLicensePlate) {
                const errors = await this.$refs.form.validate();

                if (errors) return;
            }

            this.createLoading = true;

            try {
                const { contract: uuid } = await this.$api.insuranceContract.new();

                const requests = [this.$api.insuranceContract.get(uuid)];

                if (withLicensePlate && this.formData.vehicle_license_plate) {
                    const params = {
                        type: 'number', // 'vin'
                        number: this.formData.vehicle_license_plate,
                    };

                    requests.push(this.$api.insuranceVehicle.getInfo({ params }));
                }
                else {
                    requests.push(Promise.resolve());
                }

                const [contract, vehicle] = await Promise.all(requests);

                const patchData = {};

                if (this.formData.vehicle_license_plate) {
                    contract.vehicle_license_plate = this.formData.vehicle_license_plate;
                    patchData.vehicle_license_plate = this.formData.vehicle_license_plate;
                }

                if (vehicle && Object.keys(vehicle).length) {
                    const {
                        model,
                        manufacturer,
                        power,
                        year,
                        vin,
                        body_number,
                        chassis_number,
                        car_document,
                        sts_receive,
                    } = vehicle;

                    if (year) {
                        patchData.vehicle_year = year;
                    }

                    if (power) {
                        patchData.vehicle_engine_power = power;
                    }

                    if (model) {
                        patchData.vehicle_model = model;
                    }

                    if (manufacturer) {
                        patchData.vehicle_manufacturer = manufacturer;
                    }

                    if (vin) {
                        patchData.vehicle_id_type_code = 'vin';
                        patchData.vehicle_id_number = vin;
                    }

                    if (body_number) {
                        patchData.vehicle_id_type_code = 'bodyNumber';
                        patchData.vehicle_id_number = body_number;
                    }

                    if (chassis_number) {
                        patchData.vehicle_id_type_code = 'chassisNumber';
                        patchData.vehicle_id_number = chassis_number;
                    }

                    if (car_document) {
                        patchData.vehicle_doc_type_code = car_document.type;
                        patchData.vehicle_doc_number = car_document.number;
                    }

                    if (sts_receive) {
                        patchData.vehicle_doc_date = sts_receive;
                    }
                }

                if (Object.keys(patchData).length) {
                    Object.assign(contract, patchData);

                    await this.$api.insuranceContract.change(uuid, patchData);
                }

                const serializedContract = serializeContract(contract);

                this.updateContract(serializedContract);
                this.setInitialized();

                const location = {
                    name: 'insurance-osago-request-step-1',
                    params: {
                        uuid,
                    },
                };

                if (this.company) {
                    location.query = { company: this.$route.params.company };
                }

                await this.$router.push(location);
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.createLoading = false;
            }
        },

        prevOffer() {
            const carousel = this.$refs.offersCarousel;
            carousel.prev();
        },

        nextOffer() {
            const carousel = this.$refs.offersCarousel;
            carousel.next();
        },

        prevCompany() {
            const carousel = this.$refs.companiesCarousel;
            carousel.prev();
        },

        nextCompany() {
            const carousel = this.$refs.companiesCarousel;
            carousel.next();
        },

        showBonusMalusPopup() {
            this.$popup(BonusMalusPopup);
        },

        onAfterChangeOffersCarousel(index) {
            this.offersCarouselSlideIndex = index;
        },
    },
};
</script>

<style scoped src="../../assets/css/wysiwyg.css"></style>
<style scoped>
.page-head {
    position: relative;
    width: 100vw;
    margin-left: var(--content-gap-negative);
    padding: 48px var(--content-gap);
    background-color: var(--lightest-bg);
}
@media (min-width: 1040px) {
    .page-head {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }
}
@media (max-width: 767px) {
    .page-head {
        padding-top: 28px;
        padding-bottom: 28px;
    }
}

.page-head::before {
    content: "";
    display: block;
    aspect-ratio: 6/5;
    background-size: contain;
    background-repeat: no-repeat;
}
@media (min-width: 1281px) {
    .page-head::before {
        background-image: url(../../assets/images/insurance/index-head-visual-big.jpg);
        width: var(--col-8);
        margin-right: calc(var(--col-1) + var(--grid-gap));
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .page-head::before {
            background-image: url(../../assets/images/insurance/index-head-visual-big-x2.jpg);
        }
    }
}
@media (max-width: 1280px) {
    .page-head::before {
        background-image: url(../../assets/images/insurance/index-head-visual-small.jpg);
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .page-head::before {
            background-image: url(../../assets/images/insurance/index-head-visual-small-x2.jpg);
        }
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .page-head::before {
        width: var(--col-5);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .page-head::before {
        width: 290px;
        height: 240px;
        position: absolute;
        top: calc(((100% - 136px - 48px) / 2) + 136px);
        right: var(--content-gap);
        transform: translateY(-50%);
    }

    .page-head.page-head_company::before {
        top: calc(((100% - 136px - 48px) / 2) + 136px + 50px);
    }
}
@media (max-width: 767px) {
    .page-head_main::before {
        width: 290px;
        height: 240px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 28px;
    }
}

.page-head__company-logo {
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .page-head__company-logo {
        margin-bottom: 16px;
    }
}

.page-head__text-content {
    position: relative;
    z-index: 2;
}
@media (min-width: 1281px) {
    .page-head__text-content {
        width: var(--col-10);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .page-head__text-content {
        width: var(--col-7);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .page-head__text-content {
        width: var(--col-11);
    }
}

.page-head__description {
    font-size: var(--adaptive-big-fz);
    line-height: var(--base-lh);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .page-head__description {
        width: var(--col-8);
    }
}

.page-head__form {
    margin-top: var(--gap-y-small);
}

.calc-form-wrap__title {
    margin-bottom: 20px;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .calc-form-wrap__title {
        width: var(--col-7);
    }
}

@media (min-width: 1281px) {
    .calc-form {
        grid-template-columns: var(--col-4) max-content;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .calc-form {
        grid-template-columns: var(--col-3) max-content;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .calc-form {
        grid-template-columns: var(--col-3) max-content;
    }

    .calculate-section .calc-form {
        grid-template-columns: 223px max-content;
    }
}
@media (min-width: 768px) {
    .calc-form {
        display: grid;
        grid-gap: 20px;
    }
}

.draft {
    margin-top: 36px;
    padding: 12px 16px;
    width: max-content;
}
@media (max-width: 767px) {
    .draft {
        width: 290px;
        margin-top: 28px;
        margin-right: auto;
        margin-left: auto;
    }
}

.draft__car {
    font-family: var(--f-bold);
}

.draft__number {
    margin-top: 4px;
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
}

.draft__price {
    margin-top: 4px;
    font-size: var(--small-fz);
}

.draft__continue-button {
    margin-top: 8px;
}

.calc-form__btn {
    align-self: flex-start;
    height: 44px;
}
@media (max-width: 767px) {
    .calc-form__btn {
        width: 100%;
        margin-top: 16px;
    }
}

@media (min-width: 768px) {
    .companies-section {
        padding: 36px 0;
    }
}
@media (max-width: 767px) {
    .companies-section {
        padding: 24px;
    }
}

.companies-list {
    position: relative;
}

.companies-list >>> .slider__dots {
    margin-top: var(--gap-y-mini);
}

.company-items-wrap {
    display: grid !important;
    justify-content: center;
    outline: none;
}
@media (min-width: 1160px) {
    .company-items-wrap {
        grid-template-columns: repeat(6, 120px);
    }
}
@media (min-width: 1040px) and (max-width: 1159px) {
    .company-items-wrap {
        grid-template-columns: repeat(6, 96px);
    }
}
@media (min-width: 1040px) {
    .company-items-wrap {
        grid-gap: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .company-items-wrap {
        grid-template-columns: repeat(3, 120px);
        grid-gap: 24px 72px;
    }
}
@media (max-width: 767px) {
    .company-items-wrap {
        grid-template-columns: repeat(2, 96px);
        grid-gap: 20px 48px;
    }
}

.companies-carousel__arrows {
    position: absolute;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity var(--transition);
}
.companies-wrap:hover .companies-carousel__arrows {
    opacity: 1;
}
@media (min-width: 1160px) {
    .companies-carousel__arrows {
        top: 30px;
    }
}
@media (min-width: 1040px) and (max-width: 1159px) {
    .companies-carousel__arrows {
        top: 24px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .companies-carousel__arrows {
        top: 72px;
    }
}
@media (min-width: 768px) {
    .companies-carousel__arrow-left {
        left: -24px;
    }

    .companies-carousel__arrow-right {
        right: -24px;
    }
}

@media (min-width: 1160px) {
    .company-logo {
        width: 120px;
        height: 60px;
    }
}
@media (min-width: 1040px) and (max-width: 1159px) {
    .company-logo {
        width: 96px;
        height: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .company-logo {
        width: 120px;
        height: 60px;
    }
}
@media (max-width: 767px) {
    .company-logo {
        width: 96px;
        height: 48px;
    }
}

@media (max-width: 767px) {
    .offers-section__arrow-wrap {
        display: none;
    }
}

.offers-section__title-wrap {
    margin-bottom: var(--indent-h2-block);
}

.offer-item {
    height: 100%;
    outline: none;
}
@media (min-width: 1281px) {
    .offer-item {
        width: 304px !important;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .offer-item {
        width: 300px !important;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .offer-item {
        width: 240px !important;
    }
}
@media (max-width: 767px) {
    .offer-item {
        width: 298px !important;
    }
}

.offer-card {
    display: flex;
    flex-direction: column;
    height: 100%;
}
@media (min-width: 768px) {
    .offer-card {
        margin-right: var(--grid-gap-half);
        margin-left: var(--grid-gap-half);
    }
}
@media (max-width: 767px) {
    .offer-card {
        margin-right: 4px;
        margin-left: 4px;
    }
}

.offer-card__body {
    flex-grow: 1;
}
@media (min-width: 768px) {
    .offer-card__body {
        padding: 20px;
    }
}
@media (max-width: 767px) {
    .offer-card__body {
        padding: 16px 16px 12px;
    }
}

.offer-card__text {
    line-height: var(--base-lh);
}

.offer-card__footer {
    flex-shrink: 0;
    flex-grow: 0;
    border-top: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .offer-card__footer {
        padding: 20px;
    }
}
@media (max-width: 767px) {
    .offer-card__footer {
        padding: 12px 16px 16px;
    }
}

.offer-card__price {
    font-size: var(--h3-fz);
}

.offers-list__note {
    color: var(--font-secondary-color);
}
@media (max-width: 1600px) {
    .offers-list__note {
        margin-left: var(--content-gap);
        margin-right: var(--content-gap);
    }
}
@media (min-width: 768px) {
    .offers-list__note {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .offers-list__note {
        margin-top: 16px;
    }
}

@media (min-width: 1040px) {
    .about-osago-section__title,
    .about-osago-section__description {
       text-align: center;
    }
}
.about-osago-section__description {
    margin-bottom: 36px;
    line-height: var(--base-lh);
}
@media (max-width: 767px) {
    .about-osago-section__description br {
        display: none;
    }
}

.about-osago-block {
    position: relative;
}
.about-osago-block:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .about-osago-block:not(:last-child) {
        margin-bottom: 36px;
        padding-bottom: 36px;
    }
}
@media (max-width: 767px) {
    .about-osago-block:not(:last-child) {
        margin-bottom: 28px;
        padding-bottom: 28px;
    }
}

@media (min-width: 1281px) {
    .about-osago-block_left {
        padding-left: calc(var(--col-1) + var(--grid-gap));
    }

    .about-osago-block_right {
        padding-right: calc(var(--col-1) + var(--grid-gap));
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .about-osago-block_left {
        padding-left: 36px;
    }

    .about-osago-block_right {
        padding-right: 36px;
    }
}
@media (min-width: 1040px) {
    .about-osago-block_left {
        display: flex;
        align-items: flex-start;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .about-osago-block_right {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (min-width: 1281px) {
    .about-osago__article {
        width: var(--col-9);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .about-osago__article {
        width: calc(var(--col-6) - 36px);
    }
}

.about-osago-block_1__img {
    align-self: center;
    height: auto;
}
@media (min-width: 1281px) {
    .about-osago-block_1__img {
        width: var(--col-8);
        margin-right: calc(var(--col-1) + var(--grid-gap));
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .about-osago-block_1__img {
        width: var(--col-6);
    }
}
@media (max-width: 1039px) {
    .about-osago-block_1__img {
        margin-bottom: 36px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .about-osago-block_1__img {
        width: var(--col-8);
    }
}
@media (max-width: 767px) {
    .about-osago-block_1__img {
        width: 290px;
    }
}

.about-osago__article_second {
    margin-top: 20px;
}

.about-osago-block_2__img {
    height: auto;
}
@media (min-width: 1281px) {
    .about-osago-block_2__img {
        width: var(--col-7);
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .about-osago-block_2__img {
        width: var(--col-5);
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .about-osago-block_2__img {
        width: 288px;
        height: 288px;
        position: absolute;
        left: 24px;
        bottom: 0;
    }

    .about-osago__article_second {
        width: var(--col-6);
        height: 288px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
    }
}
@media (max-width: 767px) {
    .about-osago-block_2__img {
        width: 160px;
        position: absolute;
        left: calc(var(--col-4) + 12px + var(--content-gap));
        bottom: -8px;
    }

    .about-osago__article_second {
        margin-top: 16px;
    }

    .about-osago__article_second ul {
        width: var(--col-4);
    }

    .about-osago-block {
        overflow: hidden;
        margin-left: var(--content-gap-negative);
        margin-right: var(--content-gap-negative);
        padding-left: var(--content-gap);
        padding-right: var(--content-gap);
    }
}

.about-osago__article_second ul {
    margin-top: 0 !important;
}

.questions-section .accordion {
    border: none;
}
.questions-section__head {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .questions-section__head {
        padding: 24px 36px;
    }
}
@media (max-width: 767px) {
    .questions-section__head {
        padding: 20px;
    }
}

.questions-section__accordion >>> .accordion-item:last-child {
    border-bottom: none;
}
@media (min-width: 768px) {
    .questions-section__accordion >>> .accordion-trigger,
    .questions-section__accordion >>> .accordion-section__content {
        padding-left: 36px;
        padding-right: 36px;
    }
}

.calculate-section {
    position: relative;
    background-color: var(--light-bg);
    border-radius: var(--border-radius-x2);
    overflow: hidden;
}
@media (min-width: 1281px) {
    .calculate-section {
        padding: 36px 48px 36px 0;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .calculate-section {
        padding: 36px 36px 36px 0;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .calculate-section {
        padding: 36px 36px 36px 0;
    }
}
@media (min-width: 768px) {
    .calculate-section {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }
}
@media (max-width: 767px) {
    .calculate-section {
        padding: 24px;
    }
}

@media (min-width: 768px) {
    .calculate-section::before {
        content: "";
        display: block;
        width: 200px;
        align-self: stretch;
        flex-shrink: 0;
        margin: -36px auto;
        background-image: url(../../assets/images/insurance/calculator.png);
        background-repeat: no-repeat;
        background-size: 200px 200px;
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        .calculate-section::before {
            background-image: url(../../assets/images/insurance/calculator-x2.png);
        }
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .calculate-section::before {
        background-position: center;
    }
}

@media (min-width: 1040px) {
    .calculate-section__form-wrap {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .calculate-section__form-wrap {
        width: 404px;
    }
}
.calculate-section__text {
    position: relative;
    z-index: 2;
    font-size: 18px;
    font-family: var(--f-bold);
    line-height: 24px;
    color: var(--dark-c);
    flex-shrink: 0;
}
@media (min-width: 1281px) {
    .calculate-section__text {
        width: 260px;
        margin-right: 48px;
        margin-left: 48px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .calculate-section__text {
        width: var(--col-3);
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .calculate-section__text {
        margin-bottom: 16px;
        text-align: center;
    }
}

.calculate-section__form {
    flex-shrink: 0;
}
@media (min-width: 1281px) {
    .calculate-section__form {
        display: flex;
        align-items: center;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .calculate-section__form {
        margin-top: 20px;
    }
}

@media (min-width: 1281px) {
    .calculate-section__form .calc-form__link {
        margin-top: 0;
        margin-left: 48px;
    }
}
@media (min-width: 768px) {
    .calc-form__link {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .calc-form__link {
        display: block;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>