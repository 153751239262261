<template>
    <div
        ref="animateOuter"
        class="animate_outer"
    >
        <div
            ref="animateInner"
            class="animate_inner"
        >
            <div
                v-if="localFilters.length"
                class="checked-filter"
            >
                <div class="checked-filter-list_outer">
                    <div class="checked-filter-list">
                        <ButtonPill
                            v-for="filter in localFilters"
                            :key="'checked-filter-button-' + filter.codename + '-id-' + (filter.id || 0)"
                            class="checked-btn"
                            @click="click(filter)"
                        >
                            <span class="text_secondary-dark mr-4">{{ filter.title }}: </span>{{ filter.value }}
                        </ButtonPill>

                        <ButtonText
                            v-show="localFilters.length"
                            type="reset"
                            primary
                            dashed
                            class="reset-btn reset-btn_desktop"
                            @click="reset"
                        >
                            Сбросить фильтры
                        </ButtonText>
                    </div>
                </div>

                <ButtonText
                    v-show="localFilters.length"
                    type="reset"
                    primary
                    dashed
                    class="reset-btn reset-btn_mobile"
                    @click="reset"
                >
                    Сбросить фильтры
                </ButtonText>
            </div>
        </div>
    </div>
</template>

<script>
import { animationDurationFast } from "../plugins/animationSettings.js";
import ButtonText from "@/components/_buttons/ButtonText.vue";
import ButtonPill from '@/components/_buttons/ButtonPill.vue';

export default {
    name: "CheckedFilter",

    components: { ButtonPill, ButtonText },

    props: {
        filters: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            localFilters: [],
        };
    },

    watch: {
        filters() {
            this.animateHeight();
        },
    },

    mounted() {
        this.animateHeight();
    },

    methods: {
        animateHeight() {
            const animateOuter = this.$refs.animateOuter;
            const animateInner = this.$refs.animateInner;

            animateOuter.style.height = getComputedStyle(animateInner).height;

            if (this.filters.length === 0) {
                animateInner.style.opacity = 1;
                setTimeout(() => {
                    this.localFilters = this.filters;
                }, animationDurationFast);
            }
            else {
                if (this.localFilters.length === 0) {
                    setTimeout(() => {
                        animateInner.style.opacity = 0;
                    });
                }
                setTimeout(() => {
                    this.localFilters = this.filters;
                });
            }

            setTimeout(() => {
                if (this.filters.length === 0) {
                    animateOuter.style.height = 0;
                    animateInner.style.opacity = 0;
                }
                else {
                    animateInner.style.opacity = 1;
                    animateOuter.style.height = getComputedStyle(animateInner).height;
                }
            });

            setTimeout(() => {
                animateOuter.style.height = null;
                animateInner.style.opacity = null;
            }, animationDurationFast);
        },

        click(filter) {
            this.$emit('deleteFilter', filter);
        },

        reset() {
            this.$emit('resetFilters');
        },
    },
};
</script>

<style scoped>
.checked-filter {
    border-top: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .checked-filter {
        padding: 16px 20px;
    }
}
@media (max-width: 767px) {
    .checked-filter {
        padding: 16px;
    }
}

.checked-filter-list {
    font-size: 0;
}
@media (min-width: 768px) {
    .checked-filter-list {
        margin-bottom: -8px;
    }
}
@media (max-width: 767px) {
    .checked-filter-list::before,
    .checked-filter-list::after {
        content: "";
        display: inline-block;
    }

    .checked-filter-list {
        white-space: nowrap;
        overflow: auto;
        padding-bottom: 40px;
        margin-bottom: -40px;
    }

    .checked-filter-list_outer {
        overflow: hidden;
    }
}
@media (max-width: 767px) {
    .checked-filter-list::before,
    .checked-filter-list::after {
        width: 16px;
        height: 16px;
    }

    .checked-filter-list_outer {
        margin-left: -16px;
        margin-right: -16px;
    }
}

.checked-btn {
    margin-right: 12px;
}
@media (min-width: 768px) {
    .checked-btn {
        margin-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .checked-btn:nth-last-child(2) {
        margin-right: 0;
    }
}

.reset-btn {
    font-size: var(--base-fz);
}
.reset-btn_desktop {
    margin-left: 4px;
    margin-bottom: 8px;
}
.reset-btn_mobile {
    margin-top: 16px;
}
@media (min-width: 768px) {
    .reset-btn_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .reset-btn_desktop {
        display: none;
    }
}

.animate_outer {
    height: auto;
    overflow: hidden;
    transition: height .2s ease-out;
    will-change: height;
}

.animate_inner {
    transition: opacity .2s ease-out;
    will-change: opacity;
}
</style>