var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['cover-block', 'cover-block_' + _vm.size]},[_c('div',{staticClass:"big-cover-wrap",class:{
            'big-cover-wrap_disable': !_vm.mainCover,
            'big-cover-wrap_center': _vm.photos.length === 0,
        },on:{"touchstart":_vm.lock,"touchend":_vm.move}},[_c('div',{staticClass:"big-cover-wrap_inner",on:{"click":_vm.showPhotoViewerPopup}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.bigImageLoading)?_c('div',{staticClass:"big-cover__preloader"},[_c('Spinner')],1):_vm._e()]),_vm._v(" "),_c('img',{staticClass:"big-cover",class:{
                    'big-cover_placeholder': !_vm.mainCover,
                },attrs:{"src":_vm.bigCoverSrc,"alt":""},on:{"load":_vm.handleImageLoad}})],1)]),_vm._v(" "),(_vm.photos.length !== 0)?_c('div',{staticClass:"dots-list"},[_c('ol',{staticClass:"slider__dots"},[_c('li',[_c('button',{class:{
                        'active': _vm.bigCoverIndex === -1
                    },on:{"click":function($event){return _vm.changeImage(-1)}}})]),_vm._v(" "),_vm._l((_vm.photos),function(photo,index){return _c('li',{key:'dot-' + photo.uuid},[_c('button',{class:{
                        'active': _vm.bigCoverIndex === index
                    },on:{"click":function($event){return _vm.changeImage(index)}}})])})],2)]):_vm._e(),_vm._v(" "),(_vm.photos.length !== 0)?_c('div',{staticClass:"covers-list-wrap"},[(_vm.photos.length)?_c('div',{staticClass:"covers-list"},[(_vm.hasMainCover)?_c('button',{key:'main-cover',staticClass:"cover-btn",class:{
                    'cover-btn_active': _vm.bigCoverIndex === -1
                },on:{"click":function($event){return _vm.changeImage(-1)}}},[_c('img',{staticClass:"small-cover",attrs:{"src":_vm.$links.uploads + _vm.hasMainCover,"alt":""}})]):_vm._e(),_vm._v(" "),_vm._l((_vm.photos),function(photo,index){return _c('button',{key:'item-cover-' + photo.uuid,staticClass:"cover-btn",class:{
                    'cover-btn_active': index === _vm.bigCoverIndex
                },on:{"click":function($event){return _vm.changeImage(index)}}},[_c('img',{staticClass:"small-cover",attrs:{"src":_vm.$links.uploads + photo.thumbnails[_vm.photosCodename],"alt":""}})])})],2):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }