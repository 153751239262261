var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AccountLayout',{attrs:{"title":"Отзывы"}},[[_c('section',[(!_vm.initialized)?_c('div',{staticClass:"spinner-wrap"},[_c('Spinner',{attrs:{"center":"","size":"big"}})],1):[_c('MarketItemNavigation',{staticClass:"layer-1 bottom-gap_grid",attrs:{"small":"","items":[
                        {
                            to: {
                                name: 'account-reviews-parts'
                            },
                            label: 'Запчасти',
                            amount: _vm.partsCount
                        },
                        {
                            to: {
                                name: 'account-reviews-products'
                            },
                            label: 'Автотовары',
                            amount: _vm.productsCount
                        },
                        {
                            to: {
                                name: 'account-reviews-shops'
                            },
                            label: 'Магазины',
                            amount: _vm.shopsCount
                        } ]}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade-page","mode":"out-in"}},[_c('RouterView')],1)]],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }