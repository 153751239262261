<template>
    <AfterPaymentContent
        status="failed"
    ></AfterPaymentContent>
</template>

<script>
import AfterPaymentContent from "@/components/AfterPaymentContent.vue";

export default {
    name: "PaymentFailed",

    components: { AfterPaymentContent },
};
</script>