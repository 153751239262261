<template>
    <div class="profile__main">
        <EmptyBlock
            v-if="current_count === 0"
            icon="star-stroke"
            title="Нет отзывов"
            text="Ваши отзывы на автотовары будут отображаться в этом разделе."
            :hasBtn="false"
        ></EmptyBlock>

        <template v-else>
            <ol class="items-list">
                <li
                    v-for="item in reviews"
                    :key="'products-review-' + item.create_date"
                    class="profile-review layer-1"
                >
                    <ProfileReview
                        :shop="item.shop"
                        :part="item.part"
                        :product="item.product"
                        :review="item"
                        @update="FETCH_ITEMS"
                    ></ProfileReview>
                </li>
            </ol>

            <ButtonText
                v-if="hasNext"
                class="load-more-btn"
                dashed
                :loading="loading"
                @click="load"
            >
                Показать ещё
            </ButtonText>
        </template>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { mapActions, mapMutations, mapState } from "vuex";
import ProfileReview from "@/components/ProfileReview.vue";
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonText from "@/components/_buttons/ButtonText.vue";


export default {
    name: 'ProfileReviewsProducts',

    metaInfo() {
        return {
            title: 'ЛК / Мои отзывы об автотоварах',
        };
    },

    components: { ButtonText, EmptyBlock, ProfileReview },

    data() {
        return {
            rangeData: {
                limit: 20,
                offset: 0,
                order_by: '-create_date',
            },
            source: null,
            loadingTimeout: null,
            loading: false,
        };
    },

    computed: {
        ...mapState({
            reviews: state => state.profileReviews.products.items,
            fetching: state => state.profileReviews.products.fetching,
            current_count: state => state.profileReviews.products.current_count,
        }),

        hasNext() {
            return (this.rangeData.offset + this.rangeData.limit) <  this.current_count;
        },
    },

    mounted() {
        this.$breadcrumbs([]);
    },

    methods: {
        ...mapMutations({
            ADD_ITEMS: 'profileReviews/addProducts',
        }),

        ...mapActions({
            GET_ITEMS: 'profileReviews/getProductsReviews',
            FETCH_ITEMS: 'profileReviews/fetchProductsReviews',
        }),

        async getProducts() {
            this.loadingTimeout = setTimeout(() => {
                this.loading = true;
            }, 300);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const config = {
                params: this.rangeData,
                cancelToken: this.source.token,
            };

            const response = await this.GET_ITEMS(config);

            if (response) {
                this.source = null;
                clearTimeout(this.loadingTimeout);
                return response;
            }

            this.loading = false;
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.getProducts();
            if (response) this.ADD_ITEMS(response);
        },
    },
};
</script>

<style scoped>
.profile__main {
    flex-grow: 1;
}
.profile-review:not(:last-child) {
    margin-bottom: var(--y-grid-gap);
}

.item__description {
    margin-top: 4px;
    font-size: var(--adaptive-small-fz);
}
</style>