var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.initialized)?_c('Spinner',{attrs:{"size":"big","center":""}}):_c('MarketItemsListWithOffers',{attrs:{"apiName":"parts","modelName":"part_product","items":_vm.newParts.items,"amount":_vm.newParts.current_count,"totalAmount":_vm.newPartsAmount,"staticFilters":_vm.staticFilters,"checkedFilters":_vm.checkedFilters,"filterData":_vm.filterData,"sortData":_vm.sortData,"sortOptions":_vm.sortOptions,"hasNext":_vm.hasNext,"loading":_vm.loading},on:{"changeFilter":_vm.onChangeFilter,"resetFilters":_vm.onResetFilters,"deleteFilter":_vm.onDeleteFilter,"changeSort":_vm.onChangeSort,"reload":_vm.reload,"load":_vm.load},scopedSlots:_vm._u([{key:"listItem",fn:function(ref ){
var item = ref.item;
var itemIndex = ref.itemIndex;
return [_c('MarketItemRowListItem',{attrs:{"item":item,"modelName":"part_product","reviewsInPopup":""},on:{"changeFavorite":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChangeFavorite.apply(void 0, [ itemIndex ].concat( argsArray ))},"changeCart":function($event){return _vm.onChangeCart(itemIndex)}}})]}},{key:"gridItem",fn:function(ref ){
var item = ref.item;
var itemIndex = ref.itemIndex;
return [_c('MarketItemColumn',{attrs:{"item":item,"apiName":"parts","modelName":"part_product","reviewsInPopup":""},on:{"changeFavorite":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChangeFavorite.apply(void 0, [ itemIndex ].concat( argsArray ))},"changeCart":function($event){return _vm.onChangeCart(itemIndex)}}})]}},{key:"inStockEmpty",fn:function(){return [_c('EmptyBlock',{attrs:{"icon":"empty-box","title":"Нет новых запчастей","text":"Нет информации об актуальных предложениях магазина.","btnText":"Перейти в каталог","btnUrl":{
                name: 'market',
            }}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }