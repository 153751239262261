<template>
    <ChatTemplate>
        <template #header>
            <div class="chat-complaint__header-wrap">
                <div class="chat-complaint__header">
                    <div class="chat-complaint__header-back">
                        <button
                            tabindex="-1"
                            type="button"
                            class="flex align-items-center"
                            @click="close"
                        >
                            <UIcon
                                name="back"
                                big
                                class="chat-complaint__back-icon"
                            ></UIcon>
                        </button>
                    </div>

                    <b class="chat-complaint__title">
                        Пожаловаться на магазин
                    </b>

                    <div class="chat-complaint__header-close">
                        <button
                            type="button"
                            aria-label="Закрыть чат"
                            class="chat-complaint__mobile-close-button"
                            @click="$emit('closeChat')"
                        >
                            <UIcon
                                name="close-24"
                                big
                                class="chat-complaint__mobile-close-icon"
                            ></UIcon>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <div class="chat-complaint__body">
                <div class="mb-20 flex">
                    <ShopLogo
                        :shop="shop"
                        coverCodename="medium"
                        noLink
                        class="mr-24 flex-initial"
                    ></ShopLogo>

                    <div class="flex justify-content-center flex-column">
                        <div class="text_bold mb-4">
                            {{ shop.company.name }}
                        </div>
                        <div>
                            {{ shop.address }}
                        </div>
                    </div>
                </div>

                <FormManager
                    ref="form"
                    v-model="formData"
                    class="mb-36"
                    @submit="send"
                >
                    <FormField
                        name="message"
                        type="text"
                        placeholder="Напишите, что не так"
                        rows="7"
                        validations="required"
                    ></FormField>
                </FormManager>

                <div class="chat-complaint__footer">
                    <ButtonBlock
                        primary
                        :loading="sending"
                        class="chat-complaint__button w100"
                        @click="send"
                    >
                        Отправить
                    </ButtonBlock>

                    <div class="chat-complaint__description">
                        Обратная связь от&nbsp;пользователей помогает нам делать сервис лучше. Ваша жалоба не&nbsp;будет опубликована публично.
                    </div>
                </div>
            </div>
        </template>
    </ChatTemplate>
</template>

<script>
import ChatTemplate from '@/components/_chat/ChatTemplate.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ShopLogo from '@/components/ShopLogo.vue';


export default {
    name: 'ChatComplaint',

    components: {
        ShopLogo,
        ButtonBlock,
        FormField: () => import('@/components/_form/FormField.vue'),
        FormManager: () => import('@/components/_form/FormManager.vue'),
        UIcon,
        ChatTemplate,
    },

    props: {
        chat: Object,
        shop: Object,
        client: Object,
        loading: Boolean,
    },

    data() {
        return {
            sending: false,
            formData: {
                message: '',
            },
        };
    },

    methods: {
        close() {
            const target = this;
            return this.$emit('close', { target });
        },

        async send() {
            const shop = this.shop.id;
            const uuid = this.chat.uuid;
            const client = this.client.id;
            const data = Object.assign({}, this.formData, { client, shop });

            const errors = await this.$refs.form.validate();

            if (errors) return;

            this.sending = true;

            try {
                await this.$api.chat.complaint(uuid, data);

                this.$notify({
                    type: 'success',
                    message: 'Сообщение отправлено, мы рассмотри вашу жалобу и примем меры',
                });

                this.close();
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError');
            }
            finally {
                this.sending = false;
            }
        },
    },
};
</script>

<style>
.chat-complaint__header-wrap {
    width: 100%;
    padding: 12px 24px;
    display: flex;
}
@media (max-width: 767px) {
    .chat-complaint__header-wrap {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

.chat-complaint__header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.chat-complaint__header-back {
    width: 24px;
    flex-shrink: 0;
}

.chat-complaint__title {
    flex-grow: 1;
    text-align: center;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.chat-complaint__header-close {
    width: 24px;
    flex-shrink: 0;
}

.chat-complaint__mobile-close-button {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .chat-complaint__mobile-close-button {
        display: none;
    }
}

.chat-complaint__mobile-close-icon {
    fill: var(--font-secondary-color);
    display: flex;
    align-items: center;
}

.chat-complaint__mobile-close-button:hover .chat-complaint__mobile-close-icon,
.chat-complaint__mobile-close-button:active .chat-complaint__mobile-close-icon {
    fill: var(--dark-c);
}

.chat-complaint__body {
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
}
@media (max-width: 767px) {
    .chat-complaint__body {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

.chat-complaint__footer {
    display: flex;
    flex-direction: column;
}
@media (max-width: 767px) {
    .chat-complaint__footer {
        flex-direction: column-reverse;
        margin-top: auto;
    }
}

.chat-complaint__button {
    width: 100%;
}
@media (min-width: 768px) {
    .chat-complaint__button {
        margin-bottom: 20px;
    }
}

.chat-complaint__description {
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
    line-height: 1.33;
}
@media (max-width: 767px) {
    .chat-complaint__description {
        margin-bottom: 20px;
    }
}
</style>