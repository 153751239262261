<template>
    <RouterLink
        :class="[
            'item',
            small && 'item_small',
        ]"
        :to="{
            name: 'news-item',
            params: {
                codename: item.codename,
            },
        }"
    >
        <picture>
            <source
                :srcset="`
                    ${ $links.uploads + item.cover.thumbnails.news_cover_list_vertical_x2 } 2x,
                    ${ $links.uploads + item.cover.thumbnails.news_cover_list_vertical },
                `"
                media="(min-width: 1040px)"
            >
            <source
                :srcset="`
                    ${ $links.uploads + item.cover.thumbnails.news_cover_list_horizont_x2 } 2x,
                    ${ $links.uploads + item.cover.thumbnails.news_cover_list_horizont },
                `"
                media="(max-width: 1039px)"
            >
            <img
                :src="$links.uploads + item.cover.thumbnails.news_cover_detail_x2"
                class="cover"
                loading="lazy"
            >
        </picture>

        <div class="info">
            <time class="date">{{ item.publication_date | dt }}</time>
            <h2 class="title">
                {{ item.title }}
            </h2>

            <p class="more-link-wrap">
                <span class="more-link">Подробнее</span>

                <UIcon
                    name="arrow-right"
                    small
                    class="more-link-icon"
                ></UIcon>
            </p>
        </div>
    </RouterLink>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'AboutNewsGridItem',

    components: { UIcon },

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },

        small: Boolean,
    },
};
</script>

<style scoped>
.item {
    display: block;
    width: 100%;
    position: relative;
    border-radius: var(--border-radius-x2);
    overflow: hidden;
}
@media (min-width: 1040px) {
    .item {
        height: 320px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px){
    .item_small {
        height: 256px;
    }
}
@media (max-width: 1039px) {
    .item {
        height: 220px;
    }
}
@media (max-width: 767px) {
    .item:not(:last-child) {
        margin-bottom: var(--y-grid-gap);
    }
}

.item:hover .info,
.item:focus .info {
    background-color: rgba(0, 0, 0, .6);
}

.item:hover .more-link-icon,
.item:focus .more-link-icon {
    transform: translateX(4px);
}

.cover {
    width: 100%;
    height: 100%;
    max-width: initial;
    object-fit: cover;
}

.info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: var(--light-c);
    background-color: rgba(0, 0, 0, 0.4);
    transition: background-color var(--transition);
}
@media (min-width: 768px) {
    .info {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .info {
        padding: 20px;
    }
}

.date {
    margin-bottom: 8px;
    font-size: 12px;
}

.title {
    font-size: var(--big-fz);
    font-family: var(--f-bold);
}

.more-link-wrap {
    margin-top: auto;
    font-size: 0;
}

.more-link {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    font-family: var(--f-bold);
}

.more-link-icon {
    margin-left: 8px;
    fill: currentColor;
    vertical-align: -3px;
    transition: transform var(--transition), fill var(--transition);
}
</style>