<template>
    <section class="market-part-applicability">
        <h1 class="h2 h2_block">
            Применяемость запчасти {{ part.name }}
        </h1>

        <Spinner
            v-if="initLoading"
            key="initLoading"
            center
            size="big"
        ></Spinner>

        <div
            v-if="!initLoading"
            key="loaded"
        >
            <div
                v-if="manufacturers.length"
                class="layer-1"
            >
                <ul class="manufacturers-list">
                    <li
                        v-for="manufacturer in manufacturers"
                        :key="'market-part-applicability-manufacturer-' + manufacturer.id"
                        class="manufacturer-item"
                    >
                        <button
                            class="manufacturer-wrap"
                            :class="{
                                active: manufacturer.id === currentManufacturer.id
                            }"
                            @click="setCurrentManufacturer(manufacturer)"
                        >
                            <CarManufacturerLogo
                                v-if="manufacturer.codename"
                                :manufacturer="manufacturer"
                                mono
                                class="manufacturer-logo"
                            ></CarManufacturerLogo>
                            <span class="manufacturer-name">{{ manufacturer.name }}</span>
                        </button>
                    </li>
                </ul>

                <div
                    v-if="manufacturers.length"
                    class="fields-block"
                >
                    <Selector
                        v-model="currentFamily"
                        label="Модель"
                        :options="families"
                        optionLabel="name"
                        :emptyValue="{}"
                        :disabled="!currentManufacturer.id"
                        :loading="familiesLoading"
                        @change="getYears"
                    ></Selector>

                    <Selector
                        v-model="currentYear"
                        label="Год выпуска"
                        :options="years"
                        optionLabel="name"
                        optionKey="codename"
                        :emptyValue="{}"
                        :disabled="!currentFamily.id"
                        :loading="yearsLoading"
                        @change="getCars"
                    ></Selector>
                </div>
            </div>

            <div
                v-if="currentFamily.id && currentYear.codename && loading"
                class="cars-table__loading-content"
            >
                <Spinner
                    center
                    size="big"
                ></Spinner>
            </div>

            <div v-show="currentFamily.id && currentYear.codename && !loading && cars.length">
                <div class="mt-20 cars-list">
                    <div
                        v-for="(car, carIndex) in cars"
                        :key="'applicability-table-car-' + car.id"
                        class="car-item-wrap layer-1"
                    >
                        <div class="car-item">
                            <div class="car-item__text-wrap">
                                <span class="car-item__years ">
                                    {{ car.start_year }} – {{ car.end_year === 9999 || !car.end_year ? 'н.в.' : car.end_year }}
                                </span>

                                <p class="car-item__characteristics">
                                    <span class="car-item__characteristics-text">{{ car.characteristicsString }}</span>
                                    <CarCharacteristicsTableTooltip
                                        tooltipEvent="hover"
                                        :car="car"
                                        dynamicContent
                                        class="car-item__tooltip"
                                    ></CarCharacteristicsTableTooltip>
                                </p>
                            </div>

                            <RoundArrowBtn
                                class="car-item__toggler"
                                direction="down"
                                :rotate="car._opened"
                                @click="toggleCar({ car, index: carIndex })"
                            ></RoundArrowBtn>
                        </div>

                        <TransitionExpand>
                            <div
                                v-if="car._opened"
                                key="cars-table__schemes"
                                class="cars-table__schemes"
                            >
                                <transition
                                    name="fade"
                                    mode="out-in"
                                >
                                    <div
                                        v-if="schemes[car.id].loaded"
                                        key="loaded-inner"
                                    >
                                        <div
                                            v-for="item in schemes[car.id].items"
                                            :key="'applicability-car-scheme-' + item.scheme.id + item.part.id"
                                            class="cars-table__scheme"
                                        >
                                            <div class="cars-table__pnc-column">
                                                {{ item.part.pnc }}
                                            </div>

                                            <div class="cars-table__part-name-column">
                                                <div class="cars-table__part-name">
                                                    {{ item.part.name }}
                                                </div>
                                                <div class="cars-table__breadcrumbs">
                                                    {{ item.groups[item.groups.length-1].name }}
                                                </div>
                                            </div>

                                            <div class="cars-table__actions-column">
                                                <ButtonText
                                                    dashed
                                                    secondary
                                                    dark
                                                    class="cars-table__scheme-link"
                                                    @click="showScheme({ car, item })"
                                                >
                                                    На схеме
                                                </ButtonText>

                                                <ButtonBlock
                                                    :to="{
                                                        name: 'market-parts-search',
                                                        query: {
                                                            part_product: item.part.id,
                                                        }
                                                    }"
                                                    primary
                                                    low
                                                    class="cars-table__price-link"
                                                >
                                                    Показать цены
                                                </ButtonBlock>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-else
                                        key="loading-inner"
                                        class="cars-table__loading-schemes"
                                    >
                                        <Spinner center></Spinner>
                                    </div>
                                </transition>
                            </div>
                        </TransitionExpand>
                    </div>
                </div>
            </div>

            <EmptyBlock
                v-if="!currentFamily.id || !currentYear.codename || cars.length === 0 && !loading"
                icon="car"
                :hasBtn="false"
                class="mt-20"
            >
                <template
                    v-if="carsCount"
                    #text
                >
                    Запчасть применяется в {{ carsCount | number }} {{ carsCountText }}. <br>
                    Выберите марку, модель и год выпуска.
                </template>
                <template
                    v-else
                    #text
                >
                    Нет данных о применяемости этой запчасти в автомобилях.
                </template>
            </EmptyBlock>
        </div>
    </section>
</template>

<script>
import plural from '@/lib/plural.js';
import equals from '@/lib/equals.js';
import TransitionExpand from './_transitions/TransitionExpand.vue';
import Spinner from './Spinner.vue';
import RoundArrowBtn from './project-buttons/RoundArrowBtn.vue';
import CarManufacturerLogo from '@/components/CarManufacturerLogo.vue';
import Selector from '@/components/_inputs/Selector.vue';
import CarCharacteristicsTableTooltip from '@/components/CarCharacteristicsTableTooltip.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonBlock from '@ui/components/UButton/UButtonBlock.vue';
const ShortSchemePopup = () => import('@/components/popups/ShortSchemePopup.vue');

let routeApplicabilityParams = {
    manufacturer: null,
    family: null,
};

export default {
    name: 'MarketPartApplicability',

    components: {
        ButtonBlock,
        EmptyBlock,
        ButtonText,
        CarCharacteristicsTableTooltip,
        Selector,
        CarManufacturerLogo,
        RoundArrowBtn,
        Spinner,
        TransitionExpand,
    },

    props: {
        part: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    data() {
        return {
            initLoading: true,
            loading: false,
            selectCarLoading: null,
            currentManufacturer: {},
            currentFamily: {},
            currentYear: {},
            selectedCar: {},

            manufacturers: [],
            families: [],
            familiesLoading: false,
            years: [],
            yearsLoading: false,
            cars: [],
            carsCount: 0,

            schemes: {
                // [car.id]: {
                //     loaded: false,
                //     items: []
                // }
            },
        };
    },

    computed: {
        openedCars() {
            return Object.entries(this.cars).reduce((acc, cur) => {
                const familyId = cur[0];
                const cars = cur[1];
                cars.forEach((car, index) => {
                    if (car._opened) {
                        acc[car.id] = car;
                        acc[car.id].family = { id: familyId };
                        acc[car.id].index = index;
                    }
                });
                return acc;
            }, {});
        },

        carsCountText() {
            return plural(this.carsCount, ['автомобиле', 'автомобилях', 'автомобилях']);
        },
    },

    mounted() {
        if (this.$route.query.manufacturer) {
            routeApplicabilityParams.manufacturer = this.$route.query.manufacturer;
        }
        else {
            this.resetQuery();
        }

        if (this.$route.query.family) {
            routeApplicabilityParams.family = this.$route.query.family;
        }

        this.getManufacturers();
    },

    methods: {
        async getManufacturers() {
            const { manufacturers, cars_count } = await this.getData({
                get_facets: 'manufacturers,cars_count',
            });
            this.manufacturers = manufacturers;
            this.carsCount = cars_count;
            this.initLoading = false;

            if (this.$route.query.manufacturer) {
                const foundManufacturer = manufacturers.find(item => {
                    return item.id === Number(this.$route.query.manufacturer);
                });

                if (foundManufacturer) {
                    this.currentManufacturer = foundManufacturer;
                    await this.getFamilies();
                }
                else if (manufacturers.length === 1) {
                    this.currentManufacturer = manufacturers[0];
                    await this.getFamilies();
                }
                else {
                    this.resetQuery();
                    this.initLoading = false;
                }
            }
            else {
                if (manufacturers.length === 1) {
                    this.currentManufacturer = manufacturers[0];
                    await this.getFamilies();
                }
                else {
                    this.initLoading = false;
                }
            }
        },

        async getFamilies() {
            try {
                this.familiesLoading = true;
                this.years = [];
                this.cars = [];
                this.currentFamily = {};
                this.currentYear = {};
                this.selectedCar = {};
                let options = {
                    get_facets: 'families',
                    manufacturer: this.currentManufacturer.id,
                };
                const { families } = await this.getData(options);

                if (families) {
                    this.families = families;

                    if (this.$route.query.family) {
                        const foundFamily = families.find(item => item.id === Number(this.$route.query.family));
                        this.resetQuery();

                        if (foundFamily) {
                            this.currentFamily = foundFamily;
                            await this.getYears();
                        }
                        else if (families.length === 1) {
                            this.currentFamily = families[0];
                            await this.getYears();
                        }
                        else {
                            this.initLoading = false;
                        }
                    }
                    else {
                        if (families.length === 1) {
                            this.currentFamily = families[0];
                            await this.getYears();
                        }
                        else {
                            this.initLoading = false;
                        }
                    }
                }
                else {
                    this.initLoading = false;
                }
            }
            catch (e) {
                console.log('getFamilies', e);
            }
            this.familiesLoading = false;
        },

        async getYears() {
            if (this.currentFamily.id) {
                try {
                    this.yearsLoading = true;
                    this.cars = [];
                    this.currentYear = {};
                    this.selectedCar = {};
                    let options = {
                        get_facets: 'cars_years',
                        manufacturer: this.currentManufacturer.id,
                        family: this.currentFamily.id,
                    };
                    const { cars_years } = await this.getData(options);
                    if (cars_years) {
                        this.years = cars_years;
                        if (cars_years.length === 1) {
                            this.currentYear = cars_years[0];
                            await this.getCars();
                        }
                        else {
                            this.initLoading = false;
                        }
                    }
                    else {
                        this.years = [];
                        this.initLoading = false;
                    }
                }
                catch (e) {
                    this.years = [];
                    console.log('getYears', e);
                }
                this.yearsLoading = false;
            }
            else {
                this.years = [];
            }
        },

        async getCars() {
            this.cars = [];

            let options = {
                get_facets: 'cars',
                manufacturer: this.currentManufacturer.id,
            };

            if (this.currentFamily.id) {
                options.family = this.currentFamily.id;
            }
            if (this.currentYear.codename) {
                options.car_start_year__lte = this.currentYear.codename;
                options.car_end_year__gte = this.currentYear.codename;
            }

            if (this.currentFamily.id || this.currentYear.codename) {
                try {
                    this.loading = true;
                    let { cars } = await this.getData(options);
                    if (cars) {
                        cars.forEach(car => {
                            car._opened = false;
                            let characteristicsArr = [];
                            if (car.frame) characteristicsArr.push(car.frame);
                            if (car.engine) characteristicsArr.push(car.engine);
                            characteristicsArr = characteristicsArr.concat(Object.values(car.characteristics_values).filter(item => !!item));
                            car.characteristicsString = characteristicsArr.join(', ');
                        });
                        this.cars = cars;
                    }
                }
                catch (e) {
                    console.log('getCars', e);
                }
            }
            this.loading = false;
            this.initLoading = false;
        },

        async getData(options) {
            let params = Object.assign({
                limit: 0,
                offset: 0,
            }, options);

            if (this.part.is_original) params.oem = this.part.sku;
            else params.part_product__in = this.part.originals.join('__');

            try {
                const { facets } = await this.$api.oems.get({ params });

                return facets;
            }
            catch (error) {
                return {};
            }
        },

        setCurrentManufacturer(manufacturer) {
            this.visibleMoreManufacturerFamilies = false;
            this.currentManufacturer = manufacturer;
            this.families = [];
            this.years = [];
            this.cars = [];
            this.currentFamily = {};
            this.currentYear = {};
            this.getFamilies();
        },

        setCurrentFamily(family) {
            if (this.currentFamily.id !== family.id) {
                this.currentFamily = family;
                this.cars = [];
                this.getCars();
            }
        },

        showScheme({ item, car }) {
            const props = {
                part: item.part,
                partId: item.part.id,
                car: Object.assign({
                    manufacturer: this.currentManufacturer,
                }, car),
                carId: car.id,
                manufacturerCodename: this.currentManufacturer.codename,
            };
            const options = { props };
            this.$popup(ShortSchemePopup, options);
        },

        async toggleCar({ car, index }) {
            this.cars[index]._opened = !this.cars[index]._opened;

            if (this.cars[index]._opened) {
                await this.updateSchemes(car);
            }
        },

        async updateSchemes(car) {
            if (!this.schemes[car.id]) {
                this.$set(this.schemes, car.id, { loaded: false, items: [] });

                const { results } = await this.getSchemes(car);

                if (results) {
                    this.schemes[car.id].items = results.filter(scheme => scheme.part).map(scheme => {
                        scheme.breadcrumbs = scheme.groups.map(group => group.name).join(' / ');
                        return scheme;
                    });

                    this.schemes[car.id].loaded = true;
                }
            }
        },

        async getSchemes(car) {
            const params = {
                car_id: car.id,
                order_by: 'number',
            };

            if (this.part.is_original) params.oem = this.part.sku;
            else params.part_product_id__in = this.part.originals.join(',');

            try {
                return await this.$api.catalogSchemeParts.get({ params });
            }
            catch (error) {
                console.log('getSchemes error: ', error);
                return {};
            }
        },

        resetQuery() {
            const location = {
                name: this.$route.name,
                params: { ...this.$route.params },
            };
            // почему-то роутер генерит без слэша в конце,
            // а в $route.fullPath уже со слэшем,
            // поэтому сравниваю по name и params
            const to = this.$router.resolve(location).route;

            if (to.name === this.$route.name && equals(to.params, this.$route.params)) return;

            // на всякий случай обернул в catch
            this.$router.replace(to.fullPath).catch(() => {});
        },
    },
};
</script>

<style scoped>
.market-part-applicability {
    margin-top: 48px;
}

.manufacturers-list {
    display: flex;
    padding: 20px var(--base-card-padding-x);
    background-color: var(--light-c);
    border-bottom: 1px solid var(--border-light-c);
}

.manufacturer-item {
    position: relative;
}

.manufacturer-item:not(:last-child) {
    margin-right: 24px;
}

.manufacturer-wrap {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: var(--f-bold);
    color: var(--font-secondary-color);
    text-align: left;
    transition: color var(--transition);
}

.manufacturer-wrap.active,
.manufacturer-wrap:hover,
.manufacturer-wrap:focus {
    color: var(--dark-c);
}

.manufacturer-wrap.active {
    cursor: default;
    pointer-events: none;
}

.manufacturer-logo {
    margin-right: 8px;
    opacity: .3;
    transition: opacity var(--transition);
}

.manufacturer-wrap.active .manufacturer-logo,
.manufacturer-wrap:hover .manufacturer-logo,
.manufacturer-wrap:focus .manufacturer-logo {
    opacity: 1;
}

.fields-block {
    display: grid;
    grid-gap: 20px;
    padding: 20px var(--base-card-padding-x);
    border-top: 1px solod var(--border-light-c);
}
@media (min-width: 1040px) {
    .fields-block {
        grid-template-columns: 280px 280px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .fields-block {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 767px) {
    .fields-block {
        grid-template-rows: 1fr 1fr;
    }
}

.car-item-wrap:not(:last-child) {
    margin-bottom: 8px;
}

.car-item {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .car-item {
        padding: 20px var(--base-card-padding-x);
    }
}
@media (max-width: 767px) {
    .car-item {
        padding: 16px;
    }
}

.car-item__text-wrap {
    flex-grow: 1;
}
@media (min-width: 768px) {
    .car-item__text-wrap {
        display: flex;
        align-items: center;
    }
}

.car-item__years {
    font-family: var(--f-bold);
}
@media (min-width: 1040px) {
    .car-item__years {
        width: 144px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .car-item__years {
        width: 100px;
    }
}
@media (min-width: 768px) {
    .car-item__years {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .car-item__years {
        display: block;
        margin-bottom: 4px;
    }
}

.car-item__characteristics {
    flex-grow: 1;
    line-height: var(--small-lh);
    font-size: 0;
}

.car-item__characteristics-text {
    font-size: var(--base-fz);
}

.car-item__tooltip {
    display: inline-block;
    margin-left: 8px;
    vertical-align: -2px;
    font-size: 0;
}

.car-item__toggler {
    margin-left: 24px;
}

.cars-table__schemes {
    padding: 0 var(--base-card-padding-x);
    border-top: 1px solid var(--border-light-c);
}

.cars-table__scheme {
    position: relative;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .cars-table__scheme {
        flex-wrap: wrap;
    }
}
@media (min-width: 768px) {
    .cars-table__scheme {
        display: flex;
        align-items: center;
        padding: 20px 0;
    }
}
@media (max-width: 767px) {
    .cars-table__scheme {
        padding: 16px 0;
    }
}
.cars-table__scheme:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}

.cars-table__pnc-column {
    color: var(--font-secondary-color);
    flex-shrink: 0;
}
@media (min-width: 1040px) {
    .cars-table__pnc-column {
        width: 144px;
        margin-right: 20px;
    }
}
@media (max-width: 1039px) {
    .cars-table__pnc-column {
        width: 100%;
        margin-bottom: 4px;
    }
}

.cars-table__part-name-column {
    flex-grow: 1;
}

.cars-table__part-name {
    margin-bottom: 4px;
    font-size: var(--big-fz);
    font-family: var(--f-bold);
}

.cars-table__breadcrumbs {
    font-size: var(--small-fz);
    line-height: var(--mini-lh);
    color: var(--font-secondary-color);
}

.cars-table__actions-column {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .cars-table__actions-column {
        flex-shrink: 0;
        margin-left: 24px;
    }
}
@media (max-width: 767px) {
    .cars-table__actions-column {
        margin-top: 12px;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

@media (min-width: 1040px) {
    .cars-table__price-link {
        margin-left: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .cars-table__price-link {
        margin-left: 24px;
    }
}
@media (max-width: 767px) {
    .cars-table__price-link {
        margin-right: 24px;
    }
}

.cars-table__loading-schemes,
.cars-table__loading-content {
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>