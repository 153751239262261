<template>
    <div class="chat-placeholder-template">
        <div class="content">
            <UIconRound
                v-bind="icon"
                class="icon"
            ></UIconRound>

            <div class="text-wrap">
                <slot name="text"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import UIconRound from '@ui/components/UIcon/UIconRound.vue';


export default {
    name: 'ChatPlaceholderTemplate',

    components: {
        UIconRound,
    },

    props: {
        icon: Object,
    },
};
</script>

<style scoped>
.chat-placeholder-template {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    text-align: center;
}

.icon {
    margin-bottom: 36px;
    margin-left: auto;
    margin-right: auto;
}

.text-wrap > p {
    line-height: var(--base-lh);
}

.text-wrap > p:not(:last-child) {
    margin-bottom: 36px;
}
</style>