export default {
    namespaced: true,

    state: () => ({
        items: [],
        current_count: 0,
    }),

    mutations: {
        set(state, { results, current_count }) {
            state.items = results;
            state.current_count = current_count;
        },
    },

    actions: {
        async get({ commit }) {
            try {
                const response = await this.$api.viewsHistory.get();
                commit('set', response);
            }
            catch (error) {
                //
            }
        },

        set({}, { id, type }) {
            const data = {
                [type]: id,
            };

            this.$api.viewsHistory.addToHistory(data);
        },
    },
};