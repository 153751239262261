<template>
    <div class="chat-layer">
        <ChatPopup
            v-if="show"
            :shop.sync="shop"
            @close="close"
        ></ChatPopup>

        <ChatFlyButton
            v-if="isAuthorized"
            :show="show"
            @click="toggle"
        ></ChatFlyButton>
    </div>
</template>

<script>
// utils
import bus from '@/bus.js';
// components
import ChatFlyButton from '@/components/chat/ChatFlyButton.vue';
import ChatPopup from '@/components/chat/ChatPopup.vue';


export default {
    name: 'ChatLayer',

    components: {
        ChatPopup,
        ChatFlyButton,
    },

    data() {
        return {
            show: false,
            shop: null,
            callback: null,
            onlineStatusTimeout: null,
        };
    },

    computed: {
        isAuthorized() {
            return this.$store.state.profile.isAuthorized;
        },
    },

    watch: {
        isAuthorized: {
            handler(value) {
                if (value) {
                    bus.$on('chat:open', this.open);
                    this.pushOnlineStatus();
                    this.setOnlineStatusTimeout();
                    document.addEventListener('visibilitychange', this.onVisibilityChange);
                }
                else {
                    bus.$off('chat:open', this.open);
                    this.removeOnlineStatusTimeout();
                    document.removeEventListener('visibilitychange', this.onVisibilityChange);
                }
            },
        },
    },

    mounted() {
        if (this.isAuthorized) {
            bus.$on('chat:open', this.open);
            this.pushOnlineStatus();
            this.setOnlineStatusTimeout();

            // Срабатывает при смене вкладок внутри браузера
            // или при смене рабочего стола (то есть когда браузер реально не видно)
            document.addEventListener('visibilitychange', this.onVisibilityChange);
        }
    },

    beforeDestroy() {
        bus.$off('chat:open', this.open);
        this.removeOnlineStatusTimeout();
        document.removeEventListener('visibilitychange', this.onVisibilityChange);
    },

    methods: {
        open({ shop }) {
            this.show = true;
            this.shop = shop;
        },

        close() {
            this.show = false;
        },

        toggle() {
            this.show = !this.show;
        },

        onVisibilityChange() {
            if (document.hidden) {
                this.removeOnlineStatusTimeout();
            }
            else {
                this.pushOnlineStatus();
                this.setOnlineStatusTimeout();
            }
        },

        setOnlineStatusTimeout() {
            this.onlineStatusTimeout = setTimeout(() => {
                this.pushOnlineStatus();
                this.setOnlineStatusTimeout();
            }, 1000 * 60 * 4);
        },

        removeOnlineStatusTimeout() {
            clearTimeout(this.onlineStatusTimeout);
            this.onlineStatusTimeout = null;
        },

        async pushOnlineStatus() {
            try {
                await this.$api.chat.updateOnline();
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
        },
    },
};
</script>