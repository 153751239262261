<template>
    <div
        class="stepper"
        :class="['stepper_' + steps.length]"
    >
        <div
            v-for="step in steps"
            class="step"
        >
            <p class="step__content-wrap">
                <b
                    class="step__title"
                    v-html="step.title"
                ></b>
                <span
                    class="step__text"
                    v-html="step.text"
                ></span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Stepper',

    props: {
        steps: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped>
.stepper {
    counter-reset: stepper;
}
@media (min-width: 768px) {
    .stepper {
        display: grid;
        grid-gap: var(--grid-gap);
        justify-content: center;

        --count-size: 48px;
    }
}
@media (max-width: 767px) {
    .stepper {
        --count-size: 36px;
    }
}

@media (min-width: 1281px) {
    .stepper_3 {
        grid-template-columns: repeat(3, 360px);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .stepper_3 {
        grid-template-columns: repeat(3, 280px);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .stepper_3 {
        grid-template-columns: repeat(3, var(--col-4));
    }
}

.step {
    position: relative;
}
@media (min-width: 768px) {
    .step {
        text-align: center;
    }
}
@media (max-width: 767px) {
    .step {
        display: flex;
    }

    .step:not(:last-child) {
        margin-bottom: 20px;
    }

    .step >>> br {
        display: none;
    }
}

.step::before {
    counter-increment: stepper;
    content: counter(stepper);
    display: block;
    flex-shrink: 0;
    width: var(--count-size);
    height: var(--count-size);
    line-height: var(--count-size);
    font-family: var(--f-semi-bold);
    color: var(--light-c);
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 50%;
}
@media (min-width: 768px) {
    .step::before {
        margin: 0 auto 16px;
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    .step::before {
        margin-right: 16px;
        font-size: 16px;
    }
}

@media (min-width: 1281px) {
    .stepper_3 .step::after  {
        width: calc(((360px / 2 - 36px - var(--count-size) / 2) * 2) + var(--grid-gap));
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .stepper_3 .step::after  {
        width: calc(((280px / 2 - 36px - var(--count-size) / 2) * 2) + var(--grid-gap));
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .stepper_3 .step::after  {
        width: calc(((var(--col-4) / 2 - 24px - var(--count-size) / 2) * 2) + var(--grid-gap));
    }
}

@media (min-width: 1040px) {
    .stepper_4 {
        grid-template-columns: repeat(4, var(--col-3));
    }

    .stepper_4 .step::after  {
        width: calc(((var(--col-3) / 2 - 36px - var(--count-size) / 2) * 2) + var(--grid-gap));
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .stepper_4 {
        grid-template-columns: repeat(2, var(--col-6));
        grid-gap: 36px var(--grid-gap);
    }

    .stepper_4 .step::after  {
        width: calc(((var(--col-6) / 2 - 24px - var(--count-size) / 2) * 2) + var(--grid-gap));
    }

    .stepper_4 :nth-child(2n)::after {
        display: none;
    }
}

@media (min-width: 1040px) {
    .step:not(:last-child)::after {
        left: calc(50% + 24px + 36px);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .step:not(:last-child)::after {
        left: calc(50% + 24px + 24px);
    }
}
@media (min-width: 768px) {
    .step:not(:last-child)::after {
        content: "";
        position: absolute;
        top: 22px;
        border-top: 2px dashed var(--border-dark-c);
    }
}

.step__title {
    display: block;
}
@media (min-width: 768px) {
    .step__title {
        font-size: var(--big-fz);
        margin-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .step__title {
        margin-bottom: 4px;
    }
}

.step__text {
    display: block;
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .step__text {
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>