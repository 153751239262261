<template>
    <Spinner
        v-if="!initialized"
        size="big"
        center
    ></Spinner>

    <MarketItemsListWithOffers
        v-else
        apiName="parts"
        modelName="part_product"
        :items="parts.items"
        :amount="parts.current_count"
        :totalAmount="partsTotalCount"
        :staticFilters="staticFilters"
        :checkedFilters="checkedFilters"
        :filterData="filterData"
        :sortData="sortData"
        :sortOptions="sortOptions"
        :hasNext="hasNext"
        :loading="loading"
        @changeFilter="onChangeFilter"
        @resetFilters="onResetFilters"
        @deleteFilter="onDeleteFilter"
        @changeSort="onChangeSort"
        @reload="reload"
        @load="load"
        @changeFavorite="onChangeFavorite"
    >
        <template #listItem="{ item, itemIndex }">
            <MarketItemRowListItem
                :item="item"
                modelName="part_product"
                reviewsInPopup
                @changeFavorite="onChangeFavorite(itemIndex, ...arguments)"
                @callShowOffersPopup="showOffersPopup(item, itemIndex)"
            ></MarketItemRowListItem>
        </template>

        <template #gridItem="{ item, itemIndex }">
            <MarketItemColumn
                :item="item"
                apiName="parts"
                modelName="part_product"
                reviewsInPopup
                @changeFavorite="onChangeFavorite(itemIndex, ...arguments)"
                @callShowOffersPopup="showOffersPopup(item, itemIndex)"
            ></MarketItemColumn>
        </template>

        <template #inStockEmpty>
            <EmptyBlock
                icon="empty-box"
                title="Нет запчастей"
                btnText="Перейти в каталог"
                :btnUrl="{
                    name: 'market',
                }"
            >
                <template #text>
                    От производителя <span class="text_bold">{{ brand.name }}</span> нет актуальных предложений.
                </template>
            </EmptyBlock>
        </template>
    </MarketItemsListWithOffers>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { HTTP } from '@/http.js';
import { animationDurationFast } from '@/plugins/animationSettings.js';
import MarketItemsListWithOffers from '@/components/MarketItemsListWithOffers.vue';
import MarketItemColumn from '@/components/MarketItemColumn.vue';
import MarketItemRowListItem from '@/components/MarketItemRowListItem.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import Spinner from '@/components/Spinner.vue';
const OffersPopup = () => import('@/components/popups/OffersPopup.vue');

export default {
    name: 'MarketBrandPartsPage',

    components: {
        Spinner,
        EmptyBlock,
        MarketItemRowListItem,
        MarketItemColumn,
        MarketItemsListWithOffers,
    },

    serverPrefetch() {
        return this.INIT({ query: this.$route.query });
    },

    data() {
        return {
            rangeData: {
                limit: 36,
                offset: 0,
            },
            source: null,
        };
    },

    computed: {
        ...mapState({
            brand: state => state.marketBrand.brand,
            partsTotalCount: state => state.marketBrand.partsTotalCount,
            parts: state => state.marketBrandParts.parts,
            hasNext: state => state.marketBrandParts.parts.hasNext,
            initialized: state => state.marketBrandParts.initialized,
            loading: state => state.marketBrandParts.loading,
            staticFilters: state => state.marketBrandParts.filter.staticFilters,
            checkedFilters: state => state.marketBrandParts.filter.checked,
            filterData: state => state.marketBrandParts.filter.value,
            sortOptions: state => state.marketBrandParts.sort.options,
            sortData: state => state.marketBrandParts.sort.value,
        }),

        ...mapGetters({
            filterQuery: 'marketBrandParts/filter/query',
            sortQuery: 'marketBrandParts/sort/query',
        }),

        location() {
            return {
                name: this.$route.name,
                query: Object.assign(
                    {},
                    this.filterQuery,
                    this.sortQuery,
                ),
                meta: {
                    savePosition: true,
                },
            };
        },

        fetchParams() {
            return Object.assign(
                {
                    brand: this.brand.id,
                    get_facets: 'all',
                },
                this.filterQuery,
                this.sortQuery,
                this.rangeData,
            );
        },
    },

    watch: {
        '$route.params.codename': {
            handler() {
                this.DESTROY();
                this.rangeData.offset = 0;
                this.init();
            },
        },
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.rangeData.offset = 0;
        this.DESTROY();
    },

    methods: {
        ...mapMutations({
            DELETE_FILTER: 'marketBrandParts/filter/deleteFilter',
            RESET_FILTERS: 'marketBrandParts/filter/reset',
            SET_SORT_VALUE: 'marketBrandParts/sort/setValue',
            SET_PARTS: 'marketBrandParts/setParts',
            ADD_PARTS: 'marketBrandParts/addParts',
            CHANGE_FAVORITE: 'marketBrandParts/changeFavorite',
            CHANGE_CART: 'marketBrandParts/changeCart',
            SET_LOADING: 'marketBrandParts/setLoading',
            DESTROY: 'marketBrandParts/destroy',
        }),

        ...mapActions({
            INIT: 'marketBrandParts/init',
            GET_PARTS: 'marketBrandParts/getParts',
            CHANGE_FILTER: 'marketBrandParts/filter/change',
        }),

        async init() {
            if (!this.initialized) {
                await this.INIT({ query: this.$route.query });
            }
            this.replaceQuery();
        },

        async fetch() {
            this.SET_LOADING(true);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                params: this.fetchParams,
                cancelToken: this.source.token,
            };

            const response = await this.GET_PARTS(payload);
            if (response) {
                this.source = null;
                await this.updateQuery();
                return response;
            }
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) {
                this.SET_LOADING(false);
                this.updateFiltersOptions(response.facets);
                this.ADD_PARTS(response);
            }
        },

        async reload() {
            this.rangeData.offset = 0;
            const response = await this.fetch();
            if (response) {
                this.updateFiltersOptions(response.facets);

                // Двойной по веремени timeout для того, чтобы сначала точно завершилась анимация фильтров,
                // и только потом произошел ререндер списка
                setTimeout(() => {
                    this.SET_LOADING(false);
                    this.SET_PARTS(response);
                }, animationDurationFast * 2);
            }
        },

        onChangeFilter(payload) {
            this.CHANGE_FILTER(payload);
        },

        onResetFilters() {
            this.RESET_FILTERS();
            this.reload();
        },

        onDeleteFilter(filter) {
            this.DELETE_FILTER(filter);
            this.reload();
        },

        updateFiltersOptions(facets) {
            this.$store.commit('marketBrandParts/filter/setStaticFilters', { config: this.staticFilters, facets });
        },

        isNewRoute() {
            const toPath = this.$router.resolve(this.location).route.fullPath;
            return this.$route.fullPath !== toPath;
        },

        async updateQuery() {
            if (this.isNewRoute()) {
                try {
                    return await this.$router.push(this.location);
                }
                catch (error) {
                    console.error(error);
                }
            }
            else {
                return '';
            }
        },

        async replaceQuery() {
            if (this.isNewRoute()) {
                try {
                    return await this.$router.replace(this.location);
                }
                catch (error) {
                    console.error(error);
                }
            }
            else {
                return '';
            }
        },

        showOffersPopup(item, itemIndex) {
            const props = {
                item: item,
                itemIndex: itemIndex,
                modelName: 'part_product',
                changeFavoriteCallback: (value) => this.onChangeFavorite(itemIndex, value),
            };
            const options = { props };
            this.$popup(OffersPopup, options);
        },

        onChangeFavorite(data) {
            this.CHANGE_FAVORITE(data);
        },

        onChangeSort(value) {
            this.SET_SORT_VALUE(value);
            this.reload();
        },
    },

};
</script>