<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_text">
                Удалить автомобиль
            </h2>

            <p class="bottom-gap_mini">
                Вы действительно хотите удалить этот автомобиль?
            </p>

            <CarName
                :car="car"
                class="text_big-fz"
            ></CarName>

            <CarCharacteristicsWithTooltip
                :car="car"
                :showTooltip="false"
                class="mt-8"
            ></CarCharacteristicsWithTooltip>

            <div
                v-if="car.grz || car.vin_frame"
                class="mt-4 text_secondary"
            >
                <template v-if="car.grz">
                    Гос. номер: {{ car.grz | carNumber }}
                </template>
                <template v-else-if="car.vin_frame">
                    VIN/Frame: {{ car.vin_frame }}
                </template>
            </div>

            <div class="actions-wrap">
                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="close"
                >
                    Нет
                </ButtonBlock>
                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    class="action-btn"
                    @click="confirm"
                >
                    Подтвердить
                </ButtonBlock>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import { mapActions } from "vuex";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import CarName from "@/components/CarName.vue";
import CarCharacteristicsWithTooltip from '@/components/CarCharacteristicsWithTooltip.vue';

export default {
    name: "DeleteCarConfirmPopup",

    components: { CarCharacteristicsWithTooltip, CarName, ButtonBlock, PopupWrap },

    mixins: [popup],

    props: {
        car: {
            type: Object,
            default: () => {},
        },

        uuid: {
            type: String,
            default: '',
        },

        index: {
            type: [String, Number],
            default: '',
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        ...mapActions({
            REMOVE_CAR: 'profileCars/removeCar',
        }),

        joinValues(values) {
            return values.map((e) => e.value).join('/');
        },

        async confirm() {
            this.loading = true;
            const response = await this.REMOVE_CAR({ uuid: this.uuid, index: this.index });
            if (response) {
                this.close();
                this.$notify({
                    type: 'success',
                    message: 'Автомобиль удалён из гаража',
                });
            }
            else {
                this.loading = false;
                this.$notify({
                    type: 'error',
                    message: 'Не удалось удалить автомобиль из гаража, попробуйте позже',
                });
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 476px;
    }
}

.actions-wrap {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .actions-wrap {
        margin-top: auto;
    }

    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>