<script>
export default {
    name: 'NotificationsPreviewRenderTitle',

    props: {
        title: String,
        isRead: Boolean,
    },

    render(h) {
        if (this.isRead) {
            return h('span', this.title);
        }

        const arr = this.title.split(' ');
        const lastWord = arr.pop();
        const title = arr.join(' ') + ' ';
        const marker = h('span', { class: 'notifications-preview-render-title__marker' });
        const nowrap = h('span', { class: 'notifications-preview-render-title__nowrap' }, [lastWord, marker]);
        return h('div', { class: 'notifications-preview-render-title' }, [title, nowrap]);
    },
};
</script>

<style>
.notifications-preview-render-title__nowrap {
    white-space: nowrap;
}

.notifications-preview-render-title__marker {
    margin-left: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary-color);
    vertical-align: center;
}
</style>