var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopupWrap',{on:{"close":_vm.close}},[_c('FormTemplate',{staticClass:"popup-content",scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('FormManager',{ref:"form",attrs:{"formTag":false},on:{"error":_vm.onError},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[_c('h2',{staticClass:"h2 h2_block owner-title"},[_vm._v("\n                    Собственник\n                ")]),_vm._v(" "),_c('div',{staticClass:"form-grid owner-grid"},[_c('FormField',{staticClass:"owner_last_name",attrs:{"name":"owner_last_name","label":"Фамилия","validations":"required"}}),_vm._v(" "),_c('FormField',{staticClass:"owner_first_name",attrs:{"name":"owner_first_name","label":"Имя","validations":"required"}}),_vm._v(" "),_c('FormField',{staticClass:"owner_middle_name",attrs:{"name":"owner_middle_name","label":"Отчество","validations":"required"}}),_vm._v(" "),_c('FormField',{staticClass:"owner_birth_date",attrs:{"name":"owner_birth_date","type":"calendar","label":"Дата рождения","stepByStep":"","min":_vm.minBirthDate,"max":_vm.maxBirthDate,"validations":("required|min:" + _vm.minBirthDateIso + "|max:" + _vm.maxBirthDateIso)}}),_vm._v(" "),_c('FormField',{staticClass:"owner_doc_number",attrs:{"name":"owner_doc_number","label":"Серия и номер паспорта","mask":"0000 000000","validations":"required|re","validationRules":{
                            re: function (ref) {
                                var value = ref.value;

                                return value ? !/(\d{4})\s(\d{6})/g.test(value) : false;
                            },
                        }}}),_vm._v(" "),_c('FormField',{staticClass:"owner_doc_date",attrs:{"name":"owner_doc_date","type":"date","visibleMask":"","label":"Дата выдачи паспорта","validations":("required|min:" + _vm.minOwnerDocDateIso + "|max:" + _vm.maxDocDateIso)}}),_vm._v(" "),_c('FormField',{staticClass:"owner_address_obj",attrs:{"name":"owner_address_obj","type":"address","label":"Адрес регистрации","validations":"required|house","validationRules":{
                            house: function (ref) {
                                                var value = ref.value;

                                                return !(value || {}).house;
}
                        },"validationMessages":{
                            house: _vm.address.hint
                        },"hint":_vm.address.hint,"queryOptions":{ ignore_flat: true }}}),_vm._v(" "),_c('FormField',{staticClass:"owner_address_apartment_number flat-field",attrs:{"name":"owner_address_apartment_number","label":"Квартира","maxLength":"4"}})],1),_vm._v(" "),_c('FormField',{staticClass:"mr-8 form-field-gap_top owner_is_insurer",attrs:{"name":"owner_is_insurer","type":"checkbox"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                        Собственник является "),_c('span',{staticClass:"text-nowrap"},[_vm._v("страхователем"),_c('UTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                        var value = ref.value;
                        var attrs = ref.attrs;
                        var handlers = ref.handlers;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}}])},[_vm._v("\n\n                            Физическое лицо, которое заключает договор ОСАГО\n                            и имеет право вносить в него изменения.\n                        ")])],1)]},proxy:true}])}),_vm._v(" "),(!_vm.formData.owner_is_insurer)?[_c('h2',{staticClass:"h2 mt-36 insurer-title"},[_vm._v("\n                        Страхователь\n                    ")]),_vm._v(" "),_c('div',{staticClass:"form-grid insurer-grid"},[_c('FormField',{staticClass:"insurer_last_name",attrs:{"name":"insurer_last_name","label":"Фамилия","validations":"required"}}),_vm._v(" "),_c('FormField',{staticClass:"insurer_first_name",attrs:{"name":"insurer_first_name","label":"Имя","validations":"required"}}),_vm._v(" "),_c('FormField',{staticClass:"insurer_middle_name",attrs:{"name":"insurer_middle_name","label":"Отчество","validations":"required"}}),_vm._v(" "),_c('FormField',{staticClass:"insurer_birth_date",attrs:{"name":"insurer_birth_date","type":"calendar","label":"Дата рождения","stepByStep":"","min":_vm.minBirthDate,"max":_vm.maxBirthDate,"validations":("required|min:" + _vm.minBirthDateIso + "|max:" + _vm.maxBirthDateIso)}}),_vm._v(" "),_c('FormField',{staticClass:"insurer_doc_number",attrs:{"name":"insurer_doc_number","label":"Серия и номер паспорта","mask":"0000 000000","validations":"required|re","validationRules":{
                                re: function (ref) {
                                    var value = ref.value;

                                    return value ? !/(\d{4})\s(\d{6})/g.test(value) : false;
                                },
                            }}}),_vm._v(" "),_c('FormField',{staticClass:"insurer_doc_date",attrs:{"name":"insurer_doc_date","type":"date","visibleMask":"","label":"Дата выдачи паспорта","validations":("required|min:" + _vm.minInsurerDocDateIso + "|max:" + _vm.maxDocDateIso)}}),_vm._v(" "),_c('FormField',{staticClass:"insurer_address_obj",attrs:{"name":"insurer_address_obj","type":"address","label":"Адрес регистрации","validations":"required|house","validationRules":{
                                house: function (ref) {
                                                    var value = ref.value;

                                                    return !(value || {}).house;
},
                            },"validationMessages":{
                                house: _vm.address.hint,
                            },"hint":_vm.address.hint}}),_vm._v(" "),_c('FormField',{staticClass:"insurer_address_apartment_number flat-field",attrs:{"name":"insurer_address_apartment_number","label":"Квартира","maxLength":"4"}})],1)]:_vm._e(),_vm._v(" "),_c('h2',{staticClass:"h2 mt-36 contacts-title"},[_vm._v("\n                    Контактные данные\n                ")]),_vm._v(" "),_c('div',{staticClass:"form-grid contacts-grid",class:{
                        expanded: _vm.phoneStatus === 'initial'
                    }},[_c('FormField',{staticClass:"insurer_email",attrs:{"name":"insurer_email","type":"email","label":"Email","validations":"required"},on:{"blur":function($event){return _vm.patch('insurer_email')}}}),_vm._v(" "),_c('FormField',_vm._b({staticClass:"insurer_phone",attrs:{"name":"insurer_phone","type":"phone","label":"Телефон","visibleMask":"","validations":"required","disabled":_vm.phoneStatus === 'initial'}},'FormField',_vm.phoneIsDone ? {
                            appendIcon: {
                                name: 'done',
                                small: true,
                                green: true,
                            },
                        } : {},false)),_vm._v(" "),(_vm.phoneStatus === 'initial')?[_c('div',{staticClass:"change-phone-button"},[_c('ButtonText',{attrs:{"secondary":"","dark":"","dashed":""},on:{"click":_vm.cancelConfirm}},[_vm._v("\n                                Изменить телефон\n                            ")])],1),_vm._v(" "),_c('InputText',{staticClass:"sms_code",attrs:{"label":"Код из СМС *","invalid":_vm.sms_code.invalid,"error":_vm.sms_code.error},on:{"enter":_vm.confirmCode},model:{value:(_vm.sms_code.value),callback:function ($$v) {_vm.$set(_vm.sms_code, "value", $$v)},expression:"sms_code.value"}}),_vm._v(" "),_c('div',{staticClass:"seconds"},[(_vm.seconds > 0)?_c('span',[_vm._v("Отправить ещё раз через "+_vm._s(_vm.seconds)+" сек")]):_c('ButtonText',{attrs:{"secondary":"","dark":"","dashed":""},on:{"click":_vm.sendCode}},[_vm._v("\n                                Отправить код повторно\n                            ")])],1)]:_vm._e()],2)],2)]},proxy:true},{key:"footer",fn:function(){return [_c('ButtonBlock',{staticClass:"button-secondary",attrs:{"secondary":""},on:{"click":_vm.cancel}},[_vm._v("\n                Отмена\n            ")]),_vm._v(" "),(_vm.isAuthorized || (_vm.phoneConfirmed && _vm.phoneEqual) || _vm.phoneStatus === 'initial')?_c('ButtonBlock',{staticClass:"button-primary",attrs:{"primary":"","disabled":!_vm.isAuthorized && !_vm.phoneConfirmed},on:{"click":_vm.confirm}},[_vm._v("\n                Сохранить\n            ")]):_c('ButtonBlock',{staticClass:"button-primary",attrs:{"primary":"","loading":_vm.sendingLoading},on:{"click":_vm.sendCode}},[_vm._v("\n                Получить код по СМС\n            ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }