<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2">
                Выбрать узел
            </h2>

            <PartsTree
                :nodes="catalogGroups"
                :selectedNode="selectedNode"
                :selectedCar="selectedCar"
                @change="changeSelectedNode"
            ></PartsTree>
        </div>
    </PopupWrap>
</template>

<script>
import PopupWrap from "@/components/popups/PopupWrap.vue";
import popup from "@/mixins/popup.js";
import PartsTree from "@/components/PartsTree.vue";

export default {
    name: "SelectPartsNodesCatalog",

    components: {
        PartsTree,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        catalogGroups: {
            type: Array,
            default: () => [],
        },

        selectedCar: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    data() {
        return {
            catalogSchemes: {},
            selectedNode: {
                id: '',
                name: '',
            },
        };
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        changeSelectedNode(node) {
            if (node.id) {
                this.callback(node);
                this.close();
            }
        },

        handleResize() {
            if (window.innerWidth >= 1040) {
                this.close();
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}
</style>