export default {
    install(Vue) {
        Vue.prototype.$server = Vue.$server = {
            url: process.env.API_DOMAIN,
            admin: process.env.ADMIN_URL,
            partnerSignup: process.env.PARTNER_URL + '/signup/',
            partnerIndex: process.env.PARTNER_URL,
        };

        Vue.prototype.$domain = Vue.$domain = {
            url: process.env.API_DOMAIN,
        };

        Vue.prototype.$links = Vue.$links = {
            api: process.env.API_URL,
            domain: process.env.API_DOMAIN,
            uploads: process.env.UPLOADS_URL,
            admin: process.env.ADMIN_URL,
            partner: process.env.PARTNER_URL,
            partnerSignup: process.env.PARTNER_URL + '/signup/',
            partnerIndex: process.env.PARTNER_URL,
            site: process.env.SITE_URL,
        };
    },
};
