<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_block popup-title">
                {{ computedAddGarageMode ? 'Добавить автомобиль в Гараж' : 'Выбрать автомобиль' }}
            </h2>

            <SelectCarForm
                :modeOptions="modeOptions"
                :outerAddGarageMode="outerAddGarageMode"
                @select="selectCar"
                @close="close"
                @turnOnInnerAddGarageMode="innerAddGarageMode = true;"
            ></SelectCarForm>
        </div>
    </PopupWrap>
</template>

<script>
import bus from '@/bus.js';
import { mapMutations } from 'vuex';
import popup from "@/mixins/popup.js";
import PopupWrap from "@/components/popups/PopupWrap.vue";
import SelectCarForm from "./SelectCarForm.vue";

export default {
    name: 'SelectCarPopup',

    components: {
        SelectCarForm,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        modeOptions: {
            type: Array,
            default() {
                return [];
            },
        },
        outerAddGarageMode: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            innerAddGarageMode: false,
        };
    },

    computed: {
        computedAddGarageMode() {
            return this.outerAddGarageMode ||  this.innerAddGarageMode;
        },
    },

    methods: {
        ...mapMutations({
            setCar: 'searchWidget/setCar',
        }),

        selectCar(car) {
            this.close();
            this.setCar(car);
            bus.$emit('changeCar');
            this.callback(car);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}
</style>