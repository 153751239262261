<template>
    <PopupWrap
        @close="close"
        @clickaway="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_block popup-title">
                Узнать стоимость доставки Яндекс&nbsp;Go
            </h2>

            <div class="points">
                <div class="points__indicator">
                    <span class="point point_a"></span>
                    <span class="point point_b"></span>
                </div>

                <div class="points__info">
                    <div class="flex align-items-center justify-content-between mb-20">
                        <div>
                            <ShopName
                                :shop="order.shop"
                                noLink
                            ></ShopName>
                            <ShopAddress
                                :shop="order.shop"
                                :showOnMap="false"
                            ></ShopAddress>
                        </div>
                        <ShopLogo
                            :shop="order.shop"
                            noLink
                            class="ml-20"
                        ></ShopLogo>
                    </div>

                    <div class="address-field">
                        <GeoInput
                            id="address-desktop"
                            :value="address.name"
                            :city="shopCity"
                            label="Куда доставить?"
                            :map="false"
                            @select="onAddressSelect"
                            @clear="onAddressClear"
                        ></GeoInput>
                        <Map
                            ref="desktopMap"
                            :city="shopCity"
                            :markers="markers"
                            :initialMapValue="currentCity.coordinates"
                            class="map"
                            @change="onMapChange"
                        ></Map>
                    </div>
                </div>
            </div>

            <TransitionExpand>
                <div
                    v-if="getDeliveryPriceLoading"
                    key="loading-delivery-data"
                    class="top-gap_mini flex align-items-center"
                >
                    <Spinner class="mr-8"></Spinner>
                    <span class="text_red text_semi-bold">Идёт расчёт</span>
                </div>
                <div
                    v-if="!getDeliveryPriceLoading && getDeliveryPriceSuccess"
                    key="delivery-data"
                    class="top-gap_small"
                >
                    <p class="mb-20 text_base-lh">
                        Тип автомобиля курьера зависит от веса и габаритов вашего заказа
                        и определяется далее на этапе подтверждения заказа в магазине.
                    </p>

                    <div class="tariff">
                        <div class="tariff__name-wrap">
                            <span class="tariff__name">Тариф «Легковой»</span>
                        </div>

                        <div class="tariff__price-wrap">
                            <Spinner v-show="updateDeliveryPriceLoading"></Spinner>

                            <div v-show="!updateDeliveryPriceLoading">
                                <span class="tariff__price">{{ expressTariffData.price | price }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="tariff">
                        <div class="tariff__name-wrap">
                            <span class="tariff__name">Тариф «Грузовой»</span>
                        </div>

                        <div class="tariff__price-wrap">
                            <Spinner v-if="updateDeliveryPriceLoading"></Spinner>

                            <div
                                v-if="!updateDeliveryPriceLoading && cargoTariffData.options"
                                class="flex align-items-center"
                            >
                                <UTooltip v-if="cargoTariffData.options.length > 1">
                                    <template #trigger="{ value, attrs, handlers }">
                                        <UTooltipIcon
                                            class="tariff__price-tooltip"
                                            :active="value"
                                            v-bind="attrs"
                                            v-on="handlers"
                                        ></UTooltipIcon>
                                    </template>

                                    <p
                                        v-for="option in cargoTariffData.options"
                                        class="tariff-option"
                                    >
                                        <span class="tariff-option__key">{{ option.labelText }}</span>
                                        <b class="tariff-option__value">{{ option.price | price }}</b>
                                    </p>
                                </UTooltip>
                                <span v-if="cargoTariffData.options.length === 1">{{ cargoTariffData.price | price }}</span>
                                <span v-else>от {{ cargoTariffData.priceMin | number }} до {{ cargoTariffData.priceMax | price }}</span>
                            </div>
                        </div>
                    </div>

                    <InfoMessage class="top-gap_small">
                        Цена является актуальной только на&nbsp;момент расчёта и&nbsp;может измениться
                        в&nbsp;зависимости от&nbsp;времени суток, погоды и&nbsp;количества свободных машин.
                    </InfoMessage>
                </div>
            </TransitionExpand>
        </div>
    </PopupWrap>
</template>

<script>
import { YANDEX_DELIVERY_PRICE_POLLING_TIMEOUT } from '@/settings.js';
import { mapState } from 'vuex';
import { HTTP } from '@/http.js';
import { getErrorCode } from '@/lib/errors.js';
import dictionary from '@/dictionary.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ShopName from '../ShopName.vue';
import ShopAddress from '../ShopAddress.vue';
import ShopLogo from '../ShopLogo.vue';
import GeoInput from '@/components/_inputs/GeoInput.vue';
import Map from '../geo/Map.vue';
import Spinner from '../Spinner.vue';
import InfoMessage from '../InfoMessage.vue';
import TransitionExpand from '../_transitions/TransitionExpand.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


const toArray = str => str ? str.replace(' ', '').split(',').map(Number) : null;
const toString = arr => arr ? arr.join(', ') : '';

export default {
    name: 'PrecalcYandexDeliveryPopup',

    components: {
        UTooltipIcon,
        UTooltip,
        TransitionExpand,
        InfoMessage,
        Spinner,
        Map,
        GeoInput,
        ShopLogo,
        ShopAddress,
        ShopName,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        order: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            dictionary,
            shopCity: {},
            address: {
                name: '',
                coords: '',
            },
            cargo_loaders: 0,
            expressTariffData: {},
            cargoTariffData: {},
            deliveryPriceCancelToken: null,
            getDeliveryPriceLoading: false,
            getDeliveryPriceSuccess: false,
            deliveryPaymentId: null,

            updateDeliveryPriceTimeout: null,
            updateDeliveryPriceLoading: false,
        };
    },

    computed: {
        ...mapState({
            currentCity: state => state.cities.currentCity,
            currentCityId: state => state.cities.currentCityId,
            profile: state => state.profile.profile,
        }),

        pointA() {
            return {
                coords: toArray(this.order.shop.coordinates),
                type: 'dark',
            };
        },

        pointB() {
            return {
                coords: toArray(this.address.coords),
                type: 'default',
            };
        },

        points() {
            if (this.pointB.coords) {
                return [
                    { coordinates: [this.pointA.coords[1], this.pointA.coords[0]] },
                    { coordinates: [this.pointB.coords[1], this.pointB.coords[0]] },
                ];
            }
            else {
                return [];
            }
        },

        markers() {
            if (this.pointB.coords) return [this.pointA, this.pointB];
            else return [this.pointA];
        },
    },

    created() {
        this.getShopCityData();

        // TODO: изменить на последние введенные данные
        // if (this.currentCityId === (this.profile.recipient_city || {}).id) {
        //     this.address.name = this.profile.recipient_address || '';
        //     this.address.coords = this.profile.recipient_coordinates || '';
        //     if (this.address.coords) {
        //         this.getDeliveryPriceLoading = true;
        //         this.getDeliveryPrice();
        //         this.onAddressChange();
        //     }
        // }
    },

    beforeDestroy() {
        clearTimeout(this.updateDeliveryPriceTimeout);
        if (this.deliveryPriceCancelToken) this.deliveryPriceCancelToken.cancel();
    },

    methods: {
        async getShopCityData() {
            this.shopCity = await this.$api.cities.get(this.order.shop.city.id);
        },

        onMapChange(value) {
            if (value) {
                this.address.name = value.name;
                this.address.coords = toString(value.coords);
                this.getDeliveryPrice();
            }
        },

        onAddressSelect(value) {
            if (value) {
                this.address.name = value.label;
                this.address.coords = toString(value.coords.split(' ').reverse());
                this.getDeliveryPrice();
                this.$nextTick(() => {
                    this.$refs.desktopMap.setMapCenter();
                });
            }
        },

        onAddressClear() {
            this.address.name = '';
            this.address.coords = '';
            this.getDeliveryPriceLoading = false;
            this.getDeliveryPriceSuccess = false;
            this.tariffData = [];
            if (this.deliveryPriceCancelToken) {
                clearTimeout(this.updateDeliveryPriceTimeout);
                this.deliveryPriceCancelToken.cancel();
            }
        },

        onAddressChange() {
            if (this.address.coords) {
                this.getDeliveryPriceLoading = true;
                this.getDeliveryPrice();
            }
            else {
                if (this.deliveryPriceCancelToken) {
                    clearTimeout(this.updateDeliveryPriceTimeout);
                    this.deliveryPriceCancelToken.cancel();
                }
            }
        },

        getDeliveryPrice() {
            if (this.deliveryPriceCancelToken) {
                this.deliveryPriceCancelToken.cancel();
                clearTimeout(this.updateDeliveryPriceTimeout);
            }
            this.deliveryPriceCancelToken = HTTP.CancelToken.source();

            if (this.points.length) {
                this.updateDeliveryPriceLoading = true;
                const data = {
                    points: this.points,
                };

                this.$api.delivery.yandex.getTariffsPrice({ data, cancelToken: this.deliveryPriceCancelToken.token })
                    .then((response) => {
                        this.expressTariffData = response.find(item => item.name === 'express');
                        this.expressTariffData.price = this.expressTariffData.options[0].price;

                        let cargoTariffData = response.find(item => item.name === 'cargo');
                        if (cargoTariffData.options.length > 1) {
                            cargoTariffData.priceMin = cargoTariffData.options[0].price;
                            cargoTariffData.priceMax = cargoTariffData.options[cargoTariffData.options.length - 1].price;
                            let cargoTypes = cargoTariffData.options.reduce((acc, item) => {
                                if (acc.indexOf(item.cargo_type) === -1) acc.push(item.cargo_type);
                                return acc;
                            }, []);

                            if (cargoTypes.length > 1) {
                                cargoTariffData.options.map(item => {
                                    let cargoLoadersText;
                                    switch (item.cargo_loaders) {
                                    case undefined:
                                        cargoLoadersText = 'без грузчиков';
                                        break;
                                    case 1:
                                        cargoLoadersText = 'один грузчик';
                                        break;
                                    case 2:
                                        cargoLoadersText = 'два грузчика';
                                        break;
                                    case 3:
                                        cargoLoadersText = 'три грузчика';
                                        break;
                                    default:
                                        cargoLoadersText = '';
                                    }
                                    return item.labelText = item.cargo_type_title + ', ' + cargoLoadersText;
                                });
                            }
                            else {
                                cargoTariffData.options.map(item => {
                                    let cargoLoadersText;
                                    switch (item.cargo_loaders) {
                                    case undefined:
                                        cargoLoadersText = 'Без грузчиков';
                                        break;
                                    case 1:
                                        cargoLoadersText = 'Помощь одного грузчика';
                                        break;
                                    case 2:
                                        cargoLoadersText = 'Помощь двух грузчиков';
                                        break;
                                    case 3:
                                        cargoLoadersText = 'Помощь трёх грузчиков';
                                        break;
                                    default:
                                        cargoLoadersText = '';
                                    }
                                    return item.labelText = cargoLoadersText;
                                });
                            }
                        }
                        else {
                            cargoTariffData.price = cargoTariffData.options[0].price;
                        }
                        this.cargoTariffData = cargoTariffData;
                        this.getDeliveryPriceSuccess = true;
                    })
                    .catch(error => {
                        this.getDeliveryPriceSuccess = false;
                        this.tariffData = [];
                        const code = getErrorCode(error);
                        const data = error.response.data;

                        if (code === 400) {
                            const { non_field_errors } = data;
                            if (non_field_errors) {
                                this.$error(non_field_errors[0].message);
                            }
                            else {
                                this.$error('Не удалось рассчитать стоимость доставки.');
                            }
                        }
                        else if (code === 404) {
                            this.$error('Запрашиваемый адрес не существует.');
                        }
                        else if (code === 500) {
                            this.$error(data.detail);
                        }
                    })
                    .finally(() => {
                        this.getDeliveryPriceLoading = false;
                        this.updateDeliveryPriceLoading = false;
                        this.updateDeliveryPriceTimeout = setTimeout(() => {
                            this.getDeliveryPrice();
                        }, YANDEX_DELIVERY_PRICE_POLLING_TIMEOUT);
                    });
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.points {
    display: flex;
}

.points__indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 88px;
}

.points__indicator::after {
    content: "";
    order: 2;
    flex-grow: 1;
    margin-top: 8px;
    margin-bottom: 8px;
    border-left: 2px dashed var(--font-secondary-dark-color);
}

.point {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border: 4px solid;
    border-radius: 50%;
}
.point_a {
    order: 1;
    margin-top: 2px;
    border-color: var(--font-secondary-dark-color);
}
.point_b {
    order: 3;
    border-color: var(--primary-color);
}

.points__info {
    flex-grow: 1;
}
@media (min-width: 768px) {
    .points__info {
        margin-left: 20px;
    }
}
@media (max-width: 767px) {
    .points__info {
        margin-left: 16px;
    }
}

.map {
    height: 280px;
    margin-top: 20px;
    margin-left: -32px;
}
@media (min-width: 768px) {
    .map {
        width: 580px;
    }
}
@media (max-width: 767px) {
    .map {
        width: calc(100% + 32px);
    }
}

@media (min-width: 768px) {
    .tariff {
        display: flex;
        align-items: flex-end;
        height: 20px;
    }

    .tariff::after {
        content: '';
        order: 2;
        flex-grow: 1;
        border-bottom: 1px dotted var(--border-dark-c);
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 4px;
    }
}

.tariff:not(:last-child) {
    margin-bottom: 16px;
}

.tariff__name-wrap {
    order: 1;
    flex-shrink: 0;
}

.tariff__name {
    font-family: var(--f-bold);
}

@media (min-width: 768px) {
    .tariff__price-wrap {
        flex-shrink: 0;
        order: 3;
        font-family: var(--f-bold);
    }
}
@media (max-width: 767px) {
    .tariff__price-wrap {
        margin-top: 4px;
    }
}

@media (min-width: 768px) {
    .tariff__price-tooltip {
        margin-right: 8px;
    }
}
@media (max-width: 767px) {
    .tariff__price-tooltip {
        margin-left: 8px;
        order: 1;
    }
}

.tariff-option {
    font-size: var(--small-fz);
    font-family: var(--f-semi-bold);
}

.tariff-option:not(:last-child) {
    margin-bottom: 8px;
}

.tariff-option__key,
.tariff-option__value {
    display: block;
}
</style>