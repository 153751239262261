<template>
    <AccountLayout title="Гараж">
        <template #btn>
            <ButtonBlock
                v-if="cars.length > 0"
                primary
                w100
                low
                @click="showSelectCarPopup"
            >
                Добавить автомобиль
            </ButtonBlock>
        </template>

        <section>
            <div
                v-if="!initialized"
                class="spinner-wrap"
            >
                <Spinner
                    center
                    size="big"
                ></Spinner>
            </div>

            <div
                v-else
                class="profile__main"
            >
                <EmptyBlock
                    v-if="!cars.length"
                    icon="car"
                    title="В вашем гараже пусто"
                    text="Добавьте автомобиль в гараж, чтобы быстрее подбирать для него подходящие запчасти и автотовары."
                >
                    <template #button>
                        <ButtonBlock
                            primary
                            low
                            class="account-garage__empty-btn"
                            @click="showSelectCarPopup"
                        >
                            Добавить автомобиль
                        </ButtonBlock>
                    </template>
                </EmptyBlock>

                <div v-if="cars.length">
                    <ol>
                        <li
                            v-for="(item, index) in cars"
                            :key="'car-' + item.uuid"
                            class="car-item layer-1"
                        >
                            <div class="car-item__head">
                                <div class="car">
                                    <CarManufacturerLogo
                                        v-if="item.car.manufacturer"
                                        :manufacturer="item.car.manufacturer"
                                        size="big"
                                        mono
                                        class="car__logo-wrap"
                                    ></CarManufacturerLogo>

                                    <div class="car__info">
                                        <CarName
                                            :car="item.car"
                                            noManufacturerName
                                            class="car__name"
                                        ></CarName>

                                        <CarCharacteristicsWithTooltip
                                            :car="item.car"
                                            class="car__description"
                                        ></CarCharacteristicsWithTooltip>
                                        <div
                                            v-if="item.grz || item.vin_frame"
                                            class="mt-4 text_secondary"
                                        >
                                            <template v-if="item.grz">
                                                Гос. номер: {{ item.grz |carNumber }}
                                            </template>
                                            <template v-else-if="item.vin_frame">
                                                VIN/Frame: {{ item.vin_frame }}
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <div class="car-item__actions">
                                    <UTooltip>
                                        <template #trigger="{ attrs, handlers }">
                                            <UIconButton
                                                square
                                                name="delete"
                                                aria-label="Удалить автомобиль из гаража"
                                                v-bind="attrs"
                                                v-on="handlers"
                                                @click="deleteCar(item, index)"
                                            ></UIconButton>
                                        </template>

                                        Удалить автомобиль из гаража
                                    </UTooltip>
                                </div>
                            </div>

                            <div class="car-offers-promo text_secondary-dark">
                                <p>
                                    Найти запчасти
                                    <span class="car-offers-promo__links-wrap">
                                        <ButtonText
                                            underline
                                            secondary
                                            dark
                                            @click="selectCarForSearch(item.car)"
                                        >
                                            по названию или коду
                                        </ButtonText>
                                        /
                                        <ButtonText
                                            :to="{
                                                name: 'market-parts-catalogs-manufacturer-modification',
                                                params: {
                                                    manufacturer: item.car.manufacturer.codename,
                                                    modification: item.car.id,
                                                },
                                                query: Object.assign({}, getCarProductionDate(item.car) ? {
                                                    production_date: getCarProductionDate(item.car)
                                                } : {})
                                            }"
                                            underline
                                            secondary
                                            dark
                                        >
                                            по схемам
                                        </ButtonText>
                                    </span>
                                </p>

                                <ButtonText
                                    v-if="item.grz && item.policy_number"
                                    :to="{
                                        name: 'account-insurance'
                                    }"
                                    secondary
                                    dark
                                    underline
                                    class="car-offers-promo__action"
                                >
                                    Полис ОСАГО № {{ item.policy_number }}
                                </ButtonText>

                                <ButtonText
                                    v-else-if="item.grz"
                                    secondary
                                    dark
                                    underline
                                    :loading="createOsagoLoading[item.grz]"
                                    class="car-offers-promo__action"
                                    @click="createOsago(item.grz)"
                                >
                                    Оформить полис ОСАГО
                                </ButtonText>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    </AccountLayout>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { serializeContract } from '@/store/modules/insuranceOsago.js';
import CarManufacturerLogo from '@/components/CarManufacturerLogo.vue';
import CarName from '@/components/CarName.vue';
import CarCharacteristicsWithTooltip from '@/components/CarCharacteristicsWithTooltip.vue';
import Spinner from '@/components/Spinner.vue';
import AccountLayout from '@/pages/account/AccountLayout.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
const SelectCarPopup = () => import('@/components/popups/SelectCarPopup.vue');
const DeleteCarConfirmPopup = () => import('@/pages/account/DeleteCarConfirmPopup.vue');

export default {
    name: 'ProfileGarage',

    metaInfo() {
        return {
            title: 'ЛК / Гараж',
        };
    },

    components: {
        UIconButton,
        UTooltip,
        EmptyBlock,
        ButtonText,
        ButtonBlock,
        AccountLayout,
        Spinner,
        CarName,
        CarCharacteristicsWithTooltip,
        CarManufacturerLogo,
    },

    serverPrefetch() {
        return this.INIT();
    },

    data() {
        return {
            createOsagoLoading: {},
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.profileCars.initialized,
            cars: state => state.profileCars.cars,
        }),
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
    },

    methods: {
        ...mapMutations({
            DESTROY: 'profileCars/destroy',
            updateContract: 'insuranceOsago/updateContract',
            setInitialized: 'insuranceOsago/setInitialized',
        }),

        ...mapActions({
            INIT: 'profileCars/init',
            REMOVE_CAR: 'profileCars/removeCar',
        }),

        async init() {
            this.$breadcrumbs([]);

            if (!this.initialized) await this.INIT();
            this.createOsagoLoading = this.cars.reduce((acc, car) => {
                if (car.grz) acc[car.grz] = false;
                return acc;
            }, {});
        },

        showSelectCarPopup() {
            const props = {
                modeOptions: [
                    {
                        label: 'По гос. номеру',
                        value: 'grz',
                    },
                    {
                        label: 'VIN/Frame',
                        value: 'vin_frame',
                    },
                    // {
                    //     label: 'По модели',
                    //     value: 'catalog',
                    // },
                ],
                outerAddGarageMode: true,
            };
            const options = { props };
            this.$popup(SelectCarPopup, options);
        },

        async deleteCar(item, index) {
            const props = {
                car: item.car,
                uuid: item.uuid,
                index,
            };
            const options = { props };
            this.$popup(DeleteCarConfirmPopup, options);
        },

        selectCarForSearch(car) {
            this.$store.commit('searchWidget/setCar', car);
            this.$router.push({ name: 'market' });
        },

        async createOsago(vehicle_license_plate) {
            this.createOsagoLoading[vehicle_license_plate] = true;

            try {
                const { contract: uuid } = await this.$api.insuranceContract.new();

                const requests = [this.$api.insuranceContract.get(uuid)];

                const params = {
                    type: 'number',
                    number: vehicle_license_plate,
                };

                requests.push(this.$api.insuranceVehicle.getInfo({ params }));

                const [contract, vehicle] = await Promise.all(requests);
                const patchData = {};
                contract.vehicle_license_plate = vehicle_license_plate;
                patchData.vehicle_license_plate = vehicle_license_plate;

                if (vehicle && Object.keys(vehicle).length) {
                    const {
                        model,
                        manufacturer,
                        power,
                        year,
                        vin,
                        body_number,
                        chassis_number,
                        car_document,
                        sts_receive,
                    } = vehicle;

                    if (year) {
                        patchData.vehicle_year = year;
                    }

                    if (power) {
                        patchData.vehicle_engine_power = power;
                    }

                    if (model) {
                        patchData.vehicle_model = model;
                    }

                    if (manufacturer) {
                        patchData.vehicle_manufacturer = manufacturer;
                    }

                    if (vin) {
                        patchData.vehicle_id_type_code = 'vin';
                        patchData.vehicle_id_number = vin;
                    }

                    if (body_number) {
                        patchData.vehicle_id_type_code = 'bodyNumber';
                        patchData.vehicle_id_number = body_number;
                    }

                    if (chassis_number) {
                        patchData.vehicle_id_type_code = 'chassisNumber';
                        patchData.vehicle_id_number = chassis_number;
                    }

                    if (car_document) {
                        patchData.vehicle_doc_type_code = car_document.type;
                        patchData.vehicle_doc_number = car_document.number;
                    }

                    if (sts_receive) {
                        patchData.vehicle_doc_date = sts_receive;
                    }
                }

                if (Object.keys(patchData).length) {
                    Object.assign(contract, patchData);

                    await this.$api.insuranceContract.change(uuid, patchData);
                }

                const serializedContract = serializeContract(contract);

                this.updateContract(serializedContract);
                this.setInitialized();

                const location = {
                    name: 'insurance-osago-request-step-1',
                    params: {
                        uuid,
                    },
                };

                await this.$router.push(location);
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.createOsagoLoading[vehicle_license_plate] = false;
            }
        },

        getCarProductionDate(car) {
            if (car.prod_date) {
                let str = car.prod_date.year;
                if (car.prod_date.month) {
                    str += '-';
                    str += car.prod_date.month.toString().padStart(2, '0');

                    if (car.prod_date.day) {
                        str += '-';
                        str += car.prod_date.day.toString().padStart(2, '0');
                    }
                }
                return str;
            }
            else {
                return '';
            }
        },
    },
};
</script>

<style scoped src="../../assets/css/profile.css"></style>
<style scoped>
.spinner-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

.car-item:not(:last-child) {
    margin-bottom: var(--y-grid-gap);
}

.car-item__head {
    position: relative;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .car-item__head {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .car-item__head {
        padding: 24px;
    }
}
@media (min-width: 768px) {
    .car-item__head {
        display: flex;
        align-items: flex-start;
    }
}
@media (max-width: 767px) {
    .car-item__head {
        padding: 16px;
    }
}

.car {
    flex-grow: 1;
}
@media (min-width: 768px) {
    .car {
        display: flex;
    }
}

@media (min-width: 768px) {
    .car__logo-wrap {
        flex-shrink: 0;
        margin-right: 16px;
    }
}
@media (max-width: 767px) {
    .car__logo-wrap {
        margin-bottom: 12px;
    }
}

.car__name {
    margin-bottom: 4px;
}
@media (min-width: 768px) {
    .car__name {
        margin-top: 4px;
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    .car__name {
        font-size: 16px;
    }
}

@media (min-width: 768px) {
    .car-item__actions {
        margin-left: 36px;
        flex-shrink: 0;
    }
}
@media (max-width: 767px) {
    .car-item__actions {
        position: absolute;
        top: 16px;
        right: 16px;
    }
}

@media (min-width: 1040px) {
    .car-offers-promo {
        display: flex;
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .car-offers-promo {
        display: flex;
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .car-offers-promo {
        padding: 16px;
    }
}

@media (min-width: 1281px) {
    .car-offers-promo__action {
        margin-left: 36px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .car-offers-promo__action {
        margin-left: 24px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .car-offers-promo__action {
        margin-left: 36px;
    }
}
@media (max-width: 767px) {
    .car-offers-promo__action {
        display: inline-block;
        margin-top: 16px;
    }
}

.car-offers-promo__links-wrap {
    display: inline-block;
}
</style>