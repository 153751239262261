<template>
    <div class="market-items-list-with-offers">
        <template v-if="totalAmount">
            <MarketItemListUpper
                :checkedFilters="checkedFilters"
                @resetFilters="onResetFilters"
                @deleteFilter="onDeleteFilter"
            >
                <template #sort>
                    <UMenu
                        class="sort"
                        :shift="-16"
                    >
                        <template #trigger="{ handlers, attrs, value }">
                            <button
                                type="button"
                                class="sort__pseudo-input"
                                v-bind="attrs"
                                v-on="handlers"
                            >
                                <span
                                    :key="sortData.value"
                                    class="sort__value"
                                >{{ sortData.name }}</span>
                                <UIcon
                                    name="arrow-down"
                                    tiny
                                    class="sort__arrow-icon"
                                    :class="{ opened: value }"
                                ></UIcon>
                            </button>
                        </template>

                        <UMenuItem
                            v-for="sortOption in sortOptions"
                            :key="'sort-option-' + sortOption.value"
                            :selected="sortData.value === sortOption.value"
                            @click="onChangeSort(sortOption)"
                        >
                            {{ sortOption.name }}
                        </UMenuItem>
                    </UMenu>
                </template>

                <template #view>
                    <RadioButtonsGroup
                        v-model="view"
                        name="catalog-view"
                        type="icon"
                        :options="[
                            {
                                label: '',
                                value: 'list',
                                icon: 'list-toggler',
                            },
                            {
                                label: '',
                                value: 'grid',
                                icon: 'grid',
                            },
                        ]"
                    ></RadioButtonsGroup>
                </template>

                <template #filterToggler>
                    <div class="filter-toggler-btn">
                        <span
                            v-show="checkedFilters.length"
                            class="filter-toggler-btn__counter"
                        >{{ checkedFilters.length }}</span>

                        <ButtonIcon
                            :icon="{
                                name: 'filter-btn',
                            }"
                            hoverPrimary
                            @click="openFilter"
                        ></ButtonIcon>
                    </div>
                </template>
            </MarketItemListUpper>

            <slot name="title"></slot>

            <MarketItemListContent>
                <template #main>
                    <div
                        v-show="loading"
                        class="spinner-wrap"
                    >
                        <Spinner
                            size="big"
                            center
                            page
                        ></Spinner>
                    </div>

                    <ol
                        v-if="items.length"
                        class="items-container"
                        :class="[
                            'items-' + view,
                            loading && 'items-container_loading',
                        ]"
                    >
                        <li
                            v-for="(item, itemIndex) in items"
                            :key="'item-' + (item.product_id || item.part_product_id)"
                            class="item"
                            :class="{
                                'item_view-list': view === 'list',
                                'item_view-grid-list': view === 'grid',
                            }"
                        >
                            <slot
                                v-if="view === 'list'"
                                name="listItem"
                                v-bind="{ item, itemIndex }"
                            >
                                <MarketItemRowListItem
                                    :item="item"
                                    :modelName="modelName"
                                    :reviewsInPopup="reviewsInPopup"
                                    @changeFavorite="onChangeFavorite(itemIndex, ...arguments)"
                                    @callShowOffersPopup="showOffersPopup(item, modelName, itemIndex)"
                                ></MarketItemRowListItem>
                            </slot>

                            <slot
                                v-if="view === 'grid'"
                                name="gridItem"
                                v-bind="{ item, itemIndex }"
                            >
                                <MarketItemColumn
                                    :item="item"
                                    :apiName="apiName"
                                    :modelName="modelName"
                                    :reviewsInPopup="reviewsInPopup"
                                    @changeFavorite="onChangeFavorite(itemIndex, ...arguments)"
                                    @callShowOffersPopup="showOffersPopup(item, modelName, itemIndex)"
                                ></MarketItemColumn>
                            </slot>
                        </li>
                    </ol>

                    <ButtonText
                        v-if="hasNext && amount"
                        class="load-more-btn"
                        dashed
                        dark
                        secondary
                        @click="load"
                    >
                        Показать ещё
                    </ButtonText>

                    <!-- Нет активных предложений если фильтр в положении по умолчанию -->
                    <slot
                        v-if="checkedFilters.length === 0 && !amount && !loading"
                        name="inStockEmpty"
                    >
                        <EmptyBlock
                            icon="empty-box"
                            :title="`Нет ${ modelName === 'product' ? 'автотоваров' : modelName === 'part_product' ? 'новых запчастей' : 'контрактных запчастей' }`"
                            text="Нет информации об актуальных предложениях магазина."
                            btnText="Перейти в каталог"
                            :btnUrl="{
                                name: 'market-products-catalog',
                            }"
                        ></EmptyBlock>
                    </slot>

                    <!-- Нет предложений с учётом фильтра -->
                    <EmptyBlock
                        v-if="checkedFilters.length && !amount && !loading"
                        icon="filter"
                        title="Нет подходящих предложений"
                        text="Попробуйте изменить условия поиска."
                        :hasBtn="false"
                        hasResetFilters
                        @resetFilters="onResetFilters"
                    ></EmptyBlock>
                </template>

                <template #aside>
                    <FilterComponent
                        :value="filterData"
                        :filters="[...staticFilters, ...dynamicFilters]"
                        :checkedFilters="checkedFilters"
                        :amount="amount"
                        :loading="loading"
                        @change="onChangeFilter"
                        @deleteFilter="onDeleteFilter"
                        @reset="onResetFilters"
                        @reload="onReload"
                    ></FilterComponent>
                </template>
            </MarketItemListContent>
        </template>

        <!-- Предложений вообще нет -->
        <slot
            v-else
            name="totalEmpty"
        ></slot>
    </div>
</template>

<script>
import bus from '@/bus.js';
import FilterComponent from './FilterComponent.vue';
import RadioButtonsGroup from './RadioButtonsGroup.vue';
import MarketItemRowListItem from './MarketItemRowListItem.vue';
import MarketItemColumn from './MarketItemColumn.vue';
import EmptyBlock from './EmptyBlock.vue';
import MarketItemListUpper from './MarketItemListUpper.vue';
import MarketItemListContent from './MarketItemListContent.vue';
import Spinner from './Spinner.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import UMenu from '@ui/components/UMenu/UMenu.vue';
import UMenuItem from '@ui/components/UMenu/UMenuItem.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
const OffersPopup = () => import('@/components/popups/OffersPopup.vue');

export default {
    name: 'MarketItemsListWithOffers',

    components: {
        UIcon,
        UMenuItem,
        UMenu,
        ButtonIcon,
        ButtonText,
        Spinner,
        MarketItemListUpper,
        FilterComponent,
        RadioButtonsGroup,
        MarketItemRowListItem,
        MarketItemColumn,
        EmptyBlock,
        MarketItemListContent,
    },

    props: {
        apiName: {
            type: String,
            default: 'products',
            validator(value) {
                return ['parts', 'products', 'contract_part'].includes(value);
            },
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },

        totalAmount: {
            type: Number,
            required: true,
        },

        items: {
            type: Array,
            required: true,
        },

        amount: {
            type: Number,
            required: true,
        },

        filterData: {
            type: Object,
            default() {
                return {};
            },
        },

        staticFilters: {
            type: Array,
            default() {
                return [];
            },
        },

        dynamicFilters: {
            type: Array,
            default() {
                return [];
            },
        },

        checkedFilters: {
            type: Array,
            default() {
                return [];
            },
        },

        sortOptions: {
            type: Array,
            default() {
                return [];
            },
        },

        sortData: {
            type: Object,
            default() {
                return {};
            },
        },

        hasNext: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        reviewsInPopup: Boolean,
    },

    data() {
        return {
            view: 'list',
        };
    },

    methods: {
        onChangeFilter(payload) {
            this.$emit('changeFilter', payload);
        },

        onResetFilters() {
            this.$emit('resetFilters');
        },

        onDeleteFilter(filter) {
            this.$emit('deleteFilter', filter);
        },

        onReload() {
            this.$emit('reload');
        },

        load() {
            this.$emit('load');
        },

        onChangeSort(value) {
            this.$emit('changeSort', value);
        },

        onChangeFavorite(index, value) {
            this.$emit('changeFavorite', { index, value });
        },

        showOffersPopup(item, type, itemIndex) {
            const props = {
                item: item,
                itemIndex: itemIndex,
                modelName: type,
                changeFavoriteCallback: (value) => this.onChangeFavorite(itemIndex, value),
            };
            const options = { props };
            this.$popup(OffersPopup, options);
        },

        openFilter() {
            bus.$emit('openFilter');
        },
    },
};
</script>

<style scoped>
.sort {
    position: relative;
    font-size: var(--base-fz);
}

.sort__pseudo-input {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: left;
    color: var(--dark-c);
}

.sort__pseudo-input:hover,
.sort__pseudo-input:focus {
    color: var(--primary-color);
    transition: color var(--transition);
}

.sort__arrow-icon {
    margin-left: 8px;
    fill: currentColor;
    transition: fill var(--transition), transform var(--transition);
}

.sort__arrow-icon.opened {
    transform: rotate(180deg);
}

.spinner-wrap {
    position: sticky;
    top: 0;
    height: 0;
    z-index: 1;
    padding-top: 100px;
    padding-bottom: 100px;
}

.items-container {
    opacity: 1;
    transition: opacity .1s ease-out;
}

.items-container_loading {
    margin-top: -200px;
    opacity: .3;
    transition: opacity .2s ease-in;
}

.items-list .item:not(:last-child) {
    margin-bottom: var(--grid-gap);
}

.items-grid {
    width: 100%;
}
@media (min-width: 1281px) {
    .items-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .items-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .items-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) {
    .items-grid {
        display: grid;
        gap: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .items-grid .item:not(:last-child) {
        margin-bottom: var(--grid-gap);
    }
}
</style>