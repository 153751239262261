export default {
    namespaced: true,

    state: () => ({
        items: [],
        current_count: 0,
        loading: false,
        initialized: false,
    }),

    mutations: {
        setItems(state, { results, current_count }) {
            state.items = results;
            state.current_count = current_count;
        },

        addItems(state, { results, current_count }) {
            state.items = [...state.items, ...results];
            state.current_count = current_count;
        },

        setLoading(state, value) {
            state.loading = value;
        },

        setInitialized(state) {
            setTimeout(() => {
                state.initialized = true;
            }, 1000);
        },

        destroy(state) {
            state.items = [];
            state.current_count = 0;
            state.loading = false;
            state.initialized = false;
        },
    },

    actions: {
        async init({ commit }, { params }) {
            try {
                const news = await this.$api.news.get({ params });

                commit('setItems', news);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { news };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getItems({ commit }, { params, cancelToken }) {
            try {
                return await this.$api.news.get({ params, cancelToken });
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};