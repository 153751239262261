<template>
    <section class="market-item-small-header layer-1">
        <MarketItemSecondaryPageHeader
            :item="product"
            modelName="product"
            class="main"
            @changeFavorite="onChangeFavorite"
        ></MarketItemSecondaryPageHeader>

        <MarketItemNavigation
            :items="nav"
        ></MarketItemNavigation>
    </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import MarketItemSecondaryPageHeader from '@/components/MarketItem/presets/MarketItemSecondaryPageHeader.vue';
import MarketItemNavigation from '@/components/MarketItemNavigation.vue';

export default {
    name: 'MarketProductSmallHeader',

    components: {
        MarketItemNavigation,
        MarketItemSecondaryPageHeader,
    },

    data() {
        return {
            isLiked: false,
        };
    },

    computed: {
        ...mapState({
            product: state => state.marketProduct.product,
        }),

        nav() {
            const nav = [
                {
                    to: {
                        name: 'market-product-description',
                        params: this.$route.params,
                    },
                    label: 'Описание',
                },
                // {
                //     to: {
                //         name: 'market-product-offers',
                //         params: this.$route.params,
                //     },
                //     label: 'Цены',
                //     amount: this.product.offers_amount,
                // },
            ];
            if (this.product.properties.length) {
                nav.push({
                    to: {
                        name: 'market-product-specs',
                        params: this.$route.params,
                    },
                    label: 'Характеристики',
                });
            }
            nav.push({
                to: {
                    name: 'market-product-reviews',
                    params: this.$route.params,
                },
                label: 'Отзывы',
                amount: this.product.reviews_amount,
            });

            return nav;
        },
    },

    methods: {
        ...mapMutations({
            CHANGE_FAVORITE: 'marketProduct/changeFavorite',
        }),

        onChangeFavorite(value) {
            this.CHANGE_FAVORITE(value);
        },
    },
};
</script>

<style scoped>
.market-item-small-header {
    margin-top: var(--gap-y-medium);
    margin-bottom: var(--gap-y-medium);
}

.main {
    position: relative;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .main {
        padding: 20px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .main {
        padding: 20px 24px;
    }
}
@media (max-width: 767px) {
    .main {
        padding: 16px;
    }
}
</style>