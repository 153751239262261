<template>
    <div class="share-block">
        <span class="share-block__title">Поделиться</span>

        <ul class="share-block__nets-list">
            <li class="share-block__item">
                <a
                    href=""
                    target="_blank"
                    rel="noopener"
                    class="share-block__link"
                >
                    <UIcon
                        name="vk"
                        class="share-block__icon share-block__icon_vk"
                    ></UIcon>
                </a>
            </li>
            <li class="share-block__item">
                <a
                    href=""
                    target="_blank"
                    rel="noopener"
                    class="share-block__link"
                >
                    <UIcon
                        name="fb"
                        class="share-block__icon share-block__icon_fb"
                    ></UIcon>
                </a>
            </li>
            <li class="share-block__item">
                <a
                    href=""
                    target="_blank"
                    rel="noopener"
                    class="share-block__link"
                >
                    <UIcon
                        name="ok"
                        class="share-block__icon share-block__icon_ok"
                    ></UIcon>
                </a>
            </li>
            <li class="share-block__item">
                <a
                    href=""
                    target="_blank"
                    rel="noopener"
                    class="share-block__link"
                >
                    <UIcon
                        name="twitter"
                        class="share-block__icon share-block__icon_twitter"
                    ></UIcon>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: "ShareBlock",

    components: {UIcon},
};
</script>

<style scoped>
@media (min-width: 768px) {
    .share-block {
        display: flex;
        align-items: center;
    }
}

.share-block__title {
    display: block;
    font-family: var(--f-bold);
    letter-spacing: 0.06em;
    text-transform: uppercase;
}
@media (min-width: 768px) {
    .share-block__title {
        margin-right: 24px;
    }
}
@media (max-width: 767px) {
    .share-block__title {
        margin-bottom: 16px;
    }
}

.share-block__nets-list {
    display: flex;
    align-items: center;
}

.share-block__item:not(:last-child) {
    margin-right: 12px;
}

.share-block__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 1px solid var(--border-dark-c);
    border-radius: 50%;
    transition: background-color var(--transition), border-color var(--transition);
}

.share-block__link:hover,
.share-block__link:focus {
    border-color: var(--dark-c);
    background-color: var(--dark-c);
}

.share-block__link:hover .share-block__icon,
.share-block__link:focus .share-block__icon {
    fill: var(--light-c);
}

.share-block__icon {
    fill: var(--dark-c);
}

.share-block__icon_vk {
    width: 18px;
    height: 10px;
    margin-top: 2px;
}

.share-block__icon_fb {
    width: 8px;
    height: 15px;
}

.share-block__icon_ok {
    width: 10px;
    height: 16px;
}

.share-block__icon_twitter {
    width: 13px;
    height: 12px;
}
</style>