<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="head">
                <BackLink
                    v-if="!checkboxesView"
                    class="hidden-xl hidden-l hidden-m mb-16"
                    @click="checkboxesView = true"
                >
                    К списку товаров
                </BackLink>

                <h2 class="h2">
                    Оформить возврат
                </h2>

                <p class="order-number">
                    Заказ №{{ order.number }}
                </p>

                <p v-if="checkboxesView">
                    Выберите товары, которые хотите вернуть:
                </p>
            </div>

            <!-- 1 step: list + checkbox -->
            <div v-if="checkboxesView">
                <div
                    v-for="(position, index) in flatPositions"
                    :key="position.__id"
                    class="position position_list"
                >
                    <div class="position__main-block">
                        <UCheckbox
                            :value="position.__checked"
                            class="position__checkbox"
                            @change="onChangeCheckbox($event, index)"
                        ></UCheckbox>

                        <MarketItemCover
                            :item="position.offer[position.offer.offer_type]"
                            :modelName="position.offer.offer_type"
                            coverCodename="micro"
                            class="position__cover"
                        ></MarketItemCover>

                        <MarketItemName
                            :id="position.offer[position.offer.offer_type].id"
                            :modelName="position.offer.offer_type"
                            :name="position.offer[position.offer.offer_type].name"
                            class="position__name"
                        ></MarketItemName>
                    </div>

                    <div class="position__price-block">
                        <div class="position__amount">
                            1 шт.
                        </div>

                        <div class="position__price">
                            {{ position.price | price }}
                        </div>
                    </div>
                </div>

                <div class="footer">
                    <div class="footer__description">
                        <template v-if="checkedPositions.length">
                            {{ checkedPositions.length | plural(['товар', 'товара', 'товаров']) }}
                            на сумму <b>{{ checkedPositionsPrice | price }}</b>
                        </template>
                    </div>
                    <div class="footer__actions">
                        <ButtonBlock
                            secondary
                            class="footer__button"
                            @click="close"
                        >
                            Отмена
                        </ButtonBlock>
                        <ButtonBlock
                            primary
                            :disabled="!checkedPositions.length"
                            class="footer__button"
                            @click="checkboxesView = false"
                        >
                            Продолжить
                        </ButtonBlock>
                    </div>
                </div>
            </div>

            <!-- 2 step: checked + comments -->
            <FormManager
                v-else
                ref="form"
                v-model="formData"
            >
                <FormCollection name="positions" class="form-positions">
                    <FormCollectionItem class="form-positions__item">
                        <template #default="{ index }">
                            <div class="position position_form">
                                <div class="position__main-block">
                                    <MarketItemCover
                                        :item="checkedPositions[index].offer[checkedPositions[index].offer.offer_type]"
                                        :modelName="checkedPositions[index].offer.offer_type"
                                        coverCodename="micro"
                                        class="position__cover"
                                    ></MarketItemCover>

                                    <MarketItemName
                                        :id="checkedPositions[index].offer[checkedPositions[index].offer.offer_type].id"
                                        :modelName="checkedPositions[index].offer.offer_type"
                                        :name="checkedPositions[index].offer[checkedPositions[index].offer.offer_type].name"
                                        class="position__name"
                                    ></MarketItemName>
                                </div>

                                <div class="position__price-block">
                                    <div class="position__amount">
                                        1 шт.
                                    </div>

                                    <div class="position__price">
                                        {{ checkedPositions[index].price | price }}
                                    </div>
                                </div>
                            </div>

                            <FormField
                                name="user_comment"
                                type="text"
                                label="Причина возврата"
                                validations="required"
                            ></FormField>
                        </template>
                    </FormCollectionItem>
                </FormCollection>

                <div class="phone-block form-field-gap_bottom">
                    <FormField
                        type="phone"
                        name="phone"
                        label="Телефон"
                        visibleMask
                        validations="required"
                        class="phone-block__input"
                    ></FormField>

                    <p class="text_small-fz text_small-lh text_secondary">
                        После того, как менеджер магазина проверит товар,
                        на&nbsp;этот номер телефона будет отправлено SMS от&nbsp;Ubibi для подтверждения возврата.
                    </p>
                </div>

                <div class="footer">
                    <div class="footer__description hidden-s">
                        <BackLink @click="checkboxesView = true">
                            К списку товаров
                        </BackLink>
                    </div>
                    <div class="footer__actions">
                        <ButtonBlock
                            secondary
                            class="footer__button"
                            @click="close"
                        >
                            Отмена
                        </ButtonBlock>
                        <ButtonBlock
                            primary
                            class="footer__button"
                            :loading="confirming"
                            @click="handleClickConfirm"
                        >
                            {{ order.returns.length ? 'Сохранить' : 'Оформить' }}
                        </ButtonBlock>
                    </div>
                </div>
            </FormManager>
        </div>
    </PopupWrap>
</template>

<script>
// utils
import deepClone from '@/lib/deepClone.js';
import { plural, price } from '@ui/filters/index.js';
// mixins
import popup from '@/mixins/popup.js';
// components
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import MarketItemCover from '@/components/MarketItemCover.vue';
import MarketItemName from '@/components/MarketItemName.vue';
import FormCollection from '@/components/_form/FormCollection.vue';
import FormCollectionItem from '@/components/_form/FormCollectionItem.vue';
import UCheckbox from '@ui/components/UCheckbox/UCheckbox.vue';
import BackLink from '@/components/project-buttons/BackLink.vue';


export default {
    name: 'ReturnOrderPopup',

    components: {
        BackLink,
        UCheckbox,
        FormCollectionItem,
        FormCollection,
        MarketItemName,
        MarketItemCover,
        FormField,
        FormManager,
        ButtonBlock,
        PopupWrap,
    },

    filters: {
        plural,
        price,
    },

    mixins: [popup],

    props: {
        order: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        const flatPositions = [];

        this.$options.propsData.order.positions.forEach(position => {
            const arr = new Array(position.amount)
                .fill('')
                .map((_, index) => {
                    return {
                        ...deepClone(position),
                        __checked: this.$options.propsData.order.positions.length === 1,
                        __id: position.id + '-' + index,
                    };
                });

            flatPositions.push(...arr);
        });

        return {
            flatPositions,
            checkboxesView: true,

            formData: {
                positions: [
                    // {
                    //     user_comment: '',
                    // },
                ],

                phone: '',
            },
            confirming: false,
        };
    },

    computed: {
        checkedPositions() {
            return this.flatPositions.filter(position => position.__checked);
        },

        checkedPositionsPrice() {
            return this.checkedPositions.reduce((acc, position) => acc + parseFloat(position.price), 0);
        },
    },

    created() {
        this.formData.phone = this.order.phone;
    },

    methods: {
        onChangeCheckbox({ value }, index) {
            const position = this.flatPositions[index];
            this.$set(position, '__checked', value);
            const i = this.checkedPositions.findIndex(p => p.__id === position.__id);

            // side effect
            if (value) {
                this.formData.positions.splice(i, 0, { user_comment: '' });
            }
            else {
                this.formData.positions.splice(i, 1);
            }
        },

        getData() {
            return {
                phone: this.formData.phone,
                positions: this.formData.positions.map((position, index) => {
                    return {
                        position: this.checkedPositions[index].id,
                        ...position,
                    };
                }),
            };
        },

        async handleClickConfirm() {
            const errors = await this.$refs.form.validate();

            if (errors) return;

            this.confirming = true;

            const data = this.getData();

            try {
                const response = await this.$api.orders.returnOrder(this.order.uuid, data);
                this.close();
                this.callback(response);
                this.$success('Заявка на возврат оформлена');
            }
            catch (error) {
                this.$refs.form.handle(error);
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.confirming = false;
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

@media (min-width: 768px){
    .h2 {
        margin-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .h2 {
        margin-bottom: 4px;
    }
}

@media (min-width: 768px){
    .head {
        margin-bottom: 36px;
    }
}
@media (max-width: 767px) {
    .head{
        margin-bottom: 16px;
    }
}

.order-number {
    color: var(--font-secondary-color);
    font-family: var(--f-semi-bold);
    margin-bottom: 12px;
}
@media (min-width: 768px){
    .order-number {
        font-size: var(--big-fz);
    }
}

.form-positions {
    margin-top: var(--gap-y-small);
}

.form-positions__item {
    margin-bottom: var(--gap-y-small);
}

.position.position_list {
    padding-top: 20px;
    padding-bottom: 20px;
}
.position.position_list:not(:first-child) {
    border-top: 1px solid var(--border-light-c);
}
.position.position_form {
    margin-bottom: 12px;
}
@media (min-width: 768px){
    .position {
        display: flex;
    }

    .position.position_list {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.position__main-block {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
@media (max-width: 767px) {
    .position__main-block {
        margin-bottom: 16px;
    }
}

.position__checkbox {
    flex-shrink: 0;
    margin-right: var(--gap-nano);
}
@media (min-width: 768px){
    .position__checkbox {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .position__checkbox {
        margin-right: 12px;
    }
}

@media (min-width: 768px){
    .position__cover {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .position__cover {
        margin-right: 12px;
    }
}

.position__name {
    flex-grow: 1;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}
@media (min-width: 1040px) {
    .position__name {
        margin-right: 24px;
    }
}

.position__price-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (min-width: 768px) {
    .position__price-block {
        margin-left: 20px;
    }
}

.position__amount {
    width: 106px;
    flex-shrink: 0;
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 1040px) {
    .position__amount {
        text-align: center;
    }
}

.position__price {
    flex-shrink: 0;
    font-family: var(--f-bold);
}
@media (min-width: 768px) {
    .position__price {
        width: 100px;
        margin-left: 20px;
        text-align: center;
    }
}
@media (max-width: 767px) {
    .position__price {
        margin-left: auto;
    }
}

.order-market-items-list {
    margin-top: var(--gap-y-small);
    margin-bottom: var(--gap-y-small);
}

@media (min-width: 768px) {
    .phone-block {
        display: flex;
        align-items: center;
    }

    .phone-block__input {
        width: 260px;
        flex-shrink: 0;
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .phone-block__input {
        width: 100%;
        margin-bottom: 8px;
    }
}

.footer {
    padding-top: 16px;
}
@media (min-width: 768px) {
    .footer {
        display: flex;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .footer {
        margin-top: auto;
    }
}

@media (min-width: 768px) {
    .footer__description {
        margin-right: 20px;
        flex-grow: 1;
    }
}
@media (max-width: 767px) {
    .footer__description {
        margin-bottom: 16px;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .footer__actions {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        flex-shrink: 0;
    }
}

@media (min-width: 768px) {
    .footer__button:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .footer__button {
        width: 100%;
    }

    .footer__button:not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>