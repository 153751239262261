export default store => ([
    {
        path: '/maintenance-mode',
        name: 'maintenance-mode',
        component: () => import('../pages/maintenance-mode.vue'),
    },

    // {
    //     path: '/market/request-parts-offers',
    //     name: 'market-request-parts-offers',
    //     component: () => import('../pages/market/RequestPartsOffers.vue'),
    // },
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import('../pages/Checkout.vue'),
    },
    // {
    //     path: '/account/requests/:uuid/checkout',
    //     name: 'account-request-checkout',
    //     component: () => import('../pages/Checkout.vue'),
    // },

    {
        path: '/',
        component: () => import('../layouts/DefaultLayout.vue'),
        children: [
            {
                path: '',
                name: 'main',
                component: () => import('../pages/MainPage.vue'),
            },
            {
                path: 'market',
                name: 'market',
                component: () => import('../pages/market'),
            },
            {
                path: 'market/products',
                redirect: { name: 'market-products-catalog' },
            },
            {
                path: 'market/products/catalog',
                name: 'market-products-catalog',
                component: () => import('../pages/market/products/catalog'),
            },
            {
                path: 'market/products/catalog/:slug',
                name: 'market-products-category',
                component: () => import('../pages/market/products/catalog/_slug.vue'),
            },
            {
                path: 'market/products/search/',
                name: 'market-products-search',
                component: () => import('../pages/market/products/search.vue'),
            },
            {
                path: 'market/products/p/',
                redirect: { name: 'market-products-catalog' },
            },
            {
                path: 'market/products/p/:id/:slug?', // ex.: market/products/p/1234/shina-dunlop-195-20
                alias: 'market/products/*/p/:id', // ex.: market/products/diski-i-shiny/diski/p/fsaf13124a
                component: () => import('../pages/market/products/p/_id'),
                children: [
                    {
                        path: '',
                        name: 'market-product-description',
                        alias: ['product', 'market-product'],
                        components: {
                            default: () => import('../pages/market/products/p/_id/description.vue'),
                            header: () => import('../pages/market/products/p/_id/bigHeader.vue'),
                        },
                    },
                    // {
                    //     path: 'offers',
                    //     name: 'market-product-offers',
                    //     components: {
                    //         default: () => import('../pages/market/products/p/_id/offers.vue'),
                    //         header: () => import('../pages/market/products/p/_id/smallHeader.vue'),
                    //     },
                    // },
                    {
                        path: 'specs',
                        name: 'market-product-specs',
                        components: {
                            default: () => import('../pages/market/products/p/_id/attributes.vue'),
                            header: () => import('../pages/market/products/p/_id/smallHeader.vue'),
                        },
                    },
                    {
                        path: 'reviews',
                        name: 'market-product-reviews',
                        components: {
                            default: () => import('../pages/market/products/p/_id/reviews.vue'),
                            header: () => import('../pages/market/products/p/_id/smallHeader.vue'),
                        },
                    },
                ],
            },

            {
                path: 'market/parts',
                redirect: { name: 'market-parts-catalog' },
            },
            {
                path: 'market/parts/catalog',
                name: 'market-parts-catalog',
                component: () => import('../pages/market/parts/catalog/index.vue'),
            },
            {
                path: 'market/parts/catalog/:slug',
                name: 'market-parts-catalog-category',
                component: () => import('../pages/market/parts/catalog/_slug.vue'),
            },
            {
                path: 'market/parts/catalog/:slug/:id',
                name: 'market-part-name',
                component: () => import('../pages/market/parts/catalog/_id/index.vue'),
            },
            {
                path: 'market/parts/catalogs',
                name: 'market-parts-catalogs',
                component: () => import('../pages/market/parts/catalogs'),
            },
            {
                path: 'market/parts/catalogs/:manufacturer',
                name: 'market-parts-catalogs-manufacturer',
                component: () => import('../pages/market/parts/catalogs/_manufacturer'),
            },
            {
                path: 'market/parts/catalogs/:manufacturer/:modification/:group?',
                name: 'market-parts-catalogs-manufacturer-modification',
                component: () => import('../pages/market/parts/catalogs/_manufacturer/_modification'),
            },
            {
                path: 'market/parts/n/',
                redirect: { name: 'market-parts-catalogs' },
            },
            {
                path: 'market/parts/n/:id',
                component: () => import('../pages/market/parts/n/_id'),
                children: [
                    {
                        path: '',
                        name: 'market-new-part-description',
                        alias: 'market-new-part',
                        components: {
                            default: () => import('../pages/market/parts/n/_id/description.vue'),
                            header: () => import('../pages/market/parts/n/_id/bigHeader.vue'),
                        },
                    },
                    // {
                    //     path: 'offers',
                    //     name: 'market-new-part-offers',
                    //     components: {
                    //         default: () => import('../pages/market/parts/n/_id/offers'),
                    //         header: () => import('../pages/market/parts/n/_id/smallHeader.vue'),
                    //     },
                    //     children: [
                    //         {
                    //             path: 'new',
                    //             name: 'market-new-part-offers-new-items',
                    //             component: () => import('../pages/market/parts/n/_id/offers/new.vue'),
                    //         },
                    //         // {
                    //         //     path: 'contract',
                    //         //     name: 'market-new-part-offers-contract-items',
                    //         //     component: () => import('../pages/market/parts/n/_id/offers/contract.vue'),
                    //         // },
                    //     ],
                    // },
                    {
                        path: 'applicability',
                        name: 'market-part-applicability',
                        components: {
                            default: () => import('../pages/market/parts/n/_id/applicability.vue'),
                            header: () => import('../pages/market/parts/n/_id/smallHeader.vue'),
                        },
                    },
                    {
                        path: 'reviews',
                        name: 'market-new-part-reviews',
                        components: {
                            default: () => import('../pages/market/parts/n/_id/reviews.vue'),
                            header: () => import('../pages/market/parts/n/_id/smallHeader.vue'),
                        },
                    },
                    {
                        path: 'analogs',
                        name: 'market-new-part-analogs',
                        components: {
                            default: () => import('../pages/market/parts/n/_id/analogs.vue'),
                            header: () => import('../pages/market/parts/n/_id/smallHeader.vue'),
                        },
                    },
                ],
            },
            // {
            //     path: 'market/parts/c/',
            //     redirect: { name: 'market-parts-catalogs' },
            // },
            // {
            //     path: 'market/parts/c/:id',
            //     component: () => import('../pages/market/parts/c/_id'),
            //     children: [
            //         {
            //             path: '',
            //             name: 'market-contract-part-description',
            //             alias: 'market-contract-part',
            //             components: {
            //                 default: () => import('../pages/market/parts/c/_id/description.vue'),
            //                 header: () => import('../pages/market/parts/c/_id/bigHeader.vue'),
            //             }
            //         },
            //         {
            //             path: 'applicability',
            //             name: 'contract-part-applicability',
            //             components: {
            //                 default: () => import('../pages/market/parts/c/_id/applicability.vue'),
            //                 header: () => import('../pages/market/parts/c/_id/smallHeader.vue'),
            //             }
            //         },
            //     ]
            // },
            {
                path: 'market/parts/search',
                name: 'market-parts-search',
                component: () => import('../pages/market/parts/search/index.vue'),
                // children: [
                //     {
                //         path: 'n',
                //         name: 'market-parts-search-new-items',
                //         component: () => import('../pages/market/parts/search/n.vue'),
                //     },
                //     {
                //         path: 'c',
                //         name: 'market-parts-search-contract-items',
                //         component: () => import('../pages/market/parts/search/c.vue'),
                //     }
                // ]
            },

            {
                path: 'market/shops/',
                name: 'market-shops',
                component: () => import('../pages/market/shops'),
            },
            {
                path: 'market/shops/:codename/',
                redirect: { name: 'market-shops' },
            },
            {
                path: 'market/shops/:codename/:id([0-9]+)',
                name: 'market-shop',
                component: () => import('../pages/market/shops/_id'),
                children: [
                    {
                        path: 'stock',
                        name: 'market-shop-stock',
                        component: () => import('../pages/market/shops/_id/stock/index.vue'),
                        children: [
                            {
                                path: 'parts/n',
                                name: 'market-shop-new-parts',
                                component: () => import('../pages/market/shops/_id/stock/parts/n.vue'),
                            },
                            {
                                path: 'products',
                                name: 'market-shop-products',
                                component: () => import('../pages/market/shops/_id/stock/products.vue'),
                            },
                            // {
                            //     path: 'parts/c',
                            //     name: 'market-shop-contract-parts',
                            //     component: () => import('../pages/market/shops/_id/stock/parts/c.vue'),
                            // },
                        ],
                    },
                    {
                        path: 'reviews',
                        name: 'market-shop-reviews',
                        component: () => import('../pages/market/shops/_id/reviews.vue'),
                    },
                    {
                        path: 'about',
                        name: 'market-shop-about',
                        component: () => import('../pages/market/shops/_id/about.vue'),
                    },
                ],
            },

            {
                path: 'market/brands/',
                name: 'market-brands',
                component: () => import('../pages/market/brands'),
            },
            {
                path: 'market/brands/:codename',
                name: 'market-brand',
                component: () => import('../pages/market/brands/_id'),
                children: [
                    {
                        path: 'stock',
                        name: 'market-brand-stock',
                        component: () => import('../pages/market/brands/_id/stock/index.vue'),
                        children: [
                            {
                                path: 'parts',
                                name: 'market-brand-parts',
                                component: () => import('../pages/market/brands/_id/stock/parts.vue'),
                            },
                            {
                                path: 'products',
                                name: 'market-brand-products',
                                component: () => import('../pages/market/brands/_id/stock/products.vue'),
                            },
                        ],
                    },
                    {
                        path: 'about',
                        name: 'market-brand-about',
                        component: () => import('../pages/market/brands/_id/about.vue'),
                    },
                ],
            },

            {
                path: 'market/maintenance',
                name: 'market-maintenance',
                component: () => import('../pages/market/maintenance.vue'),
            },

            {
                path: 'cart',
                name: 'cart',
                component: () => import('../pages/Cart.vue'),
            },
            {
                path: '/checkout/:uuid',
                name: 'before-payment',
                component: () => import('../pages/BeforePayment.vue'),
            },
            {
                path: 'payment-success',
                name: 'payment-success',
                component: () => import('../pages/PaymentSuccess.vue'),
            },
            {
                path: 'payment-failed',
                name: 'payment-failed',
                component: () => import('../pages/PaymentFailed.vue'),
            },

            {
                path: 'insurance',
                redirect: { name: 'insurance-osago' },
            },
            {
                path: 'insurance/osago',
                name: 'insurance-osago',
                component: () => import('../pages/insurance'),
            },
            {
                path: 'insurance/osago/c',
                redirect: { name: 'insurance-osago' },
            },
            {
                path: 'insurance/osago/c/:company',
                name: 'insurance-osago-company',
                component: () => import('../pages/insurance'),
            },
            {
                path: 'insurance/osago/:uuid([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/request',
                name: 'insurance-osago-request',
                component: () => import('../pages/insurance/osago/_uuid/request'),
                redirect: { name: 'insurance-osago-request-step-1' },
                children: [
                    {
                        path: 'step-1',
                        name: 'insurance-osago-request-step-1',
                        component: () => import('../pages/insurance/osago/_uuid/request/step-1.vue'),
                    },
                    {
                        path: 'step-2',
                        name: 'insurance-osago-request-step-2',
                        component: () => import('../pages/insurance/osago/_uuid/request/step-2.vue'),
                        meta: {
                            lastPath: '/request/step-2/',
                        },
                    },
                    {
                        path: 'step-3',
                        name: 'insurance-osago-request-step-3',
                        component: () => import('../pages/insurance/osago/_uuid/request/step-3.vue'),
                        meta: {
                            lastPath: '/request/step-3/',
                        },
                    },
                    {
                        path: 'step-4',
                        name: 'insurance-osago-request-step-4',
                        component: () => import('../pages/insurance/osago/_uuid/request/step-4.vue'),
                        meta: {
                            lastPath: '/request/step-4/',
                        },
                    },
                ],
            },
            {
                path: 'insurance/osago/:uuid([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/offers',
                name: 'insurance-osago-offers',
                component: () => import('../pages/insurance/osago/_uuid/offers'),
                meta: {
                    lastPath: '/offers/',
                },
            },
            {
                path: 'insurance/osago/:uuid([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/checkout',
                name: 'insurance-osago-checkout',
                component: () => import('../pages/insurance/osago/_uuid/checkout/index.vue'),
                meta: {
                    lastPath: '/checkout/',
                },
            },
            {
                path: 'insurance/osago/:uuid([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/edit',
                name: 'insurance-osago-edit',
                component: () => import('../pages/insurance/osago/_uuid/edit.vue'),
                meta: {
                    lastPath: '/edit/',
                },
            },
            {
                path: 'insurance/osago/:uuid([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/confirmation',
                name: 'insurance-osago-confirmation',
                component: () => import('../pages/insurance/osago/_uuid/confirmation.vue'),
            },
            {
                path: 'insurance/osago/:uuid([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/confirmation-success',
                name: 'insurance-osago-confirmation-success',
                component: () => import('../pages/insurance/osago/_uuid/confirmation-success.vue'),
            },
            {
                path: 'insurance/osago/:uuid([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/confirmation-failed',
                name: 'insurance-osago-confirmation-failed',
                component: () => import('../pages/insurance/osago/_uuid/confirmation-failed.vue'),
            },
            {
                path: 'insurance/osago/:uuid([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/payment-success',
                name: 'insurance-osago-payment-success',
                component: () => import('../pages/insurance/osago/_uuid/payment-success.vue'),
            },
            {
                path: 'insurance/osago/:uuid([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/payment-failed',
                name: 'insurance-osago-payment-failed',
                component: () => import('../pages/insurance/osago/_uuid/payment-failed.vue'),
            },

            {
                path: 'about',
                name: 'about',
                component: () => import('../pages/about/index.vue'),
            },
            {
                path: 'about',
                component: () => import('../layouts/InfoPageLayout.vue'),
                children: [
                    {
                        path: 'help-mp',
                        name: 'help-mp',
                        component: () => import('../pages/about/help'),
                    },
                    {
                        path: 'help-mp/:codename',
                        name: 'help-mp-article',
                        component: () => import('../pages/about/help/_id.vue'),
                    },
                    {
                        path: 'help-pp',
                        name: 'help-pp',
                        component: () => import('../pages/about/help'),
                    },
                    {
                        path: 'help-pp/:codename',
                        name: 'help-pp-article',
                        component: () => import('../pages/about/help/_id.vue'),
                    },
                    {
                        path: 'contacts',
                        name: 'contacts',
                        component: () => import('../pages/about/contacts.vue'),
                    },
                    {
                        path: 'news',
                        name: 'news',
                        component: () => import('../pages/about/news'),
                    },
                    {
                        path: 'news/:codename',
                        name: 'news-item',
                        component: () => import('../pages/about/news/_id.vue'),
                    },
                    {
                        path: 'publications',
                        name: 'publications',
                        component: () => import('../pages/about/publications.vue'),
                    },
                    {
                        path: 'privacy-policy',
                        name: 'privacy-policy',
                        component: () => import('../pages/DefaultStaticPage.vue'),
                    },
                    {
                        path: 'rules',
                        name: 'rules',
                        component: () => import('../pages/DefaultStaticPage.vue'),
                    },
                    {
                        path: 'payment',
                        name: 'payment',
                        component: () => import('../pages/DefaultStaticPage.vue'),
                    },
                    {
                        path: 'delivery-and-pickup',
                        name: 'delivery-and-pickup',
                        component: () => import('../pages/DefaultStaticPage.vue'),
                    },
                    {
                        path: 'guarantee',
                        name: 'guarantee',
                        component: () => import('../pages/DefaultStaticPage.vue'),
                    },
                    {
                        path: 'return-and-refund',
                        name: 'return-and-refund',
                        component: () => import('../pages/DefaultStaticPage.vue'),
                    },
                    {
                        path: 'b2b',
                        name: 'b2b',
                        component: () => import('../pages/DefaultStaticPage.vue'),
                    },
                    {
                        path: 'p/',
                        redirect: { name: 'help-mp' },
                    },
                    {
                        path: 'p/:codename',
                        name: 'additional-page',
                        component: () => import('../pages/AdditionalPage.vue'),
                    },
                ],
            },

            {
                path: 'account/garage',
                name: 'account-garage',
                component: () => import('../pages/account/garage.vue'),
            },
            {
                path: 'account/insurance',
                name: 'account-insurance',
                component: () => import('../pages/account/insurance/index.vue'),
                redirect: { name: 'account-insurance-policies' },
                children: [
                    {
                        path: 'policies',
                        name: 'account-insurance-policies',
                        component: () => import('../pages/account/insurance/policies.vue'),
                    },
                    {
                        path: 'drafts',
                        name: 'account-insurance-drafts',
                        component: () => import('../pages/account/insurance/drafts.vue'),
                    },
                ],
            },
            {
                path: 'account/favorites',
                name: 'account-favorites',
                component: () => import('../pages/account/favorites'),
                children: [
                    {
                        path: 'parts/n',
                        name: 'account-favorites-parts',
                        component: () => import('../pages/account/favorites/parts.vue'),
                    },
                    // {
                    //     path: 'parts/c',
                    //     name: 'account-favorites-contract-parts',
                    //     component: () => import('../pages/account/favorites/contract-parts.vue'),
                    // },
                    {
                        path: 'products',
                        name: 'account-favorites-products',
                        component: () => import('../pages/account/favorites/products.vue'),
                    },
                    {
                        path: 'brands',
                        name: 'account-favorites-brands',
                        component: () => import('../pages/account/favorites/brands.vue'),
                    },
                    {
                        path: 'shops',
                        name: 'account-favorites-shops',
                        component: () => import('../pages/account/favorites/shops.vue'),
                    },
                ],
            },
            {
                path: 'account/reviews',
                name: 'account-reviews',
                component: () => import('../pages/account/reviews'),
                children: [
                    {
                        path: 'parts',
                        name: 'account-reviews-parts',
                        component: () => import('../pages/account/reviews/parts.vue'),
                    },
                    {
                        path: 'products',
                        name: 'account-reviews-products',
                        component: () => import('../pages/account/reviews/products.vue'),
                    },
                    {
                        path: 'shops',
                        name: 'account-reviews-shops',
                        component: () => import('../pages/account/reviews/shops.vue'),
                    },
                ],
            },
            {
                path: 'account/orders',
                name: 'account-orders',
                component: () => import('../pages/account/orders'),
                children: [
                    {
                        path: 'active',
                        name: 'account-orders-active',
                        component: () => import('../pages/account/orders/active.vue'),
                    },
                    {
                        path: 'archive',
                        name: 'account-orders-archive',
                        component: () => import('../pages/account/orders/archive.vue'),
                    },
                ],
            },
            {
                path: 'account/orders/:uuid',
                name: 'account-order-item',
                component: () => import('../pages/account/orders/uuid.vue'),
            },
            // {
            //     path: 'account/requests',
            //     name: 'account-requests',
            //     component: () => import('../pages/account/requests'),
            //     children: [
            //         {
            //             path: 'active',
            //             name: 'account-requests-active',
            //             component: () => import('../pages/account/requests/active.vue'),
            //         },
            //         {
            //             path: 'archive',
            //             name: 'account-requests-archive',
            //             component: () => import('../pages/account/requests/archive.vue'),
            //         },
            //         {
            //             path: ':uuid',
            //             name: 'account-request-item',
            //             component: () => import('../pages/account/requests/_id.vue'),
            //         },
            //     ],
            // },
            {
                path: 'account/notifications',
                name: 'account-notifications',
                component: () => import('../pages/account/notifications/index.vue'),
            },
            {
                path: 'account/settings',
                name: 'account-settings',
                component: () => import('../pages/account/settings.vue'),
                // beforeEnter: (to, from, next) => {
                //     if (!store.state.profile.isAuthorized) {
                //         next({ name: 'signin' });
                //     }
                //     else {
                //         next();
                //     }
                // },
            },
        ],
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('../layouts/AuthLayout.vue'),
        redirect: { name: 'signup' },
        children: [
            // {
            //     path: 'signup',
            //     name: 'signup',
            //     // beforeEnter: (to, from, next) => {
            //     //     if (store.state.profile.isAuthorized) {
            //     //         next({ name: 'account-settings' });
            //     //     }
            //     //     else {
            //     //         next();
            //     //     }
            //     // },
            //     component: () => import('../pages/RegistrationPage.vue'),
            // },
            {
                path: 'signin',
                name: 'signin',
                // beforeEnter: (to, from, next) => {
                //     if (store.state.profile.isAuthorized) {
                //         next({ name: 'account-settings' });
                //     }
                //     else {
                //         next();
                //     }
                // },
                component: () => import('../pages/LoginPage.vue'),
            },
            // {
            //     path: 'password-recovery',
            //     name: 'password-recovery',
            //     // beforeEnter: (to, from, next) => {
            //     //     if (store.state.profile.isAuthorized) {
            //     //         next({ name: 'account-settings' });
            //     //     }
            //     //     else {
            //     //         next();
            //     //     }
            //     // },
            //     component: () => import('../pages/PasswordRecoveryPage.vue'),
            // },
        ],
    },

    {
        path: '/confirm-email/:code/',
        name: 'confirm-email',
        component: () => import('../pages/ConfirmEmailPage.vue'),
    },

    {
        path: '/s/:hash',
        name: 'short-link',
        component: () => import('../pages/s/index.vue'),
    },

    {
        path: '*',
        name: 'Error4xxPage',
        component: () => import('../pages/Error4xxPage.vue'),
    },
]);