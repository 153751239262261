<template>
    <div class="insurance-osago-edit">
        <h1 class="h1 h1_block insurance-osago__title">
            Оформление полиса ОСАГО
        </h1>

        <div class="summary layer-1 container_xs-external">
            <InsurerOsagoSummary
                :contract="contract"
                :error="error"
                @popup="popup"
            ></InsurerOsagoSummary>

            <div class="footer">
                <FormAgreement
                    text="Подтвердить"
                    class="agreement"
                ></FormAgreement>

                <ButtonBlock
                    class="button-primary"
                    primary
                    :disabled="hasError"
                    :loading="calculating"
                    @click="calc"
                >
                    Рассчитать стоимость
                </ButtonBlock>
            </div>
        </div>
    </div>
</template>

<script>
// store
import insuranceOsago from '@/store/modules/insuranceOsago.js';
import { mapActions, mapState } from 'vuex';
// use
import useCheckRouteMethods from '@/pages/insurance/osago/_uuid/useCheckRouteMethods.js';
// mixins
import lastPathMixin from '@/pages/insurance/osago/_uuid/lastPathMixin.js';
import validateSummaryMixin from './validateSummaryMixin.js';
// components
import InsurerOsagoSummary from './Summary.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import FormAgreement from '@/components/FormAgreement.vue';
const ChangeCarPopup = () => import('./ChangeCarPopup.vue');
const ChangeOwnerAndInsurerPopup = () => import('./ChangeOwnerPopup.vue');
const ChangePolicyPopup = () => import('./ChangePolicyPopup.vue');
const ChangeDriversPopup = () => import('@/pages/insurance/osago/_uuid/ChangeDriversPopup.vue');


const popups = {
    car: ChangeCarPopup,
    owner: ChangeOwnerAndInsurerPopup,
    insurer: ChangeOwnerAndInsurerPopup,
    policy: ChangePolicyPopup,
    contacts: ChangeOwnerAndInsurerPopup,
    drivers: ChangeDriversPopup,
};

export default {
    name: 'InsuranceOsagoEditPage',

    components: {
        FormAgreement,
        ButtonBlock,
        InsurerOsagoSummary,
    },

    mixins: [
        validateSummaryMixin,
        lastPathMixin,
    ],

    metaInfo() {
        return {
            title: 'Оформление полиса ОСАГО',
        };
    },

    serverPrefetch() {
        this.$store.registerModule('insuranceOsago', insuranceOsago);

        return this.serverPrefetch({ uuid: this.uuid });
    },

    data() {
        return {
            calculating: false,
        };
    },

    computed: {
        ...mapState({
            contract: state => state.insuranceOsago.contract,
        }),

        uuid() {
            return this.$route.params.uuid;
        },
    },

    watch: {
        uuid: {
            handler() {
                this.createBreadcrumbs();
            },

            immediate: true,
        },
    },

    beforeMount() {
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago, { preserveState: true });
        }
    },

    mounted() {
        this.init();
        this.createBreadcrumbs();
    },

    methods: {
        ...useCheckRouteMethods(),
        ...mapActions({
            serverPrefetch: 'insuranceOsago/serverPrefetch',
            browserPrefetch: 'insuranceOsago/browserPrefetch',
            calculate: 'insuranceOsago/calculate',
        }),

        async init() {
            await this.browserPrefetch({ uuid: this.uuid });

            await this.checkUuid();
        },

        createBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'insurance-osago',
                    },
                    title: 'Автострахование',
                },
                {
                    to: {
                        name: this.$route.name,
                        params: {
                            uuid: this.uuid,
                        },
                    },
                    title: 'Оформление ОСАГО',
                },
            ]);
        },

        async calc() {
            this.calculating = true;
            this.error = this.validate();

            if (this.hasError) {
                this.$error('Одно или несколько полей содержат ошибки');
                return;
            }

            try {
                await this.calculate();

                await this.$router.push({
                    name: 'insurance-osago-offers',
                    params: {
                        uuid: this.uuid,
                    },
                });
            }
            catch (error) {
                //
            }
            finally {
                this.calculating = false;
            }
        },

        popup(view) {
            const popup = popups[view];
            const options = {
                props: {
                    contract: this.contract,
                },
            };
            this.$popup(popup, options);
        },
    },
};
</script>

<style scoped>
.insurance-osago-edit {
    margin-top: var(--gap-y-medium);
}
@media (min-width: 1281px) {
    .insurance-osago-edit {
        width: calc(var(--col-8) + 72px);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .insurance-osago-edit {
        width: calc(var(--col-10) + 72px);
    }
}
@media (min-width: 1040px) {
    .insurance-osago-edit {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    .insurance-osago__title {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .summary {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .summary {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .footer {
        display: grid;
        grid-template-areas: "agreement secondary primary";
        grid-template-columns: 1fr max-content max-content;
        grid-gap: 20px;
        align-items: center;
        margin-top: 36px;
    }

    .button-primary {
        grid-area: primary;
    }
}
@media (max-width: 767px) {
    .footer {
        margin-top: 28px;
    }

    .footer > * {
        width: 100%;
    }

    .footer > :not(:last-child) {
        margin-bottom: 16px;
    }
}

.agreement {
    grid-area: agreement;
}
</style>