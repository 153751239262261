<template>
    <div>
        <Spinner
            v-if="!initialized"
            size="big"
            center
            class="top-gap_medium"
        ></Spinner>

        <template v-else>
            <Observer @intersect="intersected">
                <RouterView name="header"></RouterView>
            </Observer>

            <MarketItemStickyHeader
                ref="stickyHeader"
                :item="newPart"
                modelName="part_product"
                :show="showStickyHeader && showStickyHeaderOnTopScroll"
                :nav="nav"
            ></MarketItemStickyHeader>

            <RouterView></RouterView>

            <LastSee></LastSee>

            <SeoBlock :showMarketItemNote="$route.name === 'market-new-part-description'">
                <template
                    v-if="newPart.seo_description"
                    #seo
                >{{ newPart.seo_description }}</template>
            </SeoBlock>
        </template>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import marketNewPart from '@/store/modules/marketNewPart.js';
import marketNewPartDescription from '@/store/modules/marketNewPartDescription.js';
import marketNewPartOffers from '@/store/modules/marketNewPartOffers.js';
import Observer from '@/components/Observer.vue';
import MarketItemStickyHeader from '@/components/MarketItemStickyHeader.vue';
import LastSee from "@/components/LastSee.vue";
import SeoBlock from "@/components/SeoBlock.vue";
import Spinner from '@/components/Spinner';


export default {
    name: 'MarketNewPartPage',

    metaInfo() {
        return {
            title: this.newPart.meta_title,
            meta: [
                { name: 'description', content: this.newPart.meta_description },
                { name: 'keywords', content: this.newPart.meta_keywords },
                { property: 'og:title', content:this.newPart.meta_title },
                { property: 'og:description', content: this.newPart.meta_description },
                { property: 'og:image', content: this.newPart.og_image ? this.$links.uploads + this.newPart.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        Spinner,
        SeoBlock,
        LastSee,
        Observer,
        MarketItemStickyHeader,
    },

    serverPrefetch() {
        return this.INIT({ id: this.$route.params.id });
    },

    data() {
        return {
            showStickyHeader: false,
            showStickyHeaderOnTopScroll: false,
            lastScrollTop: 0,
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.marketNewPart.initialized,
            newPart: state => state.marketNewPart.newPart,
            analogPartsTotalCount: state => state.marketNewPart.analogs.current_count,
            newPartOffersAmount: state => state.marketNewPartDescription.newPartOffers.current_count,
            contractPartOffersAmount: state => state.marketNewPartDescription.contractPartOffers.current_count,
        }),

        nav() {
            let nav = [
                {
                    to: {
                        name: 'market-new-part-description',
                        params: {
                            id: this.$route.params.id,
                        },
                    },
                    label: 'Описание',
                },
                // {
                //     to: {
                //         name: 'market-new-part-offers-new-items',
                //         params: {
                //             id: this.$route.params.id,
                //         },
                //     },
                //     nested: ['market-new-part-offers-new-items', 'market-new-part-offers-contract-items'],
                //     label: 'Цены',
                //     amount: this.newPartOffersAmount + this.contractPartOffersAmount,
                // },
                {
                    to: {
                        name: 'market-part-applicability',
                        params: {
                            id: this.$route.params.id,
                        },
                    },
                    label: 'Применяемость',
                },
                {
                    to: {
                        name: 'market-new-part-reviews',
                        params: {
                            id: this.$route.params.id,
                        },
                    },
                    label: 'Отзывы',
                    amount: this.newPart.reviews_amount,
                },
            ];

            if (this.analogPartsTotalCount) {
                nav.splice(2, 0, {
                    to: {
                        name: 'market-new-part-analogs',
                        params: {
                            id: this.$route.params.id,
                        },
                    },
                    label: 'Аналоги',
                    amount: this.analogPartsTotalCount,
                });
            }
            return nav;
        },
    },

    watch: {
        '$route.params.id'(value, oldValue) {
            if (value !== oldValue) {
                this.DESTROY();
                this.init();
            }
        },
    },

    created() {
        this.$store.registerModule('marketNewPart', marketNewPart);
        this.$store.registerModule('marketNewPartDescription', marketNewPartDescription);
        this.$store.registerModule('marketNewPartOffers', marketNewPartOffers);
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.init();
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        this.DESTROY();
        this.$store.unregisterModule('marketNewPart');
        this.$store.unregisterModule('marketNewPartDescription');
        this.$store.unregisterModule('marketNewPartOffers');
    },

    methods: {
        ...mapActions({
            INIT: 'marketNewPart/init',
            SET_VIEWS_HISTORY: 'viewsHistory/set',
        }),

        ...mapMutations({
            DESTROY: 'marketNewPart/destroy',
        }),

        async init() {
            if (!this.initialized) await this.INIT({ id: this.$route.params.id });

            this.$breadcrumbs([
                {
                    to: {
                        name: 'market',
                    },
                    title: 'Запчасти и автотовары',
                },
                {
                    to: {
                        name: 'market-parts-catalog',
                    },
                    title: 'Автозапчасти',
                },
            ]);
            this.SET_VIEWS_HISTORY({ id: this.$route.params.id, type: 'part' });
        },

        intersected(value) {
            this.showStickyHeader = value !== 'in';
        },

        handleScroll() {
            if (window.innerWidth < 1040) {
                const minScrollDelta = 70;
                let st = window.pageYOffset;

                if (st === 0) this.showStickyHeaderOnTopScroll = false;

                if (Math.abs(this.lastScrollTop - st) <= minScrollDelta) return;

                this.showStickyHeaderOnTopScroll = st <= this.lastScrollTop;

                this.lastScrollTop = st;
            }
            else {
                this.showStickyHeaderOnTopScroll = true;
            }
        },
    },
};
</script>