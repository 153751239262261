<template>
    <component
        :is="tag"
        v-bind="attrs"
        class="company layer-1"
        @click="onClick"
    >
        <ShopLogo
            :shop="{ company }"
            noLink
            coverCodename="big"
        ></ShopLogo>

        <span class="company__name">{{ company.name }}</span>

        <span
            v-if="company.shops_amount > 1"
            class="company__branch-amount"
        >{{ branchesAmountText }}</span>
    </component>
</template>

<script>
import plural from "@/lib/plural.js";
import ShopLogo from "@/components/ShopLogo.vue";
const ShopsPopup = () => import('@/components/popups/ShopsPopup.vue');

export default {
    name: "CompanyGridItem",

    components: { ShopLogo },

    props: {
        company: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        tag() {
            if (this.company.shops_amount === 1) return 'RouterLink';
            else if (this.company.shops_amount === 0) return 'div';
            return 'button';
        },

        attrs() {
            if (this.tag === 'RouterLink') {
                return {
                    to: {
                        name: 'market-shop-about',
                        params: {
                            codename: this.company.codename,
                            id: this.company.main_shop_id,
                        },
                    },
                };
            }
            else {
                return {};
            }
        },

        branchesAmountText() {
            const n = this.company.shops_amount;
            return n + ' ' + plural(n, ['филиал', 'филиала', 'филиалов']);
        },
    },

    methods: {
        onClick() {
            if (this.tag === 'button') {
                const props = { company: this.company };
                const options = { props };
                this.$popup(ShopsPopup, options);
            }
        },
    },
};
</script>

<style scoped>
.company {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius-x2);
    transition: box-shadow var(--transition), color var(--transition);
}
@media (min-width: 768px) {
    .company {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .company {
        padding: 16px;
    }
}

.company:hover,
.company:focus {
    color: var(--primary-color);
    box-shadow: var(--base-shadow);
}

.company__name {
    display: block;
    margin-top: 12px;
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
    text-align: center;
}

.company__branch-amount {
    margin-top: 4px;
    font-size: var(--adaptive-small-fz);
}
</style>