const data = {
    orders: new Map([
        ['new', 'Новый'],
        ['wait_confirm', 'Ожидает подтверждения'],
        ['canceled_not_confirmed', {
            name: 'Отменён (не подтвержден)',
            color: 'red',
        }],
        ['partially_confirmed', {
            name: 'Подтверждён (частично)',
            color: 'orange',
        }],
        ['collecting', 'Идёт комплектация'],
        ['ready_pickup_shop', {
            name: 'Готов к выдаче',
            color: 'blue',
        }],
        ['wait_reg_delivery', {
            name: 'Ожидает оформления доставки',
            color: 'blue',
        }],
        ['wait_delivery_to_pickup_point', 'Ожидает доставки в ПВ'],
        ['wait_start_city_delivery', 'Ожидает передачи курьеру'],
        ['wait_start_country_delivery', 'Ожидает передачи в службу доставки'],
        ['delivering', 'Передан в доставку'],
        ['partially_ready_pickup_point', {
            name: 'Готов к выдаче (частично)',
            color: 'blue',
        }],
        ['ready_pickup_point', {
            name: 'Готов к выдаче',
            color: 'blue',
        }],
        ['delivery_error', {
            name: 'Доставка невозможна',
            color: 'red',
        }],
        ['partially_completed', 'Получен (частично)'],
        ['completed', {
            name: 'Получен',
            color: 'green',
        }],
        ['initiated_return', {
            name: 'Инициирован возврат',
            color: 'orange',
        }],
        ['partially_returned', {
            name: 'Возвращён (частично)',
            color: 'orange',
        }],
        ['returned', {
            name: 'Возвращён',
            color: 'red',
        }],
        ['canceled', {
            name: 'Отменён',
            color: 'red',
        }],
        ['canceled_non_payment', {
            name: 'Отменён (не оплачен)',
            color: 'red',
        }],
        ['canceled_by_client', {
            name: 'Отменён клиентом',
            color: 'red',
        }],
        ['canceled_not_pickuped', {
            name: 'Отменён (не получен)',
            color: 'red',
        }],
    ]),

    payments: new Map([
        ['new', 'Новая'],
        ['in_process', 'В обработке'],
        ['complete', {
            name: 'Подтверждена',
            color: 'green',
        }],
        ['refund_process', 'Инициирован возврат'],
        ['refunded', {
            name: 'Средства возвращены',
            color: 'orange',
        }],
        ['canceled', {
            color: 'red',
            name: 'Отменена',
        }],
    ]),

    'mailing-campaigns': new Map([
        ['new', 'Новая'],
        ['process', 'Отправляется'],
        ['wait', 'Ожидает отправки'],
        ['complete', {
            name: 'Отправлена',
            color: 'green',
        }],
    ]),

    delivery_type: new Map([
        ['pickup_point', {
            full: 'Самовывоз из пункта выдачи',
            short: 'Самовывоз из пункта выдачи',
        }],
        ['pickup_ubibi', {
            full: 'Самовывоз из пункта выдачи Ubibi',
            short: 'Самовывоз из пункта выдачи Ubibi',
        }],
        ['pickup_shop', {
            full: 'Самовывоз из магазина',
            short: 'Самовывоз из магазина',
        }],
        ['city_delivery', {
            full: 'Доставка по городу (Яндекс Go)',
            short: 'Доставка по городу',
        }],
        ['russian_delivery', {
            full: 'Доставка транспортной компанией',
            short: 'Доставка транспортной компанией',
        }],
    ]),

    payment_type: new Map([
        ['new', 'Новая'],
        ['complete', {
            name: 'Подтверждена',
            color: 'green',
        }],
        ['refund_process', {
            name: 'Оформлен возврат',
            color: 'orange',
        }],
        ['refunded', {
            name: 'Средства возвращены',
            color: 'orange',
        }],
        ['canceled', {
            color: 'red',
            name: 'Отменена',
        }],
    ]),

    data_type: new Map([
        ['text', 'Текст (параграф)'],
        ['string', 'Текст (строка)'],
        ['float', 'Число'],
        ['bool', 'Булевое значение'],
        ['one', 'Список значений'],
        ['many', 'Множество значений'],
    ]),

    usage_time: new Map([
        ['less_month', 'Меньше месяца'],
        ['several_months', 'Несколько месяцев'],
        ['near_year', 'Около года'],
        ['over_year', 'Больше года'],
    ]),

    action_type: new Map([
        ['add', 'Добавление'],
        ['change', 'Редактирование'],
        ['delete', 'Удаление'],
    ]),

    contracts: new Map([
        ['new', 'На согласовании'],
        ['wait_signing', {
            name: 'Ожидает подписи клиентом',
            color: 'blue',
        }],
        ['signed', 'Подписан клиентом'],
        ['wait_docs', {
            name: 'Заключен (ожидание оригиналов)',
            color: 'green',
        }],
        ['completed', {
            name: 'Заключен (оригиналы получены)',
            color: 'green',
        }],
        ['canceled', {
            name: 'Отменён',
            color: 'red',
        }],
        ['terminated', {
            name: 'Расторгнут',
            color: 'red',
        }],
    ]),

    'insurance-offers': new Map([
        ['new', 'Новый'],
        ['wait_confirm', 'Ожидает подтверждения'],
        ['wait_payment', {
            name: 'Ожидает оплаты',
            color: 'blue',
        }],
        ['fail_confirm', {
            name: 'Отказ в подтверждении',
            color: 'orange',
        }],
        ['completed', {
            name: 'Полис оформлен',
            color: 'green',
        }],
        ['canceled', {
            name: 'Отменён',
            color: 'red',
        }],
    ]),

    'insurance-policies': new Map([
        ['new', 'Новый'],
        ['wait_confirm', 'Ожидает подтверждения'],
        ['wait_payment', {
            name: 'Ожидает оплаты',
            color: 'blue',
        }],
        ['fail_confirm', {
            name: 'Отказ в подтверждении',
            color: 'orange',
        }],
        ['completed', {
            name: 'Полис оформлен',
            color: 'green',
        }],
        ['canceled', {
            name: 'Отменён',
            color: 'red',
        }],
    ]),

    kbm: new Map([
        ['М', 'М (2,45)'],
        ['0', '0 (2,3)'],
        ['1', '1 (1,55)'],
        ['2', '2 (1,4)'],
        ['3', '3 (1)'],
        ['4', '4 (0,95)'],
        ['5', '5 (0,9)'],
        ['6', '6 (0,85)'],
        ['7', '7 (0,8)'],
        ['8', '8 (0,75)'],
        ['9', '9 (0,7)'],
        ['10', '10 (0,65)'],
        ['11', '11 (0,6)'],
        ['12', '12 (0,55)'],
        ['13', '13 (0,5)'],
    ]),

    delivery: new Map([
        ['new', 'Новая'],
        ['estimating_failed', {
            name: 'Не удалось оценить заявку',
            color: 'orange',
        }],
        ['ready_for_approval', 'Заявка оценена и ждёт подтверждения'],
        ['performer_draft', 'Идёт поиск водителя'],
        ['performer_found', 'Водитель найден и едет в пункт выдачи'],
        ['performer_not_found', {
            name: 'Водитель не найден',
            color: 'orange',
        }],
        ['pickup_arrived', 'Водитель приехал в пункт выдачи'],
        ['ready_for_pickup_confirmation', 'Водитель ждёт код подтверждения (магазин)'],
        ['pickuped', 'Водитель забрал груз'],
        ['delivery_arrived', 'Водитель приехал к клиенту'],
        ['ready_for_delivery_confirmation', 'Водитель ждёт код подтверждения (клиент)'],
        ['delivered_finish', {
            name: 'Заказ доставлен',
            color: 'green',
        }],
        ['returning', {
            name: 'Водителю пришлось вернуть груз, едет в точку возврата',
            color: 'orange',
        }],
        ['return_arrived', {
            name: 'Водитель приехал в точку возврата',
            color: 'orange',
        }],
        ['ready_for_return_confirmation', {
            name: 'Водитель ждёт код подтверждения (возврат)',
            color: 'orange',
        }],
        ['returned_finish', {
            name: 'Водитель вернул груз',
            color: 'red',
        }],
        ['cancelled', {
            name: 'Заказ отменён клиентом (бесплатно)',
            color: 'orange',
        }],
        ['cancelled_with_payment', {
            name: 'Заказ отменён клиентом (платно)',
            color: 'red',
        }],
        ['cancelled_by_taxi', {
            name: 'Заказ был отменён водителем',
            color: 'orange',
        }],
        ['failed', {
            name: 'Ошибка',
            color: 'red',
        }],
    ]),

    taxi_class: new Map([
        ['courier', 'Курьер'],
        ['express', 'Легковой'],
        ['cargo', 'Грузовой'],
    ]),

    'auction-requests': new Map([
        ['new', 'Активное'],
        ['accept', {
            name: 'Перешло в заказ',
            color: 'green',
        }],
        ['archive', {
            name: 'Перемещено в архив',
            color: 'red',
        }],
    ]),

    'insurance-contracts': new Map([
        ['new', 'Новый'],
        ['pre_calc', 'Получен предв. расчет'],
        ['final_calc', 'Сформирован'],
        ['wait_payment', {
            name: 'Ожидает оплаты',
            color: 'blue',
        }],
        ['completed', {
            name: 'Полис оформлен',
            color: 'green',
        }],
        ['canceled', {
            name: 'Отменён',
            color: 'red',
        }],
    ]),

    order_place: new Map([
        ['new', 'Новое'],
        ['waiting_add_to_shipment', 'Ожидает добавления в отгрузку'],
        ['waiting_shipment', 'Ожидает отгрузки'],
        ['delivering', 'Доставляется'],
        ['ready_pickup_point', 'Готов к выдаче'],
        ['delivered', {
            name: 'Получено',
            color: 'green',
        }],
        ['returned', {
            name: 'Возвращено',
            color: 'yellow',
        }],
        ['canceled', {
            name: 'Отменено',
            color: 'red',
        }],
    ]),
};

const normalize = data => {
    return Object.entries(data).reduce((acc, entry) => {
        const [codename, map] = entry;

        acc[codename] = {
            obj: {},
            options: [],
        };

        map.forEach((value, key) => {
            acc[codename].obj[key] = typeof value === 'string' ? { name: value, color: 'black' } : value;
            acc[codename].options.push({
                value: key,
                name: typeof value === 'string' ? value : value.name,
            });
        });

        return acc;
    }, {});
};

export default normalize(data);