export default {
    props: {
        noDetach: Boolean,
    },

    mounted() {
        if (!this.noDetach && this.$refs.trigger && this.$refs.trigger.triggerNode) {
            this.$refs.trigger.triggerNode.forEach(node => {
                if (!node.elm) return;
                if (!this.$el.parentNode) return;

                const target = this.$el === this.$el.parentNode.firstChild
                    ? this.$el
                    : this.$el.nextSibling;

                this.$el.parentNode.insertBefore(node.elm, target);
            });
        }

        const target = document.querySelector('.app');
        target.appendChild(this.$refs.content);
    },

    beforeDestroy() {
        if (this.$refs.content) {
            this.$refs.content.parentNode.removeChild(this.$refs.content);
        }

        if (!this.noDetach && this.$refs.trigger && this.$refs.trigger.triggerNode) {
            this.$refs.trigger.triggerNode.forEach(node => {
                const parent = (node.elm || {}).parentNode;
                if (parent) parent.removeChild(node.elm);
            });
        }
    },
};