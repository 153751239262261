var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopupWrap',{on:{"clickaway":_vm.close,"close":_vm.close}},[_c('FormManager',{ref:"form",staticClass:"popup-content",on:{"submit":_vm.submit},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[_c('div',{staticClass:"review-popup__head"},[_c('h2',{staticClass:"h2 popup-title"},[_vm._v("\n                Оставить отзыв\n            ")]),_vm._v(" "),_c('p',{staticClass:"popup-subtitle"},[_vm._v("\n                "+_vm._s(_vm.shop.company.name)+", г. "+_vm._s(_vm.shop.city.name)+", "+_vm._s(_vm.shop.address)+"\n            ")])]),_vm._v(" "),_c('FormField',{staticClass:"bottom-gap_small",attrs:{"type":"rating","name":"rating","label":"Общая оценка","validations":"required","offModifyLabel":""}}),_vm._v(" "),_c('FormField',{staticClass:"bottom-gap_small",attrs:{"type":"text","name":"comment","label":"Мнение о магазине","validations":"required"}}),_vm._v(" "),_c('div',[_c('h4',{staticClass:"h4 h4_block"},[_vm._v("\n                Порекомендовали бы магазин друзьям?\n            ")]),_vm._v(" "),_c('RadioButtonsGroup',{attrs:{"name":"is_recommend","type":"text","options":[
                    {
                        label: 'Да',
                        value: 'true'
                    },
                    {
                        label: 'Нет',
                        value: 'false'
                    } ]},model:{value:(_vm.formData.is_recommend),callback:function ($$v) {_vm.$set(_vm.formData, "is_recommend", $$v)},expression:"formData.is_recommend"}})],1),_vm._v(" "),_c('div',{staticClass:"form__actions"},[_c('FormAgreement',{staticClass:"form__agreement",attrs:{"text":"Отправить"}}),_vm._v(" "),_c('ButtonBlock',{staticClass:"form__submit",attrs:{"submit":"","primary":"","loading":_vm.loading}},[_vm._v("\n                "+_vm._s(_vm.edit ? 'Сохранить' : 'Отправить')+"\n            ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }