import sortAndStringify from '../lib/sortAndStringify';


export default {
    props: {
        optionLabel: {
            type: String,
            default: 'label',
        },

        getOptionLabel: {
            type: Function,
            default(option) {
                if (option === null) {
                    return '';
                }
                else if (typeof option === 'object') {
                    return option[this.optionLabel];
                }
                else {
                    return option;
                }
            },
        },

        optionKey: {
            type: String,
            default: 'value',
        },

        getOptionKey: {
            type: Function,
            default(option) {
                if (option === null) {
                    return '';
                }
                else if (typeof option === 'object') {
                    if (option[this.optionKey] !== undefined) {
                        return option[this.optionKey];
                    }

                    if (option.id !== undefined) {
                        return option.id;
                    }

                    if (option.key !== undefined) {
                        return option.key;
                    }

                    if (option.value !== undefined) {
                        return option.value;
                    }

                    return sortAndStringify(option);
                }
                else {
                    return option;
                }
            },
        },

        isOptionSelected: {
            type: Function,
            default(option, selectedOptions) {
                return selectedOptions.some(value => this.optionComparator(value, option));
            },
        },

        optionComparator: {
            type: Function,
            default(option1, option2) {
                return this.getOptionKey(option1) === this.getOptionKey(option2)
                    && this.getOptionLabel(option1) === this.getOptionLabel(option2);
            },
        },

        reduce: {
            type: Function,
            default(option) {
                return this.getOptionKey(option);
            },
        },
    },
};