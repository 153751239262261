export default (options = { isPopup: false }) => {
    const isForm = !options.isPopup;

    return {
        'formData.unlimited_drivers_amount': {
            handler(value) {
                if (!value) {
                    if (!this.formData.drivers || !this.formData.drivers.length) {
                        this.addDriver();
                    }

                    if (isForm) {
                        this.patch('unlimited_drivers_amount');
                    }
                }
                else {
                    this.formData.drivers = [];

                    if (isForm) {
                        this.patch('unlimited_drivers_amount', 'drivers');
                    }
                }
            },
        },
    };
};