import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import { Integrations } from '@sentry/tracing';


Sentry.init({
    Vue,
    dsn: "https://40c7e6e2ddc14ee0ba26ad16d9093875@o515474.ingest.sentry.io/5620444",
    environment: process.env.SENTRY_ENV,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    // tracesSampleRate: 1.0,
});