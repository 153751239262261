export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        meta: {},
        brands: {
            current_count: 0,
        },
        companies: {
            current_count: 0,
        },
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        setBrandsCount(state, { current_count }) {
            state.brands.current_count = current_count;
        },

        setCompanies(state, { current_count }) {
            state.companies.current_count = current_count;
        },

        destroy(state) {
            state.initialized = false;
        },
    },

    actions: {
        async init({ commit, dispatch }) {
            try {
                const limit = 0;
                const offset = 0;
                const params = { limit, offset };
                const config = { params };

                const [meta, brands, companies] = await Promise.all(
                    [
                        this.$api.staticPages.get('index', config),
                        this.$api.brands.get(config),
                        this.$api.insuranceCompany.get(),
                    ],
                );

                commit('setMeta', meta);
                commit('setBrandsCount', brands);
                commit('setCompanies', companies);
                commit('clearHttpError', null, { root: true });
                commit('setInitialized');
                dispatch('promotions/get', null, { root: true });

                return { meta, brands, companies };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },
    },
};