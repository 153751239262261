<template>
    <PopupWrap
        :loading="initLoading"
        @close="close"
    >
        <div
            v-if="!initLoading"
            class="popup-content"
        >
            <FormManager
                ref="form"
                v-model="formData"
                :formTag="false"
            >
                <h2 class="h2 h2_block popup-title">
                    Оформить доставку Яндекс&nbsp;Go
                </h2>

                <div class="points">
                    <div class="points__indicator">
                        <span class="point point_a"></span>
                        <span class="point point_b"></span>
                    </div>

                    <div class="points__info">
                        <div class="flex align-items-center justify-content-between mb-20">
                            <div>
                                <ShopName
                                    :shop="order.shop"
                                    noLink
                                ></ShopName>
                                <ShopAddress
                                    :shop="order.shop"
                                    :showOnMap="false"
                                ></ShopAddress>
                            </div>
                            <ShopLogo
                                :shop="order.shop"
                                noLink
                                class="ml-20"
                            ></ShopLogo>
                        </div>

                        <div class="address-to-wrap">
                            <FormField
                                name="address_to"
                                type="geo"
                                :city="shopCity"
                                :map="false"
                                label="Куда доставить?"
                                validations="required"
                                :disabled="!canOrder"
                                @select="onAddressSelect"
                                @clear="onAddressClear"
                            ></FormField>

                            <FormField
                                name="flat_to"
                                label="Квартира (офис)"
                                class="flat-field"
                                :disabled="!canOrder"
                            ></FormField>
                        </div>

                        <Map
                            v-if="canOrder"
                            ref="desktopMap"
                            :city="shopCity"
                            :markers="markers"
                            :initialMapValue="currentCity.coordinates"
                            class="map"
                            @change="onMapChange"
                        ></Map>
                    </div>
                </div>

                <template v-if="!canOrder">
                    <InfoMessage
                        v-if="isShopClose"
                        class="mt-12"
                    >
                        Сейчас магазин закрыт, вы можете оформить доставку в другой день в рабочее время магазина.
                    </InfoMessage>
                    <InfoMessage
                        v-else-if="isShopCloseSoon"
                        class="mt-12"
                    >
                        Магазин скоро закроется, вы можете оформить доставку в другой день в рабочее время магазина.
                    </InfoMessage>

                    <h3 class="h4 h4_block mt-36">
                        Режим работы магазина:
                    </h3>
                    <ShopWorkSchedule
                        :work="shop.work_times"
                        :holidays="shop.holidays"
                    ></ShopWorkSchedule>
                </template>

                <template v-if="canOrder">
                    <div
                        v-if="getDeliveryPriceLoading"
                        class="top-gap_mini flex align-items-center"
                    >
                        <Spinner class="mr-8"></Spinner>
                        <span class="text_red text_semi-bold">Идёт расчёт</span>
                    </div>
                    <div
                        v-if="!getDeliveryPriceLoading && getDeliveryPriceSuccess"
                        key="delivery-data"
                        class="top-gap_small"
                    >
                        <div class="tariff">
                            <div class="tariff__name-wrap">
                                <span class="tariff__name">Тариф «{{ dictionary.taxi_class.obj[order.delivery_yandex_taxi_class].name }}»</span>
                                <UTooltip>
                                    <template #trigger="{ value, attrs, handlers }">
                                        <UTooltipIcon
                                            class="ml-8"
                                            :active="value"
                                            v-bind="attrs"
                                            v-on="handlers"
                                        ></UTooltipIcon>
                                    </template>

                                    Тип автомобиля зависит от веса и габаритов вашего заказа,
                                    он определяется менеджером магазина при комплектации заказа.
                                </UTooltip>
                            </div>

                            <div class="tariff__price-wrap">
                                <Spinner v-show="getDeliveryPriceLoading"></Spinner>
                                <div v-show="!getDeliveryPriceLoading">
                                    <span class="tariff__price">{{ tariffPrice | price }}</span>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="cargoLoadersOptions.length > 1"
                            class="mt-24"
                        >
                            <RadioBase
                                id="cargo_loaders-0"
                                v-model="cargo_loaders"
                                :value="0"
                                label="Без грузчиков"
                            ></RadioBase>
                            <RadioBase
                                v-for="(option, indexOption) in cargoLoadersOptions"
                                :id="'cargo_loaders-' + indexOption+1"
                                :key="'cargo_loaders-' + indexOption+1"
                                v-model="cargo_loaders"
                                :value="option.cargo_loaders"
                                :label="option.labelText"
                            ></RadioBase>
                        </div>
                        <div
                            v-if="cargoLoadersOptions.length === 1"
                            class="mt-24"
                        >
                            <CheckboxBase
                                id="needCargoLoaders"
                                v-model="cargo_loaders"
                                label="Помощь одного грузчика"
                            ></CheckboxBase>
                        </div>

                        <p
                            v-if="cargoLoadersOptions.length"
                            class="text_secondary text_small-fz mt-20"
                        >
                            При отказе от помощи грузчиков, вам нужно самостоятельно выгрузить товары из машины.
                        </p>
                    </div>

                    <div class="top-gap_small">
                        <h3 class="h4 h4_block">
                            Получатель
                        </h3>

                        <div class="receiver-data__fields">
                            <FormField
                                name="name_to"
                                label="Имя"
                                validations="required"
                                class="form-field-gap_bottom"
                            ></FormField>

                            <FormField
                                type="phone"
                                name="phone_to"
                                label="Телефон"
                                visibleMask
                                validations="required"
                                class="form-field-gap_bottom"
                            ></FormField>
                        </div>

                        <FormField
                            name="user_comment"
                            textarea
                            label="Комментарий для курьера"
                        ></FormField>
                    </div>

                    <InfoMessage class="mt-36">
                        Поиск свободной машины начнётся сразу после оплаты доставки и может занять какое-то время. Если автомобиль не будет найден в течение 30 мин, заявка будет отменена, а деньги возвращены на карту. Вы сможете оформить доставку позже.
                    </InfoMessage>

                    <p class="form-note">
                        К оплате принимаются карты VISA, MasterCard и МИР. Для совершения оплаты вы будете перенаправлены на безопасную страницу ПАО «Сбербанк». Для дополнительной аутентификации держателя карты используется защищенный протокол 3D Secure.
                    </p>

                    <div class="actions-wrap">
                        <ButtonBlock
                            secondary
                            class="action-btn"
                            @click="close"
                        >
                            Отмена
                        </ButtonBlock>
                        <ButtonBlock
                            primary
                            class="action-btn"
                            :loading="getPaymentLinkLoading"
                            :disabled="getDeliveryPriceLoading || !tariffPrice"
                            @click="getPaymentLink"
                        >
                            Оформить <template v-if="!getDeliveryPriceLoading && tariffPrice">
                                за {{ tariffPrice | price }}
                            </template>
                        </ButtonBlock>
                    </div>
                </template>
            </FormManager>
        </div>
    </PopupWrap>
</template>

<script>
import { mapState } from 'vuex';
import { HTTP } from '@/http.js';
import { YANDEX_DELIVERY_PRICE_POLLING_TIMEOUT } from '@/settings';
import getErrorCode from '@/lib/getErrorCode.js';
import popup from '@/mixins/popup.js';
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ShopName from '@/components/ShopName.vue';
import ShopAddress from '@/components/ShopAddress.vue';
import ShopLogo from '@/components/ShopLogo.vue';
import Spinner from '@/components/Spinner.vue';
import dictionary from '@/dictionary.js';
import CheckboxBase from '@/components/fields/CheckboxBase.vue';
import RadioBase from '@/components/fields/RadioBase.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import FormField from '@/components/_form/FormField.vue';
import FormManager from '@/components/_form/FormManager.vue';
import Map from '@/components/geo/Map.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ShopWorkSchedule from '@/components/ShopWorkSchedule.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


const toArray = str => str ? str.replace(' ', '').split(',').map(Number) : null;
const toString = arr => arr ? arr.join(', ') : '';

export default {
    name: 'GetYandexDeliveryPopup',

    components: {
        UTooltipIcon,
        UTooltip,
        ShopWorkSchedule,
        ButtonBlock,
        Map,
        FormManager,
        FormField,
        InfoMessage,
        RadioBase,
        CheckboxBase,
        Spinner,
        ShopLogo,
        ShopAddress,
        ShopName,
        PopupWrap,
    },

    mixins: [popup, handleFormErrorMixin],

    props: {
        order: {
            type: Object,
            default: () => {},
        },

        canOrder: Boolean,
        isShopCloseSoon: Boolean,
    },

    data() {
        return {
            dictionary,
            initLoading: true,
            shop: {},
            shopCity: {},

            formData: {
                address_to: '',
                coordinates_to: '',
                flat_to: '',
                name_to: '',
                phone_to: '',
                user_comment: '',
            },
            cargo_loaders: 0,
            tariffData: [],
            deliveryPriceCancelToken: null,
            getDeliveryPriceLoading: false,
            getDeliveryPriceSuccess: false,
            deliveryPaymentId: null,

            updateDeliveryPriceTimeout: null,
            updateDeliveryPriceLoading: false,

            getPaymentLinkLoading: false,
        };
    },

    computed: {
        ...mapState({
            currentCity: state => state.cities.currentCity,
            currentCityId: state => state.cities.currentCityId,
            profile: state => state.profile.profile,
        }),

        pointA() {
            return {
                // coords: [55.8064, 37.5337],
                coords: this.order.shop.coordinates.split(', ').map(item => Number(item)),
                type: 'dark',
            };
        },

        pointB() {
            return {
                coords: toArray(this.formData.coordinates_to),
                type: 'default',
            };
        },

        markers() {
            let markers = [this.pointA];
            if (this.pointB.coords) markers.push(this.pointB);
            return markers;
        },

        tariffPrice() {
            if (this.cargo_loaders) {
                return this.cargoLoadersOptions.find(item => item.cargo_loaders === Number(this.cargo_loaders)).price;
            }
            else if (this.tariffData.length) {
                return this.tariffData.find(item => !item.cargo_loaders).price;
            }
            else {
                return '';
            }
        },

        cargoLoadersOptions() {
            if (this.tariffData.length) {
                return this.tariffData.filter(item => item.cargo_loaders)
                    .map(item => {
                        let cargoLoadersText;
                        switch (item.cargo_loaders) {
                        case undefined:
                            cargoLoadersText = 'Без грузчиков';
                            break;
                        case 1:
                            cargoLoadersText = 'Помощь одного грузчика';
                            break;
                        case 2:
                            cargoLoadersText = 'Помощь двух грузчиков';
                            break;
                        case 3:
                            cargoLoadersText = 'Помощь трёх грузчиков';
                            break;
                        default:
                            cargoLoadersText = '';
                        }

                        return {
                            cargo_loaders: Number(item.cargo_loaders),
                            price: item.price,
                            labelText: cargoLoadersText,
                        };
                    });
            }
            else {
                return [];
            }
        },

        isShopClose() {
            return !this.order.shop.current_work.place.is_active;
        },
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        clearTimeout(this.updateDeliveryPriceTimeout);
        if (this.deliveryPriceCancelToken) this.deliveryPriceCancelToken.cancel();
    },

    methods: {
        init() {
            this.getShop();
            this.getShopCityData();

            if (this.canOrder) {
                this.formData.name_to = this.order.first_name || '';
                this.formData.phone_to = this.order.phone || '';

                // TODO: изменить на последние введенные данные
                // if (this.profile.recipient_city && this.profile.recipient_city.id === this.currentCityId) {
                //
                //     this.formData.address_to = this.profile.recipient_address || '';
                //     this.formData.coordinates_to = this.profile.recipient_coordinates || '';
                //     this.formData.flat_to = this.profile.recipient_flat || '';
                //
                //     if (this.formData.address_to && this.formData.coordinates_to) {
                //         this.getDeliveryPrice();
                //     }
                // }
            }
        },

        async getShop() {
            this.initLoading = true;
            this.shop = await this.$api.shops.get(this.order.shop.id);
            this.initLoading = false;
        },

        async getShopCityData() {
            this.shopCity = await this.$api.cities.get(this.order.shop.city.id);
        },

        onMapChange(value) {
            if (value) {
                this.formData.address_to = value.name;
                this.formData.coordinates_to = toString(value.coords);
                this.getDeliveryPrice();
            }
        },

        onAddressClear() {
            this.formData.address_to = '';
            this.formData.coordinates_to = '';
            this.getDeliveryPriceSuccess = false;
            this.tariffData = [];
            if (this.deliveryPriceCancelToken) {
                clearTimeout(this.updateDeliveryPriceTimeout);
                this.deliveryPriceCancelToken.cancel();
            }
        },

        onAddressSelect(value) {
            if (value) {
                this.formData.address_to = value.label;
                this.formData.coordinates_to = toString(value.coords.split(' ').reverse());
                this.getDeliveryPrice();
                this.$nextTick(() => {
                    this.$refs.desktopMap.setMapCenter();
                });
            }
        },

        getDeliveryPrice() {
            if (this.deliveryPriceCancelToken) {
                this.deliveryPriceCancelToken.cancel();
                clearTimeout(this.updateDeliveryPriceTimeout);
            }
            this.deliveryPriceCancelToken = HTTP.CancelToken.source();

            this.expandOnMobile = true;
            this.getDeliveryPriceLoading = true;

            const data = {
                order: this.order.uuid,
                address_to: this.formData.address_to,
                coordinates_to: toArray(this.formData.coordinates_to).reverse(),
                flat_to: this.formData.flat_to,
            };

            this.$api.delivery.yandex.getPrice({ data, cancelToken: this.deliveryPriceCancelToken.token })
                .then(({ prices }) => {
                    this.tariffData = prices;
                    this.getDeliveryPriceSuccess = true;
                })
                .catch(error => {
                    this.getDeliveryPriceSuccess = false;
                    this.tariffData = [];
                    const code = getErrorCode(error);
                    const data = error.response.data;

                    if (code === 400) {
                        const { non_field_errors, coordinates } = data;
                        if (non_field_errors) {
                            this.$error(non_field_errors[0].message);
                        }
                        else if (coordinates) {
                            this.$error(coordinates[0].message);
                        }
                        else {
                            this.$error('Не удалось оформить доставку.');
                        }
                    }
                    else if (code === 404) {
                        this.$error('Запрашиваемый адрес не существует.');
                    }
                    else if (code === 500) {
                        this.$error(data.detail);
                    }
                })
                .finally(() => {
                    this.getDeliveryPriceLoading = false;
                    this.updateDeliveryPriceLoading = false;
                    this.updateDeliveryPriceTimeout = setTimeout(() => {
                        this.getDeliveryPrice();
                    }, YANDEX_DELIVERY_PRICE_POLLING_TIMEOUT);
                });
        },

        getPaymentLink() {
            this.getPaymentLinkLoading = true;

            const data = {
                order: this.order.uuid,
                address_to: this.formData.address_to,
                coordinates_to: toArray(this.formData.coordinates_to).reverse(),
                flat_to: this.formData.flat_to,
                user_comment: this.formData.user_comment,
                phone_to: this.formData.phone_to,
                name_to: this.formData.name_to,
                cargo_loaders: Number(this.cargo_loaders),
            };

            this.$api.delivery.yandex.getPaymentLink({ data })
                .then(response => {
                    if (response && response.payment_uuid) {
                        this.$router.push({
                            name: 'before-payment',
                            params: {
                                uuid: response.payment_uuid,
                            },
                        });
                    }
                    else {
                        this.getPaymentLinkLoading = false;
                        const code = getErrorCode(response);
                        const data = response;

                        if (code === 400) {
                            const { non_field_errors } = data;
                            if (non_field_errors) {
                                this.$error(non_field_errors[0].message);
                            }
                            else {
                                this.$error('Не удалось оформить доставку.');
                            }
                        }
                        else if (code === 404) {
                            this.$error('Запрашиваемый адрес не существует.');
                        }
                        else if (code === 500) {
                            this.$error(data.detail);
                        }
                    }
                })
                .catch(error => {
                    this.getPaymentLinkLoading = false;
                    const code = getErrorCode(error);
                    const data = error.response.data;

                    if (code === 400) {
                        const { non_field_errors } = data;
                        if (non_field_errors) {
                            this.$error(non_field_errors[0].message);
                        }
                        else {
                            this.$error('Не удалось оформить доставку.');
                        }
                    }
                    else if (code === 404) {
                        this.$error('Запрашиваемый адрес не существует.');
                    }
                    else if (code === 500) {
                        this.$error(data.detail);
                    }
                });
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.points {
    display: flex;
}

.points__indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 88px;
}

.points__indicator::after {
    content: "";
    order: 2;
    flex-grow: 1;
    margin-top: 8px;
    margin-bottom: 8px;
    border-left: 2px dashed var(--font-secondary-dark-color);
}

.point {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border: 4px solid;
    border-radius: 50%;
}
.point_a {
    order: 1;
    margin-top: 2px;
    border-color: var(--font-secondary-dark-color);
}
.point_b {
    order: 3;
    border-color: var(--primary-color);
}

.points__info {
    flex-grow: 1;
}
@media (min-width: 768px) {
    .points__info {
        margin-left: 20px;
    }
}
@media (max-width: 767px) {
    .points__info {
        margin-left: 16px;
    }
}

@media (min-width: 768px) {
    .address-to-wrap {
        display: grid;
        grid-template-columns: 1fr 180px;
        grid-gap: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .flat-field {
        margin-top: 20px;
    }
}

.map {
    height: 280px;
    margin-top: 20px;
    margin-left: -32px;
}
@media (min-width: 768px) {
    .map {
        width: 580px;
    }
}
@media (max-width: 767px) {
    .map {
        width: calc(100% + 32px);
    }
}

@media (min-width: 768px) {
    .tariff {
        display: flex;
        align-items: flex-end;
        height: 20px;
    }

    .tariff::after {
        content: '';
        order: 2;
        flex-grow: 1;
        border-bottom: 1px dotted var(--border-dark-c);
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 4px;
    }
}

.tariff:not(:last-child) {
    margin-bottom: 16px;
}

.tariff__name-wrap {
    display: flex;
    align-items: center;
    order: 1;
    flex-shrink: 0;
}

.tariff__name {
    font-family: var(--f-bold);
}

@media (min-width: 768px) {
    .tariff__price-wrap {
        flex-shrink: 0;
        order: 3;
        font-family: var(--f-bold);
    }
}
@media (max-width: 767px) {
    .tariff__price-wrap {
        margin-top: 4px;
    }
}

.form-note {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    color: var(--font-secondary-color);
}

@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .action-btn {
        width: 100%;
    }
    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) {
    .receiver-data__fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--grid-gap);
    }
}
</style>