export default ({ HTTP }) => ({
    async get(config) {
        const url = '/notifications/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async setIsRead(data) {
        const url = '/notifications/set_is_read/';
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async setIsReadForAll() {
        const url = '/notifications/set_is_read_for_all/';
        const response = await HTTP.post(url);
        return response.data;
    },
});