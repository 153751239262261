<template>
    <UMenu
        ref="menu"
        showOnFocus
        :shift="false"
        :hideOnBlur="false"
        contentWidthByTrigger
        :visible="initialized"
        transition="expand-fade"
        :hideOnClickContent="false"
        v-on="$listeners"
    >
        <template #trigger="trigger">
            <slot v-bind="trigger"></slot>
        </template>

        <li
            v-if="!options.length && hasEmpty"
            class="u-dropdown__option-empty"
        >
            Ничего не найдено
        </li>

        <template v-else>
            <UMenuItem
                v-for="option in options"
                :key="getOptionKey(option)"
                :selected="isOptionSelected(option, selectedOptions)"
                @click="select(option)"
            >
                <slot
                    name="label"
                    v-bind="{ option }"
                >
                    {{ getOptionLabel(option) }}
                </slot>
            </UMenuItem>

            <UMenuItem
                v-if="hasMore"
                class="u-dropdown__option-more"
            >
                <template #content>
                    <ButtonText
                        dark
                        dashed
                        secondary
                        tabindex="-1"
                        :loading="loadingMore"
                        @click="emitMore"
                    >
                        Показать ещё
                    </ButtonText>
                </template>
            </UMenuItem>
        </template>
    </UMenu>
</template>

<script>
// mixins
import options from '@ui/mixins/options.js';
// components
import ButtonText from '@ui/components/UButton/UButtonText.vue';
import UMenuItem from '@ui/components/UMenu/UMenuItem.vue';
import UMenu from '@ui/components/UMenu/UMenu.vue';


export default {
    name: 'UDropdown',

    components: {
        UMenu,
        UMenuItem,
        ButtonText,
    },

    mixins: [options],

    props: {
        options: Array,
        selectedOptions: {
            type: Array,
            default: () => ([]),
        },
        loadingMore: Boolean,
        hasMore: Boolean,
        initialized: {
            type: Boolean,
            default: true,
        },
        hasEmpty: {
            type: Boolean,
            default: true,
        },

        isSelectable: {
            type: Function,
            default(option) {
                return !this.isOptionSelected(option, this.selectedOptions);
            },
        },
        isDisabled: {
            type: Function,
            default(option) {
                return false;
            },
        },
    },

    methods: {
        open() {
            this.$refs.menu.show();
        },

        close() {
            this.$refs.menu.hide();
        },

        toggle() {
            this.$refs.menu.toggle();
        },

        select(value) {
            const target = this;
            this.$emit('select', { value, target });
            this.$refs.menu.hide();
        },

        // emits

        emitMore() {
            const target = this;
            this.$emit('more', { target });
        },

        emitOpen() {
            const target = this;
            this.$emit('open', { target });
        },

        emitClose() {
            const target = this;
            this.$emit('close', { target });
        },
    },
};
</script>

<style>
.u-dropdown__option-more {
    width: 100%;
}

.u-dropdown__option-empty,
.u-dropdown__option-more {
    padding: 8px 12px;
}
</style>