var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',{staticClass:"step-bar"},_vm._l((_vm.normalizedItems),function(item){return _c('li',{key:'step-bar__item-' + item.step,staticClass:"step-bar__item",class:{
            'step-bar__item_active': _vm.step >= item.step,
        }},[_c(item.tag,_vm._b({tag:"component",staticClass:"step-bar__label-wrap",class:{
                'step-bar__label-wrap_error': item.error,
                'step-bar__label-wrap_active': _vm.step >= item.step,
                'step-bar__label-wrap_current': _vm.step === item.step,
            }},'component',item.options,false),[_c('span',{staticClass:"step-bar__count"},[_vm._v(_vm._s(item.step))]),_c('span',{staticClass:"step-bar__label"},[_vm._v(". "+_vm._s(item.label))])]),_vm._v(" "),_c('UIcon',{staticClass:"step-bar__arrow",class:{
                'step-bar__arrow_active': _vm.step > item.step,
            },attrs:{"name":"arrow-right","small":""}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }