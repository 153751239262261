export default {
    namespaced: true,

    state: () => ({
        shop: {},
        companyShops: [],
        initialized: false,

        newPartsInStockCount: 0,
        newPartsTotalCount: 0,
        newPartsDefaultFacets: {},
        contractPartsAmount: 0,
        contractPartsDefaultFacets: {},
        productsTotalCount: 0,
        productsInStockCount: 0,
        productsDefaultFacets: {},
    }),

    getters: {
        availableRoutes: state => {
            const routes = [];

            if (state.productsTotalCount) {
                routes.push('market-shop-products');
            }

            if (state.newPartsTotalCount) {
                routes.push('market-shop-new-parts');
            }

            // if (state.contractPartsAmount) {
            //     routes.push('market-shop-contract-parts');
            // }

            return routes;
        },
    },

    mutations: {
        setShop(state, shop) {
            state.shop = shop;
        },

        setCompanyShops(state, { results }) {
            state.companyShops = results;
        },

        setNewPartsAmount(state, { facets }) {
            state.newPartsInStockCount = facets.in_stock_count;
            state.newPartsTotalCount = facets.not_in_stock_count;
            state.newPartsDefaultFacets = facets;
        },

        setContractPartsAmount(state, { current_count, facets }) {
            state.contractPartsAmount = current_count;
            state.contractPartsDefaultFacets = facets;
        },

        setProductsTotalCount(state, { facets }) {
            state.productsInStockCount = facets.in_stock_count;
            state.productsTotalCount = facets.not_in_stock_count;
            state.productsDefaultFacets = facets;
        },

        changeFavorite(state, value) {
            state.shop.is_favorite = value;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.shop = {};
            state.companyShops = [];
            state.initialized = false;

            state.newPartsInStockCount = 0;
            state.newPartsTotalCount = 0;
            state.newPartsDefaultFacets = {};
            state.contractPartsAmount = 0;
            state.productsTotalCount = 0;
            state.productsDefaultFacets = {};
        },
    },

    actions: {
        async init({ commit, dispatch, rootState }, { id }) {
            try {
                // const [shop, products, newParts, contractParts] = await Promise.all(
                const [shop, products, newParts] = await Promise.all(
                    [
                        this.$api.shops.get(id),
                        // dispatch('getContractPartsAmount', { id }),
                        dispatch('getProductsTotalCount', { id }),
                        dispatch('getNewPartsAmount', { id }),
                        dispatch('viewsHistory/get', null, { root: true }),
                    ],
                );
                const companyShops = await this.$api.companies.shops(shop.company.id, {
                    params: {
                        city_id__in: rootState.cities.currentCityId,
                        order_by: 'name',
                    },
                });

                commit('setShop', shop);
                commit('setCompanyShops', companyShops);
                commit('setNewPartsAmount', newParts);
                // commit('setContractPartsAmount', contractParts);
                commit('setProductsTotalCount', products);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { shop, products, newParts };
                // return { shop, newParts, contractParts, products };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async updateShop({ commit }, { id }) {
            try {
                const shop = await this.$api.shops.get(id);
                commit('setShop', shop);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getNewPartsAmount({}, { id }) {
            const params = {
                shop: id,
                get_facets: 'all',
                limit: 0,
                offset: 0,
            };

            return await this.$api.esPartProductsOffers.get({ params });
        },

        async getContractPartsAmount({}, { id }) {
            const params = {
                shop: id,
                get_facets: 'all',
                limit: 0,
                offset: 0,
            };

            return await this.$api.esContractParts.get({ params });
        },

        async getProductsTotalCount({}, { id }) {
            const params = {
                shop: id,
                get_facets: 'all',
                limit: 0,
                offset: 0,
            };

            return await this.$api.esProductsOffers.get({ params });
        },
    },
};