<template>
    <div>
        <UTooltip
            noDetach
            color="white"
            maxWidth="420"
            :showOnClick="tooltipEvent === 'click'"
            class="car-characteristics-tooltip car-characteristics-tooltip_desktop"
            @open="init"
            @afterClose="afterClose"
        >
            <div
                v-if="loading"
                class="flex align-items-center justify-content-center"
            >
                <USpinner></USpinner>
            </div>

            <CarCharacteristicsTable
                v-else
                :car="carInner"
                small
            ></CarCharacteristicsTable>
        </UTooltip>

        <UIconButton
            name="info"
            small
            class="car-characteristics-tooltip car-characteristics-tooltip_mobile"
            aria-label="Дополнительная информация"
            @click="showCarCharacteristicsPopup"
        ></UIconButton>
    </div>
</template>

<script>
// services
import { HTTP } from '@/http.js';
// components
import CarCharacteristicsTable from '@/components/CarCharacteristicsTable.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
const CarCharacteristicsPopup = () => import('@/components/popups/CarCharacteristicsPopup.vue');

export default {
    name: 'CarCharacteristicsTableTooltip',

    components: {
        UIconButton,
        USpinner,
        UTooltip,
        CarCharacteristicsTable,
    },

    props: {
        car: {
            type: Object,
            default: () => {},
        },

        tooltipEvent: {
            type: String,
            default: 'hover',
        },

        dynamicContent: Boolean,
    },

    data() {
        return {
            loading: false,
            carInner: {},
            loaded: false,
            cancelToken: null,
            source: null,
        };
    },

    methods: {
        async init() {
            if (this.dynamicContent && this.car.id && !this.carInner.id) {
                this.loading = true;

                if (this.source) {
                    this.source.cancel();
                    this.source = null;
                }

                this.source = HTTP.CancelToken.source();
                const cancelToken = this.source.token;
                const config = { cancelToken };

                try {
                    this.carInner = await this.$api.cars.get(this.car.id, config);
                }
                catch (error) {
                    this.$store.commit('handleCommonHttpError', error);
                }
                finally {
                    this.loading = false;
                }
            }
            else {
                this.carInner = this.car;
            }
        },

        afterClose() {
            if (this.source) {
                this.source.cancel();
                this.source = null;
            }
        },

        showCarCharacteristicsPopup() {
            const props = {
                car: this.car,
            };
            const options = { props };
            this.$popup(CarCharacteristicsPopup, options);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .car-characteristics-tooltip_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .car-characteristics-tooltip_desktop {
        display: none;
    }
}

.car-characteristics-tooltip >>> .u-icon {
    fill: var(--font-secondary-color);
}
.car-characteristics-tooltip:hover >>> .u-icon {
    fill: var(--primary-color);
}
</style>