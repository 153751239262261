<template>
    <div class="insurance-osago-error">
        <BackLink
            :to="{
                name: 'insurance-osago-edit',
                params: {
                    uuid
                }
            }"
            text="Изменить данные"
            class="back-link"
        ></BackLink>

        <h1 class="h1">
            Оформление полиса невозможно
        </h1>

        <template v-if="offers.length">
            <p class="descriptor">
                К&nbsp;сожалению, оформление полиса ОСАГО в&nbsp;выбранной компании сейчас невозможно. 
                Вы&nbsp;можете ознакомиться с&nbsp;предложениями от&nbsp;других страховых компаний.
            </p>

            <div class="top-gap_mini">
                <InsuranceOffer
                    v-for="item in offers"
                    :key="'insurance-option-' + (item.company || {}).code"
                    :offer="item"
                    :activeOffer="activeOffer"
                    :loading="activateLoading"
                    @select="selectCompany(item)"
                ></InsuranceOffer>
            </div>
        </template>

        <EmptyBlock
            v-else
            icon="empty"
            title="Данные не подтверждены"
            class="empty-block"
        >
            <template #text>
                <p>
                    К&nbsp;сожалению, оформление полиса ОСАГО в&nbsp;выбранной компании 
                    сейчас невозможно и&nbsp;нет других подходящих предложений.
                </p>
                <p>
                    Возможно, дело в&nbsp;неверных данных или технической ошибке. 
                    Попробуйте отправить запрос ещё раз через некоторое время.
                </p>
            </template>
            <template #button>
                <ButtonText
                    primary
                    dashed
                    class="repeat-button"
                    @click="repeat"
                >Повторить запрос</ButtonText>

                <ButtonText
                    secondary
                    dark
                    dashed
                    :to="{
                        name: 'insurance-osago-edit',
                        params: $route.params,
                    }"
                >Изменить данные</ButtonText>
            </template>
        </EmptyBlock>
    </div>
</template>

<script>
// store
import insuranceOsago from '@/store/modules/insuranceOsago.js';
import { mapState, mapActions, mapGetters } from 'vuex';
// compasable
import useCheckRouteMethods from '@/pages/insurance/osago/_uuid/useCheckRouteMethods.js';
// components
import BackLink from '@/components/project-buttons/BackLink.vue';
import InsuranceOffer from './InsuranceOffer.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';


export default {
    name: 'InsuranceOsagoErrorPage',

    components: {
        ButtonText,
        EmptyBlock,
        InsuranceOffer,
        BackLink,
    },

    metaInfo() {
        return {
            title: 'Оформление полиса невозможно',
        };
    },

    serverPrefetch() {
        this.$store.registerModule('insuranceOsago', insuranceOsago);

        return this.serverPrefetch({ uuid: this.uuid });
    },

    data() {
        return {
            activateLoading: false,
            activeOffer: null,
            orderLoading: false,
        };
    },

    computed: {
        ...mapState({
            companies: state => (state.insuranceOsago || {}).companies || [],
            contract: state => (state.insuranceOsago || {}).contract || {},
        }),

        ...mapGetters({
            filteredOffers: 'insuranceOsago/filteredOffers',
        }),

        uuid() {
            return this.$route.params.uuid;
        },

        offers() {
            if (this.companies.length) {
                return this.filteredOffers;
            }
            else {
                return [];
            }
        },
    },

    watch: {
        uuid: {
            handler() {
                this.createBreadcrumbs();
            },
        },
    },

    beforeMount() {
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago, { preserveState: true });
        }
    },

    mounted() {
        this.init();
        this.createBreadcrumbs();
    },

    methods: {
        ...useCheckRouteMethods(),
        ...mapActions({
            serverPrefetch: 'insuranceOsago/serverPrefetch',
            browserPrefetch: 'insuranceOsago/browserPrefetch',
            order: 'insuranceOsago/order',
            updateActiveOffer: 'insuranceOsago/updateActiveOffer',
        }),

        async init() {
            await this.browserPrefetch({ uuid: this.uuid });

            await this.checkUuid();
        },

        createBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'insurance-osago',
                    },
                    title: 'Автострахование',
                },
                {
                    to: {
                        name: this.$route.name,
                        params: {
                            uuid: this.uuid,
                        },
                    },
                    title: 'Ошибка оформления полиса',
                },
            ]);
        },

        async selectCompany(offer) {
            this.activeOffer = offer;
            this.activateLoading = true;

            try {
                await this.updateActiveOffer(offer);

                await this.order();

                await this.$router.push({
                    name: 'insurance-osago-confirmation',
                    params: {
                        uuid: this.uuid,
                    },
                });
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.activateLoading = false;
                this.activeOffer = null;
            }
        },

        async repeat() {
            this.orderLoading = true;

            try {
                await this.order(true);

                await this.$router.push({
                    name: 'insurance-osago-confirmation',
                    params: {
                        uuid: this.uuid,
                    },
                });
            }
            catch (error) {
                console.log(error);
                //
            }
            finally {
                this.orderLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.back-link {
    margin-top: 24px;
}
@media (min-width: 1281px) {
    .back-link {
        margin-bottom: 8px;
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .back-link {
        margin-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .back-link {
        margin-bottom: 28px;
    }
}

@media (min-width: 767px) {
    .h1 {
        text-align: center;
    }
}

.descriptor {
    margin-top: var(--indent-h1-text);
    line-height: var(--base-lh);
}
@media (min-width: 1281px) {
    .descriptor {
        width: var(--col-8);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .descriptor {
        width: var(--col-10);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .descriptor {
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 767px) {
    .descriptor {
        text-align: center;
    }
}

.empty-block {
    margin-top: var(--indent-h1-block);
}
@media (max-width: 767px) {
    .empty-block >>> .empty__btns-wrap {
        flex-direction: column;
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .repeat-button {
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .repeat-button {
        margin-bottom: 16px;
    }
}
</style>