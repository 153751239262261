export default ({ HTTP }) => ({
    async get(config) {
        const url = '/companies/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async shops(id, config) {
        const url = `/companies/${ id }/shops/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },
});