<template>
    <InputText
        ref="input"
        v-bind="$attrs"
        :value="inputValue"
        @input="onInput"
        @change="onChange"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @clear="onClear"
        @enter="onEnter"
    ></InputText>
</template>

<script>
import InputText from '@/components/_inputs/InputText.vue';
import { decimal } from '@ui/filters';


const normalize = function(value, min, max) {
    if (!isNaN(min)) {
        if (value < min) {
            value = min;
        }
    }

    if (!isNaN(max)) {
        if (value > max) {
            value = max;
        }
    }

    return value;
};

export default {
    name: 'InputNumber',

    components: {
        InputText,
    },

    inheritAttrs: false,

    props: {
        value: {
            type: [Number, String, Object],
            default: null,
        },

        codename: String,

        max: [Number, String],
        min: [Number, String],

        noFormat: Boolean,
    },

    data() {
        return {
            inputValue: '',
        };
    },

    computed: {
        outputValue() {
            const value = this.inputValue;

            if (value === '') {
                return null;
            }
            else {
                if (this.noFormat) {
                    return Number(value.replace(/[^\d,]/gi, ''));
                }
                else {
                    let [integer, decimal] = value.replace(/[^\d,]/gi, '').split(',');
                    return Number(integer + (decimal === undefined ? '' : '.' + decimal));
                }
            }
        },

        emitValue() {
            return this.codename
                ? { [this.codename]: this.outputValue }
                : this.outputValue;
        },
    },

    watch: {
        value: {
            handler(value) {
                if (value !== this.outputValue) {
                    if (this.noFormat) {
                        this.inputValue = value.toString();
                    }
                    else {
                        this.inputValue = Number(value).toLocaleString('ru-RU');
                    }

                }
            },
            immediate: true,
        },
    },

    methods: {
        onEnter($event) {
            const el = $event.target;
            const oldValue = el.value;
            let newValue = oldValue;

            if (newValue) {
                if (this.noFormat) {
                    const number = Number(oldValue.replace(/[^\d,]/gi, ''));
                    newValue = normalize(number, this.min, this.max).toString();
                }
                else {
                    const [integer, decimalPart] = oldValue.replace(/[^\d,]/gi, '').split(',');
                    const number = Number(integer + (decimalPart === undefined ? '' : '.' + decimalPart));
                    newValue = decimal(normalize(number, this.min, this.max));
                }
            }

            if (newValue !== oldValue) {
                el.value = newValue;
            }

            const caretPosition = el.selectionStart;

            if (caretPosition) {
                if (newValue.length !== oldValue.length) {
                    const newCaretPosition = caretPosition + newValue.length - oldValue.length;

                    if (caretPosition !== newCaretPosition) {
                        el.selectionStart = newCaretPosition;
                        el.selectionEnd = newCaretPosition;
                    }
                }
            }

            this.inputValue = newValue;

            this.$emit('enter', this.emitValue);
        },

        onChange(oldValue) {
            let newValue = oldValue;
            const el = this.$refs.input.inputEl;

            if (newValue) {
                if (this.noFormat) {
                    const number = Number(oldValue.replace(/[^\d,]/gi, ''));
                    newValue = normalize(number, this.min, this.max).toString();
                }
                else {
                    const [integer, decimalPart] = oldValue.replace(/[^\d,]/gi, '').split(',');
                    const number = Number(integer + (decimalPart === undefined ? '' : '.' + decimalPart));
                    newValue = decimal(normalize(number, this.min, this.max));
                }
            }

            if (newValue !== oldValue) {
                el.value = newValue;
            }

            this.inputValue = newValue;

            this.$emit('change', this.emitValue);
        },

        onInput(oldValue) {
            let newValue = oldValue;
            const el = this.$refs.input.inputEl;

            if (newValue && el) {
                if (this.noFormat) {
                    newValue = oldValue.replace(/[^\d,]/gi, '');
                }
                else {
                    let [integer, decimal] = oldValue.replace(/[^\d,]/gi, '').split(',');

                    if (integer) {
                        integer = Number(integer).toLocaleString('ru-RU');
                        newValue = integer + (decimal === undefined ? '' : ',' + decimal);
                    }
                    else {
                        newValue = '';
                    }
                }

                if (newValue !== oldValue) {
                    el.value = newValue;
                }

                const caretPosition = el.selectionStart;

                if (caretPosition) {
                    if (newValue.length !== oldValue.length) {
                        const newCaretPosition = caretPosition + newValue.length - oldValue.length;

                        if (caretPosition !== newCaretPosition) {
                            el.selectionStart = newCaretPosition;
                            el.selectionEnd = newCaretPosition;
                        }
                    }
                }
            }

            this.inputValue = newValue;

            this.$emit('input', this.emitValue);
        },

        onClear() {
            this.inputValue = '';

            if (this.$listeners.clear) {
                this.$emit('clear');
            }
            else if (this.$listeners.change) {
                this.$emit('change', null);
            }
            else {
                this.$emit('input', null);
            }
        },
    },
};
</script>