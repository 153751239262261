export default {
    namespaced: true,

    state: () => {
        return {
            initialized: false,
            initializing: false,
            fetching: false,
            category: {},
            categoryChildren: {},
            characteristics: {},
        };
    },

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setInitializing(state, value) {
            state.initializing = value;
        },

        setFetching(state, value) {
            state.fetching = value;
        },

        setCategory(state, value) {
            state.category = value;
        },

        setCategoryChildren(state, value) {
            state.categoryChildren = value;
        },

        setCharacteristics(state, value) {
            state.characteristics = value;
        },

        destroy(state) {
            state.initialized = false;
            state.initializing = false;
            state.fetching = false;
            state.category = {};
            state.categoryChildren = {};
            state.characteristics = {};
        },
    },

    actions: {
        async serverPrefetch({ commit }, slug) {
            try {
                const category = await this.$api.partCategories.get(slug);
                commit('setCategory', category);
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async browserPrefetch({ state, commit, dispatch }, { partNameDocFetchParams, categoryFetchParams }) {
            try {
                commit('setInitializing', true);

                if (state.category.is_leaf) {
                    const characteristics = await this.$api.partNameDoc.get(partNameDocFetchParams);
                    commit('setCharacteristics', characteristics);
                }
                else {
                    const categoryChildren = await this.$api.partCategories.get(categoryFetchParams);
                    commit('setCategoryChildren', categoryChildren);
                }
                commit('setInitialized');

                dispatch('viewsHistory/get', null, { root: true });
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
            finally {
                commit('setInitializing', false);
            }
        },

        async fetchCategories({ commit }, config) {
            try {
                commit('setFetching', true);

                const categoryChildren = await this.$api.partCategories.get(config);

                commit('setCategoryChildren', categoryChildren);
                commit('setInitialized');
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                commit('setFetching', false);
            }
        },

        async fetchCharacteristics({ commit }, config) {
            try {
                commit('setFetching', true);

                const characteristics = await this.$api.partNameDoc.get(config);

                commit('setCharacteristics', characteristics);
                commit('setInitialized');
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                commit('setFetching', false);
            }
        },
    },
};