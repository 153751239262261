<template>
    <Spinner
        v-if="!initialized"
        size="big"
        center
        class="top-gap_medium"
    ></Spinner>
    <div v-else class="payment top-gap_medium">
        <img
            srcset="../assets/images/before-payment.jpg,
                    ../assets/images/before-payment-x2.jpg 2x"
            src="../assets/images/before-payment-x2.jpg"
            alt=""
            width="400"
            height="300"
            class="decor-img"
        >
        <h1 v-if="paymentType === 'order'" class="h1 h1_text">
            Оплата {{ ordersCount > 1 ? 'заказов' : 'заказа' }} на&nbsp;сумму {{ paymentPrice | price }}
        </h1>
        <h1 v-else-if="paymentType === 'yandex-delivery'" class="h1 h1_text">
            Оплата доставки на&nbsp;сумму {{ yandexDelivery.price_payment | price }}
        </h1>
        <h1 v-else-if="paymentType === 'world-delivery'" class="h1 h1_text">
            Оплата доставки на&nbsp;сумму {{ worldDelivery.payment.amount | price }}
        </h1>

        <div class="payment__text-wrap">
            <p>Для совершения оплаты вы будете автоматически перенаправлены на&nbsp;страницу банка через несколько секунд...</p>
            <p class="payment-note">
                К оплате принимаются карты VISA, MasterCard и МИР. Для совершения оплаты вы будете перенаправлены на безопасную страницу ПАО «Сбербанк». Для дополнительной аутентификации держателя карты используется защищенный протокол 3D Secure.
            </p>
        </div>

        <ButtonBlock
            primary
            :loading="goToPaymentLoading"
            class="action-btn"
            @click="goToPayment"
        >
            Перейти к оплате
        </ButtonBlock>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import payment from '@/store/modules/payment.js';
import { PAYMENT_REDIRECT_TIMEOUT } from '@/settings.js';
import Spinner from '@/components/Spinner.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';

export default {
    name: "BeforePayment",

    components: { Spinner, ButtonBlock },

    metaInfo() {
        return {
            title: 'Переход к оплате',
        };
    },

    serverPrefetch() {
        return this.INIT({ params: this.$route.params });
    },

    data() {
        return {
            timeout: null,

            goToPaymentLoading: false,
            getPaymentLinkCancelToken: null,
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.payment.initialized,
            ordersCount: state => state.payment.orders.count,
            paymentPrice: state => state.payment.paymentPrice,
            paymentType: state => state.payment.paymentType,
            yandexDelivery: state => state.payment.yandexDelivery,
            worldDelivery: state => state.payment.worldDelivery,
            paymentLink: state => state.payment.bank_payment_url,
        }),
    },

    created() {
        this.$store.registerModule('payment', payment);
    },

    async mounted() {
        if (!this.initialized) {
            await this.INIT({ params: this.$route.params });
        }

        this.initTimeout();
    },

    beforeDestroy() {
        this.DESTROY();
        clearTimeout(this.timeout);
        this.$store.unregisterModule('payment');
    },

    methods: {
        ...mapActions({
            INIT: 'payment/beforePaymentInit',
        }),

        ...mapMutations({
            DESTROY: 'payment/destroy',
        }),

        initTimeout() {
            this.timeout = setTimeout(() => {
                this.goToPayment();
            }, PAYMENT_REDIRECT_TIMEOUT);
        },

        goToPayment() {
            if (this.paymentLink) {
                this.goToPaymentLoading = true;
                window.location = this.paymentLink;
            }
        },
    },
};
</script>

<style scoped>
.decor-img {
    margin-bottom: var(--gap-y-medium);
}
@media (min-width: 768px) {
    .decor-img {
        width: 400px;
        height: 300px;
    }
}
@media (max-width: 767px) {
    .decor-img {
        width: 290px;
        height: 218px;
    }
}

@media (min-width: 1281px) {
    .payment__text-wrap {
        width: 740px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .payment__text-wrap {
        width: 680px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .payment__text-wrap {
        width: var(--col-10);
    }
}

.payment-note {
    font-size: var(--small-fz);
    color: var(--font-secondary-color);
}

@media (min-width: 768px) {
    .payment-note {
        margin-top: 36px;
    }
}
@media (max-width: 767px) {
    .payment-note {
        margin-top: 28px;
    }
}

@media (min-width: 768px) {
    .action-btn {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .action-btn {
        margin-top: 16px;
    }
}
</style>