<template>
    <div>
        <slot v-bind="scope"></slot>
    </div>
</template>

<script>
import genId from '@ui/utils/genId.js';
import useForm from '@/components/_form/useForm.js';
import useFormMethods from '@/components/_form/useFormMethods.js';
import useFormWatch from '@/components/_form/useFormWatch.js';
import useFormProviders from '@/components/_form/useFormProviders.js';


export default {
    name: 'FormCollectionItem',

    provide() {
        return {
            ...useFormProviders(this),
        };
    },

    inject: {
        refRoot: { default: undefined },
    },

    props: {
        name: [String, Number],

        item: {
            type: Object,
            default: () => ({}),
        },

        id: {
            type: String,
            default: () => genId(),
        },

        register: Function,
        unregister: Function,
        updateParentValue: Function,
        updateOriginalErrors: Function,
        originalErrors: {
            default: () => ({}),
        },
    },

    data() {
        return {
            ...useForm(this),
            innerErrors: {},
        };
    },

    computed: {
        initialValue() {
            return {};
        },

        originalValues() {
            return this.item;
        },

        scope() {
            return {
                index: Number(this.name),
            };
        },
    },

    watch: {
        ...useFormWatch(),
    },

    created() {
        if (this.register && typeof this.register === 'function') {
            this.register(this);
        }
    },

    beforeDestroy() {
        if (this.unregister && typeof this.unregister === 'function') {
            this.unregister(this);
        }
    },

    methods: {
        ...useFormMethods(),
    },
};
</script>