<template>
    <div>
        <div
            v-if="productsTotalCount || partsTotalCount"
            class="assortment-navigation_outer"
        >
            <ul class="assortment-navigation">
                <li
                    v-for="(item, index) in assortmentNavigation"
                    :key="'assortment-navigation-item-' + index"
                    class="assortment-navigation-item"
                >
                    <RouterLink
                        activeClass="active"
                        :to="{
                            name: item.name,
                            params: $route.params
                        }"
                        class="assortment-navigation-link"
                    >
                        <h2 class="h2">
                            <span>{{ item.title }}</span><span
                                v-if="item.amount"
                                class="h2-sub"
                            >{{ item.amount | number }}</span>
                        </h2>
                    </RouterLink>
                </li>
            </ul>
        </div>
        <h2
            v-else
            class="h2 h2_block"
        >
            Ассортимент производителя
        </h2>

        <RouterView v-if="productsTotalCount || partsTotalCount"></RouterView>
        <EmptyBlock
            v-else
            icon="empty-box"
            title="Нет товаров"
            :btnUrl="{
                name: 'market-products-catalog',
            }"
        >
            <template #text>
                Товары под брендом <span class="text_bold">{{ brand.name }}</span> пока не продаются на Ubibi.
            </template>
        </EmptyBlock>

        <LastSee></LastSee>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import marketBrandProducts from '@/store/modules/marketBrandProducts.js';
import marketBrandParts from '@/store/modules/marketBrandParts.js';
import EmptyBlock from '@/components/EmptyBlock.vue';
import LastSee from '@/components/LastSee.vue';


export default {
    name: 'MarketBrandStockPage',

    components: {
        EmptyBlock,
        LastSee,
    },

    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.replaceQuery(to);
        });
    },

    computed: {
        ...mapState({
            brand: state => state.marketBrand.brand,
            initialized: state => state.marketBrand.initialized,
            productsTotalCount: state => state.marketBrand.productsTotalCount,
            productsCurrentCount: state => state.marketBrandProducts.products.current_count,
            partsTotalCount: state => state.marketBrand.partsTotalCount,
        }),

        ...mapGetters({
            availableRoutes: 'marketBrand/availableRoutes',
        }),

        assortmentNavigation() {
            const routes = [];

            if (this.partsTotalCount) {
                routes.push({
                    name: 'market-brand-parts',
                    title: 'Запчасти',
                    amount: this.$route.name === 'market-brand-parts' ? this.newPartsCurrentCount || this.newPartsInStockCount : this.newPartsInStockCount,
                });
            }

            if (this.productsTotalCount) {
                routes.push({
                    name: 'market-brand-products',
                    title: 'Автотовары',
                    amount: this.$route.name === 'market-brand-products' ? this.productsCurrentCount || this.productsInStockCount : this.productsInStockCount,
                });
            }

            return routes;
        },
    },

    created() {
        this.$store.registerModule('marketBrandProducts', marketBrandProducts);
        this.$store.registerModule('marketBrandParts', marketBrandParts);
    },

    beforeDestroy() {
        this.$store.unregisterModule('marketBrandProducts');
        this.$store.unregisterModule('marketBrandParts');
    },

    methods: {
        replaceQuery(to) {
            let location = {};
            if (to.name === 'market-brand-stock') {
                if (this.availableRoutes.length) {
                    location = {
                        name: this.availableRoutes[0],
                        params: this.$route.params,
                        query: this.$route.query,
                    };
                }
                else {
                    location = to;
                }
            }
            else if (!this.availableRoutes.includes(to.name)) {
                if (this.availableRoutes.length) {
                    location = {
                        name: this.availableRoutes[0],
                        params: this.$route.params,
                        query: this.$route.query,
                    };
                }
                else {
                    location = {
                        name: 'market-brand-stock',
                        params: this.$route.params,
                        query: this.$route.query,
                    };
                }
            }
            else {
                location = to;
            }

            if (location.name) {
                const toPath = this.$router.resolve(location).route.fullPath;

                if (this.$route.fullPath !== toPath) {
                    this.$router.replace(location);
                }
            }
        },
    },
};
</script>

<style scoped>
.assortment-navigation_outer {
    margin-left: var(--content-gap-negative);
    margin-right: var(--content-gap-negative);
    overflow: hidden;
}

.assortment-navigation {
    display: flex;
    padding-bottom: 40px;
    overflow: auto;
}
@media (min-width: 768px) {
    .assortment-navigation {
        margin-bottom: -20px;
    }
}
@media (max-width: 767px) {
    .assortment-navigation {
        margin-bottom: -24px;
    }
}
.assortment-navigation::before,
.assortment-navigation::after {
    content: "";
    flex: 0 0 var(--content-gap);
}


@media (min-width: 1040px) {
  .assortment-navigation-item:not(:last-child) {
      margin-right: var(--gap-y-medium);
  }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .assortment-navigation-item:not(:last-child) {
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .assortment-navigation-item:not(:last-child) {
        margin-right: 20px;
    }
}

.assortment-navigation-link {
    white-space: nowrap;
    color: var(--font-secondary-color);
}

.assortment-navigation-link.active,
.assortment-navigation-link:hover,
.assortment-navigation-link:focus {
    color: var(--dark-c);
}
</style>