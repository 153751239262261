<template>
    <div class="insurer-osago-summary">
        <h2 class="h2">
            Проверьте ваши данные
        </h2>

        <div class="list-item">
            <div class="list-item__text-wrap">
                <h3 class="list-item__title">
                    Автомобиль
                </h3>
                <p class="list-item__text">
                    {{ (contract.vehicle_model || {}).fullname }}, {{ contract.vehicle_year }} ({{ contract.vehicle_engine_power }} л. с.)
                </p>
                <p class="list-item__text">
                    <span v-if="contract.vehicle_license_plate">Гос. номер: {{ contract.vehicle_license_plate | carNumber }}, </span>{{ vehicleIdTypeCodeComputed }}: {{ contract.vehicle_id_number }}
                </p>
                <p class="list-item__text">
                    {{ vehicleDocTypeCodeComputed }}: {{ contract.vehicle_doc_number }}, дата выдачи: {{ contract.vehicle_doc_date | date }}
                </p>
            </div>

            <ButtonText
                dashed
                secondary
                dark
                class="btn-edit btn-edit_desktop"
                @click="$emit('popup', 'car')"
            >
                Изменить
            </ButtonText>

            <ButtonIcon
                :icon="{
                    name: 'edit',
                    small: true
                }"
                circle
                class="btn-edit btn-edit_mobile"
                @click="$emit('popup', 'car')"
            ></ButtonIcon>
        </div>

        <div class="list-item">
            <div class="list-item__text-wrap">
                <h3 class="list-item__title">
                    Срок действия полиса
                </h3>
                <p
                    class="list-item__text"
                    :class="{
                        text_red: error.action_start_date,
                    }"
                >
                    {{ contract.action_start_date | date }}<template v-if="actionEndDate">
                        – {{ actionEndDate }}, включительно
                    </template><UTooltip v-if="error.action_start_date">
                        <template #trigger="{ value, handlers, attrs }">
                            <UIconButton
                                name="info"
                                small
                                primary
                                :active="value"
                                v-bind="attrs"
                                v-on="handlers"
                            ></UIconButton>
                        </template>
                    </UTooltip>
                </p>
            </div>

            <ButtonText
                dashed
                secondary
                dark
                class="btn-edit btn-edit_desktop"
                @click="$emit('popup', 'policy')"
            >
                Изменить
            </ButtonText>
            <ButtonIcon
                :icon="{
                    name: 'edit',
                    small: true
                }"
                circle
                class="btn-edit btn-edit_mobile"
                @click="$emit('popup', 'policy')"
            ></ButtonIcon>
        </div>

        <div class="list-item">
            <div class="list-item__text-wrap">
                <h3 class="list-item__title">
                    Водители
                </h3>

                <p
                    v-if="contract.unlimited_drivers_amount"
                    class="list-item__text"
                >
                    Без ограничений на водителей
                </p>

                <template v-else>
                    <div
                        v-for="(driver, driverIndex) in contract.drivers"
                        :key="'driver-' + driverIndex"
                        :class="{
                            'mb-8': driverIndex + 1 < contract.drivers.length && contract.drivers.length > 1,
                        }"
                    >
                        <p class="list-item__text">
                            {{ driver.last_name }} {{ driver.first_name }} {{ driver.middle_name }}
                        </p>
                        <p class="list-item__text">
                            Дата рождения: {{ driver.birth_date | date }}
                        </p>
                        <p class="list-item__text">
                            ВУ: {{ driver.license_number }}
                        </p>
                        <p class="list-item__text">
                            Дата начала стажа: {{ driver.experience_start_date | date }}
                        </p>
                    </div>
                </template>
            </div>

            <ButtonText
                dashed
                secondary
                dark
                class="btn-edit btn-edit_desktop"
                @click="$emit('popup', 'drivers')"
            >
                Изменить
            </ButtonText>
            <ButtonIcon
                :icon="{
                    name: 'edit',
                    small: true
                }"
                circle
                class="btn-edit btn-edit_mobile"
                @click="$emit('popup', 'drivers')"
            ></ButtonIcon>
        </div>

        <div class="list-item">
            <div class="list-item__text-wrap">
                <h3 class="list-item__title">
                    Собственник
                </h3>
                <p class="list-item__text">
                    {{ contract.owner_last_name }} {{ contract.owner_first_name }} {{ contract.owner_middle_name }}
                </p>
                <p class="list-item__text">
                    Дата рождения: {{ contract.owner_birth_date | date }}
                </p>
                <p class="list-item__text">
                    Паспорт: {{ contract.owner_doc_number }}, дата выдачи: {{ contract.owner_doc_date | date }}
                </p>
                <p>
                    Адрес регистрации: {{ (contract.owner_address_obj || {}).value }}<template v-if="contract.owner_address_apartment_number">
                        , кв. {{ contract.owner_address_apartment_number }}
                    </template>
                </p>
            </div>

            <ButtonText
                dashed
                secondary
                dark
                class="btn-edit btn-edit_desktop"
                @click="$emit('popup', 'owner')"
            >
                Изменить
            </ButtonText>
            <ButtonIcon
                :icon="{
                    name: 'edit',
                    small: true
                }"
                circle
                class="btn-edit btn-edit_mobile"
                @click="$emit('popup', 'owner')"
            ></ButtonIcon>
        </div>

        <div class="list-item">
            <div class="list-item__text-wrap">
                <h3 class="list-item__title">
                    Страхователь
                </h3>
                <p class="list-item__text">
                    {{ contract.insurer_last_name }} {{ contract.insurer_first_name }} {{ contract.insurer_middle_name }}
                </p>
                <p class="list-item__text">
                    Дата рождения: {{ contract.insurer_birth_date | date }}
                </p>
                <p class="list-item__text">
                    Паспорт: {{ contract.insurer_doc_number }}, дата выдачи: {{ contract.insurer_doc_date | date }}
                </p>
                <p class="list-item__text">
                    Адрес регистрации: {{ (contract.insurer_address_obj || {}).value }}<template v-if="contract.insurer_address_apartment_number">
                        , кв. {{ contract.insurer_address_apartment_number }}
                    </template>
                </p>
            </div>

            <ButtonText
                dashed
                secondary
                dark
                class="btn-edit btn-edit_desktop"
                @click="$emit('popup', 'insurer')"
            >
                Изменить
            </ButtonText>

            <ButtonIcon
                :icon="{
                    name: 'edit',
                    small: true
                }"
                circle
                class="btn-edit btn-edit_mobile"
                @click="$emit('popup', 'insurer')"
            ></ButtonIcon>
        </div>

        <div class="list-item">
            <div class="list-item__text-wrap">
                <h3 class="list-item__title">
                    Контактные данные
                </h3>
                <p class="list-item__text">
                    {{ contract.insurer_email }}
                </p>
                <p class="list-item__text">
                    {{ contract.insurer_phone | phone }}
                </p>
            </div>

            <ButtonText
                dashed
                secondary
                dark
                class="btn-edit btn-edit_desktop"
                @click="$emit('popup', 'contacts')"
            >
                Изменить
            </ButtonText>
            <ButtonIcon
                :icon="{
                    name: 'edit',
                    small: true
                }"
                circle
                class="btn-edit btn-edit_mobile"
                @click="$emit('popup', 'contacts')"
            ></ButtonIcon>
        </div>
    </div>
</template>

<script>
import isValidDate from '@ui/utils/isValidDate.js';
import pad from '@ui/utils/pad.js';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';


export default {
    name: 'InsuranceOsagoSummary',

    components: {
        UIconButton,
        UTooltip,
        ButtonIcon,
        ButtonText,
    },

    props: {
        contract: {
            type: Object,
            default() {
                return {};
            },
        },

        error: Object,
    },

    computed: {
        vehicleIdTypeCodeComputed() {
            const vehicle_id_type_code = this.contract.vehicle_id_type_code;

            const labels = {
                vin: 'VIN',
                bodyNumber: this.contract.vehicle_license_plate ? 'номер кузова' : 'Номер кузова',
                chassisNumber: this.contract.vehicle_license_plate ? 'номер шасси' : 'Номер шасси',
            };

            return labels[vehicle_id_type_code];
        },

        vehicleDocTypeCodeComputed() {
            const vehicle_doc_type_code = this.contract.vehicle_doc_type_code;

            const labels = {
                STS: 'СТС',
                PTS: 'ПТС',
                EPTS: 'ЕПТС',
            };

            return labels[vehicle_doc_type_code];
        },

        actionEndDate() {
            const iso = this.contract.action_end_date;
            const date = new Date(iso);
            const isValid = isValidDate(date);

            if (isValid) {
                const d = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
                return `${ pad(d.getDate(), 2) }.${ pad(d.getMonth() + 1, 2) }.${ d.getFullYear() }`;
            }

            return '';
        },
    },
};
</script>

<style scoped>
.list-item {
    display: flex;
    align-items: flex-start;
    padding-top: var(--gap-y-mini);
}

.list-item:not(:last-child) {
    padding-bottom: var(--gap-y-mini);
    border-bottom: 1px solid var(--border-light-c);
}

.list-item__title {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-family: var(--f-bold);
}

.list-item__text-wrap {
    margin-right: 20px;
    flex-grow: 1;
}

.list-item__text {
    line-height: var(--base-lh);
}

.btn-edit {
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .btn-edit_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .btn-edit_desktop {
        display: none;
    }
}
</style>