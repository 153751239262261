<template>
    <section class="header layer-1">
        <div class="header-main">
            <div class="product-main">
                <h1 class="h1 h1_block">
                    {{ product.page_title }}
                </h1>

                <MarketItemRatingReviewsLink
                    :item="product"
                    modelName="product"
                    class="rating-wrap"
                ></MarketItemRatingReviewsLink>

                <div class="social-block social-block_desktop">
                    <LikeBtn
                        :id="product.id"
                        :value="product.is_favorite"
                        apiName="product"
                        withText
                        noTextOnXS
                        class="like"
                        @changeFavorite="onChangeFavorite"
                    ></LikeBtn>

                    <ChatBtn
                        v-if="product"
                        :id="(product.shop || {}).id"
                        :isOnline="(((product.shop || {}).current_work || {}).place || {}).is_active"
                        :disabled="!(product.shop || {}).is_chat_active"
                        withText
                        class="chat"
                    ></ChatBtn>
                </div>

                <div class="photo-block">
                    <MarketItemPhotoBlock
                        :mainCover="product.cover"
                        :photos="product.photos"
                    ></MarketItemPhotoBlock>
                </div>
            </div>

            <div class="product-aside">
                <div class="price-block">
                    <span class="price">{{ product.price | price }}</span>
                </div>

                <div class="points-info">
                    <div v-if="product.offers_amount && product.delivery">
                        <UButtonText
                            secondary
                            dark
                            dashed
                            class="points"
                            @click="handlePointsClick(product)"
                        >
                            {{ product.delivery.currentCount }}
                            {{ product.delivery.currentCount | plural(['пункт', 'пункта', 'пунктов']) }} выдачи
                        </UButtonText>
                        <div
                            v-if="product.min_delivery_date"
                            class="delivery"
                        >
                            <span class="text_secondary">Получить:</span> {{ product.min_delivery_date }}
                        </div>
                    </div>

                    <div
                        v-else
                        class="no-items text_semi-bold text_secondary"
                    >
                        Нет в наличии
                    </div>

                    <div class="social-block social-block_mobile">
                        <LikeBtn
                            :id="product.id"
                            :value="product.is_favorite"
                            apiName="product"
                            withText
                            noTextOnXS
                            class="like"
                            @changeFavorite="onChangeFavorite"
                        ></LikeBtn>

                        <ChatBtn
                            v-if="product"
                            :id="(product.shop || {}).id"
                            :isOnline="(((product.shop || {}).current_work || {}).place || {}).is_active"
                            :disabled="!(product.shop || {}).is_chat_active"
                            withText
                            class="chat"
                        ></ChatBtn>
                    </div>
                </div>

                <div class="cart-block">
                    <AddCartBtn
                        :offer="{ id: product.offer_id, amount: product.in_stock_amount }"
                        :item="product"
                        modelName="product"
                        :disabled="!product.offers_amount"
                        :low="false"
                        class="action-btn"
                        @addToCart="$emit('changeCart')"
                        @deleteFromCart="$emit('deleteFromCart')"
                    >
                        Добавить в корзину
                    </AddCartBtn>
                </div>
            </div>
        </div>

        <MarketItemNavigation
            :items="nav"
        ></MarketItemNavigation>
    </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import MarketItemNavigation from '@/components/MarketItemNavigation.vue';
import MarketItemPhotoBlock from '@/components/MarketItemPhotoBlock.vue';
import ChatBtn from '@/components/project-buttons/ChatBtn.vue';
import AddCartBtn from '@/components/project-buttons/AddCartBtn.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const DeliveryOfficesPopup = () => import('@/components/popups/DeliveryOfficesPopup.vue');


export default {
    name: 'MarketProductBigHeader',

    components: {
        UButtonText,
        AddCartBtn,
        ChatBtn,
        MarketItemPhotoBlock,
        MarketItemRatingReviewsLink,
        LikeBtn,
        MarketItemNavigation,
    },

    computed: {
        ...mapState({
            product: state => state.marketProduct.product,
        }),

        nav() {
            const nav = [
                {
                    to: {
                        name: 'market-product-description',
                        params: this.$route.params,
                    },
                    label: 'Описание',
                },
                // {
                //     to: {
                //         name: 'market-product-offers',
                //         params: this.$route.params,
                //     },
                //     label: 'Цены',
                //     amount: this.product.offers_amount,
                // },
            ];
            if (this.product.properties.length) {
                nav.push({
                    to: {
                        name: 'market-product-specs',
                        params: this.$route.params,
                    },
                    label: 'Характеристики',
                });
            }
            nav.push({
                to: {
                    name: 'market-product-reviews',
                    params: this.$route.params,
                },
                label: 'Отзывы',
                amount: this.product.reviews_amount,
            });

            return nav;
        },
    },

    methods: {
        ...mapMutations({
            CHANGE_FAVORITE: 'marketProduct/changeFavorite',
        }),

        onChangeFavorite(value) {
            this.CHANGE_FAVORITE(value);
        },

        handlePointsClick(item) {
            const props = {
                item,
                choosable: false,
            };
            const options = { props };
            this.$popup(DeliveryOfficesPopup, options);
        },
    },
};
</script>

<style scoped>
.header {
    margin-top: var(--gap-y-medium);
    margin-bottom: var(--y-grid-gap);
}

.header-main {
    margin-bottom: 6px;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .header-main {
        display: flex;
        justify-content: space-between;
        padding: 36px;
    }
}

.product-main {
    flex-grow: 1;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .product-main {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .product-main {
        padding: 16px;
    }
}

@media (min-width: 768px)  {
    .rating-wrap {
        margin-top: 24px;
    }
}
@media (min-width: 768px) and (max-width: 767px) {
    .rating-wrap {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .rating-wrap {
        margin-top: 16px;
    }
}

.social-block_desktop {
    margin-top: 36px;
}
@media (max-width: 767px) {
    .social-block_desktop {
        display: none;
    }
}

.chat {
    margin-left: 12px;
}

@media (min-width: 768px) {
    .photo-block {
        display: none;
    }
}
@media (max-width: 767px) {
    .photo-block {
        margin-top: 16px;
    }
}

@media (min-width: 1281px) {
    .product-aside {
        width: 316px;
        margin-left: 36px;
        padding-left: 36px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .product-aside {
        width: 264px;
        margin-left: 24px;
        padding-left: 24px;
    }
}
@media (min-width: 1040px) {
    .product-aside {
        flex-shrink: 0;
        border-left: 1px solid var(--border-light-c);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .product-aside {
        display: flex;
        align-items: center;
        padding: 24px;
        border-top: 1px solid var(--border-light-c);
    }
}
@media (max-width: 767px) {
    .product-aside {
        padding: 16px;
    }
}

@media (max-width: 1039px) and (min-width: 768px) {
    .price-block {
        margin-left: auto;
        order: 2;
    }
}

.price {
    display: block;
    font-size: var(--h1-fz);
    font-family: var(--f-bold);
}

@media (min-width: 1040px) {
    .points-info {
        margin-top: 16px;
    }
}
@media (max-width: 1039px) and (min-width: 768px) {
    .points-info {
        order: 1;
    }
}
@media (max-width: 767px) {
    .points-info {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.delivery {
    margin-top: 4px;
}

@media (min-width: 768px) {
    .social-block_mobile {
        display: none;
    }
}

@media (min-width: 1040px) {
    .cart-block {
        margin-top: 36px;
    }
}
@media (max-width: 1039px) and (min-width: 768px) {
    .cart-block {
        margin-left: 20px;
        order: 3;
    }
}
@media (max-width: 767px) {
    .cart-block {
        margin-top: 12px;
    }
}

.action-btn {
    width: 100%;
}
</style>