<template>
    <div class="profile__main">
        <EmptyBlock
            v-if="current_count === 0"
            icon="heart-btn-stroke"
            title="Нет избранных производителей"
            :hasBtn="false"
        >
            <template slot="text">
                <p class="profile-empty-block__text">
                    Добавляйте производителей в избранное с помощью <span class="like-btn-wrap"><LikeBtn></LikeBtn></span>
                </p>
            </template>
        </EmptyBlock>

        <template v-else>
            <ol class="items-grid">
                <li
                    v-for="(item, index) in computedBrands"
                    :key="'brand-' + item.id"
                    class="item layer-1"
                >
                    <RouterLink
                        v-lazy-container="{ selector: 'img' }"
                        :to="{
                            name: 'market-brand',
                            params: {
                                codename: item.codename,
                            },
                        }"
                        class="item__logo-wrap"
                    >
                        <img
                            :data-src="item.logoUrl"
                            :alt="item.name"
                        >
                    </RouterLink>

                    <h3 class="h4 item__title">
                        <RouterLink
                            :to="{
                                name: 'market-brand',
                                params: {
                                    codename: item.codename,
                                },
                            }"
                            class="link-primary-hover"
                        >
                            {{ item.name }}
                        </RouterLink>
                    </h3>

                    <p class="item__country">
                        {{ item.country }}
                    </p>

                    <LikeBtn
                        :id="item.codename"
                        apiName="brand"
                        :value="true"
                        class="item__like-btn"
                        @changeFavorite="onChangeFavorite(index)"
                    ></LikeBtn>
                </li>
            </ol>

            <ButtonText
                v-if="hasNext"
                class="load-more-btn"
                dashed
                :loading="loading"
                @click="load"
            >
                Показать ещё
            </ButtonText>
        </template>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { HTTP } from '@/http.js';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';

export default {
    name: 'ProfileFavoritesBrands',

    metaInfo () {
        return {
            title: 'ЛК / Мои избранные бренды',
        };
    },

    components: { ButtonText, EmptyBlock, LikeBtn },

    data() {
        return {
            rangeData: {
                limit: 30,
                offset: 0,
                order_by: '-create_date',
            },
            source: null,
            loadingTimeout: null,
            loading: false,
        };
    },

    computed: {
        ...mapState({
            brands: state => state.profileFavorites.brands.items,
            current_count: state => state.profileFavorites.brands.current_count,
        }),

        computedBrands() {
            return this.brands.map(item => {
                let logo = require('../../../assets/images/logo-placeholder.svg');
                let logoUrl = ((item.logo || {}).thumbnails || {}).brand_logo_medium;

                if (logoUrl) {
                    logo = this.$links.uploads + logoUrl;
                }

                item.logoUrl = logo;
                return item;
            });
        },

        hasNext() {
            return (this.rangeData.offset + this.rangeData.limit) < this.current_count;
        },
    },

    mounted() {
        this.$breadcrumbs([]);
    },

    methods: {
        ...mapMutations({
            ADD_ITEMS: 'profileFavorites/addBrands',
            SET_LOADING: 'profileFavorites/setLoading',
        }),

        ...mapActions({
            GET_ITEMS: 'profileFavorites/getBrands',
        }),

        onChangeFavorite(index) {
            this.$store.commit('profileFavorites/remove', { index, type: 'brands' });
        },

        async fetch() {
            this.loadingTimeout = setTimeout(() => {
                this.loading = true;
            }, 300);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                params: this.rangeData,
                cancelToken: this.source.token,
            };

            const response = await this.GET_ITEMS(payload);
            if (response) {
                this.source = null;
                clearTimeout(this.loadingTimeout);
                return response;
            }
            this.loading = false;
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) this.ADD_ITEMS(response);
        },
    },
};
</script>

<style scoped src="../../../assets/css/profile.css"></style>
<style scoped>
@media (min-width: 768px) {
    .items-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 414px) and (max-width: 767px) {
    .items-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 414px) {
    .items-grid {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

.item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}
@media (min-width: 768px) {
    .item {
        padding: 20px;
    }
}
@media (max-width: 767px) {
    .item {
        padding: 16px;
    }
}
@media (max-width: 413px) {
    .item:not(:last-child) {
        margin-bottom: var(--grid-gap);
    }
}

.item__logo-wrap {
    display: block;
    width: 72px;
    height: 48px;
}
@media (min-width: 768px) {
    .item__logo-wrap {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .item__logo-wrap {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) {
    .item__title {
        margin-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .item__title {
        margin-bottom: 4px;
    }
}

.item__like-btn {
    position: absolute;
}
@media (min-width: 768px) {
    .item__like-btn {
        top: 20px;
        right: 20px;
    }
}
@media (max-width: 767px) {
    .item__like-btn {
        top: 16px;
        right: 16px;
    }
}
</style>