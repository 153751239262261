import cdek from '@/api/delivery/cdek.js';
import estimate from '@/api/delivery/estimate.js';
import pochtaru from '@/api/delivery/pochtaru.js';
import yandex from '@/api/delivery/yandex.js';
import { paramsSerializer } from '@/lib/paramsSerializer.js';


export default ({ HTTP }) => ({
    cdek: cdek({ HTTP }),
    estimate: estimate({ HTTP }),
    pochtaru: pochtaru({ HTTP }),
    yandex: yandex({ HTTP }),

    async offices(config) {
        const response = await HTTP.get('/delivery/offices/', {
            ...config,
            paramsSerializer,
        });
        return response.data;
    },

    async services(config) {
        const response = await HTTP.get('/delivery/services/', config);
        return response.data;
    },
});
