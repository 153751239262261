<template>
    <div class="cars-list">
        <CarsListItem
            v-for="(item, index) in computedCars"
            :key="listName + item.uuid + index"
            class="car"
            :manufacturer="item.manufacturer"
            :item="options[index]"
            noManufacturerName
            :class="{
                car_checked: index === value.index
            }"
            @click="onChange($event, item, index)"
        ></CarsListItem>
    </div>
</template>

<script>
import CarsListItem from "./CarsListItem.vue";

export default {
    name: "CarsList",

    components: {
        CarsListItem,
    },

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        options: {
            type: Array,
            default() {
                return [];
            },
        },

        value: {
            type: Object,
            default() {
                return {};
            },
        },

        listName: {
            type: String,
            default: '',
        },

        noManufacturerName: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        computedCars() {
            return this.options.map(item => {
                return item.car
                    ? item.car
                    : item;
            });
        },
    },

    methods: {
        onChange($event, value, index) {
            this.$emit('change', { ...value, index });
        },
    },
};
</script>

<style scoped>
.car {
    position: relative;
}
.car:not(:last-child) {
    margin-bottom: 16px;
}
</style>