<template>
    <div class="car">
        <div
            class="car__info"
            @click="$emit('click')"
        >
            <CarManufacturerLogo
                :manufacturer="manufacturer.mono_logo ? manufacturer : computedItem.manufacturer"
                mono
                class="car-manufacturer-logo-wrap"
            ></CarManufacturerLogo>

            <div class="car__name-wrap">
                <b class="d-block">
                    <span class="car-manufacturer_mobile">{{ computedItem.manufacturer.name }}</span>
                    <CarName
                        :car="computedItem"
                        noManufacturerName
                        :block="false"
                    ></CarName>
                </b>
                <span
                    v-if="item.grz"
                    class="d-block text_secondary hidden-s"
                >
                    {{ item.grz | carNumber }}
                </span>
                <span
                    v-else-if="item.vin_frame"
                    class="d-block text_secondary hidden-s"
                >
                    {{ item.vin_frame }}
                </span>
            </div>

            <CarCharacteristicsWithTooltip
                :car="computedItem"
                class="mt-4 car__characteristic-tooltip_desktop"
            ></CarCharacteristicsWithTooltip>

            <p
                v-if="item.grz"
                class="d-block mt-4 text_secondary hidden-m hidden-l hidden-xl"
            >
                {{ item.grz | carNumber }}
            </p>
            <p
                v-else-if="item.vin_frame"
                class="d-block mt-4 text_secondary hidden-m hidden-l hidden-xl"
            >
                {{ item.vin_frame }}
            </p>
        </div>

        <span class="car__characteristic-tooltip_mobile">
            <CarCharacteristicsTableTooltip
                :car="computedItem"
                class="car__tooltip"
            ></CarCharacteristicsTableTooltip>
        </span>
    </div>
</template>

<script>
import CarManufacturerLogo from './CarManufacturerLogo.vue';
import CarName from "./CarName.vue";
import CarCharacteristicsTableTooltip from '@/components/CarCharacteristicsTableTooltip.vue';
import CarCharacteristicsWithTooltip from '@/components/CarCharacteristicsWithTooltip.vue';

export default {
    name: "CarsListItem",

    components: {
        CarCharacteristicsWithTooltip,
        CarCharacteristicsTableTooltip,
        CarName,
        CarManufacturerLogo,
    },

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },

        manufacturer: {
            type: Object,
            default() {
                return {};
            },
        },

        noManufacturerName: Boolean,
    },

    computed: {
        computedItem() {
            return this.item.car
                ? this.item.car
                : this.item;
        },
    },
};
</script>

<style scoped>
.car {
    background-color: var(--light-c);
    border: 2px solid var(--light-c);
    border-radius: var(--border-radius-x2);
    box-shadow: var(--light-shadow);
    transition: box-shadow var(--transition), border-color var(--transition);
    cursor: pointer;
}
@media (max-width: 767px) {
    .car {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.car:hover,
.car:focus {
    box-shadow: var(--base-shadow);
}

@media (min-width: 768px) {
    .car__info {
        display: flex;
        align-items: center;
        padding: 20px 24px;
    }
}
@media (max-width: 767px) {
    .car__info {
        padding: 12px 16px;
        flex-grow: 1;
    }
}

.car_checked {
    border-color: var(--primary-color);
    box-shadow: none;
}

.car-manufacturer-logo-wrap {
    margin-right: 12px;
}
@media (max-width: 767px) {
    .car-manufacturer-logo-wrap {
        display: none;
    }
}

@media (min-width: 768px) {
    .car-manufacturer_mobile {
        display: none;
    }
}

@media (min-width: 1040px) {
    .car__name-wrap {
        width: 180px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .car__name-wrap {
        width: 160px;
    }
}
@media (min-width: 768px) {
    .car__name-wrap {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 16px;
    }
}

@media (max-width: 767px) {
    .car__params {
        margin-top: 4px;
    }
}

.car__tooltip {
    display: inline-block;
    margin-left: 8px;
    vertical-align: -2px;
    font-size: 0;
}

@media (min-width: 768px) {
    .car__characteristic-tooltip_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .car__characteristic-tooltip_desktop >>> .car__characteristic-tooltip {
        display: none;
    }

    .car__characteristic-tooltip_mobile {
        display: flex;
        align-items: center;
        align-self: stretch;
        padding: 12px 16px;
    }
}
</style>