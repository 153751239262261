<template>
    <div
        class="u-textarea-field"
        :class="{
            'u-textarea-field_disabled': disabled,
            'u-textarea-field_focused': isFocused,
            'u-textarea-field_invalid': invalid,
            'u-textarea-field_filled': !!value,
        }"
    >
        <div
            ref="border"
            class="u-textarea-field__border-box"
            @click="onClick"
            @mousedown="onMouseDown"
        >
            <UTextarea
                ref="textarea"
                class="u-textarea-field__input"
                v-bind="attrs"
                v-on="handlers"
            ></UTextarea>

            <ULabel
                v-if="label || $scopedSlots.label"
                :id="id"
                :active="isFocused || !!value"
                :invalid="invalid"
                :disabled="disabled"
            >
                <slot name="label">
                    {{ label }}
                </slot>
            </ULabel>

            <div class="u-textarea-field__icon-box u-textarea-field__append-inner">
                <UTooltip
                    v-if="hint || $scopedSlots.hint || $scopedSlots['hint-content']"
                    key="hint"
                >
                    <template #trigger="{ attrs, handlers, value }">
                        <UTooltipIcon
                            :active="value"
                            v-bind="attrs"
                            class="u-input-simple__icon"
                            v-on="handlers"
                        ></UTooltipIcon>
                    </template>

                    <template #content>
                        <slot name="hint-content">
                            <UTooltipSimpleTemplate>
                                <slot name="hint">
                                    {{ hint }}
                                </slot>
                            </UTooltipSimpleTemplate>
                        </slot>
                    </template>
                </UTooltip>

                <UIconButton
                    v-if="value"
                    key="close"
                    name="close-16"
                    small
                    secondary
                    tabindex="-1"
                    class="u-textarea-field__clear"
                    @click="clear"
                    @mousedown.native.stop=""
                ></UIconButton>
            </div>
        </div>
    </div>
</template>

<script>
import genId from '@ui/utils/genId.js';
import UTextarea from '@ui/components/UTextarea/UTextarea.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
import ULabel from '@ui/components/ULabel/ULabel.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';
import UTooltipSimpleTemplate from '@ui/components/UTooltip/UTooltipSimpleTemplate.vue';


export default {
    name: 'UTextareaField',

    components: {
        UTooltipSimpleTemplate,
        UTooltipIcon,
        UTooltip,
        ULabel,
        UIconButton,
        UTextarea,
    },

    model: {
        prop: 'value',
        event: 'change:model',
    },

    props: {
        // attrs
        id: {
            type: String,
            default: () => genId(),
        },
        name: String,
        value: String,
        label: String,
        placeholder: String,
        rows: {
            type: [String, Number],
            default: 3,
        },
        hint: String,
        contenteditable: Boolean,

        // state
        disabled: Boolean,
        invalid: Boolean,
    },

    data() {
        return {
            isFocused: false,
        };
    },

    computed: {
        attrs() {
            const {
                id,
                name,
                rows,
                value,
                invalid,
                disabled,
                placeholder,
                contenteditable,
            } = this;

            return {
                id,
                name,
                rows,
                value,
                invalid,
                disabled,
                placeholder,
                contenteditable,
            };
        },

        handlers() {
            return {
                input: this.onInput,
                focus: this.onFocus,
                blur: this.onBlur,
            };
        },

        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { input, ...listeners } = this.$listeners;
            return listeners;
        },

        methods() {
            const { clear } = this;
            return { clear };
        },
    },

    methods: {
        clear() {
            if (this.$refs.textarea) {
                this.$refs.textarea.clear();
            }
        },

        focus() {
            if (this.$refs.textarea) {
                this.$refs.textarea.focus();
            }
        },

        blur() {
            if (this.$refs.blur) {
                this.$refs.textarea.blur();
            }
        },

        onClick() {
            this.focus();
        },

        onMouseDown($event) {
            // чтобы не терялся фокус на инпуте
            if ($event.target === this.$refs.border) {
                $event.preventDefault();
            }
        },

        onFocus($event) {
            this.isFocused = true;
            this.$emit('focus', $event);
        },

        onBlur($event) {
            this.isFocused = false;
            this.$emit('blur', $event);
        },

        onInput($event) {
            this.$emit('input', $event);
            this.$emit('change:model', $event.value);
        },
    },
};
</script>

<style>
.u-textarea-field {
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    cursor: text;
}
.u-textarea-field.u-textarea-field_disabled {
    cursor: default;
}

.u-textarea-field__border-box {
    position: relative;;
    padding: 12px;
    display: flex;
    align-items: flex-start;
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
    transition: border-color var(--transition), background-color var(--transition);
}
.u-textarea-field.u-textarea-field_focused .u-textarea-field__border-box,
.u-textarea-field__border-box:hover {
    border-color: var(--fields-border);
}
.u-textarea-field.u-textarea-field_disabled .u-textarea-field__border-box {
    border: 1px solid var(--border-light-c);
    background-color: var(--bright-bg);
}
.u-textarea-field.u-textarea-field_small .u-textarea-field__border-box {
    max-height: 36px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.u-textarea-field.u-textarea-field_invalid .u-textarea-field__border-box {
    border-color: var(--primary-color);
}

.u-textarea-field__input {
    flex-grow: 1;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.u-textarea-field__icon-box {
    align-self: center;
    display: flex;
    align-items: center;
}

.u-textarea-field__append-inner {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 8px;
}

.u-textarea-field__clear {
    position: absolute;
    top: 14px;
    right: 12px;
}
</style>