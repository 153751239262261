<template>
    <section class="connect">
        <div class="connect__text-wrap">
            <h2 class="h2 h2_text connect__title">
                Вы — владелец магазина? <br> Подключайтесь!
            </h2>
            <p class="connect__text">
                Помогаем найти клиентов и&nbsp;экономим ваши деньги на&nbsp;разработке своего интернет-магазина.
            </p>
            <div class="connect__actions">
                <ButtonBlock
                    primary
                    :href="$server.partnerSignup"
                    target="_blank"
                    rel="noopener"
                    class="connect__btn_primary"
                >
                    Стать партнером
                </ButtonBlock>
            </div>
        </div>
    </section>
</template>

<script>
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';

export default {
    name: 'PartnerConnectSection',

    components: { ButtonBlock },
};
</script>

<style scoped>
.connect {
    position: relative;
    background-color: var(--dark-c);
    color: var(--light-c);
    overflow: hidden;
    border-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .connect {
        display: flex;
        padding: 36px 0 36px 36px;
    }
}
@media (max-width: 767px) {
    .connect {
        padding: 20px 20px 0;
    }
}

.connect::after {
    content: "";
    display: block;
    background-image: url(../assets/images/mp-index-connect.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
    .connect::after {
        background-image: url(../assets/images/mp-index-connect-x2.jpg);
    }
}

@media (min-width: 1040px) {
    .connect::after {
        width: 504px;
        height: 248px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .connect::after {
        width: 456px;
        height: 224px;
    }
}
@media (min-width: 768px) {
    .connect::after {
        flex-shrink: 0;
        margin: -20px auto -36px;
    }
}
@media (max-width: 767px) {
    .connect::after {
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 50%;
    }
}

.connect__text-wrap {
    position: relative;
    z-index: 2;
}
@media (min-width: 1281px) {
    .connect__text-wrap {
        width: 580px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .connect__text-wrap {
        width: 480px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .connect__text-wrap {
        width: 372px;
    }
}
@media (min-width: 768px) {
    .connect__text-wrap {
        flex-shrink: 0;
        margin-right: 24px;
    }
}

.connect__title {
    line-height: var(--small-lh);
}
@media (max-width: 767px) {
    .connect__title br {
        display: none;
    }
}

.connect__text {
    margin-bottom: var(--gap-y-mini);
    line-height: var(--base-lh);
}

@media (min-width: 768px) {
    .connect__actions {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .connect__btn_primary {
        margin-right: var(--gap-y-small);
    }
}
@media (max-width: 767px) {
    .connect__btn_primary {
        width: 100%;
    }
}
</style>