<template>
    <div
        class="market-item"
        :class="{
            'muted-block': !item.offer.amount,
        }"
    >
        <div class="market-item__info">
            <MarketItemCover
                :item="item.offer[modelName]"
                :modelName="modelName"
                coverCodename="micro"
                class="cover-wrap"
                isButton
                @click="showOfferInfo"
            ></MarketItemCover>

            <h3 class="title">
                <button
                    class="title-btn link-primary-hover"
                    @click="showOfferInfo"
                >
                    {{ item.offer[modelName] && item.offer[modelName].name }}
                </button>
            </h3>
        </div>

        <div
            v-if="item.offer.amount && item.offer.is_visible"
            class="price-wrap price-wrap_mobile"
        >
            <b class="price">
                {{ item.total_available_price | price }}
            </b>
            <p
                v-if="item.amount_in_basket > item.offer.amount && item.offer.amount !== 0"
                class="text_semi-bold text_secondary text_micro-fz"
            >
                Цена за {{ item.offer.amount }} шт.
            </p>
        </div>

        <div class="market-item__bottom">
            <template v-if="item.offer.amount && item.offer.is_visible">
                <span
                    v-if="modelName === 'contract_part'"
                    class="amount"
                >{{ item.amount_in_basket }} шт.</span>
                <div
                    v-else
                    class="counter-wrap"
                    :class="{
                        'counter-wrap_with-description': (item.amount_in_basket > item.offer.amount && item.offer.amount !== 0) || (item.offer.amount === 1),
                    }"
                >
                    <Counter
                        ref="counter"
                        :value="item.amount_in_basket"
                        :max="item.offer.amount < 100 ? item.offer.amount : 99"
                        class="counter"
                        @change="handleCounterChange"
                    ></Counter>
                    <p
                        v-if="item.amount_in_basket > item.offer.amount && item.offer.amount !== 0"
                        class="text_semi-bold text_red text_micro-fz mt-4"
                    >
                        В наличии: {{ item.offer.amount }} шт.
                    </p>
                    <p
                        v-else-if="item.offer.amount === 1"
                        class="text_semi-bold text_secondary text_micro-fz mt-4"
                    >
                        Последний товар
                    </p>
                </div>
            </template>

            <div class="market-item__price-actions-wrap">
                <div
                    v-if="item.offer.amount && item.offer.is_visible"
                    class="price-wrap price-wrap_desktop"
                >
                    <b class="price">
                        {{ item.total_available_price | price }}
                    </b>
                    <p
                        v-if="item.amount_in_basket > item.offer.amount && item.offer.amount !== 0"
                        class="text_semi-bold text_secondary text_micro-fz"
                    >
                        Цена за {{ item.offer.amount }} шт.
                    </p>
                </div>
                <div
                    v-else
                    class="market-item__amount-no-price-wrap"
                >
                    <span class="amount">{{ item.amount_in_basket }} шт.</span>

                    <span class="not-available-text">Нет в наличии</span>
                </div>

                <div class="market-item__actions">
                    <LikeBtn
                        :id="item.offer[modelName] && item.offer[modelName].id"
                        :apiName="modelName"
                        :value="item.offer[modelName] && item.offer[modelName].is_favorite"
                        class="market-item__action-btn"
                        @changeFavorite="onChangeFavorite"
                    ></LikeBtn>

                    <DeleteCartBtn
                        :id="item.offer.id"
                        :item="item"
                        :modelName="modelName"
                        class="market-item__action-btn"
                    ></DeleteCartBtn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { getErrorCode } from '@/lib/errors.js';
import { mapActions, mapMutations } from 'vuex';
import Counter from './Counter.vue';
import LikeBtn from './project-buttons/LikeBtn.vue';
import DeleteCartBtn from './project-buttons/DeleteCartBtn.vue';
import MarketItemCover from './MarketItemCover.vue';
const MarketItemInfoWithOfferPopup = () => import('@/components/popups/MarketItemInfoWithOfferPopup.vue');

export default {
    name: 'MarketItemCart',

    components: { MarketItemCover, DeleteCartBtn, Counter, LikeBtn },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },
    },

    data() {
        return {
            cancelToken: null,
        };
    },

    methods: {
        ...mapMutations({
            CHANGE_FAVORITE: 'cart/changeFavorite',
        }),

        ...mapActions({
            ADD_TO_CART: 'cart/addToCart',
        }),

        handleCounterChange(value) {
            const data = {
                offer: this.item.offer.id,
                amount: value,
            };

            if (this.cancelToken) this.cancelToken.cancel();
            this.cancelToken = HTTP.CancelToken.source();

            this.ADD_TO_CART({ data, cancelToken: this.cancelToken.token })
                .then(() => {
                    this.$refs.counter.updateValue(this.item.amount_in_basket);
                })
                .catch(error => {
                    const code = getErrorCode(error);

                    if (code === 400) {
                        const data = error.response.data;
                        const { amount, offer, non_field_errors } = data;
                        if (amount) {
                            this.$error(amount[0].message);
                        }
                        else if (offer) {
                            this.$error(offer[0].message);
                        }
                        else if (non_field_errors) {
                            this.$error(non_field_errors[0].message);
                        }
                        else {
                            this.$error('Не удалось добавить в корзину');
                        }
                    }
                });
        },

        onChangeFavorite(value) {
            this.CHANGE_FAVORITE({
                id: this.item.offer[this.modelName].id,
                type: this.modelName,
                value,
            });
        },

        showOfferInfo() {
            const props = {
                offer: this.item.offer,
                marketItemId: this.item.offer[this.item.offer.offer_type].id,
                modelName: this.item.offer.offer_type,
            };
            this.$popup(MarketItemInfoWithOfferPopup, { props });
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .market-item {
        display: flex;
        align-items: center;
    }
}

.market-item__info {
    display: flex;
    align-items: center;
}
@media (min-width: 1040px) {
    .market-item__info {
        margin-right: 20px;
        flex-shrink: 1;
        flex-grow: 1;
    }
}

.market-item__bottom {
    display: flex;
}
@media (min-width: 1040px) {
    .market-item__bottom {
        align-items: center;
        flex-shrink: 0;
    }
}
@media (max-width: 1039px) {
    .market-item__bottom {
        align-items: flex-start;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .market-item__bottom {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .market-item__bottom {
        margin-top: 12px;
        justify-content: space-between;
    }
}

@media (min-width: 768px){
    .cover-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .cover-wrap {
        margin-right: 12px;
    }
}

.title {
    display: inline-block;
    flex-grow: 1;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}

.title-btn {
    text-align: left;
}

.market-item__amount-no-price-wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
}
@media (min-width: 768px) {
    .market-item__amount-no-price-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .market-item__amount-no-price-wrap {
        align-self: center;
    }

    .market-item__amount-no-price-wrap .amount {
        display: none;
    }
}

.amount {
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 1040px) {
    .amount {
        width: 106px;
        text-align: center;
    }
}

.not-available-text {
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}

@media (min-width: 1040px) {
    .counter-wrap_with-description {
        height: 36px;
    }
}

@media (min-width: 1040px) {
    .market-item__price-actions-wrap {
        margin-left: 20px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .market-item__price-actions-wrap {
        margin-left: auto;
    }
}
@media (min-width: 768px) {
    .market-item__price-actions-wrap {
        display: flex;
        align-items: center;
    }
}

.price-wrap {
    display: block;
    text-align: right;
}
@media (min-width: 1040px) {
    .price-wrap {
        width: 112px;
    }
}
@media (min-width: 768px) {
    .price-wrap {
        margin-right: 20px;
    }

    .price-wrap_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .price-wrap_mobile {
        margin-top: 12px;
    }

    .price-wrap_desktop {
        display: none;
    }
}
</style>
