export const API_URL_PREFIX = '/mp/api/v1';
export const SELECTOR_LIMIT = 50;
export const PAGE_LIMIT = 50;
export const SELECTOR_SEARCH_PARAM = 'instant_search';
export const DEBOUNCE_DURATION = 500;
export const DAYS_TO_RECEIVE_ORDER = 14;
export const HOURS_TO_SHOP_CLOSE = 2;
export const YANDEX_CLAIM_POLLING_TIMEOUT = 5000;
export const YANDEX_DELIVERY_PRICE_POLLING_TIMEOUT = 50000;
export const PAYMENT_REDIRECT_TIMEOUT = 5000;
export const KEY = 'M5Vekver0nFbTqdvw2bnvdeH';
export const YANDEX_API_KEY = '05f4846d-7a09-46b7-91ae-27b40e6d4e84';
// export const YANDEX_API_KEY = '9fac1df3-f402-4f37-890c-8f7c9179248b';
export const YANDEX_METRIKA_ID = 90514442;

// todo: вынести в отдельный файл и положить рядом с компонентом поиска авто
export const mainAttrsArrForSelectCarByModel = {
    'toyota': [
        {
            codename: 'frame',
            label: 'Кузов',
        },
        {
            codename: 'engine_1',
            label: 'Двигатель',
        },
    ],
    'lexus': [
        {
            codename: 'frame',
            label: 'Кузов',
        },
        {
            codename: 'engine_1',
            label: 'Двигатель',
        },
    ],
    'kia': [
        {
            codename: 'body_type',
            label: 'Кузов',
        },
        {
            codename: 'engine_type',
            label: 'Двигатель',
        },
        {
            codename: 'engine_capacity',
            label: 'Объем двигателя',
        },
    ],
    'hyundai': [
        {
            codename: 'body_type',
            label: 'Кузов',
        },
        {
            codename: 'engine_type',
            label: 'Двигатель',
        },
        {
            codename: 'engine_capacity',
            label: 'Объем двигателя',
        },
    ],
    'nissan': [
        {
            codename: 'frame',
            label: 'Кузов',
        },
        {
            codename: 'engine',
            label: 'Двигатель',
        },
    ],
    'infiniti': [
        {
            codename: 'body',
            label: 'Кузов',
        },
        {
            codename: 'engine',
            label: 'Двигатель',
        },
    ],
};
export const selectionComponentFields = [
    'tire_width',
    'tire_height',
    'tire_diameter',
    'tire_season',
    'tire_spikes',
    'rim_width',
    'rim_diameter',
    'rim_et',
    'rim_pcd',
    'rim_dia',
];