var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'spinner',
        'spinner_' + _vm.size,
        _vm.light && 'spinner_light',
        _vm.dark && 'spinner_dark',
        _vm.page && 'spinner_page',
        _vm.page && 'spinner_big',
        _vm.center && 'spinner_center',
        _vm.absoluteCenter && 'spinner_absolute-center' ]},[_c('UIcon',{staticClass:"spinner__icon",attrs:{"name":"spinner"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }