<template>
    <div
        class="insurance-options__row layer-1"
        :class="{
            'insurance-options__row_unavailable': calculateIsCompleted && !offer.price,
        }"
    >
        <div class="insurance-options__company-wrap">
            <InsuranceCompanyLogo
                :logo="offer.company.logo"
                :name="offer.company.page_title"
                :disabled="calculateIsCompleted && !offer.price"
                class="insurance-options__logo"
            ></InsuranceCompanyLogo>

            <div class="insurance-options__company">
                <p class="insurance-options__company-name">
                    {{ offer.company.name }}
                </p>
                <p class="insurance-options__license">
                    {{ offer.company.license }}
                </p>
            </div>
        </div>

        <div class="insurance-options__offer-wrap">
            <div class="insurance-options__column-price">
                <div
                    v-if="!calculateIsCompleted && !offer.price"
                    class="flex justify-content-end align-items-center"
                >
                    <Spinner class="insurance-options__spinner mr-12"></Spinner>
                    <span class="insurance-options__price-unavailable">Идёт расчёт</span>
                </div>

                <span
                    v-if="offer.price"
                    class="insurance-options__price"
                >{{ offer.price | floorPrice }}</span>

                <span
                    v-if="calculateIsCompleted && !offer.price"
                    class="insurance-options__price-unavailable"
                >Расчёт недоступен</span>
            </div>

            <ButtonBlock
                primary
                class="insurance-options__button-apply insurance-options__button-apply_desktop"
                :loading="offerLoading"
                :disabled="!offer.price || offerDisabled"
                @click="selectOffer"
            >
                Оформить полис
            </ButtonBlock>

            <ButtonBlock
                primary
                low
                class="insurance-options__button-apply insurance-options__button-apply_mobile"
                :loading="offerLoading"
                :disabled="!offer.price || offerDisabled"
                @click="selectOffer"
            >
                Оформить
            </ButtonBlock>
        </div>
    </div>
</template>

<script>
import YandexMetrika from "@/mixins/yandex-metrika.js";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import InsuranceCompanyLogo from "@/pages/insurance/InsuranceCompanyLogo.vue";
import Spinner from "@/components/Spinner.vue";

export default {
    name: 'InsuranceOffer',

    components: {
        Spinner,
        InsuranceCompanyLogo,
        ButtonBlock,
    },

    mixins: [YandexMetrika],

    props: {
        offer: {
            type: Object,
            default: () => {},
        },

        activeOffer: Object,
        loading: Boolean,

        calculateIsCompleted: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isActiveOffer() {
            if (this.activeOffer && typeof this.activeOffer === 'object') {
                return this.activeOffer.uuid === this.offer.uuid;
            }

            return false;
        },

        offerLoading() {
            return this.loading && this.isActiveOffer;
        },

        offerDisabled() {
            return this.loading && !this.isActiveOffer;
        },
    },

    methods: {
        selectOffer() {
            if (this.offer.company.code) {
                const codesGoalsMap = {
                    maks: 'osago_oformlenie_maks',
                    alfa: 'osago_oformlenie_alfa',
                    mafin: 'osago_oformlenie_Mafin',
                    vsk: 'osago_oformlenie_vsk',
                    sogl: 'osago_oformlenie_soglasie',
                    ingos: 'osago_oformlenie_ingostrah',
                    renessans: 'osago_oformlenie_renessans',
                    osk: 'osago_oformlenie_osk',
                    sogaz: 'osago_oformlenie_sogaz',
                    rosgosstr: 'osago_oformlenie_rosgosstr',
                    tinkoff: 'osago_oformlenie_tinkoff',
                };

                if (codesGoalsMap[this.offer.company.code]) {
                    this.handleYandexMetrikaGoal(codesGoalsMap[this.offer.company.code]);
                }
            }

            this.$emit('select', this.offer);
        },
    },
};
</script>

<style scoped>
.insurance-options__row {
    position: relative;
}
@media (min-width: 1040px) {
    .insurance-options__row {
        padding: 16px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .insurance-options__row {
        padding: 24px;
        justify-content: space-between;
    }
}
@media (min-width: 768px) {
    .insurance-options__row {
        display: flex;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .insurance-options__row {
        padding: 16px;
    }
}

.insurance-options__row:not(:last-child) {
    margin-bottom: var(--y-grid-gap);
}

.insurance-options__company-wrap {
    flex-grow: 1;
}
@media (min-width: 768px) {
    .insurance-options__company-wrap {
        display: flex;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .insurance-options__company-wrap {
        margin-bottom: 12px;
    }
}

@media (min-width: 1040px) {
    .insurance-options__logo {
        margin-right: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .insurance-options__logo {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .insurance-options__logo {
        width: max-content;
        margin-bottom: 8px;
    }
}

.insurance-options__company-name {
    margin-bottom: 4px;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}

.insurance-options__row_unavailable .insurance-options__company-name {
    color: var(--font-secondary-color);
}

.insurance-options__license {
    color: var(--font-secondary-color)
}
@media (max-width: 767px) {
    .insurance-options__license {
        font-size: var(--small-fz);
    }
}

.insurance-options__offer-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .insurance-options__offer-wrap {
        display: block;
        text-align: right;
        margin-left: 20px;
    }
}

@media (min-width: 1040px) {
    .insurance-options__column-price {
        width: 160px;
        flex-shrink: 0;
        margin-left: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .insurance-options__column-price {
        margin-bottom: 12px;
    }
}
@media (min-width: 768px) {
    .insurance-options__column-price {
        text-align: right;
    }
}

.insurance-options__price {
    display: block;
    font-size: var(--h3-fz);
    font-family: var(--f-bold);
}

.insurance-options__price-unavailable {
    font-family: var(--f-semi-bold);
    color: var(--primary-color)
}

@media (min-width: 1040px) {
    .insurance-options__button-apply {
        margin-left: 36px;
    }
    .insurance-options__button-apply_mobile {
        display: none;
    }
}
@media (max-width: 1039px) {
    .insurance-options__button-apply_desktop {
        display: none;
    }
}
@media (max-width: 767px) {
    .insurance-options__button-apply {
        margin-left: 12px;
    }
}
</style>