<template>
    <div
        class="market-item"
        :class="{
            'muted-block': !item.offer.amount,
        }"
    >
        <div class="market-item__info">
            <MarketItemCover
                :key="`cover-${ item.offer.id }`"
                :item="item.offer[modelName]"
                :modelName="modelName"
                coverCodename="micro"
                class="cover-wrap"
                isButton
                @click="showOfferInfo"
            ></MarketItemCover>

            <h3 class="title">
                <button
                    class="title-btn link-primary-hover"
                    @click="showOfferInfo"
                >
                    {{ item.offer[modelName] && item.offer[modelName].name }}
                </button>
            </h3>
        </div>

        <div class="price-wrap price-wrap_xs">
            <b class="price">
                {{ item.total_price | price }}
            </b>
            <p
                v-if="item.amount_in_basket > item.offer.amount && item.offer.amount !== 0"
                class="text_semi-bold text_secondary text_micro-fz"
            >
                Цена за {{ item.offer.amount }} шт.
            </p>
        </div>

        <div class="market-item__bottom">
            <template v-if="item.offer.amount && item.offer.is_visible">
                <span
                    v-if="item.amount_in_basket === 1 && item.offer.amount === 1 || modelName === 'contract_part'"
                    class="amount"
                >{{ item.amount_in_basket }} шт.</span>
                <div
                    v-else-if="modelName !== 'contract_part'"
                    class="counter-wrap"
                    :class="{
                        'counter-wrap_top-offset': item.amount_in_basket > item.offer.amount && item.offer.amount !== 0,
                    }"
                >
                    <Counter
                        :value="item.amount_in_basket"
                        :max="item.offer.amount"
                        class="counter"
                        @change="handleCounterChange"
                    ></Counter>
                    <p
                        v-if="item.amount_in_basket > item.offer.amount && item.offer.amount !== 0"
                        class="text_semi-bold text_red text_micro-fz mt-4"
                    >
                        В наличии: {{ item.offer.amount }} шт.
                    </p>
                </div>

                <div class="price-wrap price-wrap_desktop">
                    <b class="price">
                        {{ item.total_price | price }}
                    </b>
                    <p
                        v-if="item.amount_in_basket > item.offer.amount && item.offer.amount !== 0"
                        class="text_semi-bold text_secondary text_micro-fz"
                    >
                        Цена за {{ item.offer.amount }} шт.
                    </p>
                </div>
            </template>
            <div
                v-else
                class="market-item__amount-no-price-wrap"
            >
                <span class="amount">{{ item.amount_in_basket }} шт.</span>

                <span class="not-available-text">Нет в наличии</span>
            </div>

            <div class="market-item__actions">
                <DeleteCartBtn
                    :id="item.offer.id"
                    :item="item"
                    :modelName="modelName"
                ></DeleteCartBtn>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { getErrorCode } from '@/lib/errors.js';
import { mapActions } from 'vuex';
import bus from '@/bus.js';
import Counter from './Counter.vue';
import DeleteCartBtn from './project-buttons/DeleteCartBtn.vue';
import MarketItemCover from './MarketItemCover.vue';
const MarketItemInfoWithOfferPopup = () => import('@/components/popups/MarketItemInfoWithOfferPopup.vue');

export default {
    name: 'MarketItemPreviewCart',

    components: { MarketItemCover, DeleteCartBtn, Counter },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },
    },

    data() {
        return {
            cancelToken: null,
        };
    },

    methods: {
        ...mapActions({
            ADD_TO_CART: 'cart/addToCart',
        }),

        handleCounterChange(value) {
            const data = {
                offer: this.item.offer.id,
                amount: value,
            };

            if (this.cancelToken) this.cancelToken.cancel();
            this.cancelToken = HTTP.CancelToken.source();

            this.ADD_TO_CART({ data, cancelToken: this.cancelToken.token })
                .then(() => {
                    bus.$emit('changeItemInCartPreview', { offer: this.item.offer, value });
                })
                .catch(error => {
                    const code = getErrorCode(error);
                    const data = error.response.data;

                    if (code === 400) {
                        const { amount, offer, non_field_errors } = data;
                        if (amount) {
                            this.$error(amount[0].message);
                        }
                        else if (offer) {
                            this.$error(offer[0].message);
                        }
                        else if (non_field_errors) {
                            this.$error(non_field_errors[0].message);
                        }
                        else {
                            this.$error('Не удалось добавить в корзину');
                        }
                    }
                });
        },

        showOfferInfo() {
            const props = {
                offer: this.item.offer,
                marketItemId: this.item.offer[this.modelName].id,
                modelName: this.modelName,
            };
            this.$popup(MarketItemInfoWithOfferPopup, { props });
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .market-item {
        display: flex;
        align-items: center;
    }
}

.market-item__info,
.market-item__bottom {
    display: flex;
    align-items: center;
}

.market-item__bottom {
    flex-grow: 1;
}
@media (max-width: 767px) {
    .market-item__bottom {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .market-item__bottom {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
    }
}

@media (min-width: 768px){
    .cover-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .cover-wrap {
        margin-right: 12px;
    }
}

.title {
    display: inline-block;
    width: 222px;
    margin-right: 20px;
    flex-shrink: 0;
    font-size: var(--small-fz);
    font-family: var(--f-bold);
}

.title-btn {
    text-align: left;
}

@media (min-width: 768px) {
    .market-item__amount-price-wrap {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        flex-grow: 1;
    }
}

.market-item__amount-no-price-wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
}
@media (min-width: 768px) {
    .market-item__amount-no-price-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .market-item__amount-no-price-wrap {
        margin-left: 20px;
        flex-wrap: wrap;
        align-self: center;
    }

    .market-item__amount-no-price-wrap .amount {
        margin-right: 20px;
    }
}

.amount {
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .amount {
        width: 108px;
        text-align: center;
    }
}

.not-available-text {
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}

.price-wrap {
    display: block;
    text-align: right;
}
@media (min-width: 768px) {
    .price-wrap {
        margin-left: auto;
        margin-right: 20px;
    }

    .price-wrap_xs {
        display: none;
    }
}
@media (max-width: 767px) {
    .price-wrap_xs {
        margin-top: 20px;
        margin-bottom: -20px;
    }

    .price-wrap_desktop {
        display: none;
    }
}

@media (min-width: 1281px) {
    .counter-wrap_top-offset {
        margin-top: 18px;
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .counter-wrap_top-offset ~ .price-wrap,
    .counter-wrap_top-offset ~ .market-item__actions {
        align-self: flex-start;
    }
}
@media (max-width: 767px) {
    .counter-wrap {
        margin-top: 16px;
        text-align: right;
    }

    .counter-wrap_top-offset ~ .market-item__actions {
        margin-bottom: 18px;
    }
}
</style>
