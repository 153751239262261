<template>
    <Selector
        ref="selector"
        :value="innerValue"
        :options="options"
        isAsync
        class="input-address__selector"
        :loading="loading"
        :optionLabel="optionLabel"
        :getOptionKey="getOptionKey"
        :clearInputOnFocus="false"
        :clearInputOnBlur="false"
        :clearInputOnSelect="false"
        v-bind="$attrs"
        @input="onInput"
        @change="onChange"
        @open="onOpen"
        @close="onClose"
        @blur="onBlur"
        @focus="onFocus"
    ></Selector>
</template>

<script>
import equals from '@ui/utils/equals.js';
import Selector from '@/components/_inputs/Selector.vue';


export default {
    name: 'InputAddress',

    components: {
        Selector,
    },

    inheritAttrs: false,

    props: {
        value: [String, Object],

        searchParam: {
            type: String,
            default: 'geocode',
        },

        optionLabel: {
            type: String,
            default: 'value',
        },

        getOptionKey: {
            type: Function,
            default(option) {
                return option.fias_id + option.value;
            },
        },

        reduceResponse: {
            type: Function,
            default(value) {
                return value;
            },
        },

        queryOptions: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            axios: null,
            inputValue: '', // input
            innerValue: null, // option
            options: [],
            loading: false,
            source: null,
            debounce: null,
            open: null,
            focused: false,
        };
    },

    computed: {
        params() {
            return {
                results: this.$formManager.options.address.results,
            };
        },

        url() {
            return '/addresses/'; // todo: options
        },
    },

    watch: {
        value: {
            handler(value) {
                if (!equals(value, this.innerValue)) {
                    this.innerValue = value;
                    this.inputValue = value ? value[this.optionLabel] : '';
                }
            },

            immediate: true,
        },

        innerValue: {
            handler(value) {
                if (!equals(value, this.value)) {
                    this.$emit('change', value);
                }
            },
        },

        inputValue: {
            handler(value) {
                this.$emit('changeInputValue', value);
            },

            immediate: true,
        },
    },

    mounted() {
        this.axios = this.$formManager.options.address.axios; // todo: default axios
    },

    methods: {
        async onOpen(open) {
            this.open = open;

            if (this.inputValue) {
                await this.fetchOptions();
            }

            this.$emit('open');
        },

        onInput(value) {
            this.innerValue = null;
            this.inputValue = value;


            if (value) {
                this.fetchOptions();
            }

            this.$emit('input', value);
        },

        onChange(value) {
            this.innerValue = value;
            this.inputValue = value[this.optionLabel];
        },

        onClose() {
            this.options = [];

            this.$emit('close');
        },

        onFocus() {
            this.focused = true;

            this.$emit('focus');
        },

        onBlur() {
            this.focused = false;

            this.$emit('blur');
        },

        async fetchOptions() {
            if (this.inputValue && this.axios) {
                this.loading = true;

                if (this.debounce) {
                    clearTimeout(this.debounce);
                    this.debounce = null;
                }

                const url = this.url;
                const params = {
                    ...this.params,
                    ...this.queryOptions,
                    q: this.inputValue, // todo: searchParam
                };

                this.debounce = setTimeout(async () => {
                    if (this.source) {
                        this.source.cancel();
                        this.source = null;
                    }

                    this.source = this.axios.CancelToken.source();
                    const cancelToken = this.source.token;

                    try {
                        const [{ data: { results } }] = await Promise.all([
                            this.axios.get(url, { params, cancelToken }),
                            this.delay(),
                        ]);

                        this.options = this.reduceResponse(results);

                        // const members = this.getMembers(response);
                        // this.options = this.reduceMembers(members);
                        this.loading = false;

                        if (this.focused && this.open && typeof this.open === 'function') {
                            this.open();
                            this.open = null;
                        }
                    }
                    catch (error) {
                        if (this.axios.isCancel(error)) return;
                        this.loading = false;
                        this.$formManager.options.address.handleHttpError(error);
                    }
                }, this.$formManager.options.address.debounce);
            }
        },

        delay() {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve();
                }, this.$formManager.options.address.delay);
            });
        },
    },
};
</script>