<template>
    <span class="timer">
        {{ timeText }}
    </span>
</template>

<script>
import plural from "../lib/plural.js";

export default {
    name: "BackTimerText",

    props: {
        seconds: {
            type: Number,
            default: 0,
        },

        withSeconds: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            interval: null,
            timeout: null,
            tickSeconds: 0,
        };
    },

    computed: {
        timeText() {
            let date = new Date(0);
            date.setSeconds(this.tickSeconds);

            let daysString = '';
            let days = Math.floor(this.tickSeconds / (3600 * 24));
            if (days > 0) daysString = days + ' ' + plural(days, ['день', 'дня', 'дней']) + ' ';

            let timeString = date.toISOString().substr(11, 8).split(':');

            let hours = '';
            let hoursNumberTimeString = Number(timeString[0]);
            if (hoursNumberTimeString > 0) {
                hours = hoursNumberTimeString + ' ч ';
            }

            let minutes = '';
            let minutesNumberTimeString = Number(timeString[1]);
            if (minutesNumberTimeString > 0) {
                minutes = minutesNumberTimeString + ' мин ';
            }

            let seconds = '';
            let secondsNumberTimeString = Number(timeString[2]);
            if (secondsNumberTimeString > 0) {
                seconds = secondsNumberTimeString + ' сек';
            }

            if (this.withSeconds) {
                return daysString + hours + minutes + seconds;
            }
            else {
                if (!daysString && !hours && !minutes) {
                    return seconds;
                }
                else {
                    return daysString + hours + minutes;
                }
            }
        },
    },

    mounted() {
        this.tickSeconds = this.seconds;

        this.interval = setInterval(() => {
            if (this.tickSeconds > 0) {
                this.tickSeconds = this.tickSeconds - 1;
            }
            else {
                clearInterval(this.interval);
            }
        }, 1000);

        this.timeout = setTimeout(() => {
            this.$emit('timeOver');
        }, this.seconds * 1000);
    },

    beforeDestroy() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
    },
};
</script>

<style scoped>
.timer {
    white-space: nowrap;
}
</style>
