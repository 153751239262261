<template>
    <div
        v-show="show"
        class="selection_mobile"
    >
        <div class="header">
            <ButtonIcon
                :secondary="false"
                :icon="{
                    name: 'arrow-left',
                    big: true,
                }"
                type="button"
                class="header__btn"
                :class="{
                    'header__btn_on-start-hidden': screen === 'start',
                }"
                aria-label="На стартовый экран"
                @click="setScreen('start')"
            ></ButtonIcon>

            <h4 class="h4">
                {{ title }}
            </h4>

            <ButtonIcon
                :secondary="false"
                :icon="{
                    name: 'cross',
                    big: true,
                    secondary: true,
                    hovered: true,
                }"
                type="button"
                class="header__btn"
                aria-label="Закрыть меню подбора"
                @click="onClose"
            ></ButtonIcon>
        </div>

        <div
            v-if="screen === 'start'"
            class="navigation"
        >
            <RadioButtonsGroup
                v-model="mode"
                name="mode"
                type="text"
                :options="[
                    {
                        label: 'По авто',
                        value: 'car',
                    },
                    {
                        label: 'По параметрам',
                        value: 'params',
                    },
                ]"
                class="navigation-widget"
            ></RadioButtonsGroup>
        </div>

        <div
            v-if="screen === 'start' && currentFilledScreens"
            class="summary"
        >
            <span class="text_secondary text_semi-bold">Выбрано: {{ currentFilledScreens }}</span>
            <ButtonText
                dashed
                primary
                @click="resetAll"
            >Сбросить все</ButtonText>
        </div>

        <div
            v-if="screen !== 'start' && screenData.options.length > 20 && screen !== 'template_sizes'"
            class="selection__search-block"
        >
            <InputText
                v-model.trim="searchQuery"
                placeholder="Поиск"
                :prependIcon="{
                    name: 'search',
                    secondary: true,
                    small: true,
                }"
                small
                autocomplete="off"
            ></InputText>
        </div>

        <div class="body">
            <template v-if="screen === 'start'">
                <button
                    v-for="item in screenData"
                    :key="item.screen"
                    :disabled="item.disabled"
                    class="start-menu-btn"
                    @click="setScreen(item.screen)"
                >
                    <span class="flex align-items-center justify-content-between">
                        <span class="text_bold">
                            {{ item.label }}
                        </span>
                        <UIcon
                            name="arrow-right"
                            small
                            secondary
                            hovered
                        ></UIcon>
                    </span>

                    <span
                        v-if="item.value || item.name"
                        class="d-block mt-4"
                    >
                        {{ item.value.name || item.name }}
                    </span>
                </button>

                <div
                    v-if="mode === 'params'"
                    class="body__section"
                >
                    <ButtonText
                        v-if="category === 'tire'"
                        dashed
                        secondary
                        dark
                        class="mt-12"
                        @click="showTireSelectionInfoPopup"
                    >
                        Подробнее о параметрах
                    </ButtonText>
                    <ButtonText
                        v-else
                        dashed
                        secondary
                        dark
                        class="mt-12"
                        @click="showRimSelectionInfoPopup"
                    >
                        Подробнее о параметрах
                    </ButtonText>
                </div>
            </template>
            <template v-else-if="screen === 'template_sizes'">
                <Spinner
                    v-if="screenLoading"
                    absoluteCenter
                ></Spinner>
                <template v-else>
                    <div
                        v-for="section in templateSizesScreenDataOptions"
                        class="options-section"
                    >
                        <p class="text_secondary text_semi-bold mb-4">
                            {{ section.title }}
                        </p>
                        <template
                            v-for="(option, indexOption) in section.options"
                        >
                            <div v-if="option.has_pair" class="options-list__group">
                                <RadioBase
                                    :id="'filter-radio-' + indexOption"
                                    :key="'filter-radio-' + indexOption + 'front'"
                                    v-model="screenValue"
                                    :value="option.front"
                                    :valueKey="option.front[category] ? category : option.front.slug? 'slug' : 'id'"
                                    class="options-list__item"
                                >
                                    <div class="flex align-items-center justify-content-between">
                                        {{ option.front.name }}
                                        <span class="text_secondary text_small-fz">
                                            Передняя ось
                                        </span>
                                    </div>
                                </RadioBase>
                                <RadioBase
                                    :id="'filter-radio-' + indexOption"
                                    :key="'filter-radio-' + indexOption + 'rear'"
                                    v-model="screenValue"
                                    :value="option.rear"
                                    :valueKey="option.rear[category] ? category : option.rear.slug? 'slug' : 'id'"
                                    class="options-list__item"
                                >
                                    <div class="flex align-items-center justify-content-between">
                                        {{ option.rear.name }}
                                        <span class="text_secondary text_small-fz">
                                            Задняя ось
                                        </span>
                                    </div>
                                </RadioBase>
                            </div>
                            <RadioBase
                                v-else
                                :id="'filter-radio-' + indexOption"
                                :key="'filter-radio-' + indexOption"
                                v-model="screenValue"
                                :value="option"
                                :valueKey="option[category] ? category : option.slug? 'slug' : 'id'"
                                class="options-list__item"
                            >
                                {{ option.name }}
                            </RadioBase>
                        </template>
                    </div>
                </template>
            </template>
            <template v-else>
                <Spinner
                    v-if="screenLoading"
                    absoluteCenter
                ></Spinner>
                <template v-else>
                    <div
                        v-if="screenData.widget === 'radio'"
                        class="options-list"
                    >
                        <RadioBase
                            v-for="(option, indexOption) in screenDataOptions"
                            :id="'filter-radio-' + indexOption"
                            :key="'filter-radio-' + indexOption"
                            v-model="screenValue"
                            :value="option"
                            :valueKey="option[category] ? category : option.slug? 'slug' : 'id'"
                            class="options-list__item"
                        >
                            {{ option.name }}
                        </RadioBase>
                    </div>
                    <div
                        v-else-if="screenData.widget === 'checkboxes'"
                        class="options-list"
                    >
                        <CheckboxBase
                            v-for="(option, indexOption) in screenDataOptions"
                            :id="'filter-checkbox-' + indexOption"
                            :key="'filter-checkbox-' + indexOption"
                            v-model="screenValue"
                            :value="option"
                            class="options-list__item"
                        >
                            {{ option.name }}
                        </CheckboxBase>
                    </div>
                </template>
            </template>
        </div>

        <div class="footer">
            <ButtonBlock
                primary
                w100
                :loading="canBtnShowSpinner"
                :disabled="btn.disabled"
                @click="handleBtnClick"
            >
                {{ btn.label }}
            </ButtonBlock>
        </div>
    </div>
</template>

<script>
import { selectionComponentFields } from '@/settings.js';
import plural from '@/lib/plural.js';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import Spinner from '@/components/Spinner.vue';
import RadioBase from '@/components/fields/RadioBase.vue';
import CheckboxBase from '@/components/fields/CheckboxBase.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import breakpointKey from '@/mixins/breakpointKey.js';
import InputText from '@/components/_inputs/InputText.vue';
import RadioButtonsGroup from '@/components/RadioButtonsGroup.vue';
const TireSelectionInfoPopup = () => import('@/components/popups/TireSelectionInfoPopup.vue');
const RimSelectionInfoPopup = () => import('@/components/popups/RimSelectionInfoPopup.vue');

export default {
    name: "MobileUI",

    components: {
        RadioButtonsGroup,
        InputText,
        UIcon,
        CheckboxBase,
        RadioBase,
        ButtonIcon,
        ButtonBlock,
        ButtonText,
        Spinner,
    },

    mixins: [breakpointKey],

    props: {
        category: {
            type: String,
            default: '',
            validator(value) {
                return ['rim', 'tire'].includes(value);
            },
        },

        dataset: {
            type: Object,
            default() {
                return {};
            },
        },

        show: Boolean,

        resultCurrentCount: {
            type: Number,
            required: true,
        },

        resultLoading: Boolean,
    },

    data() {
        return {
            mode: 'car',
            searchQuery: '',

            screen: 'start',
            screenValue: null,

            roadMap: {
                tire: {
                    car: {
                        start: {
                            title: 'Подбор шин',
                            btn: '',
                            next: 'manufacturer',
                            screenData: [
                                {
                                    label: 'Марка',
                                    screen: 'manufacturer',
                                    value: null,
                                    disabled: false,
                                    required: true,
                                },
                                {
                                    label: 'Модель',
                                    screen: 'model',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Год выпуска',
                                    screen: 'year',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Комплектация',
                                    screen: 'modification',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Подходящие размеры',
                                    screen: 'template_sizes',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Сезон',
                                    screen: 'tire_season',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Шипы',
                                    screen: 'tire_spikes',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                            ],
                        },
                        manufacturer: {
                            title: 'Марка',
                            btn: 'Далее: Модель',
                            next: 'model',
                        },
                        model: {
                            title: 'Модель',
                            btn: 'Далее: Год выпуска',
                            next: 'year',
                        },
                        year: {
                            title: 'Год выпуска',
                            btn: 'Далее: Комплектация',
                            next: 'modification',
                        },
                        modification: {
                            title: 'Комплектация',
                            btn: 'Далее: Подходящие размеры',
                            next: 'template_sizes',
                        },
                        template_sizes: {
                            title: 'Подходящие размеры',
                            btn: 'Далее: Сезон',
                            next: 'tire_season',
                        },
                        tire_season: {
                            title: 'Сезон',
                            btn: 'Далее: Шипы',
                            next: 'tire_spikes',
                            required: false,
                        },
                        tire_spikes: {
                            title: 'Шипы',
                            btn: 'Готово',
                            next: 'start',
                            required: false,
                        },
                    },
                    params: {
                        start: {
                            title: 'Подбор шин',
                            btn: '',
                            next: 'tire_width',
                            screenData: [
                                {
                                    label: 'Ширина, мм',
                                    screen: 'tire_width',
                                    value: null,
                                    disabled: false,
                                    required: true,
                                },
                                {
                                    label: 'Высота, %',
                                    screen: 'tire_height',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Диаметр, ″',
                                    screen: 'tire_diameter',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Сезон',
                                    screen: 'tire_season',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Шипы',
                                    screen: 'tire_spikes',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                            ],
                        },
                        tire_width: {
                            title: 'Ширина, мм',
                            btn: 'Далее: Высота',
                            next: 'tire_height',
                        },
                        tire_height: {
                            title: 'Высота, %',
                            btn: 'Далее: Диаметр',
                            next: 'tire_diameter',
                        },
                        tire_diameter: {
                            title: 'Диаметр, ″',
                            btn: 'Далее: Сезон',
                            next: 'tire_season',
                        },
                        tire_season: {
                            title: 'Сезон',
                            btn: 'Далее: Шипы',
                            next: 'tire_spikes',
                            required: false,
                        },
                        tire_spikes: {
                            title: 'Шипы',
                            btn: 'Готово',
                            next: 'start',
                            required: false,
                        },
                    },
                },
                rim: {
                    car: {
                        start: {
                            title: 'Подбор дисков',
                            btn: '',
                            next: 'manufacturer',
                            screenData: [
                                {
                                    label: 'Марка',
                                    screen: 'manufacturer',
                                    value: null,
                                    disabled: false,
                                    required: true,
                                },
                                {
                                    label: 'Модель',
                                    screen: 'model',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Год выпуска',
                                    screen: 'year',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Комплектация',
                                    screen: 'modification',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Подходящие размеры',
                                    screen: 'template_sizes',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                            ],
                        },
                        manufacturer: {
                            title: 'Марка',
                            btn: 'Далее: Модель',
                            next: 'model',
                        },
                        model: {
                            title: 'Модель',
                            btn: 'Далее: Год выпуска',
                            next: 'year',
                        },
                        year: {
                            title: 'Год выпуска',
                            btn: 'Далее: Комплектация',
                            next: 'modification',
                        },
                        modification: {
                            title: 'Комплектация',
                            btn: 'Далее: Подходящие размеры',
                            next: 'template_sizes',
                        },
                        template_sizes: {
                            title: 'Подходящие размеры',
                            btn: 'Готово',
                            next: 'start',
                        },
                    },
                    params: {
                        start: {
                            title: 'Подбор дисков',
                            btn: '',
                            next: 'rim_width',
                            screenData: [
                                {
                                    label: 'Ширина, мм',
                                    screen: 'rim_width',
                                    value: null,
                                    disabled: false,
                                    required: true,
                                },
                                {
                                    label: 'Диаметр, ″',
                                    screen: 'rim_diameter',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Вылет, мм',
                                    screen: 'rim_et',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Сверловка (PCD)',
                                    screen: 'rim_pcd',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                                {
                                    label: 'Ступица (DIA)',
                                    screen: 'rim_dia',
                                    value: null,
                                    disabled: true,
                                    required: true,
                                },
                            ],
                        },
                        rim_width: {
                            title: 'Ширина, мм',
                            btn: 'Далее: Диаметр',
                            next: 'rim_diameter',
                        },
                        rim_diameter: {
                            title: 'Диаметр, ″',
                            btn: 'Далее: Вылет',
                            next: 'rim_et',
                        },
                        rim_et: {
                            title: 'Вылет, мм',
                            btn: 'Далее: Сверловка (PCD)',
                            next: 'rim_pcd',
                        },
                        rim_pcd: {
                            title: 'Сверловка (PCD)',
                            btn: 'Далее: Ступица (DIA)',
                            next: 'rim_dia',
                        },
                        rim_dia: {
                            title: 'Ступица (DIA)',
                            btn: 'Готово',
                            next: 'start',
                        },
                    },
                },
            },
        };
    },

    computed: {
        roadMapCodenames() {
            return Object.keys(this.roadMap[this.category][this.mode]);
        },

        title() {
            return this.roadMap[this.category][this.mode][this.screen].title;
        },

        btn() {
            const btn = {
                label: this.roadMap[this.category][this.mode][this.screen].btn,
                disabled: false,
            };

            if (this.screen === 'start') {
                if (this.currentFilledScreens === 0) {
                    btn.label = 'Начать';
                }
                else if (this.currentFilledScreens < this.totalFilledScreens) {
                    btn.label = 'Продолжить';
                }
                else if (this.currentFilledScreens >= this.totalFilledScreens) {
                    btn.label = this.resultCurrentCount
                        ? `Показать ${ this.resultCurrentCount } ${ plural(this.resultCurrentCount, ['товар', 'товара', 'товаров']) }`
                        : 'Нет предложений';

                    btn.disabled = this.resultCurrentCount === 0;
                }
            }
            else {
                const hasValue = this.hasValue();
                const required = this.roadMap[this.category][this.mode][this.screen].required;
                btn.disabled = required === false ? false : !hasValue;

                if (this.nextScreen) {
                    if (this.hasValue(this.nextScreen)) btn.label = 'Готово';
                }
                else {
                    btn.label = 'Готово';
                }
            }

            return btn;
        },

        canBtnShowSpinner() {
            return this.screen === 'start'
                && this.currentFilledScreens >= this.totalFilledScreens
                && this.resultLoading;
        },

        currentFilledScreens() {
            let count = 0;

            const road = Object.keys(this.roadMap[this.category][this.mode]);
            road.shift();
            road.forEach(screen => {
                if (this.hasValue(screen)) count++;
            });

            return count;
        },

        totalFilledScreens() {
            if (this.category === 'tire' && this.mode === 'params') return 3;
            return 5;
        },

        nextScreen() {
            const currentIndex = this.roadMapCodenames.findIndex(item => item === this.screen);
            if (currentIndex !== this.roadMapCodenames.length-1) return this.roadMapCodenames[currentIndex+1];
            return false;
        },

        screenData() {
            if (this.screen === 'start') {
                const defaultData = this.roadMap[this.category][this.mode][this.screen].screenData;
                return defaultData.map((item, index) => {
                    const value = this.dataset[item.screen].value;
                    if (Array.isArray(value)) {
                        const names = value.map(v => v.name);
                        item.value = { name: names.join(', ') };
                    }
                    else {
                        item.value = value;
                    }

                    if (item.screen !== 'manufacturer' && item.screen !== 'tire_width' && item.screen !== 'rim_width') {
                        const hasValue = this.hasValue(item.screen);
                        if (hasValue) {
                            item.disabled = false;
                        }
                        else {
                            const hasPrevValue = this.hasValue(defaultData[index-1].screen);
                            item.disabled = !hasPrevValue;
                        }
                    }

                    if (this.mode === 'car' && this.category === 'tire' && this.hasValue('template_sizes')) {
                        item.disabled = false;
                    }
                    if (this.mode === 'params' && this.currentFilledScreens === 3) {
                        item.disabled = false;
                    }

                    return item;
                });
            }
            else {
                const obj = {
                    codename: this.screen,
                    options: this.dataset[this.screen].options,
                };

                if (['tire_spikes', 'tire_season'].includes(this.screen)) {
                    obj.widget = 'checkboxes';
                }
                else {
                    obj.widget = 'radio';
                }

                return obj;
            }
        },

        screenDataOptions() {
            if (this.screen !== 'template_sizes') {
                if (this.searchQuery) {
                    const re = new RegExp(this.searchQuery, 'i');
                    return this.dataset[this.screen].options.filter(item => re.test(item.name));
                }
                else {
                    return this.dataset[this.screen].options;
                }
            }
            return [];
        },

        templateSizesScreenDataOptions() {
            const options = {
                original: {
                    title: 'Оригинальные размеры',
                    options: [],
                },
                single: {
                    title: 'Также подойдут',
                    options: [],
                },
                pair: {
                    title: this.category === 'tire' ? 'Разноширокие шины' : 'Разноширокие диски',
                    options: [],
                },
            };

            this.dataset[this.screen].options.forEach(item => {
                if (item.is_stock) {
                    options.original.options.push(item.front);
                }
                else if (item.has_pair) {
                    options.pair.options.push(item);
                }
                else {
                    options.single.options.push(item.front);
                }
            });

            if (!options.original.options.length) options.single.title = 'Подходящие размеры';
            return options;
        },

        screenLoading() {
            return this.dataset.manufacturer.loading
                 || this.dataset.model.loading
                 || this.dataset.year.loading
                 || this.dataset.modification.loading
                || this.dataset.template_sizes.loading;
        },
    },

    watch: {
        breakpointKey(value) {
            if (value !== 'xs' && value !== 's') {
                this.onClose();
            }
        },

        dataset: {
            handler(value) {
                if (value && value[this.screen] && value[this.screen].options.length === 1) {
                    this.screenValue = this.screenDataOptions[0];
                }
            },
            immediate: true,
        },
    },

    methods: {
        setScreen(screen) {
            if (screen === 'start') {
                this.screenValue = null;
            }
            else {
                if (['tire_spikes', 'tire_season'].includes(screen)) {
                    this.screenValue = this.dataset[screen].value || [];
                }
                else {
                    this.screenValue = this.dataset[screen].value || null;
                }
            }

            this.searchQuery = '';
            this.screen = screen;
        },

        handleScreenValueChange() {
            const codename = this.screenData.codename;
            if (['manufacturer', 'model', 'year', 'modification', 'template_sizes'].includes(codename)) {
                this.onChangeCarSelect({ value: this.screenValue, codename });
            }
            else {
                if (this.mode === 'params' && codename !== 'tire_season' && codename !== 'tire_spikes') {
                    this.onChangeCarSelect({ value: null, codename: 'template_sizes' });
                }
                this.onChangeParam({ value: this.screenValue, codename });
            }
        },

        handleBtnClick() {
            let next;

            if (this.screen === 'start') {
                if (this.currentFilledScreens >= this.totalFilledScreens) {
                    this.$emit('close');
                }
                else if (this.currentFilledScreens === 0) {
                    next = this.roadMap[this.category][this.mode][this.screen].next;
                }
                else if (this.currentFilledScreens < this.totalFilledScreens) {
                    next = this.roadMapCodenames[this.currentFilledScreens + 1];
                }
            }
            else {
                next = this.roadMap[this.category][this.mode][this.screen].next;

                if (next !== 'start' && this.hasValue(next)) next = 'start';

                this.handleScreenValueChange();
            }

            if (next) this.setScreen(next);
        },

        onClose() {
            this.$emit('close');
            this.setScreen('start');
        },

        resetAll() {
            this.onChangeCarSelect({ value: null, codename: 'manufacturer' });
            selectionComponentFields.forEach(codename => {
                setTimeout(() => {
                    this.onChangeParam({ value: [], codename });
                });
            });
        },

        onChangeCarSelect({ value, codename }) {
            this.$emit('changeCar', { value, codename });
        },

        onChangeParam({ value, codename }) {
            this.$emit('changeParam', { value, codename });
        },

        selectTemplateSize(template, type) {
            this.$emit('selectTemplateSize', { template, type });
        },

        showTireSelectionInfoPopup() {
            this.$popup(TireSelectionInfoPopup);
        },

        showRimSelectionInfoPopup() {
            this.$popup(RimSelectionInfoPopup);
        },

        hasValue(codename) {
            const value = codename ? this.dataset[codename].value : this.screenValue;
            let hasValue = false;

            if (Array.isArray(value)) {
                hasValue = !!value.length;
            }
            else {
                hasValue = !!(value && (value.id || value.slug || value[this.category]));
            }

            return hasValue;
        },
    },
};
</script>

<style scoped>
.selection_mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--menu-z-index);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--light-c);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light-c);
}

.header__btn {
    width: 24px;
    height: 56px;
}
@media (min-width: 768px) {
    .header__btn {
        margin-left: 16px;
        margin-right: 16px;
    }
}
@media (max-width: 767px) {
    .header__btn {
        margin-left: var(--content-gap);
        margin-right: var(--content-gap);
    }
}

.header__btn_on-start-hidden {
    opacity: 0;
}

.summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .summary {
        padding: 16px;
    }
}
@media (max-width: 767px) {
    .summary {
        padding: 16px var(--content-gap);
    }
}

.body {
    position: relative;
    flex-grow: 1;
    overflow: auto;
}

@media (min-width: 768px) {
    .body__section {
        padding: 8px 16px;
    }
}
@media (max-width: 767px) {
    .body__section {
        padding: 8px var(--content-gap);
    }
}

.footer {
    position: relative;
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .footer {
        padding: 0 16px 20px;
    }
}
@media (max-width: 767px) {
    .footer {
        padding: 0 var(--content-gap) 20px;
    }
}

.footer::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,1) 100%);
}

.navigation {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .navigation {
        padding: 16px;
    }
}
@media (max-width: 767px) {
    .navigation {
        padding: 16px var(--content-gap);
    }
}

.navigation-widget {
    width: 100%;
}

.navigation-widget >>> .radio-group__item {
    width: 50%;
}

.navigation-widget >>> .radio-group__label {
    padding-left: 0;
    padding-right: 0;
}

.start-menu-btn {
    display: block;
    width: 100%;
    text-align: left;
    font-family: var(--f-base);
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .start-menu-btn {
        padding: 16px;
    }
}
@media (max-width: 767px) {
    .start-menu-btn {
        padding: 16px var(--content-gap);
    }
}

.start-menu-btn:disabled {
    color: var(--font-secondary-color);
    background-color: var(--bright-bg);
}

.options-section + .options-section {
    margin-top: 12px;
}

.options-section:first-child {
    padding-top: 16px;
}
.options-section:last-child {
    padding-bottom: 8px;
}
@media (min-width: 768px) {
    .options-section {
        padding-left: 16px;
        padding-right: 16px;
    }
}
@media (max-width: 767px) {
    .options-section {
        padding-left: var(--content-gap);
        padding-right: var(--content-gap);
    }
}

.options-list {
    padding-top: 8px;
    padding-bottom: 8px;
}
@media (min-width: 768px) {
    .options-list {
        padding-left: 16px;
        padding-right: 16px;
    }
}
@media (max-width: 767px) {
    .options-list {
        padding-left: var(--content-gap);
        padding-right: var(--content-gap);
    }
}

.options-list__group .options-list__item:first-child {
    position: relative;
    border-bottom: none;
}
.options-list__group .options-list__item:first-child::after {
    content: "";
    position: absolute;
    left: 32px;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid var(--border-light-c);
}
.options-list__group:not(:last-child) .options-list__item:last-child {
    border-bottom: 1px solid var(--border-light-c);
}

.options-list__item:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}

.options-list__item >>> .label,
.options-list__item >>> .radio__label {
    width: 100%;
    min-height: 48px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.options-list__item >>> .radio__label-text {
    flex-grow: 1;
}

.options-list__item >>> .radio__label[data-v-d31f5af4]::before,
.options-list__item >>> .radio__label-text,
.options-list__item >>> .label .icon,
.options-list__item >>> .label .text {
    margin-top: 2px;
}

.selection__search-block {
    position: relative;
}
@media (min-width: 768px) {
    .selection__search-block {
        padding: 20px 20px 0;
    }
}
@media (max-width: 767px) {
    .selection__search-block {
        padding: 16px var(--content-gap) 0;
    }
}

.selection__search-block::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 20px;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,1) 100%);
}
</style>