<script>
import bus from '../../bus.js';
import deepClone from '@/lib/deepClone.js';

export default {
    name: "PopupManager",

    data() {
        return {
            popups: [],
            options: [],
        };
    },

    watch: {
        popups(value) {
            const overflow = value.length ? 'hidden' : null;
            this.$emit('overflow', overflow);
        },

        '$route.path'() {
            this.popups = [];
            this.options = [];
        },
    },

    mounted() {
        bus.$on('popup', this.new);
        document.addEventListener('keyup', this.esc);
    },

    beforeDestroy() {
        bus.$off('popup', this.new);
        document.removeEventListener('keyup', this.esc);
    },

    methods: {
        new({ component, options = {} }) {
            const index = this.popups.length;
            this.options.splice(index, 0, options);
            this.popups.splice(index, 0, component);
        },

        delete(index) {
            this.popups.splice(index, 1);
            this.options.splice(index, 1);
        },

        esc(event) {
            if (event.keyCode === 27) {
                const index = this.popups.length - 1;
                this.delete(index);
            }
        },
    },

    render(h) {
        const attrs = {
            class: 'popup-manager',
        };

        const options = { attrs };

        return h(
            'div',
            options,
            this.popups.map((popup, i) => {
                const options = deepClone(this.options[i]);
                if (!options.props) options.props = {};
                options.props._index = i;
                if (!options.on) options.on = {};
                options.on.close = index => this.delete(index);
                return h(
                    popup,
                    options,
                );
            }),
        );
    },
};
</script>
