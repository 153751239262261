export default ({ HTTP }) => ({
    async get(payload) {
        if (typeof payload === 'string' || typeof payload === 'number') {
            const id = payload;
            const url = `/cities/${ id }/`;
            const response = await HTTP.get(url);
            return response.data;
        }
        else {
            const config = payload;
            const url = '/cities/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async setCity(data) {
        const url = '/cities/set_city/';
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async current(config) {
        const url = '/cities/current/';
        const response = await HTTP.get(url, config);
        return response.data;
    },
});