<template>
    <div class="market-item-list-content">
        <div class="market-item-list-content__main">
            <slot name="main"></slot>
        </div>

        <aside
            id="sticky-wrap"
            class="market-item-list-content__aside"
        >
            <div id="sticky-element">
                <slot name="aside"></slot>
            </div>
        </aside>
    </div>
</template>

<script>
let Sticky;

export default {
    name: 'MarketItemListContent',

    mounted() {
        const hcSticky = require('hc-sticky');
        Sticky = new hcSticky('#sticky-element', {
            stickTo: '#sticky-wrap',
        });

        Sticky.update({
            top: 20,
            bottom: 20,
            responsive: {
                1039: {
                    disable: true,
                },
            },
        });
    },

    beforeDestroy() {
        Sticky.destroy();
    },
};
</script>

<style scoped>
@media (min-width: 1281px) {
    .market-item-list-content {
        grid-template-columns: repeat(20, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .market-item-list-content {
        grid-template-columns: repeat(12, 1fr);
    }
}
@media (min-width: 1040px) {
    .market-item-list-content {
        display: grid;
        grid-column-gap: var(--grid-gap);
    }
}

.market-item-list-content__main {
    position: relative;
    min-height: 200px;
    align-self: start;
}

@media (min-width: 1281px) {
    .market-item-list-content__main {
        grid-column: 1/17;
    }

    .market-item-list-content__aside {
        grid-column: 17/21;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .market-item-list-content__main {
        grid-column: 1/10;
    }

    .market-item-list-content__aside {
        grid-column: 10/13;
    }
}
</style>