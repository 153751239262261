<template>
    <div class="wrap">
        <h2 class="h2 h2_block">
            Читайте также
        </h2>

        <div class="grid">
            <GridItem
                v-for="item in items"
                :key="'news-item-' + item.id"
                :item="item"
                small
                class="grid__item"
            ></GridItem>
        </div>
    </div>
</template>

<script>
import GridItem from './GridItem.vue';

export default {
    name: "ReadAlso",

    components: {
        GridItem,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped>
.wrap {
    margin-top: var(--gap-y-medium);
}

@media (min-width: 1400px) {
    .grid {
        grid-template-columns: repeat(5, 1fr);
    }
}
@media (min-width: 1039px) and (max-width: 1399px) {
    .grid {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) {
    .grid {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}
@media (min-width: 768px) and (max-width: 1399px) {
    .grid__item:nth-child(5) {
        display: none;
    }
}
@media (max-width: 767px) {
    .grid__item:not(:last-child) {
        margin-bottom: var(--y-grid-gap);
    }
}
</style>