<template>
    <div class="tree">
        <div
            v-for="(item, index) in nodes"
            :key="'node-' + index"
            class="node"
        >
            <button
                class="node-head"
                :class="{ 'node-head_active': selectedNode.name === item.name && item.is_leaf }"
                @click="handleNodeClick(item, index)"
            >
                <UIcon
                    v-if="!item.is_leaf"
                    name="arrow-right"
                    tiny
                    :class="['node-head__icon', openNodes.includes(index) && 'node-head__icon_rotate']"
                ></UIcon>

                <span class="node-name">{{ item.name }}</span>
            </button>

            <Spinner
                v-if="loading && openNodes.includes(index) && !loadedGroups.includes(item.id)"
                class="mt-4 mb-4 ml-8"
                :class="{ 'spinner_in-leaf': !item.is_leaf }"
            ></Spinner>

            <div
                v-show="openNodes.includes(index)"
                class="node-inner-list"
            >
                <PartsTree
                    :nodes="innerCatalogGroups[item.id]"
                    :selectedNode="selectedNode"
                    @change="changeInner($event)"
                ></PartsTree>
            </div>
        </div>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';
import Spinner from "@/components/Spinner.vue";

export default {
    name: "PartsTree",

    components: { Spinner, UIcon },

    props: {
        nodes: {
            type: Array,
            default() {
                return [];
            },
        },

        selectedNode: {
            type: Object,
            default() {
                return {};
            },
        },

        selectedCar: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    data() {
        return {
            loading: false,
            innerCatalogGroups: {},
            openNodes: [],
        };
    },

    computed: {
        loadedGroups() {
            return Object.keys(this.innerCatalogGroups).map(item => Number(item));
        },

        isSelectedNodeParentLoad() {
            return this.loadedGroups.includes(this.selectedNode.parent);
        },
    },

    watch: {
        isSelectedNodeParentLoad(value) {
            if (!value) {
                this.getInnerCatalogGroups(this.selectedNode.parent);
                const index = this.nodes.findIndex(item => item.id === this.selectedNode.parent);
                if (index > -1) this.openNodes.push(index);
            }
        },
    },

    methods: {
        getInnerCatalogGroups(parentId) {
            if (!this.innerCatalogGroups[parentId]) {
                this.loading = true;
                const params = {
                    limit: 100,
                    offset: 0,
                    order_by: 'name',
                    car_id: this.selectedCar.id,
                    parent_id: parentId,
                };

                this.$api.catalogGroups.get({ params })
                    .then(({ results }) => {
                        this.$set(this.innerCatalogGroups, parentId, results);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },

        handleNodeClick(item, index) {
            if (!index) {
                index = this.nodes.findIndex(node => node.id === item.id);
            }

            if (item.is_leaf) {
                this.$emit('change', item);
            }
            else {
                if (this.openNodes.includes(index)) {
                    this.openNodes.splice(this.openNodes.indexOf(index), 1);
                }
                else {
                    this.getInnerCatalogGroups(item.id);
                    this.openNodes.push(index);
                }
            }
        },

        changeInner($event) {
            this.$emit('change', $event);
        },
    },
};
</script>

<style scoped>
.nodes-catalog__tree > .tree > .node:not(:last-child) {
    margin-bottom: 8px;
}

.node-head {
    display: flex;
    align-items: baseline;
    text-align: left;
    padding: 4px 8px;
    transition: background-color var(--transition);
}

.node-head_active {
    background-color: var(--light-bg);
    border-radius: var(--border-radius);
    pointer-events: none;
    cursor: default;
}

.node-head_active .node-name {
    color: var(--dark-c);
}

.node-head__icon {
    position: relative;
    top: 1px;
    margin-right: 8px;
    fill: currentColor;
    transition: fill var(--transition), transform var(--transition);
}

.node-head__icon_rotate {
    transform: rotate(-90deg);
}

.node-name {
    font-size: var(--base-fz);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
    transition: color var(--transition);
}

.nodes-catalog__tree > .tree > .node > .node-head .node-name {
    font-size: 12px;
    font-family: var(--f-bold);
    color: var(--dark-c);
    text-transform: uppercase;
    letter-spacing: 0.06em;
}

.node-head:hover .node-name {
    color: var(--dark-c);
}

.node-head:hover .node-head__icon,
.nodes-catalog__tree > .tree > .node > .node-head .node-head__icon {
    fill: var(--dark-c);
}

.node-inner-list {
    margin-left: 20px;
}

.spinner_in-leaf {
    margin-left: 28px;
}
</style>