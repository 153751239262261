<template>
    <div>
        <Spinner
            v-if="!initialized"
            size="big"
            center
            class="top-gap_medium"
        ></Spinner>

        <template v-else>
            <section class="header layer-1">
                <div class="header-main">
                    <div :class="logoClass + '-wrap'">
                        <ShopLogo
                            :shop="shop"
                            coverCodename="detail"
                            noLink
                            :class="['logo', logoClass]"
                        ></ShopLogo>
                    </div>

                    <h1 class="h1 mb-8 shop__name">
                        {{ shop.page_title }}
                    </h1>

                    <ShopAddress
                        :shop="shop"
                        class="shop__address shop__address_desktop mb-8"
                    ></ShopAddress>

                    <div class="shop__address_mobile mb-8">
                        <ShopAddress
                            :shop="shop"
                            :showOnMap="false"
                            class="mb-4"
                        ></ShopAddress>
                        <ShopAddress
                            :shop="shop"
                            dashed
                            secondary
                            dark
                        >Показать на карте</ShopAddress>
                    </div>

                    <ShopOpenCloseTime
                        :shopId="shop.id"
                        :status="shop.current_work"
                        :tooltipData="shop"
                    ></ShopOpenCloseTime>

                    <div class="actions">
                        <LikeBtn
                            :id="shop.id"
                            :value="shop.is_favorite"
                            apiName="shop"
                            class="actions__btn"
                            withText
                            noTextOnXS
                            @changeFavorite="onChangeFavorite"
                        ></LikeBtn>
                        <ChatBtn
                            :id="shop.id"
                            :isOnline="shop.current_work.place.is_active"
                            :disabled="!shop.is_chat_active"
                            withText
                            btnText="Чат с магазином"
                            class="actions__btn"
                        ></ChatBtn>
                    </div>
                </div>

                <MarketItemNavigation
                    :items="[
                        {
                            to: {
                                name: 'market-shop-stock',
                                params: $route.params
                            },
                            label: 'Ассортимент',
                            nested: ['market-shop-products', 'market-shop-new-parts', 'market-shop-contract-parts'],
                        },
                        {
                            to: {
                                name: 'market-shop-reviews',
                                params: {
                                    codename: shop.company.codename,
                                    id: shop.id,
                                }
                            },
                            label: 'Отзывы',
                            amount: shop.reviews_amount,
                        },
                        {
                            to: {
                                name: 'market-shop-about',
                            },
                            label: 'Информация',
                        },
                    ]"
                ></MarketItemNavigation>
            </section>

            <RouterView></RouterView>

            <SeoBlock>
                <template
                    v-if="shop.seo_description"
                    #seo
                >{{ shop.seo_description }}</template>
            </SeoBlock>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import marketShop from '@/store/modules/marketShop.js';
import LikeBtn from "@/components/project-buttons/LikeBtn.vue";
import ChatBtn from "@/components/project-buttons/ChatBtn.vue";
import ShopOpenCloseTime from "@/components/ShopOpenCloseTime.vue";
import ShopAddress from "@/components/ShopAddress.vue";
import MarketItemNavigation from "@/components/MarketItemNavigation.vue";
import ShopLogo from "@/components/ShopLogo.vue";
import SeoBlock from "@/components/SeoBlock.vue";
import Spinner from "@/components/Spinner.vue";


export default {
    name: 'MarketShopPage',

    components: {
        Spinner,
        SeoBlock,
        ShopLogo,
        MarketItemNavigation,
        ShopAddress,
        ShopOpenCloseTime,
        ChatBtn,
        LikeBtn,
    },

    metaInfo() {
        return {
            title: this.shop.meta_title,
            meta: [
                { name: 'description', content: this.shop.meta_description },
                { name: 'keywords', content: this.shop.meta_keywords },
                { property: 'og:title', content: this.shop },
                { property: 'og:description', content: this.shop.meta_description },
                { property: 'og:image', content: this.shop.og_image ? this.$links.uploads + this.shop.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    beforeRouteEnter (to, from, next) {
        if (to.name === 'market-shop') {
            next(vm => {
                vm.replaceQuery();
            });
        }
        else {
            next();
        }
    },

    serverPrefetch() {
        return this.INIT({ id: this.$route.params.id });
    },

    computed: {
        ...mapState({
            shop: state => state.marketShop.shop,
            initialized: state => state.marketShop.initialized,
        }),

        ...mapGetters({
            availableRoutes: 'marketShop/availableRoutes',
        }),

        logoClass() {
            let logoClass = 'placeholder';
            let logoUrl = ((((this.shop || {}).company || {}).logo || {}).thumbnails || {}).company_logo_detail;
            if (logoUrl) {
                logoClass = 'logo';
            }
            return logoClass;
        },
    },

    watch: {
        '$route.params.id': {
            handler() {
                this.DESTROY();
                this.init();
            },
        },
    },

    created() {
        this.$store.registerModule('marketShop', marketShop);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.$store.unregisterModule('marketShop');
    },

    methods: {
        ...mapMutations({
            CHANGE_FAVORITE: 'marketShop/changeFavorite',
            DESTROY: 'marketShop/destroy',
        }),

        ...mapActions({
            INIT: 'marketShop/init',
        }),

        async init() {
            if (!this.initialized) {
                await this.INIT({ id: this.$route.params.id });
            }
            this.updateBreadcrumbs();
        },

        updateBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'market',
                    },
                    title: 'Запчасти и автотовары',
                },
                {
                    to: {
                        name: 'market-shops',
                    },
                    title: 'Магазины',
                },
                {
                    to: {
                        name: 'market-shop',
                        params: {
                            codename: this.shop.company.codename,
                            id: this.$route.params.id,
                        },
                    },
                    title: this.shop.company.name,
                },
            ]);
        },

        async replaceQuery() {
            const location = {
                name: 'market-shop-stock',
                params: this.$route.params,
                query: this.$route.query,
            };
            if (this.availableRoutes.length) {
                location.name = this.availableRoutes[0];
            }

            const toPath = this.$router.resolve(location).route.fullPath;
            if (this.$route.fullPath !== toPath) {
                await this.$router.replace(location);
            }
        },

        onChangeFavorite(value) {
            this.CHANGE_FAVORITE(value);
        },
    },
};
</script>

<style scoped>
.header {
    position: relative;
    margin-top: var(--gap-y-medium);
    margin-bottom: var(--gap-y-medium);
}

.header-main {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .header-main {
        padding: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .header-main {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .header-main {
        padding: 16px;
    }
}

@media (min-width: 1040px) {
    .logo-wrap,
    .placeholder-wrap {
        top: 36px;
        right: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo-wrap,
    .placeholder-wrap {
        top: 24px;
        right: 24px;
    }
}
@media (min-width: 768px) {
    .logo-wrap,
    .placeholder-wrap {
        position: absolute;
    }
}
@media (max-width: 767px) {
    .logo-wrap,
    .placeholder-wrap {
        display: inline-block;
        margin-bottom: 20px;
    }
}

.logo-wrap {
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius-x2);
}

.placeholder-wrap {
    border-radius: var(--border-radius-x2);
}

@media (min-width: 1040px) {
    .logo-wrap,
    .placeholder-wrap {
        width: 180px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo-wrap,
    .placeholder-wrap {
        width: 120px;
    }
}
@media (max-width: 767px) {
    .placeholder-wrap {
        width: 90px;
        height: 60px;
    }
}
@media (min-width: 1040px) {
    .logo-wrap {
        padding: 20px 30px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo-wrap {
        padding: 12px 20px;
    }
}
@media (max-width: 767px) {
    .logo-wrap {
        padding: 10px 15px;
    }
}

@media (min-width: 768px) {
    .shop__name {
        margin-right: 200px;
    }

    .shop__address {
        max-width: calc(100% - 200px);
    }
}

@media (min-width: 768px) {
    .shop__address_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .shop__address_desktop {
        display: none;
    }
}

.actions {
    display: flex;
    flex-wrap: wrap;
}
@media (min-width: 768px) {
    .actions {
        margin-top: 24px;
    }
}
@media (max-width: 767px) {
    .actions {
        position: absolute;
        top: 16px;
        right: 16px;
    }
}

.actions__btn {
    flex-shrink: 0;
}
.actions__btn:not(:first-child) {
    margin-left: 12px;
}
</style>