export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        currentCount: 0,
        items: [],
    }),

    mutations: {
        setItems(state, items) {
            state.items = items;
        },

        setCurrentCount(state, currentCount) {
            state.currentCount = currentCount;
        },

        setInitialized(state) {
            state.initialized = true;
        },
    },

    actions: {
        async fetchDeliveryOffices({ rootState, state, commit }) {
            try {
                const limit = 1000;
                const type = 'pvz';
                const service__codename__in = 'ubibi,cdek';
                const order_by = 'service__order_weight';
                const city = rootState.cities.currentCityId;
                const params = { service__codename__in, order_by, city, type, limit };
                const { results: items, current_count: currentCount } = await this.$api.delivery.offices({ params });

                commit('setItems', items);
                commit('setCurrentCount', currentCount);
                commit('setInitialized');

                return { items, currentCount };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
                return state;
            }
        },

        async getItems({ state, dispatch }) {
            if (state.initialized) {
                return state;
            }
            else {
                return await dispatch('fetchDeliveryOffices');
            }
        },
    },
};
