<template>
    <div class="product-page">
        <Spinner
            v-if="!initialized"
            size="big"
            center
            class="top-gap_medium"
        ></Spinner>
        <template v-else>
            <Observer @intersect="intersected">
                <RouterView name="header"></RouterView>
            </Observer>

            <MarketItemStickyHeader
                ref="stickyHeader"
                :item="product"
                modelName="product"
                :show="showStickyHeader && showStickyHeaderOnTopScroll"
                :nav="nav"
            ></MarketItemStickyHeader>

            <RouterView></RouterView>

            <LastSee></LastSee>

            <SeoBlock :showMarketItemNote="$route.name === 'market-product-description'">
                <template
                    v-if="product.seo_description"
                    #seo
                >{{ product.seo_description }}</template>
            </SeoBlock>
        </template>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import marketProduct from '@/store/modules/marketProduct.js';
import equals from "@/lib/equals.js";
import Observer from "@/components/Observer.vue";
import MarketItemStickyHeader from '@/components/MarketItemStickyHeader.vue';
import LastSee from "@/components/LastSee.vue";
import Spinner from "@/components/Spinner.vue";
import SeoBlock from "@/components/SeoBlock.vue";


export default {
    name: 'MarketProductPage',

    metaInfo() {
        return {
            title: this.product.meta_title,
            meta: [
                { name: 'description', content: this.product.meta_description },
                { name: 'keywords', content: this.product.meta_keywords },
                { property: 'og:title', content:this.product.meta_title },
                { property: 'og:description', content: this.product.meta_description },
                { property: 'og:image', content: this.product.og_image ? this.$links.uploads + this.product.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        SeoBlock,
        Spinner,
        LastSee,
        MarketItemStickyHeader,
        Observer,
    },

    serverPrefetch() {
        return this.INIT({ id: this.$route.params.id });
    },

    data() {
        return {
            showStickyHeader: false,
            showStickyHeaderOnTopScroll: false,
            lastScrollTop: 0,
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.marketProduct.initialized,
            product: state => state.marketProduct.product,
            breadcrumbs: state => state.marketProduct.breadcrumbs,
        }),

        nav() {
            const nav = [
                {
                    to: {
                        name: 'market-product-description',
                        params: this.$route.params,
                    },
                    label: 'Описание',
                },
                // {
                //     to: {
                //         name: 'market-product-offers',
                //         params: this.$route.params,
                //     },
                //     label: 'Цены',
                //     amount: this.product.offers_amount,
                // },
            ];
            if (this.product.properties.length) {
                nav.push({
                    to: {
                        name: 'market-product-specs',
                        params: this.$route.params,
                    },
                    label: 'Характеристики',
                });
            }
            nav.push({
                to: {
                    name: 'market-product-reviews',
                    params: this.$route.params,
                },
                label: 'Отзывы',
                amount: this.product.reviews_amount,
            });

            return nav;
        },
    },

    watch: {
        '$route.params.id'(value, oldValue) {
            if (value !== oldValue) {
                this.DESTROY();
                this.init();
            }
        },
    },

    created() {
        this.$store.registerModule('marketProduct', marketProduct);
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.init();
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        this.DESTROY();
        this.$store.unregisterModule('marketProduct');
    },

    methods: {
        ...mapActions({
            INIT: 'marketProduct/init',
            SET_VIEWS_HISTORY: 'viewsHistory/set',
        }),

        ...mapMutations({
            DESTROY: 'marketProduct/destroy',
        }),

        async init() {
            if (!this.initialized) await this.INIT({ id: this.$route.params.id });

            const newParams = {
                id: this.$route.params.id,
                slug: this.product.slug,
            };
            let newRouteName = this.$route.name;
            if (this.$route.params.slug === 'reviews') {
                newRouteName = 'market-product-reviews';
            }
            if (this.product.properties.length === 0 && this.$route.name === 'market-product-specs') {
                newRouteName = 'market-product-description';
            }

            if (!equals(this.$route.params, newParams) || newRouteName !== this.$route.name) {
                this.$router.replace({
                    name: newRouteName,
                    params: newParams,
                    query: this.$route.query,
                });
            }

            this.$breadcrumbs([
                ...this.breadcrumbs,
                {},
            ]);

            this.SET_VIEWS_HISTORY({ id: this.$route.params.id, type: 'product' });
        },

        intersected(value) {
            this.showStickyHeader = value !== 'in';
        },

        handleScroll() {
            if (window.innerWidth < 1040) {
                const minScrollDelta = 70;
                let st = window.pageYOffset;

                if (st === 0) this.showStickyHeaderOnTopScroll = false;

                if (Math.abs(this.lastScrollTop - st) <= minScrollDelta) return;

                this.showStickyHeaderOnTopScroll = st <= this.lastScrollTop;

                this.lastScrollTop = st;
            }
            else {
                this.showStickyHeaderOnTopScroll = true;
            }
        },
    },
};
</script>