import filter from './filter.js';
import sort from './sort.js';
import deliveryOffices from '@/store/modules/deliveryOffices.js';


const getCategory = (arr, itemId) => (
    arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === itemId) return item;
        if (item.children) return getCategory(item.children, itemId);
    }, null)
);

const staticFilters = [
    {
        widget: 'categories',
        codename: 'category',
        api: 'products/categories/',
        categories: [],
    },
    {
        widget: 'radio',
        codename: 'in_stock',
        options: [
            {
                value: 'true',
                label: 'В наличии',
            },
            {
                value: 'false',
                label: 'Все товары, включая отсутствующие',
            },
        ],
    },
    {
        title: 'Цена, ₽',
        widget: 'range',
        codename: 'price',
        min: 0,
        max: 0,
    },
    {
        title: 'Магазины',
        widget: 'checkboxes',
        codename: 'company',
        options: [],
    },
];

export default {
    namespaced: true,

    modules: {
        filter,
        sort,
        deliveryOffices,
    },

    state: () => ({
        products: {
            items: [],
            current_count: 0,
            hasNext: false,
        },
        initialized: false,
        loading: true,
    }),

    mutations: {
        setProducts(state, { results, current_count, next }) {
            state.products.items = results;
            state.products.current_count = current_count;
            state.products.hasNext = !!next;
        },

        addProducts(state, { results, current_count, next }) {
            state.products.items = [...state.products.items, ...results];
            state.products.current_count = current_count;
            state.products.hasNext = !!next;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        setLoading(state, value) {
            state.loading = value;
        },

        destroy(state) {
            state.products = {
                items: [],
                current_count: 0,
                hasNext: false,
            };
            state.initialized = false;
        },

        changeFavorite(state, { index, value }) {
            state.products.items[index].is_favorite = value;
        },

        changeCart(state, { itemIndex, itemId, offerId, inCartStatus }) {
            if (itemIndex !== undefined) {
                const currentOffer = state.products.items[itemIndex].offers.find(offer => offer.id === offerId);
                if (currentOffer) currentOffer.in_basket = true;
            }
            else if (itemId !== undefined) {
                const currentItem = state.products.items.find(item => item.id === itemId);
                const currentOffer = currentItem.offers.find(item => item.id === offerId);
                if (currentOffer) currentOffer.in_basket = inCartStatus;
            }
        },
    },

    actions: {
        async init({ commit, dispatch, rootState }, { route }) {
            try {
                commit('setLoading', true);

                const params = Object.assign(
                    {
                        brand: rootState.marketBrand.brand.id,
                        get_facets: 'all',
                        limit: 36,
                        offset: 0,
                        in_stock: true,
                        order_by: '-rating',
                    },
                    route.query,
                );

                const [products, delivery] = await Promise.all([
                    this.$api.productsOneOfferDoc.get({ params }),
                    dispatch('deliveryOffices/getItems'),
                ]);

                products.results.forEach(product => product.delivery = delivery);

                const facets = products.facets;
                commit('setProducts', products);

                commit('filter/setChecked', []);
                commit('filter/setStaticFilters', {
                    config: staticFilters,
                    facets,
                    defaultFacets: rootState.marketBrand.productsDefaultFacets ,
                });

                if (route.query.category) {
                    let category = getCategory(facets.categories, Number(route.query.category));
                    let categoryFilters = {
                        dynamicFilters: [],
                    };
                    if (category) {
                        categoryFilters =
                            await dispatch('filter/getDynamicFiltersInCategory', {
                                slug: category.slug,
                                routeName: route.name,
                            });
                    }

                    commit('filter/setDynamicFilters', {
                        config: categoryFilters.dynamicFilters,
                        facets,
                        defaultFacets: rootState.marketBrand.productsDefaultFacets,
                        current_count: products.current_count,
                    });
                    dispatch('filter/parseQuery', {
                        query: route.query,
                        filters: [
                            ...staticFilters,
                            ...categoryFilters.dynamicFilters,
                        ],
                    });
                }
                else {
                    commit('filter/setDynamicFilters', {
                        config: [],
                        facets,
                        defaultFacets: rootState.marketBrand.productsDefaultFacets,
                        current_count: products.current_count,
                    });
                    dispatch('filter/parseQuery', {
                        query: route.query,
                        filters: staticFilters,
                    });
                }

                commit('setInitialized');
                commit('setLoading', false);
                commit('sort/parseQuery', route.query);
                commit('clearHttpError', null, { root: true });

                return { products };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getProducts({ rootState, commit, dispatch }, { params, cancelToken }) {
            try {
                const [products, delivery] = await Promise.all([
                    this.$api.productsOneOfferDoc.get({ params, cancelToken }),
                    dispatch('deliveryOffices/getItems'),
                ]);

                products.results.forEach(product => product.delivery = delivery);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};