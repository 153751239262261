<template>
    <div
        ref="cloud"
        class="u-tooltip-cloud"
        :class="{
            'u-tooltip-cloud_black': color === 'black',
            'u-tooltip-cloud_white': color === 'white',
            'u-tooltip-cloud_top': isTop,
            'u-tooltip-cloud_right': isRight,
            'u-tooltip-cloud_bottom': isBottom,
            'u-tooltip-cloud_left': isLeft,
            'u-tooltip-cloud_start': isStart,
            'u-tooltip-cloud_end': isEnd,
        }"
    >
        <div
            class="u-tooltip-cloud__arrow-wrap"
            :class="{
                'u-tooltip-cloud__arrow-wrap_top': isTop,
                'u-tooltip-cloud__arrow-wrap_right': isRight,
                'u-tooltip-cloud__arrow-wrap_bottom': isBottom,
                'u-tooltip-cloud__arrow-wrap_left': isLeft,
                'u-tooltip-cloud__arrow-wrap_start': isStart,
                'u-tooltip-cloud__arrow-wrap_end': isEnd,
            }"
            :style="arrowWrapStyle"
        >
            <div
                class="u-tooltip-cloud__arrow"
                :class="{
                    'u-tooltip-cloud__arrow_top': isBottom,
                    'u-tooltip-cloud__arrow_right': isLeft,
                    'u-tooltip-cloud__arrow_bottom': isTop,
                    'u-tooltip-cloud__arrow_left': isRight,
                }"
            ></div>
        </div>

        <div class="u-tooltip-cloud__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UTooltipCloud',

    props: {
        color: {
            type: String,
            default: 'black',
            validator(value) {
                return ['black', 'white'].includes(value);
            },
        },

        // arrow
        placement: String,
        shift: Number,
    },

    computed: {
        isTop() {
            return this.placement.includes('top');
        },

        isRight() {
            return this.placement.includes('right');
        },

        isBottom() {
            return this.placement.includes('bottom');
        },

        isLeft() {
            return this.placement.includes('left');
        },

        isStart() {
            return this.placement.includes('start');
        },

        isEnd() {
            return this.placement.includes('end');
        },

        arrowWrapStyle() {
            const style = {};

            if (this.shift) {
                if (this.isLeft || this.isRight) {
                    style.top = -this.shift + 'px';
                }
                else {
                    style.left = -this.shift + 'px';
                }
            }

            return style;
        },
    },
};
</script>

<style>
.u-tooltip-cloud {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: var(--border-radius);
}
.u-tooltip-cloud.u-tooltip-cloud_top {
    flex-direction: column-reverse;
}
.u-tooltip-cloud.u-tooltip-cloud_right {
    flex-direction: row;
}
.u-tooltip-cloud.u-tooltip-cloud_left {
    flex-direction: row-reverse;
}
.u-tooltip-cloud.u-tooltip-cloud_white {
    border-radius: var(--border-radius-x2);
}
.u-tooltip-cloud.u-tooltip-cloud_start {
    align-items: flex-start;
}
.u-tooltip-cloud.u-tooltip-cloud_end {
    align-items: flex-end;
}

.u-tooltip-cloud__arrow-wrap {
    overflow: hidden;
    display: flex;
    position: relative;
}
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_bottom {
    top: 1px;
    height: 9px;
    align-items: flex-end;
}
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_top {
    bottom: 1px;
    height: 9px;
    align-items: flex-start;
}
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_right {
    left: 1px;
    width: 9px;
    justify-content: flex-end;
}
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_left {
    right: 1px;
    width: 9px;
    justify-content: flex-start;
}
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_bottom.u-tooltip-cloud__arrow-wrap_start,
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_top.u-tooltip-cloud__arrow-wrap_start {
    margin-left: 20px;
}
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_bottom.u-tooltip-cloud__arrow-wrap_end,
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_top.u-tooltip-cloud__arrow-wrap_end {
    margin-right: 20px;
}
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_right.u-tooltip-cloud__arrow-wrap_start,
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_left.u-tooltip-cloud__arrow-wrap_start {
    margin-top: 20px;
}
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_right.u-tooltip-cloud__arrow-wrap_end,
.u-tooltip-cloud__arrow-wrap.u-tooltip-cloud__arrow-wrap_left.u-tooltip-cloud__arrow-wrap_end {
    margin-bottom: 20px;
}

.u-tooltip-cloud__arrow {
    border: 9px solid transparent;
}
.u-tooltip-cloud.u-tooltip-cloud_black .u-tooltip-cloud__arrow {
    border-color: var(--dark-c);
}
.u-tooltip-cloud.u-tooltip-cloud_white .u-tooltip-cloud__arrow {
    border-color: var(--light-c);
}
.u-tooltip-cloud__arrow.u-tooltip-cloud__arrow_top {
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
}
.u-tooltip-cloud__arrow.u-tooltip-cloud__arrow_bottom {
    border-bottom-color: transparent !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
}
.u-tooltip-cloud__arrow.u-tooltip-cloud__arrow_left {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
}
.u-tooltip-cloud__arrow.u-tooltip-cloud__arrow_right {
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
}

.u-tooltip-cloud__content {
    box-shadow: var(--base-shadow);
    border-radius: var(--border-radius);
}
.u-tooltip-cloud.u-tooltip-cloud_black .u-tooltip-cloud__content {
    color: var(--light-c);
    background-color: var(--dark-c);
}
.u-tooltip-cloud.u-tooltip-cloud_white .u-tooltip-cloud__content {
    color: var(--dark-c);
    background-color: var(--light-c);
}
</style>