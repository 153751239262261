var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile__main"},[(_vm.current_count === 0)?_c('EmptyBlock',{attrs:{"icon":"heart-btn-stroke","title":"Нет избранных производителей","hasBtn":false}},[_c('template',{slot:"text"},[_c('p',{staticClass:"profile-empty-block__text"},[_vm._v("\n                Добавляйте производителей в избранное с помощью "),_c('span',{staticClass:"like-btn-wrap"},[_c('LikeBtn')],1)])])],2):[_c('ol',{staticClass:"items-grid"},_vm._l((_vm.computedBrands),function(item,index){return _c('li',{key:'brand-' + item.id,staticClass:"item layer-1"},[_c('RouterLink',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],staticClass:"item__logo-wrap",attrs:{"to":{
                        name: 'market-brand',
                        params: {
                            codename: item.codename,
                        },
                    }}},[_c('img',{attrs:{"data-src":item.logoUrl,"alt":item.name}})]),_vm._v(" "),_c('h3',{staticClass:"h4 item__title"},[_c('RouterLink',{staticClass:"link-primary-hover",attrs:{"to":{
                            name: 'market-brand',
                            params: {
                                codename: item.codename,
                            },
                        }}},[_vm._v("\n                        "+_vm._s(item.name)+"\n                    ")])],1),_vm._v(" "),_c('p',{staticClass:"item__country"},[_vm._v("\n                    "+_vm._s(item.country)+"\n                ")]),_vm._v(" "),_c('LikeBtn',{staticClass:"item__like-btn",attrs:{"id":item.codename,"apiName":"brand","value":true},on:{"changeFavorite":function($event){return _vm.onChangeFavorite(index)}}})],1)}),0),_vm._v(" "),(_vm.hasNext)?_c('ButtonText',{staticClass:"load-more-btn",attrs:{"dashed":"","loading":_vm.loading},on:{"click":_vm.load}},[_vm._v("\n            Показать ещё\n        ")]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }