<template>
    <!-- oem === sku -->
    <p
        v-if="computedSku"
        class="sku-wrap"
    >
        <span class="sku">{{ computedSku }}</span><UTooltip
            v-if="!disabledHint"
            class="sku-tooltip"
            noDetach
        >
            {{ computedHint }}
        </UTooltip>
    </p>
</template>

<script>
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';


export default {
    name: 'MarketItemSku',

    components: { UTooltip },

    props: {
        item: {
            type: Object,
            default: () => {},
        },

        modelName: {
            type: String,
            validator(value) {
                return ['part_product', 'contract_part'].includes(value);
            },
            default: 'part_product',
        },

        disabledHint: Boolean,
    },

    computed: {
        computedSku() {
            return this.modelName === 'part_product' ? this.item.sku || (this.item.original_part || {}).sku : this.item.sku;
        },

        computedHint() {
            return (this.modelName === 'part_product' && this.item.is_original) || this.modelName === 'contract_part'
                ? `Оригинальный OEM-код запчасти в каталоге ${ this.item.brand.name }.`
                : `Уникальный артикул запчасти в каталоге производителя аналогов ${ this.item.brand.name }.`;
        },
    },
};
</script>

<style scoped>
.sku-wrap {
    margin-top: 4px;
    font-size: 0;
    line-height: var(--small-lh);
    color: var(--font-secondary-dark-color);
}
.sku-wrap:not(:last-child) {
    margin-bottom: 8px;
}

.sku {
    font-size: var(--base-fz);
}

.sku-tooltip {
    display: inline-block;
    margin-left: 8px;
    vertical-align: -2px;
    font-size: 0;
}
</style>