<template>
    <div class="u-field-hidden"></div>
</template>

<script>
export default {
    name: 'UFieldHidden',

    data() {
        return {
            initialValue: null,
        };
    },
};
</script>

<style>
.u-field-hidden {
    display: none;
}
</style>