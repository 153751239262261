<template>
    <div
        v-show="show"
        class="filter"
    >
        <div class="header">
            <div class="header__btn"></div>

            <h3 class="h4">
                Фильтры
            </h3>

            <ButtonIcon
                :secondary="false"
                :icon="{
                    name: 'cross',
                    big: true,
                    secondary: true,
                    hovered: true,
                }"
                type="button"
                aria-label="Закрыть меню фильтров"
                class="header__btn"
                @click="toggleFilter(false)"
            ></ButtonIcon>
        </div>

        <div
            v-if="checkedFilters.length"
            class="summary"
        >
            <span class="text_secondary text_semi-bold">Выбрано: {{ checkedFilters.length }}</span>

            <ButtonText
                dashed
                primary
                @click="reset"
            >Сбросить все</ButtonText>
        </div>

        <div class="body">
            <FilterSwitcher
                :filters="filters"
                :value="value"
                :amount="amount"
                :loading="loading"
                @change="change"
                @submit="submit"
                @reload="reload"
                @deleteFilter="deleteFilter"
            ></FilterSwitcher>
        </div>

        <div class="footer">
            <ButtonBlock
                primary
                w100
                :loading="loading"
                @click="toggleFilter(false)"
            >
                {{ btn }}
            </ButtonBlock>
        </div>

        <PortalTarget
            name="mobileFilter"
            multiple
        ></PortalTarget>
    </div>
</template>

<script>
import FilterSwitcher from '@/components/FilterSwitcher.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import plural from '@/lib/plural.js';
import bus from '@/bus.js';

export default {
    name: 'MobileUI',

    components: {
        ButtonBlock,
        ButtonIcon,
        ButtonText,
        FilterSwitcher,
    },

    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },

        filters: {
            type: Array,
            default() {
                return [];
            },
        },

        checkedFilters: {
            type: Array,
            default: () => [],
        },

        amount: Number,

        loading: Boolean,
    },

    data() {
        return {
            show: false,
        };
    },

    computed: {
        btn() {
            return this.amount
                ? `Показать ${ this.$options.filters.number(this.amount) } ${ plural(this.amount, ['товар', 'товара', 'товаров']) }`
                : 'Нет предложений';
        },
    },

    mounted() {
        bus.$on('closeFilter', () => this.toggleFilter(false));
        bus.$on('openFilter', () => this.toggleFilter(true));
    },

    beforeDestroy() {
        bus.$off('closeFilter', this.toggleFilter);
        bus.$off('openFilter', this.toggleFilter);
    },

    methods: {
        toggleFilter(value) {
            if (value !== this.show) {
                this.show = value;
                // const el = document.getElementsByTagName('body')[0];
                // el.style.overflow = value ? 'hidden' : null;

                if (value) {
                    bus.$emit('openFilter');
                }
                else {
                    bus.$emit('closeFilter');
                    this.close();
                }
            }
        },

        close() {
            this.$emit('close');
        },

        reset() {
            this.$emit('reset');
        },

        submit() {
            this.$emit('submit');
        },

        reload() {
            this.$emit('reload');
        },

        change(payload) {
            this.$emit('change', payload);
        },

        deleteFilter(payload) {
            this.$emit('deleteFilter', payload);
        },
    },
};
</script>

<style scoped>
.filter {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--menu-z-index);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--light-c);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light-c);
}

.header__btn {
    width: 24px;
    height: 56px;
}
@media (min-width: 768px) {
    .header__btn {
        margin-left: 16px;
        margin-right: 16px;
    }
}
@media (max-width: 767px) {
    .header__btn {
        margin-left: var(--content-gap);
        margin-right: var(--content-gap);
    }
}

.summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .summary {
        padding: 16px;
    }
}
@media (max-width: 767px) {
    .summary {
        padding: 16px var(--content-gap);
    }
}

.body {
    position: relative;
    flex-grow: 1;
    overflow: auto;
}

.footer {
    position: relative;
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .footer {
        padding: 0 16px 12px;
    }
}
@media (max-width: 767px) {
    .footer {
        padding: 0 var(--content-gap) 20px;
    }
}

.footer::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,1) 100%);
}
</style>