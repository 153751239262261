import pad from '@ui/utils/pad.js';


export default () => {
    return {
        isVin() {
            return this.formData.vehicle_id_type_code === 'vin';
        },

        vehicleYearDate() {
            if (this.formData.vehicle_year) {
                return new Date(this.formData.vehicle_year, 0, 1);
            }

            return null;
        },

        // requiredDiagnosticCard() {
        //     if (this.formData.vehicle_year) {
        //         return (new Date().getFullYear() - this.formData.vehicle_year) > 4;
        //     }
        //
        //     return false;
        // },

        minVehicleDocDate() {
            if (this.vehicleYearDate) {
                return this.vehicleYearDate;
            }

            // TODO: поменять на серверное время
            return new Date();
        },

        minVehicleDocDateIso() {
            const date = this.minVehicleDocDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        maxVehicleDocDateIso() {
            const date = this.maxVehicleDocDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        // minDiagnosticCardExpDate() {
        //     // TODO: поменять на серверное время
        //     return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 5);
        // },
        //
        // minDiagnosticCardExpDateIso() {
        //     const date = this.minDiagnosticCardExpDate;
        //     return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        // },
        //
        // maxDiagnosticCardExpDateIso() {
        //     const date = this.maxDiagnosticCardExpDate;
        //     return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        // },
    };
};