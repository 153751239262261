import productsReviews from './productsReviews.js';
import partsReviews from './partsReviews.js';
import shopsReviews from './shopsReviews.js';


export default ({ HTTP }) => ({
    productsReviews: productsReviews({ HTTP }),
    partsReviews: partsReviews({ HTTP }),
    shopsReviews: shopsReviews({ HTTP }),

    async profile() {
        const response = await HTTP.get('/users/profile/');
        return response.data;
    },

    async changeProfile(data) {
        const url = '/users/change_profile/';
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async chatMessageAmount() {
        const url = '/users/chat_message_amount/';
        const response = await HTTP.get(url);
        return response.data;
    },

    async getCars() {
        const response = await HTTP.get('/users/cars/');
        return response.data;
    },

    async saveCar({ data }) {
        const response = await HTTP.post('/users/add_car/', data);
        return response.data;
    },

    async removeCar({ uuid }) {
        const response = await HTTP.post('/users/remove_car/', { uuid });
        return response.data;
    },

    async changeNotifications(data) {
        const response = await HTTP.patch('/users/change_notifications/', data);
        return response.data;
    },

    async changeEmail(data) {
        const response = await HTTP.post('/users/change_email/', data);
        return response.data;
    },

    async changeEmailConfirm(data) {
        const response = await HTTP.post('/users/change_email_confirm/', data);
        return response.data;
    },

    async brandFavorites(config) {
        const response = await HTTP.get('/users/brand_favorites/', config);
        return response.data;
    },

    async shopsFavorites(config) {
        const response = await HTTP.get('/users/shops_favorites/', config);
        return response.data;
    },

    async contractPartsFavorites(config) {
        const response = await HTTP.get('/users/contract_parts_favorites/', config);
        return response.data;
    },

    async partsFavorites(config) {
        const response = await HTTP.get('/users/parts_favorites/', config);
        return response.data;
    },

    async productsFavorites(config) {
        const response = await HTTP.get('/users/products_favorites/', config);
        return response.data;
    },

    async changePassword(data) {
        const response = await HTTP.post('/users/change_password/', data);
        return response.data;
    },

    async changePhone(data) {
        const response = await HTTP.post('/users/change_phone/', data);
        return response.data;
    },

    async changePhoneConfirm(data) {
        const response = await HTTP.post('/users/change_phone_confirm/', data);
        return response.data;
    },
});