<template>
    <div class="wrap">
        <input
            :id="id"
            ref="input"
            type="checkbox"
            class="input visually-hidden"
            :disabled="blocked"
            @change="onChange"
        >
        <label
            class="label"
            :for="id"
        >
            <span class="icon-wrap">
                <UIcon
                    name="done"
                    light
                    small
                    class="icon"
                ></UIcon>
            </span>

            <span
                v-if="hasLabelSlot || label"
                class="text"
            >
                <slot>
                    {{ label || '' }}
                </slot>
            </span>
        </label>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';
const id = value => value.id || value;

export default {
    // TODO: прокачать до сложного чекбокса

    name: 'CheckboxBase',

    components: { UIcon },

    model: {
        prop: 'model',
        event: 'change',
    },

    props: {
        id: {
            type: [Number, String],
            required: true,
        },

        model: {
            required: true,
        },

        value: {},

        label: {
            type: String,
            default: '',
        },

        blocked: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasLabelSlot() {
            return !!this.$slots.default;
        },
    },

    watch: {
        model() {
            this.setChecked();
        },
    },

    mounted() {
        this.setChecked();
    },

    methods: {
        setChecked() {
            if (this.$refs.input) {
                this.$refs.input.checked = Array.isArray(this.model)
                    ? this.model.some(v => id(v) === id(this.value))
                    : this.model;
            }
        },

        onChange($event) {
            const checked = $event.target.checked;
            if (Array.isArray(this.model)) {
                if (checked) {
                    this.$emit('change', [...this.model, this.value]);
                }
                else {
                    this.$emit('change', this.model.filter(v => id(v) !== id(this.value)));
                }
            }
            else {
                this.$emit('change', checked);
            }
        },
    },
};
</script>

<style scoped>
@import '../../assets/css/input-checkbox.css';
</style>