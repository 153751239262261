<template>
    <div>
        <div class="main-wrap bottom-gap_medium layer-1">
            <div class="main">
                <div class="photo-block">
                    <MarketItemPhotoBlock
                        :mainCover="product.cover"
                        :photos="product.photos"
                    ></MarketItemPhotoBlock>
                </div>

                <div class="main-info">
                    <BrandShortInfo
                        border
                        :brand="product.brand"
                        class="brand mb-0"
                    ></BrandShortInfo>

                    <MarketItemAttributesGroup
                        :attributes="mainAttributes"
                        :item="product"
                        modelName="product"
                        class="attributes"
                        valueWidth="216px"
                    >
                        <template #actions>
                            <ButtonText
                                :to="{
                                    name: 'market-product-specs',
                                    params: {
                                        id: product.id,
                                    },
                                }"
                                underline
                                secondary
                                dark
                                class="attributes__link"
                            >
                                Все характеристики
                            </ButtonText>
                        </template>
                    </MarketItemAttributesGroup>

                    <div
                        v-if="product.description"
                        class="hidden_only_l description"
                    >
                        <h3 class="h4 h4_block hidden-s">
                            Описание товара
                        </h3>
                        <DescriptionWithPopup
                            :description="product.description"
                            :popupTitle="product.page_title"
                        ></DescriptionWithPopup>
                    </div>
                </div>
            </div>

            <div
                v-if="product.description"
                class="visible_only_l description"
            >
                <h3 class="h4 h4_block hidden-s">
                    Описание товара
                </h3>
                <DescriptionWithPopup
                    :description="product.description"
                    :popupTitle="product.page_title"
                ></DescriptionWithPopup>
            </div>
        </div>

        <Spinner
            v-if="!initialized"
            size="big"
            center
            class="top-gap_medium"
        ></Spinner>
        <template v-else>
            <!--            <MarketItemOffersListWithoutFilter-->
            <!--                :item="product"-->
            <!--                :offers="offers.items"-->
            <!--                apiName="products"-->
            <!--                modelName="product"-->
            <!--                @changeCart="onChangeCart"-->
            <!--            >-->
            <!--                <template #empty>-->
            <!--                    <EmptyBlock-->
            <!--                        icon="empty-box"-->
            <!--                        title="Нет предложений"-->
            <!--                        text="Нет предложений по этому товару."-->
            <!--                        :btnUrl="{-->
            <!--                            name: 'market-products-catalog'-->
            <!--                        }"-->
            <!--                    ></EmptyBlock>-->
            <!--                </template>-->
            <!--            </MarketItemOffersListWithoutFilter>-->

            <section class="bottom-gap_medium">
                <h2 class="h2 h2_block">
                    <span>Отзывы</span><span
                        v-if="product.reviews_amount"
                        class="h2-sub"
                    >{{ product.reviews_amount }}</span>
                </h2>

                <div
                    v-if="product.reviews_amount"
                    class="reviews-section-content layer-1"
                >
                    <div class="reviews-layout">
                        <div class="reviews-aside">
                            <InfoMessage v-if="isAuthorized && product.has_review">
                                Вы уже оставили отзыв к&nbsp;этому товару.
                            </InfoMessage>
                            <ButtonBlock
                                v-if="!product.has_review"
                                primary
                                :disabled="!isAuthorized"
                                class="reviews-aside__action-btn"
                                @click="showMarketItemReviewPopup"
                            >
                                Оставить отзыв
                            </ButtonBlock>
                            <p
                                v-if="!isAuthorized"
                                class="mt-16"
                            >
                                <UButtonText
                                    underline
                                    primary
                                    @click="openSignInPopup"
                                >Войдите</UButtonText>, чтобы оставить отзыв
                            </p>

                            <div class="reviews-filter">
                                <ul class="reviews-filter__list">
                                    <li
                                        v-for="index in 5"
                                        :key="'reviews-filter__item-' + index"
                                        class="reviews-filter__item"
                                    >
                                        <div class="reviews-filter__option">
                                            <StarsRating
                                                :showValue="false"
                                                :value="6 - index"
                                                :blocked="reviews.amounts[5 - index].reviews_amount === 0"
                                            ></StarsRating>
                                            <span
                                                class="reviews-filter__option-label text_secondary"
                                            >{{ reviewsAmountText(5 - index) }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="reviews-main">
                            <div class="reviews-list">
                                <PagesReview
                                    v-for="(item, itemIndex) in reviews.items"
                                    :key="'reviews-list-item-' + itemIndex"
                                    :product="product"
                                    :review="item"
                                    class="reviews-list-item"
                                ></PagesReview>
                            </div>

                            <ButtonBlock
                                v-if="reviews.items.length > 3"
                                :to="{
                                    name: 'market-product-reviews',
                                    params: {
                                        id: $route.params.id
                                    }
                                }"
                                secondary
                                class="reviews-section-link"
                            >
                                Все отзывы
                            </ButtonBlock>
                        </div>
                    </div>
                </div>

                <EmptyBlock
                    v-else
                    icon="review"
                    title="Отзывов ещё нет"
                    text="Будьте первым, кто поделится мнением об этом товаре."
                >
                    <template #button>
                        <ButtonBlock
                            primary
                            low
                            class="empty__btn"
                            :disabled="!isAuthorized"
                            @click="showMarketItemReviewPopup"
                        >
                            Оставить отзыв
                        </ButtonBlock>
                        <p
                            v-if="!isAuthorized"
                            class="empty__btn empty__login"
                        >
                            <UButtonText
                                primary
                                underline
                                @click="openSignInPopup"
                            >Войдите</UButtonText>, чтобы оставить отзыв.
                        </p>
                    </template>
                </EmptyBlock>
            </section>

            <OtherProducts class="bottom-gap_medium"></OtherProducts>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import marketProductDescription from '@/store/modules/marketProductDescription.js';
import plural from '@/lib/plural.js';
import OtherProducts from '@/components/OtherProducts.vue';
import StarsRating from '@/components/StarsRating.vue';
import PagesReview from '@/components/PagesReview.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import MarketItemPhotoBlock from '@/components/MarketItemPhotoBlock.vue';
import BrandShortInfo from '@/components/BrandShortInfo.vue';
import MarketItemAttributesGroup from '@/components/MarketItemAttributesGroup.vue';
import Spinner from '@/components/Spinner.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import DescriptionWithPopup from '@/components/DescriptionWithPopup.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const MarketItemReviewPopup = () => import('@/components/popups/MarketItemReviewPopup.vue');
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');


export default {
    name: 'MarketProductDescriptionPage',

    components: {
        UButtonText,
        DescriptionWithPopup,
        InfoMessage,
        ButtonBlock,
        ButtonText,
        Spinner,
        MarketItemAttributesGroup,
        BrandShortInfo,
        MarketItemPhotoBlock,
        EmptyBlock,
        // MarketItemOffersListWithoutFilter,
        PagesReview,
        StarsRating,
        OtherProducts,
    },

    serverPrefetch() {
        return this.INIT( { id: this.$route.params.id });
    },

    computed: {
        ...mapState({
            product: state => state.marketProduct.product,
            initialized: state => state.marketProductDescription.initialized,
            // offers: state => state.marketProductDescription.offers,
            reviews: state => state.marketProductDescription.reviews,
            isAuthorized: state => state.profile.isAuthorized,
        }),

        mainAttributes() {
            let mainAttributes = [];

            if (this.product.properties) {
                this.product.properties.forEach(group => {
                    group.attributes.forEach(attr => {
                        if (attr.is_main) {
                            mainAttributes.push(attr);
                        }
                    });
                });
            }

            return mainAttributes;
        },
    },

    created() {
        this.$store.registerModule('marketProductDescription', marketProductDescription);
    },

    async mounted() {
        // bus.$on('deleteFromCart', this.onChangeCart);

        if (!this.initialized) {
            await this.INIT( { id: this.$route.params.id });
        }
        else if (this.product.reviews_amount && this.reviews.current_count === 0) {
            await this.UPDATE_REVIEWS({ id: this.product.id });
        }
    },

    beforeDestroy() {
        // bus.$off('deleteFromCart', this.onChangeCart);
        this.$store.unregisterModule('marketProductDescription');
    },

    methods: {
        // ...mapMutations({
        //     CHANGE_CART_STATUS: 'marketProductDescription/changeInCartStatus',
        // }),

        ...mapActions({
            INIT: 'marketProductDescription/init',
            UPDATE_PRODUCT: 'marketProduct/updateProduct',
            UPDATE_REVIEWS: 'marketProductDescription/updateReviews',
        }),

        showMarketItemReviewPopup() {
            const props = {
                product: this.product,
                callback: this.updateReviews,
            };
            const options = { props };
            this.$popup(MarketItemReviewPopup, options);
        },

        updateReviews() {
            this.UPDATE_REVIEWS({ id: this.product.id });
            this.UPDATE_PRODUCT({ id: this.product.id });
        },

        reviewsAmountText(index) {
            let n = this.reviews.amounts[index].reviews_amount;
            if (n === 0) {
                return 'Нет отзывов';
            }
            else {
                return n + ' ' + plural(n, ['отзыв', 'отзыва', 'отзывов']);
            }
        },

        // onChangeCart(data) {
        //     this.CHANGE_CART_STATUS(data);
        // },

        openSignInPopup() {
            this.$popup(SignInPopup);
        },
    },
};
</script>

<style scoped>
.visible_only_l {
    display: none;
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .hidden_only_l {
        display: none;
    }

    .visible_only_l {
        display: block;
    }
}

.main-wrap {
    position: relative;
    padding: var(--base-card-padding);
}

@media (min-width: 1040px) {
    .main {
        display: flex;
    }
}

@media (min-width: 1040px) {
    .main-info {
        flex-grow: 1;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .main-info {
        margin-top: var(--gap-y-small);
    }
}

@media (min-width: 1040px) {
    .photo-block {
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .photo-block {
        display: none;
    }
}

.attributes {
    margin-top: var(--gap-y-small);
}

@media (min-width: 1040px) and (max-width: 1280px) {
    .attributes >>> .key {
        max-width: 40%;
    }
    .attributes >>> .value {
        max-width: 160px;
    }
}

.attributes__link {
    display: inline-block;
}
@media (min-width: 768px) {
    .attributes__link {
        margin-top: 24px;
    }
}
@media (max-width: 767px) {
    .attributes__link {
        margin-top: 16px;
    }
}

.description {
    max-width: 780px;
    margin-top: var(--gap-y-small);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .description {
        margin-top: 24px;
    }
}
@media (max-width: 767px) {
    .description {
        margin-top: 16px;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
        border-top: 1px solid var(--border-light-c);
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .brand {
        position: absolute;
        top: var(--base-card-padding-y);
        right: var(--base-card-padding-x);
        width: 260px;
    }
}
@media (max-width: 767px) {
    .brand {
        width: 100%;
    }
}

.reviews-section-content {
    padding: var(--base-card-padding);
}

.reviews-section-link {
    margin-top: var(--gap-y-small);
}

@media (min-width: 1040px) {
    .reviews-layout {
        display: flex;
        flex-direction: row-reverse;
    }
}

.reviews-list-item:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .reviews-list-item:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .reviews-list-item:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
    }
}

@media (min-width: 1040px) {
    .reviews-main {
        flex-grow: 1;
    }
}

@media (min-width: 1281px) {
    .reviews-aside {
        width: 280px;
        flex-shrink: 0;
        margin-left: 120px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .reviews-aside {
        width: 220px;
        flex-shrink: 0;
        margin-left: 48px;
    }
}
@media (max-width: 1039px) {
    .reviews-aside {
        display: none;
    }
}

@media (min-width: 1040px) {
    .reviews-aside__action-btn {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .reviews-aside__action-btn {
        width: 100%;
    }
}

@media (max-width: 1039px) {
    .reviews-filter {
        display: none;
    }
}

@media (min-width: 1040px) {
    .reviews-filter__list {
        margin-top: 36px;
    }
}

.reviews-filter__item:not(:last-child) {
    margin-bottom: 16px;
}

.reviews-filter__option {
    display: flex;
}

.reviews-filter__option-label {
    margin-left: 12px;
}
</style>