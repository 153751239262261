<template>
    <div
        v-if="modelName !== 'contract_part' && item.rating"
        class="market-item-rating-reviews-link"
    >
        <StarsRating
            :value="item.rating"
            :showValue="false"
            class=""
        ></StarsRating>
        <UButtonText
            v-if="inPopup"
            underline
            secondary
            class="market-item-rating-reviews-link__review"
            @click="onClick"
        >
            {{ reviewsAmountText }}
        </UButtonText>
        <UButtonText
            v-else
            :to="reviewsPageTo"
            underline
            secondary
            class="market-item-rating-reviews-link__review"
        >
            {{ reviewsAmountText }}
        </UButtonText>
    </div>
</template>

<script>
import plural from '@/lib/plural.js';
import StarsRating from '@/components/StarsRating.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const ReviewsPopup = () => import('@/components/popups/ReviewsPopup.vue');

const modelNameLinksMap = {
    'product': 'market-product-reviews',
    'part_product': 'market-new-part-reviews',
    'shop': 'market-shop-reviews',
};

export default {
    name: 'MarketItemRatingReviewsLink',

    components: { UButtonText, StarsRating },

    props: {
        item: {
            type: Object,
            default: () => {},
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'shop', 'contract_part'].includes(value);
            },
            default: 'product',
        },

        inPopup: Boolean,
    },

    computed: {
        reviewsAmountText() {
            return this.item.reviews_amount + ' ' + plural(this.item.reviews_amount, ['отзыв', 'отзыва', 'отзывов']);
        },

        reviewsPageTo() {
            const to = {
                name: modelNameLinksMap[this.modelName],
                params: {
                    id: this.item.id || this.item.product_id || this.item.part_product_id,
                },
            };

            if (this.modelName === 'shop') {
                to.params.codename = this.item.company.codename;
            }

            return to;
        },
    },

    methods: {
        onClick() {
            const { item, modelName } = this;
            const props = { item, modelName };
            const options = { props };
            this.$popup(ReviewsPopup, options);
        },
    },
};
</script>

<style>
.market-item-rating-reviews-link {
    display: flex;
    align-items: center;
}

.market-item-rating-reviews-link__review {
    margin-left: 12px;
    color: var(--font-secondary-color);
}
</style>