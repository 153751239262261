<template>
    <div
        v-on-clickaway="hideSelect"
        class="simple-select"
    >
        <transition
            name="fade"
        >
            <button
                class="pseudo-input"
                @click="toggle"
            >
                <span
                    :key="value.name"
                    class="value"
                >{{ value.name }}</span>
                <UIcon
                    name="arrow-down"
                    tiny
                    class="simple-select__arrow-icon"
                    :class="{ opened: opened }"
                ></UIcon>
            </button>
        </transition>

        <TransitionExpandFade>
            <div
                v-if="opened"
                class="select-list_outer"
            >
                <ul class="select-list">
                    <li
                        v-for="(option, optionIndex) in options"
                        :key="'select-item-' + optionIndex"
                        class="select-item"
                    >
                        <button
                            class="select-btn"
                            :class="{
                                'select-btn_selected': value.value === option.value,
                            }"
                            @click="select(option)"
                        >
                            {{ option.name }}
                        </button>
                    </li>
                </ul>
            </div>
        </TransitionExpandFade>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import TransitionExpandFade from './_transitions/TransitionExpandFade.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'SimpleSelect',

    components: {
        UIcon,
        TransitionExpandFade,
    },

    mixins: [clickaway],

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },

        options: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            opened: false,
        };
    },

    methods: {
        select(option) {
            this.$emit('change', option);
            this.hideSelect();
        },

        toggle() {
            this.opened = !this.opened;
        },

        hideSelect() {
            this.opened = false;
        },
    },
};
</script>

<style scoped>
.simple-select {
    position: relative;
    font-size: var(--base-fz);
}

.pseudo-input {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: left;
    color: var(--dark-c);
}

.pseudo-input:hover,
.pseudo-input:focus {
    color: var(--primary-color);
    transition: color var(--transition);
}

.simple-select__arrow-icon {
    margin-left: 8px;
    fill: currentColor;
    transition: fill var(--transition), transform var(--transition);
}

.simple-select__arrow-icon.opened {
    transform: rotate(180deg);
}

.select-list_outer {
    position: absolute;
    top: calc(100% + 4px);
    left: -16px;
    z-index: 2;
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
    border-radius: var(--border-radius);
}

.select-list {
    max-width: 220px;
    margin: 4px;
    width: max-content;
}

.select-btn {
    display: block;
    width: 100%;
    padding: 8px 12px;
    font-size: var(--base-fz);
    text-align: left;
    border-radius: var(--border-radius);
    transition: background-color var(--transition);
}

.select-btn:hover:not(.select-btn_selected),
.select-btn:focus:not(.select-btn_selected) {
    background-color: var(--lightest-bg);
}

.select-btn_selected {
    color: var(--font-secondary-color);
    cursor: default;
}
</style>