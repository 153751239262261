<template>
    <PopupWrap
        @close="close"
    >
        <FormTemplate class="popup-content">
            <template slot="body">
                <h2 class="h2 h2_block">
                    Водители
                </h2>

                <FormManager
                    ref="form"
                    v-model="formData"
                    :formTag="false"
                    @error="onError"
                >
                    <div class="flex align-items-center">
                        <FormField
                            name="unlimited_drivers_amount"
                            type="checkbox"
                            label="Без ограничения на водителей"
                            class="unlimited_drivers_amount"
                        ></FormField>

                        <UTooltip>
                            <template #trigger="{ value, handlers, attrs }">
                                <UTooltipIcon
                                    :active="value"
                                    class="ml-8"
                                    v-bind="attrs"
                                    v-on="handlers"
                                ></UTooltipIcon>
                            </template>

                            Неограниченное количество лиц, допущенных к управлению автомобилем.
                        </UTooltip>
                    </div>

                    <template v-if="!formData.unlimited_drivers_amount">
                        <FormCollection name="drivers">
                            <FormCollectionItem>
                                <template #default="{ index, item }">
                                    <div
                                        :class="{
                                            'form-grid_short': !item.prev_license
                                        }"
                                        class="form-grid mt-36"
                                    >
                                        <div class="title-block">
                                            <h3 class="h3">
                                                <span class="capitalize">{{ (index + 1) | ordinalNumeral }}</span> водитель
                                            </h3>

                                            <ButtonText
                                                v-if="index > 0"
                                                primary
                                                dashed
                                                @click="removeDriver(index)"
                                            >
                                                Удалить
                                            </ButtonText>
                                        </div>

                                        <FormField
                                            name="last_name"
                                            label="Фамилия"
                                            validations="required"
                                            class="last_name"
                                        ></FormField>

                                        <FormField
                                            name="first_name"
                                            label="Имя"
                                            validations="required"
                                            class="first_name"
                                        ></FormField>

                                        <FormField
                                            name="middle_name"
                                            label="Отчество"
                                            validations="required"
                                            class="middle_name"
                                        ></FormField>

                                        <FormField
                                            name="birth_date"
                                            type="calendar"
                                            label="Дата рождения"
                                            stepByStep
                                            :min="minBirthDate"
                                            :max="maxBirthDate"
                                            :validations="`required|min:${ minBirthDateIso }|max:${ maxBirthDateIso }`"
                                            class="birth_date"
                                        ></FormField>

                                        <h4 class="h4 h4_text license-number-title">
                                            Водительское удостоверение
                                        </h4>

                                        <!-- re на ВУ -->
                                        <FormField
                                            name="license_number"
                                            label="Серия и номер ВУ"
                                            mask="0000 000000"
                                            validations="required|re"
                                            :validationRules="{
                                                re: ({ value }) => {
                                                    return value ? !/(\d{4})\s(\d{6})/g.test(value) : false;
                                                },
                                            }"
                                            class="license_number"
                                        ></FormField>

                                        <!-- min birth_date + 18 max now -->
                                        <FormField
                                            name="experience_start_date"
                                            type="date"
                                            visibleMask
                                            label="Дата начала стажа"
                                            :validations="`required|min:${ getMinExperienceStartDateIso(index) }|max:${ maxExperienceStartDateIso }`"
                                            class="experience_start_date"
                                        ></FormField>

                                        <InfoMessage class="info-message">
                                            Если у водителя была замена прав в течение года, история вождения (КБМ) скорее всего не перенесена на новое ВУ. Рекомендуем указать данные предыдущего ВУ, чтобы расчёт стоимости учитывал скидку за КБМ. Данные предыдущего ВУ указаны на обороте новых прав.
                                        </InfoMessage>

                                        <FormField
                                            name="prev_license"
                                            type="checkbox"
                                            label="Менялось водительское удостоверение за последний год"
                                            class="prev_license"
                                        ></FormField>

                                        <template v-if="item.prev_license">
                                            <h4 class="h4 h4_text prev-license-number-title">
                                                Старое водительское удостоверение
                                            </h4>

                                            <FormField
                                                name="prev_last_name"
                                                label="Фамилия"
                                                validations="required"
                                                class="prev_last_name"
                                            ></FormField>

                                            <FormField
                                                name="prev_first_name"
                                                label="Имя"
                                                validations="required"
                                                class="prev_first_name"
                                            ></FormField>

                                            <FormField
                                                name="prev_middle_name"
                                                label="Отчество"
                                                validations="required"
                                                class="prev_middle_name"
                                            ></FormField>

                                            <!-- re на ВУ -->
                                            <FormField
                                                name="prev_license_number"
                                                label="Серия и номер ВУ"
                                                mask="00RR 000000"
                                                upper
                                                validations="required|re"
                                                :validationRules="{
                                                    re: ({ value }) => {
                                                        return value ? !/(\d{2})([\dА-Я]{2})\s(\d{6})/g.test(value) : false;
                                                    },
                                                }"
                                                class="prev_license_number"
                                            ></FormField>

                                            <!-- min birth_date + 18 max now -->
                                            <FormField
                                                name="prev_license_date"
                                                type="date"
                                                visibleMask
                                                label="Дата выдачи ВУ"
                                                :validations="`required|min:${ getMinPrevLicenseDateIso(index) }|max:${ maxPrevLicenseDateIso }`"
                                                class="prev_license_date"
                                            ></FormField>
                                        </template>
                                    </div>
                                </template>
                            </FormCollectionItem>
                        </FormCollection>

                        <ButtonText
                            v-if="formData.drivers.length < 5"
                            secondary
                            dark
                            dashed
                            class="add-driver"
                            @click="addDriver()"
                        >
                            + Добавить ещё одного водителя
                        </ButtonText>
                    </template>

                    <InfoMessage class="mt-20">
                        После изменения данных стоимость страхового полиса может быть пересчитана.
                    </InfoMessage>
                </FormManager>
            </template>

            <template slot="footer">
                <ButtonBlock
                    secondary
                    class="button-secondary"
                    @click="cancel"
                >
                    Отмена
                </ButtonBlock>
                <ButtonBlock
                    primary
                    :disabled="invalid"
                    :loading="loading"
                    class="button-primary"
                    @click="confirm"
                >
                    Сохранить
                </ButtonBlock>
            </template>
        </FormTemplate>
    </PopupWrap>
</template>

<script>
// utils
import deepClone from '@/lib/deepClone.js';
// mixins
import popup from '@/mixins/popup.js';
// use
import useChangePopupData from '@/pages/insurance/osago/_uuid/useChangePopupData.js';
import useDriversData from '@/pages/insurance/osago/_uuid/useDriversData.js';
import useDriversComputed from '@/pages/insurance/osago/_uuid/useDriversComputed.js';
import useDriversWatch from '@/pages/insurance/osago/_uuid/useDriversWatch.js';
import useChangePopupMethods from '@/pages/insurance/osago/_uuid/useChangePopupMethods.js';
import useDriversMethods from '@/pages/insurance/osago/_uuid/useDriversMethods.js';
import useChangePopupProps from '@/pages/insurance/osago/_uuid/useChangePopupProps.js';
import useChangePopupComputed from '@/pages/insurance/osago/_uuid/useChangePopupComputed.js';
// components
import PopupWrap from '@/components/popups/PopupWrap.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import FormTemplate from './FormTemplate.vue';
import FormField from '@/components/_form/FormField.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormCollection from '@/components/_form/FormCollection.vue';
import FormCollectionItem from '@/components/_form/FormCollectionItem.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


export default {
    name: 'InsurerOsagoChangeDriverPopup',

    components: {
        UTooltipIcon,
        UTooltip,
        ButtonBlock,
        ButtonText,
        FormCollectionItem,
        FormCollection,
        FormManager,
        PopupWrap,
        InfoMessage,
        FormTemplate,
        FormField,
    },

    mixins: [
        popup,
    ],

    props: {
        ...useChangePopupProps(),
    },

    data() {
        return {
            ...useChangePopupData(),
            ...useDriversData(),
        };
    },

    computed: {
        ...useDriversComputed(),
        ...useChangePopupComputed(),
    },

    watch: {
        ...useDriversWatch({ isPopup: true }),
    },

    mounted() {
        this.formData = deepClone(this.initFormData);
    },

    methods: {
        ...useDriversMethods(),
        ...useChangePopupMethods(),
    },
};
</script>

<style scoped>
.last_name {
    grid-area: last_name;
}

.first_name {
    grid-area: first_name;
}

.middle_name {
    grid-area: middle_name;
}

.birth_date {
    grid-area: birth_date;
}

.license-number-title {
    grid-area: license-number-title;
}

.license_number {
    grid-area: license_number;
}

.experience_start_date {
    grid-area: experience_start_date;
}

.prev_license {
    grid-area: prev_license;
}

.prev-license-number-title {
    grid-area: prev-license-number-title;
}

.prev_last_name {
    grid-area: prev_last_name;
}

.prev_first_name {
    grid-area: prev_first_name;
}

.prev_middle_name {
    grid-area: prev_middle_name;
}

.prev_license_number {
    grid-area: prev_license_number;
}

.prev_license_date {
    grid-area: prev_license_date;
}

@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.title-block {
    grid-area: title-block;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.h4 {
    margin-top: 4px;
}
@media (min-width: 768px) {
    .h4 {
        margin-bottom: -4px;
    }
}
@media (max-width: 767px) {
    .h4 {
        margin-bottom: 16px;
    }
}

.info-message {
    grid-area: info-message;
}

.add-driver {
    width: max-content;
    margin-top: 36px;
}

@media (min-width: 1040px) {
    .form-grid:not(.form-grid_short) {
        grid-template-areas:
        "title-block title-block title-block"
        "last_name first_name middle_name"
        "birth_date . ."
        "license-number-title license-number-title license-number-title"
        "license_number experience_start_date ."
        "info-message info-message info-message"
        "prev_license prev_license prev_license"
        "prev-license-number-title prev-license-number-title prev-license-number-title"
        "prev_last_name prev_first_name prev_middle_name"
        "prev_license_number prev_license_date ."
    }

    .form-grid_short {
        grid-template-areas:
        "title-block title-block title-block"
        "last_name first_name middle_name"
        "birth_date . ."
        "license-number-title license-number-title license-number-title"
        "license_number experience_start_date ."
        "info-message info-message info-message"
        "prev_license prev_license prev_license"
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .form-grid:not(.form-grid_short) {
        grid-template-areas:
        "title-block title-block"
        "last_name first_name"
        "middle_name birth_date"
        "license-number-title license-number-title"
        "license_number experience_start_date"
        "info-message info-message"
        "prev_license prev_license"
        "prev-license-number-title prev-license-number-title"
        "prev_last_name prev_first_name"
        "prev_middle_name ."
        "prev_license_number prev_license_date"
    }

    .form-grid_short {
        grid-template-areas:
        "title-block title-block"
        "last_name first_name"
        "middle_name birth_date"
        "license-number-title license-number-title"
        "license_number experience_start_date"
        "info-message info-message"
        "prev_license prev_license"
    }
}

@media (max-width: 767px) {
    .popup-content >>> .footer {
        margin-top: auto;
        padding-top: 28px;
    }
}
</style>