export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        brands: {
            items: [],
            current_count: 0,
        },
        meta: {},
    }),

    mutations: {
        setBrands(state, { results, current_count }) {
            state.brands.items = results;
            state.brands.current_count = current_count;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.initialized = false;
            state.brands = {
                items: [],
                current_count: 0,
            };
            state.meta = {};
        },
    },

    actions: {
        async init({ commit, dispatch }) {
            try {
                const [brands, meta] = await Promise.all(
                    [
                        dispatch('getBrands'),
                        dispatch('getMeta'),
                    ],
                );

                commit('setBrands', brands);
                commit('setMeta', meta);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { brands, meta };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getBrands() {
            const params = {
                limit: 100000,
                offset: 0,
                order_by: 'name',
            };

            return await this.$api.brands.get({ params });
        },

        async getMeta() {
            return await this.$api.staticPages.get('market-brands');
        },
    },
};