<template>
    <div class="info-page-layout__main">
        <transition-group
            name="fade-page"
            mode="out-in"
            appear
        >
            <Spinner
                v-if="!initialized"
                key="loading"
                size="big"
                class="content-loader"
            ></Spinner>

            <div v-else key="content">
                <InfoPageLayoutHead :customInfo="data"></InfoPageLayoutHead>

                <div class="content layer-1 container_xs-external">
                    <PostEditorView :content="data.content"></PostEditorView>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import additionalPages from '@/store/modules/additionalPages.js';
import { mapActions, mapMutations, mapState } from 'vuex';
import PostEditorView from "../components/views/PostEditorView.vue";
import InfoPageLayoutHead from "../components/InfoPageLayoutHead.vue";
import Spinner from '@/components/Spinner.vue';

export default {
    name: "AdditionalPage",

    components: {
        Spinner,
        InfoPageLayoutHead,
        PostEditorView,
    },

    metaInfo() {
        return {
            title: this.data.meta_title || this.data.name,
            meta: [
                { name: 'description', content: this.data.meta_description },
                { name: 'keywords', content: this.data.meta_keywords },
                { property: 'og:title', content: this.data.meta_title || this.data.name },
                { property: 'og:description', content: this.data.meta_description },
                { property: 'og:image', content: this.data.og_image ? this.$links.uploads + this.data.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        return this.INIT({ codename: this.$route.params.codename });
    },

    computed: {
        ...mapState({
            data: state => state.additionalPages.data,
            initialized: state => state.additionalPages.initialized,
            loading: state => state.additionalPages.loading,
        }),
    },

    created() {
        this.$store.registerModule('additionalPages', additionalPages);
    },

    mounted() {
        if (!this.initialized) this.init();
        else this.updateBreadcrumbs();
    },

    beforeDestroy() {
        this.$store.unregisterModule('additionalPages');
    },

    methods: {
        ...mapMutations({
            DESTROY: 'additionalPages/destroy',
        }),

        ...mapActions({
            INIT: 'additionalPages/init',
        }),

        async init() {
            await this.INIT({ codename: this.$route.params.codename });
            this.updateBreadcrumbs();
        },

        updateBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'additional-page',
                        params: {
                            codename: this.$route.params.codename,
                        },
                    },
                    title: this.data.title,
                },
            ]);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .content {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .content {
        padding: 24px;
    }
}

@media (min-width: 1040px) {
    .content-loader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (max-width: 1039px) {
    .content-loader {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>