<template>
    <div
        :class="[
            'notification',
            'notification_' + position,
            type === 'cart' ? 'notification_cart' : 'notification_message',
            show && 'visible',
        ]"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
    >
        <div
            v-if="type === 'cart'"
            :class="[
                'cart-notification',
                'cart-notification_with-' + cover.classModificator,
            ]"
        >
            <CloseBtn
                :size="24"
                :shadow="false"
                class="cart-notification__close"
                @click="close"
            ></CloseBtn>

            <p class="cart-notification__title">
                Товар добавлен в корзину
            </p>

            <div class="market-item">
                <img
                    :src="cover.src"
                    alt=""
                    :class="[
                        'market-item__cover',
                        'market-item__cover_' + cover.classModificator,
                    ]"
                >
                <div class="market-item__info">
                    <p class="market-item__title">
                        {{ item.name }}
                    </p>
                    <b class="market-item__price">
                        {{ item.price | price }}
                    </b>
                </div>
            </div>

            <ButtonBlock
                :to="{
                    name: 'cart'
                }"
                primary
                low
                class="cart-notification__btn"
                @click.native="close"
            >
                Перейти в корзину
            </ButtonBlock>
        </div>

        <div
            v-else
            :class="[
                'message-notification',
                type
            ]"
        >
            <div class="message-notification__text-wrap">
                <p
                    v-for="(item, index) in message"
                    :key="index"
                    class="message-notification__text"
                >
                    {{ item }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '../bus.js';
import CloseBtn from "./project-buttons/CloseBtn.vue";
import { mapState } from "vuex";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";

const successTime = 3000;
const errorTime = 6000;
const mouseLeaveTime = 1000;

export default {
    name: 'Notification',

    components: { ButtonBlock, CloseBtn },

    data() {
        return {
            show: false,
            timeout: null,
            type: '',
            position: 'top',
            message: [],
            item: {},
            modelName: '',
            offer: {},
        };
    },

    computed: {
        ...mapState({
            queue: state => state.notifications.queue,
            error: state => state.notifications.error,
            success: state => state.notifications.success,
        }),

        cover() {
            const coverObject = ((this.item.cover || {}).thumbnails || null);
            const imageLink = coverObject
                ? (coverObject.market_item_cover_mini || coverObject.market_item_cover_medium)
                : false;
            return imageLink
                ? { src: this.$links.uploads + imageLink, classModificator: 'cover' }
                : { src: require('../assets/images/product-placeholder.svg'), classModificator: 'placeholder' };
        },
    },

    watch: {
        error() {
            if (this.error.length) {
                const reverseErrors = this.error.reverse();
            }
        },
    },

    mounted() {
        bus.$on('notify', this.set);
    },

    beforeDestroy() {
        bus.$off('notify', this.set);
    },

    methods: {
        set({ type, message, item, modelName, offer, position }) {
            this.position = position || 'top';
            this.type = type || 'message';
            this.item = item;
            this.modelName = modelName;
            this.offer = offer;

            this.message = [];
            this.message.push(message);

            this.show = true;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.show = false;
                this.timeout = null;
                this.message = [];
            }, type === 'success' ? successTime : errorTime);
        },

        close() {
            this.show = false;
            this.message = [];
            clearTimeout(this.timeout);
        },

        onMouseover() {
            clearTimeout(this.timeout);
        },

        onMouseleave() {
            this.timeout = setTimeout(() => {
                this.show = false;
                this.timeout = null;
                this.message = [];
            }, mouseLeaveTime);
        },
    },
};
</script>

<style scoped>
.notification {
    position: fixed;
    z-index: var(--notify-z-index);
    opacity: 0;
}
.notification.visible {
    opacity: 1;
}

@keyframes showNotificationMessageDesktop {
    0% {
        right: -380px;
        opacity: 0;
    }

    100% {
        right: 24px;
        opacity: 1;
    }
}

@keyframes hideNotificationMessageDesktop {
    0% {
        right: 24px;
        opacity: 1;
    }

    100% {
        right: -380px;
        opacity: 0;
    }
}

@keyframes showNotificationOnTopMessageMobile {
    0% {
        top: -30%;
        opacity: 0;
    }

    100% {
        top: 16px;
        opacity: 1;
    }
}

@keyframes hideNotificationOnTopMessageMobile {
    0% {
        top: 16px;
        opacity: 1;
    }

    100% {
        top: -30%;
        opacity: 0;
    }
}

@keyframes showNotificationOnBottomMessageMobile {
    0% {
        bottom: -30%;
        opacity: 0;
    }

    100% {
        bottom: 16px;
        opacity: 1;
    }
}

@keyframes hideNotificationOnBottomMessageMobile {
    0% {
        bottom: 16px;
        opacity: 1;
    }

    100% {
        bottom: -30%;
        opacity: 0;
    }
}


@media (min-width: 768px) {
    .notification_message {
        top: 24px;
        right: -340px;
        animation: hideNotificationMessageDesktop .1s ease-in forwards;
    }
    .notification_message.visible {
        right: 24px;
        opacity: 1;
        animation: showNotificationMessageDesktop .2s ease-out forwards;
    }
}
@media (max-width: 767px) {
    .notification_message {
        left: 20px;
        right: 20px;
    }

    .notification_message.notification_top {
        animation: hideNotificationOnTopMessageMobile .1s ease-out forwards;
    }
    .notification_message.notification_top.visible {
        animation: showNotificationOnTopMessageMobile .2s ease-out forwards;
    }

    .notification_message.notification_bottom {
        animation: hideNotificationOnBottomMessageMobile .1s ease-out forwards;
    }
    .notification_message.notification_bottom.visible {
        animation: showNotificationOnBottomMessageMobile .2s ease-out forwards;
    }
}

.message-notification {
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
    border-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .message-notification {
        width: 340px;
        padding: 20px 24px;
    }
}
@media (max-width: 767px) {
    .message-notification {
        width: 100%;
        padding: 12px 16px;
    }
}

.message-notification.error {
    border-left: 4px solid var(--primary-color);
}

.message-notification.success {
    border-left: 4px solid var(--accent-green);
}

.message-notification.dark {
    background-color: var(--dark-c);
    color: var(--light-c);
    font-family: var(--f-semi-bold);
}

.message-notification__text:not(:last-child) {
    margin-bottom: 4px;
}

@media (min-width: 768px) {
    .notification_cart {
        top: 24px;
        right: -380px;
        animation: hideNotificationCartDesktop .1s ease-out forwards;
    }

    .notification_cart.visible {
        right: 24px;
        animation: showNotificationCartDesktop .2s ease-out forwards;
    }
}
@media (max-width: 767px) {
    .notification_cart {
        bottom: -100%;
        right: 0;
        animation: hideNotificationCartMobile .1s ease-out forwards;
    }

    .notification_cart.visible {
        bottom: 0;
        right: 0;
        animation: showNotificationCartMobile .2s ease-out forwards;
    }
}

@keyframes showNotificationCartDesktop {
    0% {
        right: -380px;
        opacity: 0;
    }

    100% {
        right: 24px;
        opacity: 1;
    }
}

@keyframes hideNotificationCartDesktop {
    0% {
        right: 24px;
        opacity: 1;
    }

    100% {
        right: -380px;
        opacity: 0;
    }
}

@keyframes showNotificationCartMobile {
    0% {
        bottom: -100%;
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes hideNotificationCartMobile {
    0% {
        bottom: 0;
        opacity: 1;
    }

    100% {
        bottom: -100%;
        opacity: 0;
    }
}

.cart-notification {
    position: relative;
    box-shadow: var(--base-shadow);
    background-color: var(--light-c);
}
@media (min-width: 768px) {
    .cart-notification {
        width: 380px;
        padding: 24px;
        border-radius: var(--border-radius);
    }
}
@media (max-width: 767px) {
    .cart-notification {
        width: 100vw;
        padding: 20px var(--content-gap);
    }
}

.cart-notification__close {
    position: absolute;
    top: 12px;
    right: 12px;
}

.cart-notification__title {
    font-size: var(--big-fz);
    font-family: var(--f-bold);
}
@media (min-width: 768px) {
    .cart-notification__title {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .cart-notification__title {
        margin-bottom: 8px;
    }
}

.cart-notification__btn {
    display: block;
    width: 100%;
}

.market-item {
    display: flex;
    align-items: center;
    margin-bottom: var(--gap-y-mini);
}

.market-item__cover {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    background-color: var(--light-bg);
}
@media (min-width: 768px) {
    .market-item__cover {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .market-item__cover {
        margin-right: 8px;
    }

    .market-item__cover_placeholder {
        display: none;
    }
}

.market-item__info {
    flex-grow: 1;
}
@media (max-width: 767px) {
    .market-item__info {
        width: calc(100% - 48px - 8px);
    }
}

@media (max-width: 767px) {
    .market-item__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.market-item__price {
    margin-top: 8px;
}
@media (max-width: 767px) {
    .market-item__price {
        display: none;
    }
}
</style>
