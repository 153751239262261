<template>
    <div ref="parent">
        <transition-group
            name="fade-height"
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @leave="leave"
            @after-leave="afterLeave"
        >
            <slot></slot>
        </transition-group>
    </div>
</template>

<script>
const animationDurationFast = 200;

export default {
    name: 'TransitionWidthHeightGroup',

    methods: {
        beforeEnter(el) {
            el.style.position = 'absolute';
            el.style.opacity = '0';
            el.style.transition = `opacity ${ animationDurationFast }ms ease-in-out`;
        },
        beforeLeave(el) {
            this.$refs.parent.style.position = 'relative';
            this.$refs.parent.style.overflow = 'hidden';
            this.$refs.parent.style.height = el.clientHeight + 'px';
            this.$refs.parent.style.width = el.clientWidth + 'px';
            this.$refs.parent.style.transition = `width ${ animationDurationFast }ms ease-in-out, height ${ animationDurationFast }ms ease-in-out`;

            el.style.position = 'absolute';
            el.style.opacity = '1';
            el.style.transition = `opacity ${ animationDurationFast }ms ease-in-out`;
        },

        leave(el) {
            el.style.top = '50%';
            el.style.left = '50%';
            el.style.transform = 'translate(-50%, -50%)';
            el.style.opacity = '0';
        },
        enter(el) {
            this.$refs.parent.style.height = el.clientHeight + 'px';
            this.$refs.parent.style.width = el.clientWidth + 'px';

            el.style.top = '50%';
            el.style.left = '50%';
            el.style.transform = 'translate(-50%, -50%)';
            el.style.opacity = '1';
        },

        afterLeave(el) {
            el.style.position = null;
            el.style.top = null;
            el.style.left = null;
            el.style.transform = null;
            el.style.opacity = null;
            el.style.transition = null;
        },
        afterEnter(el) {
            el.style.position = null;
            el.style.top = null;
            el.style.left = null;
            el.style.transform = null;
            el.style.opacity = null;
            el.style.transition = null;

            this.$refs.parent.style.transition = null;
            this.$refs.parent.style.height = null;
            this.$refs.parent.style.width = null;
            this.$refs.parent.style.overflow = null;
        },
    },
};
</script>