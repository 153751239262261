<template>
    <Spinner
        v-if="!initialized"
        size="big"
        center
    ></Spinner>

    <MarketItemsListWithOffers
        v-else
        apiName="products"
        modelName="product"
        :items="products.items"
        :amount="products.current_count"
        :totalAmount="productsTotalCount"
        :staticFilters="staticFilters"
        :dynamicFilters="dynamicFilters"
        :checkedFilters="checkedFilters"
        :filterData="filterData"
        :sortData="sortData"
        :sortOptions="sortOptions"
        :hasNext="hasNext"
        :loading="loading"
        @changeFilter="onChangeFilter"
        @resetFilters="onResetFilters"
        @deleteFilter="onDeleteFilter"
        @changeSort="onChangeSort"
        @reload="reload"
        @load="load"
    >
        <template #listItem="{ item, itemIndex }">
            <MarketItemRowListItem
                :item="item"
                modelName="product"
                reviewsInPopup
                @changeFavorite="onChangeFavorite(itemIndex, ...arguments)"
                @changeCart="onChangeCart(itemIndex)"
            ></MarketItemRowListItem>
        </template>

        <template #gridItem="{ item, itemIndex }">
            <MarketItemColumn
                :item="item"
                apiName="product"
                modelName="product"
                reviewsInPopup
                @changeFavorite="onChangeFavorite(itemIndex, ...arguments)"
                @changeCart="onChangeCart(itemIndex)"
            ></MarketItemColumn>
        </template>

        <template #inStockEmpty>
            <EmptyBlock
                icon="empty-box"
                title="Нет автотоваров"
                text="Нет информации об актуальных предложениях магазина."
                btnText="Перейти в каталог"
                :btnUrl="{
                    name: 'market',
                }"
            ></EmptyBlock>
        </template>
    </MarketItemsListWithOffers>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { HTTP } from '@/http.js';
import bus from '@/bus.js';
import { animationDurationFast } from '@/plugins/animationSettings.js';
import MarketItemsListWithOffers from '@/components/MarketItemsListWithOffers.vue';
import MarketItemRowListItem from '@/components/MarketItemRowListItem.vue';
import MarketItemColumn from '@/components/MarketItemColumn.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import Spinner from '@/components/Spinner.vue';

const getCategory = (arr, itemId) => (
    arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === itemId) return item;
        if (item.children) return getCategory(item.children, itemId);
    }, null)
);

export default {
    name: 'ShopProductsPage',

    components: {
        Spinner,
        EmptyBlock,
        MarketItemColumn,
        MarketItemRowListItem,
        MarketItemsListWithOffers,
    },

    serverPrefetch() {
        return this.INIT({
            id: this.$route.params.id,
            route: this.$route,
        });
    },

    data() {
        return {
            rangeData: {
                limit: 36,
                offset: 0,
            },
            source: null,
        };
    },

    computed: {
        ...mapState({
            shop: state => state.marketShop.shop,
            productsTotalCount: state => state.marketShop.productsTotalCount,
            products: state => state.marketShopProducts.products,
            hasNext: state => state.marketShopProducts.products.hasNext,
            initialized: state => state.marketShopProducts.initialized,
            loading: state => state.marketShopProducts.loading,
            staticFilters: state => state.marketShopProducts.filter.staticFilters,
            dynamicFilters: state => state.marketShopProducts.filter.dynamicFilters,
            checkedFilters: state => state.marketShopProducts.filter.checked,
            productsDefaultFacets: state => state.marketShop.productsDefaultFacets,
            filterData: state => state.marketShopProducts.filter.value,
            sortOptions: state => state.marketShopProducts.sort.options,
            sortData: state => state.marketShopProducts.sort.value,
        }),

        ...mapGetters({
            filterQuery: 'marketShopProducts/filter/query',
            sortQuery: 'marketShopProducts/sort/query',
        }),

        location() {
            return {
                name: this.$route.name,
                query: Object.assign(
                    {},
                    this.filterQuery,
                    this.sortQuery,
                ),
                meta: {
                    savePosition: true,
                },
            };
        },

        fetchParams() {
            return Object.assign(
                {
                    shop: this.$route.params.id,
                    get_facets: 'all',
                },
                this.filterQuery,
                this.sortQuery,
                this.rangeData,
            );
        },
    },

    watch: {
        '$route.params.id': {
            handler() {
                this.DESTROY();
                this.init();
            },
        },
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();

        bus.$off('deleteFromCart', this.onDeleteFromCart);
    },

    methods: {
        ...mapMutations({
            DELETE_FILTER: 'marketShopProducts/filter/deleteFilter',
            RESET_FILTERS: 'marketShopProducts/filter/reset',
            SET_SORT_VALUE: 'marketShopProducts/sort/setValue',
            SET_PRODUCTS: 'marketShopProducts/setProducts',
            ADD_PRODUCTS: 'marketShopProducts/addProducts',
            CHANGE_FAVORITE: 'marketShopProducts/changeFavorite',
            CHANGE_CART: 'marketShopProducts/changeCart',
            DELETE_FROM_CART: 'marketShopProducts/deleteFromCart',
            SET_LOADING: 'marketShopProducts/setLoading',
            DESTROY: 'marketShopProducts/destroy',
        }),

        ...mapActions({
            INIT: 'marketShopProducts/init',
            GET_PRODUCTS: 'marketShopProducts/getProducts',
            CHANGE_FILTER: 'marketShopProducts/filter/change',
            GET_DYNAMIC_FILTER: 'marketShopProducts/filter/getDynamicFiltersInCategory',
        }),

        async init() {
            if (!this.initialized) {
                await this.INIT({
                    id: this.$route.params.id,
                    route: this.$route,
                });
            }
            if (this.productsTotalCount) {
                this.replaceQuery();
                bus.$on('deleteFromCart', this.onDeleteFromCart);
            }
        },

        async fetch() {
            this.SET_LOADING(true);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                params: this.fetchParams,
                cancelToken: this.source.token,
            };

            const response = await this.GET_PRODUCTS(payload);
            if (response) {
                this.source = null;
                await this.updateQuery();
                return response;
            }
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) {
                this.ADD_PRODUCTS(response);
                this.updateFiltersOptions(this.dynamicFilters, response.facets, response.current_count);
                this.SET_LOADING(false);
            }
        },

        async reload() {
            this.rangeData.offset = 0;
            const response = await this.fetch();
            if (response) {
                let categoryFilters = {
                    dynamicFilters: [],
                };

                if (this.$route.query.category) {
                    let category = getCategory(response.facets.categories, Number(this.$route.query.category));
                    if (category) {
                        categoryFilters = await this.GET_DYNAMIC_FILTER({
                            slug: category.slug,
                            routeName: this.$route.name,
                        });
                    }
                }

                this.updateFiltersOptions(categoryFilters.dynamicFilters, response.facets, response.current_count);
                this.$store.commit('marketShopProducts/filter/updateValue', categoryFilters.dynamicFilters);

                // Двойной по веремени timeout для того, чтобы сначала точно завершилась анимация фильтров,
                // и только потом произошел ререндер списка
                setTimeout(() => {
                    this.SET_PRODUCTS(response);
                    this.SET_LOADING(false);
                }, animationDurationFast * 2);
            }
        },

        onChangeFilter(payload) {
            this.CHANGE_FILTER(payload);
        },

        onResetFilters() {
            this.RESET_FILTERS();
            this.reload();
        },

        onDeleteFilter(filter) {
            this.DELETE_FILTER(filter);
            this.reload();
        },

        updateFiltersOptions(dynamicFilters, facets, current_count) {
            this.$store.commit('marketShopProducts/filter/setStaticFilters', {
                config: this.staticFilters,
                facets,
                defaultFacets: this.productsDefaultFacets,
            });
            this.$store.commit('marketShopProducts/filter/setDynamicFilters', {
                config: dynamicFilters,
                facets,
                defaultFacets: this.productsDefaultFacets,
                current_count,
            });
        },

        isNewRoute() {
            const toPath = this.$router.resolve(this.location).route.fullPath;
            return this.$route.fullPath !== toPath;
        },

        async updateQuery() {
            if (this.isNewRoute()) {
                try {
                    return await this.$router.push(this.location);
                }
                catch (error) {
                    console.error(error);
                }
            }
            else {
                return '';
            }
        },

        async replaceQuery() {
            if (this.isNewRoute()) {
                try {
                    return await this.$router.replace(this.location);
                }
                catch (error) {
                    console.error(error);
                }
            }
            else {
                return '';
            }
        },

        onChangeFavorite(index, value) {
            this.CHANGE_FAVORITE({ index, value });
        },

        onChangeCart(index) {
            this.CHANGE_CART({ index });
        },

        onDeleteFromCart(data) {
            this.DELETE_FROM_CART({ id: data.offerId });
        },

        onChangeSort(value) {
            this.SET_SORT_VALUE(value);
            this.reload();
        },
    },
};
</script>
