export default {
    namespaced: true,

    state: () => ({
        shops: {
            items: [],
            current_count: 0,
        },
        meta: {},
        initialized: false,
    }),

    mutations: {
        setShops(state, { results, current_count }) {
            state.shops.items = results;
            state.shops.current_count = current_count;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        setInitialized(state) {
            state.initialized = true;
        },
    },

    actions: {
        async init({ commit, dispatch }) {
            try {
                const [shops, meta] = await Promise.all(
                    [
                        dispatch('getShops'),
                        dispatch('getMeta'),
                    ],
                );

                commit('setShops', shops);
                commit('setMeta', meta);
                commit('setInitialized');
                commit('handleInitError', null, { root: true });

                return { shops, meta };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getShops({ rootState }) {
            const params = {
                limit: 100000,
                offset: 0,
                order_by: 'name',
                city_id__in: rootState.cities.currentCityId,
                has_active_shops: true,
            };

            return await this.$api.companies.get({ params });
        },

        async getMeta() {
            return await this.$api.staticPages.get('market-shops');
        },
    },
};