export default {
    namespaced: true,

    state: () => ({
        item: {},
        initialized: false,
    }),

    mutations: {
        set(state, response) {
            state.item = response;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.item = {};
            state.initialized = false;
        },
    },

    actions: {
        async init({ commit }, { codename }) {
            try {
                const manufacturer = await this.$api.manufacturers.get(codename);
                commit('set', manufacturer);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { manufacturer };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },
    },
};