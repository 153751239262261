<template>
    <div class="chat-template">
        <ChatTemplateHeader v-if="$scopedSlots.header" class="chat-template__header">
            <slot name="header"></slot>
        </ChatTemplateHeader>
        <div class="chat-template__body">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
import ChatTemplateHeader from '@/components/_chat/ChatTemplateHeader.vue';


export default {
    name: 'ChatTemplate',

    components: {
        ChatTemplateHeader,
    },
};
</script>

<style>
.chat-template {
    display: flex;
    flex-direction: column;
}

.chat-template__header {
    flex-shrink: 0;
    flex-grow: 0;
}

.chat-template__body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
</style>