import { months, monthsShort } from '../lib/dict.js';
import plural from '@/lib/plural.js';
import isValidDate from '@ui/utils/isValidDate.js';
import datetimeToString from '@/lib/datetime/datetimeToString.js';


export default {
    dt(isoDate) {
        const date = new Date(isoDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleString('ru-RU', options).slice(0, -3);
    },

    date(value) {
        if (value) {
            const re = /(\d{4})-(\d{2})-(\d{2})/g;
            const date = new Date(value);
            const isValid = isValidDate(date);
            return isValid ? value.replace(re, '$3.$2.$1') : value;
        }
        else {
            return '';
        }
    },

    datetime(isoDate, options) {
        if (isoDate) {
            const date = new Date(isoDate);
            return datetimeToString(date, options);
        }
        else {
            return '';
        }
    },

    dayMonth(isoDate) {
        if (isoDate) {
            const date = new Date(isoDate);
            const options = { month: 'long', day: 'numeric' };
            return date.toLocaleString('ru-RU', options);
        }
        else {
            return '';
        }
    },

    price(price) {
        if (price) {
            const fraction = price.toString().split('.')[1];
            if (fraction && fraction !== '00') {
                return parseFloat(price).toLocaleString(
                    'ru-RU',
                    {
                        style: 'currency',
                        currency: 'RUB',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
            }
            else {
                return parseFloat(price).toLocaleString(
                    'ru-RU',
                    {
                        style: 'currency',
                        currency: 'RUB',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    });
            }
        }
        else {
            return price;
        }
    },

    floorPrice(price) {
        if (price) {
            return parseFloat(price).toLocaleString(
                'ru-RU',
                {
                    style: 'currency',
                    currency: 'RUB',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
        }
        else {
            return price;
        }
    },

    number(number) {
        if (number) {
            return Number(number).toLocaleString('ru-RU',
                {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
        }
        else {
            return number;
        }
    },

    decimal(number) {
        if (number) {
            const decimal = Number(number % 1);
            return Number(number).toLocaleString('ru-RU',
                {
                    minimumFractionDigits: decimal ? 2 : 0,
                    maximumFractionDigits: decimal ? 2 : 0,
                });
        }
        else {
            return number;
        }
    },

    month(value) {
        const n = Number(value);
        if (!isNaN(n) && n >= 0 && n <= 12) {
            return months[n];
        }
        else {
            return value;
        }
    },

    shortMonth(value) {
        const n = Number(value);
        if (!isNaN(n) && n >= 0 && n <= 12) {
            return monthsShort[n];
        }
        else {
            return value;
        }
    },

    ordinalNumeral(number) {
        const ones = [
            '',
            'первый',
            'второй',
            'третий',
            'четвертый',
            'пятый',
            'шестой',
            'седьмой',
            'восьмой',
            'девятый',
            'десятый',
            'одиннадцатый',
            'двенадцатый',
            'тринадцатый',
            'четырнадцатый',
            'пятнадцатый',
            'шестнадцатый',
            'семнадцатый',
            'восемнадцатый',
            'девятнадцатый',
        ];

        const tens = [
            '',
            '',
            'двадцать',
            'тридцать',
            'сорок',
            'пятьдесят',
            'шестьдесят',
            'семьдесят',
            'восемьдесят',
            'девяносто',
        ];

        const numString = number.toString();

        if (number < 20) return ones[number];
        if (numString.length === 2) return tens[numString[0]] + ' ' + ones[numString[1]];
    },

    phone(value) {
        if (value) {
            const pattern = /(\+\d)(\d{3})(\d{3})(\d{2})(\d{2})/g;
            return value.replace(pattern, '$1 ($2) $3-$4-$5');
        }

        return value;
    },

    plural(count, titles) {
        return plural(count, titles);
    },

    carNumber(value) {
        if (value) {
            const r = /(?<letter1>[a-яА-Я])(?<number>\d{3})(?<letters2>[a-яА-Я]{2})(?<region>\d{2,3})/g;

            const result = r.exec(value);
            if (!result) return value;

            const { letter1, number, letters2, region } = result.groups;

            if (!/^[АВЕКМНОРСТУХавекмнорстух]{1}$/gm.test(letter1)) {
                return value;
            }

            if (!/^\d{3}$/gm.test(number)) {
                return value;
            }

            if (!/^[АВЕКМНОРСТУХавекмнорстух]{2}$/gm.test(letters2)) {
                return value;
            }

            if (!/^\d{2,3}$/gm.test(number)) {
                return value;
            }

            return `${ letter1 }${ number }${ letters2 } ${ region }`;
        }
        else {
            return '';
        }
    },

    pieces(value) {
        if (value != null && !isNaN(Number(value))) {
            return Number(value).toLocaleString('ru-RU',
                {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }) + ' шт.';
        }
        else {
            return value;
        }
    },
};