<template>
    <div>
        <div
            v-if="productsTotalCount || newPartsTotalCount"
            class="assortment-navigation_outer"
        >
            <ul class="assortment-navigation">
                <li
                    v-for="(item, index) in assortmentNavigation"
                    :key="'assortment-navigation-item-' + index"
                    class="assortment-navigation-item"
                >
                    <RouterLink
                        activeClass="active"
                        :to="{
                            name: item.name,
                            params: $route.params
                        }"
                        class="assortment-navigation-link"
                    >
                        <h2 class="h2">
                            <span>{{ item.title }}</span><span
                                v-if="item.amount"
                                class="h2-sub"
                            >{{ item.amount | number }}</span>
                        </h2>
                    </RouterLink>
                </li>
            </ul>
        </div>
        <h2 v-else class="h2 h2_block">
            Ассортимент магазина
        </h2>

        <RouterView v-if="productsTotalCount || newPartsTotalCount"></RouterView>
        <EmptyBlock
            v-else
            icon="empty-box"
            title="Нет предложений"
            text="Ассортимент этого магазина на Ubibi пока пуст."
            :btnUrl="{
                name: 'market-products-catalog',
            }"
        ></EmptyBlock>

        <LastSee></LastSee>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import marketShopProducts from '@/store/modules/marketShopProducts.js';
import marketShopNewParts from '@/store/modules/marketShopNewParts.js';
import EmptyBlock from '@/components/EmptyBlock.vue';
import LastSee from "@/components/LastSee.vue";


export default {
    name: 'MarketShopStockPage',

    metaInfo() {
        return {
            title: this.shop.meta_title,
            meta: [
                { name: 'description', content: this.shop.meta_description },
                { name: 'keywords', content: this.shop.meta_keywords },
                { property: 'og:title', content: this.shop },
                { property: 'og:description', content: this.shop.meta_description },
                { property: 'og:image', content: this.shop.og_image ? this.$links.uploads + this.shop.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        EmptyBlock,
        LastSee,
    },

    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.replaceQuery(to);
        });
    },

    computed: {
        ...mapState({
            shop: state => state.marketShop.shop,
            initialized: state => state.marketShop.initialized,

            productsTotalCount: state => state.marketShop.productsTotalCount,
            productsInStockCount: state => state.marketShop.productsInStockCount,
            productsCurrentCount: state => state.marketShopProducts.products.current_count,

            newPartsTotalCount: state => state.marketShop.newPartsTotalCount,
            newPartsInStockCount: state => state.marketShop.newPartsInStockCount,
            newPartsCurrentCount: state => state.marketShopNewParts.newParts.current_count,

            // contractPartsTotalCount: state => state.marketShop.contractPartsAmount,
            // contractPartsCurrentCount: state => state.marketShopContractParts.contractParts.current_count,
        }),

        ...mapGetters({
            availableRoutes: 'marketShop/availableRoutes',
        }),

        assortmentNavigation() {
            const routes = [];

            if (this.newPartsTotalCount) {
                routes.push({
                    name: 'market-shop-new-parts',
                    title: 'Запчасти',
                    amount: this.$route.name === 'market-shop-new-parts' ? this.newPartsCurrentCount || this.newPartsInStockCount : this.newPartsInStockCount,
                });
            }

            if (this.productsTotalCount) {
                routes.push({
                    name: 'market-shop-products',
                    title: 'Автотовары',
                    amount: this.$route.name === 'market-shop-products' ? this.productsCurrentCount || this.productsInStockCount : this.productsInStockCount,
                });
            }

            return routes;
        },
    },

    created() {
        this.$store.registerModule('marketShopProducts', marketShopProducts);
        this.$store.registerModule('marketShopNewParts', marketShopNewParts);
    },

    beforeDestroy() {
        this.$store.unregisterModule('marketShopProducts');
        this.$store.unregisterModule('marketShopNewParts');
    },

    methods: {
        replaceQuery(to) {
            let location = {};
            if (to.name === 'market-shop-stock') {
                if (this.availableRoutes.length) {
                    location = {
                        name: this.availableRoutes[0],
                        params: this.$route.params,
                        query: this.$route.query,
                    };
                }
                else {
                    location = to;
                }
            }
            else if (!this.availableRoutes.includes(to.name)) {
                if (this.availableRoutes.length) {
                    location = {
                        name: this.availableRoutes[0],
                        params: this.$route.params,
                        query: this.$route.query,
                    };
                }
                else {
                    location = {
                        name: 'market-shop-stock',
                        params: this.$route.params,
                        query: this.$route.query,
                    };
                }
            }
            else {
                location = to;
            }

            if (location.name) {
                const toPath = this.$router.resolve(location).route.fullPath;

                if (this.$route.fullPath !== toPath) {
                    this.$router.replace(location);
                }
            }
        },
    },
};
</script>

<style scoped>
.assortment-navigation_outer {
    margin-left: var(--content-gap-negative);
    margin-right: var(--content-gap-negative);
    overflow: hidden;
}

.assortment-navigation {
    display: flex;
    padding-bottom: 40px;
    overflow: auto;
}
@media (min-width: 768px) {
    .assortment-navigation {
        margin-bottom: -20px;
    }
}
@media (max-width: 767px) {
    .assortment-navigation {
        margin-bottom: -24px;
    }
}
.assortment-navigation::before,
.assortment-navigation::after {
    content: "";
    flex: 0 0 var(--content-gap);
}


@media (min-width: 1040px) {
  .assortment-navigation-item:not(:last-child) {
      margin-right: var(--gap-y-medium);
  }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .assortment-navigation-item:not(:last-child) {
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .assortment-navigation-item:not(:last-child) {
        margin-right: 20px;
    }
}

.assortment-navigation-link {
    white-space: nowrap;
    color: var(--font-secondary-color);
}

.assortment-navigation-link.active,
.assortment-navigation-link:hover,
.assortment-navigation-link:focus {
    color: var(--dark-c);
}
</style>