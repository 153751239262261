<template>
    <div class="market-item-row">
        <MarketItemCover
            :item="item"
            coverCodename="small"
            :modelName="modelName"
            class="cover-wrap"
        ></MarketItemCover>

        <div class="market-item-row__main-brand-wrap">
            <div class="market-item-row__main-info">
                <MarketItemName
                    :id="item.id"
                    :modelName="modelName"
                    :name="item.page_title || item.name"
                ></MarketItemName>

                <MarketItemSku
                    v-if="modelName !== 'product'"
                    :item="item"
                    class="mt-4 mb-0"
                ></MarketItemSku>

                <MarketOffersPriceInfo
                    :amount="item.offers_amount"
                    :max="item.max_price"
                    :min="item.min_price"
                    class="market-item-row__price-offers-wrap mt-4"
                ></MarketOffersPriceInfo>

                <BrandShortInfo
                    :brand="item.brand"
                    mobileRow
                    logoMobileHidden
                    class="market-item-row__brand market-item-row__brand_mobile"
                ></BrandShortInfo>

                <MarketItemRatingReviewsLink
                    :item="item"
                    :modelName="modelName"
                    class="market-item-row__rating-wrap"
                ></MarketItemRatingReviewsLink>
            </div>

            <div class="market-item-row__separator"></div>

            <BrandShortInfo
                :brand="item.brand"
                mobileRow
                logoMobileHidden
                class="market-item-row__brand market-item-row__brand_desktop"
            ></BrandShortInfo>
        </div>

        <LikeBtn
            :id="item.id"
            :apiName="modelName"
            :value="item.is_favorite"
            class="market-item-row__like-btn"
            @changeFavorite="onChangeFavorite"
        ></LikeBtn>
    </div>
</template>

<script>
import LikeBtn from '../../project-buttons/LikeBtn.vue';
import MarketOffersPriceInfo from '../../MarketOffersPriceInfo.vue';
import MarketItemRatingReviewsLink from '../../MarketItemRatingReviewsLink.vue';
import BrandShortInfo from '../../BrandShortInfo.vue';
import MarketItemName from '../../MarketItemName.vue';
import MarketItemCover from '../../MarketItemCover.vue';
import MarketItemSku from '../../MarketItemSku.vue';

export default {
    name: 'MarketItemSecondaryPageHeader',

    components: {
        MarketItemSku,
        MarketItemCover,
        MarketItemName,
        BrandShortInfo,
        MarketItemRatingReviewsLink,
        MarketOffersPriceInfo,
        LikeBtn,
    },

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },
    },

    methods: {
        onChangeFavorite(value) {
            this.$emit('changeFavorite', value);
        },
    },
};
</script>

<style scoped>
.market-item-row {
    position: relative;
    display: flex;
}

@media (min-width: 768px) {
    .cover-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px)  {
    .cover-wrap {
        margin-right: 12px;
    }
}


@media (max-width: 767px) {
    .market-item-row__price-offers-wrap {
        display: none;
    }
}

@media (min-width: 768px) {
    .market-item-row__rating-wrap {
        margin-top: 16px;
    }
}
@media (max-width: 767px) {
    .market-item-row__rating-wrap {
        margin-top: 12px;
    }
}

.market-item-row__main-info,
.market-item-row__main-brand-wrap {
    flex-grow: 1;
}
@media (min-width: 1040px) {
    .market-item-row__main-brand-wrap {
        display: flex;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .market-item-row__brand {
        margin-top: 16px;
    }
}
@media (max-width: 767px) {
    .market-item-row__brand {
        margin-top: 8px;
    }
}

@media (min-width: 1040px) {
    .market-item-row__brand_mobile {
        display: none;
    }
}
@media (max-width: 1039px) {
    .market-item-row__brand_desktop {
        display: none;
    }
}

.market-item-row__like-btn {
    align-self: flex-start;
}
@media (min-width: 1040px) {
    .market-item-row__like-btn {
        margin-left: 20px;
    }
}
@media (max-width: 1039px) {
    .market-item-row__like-btn {
        margin-left: 12px;
    }
}

.market-item-row__separator {
    margin-left: 20px;
    margin-right: 20px;
    width: 1px;
    background-color: var(--light-bg);
}
@media (max-width: 767px) {
    .market-item-row__separator {
        display: none;
    }
}
</style>
