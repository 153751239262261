export default {
    namespaced: true,

    state: () => ({
        profile: {},
        isAuthorized: false,
    }),

    mutations: {
        setProfile(state, profile) {
            state.profile = profile;
        },

        setIsAuthorized(state, isAuthorized) {
            state.isAuthorized = isAuthorized;
        },
    },

    actions: {
        async get({ commit }) {
            try {
                const profile = await this.$api.users.profile();

                commit('setProfile', profile);
                commit('setIsAuthorized', true);

                return { profile };
            }
            catch (error) {
                commit('setIsAuthorized', false);
                commit('setProfile', {});
                return { isAuthorized: false, profile: {} };
            }
        },

        async login({ commit, dispatch }, { data }) {
            try {
                const profile = await this.$api.auth.login(data);
                commit('setProfile', profile);
                commit('setIsAuthorized', true);

                dispatch('reloadPageService/reloadInactiveTabs', null, { root: true });

                return { isAuthorized: true, profile };
            }
            catch (error) {
                commit('setProfile', {});
                commit('setIsAuthorized', false);
                throw error;
            }
        },

        async sendLoginCode({}, data) {
            return await this.$api.auth.sendLoginCode(data);
        },

        async confirmLoginCode({ commit, dispatch }, data) {
            try {
                // const { is_created, ...profile } = await this.$api.auth.confirmLoginCode(data);
                await this.$api.auth.confirmLoginCode(data);

                const { profile } = await dispatch('get');

                commit('setProfile', profile);
                commit('setIsAuthorized', true);

                dispatch('reloadPageService/reloadInactiveTabs', null, { root: true });

                return { isAuthorized: true, profile };
            }
            catch (error) {
                commit('setProfile', {});
                commit('setIsAuthorized', false);
                throw error;
            }
        },

        async logout({ commit }) {
            try {
                await this.$api.auth.logout();
                commit('setIsAuthorized', false);
                commit('setProfile', {});
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};