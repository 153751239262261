<template>
    <PopupWrap
        :loading="mapLoading"
        closeBtn="outer"
        noPadding
        full
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <yandexMap
                :coords="pointA.coords"
                :useObjectManager="true"
                :controls="['zoomControl']"
                :behaviors="[
                    'drag',
                    'dblClickZoom',
                    'multiTouch',
                    'rightMouseButtonMagnifier',
                ]"
                zoom="16"
                :scrollZoom="false"
                :options="{ suppressMapOpenBlock: true }"
                :style="{
                    width: '100%',
                    height: '100%',
                }"
                :class="['map', {
                    map_loading: mapLoading,
                }]"
                @map-was-initialized="mapInitHandler"
            >
                <ymapMarker
                    v-if="pointA.coords.length"
                    key="pointA"
                    :coords="pointA.coords"
                    :icon="getYMapMarkerIcon(pointA)"
                    markerId="pointA"
                >
                    <template slot="balloon">
                        <div class="balloon balloon_flex">
                            <ShopLogo
                                :shop="order.shop"
                                coverCodename="medium"
                                class="balloon__shop-logo"
                            ></ShopLogo>
                            <div>
                                <ShopName
                                    :shop="order.shop"
                                    class="mb-4"
                                ></ShopName>
                                <ShopAddress
                                    :shop="order.shop"
                                    :showOnMap="false"
                                ></ShopAddress>
                            </div>
                        </div>
                    </template>
                </ymapMarker>

                <ymapMarker
                    v-if="pointB.coords.length"
                    key="pointB"
                    v-bind="pointB"
                    :coords="pointB.coords"
                    :icon="getYMapMarkerIcon(pointB)"
                >
                    <template slot="balloon">
                        <div class="balloon">
                            <p class="mb-4">
                                <b>Адрес доставки:</b>
                            </p>
                            <p>{{ deliveryData.address_to }}</p>
                        </div>
                    </template>
                </ymapMarker>

                <ymapMarker
                    v-if="carMarker.coords.length"
                    key="carMarker"
                    :coords="carMarker.coords"
                    :icon="getYMapMarkerIcon(carMarker)"
                    markerId="carMarker"
                ></ymapMarker>
            </yandexMap>

            <div class="info-block layer-1">
                <div class="set-map-center-wrap">
                    <button
                        class="set-map-center-btn"
                        @click="setMapCenter"
                    >
                        <UIcon
                            name="double-pin"
                            big
                        ></UIcon>
                    </button>
                </div>

                <div class="flex align-items-center">
                    <Spinner
                        v-if="renderYandexDeiveryInfo.icon && renderYandexDeiveryInfo.icon === 'spinner'"
                        class="mr-20"
                    ></Spinner>
                    <UIcon
                        v-else-if="renderYandexDeiveryInfo.icon"
                        :name="renderYandexDeiveryInfo.icon"
                        big
                        class="mr-20"
                    ></UIcon>

                    <h3 class="info-block__title">
                        {{ renderYandexDeiveryInfo.title }}
                    </h3>
                </div>

                <p
                    v-if="renderYandexDeiveryInfo.subtitle"
                    class="mt-4 text_small-fz text_small-lh text_secondary"
                >
                    {{ renderYandexDeiveryInfo.subtitle }}
                </p>

                <div v-if="!isDeliveryFinish">
                    <p
                        v-if="canGetDriverCoords && !carMarker.coords.length"
                        class="mt-4 text_small-fz text_small-lh text_secondary"
                    >
                        Координаты водителя сейчас недоступны
                    </p>

                    <div
                        v-if="deliveryData.performer_info"
                        class="mt-20"
                    >
                        <p class="flex align-items-center">
                            <span class="car__name">
                                <span class="capitalize">{{ deliveryData.performer_info.car_color }}</span>
                                {{ deliveryData.performer_info.car_model }}
                            </span>
                            <span class="car__number">
                                {{ deliveryData.performer_info.car_number | carNumber }}
                            </span>
                        </p>
                        <p class="mt-4">
                            {{ deliveryData.performer_info.courier_name }}
                            <br>
                            <a
                                v-if="driverPhoneData.phone"
                                :href="`tel:${ driverPhoneData.phone },,${ driverPhoneData.ext }`"
                                class="underline"
                            >{{ driverPhoneData.phone | phone }} (доб.: {{ driverPhoneData.ext }})</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import popup from '@/mixins/popup.js';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import Spinner from '@/components/Spinner.vue';
import ShopLogo from '@/components/ShopLogo.vue';
import ShopName from '@/components/ShopName.vue';
import ShopAddress from '@/components/ShopAddress.vue';
import { HTTP } from '@/http.js';
import { YANDEX_CLAIM_POLLING_TIMEOUT } from '@/settings.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'TrackYandexDeliveryPopup',

    components: {
        UIcon,
        ShopAddress,
        ShopName,
        ShopLogo,
        Spinner,
        PopupWrap,
        yandexMap,
        ymapMarker,
    },

    mixins: [ymapMarkerMixin, popup],

    props: {
        order: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            mapLoading: true,
            mapInstance: null,

            pointA: {
                coords: [],
                // coords: [48.52619, 135.06959],
                size: 'big',
                type: 'pointA',
            },
            pointB: {
                coords: [],
                // coords: [48.54619, 135.07959],
                size: 'big',
                type: 'pointB',
            },

            deliveryData: {},
            deliveryDataCancelToken: null,
            deliveryDataTimeout: null,

            carMarker: {
                coords: [],
                type: 'car',
            },
            getCarCoordsCancelToken: null,
            getCarCoordsTimeout: null,

            driverPhoneData: {},
            getDriverPhoneCancelToken: null,
            getDriverPhoneTimeout: null,
        };
    },

    computed: {
        claimId() {
            return ((this.order || {}).delivery_yandex_claim || {}).id || '';
        },

        renderYandexDeiveryInfo() {
            const state = {
                'new': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'estimating': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'estimating_failed': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'ready_for_approval': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'accepted': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'performer_lookup': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'performer_draft': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'performer_found': {
                    title: 'Курьер на пути в магазин',
                    subtitle: '',
                    icon: '',
                },
                'performer_not_found': {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                },
                'pickup_arrived': {
                    title: 'Курьер находится в точке А',
                    subtitle: '',
                    icon: '',
                },
                'ready_for_pickup_confirmation': {
                    title: 'Курьер находится в точке А',
                    subtitle: '',
                    icon: '',
                },
                'pickuped': {
                    title: 'Курьер на пути в точку В',
                    subtitle: '',
                    icon: '',
                },
                'delivery_arrived': {
                    title: 'Курьер находится в точке B',
                    subtitle: '',
                    icon: '',
                },
                'ready_for_delivery_confirmation': {
                    title: 'Курьер находится в точке B',
                    subtitle: '',
                    icon: '',
                },
                'delivered': {
                    title: 'Заказ доставлен',
                    subtitle: '',
                    icon: 'done-circle',
                },
                'delivered_finish': {
                    title: 'Заказ доставлен',
                    subtitle: '',
                    icon: 'done-circle',
                },
                'returning': {
                    title: 'Курьер на пути в точку А (возврат товара)',
                    subtitle: '',
                    icon: '',
                },
                'return_arrived': {
                    title: 'Курьер находится в точке А (возврат товара)',
                    subtitle: '',
                    icon: '',
                },
                'ready_for_return_confirmation': {
                    title: 'Курьер находится в точке А (возврат товара)',
                    subtitle: '',
                    icon: '',
                },
                'returned': {
                    title: 'Заказ возвращён в магазин',
                    subtitle: '',
                    icon: 'cancel',
                },
                'returned_finish': {
                    title: 'Заказ возвращён в магазин',
                    subtitle: '',
                    icon: 'cancel',
                },
                'cancelled': {
                    title: 'Доставка была отменена',
                    subtitle: 'Деньги за оплату доставки будут возвращены на вашу банковскую карту.',
                    icon: 'cancel',
                },
                'cancelled_with_payment': {
                    title: 'Доставка была отменена',
                    subtitle: '',
                    icon: 'cancel',
                },
                'cancelled_by_taxi': {
                    title: '',
                    subtitle: '',
                    icon: '',
                },
                'cancelled_with_items_on_hands': {
                    title: 'Доставка была отменена',
                    subtitle: '',
                    icon: 'cancel',
                },
            };

            if (this.deliveryData.status === 'in_work') {
                state.cancelled_by_taxi = {
                    title: 'Идёт поиск машины ...',
                    subtitle: 'Это может занять от одной до 10 минут.',
                    icon: 'spinner',
                };
            }
            else if (this.deliveryData.status === 'cancelled') {
                state.cancelled_by_taxi = {
                    title: 'Доставка была отменена',
                    subtitle: 'Деньги за оплату доставки будут возвращены на вашу банковскую карту.',
                    icon: 'cancel',
                };
            }

            if (this.deliveryData.status === 'cancelled_without_refund') {
                state.cancelled.subtitle = 'Заказ уже был передан курьеру для доставки по указанному адресу, деньги за доставку не будут возвращены на вашу банковскую карту.';
            }
            else if (this.deliveryData.status === 'cancelled_with_part_refund') {
                state.cancelled.subtitle = 'С вас будет удержана комиссия в размере минимальной стоимости тарифа, остаток средств будет возвращён на вашу банковскую карту.';
            }
            else if (this.deliveryData.status === 'cancelled_with_full_refund') {
                state.cancelled.subtitle = 'Деньги за оплату доставки будут возвращены на вашу банковскую карту.';
            }

            return state[this.deliveryData.claim_status] || {};
        },

        isDeliveryFinish() {
            return this.deliveryData.status === 'delivered'
                || this.isReturned
                || this.isDeliveryCancelled;
        },

        isDeliveryCancelled() {
            const statuses = [
                'cancelled',
                'cancelled_without_refund',
                'cancelled_with_part_refund',
                'cancelled_with_full_refund',
            ];
            return statuses.includes(this.deliveryData.status);
        },

        isReturned() {
            return this.deliveryData.status === 'returned'
                || this.deliveryData.claim_status === 'returned'
                || this.deliveryData.claim_status === 'returned_finish';
        },

        isStatusSearching() {
            const statuses = [
                'new',
                'estimating',
                'estimating_failed',
                'ready_for_approval',
                'accepted',
                'performer_lookup',
                'performer_draft',
                'performer_not_found',
                'cancelled_by_taxi',
            ];
            return statuses.includes(this.deliveryData.claim_status);
        },

        canGetDriverPhone() {
            const statuses = [
                'performer_found',
                'pickup_arrived',
                'ready_for_pickup_confirmation',
                'pickuped',
                'delivery_arrived',
                'ready_for_delivery_confirmation',
                'returning',
                'return_arrived',
                'ready_for_return_confirmation',
            ];
            return statuses.includes(this.deliveryData.claim_status);
        },

        markers() {
            let markers = [];
            if (this.pointA.coords.length) markers.push(this.pointA);
            if (this.pointB.coords.length) markers.push(this.pointB);
            if (this.carMarker.coords.length) markers.push(this.carMarker);
            return markers;
        },

        markersCoordsList() {
            return this.markers.reduce((acc, item) => {
                if (item.coords.length) {
                    acc.push(item.coords);
                    return acc;
                }
            }, []);
        },

        canGetDriverCoords() {
            const statuses = [
                'performer_found',
                'pickup_arrived',
                'ready_for_pickup_confirmation',
                'pickuped',
                'delivery_arrived',
                'ready_for_delivery_confirmation',
                'returning',
                'return_arrived',
                'ready_for_return_confirmation',
            ];
            return statuses.includes(this.deliveryData.claim_status);
        },
    },

    watch: {
        'markersCoordsList': {
            handler(newValue, oldValue) {
                if (newValue.length !== oldValue.length) this.setMapCenter();
            },
        },
    },

    created() {
        this.pointA.coords = this.order.shop.coordinates.split(', ').map(Number);
    },

    mounted() {
        this.getDeliveryData();
    },

    beforeDestroy() {
        clearTimeout(this.deliveryDataTimeout);
        clearTimeout(this.getCarCoordsTimeout);
        clearTimeout(this.getDriverPhoneTimeout);
        if (this.deliveryDataCancelToken) this.deliveryDataCancelToken.cancel();
        if (this.getCarCoordsCancelToken) this.getCarCoordsCancelToken.cancel();
        if (this.getDriverPhoneCancelToken) this.getDriverPhoneCancelToken.cancel();
    },

    methods: {
        mapInitHandler(mapInstance) {
            this.mapInstance = mapInstance;
            this.setMapCenter();
        },

        setMapCenter() {
            if (this.markersCoordsList.length > 1 && this.mapInstance) {
                this.mapInstance.setBounds(ymaps.util.bounds.fromPoints(this.markersCoordsList), {
                    checkZoomRange: true,
                    zoomMargin: 56,
                    duration: 300,
                }).then(() => {
                    const currentZoom = this.mapInstance.getZoom();
                    this.mapInstance.setZoom(currentZoom - 1);
                    this.mapLoading = false;
                });
            }
        },

        async getDeliveryData() {
            try {
                this.deliveryDataCancelToken = HTTP.CancelToken.source();
                this.deliveryData = await this.$api.delivery.yandex.getClaim({
                    id: this.claimId,
                    cancelToken: this.deliveryDataCancelToken.token,
                });

                if (!this.pointB.coords.length) this.pointB.coords = this.deliveryData.coordinates_to.reverse();

                if (this.isDeliveryFinish) {
                    clearTimeout(this.deliveryDataTimeout);
                    this.deliveryDataTimeout = null;
                    clearTimeout(this.getCarCoordsTimeout);
                    this.getDriverCoordsTimeout = null;
                    clearTimeout(this.getDriverPhoneTimeout);
                    this.getDriverPhoneTimeout = null;
                    if (this.getCarCoordsCancelToken) this.getCarCoordsCancelToken.cancel();
                    if (this.getDriverPhoneCancelToken) this.getDriverPhoneCancelToken.cancel();

                    if (this.isDeliveryCancelled) {
                        this.deliveryData.claim_status = 'cancelled';
                        this.order.status = 'wait_reg_delivery';
                    }
                }
                else {
                    this.deliveryDataTimeout = setTimeout(() => {
                        this.getDeliveryData();
                    }, YANDEX_CLAIM_POLLING_TIMEOUT);


                    if (!this.canGetDriverCoords) {
                        clearTimeout(this.getDriverCoordsTimeout);
                        this.getDriverCoordsTimeout = null;
                    }

                    if (this.canGetDriverCoords && !this.getCarCoordsTimeout) this.getCarCoords();
                    if (this.canGetDriverPhone && !this.getDriverPhoneTimeout) this.getDriverPhone();
                }
            }
            catch (error) {
                if (!this.isDeliveryFinish) {
                    this.deliveryDataTimeout = setTimeout(() => {
                        this.getDeliveryData();
                    }, YANDEX_CLAIM_POLLING_TIMEOUT);
                }
            }
        },

        async getDriverPhone() {
            try {
                this.getDriverPhoneCancelToken = HTTP.CancelToken.source();
                this.driverPhoneData = await this.$api.delivery.yandex.getDriverPhone({
                    id: this.claimId,
                    cancelToken: this.getDriverPhoneCancelToken.token,
                });

                if (this.isDeliveryFinish) {
                    clearTimeout(this.getDriverPhoneTimeout);
                    this.getDriverPhoneTimeout = null;
                    if (this.getDriverPhoneCancelToken) this.getDriverPhoneCancelToken.cancel();
                }
                else {
                    if (this.canGetDriverPhone) {
                        this.getDriverPhoneTimeout = setTimeout(() => {
                            this.getDriverPhone();
                        }, (this.driverPhoneData.ttl_seconds || 5) * 1000);
                    }
                }
            }
            catch (e) {
                this.driverPhoneData = {};
                if (this.canGetDriverPhone) {
                    this.getDriverPhoneTimeout = setTimeout(() => {
                        this.getDriverPhone();
                    }, YANDEX_CLAIM_POLLING_TIMEOUT);
                }
            }

        },

        async getCarCoords() {
            try {
                this.getCarCoordsCancelToken = HTTP.CancelToken.source();
                const response = await this.$api.delivery.yandex.driverPosition(this.claimId, {
                    cancelToken: this.deliveryDataCancelToken.token,
                });

                if (response) {
                    let carCoords = response.coordinates.reverse();
                    if (carCoords[0] == this.pointA.coords[0] && carCoords[1] == this.pointA.coords[1]) {
                        carCoords = [Number(carCoords[0]) + 0.0001, Number(carCoords[1]) + 0.0001];
                    }
                    if (carCoords[0] == this.pointB.coords[0] && carCoords[1] == this.pointB.coords[1]) {
                        carCoords = [Number(carCoords[0]) + 0.0001, Number(carCoords[1]) + 0.0001];
                    }
                    this.carMarker.coords = carCoords;

                    if (this.isDeliveryFinish) {
                        clearTimeout(this.getCarCoordsTimeout);
                        this.getDriverCoordsTimeout = null;
                        if (this.getCarCoordsCancelToken) this.getCarCoordsCancelToken.cancel();
                    }
                    else {
                        if (this.canGetDriverCoords) {
                            this.getCarCoordsTimeout = setTimeout(() => {
                                this.getCarCoords();
                            }, YANDEX_CLAIM_POLLING_TIMEOUT);
                        }
                        else {
                            clearTimeout(this.getDriverCoordsTimeout);
                            this.getDriverCoordsTimeout = null;
                        }
                    }
                }
            }
            catch (error) {
                this.carMarker.coords = [];

                if (this.canGetDriverCoords) {
                    this.getCarCoordsTimeout = setTimeout(() => {
                        this.getCarCoords();
                    }, YANDEX_CLAIM_POLLING_TIMEOUT);
                }
                else {
                    clearTimeout(this.getDriverCoordsTimeout);
                    this.getDriverCoordsTimeout = null;
                }
            }
        },
    },
};
</script>

<style scoped>
.popup-content {
    background-color: var(--lightest-bg);
}

.map {
    width: 100%;
    height: 100%;
}

.info-block {
    position: absolute;
    z-index: 2;
}
@media (min-width: 768px) {
    .info-block {
        left: 50%;
        transform: translateX(-50%);
        bottom: 24px;
        width: 460px;
        padding: 20px 24px;
        border-radius: var(--border-radius-x2);
    }
}
@media (max-width: 767px) {
    .info-block {
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 20px var(--content-gap);
        background-color: var(--light-c);
        box-shadow: var(--base-shadow);
        border-radius: var(--border-radius-x3) var(--border-radius-x3) 0 0;
    }
}

.info-block__title {
    font-size: 20px;
    font-family: var(--f-bold);
    padding-right: 64px;
}

.car__name {
    flex-grow: 1;
    margin-right: 20px;
    font-family: var(--f-bold);
}

.car__number {
    padding: 4px 8px;
    flex-shrink: 0;
    font-family: var(--f-semi-bold);
    text-transform: uppercase;
    background-color: var(--lightest-bg);
    border-radius: var(--border-radius);
}

.balloon {
    min-width: 240px;
    width: 100%;
    padding: 24px;
    font-size: var(--base-fz);
    line-height: var(--base-lh);
    font-family: var(--f-base);
}

.balloon_flex {
    display: flex;
    align-items: flex-start;
}

.balloon__shop-logo {
    width: 60px;
    height: 40px;
    margin-right: 24px;
    flex-shrink: 0;
}

.set-map-center-wrap {
    font-size: 0;
    position: absolute;
    top: 22px;
    right: 24px;
    padding-left: 20px;
    border-left: 1px solid var(--border-light-c);
}

.set-map-center-btn {
    color: var(--dark-c);
    transition: color var(--transition);
}

.set-map-center-btn:hover,
.set-map-center-btn:focus {
    color: var(--primary-color);
}
</style>
