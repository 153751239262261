<template>
    <component
        :is="tag"
        v-bind="attrs"
        v-lazy-container="{ selector: 'img' }"
        :class="['cover-wrap', 'cover-wrap_' + coverCodename]"
        @click="$emit('click')"
    >
        <img
            :data-src="coverSrc"
            src=""
            alt=""
            class="cover"
            :width="coversSizesMap[coverCodename]"
            :height="coversSizesMap[coverCodename]"
        >
    </component>
</template>

<script>
const coversSizesMap = {
    detail: 512,
    detail_mini: 288,
    grid: 244,
    list: 200,
    medium: 120,
    small: 96,
    mini: 64,
    micro: 48,
};
Object.freeze(coversSizesMap);

export default {
    name: 'MarketItemCover',

    props: {
        item: {
            type: Object,
            default: () => {},
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },

        coverCodename: {
            type: String,
            default: 'list',
            validator(value) {
                return ['detail', 'detail_mini', 'grid', 'list', 'medium', 'small', 'mini', 'micro'].includes(value);
            },
        },

        noLink: Boolean,
        isButton:  Boolean,
    },

    data() {
        return {
            coversSizesMap,
        };
    },

    computed: {
        tag() {
            if (this.isButton) return 'button';
            else if (this.noLink) return 'div';
            return 'RouterLink';
        },

        attrs() {
            if (this.tag === 'RouterLink') {
                return {
                    to: {
                        name: this.modelName === 'product'
                            ? 'market-product-description'
                            : this.modelName === 'part_product'
                                ? 'market-new-part-description'
                                : 'market-contract-part-description',
                        params: {
                            id: this.item.id || this.item.product_id || this.item.part_product_id,
                        },
                    },
                };
            }
            else {
                return {};
            }
        },

        coverSrc() {
            const imageLink = ((this.item.cover || {}).thumbnails || {})['market_item_cover_' + this.coverCodename];
            return imageLink
                ? this.$links.uploads + imageLink
                : require('../assets/images/product-placeholder.svg');
        },
    },
};
</script>

<style scoped>
.cover-wrap {
    display: block;
    flex-shrink: 0;
    background-color: var(--light-bg);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.cover {
    width: 100%;
    height: auto;
}

@media (min-width: 1281px) {
    .cover-wrap_list {
        width: 200px;
        height: 200px;
    }
}
@media (max-width: 1280px) and (min-width: 768px) {
    .cover-wrap_list {
        width: 160px;
        height: 160px;
    }
}
@media (max-width: 767px) and (min-width: 381px) {
    .cover-wrap_list {
        width: 100px;
        height: 100px;
    }
}
@media (max-width: 380px) {
    .cover-wrap_list {
        width: 80px;
        height: 80px;
    }
}

@media (min-width: 768px) {
    .cover-wrap_small {
        width: 96px;
        height: 96px;
    }
}
@media (max-width: 767px)  {
    .cover-wrap_small {
        width: 48px;
        height: 48px;
    }
}

.cover-wrap_mini {
    width: 64px;
    height: 64px;
}

.cover-wrap_micro {
    width: 48px;
    height: 48px;
}
</style>