<template>
    <AccountLayout
        class="account-insurance-page"
        title="Страховые полисы"
        mobileTitle="Страховые полисы"
    >
        <div>
            <div class="tabs-wrap layer-1">
                <UTabs
                    :items="[
                        {
                            label: 'Полисы ОСАГО',
                            name: 'policies',
                            to: {
                                name: 'account-insurance-policies'
                            },
                            count: policiesCount,
                        },
                        {
                            label: 'Сохранённые расчёты',
                            name: 'drafts',
                            to: {
                                name: 'account-insurance-drafts'
                            },
                            count: contractsCount,
                        }
                    ]"
                ></UTabs>
            </div>

            <RouterView></RouterView>
        </div>
    </AccountLayout>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import accountInsurance from '@/store/modules/accountInsurance.js';
import AccountLayout from '@/pages/account/AccountLayout.vue';
import UTabs from '@ui/components/UTabs/UTabs.vue';


export default {
    name: 'AccountInsurancePage',

    components: {
        UTabs,
        AccountLayout,
    },

    serverPrefetch() {
        return this.prefetch();
    },

    computed: {
        ...mapState({
            initialized: state => state.accountInsurance.initialized,
            policiesCount: state => state.accountInsurance.policiesCount,
            contractsCount: state => state.accountInsurance.contractsCount,
        }),
    },

    created() {
        this.$store.registerModule('accountInsurance', accountInsurance);
    },

    mounted() {
        this.$breadcrumbs([]);

        if (!this.initialized) {
            this.prefetch();
        }
    },

    beforeDestroy() {
        this.destroy();
        this.$store.unregisterModule('accountInsurance');
    },

    methods: {
        ...mapMutations({
            destroy: 'accountInsurance/destroy',
        }),

        ...mapActions({
            prefetch: 'accountInsurance/prefetch',
        }),
    },
};
</script>

<style scoped>
.tabs-wrap {
    margin-bottom: var(--grid-gap);
    width: 100%;
    padding: 14px 36px 0;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .tabs-wrap {
        padding: 14px 24px 0;
    }
}
@media (max-width: 767px) {
    .tabs-wrap {
        padding: 14px 16px 0;
    }
}

.tabs-wrap .u-tabs__list::after {
    height: 0;
}
</style>