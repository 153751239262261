<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2">
                Что такое VIN/Frame?
            </h2>

            <div class="vin-section">
                <p class="popup__text">
                    <b>VIN</b> — это уникальный идентификационный номер автомобиля, который содержит информацию о производителе и особенностях конкретного автомобиля. Нужен для опознания автомобиля и его биографии: когда выпущен, какие запчасти подходят, сколько было владельцев, в каких ДТП участвовал. Как бы не трансформировался и не перекрашивался автомобиль, по закону VIN-номер остаётся прежним. VIN-номер обязательно дублируется в ПТС, свидетельстве о регистрации и страховом полисе.
                </p>
                <p class="popup__text">
                    Единого стандарта по размещению номера нет, но чаще его пишут на следующих местах:
                </p>

                <ul class="pictures-grid">
                    <li class="pictures-grid__item">
                        <figure class="pictures-grid__figure">
                            <img
                                src="../../assets/images/about-vin-frame-1.jpg"
                                alt=""
                                class="pictures-grid__img"
                            >
                            <figcaption class="pictures-grid__text">
                                Табличка на стыке приборной панели и лобового стекла
                            </figcaption>
                        </figure>
                    </li>
                    <li class="pictures-grid__item">
                        <figure class="pictures-grid__figure">
                            <img
                                src="../../assets/images/about-vin-frame-2.jpg"
                                alt=""
                                class="pictures-grid__img"
                            >
                            <figcaption class="pictures-grid__text">
                                Дверь или стойка со стороны водителя
                            </figcaption>
                        </figure>
                    </li>
                    <li class="pictures-grid__item">
                        <figure class="pictures-grid__figure">
                            <img
                                src="../../assets/images/about-vin-frame-3.jpg"
                                alt=""
                                class="pictures-grid__img"
                            >
                            <figcaption class="pictures-grid__text">
                                Арки колес, за брызговиками и&nbsp;защитой
                            </figcaption>
                        </figure>
                    </li>
                    <li class="pictures-grid__item">
                        <figure class="pictures-grid__figure">
                            <img
                                src="../../assets/images/about-vin-frame-4.jpg"
                                alt=""
                                class="pictures-grid__img"
                            >
                            <figcaption class="pictures-grid__text">
                                Перегородка моторного щита
                            </figcaption>
                        </figure>
                    </li>
                </ul>
            </div>

            <div class="frame-section">
                <p class="popup__text">
                    <b>Frame</b> — это тот же идентификационный номер, но на автомобилях для японского внутреннего рынка.    В автомобиле находится на подкапотной табличке. Как и VIN, дублируется в ПТС, свидетельстве о регистрации и страховом полисе.
                </p>
                <p class="popup__text">
                    Пример таблички, расположенной под капотом автомобилей марки TOYOTA, на других авто таблички могут немного отличаться, но суть не меняется:
                </p>

                <figure class="frame-section__pictures">
                    <div class="pictures-grid">
                        <div class="pictures-grid__item">
                            <img
                                src="../../assets/images/about-vin-frame-5.jpg"
                                alt=""
                                class="pictures-grid__img"
                            >
                        </div>
                        <div class="pictures-grid__item">
                            <img
                                src="../../assets/images/about-vin-frame-6.jpg"
                                alt=""
                                class="pictures-grid__img"
                            >
                        </div>
                    </div>

                    <figcaption class="pictures-grid__legend">
                        <p>
                            <span>1. MODEL — модель автомобиля;</span>
                            <span>2. ENGINE — модель двигателя;</span>
                            <span>3. CC — объём двигателя;</span>
                        </p>
                        <p>
                            <span>4. FRAME No — фрейм номер (номер кузова);</span>
                            <span>5. COLOR — цвет кузова;</span>
                            <span>6. Код отделки салона;</span>
                        </p>
                        <p>
                            <span>7. TRANS — модель КПП;</span>
                            <span>8. Тип дифференциала;</span>
                            <span>9. PLANT — код завода изготовителя.</span>
                        </p>
                    </figcaption>
                </figure>

                <p class="popup__text">
                    VIN/Frame номера используют для быстрого поиска запчастей.
                </p>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';

export default {
    name: "AboutVinFramePopup",

    components: { PopupWrap },

    mixins: [popup],
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.popup__text {
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .popup__text + .popup__text {
        margin-top: 12px;
    }
}
@media (max-width: 767px) {
    .popup__text + .popup__text {
        margin-top: 8px;
    }
}

.vin-section {
    margin-bottom: var(--gap-y-small);
}

.frame-section__pictures {
    margin-bottom: 20px;
}

.pictures-grid {
    margin-top: 24px;
    counter-reset: stepper;
}
@media (min-width: 414px) {
    .pictures-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--grid-gap);
    }
}
@media (max-width: 413px) {
    .pictures-grid__item:not(:last-child) {
        margin-bottom: 18px;
    }
}

.pictures-grid__item {
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .pictures-grid__item {
        padding: 20px;
    }
}
@media (max-width: 767px) {
    .pictures-grid__item {
        padding: 16px;
    }
}

.pictures-grid__figure {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
}

.pictures-grid__text {
    display: flex;
    align-items: baseline;
    font-family: var(--f-semi-bold);
    color: var(--primary-color);
}

@media (min-width: 414px) and (max-width: 767px) {
    .pictures-grid__text {
        flex-wrap: wrap;
    }

    .pictures-grid__text::before {
        margin-bottom: 8px;
    }
}

.pictures-grid__text::before {
    counter-increment: stepper;
    content: counter(stepper);
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    line-height: 28px;
    margin-right: 16px;
    font-family: var(--f-semi-bold);
    color: var(--light-c);
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 50%;
}

.pictures-grid__legend {
    margin-top: 16px;
    font-size: 12px;
    line-height: var(--mini-lh);
}

.pictures-grid__legend p:not(:last-child) {
    margin-bottom: 8px;
}

@media (max-width: 767px) {
    .pictures-grid__legend span {
        display: block;
    }

    .pictures-grid__legend span:not(:last-child) {
        margin-bottom: 8px;
    }
}
</style>