<template>
    <div class="chat-search-input__wrap">
        <slot name="prepend">
            <UIcon
                name="search"
                small
                class="chat-search-input__icon chat-search-input__search-icon"
            ></UIcon>
        </slot>

        <input
            ref="input"
            :value="value"
            class="input chat-search-input__input"
            @input="onInput"
        >

        <span
            v-if="placeholder && !value"
            class="chat-search-input__placeholder"
        >{{ placeholder }}</span>

        <slot name="append">
            <USpinner
                v-if="loading"
                small
                class="chat-search-input__spinner"
            ></USpinner>

            <button
                v-else-if="value"
                type="button"
                class="chat-search-input__clear-button"
                tabindex="-1"
                aria-label="Очистить поле"
                @click="onClickClearButton"
            >
                <UIcon
                    name="close-round-hollow-16"
                    small
                    class="chat-search-input__icon chat-search-input__clear-icon"
                ></UIcon>
            </button>
        </slot>
    </div>
</template>

<script>
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'ChatSearchInput',

    components: {
        UIcon,
        USpinner,
    },

    model: {
        prop: 'value',
        event: 'inputModel',
    },

    props: {
        value: String,

        loading: Boolean,

        placeholder: {
            type: String,
            default: 'Поиск',
        },
    },

    data() {
        return {
            innerValue: '',
        };
    },

    watch: {
        value: {
            handler(value) {
                if (value && value !== this.innerValue) {
                    this.innerValue = value;
                }
            },

            immediate: true,
        },
    },

    methods: {
        setValue(value) {
            this.innerValue = value;
        },

        clearValue() {
            const value = '';
            this.setValue(value);
        },

        onClickClearButton() {
            this.clearValue();
            this.emitInput();
        },

        onInput($event) {
            const value = $event.target.value;
            this.setValue(value);
            this.emitInput();
        },

        onEnter() {
            this.$refs.inputDesktop.blur();
        },

        emitInput() {
            const target = this;
            const value = this.innerValue;
            this.$emit('input', { target, value });
            this.$emit('inputModel', value);
        },
    },
};
</script>

<style>
.chat-search-input__wrap {
    position: relative;
    width: 100%;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: var(--lightest-bg);
}

.chat-search-input__input {
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    border: none;
    background-color: transparent;
}
@media (max-width: 767px) {
    .chat-search-input__input.chat-search-input__input-desktop {
        display: none;
    }
}
@media (min-width: 768px) {
    .chat-search-input__input.chat-search-input__input-mobile {
        display: none;
    }
}

.chat-search-input__placeholder {
    position: absolute;
    left: 40px;
    color: var(--font-secondary-color);
    transition: color var(--transition);
}

.chat-search-input__input:focus ~ .chat-search-input__placeholder {
    color: var(--font-secondary-light-color);
}

.chat-search-input__input:not(:first-child) {
    margin-left: 8px;
}

.chat-search-input__clear-button {
    width: 20px;
    height: 20px;
    margin-right: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-search-input__clear-button:hover .chat-search-input__clear-icon,
.chat-search-input__clear-button:active .chat-search-input__clear-icon {
    fill: var(--font-secondary-color);
}

.chat-search-input__search-icon {
    fill: var(--font-secondary-color);
}

.chat-search-input__clear-icon {
    fill: var(--font-secondary-light-color);
}
</style>