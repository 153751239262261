import bus from '../bus.js';

export default {
    install(Vue) {
        Vue.prototype.$notify = Vue.$notify = payload => {
            bus.$emit('notify', payload);
        };

        Vue.prototype.$success = Vue.$success = (message = 'Операция успешно выполнена') => {
            bus.$emit('notify', { type: 'success', message });
        };

        Vue.prototype.$error = Vue.$error = (message = 'Не удалось совершить операцию') => {
            bus.$emit('notify', { type: 'error', message });
        };
    },
};
