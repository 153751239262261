<script>
// utils
import deepClone from '@ui/utils/deepClone.js';
import equals from '@ui/utils/equals.js';
// use
import useForm from './useForm.js';
import useFormMethods from './useFormMethods.js';
import useFormProviders from './useFormProviders.js';
import useFormWatch from './useFormWatch.js';
// components
import UTabs from '@ui/components/UTabs/UTabs.vue';


// TODO: добавить функцию get для получения чистых данных
// настраивать то, какие данные должен возвращать виджет
// для формы, можно будет в плагинах
export default {
    name: 'UFormProvide',

    provide() {
        return {
            ...useFormProviders(this),
            refRoot: this.refRoot,
        };
    },

    model: {
        prop: 'values',
        event: 'change',
    },

    props: {
        formTag: {
            type: Boolean,
            default: true,
        },

        values: { // originalValues
            type: Object,
            required: true,
        },

        errors: Object, // originalErrors
    },

    data() {
        return {
            ...useForm(this),
            initialValues: {},
            innerErrors: {},
            currentTab: null,
            tabs: [],
        };
    },

    computed: {
        originalValues() {
            return this.values;
        },

        originalErrors() {
            return this.errors;
        },

        isRoot() {
            return true;
        },
    },

    watch: {
        ...useFormWatch(),

        innerErrors: {
            handler(errors) {
                if (!equals(this.innerErrors, this.errors)) {
                    this.$emit('error', errors);
                }
            },
        },

        currentTab: 'onChangeTab',
        tabs: 'onChangeTab',
    },

    created() {
        this.initialValues = deepClone(this.values);
    },

    methods: {
        ...useFormMethods(),

        refRoot(calleeComponent, callerComponent) {
            const caller = callerComponent.name;

            if (this.fields[caller]) {
                if (this.dependencies[caller]) {
                    this.dependencies[caller] = [...this.dependencies[caller], calleeComponent];
                }
                else {
                    this.dependencies[caller] = [calleeComponent];
                }

                return this.fields[caller].innerValue;
            }
            else {
                return {};
            }
        },

        // public

        async validate() {
            // возвращает innerValues если есть ошибка
            // на странице должны быть помощники по обработке объекта ошибки

            // сначала посмотреть, есть ли ошибка вообще
            // если есть ошибка, то есть ли ошибка среди required
            // иначе вывести другую формулировку

            return new Promise(resolve => {
                this.runRules();
                const invalid = this.getInvalid();

                if (invalid) {
                    this.show();

                    setTimeout(() => {
                        this.$form.options.handleFormValidateErrors(this.innerErrors);
                        resolve(this.innerErrors);
                    });
                }
                else {
                    resolve(false);
                }
            });
        },

        handle(error) {
            this.$form.options.handleHttpError(error, this);
        },

        updateInitialValues() {
            this.$nextTick(() => {
                this.initialValues = deepClone(this.values);
            });
        },

        updateOriginalErrors() {
            if (this.originalErrors !== undefined) {
                this.$emit('error', this.innerErrors);
            }
        },

        // handlers

        onKeypress($event) {
            const keyCode = $event.keyCode;

            // enter
            if (keyCode === 13) {
                this.$emit('enter', $event);
            }
        },

        onSubmit($event) {
            $event.preventDefault();
            this.$emit('submit', $event);
        },

        // render

        onChangeTab() {
            if (this.currentTab) {
                this.tabs.forEach((vNode, index) => {
                    if (vNode.elm) {
                        vNode.elm.style.display = index === this.currentTab.name ? null : 'none';
                    }
                });
            }
        },

        getItems(tabs) {
            return tabs.map((vNode, index) => {
                const invalid = (vNode.componentInstance || {}).innerInvalid;
                const title = vNode.componentOptions.propsData.title;
                const disabled = vNode.componentOptions.propsData.disabled;

                return {
                    invalid,
                    disabled,
                    name: index,
                    label: title,
                };
            });
        },

        genPanel(tabs) {
            const items = this.getItems(tabs);

            if (this.currentTab === null) {
                this.currentTab = items[0];
            }

            const props = {
                value: this.currentTab,
                items,
            };

            const on = {
                change: value => {
                    this.currentTab = value;
                },
            };

            return this.$scopedSlots.tabs ? this.$scopedSlots.tabs({
                attrs: props,
                handlers: on,
            }) : this.$createElement(UTabs, {
                props,
                on,
            });
        },

        genChildren() {
            const vNodes = this.$scopedSlots.default();
            const tabs = this.tabs = vNodes.filter(vNode => {
                return vNode.componentOptions && vNode.componentOptions.tag === 'UFormTab';
            });

            if (tabs.length) {
                return [this.genPanel(tabs), ...tabs];
            }
            else {
                return vNodes;
            }
        },
    },

    render(h) {
        const options = {
            on: {
                keypress: this.onKeypress,
                submit: this.onSubmit,
            },
        };

        return h(this.formTag ? 'form' : 'div', options, this.genChildren());
    },
};
</script>