var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UMenu',{ref:"menu",staticClass:"u-input-date",attrs:{"showOnFocus":"","shift":false,"placement":"bottom","hideOnBlur":false,"contentWidthByTrigger":"","hideOnClickContent":false},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var value = ref.value;
var attrs = ref.attrs;
var handlers = ref.handlers;
return [_c('UInputField',_vm._g(_vm._b({ref:"input",attrs:{"value":_vm.inputValue,"mask":"00.00.0000"},scopedSlots:_vm._u([{key:"append-inner",fn:function(){return [_c('UIconButton',{staticClass:"u-input-date__icon",class:{
                        'u-input-date__icon_active': value
                    },attrs:{"name":"calendar","small":"","secondary":""},on:{"click":_vm.onClickIcon}})]},proxy:true}],null,true)},'UInputField',Object.assign({}, attrs,
                _vm.$attrs),false),Object.assign({}, handlers,
                _vm.listeners)))]}},{key:"content",fn:function(){return [_c('UDatePicker',{attrs:{"min":_vm.min,"max":_vm.max,"value":_vm.pickerValue,"stepByStep":_vm.stepByStep,"initialDate":_vm.initialDate},on:{"click:day":_vm.onClickDay,"change":_vm.onChangePicker}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }