<template>
    <component
        :is="tag"
        :disabled="disabled || loading"
        v-bind="attrs"
        :class="[
            'button',
            {
                disabled,
                primary,
                secondary,
                blue,
                loading,
                active,
                low,
                mini,
                big,
                narrow,
                w100,
            }
        ]"
        @click="$emit('click', $event)"
    >
        <Spinner
            v-if="loading"
            :light="primary"
            :dark="secondary"
            absoluteCenter
            :size="low ? 'small' : 'base'"
            class="btn-spin"
        ></Spinner>

        <slot name="prepend-icon">
            <UIcon
                v-if="prependIcon"
                v-bind="prependIcon"
                class="prepend-icon icon"
            ></UIcon>
        </slot>

        <span class="label">
            <slot>
                {{ label }}
            </slot>
        </span>

        <slot name="append-icon">
            <UIcon
                v-if="appendIcon"
                v-bind="appendIcon"
                class="icon append-icon"
            ></UIcon>
        </slot>
    </component>
</template>

<script>
import buttonMixin from './buttonMixin.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import Spinner from '../Spinner.vue';


export default {
    name: 'ButtonBlock',

    components: {
        Spinner,
        UIcon,
    },

    mixins: [
        buttonMixin,
    ],

    props: {
        w100: Boolean,
        primary: Boolean,
        secondary: Boolean,
        blue: Boolean,
        low: Boolean,
        mini: Boolean,
        narrow: Boolean,
        big: Boolean,
        appendIcon: Object,
        prependIcon: Object,
    },
};
</script>

<style scoped>
.button {
    position: relative;
    padding: 10px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    border-radius: var(--border-radius);
    transition:
        background-color var(--transition),
        border-color var(--transition),
        color var(--transition),
        fill var(--transition);
}

.button.low {
    padding: 7px 16px;
}

.button.mini {
    padding: 6px 16px;
}

.button.narrow {
    padding-left: 12px;
    padding-right: 12px;
}

.label {
    height: 20px;
    font-size: var(--big-fz);
    line-height: var(--small-lh);
    font-family: var(--f-semi-bold);
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

.button.low .label {
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.button.mini .label {
    height: 18px;
    font-size: 12px;
}

.prepend-icon {
    margin-right: 12px;
}

.append-icon {
    margin-left: 8px;
}


.button.blue {
    color: var(--light-c);
    fill: var(--light-c);
    background-color: var(--blue-medium);
    border: 2px solid var(--blue-medium);
}

.button.blue:hover,
.button.blue:focus {
    background-color: var(--blue-dark);
    border-color: var(--blue-dark);
}

.button.blue:active {
    background-color: var(--blue-darkest);
    border-color: var(--blue-darkest);
}

.button.blue:disabled,
.button.blue.disabled {
    background-color: var(--blue-inactive);
    border-color: var(--blue-inactive);
}

.button.blue.active {
    background-color: var(--blue-darkest);
    border-color: var(--blue-darkest);
}

.button.blue.active:hover,
.button.blue.active:focus {
    background-color: var(--blue-darkest);
    border-color: var(--blue-darkest);
}



.button.primary {
    color: var(--light-c);
    fill: var(--light-c);
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.button.primary.low {
    padding: 6px 16px;
}

.button.loading > *:not(.btn-spin) {
    opacity: 0;
}

.button.primary:hover,
.button.primary:focus {
    background-color: var(--primary-dark-color);
    border-color: var(--primary-dark-color);
}

.button.primary:active {
    background-color: var(--primary-darkest-color);
    border-color: var(--primary-darkest-color);
}

.button.primary:disabled:not(.loading),
.button.primary.disabled:not(.loading) {
    background-color: var(--primary-light-color);
    border-color: var(--primary-light-color);
}

.button.primary.active {
    color: var(--primary-color);
    fill: var(--primary-color);
    background-color: var(--light-c);
    border-color: var(--primary-color);
}

.button.primary.active:hover,
.button.primary.active:focus {
    color: var(--primary-dark-color);
    fill: var(--primary-dark-color);
    background-color: var(--light-c);
    border-color: var(--primary-dark-color);
}



.button.secondary {
    padding: 11px 24px;
    color: var(--dark-c);
    fill: var(--dark-c);
    background-color: var(--light-c);
    border: 1px solid var(--border-dark-c);
}

.button.secondary.low {
    padding: 7px 16px;
}

.button.secondary .icon {
    fill: var(--dark-c)
}

.button.secondary:hover,
.button.secondary:focus {
    background-color: var(--lightest-bg);
}

.button.secondary.active,
.button.secondary:active {
    background-color: var(--light-bg);
    border-color: var(--border-dark-c);
}

.button.secondary:disabled:not(.loading),
.button.secondary.disabled:not(.loading) {
    color: var(--font-secondary-light-color);
    fill: var(--font-secondary-light-color);
    border-color: var(--border-light-c);
}

.button.w100 {
    width: 100%;
    flex-shrink: 0;
}

.button.big {
    height: 60px;
    padding: 0 16px;
    font-size: 16px;
}
</style>