var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"insurer-osago-summary"},[_c('h2',{staticClass:"h2"},[_vm._v("\n        Проверьте ваши данные\n    ")]),_vm._v(" "),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"list-item__text-wrap"},[_c('h3',{staticClass:"list-item__title"},[_vm._v("\n                Автомобиль\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                "+_vm._s((_vm.contract.vehicle_model || {}).fullname)+", "+_vm._s(_vm.contract.vehicle_year)+" ("+_vm._s(_vm.contract.vehicle_engine_power)+" л. с.)\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[(_vm.contract.vehicle_license_plate)?_c('span',[_vm._v("Гос. номер: "+_vm._s(_vm._f("carNumber")(_vm.contract.vehicle_license_plate))+", ")]):_vm._e(),_vm._v(_vm._s(_vm.vehicleIdTypeCodeComputed)+": "+_vm._s(_vm.contract.vehicle_id_number)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                "+_vm._s(_vm.vehicleDocTypeCodeComputed)+": "+_vm._s(_vm.contract.vehicle_doc_number)+", дата выдачи: "+_vm._s(_vm._f("date")(_vm.contract.vehicle_doc_date))+"\n            ")])]),_vm._v(" "),_c('ButtonText',{staticClass:"btn-edit btn-edit_desktop",attrs:{"dashed":"","secondary":"","dark":""},on:{"click":function($event){return _vm.$emit('popup', 'car')}}},[_vm._v("\n            Изменить\n        ")]),_vm._v(" "),_c('ButtonIcon',{staticClass:"btn-edit btn-edit_mobile",attrs:{"icon":{
                name: 'edit',
                small: true
            },"circle":""},on:{"click":function($event){return _vm.$emit('popup', 'car')}}})],1),_vm._v(" "),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"list-item__text-wrap"},[_c('h3',{staticClass:"list-item__title"},[_vm._v("\n                Срок действия полиса\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text",class:{
                    text_red: _vm.error.action_start_date,
                }},[_vm._v("\n                "+_vm._s(_vm._f("date")(_vm.contract.action_start_date))),(_vm.actionEndDate)?[_vm._v("\n                    – "+_vm._s(_vm.actionEndDate)+", включительно\n                ")]:_vm._e(),(_vm.error.action_start_date)?_c('UTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                var value = ref.value;
                var handlers = ref.handlers;
                var attrs = ref.attrs;
return [_c('UIconButton',_vm._g(_vm._b({attrs:{"name":"info","small":"","primary":"","active":value}},'UIconButton',attrs,false),handlers))]}}],null,false,3789411534)}):_vm._e()],2)]),_vm._v(" "),_c('ButtonText',{staticClass:"btn-edit btn-edit_desktop",attrs:{"dashed":"","secondary":"","dark":""},on:{"click":function($event){return _vm.$emit('popup', 'policy')}}},[_vm._v("\n            Изменить\n        ")]),_vm._v(" "),_c('ButtonIcon',{staticClass:"btn-edit btn-edit_mobile",attrs:{"icon":{
                name: 'edit',
                small: true
            },"circle":""},on:{"click":function($event){return _vm.$emit('popup', 'policy')}}})],1),_vm._v(" "),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"list-item__text-wrap"},[_c('h3',{staticClass:"list-item__title"},[_vm._v("\n                Водители\n            ")]),_vm._v(" "),(_vm.contract.unlimited_drivers_amount)?_c('p',{staticClass:"list-item__text"},[_vm._v("\n                Без ограничений на водителей\n            ")]):_vm._l((_vm.contract.drivers),function(driver,driverIndex){return _c('div',{key:'driver-' + driverIndex,class:{
                        'mb-8': driverIndex + 1 < _vm.contract.drivers.length && _vm.contract.drivers.length > 1,
                    }},[_c('p',{staticClass:"list-item__text"},[_vm._v("\n                        "+_vm._s(driver.last_name)+" "+_vm._s(driver.first_name)+" "+_vm._s(driver.middle_name)+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                        Дата рождения: "+_vm._s(_vm._f("date")(driver.birth_date))+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                        ВУ: "+_vm._s(driver.license_number)+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                        Дата начала стажа: "+_vm._s(_vm._f("date")(driver.experience_start_date))+"\n                    ")])])})],2),_vm._v(" "),_c('ButtonText',{staticClass:"btn-edit btn-edit_desktop",attrs:{"dashed":"","secondary":"","dark":""},on:{"click":function($event){return _vm.$emit('popup', 'drivers')}}},[_vm._v("\n            Изменить\n        ")]),_vm._v(" "),_c('ButtonIcon',{staticClass:"btn-edit btn-edit_mobile",attrs:{"icon":{
                name: 'edit',
                small: true
            },"circle":""},on:{"click":function($event){return _vm.$emit('popup', 'drivers')}}})],1),_vm._v(" "),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"list-item__text-wrap"},[_c('h3',{staticClass:"list-item__title"},[_vm._v("\n                Собственник\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                "+_vm._s(_vm.contract.owner_last_name)+" "+_vm._s(_vm.contract.owner_first_name)+" "+_vm._s(_vm.contract.owner_middle_name)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                Дата рождения: "+_vm._s(_vm._f("date")(_vm.contract.owner_birth_date))+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                Паспорт: "+_vm._s(_vm.contract.owner_doc_number)+", дата выдачи: "+_vm._s(_vm._f("date")(_vm.contract.owner_doc_date))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n                Адрес регистрации: "+_vm._s((_vm.contract.owner_address_obj || {}).value)),(_vm.contract.owner_address_apartment_number)?[_vm._v("\n                    , кв. "+_vm._s(_vm.contract.owner_address_apartment_number)+"\n                ")]:_vm._e()],2)]),_vm._v(" "),_c('ButtonText',{staticClass:"btn-edit btn-edit_desktop",attrs:{"dashed":"","secondary":"","dark":""},on:{"click":function($event){return _vm.$emit('popup', 'owner')}}},[_vm._v("\n            Изменить\n        ")]),_vm._v(" "),_c('ButtonIcon',{staticClass:"btn-edit btn-edit_mobile",attrs:{"icon":{
                name: 'edit',
                small: true
            },"circle":""},on:{"click":function($event){return _vm.$emit('popup', 'owner')}}})],1),_vm._v(" "),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"list-item__text-wrap"},[_c('h3',{staticClass:"list-item__title"},[_vm._v("\n                Страхователь\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                "+_vm._s(_vm.contract.insurer_last_name)+" "+_vm._s(_vm.contract.insurer_first_name)+" "+_vm._s(_vm.contract.insurer_middle_name)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                Дата рождения: "+_vm._s(_vm._f("date")(_vm.contract.insurer_birth_date))+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                Паспорт: "+_vm._s(_vm.contract.insurer_doc_number)+", дата выдачи: "+_vm._s(_vm._f("date")(_vm.contract.insurer_doc_date))+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                Адрес регистрации: "+_vm._s((_vm.contract.insurer_address_obj || {}).value)),(_vm.contract.insurer_address_apartment_number)?[_vm._v("\n                    , кв. "+_vm._s(_vm.contract.insurer_address_apartment_number)+"\n                ")]:_vm._e()],2)]),_vm._v(" "),_c('ButtonText',{staticClass:"btn-edit btn-edit_desktop",attrs:{"dashed":"","secondary":"","dark":""},on:{"click":function($event){return _vm.$emit('popup', 'insurer')}}},[_vm._v("\n            Изменить\n        ")]),_vm._v(" "),_c('ButtonIcon',{staticClass:"btn-edit btn-edit_mobile",attrs:{"icon":{
                name: 'edit',
                small: true
            },"circle":""},on:{"click":function($event){return _vm.$emit('popup', 'insurer')}}})],1),_vm._v(" "),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"list-item__text-wrap"},[_c('h3',{staticClass:"list-item__title"},[_vm._v("\n                Контактные данные\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                "+_vm._s(_vm.contract.insurer_email)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"list-item__text"},[_vm._v("\n                "+_vm._s(_vm._f("phone")(_vm.contract.insurer_phone))+"\n            ")])]),_vm._v(" "),_c('ButtonText',{staticClass:"btn-edit btn-edit_desktop",attrs:{"dashed":"","secondary":"","dark":""},on:{"click":function($event){return _vm.$emit('popup', 'contacts')}}},[_vm._v("\n            Изменить\n        ")]),_vm._v(" "),_c('ButtonIcon',{staticClass:"btn-edit btn-edit_mobile",attrs:{"icon":{
                name: 'edit',
                small: true
            },"circle":""},on:{"click":function($event){return _vm.$emit('popup', 'contacts')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }