<template>
    <div
        :class="[
            'post-editor__block',
            'post-editor__block_' + widget.type,
            widget.align,
        ]"
    >
        <div
            :class="[
                widget.marginTop ? 'indent-wrap_mt' + widget.marginTop : '',
                widget.marginBottom ? 'indent-wrap_mb' + widget.marginBottom : '',
                widget.paddingTop ? 'indent-wrap_pt' + widget.paddingTop : '',
                widget.paddingBottom ? 'indent-wrap_pb' + widget.paddingBottom : '',
            ]"
            class="indent-wrap"
        >
            <figure v-lazy-container="{ selector: 'img' }">
                <a
                    v-if="widget.link"
                    :href="widget.link"
                    class="img-link-wrap"
                    :class="{
                        'img-link-wrap_hoverable': !widget.backgroundFlag
                    }"
                    target="_blank"
                >
                    <img
                        src=""
                        :data-src="$links.uploads + widget.image.large_url"
                        :alt="widget.alt || null"
                        :title="widget.title || null"
                        class="image"
                    >

                    <span
                        v-if="!widget.backgroundFlag"
                        class="img-link-text"
                    >
                        Перейти
                        <UIcon
                            name="arrow-right"
                            small
                            class="img-link-text__icon"
                        ></UIcon>
                    </span>
                </a>

                <img
                    v-else
                    src=""
                    :data-src="$links.uploads + widget.image.large_url"
                    :alt="widget.alt || null"
                    :title="widget.title || null"
                    class="image"
                >

                <figcaption
                    v-if="widget.caption"
                    class="caption"
                >
                    {{ widget.caption }}
                </figcaption>
            </figure>
        </div>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';
export default {
    name: "PostEditorImage",
    components: {UIcon},
    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style scoped>
.img-link-wrap {
    display: block;
    position: relative;
}

.img-link-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-c);
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0var(--transition);
}

.img-link-text__icon {
    margin-left: 8px;
    fill: currentColor;
}

.img-link-wrap_hoverable:hover .img-link-text,
.img-link-wrap_hoverable:focus .img-link-text {
    opacity: 1;
}

.caption {
    margin-top: 12px;
    color: var(--font-secondary-color);
}

.center .image {
    margin-left: auto;
    margin-right: auto;
}
.center .caption {
    text-align: center;
}

.left .image {
    margin-right: auto;
}
.left .caption {
    text-align: left;
}

.right .image {
    margin-left: auto;
}
.right .caption {
    text-align: right;
}
</style>