<template>
    <footer class="footer">
        <div class="container">
            <RouterLink
                :to="{
                    name: 'main',
                }"
                class="logo-wrap logo-wrap_mobile"
            >
                <img
                    src="../assets/images/logo.svg"
                    alt="Логотип Ubibi"
                    class="logo"
                    width="53"
                    height="17"
                >
            </RouterLink>

            <ul
                v-if="menu.length"
                class="navigation"
            >
                <li
                    v-for="(section, sectionIndex) in filteredMenu"
                    class="section"
                >
                    <div
                        class="heading-wrap"
                        @click="toggleNavSection(sectionIndex)"
                    >
                        <h4 class="heading">
                            {{ section.name }}
                        </h4>
                        <UIcon
                            name="arrow-down"
                            small
                            class="heading__arrow"
                            :class="{ 'heading__arrow_rotate': openSections[sectionIndex] }"
                        ></UIcon>
                    </div>

                    <TransitionExpand>
                        <ul
                            v-show="openSections[sectionIndex]"
                            :id="`nav-section-${ sectionIndex }`"
                            :key="`nav-section-${ sectionIndex }`"
                            class="section-list"
                        >
                            <li
                                v-for="page in section.pages"
                                class="item"
                            >
                                <RouterLink
                                    v-if="page.type === 'static-pages'"
                                    :title="page.name"
                                    :to="{
                                        name: page.entity.codename,
                                    }"
                                    :target="page.target_blank ? '_blank' : '_self'"
                                    class="link link-primary-hover"
                                >
                                    {{ page.name }}
                                </RouterLink>
                                <RouterLink
                                    v-if="page.type === 'additional-pages'"
                                    :title="page.name"
                                    :to="{
                                        name: 'additional-page',
                                        params: {
                                            codename: page.entity.codename,
                                        }
                                    }"
                                    :target="page.target_blank ? '_blank' : '_self'"
                                    class="link link-primary-hover"
                                >
                                    {{ page.name }}
                                </RouterLink>
                                <a
                                    v-if="page.type === 'link'"
                                    :href="page.url"
                                    :title="page.name"
                                    :target="page.target_blank ? '_blank' : '_self'"
                                    class="link link-primary-hover"
                                >
                                    {{ page.name }}
                                </a>
                            </li>
                        </ul>
                    </TransitionExpand>
                </li>
            </ul>

            <div class="logos">
                <RouterLink
                    :to="{
                        name: 'main',
                    }"
                    class="logo-wrap logo-wrap_desktop"
                >
                    <img
                        src="../assets/images/logo.svg"
                        alt="Логотип Ubibi"
                        class="logo"
                        width="53"
                        height="17"
                    >
                </RouterLink>

                <SocialsNets></SocialsNets>

                <div class="payments-block payments-block_mobile">
                    <Payments></Payments>
                </div>
            </div>

            <div class="footer-bottom">
                <RouterLink
                    :to="{ name: 'privacy-policy' }"
                    class="footer-bottom__item link-primary-hover bottom-link"
                >
                    Политика конфиденциальности
                </RouterLink>

                <RouterLink
                    :to="{ name: 'rules' }"
                    class="footer-bottom__item link-primary-hover bottom-link"
                >
                    Соглашения и правила
                </RouterLink>

                <span class="footer-bottom__item copyright">© {{ year }}, {{ company_name }}</span>

                <div class="payments-block payments-block_desktop">
                    <Payments></Payments>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from "vuex";
import SocialsNets from "./SocialsNets.vue";
import Payments from './Payments.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import TransitionExpand from '@/components/_transitions/TransitionExpand.vue';
import breakpointKey from '@/mixins/breakpointKey.js';

const startYear = 2020;

export default {
    name: "DefaultLayoutFooter",

    components: {
        TransitionExpand,
        UIcon,
        Payments,
        SocialsNets,
    },

    mixins: [breakpointKey],

    data() {
        return {
            openSections: '',
        };
    },

    computed: {
        ...mapState({
            menu: state => state.settings.footer_menu,
            company_name: state => state.settings.company_name,
        }),

        filteredMenu() {
            return this.menu.map(section => {
                const { pages, ...args } = section;
                const filteredPages = pages.filter(page => page.is_active);

                if (filteredPages.length) {
                    return { ...args, pages: filteredPages };
                }
            }).filter(_ => _);
        },

        year() {
            // TODO: Поменять на серверное время
            const currentYear = new Date().getFullYear();
            if (currentYear === startYear) {
                return startYear;
            }
            else {
                return `${ startYear } – ${ currentYear }`;
            }
        },
    },

    watch: {
        'breakpointKey': {
            handler (value) {
                if (value === 'xs' || value === 's') {
                    this.openSections = Array(this.filteredMenu.length).fill(false);
                }
                else {
                    this.openSections = Array(this.filteredMenu.length).fill(true);
                }
            },
            immediate: true,
        },
    },

    methods: {
        toggleNavSection(sectionIndex) {
            this.openSections.splice(sectionIndex, 1, !this.openSections[sectionIndex]);
        },
    },
};
</script>

<style scoped>
.footer {
    margin-top: var(--gap-y-max);
    color: var(--font-secondary-dark-color);
    background-color: var(--light-bg);
}
@media (min-width: 768px) {
    .footer {
        padding-top: 36px;
        padding-bottom: 36px;
    }
}
@media (max-width: 767px) {
    .footer {
        padding-top: 28px;
        padding-bottom: 28px;
    }
}

@media (min-width: 1281px) {
    .navigation {
        grid-template-columns: repeat(4, 260px);
        grid-gap: 48px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .navigation {
        grid-template-columns: repeat(4, 200px);
        grid-gap: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .navigation {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 12px;
    }
}
@media (min-width: 768px) {
    .navigation {
        display: grid;
        margin-bottom: 36px;
    }
}
@media (max-width: 767px) {
    .navigation {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .section-list {
        margin-top: -8px;
        margin-bottom: -8px;
    }
}
@media (max-width: 767px) {
    .section-list {
        padding-left: 16px;
        padding-bottom: 8px;
    }
}

.heading {
    font-family: var(--f-bold);
    color: var(--dark-c);
}
@media (min-width: 768px) {
    .heading {
        letter-spacing: 0.06em;
        text-transform: uppercase;
    }
}

@media (min-width: 768px) {
    .heading-wrap {
        margin-bottom: 16px;
    }
}
@media (max-width: 767px) {
    .heading-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
    }
}

.heading__arrow {
    transition: transform var(--transition);
}
.heading__arrow_rotate {
    transform: rotate(180deg);
}
@media (min-width: 768px) {
    .heading__arrow {
        display: none;
    }
}

.link {
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
}

@media (min-width: 768px) {
    .logos {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }
}

.logo {
    width: 53px;
}

@media (min-width: 768px) {
    .logo-wrap_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .logo-wrap_mobile {
        display: block;
        width: max-content;
        margin-bottom: 20px;
    }

    .logo-wrap_desktop {
        display: none;
    }
}

@media (min-width: 768px) {
    .socials {
        margin-left: auto;
    }
}
@media (max-width: 767px) {
    .socials {
        display: none;
    }
}

@media (min-width: 768px) {
    .footer-bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 24px;
        border-top: 1px solid var(--border-dark-c);
    }

    .bottom-link + .bottom-link {
        margin-left: 24px;
    }
}
@media (max-width: 767px) {
    .footer-bottom {
        margin-top: 28px;
    }

    .footer-bottom__item {
        display: block;
    }

    .footer-bottom__item:not(:first-child) {
        margin-top: 16px;
    }
}

@media (min-width: 1040px) {
    .copyright {
        order: -1;
        width: 250px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .copyright {
        order: -1;
        margin-right: auto;
    }
}

@media (min-width: 1040px) {
    .payments-block_desktop {
        margin-left: auto;
    }

    .payments-block_mobile {
        display: none;
    }
}
@media (max-width: 1039px) {
    .payments-block_desktop {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .payments-block_mobile {
        margin-left: 48px;
    }
}
</style>