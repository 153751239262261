<template>
    <div
        class="user-avatar"
        :class="[
            'user-avatar_' + size,
            icon && 'user-avatar_icon',
        ]"
    >
        <UIcon
            v-if="icon"
            name="user"
            big
            class="user-avatar__icon"
        ></UIcon>
        <template v-else>
            <img
                v-if="avatarSrc"
                :src="avatarSrc"
                :alt="profile.first_name"
                class="user-avatar__img"
                @load="$emit('load')"
            >
            <UIcon
                v-else-if="emptyIcon"
                name="user"
                big
                class="user-avatar__icon"
            ></UIcon>
            <span
                v-else
                class="user-avatar__initials"
            >
                {{ initials }}
            </span>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'UserAvatar',

    components: { UIcon },

    props: {
        user: {
            type: Object,
            default: () => {},
        },

        src: {
            type: [String, Boolean],
            default: '',
        },

        icon: Boolean,
        emptyIcon: Boolean,

        size: {
            type: String,
            validator(value) {
                return ['xs', 's', 'm', 'l', 'xl', 'xxl'].includes(value);
            },
            default: 'm',
        },
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),

        sourceObject() {
            return this.user ? this.user : this.profile;
        },

        avatarSrc() {
            if (this.src !== '') {
                return this.src;
            }

            let imageLink = ((this.sourceObject.avatar || {}).thumbnails || {})['avatar_' + this.size];

            return imageLink
                ? this.$links.uploads + imageLink
                : null;
        },

        initials() {
            return (this.sourceObject.first_name || 'u').charAt(0);
        },
    },
};
</script>

<style scoped>
.user-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: var(--grey-color);
    border-radius: 50%;
    overflow: hidden;
}

.user-avatar_icon {
    background-color: var(--light-c);
}

@media (min-width: 1040px) {
    .user-avatar_xxl {
        width: 120px;
        height: 120px;
        font-size: 28px;
    }
}
@media (max-width: 1039px) {
    .user-avatar_xxl {
        width: 80px;
        height: 80px;
        font-size: 20px;
    }
}
.user-avatar_xl {
    width: 80px;
    height: 80px;
    font-size: 20px;
}
.user-avatar_l {
    width: 72px;
    height: 72px;
    font-size: 20px;
}
.user-avatar_m {
    width: 48px;
    height: 48px;
    font-size: var(--base-fz);
}
.user-avatar_s {
    width: 36px;
    height: 36px;
    font-size: var(--base-fz);
}
.user-avatar_xs {
    width: 24px;
    height: 24px;
    font-size: var(--small-fz);
}

.user-avatar__img {
    width: 100%;
    height: 100%;
    user-select: none;
}

.user-avatar__initials {
    font-family: var(--f-bold);
    color: var(--light-c);
    text-transform: uppercase;
}
</style>