var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopupWrap',{attrs:{"loading":_vm.initLoading},on:{"clickaway":_vm.handleClose,"close":_vm.handleClose}},[(!_vm.initLoading)?_c('div',{staticClass:"popup-content",class:{
            'short-scheme-popup-layout': _vm.catalogSchemesCount > 1,
        }},[(!_vm.catalogSchemesCount)?[_c('h4',{staticClass:"h4 text_center"},[_vm._v("\n                Ничего не найдено\n            ")])]:_vm._e(),_vm._v(" "),(_vm.catalogSchemesCount)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectSchemeMode),expression:"!selectSchemeMode"}],staticClass:"main"},[_c('div',{staticClass:"head"},[(_vm.catalogSchemesCount > 1)?_c('BackLink',{staticClass:"back-to-schemes",on:{"click":_vm.backToSchemes}},[_vm._v("Вернуться к схемам")]):_vm._e(),_vm._v(" "),(_vm.part.name)?_c('h2',{staticClass:"h2 popup-title"},[_vm._v("\n                        "+_vm._s(_vm.part.name)+"\n                    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"actions"},[(_vm.manufacturerCodename && _vm.partOnScheme.groups && _vm.carId)?_c('ButtonText',{staticClass:"to-catalog-btn",attrs:{"to":{
                                name: 'market-parts-catalogs-manufacturer-modification',
                                params: {
                                    manufacturer: _vm.manufacturerCodename,
                                    modification: _vm.carId,
                                    group: _vm.partOnScheme.groups[_vm.partOnScheme.groups.length-1].id,
                                },
                                query: Object.assign({
                                    s: _vm.currentScheme.id,
                                    part_id: _vm.part.part_id,

                                }, _vm.carProductionDate ? {
                                    production_date: _vm.carProductionDate
                                } : {})
                            },"underline":"","primary":""}},[_vm._v("Показать схему в каталоге "+_vm._s((_vm.car.manufacturer || {}).name || ''))]):_vm._e(),_vm._v(" "),_c('ButtonBlock',{staticClass:"to-price-btn",attrs:{"to":{
                                name: 'market-parts-search',
                                query: {
                                    part_product: _vm.partId,
                                }
                            },"primary":"","low":""},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v("\n                            Показать цены\n                        ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.currentScheme.image)?_c('div',{staticClass:"scheme-wrap"},[_c('div',{ref:"imageInnerWrap",staticClass:"scheme-inner-wrap"},[_c('Spinner',{directives:[{name:"show",rawName:"v-show",value:(!_vm.schemeImageLoad || !_vm.partOnSchemeLoaded),expression:"!schemeImageLoad || !partOnSchemeLoaded"}],attrs:{"absoluteCenter":""}}),_vm._v(" "),_c('img',{key:'imageDefault-' + _vm.currentScheme.image,ref:"imageDefault",staticClass:"scheme-img scheme-img_default",attrs:{"src":_vm.$links.uploads + _vm.currentScheme.image,"alt":""}}),_vm._v(" "),_c('img',{key:'image-' + _vm.currentScheme.image,ref:"image",staticClass:"scheme-img scheme-img_zoom",class:{
                                    'scheme-img_hidden': !_vm.partOnSchemeLoaded,
                                    'scheme-img_zoomer': _vm.schemeZoomValue > 0,
                                },style:(_vm.schemeImageStyles),attrs:{"src":_vm.$links.uploads + _vm.currentScheme.image,"alt":""},on:{"load":_vm.handleSchemeImageLoad,"mousedown":_vm.onSchemeImageMouseDown}}),_vm._v(" "),(_vm.schemeImageLoad && _vm.partOnSchemeLoaded)?_vm._l((_vm.partOnScheme.coordinates),function(coordinates,coordinates_index){return _c('span',{key:(coordinates_index + "-" + (coordinates.y1)),staticClass:"scheme-node-point",class:{
                                        'scheme-node-point-wrap_moving': _vm.moving,
                                    },style:(_vm.partsOnSchemeStyles[coordinates_index])})}):_vm._e()],2),_vm._v(" "),_c('ZoomControl',{ref:"zoomControl",staticClass:"scheme-zoom-control",on:{"change":_vm.updateImageZoom}})],1):_vm._e()])]),_vm._v(" "),(_vm.catalogSchemesCount > 1)?[_c('h2',{staticClass:"h2 h2_text schemes-list__title",class:{
                        'schemes-list__title_hidden': !_vm.selectSchemeMode,
                    }},[_vm._v("\n                    Выберите схему\n                ")]),_vm._v(" "),_c('div',{staticClass:"schemes-list",class:{
                        'schemes-list_hidden': !_vm.selectSchemeMode,
                    }},[((_vm.breakpointKey === 'xl' || _vm.breakpointKey === 'l') && _vm.canScrollTop)?_c('div',{staticClass:"navigation__arrow_prev-wrap",on:{"mouseenter":function($event){return _vm.startScroll('top')},"mouseleave":_vm.stopScroll}},[_c('RoundArrowBtn',{staticClass:"navigation__arrow navigation__arrow_prev",attrs:{"direction":"up","size":"48","shadow":""}})],1):_vm._e(),_vm._v(" "),_c('div',{ref:"list",staticClass:"schemes-list_inner",on:{"scroll":_vm.checkScroll}},_vm._l((_vm.catalogSchemes),function(scheme,index){return _c('div',{key:scheme.id + index,staticClass:"schemes-list__item",class:{
                                'schemes-list__item_current': scheme.id === _vm.currentScheme.id,
                            }},[_c('SchemeGridItem',{attrs:{"scheme":scheme},on:{"schemeClick":function($event){return _vm.handleSchemeClick(scheme)}}})],1)}),0),_vm._v(" "),((_vm.breakpointKey === 'xl' || _vm.breakpointKey === 'l') && _vm.canScrollBottom)?_c('div',{staticClass:"navigation__arrow_next-wrap",on:{"mouseenter":function($event){return _vm.startScroll('bottom')},"mouseleave":_vm.stopScroll}},[_c('RoundArrowBtn',{staticClass:"navigation__arrow navigation__arrow_next",attrs:{"direction":"down","size":"48","shadow":""}})],1):_vm._e()])]:_vm._e()]:_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }