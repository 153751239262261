<template>
    <header class="header">
        <div class="header_mobile container">
            <ButtonIcon
                :icon="{
                    name: isMobileMenuOpen ? 'cross' : 'list',
                    big: true,
                }"
                small
                :secondary="false"
                class="mobile-menu-toggler"
                :aria-label="isMobileMenuOpen ? 'Закрыть мобильное меню' : 'Открыть мобильное меню'"
                @click="toggleMobileMenu"
            ></ButtonIcon>

            <UbibiLogo
                base
                class="header__ubibi-logo"
            ></UbibiLogo>

            <RouterLink
                :to="{
                    name: 'cart',
                }"
                class="cart-btn"
                aria-label="Перейти в корзину"
            >
                <UIcon
                    name="cart-stroke"
                    big
                    class="cart-btn__icon"
                ></UIcon>

                <UCircleLabelCounter
                    :count="cartTotalCount"
                    class="cart-btn__counter"
                ></UCircleLabelCounter>
            </RouterLink>
        </div>

        <div class="header_desktop container">
            <div class="header__city-static-pages-nav-wrap">
                <button
                    class="city link-primary-hover"
                    @click="showChangeCityPopup"
                >
                    <UIcon
                        name="place-16"
                        small
                        class="city__icon"
                    ></UIcon>
                    <span class="city__value">
                        {{ currentCity.name }}
                    </span>
                </button>

                <nav class="static-pages-nav">
                    <ul class="static-pages-nav__list">
                        <li
                            v-for="link in staticPagesMenu"
                            class="static-pages-nav__item"
                        >
                            <RouterLink
                                :to="link.to"
                                class="static-pages-nav__link link-primary-hover"
                            >
                                {{ link.title }}
                            </RouterLink>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="header__main">
                <div
                    class="medium-menu"
                >
                    <ButtonIcon
                        :icon="{
                            name: 'list',
                            big: true,
                        }"
                        small
                        :secondary="false"
                        class="medium-menu-toggler"
                        :aria-label="isMobileMenuOpen ? 'Закрыть мобильное меню' : 'Открыть мобильное меню'"
                        @click="toggleMobileMenu"
                    ></ButtonIcon>
                </div>

                <UbibiLogo
                    base
                    class="header__ubibi-logo"
                ></UbibiLogo>

                <nav class="desktop-menu">
                    <ul class="site-nav__list">
                        <li class="site-nav__item">
                            <RouterLink
                                :to="{
                                    name: 'market',
                                }"
                                class="site-nav__link link-primary-hover"
                                @click.native="handleYandexMetrikaGoal('header_avtozapchast')"
                            >
                                Запчасти и автотовары
                            </RouterLink>
                        </li>
                        <li class="site-nav__item">
                            <RouterLink
                                :to="{
                                    name: 'insurance-osago',
                                }"
                                class="site-nav__link link-primary-hover"
                                @click.native="handleYandexMetrikaGoal('header_osago')"
                            >
                                Страхование ОСАГО
                            </RouterLink>
                        </li>
                    </ul>
                </nav>

                <div class="all-user-actions">
                    <OrdersPreview class="mr-4"></OrdersPreview>

                    <FavoritePreview class="mr-4"></FavoritePreview>

                    <CartPreview></CartPreview>
                </div>

                <div
                    v-if="isAuthorized"
                    class="auth-user-actions"
                >
                    <NotificationsPreview
                        :unreadCount="unreadNotificationsCount"
                        class="mr-4"
                    ></NotificationsPreview>

                    <UserBar class="user-bar"></UserBar>
                </div>

                <ButtonBlock
                    v-else
                    secondary
                    low
                    class="login-btn"
                    @click="openSignInPopup"
                >
                    Войти
                </ButtonBlock>
            </div>
        </div>

        <Observer @intersect="intersected">
            <div
                v-if="showSearchSection"
                class="header__catalog-wrap container"
            >
                <div class="catalog-search">
                    <ButtonBlock
                        id="desktopCatalogTrigger"
                        :to="{
                            name: 'market-products-catalog'
                        }"
                        primary
                        class="catalog-btn"
                    >
                        Каталог
                    </ButtonBlock>

                    <SearchWidget class="flex-grow-1"></SearchWidget>
                </div>

                <div
                    v-if="showTopCategories"
                    class="top-categories-block"
                >
                    <div
                        v-if="canTopCategoriesScrollLeft"
                        class="top-categories__arrow_prev-wrap"
                        @mouseenter="startTopCategoriesScroll('left')"
                        @mouseleave="stopTopCategoriesScroll"
                    >
                        <RoundArrowBtn
                            direction="left"
                            size="20"
                            class="top-categories__arrow top-categories__arrow_prev"
                        ></RoundArrowBtn>
                    </div>
                    <nav class="top-categories top-categories_outer">
                        <ul
                            ref="topCategoriesList"
                            class="top-categories__list"
                            @scroll="checkTopCategoriesScroll"
                        >
                            <li
                                v-for="link in topCategories"
                                class="top-categories__item"
                            >
                                <RouterLink
                                    :to="link.to"
                                    class="top-categories__link link-primary-hover"
                                >
                                    {{ link.title }}
                                </RouterLink>
                            </li>
                        </ul>
                    </nav>
                    <div
                        v-if="canTopCategoriesScrollRight"
                        class="top-categories__arrow_next-wrap"
                        @mouseenter="startTopCategoriesScroll('right')"
                        @mouseleave="stopTopCategoriesScroll"
                    >
                        <RoundArrowBtn
                            direction="right"
                            size="24"
                            class="top-categories__arrow top-categories__arrow_next"
                        ></RoundArrowBtn>
                    </div>
                </div>
            </div>
        </Observer>

        <Portal
            to="sticky-holder"
            :order="1"
        >
            <div
                v-if="showSearchSection"
                id="header-sticky-section"
                ref="headerStickySection"
                class="header__sticky sticky-sections-holder__section"
                :class="{
                    'sticky-sections-holder__section_visible': isSearchSectionSticky,
                }"
            >
                <div class="header-sticky-container container">
                    <ButtonBlock
                        id="desktopCatalogTriggerSticky"
                        :to="{
                            name: 'market-products-catalog'
                        }"
                        primary
                        class="catalog-btn"
                    >
                        Каталог
                    </ButtonBlock>

                    <SearchWidget class="flex-grow-1"></SearchWidget>


                    <div class="all-user-actions">
                        <OrdersPreview
                            noTriggerText
                            class="hidden-l mr-4"
                        ></OrdersPreview>

                        <FavoritePreview
                            noTriggerText
                            class="hidden-l mr-4"
                        ></FavoritePreview>

                        <CartPreview noTriggerText></CartPreview>
                    </div>

                    <div
                        v-if="isAuthorized"
                        class="auth-user-actions"
                    >
                        <NotificationsPreview
                            :unreadCount="unreadNotificationsCount"
                            class="mr-4"
                        ></NotificationsPreview>

                        <UserBar class="user-bar"></UserBar>
                    </div>
                </div>
            </div>
        </Portal>

        <transition name="mobile-menu-wrap">
            <div
                v-show="isMobileMenuOpen"
                class="mobile-menu-wrap"
            >
                <div
                    class="mobile-menu__backdrop"
                    @click="hideMobileMenu"
                ></div>

                <div class="mobile-menu">
                    <div class="mobile-menu__section">
                        <RouterLink
                            v-if="isAuthorized"
                            :to="{
                                name: 'account-settings'
                            }"
                            class="flex align-items-center mb-20"
                        >
                            <UserAvatar
                                size="m"
                                class="mr-12"
                            ></UserAvatar>

                            <div>
                                <p class="text_bold mb-4">
                                    {{ profile.first_name }}
                                </p>
                                <p class="text_small-fz text_semi-bold text_secondary">
                                    {{ profile.phone }}
                                </p>
                            </div>
                        </RouterLink>

                        <div
                            v-if="!isAuthorized"
                            class="registration-banner"
                            @click="openSignInPopup(); handleYandexMetrikaGoal('header_enter')"
                        >
                            <h4 class="text_bold mb-4">
                                Войдите или зарегистрируйтесь
                            </h4>
                            <p class="text_base-lh">
                                Оформляйте заказы, оставляйте отзывы и&nbsp;общайтесь в&nbsp;чате.
                            </p>
                            <ButtonBlock
                                primary
                                low
                                pseudo
                                class="mt-12"
                            >
                                Войти
                            </ButtonBlock>
                        </div>

                        <ul class="site-nav__list">
                            <li class="site-nav__item">
                                <RouterLink
                                    :to="{
                                        name: 'market',
                                    }"
                                    class="site-nav__link link-primary-hover"
                                    @click.native="handleYandexMetrikaGoal('header_avtozapchast')"
                                >
                                    Запчасти и автотовары
                                    <UIcon
                                        name="arrow-right"
                                        secondary
                                        small
                                    ></UIcon>
                                </RouterLink>
                            </li>
                            <li class="site-nav__item">
                                <RouterLink
                                    :to="{
                                        name: 'insurance-osago',
                                    }"
                                    class="site-nav__link link-primary-hover"
                                    @click.native="handleYandexMetrikaGoal('header_osago')"
                                >
                                    Страхование ОСАГО
                                    <UIcon
                                        name="arrow-right"
                                        secondary
                                        small
                                    ></UIcon>
                                </RouterLink>
                            </li>
                            <li class="site-nav__item">
                                <RouterLink
                                    :to="{
                                        name: 'market-products-catalog'
                                    }"
                                    class="mobile-menu__link link-primary-hover"
                                >
                                    <UIcon
                                        name="list-search"
                                        big
                                        class="mobile-menu__link-icon"
                                    ></UIcon>

                                    Каталог товаров
                                </RouterLink>
                            </li>
                        </ul>
                    </div>

                    <div class="mobile-menu__section">
                        <button
                            class="mobile-menu__link link-primary-hover"
                            @click="showChangeCityPopup"
                        >
                            <UIcon
                                name="place-24"
                                big
                                class="mobile-menu__link-icon"
                            ></UIcon>
                            {{ currentCity.name }}
                        </button>
                    </div>

                    <div
                        v-if="isAuthorized"
                        class="mobile-menu__section"
                    >
                        <ul class="user-menu">
                            <li
                                v-for="item in userMenu"
                                :key="item.codename"
                                class="user-menu__item"
                            >
                                <RouterLink
                                    :to="{
                                        name: item.codename
                                    }"
                                    class="mobile-menu__link link-primary-hover"
                                >
                                    <UIcon
                                        :name="item.icon"
                                        big
                                        class="mobile-menu__link-icon"
                                    ></UIcon>

                                    {{ item.title }}

                                    <UCircleLabelCounter
                                        v-if="item.codename === 'account-orders-active' || item.codename === 'account-notifications'"
                                        :count="item.codename === 'account-orders-active' ? activeOrdersTotalCount : unreadNotificationsCount"
                                        class="mobile-menu__link-counter"
                                    ></UCircleLabelCounter>
                                </RouterLink>
                            </li>
                            <li class="user-menu__item">
                                <button
                                    class="mobile-menu__link link-primary-hover"
                                    @click="logout"
                                >
                                    <UIcon
                                        name="logout"
                                        big
                                        class="mobile-menu__link-icon"
                                    ></UIcon>

                                    Выйти
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </transition>

        <Catalog
            :hideBackdrop="isMobileMenuOpen"
            :isSticky="isSearchSectionSticky"
            @close="catalogIsOpen = false"
        ></Catalog>
    </header>
</template>

<script>
// services
import bus from '@/bus.js';
import socket from '@/services/web-socket';
// utils
import { mapState, mapActions } from 'vuex';
// mixins
import breakpointKey from '@/mixins/breakpointKey.js';
import YandexMetrika from '@/mixins/yandex-metrika.js';
// components
import UbibiLogo from '@/components/UbibiLogo.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import UCircleLabelCounter from '@ui/components/UBadge/UBadge.vue';
import CartPreview from './CartPreview.vue';
import OrdersPreview from '@/components/header/OrdersPreview.vue';
import FavoritePreview from '@/components/header/FavoritePreview.vue';
import NotificationsPreview from '@/components/header/NotificationsPreview.vue';
import UserBar from '@/components/header/UserBar.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import SearchWidget from '@/components/searchWidget/SearchWidget.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import RoundArrowBtn from '@/components/project-buttons/RoundArrowBtn.vue';
import Catalog from '@/components/catalog/Catalog.vue';
import Observer from '@/components/Observer.vue';
const ChangeCityPopup = () => import('@/components/popups/ChangeCityPopup.vue');
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');


export default {
    name: 'DefaultLayoutHeader',

    components: {
        Observer,
        Catalog,
        RoundArrowBtn,
        UserAvatar,
        SearchWidget,
        ButtonBlock,
        UserBar,
        UbibiLogo,
        NotificationsPreview,
        FavoritePreview,
        OrdersPreview,
        UCircleLabelCounter,
        ButtonIcon,
        UIcon,
        CartPreview,
    },

    mixins: [breakpointKey, YandexMetrika],

    data() {
        return {
            userMenu: [
                {
                    icon: 'delivery',
                    title: 'Заказы',
                    codename: 'account-orders-active',
                },
                {
                    icon: 'heart-stroke',
                    title: 'Избранное',
                    codename: 'account-favorites',
                },
                {
                    icon: 'notification',
                    title: 'Уведомления',
                    codename: 'account-notifications',
                },
                {
                    icon: 'car',
                    title: 'Гараж',
                    codename: 'account-garage',
                },
                {
                    icon: 'document',
                    title: 'Страховые полисы',
                    codename: 'account-insurance-policies',
                },
                {
                    icon: 'star-stroke',
                    title: 'Отзывы',
                    codename: 'account-reviews',
                },
                {
                    icon: 'settings',
                    title: 'Настройки профиля',
                    codename: 'account-settings',
                },
            ],
            staticPagesMenu: [
                {
                    to: { name: 'payment' },
                    title: 'Оплата',
                },
                {
                    to: { name: 'delivery-and-pickup' },
                    title: 'Доставка и самовывоз',
                },
                {
                    to: { name: 'return-and-refund' },
                    title: 'Возврат',
                },
                {
                    to: { name: 'about' },
                    title: 'О сервисе',
                },
                {
                    to: { name: 'help-mp' },
                    title: 'Помощь',
                },
            ],
            staticTopCategories: [
                {
                    to: { name: 'market-parts-catalog' },
                    title: 'Запчасти',
                },
                {
                    to: {
                        name: 'market-products-category',
                        params: {
                            slug: 'shinyi',
                        },
                        query: '',
                    },
                    title: 'Шины',
                },
                {
                    to: {
                        name: 'market-products-category',
                        params: {
                            slug: 'diski',
                        },
                        query: '',
                    },
                    title: 'Диски',
                },
                {
                    to: { name: 'market-maintenance' },
                    title: 'Товары для ТО',
                },
            ],
            isMobileMenuOpen: false,
            unreadNotificationsCount: 0,
            topCategoriesScrollInterval: null,
            canTopCategoriesScrollLeft: false,
            canTopCategoriesScrollRight: false,
            catalogIsOpen: false,
            showStickyHeader: false,
        };
    },

    computed: {
        ...mapState({
            cartTotalCount: state => state.cart.totalCount,
            activeOrdersTotalCount: state => state.profileOrders.activeOrders.current_count,
            profile: state => state.profile.profile,
            isAuthorized: state => state.profile.isAuthorized,
            currentCity: state => state.cities.currentCity,
            productsCategories: state => state.market.meta.product_categories,
        }),

        place() {
            return this.currentCity.full_name
                + (this.currentCity.region ? ', ' + this.currentCity.region : '')
                + (this.currentCity.district ? ', ' + this.currentCity.district : '');
        },

        showSearchSection() {
            const routes = [
                'main',
                'cart',
                'insurance',
                'about',
                'help-mp',
                'help-mp-article',
                'help-pp',
                'help-pp-article',
                'contacts',
                'news',
                'news-item',
                'publications',
            ];
            return !routes.includes(this.$route.name)
                && !(this.$route.path.includes('insurance/osago'))
                && (this.$route.name && !(this.$route.name.includes('Error4xxPage')))
                && !(this.$route.path.includes('account'));
        },

        showTopCategories() {
            const routes = [
                'market',
                'market-parts-catalog',
            ];
            return routes.includes(this.$route.name);
        },

        topCategories() {
            let categories = [...this.staticTopCategories];
            if (this.productsCategories && this.productsCategories.length) {
                this.productsCategories.forEach(item => {
                    const category = {
                        to: {
                            name: 'market-products-category',
                            params: {
                                slug: item.slug,
                            },
                        },
                        title: item.name,
                    };
                    categories.splice(-1, 0, category);
                });
            }
            return categories;
        },

        isSearchSectionSticky() {
            return this.showStickyHeader && (this.breakpointKey === 'l' || this.breakpointKey === 'xl');
        },
    },

    watch: {
        '$route.name'() {
            this.hideMobileMenu();
        },

        breakpointKey() {
            this.checkTopCategoriesScroll();
        },
    },

    mounted() {
        socket.onopen(() => {
            socket.on('notifications.count.message', this.onChangeUnreadNotificationsCount);
        });

        this.fetchUnreadCount();
        this.checkTopCategoriesScroll();
    },

    beforeDestroy() {
        clearInterval(this.topCategoriesScrollInterval);
        this.canTopCategoriesScrollRight = false;
        this.canTopCategoriesScrollLeft = false;
        socket.off('notifications.count.message', this.onChangeUnreadNotificationsCount);
    },

    methods: {
        ...mapActions({
            fetchActiveOrdersCount: 'profileOrders/fetchActiveOrdersCount',
        }),

        closeCatalog() {
            this.catalogIsOpen = false;
            bus.$emit('closeCatalog');
        },

        toggleCatalog() {
            this.catalogIsOpen = !this.catalogIsOpen;
            bus.$emit('toggleCatalog');
        },

        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
            const bodyEl = document.getElementsByTagName('body')[0];
            bodyEl.style.overflow = this.isMobileMenuOpen ? 'hidden' : null;
        },

        hideMobileMenu() {
            this.isMobileMenuOpen = false;
            const bodyEl = document.getElementsByTagName('body')[0];
            bodyEl.style.overflow = null;
        },

        logout() {
            this.$store.dispatch('profile/logout')
                .then(() => {
                    this.$store.dispatch('cart/getCart');
                    if (this.$route.name.includes('account-')) {
                        this.$router.push({ name: 'signin' });
                    }
                    else {
                        document.location.reload();
                    }
                });
        },

        showChangeCityPopup() {
            this.$popup(ChangeCityPopup);
        },

        onChangeUnreadNotificationsCount(data) {
            if (data && data.message_amount !== undefined) {
                this.unreadNotificationsCount = data.message_amount;
            }
        },

        async fetchUnreadCount() {
            try {
                const limit = 0;
                const is_read = false;
                const params = { limit, is_read };
                const config = { params };
                const { current_count } = await this.$api.notifications.get(config);
                this.unreadNotificationsCount = current_count;
            }
            catch (error) {
                // handleInitHttpError(error);
            }
        },

        async fetchOrders() {
            await this.fetchActiveOrdersCount();
        },

        checkTopCategoriesScroll() {
            if (this.$refs.topCategoriesList) {
                let blockWidth = this.$refs.topCategoriesList.offsetWidth;
                let scrollWidth = this.$refs.topCategoriesList.scrollWidth;
                let scrollValue = this.$refs.topCategoriesList.scrollLeft;

                this.canTopCategoriesScrollRight = scrollValue < scrollWidth - blockWidth;
                this.canTopCategoriesScrollLeft = scrollValue > 0;
            }
        },

        startTopCategoriesScroll(direction) {
            let blockWidth = this.$refs.topCategoriesList.offsetWidth;
            let scrollWidth = this.$refs.topCategoriesList.scrollWidth;
            let scrollValue = this.$refs.topCategoriesList.scrollLeft;

            this.topCategoriesScrollInterval = setInterval(() => {
                if (direction === 'left') {
                    if (scrollValue > 0) {
                        scrollValue -= 1;
                    }
                    else {
                        clearInterval(this.topCategoriesScrollInterval);
                    }
                }
                else {
                    if (scrollValue < scrollWidth - blockWidth) {
                        scrollValue += 1;
                    }
                    else {
                        clearInterval(this.topCategoriesScrollInterval);
                    }
                }

                this.$refs.topCategoriesList.scrollLeft = scrollValue;
            }, 1);
        },

        stopTopCategoriesScroll() {
            clearInterval(this.topCategoriesScrollInterval);
        },

        intersected(value) {
            this.showStickyHeader = value !== 'in';
            if (this.$refs.headerStickySection) {
                const previousSibling = this.$refs.headerStickySection.previousElementSibling;
                if (previousSibling) {
                    if (this.showStickyHeader) {
                        document.documentElement.style.setProperty('--sticky-sections-holder__section-top', previousSibling.offsetTop + previousSibling.offsetHeight + 'px');
                    }
                    else {
                        document.documentElement.style.setProperty('--sticky-sections-holder__section-top', '-' + this.$refs.headerStickySection.offsetHeight + 'px');
                    }
                }
                else {
                    if (this.showStickyHeader) {
                        document.documentElement.style.setProperty('--sticky-sections-holder__section-top', '0px');
                    }
                    else {
                        document.documentElement.style.setProperty('--sticky-sections-holder__section-top', '-' + this.$refs.headerStickySection.offsetHeight + 'px');
                    }
                }
            }
        },

        openSignInPopup() {
            this.$popup(SignInPopup);
        },
    },
};
</script>

<style>
:root {
    --sticky-sections-holder__section-top: -100%
}
</style>
<style scoped>
.header {
    /*position: relative;*/
    z-index: 9;
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
}

@media (max-width: 767px) {
    .header_desktop {
        display: none;
    }
}

.header__city-static-pages-nav-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--grey-darkest-color);
}
.header__city-static-pages-nav-wrap::after {
    content: "";
    position: absolute;
    width: 100vw;
    left: var(--content-gap-negative);
    bottom: 0;
    border-bottom: 1px solid var(--border-light-c);
}

.city {
    display: flex;
    align-items: center;
}
.city__value {
    margin-left: 8px;
}
@media (min-width: 768px) {
    .city__value {
        font-size: var(--small-fz);
    }
}

.static-pages-nav__list {
    display: flex;
    align-items: center;
    font-size: var(--small-fz);
}

.static-pages-nav__link {
    display: block;
    padding-left: 12px;
    padding-right: 12px;
}
.static-pages-nav__item:first-child .static-pages-nav__link {
    padding-left: 0;
}
.static-pages-nav__item:last-child .static-pages-nav__link {
    padding-right: 0;
}

.header__main {
    display: flex;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

@media (min-width: 1281px) {
    .header__ubibi-logo {
        margin-right: 48px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .header__ubibi-logo {
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .header__ubibi-logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 1039px) {
    .desktop-menu {
        display: none;
    }
}

@media (min-width: 1040px) {
    .site-nav__list {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 1281px) {
    .site-nav__item:not(:last-child) {
        margin-right: 24px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .site-nav__item:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 1039px) {
    .site-nav__item:not(:last-child) {
        margin-bottom: 8px;
    }
}

.site-nav__link {
    position: relative;
    display: block;
    font-family: var(--f-bold);
}
@media (max-width: 1039px) {
    .site-nav__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        background-color: var(--lightest-bg);
        border-radius: var(--border-radius);
    }
}

.medium-menu-toggler {
    display: none;
    margin-right: 24px;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .medium-menu-toggler {
        display: block;
    }
}

.all-user-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.auth-user-actions {
    display: flex;
    align-items: center;
    border-left: 1px solid var(--border-light-c);
}
@media (min-width: 1281px) {
    .auth-user-actions {
        margin-left: 12px;
        padding-left: 12px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .auth-user-actions {
        margin-left: 8px;
        padding-left: 8px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .auth-user-actions {
        margin-left: 12px;
        padding-left: 12px;
    }
}

.user-bar {
    margin-right: -8px;
}

@media (min-width: 1281px) {
    .login-btn {
        margin-left: 24px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .login-btn {
        margin-left: 20px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .login-btn {
        margin-left: 24px;
    }
}

.cart-btn {
    position: relative;
}
.cart-btn__icon {
    display: block;
}
.cart-btn__counter {
    position: absolute;
    top: -7px;
    right: -7px;
}


@media (min-width: 768px) {
    .header__catalog-wrap {
        padding-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .header__catalog-wrap {
        position: relative;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .header__catalog-wrap::before {
        content: "";
        position: absolute;
        width: 100vw;
        top: 0;
        left: var(--content-gap-negative);
        border-top: 1px solid var(--border-light-c);
    }
}

@media (min-width: 768px) {
    .catalog-search {
        display: flex;
        align-items: center;
    }
}

.catalog-btn {
    margin-right: 20px;
}
@media (max-width: 1039px) {
    .catalog-btn {
        display: none;
    }
}

.top-categories {
    margin-top: 16px;
}
@media (max-width: 1039px) {
    .top-categories {
        display: none;
    }
}
.top-categories-block {
    position: relative;
}

.top-categories_outer {
    overflow: hidden;
}

.top-categories__arrow_prev-wrap,
.top-categories__arrow_next-wrap {
    position: absolute;
    top: 50%;
    z-index: 2;
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
}

.top-categories__arrow_prev-wrap {
    left: 0;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.top-categories__arrow_next-wrap {
    justify-content: flex-end;
    right: 0;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.top-categories__arrow {
    position: relative;
    background-color: var(--light-c);
}

.top-categories__arrow_prev {
    left: 0;
}

.top-categories__arrow_next {
    right: 0;
}

.top-categories__list {
    display: flex;
    align-items: center;
    margin-bottom: -40px;
    padding-bottom: 40px;
    font-size: var(--base-fz);
    font-family: var(--f-semi-bold);
    overflow: auto;
}

@media (min-width: 1040px) {
    .top-categories__link {
        padding-right: 12px;
        padding-left: 12px;
    }

    .top-categories__item {
        flex-shrink: 0;
    }

    .top-categories__item:first-child .top-categories__link {
        padding-left: 0;
    }
    .top-categories__item:last-child .top-categories__link {
        padding-right: 0;
    }
}

.header_mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 18px;
    padding-bottom: 18px;
}
@media (min-width: 768px) {
    .header_mobile {
        display: none;
    }
}

.mobile-menu-wrap-enter,
.mobile-menu-wrap-leave-to {
    transition: all var(--transition);
}
.mobile-menu-wrap-enter .mobile-menu,
.mobile-menu-wrap-leave-to .mobile-menu {
    transform: translateX(-100%);
}
.mobile-menu-wrap-enter .mobile-menu__backdrop,
.mobile-menu-wrap-leave-to .mobile-menu__backdrop {
    opacity: 0;
}

@media (min-width: 1040px) {
    .mobile-menu-wrap {
        display: none;
    }
}
@media (max-width: 1039px) {
    .mobile-menu-wrap {
        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--menu-z-index);
        width: 100%;
        height: 100%;
    }
}

.mobile-menu {
    position: relative;
    z-index: 2;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--light-c);
    border-top: 1px solid var(--border-light-c);
    overflow: auto;
    transition: transform var(--transition);
}
@media (min-width: 768px) {
    .mobile-menu {
        width: 420px;
    }
}
@media (max-width: 767px) {
    .mobile-menu {
        width: calc(var(--col-5) + var(--content-gap));
    }
}

.mobile-menu__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity var(--transition);
}

.mobile-menu__section {
    padding-left: 20px;
    padding-right: 20px;
}
.mobile-menu__section:not(:first-child) {
    padding-top: 8px;
}
.mobile-menu__section:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--border-light-c);
}

.mobile-menu__link {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: var(--base-fz);
    font-family: var(--f-semi-bold);
}

.mobile-menu__link-icon {
    margin-right: 12px;
}

.mobile-menu__link-counter {
    margin-left: auto;
}

.registration-banner {
    position: relative;
    margin-bottom: 20px;
    padding: 16px;
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius-x2);
    overflow: hidden;
    cursor: pointer;
    outline: none;
    user-select: none;
}
.registration-banner::after {
    content: "";
    width: 94px;
    height: 72px;
    position: absolute;
    right: -16px;
    bottom: -12px;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    background-image: url(../../assets/images/mobile-menu-auth-banner.png);
}

.header__sticky {
    position: absolute;
    left: 0;
    z-index: var(--z-index-sticky-header);
    width: 100%;
    padding: 12px 0;
    background-color: var(--light-c);
    will-change: transform;
    transform: translateY(var(--sticky-sections-holder__section-top));
    transition: top var(--transition), transform var(--transition);
}
@media (max-width: 1039px) {
    .header__sticky {
        display: none !important;
    }
}

.header-sticky-container {
    display: flex;
    align-items: center;
}

.header__sticky .all-user-actions {
    margin-left: 20px;
}
</style>