<template>
    <div
        id="app"
        class="app"
    >
        <Notification></Notification>

        <Error4xxPage
            v-if="httpErrorCode === 403 || httpErrorCode === 404 || httpErrorCode === 429"
            :error="httpErrorCode"
        ></Error4xxPage>
        <Error5xxPage
            v-else-if="httpErrorCode === 500 || httpErrorCode === 502 || httpErrorCode === 504"
            :error="httpErrorCode"
            :sentryErrorHash="sentryErrorHash"
        ></Error5xxPage>
        <RouterView v-else></RouterView>

        <PopupManager @overflow="onOverflow"></PopupManager>

        <ScrollTopBtn></ScrollTopBtn>

        <AreYouHerePopup></AreYouHerePopup>
        <StickySectionsHolder></StickySectionsHolder>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import PopupManager from './components/popups/PopupManager.vue';
import Notification from './components/Notification.vue';
import ScrollTopBtn from './components/ScrollTopBtn.vue';
import Error4xxPage from './pages/Error4xxPage.vue';
import Error5xxPage from './pages/Error5xxPage.vue';
import StickySectionsHolder from '@/components/StickySectionsHolder.vue';
const AreYouHerePopup = () => import('@/components/popups/AreYouHerePopup.vue');


export default {
    name: 'App',

    metaInfo() {
        return {
            link:[
                { vmid: 'favicon', rel: 'apple-touch-icon', href: require('./assets/images/favicons/120.png'), sizes: '120x120' },
                { vmid: 'favicon', rel: 'apple-touch-icon', href: require('./assets/images/favicons/152.png'), sizes: '152x152' },
                { vmid: 'favicon', rel: 'apple-touch-icon', href: require('./assets/images/favicons/180.png'), sizes: '180x180' },
                { vmid: 'favicon', rel: 'icon', href: require('./assets/images/favicons/32.png'), sizes: '32x32' },
                { vmid: 'favicon', rel: 'icon', href: require('./assets/images/favicons/57.png'), sizes: '57x57' },
                { vmid: 'favicon', rel: 'icon', href: require('./assets/images/favicons/76.png'), sizes: '76x76' },
                { vmid: 'favicon', rel: 'icon', href: require('./assets/images/favicons/128.png'), sizes: '128x128' },
                { vmid: 'favicon', rel: 'icon', href: require('./assets/images/favicons/192.png'), sizes: '192x192' },
            ],
            meta: [{
                once: true,
                vmid: 'yandex-tableu-widget',
                name: 'yandex-tableau-widget',
                content: `logo=${ this.$domain.url }${ require('./assets/images/favicons/tableau-logo.png') }, color=#FFFFFF`,
            }, ...(this.settings.additional_meta_tags || [])],
        };
    },

    components: {
        StickySectionsHolder,
        AreYouHerePopup,
        Error4xxPage,
        Error5xxPage,
        ScrollTopBtn,
        PopupManager,
        Notification,
    },

    computed: {
        ...mapState({
            httpErrorCode: state => state.httpErrorCode,
            sentryErrorHash: state => state.sentryErrorHash,
            currentCity: state => state.cities.currentCity,
            currentCityId: state => state.cities.currentCityId,
            settings: state => state.settings,
            reloadTrigger: state => state.reloadPageService.trigger,
        }),
    },

    watch: {
        currentCityId: {
            handler() {
                this.$router.go();
            },
        },

        httpErrorCode: {
            async handler(value) {
                if (value === 401) {
                    this.$store.dispatch('profile/logout')
                        .then(async () => {
                            if (this.$route.name.includes('account-')) {
                                await this.$router.push({ name: 'signin' });
                                this.$store.commit('clearHttpError');
                            }
                        });
                }
                else if (value === 503) {
                    if (this.$route.name !== 'maintenance-mode') {
                        await this.$router.push({ name: 'maintenance-mode' });
                    }
                }
            },
        },

        reloadTrigger: {
            handler(value) {
                if (value) {
                    this.$router.go();
                }
            },
        },
    },

    mounted() {
        if (this.httpErrorCode === 503 && this.$route.name !== 'maintenance-mode') {
            this.$router.push({ name: 'maintenance-mode' });
        }
    },

    methods: {
        onOverflow(value) {
            const htmlEl = document.getElementsByTagName('html')[0];
            const bodyEl = document.getElementsByTagName('body')[0];
            htmlEl.style.overflow = value;
            bodyEl.style.overflow = value;
        },
    },
};
</script>