var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-gap_medium"},[(!_vm.initializedData)?_c('Spinner',{attrs:{"page":"","size":"big"}}):(_vm.totalAnaloguePartsAmount || _vm.totalOriginalSubstitutesPartsAmount)?[(_vm.initializedFilter)?_c('MarketItemListUpper',{staticClass:"mb-0",attrs:{"checkedFilters":_vm.checkedFilters},on:{"resetFilters":_vm.onResetFilters,"deleteFilter":_vm.onDeleteFilter},scopedSlots:_vm._u([{key:"sort",fn:function(){return [_c('SimpleSelectBase',{attrs:{"value":_vm.sortData,"options":_vm.sortOptions},on:{"change":_vm.onChangeSort}})]},proxy:true},{key:"view",fn:function(){return [_c('RadioButtonsGroup',{attrs:{"name":"catalog-view","type":"icon","options":[
                        {
                            label: '',
                            value: 'list',
                            icon: 'list-toggler',
                        },
                        {
                            label: '',
                            value: 'grid',
                            icon: 'grid',
                        } ]},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}})]},proxy:true},{key:"filterToggler",fn:function(){return [_c('ButtonIcon',{staticClass:"filter-toggler-btn",attrs:{"icon":{
                        name: 'filter-btn',
                    },"hoverPrimary":"","aria-label":"Показать фильтр"},on:{"click":_vm.openFilter}})]},proxy:true}],null,false,1289239949)}):_vm._e(),_vm._v(" "),(_vm.initializedData)?_c('MarketItemListContent',{staticClass:"market-parts-search__content",class:{
                'market-parts-search__content_not-empty': _vm.analogueParts.current_count || _vm.analoguePartsIsPart.current_count || _vm.analoguePartsInclude.current_count || _vm.originalSubstitutesParts.current_count,
            },scopedSlots:_vm._u([{key:"main",fn:function(){return [((_vm.totalAnaloguePartsAmount && !_vm.analogueParts.current_count && !_vm.analoguePartsIsPart.current_count && !_vm.analoguePartsInclude.current_count) || (_vm.totalOriginalSubstitutesPartsAmount && !_vm.originalSubstitutesParts.current_count))?_c('section',{staticClass:"filter-empty-block"},[(_vm.originalSubstitutesPartsLoading || _vm.analoguePartsLoading)?_c('Spinner',{attrs:{"center":"","size":"big"}}):[(_vm.checkedFilters.length)?_c('EmptyBlock',{attrs:{"icon":"filter","title":"Нет подходящих предложений","text":"Попробуйте изменить условия поиска.","hasBtn":false,"hasResetFilters":""},on:{"resetFilters":_vm.onResetFilters}}):_c('EmptyBlock',{attrs:{"icon":"parts","title":"Нет предложений","btnText":"Перейти в каталог","btnUrl":{
                                name: 'market-parts-catalog',
                            }},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("\n                                Для запчасти "),_c('b',[_vm._v("«"+_vm._s(_vm.newPart.name)+"»")]),_vm._v(" нет актуальных предложений.\n                            ")]},proxy:true}],null,false,3296849985)})]],2):[(_vm.originalSubstitutesParts.current_count)?_c('section',{staticClass:"result-section"},[_c('div',{staticClass:"title-wrap"},[_c('h2',{staticClass:"h2"},[_vm._v("\n                                Оригинальные "),_c('span',{staticClass:"d-inline-block"},[_vm._v("\n                                    заменители"),_c('UTooltip',{attrs:{"maxWidth":"240"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                            var value = ref.value;
                            var attrs = ref.attrs;
                            var handlers = ref.handlers;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"title__hint",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}}],null,false,2791898329)},[_vm._v("\n\n                                        Предложения магазинов по оригинальным заменителям запчасти\n                                        "+_vm._s(_vm.newPart.sku)+" от "+_vm._s(_vm.newPart.brand.name)+".\n                                    ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"result-section__content",class:{
                                'result-section__content_loading': _vm.originalSubstitutesPartsLoading,
                            }},[(_vm.originalSubstitutesPartsLoading)?_c('div',{staticClass:"result-section__spinner-wrap"},[_c('Spinner',{staticClass:"result-section__spinner",attrs:{"center":"","size":"big"}})],1):_vm._e(),_vm._v(" "),(_vm.originalSubstitutesParts.current_count)?_c('ol',{class:'items-' + _vm.view},_vm._l((_vm.originalSubstitutesParts.items),function(originalSubstitutesPartItem,originalSubstitutesPartItemIndex){return _c('li',{key:'item-' + originalSubstitutesPartItem.id,staticClass:"item",class:{
                                        'item_view-list': _vm.view === 'list',
                                        'item_view-grid-list': _vm.view === 'grid',
                                    }},[(_vm.view === 'list')?_c('MarketItemRowListItem',{attrs:{"item":originalSubstitutesPartItem,"modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                    var i = arguments.length, argsArray = Array(i);
                                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.changePartsFavorite.apply(void 0, [ originalSubstitutesPartItemIndex ].concat( argsArray, ['substitutes'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(originalSubstitutesPartItem)},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(originalSubstitutesPartItem)}}}):_vm._e(),_vm._v(" "),(_vm.view === 'grid')?_c('MarketItemColumn',{attrs:{"item":originalSubstitutesPartItem,"apiName":"parts","modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                    var i = arguments.length, argsArray = Array(i);
                                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.changePartsFavorite.apply(void 0, [ originalSubstitutesPartItemIndex ].concat( argsArray, ['substitutes'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(originalSubstitutesPartItem)},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(originalSubstitutesPartItem)}}}):_vm._e()],1)}),0):_vm._e(),_vm._v(" "),(_vm.originalSubstitutesPartsHasNext)?_c('ButtonText',{staticClass:"load-more-btn",attrs:{"dashed":"","dark":"","secondary":"","loading":_vm.originalSubstitutesPartsAddLoading,"center":""},on:{"click":function($event){return _vm.load('substitutes')}}},[_vm._v("\n                                Показать ещё\n                            ")]):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.analoguePartsLoading && _vm.analogueParts.current_count === 0 && _vm.analoguePartsIsPart.current_count === 0 && _vm.analoguePartsInclude.current_count === 0)?_c('Spinner',{staticClass:"result-section__spinner",attrs:{"center":"","size":"big"}}):_vm._e(),_vm._v(" "),(_vm.analogueParts.current_count)?_c('section',{staticClass:"result-section"},[_c('div',{staticClass:"title-wrap"},[_c('h2',{staticClass:"h2"},[_c('span',{staticClass:"mr-8"},[_vm._v("Совместимые аналоги")]),_c('UTooltip',{attrs:{"maxWidth":"240"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                                    var value = ref.value;
                                    var attrs = ref.attrs;
                                    var handlers = ref.handlers;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"title__hint",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}}],null,false,2791898329)},[_vm._v("\n\n                                    Предложения магазинов по запчастям от сторонних производителей (аналогам),\n                                    совместимым с запчастью "+_vm._s(_vm.newPart.sku)+" от "+_vm._s(_vm.newPart.brand.name)+".\n                                ")])],1)]),_vm._v(" "),_c('div',{staticClass:"result-section__content",class:{
                                'result-section__content_loading': _vm.analoguePartsLoading,
                            }},[(_vm.analoguePartsLoading)?_c('div',{staticClass:"result-section__spinner-wrap"},[_c('Spinner',{staticClass:"result-section__spinner",attrs:{"center":"","size":"big"}})],1):_vm._e(),_vm._v(" "),_c('ol',{class:'items-' + _vm.view},_vm._l((_vm.analogueParts.items),function(analoguePart,analoguePartIndex){return _c('li',{key:'item-' + analoguePart.id,staticClass:"item",class:{
                                        'item_view-list': _vm.view === 'list',
                                        'item_view-grid-list': _vm.view === 'grid',
                                    }},[(_vm.view === 'list')?_c('MarketItemRowListItem',{attrs:{"item":analoguePart,"modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                    var i = arguments.length, argsArray = Array(i);
                                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.changePartsFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['equal'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(analoguePart)},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e(),_vm._v(" "),(_vm.view === 'grid')?_c('MarketItemColumn',{attrs:{"item":analoguePart,"apiName":"parts","modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                    var i = arguments.length, argsArray = Array(i);
                                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.changePartsFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['equal'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(analoguePart)},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e()],1)}),0),_vm._v(" "),(_vm.analoguePartsHasNext)?_c('ButtonText',{staticClass:"load-more-btn",attrs:{"dashed":"","dark":"","secondary":"","loading":_vm.analoguePartsAddLoading,"center":""},on:{"click":function($event){return _vm.load('equal')}}},[_vm._v("\n                                Показать ещё\n                            ")]):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.analoguePartsIsPart.current_count)?_c('section',{staticClass:"result-section"},[_c('div',{staticClass:"title-wrap"},[_c('h2',{staticClass:"h2"},[_vm._v("\n                                Входят в состав искомой запчасти"),_c('UTooltip',{attrs:{"maxWidth":"240"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                                    var value = ref.value;
                                    var attrs = ref.attrs;
                                    var handlers = ref.handlers;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"title__hint",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}}],null,false,2791898329)},[_vm._v("\n\n                                    Предложения магазинов по запчастям от сторонних производителей (аналогам),\n                                    совместимым с запчастью "+_vm._s(_vm.newPart.sku)+" от "+_vm._s(_vm.newPart.brand.name)+",\n                                    которые входят в состав оригинальной запчасти.\n                                ")])],1)]),_vm._v(" "),_c('div',{staticClass:"result-section__content",class:{
                                'result-section__content_loading': _vm.analoguePartsLoading,
                            }},[(_vm.analoguePartsLoading)?_c('div',{staticClass:"result-section__spinner-wrap"},[_c('Spinner',{staticClass:"result-section__spinner",attrs:{"center":"","size":"big"}})],1):_vm._e(),_vm._v(" "),_c('ol',{class:'items-' + _vm.view},_vm._l((_vm.analoguePartsIsPart.items),function(analoguePart,analoguePartIndex){return _c('li',{key:'item-' + analoguePart.id,staticClass:"item",class:{
                                        'item_view-list': _vm.view === 'list',
                                        'item_view-grid-list': _vm.view === 'grid',
                                    }},[(_vm.view === 'list')?_c('MarketItemRowListItem',{attrs:{"item":analoguePart,"modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                    var i = arguments.length, argsArray = Array(i);
                                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.changePartsFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['is_part'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(analoguePart)},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e(),_vm._v(" "),(_vm.view === 'grid')?_c('MarketItemColumn',{attrs:{"item":analoguePart,"apiName":"parts","modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                    var i = arguments.length, argsArray = Array(i);
                                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.changePartsFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['is_part'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(analoguePart)},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e()],1)}),0),_vm._v(" "),(_vm.analoguePartsIsPartHasNext)?_c('ButtonText',{staticClass:"load-more-btn",attrs:{"dashed":"","dark":"","secondary":"","loading":_vm.analoguePartsIsPartAddLoading,"center":""},on:{"click":function($event){return _vm.load('is_part')}}},[_vm._v("\n                                Показать ещё\n                            ")]):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.analoguePartsInclude.current_count)?_c('section',{staticClass:"result-section"},[_c('div',{staticClass:"title-wrap"},[_c('h2',{staticClass:"h2"},[_vm._v("\n                                Включают искомую запчасть"),_c('UTooltip',{attrs:{"maxWidth":"240"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                                    var value = ref.value;
                                    var attrs = ref.attrs;
                                    var handlers = ref.handlers;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"title__hint",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}}],null,false,2791898329)},[_vm._v("\n\n                                    Предложения магазинов по запчастям от сторонних производителей (аналогам),\n                                    совместимым с запчастью "+_vm._s(_vm.newPart.sku)+" от "+_vm._s(_vm.newPart.brand.name)+",\n                                    которые включают в себя оригинальную запчасть.\n                                ")])],1)]),_vm._v(" "),_c('div',{staticClass:"result-section__content",class:{
                                'result-section__content_loading': _vm.analoguePartsLoading,
                            }},[(_vm.analoguePartsLoading)?_c('div',{staticClass:"result-section__spinner-wrap"},[_c('Spinner',{staticClass:"result-section__spinner",attrs:{"center":"","size":"big"}})],1):_vm._e(),_vm._v(" "),_c('ol',{class:'items-' + _vm.view},_vm._l((_vm.analoguePartsInclude.items),function(analoguePart,analoguePartIndex){return _c('li',{key:'item-' + analoguePart.id,staticClass:"item",class:{
                                        'item_view-list': _vm.view === 'list',
                                        'item_view-grid-list': _vm.view === 'grid',
                                    }},[(_vm.view === 'list')?_c('MarketItemRowListItem',{attrs:{"item":analoguePart,"modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                    var i = arguments.length, argsArray = Array(i);
                                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.changePartsFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['include'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(analoguePart)},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e(),_vm._v(" "),(_vm.view === 'grid')?_c('MarketItemColumn',{attrs:{"item":analoguePart,"apiName":"parts","modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                    var i = arguments.length, argsArray = Array(i);
                                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.changePartsFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['include'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(analoguePart)},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e()],1)}),0),_vm._v(" "),(_vm.analoguePartsIncludeHasNext)?_c('ButtonText',{staticClass:"load-more-btn",attrs:{"dashed":"","dark":"","secondary":"","loading":_vm.analoguePartsIncludeAddLoading,"center":""},on:{"click":function($event){return _vm.load('include')}}},[_vm._v("\n                                Показать ещё\n                            ")]):_vm._e()],1)]):_vm._e()]]},proxy:true},{key:"aside",fn:function(){return [_c('FilterComponent',{attrs:{"value":_vm.filterData,"filters":_vm.staticFilters},on:{"change":_vm.onChangeFilter,"reset":_vm.onResetFilters,"reload":_vm.reload}})]},proxy:true}],null,false,58858387)}):_vm._e()]:_c('EmptyBlock',{attrs:{"icon":"search","title":"Ничего не найдено"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("\n            Для запчасти "),_c('b',[_vm._v("«"+_vm._s(_vm.newPart.name)+"»")]),_vm._v(" нет аналогов.\n        ")]},proxy:true}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }