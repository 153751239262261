import Vue from 'vue';
import equals from '@ui/utils/equals.js';


export default () => {
    return {
        registerField(component) {
            const { name, innerErrors, initialValue } = component;

            if (this.innerValues[name] === undefined) {
                this.$set(this.innerValues, name, initialValue);
            }

            this.$set(this.fields, name, component);
            this.$set(this.innerErrors, name, innerErrors);
            this.$set(this.valueWatchers, name, this.createValueWatcher(component));
            this.$set(this.errorsWatchers, name, this.createErrorsWatchers(component));
        },

        unregisterField(component) {
            const { name, keepValue } = component;

            Vue.delete(this.fields, name);
            Vue.delete(this.dependencies, name);
            this.destroyValueWatcher(component);
            this.destroyErrorsWatcher(component);

            if (!keepValue) {
                Vue.delete(this.innerValues, name);
            }
        },

        createErrorsWatchers(component) {
            const { name, innerErrors } = component;

            if (!this.innerErrors) {
                const value = this.originalErrors[name] || innerErrors;
                this.$set(this.innerErrors, name, value);
            }

            return this.$watch('innerErrors.' + name, newVal => {
                component.originalErrors = newVal;
            }, { deep: true });
        },

        destroyErrorsWatcher(component) {
            const { name } = component;

            const watcher = this.errorsWatchers[name];

            if (watcher) {
                watcher();
                Vue.delete(this.errorsWatchers, name);
            }
        },

        createValueWatcher(component) {
            const { name, originalValue, originalValues } = component;

            return this.$watch('innerValues.' + name, newVal => {
                component.originalValue = newVal === undefined ? originalValue : newVal;
                component.originalValues = newVal === undefined ? originalValues : newVal;
            }, { deep: true, immediate: true });
        },

        destroyValueWatcher(component) {
            const { name } = component;

            const watcher = this.valueWatchers[name];

            if (watcher) {
                watcher();
                Vue.delete(this.valueWatchers, name);
            }
        },

        updateValue({ name, value }) {
            const newVal = value;
            const oldVal = this.innerValues[name];

            if (!equals(newVal, oldVal)) {
                this.innerValues[name] = newVal;
            }
        },

        ref(calleeComponent, caller) {
            if (this.fields[caller]) {
                if (this.dependencies[caller]) {
                    this.dependencies[caller] = [...this.dependencies[caller], calleeComponent];
                }
                else {
                    this.dependencies[caller] = [calleeComponent];
                }

                return this.fields[caller].innerValue;
            }
            else {
                return {};
            }
        },

        runRules() {
            const keys = Object.keys(this.fields);

            keys.forEach(key => {
                const field = this.fields[key];
                field.runRules();
            });
        },

        getInvalid() {
            const keys = Object.keys(this.fields);

            return keys.map(key => {
                const field = this.fields[key];
                return field.getInvalid();
            }).reduce((acc, cur) => acc ? acc : cur, false);
        },

        runRulesOnDependencies(name) {
            const dependencies = this.dependencies[name];

            if (dependencies) {
                dependencies.forEach(callee => {
                    const field = this.fields[callee];

                    if (field) {
                        field.runRules();
                    }
                });
            }
        },

        updateErrors({ name, errors }) {
            const newErrors = errors;
            const oldErrors = this.innerErrors[name];

            if (!equals(newErrors, oldErrors)) {
                if (!this.innerErrors[name]) {
                    this.$set(this.innerErrors, name, newErrors);
                }
                else {
                    this.innerErrors[name] = newErrors;
                }
            }
        },

        show() {
            const keys = Object.keys(this.fields);

            keys.forEach(key => {
                const field = this.fields[key];

                if (field.isField) {
                    field.showErrors = true;
                }
                else if (field.show) {
                    field.show();
                }
            });
        },

        setErrors(errors) {
            const keys = Object.keys(errors);

            keys.forEach(key => {
                const error = errors[key];
                const field = this.fields[key];
                if (field) field.setErrors(error);
            });
        },
    };
};