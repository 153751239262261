<template>
    <div class="insurance-osago-request">
        <h1 class="h1 h1_block insurance-osago__title">
            Калькулятор стоимости ОСАГО
        </h1>

        <div class="form-container container_xs-external layer-1">
            <StepBar
                class="step-bar"
                :step="step"
                :items="[
                    {
                        label: 'Автомобиль',
                        to: {
                            name: 'insurance-osago-request-step-1',
                            params: $route.params,
                            query: $route.query,
                        }
                    },
                    {
                        label: 'Срок действия полиса',
                        to: {
                            name: 'insurance-osago-request-step-2',
                            params: $route.params,
                            query: $route.query,
                        },
                        error: !!errors.action_start_date && !!errors.action_start_date.length,
                    },
                    {
                        label: 'Водители',
                        to: {
                            name: 'insurance-osago-request-step-3',
                            params: $route.params,
                            query: $route.query,
                        }
                    },
                    'Собственник',
                ]"
            ></StepBar>

            <Transition
                name="fade"
                mode="out-in"
            >
                <RouterView
                    :errors="errors"
                    @error="handleError"
                ></RouterView>
            </Transition>
        </div>

        <InsuranceFactoids></InsuranceFactoids>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import insuranceOsago from '@/store/modules/insuranceOsago.js';
import StepBar from '@/components/StepBar.vue';
import useCheckRouteMethods from '@/pages/insurance/osago/_uuid/useCheckRouteMethods.js';
import InsuranceFactoids from '@/components/UFactoids/presets/InsuranceFactoids.vue';


export default {
    name: 'InsuranceOsagoRequestPage',

    metaInfo() {
        return {
            title: 'Калькулятор стоимости ОСАГО',
        };
    },

    components: {
        InsuranceFactoids,
        StepBar,
    },

    serverPrefetch() {
        this.$store.registerModule('insuranceOsago', insuranceOsago);

        return this.serverPrefetch({ uuid: this.uuid });
    },

    data() {
        return {
            errors: {},
        };
    },

    computed: {
        ...mapState({
            contract: state => state.insuranceOsago.contract,
        }),

        uuid() {
            return this.$route.params.uuid;
        },

        step() {
            if (this.$route.name === 'insurance-osago-request-step-1') return 1;
            if (this.$route.name === 'insurance-osago-request-step-2') return 2;
            if (this.$route.name === 'insurance-osago-request-step-3') return 3;
            if (this.$route.name === 'insurance-osago-request-step-4') return 4;
            return undefined;
        },
    },

    watch: {
        '$route.params.uuid': {
            handler() {
                this.createBreadcrumbs();
            },
        },
    },

    beforeMount() {
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago, { preserveState: true });
        }
    },

    mounted() {
        this.init();
        this.createBreadcrumbs();
    },

    methods: {
        ...useCheckRouteMethods(),
        ...mapActions({
            serverPrefetch: 'insuranceOsago/serverPrefetch',
            browserPrefetch: 'insuranceOsago/browserPrefetch',
        }),

        async init() {
            await this.browserPrefetch({ uuid: this.uuid });

            await this.checkUuid();
        },

        createBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'insurance-osago',
                    },
                    title: 'Автострахование',
                },
                {
                    to: {
                        name: this.$route.name,
                        params: this.uuid ? {
                            uuid: this.uuid,
                        } : {},
                    },
                    title: 'Калькулятор стоимости ОСАГО',
                },
            ]);
        },

        handleError(errors) {
            this.errors = Object.assign({}, this.errors, errors);
        },
    },
};
</script>

<style scoped>
.insurance-osago-request {
    margin-top: var(--gap-y-medium);
}

@media (min-width: 768px) {
    .insurance-osago__title {
        text-align: center;
    }
}

@media (min-width: 1040px) {
    .form-container {
        width: 940px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) {
    .form-container {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .form-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .step-bar {
        margin-bottom: 36px;
    }
}
@media (max-width: 767px) {
    .step-bar {
        margin-bottom: 28px;
    }
}
</style>