<template>
    <div class="layer-1">
        <NavigationTabs
            class="navigation__btns-wrap"
            :currentIndex="mode === 'car' ? 0 : 1"
            noScroll
        >
            <button
                class="navigation__main-btn"
                :class="{
                    'active': mode === 'car'
                }"
                @click="mode = 'car'"
            >
                <span class="navigation__main-btn-text_short">По авто</span>
                <span class="navigation__main-btn-text_long">Подбор по авто</span>
            </button>
            <button
                class="navigation__main-btn"
                :class="{
                    'active': mode === 'params'
                }"
                @click="mode = 'params'"
            >
                <span class="navigation__main-btn-text_short">По параметрам</span>
                <span class="navigation__main-btn-text_long">Подбор по параметрам</span>
            </button>

            <ButtonText
                v-if="category === 'tire' && mode === 'params'"
                secondary
                dark
                class="navigation__info-btn"
                @click="showTireSelectionInfoPopup"
            >
                <span class="navigation__info-btn_text_short dashed">
                    О параметрах
                </span>
                <span class="navigation__info-btn_text_long dashed">
                    Подробнее о параметрах
                </span>
                <InfoIcon class="navigation__info-btn_icon"></InfoIcon>
            </ButtonText>

            <ButtonText
                v-if="category === 'rim' && mode === 'params'"
                secondary
                dark
                class="navigation__info-btn"
                @click="showRimSelectionInfoPopup"
            >
                <span class="navigation__info-btn_text_short dashed">
                    О параметрах
                </span>
                <span class="navigation__info-btn_text_long dashed">
                    Подробнее о параметрах
                </span>
                <InfoIcon class="navigation__info-btn_icon"></InfoIcon>
            </ButtonText>
        </NavigationTabs>

        <template v-if="category === 'tire'">
            <form
                v-show="mode === 'car'"
                class="selection-form"
            >
                <div class="car-selection-form">
                    <Selector
                        :value="carData.manufacturer.value"
                        label="Марка"
                        :options="carData.manufacturer.options"
                        optionLabel="name"
                        filterable
                        :emptyValue="{}"
                        :loading="carData.manufacturer.loading"
                        @change="onChangeCarSelect({ value: $event, codename: 'manufacturer' })"
                    ></Selector>

                    <Selector
                        :value="carData.model.value"
                        label="Модель"
                        :options="carData.model.options"
                        optionLabel="name"
                        filterable
                        :emptyValue="{}"
                        :disabled="!carData.manufacturer.value"
                        :loading="carData.model.loading"
                        @change="onChangeCarSelect({ value: $event, codename: 'model' })"
                    ></Selector>

                    <Selector
                        :value="carData.year.value"
                        label="Год выпуска"
                        :options="carData.year.options"
                        optionLabel="name"
                        filterable
                        :emptyValue="{}"
                        :disabled="!carData.manufacturer.value || !carData.model.value"
                        :loading="carData.year.loading"
                        @change="onChangeCarSelect({ value: $event, codename: 'year' })"
                    ></Selector>

                    <Selector
                        :value="carData.modification.value"
                        label="Комплектация"
                        :options="carData.modification.options"
                        optionLabel="name"
                        filterable
                        :emptyValue="{}"
                        :disabled="!carData.manufacturer.value || !carData.model.value || !carData.year.value"
                        :loading="carData.modification.loading"
                        @change="onChangeCarSelect({ value: $event, codename: 'modification' })"
                    ></Selector>
                </div>

                <div
                    v-if="carData.template_sizes.show"
                    class="template-sizes mt-20"
                >
                    <Spinner
                        v-if="carData.template_sizes.loading"
                        size="base"
                    ></Spinner>
                    <template v-else>
                        <p
                            v-if="!carData.template_sizes.options.length"
                            class="text_semi-bold"
                        >Подходящие размеры не найдены, воспользуйтесь поиском по параметрам</p>

                        <div
                            v-if="originalSizeTemplates.length"
                            class="template-sizes-block"
                        >
                            <span class="template-sizes__title text_bold">Оригинальные размеры:</span>
                            <div class="template-sizes__list">
                                <div
                                    v-for="item in originalSizeTemplates"
                                    class="template-sizes__item"
                                >
                                    <ButtonText
                                        :key="'template-' + item.front.tire"
                                        dashed
                                        secondary
                                        dark
                                        :prependIcon="item.rear ? {
                                            name: 'car-front'
                                        } : false"
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.front, 'tire')"
                                    >
                                        {{ item.front.tire }}
                                    </ButtonText>

                                    <span
                                        v-if="item.rear"
                                        class="ml-8 mr-8"
                                    >+</span>

                                    <ButtonText
                                        v-if="item.rear"
                                        :key="'template-' + item.rear.tire"
                                        dashed
                                        secondary
                                        dark
                                        :prependIcon="{
                                            name: 'car-rear'
                                        }"
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.rear, 'tire')"
                                    >
                                        {{ item.rear.tire }}
                                    </ButtonText>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="notOriginalSingleSizeTemplates.length"
                            class="template-sizes-block"
                        >
                            <span
                                v-if="originalSizeTemplates.length"
                                class="template-sizes__title text_bold"
                            >Также подойдут:</span>
                            <span
                                v-else
                                class="template-sizes__title text_bold"
                            >Подходящие размеры:</span>
                            <div class="template-sizes__list">
                                <div
                                    v-for="item in notOriginalSingleSizeTemplates"
                                    class="template-sizes__item"
                                >
                                    <ButtonText
                                        :key="'template-' + item.front.tire"
                                        dashed
                                        secondary
                                        dark
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.front, 'tire')"
                                    >
                                        {{ item.front.tire }}
                                    </ButtonText>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="notOriginalPairSizeTemplates.length"
                            class="template-sizes-block"
                        >
                            <span class="template-sizes__title text_bold">Разноширокие шины:</span>
                            <div class="template-sizes__list">
                                <div
                                    v-for="item in notOriginalPairSizeTemplates"
                                    class="template-sizes__item"
                                >
                                    <ButtonText
                                        :key="'template-' + item.front.tire"
                                        dashed
                                        secondary
                                        dark
                                        :prependIcon="item.rear ? {
                                            name: 'car-front'
                                        } : false"
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.front, 'tire')"
                                    >
                                        {{ item.front.tire }}
                                    </ButtonText>

                                    <span
                                        v-if="item.rear"
                                        class="ml-8 mr-8"
                                    >+</span>

                                    <ButtonText
                                        v-if="item.rear"
                                        :key="'template-' + item.rear.tire"
                                        dashed
                                        secondary
                                        dark
                                        :prependIcon="{
                                            name: 'car-rear'
                                        }"
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.rear, 'tire')"
                                    >
                                        {{ item.rear.tire }}
                                    </ButtonText>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="tire-selection-form__row_second mt-20">
                    <BtnTogglersGroup
                        :value="paramsValues.tire_season"
                        :options="paramsOptions.tire_season"
                        optionLabel="name"
                        class="tire-selection__radio-group"
                        @change="onChangeParam({ value: $event, codename: 'tire_season' })"
                    ></BtnTogglersGroup>

                    <BtnTogglersGroup
                        :value="paramsValues.tire_spikes"
                        :options="paramsOptions.tire_spikes"
                        optionLabel="name"
                        class="tire-selection__radio-group"
                        @change="onChangeParam({ value: $event, codename: 'tire_spikes' })"
                    ></BtnTogglersGroup>
                </div>
            </form>
            <form
                v-show="mode === 'params'"
                class="selection-form tire-selection-form"
            >
                <div class="tire-selection-form__row_first form-field-gap_bottom">
                    <Selector
                        id="tire-selection-width"
                        :value="paramsValues.tire_width"
                        label="Ширина, мм"
                        :options="paramsOptions.tire_width"
                        optionLabel="name"
                        optionKey="id"
                        :emptyValue="{}"
                        filterable
                        class="tire-selection__input"
                        @change="onChangeParam({ value: $event, codename: 'tire_width' })"
                    ></Selector>

                    <span
                        aria-hidden="true"
                        class="tire-selection__slash"
                    >
                        /
                    </span>

                    <Selector
                        id="tire-selection-height"
                        :value="paramsValues.tire_height"
                        label="Высота, %"
                        :options="paramsOptions.tire_height"
                        optionLabel="name"
                        optionKey="id"
                        :emptyValue="{}"
                        filterable
                        class="tire-selection__input"
                        @change="onChangeParam({ value: $event, codename: 'tire_height' })"
                    ></Selector>

                    <span
                        aria-hidden="true"
                        class="tire-selection__r"
                    >
                        R
                    </span>

                    <Selector
                        id="tire-selection-diameter"
                        :value="paramsValues.tire_diameter"
                        label="Диаметр, ″"
                        :options="paramsOptions.tire_diameter"
                        optionLabel="name"
                        optionKey="id"
                        :emptyValue="{}"
                        filterable
                        class="tire-selection__input"
                        @change="onChangeParam({ value: $event, codename: 'tire_diameter' })"
                    ></Selector>

                    <div class="tire-selection__popup-btn">
                        <ButtonText
                            dashed
                            secondary
                            dark
                            @click="showTireSelectionInfoPopup"
                        >
                            Подробнее о параметрах
                        </ButtonText>
                    </div>
                </div>

                <div class="tire-selection-form__row_second">
                    <BtnTogglersGroup
                        :value="paramsValues.tire_season"
                        :options="paramsOptions.tire_season"
                        optionLabel="name"
                        class="tire-selection__radio-group"
                        @change="onChangeParam({ value: $event, codename: 'tire_season' })"
                    ></BtnTogglersGroup>

                    <BtnTogglersGroup
                        :value="paramsValues.tire_spikes"
                        :options="paramsOptions.tire_spikes"
                        optionLabel="name"
                        class="tire-selection__radio-group"
                        @change="onChangeParam({ value: $event, codename: 'tire_spikes' })"
                    ></BtnTogglersGroup>
                </div>
            </form>
        </template>

        <template v-if="category === 'rim'">
            <form
                v-show="mode === 'car'"
                class="selection-form"
            >
                <div class="car-selection-form">
                    <Selector
                        :value="carData.manufacturer.value"
                        label="Марка"
                        :options="carData.manufacturer.options"
                        optionLabel="name"
                        filterable
                        :emptyValue="{}"
                        :loading="carData.manufacturer.loading"
                        @change="onChangeCarSelect({ value: $event, codename: 'manufacturer' })"
                    ></Selector>

                    <Selector
                        :value="carData.model.value"
                        label="Модель"
                        :options="carData.model.options"
                        optionLabel="name"
                        filterable
                        :emptyValue="{}"
                        :disabled="!carData.manufacturer.value"
                        :loading="carData.model.loading"
                        @change="onChangeCarSelect({ value: $event, codename: 'model' })"
                    ></Selector>

                    <Selector
                        :value="carData.year.value"
                        label="Год выпуска"
                        :options="carData.year.options"
                        optionLabel="name"
                        filterable
                        :emptyValue="{}"
                        :disabled="!carData.manufacturer.value || !carData.model.value"
                        :loading="carData.year.loading"
                        @change="onChangeCarSelect({ value: $event, codename: 'year' })"
                    ></Selector>

                    <Selector
                        :value="carData.modification.value"
                        label="Комплектация"
                        :options="carData.modification.options"
                        optionLabel="name"
                        filterable
                        :emptyValue="{}"
                        :disabled="!carData.manufacturer.value || !carData.model.value || !carData.year.value"
                        :loading="carData.modification.loading"
                        @change="onChangeCarSelect({ value: $event, codename: 'modification' })"
                    ></Selector>
                </div>

                <div
                    v-if="carData.template_sizes.show"
                    class="template-sizes mt-20"
                >
                    <Spinner
                        v-if="carData.template_sizes.loading"
                        size="base"
                    ></Spinner>
                    <template v-else>
                        <p
                            v-if="!carData.template_sizes.options.length"
                            class="text_semi-bold"
                        >Подходящие размеры не найдены, воспользуйтесь поиском по параметрам</p>

                        <div
                            v-if="originalSizeTemplates.length"
                            class="template-sizes-block"
                        >
                            <span class="template-sizes__title text_bold">Оригинальные размеры:</span>
                            <div class="template-sizes__list">
                                <div
                                    v-for="item in originalSizeTemplates"
                                    class="template-sizes__item"
                                >
                                    <ButtonText
                                        :key="'template-' + item.front.rim"
                                        dashed
                                        secondary
                                        dark
                                        :prependIcon="item.rear ? {
                                            name: 'car-front'
                                        } : false"
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.front, 'rim')"
                                    >
                                        {{ item.front.rim }}
                                    </ButtonText>

                                    <span
                                        v-if="item.rear"
                                        class="ml-8 mr-8"
                                    >+</span>

                                    <ButtonText
                                        v-if="item.rear"
                                        :key="'template-' + item.rear.rim"
                                        dashed
                                        secondary
                                        dark
                                        :prependIcon="{
                                            name: 'car-rear'
                                        }"
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.rear, 'rim')"
                                    >
                                        {{ item.rear.rim }}
                                    </ButtonText>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="notOriginalSingleSizeTemplates.length"
                            class="template-sizes-block"
                        >
                            <span
                                v-if="originalSizeTemplates.length"
                                class="template-sizes__title text_bold"
                            >Также подойдут:</span>
                            <span
                                v-else
                                class="template-sizes__title text_bold"
                            >Подходящие размеры:</span>
                            <div class="template-sizes__list">
                                <div
                                    v-for="item in notOriginalSingleSizeTemplates"
                                    class="template-sizes__item"
                                >
                                    <ButtonText
                                        :key="'template-' + item.front.rim"
                                        dashed
                                        secondary
                                        dark
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.front, 'rim')"
                                    >
                                        {{ item.front.rim }}
                                    </ButtonText>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="notOriginalPairSizeTemplates.length"
                            class="template-sizes-block"
                        >
                            <span class="template-sizes__title text_bold">Разноширокие диски:</span>
                            <div class="template-sizes__list">
                                <div
                                    v-for="item in notOriginalPairSizeTemplates"
                                    class="template-sizes__item"
                                >
                                    <ButtonText
                                        :key="'template-' + item.front.rim"
                                        dashed
                                        secondary
                                        dark
                                        :prependIcon="item.rear ? {
                                            name: 'car-front'
                                        } : false"
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.front, 'rim')"
                                    >
                                        {{ item.front.rim }}
                                    </ButtonText>

                                    <span
                                        v-if="item.rear"
                                        class="ml-8 mr-8"
                                    >+</span>

                                    <ButtonText
                                        v-if="item.rear"
                                        :key="'template-' + item.rear.rim"
                                        dashed
                                        secondary
                                        dark
                                        :prependIcon="{
                                            name: 'car-rear'
                                        }"
                                        class="template-sizes__btn"
                                        @click="selectTemplateSize(item.rear, 'rim')"
                                    >
                                        {{ item.rear.rim }}
                                    </ButtonText>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </form>
            <form
                v-show="mode === 'params'"
                class="selection-form rim-selection-form"
            >
                <div class="rim-selection__input rim-selection__input_x">
                    <Selector
                        id="rim-selection-width"
                        :value="paramsValues.rim_width"
                        label="Ширина, мм"
                        :options="paramsOptions.rim_width"
                        optionLabel="name"
                        optionKey="id"
                        :emptyValue="{}"
                        filterable
                        @change="onChangeParam({ value: $event, codename: 'rim_width' })"
                    ></Selector>
                </div>

                <div class="rim-selection__input">
                    <Selector
                        id="rim-selection-diameter"
                        :value="paramsValues.rim_diameter"
                        label="Диаметр, ″"
                        :options="paramsOptions.rim_diameter"
                        optionLabel="name"
                        optionKey="id"
                        :emptyValue="{}"
                        filterable
                        @change="onChangeParam({ value: $event, codename: 'rim_diameter' })"
                    ></Selector>
                </div>

                <Selector
                    id="rim-selection-et"
                    :value="paramsValues.rim_et"
                    label="Вылет, мм"
                    :options="paramsOptions.rim_et"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                    class="rim-selection__input"
                    @change="onChangeParam({ value: $event, codename: 'rim_et' })"
                ></Selector>

                <Selector
                    id="rim-selection-pcd"
                    :value="paramsValues.rim_pcd"
                    label="Сверловка (PCD)"
                    :options="paramsOptions.rim_pcd"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                    class="rim-selection__input"
                    @change="onChangeParam({ value: $event, codename: 'rim_pcd' })"
                ></Selector>

                <Selector
                    id="rim-selection-dia"
                    :value="paramsValues.rim_dia"
                    label="Ступица (DIA)"
                    :options="paramsOptions.rim_dia"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                    class="rim-selection__input"
                    @change="onChangeParam({ value: $event, codename: 'rim_dia' })"
                ></Selector>

                <div class="rim-selection__popup-btn">
                    <ButtonText
                        dashed
                        secondary
                        dark
                        @click="showRimSelectionInfoPopup"
                    >
                        Подробнее о параметрах
                    </ButtonText>
                </div>
            </form>
        </template>
    </div>
</template>

<script>
import Selector from '@/components/_inputs/Selector.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import InfoIcon from '@/components/InfoIcon.vue';
import Spinner from '@/components/Spinner.vue';
import BtnTogglersGroup from '@/components/BtnTogglersGroup.vue';
import NavigationTabs from '@/components/NavigationTabs.vue';
const TireSelectionInfoPopup = () => import('@/components/popups/TireSelectionInfoPopup.vue');
const RimSelectionInfoPopup = () => import('@/components/popups/RimSelectionInfoPopup.vue');

export default {
    name: 'DesktopUI',

    components: {
        NavigationTabs,
        BtnTogglersGroup,
        Spinner,
        InfoIcon,
        ButtonText,
        Selector,
    },

    props: {
        category: {
            type: String,
            default: '',
            validator(value) {
                return ['rim', 'tire'].includes(value);
            },
        },

        carData: {
            type: Object,
            default() {
                return {};
            },
        },

        paramsValues: {
            type: Object,
            default() {
                return {};
            },
        },

        paramsOptions: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    data() {
        return {
            mode: 'car',
        };
    },

    computed: {
        originalSizeTemplates() {
            if (this.carData.template_sizes.options) {
                return this.carData.template_sizes.options.filter(item => item.is_stock);
            }
            return [];
        },

        notOriginalSingleSizeTemplates() {
            if (this.carData.template_sizes.options) {
                return this.carData.template_sizes.options.filter(item => !item.is_stock && !item.rear);
            }
            return [];
        },

        notOriginalPairSizeTemplates() {
            if (this.carData.template_sizes.options) {
                return this.carData.template_sizes.options.filter(item => !item.is_stock && item.rear);
            }
            return [];
        },
    },

    methods: {
        onChangeCarSelect({ value, codename }) {
            this.$emit('changeCar', { value, codename });
        },

        onChangeParam({ value, codename }) {
            this.$emit('changeParam', { value, codename });
        },

        selectTemplateSize(template, type) {
            this.$emit('selectTemplateSize', { template, type });
        },

        showTireSelectionInfoPopup() {
            this.$popup(TireSelectionInfoPopup);
        },

        showRimSelectionInfoPopup() {
            this.$popup(RimSelectionInfoPopup);
        },
    },
};
</script>

<style scoped>
.navigation__btns-wrap {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .navigation__btns-wrap {
        padding: 6px 20px 0;
    }
}
@media (max-width: 767px) {
    .navigation__btns-wrap {
        padding: 6px 16px 0;
    }
}

.navigation__main-btn {
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
    color: var(--font-secondary-color);
    transition: color var(--transition);
}

.navigation__main-btn:first-child {
    margin-right: var(--gap-y-small);
}

.navigation__main-btn.active,
.navigation__main-btn:hover,
.navigation__main-btn:focus {
    color: var(--dark-c);
}
@media (min-width: 768px) {
    .navigation__main-btn-text_short {
        display: none;
    }
}
@media (max-width: 767px) {
    .navigation__main-btn-text_long {
        display: none;
    }
}

.navigation__info-btn {
    display: none;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .navigation__info-btn_text_short {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .navigation__info-btn {
        display: block;
        margin-left: auto;
    }

    .navigation__info-btn_icon {
        display: none;
    }
}
@media (max-width: 767px) {
    .navigation__info-btn {
        display: block;
        margin-left: 8px;
    }

    .navigation__info-btn_text_long,
    .navigation__info-btn_text_short {
        display: none;
    }
}


@media (min-width: 768px) {
    .selection-form {
        padding: 20px;
    }
}
@media (max-width: 767px) {
    .selection-form {
        padding: 20px 16px;
    }
}

/* car */
@media (min-width: 1040px) {
    .car-selection-form {
        grid-template-columns: repeat(3, minmax(170px, 250px)) 330px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .car-selection-form {
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width: 768px) {
    .car-selection-form {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .car-selection-form > :not(:last-child) {
        margin-bottom: 16px;
    }
}

.template-sizes {
    width: max-content;
    max-width: 100%;
    margin-bottom: 20px;
    padding: 16px;
    background-color: var(--bright-bg);
    border: 1px solid var(--border-light-c);
    box-sizing: border-box;
    border-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .template-sizes-block {
        display: flex;
        align-items: baseline;
    }

    .template-sizes-block:not(:last-child) {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) {
    .template-sizes__title {
        width: 164px;
        margin-right: 24px;
        flex-shrink: 0;
        font-size: var(--small-fz);
    }

    .template-sizes__empty-text {
        margin-left: -16px;
    }
}
@media (max-width: 767px) {
    .template-sizes__title {
        display: block;
        margin-bottom: 12px;
    }

    .template-sizes__empty-text {
        display: block;
        margin-top: -8px;
    }
}

.template-sizes__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: -12px;
    margin-right: -24px;
}

.template-sizes__item {
    display: flex;
    align-items: center;
    margin-right: 24px;
    margin-bottom: 12px;
}

.template-sizes__btn >>> svg {
    position: relative;
    top: 2px;
}


/* tire */
@media (min-width: 768px) {
    .tire-selection-form__row_first {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .tire-selection-form__row_second {
        display: flex;
    }
}

@media (min-width: 1040px) {
    .tire-selection__input {
        width: 180px;
        flex-shrink: 0;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .tire-selection__input {
        flex-basis: 200px;
        flex-shrink: 1;
    }
}
@media (max-width: 767px) {
    .tire-selection__input {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .tire-selection__slash,
    .tire-selection__r {
        margin-left: 12px;
        margin-right: 12px;
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    .tire-selection__slash,
    .tire-selection__r {
        display: none;
    }
}

@media (min-width: 1040px) {
    .tire-selection__popup-btn {
        margin-left: 36px;
        flex-shrink: 0;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .tire-selection__popup-btn {
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .tire-selection__radio-group:first-child {
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .tire-selection__radio-group:first-child {
        margin-bottom: 12px;
    }
}


/* rim */
@media (min-width: 1281px) {
    .rim-selection-form {
        grid-template-columns: 140px 140px 140px 200px 200px 1fr;
    }

    .rim-selection__input:nth-child(1) .selector,
    .rim-selection__input:nth-child(2) .selector {
        width: 150px;
    }
    .rim-selection__input:nth-child(2) .selector {
        margin-left: auto;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .rim-selection-form {
        grid-template-columns: 145px 145px 140px 180px 180px;
    }

    .rim-selection__input:nth-child(1) .selector,
    .rim-selection__input:nth-child(2) .selector,
    .rim-selection__input:nth-child(3) .selector {
        width: 140px;
    }
    .rim-selection__input:nth-child(2) .selector {
        margin-left: auto;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .rim-selection-form {
        grid-template-columns: repeat(12, 1fr);
    }

    .rim-selection__input:nth-child(1) {
        grid-column: 1/7;
        grid-row: 1/2;
    }

    .rim-selection__input:nth-child(1) .selector,
    .rim-selection__input:nth-child(2) .selector {
        width: 317px;
    }
    .rim-selection__input:nth-child(2) .selector {
        margin-left: auto;
    }

    .rim-selection__input:nth-child(2) {
        grid-column: 7/13;
        grid-row: 1/2;
    }
    .rim-selection__input:nth-child(3) {
        grid-column: 1/5;
        grid-row: 2/3;
    }
    .rim-selection__input:nth-child(4) {
        grid-column: 5/9;
        grid-row: 2/3;
    }
    .rim-selection__input:nth-child(5) {
        grid-column: 9/13;
        grid-row: 2/3;
    }
}
@media (min-width: 768px) {
    .rim-selection-form {
        display: grid;
        align-items: center;
        grid-gap: var(--grid-gap);
    }
}

@media (max-width: 767px) {
    .rim-selection__input {
        margin-bottom: 16px;
    }
}

.rim-selection__input_x {
    position: relative;
}

@media (min-width: 1281px) {
    .rim-selection__input_x::after {
        font-size: 20px;
        top: 12px;
        right: -16px;
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .rim-selection__input_x::after {
        width: 10px;
        height: 16px;
        top: 15px;
        font-size: 16px;
        right: -15px;
    }
}
@media (min-width: 768px) {
    .rim-selection__input_x::after {
        content: "X";
        position: absolute;
        line-height: 1;
    }
}

@media (min-width: 1281px) {
    .rim-selection__popup-btn {
        display: flex;
        align-items: center;
        margin-left: 16px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .rim-selection__popup-btn {
        grid-row: 2/3;
        grid-column: 1/4;
    }
}
@media (max-width: 1039px) {
    .tire-selection__popup-btn,
    .rim-selection__popup-btn {
        display: none;
    }
}
</style>