<template>
    <ul class="auth-factoids">
        <UFactoid
            icon="review"
            primaryIcon
            class="auth-factoids__item"
        >
            <template #title>
                Общайтесь
            </template>
            <template #descriptor>
                с магазинами в&nbsp;чате
            </template>
        </UFactoid>

        <UFactoid
            icon="star-stroke"
            primaryIcon
            class="auth-factoids__item"
        >
            <template #title>
                Оставляйте отзывы
            </template>
            <template #descriptor>
                к товарам и&nbsp;магазинам
            </template>
        </UFactoid>

        <UFactoid
            icon="delivery"
            primaryIcon
            class="auth-factoids__item"
        >
            <template #title>
                Покупайте с&nbsp;доставкой
            </template>
            <template #descriptor>
                по всей России
            </template>
        </UFactoid>

        <UFactoid
            icon="heart-stroke"
            primaryIcon
            class="auth-factoids__item"
        >
            <template #title>
                Добавляйте в избранное
            </template>
            <template #descriptor>
                товары, магазины, бренды
            </template>
        </UFactoid>

        <UFactoid
            icon="safe"
            primaryIcon
            class="auth-factoids__item"
        >
            <template #title>
                Покупайте уверенно
            </template>
            <template #descriptor>
                с гарантией безопасной сделки
            </template>
        </UFactoid>
    </ul>
</template>

<script>
import UFactoid from '@/components/UFactoids/UFactoid.vue';

export default {
    name: 'AuthFactoids',

    components: { UFactoid },
};
</script>

<style scoped>
.auth-factoids {
    position: relative;
    z-index: 2;
}
@media (min-width: 1180px) {
    .auth-factoids {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 36px 20px;
    }
}

@media (min-width: 1180px) {
    .auth-factoids__item:last-child {
        grid-column: 2/3;
        grid-row: 2/3;
    }
}
@media (min-width: 768px) and (max-width: 1179px) {
    .auth-factoids__item:not(:last-child) {
        margin-bottom: 36px;
    }
}
@media (max-width: 767px) {
    .auth-factoids__item:not(:last-child) {
        margin-bottom: 16px;
    }
}

@media (min-width: 1281px) {
    .auth-factoids__item >>> .u-factoid__descriptor {
        height: 20px;
    }
}
</style>