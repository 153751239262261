import throttle from '@/lib/throttle.js';


export default {

    data() {
        return {
            breakpointKey: '',
        };
    },

    mounted() {
        const t = () => throttle(this.getBreakpointKey(), 10);
        window.addEventListener('resize', t);
        this.getBreakpointKey();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.getBreakpointKey);
    },

    methods: {
        getBreakpointKey() {
            if (window.innerWidth >= 1281) {
                this.breakpointKey = 'xl';
            }
            else if (window.innerWidth >= 1040 && window.innerWidth < 1281) {
                this.breakpointKey = 'l';
            }
            else if (window.innerWidth >= 768 && window.innerWidth < 1040) {
                this.breakpointKey = 'm';
            }
            else if (window.innerWidth < 768) {
                this.breakpointKey = 's';
            }
        },
    },
};