<template>
    <Counter
        v-if="innerAmountInCart"
        ref="counter"
        :value="innerAmountInCart"
        :min="0"
        :max="offer.amount < 100 ? offer.amount : 99"
        class="counter"
        @change="handleCounterChange"
    ></Counter>

    <ButtonBlock
        v-else
        primary
        :low="low"
        :loading="loading"
        class="cart-btn"
        :disabled="disabled || loading"
        @click="handleCounterChange(1)"
    >
        <slot>
            В корзину
        </slot>
    </ButtonBlock>
</template>

<script>
import { HTTP } from '@/http.js';
import { mapActions, mapMutations, mapState } from 'vuex';
import { getErrorCode } from '@/lib/errors.js';
import yandexMetrika from '@/mixins/yandex-metrika.js';
import bus from '@/bus.js';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import Counter from '@/components/Counter.vue';

export default {
    name: 'AddCartBtn',

    components: {
        Counter,
        ButtonBlock,
    },

    mixins: [yandexMetrika],

    props: {
        item: {
            type: Object,
            default: () => {},
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },

        offer: {
            type: Object,
            default: () => {},
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        low: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            loading: false,
            cancelToken: null,
            innerAmountInCart: 0,
        };
    },

    computed: {
        ...mapState({
            orders: state => state.cart.items,
        }),

        computedAmountInCart() {
            let positionInCart;
            this.orders.forEach(order => {
                order.positions.forEach(position => {
                    if (position.offer.id === this.offer.id) {
                        positionInCart = position;
                    }
                });
            });

            if (positionInCart) return positionInCart.amount_in_basket;
            return 0;
        },
    },

    mounted() {
        this.innerAmountInCart = this.computedAmountInCart;
        bus.$on('changeItemInCartPreview', this.updateCounter);
    },

    beforeDestroy() {
        bus.$off('changeItemInCartPreview', this.updateCounter);
    },

    methods: {
        ...mapActions({
            ADD_TO_CART: 'cart/addToCart',
            DELETE_FROM_CART: 'cart/deleteFromCart',
        }),

        ...mapMutations({
            SET_CART: 'cart/set',
        }),

        async handleCounterChange(value) {
            if (value) {
                this.addToCart(value);
            }
            else {
                this.deleteFromCart();
            }

            if (value === 1) {
                this.handleYandexMetrikaGoal('vkorzinu');
            }
        },

        addToCart(value) {
            const oldValue = this.innerAmountInCart;
            this.innerAmountInCart = value;
            this.loading = true;
            const data = {
                offer: this.offer.id,
                amount: value,
            };
            if (this.cancelToken) this.cancelToken.cancel();
            this.cancelToken = HTTP.CancelToken.source();

            this.ADD_TO_CART({ data, cancelToken: this.cancelToken.token })
                .then(() => {
                    if (value === 1 && oldValue === 0) {
                        this.$notify({
                            type: 'cart',
                            item: this.item,
                            modelName: this.modelName,
                            offer: this.offer,
                        });
                    }
                    this.$emit('addToCart');
                })
                .catch(error => {
                    // this.$refs.counter.updateValue(this.computedAmountInCart);
                    this.innerAmountInCart = this.computedAmountInCart;

                    const code = getErrorCode(error);

                    if (code === 400) {
                        const data = error.response.data;
                        const { amount, offer, non_field_errors } = data;
                        if (amount) {
                            this.$error(amount[0].message);
                        }
                        else if (offer) {
                            this.$error(offer[0].message);
                        }
                        else if (non_field_errors) {
                            this.$error(non_field_errors[0].message);
                        }
                        else {
                            this.$error('Не удалось добавить в корзину');
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        deleteFromCart() {
            this.innerAmountInCart = 0;
            this.loading = true;

            const data = [{
                offer: this.offer.id,
            }];

            this.DELETE_FROM_CART({ data })
                .then(() => {
                    bus.$emit('deleteFromCart', {
                        offerId: this.offer.id,
                        itemId: this.item.id || this.item.product_id || this.item.part_product_id,
                        inCartStatus: false,
                    });
                    this.$emit('deleteFromCart', {
                        offerId: this.offer.id,
                        itemId: this.item.id || this.item.product_id || this.item.part_product_id,
                        inCartStatus: false,
                    });
                })
                .catch(() => {
                    this.$refs.counter.updateValue(this.computedAmountInCart);
                    this.innerAmountInCart = this.computedAmountInCart;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        updateCounter({ offer, value }) {
            if (offer.id === this.offer.id) {
                this.$refs.counter.updateValue(value);
            }
        },
    },
};
</script>

<style scoped>
.cart-btn,
.counter {
    width: 112px;
}
</style>

