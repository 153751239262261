<template>
    <div class="round-progress">
        <div class="round-progress__value">
            <span>{{ value }}%</span>
        </div>

        <svg
            :width="width"
            :height="width"
            :viewbox="'0 0 ' + width + ' ' + height"
            class="round-progress__line"
        >
            <circle
                :r="radius"
                :cx="x"
                :cy="y"
                :stroke-width="strokeWidth"
                fill="none"
                stroke="#F4F6F8"
                stroke-linecap="round"
            ></circle>

            <circle
                :r="radius"
                :cx="x"
                :cy="y"
                :stroke-width="strokeWidth"
                :stroke-dasharray="circumference"
                :stroke-dashoffset="strokeDashoffset"
                fill="none"
                stroke="#A2A7AB"
                stroke-linecap="round"
            ></circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'RoundProgress',

    props: {
        value: Number,
        strokeWidth: {
            type: Number,
            default: 6,
        },
        width: {
            type: Number,
            default: 48,
        },
        height: {
            type: Number,
            default: 48,
        },
    },

    computed: {
        strokeDashoffset() {
            if (this.value == null) return 0;

            return this.circumference / 100 * (100 - this.value);
        },

        radius() {
            return (this.width - Math.ceil(this.strokeWidth)) / 2;
        },

        x() {
            return this.width / 2;
        },

        y() {
            return this.height / 2;
        },

        circumference() {
            return 2 * this.radius * Math.PI;
        },
    },
};
</script>

<style>
.round-progress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
}

.round-progress__line {
    position: absolute;
    transform: rotate(-90deg);
    font-size: 0;
}

.round-progress__value {
    position: absolute;
    font-family: var(--f-bold);
    font-size: var(--micro-fz);
}
</style>