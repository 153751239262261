var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.applicability && _vm.innerApplicability)?_c('div',{staticClass:"applicability"},[_c('div',{staticClass:"applicability__title"},[_c('h4',{staticClass:"h4"},[_vm._v("\n            Применяемость к авто\n        ")]),_vm._v(" "),_c('UTooltip',{attrs:{"maxWidth":"210"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var value = ref.value;
var attrs = ref.attrs;
var handlers = ref.handlers;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}}],null,false,752151382)},[_vm._v("\n\n            Перечень марок и моделей автомобилей, для которых подходит запчасть.\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"applicability__cars"},_vm._l((_vm.applicability),function(item,itemIndex){return _c('div',{key:'applicability__manufacturer-' + itemIndex,staticClass:"applicability-block"},[_c('span',{staticClass:"text_bold"},[_vm._v(_vm._s(item.manufacturer.name)+": ")]),_vm._v(" "),_vm._l((((_vm.innerApplicability[item.manufacturer.name] || {}).families || [])),function(family,familyIndex){return _c('span',{key:'applicability__family-' + familyIndex,staticClass:"d-inline-block"},[_c('RouterLink',{staticClass:"underline",attrs:{"to":{
                        name: _vm.modelName === 'part_product' ? 'market-part-applicability' : 'contract-part-applicability',
                        params: {
                            id: _vm.$route.params.id,
                        },
                        query: {
                            manufacturer: item.manufacturer.id,
                            family: family.id,
                        },
                    }}},[_vm._v("\n                    "+_vm._s(family.name)+"\n                ")]),(familyIndex !== item.families.length - 1)?_c('span',{staticClass:"mr-4"},[_vm._v(",")]):_vm._e()],1)}),_vm._v(" "),(item.families.length > 10 && !_vm.innerApplicability[item.manufacturer.name].showMore)?_c('ButtonText',{attrs:{"dashed":"","secondary":"","dark":""},on:{"click":function($event){return _vm.toggleList(item.manufacturer.name)}}},[_vm._v("+ "+_vm._s(_vm.innerApplicability[item.manufacturer.name].restCount)+" "+_vm._s(_vm.innerApplicability[item.manufacturer.name].restCountText))]):_vm._e(),_vm._v(" "),(item.families.length > 10 && _vm.innerApplicability[item.manufacturer.name].showMore)?_c('ButtonText',{staticClass:"ml-4",attrs:{"dashed":"","secondary":"","dark":""},on:{"click":function($event){return _vm.toggleList(item.manufacturer.name)}}},[_vm._v("Свернуть")]):_vm._e()],2)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }