<template>
    <div class="auth-page">
        <TransitionHeightGroup>
            <div
                v-if="step === 1"
                key="step1"
                class="auth-page__content"
            >
                <div class="auth-page__title-wrap">
                    <h1 class="auth-page__title">
                        Вход
                    </h1>

                    <p class="auth-page__description">
                        На указанный номер телефона придёт SMS с&nbsp;кодом подтверждения
                    </p>
                </div>

                <UFormProvide
                    ref="form1"
                    v-model="formData1"
                >
                    <UFormField
                        type="string"
                        name="phone"
                        visibleMask
                        keepValue
                        mask="+7 (000) 000 00 00"
                        validations="required|phone"
                        class="auth-page__form-field"
                    >
                        <template #label>
                            Телефон
                        </template>
                    </UFormField>

                    <div class="auth-page__form-footer">
                        <FormAgreement
                            text="Получить код"
                            class="auth-page__agreement"
                        ></FormAgreement>

                        <UButtonBlock
                            primary
                            :loading="sending"
                            class="auth-page__submit-btn"
                            @click="send"
                        >
                            Получить код
                        </UButtonBlock>
                    </div>
                </UFormProvide>
            </div>

            <div
                v-else-if="step === 2"
                key="step2"
                class="auth-page__content"
            >
                <div class="auth-page__title-wrap">
                    <h1 class="auth-page__title">
                        <UIconButton
                            name="arrow-left"
                            round
                            tiny
                            class="auth-page__return-btn"
                            @click="step--"
                        ></UIconButton>

                        {{ formData1.phone | phone }}
                    </h1>

                    <p class="auth-page__description">
                        Мы отправили SMS с кодом подтверждения
                    </p>
                </div>

                <UFormProvide
                    ref="form2"
                    v-model="formData2"
                >
                    <UFormField
                        type="string"
                        name="code"
                        label="Код из СМС"
                        mask="0000"
                        validations="required"
                        class="auth-page__form-field"
                    >
                        <template #label>
                            Код из СМС
                        </template>
                    </UFormField>

                    <p
                        v-if="interval"
                        class="auth-page__repeat-btn"
                    >
                        Отправить код повторно можно через {{ countdownValue }} сек.
                    </p>

                    <UButtonText
                        v-if="!interval"
                        dashed
                        secondary
                        dark
                        class="auth-page__repeat-btn"
                        @click="send"
                    >
                        Отправить код повторно
                    </UButtonText>

                    <div class="auth-page__form-footer">
                        <UButtonBlock
                            primary
                            :loading="confirming"
                            class="auth-page__submit-btn"
                            @click="confirm"
                        >
                            Проверить код
                        </UButtonBlock>
                    </div>
                </UFormProvide>
            </div>
        </TransitionHeightGroup>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import { phone as phoneReg } from '@ui/utils/regs';
import { phone as phoneFilter } from '@ui/filters';
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';
import FormAgreement from '@/components/FormAgreement.vue';
import TransitionHeightGroup from '@ui/components/_transitions/TransitionHeightGroup.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
import UButtonBlock from '@ui/components/UButton/UButtonBlock.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
import UFormProvide from '@ui/components/UForm/UFormProvide.vue';
import UFormField from '@ui/components/UForm/UFormField.vue';

export default {
    name: 'LoginPage',

    metaInfo() {
        return {
            title: 'Вход',
        };
    },

    components: {
        UFormField,
        UFormProvide,
        UIconButton,
        UButtonBlock,
        UButtonText,
        TransitionHeightGroup,
        FormAgreement,
    },

    filters: {
        phone: phoneFilter,
    },

    mixins: [
        handleFormErrorMixin,
    ],

    data() {
        return {
            step: 1,

            formData1: {
                phone: '',
            },

            formData2: {
                code: '',
            },

            uuid: '',

            sending: false,
            confirming: false,
            sentAt: null,
            interval: null,
            countdownValue: 0,
        };
    },

    computed: {
        ...mapState({
            fromRoute: state => state.fromRoute.fromRoute,
        }),
    },

    watch: {
        // 'formData1.phone': {
        //     handler(value) {
        //         if (phoneReg.test(value)) {
        //             this.send();
        //         }
        //     },
        // },

        'formData2.code': {
            handler(value) {
                if (/\d{4}/.test(value)) {
                    this.confirm();
                }
            },
        },
    },

    mounted() {
        if (this.$route.params.phone) this.formData.phone = this.$route.params.phone;
    },

    beforeDestroy() {
        this.destroyCountdown();
    },

    methods: {
        ...mapActions({
            sendLoginCode: 'profile/sendLoginCode',
            confirmLoginCode: 'profile/confirmLoginCode',
        }),

        createCountdown() {
            this.sentAt = new Date();
            this.interval = setInterval(() => {
                const countdownValue = 60 - parseInt((new Date() - this.sentAt) / 1000);

                if (countdownValue > 0) {
                    this.countdownValue = countdownValue;
                }
                else {
                    this.destroyCountdown();
                }

            }, 1000);
        },

        destroyCountdown() {
            this.sentAt = null;
            this.countdownValue = 0;
            clearInterval(this.interval);
            this.interval = null;
        },

        async send() {
            const errors = await this.$refs.form1.validate();

            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.sending = true;

                try {
                    const { uuid } = await this.sendLoginCode(this.formData1);
                    this.createCountdown();
                    this.uuid = uuid;

                    if (this.formData1.phone) {
                        this.step++;
                    }
                }
                catch (error) {
                    this.$refs.form1.handle(error);
                    this.$store.commit('handleCommonHttpError', error);
                }
                finally {
                    this.sending = false;
                }
            }
        },

        async confirm() {
            const errors = await this.$refs.form2.validate();

            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.confirming = true;

                try {
                    const data = {
                        ...this.formData1,
                        ...this.formData2,
                        uuid: this.uuid,
                    };

                    const { isAuthorized } = await this.confirmLoginCode(data);

                    if (isAuthorized) {
                        await this.$store.dispatch('cart/getCart');
                        await this.$router.push({
                            name: this.fromRoute.name || 'main',
                            params: this.fromRoute.params,
                        });
                    }
                }
                catch (error) {
                    this.$refs.form2.handle(error);
                    this.$store.commit('handleCommonHttpError', error);
                }
                finally {
                    this.confirming = false;
                }
            }
        },
    },
};
</script>

<style scoped src="../assets/css/auth-page.css"></style>