<template>
    <footer class="footer">
        <div class="footer-container container">
            <div class="links-wrap">
                <RouterLink
                    :to="{ name: 'privacy-policy' }"
                    class="link link-primary-hover"
                >
                    Политика конфиденциальности
                </RouterLink>

                <RouterLink
                    :to="{ name: 'rules' }"
                    class="link link-primary-hover"
                >
                    Соглашения и правила
                </RouterLink>

                <RouterLink
                    :to="{
                        name: 'return-and-refund',
                    }"
                    class="link link-primary-hover"
                >
                    Возврат
                </RouterLink>

                <RouterLink
                    :to="{
                        name: 'payment',
                    }"
                    class="link link-primary-hover"
                >
                    Оплата
                </RouterLink>

                <RouterLink
                    :to="{
                        name: 'delivery-and-pickup',
                    }"
                    class="link link-primary-hover"
                >
                    Доставка&nbsp;и&nbsp;самовывоз
                </RouterLink>
            </div>

            <span class="copyright">© {{ year }}, ООО&nbsp;«Юкар»</span>
        </div>
    </footer>
</template>

<script>
const startYear = 2020;

export default {
    name: "SimpleLayoutFooter",

    computed: {
        year() {
            // TODO: Поменять на серверное время
            const currentYear = new Date().getFullYear();
            if (currentYear === startYear) {
                return startYear;
            } else {
                return `${ startYear } – ${ currentYear }`;
            }
        },
    },
};
</script>

<style scoped>
.footer {
    margin-top: var(--gap-y-max);
    padding-top: 36px;
    padding-bottom: 36px;
    background-color: var(--light-bg);
    color: var(--font-secondary-dark-color);
}

@media (min-width: 1160px) {
    .footer-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

@media (max-width: 1159px) {
    .links-wrap {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--border-dark-c);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .links-wrap {
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, max-content));
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
        grid-gap: 16px 36px;
    }
}

@media (min-width: 1281px) {
    .link:not(:last-child) {
        margin-right: 48px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .link:not(:last-child) {
        margin-right: 24px;
    }
}
@media (max-width: 767px) {
    .link {
        display: block;
        width: max-content;
    }
    .link:not(:last-child) {
        margin-bottom: 20px;
    }
}
</style>