var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"component",staticClass:"u-button-block",class:{
        'u-button-block_disabled': _vm.disabled,
        'u-button-block_primary': _vm.primary,
        'u-button-block_secondary': _vm.secondary,
        'u-button-block_blue': _vm.blue,
        'u-button-block_loading': _vm.loading,
        'u-button-block_active': _vm.active,
        'u-button-block_low': _vm.low,
        'u-button-block_narrow': _vm.narrow || _vm.narrowComputed,
        'u-button-block_fixed': _vm.fixed,
    },on:{"click":function($event){return _vm.$emit('click', $event)}}},'component',_vm.attrs,false),[_c('USpinner',{staticClass:"u-button-block__spinner",class:{
            'u-button-block__spinner_loading': _vm.loading,
        },attrs:{"light":_vm.primary,"dark":_vm.secondary,"small":_vm.low}}),_vm._v(" "),_c('span',{staticClass:"u-button-block__label",class:{
            'u-button-block__label_loading': _vm.loading
        }},[_vm._t("prepend-icon",function(){return [(_vm.prependIcon)?_c('UIcon',_vm._b({staticClass:"mr-8 u-button-block__icon u-button-block__prepend-icon"},'UIcon',_vm.prependIcon,false)):_vm._e()]}),_vm._v(" "),_vm._t("default",function(){return [_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")]}),_vm._v(" "),_vm._t("append-icon",function(){return [(_vm.appendIcon)?_c('UIcon',_vm._b({staticClass:"ml-8 u-button-block__icon u-button-block__append-icon"},'UIcon',_vm.appendIcon,false)):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }