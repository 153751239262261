var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-gap_medium"},[_c('h1',{staticClass:"h1 h1_block"},[_vm._v("\n        "+_vm._s(_vm.partName.page_title)+"\n    ")]),_vm._v(" "),(_vm.hasCar)?[(_vm.showCharacteristicsBar)?_c('div',{staticClass:"characteristics-bar hidden-s layer-1"},_vm._l((_vm.characteristicsWithOptions),function(c){return _c('div',{key:c.codename,staticClass:"characteristics-bar__item"},[_c('RadioButtonsGroup',{attrs:{"name":c.codename,"mobileAtomic":"","options":c.options},on:{"change":_vm.onCharacteristicsChange},model:{value:(_vm.characteristicsWithOptionValues[c.codename]),callback:function ($$v) {_vm.$set(_vm.characteristicsWithOptionValues, c.codename, $$v)},expression:"characteristicsWithOptionValues[c.codename]"}})],1)}),0):_vm._e(),_vm._v(" "),(_vm.showCharacteristicsBar)?_c('div',{staticClass:"characteristics-bar_mobile hidden-m hidden-l hidden-xl layer-1"},_vm._l((_vm.characteristicsWithOptions),function(c){return _c('USelect',{key:'select-' + c.codename,staticClass:"characteristics-bar__item_mobile",attrs:{"value":_vm.characteristicsWithOptionsValuesForSelect[c.codename],"options":c.options,"optionKey":"id","optionLabel":"name","searchable":false},on:{"select":function($event){return _vm.onSelectCharacteristic(Object.assign({}, $event, {codename: c.codename}))}}})}),1):_vm._e(),_vm._v(" "),(!_vm.initializedData)?_c('Spinner',{attrs:{"size":"big","center":""}}):[(_vm.totalDefaultCount)?[(_vm.initializedFilter)?_c('MarketItemListUpper',{staticClass:"mb-0",attrs:{"checkedFilters":_vm.checkedFilters},on:{"resetFilters":_vm.onResetFilters,"deleteFilter":_vm.onDeleteFilter},scopedSlots:_vm._u([{key:"sort",fn:function(){return [_c('SimpleSelectBase',{attrs:{"value":_vm.sortData,"options":_vm.sortOptions},on:{"change":_vm.onChangeSort}})]},proxy:true},{key:"view",fn:function(){return [_c('RadioButtonsGroup',{attrs:{"name":"catalog-view","type":"icon","options":[
                                {
                                    label: '',
                                    value: 'list',
                                    icon: 'list-toggler'
                                },
                                {
                                    label: '',
                                    value: 'grid',
                                    icon: 'grid'
                                } ]},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}})]},proxy:true},{key:"filterToggler",fn:function(){return [_c('ButtonIcon',{staticClass:"filter-toggler-btn",attrs:{"icon":{
                                name: 'filter-btn',
                            },"hoverPrimary":"","aria-label":"Показать фильтр"},on:{"click":_vm.openFilter}})]},proxy:true}],null,false,410790093)}):_vm._e(),_vm._v(" "),(_vm.initializedData)?_c('MarketItemListContent',{staticClass:"market-parts-search-new-items__content",class:{
                        'market-parts-search-new-items__content_empty': !_vm.defaultData.originalPartsCount || !_vm.totalCurrentCount,
                    },scopedSlots:_vm._u([{key:"main",fn:function(){return [(!_vm.totalCurrentCount)?_c('section',{staticClass:"filter-empty-block"},[(_vm.originalPartsLoading || _vm.analoguePartsLoading || _vm.analoguePartsIsPartLoading || _vm.analoguePartsIncludeLoading)?_c('Spinner',{attrs:{"center":"","size":"big"}}):[(_vm.checkedFilters.length)?_c('EmptyBlock',{attrs:{"icon":"filter","title":"Нет подходящих предложений","text":"Попробуйте изменить условия поиска.","hasBtn":false,"hasResetFilters":""},on:{"resetFilters":_vm.onResetFilters}}):_c('EmptyBlock',{attrs:{"icon":"parts","title":"Нет предложений","btnText":"Перейти в каталог","btnUrl":{
                                        name: 'market-parts-catalog',
                                    }},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("\n                                        Для запчасти "),_c('b',[_vm._v("«"+_vm._s(_vm.partName.page_title)+"»")]),_vm._v(" нет актуальных предложений.\n                                    ")]},proxy:true}],null,false,343940241)})]],2):[(_vm.defaultData.originalPartsCount && _vm.originalParts.current_count)?_c('section',{staticClass:"result-section"},[_c('div',{staticClass:"title-wrap"},[_c('h2',{staticClass:"h2"},[_c('span',{staticClass:"mr-8"},[_vm._v("Оригинальные запчасти")]),_c('UTooltip',{attrs:{"maxWidth":"240"}},[_vm._v("\n                                            Предложения магазинов по оригинальным запчастям,\n                                            подходящим для вашего автомобиля.\n                                        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"result-section__content",class:{
                                        'result-section__content_loading': _vm.originalPartsLoading,
                                    }},[(_vm.originalPartsLoading)?_c('div',{staticClass:"result-section__spinner-wrap"},[_c('Spinner',{staticClass:"result-section__spinner",attrs:{"center":"","size":"big"}})],1):_vm._e(),_vm._v(" "),(_vm.originalParts.results.length)?_c('ol',{class:'items-' + _vm.view},_vm._l((_vm.originalParts.results),function(originalPartItem,originalPartItemIndex){return _c('li',{key:'item-' + originalPartItem.part_product_id,staticClass:"item",class:{
                                                'item_view-list': _vm.view === 'list',
                                                'item_view-grid-list': _vm.view === 'grid',
                                            }},[(_vm.view === 'list')?_c('MarketItemRowListItem',{attrs:{"item":originalPartItem,"modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                            var i = arguments.length, argsArray = Array(i);
                                            while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeFavorite.apply(void 0, [ originalPartItemIndex ].concat( argsArray ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup({
                                                    item: originalPartItem,
                                                    itemIndex: originalPartItemIndex,
                                                    partType: 'original'
                                                })},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(originalPartItem)}}}):_vm._e(),_vm._v(" "),(_vm.view === 'grid')?_c('MarketItemColumn',{attrs:{"item":originalPartItem,"apiName":"parts","modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                                var i = arguments.length, argsArray = Array(i);
                                                while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeFavorite.apply(void 0, [ originalPartItemIndex ].concat( argsArray ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup({
                                                    item: originalPartItem,
                                                    itemIndex: originalPartItemIndex,
                                                    partType: 'original'
                                                })},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(originalPartItem)}}}):_vm._e()],1)}),0):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.defaultData.analoguePartsCount && _vm.analogueParts.current_count)?_c('section',{staticClass:"result-section"},[_c('div',{staticClass:"title-wrap"},[_c('h2',{staticClass:"h2"},[_c('span',{staticClass:"mr-8"},[_vm._v("Совместимые аналоги")]),_c('UTooltip',{attrs:{"maxWidth":"240"}},[_vm._v("\n                                            Предложения магазинов по запчастям\n                                            от сторонних производителей (аналогам),\n                                            подходящим для вашего автомобиля.\n                                        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"result-section__content",class:{
                                        'result-section__content_loading': _vm.analoguePartsLoading,
                                    }},[(_vm.analoguePartsLoading)?_c('div',{staticClass:"result-section__spinner-wrap"},[_c('Spinner',{staticClass:"result-section__spinner",attrs:{"center":"","size":"big"}})],1):_vm._e(),_vm._v(" "),_c('ol',{class:'items-' + _vm.view},_vm._l((_vm.analogueParts.results),function(analoguePart,analoguePartIndex){return _c('li',{key:'item-' + analoguePart.part_product_id + analoguePartIndex + 'analogueParts',staticClass:"item",class:{
                                                'item_view-list': _vm.view === 'list',
                                                'item_view-grid-list': _vm.view === 'grid',
                                            }},[(_vm.view === 'list')?_c('MarketItemRowListItem',{attrs:{"item":analoguePart,"modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                            var i = arguments.length, argsArray = Array(i);
                                            while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['equal'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup({
                                                    item: analoguePart,
                                                    itemIndex: analoguePartIndex,
                                                    partType: 'equal'
                                                })},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e(),_vm._v(" "),(_vm.view === 'grid')?_c('MarketItemColumn',{attrs:{"item":analoguePart,"apiName":"parts","modelName":"part_product","withSchemeLink":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                                var i = arguments.length, argsArray = Array(i);
                                                while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['equal'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup({
                                                    item: analoguePart,
                                                    itemIndex: analoguePartIndex,
                                                    partType: 'equal'
                                                })},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e()],1)}),0),_vm._v(" "),(!!_vm.analogueParts.next)?_c('ButtonText',{staticClass:"load-more-btn",attrs:{"dashed":"","dark":"","secondary":"","loading":_vm.analoguePartsAddLoading,"center":""},on:{"click":function($event){return _vm.load('equal')}}},[_vm._v("\n                                        Показать ещё\n                                    ")]):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.defaultData.analoguePartsCount && _vm.analoguePartsIsPart.current_count)?_c('section',{staticClass:"result-section"},[_c('div',{staticClass:"title-wrap"},[_c('h2',{staticClass:"h2"},[_c('span',{staticClass:"mr-8"},[_vm._v("Входят в состав искомой запчасти")]),_c('UTooltip',{attrs:{"maxWidth":"240"}},[_vm._v("\n                                            Предложения магазинов по запчастям\n                                            от сторонних производителей (аналогам),\n                                            подходящим для вашего автомобиля,\n                                            которые входят в состав оригинальной запчасти.\n                                        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"result-section__content",class:{
                                        'result-section__content_loading': _vm.analoguePartsIsPartLoading,
                                    }},[(_vm.analoguePartsIsPartLoading)?_c('div',{staticClass:"result-section__spinner-wrap"},[_c('Spinner',{staticClass:"result-section__spinner",attrs:{"center":"","size":"big"}})],1):_vm._e(),_vm._v(" "),_c('ol',{class:'items-' + _vm.view},_vm._l((_vm.analoguePartsIsPart.results),function(analoguePart,analoguePartIndex){return _c('li',{key:'item-' + analoguePart.part_product_id + analoguePartIndex + 'analoguePartsIsPart',staticClass:"item",class:{
                                                'item_view-list': _vm.view === 'list',
                                                'item_view-grid-list': _vm.view === 'grid',
                                            }},[(_vm.view === 'list')?_c('MarketItemRowListItem',{attrs:{"item":analoguePart,"modelName":"part_product","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                            var i = arguments.length, argsArray = Array(i);
                                            while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['is_part'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup({
                                                    item: analoguePart,
                                                    itemIndex: analoguePartIndex,
                                                    partType: 'is_part'
                                                })},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e(),_vm._v(" "),(_vm.view === 'grid')?_c('MarketItemColumn',{attrs:{"item":analoguePart,"apiName":"parts","modelName":"part_product","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                                var i = arguments.length, argsArray = Array(i);
                                                while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['is_part'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup({
                                                    item: analoguePart,
                                                    itemIndex: analoguePartIndex,
                                                    partType: 'is_part'
                                                })},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e()],1)}),0),_vm._v(" "),(!!_vm.analoguePartsIsPart.next)?_c('ButtonText',{staticClass:"load-more-btn",attrs:{"dashed":"","dark":"","secondary":"","loading":_vm.analoguePartsIsPartAddLoading,"center":""},on:{"click":function($event){return _vm.load('is_part')}}},[_vm._v("\n                                        Показать ещё\n                                    ")]):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.defaultData.analoguePartsCount && _vm.analoguePartsInclude.current_count)?_c('section',{staticClass:"result-section"},[_c('div',{staticClass:"title-wrap"},[_c('h2',{staticClass:"h2"},[_c('span',{staticClass:"mr-8"},[_vm._v("Включают искомую запчасть")]),_c('UTooltip',{attrs:{"maxWidth":"240"}},[_vm._v("\n                                            Предложения магазинов по запчастям\n                                            от сторонних производителей (аналогам),\n                                            подходящим для вашего автомобиля,\n                                            которые включают в себя оригинальную запчасть.\n                                        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"result-section__content",class:{
                                        'result-section__content_loading': _vm.analoguePartsIncludeLoading,
                                    }},[(_vm.analoguePartsIncludeLoading)?_c('div',{staticClass:"result-section__spinner-wrap"},[_c('Spinner',{staticClass:"result-section__spinner",attrs:{"center":"","size":"big"}})],1):_vm._e(),_vm._v(" "),_c('ol',{class:'items-' + _vm.view},_vm._l((_vm.analoguePartsInclude.results),function(analoguePart,analoguePartIndex){return _c('li',{key:'item-' + analoguePart.part_product_id + analoguePartIndex + 'analoguePartsInclude',staticClass:"item",class:{
                                                'item_view-list': _vm.view === 'list',
                                                'item_view-grid-list': _vm.view === 'grid',
                                            }},[(_vm.view === 'list')?_c('MarketItemRowListItem',{attrs:{"item":analoguePart,"modelName":"part_product","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                            var i = arguments.length, argsArray = Array(i);
                                            while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['include'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup({
                                                    item: analoguePart,
                                                    itemIndex: analoguePartIndex,
                                                    partType: 'include'
                                                })},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e(),_vm._v(" "),(_vm.view === 'grid')?_c('MarketItemColumn',{attrs:{"item":analoguePart,"apiName":"parts","modelName":"part_product","reviewsInPopup":""},on:{"changeFavorite":function($event){
                                                var i = arguments.length, argsArray = Array(i);
                                                while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeFavorite.apply(void 0, [ analoguePartIndex ].concat( argsArray, ['include'] ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup({
                                                    item: analoguePart,
                                                    itemIndex: analoguePartIndex,
                                                    partType: 'include'
                                                })},"callShowPartSchemesPopup":function($event){return _vm.showPartSchemes(analoguePart)}}}):_vm._e()],1)}),0),_vm._v(" "),(!!_vm.analoguePartsInclude.next)?_c('ButtonText',{staticClass:"load-more-btn",attrs:{"dashed":"","dark":"","secondary":"","loading":_vm.analoguePartsIncludeAddLoading,"center":""},on:{"click":function($event){return _vm.load('include')}}},[_vm._v("\n                                        Показать ещё\n                                    ")]):_vm._e()],1)]):_vm._e()]]},proxy:true},{key:"aside",fn:function(){return [_c('FilterComponent',{attrs:{"value":_vm.filterData,"filters":_vm.staticFilters},on:{"change":_vm.onChangeFilter,"reset":_vm.onResetFilters,"reload":_vm.reload}})]},proxy:true}],null,false,2171313845)}):_vm._e()]:[_c('EmptyBlock',{attrs:{"icon":"parts","title":"Нет предложений","btnText":"Перейти в каталог","btnUrl":{
                        name: 'market',
                    }},scopedSlots:_vm._u([(_vm.showCharacteristicsBar)?{key:"text",fn:function(){return [_vm._v("\n                        Измените расположение или выберите другую запчасть.\n                    ")]},proxy:true}:{key:"text",fn:function(){return [_vm._v("\n                        Выберите другую запчасть.\n                    ")]},proxy:true}],null,true)})]]]:_vm._e(),_vm._v(" "),(!_vm.hasCar)?_c('EmptyBlock',{attrs:{"icon":"car","title":"Автомобиль не выбран","text":"Выберите автомобиль, чтобы увидеть подходящие запчасти."}},[_c('template',{slot:"button"},[_c('ButtonBlock',{staticClass:"empty__btn",attrs:{"primary":"","low":""},on:{"click":_vm.showSelectCarPopup}},[_vm._v("\n                Выбрать авто\n            ")])],1)],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }