<template>
    <PopupWrap
        fromBottomOnXS
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_text popup-content__title">
                {{ title }}
            </h2>

            <p class="d-inline p-textarea text">{{ text }}</p>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';

export default {
    name: "DescriptionPopup",

    components: {
        PopupWrap,
    },

    mixins: [popup],

    props: {
        text: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.text {
    line-height: var(--base-lh);
}

@media (max-width: 767px) {
    .popup-content__title {
        width: var(--col-5);
        font-size: 16px;
    }
}
</style>