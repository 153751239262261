<template>
    <div class="top-gap_medium">
        <Spinner
            v-if="!initialized"
            size="big"
            center
        ></Spinner>

        <template v-else>
            <h1
                v-if="selectedCar.page_title"
                class="h1 h1_block"
            >
                {{ selectedCar.page_title }}
            </h1>

            <div
                v-if="selectedCar && selectedCar.id"
                class="selected-car layer-1"
            >
                <CarManufacturerLogo
                    :manufacturer="selectedCar.manufacturer"
                    size="big"
                    mono
                    class="car-manufacturer-logo-wrap"
                ></CarManufacturerLogo>

                <div class="selected-car__name-characteristics">
                    <CarName
                        :car="selectedCar"
                        noManufacturerName
                        class="car__name"
                    ></CarName>

                    <CarCharacteristicsWithTooltip
                        :car="selectedCar"
                        class="car__params"
                    ></CarCharacteristicsWithTooltip>
                </div>

                <ButtonBlock
                    :to="{
                        name: 'market-parts-catalogs',
                    }"
                    primary
                    low
                    class="selected-car__btn"
                >
                    Изменить автомобиль
                </ButtonBlock>
            </div>

            <section class="nodes-catalog-section layer-1">
                <div class="nodes-catalog__layout">
                    <div class="nodes-catalog__tree">
                        <PartsTree
                            ref="groupsTree"
                            :nodes="catalogGroups"
                            :selectedNode="selectedNode"
                            @change="changeSelectedNode"
                        ></PartsTree>
                    </div>

                    <div class="nodes-catalog__result-wrap">
                        <Spinner
                            v-if="schemesLoading"
                            center
                            size="big"
                        ></Spinner>

                        <template v-else>
                            <div
                                v-if="catalogSchemes.current_count"
                                class="nodes-catalog__result"
                            >
                                <p
                                    v-if="selectedNodeParent && selectedNodeParent.name"
                                    class="nodes-catalog__result-breadcrumbs"
                                >
                                    {{ selectedNodeParent.name }}
                                </p>
                                <h2
                                    v-if="catalogSchemes.current_count"
                                    class="h2 nodes-catalog__result-title"
                                >
                                    {{ selectedNode.page_title }}
                                </h2>

                                <button
                                    v-if="catalogSchemes.current_count"
                                    class="dashed text_red select-node__btn_mobile"
                                    @click="showSelectNodesTreePopup"
                                >Выбрать другой узел</button>

                                <ul class="nodes-catalog__scheme-list">
                                    <li
                                        v-for="item in catalogSchemes.results"
                                        :key="'catalog-scheme' + item.id"
                                        class="node-scheme"
                                    >
                                        <SchemeGridItem
                                            :scheme="item"
                                            @schemeClick="openSchemePopupHandler(item.id)"
                                        ></SchemeGridItem>
                                    </li>
                                </ul>
                            </div>

                            <EmptyBlock
                                v-else
                                icon="parts"
                                :hasBtn="false"
                                class="empty-block"
                            >
                                <template #text>
                                    <p class="empty__text empty__text_desktop">
                                        Выберите узел из каталога, затем подберите запчасти на схеме.
                                    </p>
                                    <p class="empty__text empty__text_mobile">
                                        <button
                                            class="dashed text_red"
                                            @click="showSelectNodesTreePopup"
                                        >Выбрать узел из каталога</button>
                                    </p>
                                </template>
                            </EmptyBlock>
                        </template>
                    </div>
                </div>
            </section>

            <template v-if="meta.seo_description">
                <SeoBlock>
                    <template
                        #seo
                    >{{ meta.seo_description }}</template>
                </SeoBlock>
            </template>
        </template>

        <LastSee></LastSee>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import marketPartsCatalogCarModification from '@/store/modules/marketPartsCatalogCarModification.js';
import LastSee from "@/components/LastSee.vue";
import PartsTree from '@/components/PartsTree.vue';
import SchemeGridItem from '@/components/SchemeGridItem.vue';
import EmptyBlock from "@/components/EmptyBlock.vue";
import CarManufacturerLogo from "@/components/CarManufacturerLogo.vue";
import CarName from "@/components/CarName.vue";
import CarCharacteristicsWithTooltip from "@/components/CarCharacteristicsWithTooltip.vue";
import SelectPartsNodesCatalog from "./SelectPartsNodesCatalog.vue";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import Spinner from "@/components/Spinner.vue";
import SeoBlock from '@/components/SeoBlock.vue';
const SchemePopup = () => import('./SchemePopup.vue');

export default {
    name: "NodeManufacturerPartsCatalog",

    metaInfo() {
        return {
            title: this.meta.meta_title,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content:this.meta.meta_title },
                { property: 'og:description', content: this.meta.meta_description },
                { property: 'og:image', content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        SeoBlock,
        Spinner,
        ButtonBlock,
        CarName,
        CarCharacteristicsWithTooltip,
        EmptyBlock,
        CarManufacturerLogo,
        SchemeGridItem,
        PartsTree,
        LastSee,
    },

    serverPrefetch() {
        return this.INIT({ id: this.$route.params.modification, production_date: this.$route.query.production_date });
    },

    data() {
        return {
            schemesLoading: false,
            catalogSchemes: {},
            selectedNode: {
                id: null,
                name: '',
            },
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.marketPartsCatalogCarModification.initialized,
            selectedCar: state => state.marketPartsCatalogCarModification.car,
            catalogGroups: state => state.marketPartsCatalogCarModification.catalogGroupsTree,
        }),

        ...mapGetters({
            productionDate: 'marketPartsCatalogCarModification/productionDate',
        }),

        selectedNodeParent() {
            return this.catalogGroups.find(group => group.id === this.selectedNode.parent);
        },

        meta() {
            return this.$route.params.group ? this.selectedNode : this.selectedCar;
        },
    },

    watch: {
        '$route.params.modification'() {
            this.destroy();
        },

        '$route.params.group'(value) {
            if (!value) {
                this.schemesLoading = false;
                this.catalogSchemes = {};
                this.selectedNode = {
                    id: null,
                    name: '',
                };
            }
            this.setBreadcrumbs();
        },

        '$route.query.s'(value) {
            if (value) {
                setTimeout(() => {
                    this.openSchemePopup(value);
                });
            }
        },
    },

    created() {
        this.$store.registerModule('marketPartsCatalogCarModification', marketPartsCatalogCarModification);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('marketPartsCatalogCarModification');
    },

    methods: {
        ...mapMutations({
            DESTROY: 'marketPartsCatalogCarModification/destroy',
        }),

        ...mapActions({
            INIT: 'marketPartsCatalogCarModification/init',
        }),

        async init() {
            if (!this.initialized) {
                await this.INIT({ id: this.$route.params.modification, production_date: this.$route.query.production_date });
            }

            if (this.$route.params.group) {
                await this.getCatalogGroup({ id: this.$route.params.group });
                this.changeSelectedNode(this.selectedNode);
                this.$refs.groupsTree.handleNodeClick({ id: this.selectedNode.parent });
                this.getSchemes({ id: this.$route.params.group });
            }
            this.setBreadcrumbs();

            // Менеджер попапов не успевает повесить событие, поэтому через setTimeout
            setTimeout(() => {
                if (this.$route.query.s) {
                    this.openSchemePopup(this.$route.query.s);
                }
            });
        },

        destroy() {
            this.DESTROY();
            this.schemesLoading = false;
            this.catalogSchemes = {};
            this.selectedNode = {
                id: null,
                name: '',
            };
            this.init();
        },

        setBreadcrumbs() {
            let breadcrumbs = [
                {
                    to: {
                        name: 'market',
                    },
                    title: 'Запчасти и автотовары',
                },
                {
                    to: {
                        name: 'market-parts-catalogs',
                    },
                    title: 'Каталоги автозапчастей',
                },
                {
                    to: {
                        name: 'market-parts-catalogs-manufacturer',
                        params: {
                            manufacturer: this.selectedCar.manufacturer.codename,
                        },
                    },
                    title: this.selectedCar.manufacturer.name,
                },
                {
                    to: {
                        name: 'market-parts-catalogs-manufacturer-modification',
                        params: {
                            manufacturer: this.selectedCar.manufacturer.codename,
                            modification: this.$route.params.modification,
                        },
                    },
                    title: `${ this.selectedCar.family.name }, ${ this.selectedCar.start_year } – ${ this.selectedCar.end_year === 9999 || !this.selectedCar.end_year ? 'н.в.' : this.selectedCar.end_year }`,
                },
            ];

            if (this.selectedNode.id) {
                breadcrumbs.push({
                    to: {
                        name: 'market-parts-catalogs-manufacturer-modification',
                        params: {
                            manufacturer: this.selectedCar.manufacturer.codename,
                            modification: this.$route.params.modification,
                            group: this.selectedNode.parent,
                        },
                    },
                    title: this.selectedNode.name,
                });
            }

            this.$breadcrumbs(breadcrumbs);
        },

        async getSchemes(group) {
            this.schemesLoading = true;
            const params = {
                car_id: this.selectedCar.id,
                group_id: group.id,
                limit: 100,
                offset: 0,
                order_by: '-start_at',
            };
            if (this.productionDate) params.production_date = this.productionDate;

            await this.$api.catalogSchemes.get({ params })
                .then(response => {
                    this.catalogSchemes = response;
                })
                .finally(() => {
                    this.schemesLoading = false;
                });
        },

        openSchemePopupHandler(schemeId) {
            if (!this.$route.query.s) {
                const location = {
                    name: 'market-parts-catalogs-manufacturer-modification',
                    params: {
                        manufacturer: this.$route.params.manufacturer,
                        modification: this.$route.params.modification,
                        group: this.$route.params.group,
                    },
                    query: {
                        s: schemeId,
                    },
                };
                if (this.productionDate) location.query.production_date = this.productionDate;
                this.$router.replace(location);
            }
        },

        openSchemePopup(schemeId) {
            const props = {
                schemeId: schemeId,
                selectedCar: this.selectedCar,
                productionDate: this.productionDate,
                callback: this.handleGroupChange,
                setSchemeData: this.setSchemeData,
            };
            const options = { props };
            this.$popup(SchemePopup, options);
        },

        setSchemeData(data) {
            let popupSelectedNode = data.groups[data.groups.length - 1];
            if (this.selectedNode.id !== popupSelectedNode.id) {
                this.selectedNode = popupSelectedNode;
                this.changeSelectedNode(this.selectedNode);
                this.$refs.groupsTree.handleNodeClick(data.groups[data.groups.length - 2]);
            }
        },

        async handleGroupChange(group) {
            this.getCatalogGroup(group);
            await this.getSchemes(group);
            if (this.catalogSchemes.current_count === 1) {
                this.openSchemePopupHandler(this.catalogSchemes.results[0].id);
            }
        },

        async getCatalogGroup(group) {
            await this.$api.catalogGroups.get(group.id)
                .then(response => {
                    this.selectedNode = response;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        changeSelectedNode(node) {
            this.selectedNode = node || {};

            if (node && node.id) {
                this.getSchemes(node);
            }
            else {
                this.catalogSchemes = {};
            }

            if (this.$route.params.group !== node.id.toString()) {
                const location = {
                    name: 'market-parts-catalogs-manufacturer-modification',
                    params: {
                        manufacturer: this.$route.params.manufacturer,
                        modification: this.$route.params.modification,
                        group: node.id,
                    },
                    query: {
                        s: this.$route.query.s,
                    },
                };
                if (this.productionDate) location.query.production_date = this.productionDate;
                this.$router.replace(location);
            }
        },

        showSelectNodesTreePopup() {
            const props = {
                catalogGroups: this.catalogGroups,
                selectedCar: this.selectedCar,
                callback: this.changeSelectedNode,
            };
            const options = { props };
            this.$popup(SelectPartsNodesCatalog, options);
        },
    },
};
</script>

<style scoped>
.nodes-catalog-section {
    padding: var(--gap-y-small);
}

@media (min-width: 1281px) {
    .nodes-catalog__layout {
        grid-template-columns: 360px auto;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .nodes-catalog__layout {
        grid-template-columns: 320px auto;
    }
}
@media (min-width: 1040px) {
    .nodes-catalog__layout {
        display: grid;
        grid-column-gap: 20px;
    }

    .empty__text_mobile,
    .select-node__btn_mobile {
        display: none;
    }

    .empty__text_desktop {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 1039px) {
    .empty__text_desktop,
    .nodes-catalog__tree {
        display: none;
    }
}

@media (min-width: 1040px) {
    .nodes-catalog__result-title {
        margin-top: 8px;
    }
}

@media (min-width: 1040px) {
    .nodes-catalog__result-breadcrumbs {
        display: none;
    }
}
@media (max-width: 1039px) {
    .nodes-catalog__result-breadcrumbs {
        margin-bottom: 8px;
        font-size: var(--small-fz);
        color: var(--font-secondary-color);
    }
}

.nodes-catalog__scheme-list {
    margin-top: var(--indent-h2-block);
}

@media (min-width: 1281px) {
    .nodes-catalog__scheme-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1040px) and (max-width: 1280px) {
    .nodes-catalog__scheme-list {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .nodes-catalog__scheme-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 414px) and (max-width: 767px) {
    .nodes-catalog__scheme-list {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 414px) {
    .nodes-catalog__scheme-list {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

@media (max-width: 413px) {
    .node-scheme:not(:last-child) {
        margin-bottom: var(--y-grid-gap);
    }
}

.select-node__btn_mobile {
    margin-top: 8px;
}

.selected-car {
    margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .selected-car {
        flex-wrap: wrap;
    }
}
@media (min-width: 768px) {
    .selected-car {
        display: flex;
        align-items: center;
        padding: 24px 36px;
    }
}
@media (max-width: 767px) {
    .selected-car {
        padding: 24px;
    }
}

.car-manufacturer-logo-wrap {
    margin-right: 20px;
}
@media (max-width: 767px) {
    .car-manufacturer-logo-wrap {
        display: none;
    }
}

.selected-car__name-characteristics {
    flex-grow: 1;
}
@media (min-width: 1040px) {
    .selected-car__name-characteristics {
        display: flex;
        align-items: center;
    }
}

.car__name {
    font-size: var(--adaptive-big-fz);
}
@media (min-width: 1040px) {
    .car__name {
        width: 220px;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 20px;
    }
}
@media (max-width: 1039px) {
    .car__name {
        margin-bottom: 4px;
    }
}

@media (min-width: 768px) {
    .car__params {
        flex-grow: 1;
        margin-right: 24px;
    }
}
@media (max-width: 767px) {
    .car__params {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .selected-car__btn {
        width: 100%;
    }
}

.empty-block {
    box-shadow: none;
    margin-top: calc(var(--gap-y-small) * -1);
    margin-bottom: calc(var(--gap-y-small) * -1);
}
</style>