<template>
    <p class="agreement">
        Нажимая кнопку «{{ text }}», вы соглашаетесь c&nbsp;<a
            href="/about/privacy-policy/"
            target="_blank"
        >Политикой&nbsp;конфиденциальности</a>
        и&nbsp;<a
            href="/about/rules/"
            target="_blank"
        >Правилами&nbsp;использования&nbsp;сайта</a>
    </p>
</template>

<script>
export default {
    name: 'FormAgreement',

    props: {
        text: {
            type: String,
            default: 'Продолжить',
        },
    },
};
</script>

<style scoped>
.agreement {
    font-size: 12px;
    color: var(--font-secondary-color);
}

.agreement a {
    text-decoration-line: underline;
    text-decoration-color: rgb(162, 167, 171, .5);
    text-underline-offset: 2px;
    transition: color var(--transition), text-decoration-color var(--transition);
}

.agreement a:hover,
.agreement a:focus {
    text-decoration-color: transparent;
}
</style>