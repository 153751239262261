let options = {
    url: '',
    reconnectInterval: 1000,
    maxReconnectInterval: 300000,
};
let ws;
const listeners = {};
let queue = [];
let isOpened = false;
let isInitialized = false;

const register = (eventName, callback) => {
    if (!listeners[eventName]) listeners[eventName] = [];

    listeners[eventName].push(callback);
};

const unregister = (eventName, callback) => {
    if (!callback) {
        // удаление всех колбэков, если не передана конкретная функция
        delete listeners[eventName];
        return;
    }

    const callbacks = listeners[eventName];

    if (!callbacks || !callbacks.length) return;

    const index = callbacks.indexOf(callback);

    if (index !== -1) {
        delete callbacks[index];
    }
};

const init = data => {
    Object.assign(options, data);
};

let reconnectInterval = options.reconnectInterval;

const connect = () => {
    if (isInitialized) return;

    isInitialized = true;

    ws = new WebSocket(options.url);

    ws.onopen = () => {
        console.log('ws opened');
        isOpened = true;
        // Restart reconnect interval
        reconnectInterval = options.reconnectInterval;

        if (queue.length) {
            queue.forEach(callback => {
                callback();
            });

            queue = [];
        }
    };

    ws.onmessage = event => {
        // New message from the backend - use JSON.parse(event.data)
        // handleNotification(event);
        const data = JSON.parse(event.data);
        const name = data.event;
        const message = data.data;
        const callbacks = listeners[name];

        if (callbacks && callbacks.length) {
            callbacks.forEach(callback => {
                callback(message);
            });
        }
    };

    ws.onclose = event => {
        console.log('websocket is closed', event.code);
        isOpened = false;
        isInitialized = false;

        if (event) {
            // Event.code 1000 is our normal close event
            if (event.code !== 1000) {
                if (reconnectInterval < options.maxReconnectInterval) {
                    // Reconnect interval can't be > x seconds
                    reconnectInterval *= 2;
                }

                console.log('websocket next try:', reconnectInterval / 1000 + 's');

                setTimeout(() => {
                    connect();
                }, reconnectInterval);
            }
        }
    };

    ws.onerror = error => {
        console.log(error);
        ws.close();
    };
};

const on = (eventName, callback) => {
    register(eventName, callback);
};

const off = (eventName, callback) => {
    unregister(eventName, callback);
};

const disconnect = () => {
    // Our custom disconnect event
    if (ws) ws.close();
};

const emit = (eventName, payload = {}) => {
    const data = { event: eventName, data: payload };

    if (ws) {
        ws.send(JSON.stringify(data));
    }
    // else {
    //     queue.push(data);
    // }
};

const onopen = callback => {
    if (isOpened) {
        callback();
    }
    else {
        queue.push(callback);
    }
};

export default {
    init,
    connect,
    disconnect,
    isOpened,
    isInitialized,
    on,
    off,
    emit,
    onopen,
};