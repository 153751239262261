export default {
    props: {
        // Обязательные параметры для любого попапа
        _index: {
            type: Number,
            required: true,
        },

        callback: {
            type: Function,
            default: () => ({}),
        },
    },

    methods: {
        confirm() {
            this.callback(true);
            this.close();
        },

        cancel() {
            this.callback(false);
            this.close();
        },

        close() {
            this.$emit('close', this._index);
        },
    },
};