const errorTextForRequiredInput = 'Заполните поле';
const errorTextForRequiredSelect = 'Выберите значение';
const regexpErrorText = 'Поле заполнено неверно';

const errorTextForRequired = {
    string: errorTextForRequiredInput,
    text: errorTextForRequiredInput,
    date: errorTextForRequiredInput,
    datetime: errorTextForRequiredInput,
    time: errorTextForRequiredInput,
    year: errorTextForRequiredInput,
    number: errorTextForRequiredInput,
    select: errorTextForRequiredSelect,
    address: errorTextForRequiredSelect,
    geo: 'Укажите адрес или точку на карте',
    geocode: 'Укажите адрес',
    checkboxes: 'Выберите хотя бы один пункт',
    tabs: 'Выберите один пункт',
    radio: 'Выберите один пункт',
    rating: 'Поставьте оценку',
    stars: 'Поставьте оценку',
};

export default {
    regexp: regexpErrorText,
    re: regexpErrorText,

    required({ type }) {
        return errorTextForRequired[type] || errorTextForRequiredInput;
    },

    min({ value, type, params }) {
        let [min] = params;

        if (type === 'date' || type === 'calendar') {
            min = min.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1');
        }

        return 'Значение должно быть больше или равно ' + min;
    },

    max({ value, type, params }) {
        let [max] = params;

        if (type === 'date' || type === 'calendar') {
            max = max.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1');
        }

        return 'Значение должно быть меньше или равно ' + max;
    },

    time() {
        return 'Укажите время в формате: ЧЧ:MM';
    },

    date() {
        return 'Укажите дату в формате: ДД.ММ.ГГГГ';
    },

    datetime() {
        return 'Укажите дату и время в формате: ДД.ММ.ГГГГ ЧЧ:MM';
    },

    year() {
        return regexpErrorText;
    },

    email() {
        return 'Укажите правильный адрес электронной почты';
    },

    url() {
        return 'Укажите URL в формате: http://link или https://link';
    },

    phone() {
        return 'Укажите телефон в\u00A0формате:\u00A0+7\u00A0(000)\u00A0000\u00A000\u00A000';
    },

    minLength() {
        return regexpErrorText;
    },

    alphanumeric() {
        return regexpErrorText;
    },

    sameAs() {
        return regexpErrorText;
    },

    password() {
        return 'Пароль должен быть не короче шести символов и может состоять только из букв латинского алфавита, ' +
            'цифр или символов: ` ? ! @ # $ % ^ & * ( ) – _ - = + [ ] { } ; : " | / , . ~';
    },
};