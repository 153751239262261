<template>
    <div
        v-if="computedInfo.title || computedInfo.descriptor"
        class="info-page-head"
    >
        <h1
            v-if="computedInfo.title"
            class="h1"
        >
            {{ computedInfo.title }}
        </h1>
        <p
            v-if="computedInfo.descriptor"
            class="info-page-descriptor"
        >
            {{ computedInfo.descriptor }}
        </p>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "InfoPageLayoutHead",

    props: {
        customInfo: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        ...mapState({
            info: state => state.staticPages.data,
        }),

        computedInfo() {
            if (this.customInfo) {
                return {
                    title: this.customInfo.title || this.customInfo.name,
                    descriptor: this.customInfo.descriptor,
                };
            }
            else {
                return this.info;
            }
        },
    },
};
</script>

<style scoped>
.info-page-head {
    margin-bottom: var(--gap-y-mini);
}

.info-page-descriptor {
    margin-top: var(--indent-h1-text);
    line-height: var(--base-lh);
}
@media (min-width: 1281px) {
    .info-page-descriptor {
        width: var(--col-12);
    }
}
</style>