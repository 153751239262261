<template>
    <PopupWrap @close="close">
        <div class="popup-content">
            <FormManager
                ref="form"
                v-model="formData"
                @submit="submit"
            >
                <h2 class="h2 h2_block">
                    Оставить жалобу на отзыв
                </h2>

                <h4 class="h4 h4_block">
                    Укажите причину жалобы:
                </h4>

                <FormField
                    name="reason"
                    type="radio"
                    :options="[
                        {
                            label: 'Оскорбительный контент',
                            value: 'abuse',
                        },
                        {
                            label: 'Информация не соответствует действительности',
                            value: 'scam',
                        },
                        {
                            label: 'Ошибки в тексте отзыва',
                            value: 'mistake',
                        },
                        {
                            label: 'Другая причина',
                            value: 'custom',
                        },
                    ]"
                    class="mb-20"
                ></FormField>

                <FormField
                    name="complaint"
                    type="text"
                    label="Причина жалобы"
                    :disabled="formData.reason !== 'custom'"
                    :validations="formData.reason === 'custom' ? 'required' : ''"
                ></FormField>
            </FormManager>

            <div class="footer">
                <InfoMessage class="info-message">
                    <p>
                        Мы внимательно рассмотрим жалобу и снимем с публикации на сайте отзыв,
                        если он не соответствует <ButtonText
                            primary
                            underline
                        >Пользовательскому соглашению</ButtonText>.
                    </p>
                </InfoMessage>

                <div class="actions-wrap">
                    <ButtonBlock
                        secondary
                        class="action-btn"
                        @click="cancel"
                    >
                        Отмена
                    </ButtonBlock>
                    <ButtonBlock
                        submit
                        primary
                        :loading="loading"
                        class="action-btn"
                        @click="submit"
                    >
                        Отправить
                    </ButtonBlock>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';


export default {
    name: 'ComplainReviewPopup',

    components: {
        ButtonText,
        InfoMessage,
        ButtonBlock,
        FormField,
        FormManager,
        PopupWrap,
    },

    mixins: [
        popup,
    ],

    inheritAttrs: false,

    props: {
        part: Object,
        product: Object,
        shop: Object,
        review: Object,
    },

    data() {
        const reasons = Object.freeze({
            abuse: 'Оскорбительный контент',
            scam: 'Информация не соответствует действительности',
            mistake: 'Ошибки в тексте ответа',
        });

        return {
            reasons,
            formData: {
                reason: 'abuse',
                complaint: '',
            },

            loading: false,
        };
    },

    methods: {
        async submit() {
            const errors = await this.$refs.form.validate();

            if (errors) return;

            this.loading = true;

            const data = this.getData();

            try {
                const rq = this.shop
                    ? this.$api.shops.reviews.addComplaint
                    : this.part
                        ? this.$api.parts.reviews.addComplaint
                        : this.$api.products.reviews.addComplaint;
                const id = (this.shop || this.part || this.product).id;
                await rq(id, this.review.uuid, data);
                this.$success('Сообщение отправлено, мы рассмотри вашу жалобу и примем меры');
                this.callback(true);
                this.close();
            }
            catch (error) {
                this.$refs.form.handle(error);
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.loading = false;
            }
        },

        getData() {
            const complaint = this.formData.reason === 'custom'
                ? this.formData.complaint
                : this.reasons[this.formData.reason];

            return { complaint };
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: calc(580px + 48px + 48px);
    }
}

@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) {
    .footer {
        padding-top: 36px;
    }
}
@media (max-width: 767px) {
    .footer {
        padding-top: 28px;
        margin-top: auto;
    }
}

@media (min-width: 768px) {
    .info-message {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .info-message {
        margin-bottom: 16px;
    }
}
</style>