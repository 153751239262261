<template>
    <component
        :is="tag"
        :disabled="disabled"
        v-bind="attrs"
        :class="[
            'button',
            {
                primary,
                secondary,
                circle,
                small,
                'hover-primary': hoverPrimary,
            },
        ]"
        v-on="$listeners"
    >
        <Spinner
            v-if="loading"
            size="small"
        ></Spinner>
        <template v-else>
            <slot>
                <UIcon v-bind="icon"></UIcon>
            </slot>
        </template>
    </component>
</template>

<script>
import buttonMixin from './buttonMixin.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import Spinner from "@/components/Spinner.vue";


export default {
    name: "ButtonIcon",

    components: {
        Spinner,
        UIcon,
    },

    mixins: [
        buttonMixin,
    ],

    props: {
        icon: Object,

        primary: Boolean,
        secondary: {
            type: Boolean,
            default: true,
        },
        small: Boolean,

        circle: Boolean,
        hoverPrimary: Boolean,
    },
};
</script>

<style scoped>
.button {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--border-radius);
    text-decoration: none;
    transition:
        background-color var(--transition),
        border-color var(--transition),
        fill var(--transition);
}

.button.small {
    width: 24px;
    height: 24px;
}

.button.secondary {
    fill: var(--dark-c);
    background-color: var(--light-c);
    border: 1px solid var(--border-dark-c);
}

.button.secondary:hover,
.button.secondary:focus {
    background-color: var(--lightest-bg);
}

.button.secondary:active {
    background-color: var(--light-bg);
    border-color: var(--border-dark-c);
}

.button.secondary:disabled,
.button.secondary.disabled {
    fill: var(--font-secondary-light-color);
    border-color: var(--border-light-c);
}

.button.secondary.hover-primary:hover,
.button.secondary.hover-primary:focus {
    background-color: var(--light-c);
    border-color: var(--border-dark-c);
}

.button.hover-primary:hover,
.button.hover-primary:focus {
    fill: var(--primary-color);
}

.button.primary {
    fill: var(--light-c);
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.button.primary:hover,
.button.primary:focus {
    background-color: var(--primary-dark-color);
    border-color: var(--primary-dark-color);
}

.button.primary:active {
    background-color: var(--primary-darkest-color);
    border-color: var(--primary-darkest-color);
}

.button.primary:disabled,
.button.primary.disabled {
    background-color: var(--primary-light-color);
    border-color: var(--primary-light-color);
}

.button.circle {
    border-radius: 50%;
}
</style>