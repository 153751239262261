<template>
    <div
        v-if="valueComputed > 0"
        :class="['stars-rating', 'stars-rating_' + size]"
    >
        <UIcon
            v-for="i in floor"
            :key="i + 'star-fill'"
            class="stars-rating__icon stars-rating__icon_fill"
            :class="{ 'stars-rating__icon_blocked': blocked }"
            name="star-fill"
        ></UIcon>

        <UIcon
            v-if="decimal !== 0"
            class="stars-rating__icon"
            :class="{ 'stars-rating__icon_blocked': blocked }"
            name="star-half"
        ></UIcon>

        <UIcon
            v-for="i in rest"
            :key="i + 'star-none'"
            class="stars-rating__icon stars-rating__icon_none"
            :class="{ 'stars-rating__icon_blocked': blocked }"
            name="star-none"
        ></UIcon>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'StarsRating',

    components: {
        UIcon,
    },

    props: {
        value: {
            type: [Number, String, Object],
            default: 0,
        },

        size: {
            type: String,
            validator(value) {
                return ['base', 'big'].includes(value);
            },
            default: 'base',
        },

        showValue: {
            type: Boolean,
            default: true,
        },

        blocked: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        valueComputed() {
            if (this.value) {
                let valueComputed = this.value.toString();
                if (valueComputed.includes('.')) return valueComputed;
                else return valueComputed + '.0';
            }
            else {
                return 0;
            }
        },

        decimal() {
            if (this.value) {
                return this.getDecimal(+this.value);
            }
            else {
                return 0;
            }
        },

        floor() {
            if (this.value) {
                return Math.floor(+this.value);
            }
            else {
                return 0;
            }
        },

        rest() {
            if (this.value) {
                return 5 - Math.ceil(+this.value);
            }
            else {
                return 5;
            }
        },
    },

    methods: {
        getDecimal(num) {
            let result;
            let initialNum = Math.abs(+num);
            let roundedNum = Math.round(initialNum);

            if (roundedNum > initialNum) {
                result = roundedNum - initialNum - 1;
                result = Math.abs(result);
                result = +result.toFixed(10);
            }
            else {
                result = initialNum - roundedNum;
                result = +result.toFixed(10);
            }

            return result;
        },
    },
};
</script>

<style scoped>
.stars-rating {
    display: flex;
    flex-shrink: 0;
}

.stars-rating__icon_fill {
    fill: var(--accent-yellow);
}

.stars-rating__icon_fill.stars-rating__icon_blocked {
    fill: #FFE3B3;
}

.stars-rating__icon_none {
    fill: #EDF0F2;
}

.stars-rating_base .stars-rating__icon {
    width: 16px;
    height: 16px;
}

.stars-rating_base .stars-rating__icon:not(:last-child) {
    margin-right: 2px;
}

@media (min-width: 768px) {
    .stars-rating_big .stars-rating__icon {
        width: 20px;
        height: 20px;
    }

    .stars-rating_big .stars-rating__icon:not(:last-child) {
        margin-right: 4px;
    }
}
@media (max-width: 767px) {
    .stars-rating_big .stars-rating__icon {
        width: 16px;
        height: 16px;
    }

    .stars-rating_big .stars-rating__icon:not(:last-child) {
        margin-right: 2px;
    }
}
</style>