<template>
    <ChatTemplate class="chat-about">
        <template #header>
            <div class="chat-about__header-wrap">
                <div class="chat-about__header">
                    <div class="chat-about__header-back">
                        <button
                            tabindex="-1"
                            type="button"
                            class="flex align-items-center"
                            @click="close"
                        >
                            <UIcon
                                name="back"
                                big
                                class="chat-about__back-icon"
                            ></UIcon>
                        </button>
                    </div>

                    <b class="chat-about__title">
                        О магазине
                    </b>

                    <div class="chat-about__header-close">
                        <button
                            type="button"
                            aria-label="Закрыть чат"
                            class="chat-about__mobile-close-button"
                            @click="$emit('closeChat')"
                        >
                            <UIcon
                                name="close-24"
                                big
                                class="chat-about__mobile-close-icon"
                            ></UIcon>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <div v-if="loading" class="chat-about__loading">
                <USpinner big></USpinner>
            </div>

            <template v-else>
                <div class="chat-about__shop w100 flex align-items-center mb-12">
                    <ShopLogo
                        :shop="shop"
                        coverCodename="medium"
                        noLink
                        class="mr-20"
                    ></ShopLogo>

                    <ShopName
                        :shop="shop"
                        noLink
                        class="mr-20"
                    ></ShopName>

                    <LikeBtn
                        :id="shop.id"
                        :value="shop.is_favorite"
                        apiName="shop"
                        class="ml-auto"
                        @changeFavorite="onChangeFavorite"
                    ></LikeBtn>
                </div>

                <UTabs
                    v-if="tabs.length > 1"
                    v-model="currentTab"
                    :items="tabs"
                    class="chat-about__tabs"
                ></UTabs>

                <div v-if="currentTab.name === 'info'" class="chat-about__page chat-about__info">
                    <div class="chat-about__page-content">
                        <ShopAddress
                            :shop="shop"
                            :showOnMap="false"
                            class="mb-4"
                        ></ShopAddress>

                        <ShopOpenCloseTime
                            :shopId="shop.id"
                            :status="shop.current_work"
                            withoutTooltip
                            class="mb-16"
                        ></ShopOpenCloseTime>

                        <MarketItemRatingReviewsLink
                            :item="shop"
                            modelName="shop"
                            class="mb-20"
                        ></MarketItemRatingReviewsLink>

                        <DeliveryInfo
                            :currentWork="shop.current_work"
                            list
                            class="mb-20"
                        ></DeliveryInfo>

                        <ShopWorkSchedule
                            :work="shop.work_times"
                            :holidays="shop.holidays"
                            class="mb-20"
                        ></ShopWorkSchedule>

                        <PointOnMapPreviewBtn
                            :coords="shop.coordinates"
                            class="chat-about__map-wrap"
                            @click="openMap"
                        ></PointOnMapPreviewBtn>

                        <div v-if="shop.company.description" class="chat-about__shop-description mb-20">
                            {{ shop.company.description }}
                        </div>

                        <ButtonBlock
                            :to="{
                                name: 'market-shop-stock',
                                params: {
                                    codename: shop.company.codename,
                                    id: shop.id
                                }
                            }"
                            secondary
                            class="w100"
                        >
                            На страницу магазина
                        </ButtonBlock>
                    </div>
                </div>

                <div v-if="currentTab.name === 'links'" class="chat-about__page chat-about__links">
                    <div class="chat-about__page-content">
                        <div
                            v-for="item in linksItems"
                            :key="item.id"
                            class="chat-about__item chat-about__link-item"
                        >
                            <div v-if="item.type === 'divider'" class="chat-about__divider">
                                {{ item.date | date }}
                            </div>

                            <div
                                v-else
                                role="button"
                                tabindex="0"
                                aria-label="Перейти к сообщению"
                                class="chat-about__link flex"
                                @click="onClickLink($event, item.obj)"
                            >
                                <div class="mr-12 flex-initial chat-about__link-image-wrap">
                                    <img
                                        v-if="item.obj.link.og_data &&
                                            item.obj.link.og_data['og:image'] &&
                                            item.obj.link.og_data['og:image'].includes('http')"
                                        :src="item.obj.link.og_data['og:image']"
                                        alt=" "
                                        class="chat-about__link-image"
                                    >
                                    <img
                                        v-else
                                        src="@/assets/images/cover-placeholder.svg"
                                        alt=" "
                                        class="chat-about__link-image"
                                    >
                                </div>

                                <div class="mr-12 flex-grow-1 chat-about__link-main-block">
                                    <div
                                        v-if="item.obj.link.og_data &&
                                            (item.obj.link.og_data['og:title'] || item.obj.link.og_data.title)"
                                        class="ellipsis"
                                    >
                                        <span
                                            class="text_semi-bold"
                                        >{{ item.obj.link.og_data['og:title'] || item.obj.link.og_data.title }}</span>
                                    </div>
                                    <div
                                        v-if="item.obj.link.og_data && item.obj.link.og_data['og:description']"
                                        class="chat-about__link-description"
                                    >
                                        <p>{{ item.obj.link.og_data['og:description'] }}</p>
                                    </div>
                                    <div class="chat-about__link-button-wrap ellipsis">
                                        <ButtonText
                                            primary
                                            underline
                                            :href="item.obj.link.url"
                                            target="_blank"
                                            class="max-w100"
                                        >
                                            {{ item.obj.link.url }}
                                        </ButtonText>
                                    </div>
                                </div>

                                <UMenu placement="bottom-end">
                                    <template #trigger="{ value, handlers, attrs }">
                                        <UMenuIcon
                                            class="flex-initial chat-about__menu-button"
                                            :active="value"
                                            v-bind="attrs"
                                            v-on="handlers"
                                        ></UMenuIcon>
                                    </template>

                                    <UMenuItem :href="item.obj.link.url" target="_blank">
                                        Перейти по ссылке
                                    </UMenuItem>
                                    <UMenuItem @click="onClickToMessage(item.obj)">
                                        Перейти к сообщению
                                    </UMenuItem>
                                </UMenu>
                            </div>
                        </div>

                        <div v-if="links.next" class="chat-about__more-wrap">
                            <USpinner v-if="links.fetching"></USpinner>

                            <ButtonText
                                v-else
                                dashed
                                secondary
                                dark
                                class="chat-about__more-button"
                                @click="more('links')"
                            >
                                Показать ещё
                            </ButtonText>
                        </div>
                    </div>
                </div>

                <div v-if="currentTab.name === 'offers'" class="chat-about__page chat-about__offers">
                    <div class="chat-about__page-content">
                        <div
                            v-for="item in offersItems"
                            :key="item.id"
                            class="chat-about__item chat-about__item-offer"
                        >
                            <div v-if="item.type === 'divider'" class="chat-about__divider">
                                {{ item.date | date }}
                            </div>

                            <div
                                v-else
                                role="button"
                                tabindex="0"
                                class="chat-about__offer flex"
                                @click="onClickOffer($event, item.obj)"
                            >
                                <div class="mr-12 flex-initial">
                                    <img
                                        v-if="item.obj.offer.item.cover"
                                        :src="$links.uploads
                                            + item.obj.offer.item.cover.thumbnails.market_item_cover_micro"
                                        :alt="item.obj.offer.name"
                                        class="chat-about__offer-image"
                                    >

                                    <img
                                        v-else
                                        src="@/assets/images/cover-placeholder.svg"
                                        alt=" "
                                        class="chat-about__offer-image"
                                    >
                                </div>

                                <div class="mr-12 flex-grow-1 flex justify-content-center flex-column">
                                    <span class="text_semi-bold">{{ item.obj.offer.item.name }}</span>
                                    <span class="mt-4 chat-about__offer-price">{{ item.obj.offer.price | price }}</span>
                                </div>

                                <UMenu placement="bottom-end">
                                    <template #trigger="{ value, handlers, attrs }">
                                        <UMenuIcon
                                            class="flex-initial chat-about__menu-button"
                                            :active="value"
                                            v-bind="attrs"
                                            v-on="handlers"
                                        ></UMenuIcon>
                                    </template>

                                    <UMenuItem
                                        :to="{
                                            name: offerNames[item.obj.offer.offer_type],
                                            params: {
                                                id: item.obj.offer.item.id,
                                            },
                                        }"
                                    >
                                        Показать на сайте
                                    </UMenuItem>
                                    <UMenuItem @click="onClickToMessage(item.obj)">
                                        Перейти к сообщению
                                    </UMenuItem>
                                </UMenu>
                            </div>
                        </div>

                        <div v-if="offers.next" class="chat-about__more-wrap">
                            <USpinner v-if="offers.fetching"></USpinner>

                            <ButtonText
                                v-else
                                dashed
                                secondary
                                dark
                                class="chat-about__more-button"
                                @click="more('offers')"
                            >
                                Показать ещё
                            </ButtonText>
                        </div>
                    </div>
                </div>

                <div v-if="currentTab.name === 'images'" class="chat-about__page chat-about__images">
                    <div class="chat-about__page-content">
                        <div
                            v-for="item in imagesItems"
                            :key="item.id"
                            class="chat-about__item chat-about__image-item"
                        >
                            <div v-if="item.type === 'divider'" class="chat-about__divider">
                                {{ item.date | date }}
                            </div>

                            <div v-else class="chat-about__images-grid">
                                <div
                                    v-for="groupItem in item.group"
                                    :key="groupItem.id"
                                    role="button"
                                    aria-label="Открыть изображение"
                                    class="chat-about__image-wrap"
                                    @click="onClickImage(groupItem.index)"
                                >
                                    <img
                                        :src="$links.uploads + groupItem.obj.mediafile.thumbnails.chat_crop_small"
                                        alt=" "
                                        class="chat-about__image"
                                    >

                                    <div class="chat-about__image-hover-background"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="currentTab.name === 'files'" class="chat-about__page chat-about__files">
                    <div class="chat-about__page-content">
                        <div
                            v-for="item in filesItems"
                            :key="item.id"
                            class="chat-about__item chat-about__file-item"
                        >
                            <div v-if="item.type === 'divider'" class="chat-about__divider">
                                {{ item.date | date }}
                            </div>

                            <div
                                v-else
                                role="button"
                                aria-label="Скачать файл"
                                class="chat-about__file"
                                @click="onClickFile($event, item.obj)"
                            >
                                <div class="flex-initial">
                                    <div class="chat-about__file-icon-wrap mr-12">
                                        <svg class="chat-about__file-icon"><use xlink:href="#file-loader"></use></svg>
                                        <span class="chat-about__file-icon-extension">{{ item.obj.file.extension }}</span>
                                    </div>
                                </div>

                                <div class="min-w0 flex-grow-1 mr-12 flex flex-column justify-content-center">
                                    <div class="text_semi-bold w100">
                                        <span class="inline-flex w100 justify-content-start">
                                            <span class="ellipsis">{{ clearName(item.obj.file.name, item.obj.file.extension) }}</span>
                                            <span v-if="item.obj.file.extension" class="flex-initial text-lowercase">.{{ item.obj.file.extension }}</span>
                                        </span>
                                    </div>
                                    <div class="chat-about__size mt-4">
                                        {{ item.obj.file.size }}
                                    </div>
                                </div>

                                <UMenu placement="bottom-end">
                                    <template #trigger="{ value, handlers, attrs }">
                                        <UMenuIcon
                                            class="flex-initial chat-about__menu-button"
                                            :active="value"
                                            v-bind="attrs"
                                            v-on="handlers"
                                        ></UMenuIcon>
                                    </template>

                                    <UMenuItem @click="onClickDownloadFile(item.obj)">
                                        Скачать
                                    </UMenuItem>
                                    <UMenuItem @click="onClickToMessage(item.obj)">
                                        Перейти к сообщению
                                    </UMenuItem>
                                </UMenu>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </ChatTemplate>
</template>

<script>
// utils
import { plural, price } from '@ui/filters';
import clearName from '@/lib/clearFileName.js';
import eventPath from '@/lib/eventPath.js';
import download from '@/lib/download.js';
import isValidDate from '@ui/utils/isValidDate.js';
// components
import ChatTemplate from '@/components/_chat/ChatTemplate.vue';
import UTabs from '@ui/components/UTabs/UTabs.vue';
import ShopLogo from '@/components/ShopLogo.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import ShopWorkSchedule from '@/components/ShopWorkSchedule.vue';
import ShopAddress from '@/components/ShopAddress.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import ShopOpenCloseTime from '@/components/ShopOpenCloseTime.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';
import DeliveryInfo from '@/components/DeliveryInfo.vue';
import ShopName from '@/components/ShopName.vue';
import PointOnMapPreviewBtn from '@/components/project-buttons/PointOnMapPreviewBtn.vue';
import UMenu from '@ui/components/UMenu/UMenu.vue';
import UMenuIcon from '@ui/components/UMenu/UMenuIcon.vue';
import UMenuItem from '@ui/components/UMenu/UMenuItem.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
const PointOnMapPopup = () => import('@/components/popups/PointOnMapPopup.vue');
const ChatImageViewPopup = () => import('@/components/chat/ChatImageViewPopup.vue');


export default {
    name: 'ChatAbout',

    components: {
        UIcon,
        UMenuItem,
        UMenuIcon,
        UMenu,
        PointOnMapPreviewBtn,
        ShopName,
        DeliveryInfo,
        MarketItemRatingReviewsLink,
        ButtonBlock,
        ShopOpenCloseTime,
        USpinner,
        ShopAddress,
        ShopWorkSchedule,
        LikeBtn,
        ButtonText,
        ShopLogo,
        UTabs,
        ChatTemplate,
    },

    filters: {
        plural,
        price,

        date(value) {
            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return value;

            const options = { month: 'long', year: 'numeric' };
            return new Intl.DateTimeFormat('ru-RU', options).format(date).slice(0, -3);
        },
    },

    props: {
        loading: Boolean,
        shop: Object,
        links: Object,
        offers: Object,
        images: Object,
        files: Object,
    },

    data() {
        return {
            offerNames: {
                product: 'market-product-description',
                part_product: 'market-new-part-description',
                contract_part: 'market-contract-part-description',
            },

            currentTab: {
                name: 'info',
                label: 'Инфо',
            },

            map: null,
        };
    },

    computed: {
        tabs() {
            const tabs = [{
                name: 'info',
                label: 'Инфо',
            }];

            if (this.links && this.links.results && this.links.results.length) {
                tabs.push({
                    name: 'links',
                    label: 'Ссылки',
                });
            }

            if (this.offers && this.offers.results && this.offers.results.length) {
                tabs.push({
                    name: 'offers',
                    label: 'Товары',
                });
            }

            if (this.images && this.images.results && this.images.results.length) {
                tabs.push({
                    name: 'images',
                    label: 'Изображения',
                });
            }

            if (this.files && this.files.results && this.files.results.length) {
                tabs.push({
                    name: 'files',
                    label: 'Файлы',
                });
            }

            return tabs;
        },

        linksItems() {
            if (!this.links || !this.links.results || !this.links.results.length) return [];

            return this.createItems(this.links.results);
        },

        offersItems() {
            if (!this.offers || !this.offers.results || !this.offers.results.length) return [];

            return this.createItems(this.offers.results);
        },

        imagesItems() {
            if (!this.images || !this.images.results || !this.images.results.length) return [];

            const items = [];
            let prevObj;
            let group = [];
            let timestampCount = 0;

            this.images.results.forEach((obj, index) => {
                if (index === 0) {
                    items.push({
                        type: 'divider',
                        id: 'divider-' + timestampCount,
                        date: obj.message.create_date,
                    });

                    timestampCount++;
                }

                if (this.shouldAddDivider(obj, prevObj)) {
                    items.push({
                        type: 'group',
                        group: [...group],
                    });

                    group = [];

                    items.push({
                        type: 'divider',
                        id: 'divider-' + timestampCount,
                        date: obj.message.create_date,
                    });

                    timestampCount++;
                }

                group.push({
                    obj,
                    index,
                    type: 'obj',
                    id: obj.mediafile.uuid,
                });

                prevObj = obj;
            });

            if (group.length) {
                items.push({
                    type: 'group',
                    group: [...group],
                });
            }

            return items;
        },

        filesItems() {
            if (!this.files || !this.files.results || !this.files.results.length) return [];

            const getId = obj => obj.file.uuid;

            return this.createItems(this.files.results, getId);
        },
    },

    methods: {
        clearName,

        createItems(arr, getId) {
            let prevObj;
            const items = [];
            let timestampCount = 0;

            arr.forEach((obj, index) => {
                if (index === 0 || this.shouldAddDivider(obj, prevObj)) {
                    items.push({
                        type: 'divider',
                        id: 'divider-' + timestampCount,
                        date: obj.message.create_date,
                    });

                    timestampCount++;
                }

                items.push({
                    obj,
                    index,
                    type: 'obj',
                    id: getId ? getId(obj) : 'obj-' + index,
                });

                prevObj = obj;
            });

            return items;
        },

        shouldAddDivider(currentObj, prevObj) {
            if (prevObj) {
                const prev = new Date(prevObj.message.create_date);
                const current = new Date(currentObj.message.create_date);

                if (
                    prev.getMonth() !== current.getMonth() ||
                    prev.getFullYear() !== current.getFullYear()
                ) {
                    return true;
                }
            }

            return false;
        },

        close() {
            this.$emit('close', { target: this });
        },

        onClickLink($event, obj) {
            const path = eventPath($event);

            if (path.some(el => el && el.classList && el.classList.contains('chat-about__menu-button'))) return;

            this.$emit('toMessage', obj);
            this.$emit('close');
        },

        onClickOffer($event, obj) {
            const path = eventPath($event);

            if (path.some(el => el && el.classList && el.classList.contains('chat-about__menu-button'))) return;

            this.$emit('toMessage', obj);
            this.$emit('close');
        },

        onClickToMessage(obj) {
            this.$emit('toMessage', obj);
            this.$emit('close');
        },

        onClickImage(initialIndex) {
            const obj = this.images.results[initialIndex];
            const initialItems = [...this.images.results];
            const toMessage = () => {
                this.$emit('toMessage', obj);
                this.$emit('close');
            };
            const props = { initialIndex, initialItems, toMessage };
            const options = { props };
            this.$popup(ChatImageViewPopup, options);
        },

        onClickFile($event, obj) {
            const path = eventPath($event);

            if (path.some(el => el && el.classList && el.classList.contains('chat-about__menu-button'))) return;

            this.$emit('toMessage', obj);
            this.$emit('close');
        },

        onClickDownloadFile(obj) {
            const url = this.$links.uploads + obj.file.url;
            const name = obj.file.name;
            download({ url, name });
        },

        more(name) {
            this.$emit('more', name);
        },

        onChangeFavorite(value) {
            if (this.$store.state.marketShop.shop.id === this.shop.id) {
                this.$store.commit('marketShop/changeFavorite', value);
            }

            this.$emit('changeFavoriteShop', value);
        },

        openMap() {
            const props = {
                shop: this.shop,
                coords: this.shop.coordinates,
            };
            const options = { props };
            this.$popup(PointOnMapPopup, options);
        },
    },
};
</script>

<style>
.chat-about__header-wrap {
    width: 100%;
    padding: 12px 24px;
    display: flex;
}
@media (max-width: 767px) {
    .chat-about__header-wrap {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

.chat-about__header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.chat-about__header-back {
    flex-shrink: 0;
}

.chat-about__title {
    flex-grow: 1;
    font-size: var(--big-fz);
    text-align: center;
}

.chat-about__header-close {
    width: 24px;
    flex-shrink: 0;
}

.chat-about__mobile-close-button {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .chat-about__mobile-close-button {
        display: none;
    }
}

.chat-about__mobile-close-icon {
    fill: var(--font-secondary-color);
    display: flex;
    align-items: center;
}

.chat-about__mobile-close-button:hover .chat-about__mobile-close-icon,
.chat-about__mobile-close-button:active .chat-about__mobile-close-icon {
    fill: var(--dark-c);
}

.chat-about__loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-about__shop {
    padding: 20px 24px;
    border-bottom: 1px solid var(--border-light-c);
}

@media (max-width: 767px) {
    .chat-about__shop {
        padding-left: var(--content-gap);
        padding-right: var(--content-gap);
    }
}

.chat-about__tabs {
    padding: 0 24px;
    flex-shrink: 0;
}
@media (max-width: 767px) {
    .chat-about__tabs {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

.chat-about__tabs .u-tabs__label-wrap {
    font-size: var(--small-fz);
}

.chat-about__tabs .u-tabs__item:not(:last-child) {
    margin-right: 24px;
}

.chat-about__tabs .u-tabs__button {
    font-size: var(--small-fz);
    line-height: 1.43;
}

.chat-about__tabs .u-tabs__item {
    height: 30px;
}

.chat-about__page {
    overflow: auto;
}

.chat-about__page-content {
    padding: 20px 24px;
    overflow-wrap: break-word;
}
@media (max-width: 767px) {
    .chat-about__page-content {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

.chat-about__item:not(:last-child) {
    margin-bottom: 16px;
}

.chat-about__divider {
    text-transform: capitalize;
    font-size: var(--small-fz);
    line-height: 1.33;
}

.chat-about__more-wrap {
    display: flex;
    justify-content: center;
}

.chat-about__shop-description {
    line-height: 1.5;
}

.chat-about__map-wrap {
    margin-bottom: 20px;
}

.chat-about__link {
    position: relative;
    cursor: pointer;
    outline: none;
}

.chat-about__link-image-wrap {
    width: 48px;
    height: 48px;
}

.chat-about__link-image {
    width: 48px;
    height: 48px;
    border-radius: var(--border-radius);
    object-fit: cover;
}

.chat-about__link-main-block {
    width: calc(100% - 48px - 12px - 36px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chat-about__link-description {
    font-size: var(--small-fz);
    line-height: 1.5;
}

.chat-about__link-description:not(:first-child) {
    margin-top: 4px;
}

.chat-about__link-button-wrap {
    font-size: var(--small-fz);
    line-height: 1.33;
    color: var(--primary-color);
}

.chat-about__link-button-wrap:not(:first-child) {
    margin-top: 4px;
}

.chat-about__menu-button {
    margin-top: 12px;
    align-self: flex-start;
    opacity: 0;
    transition: opacity var(--transition);
}
@media (max-width: 767px) {
    .chat-about__menu-button {
        opacity: 1;
    }
}

.chat-about__link:hover .chat-about__menu-button,
.chat-about__offer:hover .chat-about__menu-button,
.chat-about__file:hover .chat-about__menu-button,
.chat-about__menu-button.u-icon-button.u-icon-button_active {
    opacity: 1;
}

.chat-about__menu-button .u-icon {
    fill: var(--font-secondary-light-color) !important;
}

.chat-about__menu-button.u-icon-button:hover .u-icon,
.chat-about__menu-button.u-icon-button:active .u-icon,
.chat-about__menu-button.u-icon-button.u-icon-button_active .u-icon {
    fill: var(--font-secondary-color) !important;
}

.chat-about__offer {
    outline: none;
    cursor: pointer;
}

.chat-about__offer-image {
    width: 48px;
    height: 48px;
    object-fit: cover;
}

.chat-about__offer-price {
    font-size: var(--small-fz);
    line-height: 1.33;
    color: var(--font-secondary-color);
}

.chat-about__images-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 4px;
}

.chat-about__image-wrap {
    position: relative;
    height: 92px;
    width: 92px;
    cursor: pointer;
    outline: none;
}

.chat-about__image {
    height: 92px;
    width: 92px;
    object-fit: cover;
    border-radius: var(--border-radius);
}

.chat-about__image-hover-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: var(--border-radius);
    background-color: var(--dark-c);
    transition: opacity var(--transition);
}

.chat-about__image-wrap:hover .chat-about__image-hover-background {
    opacity: .2;
}

.chat-about__file {
    display: flex;
    cursor: pointer;
    outline: none;
}

.chat-about__file-icon-wrap {
    position: relative;
    width: 48px;
    height: 48px;
}

.chat-about__file-icon {
    width: 48px;
    height: 48px;
}

.chat-about__file-icon-extension {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    text-transform: uppercase;
    color: var(--font-secondary-light-color);
    font-family: var(--f-bold);
    font-size: var(--small-fz);
    line-height: 1.33;
    cursor: default;
    user-select: none;
    white-space: nowrap;
}

.chat-about__size {
    font-size: var(--small-fz);
    line-height: 1.33;
    color: var(--font-secondary-color);
}
</style>