var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","name":_vm.fromBottomOnXS && _vm.breakpointKey === 's' ? 'slide-from-bottom' : 'fade'},on:{"afterEnter":_vm.onTransitionEnd}},[_c('div',{class:[
            'popup-wrap',
            _vm.full && 'popup-wrap_full',
            _vm.semiFull && 'popup-wrap_semi-full',
            !_vm.noPadding && 'popup-wrap_padding',
            _vm.fromBottomOnXS && 'popup-wrap_xs-from-bottom',
            _vm.loading && 'popup-wrap_loading' ]},[_c('div',{staticClass:"outer"},[_c('div',{staticClass:"back",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.$emit('clickaway')}}}),_vm._v(" "),_c('div',{staticClass:"popup"},[(!_vm.loading)?_c('ButtonIcon',{staticClass:"close-btn",class:{
                        'close-btn_shadow': _vm.closeBtn === 'outer',
                    },attrs:{"icon":{
                        name: 'cross',
                        big: true,
                        secondary: true,
                        hovered: true
                    },"secondary":false,"aria-label":"Закрыть попап"},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('USpinner',{attrs:{"big":"","absoluteCenter":""}}):_vm._e(),_vm._v(" "),(!_vm.fromBottomOnXS || _vm.fromBottomOnXS && (_vm.breakpointKey !== 'xs' && _vm.breakpointKey !== 's'))?_vm._t("default"):_vm._e(),_vm._v(" "),(_vm.fromBottomOnXS && _vm.breakpointKey === 's')?_c('div',{key:_vm.breakpointKey,staticClass:"content-from-bottom"},[(!_vm.loading)?_c('ButtonIcon',{staticClass:"close-btn_shadow popup-wrap_xs-from-bottom__close-btn",attrs:{"icon":{
                            name: 'cross',
                            big: true,
                            secondary: true,
                            hovered: true
                        },"secondary":false,"aria-label":"Закрыть попап"},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),_vm._v(" "),_vm._t("default")],2):_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }