<template>
    <component
        :is="tag"
        v-bind="attrs"
        class="insurance-company-logo"
    >
        <img
            :src="src"
            :alt="name"
            :class="{
                'insurance-company-logo__image_big': big,
                'insurance-company-logo__image_disabled': disabled,
            }"
            class="insurance-company-logo__image"
            width="96"
            height="48"
        >
    </component>
</template>

<script>
export default {
    name: "InsuranceCompanyLogo",

    props: {
        big: Boolean,
        logo: Object,
        name: String,
        codename: {
            type: String,
            default: 'insurance_company_detail',
        },
        to: Object,
        disabled: Boolean,
    },

    computed: {
        link() {
            return ((this.logo || {}).thumbnails || {})[this.codename];
        },

        src() {
            return this.link
                ? this.$links.uploads + this.link
                : require('@/assets/images/logo-placeholder.svg');
        },

        tag() {
            return this.to ? 'RouterLink' : 'div';
        },

        attrs() {
            if (this.tag === 'RouterLink') {
                return {
                    to: this.to,
                };
            }
            else {
                return {};
            }
        },
    },
};
</script>

<style>
.insurance-company-logo {
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .insurance-company-logo__image {
        width: 120px;
        height: 60px;
    }
}
@media (max-width: 767px) {
    .insurance-company-logo__image {
        width: 96px;
        height: 48px;
    }
}

@media (min-width: 768px) {
    .insurance-company-logo__image_big {
        width: 160px;
        height: 80px;
    }
}
@media (max-width: 767px) {
    .insurance-company-logo__image_big {
        width: 120px;
        height: 60px;
    }
}

.insurance-company-logo__image_disabled {
    opacity: .3;
}
</style>