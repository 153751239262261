export default ({ HTTP }) => ({
    async get(config) {
        const url = '/basket/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async deleteFromBasket(data, config) {
        const url = '/basket/delete_from_basket/';
        const response = await HTTP.post(url, data, config);
        return response.data;
    },

    async addToBasket(data, config) {
        const url = '/basket/add_to_basket/';
        const response = await HTTP.post(url, data, config);
        return response.data;
    },

    async clearBasket() {
        const url = '/basket/clear_basket/';
        const response = await HTTP.post(url);
        return response.data;
    },
});