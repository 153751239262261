<template>
    <div
        :class="['cover-block', 'cover-block_' + size]"
    >
        <div
            class="big-cover-wrap"
            :class="{
                'big-cover-wrap_disable': !mainCover,
                'big-cover-wrap_center': photos.length === 0,
            }"
            @touchstart="lock"
            @touchend="move"
        >
            <div
                class="big-cover-wrap_inner"
                @click="showPhotoViewerPopup"
            >
                <transition
                    name="fade"
                    mode="out-in"
                >
                    <div
                        v-if="bigImageLoading"
                        class="big-cover__preloader"
                    >
                        <Spinner></Spinner>
                    </div>
                </transition>

                <img
                    :src="bigCoverSrc"
                    alt=""
                    class="big-cover"
                    :class="{
                        'big-cover_placeholder': !mainCover,
                    }"
                    @load="handleImageLoad"
                >
            </div>
        </div>

        <div v-if="photos.length !== 0" class="dots-list">
            <ol class="slider__dots">
                <li>
                    <button
                        :class="{
                            'active': bigCoverIndex === -1
                        }"
                        @click="changeImage(-1)"
                    ></button>
                </li>
                <li
                    v-for="(photo, index) in photos"
                    :key="'dot-' + photo.uuid"
                >
                    <button
                        :class="{
                            'active': bigCoverIndex === index
                        }"
                        @click="changeImage(index)"
                    >
                    </button>
                </li>
            </ol>
        </div>

        <div
            v-if="photos.length !== 0"
            class="covers-list-wrap"
        >
            <div
                v-if="photos.length"
                class="covers-list"
            >
                <button
                    v-if="hasMainCover"
                    :key="'main-cover'"
                    class="cover-btn"
                    :class="{
                        'cover-btn_active': bigCoverIndex === -1
                    }"
                    @click="changeImage(-1)"
                >
                    <img
                        :src="$links.uploads + hasMainCover"
                        alt=""
                        class="small-cover"
                    >
                </button>

                <button
                    v-for="(photo, index) in photos"
                    :key="'item-cover-' + photo.uuid"
                    class="cover-btn"
                    :class="{
                        'cover-btn_active': index === bigCoverIndex
                    }"
                    @click="changeImage(index)"
                >
                    <img
                        :src="$links.uploads + photo.thumbnails[photosCodename]"
                        alt=""
                        class="small-cover"
                    >
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
const PhotoViewer = () => import('@/components/popups/PhotoViewer.vue');

export default {
    name: "MarketItemPhotoBlock",

    components: { Spinner },

    props: {
        mainCover: {
            type: Object,
            default() {
                return {};
            },
        },

        photos: {
            type: Array,
            default() {
                return [];
            },
        },

        coverCodename: {
            type: String,
            default: 'market_item_cover_detail',
        },

        photosCodename: {
            type: String,
            default: 'market_item_cover_mini',
        },

        size: {
            type: String,
            default: 'big',
        },
    },

    data() {
        return {
            bigImageLoading: false,
            bigCoverIndex: -1,
            x0: null,
        };
    },

    computed: {
        hasMainCover() {
            return ((this.mainCover || {}).thumbnails || {})[this.coverCodename] || false;
        },

        mainCoverSrc() {
            return this.hasMainCover
                ? this.$links.uploads + this.hasMainCover
                : require('../assets/images/product-placeholder.svg');
        },

        bigCoverSrc() {
            return this.bigCoverIndex > -1
                ? this.$links.uploads + ((this.photos[this.bigCoverIndex] || {}).thumbnails || {})[this.coverCodename]
                : this.mainCoverSrc;
        },
    },

    methods: {
        handleImageLoad() {
            this.bigImageLoading = false;
        },

        showPhotoViewerPopup() {
            if (this.mainCover) {
                const props = {
                    photos: [this.mainCover, ...this.photos],
                    index: this.bigCoverIndex + 1,
                    fullCoverCodename: this.coverCodename,
                    miniCoverCodename: this.photosCodename,
                };
                const options = { props };
                this.$popup(PhotoViewer, options);
            }
        },

        changeImage(index) {
            if (index !== this.bigCoverIndex && index >= -1 && index <= this.photos.length - 1) {
                this.bigCoverIndex = index;
                this.bigImageLoading = true;
            }
        },

        move(e) {
            if (this.photos.length) {
                let dx = this.unifyTouchEvent(e).clientX - this.x0,
                    mathSign = Math.sign(dx),
                    windowWidth = window.innerWidth,
                    moveDistance = +(mathSign * dx / windowWidth).toFixed(2);

                if (moveDistance > .1) {
                    if (dx < 0) {
                        this.changeImage(this.bigCoverIndex + 1);
                    }
                    else {
                        this.changeImage(this.bigCoverIndex - 1);
                    }
                }
            }
            this.x0 = null;
        },
        unifyTouchEvent(e) {
            return e.changedTouches ? e.changedTouches[0] : e;
        },
        lock(e) {
            this.x0 = this.unifyTouchEvent(e).clientX;
        },
    },
};
</script>

<style scoped>
.big-cover__preloader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 40%);
}

.cover-block {
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .cover-block {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

.big-cover-wrap {
    position: relative;
    cursor: pointer;
}

.big-cover-wrap_disable {
    cursor: pointer;
    pointer-events: none;
}

@media (max-width: 767px) {
    .big-cover-wrap_center {
        margin-left: auto;
        margin-right: auto;
    }
}

.big-cover-wrap_inner {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
}
@media (min-width: 1281px) {
    .cover-block_big .big-cover-wrap {
        width: var(--col-7);
    }

    .cover-block_big .covers-list {
        max-height: 520px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .cover-block_big .big-cover-wrap {
        width: var(--col-4);
    }

    .cover-block_big .covers-list {
        max-height: 400px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .cover-block_big .big-cover-wrap {
        width: 288px;
    }

    .cover-block_big .covers-list {
        max-height: 288px;
    }
}
@media (min-width: 768px) {
    .cover-block_small .big-cover-wrap {
        width: 288px;
    }

    .cover-block_small .covers-list {
        max-height: 288px;
    }
}
@media (max-width: 767px) {
    .cover-block_big .big-cover-wrap {
        flex-grow: 1;
    }
}

.big-cover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
}

.big-cover_placeholder {
    width: 100%;
}

.dots-list {
    margin-top: 20px;
}
@media (min-width: 768px) {
    .dots-list {
        display: none;
    }
}

.covers-list-wrap {
    overflow: hidden;
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .covers-list-wrap {
        margin-right: 16px;
    }
}
@media (max-width: 767px) {
    .covers-list-wrap {
        display: none;
    }
}

.covers-list {
    overflow: auto;
    scroll-behavior: smooth;
}
@media (min-width: 768px) {
    .covers-list {
        flex-shrink: 0;
        margin-right: -40px;
        padding-right: 40px;
    }
}
@media (max-width: 767px) {
    .covers-list {
        display: flex;
        margin-bottom: -40px;
        padding-bottom: 40px;
    }
}

.cover-btn {
    position: relative;
    display: block;
    flex-shrink: 0;
    border: 2px solid transparent;
    border-radius: var(--border-radius);
    transition: border-color var(--transition);
}
@media (min-width: 768px) {
    .cover-btn:not(:last-child) {
        margin-bottom: 12px;
    }
}
@media (max-width: 767px) {
    .cover-btn:not(:last-child) {
        margin-right: 8px;
    }
}

.cover-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    transition: border-color var(--transition);
}
.cover-btn:not(.cover-btn_active):hover::before,
.cover-btn:not(.cover-btn_active):focus::before {
    border-color: var(--border-light-c);
}

.cover-btn_active {
    border-color: var(--primary-color);
    cursor: default;
}

.small-cover {
    margin: 2px;
}
@media (min-width: 1040px) {
    .small-cover {
        width: 64px;
        height: 64px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .small-cover {
        width: 48px;
        height: 48px;
    }
}
@media (max-width: 767px) {
    .small-cover {
        width: 56px;
        height: 56px;
    }
}
</style>