<template>
    <ChatTemplate class="chat-private-room__template">
        <template #header>
            <slot name="header">
                <div class="chat-private-room__name">
                    {{ name }}
                </div>
            </slot>
        </template>

        <template #body>
            <div class="chat-private-room__body">
                <div class="chat-private-room__overflow-wrap">
                    <div
                        ref="overflow"
                        class="chat-private-room__overflow-box"
                        @dragenter="onDragEnter"
                        @scroll="onScroll"
                    >
                        <div class="chat-private-room__scroll-box">
                            <div
                                v-if="innerMessages.length && !isFirstMessage(innerMessages[0])"
                                id="chat-private-room__top-loading"
                                ref="topLoading"
                                data-value="top"
                                class="chat-private-room__messages-loading chat-private-room__top-loading"
                            >
                                <USpinner v-if="topLoading"></USpinner>
                            </div>

                            <div class="chat-items-wrap">
                                <div
                                    v-for="item in items"
                                    :key="item.id"
                                    class="chat-item"
                                    :class="{
                                        'chat-item__is-timestamp': item.type === 'timestamp',
                                        'chat-item__own-message': item.message && own(item.message),
                                        'chat-item__foreign-message': item.message && foreign(item.message),
                                        'chat-item__message-group': item.index
                                            ? innerMessages[item.index - 1].sender.id === item.message.sender.id
                                            : false
                                    }"
                                >
                                    <div v-if="item.type === 'timestamp'" class="chat-timestamp">
                                        {{ item.timestamp | date }}
                                    </div>

                                    <div
                                        v-else-if="item.type === 'unread'"
                                        ref="unread"
                                        class="chat-unread"
                                    >
                                        Непрочитанные сообщения
                                    </div>

                                    <div
                                        v-else-if="item.type === 'message'"
                                        :id="item.id"
                                        ref="messages"
                                        :data-index="item.index"
                                        class="chat-message"
                                        :class="{
                                            'chat-message_accent': highlightedMessageId === item.message.id,
                                            'chat-message_own': own(item.message),
                                            'chat-message_foreign': foreign(item.message),
                                        }"
                                        @animationend="onAnimationEnd"
                                    >
                                        <div class="chat-message__avatar-block">
                                            <ChatMessageAvatar
                                                :src="item.message.sender.avatar"
                                                :name="item.message.sender.first_name"
                                                class="chat-message__avatar"
                                            ></ChatMessageAvatar>
                                        </div>

                                        <div class="chat-message__main-block">
                                            <div class="chat-message__sender-block">
                                                {{ (item.message.sender || {}).name }}
                                            </div>

                                            <div
                                                class="chat-message__content-block"
                                                :class="{
                                                    'chat-message__content-block_own': own(item.message),
                                                }"
                                            >
                                                <div
                                                    class="chat-message__cloud"
                                                    :class="{
                                                        'chat-message__cloud_own': own(item.message),
                                                        'chat-message__cloud_w100': item.message.offer,
                                                        'chat-message__cloud_error': item.message._notSentError,
                                                    }"
                                                >
                                                    <div v-if="item.message.offer" class="chat-message__offer-wrap">
                                                        <slot
                                                            name="offer"
                                                            :offer="item.message.offer"
                                                            :message="item.message"
                                                        >
                                                            <div class="chat-message__offer-image-block">
                                                                <img
                                                                    v-if="item.message.offer.item.cover"
                                                                    :src="item.message.offer.miniature"
                                                                    :alt="item.message.offer.item.name"
                                                                    class="chat-message__offer-image"
                                                                >
                                                                <img
                                                                    v-else
                                                                    src="@/assets/images/cover-placeholder.svg"
                                                                    alt=" "
                                                                    class="chat-message__offer-image"
                                                                >
                                                            </div>
                                                            <div class="chat-message__offer-name-block">
                                                                <div class="chat-message__offer-name">
                                                                    {{ item.message.offer.item.name }}
                                                                </div>
                                                                <div class="chat-message__offer-price-wrap">
                                                                    <b
                                                                        v-if="item.message.offer.in_stock"
                                                                        class="chat-message__offer-price"
                                                                    >
                                                                        {{ item.message.offer.price | price }}
                                                                    </b>
                                                                    <span
                                                                        v-else
                                                                        class="chat-message__offer-out-stock"
                                                                    >Нет в наличии</span>
                                                                </div>
                                                                <div class="chat-message__offer-rating">
                                                                    <Rating
                                                                        :value="item.message.offer.rating"
                                                                        emptyBehavior="nothing"
                                                                    ></Rating>
                                                                </div>
                                                            </div>
                                                        </slot>
                                                    </div>

                                                    <ChatReply
                                                        v-if="item.message.reply"
                                                        :reply="item.message.reply"
                                                        :style="{
                                                            maxWidth: item.message.image
                                                                ? Math.max(item.message.image.width, 260) + 'px'
                                                                : null
                                                        }"
                                                        class="chat-message__reply"
                                                    ></ChatReply>

                                                    <div v-if="item.message.image" class="chat-message__image-block">
                                                        <figure class="chat-message__image-figure">
                                                            <div class="chat-message__image-wrap">
                                                                <img
                                                                    ref="images"
                                                                    :src="item.message.image.local_url
                                                                        || item.message.image.miniature"
                                                                    :alt="item.message.image.name"
                                                                    class="chat-message__image"
                                                                    @load="onLoadImage"
                                                                    @error="onErrorImage"
                                                                >

                                                                <div
                                                                    class="chat-message__image-background"
                                                                    :class="{
                                                                        'chat-message__image-background_active': item.message.image.loading,
                                                                    }"
                                                                    @click="onClickImage($event, item.message)"
                                                                ></div>

                                                                <div
                                                                    v-if="item.message.image.loading"
                                                                    class="chat-message__image-progress-wrap"
                                                                >
                                                                    <RoundLoader
                                                                        :value="item.message.image.progress"
                                                                        class="chat-message__image-progress"
                                                                        @click="cancelUpload(item.message)"
                                                                    ></RoundLoader>
                                                                </div>
                                                            </div>

                                                            <figcaption
                                                                v-if="item.message.text"
                                                                class="chat-message__text-wrap"
                                                                :style="{
                                                                    maxWidth: Math.max(item.message.image.width, 260) + 'px'
                                                                }"
                                                            >
                                                                <span class="chat-message__text">{{ item.message.text }}</span>
                                                            </figcaption>
                                                        </figure>
                                                    </div>

                                                    <div v-if="item.message.file" class="chat-message__file-wrap">
                                                        <span class="chat-message__file-icon-wrap">
                                                            <RoundLoader
                                                                v-if="item.message.file.loaded === false"
                                                                :value="item.message.file.progress"
                                                                class="chat-message__file-progress"
                                                                small
                                                                @click="cancelUpload(item.message)"
                                                            ></RoundLoader>

                                                            <UIcon
                                                                v-else
                                                                name="attachment"
                                                                small
                                                                class="chat-message__file-icon"
                                                            ></UIcon>
                                                        </span>

                                                        <div class="chat-message__file-button-wrap">
                                                            <ButtonText
                                                                underline
                                                                :href="item.message.file.url ||
                                                                    item.message.file.local_url"
                                                                target="_blank"
                                                                class="chat-message__file-button"
                                                            >
                                                                <span class="chat-message__file-name">{{ clearName(item.message.file.name, item.message.file.extension) }}</span><span class="chat-message__file-extension">.{{ item.message.file.extension }}</span>
                                                            </ButtonText><span
                                                                v-if="item.message.file.size"
                                                                class="chat-message__file-size"
                                                            >{{ '\u00A0' }}({{ item.message.file.size }})</span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        v-if="!item.message.image && !item.message.offer"
                                                        class="chat-message__text-wrap"
                                                    >
                                                        <UHighlight
                                                            :text="item.message.text"
                                                            :queries="getQueries(item.message)"
                                                            class="chat-message__highlight"
                                                        ></UHighlight>
                                                    </div>

                                                    <div
                                                        v-if="item.message.link && item.message.link.og_data"
                                                        class="chat-message__link-wrap"
                                                    >
                                                        <div class="chat-message__link-main-block">
                                                            <div>
                                                                <div class="chat-message__link-title">
                                                                    {{ item.message.link.og_data['og:title'] ||
                                                                        item.message.link.og_data.title }}
                                                                </div>
                                                                <div
                                                                    v-if="item.message.link.og_data['og:description']"
                                                                    class="chat-message__link-description"
                                                                >
                                                                    {{ item.message.link.og_data['og:description'] }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="item.message.link.og_data['og:image']"
                                                            class="chat-message__link-logo-block"
                                                        >
                                                            <img
                                                                v-if="item.message.link.og_data['og:image'] &&
                                                                    item.message.link.og_data['og:image'].includes('http')"
                                                                :src="item.message.link.og_data['og:image']"
                                                                alt=" "
                                                                class="chat-message__link-logo"
                                                            >
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="chat-message__footer"
                                                        :class="{
                                                            'chat-message__footer_fly': item.message.image && !item.message.text,
                                                            'chat-message__footer_not-sent': item.message._notSentError,
                                                            'chat-message__footer_own': own(item.message),
                                                            'chat-message__footer_foreign': foreign(item.message)
                                                        }"
                                                    >
                                                        <span
                                                            v-if="item.message._notSentError"
                                                            class="chat-message__not-sent-text"
                                                        >Не отправлено</span>

                                                        <template v-else>
                                                            <span class="chat-message__time">
                                                                {{ item.message.create_date | time }}
                                                            </span>

                                                            <template v-if="own(item.message)">
                                                                <UIcon
                                                                    v-if="item.message.is_seen"
                                                                    name="delivered-16"
                                                                    tiny
                                                                    class="chat-message__icon chat-message__seen-icon"
                                                                ></UIcon>
                                                                <UIcon
                                                                    v-else-if="item.message.is_saved"
                                                                    name="done-16"
                                                                    tiny
                                                                    class="chat-message__icon chat-message__saved-icon"
                                                                ></UIcon>
                                                                <UIcon
                                                                    v-else
                                                                    name="time"
                                                                    tiny
                                                                    class="chat-message__icon chat-message__loading-icon"
                                                                ></UIcon>
                                                            </template>
                                                        </template>
                                                    </div>
                                                </div>

                                                <div
                                                    class="chat-message__reply-block"
                                                    :class="{
                                                        'chat-message__reply-block_own': own(item.message),
                                                        'chat-message__reply-block_foreign': foreign(item.message)
                                                    }"
                                                >
                                                    <button
                                                        tabindex="-1"
                                                        type="button"
                                                        class="chat-message__reply-button"
                                                        @click="setReply(item.message)"
                                                    >
                                                        <UIcon
                                                            name="reply"
                                                            small
                                                            class="chat-message__reply-icon"
                                                        ></UIcon>
                                                    </button>
                                                </div>

                                                <div
                                                    v-if="item.message._notSentError"
                                                    class="chat-message__error-block"
                                                >
                                                    <UMenu>
                                                        <template #trigger="{ value, attrs, handlers }">
                                                            <UIconButton
                                                                name="menu"
                                                                small
                                                                class="chat-message__error-menu-icon"
                                                                :class="{
                                                                    'chat-message__error-menu-icon_active': value
                                                                }"
                                                                v-bind="attrs"
                                                                v-on="handlers"
                                                            ></UIconButton>
                                                        </template>

                                                        <UMenuItem @click="resendMessage(item)">
                                                            Повторить отправку
                                                        </UMenuItem>
                                                        <UMenuItem @click="deleteMessage(item)">
                                                            <span class="text_red">Удалить</span>
                                                        </UMenuItem>
                                                    </UMenu>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="innerMessages.length && !isLastMessage(innerMessages[innerMessages.length - 1])"
                                id="chat-private-room__bottom-loading"
                                ref="bottomLoading"
                                data-value="bottom"
                                class="chat-private-room__messages-loading chat-private-room__bottom-loading"
                            >
                                <USpinner v-if="bottomLoading"></USpinner>
                            </div>
                        </div>
                    </div>

                    <TransitionFade>
                        <button
                            v-if="showToDownButton"
                            type="button"
                            tabindex="-1"
                            class="chat-private-room__to-down-button"
                            @click="onToDownClick"
                        >
                            <UIcon
                                name="arrow-down"
                                small
                                class="chat-private-room__to-down-icon"
                            ></UIcon>
                        </button>
                    </TransitionFade>

                    <div
                        v-if="!innerMessages.length && !innerLoading"
                        class="chat-private-room__placeholder"
                    >
                        <slot name="placeholder"></slot>
                    </div>

                    <div
                        class="chat-private-room__drag-zone"
                        :class="{
                            'chat-private-room__drag-zone_show': dragOverChat,
                            'chat-private-room__drag-zone_active': dragOverChat,
                        }"
                        @dragover.prevent=""
                        @dragenter="onDragEnter"
                        @dragleave="onDragLeave"
                        @drop="onDrop"
                    >
                        <div class="chat-private-room__drag-border">
                            <div class="chat-private-room__drag-text-wrap">
                                <div>
                                    <p class="chat-private-room__drag-text">
                                        Перетащите файл
                                    </p>
                                    <p class="chat-private-room__drag-text">
                                        или изображение сюда
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- крепится над сообщением -->
                <div v-if="hasPayload" class="chat-payload">
                    <ChatReply
                        v-if="reply"
                        :reply="reply"
                        class="chat-payload__reply chat-payload__row"
                        @clear="reply = null"
                    ></ChatReply>

                    <div v-if="image" class="chat-payload__image chat-payload__row">
                        <div class="chat-payload__image-img-block">
                            <div class="chat-payload__image-img-wrap">
                                <img
                                    :src="image.local_url"
                                    :alt="image.name"
                                    class="chat-payload__image-img"
                                >
                            </div>
                        </div>

                        <div class="chat-payload__image-name-block">
                            <span class="chat-payload__image-name-text">{{ clearName(image.name, image.extension) }}</span><template v-if="image.extension">
                                .<span class="chat-payload__image-name-extension">{{ image.extension }}</span>
                            </template>
                        </div>

                        <div class="chat-payload__remove-block">
                            <button
                                type="button"
                                tabindex="-1"
                                class="chat-payload__remove-button"
                                @click="image = null"
                            >
                                <UIcon
                                    name="close-16"
                                    class="chat-payload__remove-icon"
                                ></UIcon>
                            </button>
                        </div>
                    </div>

                    <div v-if="file" class="chat-payload__file chat-payload__row">
                        <div class="chat-payload__file-icon-block">
                            <svg class="chat-payload__file-icon"><use xlink:href="#file-loader"></use></svg>
                            <span class="chat-payload__file-icon-extension">{{ file.extension }}</span>
                        </div>

                        <div class="chat-payload__file-name-block">
                            <div class="chat-payload__file-name">
                                <span class="chat-payload__file-name-text">{{ clearName(file.name, file.extension) }}</span><template v-if="file.extension">
                                    .<span class="chat-payload__file-name-extension">{{ file.extension }}</span>
                                </template>
                            </div>
                            <div class="chat-payload__file-size">
                                {{ file.size }}
                            </div>
                        </div>

                        <div class="chat-payload__remove-block">
                            <button
                                type="button"
                                tabindex="-1"
                                class="chat-payload__remove-button"
                                @click="file = null"
                            >
                                <UIcon
                                    name="close-16"
                                    class="chat-payload__remove-icon"
                                ></UIcon>
                            </button>
                        </div>
                    </div>
                </div>

                <div v-show="innerLoading" class="chat-private-room__loading">
                    <USpinner big></USpinner>
                </div>
            </div>

            <div class="chat-private-room__footer">
                <template v-if="searchShow">
                    <div
                        v-if="!searchLoading && searchMessages.length"
                        class="chat-private-room__search-navigation"
                    >
                        <div class="chat-private-room__search-count">
                            {{ searchMessages.length - searchCurrentIndex }} из {{ searchMessages.length }}
                        </div>

                        <div class="chat-private-room__search-navigation-buttons">
                            <button
                                type="button"
                                :disabled="searchCurrentIndex < 1"
                                class="chat-private-room__search-button"
                                @click="prevSearchMessage"
                            >
                                <UIcon
                                    name="arrow-up-16"
                                    small
                                    class="chat-private-room__search-icon"
                                ></UIcon>
                            </button>

                            <button
                                type="button"
                                :disabled="(searchCurrentIndex + 1) >= searchMessages.length"
                                class="chat-private-room__search-button"
                                @click="nextSearchMessage"
                            >
                                <UIcon
                                    name="arrow-down-16"
                                    small
                                    class="chat-private-room__search-icon"
                                ></UIcon>
                            </button>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <div class="chat-private-room__attach-block">
                        <input
                            id="chat-private-room-attach"
                            ref="attachInput"
                            type="file"
                            accept="image/jpeg,image/png,application/pdf"
                            class="visually-hidden chat-private-room__attach-input"
                            @change="onChangeAttachInput"
                        >

                        <!-- to button -->
                        <slot name="attach">
                            <label
                                for="chat-private-room-attach"
                                class="chat-private-room__attach-button"
                            >
                                <UIcon
                                    name="attachment"
                                    small
                                    class="chat-private-room__attach-icon"
                                ></UIcon>
                            </label>
                        </slot>
                    </div>

                    <div
                        ref="inputBlock"
                        class="chat-private-room__input-block"
                        @click="onClickInputBlock"
                    >
                        <div class="chat-private-room__input-overflow-box">
                            <div class="chat-private-room__input-scroll-box">
                                <div
                                    ref="inputDesktop"
                                    role="textbox"
                                    contenteditable="true"
                                    class="chat-private-room__input chat-private-room__input-desktop"
                                    @input="onInput"
                                    @paste="onPaste"
                                    @keydown.enter="onEnter"
                                ></div>

                                <div
                                    ref="inputMobile"
                                    role="textbox"
                                    contenteditable="true"
                                    class="chat-private-room__input chat-private-room__input-mobile"
                                    @focus="onFocus"
                                    @input="onInput"
                                    @paste="onPaste"
                                ></div>

                                <div
                                    v-if="!inputValue"
                                    class="chat-private-room__input-placeholder"
                                    @click="onClickPlaceholder"
                                >
                                    <span
                                        class="chat-private-room__input-placeholder-text"
                                    >Напишите сообщение</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="chat-private-room__send-block">
                        <button
                            tabindex="-1"
                            type="button"
                            :disabled="emptyInput"
                            class="chat-private-room__send-button"
                            @click="send"
                        >
                            <UIcon
                                name="send"
                                big
                                class="chat-private-room__send-icon"
                            ></UIcon>
                        </button>
                    </div>
                </template>
            </div>
        </template>
    </ChatTemplate>
</template>

<script>
// utils
import isValidDate from '@ui/utils/isValidDate.js';
import pad from '@ui/utils/pad.js';
import clearFileName from '@/lib/clearFileName.js';
import imageTypes from './image-types.js';
import fileTypes from './file-types.js';
import { price } from '@ui/filters';
import { timestampType } from '@/components/_chat/format.js';
// components
import ChatTemplate from '@/components/_chat/ChatTemplate.vue';
import ChatMessageAvatar from '@/components/_chat/ChatMessageAvatar.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import RoundLoader from '@/components/_chat/RoundLoader.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ChatReply from '@/components/_chat/ChatReply.vue';
import Rating from '@/components/Rating.vue';
import TransitionFade from '@/components/_transitions/TransitionFade.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UHighlight from '@ui/components/UHighLight/UHighlight.vue';
import UMenu from '@ui/components/UMenu/UMenu.vue';
import UMenuItem from '@ui/components/UMenu/UMenuItem.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';


function placeCaretAtEnd(el) {
    el.focus();

    if (window.getSelection && document.createRange) {
        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }
    else if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(el);
        range.collapse(false);
        range.select();
    }
}

export default {
    name: 'ChatPrivateRoom',

    components: {
        UIconButton,
        UMenuItem,
        UMenu,
        UHighlight,
        USpinner,
        TransitionFade,
        Rating,
        ChatReply,
        ButtonText,
        RoundLoader,
        UIcon,
        ChatMessageAvatar,
        ChatTemplate,
    },

    filters: {
        date(value) {
            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return value;

            const type = timestampType(date);

            if (['today', 'yesterday', 'week', 'year'].includes(type)) {
                const options = { day: 'numeric', month: 'long' };
                return new Intl.DateTimeFormat('ru-RU', options).format(date);
            }
            else {
                const options = { day: 'numeric', month: 'long', year: 'numeric' };
                return new Intl.DateTimeFormat('ru-RU', options).format(date);
            }
        },

        time(value) {
            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return '';

            return `${ pad(date.getHours()) }:${ pad(date.getMinutes()) }`;
        },

        price,
    },

    provide() {
        return {
            isOwn: this.own,
            isForeign: this.foreign,
        };
    },

    props: {
        chat: Object,

        user: {
            type: Object,
            default() {
                return {
                    // id: 1,
                };
            },
        },

        name: String,

        getMessageKey: {
            type: Function,
            default(message) {
                return message.nonce || message.id;
            },
        },

        own: {
            type: Function,
            default(message) {
                return ((message || {}).sender || {}).id === this.user.id;
            },
        },

        foreign: {
            type: Function,
            default(message) {
                return ((message || {}).sender || {}).id !== this.user.id;
            },
        },

        searchShow: Boolean,
        searchValue: String,
        searchLoading: Boolean,
        searchMessages: {
            type: Array,
            default() {
                return [
                    // {
                    //     id: 1,
                    //     search_value: '',
                    //     text: '',
                    // },
                ];
            },
        },
    },

    data() {
        return {
            firstTime: true,
            inputValue: '',
            innerMessages: [],
            file: null,
            image: null,
            reply: null,
            dragOverBody: false,
            dragOverChat: false,
            highlightedMessageId: null,

            initImagesCount: 0,
            innerLoading: false,
            topLoading: false,
            bottomLoading: false,
            lastNonce: 0,
            observer: null,
            messageObserver: null,
            showToDownButton: false,

            searchCurrentIndex: 0,
        };
    },

    computed: {
        hasPayload() {
            return !!this.file || !!this.image || !!this.reply;
        },

        emptyInput() {
            return !this.inputValue && !this.file && !this.image;
        },

        items() {
            const items = [];
            let prevMessage;
            let hasUnread;
            let timestampCount = 0;

            this.innerMessages.forEach((message, index) => {
                if (prevMessage) {
                    const prev = new Date(prevMessage.create_date);
                    const current = new Date(message.create_date);

                    if (
                        prev.getDate() !== current.getDate() ||
                        prev.getMonth() !== current.getMonth() ||
                        prev.getFullYear() !== current.getFullYear()
                    ) {
                        items.push({
                            type: 'timestamp',
                            id: 'timestamp-' + timestampCount,
                            timestamp: message.create_date,
                        });

                        timestampCount++;
                    }
                }
                else {
                    items.push({
                        type: 'timestamp',
                        id: 'timestamp-' + timestampCount,
                        timestamp: message.create_date,
                    });

                    timestampCount++;
                }

                if (this.foreign(message) && message._isNotSeen && !hasUnread) {
                    items.push({
                        type: 'unread',
                        id: 'chat-item-unread',
                    });

                    hasUnread = true;
                }

                items.push({
                    type: 'message',
                    index,
                    message,
                    id: 'chat-message-' + (message.id || message.nonce + '-nonce'),
                });

                prevMessage = message;
            });

            return items;
        },
    },

    watch: {
        chat: {
            handler() {
                this.reset();
                this.init();
            },
        },

        'chat.last_message': {
            handler(message) {
                if (message && message.id && message.id > this.lastNonce) {
                    this.lastNonce = message.id;
                }
            },

            immediate: true,
        },

        innerMessages: {
            handler(oldVal, newVal) {
                if (!this.innerLoading && (oldVal.length !== newVal.length)) {
                    this.updateObserver();
                    this.updateMessageObserver();
                }
            },
        },

        inputValue: {
            handler() {
                const target = this;
                this.$emit('typing', { target });
            },
        },
    },

    beforeMount() {
        this.init();
    },

    mounted() {
        this.$nextTick(() => {
            this.resetInputScroll();
            this.scrollDown();
        });

        document.addEventListener('visibilitychange', this.onVisibilityChange);

        this.$emit('mounted', { target: this, chat: this.chat });

        // document.addEventListener('dragenter', this.onDragOver);
        // document.addEventListener('dragleave', this.onDragLeave);
    },

    beforeDestroy() {
        this.removeObservers();

        document.removeEventListener('visibilitychange', this.onVisibilityChange);
        // document.removeEventListener('dragenter', this.onDragOver);
        // document.removeEventListener('dragleave', this.onDragLeave);
    },

    methods: {
        clearName: clearFileName,

        init() {
            this.innerLoading = true;
            this.createObserver();
            this.createMessageObserver();
            const target = this;
            const callback = async ({ messages, to }) => {
                let firstNotSeenMessageId;

                this.innerMessages = messages.map(message => {
                    if (this.foreign(message) && !message.is_seen) {
                        if (!firstNotSeenMessageId) {
                            firstNotSeenMessageId = message.id;
                        }

                        return { ...message, _isNotSeen: true };
                    }

                    return message;
                });

                await this.waitImages(messages);

                if (to) {
                    const id = 'chat-message-' + (to.id || to);
                    const el = document.getElementById(id);

                    if (el) {
                        el.scrollIntoView({
                            block: 'center',
                        });

                        this.highlightedMessageId = to.id || to;
                    }
                }
                else if (firstNotSeenMessageId) {
                    const id = 'chat-message-' + firstNotSeenMessageId;
                    const el = document.getElementById(id);

                    if (el) {
                        el.scrollIntoView({
                            block: 'center',
                        });
                    }
                }
                else {
                    await this.toBottom();
                }

                this.innerLoading = false;

                this.updateObserver();
                this.updateMessageObserver();
            };

            this.$emit('init', { target, callback });
        },

        waitImages(messages) {
            return new Promise(resolve => {
                this.initImagesCount = messages.reduce((acc, message) => message.image ? acc + 1 : acc, 0);

                const images = document.querySelectorAll('.chat-message__image-block .chat-message__image');

                if (images) {
                    for (const el of images) {
                        if (el.complete && this.initImagesCount) {
                            this.initImagesCount -= 1;
                        }
                    }

                    const unwatch = this.$watch('initImagesCount', value => {
                        if (value) return;

                        resolve();

                        if (unwatch) unwatch();
                    }, { immediate: true });
                }
                else {
                    resolve();
                }
            });
        },

        scrollDown() {
            this.$refs.overflow.scrollTop = this.$refs.overflow.scrollHeight;
        },

        scrollToTop() {
            if (!this.innerMessages.length) return;
            if (!this.chat.first_message) return;
            if (this.topLoading) return;

            this.messageObserver.disconnect();

            const target = this;
            const chat = this.chat;
            const message = this.innerMessages[0];

            if (
                (message.id && message.id === this.chat.first_message.id) ||
                (message.nonce && message.nonce === this.chat.first_message.nonce)
            ) return;

            this.topLoading = true;

            const callback = ({ messages }) => {
                this.topLoading = false;
                if (!messages) return;
                this.innerMessages = [...messages, ...this.innerMessages];
                // sort
                const overflow = this.$refs.overflow;
                const oldTop = overflow.scrollTop;
                const oldHeight = overflow.scrollHeight;

                this.$nextTick(() => {
                    const overflow = this.$refs.overflow;
                    const newHeight = overflow.scrollHeight;
                    const diff = newHeight - oldHeight;
                    overflow.scrollTop = oldTop + diff;
                });
            };

            this.$emit('scrollToTop', { target, message, chat, callback });
        },

        scrollToBottom() {
            if (!this.innerMessages.length) return;
            if (!this.chat.last_message) return;
            if (this.bottomLoading) return;

            this.messageObserver.disconnect();

            const target = this;
            const chat = this.chat;
            const message = this.innerMessages[this.innerMessages.length - 1];

            if (
                (message.id && message.id === this.chat.last_message.id) ||
                (message.nonce && message.nonce === this.chat.last_message.nonce)
            ) return;

            this.bottomLoading = true;

            const callback = ({ messages }) => {
                this.bottomLoading = false;
                if (!messages) return;
                this.innerMessages = [...this.innerMessages, ...messages];
            };

            this.$emit('scrollToBottom', { target, message, chat, callback });
        },

        onScroll($event) {
            if (!this.innerMessages.length) return;

            const message = this.innerMessages[this.innerMessages.length - 1];

            if (message && this.chat.last_message) {
                if (message.id) {
                    if (message.id !== this.chat.last_message.id) {
                        this.showToDownButton = true;
                        return;
                    }
                }
                else if (message.nonce) {
                    if (message.nonce !== this.chat.last_message.nonce) {
                        this.showToDownButton = true;
                        return;
                    }
                }
            }

            const el = $event.target;

            this.showToDownButton = (el.scrollHeight - el.clientHeight - el.scrollTop) > el.clientHeight;
        },

        send() {
            if (!this.inputValue && !this.file && !this.image) return;

            const message = this.createMessage();
            const target = this;

            if (
                !this.innerMessages.length ||
                this.isLastMessage(this.innerMessages[this.innerMessages.length - 1])
            ) {
                const messages = this.innerMessages.map(message => {
                    if (this.foreign(message)) {
                        return { ...message, is_seen: true, _isNotSeen: false };
                    }

                    return message;
                });
                this.innerMessages = [...messages, message];
                this.updateScroll();
            }

            this.$emit('send', { message, target });

            if (this.$refs.inputMobile) {
                this.$refs.inputMobile.innerText = '';
            }

            if (this.$refs.inputDesktop) {
                this.$refs.inputDesktop.innerText = '';
            }

            this.file = null;
            this.image = null;
            this.reply = null;
            this.inputValue = '';
        },

        async addFiles(files) {
            const count = files.length + this.file ? 1 : 0 + this.image ? 1 : 0;

            if (count > 1) {
                this.emitMaxFilesError(files);
                return;
            }

            const file = files[0];

            if (file.size > 3 * 1024 * 1024) {
                this.emitMaxSizeError(file);
                return;
            }

            const preparedFile = this.prepareFile(file);

            if (imageTypes.includes(preparedFile.type)) {
                this.image = preparedFile;

                await this.updateScroll();
            }
            else if (fileTypes.includes(preparedFile.type)) {
                this.file = preparedFile;

                await this.updateScroll();
            }
        },

        prepareFile(file) {
            const name = file.name;
            const type = file.type;
            const size = this.humanizeSize(file.size);
            const extension = type ? type.split('/')[1] : '';
            const local_url = URL.createObjectURL(file);

            return {
                name,
                type,
                size,
                extension,
                local_url,
                blob: file,
                progress: 0,
                loading: true,
                loaded: false,
            };
        },

        humanizeSize(size) {
            const kb = (size / 1024).toFixed(2);

            if (kb < 1024) {
                return kb.toLocaleString('ru-RU', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                }) + ' KБ';
            }
            else {
                return (kb / 1024).toFixed(2).toLocaleString('ru-RU', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                }) + ' МБ';
            }
        },

        emitMaxFilesError(files) {
            this.$emit('maxFilesError', { files });
        },

        emitMaxSizeError(file) {
            this.$emit('maxSizeError', { file });
        },

        cancelUpload(message) {
            this.$emit('cancelUpload', message);
        },

        setReply(message) {
            this.reply = message;
            this.focusInput();
            this.updateScroll();
        },

        isLastMessage(message) {
            const lastMessage = this.chat.last_message;

            if (lastMessage && message) {
                if (message.nonce && lastMessage.nonce) {
                    return message.nonce === lastMessage.nonce;
                }

                return message.id === lastMessage.id;
            }
        },

        isFirstMessage(message) {
            const firstMessage = this.chat.first_message;

            if (firstMessage && message) {
                if (message.nonce && firstMessage.nonce) {
                    return message.nonce === firstMessage.nonce;
                }

                return message.id === firstMessage.id;
            }
        },

        getQueries(message) {
            const queries = [];

            if (message.link) {
                queries.push({
                    tag: 'a',
                    name: 'link',
                    value: 'https?:\\/\\/[a-z0-9.-]+\\.[a-z]{2,3}(\\/\\S*?(?=\\.*(?:\\s|$)))?',
                });
            }

            if (this.searchValue) {
                queries.push({
                    name: 'search',
                    value: this.searchValue,
                });
            }

            return queries;
        },

        createObserver() {
            this.observer = new IntersectionObserver(([entry]) => {
                if (entry && entry.isIntersecting) {
                    const value = entry.target.dataset.value;

                    if (value === 'top') {
                        this.scrollToTop();
                    }
                    else if (value === 'bottom') {
                        this.scrollToBottom();
                    }
                }
            }, {
                root: this.$refs.overflow,
                threshold: 0.1,
            });
        },

        updateObserver() {
            if (!this.observer) return;
            if (this.observer) this.observer.disconnect();

            this.$nextTick(() => {
                const top = this.$refs.topLoading;
                const bottom = this.$refs.bottomLoading;

                if (top) {
                    this.observer.observe(top);
                }

                if (bottom) {
                    this.observer.observe(bottom);
                }
            });
        },

        createMessageObserver() {
            this.messageObserver = new IntersectionObserver(([entry]) => {
                if (document.hidden) return;

                if (entry && entry.isIntersecting) {
                    this.messageObserver.unobserve(entry.target);

                    const index = Number(entry.target.dataset.index);
                    const message = this.innerMessages[index];

                    this.seen(message);
                }
            }, {
                root: this.$refs.overflow,
                threshold: 1.0,
            });
        },

        updateMessageObserver() {
            if (document.hidden) return;
            if (!this.messageObserver) return;
            if (this.messageObserver) this.messageObserver.disconnect();

            const overflow = this.$refs.overflow;

            this.$nextTick(() => {
                this.innerMessages.forEach((message, index) => {
                    if (this.own(message)) return;
                    if (message.is_seen) return;

                    const el = this.$refs.messages[index];

                    if (!el) return;

                    if (
                        (el.offsetTop + el.offsetHeight) > (overflow.scrollTop + overflow.offsetHeight) ||
                        el.offsetTop < overflow.scrollTop
                    ) {
                        this.messageObserver.observe(el);
                    }
                    else {
                        this.seen(message);
                    }
                });
            });
        },

        sortMessages() {
            this.innerMessages.sort((a, b) => {
                return new Date(a.create_date) - new Date(b.create_date);
            });
        },

        removeObservers() {
            if (this.observer) {
                this.observer.disconnect();
                this.observer = null;
            }

            if (this.messageObserver) {
                this.messageObserver.disconnect();
                this.messageObserver = null;
            }
        },

        seen(message) {
            if (!message) return;
            if (message.is_seen) return;

            const target = this;
            this.$emit('seen', { message, target });
        },

        resendMessage({ message }) {
            const target = this;
            message._notSentError = false;
            this.$emit('send', { message, target });
        },

        deleteMessage({ message, index }) {
            const target = this;
            this.innerMessages.splice(index, 1);

            if (message.id === (this.chat.last_message || {}).id) {
                const message = this.innerMessages.length
                    ? this.innerMessages[this.innerMessages.length - 1]
                    : null;
                this.$emit('changeLastMessage', { target, message });
            }

            if (message.id === (this.chat.first_message || {}).id) {
                const message = this.innerMessages.length ? this.innerMessages[0] : null;
                this.$emit('changeFirstMessage', { target, message });
            }
        },

        // handlers

        onVisibilityChange() {
            if (document.hidden) {
                if (this.messageObserver) {
                    this.messageObserver.disconnect();
                }
            }
            else {
                this.updateMessageObserver();
            }
        },

        onClickPlaceholder() {
            this.focusInput();
        },

        onClickInputBlock() {
            this.focusInput();
        },

        onChangeAttachInput($event) {
            const files = $event.target.files;
            this.addFiles(files);
        },

        onInput($event) {
            let value = $event.target.innerText;
            const html = $event.target.innerHTML;

            if (html === '<br>') value = '';

            this.inputValue = value;
        },

        onPaste($event) {
            if (!$event.clipboardData && !$event.clipboardData.getData) return;

            $event.preventDefault();

            const file = $event.clipboardData.files[0];

            if (file) {
                this.addFiles([file]);
            }

            const text = $event.clipboardData.getData('text/plain');

            if (text) {
                const el = $event.target;
                el.innerHTML += text;
                this.inputValue += text;
                placeCaretAtEnd(el);
            }
        },

        onEnter($event) {
            if ($event.shiftKey) return;

            $event.preventDefault();

            this.send();
        },

        onFocus() {
            this.updateScroll();
        },

        onLoadImage($event) {
            if (!this.initImagesCount) return;

            const image = $event.target;
            const overflow = this.$refs.overflow;

            this.$refs.overflow.scrollTop = overflow.scrollTop + image.height;

            this.initImagesCount -= 1;
        },

        onErrorImage($event) {
            if (!this.initImagesCount) return;

            this.initImagesCount -= 1;
        },

        onClickImage(nativeEvent, message) {
            // this.viewImage(message);

            const target = this;
            this.$emit('clickImage', { target, nativeEvent, message });
        },

        onDragEnter($event) {
            $event.preventDefault();

            this.dragOverChat = true;
        },

        onDragLeave($event) {
            $event.preventDefault();

            this.dragOverChat = false;
        },

        onDrop($event) {
            $event.preventDefault();

            const file = $event.dataTransfer.files[0];

            if (file) {
                this.addFiles([file]);
            }

            this.dragOverChat = false;
        },

        onAnimationEnd() {
            this.highlightedMessageId = null;
        },

        onToDownClick() {
            // TODO: сначала к first_not_seen_message потом к last_message
            this.toMessage(this.chat.last_message);
        },

        // search

        prevSearchMessage() {
            if (this.searchCurrentIndex < 1) return;

            this.searchCurrentIndex -= 1;
            const message = this.searchMessages[this.searchCurrentIndex];

            if (message) {
                this.toMessage(message);
            }
        },

        nextSearchMessage() {
            if (this.searchCurrentIndex >= (this.searchMessages.length - 1)) return;

            this.searchCurrentIndex += 1;

            const message = this.searchMessages[this.searchCurrentIndex];

            if (message) {
                this.toMessage(message);
            }
        },

        // public

        reset() {
            this.innerLoading = true;
            this.innerMessages = [];
            this.removeObservers();
        },

        attach() {
            const input = this.$refs.attachInput;

            if (!input) return;

            input.click();
        },

        focusInput() {
            if (this.$refs.inputMobile) {
                this.$refs.inputMobile.focus();
            }

            if (this.$refs.inputDesktop) {
                this.$refs.inputDesktop.focus();
            }
        },

        createMessage() {
            return {
                text: this.inputValue,
                file: this.file ? { ...this.file } : null,
                image: this.image ? { ...this.image } : null,
                reply: this.reply ? { ...this.reply } : null,
                create_date: Date.now(),
                nonce: this.createNonce(),
                sender: this.user,
            };
        },

        createNonce() {
            this.lastNonce++;
            return this.lastNonce;
        },

        resetInputScroll() {
            if ((this.$refs.inputMobile || this.$refs.inputDesktop) && this.inputValue) {
                if (this.$refs.inputMobile) {
                    this.$refs.inputMobile.innerText = this.inputValue;
                }

                if (this.$refs.inputDesktop) {
                    this.$refs.inputDesktop.innerText = this.inputValue;
                }

                if (this.$refs.inputBlock) {
                    this.$refs.inputBlock.scrollTop = this.$refs.inputBlock.scrollHeight;
                }
            }
        },

        async updateScroll() {
            let shouldScrollToBottom = false;
            const target = this.$refs.overflow;

            if (target) {
                const top = target.scrollTop;
                const height = target.scrollHeight;
                const bottom = height - top - target.clientHeight;
                shouldScrollToBottom = bottom <= 16;
            }

            if (shouldScrollToBottom) {
                await this.toBottom();
            }
        },

        toBottom() {
            return new Promise(resolve => {
                this.$nextTick(() => {
                    if (this.$refs.overflow) {
                        this.$refs.overflow.scrollTop = this.$refs.overflow.scrollHeight;
                    }

                    resolve();
                });
            });
        },

        toMessage(message) {
            if (!this.innerMessages.length) return;

            const id = 'chat-message-' + (message.id ? message.id : message.nonce + '-nonce');
            const el = document.getElementById(id);

            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });

                this.highlightedMessageId = message.id;

                this.updateObserver();
            }
            else {
                this.innerLoading = true;

                if (this.observer) this.observer.disconnect();
                if (this.messageObserver) this.messageObserver.disconnect();

                const target = this;
                const callback = async messages => {
                    this.innerMessages = messages;

                    await this.waitImages(messages);

                    const id = 'chat-message-' + (message.id ? message.id : message.nonce + '-nonce');
                    const el = document.getElementById(id);

                    if (el) {
                        el.scrollIntoView({
                            block: 'center',
                        });
                    }

                    this.highlightedMessageId = message.id;
                    this.innerLoading = false;

                    this.updateObserver();
                    this.updateMessageObserver();
                };

                this.$emit('toMessage', { message, target, callback });
            }
        },

        updateMessage(...args) {
            let id;
            let data;
            let nonce;
            let isMessage = false;

            if (args.length === 1 && typeof args[0] === 'object') {
                isMessage = true;
                id = args[0].id;
                data = args[0];
                nonce = args[0].nonce;
            }
            else if (args.length === 2 && typeof args[0] === 'number') {
                id = args[0];
                data = args[1];
            }
            else {
                return;
            }

            const messages = [...this.innerMessages].reverse();

            for (let i = 0; i < messages.length; i++) {
                if (
                    (id && messages[i].id && messages[i].id === id) ||
                    (nonce && messages[i].nonce && messages[i].nonce === nonce)
                ) {
                    const index = messages.length - i - 1;
                    this.innerMessages.splice(index, 1, { ...messages[i], ...data });
                    this.sortMessages();
                    return;
                }

                if (
                    (nonce && messages[i].nonce && messages[i].nonce < nonce) ||
                    (id && messages[i].id && messages[i].id < id)

                ) {
                    break;
                }
            }

            if (isMessage) {
                this.addMessage(data);
            }
        },

        addMessage(message) {
            if (!this.isLastMessage(message)) {
                if (document.hidden) {
                    message._isNotSeen = true;
                }

                this.innerMessages = [...this.innerMessages, message];

                if (document.hidden) {
                    this.$nextTick(() => {
                        const unread = this.$refs.unread;

                        if (!unread) return;

                        const el = unread[0];

                        if (el) {
                            el.scrollIntoView({
                                block: 'center',
                            });
                        }
                    });
                }
                else {
                    this.updateScroll();
                }
            }
        },
    },
};
</script>

<style>
.chat-private-room__template {
    background-color: var(--light-c);
    width: 100%;
    height: 100%;
}

.chat-private-room__template .chat-template__body {
    min-height: 0;
    overflow: visible;
}

.chat-private-room__body {
    position: relative;
    min-height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.chat-private-room__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-c);
}

.chat-private-room__overflow-wrap {
    position: relative;
    min-height: 0;
    flex: 1;
}

.chat-private-room__overflow-box {
    height: 100%;
    overflow: auto;
}

.chat-private-room__scroll-box {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.chat-private-room__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--light-c);
}

.chat-private-room__drag-zone {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: var(--light-c);
}

.chat-private-room__drag-zone * {
    pointer-events: none;
}

.chat-private-room__drag-zone_show {
    display: block;
}

.chat-private-room__drag-border {
    height: 100%;
    border: 2px dashed var(--border-dark-c);
    border-radius: var(--border-radius);
    background-color: var(--light-c);
    transition: border-color var(--transition), background-color var(--transition);
}

.chat-private-room__drag-zone.chat-private-room__drag-zone_active .chat-private-room__drag-border {
    border-color: var(--border-dark-active-c);
    background-color: var(--bright-bg);
}

.chat-private-room__drag-text-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.chat-private-room__drag-text {
    color: var(--font-secondary-color);
}

.chat-private-room__messages-loading {
    height: 56px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-items-wrap {
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.chat-item + .chat-item {
    margin-top: 12px;
}

.chat-item + .chat-item.chat-item__is-timestamp {
    margin-top: 24px;
}

.chat-item.chat-item__own-message + .chat-item.chat-item__own-message,
.chat-item.chat-item__foreign-message + .chat-item.chat-item__foreign-message {
    margin-top: 8px;
}

.chat-item:first-child {
    margin-top: 16px;
}

.chat-item:last-child {
    margin-bottom: 16px;
}

.chat-timestamp {
    display: flex;
    justify-content: center;
    color: var(--font-secondary-color);
    font-family: var(--f-semi-bold);
    font-size: var(--small-fz);
    line-height: 1.33;
}

.chat-unread {
    display: flex;
    justify-content: center;
    margin-left: -24px;
    margin-right: -24px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: var(--lightest-bg);
    color: var(--font-secondary-color);
    font-family: var(--f-semi-bold);
    font-size: var(--small-fz);
    line-height: 1.33;
}

.chat-message.chat-message_accent::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 24px + 24px);
    height: calc(100% + 4px + 4px);
    margin-left: -24px;
    margin-top: -4px;
    background-color: var(--grey-color);
    opacity: .3;
    animation: accent-chat-message 3s forwards 1s;
}

@keyframes accent-chat-message {
    from {
        opacity: .3;
    }
    to {
        opacity: 0;
    }
}

.chat-message {
    position: relative;
    width: 100%;
    display: flex;
}

.chat-message.chat-message_own {
    justify-content: flex-end;
    align-self: flex-end;
}

.chat-message__avatar-block {
    flex: 0 0 36px;
}

.chat-message.chat-message_foreign .chat-message__avatar-block {
    margin-right: 12px;
}

.chat-message.chat-message_own .chat-message__avatar-block {
    margin-left: 12px;
    order: 2;
}

.chat-message__avatar .chat-list-item-avatar__image {
    user-select:none;
}

.chat-item.chat-item__message-group .chat-message__avatar {
    display: none;
}

.chat-message__sender-block {
    margin-bottom: 4px;
    font-size: var(--small-fz);
    line-height: 1.33;
}

.chat-message.chat-message_own .chat-message__sender-block {
    display: flex;
    justify-content: flex-end;
}

.chat-item.chat-item__message-group .chat-message__sender-block {
    display: none;
}

.chat-message__main-block {
    width: calc(100% - 36px - 12px);
}

.chat-message.chat-message_own .chat-message__main-block {
    order: 1;
}

.chat-message__content-block {
    display: flex;
    max-width: 100%;
}

.chat-message__content-block.chat-message__content-block_own {
    justify-content: flex-end;
}

.chat-message__cloud {
    position: relative;
    max-width: calc(520px - 36px - 8px);
    padding: 8px 12px;
    background-color: var(--bright-bg);
    border-radius: 0 var(--border-radius-x2) var(--border-radius-x2) var(--border-radius-x2);
    order: 1;
    overflow: hidden;
}

.chat-message__cloud.chat-message__cloud_own {
    background-color: var(--blue-lightest);
    border-radius: var(--border-radius-x2) 0 var(--border-radius-x2) var(--border-radius-x2);
    order: 2;
}

.chat-message__cloud.chat-message__cloud_w100 {
    width: min(476px, calc(100% - 20px));
}

.chat-message__cloud.chat-message__cloud_error {
    background-color: var(--error-brightest-color);
}

.chat-message__reply {
    margin-bottom: 8px;
}

.chat-message__offer-wrap {
    margin-left: -4px;
    margin-right: -4px;
    padding: 12px;
    display: flex;
    background-color: var(--light-c);
}

.chat-message__offer-image-block {
    width: 120px;
    height: 120px;
    margin-right: 16px;
}

.chat-message__offer-image {
    max-width: 120px;
    max-height: 120px;
    margin-right: 20px;
    border-radius: var(--border-radius);
}

.chat-message__offer-name-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chat-message__offer-name {
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-message__offer-price-wrap {
    margin-bottom: 8px;
}

.chat-message__offer-price {
    font-size: var(--big-fz);
    line-height: 1.37;
}

.chat-message__offer-out-stock {
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.chat-message__image-wrap {
    position: relative;
    margin-right: -4px;
    margin-left: -4px;
    max-height: 100%;
}

.chat-message__image {
    min-width: 120px;
    min-height: 120px;
    max-height: 520px;
    background-color: var(--light-c);
    border-radius: var(--border-radius-x2);
}

.chat-message__avatar-block + .chat-message__main-block .chat-message__image {
    max-height: 472px;
}

/* Изображение с подписью */
.chat-message__image-wrap:not(:last-child) .chat-message__image {
    min-width: 280px;
}

/* Изображение с подписью */
.chat-message__reply ~ .chat-message__image-block .chat-message__image {
    min-width: 280px;
}

.chat-message__image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: var(--border-radius-x2);
    opacity: 0;
    transition: opacity var(--transition);
    cursor: pointer;
}

.chat-message__image-background.chat-message__image-background_active {
    cursor: default;
    opacity: .4;
}

.chat-message__image-progress-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-message__image-progress .round-loader__progress {
    stroke: var(--light-c);
}

.chat-message__image-progress .round-loader__close-icon {
    fill: var(--light-c);
}

.chat-message__image-wrap ~ .chat-message__text-wrap {
    margin-top: 8px;
}

.chat-message__file-wrap {
    display: flex;
}

.chat-message__file-icon-wrap {
    margin-right: 8px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    fill: var(--font-secondary-color);
}

.chat-message__file-progress .round-loader__close-icon {
    fill: var(--font-secondary-color);
}

.chat-message__file-progress .round-loader__progress {
    stroke: var(--font-secondary-color);
}

.chat-message__file-button-wrap {
    max-width: calc(100% - 20px - 8px);
    display: grid;
    grid-template-columns: 1fr max-content;
}

.chat-message__file-button .button-text__label {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.chat-message__file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-message__file-extension {
    flex-shrink: 0;
    text-transform: lowercase;
}

.chat-message__file-size {
    color: var(--font-secondary-color);
    white-space: nowrap;
}

.chat-message__text {
    white-space: pre-wrap;
}

.chat-message__link-wrap {
    margin-top: 8px;
    display: flex;
}

.chat-message__link-main-block::before {
    content: "";
    margin-right: 12px;
    height: 100%;
    width: 3px;
    background-color: var(--primary-color);
    border-radius: var(--border-radius);
    flex-shrink: 0;
}

.chat-message__link-main-block {
    margin-right: 12px;
    display: flex;
}

.chat-message__link-title {
    font-family: var(--f-bold);
}

.chat-message__link-description {
    margin-top: 4px;
    font-size: var(--small-fz);
    line-height: 1.5;
}

.chat-message__link-logo-block {
    width: 48px;
    height: 48px;
}

.chat-message__link-logo {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    object-fit: cover;
    border-radius: var(--border-radius);
}

.chat-message__text-wrap {
    overflow-wrap: break-word;
    white-space: pre-line;
}

.chat-message__highlight .u-highlight__search {
    color: var(--primary-color);
    font-family: var(--f-semi-bold);
}

.chat-message__text-wrap a {
    text-decoration-line: underline;
    color: var(--primary-color);
}

.chat-message__text-wrap a:hover {
    text-decoration-line: none;
}

.chat-message__file-wrap ~ .chat-message__text-wrap {
    margin-top: 8px;
    min-width: 0;
}

.chat-message__footer {
    margin-top: 4px;
    width: 100%;
    display: inline-block;
    font-size: var(--micro-fz);
    line-height: 1.4;
}

.chat-message__footer.chat-message__footer_own {
    text-align: right;
}

.chat-message__footer.chat-message__footer_fly {
    position: absolute;
    bottom: 8px;
    padding: 2px 4px;
    width: auto;
    margin-top: 0;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.chat-message__footer.chat-message__footer_fly::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-c);
    opacity: .5;
}

.chat-message__footer.chat-message__footer_fly.chat-message__footer_not-sent::before {
    background-color: var(--primary-color);
    opacity: .5;
}

.chat-message__footer.chat-message__footer_fly.chat-message__footer_foreign {
    left: 8px;
}

.chat-message__footer.chat-message__footer_fly.chat-message__footer_own {
    right: 8px;
}

.chat-message__time {
    font-size: var(--micro-fz);
    line-height: 1.4;
    color: var(--font-secondary-color);
}

.chat-message__footer.chat-message__footer_fly .chat-message__time {
    position: relative;
    z-index: 1;
    color: var(--light-c);
}

.chat-message__time + .chat-message__icon {
    margin-left: 4px;
}

.chat-message__not-sent-text {
    color: var(--primary-color);
    font-size: var(--micro-fz);
}

.chat-message__saved-icon,
.chat-message__seen-icon {
    fill: var(--blue-medium);
    vertical-align: middle;
}

.chat-message__footer.chat-message__footer_fly .chat-message__saved-icon,
.chat-message__footer.chat-message__footer_fly .chat-message__seen-icon,
.chat-message__footer.chat-message__footer_fly .chat-message__loading-icon {
    position: relative;
    z-index: 1;
    fill: var(--light-c);
}

.chat-message__footer.chat-message__footer_fly .chat-message__not-sent-text {
    position: relative;
    z-index: 1;
    color: var(--light-c);
}

.chat-message__loading-icon {
    fill: var(--font-secondary-color);
    vertical-align: middle;
}

.chat-message__reply-block {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    order: 2;
    transition: opacity var(--transition);
}

.chat-message__reply-block.chat-message__reply-block_foreign {
    margin-left: 4px;
}

.chat-message__reply-block.chat-message__reply-block_own {
    margin-right: 4px;
    order: 1;
}

.chat-message:hover .chat-message__reply-block {
    opacity: 1;
}

.chat-message__error-block {
    order: 3;
    flex-shrink: 0;
    flex-grow: 0;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-message__error-menu-icon {
    fill: var(--font-secondary-color);
}

.chat-message__error-menu-icon:hover,
.chat-message__error-menu-icon.chat-message__error-menu-icon_active {
    fill: var(--dark-c);
}

.chat-message__reply-icon {
    fill: var(--font-secondary-light-color);
}

.chat-message__reply-button:hover .chat-message__reply-icon {
    fill: var(--font-secondary-color);
}

.chat-private-room__to-down-button {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--grey-darkest-color);
    opacity: .4;
    transition: opacity var(--transition);
}

.chat-private-room__to-down-button:hover,
.chat-private-room__to-down-button:active {
    opacity: .6;
}

.chat-private-room__to-down-icon {
    fill: var(--light-c);
}

.chat-payload {
    flex-shrink: 0;
    flex-grow: 0;
    font-size: var(--small-fz);
    line-height: 1.33;
}

.chat-payload__row {
    width: 100%;
    height: 60px;
    border-top: 1px solid var(--border-light-c);
}

.chat-payload__reply {
    padding: 12px 24px;
}

.chat-payload__image {
    padding: 12px 24px;
    display: flex;
    align-items: center;
}

.chat-payload__image-img-block {
    flex-shrink: 0;
    margin-right: 12px;
}

.chat-payload__image-img-wrap {
    width: 36px;
    height: 36px;
    border-radius: var(--border-radius);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-payload__image-img {
    width: 36px;
    height: 36px;
    border-radius: var(--border-radius);
    object-fit: cover;
}

.chat-payload__image-name-block {
    display: flex;
    margin-right: 12px;
    width: calc(100% - 36px - 12px - 12px - 20px);
}

.chat-payload__image-name-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-payload__image-name-extension {
    flex-shrink: 0;
}

.chat-payload__file {
    padding: 12px 24px;
    display: flex;
}

.chat-payload__file-icon-block {
    position: relative;
    margin-right: 12px;
    flex-shrink: 0;
}

.chat-payload__file-icon {
    width: 36px;
    height: 36px;
}

.chat-payload__file-icon-extension {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    text-transform: uppercase;
    color: var(--font-secondary-light-color);
    font-family: var(--f-bold);
    font-size: 8px;
    line-height: 1.5;
    cursor: default;
    user-select: none;
}

.chat-payload__file-name-block {
    margin-right: 12px;
    width: calc(100% - 36px - 12px - 12px - 20px);
}

.chat-payload__file-name {
    width: 100%;
    margin-bottom: 4px;
    display: flex;
}

.chat-payload__file-name-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-payload__file-name-extension {
    flex-shrink: 0;
}

.chat-payload__file-size {
    color: var(--font-secondary-color);
}

.chat-payload__remove-block {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.chat-payload__remove-button {
    font-size: 0;
}

.chat-payload__remove-icon {
    fill: var(--font-secondary-color);
}

.chat-payload__remove-button:hover .chat-payload__remove-icon {
    fill: var(--font-secondary-dark-color);
}

.chat-private-room__footer {
    width: 100%;
    min-height: 60px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    padding: 0 24px 18px;
    border-top: 1px solid var(--border-light-c);
}

.chat-private-room__search-navigation {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.chat-private-room__search-count {
    display: flex;
    align-items: center;
    font-size: var(--small-fz);
    line-height: 1.33;
}

.chat-private-room__search-navigation-buttons {
    display: flex;
    align-items: center;
}

.chat-private-room__search-button {
    display: flex;
    align-items: center;
}

.chat-private-room__search-button:not(:last-child) {
    margin-right: 16px;
}

.chat-private-room__search-icon {
    fill: var(--dark-c);
}

.chat-private-room__search-button:disabled .chat-private-room__search-icon {
    fill: var(--font-secondary-color);
}

.chat-private-room__search-button:hover:not(:disabled) .chat-private-room__search-icon,
.chat-private-room__search-button:active:not(:disabled) .chat-private-room__search-icon {
    fill: var(--primary-color);
}

.chat-private-room__attach-block {
    flex-shrink: 0;
    height: 24px;
    margin-right: 12px;
    align-self: flex-end;
    display: flex;
    align-items: center;
}

.chat-private-room__attach-icon {
    fill: var(--font-secondary-color);
    vertical-align: middle;
}

.chat-private-room__attach-button:hover .chat-private-room__attach-icon {
    fill: var(--dark-c);
}

.chat-private-room__input-block {
    flex-grow: 1;
    align-self: flex-end;
    cursor: text;
}

.chat-private-room__input-overflow-box {
    max-height: calc(60px + 18px);
    margin-bottom: 2px;
    overflow: hidden auto;
}

.chat-private-room__input-scroll-box {
    position: relative;
}

.chat-private-room__input-placeholder {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--font-secondary-color);
    cursor: text;
}

.chat-private-room__input {
    width: 100%;
    margin-top: auto;
    padding-top: 18px;
    caret-color: var(--dark-c);
    user-select: text;
    outline: none;
    overflow-wrap: break-word;
    word-break: break-word;
}
@media (min-width: 768px) {
    .chat-private-room__input.chat-private-room__input-mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .chat-private-room__input.chat-private-room__input-desktop {
        display: none;
    }
}

.chat-private-room__send-block {
    flex-shrink: 0;
    height: 24px;
    margin-left: 12px;
    align-self: flex-end;
    display: flex;
    align-items: center;
}

.chat-private-room__send-button:disabled .chat-private-room__send-icon {
    fill: var(--font-secondary-light-color);
}

.chat-private-room__send-icon {
    fill: var(--font-secondary-dark-color);
    vertical-align: middle;
}
</style>