var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.facebook || _vm.vkontakte || _vm.instagram)?_c('ul',{staticClass:"socials"},[(_vm.vkontakte)?_c('li',{staticClass:"socials__item"},[_c('a',{staticClass:"socials__link",class:{
                'socials__link_white': _vm.white,
            },attrs:{"href":_vm.vkontakte,"target":"_blank","rel":"noopener"}},[_c('UIcon',{staticClass:"socials__icon socials__icon_vk",class:{
                    'icon_white': _vm.white,
                },attrs:{"name":"vk"}})],1)]):_vm._e(),_vm._v(" "),(_vm.instagram)?_c('li',{staticClass:"socials__item"},[_c('a',{staticClass:"socials__link",class:{
                'socials__link_white': _vm.white,
            },attrs:{"href":_vm.instagram,"target":"_blank","rel":"noopener"}},[_c('UIcon',{staticClass:"socials__icon socials__icon_insta",class:{
                    'icon_white': _vm.white,
                },attrs:{"name":"insta"}})],1)]):_vm._e(),_vm._v(" "),(_vm.facebook)?_c('li',{staticClass:"socials__item"},[_c('a',{staticClass:"socials__link",class:{
                'socials__link_white': _vm.white,
            },attrs:{"href":_vm.facebook,"target":"_blank","rel":"noopener"}},[_c('UIcon',{staticClass:"socials__icon socials__icon_fb",class:{
                    'icon_white': _vm.white,
                },attrs:{"name":"fb"}})],1)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }