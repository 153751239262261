<template>
    <div
        class="u-spinner"
        :class="{
            'u-spinner_small': small,
            'u-spinner_big': big,
            'u-spinner_light': light,
            'u-spinner_dark': dark,
            'u-spinner_center': center,
            'u-spinner_absolute-center': absoluteCenter,
        }"
    >
        <UIcon
            name="spinner"
            class="u-spinner__icon"
        ></UIcon>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'USpinner',

    components: { UIcon },

    props: {
        small: Boolean,
        big: Boolean,
        center: Boolean,
        absoluteCenter: Boolean,
        light: Boolean,
        dark: Boolean,
    },
};
</script>

<style>
.u-spinner {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    color: var(--primary-color);
    background-color: transparent;
    font-size: 0;
    pointer-events: none;
}

.u-spinner.u-spinner_light {
    color: var(--light-c);
}

.u-spinner.u-spinner_dark {
    color: var(--dark-c);
}

.u-spinner.u-spinner_small {
    width: 16px;
    height: 16px;
}

.u-spinner.u-spinner_big {
    width: 36px;
    height: 36px;
}

.u-spinner.u-spinner_center {
    margin-left: auto;
    margin-right: auto;
}

.u-spinner.u-spinner_absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.u-spinner__icon {
    animation: spinner 1s infinite ease-out;
}

.u-spinner__icon {
    width: 100%;
    height: 100%;
    stroke: currentColor;
}
</style>