<template>
    <label
        class="u-checkbox__wrap"
        :class="{
            'u-checkbox__wrap_disabled': disabled,
            'u-checkbox__wrap_invalid': invalid,
            'u-checkbox__wrap_border': border,
            'u-checkbox__wrap_checked': value,
        }"
    >
        <input
            :id="id"
            :checked="value"
            type="checkbox"
            class="visually-hidden u-checkbox__input"
            :class="{
                'u-checkbox__input_indeterminate': indeterminate
            }"
            :disabled="disabled"
            @click="toggle"
        >

        <span class="u-checkbox__icon-wrap">
            <UIcon
                v-if="indeterminate"
                name="minus"
                light
                small
                class="u-checkbox__icon u-checkbox__icon_minus"
            ></UIcon>

            <UIcon
                v-else
                name="done"
                light
                small
                class="u-checkbox__icon u-checkbox__icon_check"
            ></UIcon>
        </span>

        <span class="u-checkbox__label">
            <slot name="label">
                {{ label }}
            </slot>
        </span>
    </label>
</template>

<script>
import genId from '@ui/utils/genId.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'UCheckbox',

    components: { UIcon },

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        // attrs
        id: {
            type: String,
            default: () => genId(),
        },
        label: String,

        // state
        value: Boolean,
        indeterminate: Boolean,
        disabled: Boolean,
        invalid: Boolean,

        // style
        border: Boolean,
    },

    methods: {
        toggle($event) {
            const target = this;
            const value = !this.value || this.indeterminate;
            $event.target.checked = value;
            this.$emit('change', { target, value });
        },
    },
};
</script>

<style>
.u-checkbox__wrap {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: fit-content;
    max-width: 100%;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.u-checkbox__wrap.u-checkbox__wrap_border {
    width: 100%;
    height: 80px;
    padding: 20px;
    font-family: var(--f-semi-bold);
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
}

.u-checkbox__wrap.u-checkbox__wrap_border:hover:not(.u-checkbox__wrap_disabled),
.u-checkbox__wrap.u-checkbox__wrap_border.u-checkbox__wrap_checked:not(.u-checkbox__wrap_disabled) {
    border-color: var(--border-dark-active-c);
}

.u-checkbox__wrap.u-checkbox__wrap_border.u-checkbox__wrap_disabled {
    border-color: var(--border-light-c);
}

.u-checkbox__wrap.u-checkbox__wrap_invalid {
    border-color: var(--primary-color);
}

.u-checkbox__wrap.u-checkbox__wrap_border.u-checkbox__wrap_invalid {
    background-color: var(--error-brightest-color);
}

.u-checkbox__wrap.u-checkbox__wrap_invalid:hover {
    border-color: var(--error-main-color);
}

.u-checkbox__icon-wrap {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    background-color: var(--light-c);
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
    transition: border-color .1s ease-in, background-color .1s ease-in;
}

.u-checkbox__wrap.u-checkbox__wrap_invalid .u-checkbox__icon-wrap {
    border-color: var(--primary-color);
}

.u-checkbox__wrap:hover .u-checkbox__icon-wrap,
.u-checkbox__input:focus .u-checkbox__icon-wrap {
    border-color: var(--fields-border);
    transition: border-color var(--transition), background-color var(--transition);
}

.u-checkbox__wrap_checked .u-checkbox__icon-wrap {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    transition: border-color var(--transition), background-color var(--transition);
}

.u-checkbox__wrap_checked:hover .u-checkbox__icon-wrap,
.u-checkbox__wrap_checked .u-checkbox__input:focus .u-checkbox__icon-wrap {
    background-color: var(--primary-dark-color);
    border-color: var(--primary-dark-color);
}

.u-checkbox__wrap_disabled {
    color: var(--font-secondary-light-color);
    cursor: default;
    pointer-events: none;
}

.u-checkbox__wrap_disabled .u-checkbox__icon-wrap {
    background-color: var(--bright-bg);
    border-color: var(--border-light-c);
}

.u-checkbox__icon {
    margin: 1px;
    transform: scale(0.5);
    opacity: 0;
    transition: all .1s ease-in;
    fill: var(--light-c);
}

.u-checkbox__wrap_checked .u-checkbox__icon {
    transform: scale(1);
    opacity: 1;
}

.u-checkbox__label {
    display: flex;
    align-items: center;
}

.u-checkbox__wrap_invalid .u-checkbox__label {
    color: var(--primary-color);
}
</style>