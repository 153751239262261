<template>
    <div>
        <h1 class="h2 h2_block">
            Характеристики товара {{ product.name }}
        </h1>

        <div class="attributes layer-1">
            <div
                v-for="(group, groupIndex) in product.properties"
                :key="'group-' + groupIndex"
                class="group-wrap"
            >
                <MarketItemAttributesGroup
                    :attributes="group.attributes"
                    :item="product"
                    modelName="product"
                    class="market-item-page__attributes"
                >
                    <template #preTable>
                        <h3 class="h4 h4_text">
                            {{ group.name }}
                        </h3>
                    </template>
                </MarketItemAttributesGroup>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import MarketItemAttributesGroup from '@/components/MarketItemAttributesGroup.vue';

export default {
    name: "MarketProductAttributesPage",

    components: {
        MarketItemAttributesGroup,
    },

    computed: {
        ...mapState({
            product: state => state.marketProduct.product,
        }),
    },
};
</script>

<style scoped>
.attributes {
    padding: var(--base-card-padding);
}

.group-wrap:not(:last-child) {
    margin-bottom: 24px;
}
</style>

