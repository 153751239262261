<template functional>
    <div
        :class="[
            'post-editor__block',
            'post-editor__block_' + props.widget.type,
        ]"
    >
        <div
            :class="[
                props.widget.marginTop ? 'indent-wrap_mt' + props.widget.marginTop : '',
                props.widget.marginBottom ? 'indent-wrap_mb' + props.widget.marginBottom : '',
                props.widget.paddingTop ? 'indent-wrap_pt' + props.widget.paddingTop : '',
                props.widget.paddingBottom ? 'indent-wrap_pb' + props.widget.paddingBottom : '',
            ]"
            class="indent-wrap"
        >
            <pre><code>{{ props.widget.code }}</code></pre>
        </div>
    </div>
</template>

<script>
export default {
    name: "PostEditorCode",

    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style scoped>
.post-editor__block_code pre {
    margin: 0;
    background-color: var(--bright-bg);
    border: 1px solid var(--border-light-c);
    box-sizing: border-box;
    border-radius: var(--border-radius-x2);
}

.post-editor__block_code code {
    display: block;
    overflow: auto;
}
@media (min-width: 1040px) {
    .post-editor__block_code code {
        padding: 36px;
    }
}
@media (max-width: 1039px) {
    .post-editor__block_code code {
        padding: 24px;
    }
}
</style>