var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RouterLink',{class:[
        'item',
        _vm.small && 'item_small' ],attrs:{"to":{
        name: 'news-item',
        params: {
            codename: _vm.item.codename,
        },
    }}},[_c('picture',[_c('source',{attrs:{"srcset":("\n                " + (_vm.$links.uploads + _vm.item.cover.thumbnails.news_cover_list_vertical_x2) + " 2x,\n                " + (_vm.$links.uploads + _vm.item.cover.thumbnails.news_cover_list_vertical) + ",\n            "),"media":"(min-width: 1040px)"}}),_vm._v(" "),_c('source',{attrs:{"srcset":("\n                " + (_vm.$links.uploads + _vm.item.cover.thumbnails.news_cover_list_horizont_x2) + " 2x,\n                " + (_vm.$links.uploads + _vm.item.cover.thumbnails.news_cover_list_horizont) + ",\n            "),"media":"(max-width: 1039px)"}}),_vm._v(" "),_c('img',{staticClass:"cover",attrs:{"src":_vm.$links.uploads + _vm.item.cover.thumbnails.news_cover_detail_x2,"loading":"lazy"}})]),_vm._v(" "),_c('div',{staticClass:"info"},[_c('time',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("dt")(_vm.item.publication_date)))]),_vm._v(" "),_c('h2',{staticClass:"title"},[_vm._v("\n            "+_vm._s(_vm.item.title)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"more-link-wrap"},[_c('span',{staticClass:"more-link"},[_vm._v("Подробнее")]),_vm._v(" "),_c('UIcon',{staticClass:"more-link-icon",attrs:{"name":"arrow-right","small":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }