<template>
    <div
        class="market-item-offer"
        :class="{
            'market-item-offer_early-adaptation': earlyAdaptation,
        }"
    >
        <div class="market-item-offer__shop-delivery-wrap">
            <div class="market-item-offer__shop">
                <ShopName
                    :shop="offer.shop"
                    hint
                    mobilePopup
                ></ShopName>

                <p class="market-item-offer__address">
                    {{ offer.shop.city.name }}, {{ offer.shop.address }}
                </p>

                <StarsRating
                    :value="(offer.shop || {}).rating"
                    class="mt-8"
                ></StarsRating>
            </div>

            <DeliveryInfo
                :offer="offer"
                :shop="offer.shop"
                :currentWork="offer.shop.current_work"
                list
                small
                class="market-item-offer__delivery-info"
            ></DeliveryInfo>
        </div>

        <div class="market-item-offer__price-actions-wrap">
            <div class="market-item-offer__price-wrap">
                <span class="market-item-offer__price">{{ offer.price | price }}</span>

                <span
                    v-if="offer.amount === 1"
                    class="text_red text_semi-bold text_small-fz"
                >Последний товар</span>
            </div>

            <div class="market-item-offer__actions market-item__actions">
                <ChatBtn
                    :id="offer.shop.id"
                    class="market-item__chat-btn"
                ></ChatBtn>

                <AddCartBtn
                    :offer="offer"
                    :item="item"
                    :modelName="modelName"
                    :disabled="!offer.in_stock"
                    class="market-item__action-btn"
                    @addToCart="onAddToCart"
                    @deleteFromCart="onDeleteFromCart"
                ></AddCartBtn>
            </div>
        </div>
    </div>
</template>

<script>
import StarsRating from "./StarsRating.vue";
import AddCartBtn from "./project-buttons/AddCartBtn.vue";
import DeliveryInfo from './DeliveryInfo.vue';
import ShopName from '@/components/ShopName.vue';
import ChatBtn from '@/components/project-buttons/ChatBtn.vue';

export default {
    name: 'MarketItemOffer',

    components: {
        ChatBtn,
        ShopName,
        DeliveryInfo,
        StarsRating,
        AddCartBtn,
    },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        offer: {
            type: Object,
            default: () => ({}),
        },

        earlyAdaptation: {
            type: Boolean,
            default: false,
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },
    },

    methods: {
        onAddToCart() {
            this.$emit('addToCart');
        },

        onDeleteFromCart() {
            this.$emit('deleteFromCart');
        },

        onClickChat() {
            this.$emit('clickChat');
        },
    },
};
</script>

<style scoped>
.market-item-offer {
    position: relative;
}
@media (min-width: 768px) {
    .market-item-offer {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .market-item-offer {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}
@media (min-width: 414px) {
    .market-item-offer {
        display: flex;
    }
}
@media (min-width: 414px) and (max-width: 767px) {
    .market-item-offer {
        align-items: flex-end;
    }
}

.market-item-offer__shop-delivery-wrap {
    flex-grow: 1;
}
@media (min-width: 768px) {
    .market-item-offer__shop-delivery-wrap {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }
}

@media (min-width: 768px) {
    .market-item-offer__shop {
        flex-basis: 50%;
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .market-item-offer__shop {
        margin-bottom: 12px;
    }
}

.market-item-offer__address {
    margin-bottom: 8px;
}

@media (min-width: 768px) {
    .market-item-offer__delivery-info {
        flex-basis: 50%;
    }
}

.market-item-offer__price-actions-wrap {
    display: flex;
    align-items: center;
}
@media (min-width: 1040px) and (max-width: 1159px) {
    .market-item-offer_early-adaptation .market-item-offer__price-actions-wrap {
        flex-direction: column;
        align-items: flex-end;
    }
}
@media (min-width: 414px) and (max-width: 1039px) {
    .market-item-offer__price-actions-wrap {
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
}
@media (max-width: 413px) {
    .market-item-offer__price-actions-wrap {
        margin-top: 12px;
        justify-content: space-between;
    }
}

@media (min-width: 1160px) {
    .market-item-offer__actions {
        margin-left: 36px;
    }
}
@media (min-width: 1040px) and (max-width: 1159px) {
    .market-item-offer__actions {
        margin-left: 36px;
    }

    .market-item-offer_early-adaptation .market-item-offer__actions {
        margin-left: 0;
        margin-top: 8px;
    }
}
@media (min-width: 414px) and (max-width: 1039px)  {
    .market-item-offer__actions {
        margin-top: 8px;
        justify-content: flex-end;
    }
}

.market-item__chat-btn {
    margin-right: 12px;
}

.market-item-offer__price {
    display: block;
    font-size: var(--h3-fz);
    font-family: var(--f-bold);
}
@media (min-width: 768px) {
    .market-item-offer__price {
        text-align: right;
    }
}
</style>