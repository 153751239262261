var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-gap_medium"},[_c('h1',{staticClass:"h1 h1_block"},[_vm._v("\n        Автозапчасти\n    ")]),_vm._v(" "),_c('TransitionFade',[(_vm.fetching || _vm.initializing)?_c('div',{key:"loading",staticClass:"loading"},[_c('USpinner',{attrs:{"big":""}})],1):_c('div',{key:"categories",staticClass:"categories-grid"},_vm._l((_vm.partCategories),function(category){return _c('div',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],key:category.id,staticClass:"parent layer-1"},[(category.children.length)?_c('RouterLink',{staticClass:"parent__cover-name-block link-primary-hover",attrs:{"to":{
                        name: 'market-parts-catalog-category',
                        params: {
                            slug: category.slug,
                        }
                    }}},[(category.cover)?_c('img',{staticClass:"parent__cover",attrs:{"data-src":_vm.$links.uploads + category.cover.thumbnails.category_cover_list,"alt":category.name}}):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"h3"},[_vm._v("\n                        "+_vm._s(category.name)+"\n                    ")])]):_c('button',{staticClass:"parent__cover-name-block link-primary-hover",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleToCategoryClick(category)}}},[(category.cover)?_c('img',{staticClass:"parent__cover",attrs:{"data-src":_vm.$links.uploads + category.cover.thumbnails.category_cover_list,"alt":category.name}}):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"h3"},[_vm._v("\n                        "+_vm._s(category.name)+"\n                    ")])]),_vm._v(" "),(category.children.length)?_c('ul',{staticClass:"children-list"},_vm._l((category.children.slice(0, 3)),function(child){return _c('li',{key:'child-' + child.id,staticClass:"children-list__item"},[_c('ButtonText',{staticClass:"children-list__link link-primary-hover",on:{"click":function($event){return _vm.handleToCategoryClick(child)}}},[_vm._v(_vm._s(child.name))])],1)}),0):_vm._e(),_vm._v(" "),(category.children.length > 3)?_c('ButtonText',{staticClass:"parent__link",attrs:{"to":{
                        name: 'market-parts-catalog-category',
                        params: {
                            slug: category.slug,
                        }
                    },"underline":"","dark":""}},[_vm._v("\n                    Все запчасти\n                ")]):_vm._e()],1)}),0)]),_vm._v(" "),_c('LastSee')],1)}
var staticRenderFns = []

export { render, staticRenderFns }