import { render, staticRenderFns } from "./MainPage.vue?vue&type=template&id=e9436d0a&scoped=true&"
import script from "./MainPage.vue?vue&type=script&lang=js&"
export * from "./MainPage.vue?vue&type=script&lang=js&"
import style0 from "./MainPage.vue?vue&type=style&index=0&id=e9436d0a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9436d0a",
  null
  
)

export default component.exports