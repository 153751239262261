import { YANDEX_METRIKA_ID } from '@/settings.js';
import isBrowser from '@/lib/isBrowser.js';


export default {
    methods: {
        handleYandexMetrikaGoal(goalName) {
            if (isBrowser && window.ym) {
                ym(YANDEX_METRIKA_ID, 'reachGoal', goalName);
            }
        },
    },
};