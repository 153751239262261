<template>
    <PopupWrap
        :loading="loading"
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="main">
                <div
                    class="cover-wrap"
                    :class="{
                        'hidden-s': !hasMainCover,
                    }"
                >
                    <MarketItemPhotoBlock
                        :mainCover="marketItem.cover"
                        :photos="marketItem.photos"
                        size="small"
                        fullCoverCodename="market_item_cover_detail"
                        miniCoverCodename="market_item_cover_mini"
                    ></MarketItemPhotoBlock>
                </div>

                <div class="main-info">
                    <h2>
                        <MarketItemName
                            :id="marketItemId"
                            :modelName="modelName"
                            :name="marketItem.name"
                            class="title__link"
                        ></MarketItemName>
                    </h2>

                    <MarketItemSku
                        v-if="modelName !== 'product'"
                        :item="marketItem"
                        :modelName="modelName"
                        class="mt-8"
                    ></MarketItemSku>

                    <MarketItemRatingReviewsLink
                        :item="marketItem"
                        :modelName="modelName"
                        reviewsInPopup
                        class="rating"
                    ></MarketItemRatingReviewsLink>

                    <BrandShortInfo
                        v-if="modelName !== 'contract_part'"
                        border
                        isLink
                        :brand="marketItem.brand"
                        class="brand"
                    ></BrandShortInfo>

                    <div class="price-favorite-block">
                        <div
                            v-if="offer.is_visible"
                            class="price-block"
                        >
                            <span class="price">
                                {{ offer.price | price }}
                            </span>

                            <span
                                v-if="offer.amount === 1"
                                class="amount"
                            >
                                Последний товар
                            </span>
                        </div>
                        <p
                            v-else
                            class="text_secondary text_semi-bold"
                        >
                            Нет в наличии
                        </p>

                        <LikeBtn
                            :id="marketItemId"
                            :value="marketItem.is_favorite"
                            :apiName="modelName"
                            @changeFavorite="onChangeFavorite"
                        ></LikeBtn>
                    </div>
                </div>
            </div>

            <div
                v-if="modelName !== 'product' && mainAttributes.length"
                class="section"
            >
                <MarketItemAttributesGroup
                    :attributes="mainAttributes"
                    :item="marketItem"
                    :modelName="modelName"
                    class="attributes"
                >
                    <template #preTable>
                        <h3 class="h4 h4_text">
                            Характеристики товара
                        </h3>
                    </template>
                </MarketItemAttributesGroup>
            </div>

            <MarketPartApplicabilityLinksList
                v-if="!loading && modelName !== 'product'"
                :applicability="applicability"
                :modelName="modelName"
                class="section"
            ></MarketPartApplicabilityLinksList>

            <div
                v-if="modelName === 'contract_part' && marketItem.description"
                class="section"
            >
                <WysiwygView :value="marketItem.description"></WysiwygView>
            </div>

            <ButtonBlock
                :to="{
                    name: modelName === 'product' ? 'market-product-description' : modelName === 'part_product' ? 'market-new-part-description' : 'market-contract-part-description',
                    params: {
                        id: marketItemId,
                    },
                }"
                secondary
                class="page-link"
            >
                Подробнее о товаре
            </ButtonBlock>
        </div>
    </PopupWrap>
</template>

<script>
import { mapMutations } from 'vuex';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import MarketItemPhotoBlock from '@/components/MarketItemPhotoBlock.vue';
import MarketItemAttributesGroup from '@/components/MarketItemAttributesGroup.vue';
import BrandShortInfo from '@/components/BrandShortInfo.vue';
import MarketItemName from '@/components/MarketItemName.vue';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import WysiwygView from '@/components/views/WysiwygView.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import MarketItemSku from '@/components/MarketItemSku.vue';
import MarketPartApplicabilityLinksList from '@/components/MarketPartApplicabilityLinksList.vue';

export default {
    name: 'MarketItemInfoWithOfferPopup',

    components: {
        MarketPartApplicabilityLinksList,
        MarketItemSku,
        ButtonBlock,
        WysiwygView,
        LikeBtn,
        MarketItemRatingReviewsLink,
        MarketItemName,
        MarketItemPhotoBlock,
        PopupWrap,
        MarketItemAttributesGroup,
        BrandShortInfo,
    },

    mixins: [popup],

    props: {
        offer: {
            type: Object,
            default: () => {},
        },

        marketItemId: {
            type: Number,
            default: 0,
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },
    },

    data() {
        return {
            marketItem: {},
            applicability: null,
            loading: true,
        };
    },

    computed: {
        hasMainCover() {
            return ((this.marketItem.cover || {}).thumbnails || {}).market_item_cover_detail || false;
        },

        mainAttributes() {
            let mainAttributes = [];

            if (this.marketItem.properties) {
                this.marketItem.properties.forEach(group => {
                    group.attributes.forEach(attr => {
                        if (attr.is_main) {
                            mainAttributes.push(attr);
                        }
                    });
                });
            }

            return mainAttributes;
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        ...mapMutations({
            CHANGE_FAVORITE: 'cart/changeFavorite',
        }),

        async init() {
            this.loading = true;
            const requestMap = {
                product: this.$api.products.get,
                part_product: this.$api.parts.get,
                contract_part: this.$api.contractParts,
            };

            this.marketItem = await requestMap[this.modelName](this.marketItemId);
            if (this.modelName === 'part_product') {
                await this.getApplicability();
            }
            this.loading = false;
        },

        async getApplicability() {
            const params = {
                oem: this.marketItem.sku,
                get_facets: 'manufacturers,families',
                limit: 0,
                offset: 0,
            };

            const { facets } = await this.$api.oems.get({ params });

            const manufacturers = facets.manufacturers;
            if (manufacturers.length) {
                const families = facets.families;

                let applicability = {};
                manufacturers.families = [];
                manufacturers.forEach(item => {
                    applicability[item.id] = {
                        manufacturer: {
                            name: item.name,
                            id: item.id,
                        },
                        families: [],
                    };
                });

                families.forEach(item => {
                    applicability[item.manufacturer_id].families.push({
                        name: item.name,
                        id: item.id,
                    });
                });
                this.applicability = applicability;
            }
        },

        onChangeFavorite(value) {
            this.marketItem.is_favorite = value;
            this.CHANGE_FAVORITE({ id: this.marketItemId, type: this.modelName, value });
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.main {
    margin-bottom: var(--gap-y-small);
}
@media (min-width: 768px) {
    .main {
        display: flex;
    }
}

@media (min-width: 1040px) {
    .main-info {
        flex-grow: 1;
        margin-left: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .main-info {
        flex-grow: 1;
        margin-left: 36px;
    }
}
@media (max-width: 767px) {
    .cover-wrap {
        margin-bottom: var(--gap-y-small);
    }
}

@media (max-width: 767px) {
    .main-info:first-child .title {
        width: var(--col-5);
    }
}

.title__link {
    font-size: var(--h2-fz);
}

.rating {
    margin-top: var(--gap-micro);
}

.brand {
    margin-top: var(--gap-y-mini);
    margin-bottom: 0;
}
@media (max-width: 767px) {
    .brand {
        width: 100%;
    }
}

.price-favorite-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--gap-y-mini);
}

.price {
    display: block;
    margin-bottom: 4px;
    font-size: var(--h2-fz);
    font-family: var(--f-bold);
}

.amount {
    display: block;
    font-family: var(--f-semi-bold);
    color: var(--primary-color);
}

.section:not(:last-child) {
    margin-bottom: var(--gap-y-small);
}

@media (max-width: 767px) {
    .page-link {
        width: 100%;
        margin-top: auto;
    }
}

@media (min-width: 1040px) {
    .attributes >>> .value {
        width: 380px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .attributes >>> .value {
        width: 252px;
    }
}
</style>