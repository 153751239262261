import isNumber from '@/lib/isNumber.js';


export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        // offers: {
        //     items: [],
        //     current_count: 0,
        // },
        reviews: {
            items: [],
            current_count: 0,
            amounts: [],
        },
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        // setOffers(state, { current_count, results }) {
        //     state.offers.items = results;
        //     state.offers.current_count = current_count;
        // },

        setReviews(state, { current_count, results }) {
            state.reviews.items = results;
            state.reviews.current_count = current_count;
        },

        setReviewsAmounts(state, amounts) {
            state.reviews.amounts = amounts;
        },

        // changeInCartStatus(state, { index, offerId, inCartStatus }) {
        //     if (isNumber(index)) {
        //         state.offers.items[index].in_basket = true;
        //     }
        //     else if (offerId) {
        //         const currentOffer = state.offers.items.find(offer => offer.id === offerId);
        //         currentOffer.in_basket = inCartStatus;
        //     }
        // },

        destroy(state) {
            state.initialized = false;
            // state.offers = {
            //     items: [],
            //     current_count: 0,
            // };

            state.reviews = {
                items: [],
                current_count: 0,
                amounts: [],
            };
        },
    },

    actions: {
        async init({ commit, dispatch }, { id }) {
            try {
                const [reviews, reviewsAmounts] = await Promise.all(
                    [
                        // dispatch('getOffers', { id }),
                        dispatch('getReviews', { id }),
                        dispatch('getReviewsAmounts', { id }),
                    ],
                );

                // commit('setOffers', offers);
                commit('setReviews', reviews);
                commit('setReviewsAmounts', reviewsAmounts);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { reviews };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        // async getOffers({ rootState }, { id }) {
        //     const params = {
        //         product: id,
        //         in_stock: true,
        //         get_facets: 'all',
        //         limit: 5,
        //         offset: 0,
        //         order_by: 'price',
        //         city: rootState.cities.currentCity.id,
        //     };
        //
        //     return await getProductOffers({ params });
        // },

        async getReviews({}, { id }) {
            const params = {
                limit: 3,
                offset: 0,
                order_by: '-create_date',
            };

            return await this.$api.products.reviews.get(id, { params });
        },

        async getReviewsAmounts({} , { id }) {
            return await this.$api.products.reviews.amounts(id);
        },

        async updateReviews({ dispatch, commit }, { id }) {
            try {
                const [reviews, reviewsAmounts] = await Promise.all(
                    [
                        dispatch('getReviews', { id }),
                        dispatch('getReviewsAmounts', { id }),
                    ],
                );
                commit('setReviews', reviews);
                commit('setReviewsAmounts', reviewsAmounts);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};