export default () => {
    return {
        'formData.vehicle_id_type_code': {
            handler(value) {
                setTimeout(() => {
                    this.formData.vehicle_id_number = this.vehicle_id_number[value] || '';
                });
            },
        },

        'formData.vehicle_id_number': {
            handler(value) {
                this.vehicle_id_number[this.formData.vehicle_id_type_code] = value;
            },

            immediate: true,
        },

        'formData.vehicle_doc_type_code': {
            handler(value) {
                this.formData.vehicle_doc_number = this.vehicle_doc_number[value] || '';
                this.formData.vehicle_doc_date = this.vehicle_doc_date[value] || null;
            },
        },

        'formData.vehicle_doc_number': {
            handler(value) {
                this.vehicle_doc_number[this.formData.vehicle_doc_type_code] = value;
            },

            immediate: true,
        },

        'formData.vehicle_doc_date': {
            handler(value) {
                this.vehicle_doc_date[this.formData.vehicle_doc_type_code] = value;
            },

            immediate: true,
        },
    };
};