<template>
    <ul class="profits-list">
        <li class="profit layer-1">
            <div class="profit__text-wrap">
                <h2 class="h2 h2_text profit__title">
                    Гарантия подлинности
                </h2>
                <p class="profit__text">
                    Оплата и&nbsp;оформление ОСАГО производятся в&nbsp;системе страховой компании. После оплаты, полис появляется в&nbsp;базах ГИБДД, РСА, вашем личном кабинете на&nbsp;Ubibi и&nbsp;отправляется на&nbsp;электронную почту.
                </p>
            </div>
        </li>
        <li class="profit layer-1">
            <div class="profit__text-wrap">
                <h2 class="h2 h2_text profit__title">
                    Экономия времени и денег
                </h2>
                <p class="profit__text">
                    Чтобы просмотреть предложения от&nbsp;страховых компаний, вам нужно ввести свои данные всего один раз. Сравнение предложений помогает сэкономить от&nbsp;500 до&nbsp;3000&nbsp;₽.
                </p>
            </div>
            <div class="profit__img-wrap">
                <img
                    srcset="../assets/images/insurance/index-profit-2.jpg,
                                            ../assets/images/insurance/index-profit-2-x2.jpg 2x"
                    src="../assets/images/insurance/index-profit-2-x2.jpg"
                    alt=""
                    width="270"
                    height="270"
                    class="profit__img"
                >
            </div>
        </li>
        <li class="profit layer-1">
            <div class="profit__text-wrap">
                <h2 class="h2 h2_text profit__title">
                    Безопасно
                </h2>
                <p class="profit__text">
                    Строго соблюдаем федеральный закон о&nbsp;защите персональных данных. Мы&nbsp;не&nbsp;публикуем и&nbsp;не&nbsp;разглашаем эту информацию.
                </p>
            </div>
        </li>
        <li class="profit layer-1">
            <div class="profit__text-wrap">
                <h2 class="h2 h2_text profit__title">
                    Без переплат <br> и дополнительных опций
                </h2>
                <p class="profit__text">
                    Окончательный расчет стоимости полиса будет такой&nbsp;же, как на&nbsp;официальных сайтах страховых компаний. Без дополнительных услуг и&nbsp;абонентской платы.
                </p>
            </div>
            <img
                srcset="../assets/images/insurance/index-profit-3.jpg,
                                        ../assets/images/insurance/index-profit-3-x2.jpg 2x"
                src="../assets/images/insurance/index-profit-3-x2.jpg"
                alt=""
                width="300"
                height="172"
                class="profit__img"
            >
        </li>
        <li class="profit layer-1">
            <div class="profit__text-wrap">
                <h2 class="h2 h2_text profit__title">
                    Напомним о продлении
                </h2>
                <p class="profit__text">
                    За&nbsp;месяц до&nbsp;окончания действия страховки отправим письмо по&nbsp;электронной почте. Полис можно будет продлить в&nbsp;личном кабинете в&nbsp;пару кликов.
                </p>
            </div>
            <img
                srcset="../assets/images/insurance/index-profit-5.jpg,
                                        ../assets/images/insurance/index-profit-5-x2.jpg 2x"
                src="../assets/images/insurance/index-profit-5-x2.jpg"
                alt=""
                width="420"
                height="420"
                class="profit__img"
            >
        </li>
    </ul>
</template>

<script>
export default {
    name: 'InsuranceProfitsList',
};
</script>

<style scoped>
@media (min-width: 1281px) {
    .profits-list {
        grid-template-columns: repeat(20, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profits-list {
        grid-template-columns: repeat(12, 1fr);
    }
}
@media (min-width: 1040px) {
    .profits-list {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

.profit {
    position: relative;
    overflow: hidden;
}
@media (max-width: 1039px) {
    .profit:not(:last-child) {
        margin-bottom: var(--y-grid-gap);
    }
}
@media (min-width: 768px){
    .profit {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .profit {
        padding: 20px;
    }
}

@media (min-width: 1281px) {
    .profit:nth-child(1) {
        grid-column: 1/9;
    }

    .profit:nth-child(2) {
        grid-column: 9/21;
    }

    .profit:nth-child(3) {
        grid-column: 15/21;
        grid-row: 2/3;
    }

    .profit:nth-child(4) {
        grid-column: 1/15;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit:nth-child(1) {
        grid-column: 1/6;
    }

    .profit:nth-child(2) {
        grid-column: 6/13;
    }

    .profit:nth-child(3) {
        grid-column: 8/13;
        grid-row: 2/3;
    }

    .profit:nth-child(4) {
        grid-column: 1/8;
    }
}
@media (min-width: 1040px) {
    .profit:nth-child(5) {
        grid-column: 1/-1;
    }
}

@media (min-width: 1281px) {
    .profit:nth-child(2) .profit__text-wrap {
        width: 380px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit:nth-child(2) .profit__text-wrap {
        width: 258px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit:nth-child(2) .profit__text-wrap {
        width: 384px;
    }
}

@media (min-width: 1281px) {
    .profit:nth-child(4) .profit__text-wrap {
        width: 380px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit:nth-child(4) .profit__text-wrap {
        width: 340px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit:nth-child(4) .profit__text-wrap {
        width: 384px;
    }
}

@media (min-width: 1281px) {
    .profit:nth-child(5) .profit__text-wrap {
        width: 544px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit:nth-child(5) .profit__text-wrap {
        width: 474px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit:nth-child(5) .profit__text-wrap {
        width: 384px;
    }
}

.profit__text-wrap {
    position: relative;
    z-index: 2;
}

.profit__text {
    line-height: var(--base-lh);
}

.profit__img {
    height: auto;
}
@media (min-width: 768px) {
    .profit__img {
        position: absolute;
        z-index: 1;
    }
}
@media (max-width: 767px) {
    .profit__img {
        margin-top: 32px;
    }
}

@media (min-width: 1281px) {
    .profit:nth-child(2) .profit__img {
        width: 270px;
        height: 270px;
        bottom: -86px;
        right: -42px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit:nth-child(2) .profit__img {
        width: 270px;
        height: 270px;
        bottom: -58px;
        right: -78px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit:nth-child(2) .profit__img {
        width: 250px;
        height: 250px;
        bottom: -78px;
        right: -46px;
    }
}
@media (max-width: 767px) {
    .profit:nth-child(2) .profit__img-wrap {
        width: 242px;
        height: 170px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        margin-bottom: -24px;
    }
}

@media (min-width: 1281px) {
    .profit:nth-child(4) .profit__img {
        width: 300px;
        height: 172px;
        right: 36px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit:nth-child(4) .profit__img {
        width: 300px;
        height: 172px;
        right: -148px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit:nth-child(4) .profit__img {
        width: 278px;
        height: 160px;
        right: -36px;
    }
}
@media (min-width: 768px) {
    .profit:nth-child(4) .profit__img {
        top: 50%;
        transform: translateY(-50%);
    }
}
@media (max-width: 767px) {
    .profit:nth-child(4) .profit__img {
        width: 242px;
        height: 138px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1281px) {
    .profit:nth-child(5) .profit__img {
        width: 420px;
        height: 420px;
        top: 0;
        right: 110px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit:nth-child(5) .profit__img {
        width: 362px;
        height: 362px;
        top: 0;
        right: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit:nth-child(5) .profit__img {
        width: 320px;
        height: 320px;
        bottom: -124px;
        right: -44px;
    }
}
@media (max-width: 767px) {
    .profit:nth-child(5)::after {
        content: "";
        display: block;
        margin-bottom: -130px;
    }
    .profit:nth-child(5) .profit__img {
        width: 242px;
        height: 242px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>