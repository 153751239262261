<template>
    <component
        :is="tag"
        v-bind="attrs"
        class="u-button-icon"
        :class="{
            'u-button-icon_secondary': secondary,
        }"
        @click="$emit('click', $event)"
    >
        <slot>
            <UIcon
                v-bind="icon"
                class="u-button-icon__icon"
            ></UIcon>
        </slot>
    </component>
</template>

<script>
import buttonMixin from '@ui/mixins/button.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';

// TODO: default style padding 4px
export default {
    name: 'UButtonIcon',

    components: {
        UIcon,
    },

    mixins: [
        buttonMixin,
    ],

    props: {
        icon: Object,
        secondary: Boolean,
    },
};
</script>

<style>
.u-button-icon {
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    text-decoration: none;
    fill: var(--dark-c);
    transition:
        background-color var(--transition),
        border-color var(--transition),
        fill var(--transition);
}

.u-button-icon:hover {
    fill: var(--primary-color);
}

.u-button-icon__icon {
    font-size: 0;
}

.u-button-icon.u-button-icon_secondary {
    fill: var(--dark-c);
    background-color: var(--light-c);
    border: 1px solid var(--border-dark-c);
}

.u-button-icon.u-button-icon_secondary:hover,
.u-button-icon.u-button-icon_secondary:focus {
    background-color: var(--lightest-bg);
}

.u-button-icon.u-button-icon_secondary:active {
    background-color: var(--light-bg);
    border-color: var(--border-dark-c);
}

.u-button-icon.u-button-icon_secondary:disabled,
.u-button-icon.u-button-icon_secondary.u-button-icon_disabled {
    fill: var(--font-secondary-light-color);
    border-color: var(--border-light-c);
}
</style>