<script>
export default {
    name: 'TransitionExpandFade',

    functional: true,

    render(createElement, context) {
        const data = {
            props: {
                name: 'expand-fade',
            },

            on: {
                afterEnter(element) {
                    element.style.height = 'auto';
                },

                enter(element) {
                    const { width } = getComputedStyle(element);
                    /* eslint-disable no-param-reassign */
                    element.style.width = width;
                    element.style.position = 'absolute';
                    element.style.visibility = 'hidden';
                    element.style.height = 'auto';
                    /* eslint-enable */
                    const { height } = getComputedStyle(element);
                    /* eslint-disable no-param-reassign */
                    element.style.width = null;
                    element.style.position = null;
                    element.style.visibility = null;
                    element.style.height = 0;
                    /* eslint-enable */
                    // Force repaint to make sure the
                    // animation is triggered correctly.
                    // eslint-disable-next-line no-unused-expressions
                    getComputedStyle(element).height;
                    requestAnimationFrame(() => {
                        // eslint-disable-next-line no-param-reassign
                        element.style.height = height;
                    });
                },

                afterLeave(element) {
                    element.style.height = 'auto';
                },
            },
        };
        if (context.children.length > 1) return createElement('transition-group', data, context.children);
        else return createElement('transition', data, context.children);
    },
};
</script>

<style>
.expand-fade-enter-active {
    transition-property: height;
    transition-duration: .2s;
    transition-timing-function: ease-out;
    overflow: hidden;
}

.expand-fade-enter {
    height: 0;
}

.expand-fade-leave-active {
    transition-property: opacity;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.expand-fade-leave {
    opacity: 1;
}

.expand-fade-leave-to {
    opacity: 0;
}
</style>