<template>
    <PopupWrap
        :loading="initLoading"
        noPadding
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="header">
                <h2 class="h2">
                    <template v-if="choosable">
                        Выбрать пункт выдачи
                    </template>
                    <template v-else>
                        {{ deliveryOffices.currentCount | plural(['пункт', 'пункта', 'пунктов']) }} выдачи в
                        {{ city ? city.full_name : currentCity.full_name }}
                    </template>
                </h2>
            </div>

            <div class="toolbar">
                <RadioButtonsGroup
                    :value="view"
                    name="offers-desktop"
                    type="text"
                    :options="[
                        {
                            label: 'Список',
                            value: 'list',
                        },
                        {
                            label: 'Карта',
                            value: 'map',
                        },
                    ]"
                    class="view-button-desktop"
                    @change="onChangeView"
                ></RadioButtonsGroup>

                <UButtonBlock
                    v-if="view === 'map'"
                    low
                    secondary
                    class="view-button-mobile view-button"
                    @click="onChangeView('list')"
                >
                    Списком
                </UButtonBlock>

                <UButtonBlock
                    v-if="view === 'list'"
                    low
                    secondary
                    class="view-button-mobile view-button"
                    @click="onChangeView('map')"
                >
                    На карте
                </UButtonBlock>
            </div>

            <div
                class="content-wrap"
                :class="{
                    'content-wrap_list': view === 'list',
                    'content-wrap_map': view === 'map',
                }"
            >
                <InfoMessage
                    v-if="!choosable"
                    flex
                    class="info-message"
                    :class="{
                        'info-message_map': view === 'map',
                    }"
                >
                    Выбор способа получения происходит во время оформления заказа.
                </InfoMessage>

                <div
                    v-if="view === 'list'"
                    class="view_list"
                >
                    <ol class="points-list">
                        <li
                            v-for="point in deliveryOffices.items"
                            :key="'point-' + point.id"
                            class="point-wrap"
                        >
                            <div class="point__block">
                                <div class="point__logo-block">
                                    <img
                                        :src="$links.uploads
                                            + ((point.service.logo || {}).thumbnails || {}).delivery_service_logo_detail"
                                        alt=""
                                        class="point__logo"
                                    >
                                </div>
                                <div class="point__address-block">
                                    {{ point.city }}, {{ point.address }}
                                </div>
                                <div class="point__schedule-block">
                                    <div
                                        v-for="workTimes in point.work_times_merged"
                                        :key="`work-times-${ workTimes.day }-days`"
                                        class="point__schedule-item"
                                    >
                                        <div class="point__schedule">
                                            {{ workTimes.day_name }},
                                            <span v-if="workTimes.is_day_off">Выходной</span><span
                                                v-else
                                            >{{ workTimes.start_time | time }}-{{ workTimes.end_time | time }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="point__block">
                                <div
                                    v-if="shop"
                                    class="point__delivery"
                                >
                                    <span class="text_secondary">Самовывоз: </span>
                                    <template v-if="point.deadline">
                                        {{ point.deadline | dt('DD MM, dd') }}
                                    </template>
                                    <template v-else>
                                        {{ (shop.current_work.pickup_point_delivery || {}).text }}
                                    </template>
                                </div>
                                <div class="point__price-block">
                                    <span
                                        v-if="point.price"
                                        class="point__price"
                                    >{{ point.price | price }}</span>
                                    <span
                                        v-else
                                        class="point__price point__price--free"
                                    >Бесплатно</span>
                                </div>

                                <template v-if="choosable">
                                    <UButtonBlock
                                        v-if="point.id === (selectedDeliveryOffice || {}).id"
                                        primary
                                        active
                                        low
                                        class="point__action"
                                        @click="selectDeliveryOffice(null)"
                                    >
                                        Выбран
                                    </UButtonBlock>

                                    <UButtonBlock
                                        v-else
                                        primary
                                        low
                                        class="point__action"
                                        @click="selectDeliveryOffice(point)"
                                    >
                                        Выбрать
                                    </UButtonBlock>
                                </template>
                            </div>
                        </li>
                    </ol>
                </div>

                <div
                    v-else
                    ref="map"
                    class="map points-map"
                >
                    <yandexMap
                        ref="map"
                        :coords="deliveryOffices.items[0].coordinates"
                        zoom="13"
                        :controls="['zoomControl']"
                        :scrollZoom="false"
                        :options="{ suppressMapOpenBlock: true }"
                        style="width: 100%; height: 100%;"
                        @map-was-initialized="setMapCenter"
                        @actionend="handleActionEnd"
                    >
                        <ymapMarker
                            v-for="(point, index) in deliveryOffices.items"
                            :key="'point-' + point.id"
                            :ref="'point-' + point.id"
                            :coords="point.coordinates"
                            :icon="getYMapMarkerIcon({
                                selected: point.id === (selectedDeliveryOffice || {}).id,
                                active: (balloon.visible && balloon.index === index),
                                type: point.service.codename,
                            })"
                            :markerId="'offer-' + point.id"
                            @click="showBalloon({ point, index })"
                        ></ymapMarker>
                    </yandexMap>

                    <DeliveryOfficeBalloon
                        v-if="balloon.visible"
                        :point="balloon.point"
                        :shop="item.shop"
                        :choosable="choosable"
                        @close="closeBalloon"
                        @select="selectDeliveryOffice"
                    ></DeliveryOfficeBalloon>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import { plural, price, dt } from '@ui/filters/index.js';
import { mapState } from 'vuex';
import { HTTP } from '@/http.js';
import popup from '@/mixins/popup.js';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import RadioButtonsGroup from '@/components/RadioButtonsGroup.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import DeliveryOfficeBalloon from '@/components/DeliveryOfficeBalloon.vue';
import UButtonBlock from '@ui/components/UButton/UButtonBlock.vue';


export default {
    name: 'DeliveryOfficesPopup',

    components: {
        UButtonBlock,
        DeliveryOfficeBalloon,
        InfoMessage,
        RadioButtonsGroup,
        PopupWrap,
        yandexMap,
        ymapMarker,
    },

    filters: {
        plural,
        price,
        dt,

        time(value) {
            return value.slice(0, 5);
        },
    },

    mixins: [popup, ymapMarkerMixin],

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        value: {
            type: Object,
        },

        choosable: {
            type: Boolean,
            default: false,
        },

        services: {
            type: Array,
            default: () => ['cdek', 'ubibi'],
        },

        city: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            initLoading: true,
            mapInstance: null,
            mapCenter: [],
            view: 'list',
            source: null,
            balloon: {
                point: {},
                index: null,
                visible: false,
            },
            deliveryOffices: {
                items: [],
                currentCount: null,
            },
            selectedDeliveryOffice: null,
        };
    },

    computed: {
        ...mapState({
            currentCity: state => state.cities.currentCity,
        }),

        shop() {
            return this.item.shop;
        },
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            if (this.value) {
                this.selectedDeliveryOffice = this.value;
            }

            this.fetchPoints();
        },

        close() {
            this.callback(this.selectedDeliveryOffice);
            this.$emit('close', this._index);
        },

        async fetchPoints() {
            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const limit = 1000;
            const type = 'pvz';
            const city = this.city ? this.city.id : this.currentCity.id;
            const service__codename__in = this.services.join(',');
            const order_by = 'service__order_weight';
            const params = { service__codename__in, order_by, city, type, limit };

            try {
                const [deliveryOfficesResponse, deliveryEstimateResponse] = await Promise.all([
                    this.$api.delivery.offices({ params }),
                    this.$api.delivery.estimate.byCity({
                        service: 'cdek',
                        city_to: city,
                        city_from: ((this.item.shop || {}).city || {}).id,
                        weight: this.item.weight,
                        shop_from: this.item && this.item.shop ? this.item.shop.id : null,
                    }),
                ]);
                this.deliveryOffices.items = deliveryOfficesResponse.results.map(point => {
                    return {
                        ...point,
                        coordinates: point.coordinates.reverse(),
                        price: point.service.codename === 'cdek' && deliveryEstimateResponse.delivery_price,
                        deadline: point.service.codename === 'cdek' && deliveryEstimateResponse.deadline,
                    };
                });
                this.deliveryOffices.currentCount = deliveryOfficesResponse.current_count;
            }
            catch (e) {
                console.error(e);
            }
            finally {
                this.source = null;
                this.initLoading = false;
            }
        },

        async onChangeView(value) {
            this.view = value;
        },

        setMapCenter(mapInstance) {
            this.mapInstance = mapInstance;
            if (this.deliveryOffices.items.length > 1) {
                const pointsList = this.deliveryOffices.items.map(item => item.coordinates);
                mapInstance.setBounds(ymaps.util.bounds.fromPoints(pointsList));
            }
        },

        handleActionEnd(e) {
            const newCenter = e.originalEvent.map.getCenter();
            if (this.mapCenter[0] !== newCenter[0] || this.mapCenter[1] !== newCenter[1]) {
                this.mapCenter = newCenter;
            }
        },

        showBalloon({ point, index }) {
            this.closeBalloon();
            this.balloon.visible = true;
            this.balloon.point = point;
            this.balloon.index = index;
            this.$nextTick(() => {
                if (this.mapInstance && this.mapInstance.length === 2) this.mapInstance.setCenter(this.mapCenter);
            });
        },

        closeBalloon() {
            this.balloon.visible = false;
            this.balloon.point = {};
            this.balloon.index = null;
            this.$nextTick(() => {
                if (this.mapInstance && this.mapInstance.length === 2) this.mapInstance.setCenter(this.mapCenter);
            });
        },

        selectDeliveryOffice(point) {
            this.selectedDeliveryOffice = point;

            setTimeout(() => {
                this.close();
            });
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.header {
    padding: var(--popup-paddings-y) var(--popup-paddings-x) 20px;
}

.toolbar {
    padding: 16px var(--popup-paddings-x);
    border-top: 1px solid var(--border-light-c);
    border-bottom: 1px solid var(--border-light-c);
}

.view-button.button {
    padding-left: 20px;
    padding-right: 20px;
}

.view-button.button >>> .label {
    font-family: var(--f-base);
}

@media (max-width: 767px) {
    .view-button-desktop {
        display: none;
    }
}

@media (min-width: 768px) {
    .view-button-mobile {
        display: none;
    }
}

.content-wrap {
    position: relative;
    min-height: 120px;
    overflow: hidden;
}
.content-wrap.content-wrap_list {
    padding: 20px var(--popup-paddings-x) var(--popup-paddings-y);
}
.content-wrap.content-wrap_map {
    display: flex;
    justify-content: center;
}
@media (max-width: 767px) {
    .content-wrap {
        flex: 1;
    }
}

.view_list {
    width: 100%;
}

.info-message {
    margin-bottom: 20px;
}
.info-message.info-message_map {
    position: absolute;
    bottom: 20px;
    margin-left: var(--popup-paddings-y);
    margin-right: var(--popup-paddings-y);
    width: 100%;
    max-width: calc(100% - (var(--popup-paddings-y) * 2));
    box-shadow: 0 0 20px rgba(31, 31, 31, 0.1);
    z-index: 1;
}

.points-list {
    transition: opacity var(--transition);
}

@media (min-width: 768px) {
    .point-wrap {
        display: flex;
        justify-content: space-between;
    }
}
.point-wrap:not(:first-child) {
    padding-top: 20px;
}
.point-wrap:last-child {
    margin-bottom: -20px;
}
.point-wrap:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light-c);
}

.point__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.point__block:first-child {
    width: 403px;
    flex-grow: 0;
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .point__block:last-child {
        align-items: flex-end;
    }
}
@media (max-width: 767px) {
    .point__block:last-child {
        margin-top: 12px;
    }
}

.point__logo-block {
    width: 60px;
}

.point__address-block {
    margin-top: 4px;
}

.point__schedule-block {
    margin-top: 12px;
}

.point__price-block {
    margin-top: 4px;
}

.point__price {
    font-family: var(--f-bold);
}
.point__price.point__price--free {
    color: var(--accent-green);
}

.point__action {
    margin-top: 12px;
}

.map {
    width: 100%;
    background-color: var(--light-bg);
    opacity: 1;
    transition: opacity var(--transition-fastest);
    border-bottom-left-radius: var(--border-radius-x2);
    border-bottom-right-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .map {
        height: 600px;
    }
}
@media (max-width: 767px) {
    .map {
        flex: 1;
    }
}

.map >>> .ymaps-2-1-79-map,
.map >>> .ymaps-2-1-79-inner-panes {
    border-bottom-left-radius: var(--border-radius-x2);
    border-bottom-right-radius: var(--border-radius-x2);
}
</style>