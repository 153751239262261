var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-select"},[_c('UDropdown',_vm._g({ref:"dropdown",attrs:{"options":_vm.filteredOptions,"hasMore":_vm.hasMore,"loadingMore":_vm.loadingMore,"initialized":_vm.initialized,"optionKey":_vm.optionKey,"optionLabel":_vm.optionLabel,"getOptionKey":_vm.getOptionKey,"getOptionLabel":_vm.getOptionLabel,"selectedOptions":[_vm.innerValue]},on:{"select":_vm.onSelect,"open":function($event){_vm.opened = true; _vm.emitOpen()},"close":function($event){_vm.opened = false; _vm.emitClose()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handlers = ref.handlers;
return [(_vm.searchable)?_c('UInputField',{ref:"input",attrs:{"value":_vm.inputValue,"small":_vm.small,"disabled":_vm.disabled,"clearInputOnBlur":""},on:{"input":_vm.onInput,"change":_vm.onChange,"focus":function($event){_vm.focused = true; _vm.$emit('focus', $event)},"blur":function($event){_vm.focused = false; _vm.$emit('blur', $event)}},nativeOn:{"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false},"focusin":function($event){return handlers.focusin.apply(null, arguments)}},scopedSlots:_vm._u([(_vm.label)?{key:"label-element",fn:function(ref){
var attrs = ref.attrs;
return [_c('ULabel',_vm._b({attrs:{"active":_vm.focused || !!_vm.innerValue || !!_vm.inputValue}},'ULabel',attrs,false),[_vm._v("\n                        "+_vm._s(_vm.label)+"\n                    ")])]}}:null,{key:"append-inner",fn:function(){return [(_vm.loading)?_c('USpinner',{attrs:{"small":""}}):(_vm.innerValue && _vm.active)?_c('UIconButton',{staticClass:"u-select__clear",attrs:{"name":"close-16","small":"","secondary":"","tabindex":"-1","aria-label":"Очистить поле"},on:{"click":_vm.onClickClear},nativeOn:{"focusin":function($event){$event.stopPropagation();}}}):_c('UIconButton',{staticClass:"u-select__arrow",class:{
                            'u-select__arrow_up': _vm.opened,
                        },attrs:{"name":"arrow-down-16","small":"","secondary":"","tabindex":"-1","aria-label":_vm.opened ? 'Показать список значений' : 'Скрыть список значений'},on:{"click":_vm.toggle},nativeOn:{"focusin":function($event){$event.stopPropagation();}}})]},proxy:true}],null,true)}):_c('button',{staticClass:"u-select__pseudo-input",attrs:{"type":"button"},on:{"focus":function($event){_vm.focused = true; _vm.$emit('focus', $event)},"blur":function($event){_vm.focused = false; _vm.$emit('blur', $event)},"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false},"click":_vm.toggle}},[_c('span',{staticClass:"u-select__pseudo-label"},[_vm._v(_vm._s(_vm.getOptionLabel(_vm.innerValue)))]),_vm._v(" "),_c('UIcon',{staticClass:"u-select__arrow u-select__pseudo-arrow",class:{
                        'u-select__arrow_up': _vm.opened,
                    },attrs:{"name":"arrow-filled-down-16","small":"","secondary":""}})],1)]}},{key:"label",fn:function(payload){return [_vm._t("list-label",function(){return [_vm._t("label",function(){return [_vm._v("\n                    "+_vm._s(_vm.getOptionLabel(payload.option))+"\n                ")]},{"item":payload.option})]},null,payload)]}}],null,true)},_vm.listeners)),_vm._v(" "),(!_vm.inputValue && _vm.innerValue && _vm.searchable)?_c('span',{staticClass:"u-select__value",class:{
            'u-select__value_inactive': _vm.focused,
        },on:{"click":_vm.onClickValue}},[_vm._t("value-label",function(){return [_vm._t("label",function(){return [_vm._v("\n                "+_vm._s(_vm.getOptionLabel(_vm.innerValue))+"\n            ")]},{"item":_vm.innerValue})]},{"value":_vm.innerValue})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }