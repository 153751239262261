<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_block popup-title">
                Способ получения
            </h2>

            <InputRadioBorders
                v-model="value"
                :options="options"
                class="delivery-services-list"
            >
                <template #label="{ option }">
                    <div class="delivery-services-list__label">
                        <div class="delivery-services-list__text-wrap">
                            <p class="text_semi-bold">
                                {{ option.label }}
                            </p>
                            <p class="text_base-font text_secondary text_small-fz mt-4">
                                {{ option.description }}
                            </p>
                        </div>
                    </div>

                    <p
                        v-if="option.value === 'ubibi' && ubibiPoint"
                        class="delivery-services-list__address"
                    >
                        {{ ubibiPoint.city }},
                        {{ ubibiPoint.address }}
                        <InfoIcon
                            class="delivery-services-list__tooltip"
                            @click="showPickupPointInfoPopup"
                        ></InfoIcon>
                    </p>

                    <div
                        v-if="!option.disabled"
                        class="delivery-service-block__actions mt-8 text_base-font"
                    >
                        <template v-if="option.value === 'ubibi'">
                            <UButtonText
                                dashed
                                secondary
                                dark
                                class="delivery-service-block__action"
                                @click="showHowPickupOrderPopup"
                            >
                                Как получить заказ?
                            </UButtonText>
                        </template>

                        <template v-else-if="option.value === 'cdek'">
                            <UButtonText
                                dashed
                                secondary
                                dark
                                class="delivery-service-block__action"
                                @click="showCdekPoints"
                            >
                                Пункты выдачи
                            </UButtonText>

                            <UButtonText
                                dashed
                                secondary
                                dark
                                class="delivery-service-block__action"
                                @click="showHowGetCountryDeliveryPopup"
                            >Как оформить доставку?</UButtonText>
                        </template>
                    </div>
                </template>
            </InputRadioBorders>

            <div class="form__actions">
                <ButtonBlock
                    primary
                    class="form__action"
                    @click="save"
                >
                    Выбрать
                </ButtonBlock>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InputRadioBorders from '@/components/_inputs/InputRadioBorders.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
import InfoIcon from '@/components/InfoIcon.vue';
const HowGetCountryDeliveryPopup = () => import('@/components/popups/HowGetCountryDeliveryPopup.vue');
const HowPickupOrderPopup = () => import('@/components/popups/HowPickupOrderPopup.vue');
const DeliveryOfficesPopup = () => import('@/components/popups/DeliveryOfficesPopup.vue');
const PickupPointInfoPopup = () => import('@/components/popups/PickupPointInfoPopup.vue');

export default {
    name: 'CheckoutSelectDeliveryServicePopup',

    components: {
        InfoIcon,
        UButtonText,
        InputRadioBorders,
        PopupWrap,
        ButtonBlock,
    },

    mixins: [popup],

    props: {
        order: {
            type: Object,
            default: () => {},
        },
        options: {
            type: Array,
            default() {
                return [];
            },
        },
        currentValue: {
            type: String,
            default: '',
        },
        ubibiPoint: {
            type: Object,
            default: null,
        },
        selectedCity: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            value: null,
        };
    },

    computed: {
        computedAddGarageMode() {
            return this.outerAddGarageMode ||  this.innerAddGarageMode;
        },
    },

    mounted() {
        this.value = this.currentValue;
    },

    methods: {
        save() {
            this.callback(this.value);
            this.close();
        },

        showHowPickupOrderPopup() {
            this.$popup(HowPickupOrderPopup);
        },

        showHowGetCountryDeliveryPopup() {
            this.$popup(HowGetCountryDeliveryPopup);
        },

        showCdekPoints(index) {
            const item = this.computedOrders[index];
            const city = this.selectedCity;
            const services = ['cdek'];

            const props = {
                item,
                city,
                services,
            };
            const options = { props };
            this.$popup(DeliveryOfficesPopup, options);
        },

        showPickupPointInfoPopup() {
            const point = this.ubibiPoint;
            const props = { point };
            const options = { props };
            this.$popup(PickupPointInfoPopup, options);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.form__actions {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .form__actions {
        display: flex;
        justify-content: flex-end;
    }
}
@media (max-width: 767px) {
    .form__actions {
        margin-top: auto;
    }

    .form__action {
        width: 100%;
    }
}

.delivery-services-list {
    display: block;
}

.delivery-service-block__actions {
    display: flex;
    align-items: center;
}
@media (max-width: 767px) {
    .delivery-service-block__actions {
        flex-wrap: wrap;
        margin-right: -20px;
        margin-bottom: -12px;
    }
}

@media (min-width: 768px) {
    .delivery-service-block__action {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .delivery-service-block__action {
        display: block;
        margin-right: 20px;
        margin-bottom: 12px;
    }
}

@media (min-width: 768px) {
    .delivery-services-list__label {
        display: flex;
        align-items: flex-start;
    }

    .delivery-services-list__text-wrap {
        flex-grow: 1;
    }
}

.delivery-services-list__address {
    margin-top: 12px;
    font-family: var(--f-base);
}

.delivery-services-list__tooltip {
    margin-left: 8px;
}
</style>