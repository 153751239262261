<template>
    <span
        :class="['status', 'status_' + status.color]"
    >
        {{ status.name }}
    </span>
</template>

<script>
export default {
    name: 'Status',

    props: {
        status: {
            type: Object,
            default: () => {},
        },

        color: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped>
.status {
    display: inline-block;
    padding: 6px 12px;
    font-size: 12px;
    font-family: var(--f-semi-bold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: var(--border-radius);
}

.status_black {
    color: var(--font-secondary-dark-color);
    background-color: var(--light-bg);
}

.status_red {
    color: var(--primary-color);
    background-color: var(--primary-light-color);
}

.status_orange {
    color: var(--accent-yellow);
    background-color: var(--accent-yellow-light);
}

.status_blue {
    color: var(--blue-medium);
    background-color: var(--blue-lightest);
}

.status_green {
    color: var(--accent-green);
    background-color: var(--accent-green-light);
}
</style>