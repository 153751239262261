<template>
    <div class="u-tooltip-simple-template">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'UTooltipSimpleTemplate',
};
</script>

<style>
.u-tooltip-simple-template {
    padding: 8px 12px;
    width: fit-content;
    max-width: 320px;
    font-size: var(--small-fz);
    font-family: var(--f-semi-bold);
}
</style>