import { paramsSerializer } from '@/lib/paramsSerializer.js';


export default ({ HTTP }) => ({
    async getManufacturers({ params, cancelToken }) {
        const response = await HTTP.get('/wheelsize/manufacturers/', {
            params,
            paramsSerializer,
            cancelToken,
        });
        return response.data;
    },

    async getModels({ params, cancelToken }) {
        const response = await HTTP.get('/wheelsize/models/', {
            params,
            paramsSerializer,
            cancelToken,
        });
        return response.data;
    },

    async getYears({ params, cancelToken }) {
        const response = await HTTP.get('/wheelsize/years/', {
            params,
            paramsSerializer,
            cancelToken,
        });
        return response.data;
    },

    async getModifications({ params, cancelToken }) {
        const response = await HTTP.get('/wheelsize/modifications/', {
            params,
            paramsSerializer,
            cancelToken,
        });
        return response.data;
    },

    async getWheels({ params, cancelToken }) {
        const response = await HTTP.get('/wheelsize/wheels/', {
            params,
            paramsSerializer,
            cancelToken,
        });
        return response.data;
    },
});