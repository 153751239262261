<template>
    <button
        type="button"
        class="close-btn"
        :class="{
            'close-btn_shadow': shadow,
        }"
        :style="sizeStyles"
        :aria-label="ariaLabel"
        @click="$emit('click')"
    >
        <UIcon
            name="cross"
            big
            class="close-btn__icon"
        ></UIcon>
    </button>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'CloseBtn',

    components: { UIcon },

    props: {
        shadow: {
            type: Boolean,
            default: true,
        },

        size: {
            type: [Number, String],
            default: 36,
        },

        ariaLabel: {
            type: String,
            default: '',
        },
    },

    computed: {
        sizeStyles() {
            return {
                width: this.size + 'px',
                height: this.size + 'px',
            };
        },
    },
};
</script>

<style scoped>
.close-btn {
    color: var(--font-secondary-color);
    font-size: 0;
}

.close-btn:hover,
.close-btn:focus {
    color: var(--dark-c);
}

.close-btn_shadow {
    background-color: var(--light-c);
    border-radius: 50%;
    box-shadow: var(--base-shadow);
    transform: scale(1);
    transition: all var(--transition-fastest);
}

.close-btn_shadow:hover,
.close-btn_shadow:focus {
    box-shadow: var(--hover-base-shadow);
    transform: scale(1.2);
}

.close-btn__icon {
    fill: currentColor;
}
</style>