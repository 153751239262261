<template>
    <div
        ref="parent"
        class="transition-height-parent"
    >
        <transition-group
            name="transition-height"
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @leave="leave"
            @after-leave="afterLeave"
        >
            <slot></slot>
        </transition-group>
    </div>
</template>

<script>
const animationDurationFast = 200;

export default {
    name: 'TransitionHeightList',

    methods: {
        beforeEnter(el) {
            el.style.transition = `opacity ${ animationDurationFast }ms ease-in-out`;
            el.style.position = 'absolute';
            el.style.width = '100%';
            el.style.opacity = '0';

            this.$refs.parent.style.overflow = 'hidden';
            this.$refs.parent.style.height = this.$refs.parent.clientHeight + 'px';
        },
        beforeLeave(el) {
            this.$refs.parent.style.overflow = 'hidden';
            this.$refs.parent.style.height = this.$refs.parent.clientHeight + 'px';

            el.style.transition = `opacity ${ animationDurationFast }ms ease-in-out`;
            el.style.position = 'absolute';
            el.style.width = '100%';
            el.style.opacity = '1';

        },

        leave(el) {
            this.$refs.parent.style.height = (this.$refs.parent.clientHeight - el.clientHeight) + 'px';
            el.style.opacity = '0';
        },
        enter(el) {
            this.$refs.parent.style.height = (this.$refs.parent.clientHeight + el.clientHeight) + 'px';
            el.style.opacity = '1';
        },

        afterLeave(el) {
            el.style.position = null;
            el.style.width = null;
            el.style.opacity = null;
            el.style.transition = null;

            this.$refs.parent.style.overflow = null;
            this.$refs.parent.style.height = null;
        },
        afterEnter(el) {
            el.style.position = null;
            el.style.width = null;
            el.style.opacity = null;
            el.style.transition = null;

            this.$refs.parent.style.overflow = null;
            this.$refs.parent.style.height = null;
        },
    },
};
</script>

<style scoped>
.transition-height-parent {
    position: relative;
    transition: height 200ms ease-in-out;
}
</style>