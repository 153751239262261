<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <FormManager
            v-show="!showPhoneConfirm"
            ref="phoneFormData"
            v-model="phoneFormData"
            class="popup-content"
            @submit="submitPhone"
        >
            <h2 class="h2 h2_text">
                Номер телефона
            </h2>

            <p
                v-if="profile.phone && isPhonesEquals"
                class="form-subtitle"
            >
                Телефон подтверждён, вы можете изменить его в&nbsp;любой момент, при этом все данные будут перенесены на новый номер.
            </p>

            <FormField
                type="phone"
                name="phone"
                label="Телефон"
                visibleMask
                offModifyLabel
                validations="required"
                class="form-field-gap_top"
            ></FormField>

            <div class="footer actions-wrap">
                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="close"
                >
                    Отмена
                </ButtonBlock>
                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    :disabled="phoneFormData.phone === profile.phone"
                    class="action-btn"
                >
                    Подтвердить
                </ButtonBlock>
            </div>
        </FormManager>

        <FormManager
            v-show="showPhoneConfirm"
            ref="codeFormData"
            v-model="codeFormData"
            class="popup-content"
            @submit="checkSmsCodeLength"
        >
            <div class="flex align-items-center h2_text">
                <RoundArrowBtn
                    direction="left"
                    class="back-btn"
                    @click="backToForm"
                ></RoundArrowBtn>
                <h2 class="h2">
                    {{ phoneFormData.phone }}
                </h2>
            </div>

            <p class="form-subtitle">
                Мы отправили SMS с кодом подтверждения.
            </p>

            <FormField
                type="string"
                name="sms_code"
                label="Код из SMS"
                offModifyLabel
                validations="required"
                mask="0000"
                class="form-field-gap_top"
                @change="checkSmsCodeLength"
            ></FormField>

            <div class="footer">
                <p v-if="smsTimer" class="action-btn_link action-btn_center">
                    Отправить ещё раз через <span class="timer-seconds">{{ secondsToShow }} сек</span>
                </p>
                <ButtonText
                    v-else
                    dashed
                    secondary
                    dark
                    spinnerLeft
                    class="action-btn_link action-btn_center"
                    @click="submitPhone"
                >
                    Отправить код повторно
                </ButtonText>

                <div class="actions-wrap">
                    <ButtonBlock
                        submit
                        primary
                        class="action-btn"
                        :loading="inProcess"
                    >
                        Проверить код
                    </ButtonBlock>
                </div>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import RoundArrowBtn from "@/components/project-buttons/RoundArrowBtn.vue";
import handleFormErrorMixin from "@/mixins/handleFormErrorMixin.js";
import FormManager from "@/components/_form/FormManager.vue";
import FormField from "@/components/_form/FormField.vue";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import { mapState } from 'vuex';
import ButtonText from '@/components/_buttons/ButtonText.vue';

export default {
    name: "ChangePhonePopup",

    components: {
        ButtonText,
        ButtonBlock,
        FormField,
        FormManager,
        RoundArrowBtn,
        PopupWrap,
    },

    mixins: [popup, handleFormErrorMixin],

    data() {
        return {
            phoneFormData: {
                phone: '',
            },

            codeFormData: {
                sms_code: '',
            },

            loading: false,
            showPhoneConfirm: false,

            smsTimer: null,
            defaultSeconds: 60,
            secondsToShow: null,
            inProcess: false,
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),

        isPhonesEquals() {
            return this.profile.phone ===  this.phoneFormData.phone;
        },
    },

    watch: {
        'codeFormData.sms_code'() {
            this.checkSmsCodeLength();
        },
    },

    created() {
        this.phoneFormData.phone = this.profile.phone || '';
    },

    methods: {
        async submitPhone() {
            const errors = await this.$refs.phoneFormData.validate();
            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.loading = true;

                this.$api.users.changePhone(this.phoneFormData)
                    .then(() => {
                        this.showPhoneConfirm = true;

                        clearInterval(this.smsTimer);

                        this.secondsToShow = this.defaultSeconds;
                        this.smsTimer = setInterval(() => {
                            this.secondsToShow--;
                            if (this.secondsToShow === 0) {
                                clearInterval(this.smsTimer);
                                this.smsTimer = null;
                            }
                        }, 1000);
                    })
                    .catch(error => {
                        this.$refs.phoneFormData.handle(error);
                        this.$store.commit('handleCommonHttpError', error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },

        backToForm() {
            this.showPhoneConfirm = false;
            clearInterval(this.smsTimer);
            this.smsTimer = null;
            this.codeFormData.sms_code = '';
        },

        checkSmsCodeLength() {
            if (!this.inProcess && this.codeFormData.sms_code.length === 4) {
                this.confirmation();
            }
        },

        async confirmation() {
            const errors = await this.$refs.codeFormData.validate();
            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.inProcess = true;

                const data = Object.assign({}, this.codeFormData, this.phoneFormData);

                this.$api.users.changePhoneConfirm(data)
                    .then(response => {
                        this.$success('Телефон изменен, данные учетной записи перенесены на новый номер');
                        this.$store.commit('profile/setProfile', response);
                        this.close();
                    })
                    .catch(error => {
                        this.$refs.codeFormData.handle(error);
                        this.$store.commit('handleCommonHttpError', error);
                    })
                    .finally(() => {
                        this.inProcess = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 476px;
    }
}

.form-subtitle {
    line-height: var(--base-lh);
}

.footer {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn_link {
        display: block;
        margin-top: -16px;
        margin-bottom: 36px;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .footer {
        margin-top: auto;
        text-align: center;
    }

    .action-btn_link {
        margin-bottom: 16px;
    }

    .actions-wrap {
        margin-top: auto;
    }

    .action-btn {
        width: 100%;
    }

    .action-btn_center {
        display: block;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

.back-btn {
    margin-right: var(--gap-y-mini);
}

.timer-seconds {
    display: inline-block;
    width: 50px;
    text-align: left;
}
</style>