import Vuex from 'vuex';
import { getErrorCode, HttpError } from '../lib/errors.js';
import isNode from '../lib/isNode.js';
import * as Sentry from '@sentry/vue';
import bus from '@/bus.js';

import fromRoute from './modules/fromRoute.js';
import reloadPageService from '@/store/modules/reloadPageService.js';
import staticPages from './modules/staticPages.js';
import cities from '@/store/modules/cities.js';
import settings from './modules/settings.js';
import profile from './modules/profile.js';
import market from './modules/market.js';
import cart from './modules/cart.js';
import profileCars from './modules/profileCars.js';
import profileOrders from './modules/profileOrders.js';
import chat from '@/store/modules/chat';
import promotions from './modules/promotions.js';
import notifications from './modules/notifications.js';
import viewsHistory from './modules/viewsHistory.js';
import searchWidget from './modules/searchWidget.js';
import searchHistory from './modules/searchHistory.js';


export function createStore(context) {
    const store = new Vuex.Store({
        modules: {
            fromRoute,
            reloadPageService,
            staticPages,
            cities,
            settings: settings(context),
            profile,
            market,
            cart,
            profileCars,
            profileOrders,
            chat,
            promotions,
            notifications,
            viewsHistory,
            searchWidget,
            searchHistory,
        },

        state: () => ({
            has500Error: false,
            httpErrorCode: null,
            sentryErrorHash: null,
            appInitialized: false,
            appInitializing: false,
        }),

        mutations: {
            setHttpErrorCode(state, code) {
                state.httpErrorCode = code;
            },

            clearHttpError(state) {
                state.httpErrorCode = null;
                state.sentryErrorHash = null;
            },

            set500Error(state) {
                state.has500Error = true;
            },

            clear500Error(state) {
                state.has500Error = false;
            },

            // TODO: удалить
            handleApiError(state, error) {
                const status = getErrorCode(error);

                // 401 redirect / node: throw error
                // 403 httpErrorCode
                // 404 httpErrorCode
                // 429 httpErrorCode
                // 500 httpErrorCode
                // TODO: add 500 static html to node
                // 503 redirect / node: throw error
                // 504 httpErrorCode
                // TODO: add 504 static html to node
                // 5xx httpErrorCode
                // TODO: add 5xx static html to node
                if (status === 401 || status === 403 || status === 404) {
                    state.has404Error = true;
                }
                else if (status === 500) {
                    state.has500Error = true;
                }
                else if (status === 503) {
                    if (isNode) {
                        const { url } = context.req;

                        if (url !== '/maintenance-mode') {
                            throw error;
                        }
                    }
                    else if (state.route.name !== 'maintenance-mode') {
                        window.location = 'maintenance-mode';
                    }
                }
                else {
                    console.error(error);
                }
            },

            handleInitError(state, error) {
                const code = getErrorCode(error);

                if (!code) throw error;

                // 400 ???
                // 401 redirect / node: throw error
                // 403 httpErrorCode
                // 404 httpErrorCode
                // 429 httpErrorCode
                // 5xx httpErrorCode
                // TODO: add 5xx static html to node
                // 500 httpErrorCode
                // TODO: add 500 static html to node
                // 503 redirect / node: throw error
                // 504 httpErrorCode
                if (code === 401) {
                    if (isNode) {
                        console.log('Handled error 401');
                        throw HttpError.normalizeError(error, code, '/auth/signin');
                    }
                    else if (state.route.name !== 'signin') {
                        // window.location = '/auth/signin';
                        // router.push({ name: 'signin' });
                        state.httpErrorCode = code;
                    }
                }
                else if (code === 503) {
                    if (isNode) {
                        const { url } = context.req;

                        if (url !== '/maintenance-mode') {
                            throw error;
                        }
                    }
                    else if (state.route.name !== 'maintenance-mode') {
                        window.location = 'maintenance-mode';
                    }
                }
                else if (
                    code === 403 ||
                    code === 404 ||
                    code === 429 ||
                    code === 500 ||
                    code === 502 ||
                    code === 504
                ) {
                    Sentry.captureException(error);

                    state.httpErrorCode = code;
                    state.sentryErrorHash = error.response.data.hash;
                }
                else {
                    throw error;
                }
            },

            handleCommonHttpError(state, error) {
                if (error.response) {
                    const code = getErrorCode(error);

                    // 500 notify
                    // 503 redirect
                    // 504 notify
                    // 5xx notify
                    // 400 notify + validate
                    // 401 redirect with next
                    // 403 notify
                    // 404 notify
                    const data = error.response.data;

                    if (code === 400) {
                        const { non_field_errors } = data;

                        if (non_field_errors) {
                            bus.$emit('notify', { type: 'error', message: non_field_errors[0].message });
                        }
                        else {
                            bus.$emit('notify', { type: 'error', message: 'Одно или несколько полей содержат ошибки' });
                        }
                    }
                    else if (code === 404) {
                        bus.$emit('notify', { type: 'error', message: 'Запрашиваемый адрес не существует.' });
                        // this.$error('Запрашиваемый адрес не существует.');
                    }
                    else if (code === 500) {
                        let message = 'Внутренняя ошибка сервера, попробуйте повторить попытку позже.';

                        if (data.hash) {
                            message += ' Код ошибки: ' + data.hash;
                        }

                        bus.$emit('notify', { type: 'error', message });
                    }
                    else if (code === 503) {
                        if (isNode) {
                            const { url } = context.req;

                            if (url !== '/maintenance-mode') {
                                throw error;
                            }
                        }
                        else if (state.route.name !== 'maintenance-mode') {
                            window.location = 'maintenance-mode';
                        }
                    }

                    // TODO: добавить модуль для уведомлений-шторок и в него складывать код ошибки и/или формулировку
                    // this.commit('notifications/setError', error, { root: true });

                    if (process.env.VUE_ENV === 'client' && process.env.NODE_ENV === 'development') {
                        console.log(error);
                    }
                }
                else {
                    throw error;
                }
            },

            setAppInitialized(state) {
                state.appInitialized = true;
            },

            setAppInitializing(state, value) {
                state.appInitializing = value;
            },
        },

        actions: {
            async appInit({ commit, dispatch, state }) {
                commit('setAppInitializing', true);

                await Promise.all([
                    dispatch('cities/prefetch'),
                    dispatch('settings/get'),
                    dispatch('profile/get'),
                    dispatch('cart/getCart'),
                ]);

                if (state.profile.isAuthorized) {
                    await dispatch('profileOrders/fetchActiveOrdersCount');
                }

                commit('setAppInitialized');
                commit('setAppInitializing', false);
            },
        },
    });

    store.$api = context.api;
    store.$http = context.HTTP;

    if (process.env.VUE_ENV === 'client') {
        require('vuex-persistedstate').default({
            key: 'ubibi_vuex',
            paths: ['reloadPageService/value'],
        })(store);

        const shareMutations = require('vuex-shared-mutations');
        shareMutations.default({
            predicate: ['reloadPageService/checkValue'],
        })(store);
    }

    return store;
}