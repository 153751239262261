import axios from 'axios';
import get1 from '@/lib/get1.js';
import get2 from '@/lib/get2.js';
import { paramsSerializer } from '@/lib/paramsSerializer.js';

export let HTTP = {};

const createHeaders = context => {
    if (process.env.VUE_ENV === 'server') {
        const { req } = context;
        const { cookies, headers, ip } = req;
        const Referer = req.get('Referrer') || 'https://ubibi.ru/';

        const obj = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'User-Agent': headers['user-agent'],
            Cookie: Object.entries(cookies).map(([key, value]) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(value);
            }).join('; '),
        };

        if (headers['X-Real-IP'] || headers['x-real-ip']) {
            obj['X-Real-IP'] = headers['X-Real-IP'] || headers['x-real-ip'];
        }

        if (headers['X-Forwarded-For'] || headers['x-forwarded-for'] || ip) {
            obj['X-Forwarded-For'] = headers['X-Forwarded-For'] || headers['x-forwarded-for'] || ip;
        }

        if (cookies.csrftoken) {
            obj['X-CSRFTOKEN'] = cookies.csrftoken;
        }

        if (Referer) {
            obj.Referer = Referer;
        }

        return obj;
    }

    if (process.env.VUE_ENV === 'client') {
        return {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
    }
};

export const initHttpService = context => {
    const { req } = context;
    const headers = createHeaders(context);

    const axiosInstance = axios.create({
        baseURL: process.env.API_URL,
        timeout: 120000,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFTOKEN',
        withCredentials: true,
        headers,
        paramsSerializer,
    });

    axiosInstance.CancelToken = axios.CancelToken;
    axiosInstance.isCancel = axios.isCancel;

    const request = async options => {
        options.headers = { ...headers, ...(options.headers || {}) };
        const timestamp = new Date().getTime();
        const userAgent = req ? req.get('User-Agent') : window.navigator.userAgent;
        const string1 = get1(userAgent);
        const string2 = get2(options, timestamp);

        options.headers.Client = (string1 + string2).toUpperCase();
        options.headers['Request-Time'] = timestamp;

        return await axiosInstance(options);
    };

    axiosInstance.get = async (url, config = {}) => {
        return await request({
            method: 'get',
            url,
            ...config,
        });
    };

    axiosInstance.post = async (url, data, config = {}) => {
        return await request({
            method: 'post',
            url,
            data,
            ...config,
        });
    };

    axiosInstance.patch = async (url, data, config = {}) => {
        return await request({
            method: 'patch',
            url,
            data,
            ...config,
        });
    };

    axiosInstance.put = async (url, data, config = {}) => {
        return await request({
            method: 'put',
            url,
            data,
            ...config,
        });
    };

    axiosInstance.delete = async (url, config = {}) => {
        return await request({
            method: 'delete',
            url,
            ...config,
        });
    };

    HTTP = axiosInstance;

    return axiosInstance;
};