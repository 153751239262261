<template>
    <ol class="step-bar">
        <li
            v-for="item in normalizedItems"
            :key="'step-bar__item-' + item.step"
            class="step-bar__item"
            :class="{
                'step-bar__item_active': step >= item.step,
            }"
        >
            <component
                :is="item.tag"
                v-bind="item.options"
                class="step-bar__label-wrap"
                :class="{
                    'step-bar__label-wrap_error': item.error,
                    'step-bar__label-wrap_active': step >= item.step,
                    'step-bar__label-wrap_current': step === item.step,
                }"
            >
                <span class="step-bar__count">{{ item.step }}</span><span class="step-bar__label">. {{ item.label }}</span>
            </component>
            <UIcon
                name="arrow-right"
                small
                class="step-bar__arrow"
                :class="{
                    'step-bar__arrow_active': step > item.step,
                }"
            ></UIcon>
        </li>
    </ol>
</template>

<script>
import ButtonText from '@/components/_buttons/ButtonText.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'StepBar',

    components: {
        UIcon,
        ButtonText,
    },

    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },

        step: {
            type: Number,
            default: 1,
        },
    },

    computed: {
        normalizedItems() {
            return this.items.map((item, index) => {
                const step = index + 1;
                const isPrev = step < this.step;
                const tag = item.to && isPrev ? 'ButtonText' : 'span';
                const label = typeof item === 'string' ? item : item.label;
                const error = item.error;

                const normalizedItem = {
                    tag,
                    label,
                    step,
                    error,
                };

                if (item.to && isPrev) {
                    normalizedItem.options = {
                        to: item.to,
                        black: true,
                    };
                }

                return normalizedItem;
            });
        },
    },
};
</script>

<style scoped>
.step-bar {
    display: flex;
    list-style-position: inside;
}

.step-bar__item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-size: 12px;
    line-height: var(--mini-lh);
    font-family: var(--f-bold);
    color: var(--font-secondary-color);
    text-transform: uppercase;
    list-style-type: decimal;
    transition: color var(--transition);
}
@media (max-width: 767px) {
    .step-bar__item:not(:last-child)::after {
        content: "";
        display: block;
        flex-shrink: 0;
        width: 20px;
        height: 2px;
        background-image: linear-gradient(to right, var(--border-dark-c) 60%, rgba(255,255,255,0) 0%);
        background-size: 6px 2px;
        background-position: 5px 0;
        background-repeat: repeat-x;
        margin-left: 8px;
        margin-right: 8px;
    }
}

.step-bar__item_active {
    color: var(--dark-c)
}

.step-bar__label-wrap {
    flex-shrink: 0;
    letter-spacing: 0.06em;
}
.step-bar__label-wrap.step-bar__label-wrap_error {
    color: var(--primary-color);
}

.step-bar__arrow {
    flex-shrink: 0;
    margin-left: 12px;
    margin-right: 16px;
    fill: var(--font-secondary-color);
    vertical-align: -3px;
    transition: fill var(--transition);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .step-bar__arrow {
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media (max-width: 767px) {
    .step-bar__arrow {
        display: none;
    }
}
.step-bar__arrow_active {
    fill: var(--dark-c)
}

.step-bar__item:last-child .step-bar__arrow {
    opacity: 0;
}

@media (max-width: 767px) {
    .step-bar__label-wrap {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--light-bg);
        border: 1px solid var(--light-bg);
        transition: color var(--transition), background-color var(--transition), border-color var(--transition);
    }

    .step-bar__label-wrap_active {
        background-color: var(--accent-green-light);
        border-color: var(--accent-green-light);
        color: var(--accent-green);
    }

    .step-bar__label-wrap_current {
        background-color: var(--accent-green-light);
        border-color: var(--accent-green);
        color: var(--accent-green);
    }

    .step-bar__label {
        display: none;
    }
}
</style>