<template>
    <div class="u-input__wrap">
        <input
            :id="id"
            ref="input"
            :name="name || id"
            :type="type"
            :value="innerValue"
            :disabled="disabled"
            :autocomplete="autocomplete"
            :placeholder="innerPlaceholder"
            class="u-input__el"
            v-on="{
                ...listeners,
                ...handlers,
            }"
        >

        <span
            v-if="visibleMask && (isFocused || innerValue)"
            class="u-input__placeholder"
        ><i class="u-input__placeholder-visible-value">{{ innerValue }}</i>{{ restMask }}</span>
        <span
            v-if="placeholder != null && innerValue === ''"
            class="u-input__placeholder"
            @click="focus"
        >{{ placeholder }}</span>
    </div>
</template>

<script>
import mask, { tokens } from '@ui/utils/mask.js';
import genId from '@ui/utils/genId.js';


export default {
    name: 'UInput',

    model: {
        event: 'change:model',
        prop: 'value',
    },

    props: {
        // attrs
        id: {
            type: String,
            default: () => genId(),
        },
        name: String,
        type: String,
        value: [String, Number, Object],
        label: String,
        placeholder: String,
        autocomplete: String,

        // state
        disabled: Boolean,
        invalid: Boolean,
        loading: Boolean,

        // mods
        upper: Boolean,
        mask: String,
        replace: Function,
        trim: {
            type: Boolean,
            default: true,
        },

        // behaviour
        clearInputOnBlur: Boolean,
        clearInputOnFocus: Boolean,

        // presets
        integer: Boolean,
        decimal: Boolean,

        // other
        visibleMask: Boolean,
    },

    data() {
        return {
            startValue: '',
            innerValue: '',

            isFocused: false,
            isActive: false,

            touched: false,
            innerPlaceholder: '',
        };
    },

    computed: {
        listeners() {
            const {
                // eslint-disable-next-line no-unused-vars
                input,
                // eslint-disable-next-line no-unused-vars
                change,
                // eslint-disable-next-line no-unused-vars
                blur,
                // eslint-disable-next-line no-unused-vars
                focus,
                ...listeners
            } = this.$listeners;

            return listeners;
        },

        handlers() {
            return {
                input: this.onInput,
                focus: this.onFocus,
                blur: this.onBlur,
            };
        },

        hasMods() {
            return this.trim
                || this.upper
                || !!this.mask
                || this.integer
                || !!this.replace;
        },

        maskStartValue() {
            if (!this.mask) return '';

            let index;

            for (let i = 0; i < this.mask.length; i++) {
                const symbol = this.mask[i];

                if (tokens[symbol]) {
                    index = i;
                    break;
                }
            }

            return this.mask.substr(0, index);
        },

        outputValue() {
            if (this.integer) {
                const value = this.innerValue.replace(/(?!^)[^\d*]/g, '');

                if (value === '') return null;

                const number = Number(value);

                if (!isNaN(number)) {
                    return number;
                }
            }

            return this.innerValue;
        },

        restMask() {
            if (this.mask && this.visibleMask) {
                return this.mask.substring(this.innerValue.length);
            }

            return '';
        },
    },

    watch: {
        value: {
            handler(value) {
                let newValue = value == null ? '' : value.toString();

                if (value) {
                    newValue = this.modify(newValue);
                }

                if (newValue !== this.innerValue) {
                    this.innerValue = newValue;
                    this.emitInput();
                }
            },

            immediate: true,
        },

        mask: {
            handler(m) {
                if (m) {
                    const newValue = mask(this.innerValue, m);

                    if (this.innerValue !== newValue) {
                        this.innerValue = newValue;
                        this.emitInput();
                    }
                }
            },
        },
    },

    methods: {
        modify(value) {
            // может, вынести модификаторы выше?

            if (this.upper) {
                value = value.toUpperCase();
            }

            if (this.trim) {
                value = value.trim();
            }

            if (this.mask) {
                value = mask(value, this.mask);
            }

            if (this.integer) {
                const number = Number(value.replace(/(?!^)[^\d*]/g, ''));

                if (!isNaN(number)) {
                    value = number.toLocaleString('ru-RU');
                }
            }

            return value;
        },

        // public

        focus() {
            this.$refs.input.focus();
        },

        blur() {
            this.$refs.input.blur();
        },

        // handlers

        onInput($event) {
            const el = $event.target;
            let value = el.value;

            if (this.hasMods && value) {
                // может, каретку перенести в watch?
                const caretPosition = el.selectionStart;

                value = this.modify(value);

                const newCaretPosition = caretPosition + value.length - el.value.length;

                el.value = value;

                el.selectionStart = newCaretPosition;
                el.selectionEnd = newCaretPosition;
            }

            this.innerValue = value;
            this.touched = true;
            this.innerPlaceholder = '';

            this.emitInput();
        },

        async onFocus($event) {
            this.isFocused = true;
            const el = this.$refs.input;
            this.startValue = el.value;

            if (this.clearInputOnFocus && this.innerValue) {
                this.innerPlaceholder = this.innerValue;
                this.innerValue = '';
            }
            else if (this.maskStartValue && !this.value) {
                const value = this.maskStartValue;

                const newCaretPosition = value.length;

                this.innerValue = value;
                el.value = value;

                el.selectionStart = newCaretPosition;
                el.selectionEnd = newCaretPosition;

                this.emitInput();
            }

            this.$emit('focus', $event);
        },

        onBlur($event) {
            this.isFocused = false;
            const el = $event.target;
            let value = el.value;

            if (this.clearInputOnBlur) {
                this.innerValue = '';
                this.emitInput();
                this.emitChange();
            }
            else if (this.clearInputOnFocus && !this.touched && this.value) {
                this.innerValue = this.value.toString();
                this.innerPlaceholder = '';
            }
            else {
                if (this.hasMods && value) {
                    value = this.modify(value);
                }

                if (this.maskStartValue === value) {
                    value = '';
                }

                if (el.value !== value) {
                    el.value = value;
                    this.innerValue = value;
                    this.emitInput();
                }

                if (value !== this.startValue) {
                    this.emitChange();
                }
            }

            this.startValue = '';
            this.touched = false;

            this.$emit('blur', $event);
        },

        // public

        clear() {
            this.innerValue = '';
            this.innerPlaceholder = '';
            this.touched = true;
            this.$refs.input.focus();
            this.emitInput();
            this.emitChange();
            this.emitClear();
        },

        // emits

        emitChange() {
            const value = this.outputValue;
            const target = this.$refs.input;
            this.$emit('change', { value, target });
        },

        emitInput() {
            const value = this.outputValue;
            const target = this.$refs.input;
            this.$emit('input', { value, target });
            this.$emit('change:model', value);
        },

        emitClear() {
            const target = this;
            this.$emit('clear', { target });
        },
    },
};
</script>

<style>
.u-input__wrap {
    position: relative;
    display: flex;
}

.u-input__el {
    flex-grow: 1;
    font-size: inherit;
    line-height: inherit;
    border: none;
}
.u-input__el:disabled {
    cursor: default;
    background-color: transparent;
}

.u-input__el::placeholder {
    font-size: inherit;
    line-height: inherit;
    color: var(--font-secondary-light-color);
}

.u-input__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: var(--font-secondary-color);
    cursor: text;
    transition: color var(--transition);
}
.u-input__el:focus + .u-input__placeholder {
    color: var(--font-secondary-light-color);
}
.u-input__el:disabled + .u-input__placeholder {
    cursor: default;
}

.u-input__placeholder-visible-value {
    color: transparent;
}
</style>