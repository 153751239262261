export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        orders: {
            items: [],
            count: 0,
            offersAmount: 0,
            offersPrice: 0,
            totalPrice: 0,
        },
        yandexDelivery: {},
        worldDelivery: {},
        paymentType: '',
        paymentPrice: '',
        bank_payment_url: '',
    }),

    mutations: {
        setPaymentType(state, type) {
            state.paymentType = type;
        },

        setPaymentUrl(state, url) {
            state.bank_payment_url = url;
        },

        setOrders(state, orders) {
            state.orders.items = orders.orders;
            state.orders.count = orders.orders.length;
            state.orders.offersAmount = orders.offers_amount;
            state.orders.offersPrice = orders.offers_price;
            state.orders.totalPrice = orders.total_price;
        },

        setYandexClaims(state, data) {
            state.yandexDelivery = data;
            state.paymentPrice = data.price_payment;
        },

        setWorldDeliveryClaim(state, data) {
            state.worldDelivery = {
                orderNumber: data.order.number,
                service: data.service,
                approximate_delivery_date: data.approximate_delivery_date,
                address_to: data.address_to,
                office_to: data.office_to,
                city_to: data.city_to,
                user_comment: data.user_comment,
            };
            state.paymentPrice = data.price_payment;
        },

        setInitialized(state, value) {
            state.initialized = value;
        },

        destroy(state) {
            state.initialized = false;

            state.orders = {
                items: [],
                count: 0,
                offersAmount: 0,
                offersPrice: 0,
                totalPrice: 0,
            };
            state.yandexDelivery = {};
            state.worldDelivery = {};

            state.paymentType = '';
            state.paymentPrice = '';
            state.bank_payment_url = '';
        },
    },

    actions: {
        async beforePaymentInit({ commit, dispatch }, { params }) {
            if (params.uuid) {
                try {
                    const [payment] = await Promise.all(
                        [
                            this.$api.payments.paymentItems({ payment_uuid: params.uuid }),
                            dispatch('profileOrders/fetchActiveOrdersCount', null, { root: true }),
                        ],
                    );

                    const { orders, yandex_claim, delivery_claim, bank_payment_url } = payment;

                    if (orders && orders.orders && orders.orders.length) {
                        commit('setOrders', orders);
                        commit('setPaymentType', 'order');
                    }
                    else if (yandex_claim) {
                        commit('setYandexClaims', yandex_claim);
                        commit('setPaymentType', 'yandex-delivery');
                    }
                    else if (delivery_claim) {
                        commit('setWorldDeliveryClaim', delivery_claim);
                        commit('setPaymentType', 'world-delivery');
                    }

                    commit('setPaymentUrl', bank_payment_url);
                    commit('setInitialized', true);
                    commit('clearHttpError', null, { root: true });
                }
                catch (error) {
                    commit('handleInitError', error, { root: true });
                }
            }
            else {
                commit('handleInitError', { status: 404 }, { root: true });
            }
        },

        async afterPaymentInit({ commit }, { query }) {
            if (query.orderId) {
                try {
                    const data = {
                        bank_payment_id: query.orderId,
                    };
                    const payment = await this.$api.payments.paymentItems(data);
                    const { orders, yandex_claim, delivery_claim } = payment;

                    if (orders && orders.orders && orders.orders.length) {
                        commit('setOrders', orders);
                        commit('setPaymentType', 'order');
                    }
                    else if (yandex_claim) {
                        commit('setYandexClaims', yandex_claim);
                        commit('setPaymentType', 'yandex-delivery');
                    }
                    else if (delivery_claim) {
                        commit('setWorldDeliveryClaim', delivery_claim);
                        commit('setPaymentType', 'world-delivery');
                    }

                    commit('clearHttpError', null, { root: true });
                }
                catch (error) {
                    commit('handleInitError', error, { root: true });
                }
            }
            else {
                commit('handleInitError', { status: 404 }, { root: true });
            }
        },
    },
};