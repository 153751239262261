<template>
    <div class="profile__main_inner">
        <USpinner
            v-if="initLoading"
            big
            center
            class="mt-20"
        ></USpinner>

        <template v-else>
            <EmptyBlock
                v-if="ordersCount === 0"
                icon="cart-stroke"
                title="Нет заказов"
                text="В настоящий момент у вас нет активных заказов."
                :hasBtn="false"
            ></EmptyBlock>

            <template v-else>
                <ActiveOrder
                    v-for="order in orders"
                    :key="'order-' + order.uuid"
                    :initialOrder="order"
                    class="order"
                    @cancel="handleCancel"
                    @cancelReturn="handleCancelReturn"
                ></ActiveOrder>
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ActiveOrder from './components/ActiveOrder.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';

export default {
    name: 'ProfileOrdersActive',

    components: {
        USpinner,
        ActiveOrder,
        EmptyBlock,
    },

    metaInfo() {
        return {
            title: 'ЛК / Мои активные заказы',
        };
    },

    data() {
        return {
            initLoading: true,
        };
    },

    computed: {
        ...mapState({
            orders: state => state.profileOrders.activeOrders.items,
            ordersCount: state => state.profileOrders.activeOrders.current_count,
        }),
    },

    mounted() {
        this.$breadcrumbs([]);
        this.init();
    },

    methods: {
        ...mapMutations({
            SET: 'profileOrders/setActiveOrders',
            REMOVE: 'profileOrders/removeActiveOrder',
            DESTROY: 'profileOrders/destroy',
            ADD_ARCHIVE: 'profileOrders/addArchivedOrders',
        }),

        ...mapActions({
            GET: 'profileOrders/getActiveOrders',
            UPDATE_CART: 'cart/getCart',
        }),

        async init() {
            const response = await this.GET();
            if (response) this.SET(response);
            this.initLoading = false;
        },

        handleCancel() {
            this.initLoading = true;
            this.$store.dispatch('profileOrders/init');
            this.init();
            this.UPDATE_CART();
        },

        handleCancelReturn(order) {
            this.REMOVE({ uuid: order.uuid });
            this.ADD_ARCHIVE({ results: [order] });
        },
    },
};
</script>