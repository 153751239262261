<template>
    <UTooltip
        ref="tooltip"
        noDetach
        showOnClick
        color="white"
        maxWidth="380"
        class="point-tooltip-wrap"
    >
        <div class="point-tooltip">
            <img
                v-if="point.service"
                :src="$links.uploads + point.service.logo.thumbnails.delivery_service_logo_big"
                alt=""
                class="point-tooltip__logo"
            >

            <p>
                {{ office.city }}, {{ office.address }}
            </p>

            <p
                v-if="office.current_work"
                class="point-tooltip__time mt-4 mb-16"
                :class="{
                    'point-tooltip__time_open': office.current_work.is_active,
                    'point-tooltip__time_close': !office.current_work.is_active,
                }"
            >
                {{ office.current_work.is_active
                    ? 'Открыто'
                    : 'Закрыто' }} до {{ office.current_work.text }}
            </p>

            <template v-for="phone in office.phones">
                <a
                    v-if="phone.length && phone[0]"
                    :href="`tel:${ phone[0] }${ phone[1] ? ',,' + phone[1] : '' }`"
                    class="point-tooltip__contact"
                >
                    <UIcon
                        name="phone"
                        secondary
                        small
                        class="mr-8"
                    ></UIcon>
                    <span>{{ phone[0] | phone }} <template v-if="phone[1]"> (доб.: {{ phone[1] }})</template></span>
                </a>
            </template>

            <p
                v-if="office.email"
                class="point-tooltip__contact"
            >
                <UIcon
                    name="envelope-fill"
                    secondary
                    small
                    class="mr-8"
                ></UIcon>
                <span>{{ office.email }}</span>
            </p>

            <div class="schedule">
                <div
                    v-for="(item, index) in office.work_times_merged"
                    :key="`day-${ index }`"
                    class="schedule-row"
                >
                    <div class="schedule-td">
                        {{ item.day_name }}
                    </div>
                    <div
                        v-if="item.is_day_off"
                        class="schedule-td text_red"
                    >
                        Выходной
                    </div>
                    <div
                        v-else
                        class="schedule-td"
                    >
                        {{ item.start_time.slice(0,-3) }} – {{ item.end_time.slice(0,-3) }}
                    </div>
                </div>
            </div>

            <PointOnMapPreviewBtn
                :coords="coords"
                :type="point.service.codename"
                class="mt-16"
                @click="openMap"
            ></PointOnMapPreviewBtn>
        </div>
    </UTooltip>
</template>

<script>
import PointOnMapPreviewBtn from '@/components/project-buttons/PointOnMapPreviewBtn.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
const PointOnMapPopup = () => import('@/components/popups/PointOnMapPopup.vue');


export default {
    name: 'PickupPointInfoTooltip',

    components: {
        UTooltip,
        UIcon,
        PointOnMapPreviewBtn,
    },

    props: {
        point: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        office() {
            return this.point.office_to || this.point;
        },

        coords() {
            const coords = this.office.coordinates;
            return coords.reverse();
        },
    },

    methods: {
        openMap() {
            this.$refs.tooltip.hide();
            const props = {
                pickupPoint: this.point,
                coords: this.coords,
            };
            const options = { props };
            this.$popup(PointOnMapPopup, options);
        },
    },
};
</script>

<style scoped>
.point-tooltip-wrap {
    display: inline;
    vertical-align: middle;
}

.point-tooltip {
    width: 340px;
    margin: 12px 8px;
    font-size: var(--base-fz);
    font-family: var(--f-base);
}

.point-tooltip__logo {
    width: 60px;
    height: 40px;
    margin-bottom: 12px;
}

.schedule {
    margin-top: 16px;
}

.schedule-row {
    display: flex;
}

.schedule-row:not(:last-child) {
    margin-bottom: 8px;
}

.schedule-row::after {
    content: "";
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 5px;
    order: 2;
    align-self: flex-end;
    flex-grow: 1;
    border-bottom: 1px dotted var(--border-dark-c);
}

.schedule-td:first-child {
    order: 1;
}

.schedule-td:last-child {
    order: 3;
}

.point-tooltip__contact {
    display: flex;
    align-items: center;
}

.point-tooltip__contact:not(:last-child) {
    margin-bottom: 8px;
}

.point-tooltip__time_open {
    color: var(--accent-green);
}

.point-tooltip__time_close {
    color: var(--primary-color);
}
</style>