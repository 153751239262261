<template>
    <div
        v-if="filteredAttributes.length"
        class="market-item-attributes-group"
    >
        <slot name="preTable"></slot>

        <div>
            <div
                v-for="attribute in filteredAttributes"
                :key="attribute.codename"
                class="row"
            >
                <div
                    class="key"
                    :title="attribute.name"
                >
                    <span class="key__text">
                        {{ attribute.name }}
                    </span>
                    <span
                        v-if="attribute.unit"
                        class="flex-shrink-0"
                    >
                        , {{ attribute.unit }}
                    </span>

                    <UTooltip
                        v-if="attribute.hint && !isHintEmpty(attribute.hint)"
                        noDetach
                        class="key__hint"
                    >
                        <WysiwygView
                            :value="attribute.hint"
                            smallSize
                        ></WysiwygView>
                    </UTooltip>
                </div>

                <div
                    :style="{
                        flexBasis: valueWidth
                    }"
                    class="value"
                >
                    <template
                        v-if="attribute.data_type === 'many'
                            && attribute.is_filter_option
                            && modelName === 'product'"
                    >
                        <template v-for="(attributeValue, attributeValueIndex) in item[attribute.codename]">
                            <ButtonText
                                :key="attributeValueIndex"
                                :to="{
                                    name: 'market-products-category',
                                    params: {
                                        slug: item.category.slug,
                                    },
                                    query: {
                                        [attribute.codename]: attributeValue.id,
                                    },
                                }"
                                target="_blank"
                                underline
                                black
                            >{{ attributeValue.name }}</ButtonText><template v-if="attributeValueIndex < item[attribute.codename].length - 1">
                                ,
                            </template>
                        </template>
                    </template>

                    <ButtonText
                        v-else-if="attribute.is_filter_option && modelName === 'product' && item[attribute.codename].id"
                        target="_blank"
                        :to="{
                            name: 'market-products-category',
                            params: {
                                slug: item.category.slug,
                            },
                            query: {
                                [attribute.codename]: item[attribute.codename].id,
                            },
                        }"
                        underline
                        black
                    >
                        {{ item[attribute.codename].name || item[attribute.codename] }}
                    </ButtonText>

                    <template v-else>
                        <template v-if="attribute.data_type === 'many'">
                            <template v-for="(attributeValue, attributeValueIndex) in item[attribute.codename]">
                                {{ attributeValue.name }}<template
                                    v-if="attributeValueIndex < item[attribute.codename].length - 1"
                                >
                                    ,
                                </template>
                            </template>
                        </template>
                        <template v-else>
                            {{ item[attribute.codename].name || item[attribute.codename] }}
                        </template>
                    </template>
                </div>
            </div>
        </div>

        <slot name="actions"></slot>
    </div>
</template>

<script>
import WysiwygView from './views/WysiwygView.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';


export default {
    name: 'MarketItemAttributesGroup',

    components: { UTooltip, ButtonText, WysiwygView },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        modelName: {
            type: String,
            default: '',
        },

        attributes: {
            type: Array,
            default() {
                return [];
            },
        },

        valueWidth: {
            type: String,
            default: '',
        },
    },

    computed: {
        filteredAttributes() {
            return this.attributes.filter(attribute => {
                if (attribute.data_type !== 'many' && this.item[attribute.codename]) {
                    return this.item[attribute.codename];
                }
                else if (attribute.data_type === 'many' && this.item[attribute.codename].length) {
                    return this.item[attribute.codename];
                }
            });
        },
    },

    methods: {
        isHintEmpty(hint) {
            return hint.toString() === '<p>​</p>';
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .row {
        display: flex;
        align-items: baseline;
    }

    .row:not(:last-child) {
        margin-bottom: 16px;
    }

    .row::after {
        content: "";
        flex-grow: 1;
        flex-shrink: 0;
        order: 2;
        min-width: 16px;
        margin-left: 16px;
        margin-right: 16px;
        border-bottom: 1px dotted var(--border-dark-c);
    }
}
@media (max-width: 767px) {
    .row:not(:last-child) {
        margin-bottom: 12px;
    }
}

.key {
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .key {
        display: flex;
        align-items: baseline;
        order: 1;
        max-width: 50%;
        flex-shrink: 1;
    }

    .key__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.key__hint {
    margin-left: 8px;
    font-size: 0;
    align-self: center;
}

@media (min-width: 768px) and (max-width: 1039px) {
    .value {
        flex-grow: 1;
        max-width: 300px;
    }
}
@media (min-width: 768px) {
    .value {
        order: 3;
        flex-shrink: 0;
    }
}
@media (max-width: 767px) {
    .value {
        margin-top: 4px;
    }
}

@media (min-width: 1040px) {
    .market-item-page__attributes .value {
        width: var(--col-7);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .market-item-page__attributes .value {
        width: var(--col-6);
    }
}
</style>