<template>
    <InputRadioBase
        :id="id"
        v-model="valueComputed"
        :options="options"
        :label="label"
        :optionKey="optionKey"
        :getOptionKey="getOptionKey"
        :optionLabel="optionLabel"
        :getOptionLabel="getOptionLabel"
        :optionComparator="optionComparator"
        :isOptionSelected="isOptionSelected"
        :reduce="reduce"
        wrapClasses="input-radio-borders__wrap"
        :itemClasses="[
            'input-radio-borders__item',
            itemClasses,
            {
                'input-radio-borders__item_checked': valueComputed
            }
        ]"
        inputClasses="input-radio-borders__input"
        labelClasses="input-radio-borders__label"
        v-on="listeners"
    >
        <template #label="{ option, id }">
            <label
                class="input-radio-borders__label"
                :class="labelClasses"
                :for="id"
            >
                <slot
                    name="label"
                    v-bind="{ option, id }"
                >
                    <div class="input-radio-borders__title">{{ getOptionLabel(option) }}</div>
                    <div class="input-radio-borders__description">{{ getOptionDescription(option) }}</div>
                </slot>
            </label>
        </template>
    </InputRadioBase>
</template>

<script>
import inputRadioMixin from '@/components/_inputs/inputRadioMixin.js';


export default {
    name: 'InputRadioBorders',

    mixins: [
        inputRadioMixin,
    ],

    props: {
        descriptionClasses: [String, Array, Object],

        optionDescription: {
            type: String,
            default: 'description',
        },

        getOptionDescription: {
            type: Function,
            default(option) {
                if (option === null) {
                    return '';
                }
                else if (typeof option === 'object') {
                    if (option[this.optionDescription] !== undefined) {
                        return option[this.optionDescription];
                    }

                    if (option.description !== undefined) {
                        return option.description;
                    }
                }
                else {
                    return option;
                }
            },
        },
    },
};
</script>

<style>
.input-radio-borders__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
    margin-right: -20px;
}

.input-radio-borders__label {
    position: relative;
    padding: 16px 16px 16px 48px;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-dark-c);
    margin-bottom: 20px;
    margin-right: 20px;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    font-family: var(--f-semi-bold);
    transition: border-color .1s ease-in, box-shadow .1s ease-in;
}

.input-radio-borders__description {
    color: var(--font-secondary-color);
}

.input-radio-borders__label::before {
    content: "";
    position: absolute;
    top: 16px;
    left: 16px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--border-dark-c);
    background-color: var(--light-c);
    transition: border-color .1s ease-in;
}

.input-radio-borders__input:disabled + .input-radio-borders__label {
    border-color: var(--border-light-c);
    color: var(--grey-color);
    cursor: default;
    pointer-events: none;
}

.input-radio-borders__input:disabled + .input-radio-borders__label::before {
    background-color: var(--bright-bg);
    border-color: var(--border-light-c);
}

.input-radio-borders__input:not(:disabled):not(:checked) + .input-radio-borders__label:hover::before,
.input-radio-borders__input:not(:disabled):not(:checked) + .input-radio-borders__label:hover,
.input-radio-borders__input:checked + .input-radio-borders__label {
    border-color: var(--border-dark-active-c);
}

.input-radio-borders__input:checked + .input-radio-borders__label::before {
    border-width: 6px;
    border-color: var(--primary-color);
}

.input-radio-borders__title {
    font-family: var(--f-bold);
    margin-bottom: 4px;
}
</style>