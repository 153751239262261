<template>
    <UTooltip noDetach>
        <template #trigger="{ attrs, handlers }">
            <button
                ref="deleteFromCart"
                class="cart-btn btn btn_icon"
                :aria-label="tooltipText"
                :disabled="loading"
                v-bind="attrs"
                v-on="handlers"
                @click="deleteFromCart"
            >
                <USpinner
                    v-if="loading"
                    small
                ></USpinner>
                <UIcon
                    v-else
                    name="delete"
                    class="cart-icon"
                ></UIcon>
            </button>
        </template>

        {{ tooltipText }}
    </UTooltip>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import bus from "../../bus.js";
import UIcon from '@ui/components/UIcon/UIcon.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';


export default {
    name: 'DeleteCartBtn',

    components: { USpinner, UTooltip, UIcon },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        id: {
            type: Number,
            default: null,
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },

        tooltipText: {
            type: String,
            default: 'Убрать из корзины',
        },

        customEvent: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        ...mapActions({
            DELETE_FROM_CART: 'cart/deleteFromCart',
        }),

        ...mapMutations({
            SET_CART: 'cart/set',
        }),

        deleteFromCart() {
            if (this.customEvent) {
                this.$emit('delete');
            }
            else {
                this.loading = true;

                const data = [{
                    offer: this.id,
                }];

                this.DELETE_FROM_CART({ data })
                    .then(() => {
                        bus.$emit('deleteFromCart', {
                            offerId: this.item.offer.id,
                            itemId: this.item.offer[this.modelName].id,
                            inCartStatus: false,
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
.cart-btn {
    color: var(--dark-c);
    border: 1px solid var(--border-dark-c);
    transition: color var(--transition);
}

.cart-btn:hover,
.cart-btn:focus {
    color: var(--primary-color);
}

.cart-icon {
    fill: currentColor;
}
</style>