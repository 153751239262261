<template>
    <div class="top-gap_medium">
        <Spinner
            v-if="fetching || initializing"
            size="big"
            center
        ></Spinner>

        <template v-else>
            <h1 class="h1 h1_block">
                {{ category.page_title }}
            </h1>

            <ol
                v-if="category.is_leaf && characteristics.current_count"
                class="grid"
            >
                <li
                    v-for="(characteristic, index) in characteristics.results"
                    :key="'item-' + characteristic.part_name_id + index"
                    class="grid-item layer-1"
                >
                    <RouterLink
                        :to="{
                            name: 'market-part-name',
                            params: {
                                slug: category.slug,
                                id: characteristic.part_name_id,
                            }
                        }"
                        class="grid-item__main-link grid-item__main-link_full h3 link-primary-hover"
                    >
                        {{ characteristic.part_name }}
                    </RouterLink>
                </li>
            </ol>
            <ol
                v-else-if="!category.is_leaf && categoryChildren.current_count"
                class="grid"
            >
                <li
                    v-for="childCategory in categoryChildren.results"
                    :key="childCategory.id"
                    class="grid-item layer-1"
                >
                    <div class="grid-item__cover-wrap">
                        <img
                            v-if="childCategory.cover"
                            :src="$links.uploads + childCategory.cover.thumbnails.category_cover_list"
                            alt=""
                            class="grid-item__cover"
                        >
                        <img
                            v-else
                            src="../../../../assets/images/cover-placeholder.svg"
                            alt=""
                            class="grid-item__cover"
                        >
                    </div>

                    <div class="grid-item__name-wrap">
                        <h2 class="h3">
                            <RouterLink
                                v-if="childCategory.children.length"
                                :to="{
                                    name: 'market-parts-catalog-category',
                                    params: {
                                        slug: childCategory.slug,
                                    }
                                }"
                                class="grid-item__main-link link-primary-hover"
                            >
                                {{ childCategory.name }}
                            </RouterLink>

                            <button
                                v-else
                                type="button"
                                class="grid-item__main-link link-primary-hover"
                                @click="handleToCategoryClick(childCategory)"
                            >
                                {{ childCategory.name }}
                            </button>
                        </h2>

                        <ul
                            v-if="childCategory.children.length"
                            class="children-list"
                        >
                            <li
                                v-for="child in childCategory.children.slice(0, 3)"
                                :key="'child-' + child.id"
                                class="children-list__item"
                            ><button
                                type="button"
                                class="children-list__link link-primary-hover"
                                @click="handleToCategoryClick(child)"
                            >{{ child.name }}</button></li>
                            <li
                                v-if="childCategory.children.length > 3"
                                class="children-list__item children-list__item_more"
                            >
                                <RouterLink
                                    :to="{
                                        name: 'market-parts-catalog-category',
                                        params: {
                                            slug: childCategory.slug,
                                        }
                                    }"
                                    class="link-primary-hover"
                                >
                                    ... и ещё +{{ childCategory.children.length - 3 }}
                                </RouterLink>
                            </li>
                        </ul>
                    </div>
                </li>
            </ol>
            <EmptyBlock
                v-else
                icon="parts"
                title="Пустая категория"
                text="Попробуйте изменить условия поиска."
                :hasBtn="false"
            >
                <template #text>
                    В категории <b>«{{ category.page_title }}»</b> нет запчастей.
                </template>
            </EmptyBlock>

            <LastSee></LastSee>

            <SeoBlock>
                <template
                    v-if="category.seo_description"
                    #seo
                >{{ category.seo_description }}</template>
            </SeoBlock>
        </template>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { safeRouterPush } from '@/router/router.js';
import marketPartsCatalogCategory from '@/store/modules/marketPartsCatalogCategory.js';
import Spinner from '@/components/Spinner.vue';
import SeoBlock from '@/components/SeoBlock.vue';
import LastSee from '@/components/LastSee.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
const SelectCarPopup = () => import('@/components/popups/SelectCarPopup.vue');

export default {
    name: 'PartsCatalogCategoryPage',

    components: {
        EmptyBlock,
        LastSee,
        SeoBlock,
        Spinner,
    },

    metaInfo() {
        return {
            title: this.category.meta_title || this.category.name,
            meta: [
                { name: 'description', content: this.category.meta_description },
                { name: 'keywords', content: this.category.meta_keywords },
                { property: 'og:title', content: this.category.meta_title ? this.category.meta_title : this.category.name },
                { property: 'og:description', content: this.category.meta_description },
                { property: 'og:image', content: this.category.og_image ? this.$links.uploads + this.category.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        return this.serverPrefetch(this.$route.params.slug);
    },

    data() {
        return {
        };
    },

    computed: {
        ...mapState({
            car: state => state.searchWidget.car,
            fetching: state => state.marketPartsCatalogCategory.fetching,
            initialized: state => state.marketPartsCatalogCategory.initialized,
            initializing: state => state.marketPartsCatalogCategory.initializing,
            category: state => state.marketPartsCatalogCategory.category,
            categoryChildren: state => state.marketPartsCatalogCategory.categoryChildren,
            characteristics: state => state.marketPartsCatalogCategory.characteristics,
            selectedCar: state => state.searchWidget.car,
        }),

        partNameDocFetchParams() {
            return {
                car_id: (this.car || {}).id,
                category_id: this.category.id,
                order_by: 'name',
            };
        },

        categoryFetchParams() {
            return {
                car_id: (this.car || {}).id,
                parent_id: this.category.id,
                order_by: 'lft',
            };
        },

        hasSelectedCar() {
            return this.selectedCar && !!this.selectedCar.id;
        },
    },

    watch: {
        '$route.params.slug': {
            async handler() {
                this.destroy();
                this.setInitializing(true);
                await this.serverPrefetch(this.$route.params.slug);
                await this.init();
            },
        },

        car: {
            handler() {
                if (this.initialized) {
                    this.update();
                }
            },
        },
    },

    created() {
        this.$store.registerModule('marketPartsCatalogCategory', marketPartsCatalogCategory);
    },

    beforeMount() {
        this.setInitializing(true);
    },

    async mounted() {
        if (!this.initialized) {
            await this.serverPrefetch(this.$route.params.slug);
            await this.init();
        }
    },

    beforeDestroy() {
        this.destroy();
        this.$store.unregisterModule('marketPartsCatalogCategory');
    },

    methods: {
        ...mapMutations({
            setInitializing: 'marketPartsCatalogCategory/setInitializing',
            destroy: 'marketPartsCatalogCategory/destroy',
        }),

        ...mapActions({
            serverPrefetch: 'marketPartsCatalogCategory/serverPrefetch',
            browserPrefetch: 'marketPartsCatalogCategory/browserPrefetch',
            fetchCategories: 'marketPartsCatalogCategory/fetchCategories',
            fetchCharacteristics: 'marketPartsCatalogCategory/fetchCharacteristics',
        }),

        async init() {
            const parents = this.category.parents.reduce((acc, item) => {
                acc.push({
                    to: {
                        name: 'market-parts-catalog-category',
                        params: {
                            slug: item.slug,
                        },
                    },
                    title: item.name,
                });
                return acc;
            }, []);

            this.$breadcrumbs([
                {
                    to: {
                        name: 'market',
                    },
                    title: 'Запчасти и автотовары',
                },
                {
                    to: {
                        name: 'market-parts-catalog',
                    },
                    title: 'Автозапчасти',
                },
                ...parents,
                {
                    to: {
                        name: 'market-parts-catalog-category',
                        params: {
                            slug: this.category.slug,
                        },
                    },
                    title: this.category.name,
                },
            ]);

            const config = {
                partNameDocFetchParams: { params: this.partNameDocFetchParams },
                categoryFetchParams: { params: this.categoryFetchParams },
            };

            await this.browserPrefetch(config);

            if (this.characteristics.current_count === 1) {
                await this.$router.push({
                    name: 'market-part-name',
                    params: {
                        slug: this.category.slug,
                        id: this.characteristics.results[0].part_name_id,
                    },
                });
            }
        },

        async update() {
            if (this.category.is_leaf) {
                const config = { params: this.partNameDocFetchParams };
                await this.fetchCharacteristics(config);
            }
            else {
                const config = { params: this.categoryFetchParams };
                await this.fetchCategories(config);
            }
        },

        async handleToCategoryClick(category) {
            try {
                await this.checkSelectCar();
            }
            catch (e) {
                return;
            }

            this.toCategory(category);
        },

        toCategory(category) {
            safeRouterPush({
                name: 'market-parts-catalog-category',
                params: {
                    slug: category.slug,
                },
            });
        },

        async checkSelectCar() {
            if (this.hasSelectedCar) return;

            await this.showSelectCarPopup();
        },

        showSelectCarPopup() {
            return new Promise((resolve, reject) => {
                const props = {
                    modeOptions: [
                        {
                            label: 'По гос. номеру',
                            value: 'grz',
                        },
                        {
                            label: 'VIN/Frame',
                            value: 'vin_frame',
                        },
                        {
                            label: 'Из гаража',
                            value: 'garage',
                        },
                    ],
                    outerAddGarageMode: false,
                    callback: car => {
                        if (car) {
                            resolve();
                        }
                        else {
                            reject();
                        }
                    },
                };
                const options = { props };
                this.$popup(SelectCarPopup, options);
            });
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: var(--grid-gap);
    }
}

.grid-item {
    display: flex;
}

@media (min-width: 768px) {
    .grid-item {
        padding: 20px;
    }
}

@media (max-width: 767px) {
    .grid-item {
        padding: 12px;
    }

    .grid-item:not(:last-child) {
        margin-bottom: 12px;
    }
}

.grid-item__cover-wrap {
    flex-shrink: 0;
    flex-grow: 0;
}

@media (min-width: 768px) {
    .grid-item__cover-wrap {
        margin-right: 20px;
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 767px) {
    .grid-item__cover-wrap {
        margin-right: 12px;
        width: 48px;
        height: 48px;
    }
}

.grid-item__cover {
    width: 100%;
    height: 100%;
}

.grid-item__name-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.grid-item__main-link {
    text-align: left;
}
@media (min-width: 768px) {
    .grid-item__main-link {
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    .grid-item__main-link {
        font-size: 14px;
    }
}

@media (min-width: 768px) {
    .children-list {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .children-list {
        display: none;
    }
}

.children-list__item {
    display: inline;
    color: var(--dark-c);
}

.children-list__item:not(:first-child):not(.children-list__item_more)::before {
    content: ", ";
}
</style>