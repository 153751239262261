export default url => {
    let str = url;

    if (url[0] !== '/') {
        str = '/' + str;
    }

    if (url[url.length - 1] !== '/') {
        str += '/';
    }

    return str;
};