<template>
    <button
        type="button"
        class="pill-btn"
        @click="$emit('click', $event)"
    >
        <span class="pill-btn__label">
            <slot></slot>
        </span>

        <slot name="append-icon">
            <span class="pill-btn__icon-wrap">
                <UIcon
                    name="cross"
                    small
                    secondary
                    class="pill-btn__icon"
                ></UIcon>
            </span>
        </slot>
    </button>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: "ButtonPill",

    components: {
        UIcon,
    },
};
</script>

<style scoped>
.pill-btn {
    display: inline-flex;
    align-items: center;
    padding: 4px 4px 4px 12px;
    background-color: var(--lightest-bg);
    border-radius: 32px;
    transition: background-color var(--transition);
}

.pill-btn:hover,
.pill-btn:focus {
    background-color: var(--light-bg);
}

.pill-btn:hover .pill-btn__icon,
.pill-btn:focus .pill-btn__icon {
    fill: var(--dark-c);
}

.pill-btn__label {
    font-size: var(--small-fz);
    line-height: var(--mini-lh);
    color: var(--dark-c);
}

.pill-btn__icon-wrap {
    display: block;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    padding: 4px;
    font-size: 0;
    background-color: var(--light-c);
    box-shadow: var(--light-shadow);
    border-radius: 50%;
}
</style>