var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-textarea-field",class:{
        'u-textarea-field_disabled': _vm.disabled,
        'u-textarea-field_focused': _vm.isFocused,
        'u-textarea-field_invalid': _vm.invalid,
        'u-textarea-field_filled': !!_vm.value,
    }},[_c('div',{ref:"border",staticClass:"u-textarea-field__border-box",on:{"click":_vm.onClick,"mousedown":_vm.onMouseDown}},[_c('UTextarea',_vm._g(_vm._b({ref:"textarea",staticClass:"u-textarea-field__input"},'UTextarea',_vm.attrs,false),_vm.handlers)),_vm._v(" "),(_vm.label || _vm.$scopedSlots.label)?_c('ULabel',{attrs:{"id":_vm.id,"active":_vm.isFocused || !!_vm.value,"invalid":_vm.invalid,"disabled":_vm.disabled}},[_vm._t("label",function(){return [_vm._v("\n                "+_vm._s(_vm.label)+"\n            ")]})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"u-textarea-field__icon-box u-textarea-field__append-inner"},[(_vm.hint || _vm.$scopedSlots.hint || _vm.$scopedSlots['hint-content'])?_c('UTooltip',{key:"hint",scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
    var attrs = ref.attrs;
    var handlers = ref.handlers;
    var value = ref.value;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"u-input-simple__icon",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}},{key:"content",fn:function(){return [_vm._t("hint-content",function(){return [_c('UTooltipSimpleTemplate',[_vm._t("hint",function(){return [_vm._v("\n                                "+_vm._s(_vm.hint)+"\n                            ")]})],2)]})]},proxy:true}],null,true)}):_vm._e(),_vm._v(" "),(_vm.value)?_c('UIconButton',{key:"close",staticClass:"u-textarea-field__clear",attrs:{"name":"close-16","small":"","secondary":"","tabindex":"-1"},on:{"click":_vm.clear},nativeOn:{"mousedown":function($event){$event.stopPropagation();}}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }