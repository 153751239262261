export default {
    methods: {
        getYMapMarkerIcon({ size = 'base', type = 'default', active = false, selected = false }) {
            const icon = {
                layout: 'default#image',
                imageHref: require(`@/assets/images/map-markers/${ type }.svg`),
                imageSize: [30, 36],
                imageOffset: [-15, -36],
            };

            if (size === 'big' || active || selected) {
                icon.imageSize = [40, 48];
                icon.imageOffset = [-20, -48];
            }

            if (type === 'car') {
                icon.imageSize = [48, 48];
                icon.imageOffset = [-24, -48];
            }

            if (active) {
                icon.imageHref = require(`@/assets/images/map-markers/${ type }-active.svg`);
            }
            else if (selected) {
                icon.imageHref = require(`@/assets/images/map-markers/${ type }-selected.svg`);
            }

            return icon;
        },
    },
};