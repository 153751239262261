var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-input-field",class:{
        'u-input-field_disabled': _vm.disabled,
        'u-input-field_focused': _vm.isFocused,
        'u-input-field_invalid': _vm.invalid,
        'u-input-field_micro': _vm.micro,
        'u-input-field_small': _vm.small,
        'u-input-field_mini': _vm.mini,
        'u-input-field_big': _vm.big,
    }},[(_vm.$scopedSlots['prepend-outer'])?_c('div',{staticClass:"u-input-field__icon-box u-input-field__prepend-outer"},[_vm._t("prepend-outer")],2):_vm._e(),_vm._v(" "),_c('div',{ref:"border",staticClass:"u-input-field__border-box",on:{"click":_vm.onClick,"mousedown":_vm.onMouseDown}},[(_vm.$scopedSlots['prepend-inner'])?_c('div',{staticClass:"u-input-field__icon-box u-input-field__prepend-inner"},[_vm._t("prepend-inner",null,null,Object.assign({}, _vm.scope.attrs, _vm.scope.methods))],2):_vm._e(),_vm._v(" "),_c('UInput',_vm._g(_vm._b({staticClass:"u-input-field__input"},'UInput',_vm.scope.attrs,false),_vm.scope.handlers)),_vm._v(" "),_vm._t("label-element",function(){return [(_vm.label || _vm.$scopedSlots.label)?_c('ULabel',_vm._b({attrs:{"active":!!_vm.value || _vm.isFocused}},'ULabel',_vm.labelScope.attrs,false),[_vm._t("label",function(){return [_vm._v("\n                    "+_vm._s(_vm.label)+"\n                ")]})],2):_vm._e()]},null,_vm.labelScope),_vm._v(" "),(_vm.$scopedSlots['append-inner'])?_c('div',{staticClass:"u-input-field__icon-box u-input-field__append-inner"},[_vm._t("append-inner",null,null,Object.assign({}, _vm.scope.attrs, _vm.scope.methods))],2):_vm._e()],2),_vm._v(" "),(_vm.$scopedSlots['append-outer'])?_c('div',{staticClass:"u-input-field__icon-box u-input-field__append-outer"},[_vm._t("append-outer")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }