<template>
    <div class="filter-categories-item">
        <div
            v-for="category in categories"
            :key="'category-' + category.id"
            class="category"
        >
            <button
                class="category__name-wrap link-primary-hover"
                :class="{
                    'category__name-wrap_active': category.id === value.id,
                }"
                :disabled="category.id === value.id"
                @click="change(category)"
            >
                <span class="category__name-text">
                    {{ category.name }}<span
                        v-if="category.count"
                        class="category__name-counter"
                    >{{ category.count | number }}</span>
                </span>
            </button>

            <FilterCategoriesItem
                v-if="category.children.length"
                :categories="category.children"
                :value="value"
                :config="config"
                class="children"
                @change="$emit('change', $event)"
                @reload="$emit('reload')"
            ></FilterCategoriesItem>
        </div>
    </div>
</template>

<script>
export default {
    name: "FilterCategoriesItem",

    props: {
        categories: {
            type: Array,
            default() {
                return [];
            },
        },

        value: {
            type: Object,
            required: true,
        },

        config: {
            type: Object,
            default() {
                return {
                    label: '',
                    codename: '',
                    id: '',
                    name: '',
                };
            },
        },
    },

    methods: {
        change(category) {
            this.$emit('change', category);
            this.$emit('reload');
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .children {
        margin-left: 12px;
    }
}
@media (max-width: 767px) {
    .children {
        margin-left: 32px;
    }
}

@media (min-width: 768px) {
    .filter-categories-item.children > .category:not(:last-child) {
        margin-bottom: 0;
    }
    .category:not(:last-child) {
        margin-bottom: 16px;
    }
    .category__name-wrap {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.category__name-wrap {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.category__name-counter {
    margin-left: 4px;
    font-size: var(--small-fz);
    color: var(--font-secondary-color);
    transition: color var(--transition);
}

.category__name-wrap:hover .category__name-counter,
.category__name-wrap:focus .category__name-counter {
    color: var(--primary-color);
}

@media (min-width: 768px) {
    .category__name-wrap_active {
        font-family: var(--f-bold);
    }
}
@media (max-width: 767px) {
    .category__name-wrap {
        display: flex;
        align-items: flex-start;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .category__name-wrap::before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-right: 12px;
        border-radius: 50%;
        border: 1px solid var(--border-dark-c);
        background-color: var(--light-c);
        transition: border-color var(--transition-fastest);
    }

    .category__name-wrap:not(.category__name-wrap_active):hover::before {
        border-color: var(--fields-border);
        transition: border-color var(--transition);
    }

    .category__name-wrap_active::before {
        border-width: 6px;
        border-color: var(--primary-color);
        transition: border-color var(--transition-fastest);
    }
}
</style>