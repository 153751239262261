<template>
    <div class="u-textarea__wrap">
        <div
            v-if="contenteditable"
            :id="id"
            ref="contenteditable"
            role="textbox"
            :name="name"
            :disabled="disabled"
            contenteditable="true"
            class="u-textarea__el u-textarea__contenteditable"
            :class="{
                'u-textarea__el_invalid': invalid,
            }"
            v-on="{
                ...listeners,
                ...handlers,
            }"
        ></div>

        <textarea
            v-else
            :id="id"
            ref="textarea"
            :name="name"
            :value="innerValue"
            :disabled="disabled"
            :rows="rows"
            class="u-textarea__el"
            :class="{
                'u-textarea__el_invalid': invalid,
            }"
            v-on="{
                ...listeners,
                ...handlers,
            }"
        ></textarea>
    </div>
</template>

<script>
import genId from '@ui/utils/genId.js';


export default {
    name: 'UTextarea',

    model: {
        event: 'change:model',
        prop: 'value',
    },

    props: {
        // attrs
        id: {
            type: String,
            default: () => genId(),
        },
        name: String,
        value: String,
        placeholder: String,
        rows: {
            type: [String, Number],
            default: 3,
        },

        // state
        disabled: Boolean,
        invalid: Boolean,
        contenteditable: Boolean,
    },

    data() {
        return {
            innerValue: '',
        };
    },

    computed: {
        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { input, ...listeners } = this.$listeners;
            return listeners;
        },

        handlers() {
            return {
                input: this.onInput,
            };
        },

        currentRef() {
            return this.contenteditable ? 'contenteditable' : 'textarea';
        },
    },

    watch: {
        value: {
            handler(value) {
                let newValue = value == null ? '' : value.toString();

                if (newValue !== this.innerValue) {
                    this.innerValue = newValue;

                    if (this.$refs.contenteditable) {
                        this.$refs.contenteditable.innerText = newValue;
                    }

                    this.emitInput();
                }
            },

            immediate: true,
        },
    },

    mounted() {
        if (this.$refs.contenteditable) {
            this.$refs.contenteditable.innerText = this.innerValue;
        }
    },

    methods: {
        // public

        focus() {
            this.$refs[this.currentRef].focus();
        },

        blur() {
            this.$refs[this.currentRef].blur();
        },

        clear() {
            this.innerValue = '';
            this.$refs[this.currentRef].focus();
            this.emitInput();
            this.emitChange();
            this.emitClear();
        },

        // handlers

        onInput($event) {
            let value = $event.target.value;

            if (this.contenteditable) {
                value = $event.target.innerText;

                const html = $event.target.innerHTML;

                if (html === '<br>') value = '';
            }

            this.innerValue = value;
            this.emitInput();
        },

        // emits

        emitInput() {
            const value = this.innerValue;
            const target = this.$refs[this.currentRef];
            this.$emit('input', { value, target });
            this.$emit('change:model', value);
        },

        emitChange() {
            const value = this.innerValue;
            const target = this.$refs[this.currentRef];
            this.$emit('change', { value, target });
        },

        emitClear() {
            const target = this;
            this.$emit('clear', { target });
        },
    },
};
</script>

<style>
.u-textarea__wrap {
    position: relative;
    display: flex;
}

.u-textarea__el {
    flex-grow: 1;
    font-size: inherit;
    line-height: inherit;
    border: none;
}
.u-textarea__el:disabled {
    cursor: default;
    background-color: transparent;
}

.u-textarea__el::placeholder {
    font-size: inherit;
    line-height: inherit;
    color: var(--font-secondary-light-color);
}

.u-textarea__contenteditable {
    outline: none;
}
</style>