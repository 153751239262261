<template>
    <div class="rating-block">
        <div class="label_wrap">
            <h4 class="h4 label">
                {{ label }}
            </h4>
        </div>

        <div class="flex flex-column">
            <div class="rating" :class="{ rating_invalid: invalid }">
                <button
                    v-for="index in 5"
                    type="button"
                    class="rating__value"
                    :aria-label="`Значение ${ 5 - index + 1 }`"
                    @click="setValue(5 - index + 1)"
                >
                    <UIcon
                        v-if="5 - index >= value"
                        name="star-none"
                        big
                        class="star star_none"
                    ></UIcon>

                    <UIcon
                        v-else
                        name="star-fill"
                        big
                        class="star star_fill"
                    ></UIcon>
                </button>
            </div>

            <FieldError
                :show="invalid"
                class="error"
            >
                {{ error }}
            </FieldError>
        </div>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';
import FieldError from '@/components/_form/FieldError.vue';


export default {
    name: 'Rating',

    components: { FieldError, UIcon },

    props: {
        value: {
            type: [String, Number],
            default: '',
        },

        label: {
            type: String,
            default: 'Общая оценка',
        },

        invalid: Boolean,
        error: String,
    },

    methods: {
        setValue(value) {
            this.$emit('change', value);
        },
    },
};
</script>

<style scoped>
.rating-block {
    display: flex;
}

.label_wrap {
    margin-right: 16px;
    height: 24px;
    display: flex;
    align-items: center;
}

.label {

}

.rating {
    display: inline-flex;
    flex-direction: row-reverse;
}

.rating__value {
    font-size: 0;
}

.rating__value:not(:last-child) {
    margin-left: 4px;
}

.star.star_fill {
    fill: var(--accent-yellow);
}
.star.star_none {
    fill: #EDF0F2;
}
.rating.rating_invalid .star.star_none {
    stroke: var(--error-lightest-color);
    fill: var(--error-brightest-color);
}
.rating__value:hover ~ .rating__value .star,
.rating__value:focus ~ .rating__value .star  {
    fill: var(--accent-yellow);
}
.rating__value:hover .star.star_none,
.rating__value:focus .star.star_none  {
    fill: var(--accent-yellow);
}

.error >>> .field-error {
    margin-top: 7px;
}
</style>