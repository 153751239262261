<template>
    <button
        class="select-car-btn btn"
        @click="handleCarBtnClick"
    >
        <template v-if="hasSelectedCar">
            <CarManufacturerLogo
                :manufacturer="selectedCar.manufacturer"
                size="micro"
                mono
                class="select-car-btn__icon_logo"
            ></CarManufacturerLogo>

            <CarName
                :car="selectedCar"
                noManufacturerName
                class="select-car-btn__car-name"
            ></CarName>
        </template>

        <template v-else>
            <span class="select-car-btn__text">
                Выберите автомобиль
            </span>

            <UIcon
                name="dots"
                small
                class="select-car-btn__dots-icon"
            ></UIcon>
        </template>
    </button>
</template>

<script>
import { mapState } from 'vuex';
import CarManufacturerLogo from "@/components/CarManufacturerLogo.vue";
import CarName from "@/components/CarName.vue";
import UIcon from '@ui/components/UIcon/UIcon.vue';
const SelectCarPopup = () => import('@/components/popups/SelectCarPopup.vue');
const SelectedSearchCarPopup = () => import('@/components/popups/SelectedSearchCarPopup.vue');

export default {
    name: 'SelectCarBtn',

    components: {
        UIcon,
        CarManufacturerLogo,
        CarName,
    },

    computed: {
        ...mapState({
            selectedCar: state => state.searchWidget.car,
        }),

        hasSelectedCar() {
            return this.selectedCar && !!this.selectedCar.id;
        },
    },

    methods: {
        handleCarBtnClick() {
            if (this.hasSelectedCar) this.$popup(SelectedSearchCarPopup);
            else this.showSelectCarPopup();
        },

        showSelectCarPopup() {
            const props = {
                modeOptions: [
                    {
                        label: 'По гос. номеру',
                        value: 'grz',
                    },
                    {
                        label: 'VIN/Frame',
                        value: 'vin_frame',
                    },
                    {
                        label: 'Из гаража',
                        value: 'garage',
                    },
                ],
                outerAddGarageMode: false,
            };
            const options = { props };
            this.$popup(SelectCarPopup, options);
        },
    },
};
</script>

<style scoped>
.select-car-btn {
    display: flex;
    align-items: center;
    padding: 0 16px;
    text-align: left;
    background-color: var(--light-bg);
}
.select-car-btn:hover,
.select-car-btn:focus {
    background-color: var(--lightest-bg);
}
@media (min-width: 768px) {
    .select-car-btn {
        width: 280px;
        height: 36px;
    }
}
@media (max-width: 767px) {
    .select-car-btn {
        width: 100%;
        height: 44px;
        margin-bottom: 8px;
    }
}

.select-car-btn__icon_logo {
    margin-right: 8px;
}

.select-car-btn__text {
    font-size: var(--base-fz);
    font-family: var(--f-semi-bold);
}

.select-car-btn__car-name {
    font-size: var(--small-fz);
    letter-spacing: 0.06em;
    text-transform: uppercase;
}
@media (min-width: 768px) {
    .select-car-btn__car-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
@media (max-width: 767px) {
    .select-car-btn__car-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.select-car-btn__dots-icon {
    margin-left: auto;
}
</style>