<template>
    <div class="u-field-string">
        <UInputSimple
            :invalid="invalid"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template
                v-for="slotName in Object.keys($scopedSlots)"
                v-slot:[slotName]="payload"
            >
                <slot :name="slotName" v-bind="payload"></slot>
            </template>
        </UInputSimple>

        <UFormFieldError
            :show="invalid"
            class="u-field-string__error"
        >
            {{ error }}
        </UFormFieldError>
    </div>
</template>

<script>
import fieldMixin from '@ui/components/UForm/fields/fieldMixin.js';
import UFormFieldError from '@ui/components/UForm/UFormFieldError.vue';
import UInputSimple from '@ui/components/UInput/presets/UInputSimple.vue';


export default {
    name: 'UFieldString',

    components: {
        UInputSimple,
        UFormFieldError,
    },

    mixins: [
        fieldMixin,
    ],

    data() {
        return {
            initialValue: '',
        };
    },
};
</script>

<style>
.u-field-string__error .u-form-field-error {
    margin-top: 8px;
}
</style>