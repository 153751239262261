<template>
    <div
        v-if="breakpointKey === 'l' || breakpointKey === 'xl'"
        class="market-item layer-1"
    >
        <MarketItemCover
            :item="item"
            modelName="part_product"
            coverCodename="small"
            class="market-item__cover"
        ></MarketItemCover>

        <div class="market-item__main">
            <MarketItemName
                :id="item.id || item.part_product_id"
                modelName="part_product"
                class="market-item__name"
            >
                {{ item.name }}
            </MarketItemName>
            <div class="market-item__sku-scheme-wrap">
                <MarketItemSku
                    :item="item"
                    modelName="part_product"
                    class="market-item__sku"
                ></MarketItemSku>
                <ButtonText
                    dashed
                    secondary
                    dark
                    class="market-item__scheme-link"
                    @click="showPartSchemes"
                >
                    На схеме
                </ButtonText>
            </div>
            <MarketItemRatingReviewsLink
                :item="item"
                modelName="part_product"
                class="market-item__reviews"
            ></MarketItemRatingReviewsLink>
        </div>

        <div
            class="market-item__separator"
            aria-hidden="true"
        ></div>

        <BrandShortInfo
            :brand="item.brand"
            class="market-item__brand"
        ></BrandShortInfo>

        <div class="market-item__actions">
            <LikeBtn
                :id="item.id"
                :value="item.is_favorite"
                apiName="part_product"
                class="market-item__like-btn"
                @changeFavorite="$emit('changeFavorite', $event)"
            ></LikeBtn>
            <ButtonBlock
                :to="{
                    name: 'market-parts-search',
                    query: {
                        part_product: item.id,
                    }
                }"
                primary
                low
                class="market-item__main-btn"
            >
                Показать цены
            </ButtonBlock>
        </div>
    </div>

    <div
        v-else-if="breakpointKey === 'm'"
        class="market-item layer-1"
    >
        <MarketItemCover
            :item="item"
            modelName="part_product"
            coverCodename="small"
            class="market-item__cover"
        ></MarketItemCover>

        <div class="market-item__main">
            <MarketItemName
                :id="item.id || item.part_product_id"
                modelName="part_product"
                class="market-item__name"
            >
                {{ item.name }}
            </MarketItemName>
            <div class="market-item__sku-scheme-wrap">
                <MarketItemSku
                    :item="item"
                    modelName="part_product"
                    class="market-item__sku"
                ></MarketItemSku>
                <ButtonText
                    dashed
                    secondary
                    dark
                    class="market-item__scheme-link"
                    @click="showPartSchemes"
                >
                    На схеме
                </ButtonText>
            </div>

            <BrandShortInfo
                :brand="item.brand"
                row
                class="market-item__brand"
            ></BrandShortInfo>

            <MarketItemRatingReviewsLink
                :item="item"
                modelName="part_product"
                class="market-item__reviews"
            ></MarketItemRatingReviewsLink>
        </div>

        <div class="market-item__actions">
            <LikeBtn
                :id="item.id"
                :value="item.is_favorite"
                apiName="part_product"
                class="market-item__like-btn"
                @changeFavorite="$emit('changeFavorite', $event)"
            ></LikeBtn>
            <ButtonBlock
                :to="{
                    name: 'market-parts-search',
                    query: {
                        part_product: item.id,
                    }
                }"
                primary
                low
                class="market-item__main-btn"
            >
                Показать цены
            </ButtonBlock>
        </div>
    </div>

    <div
        v-else-if="breakpointKey === 's'"
        class="market-item layer-1"
    >
        <div class="market-item__top">
            <MarketItemCover
                :item="item"
                modelName="part_product"
                coverCodename="mini"
                class="market-item__cover"
            ></MarketItemCover>

            <div class="market-item__main">
                <MarketItemName
                    :id="item.id || item.part_product_id"
                    modelName="part_product"
                    class="market-item__name"
                >
                    {{ item.name }}
                </MarketItemName>
                <MarketItemSku
                    :item="item"
                    modelName="part_product"
                    class="market-item__sku"
                ></MarketItemSku>
                <ButtonText
                    dashed
                    secondary
                    dark
                    class="market-item__scheme-link"
                    @click="showPartSchemes"
                >
                    На схеме
                </ButtonText>

                <MarketItemRatingReviewsLink
                    :item="item"
                    modelName="part_product"
                    :inPopup="reviewsInPopup"
                    class="market-item__reviews"
                ></MarketItemRatingReviewsLink>
            </div>

            <LikeBtn
                :id="item.id"
                :value="item.is_favorite"
                apiName="part_product"
                class="market-item__like-btn"
                @changeFavorite="$emit('changeFavorite', $event)"
            ></LikeBtn>
        </div>

        <div class="market-item__bottom">
            <BrandShortInfo
                :brand="item.brand"
                row
                class="market-item__brand"
            ></BrandShortInfo>

            <ButtonBlock
                :to="{
                    name: 'market-parts-search',
                    query: {
                        part_product: item.id,
                    }
                }"
                primary
                low
                class="market-item__main-btn"
            >
                Цены
            </ButtonBlock>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import breakpointKey from '@/mixins/breakpointKey.js';
import MarketItemCover from '@/components/MarketItemCover.vue';
import MarketItemName from '@/components/MarketItemName.vue';
import MarketItemSku from '@/components/MarketItemSku.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';
import BrandShortInfo from '@/components/BrandShortInfo.vue';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';

export default {
    name: 'MarketItemInPartNamePage',

    components: {
        ButtonBlock,
        LikeBtn,
        BrandShortInfo,
        MarketItemRatingReviewsLink,
        ButtonText,
        MarketItemSku,
        MarketItemName,
        MarketItemCover,
    },

    mixins: [breakpointKey],

    props: {
        item: {
            type: Object,
            default: () => {},
        },

        reviewsInPopup: Boolean,
    },

    computed: {
        ...mapState({
            globalSearchSelectedCar: state => state.searchWidget.car,
        }),
    },

    methods: {
        showPartSchemes() {
            this.$emit('callShowPartSchemesPopup');
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .market-item {
        display: flex;
        align-items: center;
        padding: 20px;
    }

    .market-item__main {
        flex-grow: 1;
    }

    .market-item__cover {
        margin-right: 20px;
        align-self: flex-start;
    }

    .market-item__sku-scheme-wrap {
        display: flex;
        align-items: center;
        margin-top: 4px;
    }

    .market-item__sku-scheme-wrap::after {
        content: "";
        align-self: stretch;
        order: 2;
        width: 1px;
        flex-shrink: 0;
        margin-left: 16px;
        margin-right: 16px;
        background-color: var(--border-light-c);
    }

    .market-item__sku.sku-wrap {
        order: 1;
        margin-top: 0;
        margin-bottom: 0;
    }

    .market-item__scheme-link {
        order: 3;
    }

    .market-item__reviews {
        margin-top: 16px;
    }

    .market-item__actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        align-self: stretch;
        margin-left: 20px;
    }

    .market-item__like-btn {
        margin-bottom: 24px;
    }

    .market-item__separator {
        width: 1px;
        flex-shrink: 0;
        align-self: stretch;
        margin-left: 20px;
        margin-right: 20px;
        background-color: var(--border-light-c);
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .market-item {
        display: flex;
        align-items: center;
        padding: 20px;
    }

    .market-item__main {
        flex-grow: 1;
    }

    .market-item__cover {
        margin-right: 20px;
        align-self: flex-start;
    }

    .market-item__sku-scheme-wrap {
        display: flex;
        align-items: center;
        margin-top: 4px;
    }

    .market-item__sku-scheme-wrap::after {
        content: "";
        align-self: stretch;
        order: 2;
        width: 1px;
        flex-shrink: 0;
        margin-left: 16px;
        margin-right: 16px;
        background-color: var(--border-light-c);
    }

    .market-item__sku.sku-wrap {
        order: 1;
        margin-top: 0;
        margin-bottom: 0;
    }

    .market-item__scheme-link {
        order: 3;
    }

    .market-item__brand {
        margin-top: 16px;
    }

    .market-item__reviews {
        margin-top: 16px;
    }

    .market-item__actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        align-self: stretch;
        margin-left: 16px;
    }

    .market-item__like-btn {
        margin-bottom: 24px;
    }
}

@media (max-width: 767px) {
    .market-item__top {
        display: flex;
        align-items: flex-start;
        padding: 16px 16px 12px;
    }

    .market-item__main {
        flex-grow: 1;
        margin-right: 12px;
    }

    .market-item__cover {
        margin-right: 12px;
    }

    .market-item__sku.sku-wrap {
        margin-top: 0;
        margin-bottom: 0;
    }

    .market-item__scheme-link {
        margin-top: 12px;
    }

    .market-item__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px 16px;
        border-top: 1px solid var(--border-light-c);
    }

    .market-item__brand >>> .logo {
        width: 36px;
    }

    .market-item__reviews {
        margin-top: 12px;
    }
}
</style>