<template>
    <div
        v-if="isPromotionVisible"
        ref="promotionWrapper"
        class="promotion-wrapper"
    >
        <div class="promotion-container container">
            <div class="promotion__message">
                {{ currentPromotion.title }}
            </div>
            <ButtonBlock
                secondary
                low
                class="promotion__btn"
                @click="hideRedirect(currentPromotion.id, currentPromotion.link)"
            >
                {{ currentPromotion.label }}
            </ButtonBlock>
        </div>
        <button
            class="promotion__close-btn"
            aria-label="Закрыть уведомление"
            @click="hide(currentPromotion.id)"
        >
            <UIcon
                name="cross"
                big
                class="promotion__close-btn-icon"
            ></UIcon>
        </button>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'LinePromotion',

    components: { UIcon, ButtonBlock },

    computed: {
        ...mapState({
            allPromotions: state => state.promotions.allPromotions,
            viewedPromotions: state => state.promotions.viewedPromotions,
            currentPromotion: state => state.promotions.currentPromotion,
            isPromotionVisible: state => state.promotions.isPromotionVisible,
        }),
    },

    watch: {
        allPromotions: {
            handler() {
                this.init();
            },
        },
    },

    methods: {
        ...mapMutations({
            SET_CURRENT_PROMOTION: 'promotions/setCurrentPromotion',
            SET_PROMOTION_VISIBILITY: 'promotions/setPromotionVisibility',
            SET_VIEWED_PROMOTIONS: 'promotions/setViewedPromotions',
        }),

        init() {
            if (localStorage.getItem('UbibiVuexViewedPromotions')) {
                let viewedPromotions = JSON.parse(localStorage.getItem('UbibiVuexViewedPromotions'));
                let notViewedPromotions = this.allPromotions.filter(allItem => !viewedPromotions.includes(allItem.id));

                if (notViewedPromotions.length) {
                    this.SET_PROMOTION_VISIBILITY(true);
                    this.SET_CURRENT_PROMOTION(notViewedPromotions[0]);
                }
                else {
                    this.SET_PROMOTION_VISIBILITY(false);
                }
            }
            else {
                if (this.allPromotions.length) {
                    this.SET_PROMOTION_VISIBILITY(true);
                    this.SET_CURRENT_PROMOTION(this.allPromotions[0]);
                }
            }
        },

        hide(id) {
            let viewedPromotions;
            if (localStorage.getItem('UbibiVuexViewedPromotions')) {
                viewedPromotions = JSON.parse(localStorage.getItem('UbibiVuexViewedPromotions'));
            }
            else {
                viewedPromotions = [];
            }

            this.$refs.promotionWrapper.style.marginTop = `-${ this.$refs.promotionWrapper.offsetHeight }px`;

            setTimeout(() => {
                viewedPromotions.push(id);
                this.SET_VIEWED_PROMOTIONS(viewedPromotions);
                this.SET_PROMOTION_VISIBILITY(false);
                this.$refs.promotionWrapper.style.marginTop = null;
            }, 200);
        },

        hideRedirect(id, link) {
            this.hide(id);

            const urlFromLink = new URL(link);
            const linkDomain = urlFromLink.hostname;
            const pageDomain = window.location.hostname;

            if (linkDomain === pageDomain) {
                this.$router.push(urlFromLink.pathname);
            }
            else {
                window.open(link);
            }
        },
    },
};
</script>

<style scoped>
.promotion-wrapper {
    position: relative;
    padding: 12px 0;
    background-color: var(--dark-c);
    transition: margin var(--transition);
}

@media (min-width: 768px) {
    .promotion-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .promotion-container {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.promotion__message {
    color: var(--light-c);
    font-size: var(--small-fz);
    line-height: var(--base-lh);
    font-family: var(--f-semi-bold);
}
@media (max-width: 767px) {
    .promotion__message {
        margin-right: 24px;
    }
}

@media (min-width: 768px) {
    .promotion__btn {
        margin-left: 56px;
    }
}
@media (max-width: 767px) {
    .promotion__btn {
        margin-top: 12px;
    }
}

.promotion__close-btn {
    position: absolute;
    right: 12px;
    color: var(--light-c);
    font-size: 0;
    transition: color var(--transition);
}
@media (min-width: 768px) {
    .promotion__close-btn {
        top: 50%;
        transform: translateY(-50%);
    }
}
@media (max-width: 767px) {
    .promotion__close-btn {
        top: 12px;
    }
}

.promotion__close-btn:hover,
.promotion__close-btn:focus {
    color: var(--font-secondary-dark-color);
}

.promotion__close-btn-icon {
    fill: currentColor;
}
</style>
