<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <FormManager
            ref="form"
            v-model="formData"
            class="popup-content"
            @submit="submit"
        >
            <div class="review-popup__head">
                <h2 class="h2 popup-title">
                    Оставить отзыв
                </h2>

                <p class="popup-subtitle">
                    {{ shop.company.name }}, г. {{ shop.city.name }}, {{ shop.address }}
                </p>
            </div>

            <FormField
                type="rating"
                name="rating"
                label="Общая оценка"
                validations="required"
                offModifyLabel
                class="bottom-gap_small"
            ></FormField>

            <FormField
                type="text"
                name="comment"
                label="Мнение о магазине"
                validations="required"
                class="bottom-gap_small"
            ></FormField>

            <div>
                <h4 class="h4 h4_block">
                    Порекомендовали бы магазин друзьям?
                </h4>
                <RadioButtonsGroup
                    v-model="formData.is_recommend"
                    name="is_recommend"
                    type="text"
                    :options="[
                        {
                            label: 'Да',
                            value: 'true'
                        },
                        {
                            label: 'Нет',
                            value: 'false'
                        },
                    ]"
                ></RadioButtonsGroup>
            </div>

            <div class="form__actions">
                <FormAgreement
                    text="Отправить"
                    class="form__agreement"
                ></FormAgreement>

                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    class="form__submit"
                >
                    {{ edit ? 'Сохранить' : 'Отправить' }}
                </ButtonBlock>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import { mapState } from 'vuex';
import RadioButtonsGroup from '../RadioButtonsGroup.vue';
import FormAgreement from '../FormAgreement.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';


export default {
    name: 'ShopReviewPopup',

    components: {
        ButtonBlock,
        FormField,
        FormManager,
        FormAgreement,
        RadioButtonsGroup,
        PopupWrap,
    },

    mixins: [
        popup,
    ],

    inheritAttrs: false,

    props: {
        shop: Object,
        edit: Boolean,
        review: Object,
    },

    data() {
        return {
            formData: {
                rating: '',
                comment: '',
                is_recommend: true,
            },
            loading: false,
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),
    },

    beforeMount() {
        if (this.edit) {
            this.formData.rating = this.review.rating;
            this.formData.comment = this.review.comment;
            this.formData.is_recommend = this.review.is_recommend;
        }
    },

    methods: {
        async submit() {
            const errors = await this.$refs.form.validate();

            if (errors) return;

            this.loading = true;

            try {
                if (this.edit) {
                    await this.$api.shops.reviews.updateReview(this.shop.id, this.review.uuid, this.formData);
                    this.$success('Изменения сохранены');
                }
                else {
                    await this.$api.shops.reviews.addReview(this.shop.id, this.formData);
                    this.$success('Отзыв опубликован');
                }

                this.callback(true);
                this.close();
            }
            catch (error) {
                this.$refs.form.handle(error);
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 776px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.popup-subtitle {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .popup-subtitle {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .popup-subtitle {
        margin-top: 4px;
    }
}
@media (max-width: 767px) {
    .popup-subtitle {
        width: var(--col-5);
    }
}

.review-popup__head {
    margin-bottom: var(--gap-y-mini);
}

.form__actions {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .form__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .form__actions {
        margin-top: auto;
    }
}

@media (max-width: 767px) {
    .form__submit {
        width: 100%;
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .form__agreement {
        margin-right: 20px;
    }
}
</style>