<template>
    <component
        :is="view"
        :filters="filters"
        :checkedFilters="checkedFilters"
        :value="value"
        :amount="amount"
        :loading="loading"
        @change="change"
        @submit="submit"
        @reload="reload"
        @reset="reset"
        @deleteFilter="deleteFilter"
    ></component>
</template>

<script>
import breakpointKey from '@/mixins/breakpointKey.js';
import DesktopUI from '@/components/filterUI/DesktopUI.vue';
import MobileUI from '@/components/filterUI/MobileUI.vue';
import TabletUI from '@/components/filterUI/TabletUI.vue';


export default {
    name: 'FilterComponent',

    components: {
        TabletUI,
        MobileUI,
        DesktopUI,
    },

    mixins: [breakpointKey],

    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },

        filters: {
            type: Array,
            default() {
                return [];
            },
        },

        checkedFilters: {
            type: Array,
            default: () => [],
        },

        amount: Number,

        loading: Boolean,
    },

    computed: {
        view() {
            if (['xl', 'l'].includes(this.breakpointKey)) {
                return 'DesktopUI';
            }
            else if (this.breakpointKey === 'm') {
                return 'TabletUI';
            }
            else if (this.breakpointKey === 's') {
                return 'MobileUI';
            }

            return null;
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },

        reset() {
            this.$emit('reset');
        },

        submit() {
            this.$emit('submit');
        },

        reload() {
            this.$emit('reload');
        },

        change(payload) {
            this.$emit('change', payload);
        },

        deleteFilter(payload) {
            this.$emit('deleteFilter', payload);
        },
    },
};
</script>