<template>
    <MobileMenu
        position="right"
        :isOpen="show"
    >
        <div class="filter">
            <div class="header">
                <h3 class="h3">
                    Фильтры
                </h3>

                <ButtonIcon
                    :secondary="false"
                    :icon="{
                        name: 'cross',
                        big: true,
                        secondary: true,
                        hovered: true,
                    }"
                    type="button"
                    aria-label="Закрыть меню фильтров"
                    @click="toggleFilter(false)"
                ></ButtonIcon>
            </div>

            <div class="body">
                <FilterSwitcher
                    :filters="filters"
                    :value="value"
                    :amount="amount"
                    :loading="loading"
                    @change="change"
                    @submit="submit"
                    @reload="reload"
                ></FilterSwitcher>
            </div>

            <div
                v-if="checkedFilters.length"
                class="footer"
            >
                <ButtonText
                    type="reset"
                    primary
                    dashed
                    @click="reset"
                >
                    Сбросить фильтры
                </ButtonText>
            </div>
        </div>
    </MobileMenu>
</template>

<script>
import FilterSwitcher from '@/components/FilterSwitcher.vue';
import ButtonText from "@/components/_buttons/ButtonText.vue";
import MobileMenu from '@/components/MobileMenu.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import bus from '@/bus.js';

export default {
    name: "TabletUI",

    components: {
        ButtonIcon,
        MobileMenu,
        ButtonText,
        FilterSwitcher,
    },

    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },

        filters: {
            type: Array,
            default() {
                return [];
            },
        },

        checkedFilters: {
            type: Array,
            default: () => [],
        },

        amount: Number,

        loading: Boolean,
    },

    data() {
        return {
            show: false,
        };
    },

    mounted() {
        bus.$on('closeFilter', () => this.toggleFilter(false));
        bus.$on('openFilter', () => this.toggleFilter(true));
    },

    beforeDestroy() {
        bus.$off('closeFilter', this.toggleFilter);
        bus.$off('openFilter', this.toggleFilter);
    },

    methods: {
        toggleFilter(value) {
            if (value !== this.show) {
                this.show = value;
                // const el = document.getElementsByTagName('body')[0];
                // el.style.overflow = value ? 'hidden' : null;

                if (value) bus.$emit('openFilter');
                else bus.$emit('closeFilter');
            }
        },

        close() {
            this.$emit('close');
        },

        reset() {
            this.$emit('reset');
        },

        submit() {
            this.$emit('submit');
        },

        reload() {
            this.$emit('reload');
        },

        change(payload) {
            this.$emit('change', payload);
        },
    },
};
</script>

<style scoped>
.filter {
    position: relative;
    height: 100%;
    overflow: auto;
}

.header {
    position: sticky;
    top: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    border-bottom: 1px solid var(--border-light-c);
    background-color: var(--light-c);
}

.footer {
    position: sticky;
    bottom: 0;
    z-index: 9;
    margin-top: -1px;
    padding: 20px;
    border-top: 1px solid var(--border-light-c);
    background-color: var(--light-c);
    text-align: center;
}
</style>