<script>
export default {
    name: 'RadioBase',

    model: {
        prop: 'model',
        event: 'change',
    },

    props: {
        id: {
            type: String,
            required: true,
        },

        classes: {
            type: Object,
            default() {
                return {
                    wrap: 'radio__wrap',
                    input: 'radio__input',
                    label: 'radio__label',
                };
            },
        },

        model: {
            type: [String, Number, Boolean, Object],
            default: null,
        },

        // eslint-disable-next-line vue/require-default-prop
        value: {
            type: [String, Number, Boolean, Object],
            required: true,
        },

        valueKey: {
            type: String,
            default: 'id',
        },

        label: {
            type: String,
            default: '',
        },

        subtext: {
            type: String,
            default: '',
        },

        name: {
            type: String,
            default: '',
        },
    },

    watch: {
        model() {
            this.setChecked();
        },
    },

    mounted() {
        this.setChecked();
    },

    methods: {
        setChecked() {
            if (this.$refs.input) {
                if (typeof this.model === 'object' && this.model !== null) {
                    this.$refs.input.checked = this.value[this.valueKey] === this.model[this.valueKey];
                }
                else {
                    this.$refs.input.checked = this.value === this.model;
                }
            }
        },
    },

    render(h) {
        return h(
            'div',
            {
                class: this.classes.wrap || 'radio__wrap',
            },
            [
                h(
                    'input',
                    {
                        ref: 'input',
                        class: this.classes.input || 'radio__input',
                        on: {
                            change: $event => {
                                if ($event.target.checked) {
                                    this.$emit('change', this.value);
                                }
                            },
                        },
                        attrs: {
                            id: this.id,
                            type: 'radio',
                            name: this.name,
                        },
                    },
                ),

                h(
                    'label',
                    {
                        class: this.classes.label || 'radio__label',
                        attrs: {
                            for: this.id,
                        },
                    },
                    [
                        h(
                            'span',
                            {
                                class: 'radio__label-text',
                            },
                            this.$slots.default || this.label,
                        ),
                    ],
                ),
            ],
        );
    },
};
</script>

<style scoped>
.radio__input {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    cursor: default;
}

.radio__label {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.radio__label-text {
    min-width: 0;
}

.radio__label::before {
    content: "";
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    background-color: var(--light-c);
    border: 1px solid var(--border-dark-c);
    border-radius: 50%;
    transition: border-color var(--transition), border-width .1s ease-out;
}

.radio__input:disabled + .radio__label {
    cursor: default;
    pointer-events: none;
}

.radio__input:disabled + .radio__label::before {
    background-color: var(--bright-bg);
}

.radio__input:not(:disabled):not(:checked) + .radio__label:not(:active):hover::before {
    border-color: var(--fields-border);
    transition: border var(--transition);
}

.radio__input:not(:disabled):not(:checked) + .radio__label:active::before {
    border-color: var(--primary-color);
    border-width: 10px;
    transition: border .1s ease-out;
}

.radio__input:checked + .radio__label::before {
    border-color: var(--primary-color);
    border-width: 6px;
    transition: border .1s ease-in;
}

.radio__wrap:not(:first-child) >>> .radio__label {
    padding-top: 8px;
}

.radio__wrap:not(:last-child) >>> .radio__label {
    padding-bottom: 8px;
}
</style>