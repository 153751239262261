export default filters => {
    return filters.map(data => {
        const filter = {};
        const type = data.data_type;
        const codename = data.codename;

        if (type === 'one') {
            filter.widget = codename === 'tire_season' || codename === 'tire_spikes' ? 'checkboxes' : 'multiselect';
            filter.api = 'choices';
            filter.params = {
                attribute_id__in: data.id,
                order_by: 'name',
            };
            filter.options = data.options || [];
            filter.isSelection = true;
            filter.codename = codename;
            filter.title = data.name + (data.unit ? ', ' + data.unit : '');
        }

        return filter;
    }).filter(filter => !!Object.keys(filter).length);
};