<template>
    <div class="maintenance-mode">
        <div class="text-block container">
            <img
                src="../assets/images/logo.svg"
                alt="Логотип Ubibi"
                class="logo"
                width="80"
                height="24"
            >

            <h1 class="h1 h1_text">
                {{ disableTitle }}
            </h1>
            <WysiwygView
                class="text"
                :value="disableText"
            ></WysiwygView>
            <a
                :href="$server.admin"
                class="text_red underline entry"
            >
                Вход для администраторов
            </a>
        </div>

        <div class="inverted-u-logo-wrap">
            <div class="contacts">
                <a
                    class="email"
                    :href="'mailto:' + email"
                >{{ email }}</a>
                <a
                    class="phone"
                    :href="'tel:' + phone"
                >{{ phone }}</a>
            </div>
            <img
                src="../assets/images/inverted-u-for-maintenance-mode.svg"
                alt=""
                class="inverted-u-logo"
            >
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import WysiwygView from '../components/views/WysiwygView.vue';

export default {
    name: "MaintenanceModePage",

    metaInfo() {
        return {
            title: 'Сайт временно недоступен',
        };
    },

    components: {
        WysiwygView,
    },

    computed: {
        ...mapState({
            disableTitle: state => state.settings.disable_title,
            disableText: state => state.settings.disable_text,
            email: state => state.settings.email,
            phone: state => state.settings.phone,
            isAuthorized: state => state.profile.isAuthorized,
        }),
    },

    mounted() {
        if (this.isAuthorized) {
            this.$router.push({ name: 'main' });
        }
    },
};
</script>

<style scoped>
.maintenance-mode {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.logo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--gap-y-big);
}
@media (min-width: 768px) {
    .logo {
        width: 197px;
        height: 60px;
    }
}
@media (max-width: 767px) {
    .logo {
        width: 80px;
        height: 24px;
        margin-bottom: 20px;
    }
}

.text-block {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: var(--gap-y-big);
    padding-bottom: var(--gap-y-big);
}

.text {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--gap-y-small);
}
@media (min-width: 1040px) {
    .text {
        max-width: 780px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .text {
        width: var(--col-10);
    }
}

.entry {
    font-size: var(--adaptive-big-fz);
}

.inverted-u-logo-wrap {
    position: relative;
    width: 100%;
    min-height: 185px;
    justify-self: flex-end;
    overflow: hidden;
}

.inverted-u-logo {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
@media (min-width: 1281px) {
    .inverted-u-logo {
        width: 780px;
        height: 390px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .inverted-u-logo {
        width: var(--col-8);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .inverted-u-logo {
        width: 708px;
        height: 354px;
    }
}
@media (max-width: 767px) {
    .inverted-u-logo {
        width: 5122px;
        max-width: initial;
        height: 260px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
    color: var(--light-c);
}
@media (min-width: 1281px) {
    .contacts {
        top: 77px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .contacts {
        top: 18%;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .contacts {
        top: 68px;
    }
}
@media (max-width: 767px) {
    .contacts {
        top: 48px;
    }
}

.email {
    display: inline-block;
    margin-bottom: 12px;
}

.phone {
    display: inline-block;
}
</style>