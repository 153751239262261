<template>
    <div v-if="buttons" class="delivery-info_buttons">
        <span
            v-for="item in buttonItems"
            :key="item.key"
            class="chip"
        >
            <span class="chip__label">
                <span class="chip__label-title">{{ item.label }}</span>
                <span class="chip__label-text"> {{ item.dateShortText }}</span>
            </span>
        </span>

        <UTooltip v-if="currentWork.delivery.is_active && (offer || order) && hasWeight">
            <template #trigger="{ value, attrs, handlers }">
                <button
                    type="button"
                    tabindex="-1"
                    class="chip"
                    :class="{
                        chip_active: value
                    }"
                    v-bind="attrs"
                    v-on="handlers"
                    @click="calcCountryDelivery"
                >
                    <span class="chip__label">
                        <span class="chip__label-title">Доставка по России</span>
                    </span>
                </button>
            </template>

            Нажмите, чтобы рассчитать сроки и&nbsp;стоимость доставки.
            Оформление и&nbsp;оплата доставки происходит после получения подтверждения
            и&nbsp;комплектации заказа от&nbsp;магазина.
        </UTooltip>
    </div>

    <ul
        v-else-if="list"
        class="delivery-info_list"
        :class="{ 'delivery-info_list_small': small }"
    >
        <li v-for="item in listItems" class="receive-type">
            <span class="text_secondary">{{ item.label }}:</span> {{ item.dateText }}
        </li>
        <li v-if="currentWork.delivery.is_active && (offer || order) && hasWeight" class="receive-type">
            <span class="text_secondary">Доставка по России</span>
            <ButtonText
                dashed
                secondary
                dark
                @click="calcCountryDelivery"
            >
                рассчитать
            </ButtonText>
        </li>
        <li v-if="currentWork.delivery.is_active && !offer && !order" class="receive-type">
            <span class="text_secondary">Доставка по России</span><UTooltip>
                <template #trigger="{ value, attrs, handlers }">
                    <UTooltipIcon
                        class="ml-8"
                        :active="value"
                        v-bind="attrs"
                        v-on="handlers"
                    ></UTooltipIcon>
                </template>

                Чтобы рассчитать сроки и&nbsp;стоимость доставки товаров из&nbsp;этого магазина в&nbsp;свой город,
                добавьте их&nbsp;в&nbsp;корзину.
                Оформление и&nbsp;оплата доставки происходит после получения подтверждения
                и&nbsp;комплектации заказа от&nbsp;магазина.
            </UTooltip>
        </li>
    </ul>
</template>

<script>
import { monthsDeclination, shortMonthsDeclination, weekDays, weekDaysShort } from '@/lib/datetime/names.js';
import { mapState } from 'vuex';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';
const PrecalcCountryDeliveryPopup = () => import('@/components/popups/PrecalcCountryDeliveryPopup.vue');

export default {
    name: 'DeliveryInfo',

    components: {
        UTooltipIcon,
        UTooltip,
        ButtonText,
    },

    props: {
        shop: {
            type: Object,
            default: () => {},
        },

        offer: {
            type: Object,
            default: () => {},
        },

        order: {
            type: Object,
            default: () => {},
        },

        currentWork: {
            type: Object,
            required: true,
        },

        buttons: Boolean,
        list: Boolean,
        small: Boolean,
    },

    computed: {
        ...mapState({
            currentCity: state => state.cities.currentCity,
        }),

        buttonItems() {
            const order = ['pick_up', 'express_delivery'];
            const icons = {
                pick_up: 'pick-up',
                express_delivery: 'express-delivery',
            };
            const labels = {
                pick_up: 'Самовывоз:',
                express_delivery: 'Курьером по городу:',
            };
            const titles = {
                pick_up: 'Самовывоз из магазина: ',
                express_delivery: `Экспресс-доставка (Яндекс Go) по г. ${ this.currentCity.name }: `,
            };

            return order
                .filter(key => this.currentWork && this.currentWork[key] && this.currentWork[key].is_active && this.currentWork[key].date)
                .map(key => {
                    return {
                        key,
                        date: this.currentWork[key].date,
                        label: labels[key],
                        title: titles[key],
                        icon: icons[key],
                        dateShortText: this.getDateString(this.currentWork[key].date, true),
                        dateText: this.getDateString(this.currentWork[key].date),
                    };
                });
        },

        listItems() {
            const order = ['pick_up', 'express_delivery'];
            const labels = {
                pick_up: 'Самовывоз',
                express_delivery: 'Курьером по городу',
            };

            return order
                .filter(key => this.currentWork && this.currentWork[key] && this.currentWork[key].is_active && this.currentWork[key].date)
                .map(key => {
                    return {
                        key,
                        date: this.currentWork[key].date,
                        label: labels[key],
                        dateText: this.getDateString(this.currentWork[key].date),
                    };
                });
        },

        hasWeight() {
            if (this.order) {
                return this.order.positions.every(position => !!(position.offer.part_product || position.offer.product).weight);
            }
            else if (this.offer) {
                return !!(this.offer.part_product || this.offer.product).weight;
            }
            return false;
        },
    },

    methods: {
        getDateString(date, short = false) {
            if (date) {
                let text = '';
                if (date === 'today') {
                    text = 'Сегодня';
                }
                else if (date === 'tomorrow') {
                    text = 'Завтра';
                }
                else {
                    let dt = new Date(date);
                    let weekday = dt.getDay();
                    let day = dt.getDate();
                    let month = dt.getMonth();
                    if (short) {
                        dt = `${ weekDaysShort[weekday] }, ${ day } ${ shortMonthsDeclination[month] }`;
                    }
                    else {
                        dt = `${ weekDays[weekday] }, ${ day } ${ monthsDeclination[month] }`;
                    }
                    text = dt;
                }

                if (short) {
                    return text;
                }
                else {
                    return text.toLowerCase();
                }
            }
            return '';
        },

        calcCountryDelivery() {
            const props = {
                shop: this.shop,
                offer: this.offer,
                order: this.order,
            };
            const options = { props };
            this.$popup(PrecalcCountryDeliveryPopup, options);
        },
    },
};
</script>

<style scoped>
.delivery-info_buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-bottom: -8px;
}

.chip {
    display: flex;
    align-items: center;
    height: 28px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 4px 12px 5px;
    text-align: left;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-light-c);
    transition: border-color var(--transition), background-color var(--transition);
}
button.chip:hover,
button.chip.chip_active {
    background-color: var(--lightest-bg);
    border-color: var(--border-dark-c);
}

.chip__label {
    font-size: var(--small-fz);
    line-height: var(--small-lh);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-dark-color);
}

.chip__label-text {
    text-transform: lowercase;
}

.receive-type {
    color: var(--font-secondary-dark-color);
}

.receive-type:not(:last-child) {
    margin-bottom: 8px;
}

.delivery-info_list_small {
    font-size: var(--small-fz);
}

.delivery-info_list_small .receive-type:not(:last-child) {
    margin-bottom: 4px;
}
</style>