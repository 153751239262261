<template>
    <div class="about-shop-page">
        <section>
            <h1 class="h2 h2_block">
                О магазине
            </h1>

            <div class="about-shop-page__section layer-1">
                <div v-if="shop.rating" class="bottom-gap_small">
                    <h2 class="h4 h4_block">
                        Рейтинг
                    </h2>
                    <MarketItemRatingReviewsLink
                        :item="shop"
                        modelName="shop"
                    ></MarketItemRatingReviewsLink>
                </div>

                <div class="bottom-gap_small">
                    <h2 class="h4 h4_block">
                        Способы получения заказов
                    </h2>

                    <DeliveryInfo
                        :currentWork="shop.current_work"
                        list
                    ></DeliveryInfo>
                </div>

                <div class="bottom-gap_small">
                    <h2 class="h4 h4_block">
                        Режим работы
                    </h2>
                    <ShopWorkSchedule
                        :work="shop.work_times"
                        :holidays="shop.holidays"
                    ></ShopWorkSchedule>
                </div>

                <div v-if="shop.company.description">
                    <h2 class="h4 h4_block">
                        Описание
                    </h2>

                    <p class="about-shop-page__content p-textarea">{{ shop.company.description }}</p>
                </div>
            </div>
        </section>

        <section
            v-if="shop.photos.length"
            class="top-gap_medium"
        >
            <h2 class="h2 h2_block">
                <span>Фотографии</span>
                <span class="h2-sub">{{ shop.photos.length | number }}</span>
            </h2>

            <ScrollSlider>
                <button
                    v-for="(photo, index) in shop.photos"
                    :key="'item-cover-' + photo.uuid"
                    class="photos-list__item"
                    @click="showPhotoViewerPopup(index)"
                >
                    <img
                        :src="$links.uploads + photo.thumbnails.shop_photo_preview"
                        alt=""
                        class="photos-list__img"
                        height="160"
                    >
                </button>
            </ScrollSlider>
        </section>

        <section
            v-if="shop.shops_amount && companyShops.length"
            class="top-gap_medium"
        >
            <h2 class="h2 h2_block">
                <span>Магазины {{ shop.company.name }}</span>
                <span class="h2-sub">{{ shop.shops_amount | number }}</span>
            </h2>

            <div
                ref="map"
                class="map map_desktop"
            >
                <yandexMap
                    ref="mapComponent"
                    :coords="getCoordsArray(companyShops[0])"
                    zoom="10"
                    :controls="['zoomControl']"
                    :scrollZoom="false"
                    :options="{ suppressMapOpenBlock: true }"
                    style="width: 100%; height: 100%;"
                    @map-was-initialized="setMapCenter"
                    @actionend="handleActionEnd"
                >
                    <ymapMarker
                        v-for="(item, index) in companyShops"
                        :key="'item-' + index"
                        :ref="'item-' + index"
                        :coords="getCoordsArray(item)"
                        :icon="getYMapMarkerIcon({ active: balloon.visible && balloon.index === index })"
                        :markerId="'item-' + index"
                        @click="showBalloon({ shop: item, index })"
                    ></ymapMarker>
                </yandexMap>

                <ShopBalloon
                    v-if="balloon.visible"
                    :shop="balloon.shop"
                    :company="shop.company"
                    @close="closeBalloon"
                ></ShopBalloon>
            </div>

            <PointOnMapPreviewBtn
                :coords="shop.coordinates"
                class="map map_mobile"
                @click="openMap"
            ></PointOnMapPreviewBtn>
        </section>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';
import ShopWorkSchedule from '@/components/ShopWorkSchedule.vue';
import ShopBalloon from '@/components/ShopBalloon.vue';
import DeliveryInfo from '@/components/DeliveryInfo.vue';
import PointOnMapPreviewBtn from '@/components/project-buttons/PointOnMapPreviewBtn.vue';
import ScrollSlider from '@/components/ScrollSlider.vue';
const ShopsOnMapPopup = () => import('@/components/popups/ShopsOnMapPopup.vue');
const PhotoViewer = () => import('@/components/popups/PhotoViewer.vue');

export default {
    name: 'MarketAboutShopPage',

    metaInfo() {
        return {
            title: this.shop.meta_title,
            meta: [
                { name: 'description', content: this.shop.meta_description },
                { name: 'keywords', content: this.shop.meta_keywords },
                { property: 'og:title', content: this.shop },
                { property: 'og:description', content: this.shop.meta_description },
                { property: 'og:image', content: this.shop.og_image ? this.$links.uploads + this.shop.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        ScrollSlider,
        PointOnMapPreviewBtn,
        DeliveryInfo,
        yandexMap,
        ymapMarker,
        ShopBalloon,
        ShopWorkSchedule,
        MarketItemRatingReviewsLink,
    },

    mixins: [ymapMarkerMixin],

    data() {
        return {
            mapInstance: null,
            mapCenter: [],
            balloon: {
                shop: {},
                index: null,
                visible: false,
            },
        };
    },

    computed: {
        ...mapState({
            shop: state => state.marketShop.shop,
            companyShops: state => state.marketShop.companyShops,
        }),
    },

    methods: {
        setMapCenter(mapInstance) {
            this.mapInstance = mapInstance;
            if (this.companyShops.length > 1) {
                let pointsList = [];
                this.companyShops.forEach(item => {
                    pointsList.push(this.getCoordsArray(item));
                });
                mapInstance.setBounds(ymaps.util.bounds.fromPoints(pointsList));
            }
        },

        handleActionEnd(e) {
            const newCenter = e.originalEvent.map.getCenter();
            if (this.mapCenter[0] !== newCenter[0] || this.mapCenter[1] !== newCenter[1]) {
                this.mapCenter = newCenter;
            }
        },

        getCoordsArray(item) {
            return item.coordinates.split(', ').map(Number);
        },

        showBalloon({ shop, index }) {
            this.balloon.visible = true;
            this.balloon.shop = shop;
            this.balloon.index = index;
            this.$nextTick(() => {
                this.mapInstance.setCenter(this.mapCenter);
            });
        },

        closeBalloon() {
            this.balloon.visible = false;
            this.balloon.shop = {};
            this.balloon.index = null;
            this.$nextTick(() => {
                this.mapInstance.setCenter(this.mapCenter);
            });
        },

        openMap() {
            const props = {
                company: this.shop.company,
                shops: this.companyShops,
            };
            const options = { props };
            this.$popup(ShopsOnMapPopup, options);
        },

        showPhotoViewerPopup(index) {
            const props = {
                photos: this.shop.photos,
                index: index,
                fullCoverCodename: 'shop_photo_pv',
                miniCoverCodename: 'shop_photo_mini',
            };
            const options = { props };
            this.$popup(PhotoViewer, options);
        },
    },
};
</script>

<style scoped>
.about-shop-page__section {
    padding: var(--base-card-padding);
}

@media (min-width: 1281px) {
    .about-shop-page__content {
        max-width: 1000px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .about-shop-page__content {
        max-width: 780px;
    }
}

.map {
    position: relative;
}
@media (min-width: 768px) {
    .map {
        height: 600px;
    }

    .map_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .map_desktop {
        display: none;
    }
}

.photos-list__item {
    position: relative;
    flex-shrink: 0;
    border-radius: var(--border-radius);
    overflow: hidden;
}
.photos-list__item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-active-bg);
    opacity: 0;
    transition: opacity var(--transition);
}
.photos-list__item:hover::before,
.photos-list__item:focus::before {
    opacity: .4;
}

.photos-list__item:not(:last-child) {
    margin-right: var(--grid-gap);
}

.photos-list__img {
    height: 160px;
}
</style>