export default {
    namespaced: true,

    state: () => ({
        brand: {},
        initialized: false,

        productsTotalCount: 0,
        productsDefaultFacets: {},
        partsTotalCount: 0,
        partsDefaultFacets: {},
    }),

    getters: {
        firstRoute: state => {
            if (state.productsTotalCount) {
                return 'market-brand-products';
            }
            else if (state.partsTotalCount) {
                return 'market-brand-parts';
            }
            else {
                return 'market-brand-products';
            }
        },

        availableRoutes: state => {
            const routes = [];

            if (state.productsTotalCount) {
                routes.push('market-brand-products');
            }

            if (state.partsTotalCount) {
                routes.push('market-brand-parts');
            }

            return routes;
        },
    },

    mutations: {
        setBrand(state, brand) {
            state.brand = brand;
        },

        setProductsTotalCount(state, { current_count, facets }) {
            state.productsTotalCount = current_count;
            state.productsDefaultFacets = facets;
        },

        setPartsTotalCount(state, { current_count, facets }) {
            state.partsTotalCount = current_count;
            state.partsDefaultFacets = facets;
        },

        changeFavorite(state, value) {
            state.brand.is_favorite = value;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.brand = {};
            state.initialized = false;

            state.productsTotalCount = 0;
            state.productsDefaultFacets = {};
            state.partsTotalCount = 0;
            state.partsDefaultFacets = {};
        },
    },

    actions: {
        async init({ commit, dispatch }, { codename }) {
            try {
                const brand = await dispatch('getBrand', { codename });

                const [products, parts] = await Promise.all(
                    [
                        dispatch('getProductsTotalCount', { id: brand.id }),
                        dispatch('getPartsTotalCount', { id: brand.id }),
                    ],
                );

                commit('setBrand', brand);
                commit('setProductsTotalCount', products);
                commit('setPartsTotalCount', parts);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { brand, products, parts };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getBrand({}, { codename }) {
            return await this.$api.brands.get(codename);
        },

        async getProductsTotalCount({ rootState }, { id }) {
            const params = {
                brand: id,
                get_facets: 'all',
                limit: 0,
                offset: 0,
                city: rootState.cities.currentCityId,
            };

            return await this.$api.esProducts.get({ params });
        },

        async getPartsTotalCount({ rootState }, { id }) {
            const params = {
                brand: id,
                get_facets: 'all',
                limit: 0,
                offset: 0,
                city: rootState.cities.currentCityId,
            };

            return await this.$api.partProductsDoc.get({ params });
        },
    },
};