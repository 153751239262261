export default ({ HTTP }) => ({
    async get(...args) {
        let config;
        let uuid;

        if (args && args.length) {
            if (typeof args[0] === 'string') {
                uuid = args[0];
                config = args[1];
            }
            else {
                config = args[0];
            }
        }

        if (!config) config = {};

        if (uuid) {
            const url = `/insurance_offer/${ uuid }/`;
            const response = await HTTP.get(url);
            return response.data;
        }
        else {
            const url = '/insurance_offer/';

            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async getCount() {
        const params = { limit: 0 };
        const config = { params };
        const url = '/insurance_offer/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async setActive(uuid) {
        const url = `/insurance_offer/${ uuid }/set_active/`;
        const response = await HTTP.post(url);
        return response.data;
    },

    async order(uuid, data) {
        const url = `/insurance_offer/${ uuid }/order/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async cancel(uuid) {
        const url = `/insurance_offer/${ uuid }/cancel/`;
        const response = await HTTP.patch(url);
        return response.data;
    },
});