<template>
    <component
        :is="tag"
        v-bind="attrs"
        v-if="brand"
        class="brand-short-info"
        :class="{
            'brand-short-info_base': !row && !border,
            'brand-short-info_row': row && !border,
            'brand-short-info_row_mobile': mobileRow && !border,
            'brand-short-info_border': !row && border,
        }"
        @click="showBrandPopup"
    >
        <img
            v-if="!disableLogo"
            :src="brandLogoSrc"
            alt=""
            class="logo"
            :class="{
                'logo_base': !row && !border,
                'logo_row': row && !border,
                'logo_row_mobile': mobileRow && !border,
                'logo_border': !row && border,
                'logo_mobile_hidden': logoMobileHidden
            }"
            width="54"
            height="36"
        >
        <div>
            <p
                v-if="border"
                class="label"
            >
                Производитель
            </p>
            <p class="text">
                <span class="name">{{ brand.name }}</span><template v-if="brand.country">
                    <span v-if="row || mobileRow || border" class="comma">,</span> <br v-if="!row && !border" :class="[mobileRow && 'br_desktop']"> {{ brand.country }}
                </template>
            </p>
        </div>
    </component>
</template>

<script>
const BrandInfoPopup = () => import('@/components/popups/BrandInfoPopup.vue');

export default {
    name: "BrandShortInfo",

    props: {
        row: {
            type: Boolean,
            default: false,
        },
        mobileRow: {
            type: Boolean,
            default: false,
        },

        big: {
            type: Boolean,
            default: false,
        },

        border: {
            type: Boolean,
            default: false,
        },

        brand: {
            type: Object,
            default() {
                return {};
            },
        },

        disableLogo: {
            type: Boolean,
            default: false,
        },

        logoMobileHidden: {
            type: Boolean,
            default: false,
        },

        isLink: Boolean,
    },

    computed: {
        tag() {
            if (this.isLink) return 'RouterLink';
            return 'button';
        },

        attrs() {
            if (this.isLink) {
                return {
                    to: {
                        name: 'market-brand',
                        params: {
                            codename: this.brand.codename,
                        },
                    },
                };
            }
            return {};
        },

        brandLogoSrc() {
            const coverCodename = this.border ?  'brand_logo_medium' : 'brand_logo_small';
            return (this.brand.logo || {}).original_url
                ? this.$links.uploads + this.brand.logo.thumbnails[coverCodename]
                : require('../assets/images/logo-placeholder.svg');
        },
    },

    methods: {
        showBrandPopup() {
            if (!this.isLink) {
                const props = {
                    codename: this.brand.codename,
                };
                const options = { props };
                this.$popup(BrandInfoPopup, options);
            }
        },
    },
};
</script>

<style scoped>
.brand-short-info {
    align-items: center;
    text-align: left;
}

.brand-short-info:hover .name,
.brand-short-info:focus .name {
    color: var(--primary-color);
}

.brand-short-info_base {
    display: flex;
    width: 200px;
    flex-shrink: 0;
}

.brand-short-info_row {
    display: flex;
}

@media (min-width: 1040px) {
    .brand-short-info_row_mobile .comma {
        display: none;
    }
}
@media (max-width: 1039px) {
    .brand-short-info_row_mobile {
        display: flex;
        width: 100%;
    }
}

.brand-short-info_border {
    display: inline-flex;
    padding: 16px;
    margin-bottom: 36px;
    border: 1px solid var(--border-light-c);
    box-sizing: border-box;
    border-radius: var(--border-radius-x2);
}

.label {
    margin-bottom: 4px;
    color: var(--font-secondary-color);
}

.logo {
    flex-shrink: 0;
    height: auto;
}

.logo_base {
    width: 54px;
    margin-right: 16px;
}

.logo_row {
    width: 54px;
    margin-right: 8px;
}
@media (max-width: 1039px) {
    .logo_row_mobile {
        width: 54px;
        margin-right: 16px;
    }
}

.logo_border {
    width: 72px;
    margin-right: 16px;
}

@media (max-width: 767px) {
    .logo_mobile_hidden {
        display: none;
    }
}

.name {
    font-family: var(--f-bold);
    transition: color var(--transition);
}

@media (max-width: 1039px) {
    .br_desktop {
        display: none;
    }
}
</style>