import isEmpty from '@/lib/isEmpty.js';
import {
    date,
    datetime,
    email,
    licensePlate,
    number,
    password,
    phone,
    time,
    url,
    year,
} from '@/components/_form/utils/regs.js';
import equals from '@/lib/equals.js';

// true === invalid
// false === isValid

// TODO: добавить возможность создавать свои правила в formManagerOptions
export default {
    required: ({ value, ref, params, index }) => {
        return isEmpty(value);
    },

    email: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !email.test(value);
    },

    phone: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !phone.test(value);
    },

    url: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !url.test(value);
    },

    date: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !date.test(value);
    },

    datetime: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !datetime.test(value);
    },

    time: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !time.test(value);
    },

    year: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !year.test(value);
    },

    licensePlate: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !licensePlate.test(value);
    },

    number: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !number.test(value);
    },

    password: ({ value }) => {
        return isEmpty(value) ? false : !password.test(value);
    },

    minLength: ({ value, ref, params, index }) => {
        if (isEmpty(value)) return false;

        const [min] = params;
        const minValue = Number(min);

        if (Array.isArray(value) || typeof value === 'string') {
            return minValue > value.length;
        }

        // если не массив и не строка, то тогда это дата или число
        return minValue > value;
    },

    alphanumeric: ({ value, ref, params, index }) => {
        return isEmpty(value) ? false : !/^[\w]*$/.test(value);
    },

    min: ({ value, params, type }) => {
        // может быть больше или равно
        if (isEmpty(value)) return false;
        let [min] = params;

        if (type === 'calendar' || type === 'date') {
            const valueIsValid = date.test(value);
            const minIsValid = !isNaN(new Date(min));

            if (valueIsValid && minIsValid) {
                const valueDate = new Date(value.replace(/(\d{2}).(\d{2}).(\d{4})/, '$3-$2-$1'));
                const minDate = new Date(min);

                return valueDate < minDate;
            }
            else {
                if (!minIsValid && process.env.NODE_ENV === 'development') {
                    console.warn('Значение min не соответствует формату iso');
                }

                return false;
            }
        }

        return value < min;
    },

    max: ({ value, params, type }) => {
        // может быть меньше или равно
        if (isEmpty(value)) return false;
        let [max] = params;

        if (type === 'calendar' || type === 'date') {
            const valueIsValid = date.test(value);
            const maxIsValid = !isNaN(new Date(max));

            if (valueIsValid && maxIsValid) {
                const valueDate = new Date(value.replace(/(\d{2}).(\d{2}).(\d{4})/, '$3-$2-$1'));
                const maxDate = new Date(max);

                return valueDate > maxDate;
            }
            else {
                if (!maxIsValid && process.env.NODE_ENV === 'development') {
                    console.warn('Значение max не соответствует формату iso');
                }

                return false;
            }
        }

        return value > max;
    },

    sameAs: ({ value, params, ref }) => {
        const [key] = params;
        const value2 = ref(key);
        return isEmpty(value) ? false : !equals(value, value2);
    },
};