<template>
    <div
        :class="[
            onDarkBg && 'schedule-wrap_on-dark',
            onLightBg && !onDarkBg && 'schedule-wrap_on-light',
        ]"
    >
        <div class="schedule schedule_work">
            <div
                v-for="(item, index) in computedWorkSchedule"
                :key="`day-${ index }`"
                class="schedule-row"
            >
                <div class="schedule-td">
                    {{ item.key }}
                </div>
                <div
                    v-if="item.is_day_off"
                    class="schedule-td text_red"
                >
                    Выходной
                </div>
                <div
                    v-else
                    class="schedule-td"
                >
                    {{ item.start_time.slice(0,-3) }} – {{ item.end_time.slice(0,-3) }}
                </div>
            </div>
        </div>

        <div
            v-if="holidays.length"
            class="schedule schedule_holidays"
        >
            <div
                v-for="(item, index) in holidays"
                :key="`day-${ index }`"
                class="schedule-row"
            >
                <div class="schedule-td">
                    {{ item.day | dt }}
                </div>
                <div
                    v-if="item.is_day_off"
                    class="schedule-td"
                >
                    Выходной
                </div>
                <div
                    v-else
                    class="schedule-td"
                >
                    {{ item.start_time.slice(0, -3) }} – {{ item.end_time.slice(0, -3) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { weekDays } from '@/lib/datetime/names.js';

export default {
    name: 'ShopWorkSchedule',

    props: {
        work: {
            type: Array,
            default: () => ([]),
        },

        holidays: {
            type: Array,
            default: () => ([]),
        },

        onDarkBg: Boolean,
        onLightBg: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        isAllDaysSimilar() {
            const startTimeArr = this.work.map(item => item.start_time);
            const endTimeArr = this.work.map(item => item.end_time);
            const allStartTimeEqual = startTimeArr.every(v => v === startTimeArr[0]);
            const allEndTimeEqual = endTimeArr.every(v => v === endTimeArr[0]);
            return allStartTimeEqual && allEndTimeEqual;
        },

        isWorkDaysSimilar() {
            const workdays = this.work.slice(0, 5);
            const startTimeArr = workdays.map(item => item.start_time);
            const endTimeArr = workdays.map(item => item.end_time);
            const allStartTimeEqual = startTimeArr.every(v => v === startTimeArr[0]);
            const allEndTimeEqual = endTimeArr.every(v => v === endTimeArr[0]);
            return allStartTimeEqual && allEndTimeEqual;
        },

        isWeekendDaysSimilar() {
            const weekendDays = this.work.slice(5, 7);
            const startTimeArr = weekendDays.map(item => item.start_time );
            const endTimeArr = weekendDays.map(item => item.end_time );
            const allStartTimeEqual = startTimeArr.every(v => v === startTimeArr[0]);
            const allEndTimeEqual = endTimeArr.every(v => v === endTimeArr[0]);
            return allStartTimeEqual && allEndTimeEqual;
        },

        computedWorkSchedule() {
            const schedule = [];

            if (this.isAllDaysSimilar) {
                schedule.push({
                    key: 'Ежедневно',
                    start_time: this.work[0].start_time,
                    end_time: this.work[0].end_time,
                    is_day_off: this.work[0].is_day_off,
                });
            }
            else {
                if (this.isWorkDaysSimilar) {
                    schedule.push({
                        key: 'Пн-Пт',
                        start_time: this.work[0].start_time,
                        end_time: this.work[0].end_time,
                        is_day_off: this.work[0].is_day_off,
                    });
                }

                if (!this.isWorkDaysSimilar && this.isWeekendDaysSimilar) {
                    const workdays = this.work.slice(0, 5);
                    workdays.forEach(item => {
                        schedule.push({
                            key: weekDays[item.day - 1],
                            start_time: item.start_time,
                            end_time: item.end_time,
                            is_day_off: item.is_day_off,
                        });
                    });
                }

                if (this.isWeekendDaysSimilar) {
                    schedule.push({
                        key: 'Сб-Вс',
                        start_time: this.work[6].start_time,
                        end_time: this.work[6].end_time,
                        is_day_off: this.work[6].is_day_off,
                    });
                }

                if (this.isWorkDaysSimilar && !this.isWeekendDaysSimilar) {
                    const weekendDays = this.work.slice(5, 7);
                    weekendDays.forEach(item => {
                        schedule.push({
                            key: weekDays[item.day - 1],
                            start_time: item.start_time,
                            end_time: item.end_time,
                            is_day_off: item.is_day_off,
                        });
                    });
                }

                if (!this.isWorkDaysSimilar && !this.isWeekendDaysSimilar) {
                    this.work.forEach(item => {
                        schedule.push({
                            key: weekDays[item.day - 1],
                            start_time: item.start_time,
                            end_time: item.end_time,
                            is_day_off: item.is_day_off,
                        });
                    });
                }
            }

            return schedule;
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .schedule {
        width: 100%;
        max-width: 380px;
    }
}

.schedule_holidays {
    margin-top: 16px;
    color: var(--primary-color);
}

.schedule-row {
    display: flex;
}

.schedule-row:not(:last-child) {
    margin-bottom: 8px;
}

.schedule-row::after {
    content: "";
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 5px;
    order: 2;
    align-self: flex-end;
    flex-grow: 1;
    border-bottom: 1px dotted;
}

.schedule-wrap_on-dark .schedule-row::after {
    border-bottom-color: var(--font-secondary-dark-color);
}

.schedule-wrap_on-light .schedule-row::after {
    border-bottom-color: var(--border-dark-c);
}

.schedule-td:first-child {
    order: 1;
}

.schedule-td:last-child {
    order: 3;
}
</style>