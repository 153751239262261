<template>
    <div class="insurance-osago-offers">
        <BackLink
            v-if="!calculateIsCompleted || offers.length"
            :to="{
                name: hasOrder ? 'insurance-osago-edit' : 'insurance-osago-request-step-4',
                params: {
                    uuid,
                },
            }"
            :text="hasOrder ? 'Изменить данные' : 'Вернуться к заявке'"
            class="back-link"
            @click.native="handleYandexMetrikaGoalClick"
        ></BackLink>

        <h1 class="h1 h1_text insurance-osago__title">
            Варианты стоимости ОСАГО
        </h1>

        <p class="descriptor">
            Мы&nbsp;{{ calculateIsCompleted ? 'рассчитали' : 'рассчитываем' }} стоимость ОСАГО в&nbsp;возможных страховых компаниях. Стоимость полиса зависит от&nbsp;КБМ.
            <ButtonText
                dashed
                secondary
                dark
                @click="showBonusMalusPopup"
            >Подробнее</ButtonText>
        </p>

        <div
            v-if="!calculateIsCompleted || offersComputed.length"
            class="insurance-options"
        >
            <InsuranceOffer
                v-for="offer in offersComputed"
                :key="'insurance-option-' + offer.company.code"
                :offer="offer"
                :activeOffer="activeOffer"
                :loading="activateLoading"
                :calculateIsCompleted="calculateIsCompleted"
                @select="selectOffer(offer)"
            ></InsuranceOffer>
        </div>

        <EmptyBlock
            v-if="!offersComputed.length && calculateIsCompleted"
            icon="empty"
            title="Расчёт недоступен"
            text="Не получилось рассчитать стоимость полиса ОСАГО на ваш автомобиль, попробуйте изменить данные к заявке."
        >
            <template #button>
                <ButtonBlock
                    :to="{
                        name: 'insurance-osago-request-step-4',
                        params: {
                            uuid,
                        }
                    }"
                    primary
                    low
                    class="empty__btn"
                >
                    Вернуться к заявке
                </ButtonBlock>
            </template>
        </EmptyBlock>

        <InsuranceFactoids></InsuranceFactoids>
    </div>
</template>

<script>
// utils
import { mapState, mapGetters, mapActions } from 'vuex';
import insuranceOsago from '@/store/modules/insuranceOsago.js';
// use
import useCheckRouteMethods from '@/pages/insurance/osago/_uuid/useCheckRouteMethods.js';
// mixins
import YandexMetrika from '@/mixins/yandex-metrika.js';
import lastPathMixin from '@/pages/insurance/osago/_uuid/lastPathMixin.js';
// components
import BackLink from '@/components/project-buttons/BackLink.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import InsuranceOffer from '../InsuranceOffer.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InsuranceFactoids from '@/components/UFactoids/presets/InsuranceFactoids.vue';
const BonusMalusPopup = () => import('@/pages/insurance/BonusMalusPopup.vue');


export default {
    name: 'InsuranceOsagoOffersPage',

    components: {
        InsuranceFactoids,
        ButtonBlock,
        ButtonText,
        InsuranceOffer,
        BackLink,
        EmptyBlock,
    },

    mixins: [
        YandexMetrika,
        lastPathMixin,
    ],

    metaInfo() {
        return {
            title: 'Варианты стоимости ОСАГО',
        };
    },

    serverPrefetch() {
        this.$store.registerModule('insuranceOsago', insuranceOsago);

        return this.serverPrefetch({ uuid: this.uuid, requiredOffers: true });
    },

    data() {
        return {
            activateLoading: false,
            activeOffer: null,
        };
    },

    computed: {
        ...mapState({
            contract: state => state.insuranceOsago.contract,
            calculateIsCompleted: state => state.insuranceOsago.calculateIsCompleted,
            companies: state => state.insuranceOsago.companies,
        }),

        ...mapGetters({
            offersByCompany: 'insuranceOsago/offersByCompany',
            filteredOffers: 'insuranceOsago/filteredOffers',
        }),

        uuid() {
            return this.$route.params.uuid;
        },

        hasOrder() {
            return this.contract.has_order;
        },

        offers() {
            return this.contract.offers;
        },

        company() {
            return this.$route.query.company;
        },

        hasCompany() {
            return (this.companies || []).map(company => company.code).indexOf(this.company) !== -1;
        },

        offersComputed() {
            return (this.companies || []).map(company => {
                return Object.assign({}, (this.offersByCompany[company.code] || {}), { company });
            }).sort((a, b) => {
                // в самом вверху компания,
                // коднейм которой указан в query
                if (this.hasCompany && a.company.code === this.company) {
                    return -1;
                }
                else if (this.hasCompany && b.company.code === this.company) {
                    return 1;
                }
                // далее компании, у которых есть цена
                else if (a.price && !b.price) {
                    return -1;
                }
                else if (!a.price && b.price) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        },
    },

    watch: {
        '$route.params.uuid': {
            handler() {
                this.createBreadcrumbs();
            },
        },
    },

    beforeMount() {
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago, { preserveState: true });
        }
    },

    mounted() {
        this.createBreadcrumbs();
        this.init();
    },

    methods: {
        ...useCheckRouteMethods(),
        ...mapActions({
            startPolling: 'insuranceOsago/startPollingOffers',
            order: 'insuranceOsago/order',
            serverPrefetch: 'insuranceOsago/serverPrefetch',
            browserPrefetch: 'insuranceOsago/browserPrefetch',
            updateActiveOffer: 'insuranceOsago/updateActiveOffer',
        }),

        async init() {
            await this.browserPrefetch({ uuid: this.uuid, requiredOffers: true });

            await this.checkUuid();

            if (this.calculateIsCompleted) return;

            this.startPolling();
        },

        createBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'insurance-osago',
                    },
                    title: 'Автострахование',
                },
                {
                    to: {
                        name: this.$route.name,
                        params: {
                            uuid: this.uuid,
                        },
                    },
                    title: 'Калькулятор стоимости ОСАГО',
                },
            ]);
        },

        showBonusMalusPopup() {
            const getMaxBy = (arr, key) => {
                return Object.entries(arr.reduce((acc, item) => {
                    const value = item[key];
                    acc[value] = acc[value] ? acc[value] + 1 : 1;
                    return acc;
                }, {})).reduce((acc, item) => item[1] > (acc[1] || 0) ? item : acc, [])[0];
            };

            const k = this.offers.length ? getMaxBy(this.offers, 'kbm_class') : '';

            const props = { k: k ? k : '' };
            const options = { props };
            this.$popup(BonusMalusPopup, options);
        },

        async selectOffer(offer) {
            this.activeOffer = offer;
            this.activateLoading = true;

            try {
                await this.updateActiveOffer(offer);

                if (this.hasOrder) {
                    await this.order();

                    await this.$router.push({
                        name: 'insurance-osago-confirmation',
                        params: {
                            uuid: this.uuid,
                        },
                    });
                }
                else {
                    await this.$router.push({
                        name: 'insurance-osago-checkout',
                        params: {
                            uuid: this.uuid,
                        },
                    });
                }
            }
            catch (error) {
                //
            }
            finally {
                this.activateLoading = false;
                this.activeOffer = null;
            }
        },

        handleYandexMetrikaGoalClick() {
            if (!this.hasOrder) this.handleYandexMetrikaGoal('osago_back_to_request');
        },
    },
};
</script>

<style scoped>
.back-link {
    margin-top: 24px;
}
@media (min-width: 1281px) {
    .back-link {
        margin-bottom: 8px;
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .back-link {
        margin-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .back-link {
        margin-bottom: 36px;
    }
}

@media (min-width: 768px) {
    .insurance-osago__title {
        text-align: center;
    }
}

.descriptor {
    margin-bottom: var(--gap-y-mini);
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .descriptor {
        max-width: 580px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

@media (min-width: 1040px) {
    .insurance-options {
        width: 940px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>