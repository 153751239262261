import deliveryOffices from '@/store/modules/deliveryOffices.js';


const getBreadcrumbs = category => {
    return [
        {
            to: {
                name: 'market',
            },
            title: 'Запчасти и автотовары',
        },
        {
            to: {
                name: 'market-products-catalog',
            },
            title: 'Каталог автотоваров',
        },
        ...category.parents.map(category => {
            return {
                to: {
                    name: 'market-products-category',
                    params: {
                        slug: category.slug,
                    },
                },
                title: category.name,
            };
        }),
        {
            to: {
                name: 'market-products-category',
                params: {
                    slug: category.slug,
                },
            },
            title: category.name,
        },
    ];
};

export default {
    namespaced: true,

    modules: {
        deliveryOffices,
    },

    state: () => ({
        initialized: false,
        product: {},
        breadcrumbs: [],
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.initialized = false;
            state.product = {};
            state.breadcrumbs = [];
        },

        setProduct(state, product) {
            state.product = product;
        },

        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },

        changeFavorite(state, value) {
            state.product.is_favorite = value;
        },
    },

    actions: {
        async init({ commit, dispatch }, { id }) {
            try {
                const [product, delivery] = await Promise.all(
                    [
                        dispatch('getProduct', { id }),
                        dispatch('deliveryOffices/getItems'),
                        dispatch('viewsHistory/get', null, { root: true }),
                    ],
                );

                product.delivery = delivery;

                const category = await dispatch('getCategory', product.category);
                const breadcrumbs = getBreadcrumbs(category);

                commit('setProduct', product);
                commit('setBreadcrumbs', breadcrumbs);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { product, breadcrumbs };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getProduct({}, { id }) {
            return await this.$api.products.get(id);
        },

        async updateProduct({ dispatch, commit }, { id }) {
            try {
                const product = await dispatch('getProduct', { id });
                commit('setProduct', product);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getCategory({}, { slug }) {
            return await this.$api.goodsCategories.get(slug);
        },
    },
};