<template>
    <transition name="catalog">
        <div v-if="isOpen" class="catalog">
            <div
                class="catalog__backdrop"
                :class="{
                    'catalog__backdrop_transparent': hideBackdrop,
                }"
                @click="close"
            ></div>

            <div class="catalog-screen">
                <div class="catalog-screen__head">
                    <div class="second-view__head-btn-wrap">
                        <ButtonIcon
                            v-if="parent.parentCategoryCodename"
                            small
                            :secondary="false"
                            :icon="{
                                name: 'arrow-left',
                                big: true,
                            }"
                            type="button"
                            class="second-view__head-btn second-view__head-btn_back"
                            @click="back"
                        ></ButtonIcon>
                    </div>

                    <h3 class="text_big-fz text_bold">
                        {{ parent.title }}
                    </h3>

                    <div class="second-view__head-btn-wrap">
                        <ButtonIcon
                            small
                            :secondary="false"
                            :icon="{
                                name: 'cross',
                                big: true,
                                secondary: true,
                            }"
                            type="button"
                            class="second-view__head-btn second-view__head-btn_close"
                            @click="close"
                        ></ButtonIcon>
                    </div>
                </div>

                <div class="catalog-screen__body">
                    <ol
                        v-if="!loading"
                        class="catalog__section"
                    >
                        <li
                            v-for="category in section"
                            class="catalog__item"
                        >
                            <RouterLink
                                v-if="category.is_leaf"
                                :to="category.to"
                                class="catalog__link"
                            >
                                <UIcon
                                    v-if="category.icon"
                                    :name="category.icon"
                                    big
                                    class="mr-12"
                                ></UIcon>

                                <span class="catalog__link-text">
                                    {{ category.title }}
                                </span>
                            </RouterLink>
                            <button
                                v-else
                                class="catalog__link"
                                @click="onSelectCategory(category)"
                            >
                                <UIcon
                                    v-if="category.icon"
                                    :name="category.icon"
                                    big
                                    class="mr-12"
                                ></UIcon>

                                <span class="catalog__link-text">
                                    {{ category.title }}
                                </span>

                                <UIcon
                                    v-if="category.children && category.children.length"
                                    name="arrow-right"
                                    small
                                    class="catalog__link-arrow"
                                ></UIcon>
                            </button>
                        </li>
                    </ol>
                    <USpinner
                        v-if="loading"
                        absoluteCenter
                        big
                    ></USpinner>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';

export default {
    name: 'MobileUI',

    components: {
        ButtonIcon,
        UIcon,
        USpinner,
    },

    props: {
        isOpen: Boolean,
        section: {
            type: Array,
            default: () => [],
        },
        parent: {
            type: Object,
            default: () => {},
        },
        loading: Boolean,
        hideBackdrop: Boolean,
    },

    methods: {
        close() {
            this.$emit('close');
        },

        back() {
            this.$emit('back', this.parent);
        },

        onSelectCategory(category) {
            this.$emit('selectCategory', category);
        },
    },
};
</script>

<style scoped>
.catalog-enter,
.catalog-leave-to {
    transition: all var(--transition);
}
.catalog-enter .catalog-screen,
.catalog-leave-to .catalog-screen {
    transform: translateX(-100%);
}
.catalog-enter .catalog__backdrop,
.catalog-leave-to .catalog__backdrop {
    opacity: 0;
}

.catalog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--menu-z-index);
    width: 100%;
    height: 100%;
}

.catalog-screen {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--light-c);
    overflow: auto;
    transition: transform var(--transition);
}
@media (min-width: 768px) {
    .catalog-screen {
        width: 420px;
    }
}
@media (max-width: 767px) {
    .catalog-screen {
        width: calc(var(--col-5) + var(--content-gap));
    }
}

.catalog-screen__head {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 16px 20px;
    border-bottom: 1px solid var(--border-light-c);
}

.second-view__head-btn-wrap {
    width: 24px;
    height: 24px;
}

.catalog-screen__body {
    position: relative;
    padding: 8px 20px;
    flex-grow: 1;
    overflow: auto;
}

.catalog__item:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}

.catalog__link {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    padding: 12px 0;
    font-family: var(--f-semi-bold);
    text-align: left;
    transition: background-color var(--transition);
}

.catalog__link-text {
    flex-grow: 1;
}

.catalog__link-arrow {
    margin-left: 12px;
    margin-right: 4px;
    fill: var(--font-secondary-light-color);
}
.catalog__link:hover .catalog__link-arrow,
.catalog__link:focus .catalog__link-arrow {
    fill: var(--dark-c);
}

.catalog__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity var(--transition);
}
.catalog__backdrop_transparent {
    background-color: transparent;
}
</style>