<template>
    <UIconButton
        name="info"
        small
        secondary
        class="u-tooltip-icon"
        v-bind="$attrs"
        v-on="$listeners"
    ></UIconButton>
</template>

<script>
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';


export default {
    name: 'UTooltipIcon',
    components: { UIconButton },
    inheritAttrs: false,
};
</script>

<style>
.u-tooltip-icon.u-icon-button.u-icon-button_secondary:hover:not(.u-icon-button_disabled) .u-icon-button__icon,
.u-tooltip-icon.u-icon-button.u-icon-button_secondary:active:not(.u-icon-button_disabled) .u-icon-button__icon,
.u-tooltip-icon.u-icon-button.u-icon-button_secondary.u-icon-button_active:not(.u-icon-button_disabled) .u-icon-button__icon {
    fill: var(--primary-color);
}
</style>