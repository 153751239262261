<template>
    <PreviewComponent>
        <template #trigger>
            <component
                :is="trigger.tag"
                v-bind="trigger.attrs"
                class="preview-trigger"
                :class="{
                    'preview-trigger_active': isOpen
                }"
            >
                <UIcon
                    name="cart-stroke"
                    big
                    class="preview-trigger__icon"
                ></UIcon>

                <UCircleLabelCounter
                    :count="cartTotalCount"
                    class="preview-trigger__counter"
                ></UCircleLabelCounter>

                <span v-if="!noTriggerText" class="preview-trigger__text">Корзина</span>
            </component>
        </template>

        <template #content>
            <div class="layer-2">
                <div v-if="!cartTotalCount" class="placeholder">
                    <PlaceholderIcon
                        icon="cart-stroke"
                        size="small"
                        class="mr-24"
                    ></PlaceholderIcon>

                    <div>
                        <h4 class="text_bold mb-4">
                            Ваша корзина пуста
                        </h4>
                        <p class="text_base-lh">Чтобы подобрать подходящие товары, воспользуйтесь поиском или каталогом.</p>
                    </div>
                </div>

                <div v-if="cartTotalCount" class="preview__body">
                    <ul class="orders-list">
                        <li
                            v-for="(order, orderIndex) in orders"
                            :key="'order' + order.shop.id + orderIndex"
                            class="order"
                        >
                            <div class="shop mb-20">
                                <ShopName
                                    :shop="order.shop"
                                    class="shop__name mb-4"
                                ></ShopName>
                                <ShopAddress
                                    :shop="order.shop"
                                    :showOnMap="false"
                                ></ShopAddress>
                            </div>

                            <MarketItemPreviewCart
                                v-for="(marketItem, marketItemIndex) in order.positions"
                                :key="'order-market-item' + marketItem.offer.id + marketItemIndex"
                                :item="marketItem"
                                :modelName="marketItem.offer.offer_type"
                                class="order__position"
                            ></MarketItemPreviewCart>
                        </li>
                    </ul>
                </div>

                <div v-if="cartTotalCount" class="preview__footer">
                    <ButtonText
                        v-if="availableCount === 0"
                        dashed
                        secondary
                        dark
                        @click="clearCart"
                    >
                        Очистить корзину
                    </ButtonText>

                    <p
                        v-if="availableCount > 0"
                        class="preview__total"
                    >
                        <span>Итого:</span><b>{{ price | price }}</b>
                    </p>
                    <ButtonBlock
                        v-if="availableCount > 0"
                        :to="{
                            name: 'cart'
                        }"
                        primary
                        low
                    >
                        Перейти в корзину
                    </ButtonBlock>
                </div>
            </div>
        </template>
    </PreviewComponent>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MarketItemPreviewCart from "../MarketItemPreviewCart.vue";
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import PlaceholderIcon from '@/components/PlaceholderIcon.vue';
import PreviewComponent from '@/components/header/PreviewComponent.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import UCircleLabelCounter from '@ui/components/UBadge/UBadge.vue';
import ShopName from '@/components/ShopName.vue';
import ShopAddress from '@/components/ShopAddress.vue';
import breakpointKey from '@/mixins/breakpointKey.js';

export default {
    name: 'CartPreview',

    components: {
        ShopAddress,
        ShopName,
        UCircleLabelCounter,
        UIcon,
        PreviewComponent,
        PlaceholderIcon,
        ButtonText,
        ButtonBlock,
        MarketItemPreviewCart,
    },

    mixins: [breakpointKey],

    props: {
        noTriggerText: Boolean,
    },

    data() {
        return {
            isOpen: false,
        };
    },

    computed: {
        ...mapState({
            orders: state => state.cart.items,
            availableCount: state => state.cart.availableCount,
            cartTotalCount: state => state.cart.totalCount,
            price: state => state.cart.availablePrice,
        }),

        trigger() {
            if (this.breakpointKey === 'm') {
                if (this.cartTotalCount) {
                    return {
                        tag: 'button',
                        attrs: {},
                    };
                }
                else {
                    return {
                        tag: 'RouterLink',
                        attrs: {
                            to: {
                                name: 'cart',
                            },
                            'aria-label': 'Перейти в корзину',
                        },
                    };
                }
            }
            else {
                return {
                    tag: 'RouterLink',
                    attrs: {
                        to: {
                            name: 'cart',
                        },
                        'aria-label': 'Перейти в корзину',
                    },
                };
            }
        },
    },

    methods: {
        ...mapActions({
            UPDATE_CART: 'cart/getCart',
            CLEAR_CART: 'cart/clearCart',
        }),

        clearCart() {
            this.CLEAR_CART();
        },
    },
};
</script>

<style scoped>
.preview__body {
    width: 680px;
    max-height: 600px;
    padding: 20px 24px;
    overflow: auto;
}

.shop__name {
    font-size: var(--base-fz);
}

.order:not(:first-child) {
    margin-top: 20px;
}
.order:not(:last-child) {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-light-c);
}

.order__position {
    padding-bottom: 20px;
}
.order:last-child .order__position:last-child {
    padding-bottom: 0;
}

.preview__footer {
    border-top: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .preview__footer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        padding: 20px 24px;
    }
}
@media (max-width: 767px) {
    .preview__footer {
        padding: 20px var(--content-gap);
    }
}

.preview__total {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
@media (max-width: 767px) {
    .preview__total {
        margin-bottom: 20px;
        justify-content: space-between;
    }
}

.preview__total b {
    margin-left: 20px;
    font-size: 20px;
}

.placeholder {
    width: 380px;
    padding: 20px 24px;
    display: flex;
    align-items: center;
}

.preview-trigger {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    transition: color var(--transition);
}

.preview-trigger_active,
.preview-trigger:hover,
.preview-trigger:focus {
    color: var(--primary-color);
}

.preview-trigger__icon {
    display: block;
    fill: currentColor;
}

.preview-trigger__text {
    display: block;
    margin-left: 8px;
    font-size: var(--small-fz);
    font-family: var(--f-semi-bold);
}

.preview-trigger__counter {
    position: absolute;
    top: 0;
    left: 18px;
}
</style>