var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.initialized)?_c('Spinner',{attrs:{"size":"big","center":""}}):_c('MarketItemsListWithOffers',{attrs:{"apiName":"products","modelName":"product","items":_vm.products.items,"amount":_vm.products.current_count,"totalAmount":_vm.productsTotalCount,"staticFilters":_vm.staticFilters,"dynamicFilters":_vm.dynamicFilters,"checkedFilters":_vm.checkedFilters,"filterData":_vm.filterData,"sortData":_vm.sortData,"sortOptions":_vm.sortOptions,"hasNext":_vm.hasNext,"loading":_vm.loading},on:{"changeFilter":_vm.onChangeFilter,"resetFilters":_vm.onResetFilters,"deleteFilter":_vm.onDeleteFilter,"changeSort":_vm.onChangeSort,"reload":_vm.reload,"load":_vm.load,"changeFavorite":_vm.onChangeFavorite},scopedSlots:_vm._u([{key:"listItem",fn:function(ref ){
var item = ref.item;
var itemIndex = ref.itemIndex;
return [_c('MarketItemRowListItem',{attrs:{"item":item,"modelName":"product","noBrand":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChangeFavorite.apply(void 0, [ itemIndex ].concat( argsArray ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(item, itemIndex)}}})]}},{key:"gridItem",fn:function(ref ){
var item = ref.item;
var itemIndex = ref.itemIndex;
return [_c('MarketItemColumn',{attrs:{"item":item,"apiName":"product","modelName":"product","noBrand":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChangeFavorite.apply(void 0, [ itemIndex ].concat( argsArray ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(item, itemIndex)}}})]}},{key:"inStockEmpty",fn:function(){return [_c('EmptyBlock',{attrs:{"icon":"empty-box","title":"Нет автотоваров","btnText":"Перейти в каталог","btnUrl":{
                name: 'market',
            }},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("\n                От производителя "),_c('span',{staticClass:"text_bold"},[_vm._v(_vm._s(_vm.brand.name))]),_vm._v(" нет актуальных предложений.\n            ")]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }