export default ({ HTTP }) => ({
    async get(...args) {
        let config;
        let uuid;

        if (args && args.length) {
            if (typeof args[0] === 'string') {
                uuid = args[0];
                config = args[1];
            }
            else {
                config = args[0];
            }
        }

        if (!config) config = {};

        if (uuid) {
            const url = `/auction_requests/${ uuid }/`;
            const response = await HTTP.get(url, config);
            return response.data;
        }
        else {
            const url = '/auction_requests/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async getOrders(uuid) {
        const url = `/auction_requests/${ uuid }/get_orders/`;
        const response = await HTTP.get(url);
        return response.data;
    },

    async checkout(uuid, data) {
        const url = `/auction_requests/${ uuid }/checkout/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async createOrders(uuid, data) {
        const url = `/auction_requests/${ uuid }/create_orders/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async cancel(uuid, data) {
        const url = `/auction_requests/${ uuid }/cancel/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async addRequest(data) {
        const url = '/auction_requests/add_request/';
        const response = await HTTP.post(url, data);
        return response.data;
    },
});
