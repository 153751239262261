<template>
    <ButtonText
        v-if="showOnMap || customEvent"
        type="button"
        :dashed="dashed"
        :secondary="secondary"
        :dark="dark"
        class="address_btn"
        @click="handleAddressClick"
    >
        <slot>
            г. {{ shop.city.name }}, {{ shop.address }}
        </slot>
    </ButtonText>

    <address v-else>
        г. {{ shop.city.name }}, {{ shop.address }}
    </address>
</template>

<script>
import ButtonText from '@/components/_buttons/ButtonText.vue';
const PointOnMapPopup = () => import('@/components/popups/PointOnMapPopup.vue');

export default {
    name: "ShopAddress",

    components: {
        ButtonText,
    },

    props: {
        shop: {
            type: Object,
            default: () => {},
        },

        showOnMap: {
            type: Boolean,
            default: true,
        },

        customEvent: {
            type: Boolean,
            default: false,
        },

        address: {
            type: String,
            default: '',
        },

        dashed: {
            type: Boolean,
            default: true,
        },
        secondary: {
            type: Boolean,
            default: true,
        },
        dark: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        handleAddressClick() {
            if (this.customEvent) this.$emit('clickOnAddress');
            else this.openMap();
        },

        openMap() {
            const props = {
                shop: this.shop,
                coords: this.shop.coordinates,
                callback: value => this.$emit('changeFavorite', value),
            };
            const options = { props };
            this.$popup(PointOnMapPopup, options);
        },
    },
};
</script>

<style scoped>
.address_btn {
    display: block;
    max-width: 100%;
    width: max-content;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>