import deepClone from '@/lib/deepClone.js';
import { PAGE_LIMIT } from '@/settings.js';


const initial = {
    fetching: false,
    initialized: false,
    initializing: true,
    contracts: [],
    currentCount: 0,
    limit: PAGE_LIMIT,
    offset: 0,
};

export default {
    namespaced: true,

    state() {
        return deepClone(initial);
    },

    getters: {
        hasMore: state => state.currentCount > state.offset,
    },

    mutations: {
        setInitialized(state, value) {
            state.initialized = value;
        },

        setInitializing(state, value) {
            state.initializing = value;
        },

        setContracts(state, contracts) {
            state.contracts = contracts;
        },

        addContracts(state, contracts) {
            state.contracts = [...state.contracts, ...contracts];
        },

        setCurrentCount(state, count) {
            state.currentCount = count;
        },

        setFetching(state, value) {
            state.fetching = value;
        },

        incrementOffset(state) {
            state.offset += state.limit;
        },

        destroy(state) {
            Object.entries(deepClone(initial)).forEach(([key, value]) => {
                state[key] = value;
            });
        },
    },

    actions: {
        async prefetch({ commit, dispatch }) {
            commit('setInitializing', true);

            try {
                const { results, current_count } = await dispatch('get');

                commit('setContracts', results);
                commit('setCurrentCount', current_count);
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
            finally {
                commit('setInitializing', false);
                commit('setInitialized', true);
            }
        },

        async get({ state }) {
            const limit = state.limit;
            const offset = state.offset;
            const params = { limit, offset };
            const config = { params };
            return await this.$api.insuranceContract.get(config);
        },

        async more({ commit, dispatch }) {
            commit('setFetching', true);
            commit('incrementOffset');

            try {
                const { results, current_count } = await dispatch('get');

                commit('addContracts', results);
                commit('setCurrentCount', current_count);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                commit('setFetching', false);
            }
        },
    },
};