var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('RouterLink',{staticClass:"logo-wrap logo-wrap_mobile",attrs:{"to":{
                name: 'main',
            }}},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/images/logo.svg"),"alt":"Логотип Ubibi","width":"53","height":"17"}})]),_vm._v(" "),(_vm.menu.length)?_c('ul',{staticClass:"navigation"},_vm._l((_vm.filteredMenu),function(section,sectionIndex){return _c('li',{staticClass:"section"},[_c('div',{staticClass:"heading-wrap",on:{"click":function($event){return _vm.toggleNavSection(sectionIndex)}}},[_c('h4',{staticClass:"heading"},[_vm._v("\n                        "+_vm._s(section.name)+"\n                    ")]),_vm._v(" "),_c('UIcon',{staticClass:"heading__arrow",class:{ 'heading__arrow_rotate': _vm.openSections[sectionIndex] },attrs:{"name":"arrow-down","small":""}})],1),_vm._v(" "),_c('TransitionExpand',[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.openSections[sectionIndex]),expression:"openSections[sectionIndex]"}],key:("nav-section-" + sectionIndex),staticClass:"section-list",attrs:{"id":("nav-section-" + sectionIndex)}},_vm._l((section.pages),function(page){return _c('li',{staticClass:"item"},[(page.type === 'static-pages')?_c('RouterLink',{staticClass:"link link-primary-hover",attrs:{"title":page.name,"to":{
                                    name: page.entity.codename,
                                },"target":page.target_blank ? '_blank' : '_self'}},[_vm._v("\n                                "+_vm._s(page.name)+"\n                            ")]):_vm._e(),_vm._v(" "),(page.type === 'additional-pages')?_c('RouterLink',{staticClass:"link link-primary-hover",attrs:{"title":page.name,"to":{
                                    name: 'additional-page',
                                    params: {
                                        codename: page.entity.codename,
                                    }
                                },"target":page.target_blank ? '_blank' : '_self'}},[_vm._v("\n                                "+_vm._s(page.name)+"\n                            ")]):_vm._e(),_vm._v(" "),(page.type === 'link')?_c('a',{staticClass:"link link-primary-hover",attrs:{"href":page.url,"title":page.name,"target":page.target_blank ? '_blank' : '_self'}},[_vm._v("\n                                "+_vm._s(page.name)+"\n                            ")]):_vm._e()],1)}),0)])],1)}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"logos"},[_c('RouterLink',{staticClass:"logo-wrap logo-wrap_desktop",attrs:{"to":{
                    name: 'main',
                }}},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/images/logo.svg"),"alt":"Логотип Ubibi","width":"53","height":"17"}})]),_vm._v(" "),_c('SocialsNets'),_vm._v(" "),_c('div',{staticClass:"payments-block payments-block_mobile"},[_c('Payments')],1)],1),_vm._v(" "),_c('div',{staticClass:"footer-bottom"},[_c('RouterLink',{staticClass:"footer-bottom__item link-primary-hover bottom-link",attrs:{"to":{ name: 'privacy-policy' }}},[_vm._v("\n                Политика конфиденциальности\n            ")]),_vm._v(" "),_c('RouterLink',{staticClass:"footer-bottom__item link-primary-hover bottom-link",attrs:{"to":{ name: 'rules' }}},[_vm._v("\n                Соглашения и правила\n            ")]),_vm._v(" "),_c('span',{staticClass:"footer-bottom__item copyright"},[_vm._v("© "+_vm._s(_vm.year)+", "+_vm._s(_vm.company_name))]),_vm._v(" "),_c('div',{staticClass:"payments-block payments-block_desktop"},[_c('Payments')],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }