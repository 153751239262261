<template>
    <div v-if="reply" class="chat-reply">
        <div class="chat-reply__wrap">
            <div class="chat-reply__content-block">
                <div class="chat-reply__sender">
                    <span>{{ reply.sender.name }}</span>
                </div>
                <ChatMessagePreview
                    :message="reply"
                    class="chat-reply__content"
                ></ChatMessagePreview>
            </div>
            <div v-if="hasClear" class="chat-payload__remove-block">
                <button
                    type="button"
                    tabindex="-1"
                    class="chat-payload__remove-button"
                    @click="$emit('clear')"
                >
                    <UIcon
                        name="close-16"
                        class="chat-payload__remove-icon"
                    ></UIcon>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import clearFileName from '@/lib/clearFileName.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import ChatMessagePreview from '@/components/_chat/ChatMessagePreview.vue';


export default {
    name: 'ChatReply',

    components: { ChatMessagePreview, UIcon },

    props: {
        reply: Object,
    },

    computed: {
        hasClear() {
            return this.$listeners.clear;
        },
    },

    methods: {
        clearName: clearFileName,
    },
}
</script>

<style>
.chat-reply__wrap {
    display: flex;
    font-size: var(--small-fz);
    line-height: 1.33;
}

.chat-reply__wrap::before {
    content: "";
    width: 3px;
    flex-shrink: 0;
    height: 36px;
    margin-right: 12px;
    background-color: var(--primary-color);
}

.chat-reply__content-block {
    flex-grow: 1;
    width: calc(100% - 3px - 12px - 12px - 20px);
}

.chat-reply__sender {
    margin-bottom: 4px;
    font-family: var(--f-bold);
}

.chat-reply__content {
    width: 100%;
}
</style>