<template>
    <div class="profile-page">
        <div
            v-if="title"
            class="profile-page__head hidden-xl hidden-l"
        >
            <h1
                class="h1 hidden-s"
                @click="$refs.mobileMenu.toggleDropdown()"
            >
                {{ title }}
            </h1>

            <div class="profile-page__head-menu-wrap">
                <RoundArrowBtn
                    direction="down"
                    shadow
                    size="36"
                    :rotate="isMobileOpen"
                    class="user-menu-toggler_l"
                    @click="$refs.mobileMenu.toggleDropdown()"
                ></RoundArrowBtn>

                <button
                    class="user-menu-toggler_mobile"
                    :class="[!isMobileOpen && 'user-menu-toggler_mobile_with-shadow']"
                    @click="$refs.mobileMenu.toggleDropdown()"
                >
                    <span class="h1 ellipsis">
                        {{ mobileTitle || title }}
                    </span>

                    <RoundArrowBtn
                        direction="down"
                        :rotate="isMobileOpen"
                        :isButton="false"
                        class="ml-8"
                    ></RoundArrowBtn>
                </button>

                <SelectMenu
                    ref="mobileMenu"
                    :options="menu"
                    :title="mobileTitle || title"
                    class="user-menu user-menu_mobile"
                    @change="value => isMobileOpen = value"
                ></SelectMenu>
            </div>

            <div
                v-if="hasBtnSlot"
                class="profile-page__head-btn"
            >
                <slot name="btn"></slot>
            </div>
        </div>

        <div class="profile-layout">
            <aside class="profile-layout__aside">
                <RouterLink
                    :to="{
                        name: 'account-settings'
                    }"
                    class="user-info"
                >
                    <UserAvatar
                        size="xl"
                        class="avatar"
                    ></UserAvatar>

                    <div class="user-info__text-wrap">
                        <span class="name">
                            {{ profile.first_name }} {{ lastNameFirstLetter }}
                        </span>
                    </div>
                </RouterLink>

                <ul class="user-menu user-menu_desktop">
                    <li
                        v-for="option in menu"
                        :key="option.codename"
                        class="user-menu__item"
                    >
                        <RouterLink
                            :to="{
                                name: option.codename
                            }"
                            :tag="currentRoute === option.codename ? 'p' : 'a'"
                            exactActiveClass="user-menu__link_active"
                            class="user-menu__link"
                            :class="{ 'user-menu__link_active': currentRoute === option.codename }"
                        >
                            <UIcon
                                :name="option.icon"
                                big
                                class="user-menu__icon"
                            ></UIcon>
                            {{ option.title }}
                        </RouterLink>
                    </li>
                </ul>
            </aside>

            <div class="profile-layout__main">
                <div
                    v-if="title"
                    class="profile-page__head hidden-s hidden-m"
                >
                    <p
                        class="h1"
                        @click="$refs.mobileMenu.toggleDropdown()"
                    >
                        {{ title }}
                    </p>

                    <div
                        v-if="hasBtnSlot"
                        class="profile-page__head-btn"
                    >
                        <slot name="btn"></slot>
                    </div>
                </div>

                <transition
                    name="fade-page"
                    mode="out-in"
                >
                    <slot></slot>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import SelectMenu from "@/components/SelectMenu.vue";
import RoundArrowBtn from "@/components/project-buttons/RoundArrowBtn.vue";
import UserAvatar from '@/components/UserAvatar.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'AccountLayout',

    components: { UIcon, UserAvatar, RoundArrowBtn, SelectMenu },

    props: {
        title: {
            type: String,
            default: '',
        },

        mobileTitle: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            menu: [
                {
                    icon: 'delivery',
                    title: 'Заказы',
                    codename: 'account-orders',
                    children: [
                        'account-orders',
                        'account-orders-active',
                        'account-orders-archive',
                        'account-order-item',
                    ],
                },
                {
                    icon: 'heart-stroke',
                    title: 'Избранное',
                    codename: 'account-favorites',
                    children: [
                        'account-favorites-parts',
                        'account-favorites-contract-parts',
                        'account-favorites-products',
                        'account-favorites-brands',
                        'account-favorites-shops',
                    ],
                },
                {
                    icon: 'notification',
                    title: 'Уведомления',
                    codename: 'account-notifications',
                },
                {
                    icon: 'car',
                    title: 'Гараж',
                    codename: 'account-garage',
                },
                {
                    icon: 'document',
                    title: 'Страховые полисы',
                    codename: 'account-insurance',
                    children: [
                        'account-insurance-policies',
                        'account-insurance-drafts',
                    ],
                },
                {
                    icon: 'star-stroke',
                    title: 'Отзывы',
                    codename: 'account-reviews',
                    children: [
                        'account-reviews-parts',
                        'account-reviews-products',
                        'account-reviews-shops',
                    ],
                },
                {
                    icon: 'settings',
                    title: 'Настройки профиля',
                    codename: 'account-settings',
                },
            ],

            isMobileOpen: false,
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),

        currentRoute() {
            let selectedOption;
            this.menu.forEach(option => {
                if (option.codename === this.$route.name) {
                    selectedOption = option.codename;
                }
                else if (option.children) {
                    option.children.forEach(child => {
                        if (child === this.$route.name) selectedOption = option.codename;
                    });
                }
            });
            return selectedOption;
        },

        lastNameFirstLetter() {
            return this.profile.last_name ? this.profile.last_name.charAt(0) + '.' : '';
        },

        isOrdersSection() {
            return !!this.$route.matched.find(item => item.name === 'account-orders');
        },

        isRequestsSection() {
            return !!this.$route.matched.find(item => item.name === 'account-requests');
        },

        hasBtnSlot() {
            return !!this.$slots.btn || !!this.$scopedSlots.btn;
        },
    },
};
</script>

<style scoped>
.profile-page {
    margin-top: var(--gap-y-medium);
}

@media (min-width: 1281px) {
    .profile-layout {
        grid-template-columns: repeat(20, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profile-layout {
        grid-template-columns: repeat(12, 1fr);
    }
}
@media (min-width: 1040px) {
    .profile-layout {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

@media (min-width: 1281px) {
    .profile-layout__main {
        grid-column: 7/19;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profile-layout__main {
        grid-column: 4/13;
    }
}

@media (min-width: 1281px) {
    .profile-layout__aside {
        grid-column: 3/7;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profile-layout__aside {
        grid-column: 1/4;
    }
}
@media (min-width: 1040px) {
    .profile-layout__aside {
        align-self: start;
    }
}
@media (max-width: 1039px) {
    .profile-layout__aside {
        display: none;
    }
}

@media (min-width: 1040px) {
    .user-info {
        display: block;
    }
}
@media (max-width: 1039px) {
    .user-info {
        display: flex;
        align-items: center;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .user-info {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .user-info {
        padding: 16px;
    }
}

@media (min-width: 1040px) {
    .avatar {
        margin-bottom: 16px;
    }
}
@media (max-width: 1039px) {
    .avatar {
        margin-right: 20px;
    }
}

.name {
    display: block;
    font-family: var(--f-bold);
}
@media (min-width: 768px) {
    .name {
        font-size: 18px;
    }
}
@media (max-width: 767px) {
    .name {
        font-size: 16px;
    }
}

@media (min-width: 1040px) {
    .user-menu {
        margin-top: 24px;
    }
}
@media (max-width: 1039px) {
    .user-menu_desktop {
        display: none;
    }

    .user-menu {
        margin-bottom: var(--grid-gap);
    }
}
@media (min-width: 1040px) {
    .user-menu_mobile {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .profile-page__head-menu-wrap {
        position: relative;
        margin-right: auto;
        margin-left: 16px;
    }
}

.user-menu__link {
    display: flex;
    align-items: center;
    font-family: var(--f-semi-bold);
}
@media (min-width: 1040px) {
    .user-menu__link {
        padding: 10px 0;
    }
}

.user-menu__link:hover,
.user-menu__link:focus,
.user-menu__link_active {
    color: var(--primary-color);
}

.user-menu-toggler_l,
.user-menu-toggler_mobile {
    display: none;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .user-menu-toggler_l {
        display: block;
        line-height: 44px;
    }
}
@media (max-width: 767px) {
    .user-menu-toggler_mobile {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        background-color: var(--light-c);
        border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
    .user-menu-toggler_mobile_with-shadow {
        box-shadow: var(--light-shadow);
        border-radius: var(--border-radius);
    }
}

.user-menu__icon {
    margin-right: 12px;
    fill: currentColor;
}

.profile-page__head {
    position: relative;
}
@media (min-width: 768px) {
    .profile-page__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        margin-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .profile-page__head {
        margin-bottom: var(--y-grid-gap);
    }
}

@media (max-width: 767px) {
    .profile-page__head-btn {
        width: 100%;
        margin-top: var(--y-grid-gap);
    }
}
</style>