var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"market-item"},[_c('div',{staticClass:"market-item__cover-title-wrap"},[_c('MarketItemCover',{staticClass:"market-item__cover-wrap",attrs:{"item":_vm.item.offer[_vm.modelName],"modelName":_vm.modelName,"coverCodename":"micro"}}),_vm._v(" "),_c('MarketItemName',{staticClass:"market-item__title",attrs:{"id":_vm.item.offer[_vm.modelName].id,"modelName":_vm.modelName,"name":_vm.item.offer[_vm.modelName].name}})],1),_vm._v(" "),(_vm.item.deleted)?_c('span',{staticClass:"market-item__deleted-text"},[_vm._v("Товар удалён")]):_c('div',{staticClass:"market-item__counter-wrap"},[(_vm.modelName === 'contract_part')?_c('span',{staticClass:"market-item__amount"},[_vm._v("1 шт.")]):_c('Counter',{staticClass:"market-item__counter",attrs:{"value":_vm.item.amount,"min":0,"max":_vm.item.confirmed_amount},on:{"change":_vm.handleCounterChange}}),_vm._v(" "),(_vm.modelName === 'contract_part' || _vm.item.confirmed_amount)?_c('UTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var attrs = ref.attrs;
var handlers = ref.handlers;
var value = ref.value;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"market-item__counter-tooltip",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}}],null,false,99630674)},[_vm._v(" "),(_vm.modelName === 'contract_part')?_c('p',[_vm._v("\n                Контрактные автозапчасти продаются в единственном экземпляре.\n            ")]):_c('p',[_vm._v("\n                В наличии есть "+_vm._s(_vm.item.confirmed_amount)+" шт.\n            ")])]):_vm._e()],1),_vm._v(" "),_c('b',{staticClass:"market-item__price"},[_vm._v("\n        "+_vm._s(_vm._f("price")(_vm.item.total_price))+"\n    ")]),_vm._v(" "),(_vm.item.deleted)?_c('UTooltip',{staticClass:"market-item__action-btn",attrs:{"noDetach":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var value = ref.value;
var attrs = ref.attrs;
var handlers = ref.handlers;
return [_c('ButtonIcon',_vm._g(_vm._b({attrs:{"hoverPrimary":"","icon":{
                    name: 'restore',
                },"active":value,"aria-label":"Вернуть товар"},on:{"click":_vm.handleRevert}},'ButtonIcon',attrs,false),handlers))]}}],null,false,2697164094)},[_vm._v("\n\n        Вернуть товар в заказ\n    ")]):_vm._e(),_vm._v(" "),(!_vm.item.deleted)?_c('UTooltip',{staticClass:"market-item__action-btn",attrs:{"noDetach":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                var value = ref.value;
                var attrs = ref.attrs;
                var handlers = ref.handlers;
return [_c('ButtonIcon',_vm._g(_vm._b({attrs:{"hoverPrimary":"","icon":{
                    name: 'delete',
                },"aria-label":"Удалить товар из заказа","active":value},on:{"click":_vm.handleDelete}},'ButtonIcon',attrs,false),handlers))]}}],null,false,2553269228)},[_vm._v("\n\n        Удалить товар из заказа\n    ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }