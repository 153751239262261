export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        meta: {},
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        destroy(state) {
            state.initialized = false;
        },
    },

    actions: {
        async init({ commit, dispatch }) {
            try {
                const meta = await dispatch('getMeta');

                commit('setMeta', meta);
                commit('clearHttpError', null, { root: true });
                commit('setInitialized');

                return { meta };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getMeta() {
            const params = {
                limit: 0,
                offset: 0,
            };

            return await this.$api.staticPages.get('about', { params });
        },
    },
};