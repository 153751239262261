<template>
    <div class="insurance-payment-fail">
        <h1 class="h1 h1_text">
            Оплата не прошла
        </h1>
        <p class="page-descriptor">
            Во время совершения оплаты произошла ошибка.
            <br>
            Если проблема возникла не по вашей вине, <ButtonText
                primary
                dashed
                @click="openWriteUsPopup"
            >
                напишите нам
            </ButtonText>.
        </p>

        <div class="info-container layer-1">
            <div class="info-container__main">
                <div class="police">
                    <h3 class="h3">
                        Полис ОСАГО <span v-if="offer.policy_number">№ {{ offer.policy_number }}</span>
                    </h3>

                    <div class="company">
                        <div
                            v-lazy-container="{ selector: 'img' }"
                            class="company__logo-wrap"
                        >
                            <img
                                v-if="company.logo"
                                :data-src="$links.uploads + company.logo.thumbnails.insurance_company_detail"
                                alt=""
                                class="company__logo"
                            >
                        </div>

                        <div class="company__text-data">
                            <h4 class="company__name">
                                {{ company.name }}
                            </h4>

                            <p class="company__license">
                                {{ company.license }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="data">
                    <div class="data__column">
                        <b class="data__label">
                            Срок действия полиса:
                        </b>
                        <p class="data__text">
                            {{ contract.action_start_date | date }} – {{ contract.action_end_date | date }}
                        </p>
                    </div>

                    <div class="data__column">
                        <b class="data__label">
                            Автомобиль:
                        </b>
                        <p class="data__text">
                            {{ contract.vehicle_model.fullname }}, 
                            {{ contract.vehicle_year }} ({{ contract.vehicle_engine_power }} л. с.)
                            <template v-if="contract.vehicle_license_plate">
                                <br>
                                {{ contract.vehicle_license_plate | carNumber }}
                            </template>
                        </p>
                    </div>

                    <div class="data__column">
                        <b class="data__label">
                            Страхователь:
                        </b>
                        <p class="data__text">
                            {{ contract.insurer_last_name }} 
                            {{ contract.insurer_first_name }} {{ contract.insurer_middle_name }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="info-container__total">
                <span class="total__text">Стоимость полиса:</span>
                <span class="total__price">{{ offer.price | price }}</span>
            </div>
        </div>

        <ButtonBlock
            primary
            target="_blank"
            :href="offer.payment_url"
            class="button-repeat"
        >
            Повторить оплату
        </ButtonBlock>
    </div>
</template>

<script>
// store
import insuranceOsago from '@/store/modules/insuranceOsago.js';
import { mapActions, mapState } from 'vuex';
// components
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
const WriteUsPopup = () => import('@/components/popups/WriteUsPopup.vue');


export default {
    name: 'InsuranceCheckoutFailPage',

    components: { ButtonBlock, ButtonText },

    metaInfo() {
        return {
            title: 'Оплата не прошла',
        };
    },

    serverPrefetch() {
        this.$store.registerModule('insuranceOsago', insuranceOsago);

        return this.serverPrefetch({ uuid: this.uuid });
    },

    computed: {
        ...mapState({
            contract: state => state.insuranceOsago.contract,
            offer: state => (state.insuranceOsago.contract.active || {}).offer_obj || {},
            company: state => state.insuranceOsago.contract.active.company || {},
        }),

        uuid() {
            return this.$route.params.uuid;
        },
    },

    beforeMount() {
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago, { preserveState: true });
        }
    },

    mounted() {
        this.init();
        this.createBreadcrumbs();
    },

    methods: {
        ...mapActions({
            serverPrefetch: 'insuranceOsago/serverPrefetch',
            browserPrefetch: 'insuranceOsago/browserPrefetch',
        }),

        async init() {
            await this.browserPrefetch({ uuid: this.uuid });
        },

        createBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: this.$route.name,
                        params: this.$route.params,
                    },
                    title: 'Оплата не прошла',
                },
            ]);
        },

        openWriteUsPopup() {
            this.$popup(WriteUsPopup);
        },
    },
};
</script>

<style scoped>
.insurance-payment-fail {
    margin-top: var(--gap-y-medium);
}

.page-descriptor {
    margin-bottom: var(--gap-y-mini);
    line-height: var(--base-lh);
}
@media (min-width: 1281px) {
    .page-descriptor {
        width: var(--col-8);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .page-descriptor {
        width: 880px;
    }
}

.info-container {
    max-width: 880px;
}

.info-container__main {
    padding: 24px;
    border-bottom: 1px solid var(--border-light-c);
}

.police {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--border-light-c);
}

.company {
    margin-top: 24px;
}
@media (min-width: 1040px) {
    .company {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }
}

.company__text-data {
    width: 320px;
    flex-shrink: 0;
}

.company__name {
    margin-bottom: 4px;
    font-family: var(--f-bold);
}

.company__license {
    color: var(--font-secondary-color);
}

.company__logo-wrap {
    width: 120px;
    height: 60px;
    flex-shrink: 0;
}

@media (max-width: 1039px) {
    .company__logo-wrap {
        margin-bottom: 12px;
    }
}

.company__logo {
    max-height: 100%;
}

.data {
    margin-top: 24px;
}
@media (min-width: 1040px) {
    .data {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }
}

@media (max-width: 1039px) {
    .data__column:not(:last-child) {
        margin-bottom: 20px;
    }
}

.data__label {
    display: block;
    margin-bottom: 4px;
}

.info-container__total {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px;
    text-align: right;
}

.total__price {
    margin-left: 20px;
    font-size: var(--h3-fz);
    font-family: var(--f-bold);
}

@media (min-width: 768px) {
    .button-repeat {
        margin-top: 36px;
    }
}
@media (max-width: 767px) {
    .button-repeat {
        width: 100%;
        margin-top: 28px;
    }
}
</style>