<template>
    <div
        :id="'selector' + id"
        class="selector"
        :class="[
            {
                selector_opened: opened,
                selector_disabled: disabled,
                selector_invalid: invalid,
                selector_multi: multi,
                selector_single: !multi,
                selector_small: small,
                selector_fill: inputValue.length
            },
        ]"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
    >
        <div class="selector__input-container">
            <slot
                name="input"
                v-bind="scope.input"
            >
                <InputText
                    ref="input"
                    v-bind="scope.input.attrs"
                    :readonly="readonly"
                    :small="small"
                    autocomplete="off"
                    :clearable="false"
                    rightIndent
                    class="selector__input"
                    :isFilled="!!inputValue.length || !isEmpty || (!placeholder && opened)"
                    v-on="scope.input.events"
                ></InputText>
            </slot>

            <slot
                name="clear"
                v-bind="scope.clear"
            >
                <button
                    v-if="showCross"
                    type="button"
                    class="selector__button selector__clear"
                    v-bind="scope.clear.attrs"
                    tabindex="-1"
                    v-on="scope.clear.events"
                >
                    <UIcon
                        name="cross"
                        small
                        secondary
                        hovered
                    ></UIcon>
                </button>
            </slot>

            <slot
                name="toggle"
                v-bind="scope.toggle"
            >
                <button
                    v-if="showToggle"
                    type="button"
                    class="selector__button selector__toggle"
                    v-bind="scope.toggle.attrs"
                    tabindex="-1"
                    v-on="scope.toggle.events"
                >
                    <UIcon
                        name="arrow-down"
                        small
                        hovered
                        class="selector__arrow"
                        :class="{
                            selector__arrow_opened: opened,
                        }"
                    ></UIcon>
                </button>
            </slot>

            <TransitionExpandFade>
                <ul
                    v-show="showList && (!loading || filteredOptions.length)"
                    ref="optionsList"
                    class="layer-2 selector__options-list"
                    :class="{
                        'selector__options-list_content-width': checkboxOption,
                    }"
                >
                    <li
                        v-for="(option, optionIndex) in filteredOptions"
                        :key="getOptionKey(option)"
                        ref="option"
                        class="selector__option-item"
                    >
                        <div
                            v-if="checkboxOption"
                            ref="optionButton"
                            class="selector__option-button"
                            :class="{
                                'selector__option-button_focus': focusIndex === optionIndex,
                            }"
                        >
                            <slot
                                name="label"
                                v-bind="{ option }"
                            >
                                <UCheckbox
                                    :value="isOptionSelected(option, selectedOptions)"
                                    @change="select(option)"
                                >
                                    <template #label>
                                        {{ getOptionLabel(option) }}
                                    </template>
                                </UCheckbox>
                            </slot>
                        </div>
                        <button
                            v-else
                            ref="optionButton"
                            type="button"
                            class="selector__option-button"
                            :class="{
                                'selector__option-button_selected': isOptionSelected(option, selectedOptions),
                                'selector__option-button_focus': focusIndex === optionIndex,
                            }"
                            :tabindex="-1"
                            @click="selectable(option) ? select(option) : null"
                        >
                            <slot
                                name="label"
                                v-bind="{ option }"
                            >
                                {{ getOptionLabel(option) }}
                            </slot>
                        </button>
                    </li>
                    <li
                        v-if="!loading && hasMore"
                        class="selector__option-item selector__option-more"
                    >
                        <ButtonText
                            ref="more"
                            secondary
                            dark
                            dashed
                            :focused="focusIndex === filteredOptions.length"
                            :tabindex="-1"
                            @click="$emit('more')"
                        >
                            Показать ещё
                        </ButtonText>
                    </li>
                    <li
                        v-if="!loading && !filteredOptions.length"
                        class="selector__option-item selector__option-empty"
                        :tabindex="-1"
                    >
                        Ничего не найдено
                    </li>
                </ul>
            </TransitionExpandFade>
        </div>

        <slot
            v-if="!inputValue.length"
            name="value"
            v-bind="scope.value"
        >
            <div
                v-if="multi && !checkboxOption"
                class="selector__value-wrap"
            >
                <div
                    v-for="option in selectedOptions"
                    :key="'selected' + getOptionKey(option)"
                    class="selector__value"
                >
                    <slot
                        name="selected-option"
                        v-bind="option"
                    >
                        <Tag
                            class="selector__tag"
                            @clear="remove(option)"
                        >
                            <slot
                                name="label"
                                v-bind="{ option }"
                            >
                                <span :title="getOptionLabel(option)">{{ getOptionLabel(option) }}</span>
                            </slot>
                        </Tag>
                    </slot>
                </div>
            </div>

            <!-- псевдо-плейсхолдер -->
            <div
                v-if="clearInputOnFocus"
                class="selector__selected-option"
                :class="{
                    'selector__selected-option_empty': !selectedOptions.length,
                    'selector__selected-option_multi': multi,
                    'selector__selected-option_small': small,
                }"
                @click="onClickValue"
            >
                <span
                    v-if="multi"
                    :title="pseudoPlaceholderValue"
                >{{ pseudoPlaceholderValue }}</span>
                <template v-else>
                    <slot
                        name="label"
                        v-bind="{ option: selectedOptions[0] }"
                    >
                        <span :title="pseudoPlaceholderValue">{{ pseudoPlaceholderValue }}</span>
                    </slot>
                </template>
            </div>
        </slot>
    </div>
</template>

<script>
import genId from '@ui/utils/genId.js';
import equals from '@/lib/equals.js';
import eventPath from '@/lib/eventPath.js';
import options from '@/mixins/options.js';
import Tag from '@/components/_inputs/Tag.vue';
import InputText from '@/components/_inputs/InputText.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import TransitionExpandFade from '@/components/_transitions/TransitionExpandFade.vue';
import UCheckbox from '@ui/components/UCheckbox/UCheckbox.vue';


export default {
    name: 'Selector',

    components: {
        UCheckbox,
        TransitionExpandFade,
        ButtonText,
        Tag,
        UIcon,
        InputText,
    },

    mixins: [
        options,
    ],

    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        id: {
            type: String,
            default: () => genId(),
        },

        value: {
            required: true,
        },

        multi: Boolean,
        checkboxOption: Boolean,
        disabled: Boolean,
        loading: Boolean,
        label: String,
        placeholder: String,
        selectedLabel: String,
        invalid: Boolean,
        error: String,

        // Если опции приходят по API,
        // то тогда используется innerOptions
        options: Array,

        hasMore: Boolean,

        small: Boolean,

        //
        selectable: {
            type: Function,
            default(option) {
                return !this.isOptionSelected(option, this.selectedOptions);
            },
        },

        // Наличие крестика
        clearable: {
            type: Boolean,
            default: true,
        },

        // Возможность вводить данные в инпут
        searchable: {
            type: Boolean,
            default: true,
        },

        // Если true, то опции фильтруются внутри компонента
        filterable: Boolean,

        // null - для одиночного селектора и массива чисел
        // [] - для множественного селектора
        // '' - для массива строк
        emptyValue: {
            type: [String, Object, Number, Array],
            default: null,
        },

        clearInputOnBlur: {
            type: Boolean,
            default: true,
        },

        clearInputOnFocus: {
            type: Boolean,
            default: true,
        },

        clearSelectedOptionsOnInput: Boolean,

        clearInputOnSelect: {
            type: Boolean,
            default: true,
        },

        isAsync: Boolean,

        reduce: {
            type: Function,
            default(option) {
                return option;
            },
        },

        autocomplete: Boolean,
    },

    data() {
        return {
            opened: false,
            focusIndex: -1,
            inputValue: '',
            hovered: false,
            focused: false,
            selectedOptions: [],
            showList: false,
        };
    },

    computed: {
        normalizedOptions() {
            return this.options || this.innerOptions;
        },

        normalizedValue() {
            let arr = Array.isArray(this.value)
                ? this.value
                : equals(this.value, this.emptyValue)
                    ? []
                    : [this.value];

            if (
                arr.length &&
                !this.isAsync &&
                arr.some(item => typeof item === 'string')
            ) {
                arr = arr.map(item => {
                    if (typeof item === 'string') {
                        for (const option of this.normalizedOptions) {
                            if (item === this.getOptionKey(option)) {
                                return option;
                            }
                        }
                    }

                    return item;
                });
            }

            return arr.filter(_ => _);
        },

        normalizedEmptyValue() {
            return this.multi ? [] : this.emptyValue;
        },

        filteredOptions() {
            if (!this.filterable) {
                return this.normalizedOptions;
            }

            if (!this.inputValue.length) {
                return this.normalizedOptions;
            }

            return this.filter(this.normalizedOptions, this.inputValue);
        },

        isEmpty() {
            return !this.selectedOptions.length;
        },

        outputValue() {
            return this.selectedOptions.length
                ? this.multi
                    ? this.selectedOptions.map(option => this.reduce(option))
                    : this.reduce(this.selectedOptions[0])
                : this.normalizedEmptyValue;
        },

        showCross() {
            return (this.opened || this.hovered) && !this.isEmpty && !this.loading;
        },

        showToggle() {
            return !this.autocomplete && !this.showCross && !this.loading;
        },

        scope() {
            // для слотов
            return {
                input: {
                    events: {
                        input: this.onInput,
                        change: this.onInputChange,
                        focus: this.onInputFocus,
                        click: this.readonly && !this.disabled ? this.onClickInput : () => ({}),
                        keydown: this.onKeyDown,
                        // mouseenter: this.onMouseEnter,
                        // mouseleave: this.onMouseLeave,
                        clear: this.onClear,
                    },
                    attrs: {
                        id: this.id,
                        disabled: this.disabled,
                        value: this.inputValue,
                        loading: this.loading,
                        invalid: this.invalid,
                        error: this.error,
                        label: this.label,
                        focused: this.focused,
                        placeholder: this.placeholder,
                    },
                },

                value: {
                    selectedOptions: this.selectedOptions,
                },

                toggle: {
                    attrs: {
                        disabled: this.disabled,
                        loading: this.loading,
                    },
                    events: {
                        click: this.toggle,
                    },
                },

                clear: {
                    attrs: {
                        disabled: this.disabled,
                    },
                    events: {
                        click: this.onClear,
                    },
                },
            };
        },

        readonly() {
            return !this.searchable;
        },

        hasSelectable() {
            return this.filteredOptions.some(option => this.selectable(option));
        },

        pseudoPlaceholderValue() {
            if (this.multi) {
                if (this.selectedOptions.length) {
                    return (this.selectedLabel || 'Выбрано') + ': ' + this.selectedOptions.length;
                }
                else {
                    return this.selectedLabel ? this.selectedLabel + ': все' : 'Все';
                }
            }
            else {
                if (this.selectedOptions[0]) {
                    return this.selectedLabel
                        ? this.selectedLabel + ': ' + this.getOptionLabel(this.selectedOptions[0]).toLowerCase()
                        : this.getOptionLabel(this.selectedOptions[0]);
                }
                else {
                    return '';
                }
            }
        },
    },

    watch: {
        normalizedValue: {
            handler(normalizedValue) {
                let equal = true;

                if (equal) {
                    equal = normalizedValue.length === this.selectedOptions.length;
                }

                if (equal) {
                    for (let value of normalizedValue) {
                        if (equal) {
                            equal = this.selectedOptions.some(option => {
                                return this.optionComparator(this.reduce(option), value);
                            });
                        }
                        else {
                            break;
                        }
                    }
                }

                if (!equal) {
                    this.selectedOptions = [...normalizedValue];
                }
            },

            immediate: true,
        },

        selectedOptions: {
            handler(selectedOptions) {
                // если поле не в фокусе и меняется выбранное значение
                if (!this.focused && !this.clearInputOnSelect) {
                    this.inputValue = selectedOptions.length ? this.getOptionLabel(selectedOptions[0]) : '';
                }

                // if (this.autocomplete) {
                //     this.inputValue = selectedOptions.length ? this.getOptionLabel(selectedOptions[0]) : '';
                // }

                let equal = true;

                if (equal) {
                    equal = selectedOptions.length === this.normalizedValue.length;
                }

                if (equal) {
                    for (let option of selectedOptions) {
                        if (equal) {
                            equal = this.normalizedValue.some(value => {
                                return this.optionComparator(this.reduce(option), value);
                            });
                        }
                        else {
                            break;
                        }
                    }
                }

                if (!equal) {
                    this.$emit('change', this.outputValue);
                }
            },
        },

        opened(value) {
            if (!value) this.showList = false;
        },

        focusIndex() {
            this.scrollList();
        },

        inputValue: {
            handler(value) {
                this.$emit('changeInputValue', value);
            },

            immediate: true,
        },
    },

    beforeDestroy() {
        document.removeEventListener('mousedown', this.onClickAway);
    },

    methods: {
        toggle($event) {
            if (this.opened) {
                // TODO: избавиться от остановки всплытия
                $event.stopPropagation();
                this.hide();
            }
            else {
                this.open();
                this.focus();
            }
        },

        async open() {
            if (!this.opened) {
                this.opened = true;

                document.addEventListener('mousedown', this.onClickAway);

                if (this.isAsync) {
                    const open = () => {
                        this.showList = true;
                    };

                    this.$emit('open', open);
                }
                else {
                    this.showList = true;
                    this.$emit('open', () => {});
                }
            }
        },

        focus() {
            this.$refs.input.focus();
        },

        blur() {
            this.$refs.input.blur();
        },

        hide() {
            this.opened = false;
            this.focusIndex = -1;
            this.offset = 0;
            this.$refs.optionsList.scrollTop = 0;

            if (this.clearInputOnBlur) {
                this.inputValue = '';
            }

            this.focused = false;
            // this.blur();
            // this.$emit('blur');
            document.removeEventListener('mousedown', this.onClickAway);
        },

        onInput(value) {
            this.inputValue = value;

            if (this.clearSelectedOptionsOnInput) {
                this.selectedOptions = [];
            }

            this.$emit('input', value);
        },

        onInputChange(value) {
            if (value !== this.inputValue) {
                this.inputValue = value;
            }
        },

        onInputFocus($event) {
            this.focused = true;
            this.open();
            this.$emit('focus', $event);
        },

        onClickInput($event) {
            this.toggle($event);
        },

        onMouseEnter() {
            this.hovered = true;
        },

        onMouseLeave() {
            this.hovered = false;
        },

        onKeyDown($event) {
            const keyCode = $event.keyCode;
            const handlers = {
                9: () => this.onTab(),
                13: () => this.onEnter(),
                27: () => this.onEsc(),
                38: () => this.onUp($event),
                40: () => this.onDown($event),
            };

            if (handlers[keyCode]) {
                handlers[keyCode]($event);
            }
        },

        onTab() {
            this.hide();
        },

        onEnter() {
            const isMoreButton = this.focusIndex === this.filteredOptions.length;

            if (isMoreButton) {
                // this.showMore();
                this.$emit('more');
            }
            else if (this.focusIndex > -1) {
                this.select(this.filteredOptions[this.focusIndex]);
            }
            else {
                this.open();
            }
        },

        onEsc() {
            this.hide();
        },

        onUp($event) {
            if ($event) {
                $event.preventDefault();
            }

            if (this.hasSelectable) {
                let newIndex = -1;
                const oldIndex = this.focusIndex === -1
                    ? this.filteredOptions.length + Number(this.hasMore)
                    : this.focusIndex;

                for (let i = oldIndex - 1; i >= 0; i--) {
                    const isMoreButton = i === this.filteredOptions.length;

                    if (isMoreButton || this.selectable(this.filteredOptions[i])) {
                        newIndex = i;
                        break;
                    }
                }

                if (newIndex === -1) {
                    this.focusIndex = this.filteredOptions.length + Number(this.hasMore) ;
                    this.onUp();
                }
                else {
                    this.focusIndex = newIndex;
                }
            }
        },

        onDown($event) {
            if ($event) {
                $event.preventDefault();
            }

            if (this.hasSelectable) {
                let newIndex = -1;
                const length = this.filteredOptions.length + Number(this.hasMore);

                for (let i = this.focusIndex + 1; i < length; i++) {
                    const isMoreButton = i === this.filteredOptions.length;

                    if (isMoreButton || this.selectable(this.filteredOptions[i])) {
                        newIndex = i;
                        break;
                    }
                }

                if (newIndex === -1) {
                    this.focusIndex = -1;
                    this.onDown();
                }
                else {
                    this.focusIndex = newIndex;
                }
            }
        },

        scrollList() {
            const index = this.focusIndex;

            if (index > -1) {
                const isMoreButton = index === this.filteredOptions.length;

                const list = this.$refs.optionsList;
                const item = isMoreButton ? this.$refs.more.$el.parentElement : this.$refs.option[index];

                if (list.scrollHeight > list.clientHeight) {
                    let scrollBottom = list.clientHeight + list.scrollTop;
                    let itemBottom = item.offsetTop + item.offsetHeight;

                    if (itemBottom > scrollBottom) {
                        list.scrollTop = itemBottom - list.clientHeight;
                    }
                    else if (item.offsetTop < list.scrollTop) {
                        list.scrollTop = item.offsetTop;
                    }
                }
            }
        },

        select(option) {
            const selectedOptions = [...this.selectedOptions];

            this.change(option);

            if (!this.isOptionSelected(option, selectedOptions)) {
                if (!this.multi) {
                    this.hide();
                }

                this.$emit('select', option);

                if (this.clearInputOnSelect) {
                    this.inputValue = '';
                }
            }
        },

        onClickAway($event) {
            if (this.opened) {
                const path = eventPath($event);

                if (path) {
                    const isSelector = path.some(el => {
                        return el && el.id === 'selector' + this.id;
                    });

                    if (!isSelector) {
                        this.hide();
                    }
                }
            }
        },

        change(option) {
            const value = option;

            if (this.multi) {
                const isSelected = this.isOptionSelected(option, this.selectedOptions);
                if (isSelected) {
                    const selectedIndex = this.selectedOptions.findIndex(item => equals(item, option));
                    this.selectedOptions.splice(this.selectedOptions[selectedIndex], 1);
                }
                else {
                    this.selectedOptions.splice(this.selectedOptions.length, 0, value);
                }
            }
            else {
                this.selectedOptions.splice(0, 1, value);
            }
        },

        onClear() {
            this.selectedOptions = [];
            this.inputValue = '';
            this.$emit('clear');
            this.open();
            this.focus();
        },

        remove(option) {
            const index = this.selectedOptions
                .map(option => this.getOptionKey(option))
                .indexOf(this.getOptionKey(option));
            this.selectedOptions.splice(index, 1);
        },

        onClickValue() {
            if (!this.disabled) {
                this.focus();
            }
        },

        filterBy(label, search) {
            return (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1;
        },

        filter(options, search) {
            return options.filter(option => {
                let label = this.getOptionLabel(option);

                if (typeof label === 'number') {
                    label = label.toString();
                }

                return this.filterBy(label, search);
            });
        },
    },
};
</script>

<style>
.selector {
    position: relative;
}

.selector__input-container {
    position: relative;
}

.selector__input-container .input-text__label {
    max-width: calc(100% - 44px);
}

.selector__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    font-size: 0;
}

.selector__toggle {
    right: 12px;
}

.selector__clear {
    right: 12px;
}

.selector__arrow {
    fill: var(--font-secondary-color);
    transform: rotate(0deg);
    transition: transform var(--transition);
}
.selector_disabled .selector__arrow {
    fill: var(--font-secondary-light-color);
}
.selector__arrow.selector__arrow_opened {
    transform: rotate(180deg);
}
.selector__button:hover .selector__arrow,
.selector__button:focus .selector__arrow {
    fill: var(--dark-c);
}
.selector.selector_invalid:not(.selector_disabled) .selector__arrow {
    fill: var(--error-medium-color)
}

.selector__options-list {
    position: absolute;
    top: calc(100% + 4px);
    z-index: 10;
    width: 100%;
    max-height: 232px;
    padding: 4px;
    overflow: auto;
    outline: none;
}

.selector__options-list_content-width {
    width: max-content;
    max-width: 320px;
}

.selector__option-button {
    display: block;
    width: 100%;
    padding: 8px 12px;
    border-radius: var(--border-radius);
    text-align: left;
    transition: background-color var(--transition);
}

.selector__option-button:hover:not(.selector__option-button_selected),
.selector__option-button.selector__option-button_focus,
.selector__option-button:focus {
    background-color: var(--bright-bg);
}

.selector__option-button.selector__option-button_selected {
    color: var(--font-secondary-color);
    cursor: default;
    pointer-events: none;
}

.selector__option-empty,
.selector__option-more {
    padding: 8px 12px;
}

.selector_disabled .selector__value {
    cursor: default;
    color: var(--font-secondary-color);
}

.selector__value-wrap {
    display: flex;
    flex-wrap: wrap;
}

.selector__value {
    position: relative;
    max-width: 100%;
    margin-top: 8px;
}

.selector__tag {
    margin-right: 8px;
    max-width: 100%;
}

.selector__selected-option {
    position: absolute;
    top: 12px;
    left: 17px;
    width: calc(100% - 32px - 16px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selector__selected-option.selector__selected-option_small {
    top: 8px;
    left: 15px;
}

.selector__selected-option.selector__selected-option_multi.selector__selected-option_empty,
.selector_opened .selector__selected-option {
    color: var(--font-secondary-color)
}

.selector_opened .selector__selected-option,
.selector_disabled .selector__selected-option {
    color: var(--font-secondary-color)
}
</style>