<template>
    <!-- Нужна ли обертка? -->
    <div
        v-if="!hidden"
        class="form-field"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
    >
        <component
            :is="fieldComponent"
            ref="input"
            v-bind="attrs"
            v-on="{
                ...listeners,
                ...handlers,
            }"
        >
            <slot></slot>

            <template #label="scope">
                <slot
                    name="label"
                    v-bind="scope"
                ></slot>
            </template>

            <template #hint>
                <slot name="hint"></slot>
            </template>

            <template #hint-content>
                <slot name="hint-content"></slot>
            </template>
        </component>

        <!-- datepicker -->
        <!-- checkbox -->
        <!-- checkboxes -->
        <FieldError
            v-if="hasErrorField"
            :show="showErrorField"
            class="form-field__error"
        >
            {{ error }}
        </FieldError>
    </div>
</template>

<script>
// utils
import validationMessages from '@/components/_form/utils/validationMessages.js';
import validationRules from '@/components/_form/utils/validationRules.js';
import equals from '@ui/utils/equals.js';
import humanizer from '@/components/_form/utils/humanizer.js';
import converter from '@/components/_form/utils/converter.js';
import genId from '@ui/utils/genId.js';
import isEmpty from '@/lib/isEmpty.js';
import FieldError from '@/components/_form/FieldError.vue';


const initialValue = {
    text: '',
    string: '',
    phone: '',
    email: '',
    url: '',
    number: null,
    date: null,
    datetime: null,
    year: null,
    licensePlate: '',
    time: null,
    geo: '',
    geocode: '', // TODO: избавиться
    select: null,
    loader: null,
    tabs: '',
    radio: '',
    checkbox: false,
    checkboxes: [],
    calendar: '',
    password: '', // TODO: избавиться
    rating: null, // TODO: избавиться
    stars: null,
    address: null,
};

export default {
    name: 'FormField',

    components: { FieldError },

    inject: {
        register: { default: undefined },
        unregister: { default: undefined },
        fieldRegisterToTab: { default: undefined },
        fieldUnregisterToTab: { default: undefined },
        updateParentValue: { default: undefined },
        ref: { default: () => () => {} },
        refRoot: { default: () => () => {} },
        updateOriginalErrors: { default: undefined },
        runRulesOnDependencies: { default: () => {} },
    },

    provide() {
        return {};
    },

    inheritAttrs: false,

    props: {
        id: {
            type: String,
            default: () => genId(),
        },

        name: {
            type: String,
            required: true,
        },

        type: {
            type: String,
            default: 'string',
        },

        label: String,

        validations: {
            type: String,
            default: '',
        },

        validationRules: {
            type: Object,
            default: () => ({}),
        },

        validationMessages: Object,

        offModifyLabel: Boolean,

        disabled: Boolean,

        hidden: Boolean,
    },

    data() {
        const initVal = initialValue[this.$options.propsData.type];

        return {
            didValidate: false,
            changed: false,
            touched: false,
            hover: false,
            focus: false,

            originalValue: initVal, // non-humanized from FormComponent
            innerValue: initVal, // humanized for input
            // checkedValue: undefined, // to avoid repeat validation

            isField: true,

            originalErrors: [],
            innerErrors: [
                // {
                //     message: '',
                //     code: '',
                // },
            ],
        };
    },

    computed: {
        // TODO: разобраться, так ли необходимо initialValue
        initialValue() {
            return initialValue[this.type];
        },

        humanizedValue() {
            if (this.originalValue === undefined) {
                return this.initialValue;
            }

            return humanizer(this.originalValue, this.type);
        },

        convertedValue() {
            return converter(this.innerValue, this.type);
        },

        fieldComponent() {
            return this.$formManager.options.widgets[this.type];
        },

        hasErrors() {
            return this.innerErrors && !!this.innerErrors.length;
        },

        invalid() {
            return this.didValidate && this.hasErrors;
        },

        isMaskedType() {
            // для установки соответствующего правила
            const types = [
                'date',
                'time',
                'datetime',
                'year',
                'url',
                'email',
                'phone',
                'number',
                'licensePlate',
                'password',
            ];

            return types.includes(this.type);
        },

        parsedValidations() {
            // <Array>
            let validations;

            if (!this.validations) {
                validations = [];
            }
            else if (Array.isArray(this.validations)) {
                validations = this.validations;
            }
            else {
                validations = this.validations
                    .split('|')
                    .map(str => {
                        const [name, dirtyParams] = str.split(':');
                        const params = dirtyParams ? dirtyParams.split(',') : [];
                        return { name, params };
                    });
            }

            // как-то избавиться или вынести наружу
            if (this.isMaskedType) {
                validations.push({ name: this.type });
            }

            if (this.type === 'calendar') {
                validations.push({ name: 'date' });
            }

            return validations;
        },

        rules() {
            // <Array>
            const all = Object.assign({}, validationRules, this.validationRules);

            return this.parsedValidations
                .map(({ name, params }) => {
                    const rule = all[name];
                    return { name, params, rule };
                });
        },

        messages() {
            // <Object>
            return Object.assign({}, validationMessages, this.validationMessages);
        },

        hasRequired() {
            return this.parsedValidations.map(obj => obj.name).includes('required');
        },

        labelComputed() {
            if (!this.offModifyLabel && this.label && this.hasRequired && !this.disabled) {
                return this.label + ' *';
            }

            return this.label;
        },

        error() {
            if (this.type === 'loader') {
                if (this.innerErrors.some(({ code }) => code === 'required')) {
                    return 'Загрузите ' + this.label.toLowerCase();
                }
            }
            else {
                return ((this.innerErrors || [])[0] || {}).message;
            }

            return '';
        },

        // TODO: рефактор
        attrs() {
            const attrs = Object.assign({}, this.$attrs);

            attrs.label = this.labelComputed;
            attrs.value = this.innerValue;

            if (this.disabled !== undefined) {
                attrs.disabled = this.disabled;
            }

            // как-то избавиться или вынести наружу
            if (this.type === 'phone') {
                attrs.mask = '+7 (000) 000 00 00';
            }

            if (this.type === 'date') {
                attrs.mask = '00.00.0000';
            }

            if (this.type === 'time') {
                attrs.mask = '00:00';
            }

            if (this.type === 'datetime') {
                attrs.mask = '00.00.0000 00:00';
            }

            if (this.type === 'year') {
                attrs.mask = '0000';
            }

            if (this.type === 'licensePlate') {
                attrs.mask = 'A000AA 000';
                attrs.upper = true;
            }

            if (this.type === 'text') {
                attrs.textarea = true;
            }

            if (this.type === 'geocode') {
                attrs.map = false;
            }

            if (this.type === 'password') {
                attrs.password = true;
            }

            if (this.type === 'email') {
                attrs.trim = true;
            }

            attrs.invalid = this.invalid;

            if (['rating'].includes(this.type)) {
                attrs.error = this.error;
            }

            return attrs;
        },

        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { input, change, focus, blur, error, ...listeners } = this.$listeners;
            return listeners;
        },

        handlers() {
            return {
                change: this.onChange,
                input: this.onInput,
                focus: this.onFocus,
                blur: this.onBlur,
                error: this.onError,
            };
        },

        // TODO: рефактор
        hasErrorField() {
            return !['rating'].includes(this.type);
        },

        showErrorField() {
            if (this.type === 'loader') {
                return this.innerErrors.some(({ code }) => code === 'required');
            }

            return this.invalid;
        },
    },

    watch: {
        humanizedValue: {
            handler(humanizedValue) {
                if (!equals(humanizedValue, this.innerValue)) {
                    // Если humanizedValue и innerValue не равны,
                    // то это значит, что значение изменилось извне
                    this.innerValue = humanizedValue;

                    if (!isEmpty(humanizedValue)) {
                        if (!this.touched) this.touched = true;
                        if (!this.changed) this.changed = true;
                        if (this.didValidate) this.didValidate = false;
                        this.runRulesOnDependencies(this.name);
                    }
                }
            },

            immediate: true,
        },

        convertedValue: {
            handler(convertedValue) {
                if (!equals(convertedValue, this.originalValue)) {

                    this.updateParentValue({ name: this.name, value: convertedValue });
                }
            },
        },

        innerErrors: {
            handler(innerErrors) {
                if (!equals(innerErrors, this.originalErrors)) {
                    if (this.updateOriginalErrors && typeof this.updateOriginalErrors === 'function') {
                        this.updateOriginalErrors({ name: this.name, errors: innerErrors });
                    }
                }
            },

            deep: true,
        },

        originalErrors: {
            handler(originalErrors) {
                if (!equals(originalErrors, this.innerErrors)) {
                    this.didValidate = true;
                    this.innerErrors = this.originalErrors;
                }
            },

            deep: true,
        },

        rules: {
            handler(oldRules, newRules) {
                const oldNames = oldRules.map(obj => obj.name);
                const newNames = newRules.map(obj => obj.name);

                if (!equals(oldNames, newNames)) {
                    this.didValidate = false;
                    this.clearErrors();
                }
            },
        },
    },

    created() {
        if (this.register && typeof this.register === 'function') {
            this.register(this);
        }

        if (this.fieldRegisterToTab && typeof this.fieldRegisterToTab === 'function') {
            this.fieldRegisterToTab(this);
        }
    },

    beforeDestroy() {
        if (this.unregister && typeof this.unregister === 'function') {
            this.unregister(this);
        }

        if (this.fieldUnregisterToTab && typeof this.fieldUnregisterToTab === 'function') {
            this.fieldUnregisterToTab(this);
        }
    },

    methods: {
        clearErrors() {
            this.innerErrors = [];
        },

        onInput($event) {
            const value = $event && typeof $event === 'object' && $event.target && $event.value !== undefined
                ? $event.value
                : $event;

            if (this.type !== 'select' && this.type !== 'address') {
                this.innerValue = value;
                if (!this.touched) this.touched = true;
                if (!this.changed) this.changed = true;
                if (this.didValidate) this.didValidate = false;
            }

            this.clearErrors();

            this.$emit('input', value);
        },

        onChange($event) {
            const value = $event && typeof $event === 'object' && $event.target && $event.value !== undefined
                ? $event.value
                : $event;

            this.innerValue = value;
            if (!this.touched) this.touched = true;
            if (!this.changed) this.changed = true;
            if (this.didValidate) this.didValidate = false;
            this.clearErrors();

            if (!isEmpty(value)) {
                this.runRules();
                this.runRulesOnDependencies(this.name);
            }

            this.$emit('change', value);
        },

        onError(errors) {
            const handle = this.$formManager.options.field.handleInputErrors[this.type];

            if (handle && typeof handle === 'function') {
                handle(errors, this);
            }
            else {
                if (errors && Array.isArray(errors)) {
                    this.updateErrors(errors);
                }
            }
        },

        onFocus() {
            this.focus = true;
            this.$emit('focus');
        },

        onBlur() {
            this.focus = false;

            if (this.touched) {
                this.runRules();
            }

            this.$emit('blur');
        },

        getErrorMessage({ name, type, value, refs, params }) {
            let message = this.messages[name];
            if (!message) message = this.messages.regexp;

            if (typeof message === 'function') {
                return message({ type, value, refs, params });
            }
            else {
                return message;
            }
        },

        runRules() {
            const type = this.type;
            const value = this.innerValue;

            const errors = this.rules.reduce((acc, { name, rule, params }) => {
                // normalized params for dynamic (ex: date)
                const normalizedParams = params && params.length
                    ? params.map(param => typeof param === 'function' ? param() : param)
                    : params;
                const refs = {};
                const ref = (name, options = {}) => {
                    const { key, root } = options;
                    let value;

                    if (root) {
                        value = this.refRoot(this, name);
                    }
                    else {
                        value = this.ref(this, name);
                    }

                    if (key) {
                        refs[key] = value;
                    }

                    return value;
                };

                const invalid = rule({ value, ref, params: normalizedParams, type: this.type });

                if (invalid) {
                    const message = this.getErrorMessage({ name, type, value, refs, params: normalizedParams });

                    acc.push({
                        code: name,
                        message,
                    });
                }

                return acc;
            }, []);

            this.innerErrors = [...this.innerErrors, ...errors];

            this.changed = false;
            // this.checkedValue = this.innerValue;

            this.didValidate = true;
        },

        onMouseEnter() {
            this.hover = true;
        },

        onMouseLeave() {
            this.hover = false;
        },

        getInvalid() {
            return !!this.innerErrors.length;
        },

        updateErrors(errors) {
            this.didValidate = true;
            this.innerErrors = errors;
        },

        setErrors(errors) {
            // Эту функцию используют родительские компоненты
            if (errors && Array.isArray(errors)) {
                const handler = this.$formManager.options.field.handleHttpErrors[this.type];

                if (handler && typeof handler === 'function') {
                    handler(errors, this);
                }
                else {
                    this.updateErrors(errors);
                }
            }
        },

        getFieldElement() {
            const ref = this.$refs.input;

            if (Array.isArray(ref)) {
                return ref[0];
            }

            return ref;
        },
    },
};
</script>

<style>
.form-field__error .field-error {
    margin-top: 8px;
}
</style>