import { render, staticRenderFns } from "./CompanyGridItem.vue?vue&type=template&id=345d3ddc&scoped=true&"
import script from "./CompanyGridItem.vue?vue&type=script&lang=js&"
export * from "./CompanyGridItem.vue?vue&type=script&lang=js&"
import style0 from "./CompanyGridItem.vue?vue&type=style&index=0&id=345d3ddc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345d3ddc",
  null
  
)

export default component.exports