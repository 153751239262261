import { mapState } from 'vuex';

export default () => {
    return {
        ...mapState({
            contract: state => state.insuranceOsago.contract,
        }),

        initFormData() {
            return this.keys.reduce((acc, key) => {
                acc[key] = this.contract[key];
                return acc;
            }, {});
        },

        uuid() {
            return this.$route.params.uuid;
        },

        invalid() {
            return Object.entries(this.errors || {}).some(([key, value]) => {
                if (key === 'drivers') {
                    return value.some(driver => {
                        return Object.values(driver).some(value => {
                            return value && value.length;
                        });
                    });
                }
                else {
                    return value && value.length;
                }
            });
        },
    };
};