import { HTTP } from '@/http.js';
import filter from './filter.js';
import sort from './sort.js';


const staticFilters = [
    {
        widget: 'radio',
        codename: 'in_stock',
        options: [
            {
                value: 'true',
                label: 'В наличии',
            },
            {
                value: 'false',
                label: 'Все товары, включая отсутствующие',
            },
        ],
    },
    {
        title: 'Цена, ₽',
        widget: 'range',
        codename: 'price',
        min: 0,
        max: 0,
    },
    {
        title: 'Производители',
        widget: 'checkboxes',
        codename: 'brand',
        options: [],
    },
    {
        title: 'Магазины',
        widget: 'checkboxes',
        codename: 'company',
        options: [],
    },
];

function getMergedFacets(facetsArray) {
    const cleanFacetsArray = facetsArray.filter(el => el != null);
    let facets = {
        in_stock_count: 0,
        not_in_stock_count: 0,
    };
    const minPrices = [];
    const maxPrices = [];
    cleanFacetsArray.forEach(facet => {
        facets.in_stock_count += facet.in_stock_count;
        facets.not_in_stock_count += facet.not_in_stock_count;
        if (facet.prices.min_price) {
            minPrices.push(facet.prices.min_price);
        }
        if (facet.prices.max_price) {
            maxPrices.push(facet.prices.max_price);
        }
    });
    facets.prices = {
        min_price: minPrices.length ? Math.min(...minPrices) : 0,
        max_price: maxPrices.length ? Math.max(...maxPrices) : 0,
    };

    const filterKeys = ['brands', 'companies'];
    const facetsMap = {};
    filterKeys.forEach(item => {
        facetsMap[item] = cleanFacetsArray.reduce((acc, a) => {
            if (a[item] && a[item].length) acc.push(a[item]);
            return acc;
        }, []).flat();
    });
    for (let [key, value] of Object.entries(facetsMap)) {
        facets[key] = Object.values(value.reduce((acc, item) => {
            if (acc[item.id]) {
                acc[item.id].count += item.count;
            }
            else {
                acc[item.id] = item;
            }
            return acc;
        }, {}));

        facets[key].sort((a, b) => a.name.localeCompare(b.name));
    }

    return facets;
}

export default {
    namespaced: true,

    modules: {
        filter,
        sort,
    },

    state: () => ({
        searchedPartId: null,
        searchedPart: {},
        hasSearchedPart: false,
        showSearchedPart: false,
        searchedPartLoading: false,

        defaultFacets: {},

        originalSubstitutesParts: {
            items: [],
            current_count: 0,
            hasNext: false,
        },
        originalSubstitutesPartsLoading: false,
        totalOriginalSubstitutesPartsAmount: 0,

        totalAnaloguePartsAmount: 0,

        analogueParts: {
            items: [],
            current_count: 0,
            hasNext: false,
        },
        analoguePartsIsPart: {
            items: [],
            current_count: 0,
            hasNext: false,
        },
        analoguePartsInclude: {
            items: [],
            current_count: 0,
            hasNext: false,
        },
        analoguePartsLoading: false,
        analoguePartsIsPartLoading: false,
        analoguePartsIncludeLoading: false,

        allPartNamesBySku: {},

        initialized: false,
        initializedData: false,
        initializedFilter: false,
    }),

    mutations: {
        setSearchedPart(state, { current_count, results }) {
            state.hasSearchedPart = current_count === 1;
            state.showSearchedPart = current_count === 1;
            if (current_count === 1) {
                state.searchedPart = results[0];
                state.searchedPartId = results[0].part_product_id;
            }
        },

        setCurrentSearchedPart(state, { current_count }) {
            state.showSearchedPart = current_count === 1;
        },

        setTotalOriginalSubstitutesPartsAmount(state, { current_count }) {
            state.totalOriginalSubstitutesPartsAmount = current_count;
        },

        setOriginalSubstitutesParts(state, data) {
            state.originalSubstitutesParts.items = data.results;
            state.originalSubstitutesParts.current_count = data.current_count;
            state.originalSubstitutesParts.hasNext = !!data.next;
        },

        setTotalAnaloguePartsAmount(state, { current_count }) {
            state.totalAnaloguePartsAmount = current_count;
        },

        setDefaultFacets(state, facets) {
            state.defaultFacets = getMergedFacets(facets);
        },

        setAnalogueParts(state, { equal, is_part, include }) {
            state.analogueParts.items = equal.results || [];
            state.analogueParts.current_count = equal.current_count || 0;
            state.analogueParts.hasNext = !!equal.next;

            state.analoguePartsIsPart.items = is_part.results || [];
            state.analoguePartsIsPart.current_count = is_part.current_count || 0;
            state.analoguePartsIsPart.hasNext = !!is_part.next;

            state.analoguePartsInclude.items = include.results || [];
            state.analoguePartsInclude.current_count = include.current_count || 0;
            state.analoguePartsInclude.hasNext = !!include.next;
        },

        addParts(state, { data, type }) {
            if (data.results) {
                if (type === 'substitutes') {
                    state.originalSubstitutesParts.items = [...state.originalSubstitutesParts.items, ...data.results];
                    state.originalSubstitutesParts.current_count = data.current_count;
                    state.originalSubstitutesParts.hasNext = !!data.next;
                }
                else if (type === 'equal') {
                    state.analogueParts.items = [...state.analogueParts.items, ...data.results];
                    state.analogueParts.current_count = data.current_count;
                    state.analogueParts.hasNext = !!data.next;
                }
                else if (type === 'is_part') {
                    state.analoguePartsIsPart.items = [...state.analoguePartsIsPart.items, ...data.results];
                    state.analoguePartsIsPart.current_count = data.current_count;
                    state.analoguePartsIsPart.hasNext = !!data.next;
                }
                else if (type === 'include') {
                    state.analoguePartsInclude.items = [...state.analoguePartsInclude.items, ...data.results];
                    state.analoguePartsInclude.current_count = data.current_count;
                    state.analoguePartsInclude.hasNext = !!data.next;
                }
            }
        },

        changePartsFavorite(state, { index, value, type }) {
            if (type === 'searched') {
                state.searchedPart.is_favorite = value;
            }
            else if (type === 'substitutes') {
                state.originalSubstitutesParts.items[index].is_favorite = value;
            }
            else if (type === 'equal') {
                state.analogueParts.items[index].is_favorite = value;
            }
            else if (type === 'is_part') {
                state.analoguePartsIsPart.items[index].is_favorite = value;
            }
            else if (type === 'include') {
                state.analoguePartsInclude.items[index].is_favorite = value;
            }
        },

        setLoading(state, { codename, value }) {
            state[codename] = value;
        },

        setAllPartNamesBySku(state, data) {
            state.allPartNamesBySku = data;
        },

        changeFavoriteInAllPartNamesBySku(state, { index, value }) {
            state.allPartNamesBySku.results[index].is_favorite = value;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        setInitializedData(state) {
            state.initializedData = true;
        },

        setInitializedFilter(state) {
            state.initializedFilter = true;
        },

        destroy(state) {
            state.searchedPartId = null;
            state.searchedPart = {};
            state.hasSearchedPart = false;
            state.showSearchedPart = false;
            state.searchedPartLoading = false;

            state.defaultFacets = {};

            state.totalOriginalSubstitutesPartsAmount = 0;
            state.originalSubstitutesParts.items = [];
            state.originalSubstitutesParts.current_count = 0;
            state.originalSubstitutesParts.hasNext = false;

            state.totalAnaloguePartsAmount = 0;
            state.analogueParts.items = [];
            state.analogueParts.current_count = 0;
            state.analogueParts.hasNext = false;
            state.analoguePartsLoading = false;

            state.analoguePartsIsPart.items = [];
            state.analoguePartsIsPart.current_count = 0;
            state.analoguePartsIsPart.hasNext = false;
            state.analoguePartsIsPartLoading = false;

            state.analoguePartsInclude.items = [];
            state.analoguePartsInclude.current_count = 0;
            state.analoguePartsInclude.hasNext = false;
            state.analoguePartsIncludeLoading = false;

            state.allPartNamesBySku = {};

            state.initialized = false;
            state.initializedData = false;
            state.initializedFilter = false;
        },
    },

    actions: {
        async init({ state, commit, dispatch }, query) {
            try {
                if (query.sku) {
                    await dispatch('fetchAllPartNamesBySku', query.sku);
                }
                else if (query.part_product) {
                    const searchedPart_default = await dispatch('getSearchedPart', {
                        query: {
                            part_product: query.part_product,
                            get_facets: 'all',
                        },
                    });
                    commit('setSearchedPart', searchedPart_default);

                    if (searchedPart_default.current_count === 1) {
                        let currentFacets = [];
                        if (searchedPart_default.results[0].is_original) {
                            // Get default counts and facets
                            const analogueParts_default = await dispatch('getAnalogueParts', { query: {
                                get_facets: 'all',
                                limit: 0,
                                offset: 0,
                            } });
                            commit('setDefaultFacets', [
                                searchedPart_default.facets,
                                analogueParts_default.facets,
                            ]);
                            commit('setTotalAnaloguePartsAmount', analogueParts_default);

                            // Get data by query
                            const responses = await Promise.all([
                                dispatch('fetchSearchedPart', { query: {
                                    in_stock: true,
                                    ...query,
                                    get_facets: 'all',
                                } }),
                                dispatch('fetchAnalogueParts', { query: {
                                    in_stock: true,
                                    ...query,
                                    get_facets: 'all',
                                    limit: 36,
                                    offset: 0,
                                } }),
                            ]);
                            currentFacets = [
                                responses[0].searchedPart.facets,
                                responses[1].analogueParts.facets,
                                responses[1].analoguePartsIsPart.facets,
                                responses[1].analoguePartsInclude.facets,
                            ];
                        }
                        else {
                            // Get default counts and facets
                            const originalSubstitutes_default = await dispatch('getOriginalSubstitutes', {
                                query: {
                                    get_facets: 'all',
                                    limit: 0,
                                    offset: 0,
                                },
                            });
                            commit('setDefaultFacets', [
                                searchedPart_default.facets,
                                originalSubstitutes_default.facets,
                            ]);
                            commit('setTotalOriginalSubstitutesPartsAmount', originalSubstitutes_default);

                            // Get data by query
                            const responses = await Promise.all([
                                dispatch('fetchSearchedPart', { query: {
                                    in_stock: true,
                                    ...query,
                                    get_facets: 'all',
                                } }),
                                dispatch('fetchOriginalSubstitutesPart', { query: {
                                    in_stock: true,
                                    ...query,
                                    get_facets: 'all',
                                    limit: 36,
                                    offset: 0,
                                } }),
                            ]);
                            currentFacets = [responses[0].searchedPart.facets, responses[1].originalSubstitutesParts.facets];
                        }

                        commit('setInitializedData');

                        commit('filter/setChecked', []);
                        commit('filter/setStaticFilters', {
                            config: staticFilters,
                            facets: getMergedFacets(currentFacets),
                            defaultFacets: state.defaultFacets,
                        });

                        dispatch('filter/parseQuery', {
                            query,
                            filters: staticFilters,
                        });
                        commit('sort/parseQuery', query);
                        commit('setInitializedFilter');
                    }
                }

                commit('setInitialized');
                commit('clearHttpError', null, { root: true });
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getSearchedPart({ rootState }, { query, cancelToken }) {
            return await this.$api.partProductsOneOfferDoc.get({
                params: {
                    ...query,
                },
                cancelToken,
            });
        },

        async fetchSearchedPart({ commit, dispatch }, { query = {}, cancelToken }) {
            commit('setLoading', { codename: 'searchedPartLoading', value: true });
            try {
                const searchedPart = await dispatch('getSearchedPart', { query, cancelToken });
                commit('setCurrentSearchedPart', searchedPart);
                commit('setLoading', { codename: 'searchedPartLoading', value: false });
                return { searchedPart };
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;
                commit('setLoading', { codename: 'searchedPartLoading', value: false });
            }
        },

        async getAnalogueParts({ state, rootState }, { query, cancelToken }) {
            const params = {
                original_part__in: state.searchedPartId,
                is_original: false,
                ...query,
            };

            return await this.$api.partProductsOneOfferDoc.get( { params, cancelToken });
        },

        async fetchAnalogueParts({ dispatch, commit }, { query = {}, cancelToken }) {
            commit('setLoading', { codename: 'analoguePartsLoading', value: true });
            commit('setLoading', { codename: 'analoguePartsIsPartLoading', value: true });
            commit('setLoading', { codename: 'analoguePartsIncludeLoading', value: true });

            let analogueParts, analoguePartsIsPart, analoguePartsInclude;

            const getEquals = () => dispatch('getAnalogueParts', {
                query: {
                    relation_type: 'equal',
                    ...query,
                },
                cancelToken,
            })
                .then(response => {
                    analogueParts = response;
                    commit('setAnalogueParts', { analogueParts: response });
                    commit('setLoading', { codename: 'analoguePartsLoading', value: false });
                })
                .catch((error) => {
                    if (HTTP.isCancel(error)) return;
                    commit('setLoading', { codename: 'analoguePartsLoading', value: false });
                });

            const getIsPart = () => dispatch('getAnalogueParts', {
                query: {
                    relation_type: 'is_part',
                    ...query,
                },
                cancelToken,
            })
                .then(response => {
                    analoguePartsIsPart = response;
                    commit('setAnalogueParts', { analoguePartsIsPart: response });
                    commit('setLoading', { codename: 'analoguePartsIsPartLoading', value: false });
                })
                .catch((error) => {
                    if (HTTP.isCancel(error)) return;
                    commit('setLoading', { codename: 'analoguePartsIsPartLoading', value: false });
                });

            const getIncludes = () => dispatch('getAnalogueParts', {
                query: {
                    relation_type: 'include',
                    ...query,
                },
                cancelToken,
            })
                .then(response => {
                    analoguePartsInclude = response;
                    commit('setAnalogueParts', { analoguePartsInclude: response });
                    commit('setLoading', { codename: 'analoguePartsIncludeLoading', value: false });
                })
                .catch((error) => {
                    if (HTTP.isCancel(error)) return;
                    commit('setLoading', { codename: 'analoguePartsIncludeLoading', value: false });
                });

            await Promise.all([
                getEquals(),
                getIsPart(),
                getIncludes(),
            ]);

            return { analogueParts, analoguePartsIsPart, analoguePartsInclude };
        },

        async getOriginalSubstitutes({ state, rootState }, { query, cancelToken }) {
            const params = {
                analog_part: state.searchedPartId,
                ...query,
            };
            delete params.part_product;

            return await this.$api.partProductsOneOfferDoc.get( { params, cancelToken });
        },

        async fetchOriginalSubstitutesPart({ commit, dispatch }, { query = {}, cancelToken }) {
            commit('setLoading', { codename: 'originalSubstitutesPartsLoading', value: true });
            try {
                const originalSubstitutesParts = await dispatch('getOriginalSubstitutes', { query, cancelToken });
                commit('setOriginalSubstitutesParts', originalSubstitutesParts);
                commit('setLoading', { codename: 'originalSubstitutesPartsLoading', value: false });
                return { originalSubstitutesParts };
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;
                commit('setLoading', { codename: 'originalSubstitutesPartsLoading', value: false });
            }
        },

        async fetchAllPartNamesBySku({ commit }, sku) {
            const params = {
                sku,
            };

            const data = await this.$api.partProductsOneOfferDoc.get({ params });
            commit('setAllPartNamesBySku', data);
        },

        updateFilters({ commit, state }, { facets }) {
            commit('filter/setStaticFilters', {
                config: staticFilters,
                facets: getMergedFacets(facets),
                defaultFacets: state.defaultFacets,
            });
        },
    },
};