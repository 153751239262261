<template>
    <PopupWrap
        :loading="initLoading"
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="popup-head">
                <h2 class="h2 popup-title">
                    Изменить состав заказа
                </h2>

                <p class="popup-head__text">
                    Не&nbsp;все товары есть в&nbsp;наличии. Подтвердите новый состав заказа. Стоимость удаленных позиций будет возвращена на&nbsp;вашу банковскую карту.
                </p>
            </div>

            <div class="order-body">
                <ul class="order-market-items-list">
                    <li
                        v-for="(marketItem, marketItemIndex) in newOrder.positions"
                        :key="'order-market-item' + marketItem.offer.id"
                        class="order-market-item-wrap"
                    >
                        <MarketItemEditOrder
                            :item="marketItem"
                            :modelName="marketItem.offer.offer_type"
                            @amountChange="handleCartItemAmountChange(marketItemIndex, ...arguments)"
                            @delete="handleCartItemDelete(marketItemIndex)"
                            @revert="handleCartItemRevert(marketItemIndex)"
                        ></MarketItemEditOrder>
                    </li>
                </ul>
            </div>

            <div class="footer">
                <p
                    v-if="totalPrice"
                    class="summary"
                >
                    {{ totalText }} <b>{{ totalPrice | price }}</b>
                </p>

                <p
                    v-else
                    class="summary flex align-items-center"
                >
                    <UIcon
                        name="info"
                        small
                        primary
                        class="mr-8"
                    ></UIcon>
                    <span class="text_semi-bold text_red">Заказ будет отменён</span>
                </p>

                <div class="actions-wrap">
                    <ButtonBlock
                        secondary
                        class="action-btn"
                        @click="close"
                    >
                        Отмена
                    </ButtonBlock>
                    <ButtonBlock
                        primary
                        class="action-btn"
                        :loading="loading"
                        :disabled="loading"
                        @click="confirm"
                    >
                        Применить
                    </ButtonBlock>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import deepClone from '@/lib/deepClone.js';
import { getErrorCode } from '@/lib/errors.js';
import plural from '@/lib/plural.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import MarketItemEditOrder from './MarketItemEditOrder.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'EditOrderPopup',

    components: {
        UIcon,
        ButtonBlock,
        MarketItemEditOrder,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        order: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            newOrder: {},
            initLoading: true,
            loading: false,
        };
    },

    computed: {
        totalText() {
            if (this.newOrder.positions) {
                return this.totalAmount + ' ' + plural(this.totalAmount, ['товар', 'товара', 'товаров']) + ' на сумму ';
            }
            return '';
        },

        totalAmount() {
            if (this.newOrder.positions) {
                return this.newOrder.positions.reduce((acc, item) => {
                    if (!item.deleted) acc += Number(item.amount);
                    return acc;
                }, 0);
            }
            return 0;
        },

        totalPrice() {
            if (this.newOrder.positions) {
                return this.newOrder.positions.reduce((acc, item) => {
                    if (!item.deleted) acc += Number(item.total_price);
                    return acc;
                }, 0);
            }
            return 0;
        },
    },

    async mounted() {
        let newOrder;

        if (!this.order.positions) {
            newOrder = await this.$api.orders.get(this.order.uuid);
        }
        else {
            newOrder = deepClone(this.order);
        }
        this.initLoading = false;
        newOrder.positions = newOrder.positions.filter(item => {
            if (item.confirmed_amount) {
                item.amount = item.confirmed_amount;
                item.total_price = item.confirmed_total_price;
                item.deleted = false;
                return item;
            }
        });
        this.newOrder = Object.assign({}, newOrder);
    },

    methods: {
        handleCartItemAmountChange(index, value) {
            this.newOrder.positions[index].amount = value;
            this.newOrder.positions[index].total_price = this.newOrder.positions[index].price * value;
        },

        handleCartItemRevert(index) {
            this.newOrder.positions[index].deleted = false;
        },

        handleCartItemDelete(index) {
            this.newOrder.positions[index].deleted = true;
        },

        confirm() {
            this.loading = true;
            if (this.totalAmount) {
                const data = {
                    positions: this.newOrder.positions.reduce((acc, item) => {
                        if (!item.deleted) {
                            acc.push({
                                id: item.id,
                                amount: item.amount,
                            });
                        }
                        return acc;
                    }, []),
                };

                this.$api.orders.changePositions(this.order.uuid, data)
                    .then(response => {
                        this.close();
                        this.callback(response);
                        this.$success('Изменения сохранены, разница в стоимости товаров будет возвращена на карту');
                    })
                    .catch(error => {
                        this.$store.commit('handleCommonHttpError', error);

                        const code = getErrorCode(error);
                        const data = error.response.data;

                        if (code === 400) {
                            const { positions } = data;
                            if (positions) {
                                this.$error(positions[0].message);
                            }
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
            else {
                const data = {
                    cancel_reason: 'Необходимый товар отсутствует',
                    is_move_to_basket: false,
                };

                this.$api.orders.cancel(this.order.uuid, data)
                    .then(response => {
                        this.close();
                        this.callback(response);
                        this.$success('Заказ отменён');
                    })
                    .catch(error => {
                        this.$store.commit('handleCommonHttpError', error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.popup-head {
    padding-bottom: var(--gap-y-small);
    border-bottom: 1px solid var(--border-light-c);
}

.popup-head__text {
    max-width: 680px;
    margin-top: var(--gap-y-mini);
    line-height: var(--base-lh);
}

.order-market-item-wrap {
    padding-top: 20px;
}

.order-market-item-wrap:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light-c);
}

.footer {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .footer {
        display: flex;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .footer {
        margin-top: auto;
    }
}

@media (min-width: 768px) {
    .summary {
        margin-right: 20px;
        flex-grow: 1;
    }
}
@media (max-width: 767px) {
    .summary {
        margin-bottom: 16px;
        text-align: center;
        justify-content: center;
    }
}

@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        flex-shrink: 0;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>