var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.brand)?_c(_vm.tag,_vm._b({tag:"component",staticClass:"brand-short-info",class:{
        'brand-short-info_base': !_vm.row && !_vm.border,
        'brand-short-info_row': _vm.row && !_vm.border,
        'brand-short-info_row_mobile': _vm.mobileRow && !_vm.border,
        'brand-short-info_border': !_vm.row && _vm.border,
    },on:{"click":_vm.showBrandPopup}},'component',_vm.attrs,false),[(!_vm.disableLogo)?_c('img',{staticClass:"logo",class:{
            'logo_base': !_vm.row && !_vm.border,
            'logo_row': _vm.row && !_vm.border,
            'logo_row_mobile': _vm.mobileRow && !_vm.border,
            'logo_border': !_vm.row && _vm.border,
            'logo_mobile_hidden': _vm.logoMobileHidden
        },attrs:{"src":_vm.brandLogoSrc,"alt":"","width":"54","height":"36"}}):_vm._e(),_vm._v(" "),_c('div',[(_vm.border)?_c('p',{staticClass:"label"},[_vm._v("\n            Производитель\n        ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"text"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.brand.name))]),(_vm.brand.country)?[(_vm.row || _vm.mobileRow || _vm.border)?_c('span',{staticClass:"comma"},[_vm._v(",")]):_vm._e(),_vm._v(" "),(!_vm.row && !_vm.border)?_c('br',{class:[_vm.mobileRow && 'br_desktop']}):_vm._e(),_vm._v(" "+_vm._s(_vm.brand.country)+"\n            ")]:_vm._e()],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }