import { computePosition } from '@ui/components/UPosition';


export default {
    props: {
        placement: {
            type: String,
            default: 'bottom',
            validator(value) {
                return [
                    'top-start',
                    'top',
                    'top-end',
                    'right-start',
                    'right',
                    'right-end',
                    'bottom-end',
                    'bottom',
                    'bottom-start',
                    'left-end',
                    'left',
                    'left-start',
                ].includes(value);
            },
        },
        flip: {
            type: [Boolean, Number],
            default: true,
        },
        shift: {
            type: [Boolean, Number],
            default: true,
        },
        offset: {
            type: Number,
            default: 4,
        },
        margin: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            position: {
                top: null,
                left: null,
                width: null,
                shift: null,
                observer: null,
                placement: 'bottom',
                dimensions: null,
            },
        };
    },

    methods: {
        addContentResizeObserver() {
            this.position.observer = new ResizeObserver(this.updatePosition);
            this.position.observer.observe(this.$refs.content);
        },

        removeContentResizeObserver() {
            this.position.observer.disconnect();
        },

        updatePosition() {
            if (!this.$refs.trigger) return;
            if (!this.$refs.content) return;

            const {
                x,
                y,
                shift,
                placement,
                dimensions,
            } = computePosition(this.$refs.trigger.triggerNode[0].elm, this.$refs.content, {
                flip: this.flip,
                shift: this.shift,
                offset: this.offset,
                margin: this.margin,
                placement: this.placement,
            });

            this.position.top = y;
            this.position.left = x;
            this.position.shift = shift;
            this.position.placement = placement;
            this.position.dimensions = dimensions;
        },
    },
};