var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RouterLink',{staticClass:"market-item-name",attrs:{"to":{
        name: _vm.modelName === 'product'
            ? 'market-product-description'
            : _vm.modelName === 'part_product' || _vm.modelName === 'part'
                ? 'market-new-part-description'
                : 'market-contract-part-description',
        params: {
            id: _vm.id,
        },
    }}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.name))]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }