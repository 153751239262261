import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'u-timeline-item',

    inject: ['timeline'],

    props: {
        icon: String,
        dotColor: {
            type: String,
            default: 'grey',
            validator(value) {
                return ['grey', 'green'].includes(value);
            },
        },
    },

    computed: {
        hasIcon() {
            return !!this.icon || !!this.$slots.icon;
        },
    },

    methods: {
        genBody() {
            return this.$createElement('div', {
                staticClass: 'u-timeline-item__body',
            }, this.$slots.default);
        },

        genIcon() {
            if (this.$slots.icon) {
                return this.$slots.icon;
            }

            return this.$createElement(UIcon, {
                props: {
                    name: this.icon,
                    tiny: true,
                    white: true,
                },
            }, this.icon);
        },

        genDot() {
            return this.$createElement('div', {
                staticClass: 'u-timeline-item__dot',
                class: [
                    'u-timeline-item__dot--' + this.dotColor,
                ],
            }, [this.hasIcon && this.genIcon()]);
        },

        genDivider() {
            return this.$createElement('div', {
                staticClass: 'u-timeline-item__divider',
            });
        },
    },

    render (h) {
        const children = [
            this.genDot(),
            this.genBody(),
            this.genDivider(),
        ];

        return h('div', {
            staticClass: 'u-timeline-item',
        }, children);
    },
};