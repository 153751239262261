<template>
    <component
        :is="tag"
        v-bind="attrs"
        class="back-link"
        @click="$emit('click')"
    >
        <UIcon
            name="arrow-left"
            small
            class="back-link__icon"
        ></UIcon>

        <span class="back-link__text">
            <slot>
                {{ text }}
            </slot>
        </span>
    </component>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'BackLink',

    components: { UIcon },

    props: {
        text: {
            type: String,
            default: '',
        },

        to: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        tag() {
            if (!this.to) return 'button';
            return 'RouterLink';
        },

        attrs() {
            if (this.tag === 'RouterLink') {
                return {
                    to: this.to,
                };
            }
            else {
                return {
                    type: 'button',
                };
            }
        },
    },
};
</script>

<style scoped>
.back-link {
    display: flex;
    align-items: center;
    width: max-content;
    color: var(--font-secondary-dark-color);
    transition: color var(--transition);
}

.back-link__icon {
    margin-right: 8px;
    fill: currentColor;
    transition: fill var(--transition);
}

.back-link__text {
    font-size: 12px;
    font-family: var(--f-bold);
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

.back-link:hover,
.back-link:focus {
    color: var(--primary-color);
}
</style>