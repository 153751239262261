<template>
    <ul
        v-if="facebook || vkontakte || instagram"
        class="socials"
    >
        <li
            v-if="vkontakte"
            class="socials__item"
        >
            <a
                :href="vkontakte"
                target="_blank"
                rel="noopener"
                class="socials__link"
                :class="{
                    'socials__link_white': white,
                }"
            >
                <UIcon
                    name="vk"
                    class="socials__icon socials__icon_vk"
                    :class="{
                        'icon_white': white,
                    }"
                ></UIcon>
            </a>
        </li>
        <li
            v-if="instagram"
            class="socials__item"
        >
            <a
                :href="instagram"
                target="_blank"
                rel="noopener"
                class="socials__link"
                :class="{
                    'socials__link_white': white,
                }"
            >
                <UIcon
                    name="insta"
                    class="socials__icon socials__icon_insta"
                    :class="{
                        'icon_white': white,
                    }"
                ></UIcon>
            </a>
        </li>
        <li
            v-if="facebook"
            class="socials__item"
        >
            <a
                :href="facebook"
                target="_blank"
                rel="noopener"
                class="socials__link"
                :class="{
                    'socials__link_white': white,
                }"
            >
                <UIcon
                    name="fb"
                    class="socials__icon socials__icon_fb"
                    :class="{
                        'icon_white': white,
                    }"
                ></UIcon>
            </a>
        </li>
    </ul>
</template>

<script>
import { mapState } from "vuex";
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: "SocialsNets",

    components: { UIcon },

    props: {
        white: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState({
            vkontakte: state => state.settings.vkontakte,
            instagram: state => state.settings.instagram,
            facebook: state => state.settings.facebook,
        }),
    },
};
</script>

<style scoped>
.socials {
    display: flex;
    align-items: center;
}

.socials__item:not(:last-child) {
    margin-right: 24px;
}

.socials__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 0;
    border-radius: 50%;
    background-color: var(--dark-c);
    transition: background-color var(--transition);
}

.socials__link:hover,
.socials__link:focus {
    background-color: var(--primary-color);
}

.socials__link_white {
    background-color: var(--light-c);
}

.socials__link_white:hover,
.socials__link_white:focus {
    background-color: var(--dark-c);
}

.socials__icon {
    fill: var(--light-c);
}

.socials__icon_white {
    fill: var(--primary-color);
}

.socials__icon_vk {
    width: 13px;
    height: 6px;
    margin-top: 2px;
}

.socials__icon_insta {
    width: 12px;
    height: 12px;
}

.socials__icon_fb {
    width: 6px;
    height: 12px;
}
</style>