<template>
    <InputRadioBase
        :id="id"
        :options="options"
        :value="valueComputed"
        :label="label"
        :optionKey="optionKey"
        :getOptionKey="getOptionKey"
        :optionLabel="optionLabel"
        :getOptionLabel="getOptionLabel"
        :optionComparator="optionComparator"
        :isOptionSelected="isOptionSelected"
        :reduce="reduce"
        wrapClasses="input-radio-simple__wrap"
        itemClasses="input-radio-simple__item"
        inputClasses="input-radio-simple__input"
        :labelClasses="[
            'input-radio-simple__label',
            {
                'input-radio-simple__label_center': center,
            }
        ]"
        v-on="listeners"
    >
        <template #label="{ option, id, checked }">
            <slot
                name="label"
                v-bind="{ option, id, classes: 'input-radio-simple__label', checked }"
            >
                <label
                    class="input-radio-simple__label"
                    :class="labelClasses"
                    :for="id"
                >
                    {{ getOptionLabel(option) }}
                </label>
            </slot>
        </template>
    </InputRadioBase>
</template>

<script>
import inputRadioMixin from '@/components/_inputs/inputRadioMixin.js';


export default {
    name: "InputRadioSimple",

    mixins: [
        inputRadioMixin,
    ],

    props: {
        center: Boolean,
    },
};
</script>

<style>
.input-radio-simple__item:not(:last-child) {
    margin-bottom: 16px;
}

.input-radio-simple__label {
    display: flex;
    align-items: flex-start;
    position: relative;
    min-width: 0;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.input-radio-simple__label.input-radio-simple__label_center {
    align-items: center;
}

.input-radio-simple__label::before {
    content: "";
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 12px;
    border-radius: 50%;
    border: 1px solid var(--border-dark-c);
    background-color: var(--light-c);
    transition: border-color .1s ease-in;
}

.input-radio-simple__input:disabled + .input-radio-simple__label {
    cursor: default;
    pointer-events: none;
}

.input-radio-simple__input:disabled + .input-radio-simple__label::before {
    background-color: var(--bright-bg);
}

.input-radio-simple__input:not(:disabled):not(:checked) + .input-radio-simple__label:hover::before {
    border-color: var(--border-light-c);
    transition: border-color var(--transition);
}

.input-radio-simple__input:checked + .input-radio-simple__label::before {
    border-width: 6px;
    border-color: var(--primary-color);
    transition: border-color .1s ease-in;
}
</style>