<template>
    <div>
        <Spinner
            v-if="!initialized"
            size="big"
            center
        ></Spinner>
        <section v-else>
            <h1 class="h2 h2_block">
                <span>Отзывы о&nbsp;запчасти</span> {{ newPart.name }}<span
                    v-if="newPart.reviews_amount"
                    class="h2-sub"
                >{{ newPart.reviews_amount }}</span>
            </h1>

            <div
                v-if="!loading && newPart.reviews_amount"
                class="reviews-section-content layer-1"
            >
                <div class="reviews-layout">
                    <div class="reviews-aside">
                        <div class="reviews-aside__actions">
                            <InfoMessage
                                v-if="isAuthorized && newPart.has_review"
                                class="reviews-aside__message"
                            >
                                Вы уже оставили отзыв к&nbsp;этой запчасти.
                            </InfoMessage>

                            <ButtonBlock
                                v-if="!newPart.has_review"
                                primary
                                :disabled="!isAuthorized"
                                class="reviews-aside__action-btn"
                                @click="showMarketItemReviewPopup"
                            >
                                Оставить отзыв
                            </ButtonBlock>

                            <p
                                v-if="!isAuthorized"
                                class="mt-16"
                            >
                                <UButtonText
                                    primary
                                    underline
                                    @click="openSignInPopup"
                                >Войдите</UButtonText>, чтобы оставить отзыв
                            </p>

                            <div class="sort-block sort-block_mobile">
                                <SimpleSelectBase
                                    :value="sortData"
                                    :options="sortOptions"
                                    @change="onChangeSort"
                                ></SimpleSelectBase>
                            </div>

                            <div class="reviews-filter">
                                <ul class="reviews-filter__list">
                                    <li
                                        v-for="index in 5"
                                        :key="'reviews-filter__item-' + index"
                                        class="reviews-filter__item"
                                    >
                                        <div class="reviews-filter__option">
                                            <StarsRating
                                                :showValue="false"
                                                :value="6 - index"
                                                :blocked="reviewsAmounts[5 - index].reviews_amount === 0"
                                            ></StarsRating>
                                            <span
                                                class="reviews-filter__option-label"
                                                :class="{
                                                    'text_secondary': reviewsAmounts[5 - index].reviews_amount === 0
                                                }"
                                            >{{ reviewsAmountText(5 - index) }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="reviews-main">
                        <div class="top-filter">
                            <div class="sort-block sort-block_desktop">
                                <span class="sort-block__label">Сортировка:</span>

                                <SimpleSelectBase
                                    :value="sortData"
                                    :options="sortOptions"
                                    @change="onChangeSort"
                                ></SimpleSelectBase>
                            </div>
                        </div>

                        <EmptyBlock
                            v-if="reviews.current_count === 0"
                            icon="filter"
                            title="Нет подходящих отзывов"
                            text="Попробуйте изменить условия поиска."
                            :hasBtn="false"
                            class="no-shadow"
                        ></EmptyBlock>

                        <div
                            v-else
                            class="reviews-list"
                        >
                            <PagesReview
                                v-for="(item, index) in reviews.items"
                                :key="'market-part-review-' + index"
                                :part="newPart"
                                :review="item"
                                class="reviews-list-item"
                                @update="fetch"
                            ></PagesReview>
                        </div>
                    </div>
                </div>
            </div>

            <EmptyBlock
                v-if="!loading && !newPart.reviews_amount"
                icon="review"
                title="Отзывов ещё нет"
                text="Будьте первым, кто поделится мнением об этой запчасти."
            >
                <template #button>
                    <ButtonBlock
                        primary
                        low
                        class="empty__btn"
                        :disabled="!isAuthorized"
                        @click="showMarketItemReviewPopup"
                    >
                        Оставить отзыв
                    </ButtonBlock>
                    <p
                        v-if="!isAuthorized"
                        class="empty__btn empty__login"
                    >
                        <UButtonText
                            primary
                            underline
                            @click="openSignInPopup"
                        >Войдите</UButtonText>, чтобы оставить отзыв.
                    </p>
                </template>
            </EmptyBlock>

            <Spinner
                v-if="loading"
                size="big"
                center
            ></Spinner>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import marketNewPartReviews from '@/store/modules/marketNewPartReviews.js';
import { HTTP } from '@/http.js';
import plural from '@/lib/plural.js';
import PagesReview from '@/components/PagesReview.vue';
import SimpleSelectBase from '@/components/SimpleSelectBase.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import Spinner from '@/components/Spinner.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import StarsRating from '@/components/StarsRating.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const MarketItemReviewPopup = () => import('@/components/popups/MarketItemReviewPopup.vue');
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');

export default {
    name: 'MarketPartReviewsPage',

    components: {
        UButtonText,
        StarsRating,
        InfoMessage,
        Spinner,
        ButtonBlock,
        EmptyBlock,
        PagesReview,
        SimpleSelectBase,
    },

    serverPrefetch() {
        return this.INIT({ id: this.$route.params.id, query: this.$route.query });
    },

    data() {
        return {
            source: null,
            rangeData: {
                limit: 20,
                offset: 0,
            },
        };
    },

    computed: {
        ...mapState({
            newPart: state => state.marketNewPart.newPart,
            initialized: state => state.marketNewPartReviews.initialized,
            loading: state => state.marketNewPartReviews.loading,
            reviews: state => state.marketNewPartReviews.reviews,
            reviewsAmounts: state => state.marketNewPartReviews.reviews.amounts,
            sortOptions: state => state.marketNewPartReviews.sort.options,
            sortData: state => state.marketNewPartReviews.sort.value,
            isAuthorized: state => state.profile.isAuthorized,
        }),

        ...mapGetters({
            sortQuery: 'marketNewPartReviews/sort/query',
        }),

        fetchParams() {
            return Object.assign(
                {},
                this.sortQuery,
                this.rangeData,
            );
        },

        location() {
            return {
                name: this.$route.name,
                params: {
                    id: this.$route.params.id,
                },
                query: Object.assign(
                    {},
                    this.sortQuery,
                ),
                meta: {
                    savePosition: true,
                },
            };
        },
    },

    created() {
        this.$store.registerModule('marketNewPartReviews', marketNewPartReviews);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.$store.unregisterModule('marketNewPartReviews');
    },

    methods: {
        ...mapMutations({
            SET_REVIEWS: 'marketNewPartReviews/setReviews',
            ADD_REVIEWS: 'marketNewPartReviews/addReviews',
            SET_SORT_VALUE: 'marketNewPartReviews/sort/setValue',
            SET_LOADING: 'marketNewPartReviews/setLoading',
        }),

        ...mapActions({
            UPDATE_PART: 'marketNewPart/updateNewPart',
            INIT: 'marketNewPartReviews/init',
            GET_REVIEWS: 'marketNewPartReviews/getReviews',
            UPDATE_FILTER: 'marketNewPartReviews/getReviewsAmounts',
        }),

        async init() {
            if (!this.initialized) {
                await this.INIT({
                    id: this.$route.params.id,
                    query: this.$route.query,
                });
            }
            else {
                if (this.newPart.reviews_amount && this.reviews.current_count === 0) {
                    await this.UPDATE_REVIEWS({ id: this.newPart.id });
                }
            }
            await this.replaceQuery();
        },

        onChangeSort(value) {
            this.SET_SORT_VALUE(value);
            this.reload();
        },

        onChangeFilter($event) {
            this.CHANGE_FILTER($event);
        },

        isNewRoute() {
            const toPath = this.$router.resolve(this.location).route.fullPath;
            return this.$route.fullPath !== toPath;
        },

        async updateQuery() {
            if (this.isNewRoute()) {
                try {
                    return await this.$router.push(this.location);
                }
                catch (e) {
                    console.error(e);
                }
            }
            else {
                return '';
            }
        },

        async replaceQuery() {
            if (this.isNewRoute()) {
                try {
                    return await this.$router.replace(this.location);
                }
                catch (e) {
                    console.error(e);
                }
            }
            else {
                return '';
            }
        },

        async fetch() {
            this.SET_LOADING(true);
            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                id: this.$route.params.id,
                params: this.fetchParams,
                cancelToken: this.source.token,
            };

            const response = await this.GET_REVIEWS(payload);
            if (response) {
                this.source = null;
                await this.updateQuery();
                this.SET_LOADING(false);
                return response;
            }
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) {
                this.ADD_REVIEWS(response);
                return response;
            }
        },

        async reload() {
            this.rangeData.offset = 0;
            const response = await this.fetch();
            if (response) {
                this.SET_REVIEWS(response);
                return response;
            }
        },

        showMarketItemReviewPopup() {
            const props = {
                part: this.newPart,
                callback: this.updateReviews,
            };
            const options = { props };
            this.$popup(MarketItemReviewPopup, options);
        },

        updateReviews() {
            this.reload();
            this.UPDATE_FILTER({
                id: this.$route.params.id,
                query: this.$route.query,
            });
            this.UPDATE_PART({ id: this.newPart.id });
        },

        reviewsAmountText(index) {
            let n = this.reviews.amounts[index].reviews_amount;
            if (n === 0) {
                return 'Нет отзывов';
            }
            else {
                return n + ' ' + plural(n, ['отзыв', 'отзыва', 'отзывов']);
            }
        },

        openSignInPopup() {
            this.$popup(SignInPopup);
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .reviews-section-content {
        padding: 24px var(--base-card-padding-x);
    }
}
@media (max-width: 1039px) {
    .reviews-section-content {
        padding: var(--base-card-padding);
    }
}

@media (min-width: 1040px) {
    .reviews-layout {
        display: flex;
        flex-direction: row-reverse;
    }
}

.top-filter {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--border-light-c);
}
@media (max-width: 1039px) {
    .top-filter {
        display: none;
    }
}

.sort-block {
    display: flex;
    font-size: var(--base-fz);
}
@media (min-width: 1040px) {
    .sort-block_mobile {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .sort-block {
        margin-left: 20px;
        flex-shrink: 0;
    }
}

.sort-block__label {
    margin-right: 12px;
    color: var(--font-secondary-color);
}

.reviews-list {
    margin-top: var(--gap-y-small);
}

.reviews-list-item:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .reviews-list-item:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .reviews-list-item:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
    }
}

@media (min-width: 1040px) {
    .reviews-main {
        flex-grow: 1;
    }
}

@media (min-width: 1281px) {
    .reviews-aside {
        width: 280px;
        flex-shrink: 0;
        margin-left: 120px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .reviews-aside {
        width: 220px;
        flex-shrink: 0;
        margin-left: 48px;
    }
}
@media (max-width: 1039px) {
    .reviews-aside {
        margin-bottom: 24px;
    }
}

@media (max-width: 1039px) {
    .reviews-aside__actions {
        margin-bottom: var(--gap-y-mini);
        padding-bottom: var(--gap-y-mini);
        border-bottom: 1px solid var(--border-light-c);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .reviews-aside__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 1039px) {
    .reviews-aside__message {
        margin-right: auto;
    }
}
@media (max-width: 767px) {
    .reviews-aside__message {
        width: 100%;
        margin-bottom: 20px;
    }
}

.no-shadow {
    box-shadow: none;
}

@media (max-width: 1039px) {
    .reviews-filter {
        display: none;
    }
}

@media (min-width: 1040px) {
    .reviews-filter__list {
        margin-top: 36px;
    }
}

.reviews-filter__item:not(:last-child) {
    margin-bottom: 16px;
}

.reviews-filter__option {
    display: flex;
}

.reviews-filter__option-label {
    margin-left: 12px;
}
</style>