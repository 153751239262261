<template>
    <div
        class="review-wrap"
        :class="{ 'review-wrap_blocked': !review.is_active }"
    >
        <div class="item">
            <ShopLogo
                v-if="shop"
                :shop="review.shop"
                coverCodename="medium"
                class="item__logo-wrap"
            ></ShopLogo>
            <MarketItemCover
                v-else
                :item="part || product"
                :modelName="part ? 'part_product' : 'product'"
                coverCodename="mini"
                class="item__logo-wrap"
            ></MarketItemCover>

            <div class="item__main">
                <template v-if="shop">
                    <ShopName
                        :shop="review.shop"
                        class="item__name link-primary-hover"
                    ></ShopName>

                    <ShopAddress
                        :shop="review.shop"
                        :showOnMap="false"
                        class="text_adaptive-small-fz"
                    ></ShopAddress>
                </template>

                <MarketItemName
                    v-else
                    :id="(part || product).id"
                    :modelName="part ? 'part_product' : 'product'"
                    :name="(part || product).name"
                ></MarketItemName>

                <div class="mt-8 flex align-items-center">
                    <StarsRating
                        class="rating"
                        :value="review.rating"
                        :showValue="false"
                        :blocked="!review.is_active"
                    ></StarsRating>

                    <p class="review__date">
                        {{ review.create_date | dt }}
                    </p>
                </div>
            </div>

            <UTooltip
                v-if="!review.is_active"
                noDetach
                maxWidth="240"
                class="error-wobbler-block"
            >
                <template #trigger="{ attrs, handlers }">
                    <span
                        v-bind="attrs"
                        class="error-wobbler"
                        v-on="handlers"
                    >
                        Заблокирован
                    </span>
                </template>

                Отзыв был заблокирован модератором за несоблюдение правил использования сервиса.
            </UTooltip>

            <UMenu
                v-else
                noDetach
                placement="left"
                class="menu-block"
            >
                <UMenuItem
                    v-if="shop"
                    :to="{
                        name: 'market-shop',
                        params: {
                            codename: shop.company.codename,
                            id: shop.id
                        }
                    }"
                >
                    Перейти к магазину
                </UMenuItem>
                <UMenuItem
                    v-else-if="product"
                    :to="{
                        name: 'market-product-description',
                        params: {
                            id: product.id
                        }
                    }"
                >
                    Перейти к товару
                </UMenuItem>
                <UMenuItem
                    v-else
                    :to="{
                        name: 'market-new-part-description',
                        params: {
                            id: part.id
                        }
                    }"
                >
                    Перейти к товару
                </UMenuItem>
                <UMenuItem
                    v-if="review.is_editable"
                    @click="handleClickEditReview"
                >
                    Редактировать отзыв
                </UMenuItem>
                <UMenuItem
                    v-if="review.response && review.response.is_active"
                    @click="handleClickComplainResponse"
                >
                    Пожаловаться на ответ
                </UMenuItem>
                <UMenuItem
                    v-if="review.is_editable"
                    @click="handleClickRemoveReview"
                >
                    <span class="text_red">Удалить отзыв</span>
                </UMenuItem>
            </UMenu>
        </div>

        <div class="review">
            <div class="review__main">
                <div
                    v-if="!shop"
                    class="review-section"
                >
                    <h3 class="title">
                        Срок использования:
                    </h3>
                    <p class="review-text">
                        {{ review.usage_time_name.toLowerCase() }}
                    </p>
                </div>
                <div
                    v-if="review.advantages"
                    class="review-section"
                >
                    <h3 class="title">
                        Достоинства:
                    </h3>
                    <p class="review-text p-textarea">{{ review.advantages }}</p>
                </div>
                <div
                    v-if="review.disadvantages"
                    class="review-section"
                >
                    <h3 class="title">
                        Недостатки:
                    </h3>
                    <p class="review-text p-textarea">{{ review.disadvantages }}</p>
                </div>
                <div class="review-section">
                    <h3
                        v-if="!review.shop"
                        class="title"
                    >
                        Комментарий:
                    </h3>
                    <p class="review-text p-textarea">{{ review.comment }}</p>
                </div>

                <div
                    v-if="review.photos && review.photos.length"
                    class="review-section photos-row"
                >
                    <button
                        v-for="(item, index) in review.photos"
                        :key="'photo-' + item.uuid"
                        class="photo-btn"
                        @click="showPhotoViewerPopup(index)"
                    >
                        <img
                            :src="$links.uploads + item.thumbnails.review_photo_mini"
                            alt=""
                            class="photo"
                        >
                    </button>
                </div>
            </div>
        </div>

        <div
            v-if="review.response && review.response.is_active"
            class="answer-wrap"
        >
            <div class="answer">
                <span class="answer-date">{{ review.response.create_date | dt }}</span>
                <span class="answer-title">Ответ магазина</span>
                <p class="answer-text p-textarea">{{ review.response.comment }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import StarsRating from './StarsRating.vue';
import ShopLogo from './ShopLogo.vue';
import MarketItemCover from './MarketItemCover.vue';
import ShopName from './ShopName.vue';
import MarketItemName from './MarketItemName.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UMenu from '@ui/components/UMenu/UMenu.vue';
import UMenuItem from '@ui/components/UMenu/UMenuItem.vue';
import ShopAddress from '@/components/ShopAddress.vue';
const ComplainReviewResponsePopup = () => import('@/pages/market/shops/_id/ComplainReviewResponsePopup.vue');
const RemoveReviewPopup = () => import('@/pages/account/reviews/RemoveReviewPopup.vue');
const ShopReviewPopup = () => import('@/components/popups/ShopReviewPopup.vue');
const MarketItemReviewPopup = () => import('@/components/popups/MarketItemReviewPopup.vue');
const PhotoViewer = () => import('@/components/popups/PhotoViewer.vue');

export default {
    name: 'ProfileReview',

    components: {
        ShopAddress,
        UMenuItem,
        UMenu,
        UTooltip,
        MarketItemName,
        ShopName,
        MarketItemCover,
        ShopLogo,
        StarsRating,
    },

    props: {
        review: Object,
        part: Object,
        product: Object,
        shop: Object,
    },

    methods: {
        showPhotoViewerPopup(index) {
            const props = {
                index,
                photos: this.info.photos,
                fullCoverCodename: 'review_photo_pv',
                miniCoverCodename: 'review_photo_mini',
            };
            const options = { props };
            this.$popup(PhotoViewer, options);
        },

        handleClickEditReview() {
            const callback = value => value && this.$emit('update');

            const props = {
                callback,
                shop: this.shop,
                part: this.part,
                product: this.product,
                review: this.review,
                edit: true,
            };

            const options = { props };
            this.$popup(this.shop ? ShopReviewPopup : MarketItemReviewPopup, options);
        },

        handleClickComplainResponse() {
            const shop = this.shop;
            const review = this.review;
            const props = { shop, review };
            const options = { props };
            this.$popup(ComplainReviewResponsePopup, options);
        },

        handleClickRemoveReview() {
            const callback = value => value && this.$emit('update');
            const shop = this.shop;
            const part = this.part;
            const product = this.product;
            const review = this.review;
            const props = { shop, part, product, review, callback };
            const options = { props };
            this.$popup(RemoveReviewPopup, options);
        },
    },
};
</script>

<style scoped>
.review-wrap_blocked > *:not(.error-wobbler ) {
    color: var(--font-secondary-color);
}

.review-wrap_blocked .item__logo-wrap {
    opacity: 0.3;
}

.error-wobbler-block,
.menu-block {
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .error-wobbler-block,
    .menu-block {
        margin-left: 20px;
    }
}
@media (max-width: 767px) {
    .error-wobbler-block,
    .menu-block {
        margin-left: 12px;
        flex-shrink: 0;
    }
}

.error-wobbler {
    padding: 6px 12px;
    flex-shrink: 0;
    font-size: 12px;
    font-family: var(--f-semi-bold);
    color: var(--primary-color);
    background-color: var(--primary-light-color);
    border-radius: var(--border-radius);
}

@media (min-width: 1024px) {
    .review {
        padding: 24px 36px;
    }
}
@media (max-width: 1023px) {
    .review {
        padding: var(--base-card-padding);
    }
}
@media (min-width: 768px) {
    .review {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
    }
}

.review__main {
    flex-grow: 1;
}

@media (min-width: 768px) {
    .review-section:not(:first-child) {
        margin-top: 16px;
    }
}
@media (max-width: 767px) {
    .review-section:not(:first-child) {
        margin-top: 12px;
    }
}

.title {
    margin-bottom: 4px;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}

.review-text {
    line-height: var(--base-lh);
}

.photos-row {
    font-size: 0;
}

.photo-btn {
    position: relative;
    width: 64px;
    height: 64px;
    background-color: var(--light-bg);
    border-radius: var(--border-radius);
    overflow: hidden;
}
.photo-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-active-bg);
    opacity: 0;
    transition: opacity var(--transition);
}
.photo-btn:hover::before,
.photo-btn:focus::before {
    opacity: .4;
}

.photo-btn:not(:last-child) {
    margin-right: 8px;
}

.review__date {
    color: var(--font-secondary-color);
    margin-left: 12px;
}
@media (max-width: 767px) {
    .review__date {
        font-size: var(--small-fz);
    }
}

@media (min-width: 1024px) {
    .answer-wrap {
        padding: 0 36px 24px;
    }
}
@media (max-width: 1023px) {
    .answer-wrap {
        padding: 0 var(--base-card-padding-x) var(--base-card-padding-y);
    }
}
.answer {
    position: relative;
    background-color: var(--bright-bg);
    border-radius: var(--border-radius);
}
@media (min-width: 768px) {
    .answer {
        padding: 20px 24px;
    }
}
@media (max-width: 767px) {
    .answer {
        padding: 16px;
    }
}

.answer-title {
    font-family: var(--f-bold);
    color: var(--font-secondary-color);
}

.answer-text {
    line-height: var(--base-lh);
}
@media (min-width: 1280px) {
    .answer-text {
        max-width: 556px;
    }
}
@media (min-width: 768px) {
    .answer-text {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .answer-text {
        margin-top: 4px;
    }
}

.answer-date {
    display: block;
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .answer-date {
        position: absolute;
        right: 24px;
        top: 20px;
    }
}
@media (max-width: 767px) {
    .answer-date {
        margin-bottom: 12px;
    }
}

.item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1024px) {
    .item {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .item {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .item {
        padding: 16px;
    }
}

@media (min-width: 1024px) {
    .item__logo-wrap {
        margin-right: 24px;
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .item__logo-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .item__logo-wrap {
        margin-right: 12px;
    }
}

.item__main {
    flex-grow: 1;
}

.item__name {
    display: block;
    margin-bottom: 4px;
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
}
</style>