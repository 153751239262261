<template>
    <div class="u-field-text">
        <UTextareaField
            :invalid="invalid"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template
                v-for="slotName in Object.keys($scopedSlots)"
                #[slotName]="payload"
            >
                <slot :name="slotName" v-bind="payload"></slot>
            </template>
        </UTextareaField>

        <UFormFieldError
            :show="invalid"
            class="u-field-text__error"
        >
            {{ error }}
        </UFormFieldError>
    </div>
</template>

<script>
import fieldMixin from '@ui/components/UForm/fields/fieldMixin.js';
import UTextareaField from '@ui/components/UTextarea/UTextareaField.vue';
import UFormFieldError from '@ui/components/UForm/UFormFieldError.vue';


export default {
    name: 'UFieldText',

    components: {
        UFormFieldError,
        UTextareaField,
    },

    mixins: [
        fieldMixin,
    ],

    data() {
        return {
            initialValue: '',
        };
    },
};
</script>

<style scoped>
.u-field-text__error .u-form-field-error {
    margin-top: 8px;
}
</style>