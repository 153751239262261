<template>
    <component
        :is="tag"
        type="button"
        tabindex="-1"
        class="u-button-round"
        :class="{
            'u-button-round_primary': primary,
            'u-button-round_shadow': shadow,
            'u-button-round_small': small,
            'u-button-round_mini': mini,
        }"
        :disabled="disabled"
        :aria-label="label"
        v-on="$listeners"
    >
        <UIcon
            :name="name"
            :small="small"
            :tiny="mini"
            class="u-button-round__icon"
        ></UIcon>
    </component>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'UButtonRound',

    components: { UIcon },

    props: {
        name: String,
        primary: Boolean,
        disabled: Boolean,

        shadow: Boolean,

        button: Boolean,

        small: Boolean,
        mini: Boolean,

        label: String,
    },

    computed: {
        tag() {
            if (this.$listeners.click || this.button) return 'button';
            return 'span';
        },
    },
};
</script>

<style>
.u-button-round {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: var(--font-secondary-dark-color);
    border: 1px solid var(--border-dark-c);
    border-radius: 50%;
    background-color: var(--light-c);
    transition: all var(--transition);
}
.u-button-round:hover:not(:disabled),
.u-button-round:focus:not(:disabled) {
    background-color: var(--lightest-bg);
}
.u-button-round:disabled {
    color: var(--light-bg);
    border-color: var(--border-light-c);
}
.u-button-round.u-button-round_primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.u-button-round.u-button-round_primary:hover:not(:disabled),
.u-button-round.u-button-round_primary:focus:not(:disabled){
    background-color: var(--primary-dark-color);
    border-color: var(--primary-dark-color);
}
.u-button-round.u-button-round_primary:disabled {
    color: var(--primary-light-color);
    border-color: var(--primary-light-color);
}

.u-button-round_small {
    width: 36px;
    height: 36px;
}

.u-button-round_mini {
    width: 24px;
    height: 24px;
}

.u-button-round_shadow {
    color: var(--font-secondary-color);
    background-color: var(--light-c);
    border: none;
    box-shadow: var(--base-shadow);
}

.u-button-round_shadow:hover:not(:disabled),
.u-button-round_shadow:focus:not(:disabled) {
    color: var(--dark-c);
    background-color: var(--light-c);
}

.u-button-round__icon {
    fill: currentColor;
}
.u-button-round.u-button-round_primary .u-button-round__icon {
    fill: var(--light-c);
}
</style>
