<template>
    <div class="u-radio__list">
        <div
            v-for="option in options"
            :key="getOptionKey(option)"
            class="u-radio__item"
            :class="{
                'u-radio__item_checked': optionComparator(innerValue, option),
            }"
        >
            <input
                :id="getOptionKey(option) + id"
                type="radio"
                :value="reduce(option)"
                :checked="optionComparator(innerValue, option)"
                class="visually-hidden u-radio__input"
                v-on="{
                    ...handlers,
                    ...listeners,
                }"
            >

            <slot
                :id="getOptionKey(option) + id"
                name="label"
                :option="option"
                :label="getOptionLabel(option)"
            >
                <label
                    :for="getOptionKey(option) + id"
                    class="u-radio__label"
                >
                    {{ getOptionLabel(option) }}
                </label>
            </slot>
        </div>
    </div>
</template>

<script>
import genId from '@ui/utils/genId.js';
import options from '@ui/mixins/options.js';
import sortAndStringify from '@ui/utils/sortAndStringify.js';
import equals from '@ui/utils/equals.js';


export default {
    name: 'URadio',

    mixins: [options],

    props: {
        id: {
            type: String,
            default: () => genId(),
        },

        value: {
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        small: Boolean,

        options: {
            type: Array,
            required: true,
        },

        getOptionLabel: {
            type: Function,
            default(option) {
                if (typeof option === 'object' && option != null) {
                    return option[this.optionLabel];
                }
                else {
                    return option;
                }
            },
        },

        getOptionKey: {
            type: Function,
            default(option) {
                if (typeof option === 'object' && option != null) {
                    if (option[this.optionKey] !== undefined) {
                        return option[this.optionKey];
                    }

                    if (option.name !== undefined) {
                        return option.name;
                    }

                    return sortAndStringify(option);
                }
                else {
                    return option;
                }
            },
        },

        optionComparator: {
            type: Function,
            default(option1, option2) {
                return this.getOptionKey(option1) === this.getOptionKey(option2);
            },
        },

        reduce: {
            type: Function,
            default(value) {
                return this.getOptionKey(value);
            },
        },
    },

    data() {
        const options = this.$options.propsData.options;
        const innerValue = Array.isArray(options) && options[0] ? this.reduce(options[0]) : null;

        return {
            innerValue,
        };
    },

    computed: {
        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { change, ...listeners } = this.$listeners;
            return listeners;
        },

        handlers() {
            return {
                change: this.onChange,
            };
        },
    },

    watch: {
        value: {
            handler(value) {
                if (!equals(this.value, this.innerValue)) {
                    this.innerValue = value;
                }
            },

            immediate: true,
        },

        innerValue(value) {
            if (!equals(this.value, value)) {
                this.emitChange();
            }
        },
    },

    methods: {
        onChange($event) {
            try {
                this.innerValue = $event.target.value ? JSON.parse($event.target.value) : null;
            }
            catch (e) {
                this.innerValue = $event.target.value;
            }
        },

        emitChange() {
            const target = this;
            const value = this.innerValue;
            this.$emit('change', { target, value });
        },
    },
};
</script>

<style>
.u-radio__list {
    position: relative;
}

.u-radio__item:not(:last-child) {
    margin-bottom: 16px;
}

.u-radio__label {
    display: flex;
    align-items: flex-start;
    position: relative;
    min-width: 0;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.u-radio__label::before {
    content: "";
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 12px;
    border-radius: 50%;
    border: 1px solid var(--border-dark-c);
    background-color: var(--light-c);
    transition: border-color var(--transition), border-width .1s ease-out;
}

.u-radio__input:disabled + .u-radio__label {
    cursor: default;
    pointer-events: none;
}

.u-radio__input:disabled + .u-radio__label::before {
    background-color: var(--bright-bg);
}

.u-radio__input:not(:disabled):not(:checked) + .u-radio__label:not(:active):hover::before {
    border-color: var(--border-light-c);
    transition: border var(--transition);
}

.u-radio__input:not(:disabled):not(:checked) + .u-radio__label:hover::before {
    border-color: var(--primary-color);
    border-width: 10px;
    transition: border .1s ease-out;
}

.u-radio__input:checked + .u-radio__label::before {
    border-width: 6px;
    border-color: var(--primary-color);
    transition: border .1s ease-in;
}
</style>