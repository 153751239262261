<template>
    <PopupWrap
        noPadding
        :loading="loading"
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="head head_desktop">
                <ShopLogo
                    :shop="{ company }"
                    class="logo"
                    coverCodename="big"
                    noLink
                ></ShopLogo>

                <div>
                    <h2 class="h2 mb-8">
                        Магазины компании {{ company.name }}
                    </h2>
                    <span class="text_secondary text_semi-bold text_adaptive-big-fz">{{ branchesAmountText }}</span>
                </div>
            </div>

            <div class="head head_mobile">
                <h2 class="h2 popup-title">
                    Компания {{ company.name }}
                </h2>
                <span class="mt-8 text_secondary text_semi-bold">{{ branchesAmountText }}</span>
            </div>

            <div class="toolbar">
                <div class="view-toggler">
                    <RadioButtonsGroup
                        v-model="view"
                        name="offers"
                        type="text"
                        :options="[
                            {
                                label: 'Список',
                                value: 'list',
                            },
                            {
                                label: 'Карта',
                                value: 'map',
                            },
                        ]"
                        @change="onChangeView"
                    ></RadioButtonsGroup>
                </div>
            </div>

            <div
                class="content-wrap"
                :class="{ 'content-wrap_list': view === 'list' }"
            >
                <ol
                    v-if="view === 'list'"
                    class="branches-list view_list"
                >
                    <li
                        v-for="(item, itemIndex) in shops"
                        :key="`shop-${ item.id }`"
                        class="branch"
                    >
                        <div class="flex justify-content-between mb-12">
                            <div class="shop-address-wrap">
                                <button
                                    type="button"
                                    class="shop-address-btn link-primary-hover"
                                    @click="showOfferOnMap({ shop: item, index: itemIndex })"
                                >
                                    г. {{ item.city.name }}, {{ item.address }}
                                </button>

                                <ShopOpenCloseTime
                                    :shopId="item.id"
                                    :status="item.current_work"
                                ></ShopOpenCloseTime>
                            </div>

                            <div class="market-item__actions ml-12">
                                <LikeBtn
                                    :id="item.id"
                                    :value="item.is_favorite"
                                    apiName="shop"
                                    class="market-item__action-btn"
                                    @changeFavorite="onChangeFavorite($event, itemIndex)"
                                ></LikeBtn>

                                <ChatBtn
                                    :id="item.id"
                                    :isOnline="(((item.current_work) || {}).place || {}).is_active"
                                    :disabled="!item.is_chat_active"
                                    class="market-item__action-btn"
                                    @click="close"
                                ></ChatBtn>
                            </div>
                        </div>

                        <div class="rating-link-wrap">
                            <MarketItemRatingReviewsLink
                                :item="item"
                                modelName="shop"
                                class="rating-wrap"
                            ></MarketItemRatingReviewsLink>

                            <ButtonText
                                :to="{
                                    name: 'market-shop-stock',
                                    params: {
                                        codename: company.codename,
                                        id: item.id,
                                    },
                                }"
                                secondary
                                dark
                                underline
                                class="rating-link-wrap__link"
                            >
                                На страницу магазина
                            </ButtonText>
                        </div>
                    </li>
                </ol>

                <div
                    v-if="view === 'map'"
                    class="map-wrap"
                ></div>
                <div
                    v-if="shops && shops.length"
                    ref="map"
                    class="map"
                    :class="{ 'visually-hidden': view === 'list' }"
                >
                    <yandexMap
                        ref="mapComponent"
                        :coords="getCoordsArray(shops[0])"
                        zoom="10"
                        :controls="['zoomControl']"
                        :scrollZoom="false"
                        :options="{ suppressMapOpenBlock: true }"
                        style="width: 100%; height: 100%;"
                        @map-was-initialized="setMapCenter"
                        @actionend="handleActionEnd"
                    >
                        <ymapMarker
                            v-for="(item, index) in shops"
                            :key="'item-' + index"
                            :ref="'item-' + index"
                            :coords="getCoordsArray(item)"
                            :icon="getYMapMarkerIcon({ active: balloon.visible && balloon.index === index })"
                            :markerId="'item-' + index"
                            @click="showBalloon({ shop: item, index })"
                        ></ymapMarker>
                    </yandexMap>

                    <ShopBalloon
                        v-if="balloon.visible"
                        :shop="balloon.shop"
                        :company="companyDetails"
                        @close="closeBalloon"
                        @clickChat="close"
                        @changeFavorite="onChangeFavorite($event, balloon.index)"
                    ></ShopBalloon>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import { HTTP } from '@/http.js';
import { mapState } from 'vuex';
import plural from '@/lib/plural.js';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import popup from '@/mixins/popup.js';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import ChatBtn from '../project-buttons/ChatBtn.vue';
import ShopBalloon from '../ShopBalloon.vue';
import ShopOpenCloseTime from '../ShopOpenCloseTime.vue';
import RadioButtonsGroup from '../RadioButtonsGroup.vue';
import LikeBtn from '../project-buttons/LikeBtn.vue';
import ButtonText from '../_buttons/ButtonText.vue';
import ShopLogo from '../ShopLogo.vue';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';

export default {
    name: 'ShopsPopup',

    components: {
        MarketItemRatingReviewsLink,
        ShopLogo,
        ButtonText,
        ShopBalloon,
        LikeBtn,
        RadioButtonsGroup,
        ShopOpenCloseTime,
        PopupWrap,
        ChatBtn,
        yandexMap,
        ymapMarker,
    },

    mixins: [ymapMarkerMixin, popup],

    props: {
        company: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            loading: true,
            companyDetails: {},
            shops: [],
            view: 'list',
            mapInstance: null,
            mapCenter: [],
            balloon: {
                shop: {},
                index: null,
                visible: false,
            },
        };
    },

    computed: {
        ...mapState({
            currentCityId: state => state.cities.currentCityId,
        }),

        branchesAmountText() {
            const n = (this.shops || []).length;
            return n + ' ' + plural(n, ['филиал', 'филиала', 'филиалов']);
        },

        singleViewTogglerOptions() {
            if (this.view === 'list') {
                return [
                    {
                        label: 'На карте',
                        value: 'map',
                    },
                ];
            }
            else {
                return [
                    {
                        label: 'Списком',
                        value: 'list',
                    },
                ];
            }
        },
    },

    created() {
        this.init();
    },

    methods: {
        async init() {
            const [ company, shops ] = await Promise.all([
                HTTP.get(`/companies/${ this.company.id }/`, {
                    params: {
                        city_id__in: this.currentCityId,
                    },
                }),
                HTTP.get('/companies/' + this.company.id + '/shops/', {
                    params: {
                        city_id__in: this.currentCityId,
                        order_by: 'name',
                    },
                }),
            ]);

            this.companyDetails = company.data;
            this.shops = shops.data.results;
            this.mapCenter = this.getCoordsArray(this.shops[0]);
            this.loading = false;
        },

        setMapCenter(mapInstance) {
            this.mapInstance = mapInstance;
            if (this.shops.length > 1) {
                let pointsList = [];
                this.shops.forEach(item => {
                    pointsList.push(this.getCoordsArray(item));
                });
                mapInstance.setBounds(ymaps.util.bounds.fromPoints(pointsList));
            }
        },

        handleActionEnd(e) {
            const newCenter = e.originalEvent.map.getCenter();
            if (this.mapCenter[0] !== newCenter[0] || this.mapCenter[1] !== newCenter[1]) {
                this.mapCenter = newCenter;
            }
        },

        showOfferOnMap({ shop, index }) {
            this.view = 'map';
            this.showBalloon({ shop, index });
        },

        showBalloon({ shop, index }) {
            this.balloon.visible = true;
            this.balloon.shop = shop;
            this.balloon.index = index;
            this.$nextTick(() => {
                this.mapInstance.setCenter(this.mapCenter);
            });
        },

        closeBalloon() {
            this.balloon.visible = false;
            this.balloon.shop = {};
            this.balloon.index = null;
            this.$nextTick(() => {
                this.mapInstance.setCenter(this.mapCenter);
            });
        },

        getCoordsArray(item) {
            return item.coordinates.split(', ').map(Number);
        },

        onChangeView(value) {
            if (value === 'map') {
                this.setMapCenter(this.mapInstance);
                this.$nextTick(() => {
                    this.mapInstance.container.fitToViewport();
                });
            }
            this.closeBalloon();
        },

        onChangeFavorite(value, index) {
            this.shops[index].is_favorite = value;
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 776px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}
@media (max-width: 767px) {
    .popup-content {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
}

.head {
    padding: var(--popup-paddings-y) var(--popup-paddings-x) var(--gap-y-small);
}
@media (min-width: 768px) {
    .head_desktop {
        display: flex;
        align-items: center;
    }

    .head_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .head_desktop {
        display: none;
    }
}

.logo {
    margin-right: 20px;
}

.toolbar {
    padding: 12px var(--popup-paddings-x);
    border-top: 1px solid var(--border-light-c);
}

.content-wrap {
    position: relative;
    /*overflow: auto;*/
    flex-grow: 1;
}

.content-wrap_list {
    border-top: 1px solid var(--border-light-c);
}

.shop-address-btn {
    display: block;
    margin-bottom: 4px;
    font-family: var(--f-semi-bold);
    text-align: left;
    white-space: normal;
}

@media (min-width: 768px) {
    .rating-link-wrap {
        display: flex;
        align-items: center;
    }

    .rating-link-wrap__link {
        margin-left: auto;
    }
}
@media (max-width: 767px) {
    .rating-wrap {
        margin-bottom: 12px;
    }
}

.rating {
    margin-right: 12px;
}

.map  {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--light-bg);
    opacity: 1;
}
.map.visually-hidden  {
    opacity: 0;
    margin: 0;
}
@media (min-width: 768px) {
    .map,
    .map-wrap {
        height: 600px;
    }
}
@media (max-width: 767px) {
    .map,
    .map-wrap {
        height: 100%;
    }
}

.branches-list {
    overflow: auto;
}
@media (min-width: 1040px) {
    .branches-list {
        max-height: 600px;
    }
}

.branches-list::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: relative;
    z-index: 2;
    background-color: var(--light-c);
}

.branch:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light-c);
}

.view_list {
    padding: 20px var(--popup-paddings-x) var(--popup-paddings-y);
}
</style>