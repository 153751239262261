import UFieldString from './fields/UFieldString.vue';
import UFieldPassword from './fields/UFieldPassword.vue';
import UFieldEmail from './fields/UFieldEmail.vue';
import UFieldText from './fields/UFieldText.vue';
import UFieldCollection from './fields/UFieldCollection.vue';
import UFieldSelect from './fields/UFieldSelect.vue';
import UFieldCheckbox from './fields/UFieldCheckbox.vue';
import UFieldTabs from './fields/UFieldTabs.vue';
import UFieldHidden from './fields/UFieldHidden.vue';
import UFieldCalendar from './fields/UFieldCalendar.vue';
import UFieldNumber from './fields/UFieldNumber.vue';


class Form {
    constructor() {
        this.options = {};
        this.defaults = {
            widgets: {
                string: UFieldString,
                password: UFieldPassword,
                email: UFieldEmail,
                text: UFieldText,
                collection: UFieldCollection,
                select: UFieldSelect,
                checkbox: UFieldCheckbox,
                tabs: UFieldTabs,
                hidden: UFieldHidden,
                calendar: UFieldCalendar,
                number: UFieldNumber,
            },

            // обработка ошибок
            handleHttpError: (errors, component) => {
                component.setErrors(errors);
            },
            handleFormValidateErrors: errors => errors,
            plugins: [],
        };
    }

    install(Vue, options = {}) {
        Vue.prototype.$form = this;

        this.options = this.defaults;

        if (options.plugins && Array.isArray(options.plugins) && options.plugins.length) {
            this.options.plugins = [...this.options.plugins, ...options.plugins];
        }

        this.options.plugins.forEach(plugin => (typeof plugin === 'function') ? plugin(this) : null);

        if (options.widgets) {
            this.options.widgets = Object.assign({}, this.options.widgets, options.widgets);
        }
    }
}

export default new Form();