import additionalPages from '@/api/additionalPages.js';
import auctionRequests from '@/api/auctionRequests.js';
import auth from '@/api/auth.js';
import basket from '@/api/basket.js';
import brands from '@/api/brands.js';
import cars from '@/api/cars.js';
import catalogGroups from '@/api/catalogGroups.js';
import catalogPartCodes from '@/api/catalogPartCodes.js';
import catalogSchemeParts from '@/api/catalogSchemeParts.js';
import catalogSchemes from '@/api/catalogSchemes.js';
import chat from '@/api/chat.js';
import cities from '@/api/cities.js';
import companies from '@/api/companies.js';
import contractParts from '@/api/contractParts.js';
import delivery from '@/api/delivery/index.js';
import esCars from '@/api/esCars.js';
import esContractParts from '@/api/esContractParts.js';
import esPartProductsOffers from '@/api/esPartProductsOffers.js';
import esProducts from '@/api/esProducts.js';
import esProductsOffers from '@/api/esProductsOffers.js';
import feedback from '@/api/feedback.js';
import goodsCategories from '@/api/goodsCategories.js';
import help from '@/api/help.js';
import insuranceCompany from '@/api/insuranceCompany.js';
import insuranceContract from '@/api/insuranceContract.js';
import insuranceOffer from '@/api/insuranceOffer.js';
import insuranceVehicle from '@/api/insuranceVehicle.js';
import manufacturers from '@/api/manufacturers.js';
import mediafiles from '@/api/mediafiles.js';
import news from '@/api/news.js';
import notices from '@/api/notices.js';
import notifications from '@/api/notifications.js';
import oems from '@/api/oems.js';
import orders from '@/api/orders.js';
import partCategories from '@/api/partCategories.js';
import partName from '@/api/partName.js';
import partNameDoc from '@/api/partNameDoc.js';
import partProductsDoc from '@/api/partProductsDoc.js';
import partProductsOneOfferDoc from '@/api/partProductsOneOfferDoc.js';
import parts from '@/api/parts/index.js';
import payments from '@/api/payments.js';
import popularPartCategories from '@/api/popularPartCategories.js';
import privateFiles from '@/api/privateFiles.js';
import privateMediafiles from '@/api/privateMediafiles.js';
import products from '@/api/products/index.js';
import productsOneOfferDoc from '@/api/productsOneOfferDoc.js';
import publications from '@/api/publications.js';
import search from '@/api/search.js';
import searchHistory from '@/api/searchHistory.js';
import settings from '@/api/settings.js';
import shops from '@/api/shops/index.js';
import shortlink from '@/api/shortlink.js';
import staticPages from '@/api/staticPages.js';
import users from '@/api/users/index.js';
import viewsHistory from '@/api/viewsHistory.js';
import wheelsize from '@/api/wheelsize.js';


export const createApi = context => {
    return {
        additionalPages: additionalPages(context),
        auctionRequests: auctionRequests(context),
        auth: auth(context),
        basket: basket(context),
        brands: brands(context),
        cars: cars(context),
        catalogGroups: catalogGroups(context),
        catalogPartCodes: catalogPartCodes(context),
        catalogSchemeParts: catalogSchemeParts(context),
        catalogSchemes: catalogSchemes(context),
        chat: chat(context),
        cities: cities(context),
        companies: companies(context),
        contractParts: contractParts(context),
        delivery: delivery(context),
        esCars: esCars(context),
        esContractParts: esContractParts(context),
        esPartProductsOffers: esPartProductsOffers(context),
        esProducts: esProducts(context),
        esProductsOffers: esProductsOffers(context),
        feedback: feedback(context),
        goodsCategories: goodsCategories(context),
        help: help(context),
        insuranceCompany: insuranceCompany(context),
        insuranceContract: insuranceContract(context),
        insuranceOffer: insuranceOffer(context),
        insuranceVehicle: insuranceVehicle(context),
        manufacturers: manufacturers(context),
        mediafiles: mediafiles(context),
        news: news(context),
        notices: notices(context),
        notifications: notifications(context),
        oems: oems(context),
        orders: orders(context),
        partCategories: partCategories(context),
        partName: partName(context),
        partNameDoc: partNameDoc(context),
        partProductsDoc: partProductsDoc(context),
        partProductsOneOfferDoc: partProductsOneOfferDoc(context),
        parts: parts(context),
        payments: payments(context),
        popularPartCategories: popularPartCategories(context),
        privateFiles: privateFiles(context),
        privateMediafiles: privateMediafiles(context),
        products: products(context),
        productsOneOfferDoc: productsOneOfferDoc(context),
        publications: publications(context),
        search: search(context),
        searchHistory: searchHistory(context),
        settings: settings(context),
        shops: shops(context),
        shortlink: shortlink(context),
        staticPages: staticPages(context),
        users: users(context),
        viewsHistory: viewsHistory(context),
        wheelsize: wheelsize(context),
    };
};
