<template>
    <transition
        appear
        name="slide-from-bottom"
    >
        <div class="balloon layer-1">
            <TransitionWidthHeightGroup>
                <div
                    v-if="loading"
                    key="loading"
                    class="balloon__spinner"
                >
                    <Spinner></Spinner>
                </div>
                <div
                    v-else
                    key="content"
                    class="balloon__content"
                >
                    <ButtonIcon
                        :icon="{
                            name: 'cross',
                            base: true,
                            secondary: true,
                            hovered: true
                        }"
                        :secondary="false"
                        small
                        class="close-btn"
                        @click="closeBalloon"
                    ></ButtonIcon>

                    <div class="mb-20 flex">
                        <ShopLogo
                            :shop="shop"
                            class="mr-20"
                        ></ShopLogo>

                        <div>
                            <ShopName
                                :shop="shop"
                                class="mb-8"
                            ></ShopName>

                            <ShopAddress
                                :shop="shop"
                                :showOnMap="false"
                                class="mb-4"
                            ></ShopAddress>

                            <ShopOpenCloseTime
                                :shopId="shop.id"
                                :status="shop.current_work"
                                withoutTooltip
                            ></ShopOpenCloseTime>

                            <StarsRating
                                :value="shop.rating"
                                class="mt-16"
                            ></StarsRating>
                        </div>
                    </div>

                    <div class="offer-info">
                        <div class="price-amount-wrap">
                            <div
                                v-if="offer.in_stock"
                                class="price"
                            >
                                {{ offer.price | price }}
                            </div>
                            <div
                                v-if="offer.in_stock && offer.amount === 1"
                                class="amount text_red"
                            >
                                Последний товар
                            </div>
                            <div
                                v-if="!offer.in_stock"
                                class="amount"
                            >
                                Нет в наличии
                            </div>
                        </div>

                        <div class="market-item__actions">
                            <AddCartBtn
                                v-if="offer.in_stock"
                                :offer="offer"
                                :item="offer[modelName]"
                                :modelName="modelName"
                                :disabled="!offer.amount"
                                class="market-item__action-btn"
                                @addToCart="onChangeCart"
                            ></AddCartBtn>

                            <ChatBtn
                                :id="shop.id"
                                :isOnline="shop.current_work.place.is_active"
                                :disabled="!shop.is_chat_active"
                                class="market-item__action-btn"
                                @click="$emit('clickChat')"
                            ></ChatBtn>
                        </div>
                    </div>

                    <DeliveryInfo
                        :currentWork="shop.current_work"
                        :offer="offer"
                        :shop="shop"
                        buttons
                        class="mt-16"
                    ></DeliveryInfo>

                    <ShopWorkSchedule
                        :work="shop.work_times"
                        :holidays="shop.holidays"
                        class="mt-20"
                    ></ShopWorkSchedule>
                </div>
            </TransitionWidthHeightGroup>
        </div>
    </transition>
</template>

<script>
import ShopWorkSchedule from './ShopWorkSchedule.vue';
import AddCartBtn from './project-buttons/AddCartBtn.vue';
import ChatBtn from './project-buttons/ChatBtn.vue';
import DeliveryInfo from './DeliveryInfo.vue';
import Spinner from './Spinner.vue';
import StarsRating from './StarsRating.vue';
import ShopLogo from './ShopLogo.vue';
import ShopOpenCloseTime from './ShopOpenCloseTime.vue';
import ShopAddress from './ShopAddress.vue';
import ShopName from './ShopName.vue';
import ButtonIcon from './_buttons/ButtonIcon.vue';
import TransitionWidthHeightGroup from '@/components/_transitions/TransitionWidthHeightGroup.vue';

export default {
    name: 'OfferBalloon',

    components: {
        TransitionWidthHeightGroup,
        ButtonIcon,
        ShopName,
        ShopAddress,
        ShopOpenCloseTime,
        ShopLogo,
        StarsRating,
        Spinner,
        ShopWorkSchedule,
        AddCartBtn,
        ChatBtn,
        DeliveryInfo,
    },

    props: {
        offer: {
            type: Object,
            default: () => {},
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },
    },

    data() {
        return {
            shop: {},
            loading: true,
        };
    },

    watch: {
        offer: {
            handler() {
                this.showBalloon();
            },
        },
    },

    created() {
        this.showBalloon();
    },

    methods: {
        async showBalloon() {
            this.loading = true;
            this.shop = await this.$api.shops.get(this.offer.shop.id);
            this.loading = false;
        },

        closeBalloon() {
            this.$emit('close');
            this.shop = {};
        },

        onChangeCart() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.balloon {
    z-index: 2;
    font-size: var(--base-fz);
    font-family: var(--f-base);
}
@media (min-width: 768px) {
    .balloon {
        padding: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
    }

    .balloon__content {
        width: 332px;
    }

    .slide-from-bottom-enter,
    .slide-from-bottom-leave-to,
    .slide-from-bottom-enter-to,
    .slide-from-bottom-leave {
        transition: opacity var(--transition);
    }

    .slide-from-bottom-enter,
    .slide-from-bottom-leave-to {
        opacity: 0;
    }

    .slide-from-bottom-enter-to,
    .slide-from-bottom-leave {
        opacity: 1
    }
}
@media (max-width: 767px) {
    .balloon {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: var(--popup-z-index);
        width: 100%;
        max-height: 90vh;
        padding: 20px var(--content-gap);
        box-shadow: var(--base-shadow);
        border-radius: var(--border-radius-x3) var(--border-radius-x3) 0 0;
        overflow: auto;
    }

    .balloon__spinner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .balloon__content {
        width: 100%;
    }

    .slide-from-bottom-enter,
    .slide-from-bottom-leave-to {
        transform: translateY(100%);
    }
}

@media (min-width: 768px) {
    .offer-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 767px) {
    .price-amount-wrap {
        margin-bottom: 12px;
    }
}

.price {
    font-size: 20px;
    line-height: var(--small-lh);
    font-family: var(--f-bold);
}

.amount {
    font-size: var(--base-fz);
    color: var(--font-secondary-color);
    font-family: var(--f-semi-bold);
    text-align: right;
}

@media (min-width: 768px) {
    .close-btn {
        position: absolute;
        top: -12px;
        right: -12px;
    }
}
@media (max-width: 767px) {
    .close-btn {
        position: absolute;
        top: -4px;
        right: calc(var(--content-gap-negative) + 16px);
    }
}
</style>