<template>
    <div class="accordion">
        <div
            v-for="(item, index) in questions"
            :key="`accordion-item-${ index }`"
            class="accordion-item"
            :class="{
                'open': active === index
            }"
        >
            <h3>
                <button
                    :id="`accordion-item-${ index }`"
                    :aria-expanded="active === index"
                    class="accordion-trigger"
                    :aria-controls="`accordion-section-${ index }`"
                    @click="open(index)"
                >
                    <span class="accordion-title">{{ item.question }}</span>
                    <RoundArrowBtn
                        :isButton="false"
                        direction="down"
                        :rotate="active === index"
                        class="accordion-icon"
                    ></RoundArrowBtn>
                </button>
            </h3>

            <div class="accordion-section-wrap">
                <TransitionExpand>
                    <div
                        v-show="active === index"
                        :id="`accordion-section-${ index }`"
                        :key="`accordion-section-${ index }`"
                        role="region"
                        :aria-labelledby="`accordion-item-${ index }`"
                        class="accordion-section"
                    >
                        <div class="accordion-section__content">
                            <WysiwygView :value="item.answer"></WysiwygView>
                        </div>
                    </div>
                </TransitionExpand>
            </div>
        </div>
    </div>
</template>

<script>
import WysiwygView from './views/WysiwygView.vue';
import TransitionExpand from './_transitions/TransitionExpand.vue';
import RoundArrowBtn from '@/components/project-buttons/RoundArrowBtn.vue';

export default {
    name: 'Accordion',

    components: {
        RoundArrowBtn,
        TransitionExpand,
        WysiwygView,
    },

    props: {
        questions: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            active: null,
        };
    },

    methods: {
        open(index) {
            if (this.active === index) {
                this.active = null;
            }
            else {
                this.active = index;
            }
        },
    },
};
</script>

<style scoped>
.accordion {
    border: 1px solid var(--border-light-c);
    border-bottom: none;
    border-radius: var(--border-radius-x2);
}

.accordion-item {
    position: relative;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .accordion-item {
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .accordion-item {
        padding-bottom: 20px;
    }
}

.accordion-item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: transparent;
    transition: background-color var(--transition);
}

.accordion-item.open::after {
    background-color: var(--primary-color);
}

.accordion-trigger {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    text-align: left;
}
@media (min-width: 768px) {
    .accordion-trigger {
        padding: 24px 24px 24px;
    }

    .accordion-section-wrap {
        margin-top: -24px;
    }
}
@media (max-width: 767px) {
    .accordion-trigger {
        padding: 20px 20px 0;
    }
}

.accordion-title {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
    transition: color var(--transition);
}

.accordion-title:hover,
.accordion-title:focus {
    color: var(--primary-color);
}

.accordion-item:hover .accordion-title,
.accordion-item.open .accordion-title {
    color: var(--primary-color);
}

@media (min-width: 768px) {
    .accordion-icon {
        margin-left: 72px;
    }
}
@media (max-width: 767px) {
    .accordion-icon {
        margin-left: 24px;
    }
}

.accordion-section__content {
    padding-top: 12px;
}
@media (min-width: 768px) {
    .accordion-section__content {
        padding-left: 24px;
        padding-right: 24px;
    }
}
@media (max-width: 767px) {
    .accordion-section__content {
        padding-left: 20px;
        padding-right: 20px;
    }
}
</style>