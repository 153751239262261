<template>
    <label
        :for="id"
        class="u-label"
        :class="{
            'u-label_active': active,
            'u-label_invalid': invalid,
            'u-label_disabled': disabled,
        }"
    >
        <slot></slot>
    </label>
</template>

<script>
export default {
    name: 'ULabel',

    props: {
        id: String,
        active: Boolean,
        invalid: Boolean,
        disabled: Boolean,
    },
};
</script>

<style>
.u-label {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 3;
    max-width: calc(100% - 32px);
    padding: 0 4px;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    color: var(--font-secondary-color);
    background-color: var(--light-c);
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition:
        top var(--transition-fast),
        font-size var(--transition-fast),
        background-color var(--transition-fast);
}
.u-label.u-label_invalid {
    color: var(--primary-color);
}
.u-label.u-label_active {
    top: -8px;
    font-size: var(--small-fz);
    line-height: 1.33;
}
.u-label.u-label_disabled {
    background-color: var(--bright-bg);
}
</style>