<template>
    <div class="auth-layout">
        <div class="auth-container">
            <div class="auth-container_inner">
                <main class="main-content">
                    <UbibiLogo big class="auth-layout__logo-wrap"></UbibiLogo>

                    <div class="auth-container__form-wrap">
                        <transition
                            name="fade-page"
                            mode="out-in"
                        >
                            <RouterView></RouterView>
                        </transition>
                    </div>
                </main>

                <aside class="aside">
                    <AuthFactoids></AuthFactoids>

                    <img
                        srcset="../assets/images/auth-car.jpg,
                                    ../assets/images/auth-car-x2.jpg 2x"
                        src="../assets/images/auth-car-x2.jpg"
                        class="auth-layout__car-img"
                        alt=""
                    >
                </aside>

                <UIconButton
                    :to="{
                        name: fromRoute.name,
                        params: fromRoute.params,
                    }"
                    secondary
                    shadow
                    big
                    round
                    class="auth-layout__close"
                ></UIconButton>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import UbibiLogo from '@/components/UbibiLogo.vue';
import AuthFactoids from '@/components/UFactoids/presets/AuthFactoids.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';

export default {
    name: "AuthLayout",

    components: {
        UIconButton,
        AuthFactoids,
        UbibiLogo,
    },

    computed: {
        ...mapState({
            isAuthorized: state => state.profile.isAuthorized,
            fromRoute: state => state.fromRoute.fromRoute,
        }),
    },

    mounted() {
        if (this.isAuthorized) {
            this.$router.push({ name: this.fromRoute.name || 'market', params: this.fromRoute.params });
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name !== null && from.name !== 'auth' && from.name !== 'signup' && from.name !== 'signin' && from.name !== 'password-recovery') {
                vm.$store.commit('fromRoute/setFromRoute', from);
            }
            else {
                const route = {
                    name: 'main',
                };
                vm.$store.commit('fromRoute/setFromRoute', route);
            }
        });
    },
};
</script>

<style scoped>
.auth-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}

.auth-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
@media (min-width: 1281px) {
    .auth-container {
        justify-content: center;
    }
}
@media (min-width: 768px) {
    .auth-container {
        margin-left: auto;
        margin-right: auto;
        flex-shrink: 0;
        padding-top: 72px;
        padding-bottom: 72px;
    }
}

@media (min-width: 768px) {
    .auth-container_inner {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
}
@media (max-width: 767px) {
    .auth-container_inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

@media (min-width: 1040px) {
    .main-content {
        width: 340px;
        flex-shrink: 0;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .main-content {
        width: 304px;
    }
}
@media (max-width: 767px) {
    .main-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 28px var(--content-gap);
    }
}

@media (min-width: 1281px) {
    .aside {
        min-height: 640px;
        margin-left: 240px;
    }

    .aside::after {
        left: -120px;
    }
}
@media (min-width: 1180px) and (max-width: 1280px) {
    .aside {
        width: var(--col-6);
        margin-top: calc(40px + 96px);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .aside {
        min-height: 620px;
        margin-left: 200px;
    }

    .aside::after {
        left: -100px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .aside {
        width: 304px;
        margin-left: 80px;
        margin-top: calc(40px + 96px);
    }

    .aside::after {
        left: -40px;
    }
}
@media (min-width: 768px) {
    .aside {
        position: relative;
    }

    .aside::after {
        content: "";
        position: absolute;
        background-color: var(--bright-bg);
        height: 200vh;
        width: 100vw;
        top: -50vh;
    }
}
@media (max-width: 767px) {
    .aside {
        padding: 28px var(--content-gap);
        background-color: var(--bright-bg);
    }
}

@media (min-width: 1281px) {
    .auth-layout__car-img {
        height: 488px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .auth-layout__car-img {
        height: 420px;
        margin-top: -64px;
    }
}
@media (min-width: 1040px) {
    .auth-layout__car-img {
        position: absolute;
        left: 0;
        max-width: initial;
        z-index: 1;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .auth-layout__car-img {
        position: relative;
        z-index: 1;
        max-width: initial;
        height: 200px;
        margin-top: 48px;
    }
}
@media (max-width: 767px) {
    .auth-layout__car-img {
        display: none;
    }
}

@media (min-width: 768px) {
    .auth-layout__logo-wrap {
        margin-bottom: 96px;
    }
}
@media (max-width: 767px) {
    .auth-layout__logo-wrap {
        margin-bottom: 28px;
    }
}

.auth-layout__close {
    position: absolute;
    top: 16px;
    right: 16px;
}
</style>