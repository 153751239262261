<template>
    <div
        :class="[
            'radio-group',
            mobileAtomic && 'radio-group_mobile-atomic',
            mobileAtomicFlow && 'radio-group_mobile-atomic-flow'
        ]"
    >
        <div
            v-for="option in options"
            :key="`${ name }-${ option.value || option.id }`"
            :class="['radio-group__item', 'radio-group__item_' + type]"
        >
            <input
                :id="`${ name }-${ option.value || option.id }`"
                v-model="valueComputed"
                type="radio"
                :name="name"
                :value="option.value || option.id"
                :disabled="option.disabled"
                class="radio-group__input visually-hidden"
            >
            <label
                :for="`${ name }-${ option.value || option.id }`"
                class="radio-group__label"
            >
                <UIcon
                    v-if="type === 'icon'"
                    :name="option.icon"
                    small
                    class="radio-group__label-icon"
                ></UIcon>
                <span
                    v-else
                    class="radio-group__label-text"
                >
                    {{ option.label || option.name }}
                </span>
            </label>
        </div>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'RadioButtonsGroup',

    components: {
        UIcon,
    },

    model: {
        event: 'change',
        prop: 'value',
    },

    props: {
        name: {
            type: String,
            default: '',
        },

        type: {
            type: String,
            default: 'text',
        },

        value: {
            type: [String, Number, Boolean, Object],
            required: true,
        },

        options: {
            type: Array,
            default() {
                return [];
            },
        },

        mobileAtomic: Boolean,
        mobileAtomicFlow: Boolean,
    },

    computed: {
        valueComputed: {
            get() {
                return this.value.id || this.value;
            },

            set(value) {
                this.$emit('change', value);
            },
        },
    },
};
</script>

<style scoped>
.radio-group {
    position: relative;
    display: inline-flex;
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
}

@media (max-width: 767px) {
    .radio-group_mobile-atomic {
        border: none;
        border-radius: 0;
    }

    .radio-group_mobile-atomic .radio-group__item_text .radio-group__label {
        padding: 7px 16px;
        border-radius: var(--border-radius);
        border: 1px solid var(--border-dark-c);
    }

    .radio-group_mobile-atomic-flow {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -8px;
        margin-right: -8px;
    }

    .radio-group_mobile-atomic-flow .radio-group__item_text .radio-group__label {
        margin-right: 8px;
        margin-bottom: 8px;
    }
}

.radio-group__item:first-child .radio-group__label {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.radio-group__item:not(:last-child) .radio-group__label {
    border-right: 1px solid var(--border-dark-c);
}

.radio-group__item:last-child .radio-group__label {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.radio-group__item_icon .radio-group__label {
    width: 36px;
    height: 36px;
    padding: 10px;
    font-size: 0;
}

.radio-group__item_text .radio-group__label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    text-align: center;
}

.radio-group__input:checked + .radio-group__label {
    color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
    border-right-color: transparent;
}

.radio-group__item_icon .radio-group__input:checked + .radio-group__label {
    color: var(--light-c);
    background-color: var(--primary-color);
}

.radio-group__item_text .radio-group__input:checked + .radio-group__label {
    border-color: transparent;
}

.radio-group__label {
    transition: all var(--transition);
}

.radio-group__label:hover {
    color: var(--primary-color);
}

.radio-group__input:disabled + .radio-group__label {
    color: var(--font-secondary-color);
    cursor: default;
    pointer-events: none;
}

.radio-group__label-icon {
    fill: currentColor;
}

.radio-group__label-text {
    font-size: var(--base-fz);
}
</style>