export default ({ HTTP }) => ({
    async get(...args) {
        let config;
        let uuid;

        if (args && args.length) {
            if (typeof args[0] === 'string') {
                uuid = args[0];
                config = args[1];
            }
            else {
                config = args[0];
            }
        }

        if (!config) config = {};

        if (uuid) {
            const url = `/insurance_contract/${ uuid }/`;
            const response = await HTTP.get(url);
            return response.data;
        }
        else {
            const url = '/insurance_contract/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async getCount() {
        const params = { limit: 0 };
        const config = { params };
        const url = '/insurance_contract/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async change(uuid, data) {
        const url = `/insurance_contract/${ uuid }/`;
        const response = await HTTP.patch(url, data);
        return response.data;
    },

    async new() {
        const url = '/insurance_contract/new/';
        const response = await HTTP.post(url);
        return response.data;
    },

    async replicate(uuid) {
        const url = `/insurance_contract/${ uuid }/replicate/`;
        const response = await HTTP.post(url);
        return response.data;
    },

    async offers(uuid) {
        const url = `/insurance_contract/${ uuid }/offers/`;
        const response = await HTTP.get(url);
        return response.data;
    },

    async calculate(uuid, data) {
        const url = `/insurance_contract/${ uuid }/calculate/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async sendCode(uuid, data) {
        const url = `/insurance_contract/${ uuid }/send_code/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async confirmCode(uuid, data) {
        const url = `/insurance_contract/${ uuid }/code_confirm/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async cancel(uuid) {
        const url = `/insurance_contract/${ uuid }/cancel/`;
        const response = await HTTP.patch(url);
        return response.data;
    },
});