<template>
    <div>
        <DesktopUI
            :isOpen="isOpen"
            :isSticky="isSticky"
            :catalog="catalog"
            :loading="loading"
            :path="path"
            class="hidden-s hidden-m"
            @close="close"
            @selectCategory="desktopOnSelectCategory"
            @sectionMouseleave="desktopOnSectionMouseleave"
        ></DesktopUI>

        <MobileUI
            :isOpen="isOpen"
            :parent="parent"
            :section="currentSection"
            :loading="loading"
            :hideBackdrop="hideBackdrop"
            class="hidden-l hidden-xl"
            @selectCategory="mobileOnSelectCategory"
            @close="close"
            @back="mobileOnBack"
        ></MobileUI>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import bus from '@/bus.js';
import DesktopUI from './DesktopUI.vue';
import MobileUI from './MobileUI.vue';

export default {
    name: 'Catalog',

    components: {
        MobileUI,
        DesktopUI,
    },

    props: {
        hideBackdrop: Boolean,
        isSticky: Boolean,
    },

    data() {
        return {
            isOpen: false,
            path: ['root'],
            catalog: [],
            catalogObj: {},
            parent: {},
            cache: {},
            cancelToken: null,
            loading: false,
            catalogLeaveTimeout: null,
            desktopTrigger: null,
        };
    },

    computed: {
        currentSection() {
            return this.catalog[this.catalog.length - 1];
        },
    },

    watch: {
        '$route.name'() {
            this.close();
        },
    },

    mounted() {
        this.init();
        bus.$on('closeCatalog', this.close);
        bus.$on('showCatalog', this.show);
        bus.$on('toggleCatalog', this.toggle);
    },

    beforeDestroy() {
        bus.$off('closeCatalog', this.close);
        bus.$off('showCatalog', this.show);
        bus.$off('toggleCatalog', this.toggle);
    },

    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
            if (!this.isOpen) {
                this.reset();
            }
        },

        show() {
            this.isOpen = true;
        },

        close() {
            this.isOpen = false;
            this.reset();
            this.$emit('close');
        },

        async init() {
            const iconsMap = {
                'shinyi-i-diski': 'wheel',
                'masla-i-tehnicheskie-zhidkosti': 'drop',
                'masla-i-zhidkosti': 'drop',
                'elektrooborudovanie': 'lightning',
                'avtokosmetika': 'shine',
            };
            this.parent = {
                title: 'Каталог',
                parentCategoryCodename: false,
            };

            await Promise.all([
                this.$api.partCategories.get({ params: {
                    parent_id__isnull: 1,
                    order_by: 'tree_id',
                } }),
                this.$api.goodsCategories.get( { params: {
                    parent_id__isnull: 1,
                    order_by: 'tree_id',
                } }),
                this.$api.staticPages.get('market-maintenance'),
            ])
                .then(responses => {
                    const [ parts, products, maintenance ] = responses;
                    const root = [];
                    root.push({
                        modelName: 'part',
                        title: 'Автозапчасти',
                        icon: 'parts',
                        id: 'market-parts-catalog',
                        slug: 'market-parts-catalog',
                        to: { name: 'market-parts-catalog' },
                        children: parts.results,
                        is_leaf: parts.current_count === 0,
                        parentCategoryCodename: 'root',
                    });
                    products.results.forEach(item => {
                        root.push({
                            modelName: 'product',
                            title: item.name,
                            icon: iconsMap[item.slug] || '',
                            id: item.id,
                            slug: item.slug,
                            to: { name: 'market-products-category', params: { slug: item.slug } },
                            children: item.children,
                            is_leaf: item.children.length === 0,
                            parentCategoryCodename: 'root',
                        });
                        this.catalogObj[item.slug] = item;
                    });
                    root.push({
                        modelName: 'product',
                        title: 'Товары для ТО',
                        icon: 'car',
                        id: 'market-maintenance',
                        slug: 'market-maintenance',
                        to: { name: 'market-maintenance' },
                        children: [
                            ...maintenance.popular_categories,
                            ...maintenance.additional_categories,
                        ],
                        is_leaf: false,
                        parentCategoryCodename: 'root',
                    });

                    this.$set(this.cache, 'market-parts-catalog', parts.results);
                    this.$set(this.cache, 'market-maintenance', [
                        ...maintenance.popular_categories,
                        ...maintenance.additional_categories,
                    ]);
                    this.catalog.splice(0, 0, root);
                    this.catalogObj['market-parts-catalog'] = parts;
                    this.catalogObj['market-maintenance'] = maintenance;
                });
        },

        async desktopOnSelectCategory(category) {
            if (this.cancelToken) this.cancelToken.cancel();

            const categoryInPart = this.path.findIndex(item => item === category.slug);
            if (categoryInPart > -1) {
                this.catalog.splice(categoryInPart + 1);
                this.path.splice(categoryInPart + 1);
            }
            else {
                this.path.push(category.slug);
            }

            const parentCategoryInPart = this.path.findIndex(item => item === category.parentCategoryCodename);
            if (parentCategoryInPart > -1) {
                this.catalog.splice(parentCategoryInPart + 1);
                this.path.splice(parentCategoryInPart + 1);
                this.path.push(category.slug);
            }

            if (!category.is_leaf) {
                if (!this.cache[category.slug]) {
                    this.loading = true;
                    this.cancelToken = HTTP.CancelToken.source();
                    this.getCategory(category)
                        .then(response => {
                            const { results, current_count } = response;
                            if (current_count) {
                                category.children = results.map(item => {
                                    item.is_leaf = item.children.length === 0;
                                    return item;
                                });
                                category.is_leaf = false;
                            }
                            else {
                                category.is_leaf = true;
                            }
                            this.$set(this.cache, category.slug, category);
                            this.loading = false;
                            this.addSection(category);
                            this.cancelToken = null;
                        })
                        .catch(error => {
                            if (!HTTP.isCancel(error)) {
                                category.is_leaf = true;
                            }
                        })
                        .finally(() => {
                            if (!this.cancelToken) this.loading = false;
                        });
                }
                else {
                    this.cancelToken = null;
                    this.loading = false;
                    this.addSection(category);
                }
            }
        },

        desktopOnSectionMouseleave() {
            clearTimeout(this.catalogLeaveTimeout);
            this.catalogLeaveTimeout = setTimeout(() => {
                if (this.cancelToken) this.cancelToken = null;
                this.loading = false;
            }, 200);
        },

        async mobileOnSelectCategory(category) {
            this.parent = category;
            if (this.cancelToken) this.cancelToken.cancel();

            this.path.push(category.slug);

            if (!this.cache[category.slug]) {
                this.loading = true;
                this.cancelToken = HTTP.CancelToken.source();
                this.getCategory(category)
                    .then(response => {
                        const { results, current_count } = response;
                        if (current_count) {
                            category.children = results.map(item => {
                                item.is_leaf = item.children.length === 0;
                                return item;
                            });
                            category.is_leaf = false;
                        }
                        else {
                            category.is_leaf = true;
                        }
                        this.$set(this.cache, category.slug, category);
                        this.loading = false;
                        this.addSection(category);
                        this.cancelToken = null;
                    })
                    .catch(error => {
                        if (!HTTP.isCancel(error)) {
                            category.is_leaf = true;
                        }
                    })
                    .finally(() => {
                        if (!this.cancelToken) this.loading = false;
                    });
            }
            else {
                this.cancelToken = null;
                this.loading = false;
                this.addSection(category);
            }
        },

        mobileOnBack(parent) {
            this.catalog.splice(this.catalog.length - 1);
            this.path.splice(this.path.length - 1);

            if (parent.parentCategoryCodename === 'root') {
                this.parent = {
                    title: 'Каталог',
                    parentCategoryCodename: false,
                };
            }
            else {
                this.parent = this.catalogObj[parent.parentCategoryCodename];
            }
        },

        async getCategory(category) {
            if (category.modelName === 'product') {
                return await this.$api.goodsCategories.get({
                    params: {
                        parent_id__in: category.id,
                        order_by: 'lft',
                    },
                    cancelToken: this.cancelToken.token,
                });
            }
            else if (category.modelName === 'part') {
                return await this.$api.partCategories.get({
                    params: {
                        parent_id: category.id,
                        order_by: 'lft',
                    },
                    cancelToken: this.cancelToken.token,
                });
            }
        },

        addSection(category) {
            if (!category.is_leaf && this.catalog.length < 4) {
                let newSection = category.children.map((item) => {
                    item.modelName = category.modelName;
                    item.title = item.name;
                    if (category.modelName === 'part') {
                        item.to = { name: 'market-parts-catalog-category', params: { slug: item.slug } };
                    }
                    else if (category.modelName === 'product') {
                        item.to = { name: 'market-products-category', params: { slug: item.slug } };
                    }
                    item.parentCategoryCodename = category.slug;
                    return item;
                });
                this.catalog.push(newSection);
                this.catalogObj[category.slug] = category;
            }
        },

        reset() {
            if (this.cancelToken) this.cancelToken.cancel();
            this.loading = false;
            this.catalog.splice(1);
            this.path.splice(1);
            this.parent = {
                title: 'Каталог',
                parentCategoryCodename: false,
            };
        },
    },
};
</script>