export default ({ HTTP }) => ({
    async byOrder(data, config) {
        const response = await HTTP.post('/delivery/estimate/by_order/', data, config);
        return response.data;
    },

    async byCity(data, config) {
        const response = await HTTP.post('/delivery/estimate/by_city/', data, config);
        return response.data;
    },
});
