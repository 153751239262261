<template>
    <PopupWrap
        verticalCenterNotXS
        :loading="initLoading"
        @close="close"
    >
        <FormManager
            v-if="!initLoading"
            ref="form"
            v-model="formData"
            :formTag="false"
            class="popup-content"
        >
            <h2 class="h2 h2_block popup-title">
                Оформить доставку по&nbsp;России
            </h2>

            <UDropdown
                ref="dropdown"
                :options="searchOptions"
                :initialized="searchInitialized"
                class="dropdown dropdown_city"
                @select="onSelectCity"
            >
                <template #default="{ handlers }">
                    <UInputSearch
                        :value="searchValue"
                        :loading="searching"
                        label="Куда доставить? *"
                        placeholder="Город, посёлок или деревня"
                        :clearInputOnFocus="!!currentCity"
                        autocomplete="off"
                        :invalid="cityFieldHasError"
                        @blur="handlers.blur(); $nextTick(onBlurSearchValue)"
                        @focus="handlers.focus(); $nextTick(onFocus)"
                        @input="onChangeSearchValue"
                        @keydown="handlers.keydown"
                        @click:clear="onClear"
                    ></UInputSearch>
                </template>

                <template #label="{ option }">
                    <p class="ellipsis">
                        {{ option.full_name }}
                    </p>
                    <p class="dropdown__option-description ellipsis">
                        <span
                            v-if="option.region"
                        >{{ option.region }}</span><span
                            v-if="option.region && option.district"
                        >,</span>
                        <span v-if="option.district">{{ option.district }}</span>
                    </p>
                </template>
            </UDropdown>

            <template v-if="currentCity">
                <Spinner
                    v-if="getDeliveryServiceDataLoading"
                    class="top-gap_small"
                ></Spinner>
                <InfoMessage v-else-if="!hasCourierType && !hasPickUpType">
                    Доставка заказов в {{ currentCity.full_name }} недоступна.
                    Пожалуйста, выберите другой населенный пункт рядом с вами.
                </InfoMessage>
                <template v-else>
                    <FormField
                        v-if="hasCourierType && hasPickUpType"
                        type="radio"
                        name="receiving_method"
                        :options="[
                            {
                                value: 'pickup',
                                label: 'Забрать из пункта выдачи',
                            },
                            {
                                value: 'courier',
                                label: 'Доставить курьером',
                            }
                        ]"
                        class="form-field-gap_top"
                    ></FormField>

                    <section class="top-gap_small">
                        <h3
                            v-if="!hasCourierType && hasPickUpType"
                            class="h4 h4_block"
                        >Пункт выдачи</h3>
                        <h3
                            v-if="hasCourierType && !hasPickUpType"
                            class="h4 h4_block"
                        >Доставка курьером</h3>

                        <template v-if="formData.receiving_method === 'courier'">
                            <FormField
                                name="address"
                                type="address"
                                label="Адрес доставки"
                                validations="required|house|flat"
                                :validationRules="{
                                    house: ({ value }) => !(value || {}).house,
                                    flat: ({ value }) => !(value || {}).flat,
                                }"
                                :validationMessages="{
                                    house: 'Укажите адрес полностью, включая дом, квартиру или офис, если есть.',
                                    flat: 'Укажите адрес полностью, квартиру или офис, если есть.',
                                }"
                                :queryOptions="{
                                    location_city: currentCity.name,
                                }"
                                :reduceResponse="addressFieldReducer"
                                @change="onSelectCourierAddress"
                            ></FormField>
                            <p class="text_small-fz text_secondary mt-8">
                                Укажите адрес полностью, включая квартиру или офис, если есть.
                            </p>

                            <template v-if="formData.address && isAddressValid">
                                <Spinner
                                    v-if="courierOptionsPriceByAddressCounter < activeCourierDeliveryService.length"
                                    class="mt-20"
                                ></Spinner>

                                <InfoMessage
                                    v-if="courierOptionsPriceByAddressCounter === activeCourierDeliveryService.length
                                        && !courierOptions.length"
                                    class="mt-20"
                                >
                                    Курьерская доставка заказов по выбранному адресу недоступна.
                                    Пожалуйста, выберите другой адрес рядом с вами.
                                </InfoMessage>

                                <template
                                    v-if="courierOptionsPriceByAddressCounter === activeCourierDeliveryService.length
                                        && courierOptions.length"
                                >
                                    <FormField
                                        type="radio"
                                        borders
                                        name="courier_option"
                                        :options="courierOptions"
                                        optionKey="codename"
                                        validations="required"
                                        class="pick-up-options form-field-gap_top"
                                    >
                                        <template #label="{ option }">
                                            <div class="flex align-items-center">
                                                <div class="mr-4">
                                                    <span
                                                        class="d-block text_semi-bold"
                                                    >{{ option.service.name }}</span>
                                                    <b
                                                        class="d-block"
                                                    >{{ option.price | price }} / {{ option.days }}</b>
                                                </div>
                                                <img
                                                    :src="$links.uploads
                                                        + option.service.logo.thumbnails.delivery_service_logo_big"
                                                    alt=""
                                                    class="transport__logo"
                                                >
                                            </div>
                                        </template>
                                    </FormField>

                                    <InfoMessage
                                        v-if="formData.courier_option === 'pochtaru'
                                            && order.delivery_service_orders.length > 1"
                                        class="mt-20"
                                    >
                                        Заказ укомплектован в несколько упаковок
                                        и будет отправлен Почтой России отдельными посылками.
                                    </InfoMessage>
                                </template>
                            </template>
                        </template>

                        <template v-if="formData.receiving_method === 'pickup'">
                            <div
                                v-if="selectedPickupPoint"
                                class="selected-pickup-point"
                            >
                                <div class="selected-pickup-point__section selected-pickup-point__section_main">
                                    <img
                                        :src="$links.uploads
                                            + selectedPickupPoint.service.logo.thumbnails.delivery_service_logo_big"
                                        alt=""
                                        class="selected-pickup-point__logo"
                                    >
                                    <p>
                                        {{ selectedPickupPoint.service.codename === 'pochtaru'
                                            ? selectedPickupPoint.code + ','
                                            : '' }}
                                        {{ currentCity.full_name }},
                                        {{ selectedPickupPoint.address }}
                                    </p>
                                    <ButtonText
                                        dashed
                                        secondary
                                        dark
                                        class="selected-pickup-point__reset-btn"
                                        @click="resetSelectedPickupPoint"
                                    >
                                        Изменить
                                    </ButtonText>
                                </div>
                                <div class="selected-pickup-point__section">
                                    <div class="schedule">
                                        <div
                                            v-for="(item, index) in selectedPickupPoint.work_times_merged"
                                            :key="`day-${ index }`"
                                            class="schedule-row"
                                        >
                                            <div class="schedule-td">
                                                {{ item.day_name }}
                                            </div>
                                            <div
                                                v-if="item.is_day_off"
                                                class="schedule-td text_red"
                                            >
                                                Выходной
                                            </div>
                                            <div
                                                v-else
                                                class="schedule-td"
                                            >
                                                {{ item.start_time.slice(0,-3) }} – {{ item.end_time.slice(0,-3) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="selected-pickup-point__section">
                                    <Spinner
                                        v-if="selectedPickupPoint.loading"
                                    ></Spinner>
                                    <b v-else>
                                        {{ selectedPickupPoint.total_price | price }} /
                                        {{ selectedPickupPoint.days_max_text }}
                                    </b>
                                </div>

                                <InfoMessage
                                    v-if="selectedPickupPoint.service.codename === 'pochtaru' && order.places.length > 1"
                                    class="selected-pickup-point__description"
                                >
                                    Заказ укомплектован в несколько упаковок
                                    и будет отправлен Почтой России отдельными посылками.
                                </InfoMessage>
                            </div>
                            <template v-else>
                                <div
                                    class="pickup-section_desktop"
                                    :class="{
                                        'pickup-section_desktop_hidden': !selectPickupPointMobileView
                                    }"
                                >
                                    <div class="select-head pickup-section_desktop__head">
                                        <ButtonIcon
                                            :icon="{
                                                name: 'arrow-left',
                                                big: true,
                                            }"
                                            :secondary="false"
                                            class="select-head__back-btn"
                                            @click="selectPickupPointMobileView = false"
                                        ></ButtonIcon>
                                        <h4 class="h4 select-head__title">
                                            Выбор пункта выдачи
                                        </h4>
                                    </div>

                                    <div class="select-body">
                                        <UDropdown
                                            ref="address-dropdown"
                                            :options="pointSearchOptions"
                                            class="dropdown"
                                            @select="onSelectSearchPoint"
                                        >
                                            <template #default="{ handlers }">
                                                <UInputSearch
                                                    :value="pointSearchValue"
                                                    placeholder="Адрес пункта выдачи"
                                                    :clearInputOnFocus="!!pointSearch"
                                                    autocomplete="off"
                                                    @input="onChangePointSearchValue"
                                                    @blur="handlers.blur();"
                                                    @focus="handlers.focus();"
                                                    @keydown="handlers.keydown"
                                                ></UInputSearch>
                                            </template>

                                            <template #label="{ option }">
                                                <p class="ellipsis">
                                                    {{ option.service.codename === 'pochtaru'
                                                        ? option.code + ','
                                                        : '' }}
                                                    {{ currentCity.full_name }},
                                                    {{ option.address }}
                                                </p>
                                                <p class="dropdown__option-description ellipsis">
                                                    <span>{{ option.service.name }}</span>
                                                </p>
                                            </template>
                                        </UDropdown>

                                        <div class="points-filters-list_outer">
                                            <div class="points-filters-list">
                                                <div v-if="activeDeliveryService.length === 1">
                                                    <div
                                                        v-for="option in activeDeliveryService"
                                                        class="points-filter layer-2"
                                                    >
                                                        <div class="text_semi-bold">
                                                            <span class="d-block">
                                                                {{ option.name }} ({{ option.pickupPointsAmount }})
                                                            </span>
                                                            <span class="d-block text_secondary">
                                                                от {{ option.price | price }} / {{ option.days }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <template v-else>
                                                    <CheckboxBase
                                                        v-for="option in deliveryService"
                                                        :id="'pickup-point-' + option.codename"
                                                        :key="'pickup-point-' + option.codename"
                                                        v-model="pickupPointsFilter"
                                                        optionKey="codename"
                                                        :value="option.codename"
                                                        class="points-filter layer-2"
                                                    >
                                                        <div>
                                                            <span class="d-block text_bold">
                                                                {{ option.name }} ({{ option.pickupPointsAmount }})
                                                            </span>
                                                            <span class="d-block text_secondary text_semi-bold">
                                                                от {{ option.price | price }} / {{ option.days }}
                                                            </span>
                                                        </div>
                                                    </CheckboxBase>
                                                </template>
                                            </div>
                                        </div>

                                        <div class="map-wrap map-wrap_desktop">
                                            <yandexMap
                                                v-if="mapDefaultCenter.length"
                                                ref="map"
                                                class="map"
                                                :coords="mapDefaultCenter"
                                                zoom="13"
                                                :controls="['zoomControl']"
                                                :scrollZoom="false"
                                                :options="{ suppressMapOpenBlock: true }"
                                                :showAllMarkers="true"
                                                :clusterOptions="{
                                                    pochtaru: {
                                                        clusterDisableClickZoom: false,
                                                        clusterOpenBalloonOnClick: false,
                                                        clusterIconColor: '#0055A6',
                                                    },
                                                    cdek: {
                                                        clusterDisableClickZoom: false,
                                                        clusterOpenBalloonOnClick: false,
                                                        clusterIconColor: '#00B33C',
                                                    },
                                                }"
                                                @map-was-initialized="setMapCenter"
                                                @actionend="handleActionEnd"
                                            >
                                                <ymapMarker
                                                    v-for="(point, index) in pickupPoints"
                                                    :key="'point-' + point.code"
                                                    :ref="'point-' + point.code"
                                                    :icon="getYMapMarkerIcon({
                                                        type: point.service.codename,
                                                        active: balloon.visible && balloon.index === index
                                                    })"
                                                    :coords="point.coordinates"
                                                    :markerId="'point-' + point.code"
                                                    :clusterName="point.service.codename"
                                                    @click="showBalloon(point, index)"
                                                ></ymapMarker>
                                            </yandexMap>

                                            <PickupPointBalloon
                                                v-if="balloon.visible"
                                                :point="balloon.data"
                                                :orderUUID="order.uuid"
                                                :city="currentCity"
                                                :order="order"
                                                @getData="handlePointUpdateData"
                                                @close="resetBalloon"
                                                @select="selectPickupPoint"
                                            ></PickupPointBalloon>
                                        </div>

                                        <InfoMessage class="pick-up-points-note">
                                            Если вы не нашли свой пункт выдачи, скорее всего,
                                            заказ не&nbsp;может быть доставлен туда
                                            из-за ограничений на&nbsp;максимальный вес отправления.
                                        </InfoMessage>
                                    </div>
                                </div>

                                <div class="pickup-section_mobile">
                                    <div
                                        class="map-wrap map-wrap_mobile"
                                        @click="selectPickupPointMobileView = true"
                                    >
                                        <img
                                            v-if="currentCity.coordinates"
                                            :src="`https://static-maps.yandex.ru/1.x/?ll=${ currentCityCoordinatesArr[1] },${ currentCityCoordinatesArr[0] }&z=12&size=650,200&l=map&lang=ru_RU`"
                                            class="pickup-section__pseudo-map"
                                            alt=""
                                        >
                                        <ButtonBlock
                                            primary
                                            low
                                            class="pickup-section__btn"
                                        >Выбрать пункт выдачи</ButtonBlock>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </section>
                </template>
            </template>

            <section class="top-gap_small">
                <h3 class="h4 h4_block">
                    Получатель
                </h3>

                <div class="receiver-form">
                    <FormField
                        name="last_name"
                        label="Фамилия"
                        validations="required"
                        class="receiver-form__field"
                    ></FormField>
                    <FormField
                        name="first_name"
                        label="Имя"
                        validations="required"
                        class="receiver-form__field"
                    ></FormField>
                    <FormField
                        name="patronymic"
                        label="Отчество"
                        validations="required"
                        class="receiver-form__field"
                    ></FormField>
                    <FormField
                        type="phone"
                        name="phone"
                        label="Телефон"
                        visibleMask
                        validations="required"
                        class="receiver-form__field"
                    ></FormField>
                    <FormField
                        type="text"
                        name="comment"
                        label="Комментарий для службы доставки"
                        class="receiver-form__field receiver-form__field_comment"
                    ></FormField>
                </div>
            </section>

            <div class="footer">
                <p class="text_small-fz text_secondary">
                    К оплате принимаются карты VISA, MasterCard и МИР.
                    Для совершения оплаты вы будете перенаправлены на безопасную страницу ПАО «Сбербанк».
                    Для дополнительной аутентификации держателя карты используется защищенный протокол 3D Secure.
                </p>

                <div class="actions-wrap">
                    <ButtonBlock
                        secondary
                        class="action-btn"
                        @click="close"
                    >
                        Отмена
                    </ButtonBlock>
                    <ButtonBlock
                        primary
                        class="action-btn"
                        :loading="submitLoading"
                        @click="getDeliveryPaymentLink"
                    >
                        Оформить
                    </ButtonBlock>
                </div>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import { mapState } from 'vuex';
import { HTTP } from '@/http.js';
import dictionary from '@/dictionary.js';
import plural from '@/lib/plural.js';
import equals from '@/lib/equals.js';
import { getErrorCode } from '@/lib/errors.js';
import breakpointKey from '@/mixins/breakpointKey.js';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import popup from '@/mixins/popup.js';
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import FormField from '@/components/_form/FormField.vue';
import FormManager from '@/components/_form/FormManager.vue';
import UDropdown from '@/components/UDropdownDeprecated/UDropdownDeprecated.vue';
import UInputSearch from '@/components/InputSearch.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import Spinner from '@/components/Spinner.vue';
import PickupPointBalloon from '@/components/PickupPointBalloon.vue';
import CheckboxBase from '@/components/fields/CheckboxBase.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import InfoMessage from '@/components/InfoMessage.vue';


export default {
    name: 'GetCountryDeliveryPopup',

    components: {
        InfoMessage,
        ButtonIcon,
        ButtonText,
        CheckboxBase,
        PickupPointBalloon,
        yandexMap,
        ymapMarker,
        Spinner,
        ButtonBlock,
        FormField,
        UInputSearch,
        UDropdown,
        FormManager,
        PopupWrap,
    },

    mixins: [ymapMarkerMixin, popup, handleFormErrorMixin, breakpointKey],

    props: {
        order: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            dictionary,
            initLoading: true,

            searchValue: '',
            searchOptions: [],
            searchSource: null,
            searchTimeout: null,
            searching: false,
            searchInitialized: false,
            confirming: false,
            currentCity: null,
            cityFieldHasError: false,
            fetchingCurrentCity: false,

            pointSearchValue: '',
            pointSearch: null,

            formData: {
                receiving_method: '',
                courier_option: null,
                address: '',
                last_name: '',
                first_name: '',
                patronymic: '',
                phone: '',
                comment: '',
            },
            submitLoading: false,

            deliveryService: {},
            pickupPointsFilter: [],
            selectedPickupPoint: null,
            getDeliveryServiceDataLoading: false,

            courierOptions: [],
            courierOptionsPriceByAddressCounter: 0,

            mapInstance: null,
            mapCenter: [],
            balloon: {
                data: null,
                visible: false,
                index: null,
            },

            selectPickupPointMobileView: false,
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),

        currentCityCoordinatesArr() {
            return this.currentCity ? this.currentCity.coordinates.split(', ') : [];
        },

        deliveryServiceCodenames() {
            const { is_delivery_cdek, is_delivery_pochtaru } = this.order.shop;
            const deliveryService = [];
            if (is_delivery_cdek) deliveryService.push('cdek');
            if (is_delivery_pochtaru) deliveryService.push('pochtaru');
            return deliveryService;
        },

        activeDeliveryService() {
            return Object.values(this.deliveryService).filter(item => !item.disabled);
        },

        activeCourierDeliveryService() {
            const couriersServices = ['pochtaru', 'cdek'];

            return Object.values(this.deliveryService).filter(item => {
                return !item.disabled && couriersServices.includes(item.codename);
            });
        },

        isAddressValid() {
            return !this.$refs.form.innerErrors.address.length;
        },

        hasCourierType() {
            return Object.values(this.deliveryService).some(item => !item.disabled);
        },

        hasPickUpType() {
            return Object.values(this.deliveryService).some(item => item.pickup_points.length > 0);
        },

        pickupPoints() {
            let points = [];
            for (const [key, value] of Object.entries(this.deliveryService)) {
                if (this.pickupPointsFilter.includes(key)) {
                    points = [...points, ...value.pickup_points];
                }
            }
            return points;
        },

        pointSearchOptions() {
            let points = [];
            for (const [key, value] of Object.entries(this.deliveryService)) {
                if (this.pickupPointsFilter.includes(key)) {
                    let filteredPoints = value.pickup_points;
                    if (this.pointSearchValue) {
                        const re = new RegExp(this.pointSearchValue, 'i');
                        filteredPoints = value.pickup_points.filter(item => re.test(item.address));
                    }

                    points = [...points, ...filteredPoints];
                }
            }
            return points;
        },

        mapDefaultCenter() {
            if (this.currentCity.coordinates.length) {
                return this.currentCity.coordinates.split(', ').map(_ => Number(_));
            }
            if (this.pickupPoints[0]) {
                return this.pickupPoints[0].coordinates;
            }
            return [];
        },
    },

    watch: {
        courierOptionsPriceByAddressCounter: {
            handler(value) {
                if (value === this.activeDeliveryService.length) {
                    if (this.courierOptions.length === 1) {
                        this.formData.courier_option = this.courierOptions[0].value;
                    }
                }
            },
        },

        breakpointKey: {
            handler (value){
                this.selectPickupPointMobileView = value !== 'xs';
            },
            immediate: true,
        },
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        clearTimeout(this.searchTimeout);
        if (this.searchSource) this.searchSource.cancel();
    },

    methods: {
        async init() {
            this.formData.last_name = this.profile.last_name || '';
            this.formData.first_name = this.profile.first_name || '';
            this.formData.patronymic = this.profile.patronymic || '';
            this.formData.phone = this.profile.phone || '';

            const deliveryService = {};
            const { is_delivery_cdek, is_delivery_pochtaru } = this.order.shop;
            if (is_delivery_pochtaru) {
                deliveryService.pochtaru = {
                    codename: 'pochtaru',
                    name: 'Почта России',
                    logo: require('../../../../assets/images/pochtaru-logo.png'),
                    price: null,
                    days: null,
                    disabled: false,
                    pickup_points: [],
                };
            }
            if (is_delivery_cdek) {
                deliveryService.cdek = {
                    codename: 'cdek',
                    name: 'СДЭК',
                    logo: require('../../../../assets/images/cdek-logo.png'),
                    price: null,
                    days: null,
                    disabled: false,
                    pickup_points: [],
                };
            }
            this.deliveryService = deliveryService;

            await this.$api.delivery.services()
                .then(res => {
                    res.results.forEach(item => {
                        this.deliveryService[item.codename] = Object.assign(this.deliveryService[item.codename], item);
                    });
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                });

            this.initLoading = false;
        },

        onSelectCity({ value: option }) {
            this.currentCity = option;
            this.searchValue = option.full_name;
            this.cityFieldHasError = false;
            this.resetBalloon();
            this.resetSelectedPickupPoint();
            this.getDeliveryServiceData();
        },

        onClear() {
            this.searchInitialized = false;
            this.cityFieldHasError = false;
            // ждём, пока не закончится анимация
            setTimeout(() => {
                this.searchOptions = [];
            }, 200);
        },

        onFocus() {
            if (this.currentCity || !this.searchValue) return;
            this.fetchCities();
        },

        async fetchCities() {
            if (this.searchSource) {
                this.searchSource.cancel();
                this.searchSource = null;
            }

            const search = this.searchValue;

            if (!search || search.length < 2) return;

            this.searching = true;
            const limit = 6;
            const params = { search, limit };
            this.searchSource = HTTP.CancelToken.source();
            const cancelToken = this.searchSource.token;
            const config = { params, cancelToken };

            try {
                const { results } = await this.$api.cities.get(config);

                this.searchOptions = results;
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;

                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.searching = false;

                // ждём, пока options обновится в UDropdown
                // чтобы корректно отработала анимация
                this.$nextTick(() => {
                    this.searchInitialized = true;
                });
            }
        },

        async setFetchCitiesTimeout() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }

            if (this.searchValue) {
                this.searchTimeout = setTimeout(() => {
                    this.fetchCities();
                }, 500);
            }
            else {
                if (this.searchSource) {
                    this.searchSource.cancel();
                    this.searchSource = null;
                }
            }
        },

        onChangeSearchValue({ value }) {
            if (value === this.searchValue) return;

            this.searchValue = value;
            this.setFetchCitiesTimeout();
            this.currentCity = null;
        },

        onBlurSearchValue() {
            this.cityFieldHasError = this.searchValue && !this.currentCity;
            this.searchInitialized = false;
        },

        onChangePointSearchValue({ value }) {
            if (value === this.pointSearchValue) return;
            this.pointSearchValue = value;
            this.pointSearch = null;
        },
        onSelectSearchPoint({ value: option }) {
            this.pointSearch = option;
            this.pointSearchValue = (option.service.codename === 'pochtaru' ? option.code + ', ' : '')
                + this.currentCity.full_name + ', ' + option.address;
            this.showBalloon(option);
        },

        async getDeliveryServiceData() {
            this.pickupPointsFilter = [];
            // 1. Запрос на пункты выдачи => анализ наличия возможности забрать из ПВЗ
            // 2. Запрос на предрасчёт цен и сроков по всем СД (by_city) => анализ наличия курьерской доставки

            // ПВЗ
            // 1. Отображение карты с ПВЗ
            // 2. Отображение балуна ПВЗ
            // 3. Поиск ПВЗ по адресу
            // 4. Выбор ПВЗ
            // 5. Изменение ПВЗ
            // 6. Оплата

            // Курьер
            // 1. Получение СД(цен и сроков) по выбранном адресу (by_order)
            // 2. Отображение СД
            // 3. Выбор СД
            // 4. Оплата

            this.getDeliveryServiceDataLoading = true;
            let requests = [
                this.$api.delivery.offices({ params: {
                    city: this.currentCity.id,
                    service__codename__in: this.deliveryServiceCodenames.join(','),
                } }),
            ];
            this.deliveryServiceCodenames.forEach(item => {
                requests.push(this.$api.delivery.estimate.byCity({
                    service: item,
                    shop_from: this.order.shop.id,
                    city_from: this.order.shop.city.id,
                    city_to: this.currentCity.id,
                    weight: this.order.weight,
                }));
            });

            let deliveryOffices, services = [];
            await Promise.allSettled(requests)
                .then((results) => {
                    if (results[0].status === 'fulfilled') {
                        deliveryOffices = results[0].value;
                    }
                    results.slice(1).forEach((result) => {
                        if (result.status === 'fulfilled') {
                            services.push(result.value);
                        }
                    });
                });
            this.pickupPointsFilter = [];
            deliveryOffices.results.forEach(item => {
                item.coordinates.reverse();
                this.deliveryService[item.service.codename].pickup_points.push(item);

                if (!this.pickupPointsFilter.includes(item.service.codename)) {
                    this.pickupPointsFilter.push(item.service.codename);
                }
            });
            services.forEach(item => {
                if (item.service) {
                    this.deliveryService[item.service] = Object.assign(
                        this.deliveryService[item.service],
                        {
                            pickupPointsAmount: this.deliveryService[item.service].pickup_points.length,
                            tariff_code: item.tariff_code,
                            price: item.total_price,
                            days: item.days_max + ' ' + plural(item.days_max, ['день', 'дня', 'дней']),
                            disabled: false,
                        },
                    );
                }
            });

            if (this.hasCourierType && !this.hasPickUpType) this.formData.receiving_method = 'courier';
            else this.formData.receiving_method = 'pickup';

            this.getDeliveryServiceDataLoading = false;
        },

        setMapCenter(mapInstance) {
            this.mapInstance = mapInstance;
            if (this.pickupPoints.length > 1) {
                const pointsList = this.pickupPoints.map(item => item.coordinates);
                mapInstance.setBounds(ymaps.util.bounds.fromPoints(pointsList),{ checkZoomRange: true });
            }
        },

        handleActionEnd(e) {
            const newCenter = e.originalEvent.map.getCenter();
            if (this.mapCenter[0] !== newCenter[0] || this.mapCenter[1] !== newCenter[1]) {
                this.mapCenter = newCenter;
            }
        },

        showBalloon(point, index) {
            this.resetBalloon();
            this.balloon.visible = true;
            this.balloon.data = point;
            this.balloon.index = index;
            this.$nextTick(() => {
                if (this.mapInstance && this.mapInstance.length === 2) this.mapInstance.setCenter(this.mapCenter);
            });
        },

        resetBalloon() {
            this.balloon.visible = false;
            this.balloon.data = null;
            this.balloon.index = null;
            this.$nextTick(() => {
                if (this.mapInstance && this.mapInstance.length === 2) this.mapInstance.setCenter(this.mapCenter);
            });
        },

        selectPickupPoint({ point, priceData }) {
            this.selectedPickupPoint = Object.assign({
                days_max: priceData.days_max,
                days_max_text: priceData.days_max_text,
                total_price: priceData.total_price,
                tariff_code: priceData.tariff_code,
                loading: false,
            }, point);
        },

        handlePointUpdateData(data) {
            if (data) {
                const point = this.deliveryService[this.balloon.data.service.codename].pickup_points.find(item => {
                    return item.code === data.code;
                });

                if (point) {
                    point.priceData = data.priceData || {};
                }
            }
        },

        resetSelectedPickupPoint() {
            this.selectedPickupPoint = null;
            this.deliveryServiceCodenames.forEach(item => {
                this.deliveryService[item] = Object.assign(
                    this.deliveryService[item],
                    {
                        pickup_points: [],
                        pickupPointsAmount: 0,
                        tariff_code: null,
                        price: null,
                        days: null,
                        disabled: true,
                    },
                );
            });
        },

        addressFieldReducer(data) {
            return data.reduce((acc, item) => {
                const fullAddress = [];
                if (item.settlement_with_type) fullAddress.push(item.settlement_with_type);
                if (item.street_with_type) fullAddress.push(item.street_with_type);
                if (item.house) fullAddress.push(item.house_type + ' ' + item.house);
                if (item.block) fullAddress.push(item.block_type + ' ' + item.block);
                if (item.flat) fullAddress.push(item.flat_type + ' ' + item.flat);
                acc.push({
                    ...item,
                    value: fullAddress.join(', '),
                });
                return acc;
            }, []);
        },

        onSelectCourierAddress(option) {
            if (!equals(this.formData.address, option)) {
                this.courierOptions = [];
                if (option) {
                    this.$nextTick(() => {
                        if (this.isAddressValid) {
                            this.getCourierOptionsPriceByAddress(option.value);
                        }
                    });
                }
            }
        },

        getCourierOptionsPriceByAddress(address_to) {
            this.courierOptionsPriceByAddressCounter = 0;
            this.activeCourierDeliveryService.forEach(item => {
                let response = {};
                const data = {
                    service: item.codename,
                    order: this.order.uuid,
                    address_to,
                    office_to: '',
                    city_to: this.currentCity.id,
                };
                this.$api.delivery.estimate.byOrder(data)
                    .then(res => {
                        response = {
                            value: item.codename,
                            price: res.total_price,
                            service: this.deliveryService[item.codename],
                            days: (res.days_max > 1 ? 'до ' : '')
                                + res.days_max
                                + ' '
                                + plural(res.days_max, ['день', 'дней', 'дней']),
                        };
                        if (!res.tariff_code) response.disabled = true;
                        this.courierOptions.push(response);
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        this.courierOptionsPriceByAddressCounter++;
                    });
            });
        },

        async getDeliveryPaymentLink() {
            const errors = await this.$refs.form.validate();
            if (errors) return;

            if (!this.currentCity) {
                this.cityFieldHasError = true;
                this.$error('Укажите город для доставки.');
                return;
            }

            if (this.formData.receiving_method === 'courier') {
                if (!this.formData.courier_option) {
                    this.$error('Выберите службу доставки.');
                    return;
                }
            }
            else {
                if (!this.selectedPickupPoint) {
                    this.$error('Выберите пункт выдачи заказа.');
                    return;
                }
            }

            this.submitLoading = true;
            let service;
            const data = {
                address_to: '',
                address_full_to: null,
                office_to: '',

                order: this.order.uuid,
                city_to: this.currentCity.id,
                first_name_to: this.formData.first_name,
                second_name_to: this.formData.patronymic,
                last_name_to: this.formData.last_name,
                phone_to: this.formData.phone,
                user_comment: this.formData.comment,
            };
            if (this.formData.receiving_method === 'courier') {
                service = this.formData.courier_option;
                data.address_to = this.formData.address.value;
                data.address_full_to = {
                    location: this.formData.address.settlement || '',
                    street: this.formData.address.street,
                    house: this.formData.address.house,
                    building: this.formData.address.block || '',
                    corpus: this.formData.address.block || '',
                    office: this.formData.address.flat,
                    room: this.formData.address.flat,
                };
            }
            else {
                service = this.selectedPickupPoint.service.codename;
                data.office_to = this.selectedPickupPoint.id;
            }

            try {
                const { payment_url } = await this.$api.delivery[service].paymentLink(data);
                window.location.href = payment_url;
            }
            catch (error) {
                const code = getErrorCode(error);

                if (code === 400) {
                    const data = error.response.data;
                    const { non_field_errors } = data;
                    if (non_field_errors && non_field_errors.find(_ => _.code === 'not_estimated')) {
                        this.$error('Расчёт стоимости доставки устарел. Оформить доставку можно после обновления цены.');
                        if (this.selectedPickupPoint) {
                            const data = {
                                service: this.selectedPickupPoint.service.codename,
                                order: this.order.uuid,
                                address_to: '',
                                office_to: this.selectedPickupPoint.id,
                            };
                            this.selectedPickupPoint.loading = true;
                            this.$api.delivery.estimate.byOrder(data)
                                .then(res => {
                                    this.selectedPickupPoint = Object.assign(this.selectedPickupPoint, {
                                        price: res.total_price,
                                        days: (res.days_max > 1 ? 'до ' : '')
                                            + res.days_max
                                            + ' '
                                            + plural(res.days_max, ['день', 'дней', 'дней']),
                                    });
                                })
                                .catch(() => {
                                })
                                .finally(() => {
                                    this.selectedPickupPoint.loading = false;
                                });
                        }
                        else if (this.formData.courier_option) {
                            const data = {
                                service: this.formData.courier_option.service,
                                order: this.order.uuid,
                                address_to: this.formData.address,
                                office_to: '',
                            };
                            this.$api.delivery.estimate.byOrder(data)
                                .then(res => {
                                    this.formData.courier_option = Object.assign(this.formData.courier_option, {
                                        price: res.total_price,
                                        days: (res.days_max > 1 ? 'до ' : '')
                                            + res.days_max
                                            + ' '
                                            + plural(res.days_max, ['день', 'дней', 'дней']),
                                    });
                                })
                                .catch(() => {
                                })
                                .finally(() => {
                                    this.courierOptionsPriceByAddressCounter++;
                                });
                        }
                    }
                    else {
                        this.$store.commit('handleCommonHttpError', error);
                    }
                }
                else {
                    this.$store.commit('handleCommonHttpError', error);
                }
            }
            this.submitLoading = false;
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 780px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 636px;
    }
}

.dropdown >>> .u-dropdown__options-list {
    max-height: 320px;
}

.dropdown__option-description {
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
    line-height: 1.33;
}

@media (min-width: 768px) {
    .receiver-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }

    .receiver-form__field_comment {
        grid-column: 1/3;
    }
}
@media (max-width: 767px) {
    .receiver-form__field:not(:last-child) {
        margin-bottom: 16px;
    }
}

.pick-up-options >>> .input-radio-borders__label {
    margin-right: 0;
}
@media (min-width: 768px) {
    .pick-up-options >>> .input-radio-borders__wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        margin-bottom: 0;
        margin-right: 0;
    }

    .pick-up-options >>> .input-radio-borders__label {
        margin-bottom: 0;
    }
}

.footer {
    padding-top: 36px;
}
@media (max-width: 767px) {
    .footer {
        margin-top: auto;
    }
}

.actions-wrap {
    margin-top: 20px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

.transport__logo {
    width: 60px;
    height: 40px;
    margin-left: auto;
}

.map-wrap {
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
}
@media (min-width: 768px) {
    .map-wrap {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .map-wrap_desktop {
        width: calc(100% + var(--content-gap) * 2);
        max-height: 617px;
        height: 100%;
        margin-left: var(--content-gap-negative);
    }

    .map-wrap_mobile {
        height: 100px;
    }
}

.map {
    width: 100%;
}
@media (min-width: 768px) {
    .map {
        height: 580px;
    }
}
@media (max-width: 767px) {
    .map {
        height: 100%;
        max-height: 617px;
    }
}


.pickup-section__pseudo-map {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    max-width: 515px;
    min-height: 100px;
    object-fit: cover;
}

@media (max-width: 767px) {
    .pickup-section_desktop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: calc(var(--popup-close-button-z-index) + 1);
        display: flex;
        flex-direction: column;
        background-color: var(--light-c);
    }

    .pickup-section_desktop_hidden {
        opacity: 0;
        z-index: -1;
    }

    .select-body {
        flex-grow: 1;
        padding-top: 20px;
        padding-left: var(--content-gap);
        padding-right: var(--content-gap);
    }
}

.pickup-section_mobile {
    display: none;
}
@media (max-width: 767px) {
    .pickup-section_mobile {
        display: block;
    }
}

.pickup-section__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
}

.select-head {
    display: none;
}
@media (max-width: 767px) {
    .select-head {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: calc(var(--content-gap) - 6px);
        padding-right: calc(var(--content-gap) + 36px - 6px);
        border-bottom: 1px solid var(--border-light-c);
    }
}

.select-head__back-btn {

}
.select-head__title {
    margin: auto;
}

.points-filters-list_outer {
    position: relative;
}

.points-filters-list {
    display: flex;
}
@media (min-width: 768px) {
    .points-filters-list {
        position: absolute;
        top: 16px;
        left: 8px;
        z-index: 2;
        max-width: calc(100% - 16px);
        margin-bottom: -8px;
        margin-right: -8px;
        flex-wrap: wrap;
    }
}
@media (max-width: 767px) {
    .points-filters-list_outer {
        margin-left: var(--content-gap-negative);
        margin-right: var(--content-gap-negative);
        overflow: hidden;
    }

    .points-filters-list {
        padding-top: 8px;
        padding-bottom: 40px;
        margin-bottom: -32px;
        overflow: auto;
    }
    .points-filters-list::before,
    .points-filters-list::after {
        content: "";
        display: block;
        width: var(--content-gap);
        height: 1px;
        flex-shrink: 0;
    }
}

.points-filter {
    padding: 8px 16px;
}
@media (min-width: 768px) {
    .points-filter {
        margin-right: 8px;
        margin-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .points-filter:not(:last-child) {
        margin-right: 8px;
    }
}

@media (min-width: 768px) {
    .pick-up-points-note {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .pick-up-points-note {
        position: absolute;
        z-index: 2;
        left: var(--content-gap);
        right: var(--content-gap);
        bottom: 20px;
    }
}

.selected-pickup-point {
    border-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .selected-pickup-point {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.selected-pickup-point__section {
    position: relative;
    padding: 20px;
    border: 1px solid var(--border-light-c);
}
.selected-pickup-point__section_main {
    border-bottom: none;
    border-radius: var(--border-radius-x2) var(--border-radius-x2) 0 0;
}
@media (min-width: 768px) {
    .selected-pickup-point__section:nth-child(2) {
        border-right: none;
        border-bottom-left-radius: var(--border-radius-x2);
    }

    .selected-pickup-point__section:nth-child(3) {
        text-align: right;
        border-bottom-right-radius: var(--border-radius-x2);
    }
    .selected-pickup-point__section:nth-child(3) .spinner {
        margin-left: auto;
    }
    .selected-pickup-point__section_main {
        grid-column: 1/3;
        border-bottom: none;
    }
}
@media (max-width: 767px) {
    .selected-pickup-point__section:nth-child(2) {
        border-bottom: none;
    }

    .selected-pickup-point__section:nth-child(3) {
        border-radius: 0 0 var(--border-radius-x2) var(--border-radius-x2);
    }
}

.selected-pickup-point__logo {
    width: 60px;
    height: 40px;
    margin-bottom: 12px;
}

.selected-pickup-point__reset-btn {
    position: absolute;
    top: 20px;
    right: 10px;
}

@media (min-width: 768px) {
    .selected-pickup-point__description {
        margin-top: 20px;
        grid-column: 1/3;
    }
}
@media (max-width: 767px) {
    .selected-pickup-point__description {
        margin-top: 16px;
    }
}

.schedule-row {
    display: flex;
}

.schedule-row:not(:last-child) {
    margin-bottom: 8px;
}

.schedule-row::after {
    content: "";
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 5px;
    order: 2;
    align-self: flex-end;
    flex-grow: 1;
    border-bottom: 1px dotted var(--border-dark-c);
}

.schedule-td:first-child {
    order: 1;
}

.schedule-td:last-child {
    order: 3;
}
</style>