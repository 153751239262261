import filter from './filter.js';
import sort from './sort.js';
import deliveryOffices from '@/store/modules/deliveryOffices.js';


const staticFilters = [
    {
        widget: 'radio',
        codename: 'in_stock',
        options: [
            {
                value: 'true',
                label: 'В наличии',
            },
            {
                value: 'false',
                label: 'Все товары, включая отсутствующие',
            },
        ],
    },
    {
        title: 'Цена, ₽',
        widget: 'range',
        codename: 'price',
        min: 0,
        max: 0,
    },
    {
        title: 'Производители',
        widget: 'checkboxes',
        codename: 'brand',
        options: [],
    },
    {
        title: 'Магазины',
        widget: 'checkboxes',
        codename: 'company',
        options: [],
    },
];

export default {
    namespaced: true,

    modules: {
        filter,
        sort,
        deliveryOffices,
    },

    state: () => ({
        category: {
            item: {},
            children: {
                items: [],
                current_count: 0,
            },
        },
        products: {
            items: [],
            current_count: 0,
            total_count: 0,
            hasNext: false,
        },
        initialized: false,
        loading: true,
    }),

    getters: {
        breadcrumbs: state => {
            return [
                {
                    to: {
                        name: 'market',
                    },
                    title: 'Запчасти и автотовары',
                },
                {
                    to: {
                        name: 'market-products-catalog',
                    },
                    title: 'Каталог автотоваров',
                },
                ...((state.category.item || {}).parents || []).map(category => {
                    return {
                        to: {
                            name: 'market-products-category',
                            params: {
                                slug: category.slug,
                            },
                        },
                        title: category.name,
                    };
                }),
            ];
        },
    },

    mutations: {
        setCategory(state, category) {
            state.category.item = category;
        },

        setCategoryChildren(state, { results, current_count }) {
            state.category.children.items = results;
            state.category.children.current_count = current_count;
        },

        setProductsTotalCount(state, count) {
            state.products.total_count = count;
        },

        setProducts(state, { results, current_count, next }) {
            state.products.items = results;
            state.products.current_count = current_count;
            state.products.hasNext = !!next;
        },

        addProducts(state, { results, current_count, next }) {
            state.products.items = [...state.products.items, ...results];
            state.products.current_count = current_count;
            state.products.hasNext = !!next;
        },

        changeFavorite(state, { index, value }) {
            state.products.items[index].is_favorite = value;
        },

        changeCart(state, { itemIndex, itemId, offerId, inCartStatus }) {
            if (itemIndex !== undefined) {
                const currentOffer = state.products.items[itemIndex].offers.find(offer => offer.id === offerId);
                if (currentOffer) currentOffer.in_basket = true;
            }
            else if (itemId !== undefined) {
                const currentItem = state.products.items.find(item => item.id === itemId);
                const currentOffer = currentItem.offers.find(item => item.id === offerId);
                if (currentOffer) currentOffer.in_basket = inCartStatus;
            }
        },

        setInitialized(state) {
            state.initialized = true;
        },

        setLoading(state, value) {
            state.loading = value;
        },

        destroy(state) {
            state.category = {
                item: {},
                children: {
                    items: [],
                    current_count: 0,
                },
            };
            state.products = {
                items: [],
                current_count: 0,
                total_count: 0,
            };
            state.initialized = false;
        },
    },

    actions: {
        async init({ rootState, dispatch, commit }, { route }) {
            try {
                commit('setLoading', true);
                const category = await this.$api.goodsCategories.get(route.params.slug);
                commit('setCategory', category);

                if (category.is_leaf) {
                    let queryWithoutCar = Object.assign({}, route.query);
                    delete queryWithoutCar.car_manufacturer;
                    delete queryWithoutCar.car_model;
                    delete queryWithoutCar.car_year;
                    delete queryWithoutCar.car_modification;

                    const params = Object.assign(
                        {
                            category: category.id,
                            get_facets: 'all',
                            limit: 36,
                            offset: 0,
                            order_by: '-rating',
                            in_stock: true,
                        },
                        queryWithoutCar,
                    );

                    try {
                        const [
                            products,
                            defaultFacets,
                            { dynamicFilters, selectionFilters },
                            delivery,
                        ] = await Promise.all([
                            this.$api.productsOneOfferDoc.get({ params }),
                            dispatch('getDefaultFacets', { id: category.id }),
                            dispatch('filter/getDynamicFiltersInCategory', {
                                slug: category.slug,
                                routeName: route.name,
                            }),
                            dispatch('deliveryOffices/getItems'),
                        ]);

                        products.results.forEach(product => product.delivery = delivery);

                        commit('setProducts', products);
                        commit('setProductsTotalCount', defaultFacets.current_count);

                        commit('filter/setChecked', []);

                        const facets = products.facets;

                        commit('filter/setStaticFilters', {
                            config: staticFilters,
                            facets,
                            defaultFacets: defaultFacets.facets,
                        });
                        commit('filter/setDynamicFilters', {
                            config: dynamicFilters,
                            facets,
                            defaultFacets: defaultFacets.facets,
                            current_count: products.current_count,
                        });
                        commit('filter/setSelectionFilters', {
                            config: selectionFilters,
                            facets,
                            defaultFacets: defaultFacets.facets,
                        });

                        dispatch(
                            'filter/parseQuery',
                            {
                                query: route.query,
                                filters: [
                                    ...staticFilters,
                                    ...dynamicFilters,
                                    ...selectionFilters,
                                ],
                            },
                        );
                        commit('sort/parseQuery', route.query);
                        commit('setInitialized');
                        commit('setLoading', false);
                        commit('clearHttpError', null, { root: true });

                        return { category, products, staticFilters, selectionFilters, dynamicFilters };
                    }
                    catch (error) {
                        commit('handleInitError', error, { root: true });
                    }
                }
                else {
                    try {
                        const categoryChildren = await dispatch('getCategoryChildren', { id: category.id });

                        commit('setCategory', category);
                        commit('setCategoryChildren', categoryChildren);
                        commit('setInitialized');
                        commit('setLoading', false);
                        commit('clearHttpError', null, { root: true });

                        return { category, categoryChildren };
                    }
                    catch (error) {
                        commit('handleInitError', error, { root: true });
                    }
                }
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getCategoryChildren({}, { id }) {
            const params = {
                parent_id__in: id,
                limit: 100000,
                offset: 0,
            };

            return await this.$api.goodsCategories.get({ params });
        },

        async getDefaultFacets({ rootState }, { id }) {
            const params = {
                category: id,
                get_facets: 'all',
                limit: 0,
                offset: 0,
            };

            return await this.$api.productsOneOfferDoc.get({ params });
        },

        async getProducts({ commit, dispatch }, { params, cancelToken }) {
            try {
                const [products, delivery] = await Promise.all([
                    this.$api.productsOneOfferDoc.get({ params, cancelToken }),
                    dispatch('deliveryOffices/getItems'),
                ]);

                products.results.forEach(product => product.delivery = delivery);

                return products;
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};