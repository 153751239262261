<template>
    <button
        type="button"
        class="map-preview-btn"
        @click="$emit('click')"
    >
        <yandexMap
            :coords="coordsArray"
            zoom="12"
            :controls="[]"
            :scrollZoom="false"
            :draggable="false"
            :options="{ suppressMapOpenBlock: true }"
            class="map-preview-btn__map"
        >
            <ymapMarker
                :coords="coordsArray"
                :icon="getYMapMarkerIcon({ type })"
                :markerId="coordsString"
            ></ymapMarker>
        </yandexMap>

        <UIcon name="expand" class="map-preview-btn__icon"></UIcon>
    </button>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'PointOnMapPreviewBtn',

    components: {
        UIcon,
        yandexMap,
        ymapMarker,
    },

    mixins: [ymapMarkerMixin],

    props: {
        coords: [Array, String],
        type: String,
    },

    computed: {
        coordsArray() {
            if (Array.isArray(this.coords)) {
                return this.coords;
            }
            return this.coords.split(', ').map(Number);
        },

        coordsString() {
            if (Array.isArray(this.coords)) {
                return this.coords.join();
            }
            return this.coords;
        },
    },
};
</script>

<style scoped>
.map-preview-btn {
    display: block;
    position: relative;
    width: 100%;
    height: 120px;
    background-color: var(--light-bg);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.map-preview-btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #000000;
    transition: opacity var(--transition);
}
.map-preview-btn:hover::after {
    opacity: .4;
}

.map-preview-btn__map {
    width: 100%;
    height: 100%;
}

.map-preview-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    background-color: var(--light-c);
    opacity: 0;
    cursor: pointer;
    transition: opacity var(--transition);
}

.map-preview-btn:hover .map-preview-btn__icon {
    opacity: 1;
}
</style>