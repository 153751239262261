var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"insurance-osago-offers"},[(!_vm.calculateIsCompleted || _vm.offers.length)?_c('BackLink',{staticClass:"back-link",attrs:{"to":{
            name: _vm.hasOrder ? 'insurance-osago-edit' : 'insurance-osago-request-step-4',
            params: {
                uuid: _vm.uuid,
            },
        },"text":_vm.hasOrder ? 'Изменить данные' : 'Вернуться к заявке'},nativeOn:{"click":function($event){return _vm.handleYandexMetrikaGoalClick.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"h1 h1_text insurance-osago__title"},[_vm._v("\n        Варианты стоимости ОСАГО\n    ")]),_vm._v(" "),_c('p',{staticClass:"descriptor"},[_vm._v("\n        Мы "+_vm._s(_vm.calculateIsCompleted ? 'рассчитали' : 'рассчитываем')+" стоимость ОСАГО в возможных страховых компаниях. Стоимость полиса зависит от КБМ.\n        "),_c('ButtonText',{attrs:{"dashed":"","secondary":"","dark":""},on:{"click":_vm.showBonusMalusPopup}},[_vm._v("Подробнее")])],1),_vm._v(" "),(!_vm.calculateIsCompleted || _vm.offersComputed.length)?_c('div',{staticClass:"insurance-options"},_vm._l((_vm.offersComputed),function(offer){return _c('InsuranceOffer',{key:'insurance-option-' + offer.company.code,attrs:{"offer":offer,"activeOffer":_vm.activeOffer,"loading":_vm.activateLoading,"calculateIsCompleted":_vm.calculateIsCompleted},on:{"select":function($event){return _vm.selectOffer(offer)}}})}),1):_vm._e(),_vm._v(" "),(!_vm.offersComputed.length && _vm.calculateIsCompleted)?_c('EmptyBlock',{attrs:{"icon":"empty","title":"Расчёт недоступен","text":"Не получилось рассчитать стоимость полиса ОСАГО на ваш автомобиль, попробуйте изменить данные к заявке."},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('ButtonBlock',{staticClass:"empty__btn",attrs:{"to":{
                    name: 'insurance-osago-request-step-4',
                    params: {
                        uuid: _vm.uuid,
                    }
                },"primary":"","low":""}},[_vm._v("\n                Вернуться к заявке\n            ")])]},proxy:true}],null,false,1930982451)}):_vm._e(),_vm._v(" "),_c('InsuranceFactoids')],1)}
var staticRenderFns = []

export { render, staticRenderFns }