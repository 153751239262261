import axios from 'axios';

export const geocode = async ({ coords, cancelToken }) => {
    const url = 'https://geocode-maps.yandex.ru/1.x/';
    const params = {
        geocode: coords,
        format: 'json',
        apikey: '9fac1df3-f402-4f37-890c-8f7c9179248b',
        results: 8,
    };

    const { data } = await axios.get(url, { params, cancelToken, withCredentials: false });
    return data;
};