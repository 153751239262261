<template>
    <div class="top-gap_medium">
        <h1 class="h1 h1_block">
            Автозапчасти
        </h1>

        <TransitionFade>
            <div
                v-if="fetching || initializing"
                key="loading"
                class="loading"
            >
                <USpinner big></USpinner>
            </div>

            <div
                v-else
                key="categories"
                class="categories-grid"
            >
                <div
                    v-for="category in partCategories"
                    :key="category.id"
                    v-lazy-container="{ selector: 'img' }"
                    class="parent layer-1"
                >
                    <RouterLink
                        v-if="category.children.length"
                        :to="{
                            name: 'market-parts-catalog-category',
                            params: {
                                slug: category.slug,
                            }
                        }"
                        class="parent__cover-name-block link-primary-hover"
                    >
                        <img
                            v-if="category.cover"
                            :data-src="$links.uploads + category.cover.thumbnails.category_cover_list"
                            :alt="category.name"
                            class="parent__cover"
                        >

                        <h2 class="h3">
                            {{ category.name }}
                        </h2>
                    </RouterLink>

                    <button
                        v-else
                        type="button"
                        class="parent__cover-name-block link-primary-hover"
                        @click="handleToCategoryClick(category)"
                    >
                        <img
                            v-if="category.cover"
                            :data-src="$links.uploads + category.cover.thumbnails.category_cover_list"
                            :alt="category.name"
                            class="parent__cover"
                        >

                        <h2 class="h3">
                            {{ category.name }}
                        </h2>
                    </button>

                    <ul
                        v-if="category.children.length"
                        class="children-list"
                    >
                        <li
                            v-for="child in category.children.slice(0, 3)"
                            :key="'child-' + child.id"
                            class="children-list__item"
                        >
                            <ButtonText
                                class="children-list__link link-primary-hover"
                                @click="handleToCategoryClick(child)"
                            >{{ child.name }}</ButtonText>
                        </li>
                    </ul>

                    <ButtonText
                        v-if="category.children.length > 3"
                        :to="{
                            name: 'market-parts-catalog-category',
                            params: {
                                slug: category.slug,
                            }
                        }"
                        underline
                        dark
                        class="parent__link"
                    >
                        Все запчасти
                    </ButtonText>
                </div>
            </div>
        </TransitionFade>

        <LastSee></LastSee>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import marketPartsCatalog from '@/store/modules/marketPartsCatalog.js';
import { safeRouterPush } from '@/router/router.js';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import TransitionFade from '@/components/_transitions/TransitionFade.vue';
import LastSee from '@/components/LastSee.vue';
const SelectCarPopup = () => import('@/components/popups/SelectCarPopup.vue');

export default {
    name: 'PartsCatalogPage',

    components: {
        LastSee,
        TransitionFade,
        USpinner,
        ButtonText,
    },

    metaInfo() {
        return {
            title: this.meta.meta_title || this.meta.name,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content: this.meta.meta_title ? this.meta.meta_title : this.meta.name },
                { property: 'og:description', content: this.meta.meta_description },
                { property: 'og:image', content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        return this.serverPrefetch();
    },

    computed: {
        ...mapState({
            car: state => state.searchWidget.car,
            meta: state => state.marketPartsCatalog.meta,
            fetching: state => state.marketPartsCatalog.fetching,
            initialized: state => state.marketPartsCatalog.initialized,
            initializing: state => state.marketPartsCatalog.initializing,
            partCategories: state => state.marketPartsCatalog.partCategories,
            selectedCar: state => state.searchWidget.car,
        }),

        fetchParams() {
            return {
                car_id: (this.car || {}).id,
                parent_id__isnull: 1,
                order_by: 'tree_id',
            };
        },

        hasSelectedCar() {
            return this.selectedCar && !!this.selectedCar.id;
        },
    },

    watch: {
        car: {
            handler() {
                if (this.initialized) {
                    this.updatePartCategories();
                }
            },
        },
    },

    created() {
        this.$store.registerModule('marketPartsCatalog', marketPartsCatalog);
    },

    beforeMount() {
        this.setInitializing(true);
    },

    mounted() {
        this.$breadcrumbs([
            {
                to: {
                    name: 'market',
                },
                title: 'Запчасти и автотовары',
            },
            {
                to: {
                    name: 'market-parts-catalog',
                },
                title: 'Автозапчасти',
            },
        ]);

        if (!this.initialized) {
            const config = { params: this.fetchParams };
            this.browserPrefetch(config);
        }
    },

    beforeDestroy() {
        this.destroy();
        this.$store.unregisterModule('marketPartsCatalog');
    },

    methods: {
        ...mapMutations({
            destroy: 'marketPartsCatalog/destroy',
            setInitializing: 'marketPartsCatalog/setInitializing',
        }),

        ...mapActions({
            serverPrefetch: 'marketPartsCatalog/serverPrefetch',
            browserPrefetch: 'marketPartsCatalog/browserPrefetch',
            fetchCategories: 'marketPartsCatalog/fetchCategories',
        }),

        async updatePartCategories() {
            const config = { params: this.fetchParams };
            await this.fetchCategories(config);
        },

        async handleToCategoryClick(category) {
            try {
                await this.checkSelectCar();
            }
            catch (e) {
                return;
            }

            this.toCategory(category);
        },

        toCategory(category) {
            safeRouterPush({
                name: 'market-parts-catalog-category',
                params: {
                    slug: category.slug,
                },
            });
        },

        async checkSelectCar() {
            if (this.hasSelectedCar) return;

            await this.showSelectCarPopup();
        },

        showSelectCarPopup() {
            return new Promise((resolve, reject) => {
                const props = {
                    modeOptions: [
                        {
                            label: 'По гос. номеру',
                            value: 'grz',
                        },
                        {
                            label: 'VIN/Frame',
                            value: 'vin_frame',
                        },
                        {
                            label: 'Из гаража',
                            value: 'garage',
                        },
                    ],
                    outerAddGarageMode: false,
                    callback: car => {
                        if (car) {
                            resolve();
                        }
                        else {
                            reject();
                        }
                    },
                };
                const options = { props };
                this.$popup(SelectCarPopup, options);
            });
        },
    },
};
</script>

<style scoped>
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}

@media (min-width: 1300px) {
    .categories-grid {
        grid-template-columns: repeat(5, 1fr);
    }
}
@media (min-width: 1160px) and (max-width: 1299px) {
    .categories-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1159px) {
    .categories-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) {
    .categories-grid {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

@media (min-width: 1040px) {
    .parent {
        padding: 24px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .parent {
        padding: 16px;
    }
}
@media (min-width: 768px) {
    .parent {
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 767px) {
    .parent {
        padding: 12px;
    }

    .parent:not(:last-child) {
        margin-bottom: 12px;
    }
}

.parent__cover-name-block {
    text-align: left;
}
@media (max-width: 767px) {
    .parent__cover-name-block {
        display: flex;
        align-items: center;
    }
}

.parent__cover {
    flex-shrink: 0;
}
@media (min-width: 1040px) {
    .parent__cover {
        width: 160px;
        height: 160px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .parent__cover {
        width: 120px;
        height: 120px;
    }
}
@media (min-width: 768px) {
    .parent__cover {
        margin-bottom: 12px;
    }
}
@media (max-width: 767px) {
    .parent__cover {
        width: 48px;
        height: 48px;
        margin-right: 12px;
    }
}

@media (min-width: 768px) {
    .children-list {
        margin-top: 14px;
        margin-bottom: auto;
    }
}
@media (max-width: 767px) {
    .children-list {
        display: none;
    }
}

.children-list__link {
    display: block;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: left;
}

.parent__link {
    margin-top: 18px;
    align-self: flex-start;
}
</style>