<template>
    <div class="profile__main">
        <EmptyBlock
            v-if="current_count === 0"
            icon="heart-btn-stroke"
            title="Нет избранных автотоваров"
            :hasBtn="false"
        >
            <template slot="text">
                <p class="profile-empty-block__text">
                    Добавляйте автотовары в избранное с помощью <span class="like-btn-wrap"><LikeBtn></LikeBtn></span>
                </p>
            </template>
        </EmptyBlock>

        <template v-else>
            <div class="items-grid">
                <div
                    v-for="(item, index) in products"
                    :key="'product-wrap-' + item.id"
                    class="item"
                >
                    <MarketItemColumn
                        :key="'product-' + item.id"
                        :item="item"
                        apiName="products"
                        modelName="product"
                        reviewsInPopup
                        @changeFavorite="onChangeFavorite(index)"
                        @callShowOffersPopup="showOffersPopup(item, 'product', index)"
                    ></MarketItemColumn>
                </div>
            </div>

            <ButtonText
                v-if="hasNext"
                class="load-more-btn"
                dashed
                :loading="loading"
                @click="load"
            >
                Показать ещё
            </ButtonText>
        </template>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { mapActions, mapMutations, mapState } from 'vuex';
import MarketItemColumn from '@/components/MarketItemColumn.vue';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
const OffersPopup = () => import('@/components/popups/OffersPopup.vue');

export default {
    name: 'ProfileFavoritesProducts',

    metaInfo () {
        return {
            title: 'ЛК / Мои избранные автотовары',
        };
    },

    components: { ButtonText, EmptyBlock, MarketItemColumn, LikeBtn },

    data() {
        return {
            rangeData: {
                limit: 30,
                offset: 0,
                order_by: '-create_date',
            },
            source: null,
            loadingTimeout: null,
            loading: false,
        };
    },

    computed: {
        ...mapState({
            products: state => state.profileFavorites.products.items,
            current_count: state => state.profileFavorites.products.current_count,
        }),

        hasNext() {
            return (this.rangeData.offset + this.rangeData.limit) < this.current_count;
        },
    },

    mounted() {
        this.$breadcrumbs([]);
    },

    methods: {
        ...mapMutations({
            ADD_ITEMS: 'profileFavorites/addProducts',
            REMOVE_ITEM: 'profileFavorites/remove',
        }),

        ...mapActions({
            GET_ITEMS: 'profileFavorites/getProducts',
        }),

        onChangeFavorite(index) {
            this.REMOVE_ITEM({ index, type: 'products' });
        },

        changeCart(itemIndex, offerId) {
            this.$emit('changeCart', { itemIndex, offerId });
        },

        showOffersPopup(item, type, itemIndex) {
            const props = {
                item: item,
                itemIndex: itemIndex,
                modelName: type,
                changeFavoriteCallback: (value) => this.onChangeFavorite(itemIndex, value),
                changeCartCallback: (offerId) => this.changeCart(itemIndex, offerId),
            };
            const options = { props };
            this.$popup(OffersPopup, options);
        },

        async fetch() {
            this.loadingTimeout = setTimeout(() => {
                this.loading = true;
            }, 300);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                params: this.rangeData,
                cancelToken: this.source.token,
            };

            const response = await this.GET_ITEMS(payload);
            if (response) {
                this.source = null;
                clearTimeout(this.loadingTimeout);
                return response;
            }
            this.loading = false;
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) this.ADD_ITEMS(response);
        },
    },
};
</script>

<style scoped src="../../../assets/css/profile.css"></style>
<style scoped>
@media (min-width: 1040px) {
    .items-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .items-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) {
    .items-grid {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .item:not(:last-child) {
        margin-bottom: var(--grid-gap);
    }
}
</style>