<template>
    <AccountLayout title="Заказы">
        <section>
            <div
                v-if="!initialized"
                class="spinner-wrap"
            >
                <USpinner
                    center
                    big
                ></USpinner>
            </div>

            <template v-else>
                <MarketItemNavigation
                    v-if="activeOrdersCount !== 0 || archivedOrdersCount !== 0"
                    class="layer-1 bottom-gap_grid"
                    small
                    :items="[
                        {
                            to: {
                                name: 'account-orders-active'
                            },
                            label: 'Активные',
                            amount: activeOrdersCount
                        },
                        {
                            to: {
                                name: 'account-orders-archive'
                            },
                            label: 'Завершённые',
                            amount: archivedOrdersCount
                        },
                    ]"
                ></MarketItemNavigation>

                <EmptyBlock
                    v-if="activeOrdersCount === 0 && archivedOrdersCount === 0"
                    icon="cart-stroke"
                    title="Нет заказов"
                    text="Здесь будут отображаться все заказы, оформленные вами в маркетплейсе."
                    :hasBtn="false"
                ></EmptyBlock>

                <transition
                    v-else
                    name="fade-page"
                    mode="out-in"
                >
                    <RouterView></RouterView>
                </transition>
            </template>
        </section>
    </AccountLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import AccountLayout from "@/pages/account/AccountLayout.vue";
import USpinner from '@ui/components/USpinner/USpinner.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import MarketItemNavigation from "@/components/MarketItemNavigation.vue";

export default {
    name: 'ProfileOrders',

    components: {
        MarketItemNavigation,
        EmptyBlock,
        USpinner,
        AccountLayout,
    },

    metaInfo() {
        return {
            title: 'ЛК / Мои заказы',
        };
    },

    serverPrefetch() {
        return this.INIT();
    },

    computed: {
        ...mapState({
            initialized: state => state.profileOrders.initialized,
            orders: state => state.profileOrders.activeOrders.items,
            activeOrdersCount: state => state.profileOrders.activeOrders.current_count,
            archivedOrdersCount: state => state.profileOrders.archivedOrders.current_count,
        }),

        ...mapGetters({
            ordersRoute: 'profileOrders/ordersRoute',
        }),
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
    },

    methods: {
        ...mapMutations({
            DESTROY: 'profileOrders/destroy',
        }),

        ...mapActions({
            INIT: 'profileOrders/init',
        }),

        async init() {
            this.$breadcrumbs([]);
            if (!this.initialized) await this.INIT();
            this.ordersRedirect();
        },

        ordersRedirect() {
            const location = {
                name: this.ordersRoute,
            };
            const toPath = this.$router.resolve(location).route.fullPath;

            if (this.$route.fullPath !== toPath) {
                this.$router.replace(location);
            }
        },
    },
};
</script>

<style scoped>
.spinner-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}
</style>