<template>
    <UTooltip
        v-if="value"
        noDetach
    >
        <template #trigger="{ handlers, attrs }">
            <button
                v-if="value"
                ref="likeActive"
                v-bind="attrs"
                class="like-btn like-btn_active btn btn_icon"
                :class="{
                    'like-btn_with-text': withText,
                    'like-btn_without-text-on-xs': noTextOnXS
                }"
                v-on="handlers"
                @click="removeFromFavorites"
            >
                <UIcon
                    class="like-icon like-icon_fill"
                    name="heart-btn-fill"
                ></UIcon>
                <span
                    v-if="withText"
                    class="like-text"
                    :class="{
                        'hidden-s': noTextOnXS
                    }"
                >В избранном</span>
            </button>
        </template>

        Убрать из избранного
    </UTooltip>

    <UTooltip
        v-else
        :maxWidth="isAuthorized ? 180 : null"
        noDetach
    >
        <template #trigger="{ handlers, attrs }">
            <button
                ref="likeNotActive"
                v-bind="attrs"
                class="like-btn btn btn_icon"
                :class="{
                    'like-btn_with-text': withText,
                    'like-btn_no-authorised': !isAuthorized,
                    'like-btn_without-text-on-xs': noTextOnXS,
                }"
                v-on="handlers"
                @click="addToFavorites"
            >
                <UIcon
                    class="like-icon like-icon_stroke"
                    name="heart-btn-stroke"
                ></UIcon>
                <span
                    v-if="withText"
                    class="like-text"
                    :class="{
                        'hidden-s': noTextOnXS
                    }"
                >В избранное</span>
            </button>
        </template>

        <span v-if="isAuthorized">
            Добавить в избранное
        </span>
        <span v-else>
            Только авторизованные пользователи могут добавлять в избранное
        </span>
    </UTooltip>
</template>

<script>
import { mapState } from 'vuex';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'LikeBtn',

    components: { UIcon, UTooltip },

    props: {
        value: {
            type: Boolean,
            default: false,
        },

        id: {
            type: [Number, String],
            default: null,
        },

        apiName: {
            type: String,
            default: '', // 'product', 'part_product', 'contract_part', 'shop', 'brand'
        },

        withText: {
            type: Boolean,
            default: false,
        },

        noTextOnXS: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState({
            isAuthorized: state => state.profile.isAuthorized,
        }),
    },

    methods: {
        addToFavorites() {
            if (!this.apiName) return;

            if (this.isAuthorized) {
                const url = `/${ this.apiName === 'part_product' ? 'part' : this.apiName }s/${ this.id }/add_to_favorites/`;
                this.$emit('changeFavorite', true);

                this.$http.post(url)
                    .then(() => {
                        const messagesMap = {
                            product: 'Товар добавлен в избранное',
                            part_product: 'Запчасть добавлена в избранное',
                            contract_part: 'Запчасть добавлена в избранное',
                            shop: 'Магазин добавлен в избранное',
                            brand: 'Производитель добавлен в избранное',
                        };
                        this.$success(messagesMap[this.apiName]);
                    })
                    .catch(error => {
                        this.$store.commit('handleCommonHttpError', error);
                        this.$emit('changeFavorite', false);
                    });
            }
            else {
                this.$notify({
                    type: 'error',
                    message: 'Только авторизованные пользователи могут добавлять в избранное',
                });
            }
        },

        removeFromFavorites() {
            if (!this.apiName) return;

            const url = `/${ this.apiName === 'part_product' ? 'part' : this.apiName }s/${ this.id }/remove_from_favorites/`;
            this.$emit('changeFavorite', false);

            this.$http.post(url)
                .then(() => {
                    const messagesMap = {
                        product: 'Товар удалён из избранного',
                        part_product: 'Запчасть удалена из избранного',
                        contract_part: 'Запчасть удалена из избранного',
                        shop: 'Магазин удалён из избранного',
                        brand: 'Производитель удалён из избранного',
                    };
                    this.$success(messagesMap[this.apiName]);
                })
                .catch(error => {
                    this.$store.commit('handleCommonHttpError', error);
                    this.$emit('changeFavorite', true);
                });
        },
    },
};
</script>

<style scoped>
.like-btn {
    border: 1px solid var(--border-dark-c);
    color: var(--dark-c);
    transition: color var(--transition);
}

@media (min-width: 768px) {
    .like-btn_with-text {
        width: 152px;
        padding-left: 12px;
        padding-right: 0;
        justify-content: flex-start;
    }
}
@media (max-width: 767px) {
    .like-btn_with-text:not(.like-btn_without-text-on-xs) {
        width: 152px;
        padding-left: 12px;
        padding-right: 0;
        justify-content: flex-start;
    }
}

.like-icon {
    width: 20px;
    height: 20px;
    fill: currentColor;
    transition: fill var(--transition), transform .1s ease-out;
}

.like-text {
    margin-left: 8px;
    font-size: 14px;
}

.like-btn_active,
.like-btn:hover,
.like-btn:focus {
    color: var(--primary-color);
}

.like-btn_active:hover,
.like-btn_active:focus {
    color: var(--primary-dark-color);
}

.like-btn:active .like-icon {
    transform: scale(1.2);
}

.like-btn_no-authorised {
    cursor: default;
}
</style>