<template>
    <button
        type="button"
        tabindex="-1"
        class="round-loader"
        :class="{
            'round-loader_small': small
        }"
    >
        <svg
            :width="width"
            :height="width"
            :viewbox="'0 0 ' + width + ' ' + height"
            class="round-loader__progress"
        >
            <circle
                :r="radius"
                :cx="x"
                :cy="y"
                :stroke-width="strokeWidth"
                :stroke-dasharray="circumference"
                :stroke-dashoffset="strokeDashoffset"
                fill="none"
                stroke-linecap="round"
            ></circle>
        </svg>

        <UIcon v-bind="icon" class="round-loader__close-icon"></UIcon>
    </button>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'RoundLoader',

    components: { UIcon },

    props: {
        small: Boolean,

        value: Number,
    },

    computed: {
        strokeWidth() {
            if (this.small) {
                return 1.3;
            }

            return 2;
        },

        strokeDashoffset() {
            if (this.value == null) return 0;

            return (this.circumference - this.strokeWidth * 2) / 100 * (100 - this.value);
        },

        radius() {
            return (this.width - Math.ceil(this.strokeWidth)) / 2;
        },

        x() {
            return this.width / 2;
        },

        y() {
            return this.height / 2;
        },

        circumference() {
            return 2 * this.radius * Math.PI;
        },

        icon() {
            const data = {
                name: 'close-16',
            };

            if (this.small) {
                data.small = true;
            }
            else {
                data.big = true;
            }

            return data;
        },

        width() {
            if (this.small) {
                return 20;
            }

            return 34;
        },

        height() {
            if (this.small) {
                return 20;
            }

            return 34;
        },
    },
};
</script>

<style>
.round-loader {
    position: relative;
    font-size: 0;
    width: 36px;
    height: 36px;
}

.round-loader.round-loader_small {
    width: 20px;
    height: 20px;
}

.round-loader__close-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}
</style>