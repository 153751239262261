<template>
    <PopupWrap
        noPadding
        semiFull
        @clickaway="close"
        @close="close"
    >
        <div class="photo-view-wrap">
            <Spinner
                v-if="!isOriginalLoaded"
                size="big"
                absoluteCenter
            ></Spinner>

            <div class="photo-view">
                <div
                    class="original-wrap"
                    :class="{ 'original-wrap_offset': initialPhotosLength > 1 }"
                >
                    <div class="original">
                        <div
                            ref="originalSlider"
                            class="original-slider"
                        >
                            <div
                                ref="originalSliderTrack"
                                class="original-slider-track"
                                @touchstart="lock"
                                @touchmove="drag"
                                @touchend="move"
                            >
                                <div
                                    v-for="(item, originalIndex) in currentPhotos"
                                    :key="'main-photo-' + originalIndex"
                                    :class="{ 'visually-hidden': !isOriginalLoaded }"
                                    class="original-slide original-photo-wrap"
                                >
                                    <img
                                        :src="$links.uploads + item.thumbnails[fullCoverCodename]"
                                        alt=""
                                        class="original-photo"
                                        @click="nextOriginalSlide"
                                        @load="onOriginalLoaded"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="initialPhotosLength > 1"
                    class="photo-view__arrow-navigation"
                >
                    <RoundArrowBtn
                        direction="left"
                        size="48"
                        shadow
                        class="photo-view__arrow photo-view__arrow_prev"
                        :disabled="slideIndex === 0"
                        @click="prevOriginalSlide"
                    ></RoundArrowBtn>
                    <RoundArrowBtn
                        direction="right"
                        size="48"
                        shadow
                        class="photo-view__arrow photo-view__arrow_next"
                        :disabled="slideIndex === initialPhotosLength - 1"
                        @click="nextOriginalSlide"
                    ></RoundArrowBtn>
                </div>

                <div
                    v-if="initialPhotosLength > 1"
                    class="thumbs-slider-wrap"
                    :class="{ 'visually-hidden': !isOriginalLoaded }"
                >
                    <div
                        ref="thumbsSlider"
                        class="thumbs-slider"
                    >
                        <button
                            v-for="(item, index) in initialPhotos"
                            :key="item.id"
                            :class="{ current: index === slideIndex }"
                            class="thumbs-item-wrap"
                            @click="thumbClickHandle(index)"
                        >
                            <img
                                :src="$links.uploads + (item.thumbnails[miniCoverCodename] || item.thumbnails[fullCoverCodename])"
                                alt=""
                                class="thumbs-item"
                            >
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import PopupWrap from "@/components/popups/PopupWrap.vue";
import popup from "@/mixins/popup.js";
import RoundArrowBtn from "../project-buttons/RoundArrowBtn.vue";
import Spinner from "../Spinner.vue";

export default {
    name: 'PhotoViewer',

    components: {
        Spinner,
        RoundArrowBtn,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        photos: {
            type: Array,
            default: () => ([]),
        },

        index: {
            type: Number,
            default: 0,
        },

        fullCoverCodename: {
            type: String,
            default: '',
        },

        miniCoverCodename: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            initialPhotos: [],
            initialPhotosLength: 0,
            currentPhotos: [],
            slideIndex: null,
            scenePosition: null,
            x0: null,
            preloaderTimeout: null,
            isOriginalLoaded: false,
        };
    },

    created() {
        this.slideIndex = this.index;

        this.initialPhotos = this.photos.map((currentValue) => {
            currentValue.isOriginalLoaded = false;
            return currentValue;
        });

        this.initialPhotosLength = this.initialPhotos.length;

        this.index === 0 ? this.scenePosition = 0 : this.scenePosition = 1;

        if (this.index > 0) {
            this.currentPhotos.push(this.initialPhotos[(this.index - 1)]);
        }

        this.currentPhotos.push(this.initialPhotos[this.index]);

        if (this.index !== this.initialPhotos.length - 1) {
            this.currentPhotos.push(this.initialPhotos[(this.index + 1)]);
        }
    },

    mounted() {
        this.setTrackTransform(this.scenePosition, true);
        document.addEventListener('keyup', event => this.chooseDirectionFunction(event));
        window.addEventListener('resize', this.handleResize);
    },

    beforeDestroy() {
        document.removeEventListener('keyup', event => this.chooseDirectionFunction(event));
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        getOriginalSliderWidth() {
            return this.$refs.originalSlider.offsetWidth;
        },

        setTrackTransform(position, withoutTransition) {
            let originalSliderTrack = this.$refs.originalSliderTrack;
            let offset = position * this.getOriginalSliderWidth();

            if (!withoutTransition) {
                originalSliderTrack.style.transition = 'transform .3s linear';
            }

            originalSliderTrack.style.transform = 'translateX(-' + offset + 'px)';

            if (!withoutTransition) {
                setTimeout(()=> {
                    originalSliderTrack.style.transition = '';
                }, 300);
            }
        },

        nextOriginalSlide() {
            if (this.slideIndex !== this.initialPhotosLength - 1) {
                let originalSliderTrack = this.$refs.originalSliderTrack;

                this.slideIndex += 1;
                this.scenePosition = 1;

                this.setTrackTransform(this.currentPhotos.length - 1);

                setTimeout(() => {
                    if (this.slideIndex + 1 <= this.initialPhotosLength - 1) {
                        this.currentPhotos.push(this.initialPhotos[this.slideIndex + 1]);
                        if (this.currentPhotos.length === 4) this.currentPhotos.shift();
                    }
                    else {
                        if (this.currentPhotos.length === 3) this.currentPhotos.shift();
                    }

                    originalSliderTrack.style.transform = 'translateX(-' + this.getOriginalSliderWidth() + 'px)';
                }, 300);
            }
        },
        prevOriginalSlide() {
            if (this.slideIndex !== 0) {
                let originalSliderTrack = this.$refs.originalSliderTrack;

                this.slideIndex = this.slideIndex - 1;
                this.scenePosition = this.currentPhotos.length - 2;

                this.setTrackTransform(0);

                setTimeout(() => {
                    if (this.slideIndex >= 1) {
                        this.currentPhotos.unshift(this.initialPhotos[this.slideIndex - 1]);
                        if (this.currentPhotos.length === 4) this.currentPhotos.pop();
                        originalSliderTrack.style.transform = 'translateX(-' + this.getOriginalSliderWidth() + 'px)';
                    }
                    else {
                        if (this.currentPhotos.length === 3) this.currentPhotos.pop();
                    }

                }, 300);
            }
        },
        indexOriginalSlide(index) {
            this.scenePosition = index === 0 ? 0 : 1;
            let originalSliderTrack = this.$refs.originalSliderTrack;

            if (this.currentPhotos.length === 2) {
                if (this.slideIndex === 0 && index === this.initialPhotosLength - 1) {
                    this.currentPhotos.splice(1, 1, this.initialPhotos[index]);
                    this.setTrackTransform(1);
                    setTimeout(() => {
                        this.currentPhotos.splice(0, 1, this.initialPhotos[index-1]);
                    }, 300);
                }
                else if (index === 0) {
                    this.currentPhotos.splice(0, 1, this.initialPhotos[index]);
                    this.setTrackTransform(0);
                    setTimeout(() => {
                        this.currentPhotos.splice(1, 1, this.initialPhotos[index+1]);
                    }, 300);
                }
                else if (this.slideIndex === this.initialPhotosLength - 1) {
                    this.currentPhotos.splice(0, 1, this.initialPhotos[index]);
                    this.setTrackTransform(0);
                    this.currentPhotos.splice(1, 1, this.initialPhotos[index+1]);
                    setTimeout(() => {
                        this.currentPhotos.splice(0, 0, this.initialPhotos[index-1]);
                        originalSliderTrack.style.transform = 'translateX(-' + this.getOriginalSliderWidth() + 'px)';
                    }, 300);
                }
                else {
                    this.currentPhotos.splice(1, 1, this.initialPhotos[index]);
                    this.currentPhotos.push(this.initialPhotos[index+1]);
                    this.setTrackTransform(1);
                }
            }
            else {
                if (index > this.slideIndex) {
                    this.currentPhotos.splice(2, 1, this.initialPhotos[index]);
                    if (index < this.initialPhotosLength-1) {
                        this.currentPhotos.push(this.initialPhotos[index+1]);
                    }
                    this.setTrackTransform(2);
                    setTimeout(() => {
                        this.currentPhotos.splice(0, 2, this.initialPhotos[index-1]);
                        originalSliderTrack.style.transform = 'translateX(-' + this.getOriginalSliderWidth() + 'px)';
                    }, 300);
                }
                else {
                    this.currentPhotos.splice(0, 1, this.initialPhotos[index]);
                    this.setTrackTransform(0);
                    this.currentPhotos.splice(1, 2, this.initialPhotos[index+1]);
                    if (index !== 0) {
                        setTimeout(() => {
                            this.currentPhotos.splice(0, 0, this.initialPhotos[index-1]);
                            originalSliderTrack.style.transform = 'translateX(-' + this.getOriginalSliderWidth() + 'px)';
                        }, 300);
                    }
                }
            }

            this.slideIndex = index;
        },

        handleResize() {
            this.setTrackTransform(this.scenePosition, true);
        },

        move(e) {
            let dx = this.unifyTouchEvent(e).clientX - this.x0,
                mathSign = Math.sign(dx),
                windowWidth = window.innerWidth,
                moveDistance = +(mathSign * dx / windowWidth).toFixed(2);

            if (moveDistance > .1) {
                if (dx < 0) {
                    this.nextOriginalSlide();
                }
                else {
                    this.prevOriginalSlide();
                }
            }
            else {
                this.$refs.originalSliderTrack.style.transition = 'transform .3s linear';
                this.setTrackTransform(this.scenePosition, true);
            }

            this.x0 = null;
        },
        unifyTouchEvent(e) {
            return e.changedTouches ? e.changedTouches[0] : e;
        },
        lock(e) {
            this.x0 = this.unifyTouchEvent(e).clientX;
        },
        drag(e) {
            let dx = this.unifyTouchEvent(e).clientX - this.x0,
                originalSliderTrack = this.$refs.originalSliderTrack,
                currentOffset = this.scenePosition * this.getOriginalSliderWidth(),
                offset = currentOffset - dx;

            originalSliderTrack.style.transition = 'transform .3s linear';
            originalSliderTrack.style.transform = 'translateX(-' + offset + 'px)';
        },
        chooseDirectionFunction(event) {
            if (event.code === 'ArrowRight') {
                this.nextOriginalSlide();
            }
            if (event.code === 'ArrowLeft') {
                this.prevOriginalSlide();
            }
        },

        thumbClickHandle(index) {
            if (index !== this.slideIndex) {
                this.indexOriginalSlide(index, true, true);

                let thumbsSlider = this.$refs.thumbsSlider;
                let currentThumb = document.querySelectorAll('.thumbs-item-wrap')[this.slideIndex];
                let windowPosition = {
                        left: thumbsSlider.getBoundingClientRect().left,
                        right: thumbsSlider.getBoundingClientRect().right - thumbsSlider.getBoundingClientRect().left,
                    },
                    targetPosition = {
                        left: currentThumb.getBoundingClientRect().left,
                        right: currentThumb.getBoundingClientRect().right + 16,
                    };

                if (targetPosition.right < windowPosition.right && targetPosition.left > windowPosition.left) {
                    thumbsSlider.scrollLeft = thumbsSlider.scrollLeft + currentThumb.clientWidth;
                }
                else {
                    if (targetPosition.left < windowPosition.left) {
                        thumbsSlider.scrollLeft = currentThumb.offsetLeft - currentThumb.clientWidth;
                    }

                    if (targetPosition.right > windowPosition.right) {
                        let nextThumbWidth = document.querySelectorAll('.thumbs-item-wrap')[this.slideIndex+1] ?
                            document.querySelectorAll('.thumbs-item-wrap')[this.slideIndex+1].clientWidth
                            : 0;

                        thumbsSlider.scrollLeft = thumbsSlider.scrollLeft + (targetPosition.right - windowPosition.right)
                            + nextThumbWidth;
                    }
                }
            }
        },

        onOriginalLoaded() {
            clearTimeout(this.preloaderTimeout);
            this.preloaderTimeout = setTimeout(() => {
                this.isOriginalLoaded = true;
            }, 500);
        },
    },
};
</script>

<style scoped>
.photo-view-wrap {
    position: relative;
    height: 100%;
}
@media (min-width: 1281px) {
    .photo-view-wrap {
        padding: 48px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .photo-view-wrap {
        padding: 36px;
    }
}
@media (max-width: 1039px) {
    .photo-view-wrap {
        padding-top: 76px;
        padding-bottom: 36px;
    }
}

.photo-view {
    position: relative;
    height: 100%;
}
@media (max-width: 1039px) {
    .photo-view {
        display: flex;
        flex-direction: column;
    }
}

.original-wrap {
    display: flex;
    height: 100%;
    flex-grow: 1;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .original-wrap {
        padding-left: 24px;
        padding-right: 24px;
    }
}
@media (max-width: 767px) {
    .original-wrap {
        padding-left: var(--content-gap);
        padding-right: var(--content-gap);
    }
}

@media (min-width: 1040px) {
    .original-wrap_offset {
        height: calc(100% - 48px - 36px);
        padding-left: 96px;
        padding-right: 96px;
    }
}
@media (max-width: 1039px) {
    .original-wrap {
        height: calc(100vh - 76px - 36px);
    }

    .original-wrap_offset {
        height: calc(100vh - 76px - 36px - 72px - 36px);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .original-wrap_offset {
        margin-left: 72px;
        margin-right: 72px;
    }
}

.original-slider-track {
    display: flex;
    min-width: 0;
    height: 100%;
    transition-timing-function: cubic-bezier(0.65, 0.05, 0.2, 1);
}

.original-slide {
    flex-shrink: 0;
}

@media (min-width: 1040px) {
    .photo-view__arrow-navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 36px;
    }
}
@media (max-width: 767px) {
    .photo-view__arrow-navigation {
        display: none;
    }
}

@media (min-width: 1040px) {
    .photo-view__arrow {
        margin-left: 18px;
        margin-right: 18px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .photo-view__arrow {
        position: absolute;
        top: calc(50% - 56px);
        transform: translateY(-50%);
    }

    .photo-view__arrow_prev {
        left: 0;
    }

    .photo-view__arrow_next {
        right: 0;
    }
}

.original {
    position: relative;
    max-width: 820px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    margin: auto;
    align-self: center;
}
@media (min-width: 1040px) {
    .original {
        display: flex;
        flex-direction: column;
    }
}

.original-slider {
    flex-grow: 1;
    overflow: hidden;
}
@media (max-width: 1039px) {
    .original-slider {
        height: 100%;
    }
}

.original-photo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
}

.original-photo {
    display: block;
    max-height: 100%;
    object-fit: contain;
}
.original-photo-wrap:not(:last-child) .original-photo {
    cursor: pointer;
    user-select: none;
}

.thumbs-slider-wrap {
    flex-shrink: 0;
    overflow: hidden;
}
@media (min-width: 1040px) {
    .thumbs-slider-wrap {
        position: absolute;
        top: 0;
        left: 0;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .thumbs-slider-wrap {
        margin-top: 36px;
    }
}
@media (max-width: 767px) {
    .thumbs-slider-wrap {
        margin-top: 24px;
    }
}

.thumbs-slider {
    overflow: auto;
    scroll-behavior: smooth;
}
@media (min-width: 1040px) {
    .thumbs-slider {
        margin-right: -40px;
        padding-right: 40px;
    }
}
@media (max-width: 1039px) {
    .thumbs-slider {
        display: flex;
        margin-bottom: -40px;
        padding-bottom: 40px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .thumbs-slider::before,
    .thumbs-slider::after {
        content: "";
        display: block;
        flex-shrink: 0;
        width: 24px;
    }
}
@media (max-width: 767px) {
    .thumbs-slider::before,
    .thumbs-slider::after {
        content: "";
        display: block;
        flex-shrink: 0;
        width: var(--content-gap);
    }
}

.thumbs-item-wrap {
    position: relative;
    display: block;
    flex-shrink: 0;
    border: 2px solid transparent;
    border-radius: var(--border-radius);
    transition: border-color var(--transition);
}

@media (min-width: 1040px) {
    .thumbs-item-wrap:not(:last-child) {
        margin-bottom: 12px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .thumbs-item-wrap:not(:last-child) {
        margin-right: 12px;
    }
}
@media (max-width: 767px) {
    .thumbs-item-wrap:not(:last-child) {
        margin-right: 8px;
    }
}

.thumbs-item-wrap::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    transition: border-color var(--transition);
}
.thumbs-item-wrap:not(.current):hover::before,
.thumbs-item-wrap:not(.current):focus::before {
    border-color: var(--border-light-c);
}

.thumbs-item-wrap.current {
    border-color: var(--primary-color);
    cursor: default;
}

.thumbs-item {
    margin: 2px;
}
@media (min-width: 768px) {
    .thumbs-item {
        width: 64px;
        height: 64px;
    }
}
@media (max-width: 767px) {
    .thumbs-item {
        width: 56px;
        height: 56px;
    }
}
</style>
