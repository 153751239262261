<template>
    <div class="u-key-value-dotted-item">
        <span
            role="term"
            class="u-key-value-dotted-item__key-wrap"
        >
            <span class="u-key-value-dotted-item__key">
                <slot name="key"></slot>
            </span>
            <span
                aria-hidden="true"
                class="u-key-value-dotted-item__dots"
            ></span>
        </span>
        <span
            role="definition"
            class="u-key-value-dotted-item__value"
        >
            <slot name="value"></slot>
        </span>
    </div>
</template>

<script>
export default {
    name: 'UKeyValueDottedItem',
};
</script>

<style>
.u-key-value-dotted-item {
    width: 100%;
    display: inline-flex;
}

.u-key-value-dotted-item__key-wrap {
    flex: 1;
    display: inline-flex;
    align-items: baseline;
    color: var(--font-secondary-color);
}

.u-key-value-dotted-item__key {
    flex-shrink: 0;
}

.u-key-value-dotted-item__dots {
    display: inline-flex;
    min-width: 24px;
    margin-left: 8px;
    margin-right: 8px;
    flex-grow: 1;
    border-bottom: 1px dotted var(--border-dark-c);
}

.u-key-value-dotted-item__value {
    flex: 1;
    color: var(--dark-c);
}
</style>