<template>
    <div
        ref="marketItemListUpper"
        class="market-item-list-upper layer-1"
    >
        <div
            ref="marketItemListUpperConstRow"
            :class="{
                'const-row_sticky': isSticky
            }"
            class="const-row"
        >
            <div
                v-if="$slots.sort"
                class="sort"
            >
                <span class="sort-label">Сортировка:</span>
                <slot name="sort"></slot>
            </div>

            <div class="view view_desktop">
                <slot name="view"></slot>
            </div>

            <slot name="filterToggler"></slot>
        </div>

        <CheckedFilter
            :filters="checkedFilters"
            @deleteFilter="onDeleteFilter"
            @resetFilters="onResetFilters"
        ></CheckedFilter>
    </div>
</template>

<script>
import CheckedFilter from "./CheckedFilter.vue";

export default {
    name: "MarketItemListUpper",

    components: { CheckedFilter },

    props: {
        checkedFilters: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            isSticky: false,
            lastScrollTop: 0,
        };
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        onResetFilters() {
            this.$emit('resetFilters');
        },

        onDeleteFilter(filter) {
            this.$emit('deleteFilter', filter);
        },

        handleScroll() {
            if (window.innerWidth < 768) {
                const minScrollDelta = 20;
                const st = window.pageYOffset;
                const minST = this.$refs.marketItemListUpper.offsetTop + this.$refs.marketItemListUpperConstRow.offsetHeight;

                if (st < minST) this.isSticky = false;

                if (Math.abs(this.lastScrollTop - st) <= minScrollDelta) return;

                this.isSticky = st <= this.lastScrollTop && st > minST;

                this.lastScrollTop = st;

                if (this.isSticky || st < minST) {
                    document.documentElement.style.setProperty('--market-item-list-upper-top', '0px');
                }
                else {
                    document.documentElement.style.setProperty('--market-item-list-upper-top', '-' + this.$refs.marketItemListUpperConstRow.offsetHeight + 'px');
                }
            }
            else {
                this.isSticky = false;
                document.documentElement.style.setProperty('--market-item-list-upper-top', '0px');
            }
        },
    },
};
</script>

<style>
:root {
    --market-item-list-upper-top: 0;
}
</style>
<style scoped>
.market-item-list-upper {
    margin-bottom: var(--y-grid-gap);
}

.const-row {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .const-row {
        padding: 12px 20px;
    }
}
@media (max-width: 767px) {
    .const-row {
        padding: 16px;
        will-change: transform;
        transform: translateY(var(--market-item-list-upper-top));
        transition: transform var(--transition);
    }

    .const-row_sticky {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        padding: 12px 16px;
        background-color: var(--light-c);
        box-shadow: var(--base-shadow);
    }
}

.sort {
    display: flex;
    font-size: var(--base-fz);
}

.sort-label {
    margin-right: 12px;
    color: var(--font-secondary-color);
}
@media (max-width: 767px) {
    .sort-label {
        display: none;
    }
}

@media (min-width: 768px) {
    .view {
        margin-left: auto;
    }
}
@media (max-width: 767px) {
    .view_desktop {
        display: none;
    }
}

.filter-toggler-btn {
    position: relative;
}
@media (min-width: 1040px) {
    .filter-toggler-btn {
        display: none;
    }
}
@media (min-width: 768px) {
    .filter-toggler-btn {
        margin-left: 20px;
    }
}
@media (max-width: 767px) {
    .filter-toggler-btn {
        margin-left: auto;
    }
}

.filter-toggler-btn__counter {
    display: inline-block;
    position: absolute;
    top: -9px;
    right: -9px;
    height: 18px;
    min-width: 18px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 9px;
    font-size: 10px;
    line-height: 18px;
    font-family: var(--f-semi-bold);
    letter-spacing: 0.06em;
    color: var(--light-c);
    text-align: center;
    background-color: var(--primary-color);
}
@media (min-width: 768px) {
    .filter-toggler-btn__counter {
        display: none;
    }
}
</style>