<script>
export default {
    name: 'Chip',

    props: {
        value: String,
        color: {
            type: String,
            default: 'base',
        },
        tag: {
            type: String,
            default: 'span',
        },
        outline: Boolean,
    },

    render(h) {
        let title = this.value;

        if (this.$slots.default && this.$slots.default[0].text) {
            title = this.$slots.default[0].text.trim();
        }

        return h(
            this.tag,
            {
                class: [
                    'chip',
                    this.color,
                    {
                        outline: this.outline,
                    },
                ],
                attrs: {
                    title,
                },
            },
            this.$slots.default || this.value,
        );
    },
};
</script>

<style scoped>
.chip {
    display: inline-block;
    padding: 5px 11px;
    font-size: var(--small-fz);
    font-family: var(--f-semi-bold);
    line-height: 1.33;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
}

.chip.black,
.chip.grey,
.chip.gray,
.chip.base {
    color: var(--font-secondary-dark-color);
    border-color: var(--light-bg);
    background-color: var(--light-bg);
}
.chip.black.outline,
.chip.grey.outline,
.chip.gray.outline,
.chip.base.outline {
    border-color: var(--font-secondary-dark-color);
    background-color: transparent;
}

.chip.yellow {
    color: var(--accent-yellow);
    border-color: var(--accent-yellow-light);
    background-color: var(--accent-yellow-light);
}
.chip.yellow.outline {
    border-color: var(--accent-yellow);
    background-color: transparent;
}

.chip.blue {
    color: var(--blue-medium);
    border-color: var(--blue-lightest);
    background-color: var(--blue-lightest);
}
.chip.blue.outline {
    border-color: var(--blue-medium);
    background-color: transparent;
}

.chip.green {
    color: var(--accent-green);
    border-color: var(--accent-green-light);
    background-color: var(--accent-green-light);
}
.chip.green.outline {
    border-color: var(--accent-green);
    background-color: transparent;
}

.chip.red {
    color: var(--primary-color);
    border-color: var(--primary-light-color);
    background-color: var(--primary-light-color);
}
.chip.red.outline {
    border-color: var(--primary-color);
    background-color: transparent;
}
</style>