<template>
    <div class="main-page">
        <section class="bg-section main-page__section">
            <h2 class="visually-hidden">
                Мы предлагаем:
            </h2>

            <ul class="services-list">
                <li class="service layer-1">
                    <div class="service__text-wrap">
                        <h3 class="h1 h1_text service__title">
                            Купить запчасти и&nbsp;автотовары
                        </h3>
                        <p class="service__description service__description_desktop">
                            Среди множества предложений магазинов, поможем найти варианты для&nbsp;вашего автомобиля.
                        </p>
                        <p
                            v-if="brands"
                            class="service__description service__description_mobile"
                        >
                            {{ brandsAmountText }}
                        </p>
                        <ButtonBlock
                            :to="{ name: 'market' }"
                            primary
                            class="service__btn hidden-s"
                            @click.native="handleYandexMetrikaGoal('main_buy_avtozapchast')"
                        >
                            Выбрать товары
                        </ButtonBlock>
                        <ButtonBlock
                            :to="{ name: 'market' }"
                            primary
                            low
                            class="service__btn hidden-m hidden-l hidden-xl"
                            @click.native="handleYandexMetrikaGoal('main_buy_avtozapchast')"
                        >
                            Выбрать товары
                        </ButtonBlock>
                    </div>
                    <picture class="service__img-wrap">
                        <source
                            srcset="../assets/images/main-page/service-1-big.jpg,
                                    ../assets/images/main-page/service-1-big-x2.jpg 2x"
                            media="(min-width: 768px)"
                            type="image/jpeg"
                        >
                        <source
                            srcset="../assets/images/main-page/service-1-small.jpg,
                                    ../assets/images/main-page/service-1-small-x2.jpg 2x"
                            media="(max-width: 767px)"
                            type="image/jpeg"
                        >
                        <img
                            src="../assets/images/main-page/service-1-big-x2.jpg"
                            alt=""
                            width="337"
                            height="290"
                            class="service__img"
                        >
                    </picture>
                </li>

                <li class="service layer-1">
                    <div class="service__text-wrap">
                        <h3 class="h1 h1_text service__title">
                            Оформить ОСАГО&nbsp;онлайн
                        </h3>
                        <p class="service__description service__description_desktop">
                            Рассчитаем стоимость полиса. Один раз заполните заявку и&nbsp;выберите страховую компанию.
                        </p>
                        <p
                            v-if="companies"
                            class="service__description service__description_mobile"
                        >
                            {{ companies | number }} {{ companiesAmountText }}
                        </p>
                        <ButtonBlock
                            :to="{ name: 'insurance-osago' }"
                            primary
                            class="service__btn hidden-s"
                            @click.native="handleYandexMetrikaGoal('main_arrange_osago')"
                        >
                            Оформить полис
                        </ButtonBlock>
                        <ButtonBlock
                            :to="{ name: 'insurance-osago' }"
                            primary
                            low
                            class="service__btn hidden-m hidden-l hidden-xl"
                            @click.native="handleYandexMetrikaGoal('main_arrange_osago')"
                        >
                            Оформить полис
                        </ButtonBlock>
                    </div>
                    <picture class="service__img-wrap">
                        <source
                            srcset="../assets/images/main-page/service-2-big.jpg,
                                    ../assets/images/main-page/service-2-big-x2.jpg 2x"
                            media="(min-width: 768px)"
                            type="image/jpeg"
                        >
                        <source
                            srcset="../assets/images/main-page/service-2-small.jpg,
                                    ../assets/images/main-page/service-2-small-x2.jpg 2x"
                            media="(max-width: 767px)"
                            type="image/jpeg"
                        >
                        <img
                            src="../assets/images/main-page/service-2-big-x2.jpg"
                            alt=""
                            width="338"
                            height="298"
                            class="service__img"
                        >
                    </picture>
                </li>
            </ul>
        </section>

        <section class="facts-section main-page__section">
            <h1 class="h2 h2_text text_center">
                Ubibi — маркетплейс для&nbsp;автомобилистов
            </h1>
            <p class="facts-section__description">
                У&nbsp;нас есть все необходимое для&nbsp;обслуживания и&nbsp;ремонта вашей машины.
            </p>

            <ul class="facts-list bottom-gap_big">
                <li class="fact layer-1">
                    <div class="fact__icon-wrap">
                        <UIconRound
                            name="ruble"
                            small
                            class="fact__icon"
                        ></UIconRound>
                    </div>
                    <div>
                        <h3 class="fact__title h3">
                            Экономия
                        </h3>
                        <p class="fact__text">
                            Сравнивайте цены и выбирайте выгодные предложения.
                        </p>
                    </div>
                </li>
                <li class="fact layer-1">
                    <div class="fact__icon-wrap">
                        <UIconRound
                            name="time-24"
                            small
                            class="fact__icon"
                        ></UIconRound>
                    </div>
                    <div>
                        <h3 class="fact__title h3">
                            Скорость
                        </h3>
                        <p class="fact__text">
                            Не тратьте время на поиск среди множества сайтов.
                        </p>
                    </div>
                </li>
                <li class="fact layer-1">
                    <div class="fact__icon-wrap">
                        <UIconRound
                            name="like"
                            small
                            class="fact__icon"
                        ></UIconRound>
                    </div>
                    <div>
                        <h3 class="fact__title h3">
                            Выбор
                        </h3>
                        <p class="fact__text">
                            Покупайте то, что подходит именно вам.
                        </p>
                    </div>
                </li>
            </ul>
        </section>

        <section class="bg-section main-page__section">
            <h2 class="visually-hidden">
                Преимущества сервиса Ubibi
            </h2>

            <ul class="profits-list">
                <li class="profit layer-1">
                    <div class="profit__text-wrap">
                        <h3 class="h2 h2_text profit__title">
                            Экспресс-доставка
                        </h3>
                        <p class="profit__text">
                            Доставим ваш заказ из&nbsp;магазина по&nbsp;городу в&nbsp;течение часа с&nbsp;Яндекс&nbsp;Go.
                        </p>
                    </div>
                    <img
                        srcset="../assets/images/main-page/profit-1.jpg,
                                    ../assets/images/main-page/profit-1-x2.jpg 2x"
                        src="../assets/images/main-page/profit-1-x2.jpg"
                        alt=""
                        width="320"
                        class="profit__img"
                    >
                </li>
                <li class="profit layer-1">
                    <div class="profit__text-wrap">
                        <h3 class="h2 h2_text profit__title">
                            Безопасная сделка
                        </h3>
                        <p class="profit__text">
                            Ваши деньги находятся на&nbsp;нашем счёте и&nbsp;поступят продавцу только после того, как вы&nbsp;получите товар.
                        </p>
                    </div>
                    <img
                        srcset="../assets/images/main-page/profit-2.jpg,
                                    ../assets/images/main-page/profit-2-x2.jpg 2x"
                        src="../assets/images/main-page/profit-2-x2.jpg"
                        alt=""
                        width="400"
                        class="profit__img"
                    >
                </li>
                <li class="profit layer-1">
                    <div class="profit__text-wrap">
                        <h3 class="h2 h2_text profit__title">
                            Общение с&nbsp;продавцами
                        </h3>
                        <p class="profit__text">
                            Задавайте вопросы менеджерам магазинов напрямую в&nbsp;онлайн-чатах.
                        </p>
                    </div>
                    <img
                        srcset="../assets/images/main-page/profit-3.jpg,
                                    ../assets/images/main-page/profit-3-x2.jpg 2x"
                        src="../assets/images/main-page/profit-3-x2.jpg"
                        alt=""
                        width="264"
                        class="profit__img"
                    >
                </li>
                <li class="profit layer-1">
                    <div class="profit__text-wrap">
                        <h3 class="h2 h2_text profit__title">
                            Отзывы о&nbsp;товарах и&nbsp;услугах
                        </h3>
                        <p class="profit__text">
                            Делитесь своим мнением и&nbsp;читайте отзывы других клиентов перед покупкой.
                        </p>
                    </div>
                    <img
                        srcset="../assets/images/main-page/profit-4.jpg,
                                    ../assets/images/main-page/profit-4-x2.jpg 2x"
                        src="../assets/images/main-page/profit-4-x2.jpg"
                        alt=""
                        width="263"
                        class="profit__img"
                    >
                </li>
            </ul>
        </section>

        <PartnerConnectSection></PartnerConnectSection>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import mainPage from '@/store/modules/mainPage.js';
import plural from '@/lib/plural.js';
import YandexMetrika from '@/mixins/yandex-metrika.js';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import PartnerConnectSection from '@/components/PartnerConnectSection.vue';
import UIconRound from '@ui/components/UIcon/UIconRound.vue';


export default {
    name: 'MainPage',

    metaInfo() {
        return {
            title: this.meta.meta_title || this.meta.name,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content: this.meta.meta_title ? this.meta.meta_title : this.meta.name },
                { property: 'og:description', content: this.meta.meta_description },
                { property: 'og:image', content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        UIconRound,
        PartnerConnectSection,
        ButtonBlock,
    },

    mixins: [YandexMetrika],

    serverPrefetch() {
        return this.INIT();
    },

    computed: {
        ...mapState({
            initialized: state => state.mainPage.initialized,
            meta: state => state.mainPage.meta,
            brands: state => state.mainPage.brands.current_count,
            companies: state => state.mainPage.companies.current_count,
        }),

        brandsAmountText() {
            // Логика такая:
            // - Если брендов меньше 100, выводим точное кол-во.
            // - Если меньше 1000, округляем до десятков и в конце добавляем +, например: 670+.
            // - Если меньше 10000, округляем до сотен и в конце добавляем +, например: 6700+.
            // - Если меньше 100000, округляем до тысяч и в конце добавляем +, например: 67000+.
            const initialCount = this.brands;
            let addPlus = false;
            let roundBrandsCount = initialCount;
            if (initialCount > 100 && initialCount < 1000) {
                roundBrandsCount = Math.floor(initialCount / 10) * 10;
                addPlus = roundBrandsCount !== initialCount;
            }
            else if (initialCount > 1000 && initialCount < 10000) {
                roundBrandsCount = Math.floor(initialCount / 100) * 100;
                addPlus = roundBrandsCount !== initialCount;
            }
            else if (initialCount >= 10000) {
                roundBrandsCount = Math.floor(initialCount / 1000) * 1000;
                addPlus = roundBrandsCount !== initialCount;
            }

            return this.$options.filters.number(roundBrandsCount) + (addPlus ? '+ ' : ' ') + plural(roundBrandsCount, ['бренд', 'бренда', 'брендов']);
        },

        companiesAmountText() {
            const n = this.companies;
            return plural(n, ['страховая компания', 'страховые компании', 'страховых компаний']);
        },
    },

    created() {
        this.$store.registerModule('mainPage', mainPage);
    },

    mounted() {
        this.$breadcrumbs([]);
        if (!this.initialized) this.INIT();
    },

    beforeDestroy() {
        this.$store.unregisterModule('mainPage');
    },

    methods: {
        ...mapActions({
            INIT: 'mainPage/init',
        }),
    },
};
</script>

<style scoped>
.bg-section {
    margin-left: var(--content-gap-negative);
    margin-right: var(--content-gap-negative);
    padding: var(--gap-y-big) var(--content-gap);
    background-color: var(--lightest-bg);
}

.main-page__section {
    margin-bottom: var(--gap-y-big);
}

@media (min-width: 1040px) {
    .services-list {
        display: grid;
        grid-template-columns: 7fr 5fr;
        grid-gap: var(--grid-gap);
    }
}

.service {
    position: relative;
    overflow: hidden;
}
@media (min-width: 1281px) {
    .service {
        height: 420px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .service {
        height: 424px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .service:nth-child(1) {
        height: 288px;
    }
    .service:nth-child(2) {
        height: 254px;
    }
}
@media (max-width: 1039px) {
    .service:not(:last-child) {
        margin-bottom: var(--y-grid-gap);
    }
}
@media (min-width: 768px) {
    .service {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .service {
        padding: 20px;
    }
}

@media (min-width: 1281px) {
    .service__title {
        width: 300px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .service__title {
        width: 320px;
    }
}

.service__text-wrap {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .service__text-wrap {
        width: 412px;
    }
}
@media (max-width: 767px) {
    .service__text-wrap {
        max-width: 250px;
    }
}

.service__description {
    line-height: var(--base-lh);
}
@media (min-width: 1040px) {
    .service:nth-child(1) .service__description {
        width: 300px;
    }

    .service:nth-child(2) .service__description {
        width: 270px;
    }
}
@media (min-width: 768px) {
    .service__description {
        margin-bottom: 36px;
    }

    .service__description_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .service__description {
        margin-bottom: 24px;
    }

    .service__description_desktop {
        display: none;
    }
}

.service__btn {
    margin-top: auto;
    align-self: flex-start;
}

.service__img-wrap {
    position: absolute;
    z-index: 1;
}
@media (min-width: 1281px) {
    .service:nth-child(1) .service__img-wrap {
        left: var(--col-6);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .service:nth-child(1) .service__img-wrap {
        left: 50%;
    }
}
@media (min-width: 1040px) {
    .service:nth-child(1) .service__img-wrap {
        top: 36px;
        width: 580px;
        height: 490px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .service:nth-child(1) .service__img-wrap {
        top: 24px;
        right: -106px;
        width: 368px;
        height: 312px;
    }
}


@media (min-width: 1160px) {
    .service:nth-child(2) .service__img-wrap {
        top: 36px;
        left: 55%;
        width: 350px;
        height: 360px;
    }
}
@media (min-width: 1040px) and (max-width: 1160px) {
    .service:nth-child(2) .service__img-wrap {
        bottom: -128px;
        left: 50%;
        width: 316px;
        height: 326px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .service:nth-child(2) .service__img-wrap {
        top: 36px;
        right: -20px;
        width: 268px;
        height: 276px;
    }
}

@media (max-width: 767px) {
    .service__img-wrap {
        width: 168px;
        height: 148px;
        top: 50%;
        transform: translateY(-50%);
    }
}
@media (min-width: 415px) and (max-width: 767px) {
    .service__img-wrap {
        right: 20px;
    }
}
@media (min-width: 380px) and (max-width: 414px) {
    .service__img-wrap {
        right: -20px;
    }
}
@media (max-width: 379px) {
    .service__img-wrap {
        right: -80px;
    }
}

.service__img {
    width: 100%;
    height: 100%;
    max-width: initial;
}

.facts-section__description {
    margin-bottom: var(--gap-y-mini);
    font-size: var(--adaptive-big-fz);
    line-height: var(--base-lh);
    text-align: center;
}

@media (min-width: 768px) {
    .facts-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: var(--grid-gap);
        text-align: center;
    }
}

@media (min-width: 1040px) {
    .fact {
        padding: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .fact {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .fact {
        display: flex;
        align-items: flex-start;
        padding: 20px;
    }

    .fact:not(:last-child) {
        margin-bottom: var(--y-grid-gap);
    }
}

.fact__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .fact__icon-wrap {
        margin-bottom: 24px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 767px) {
    .fact__icon-wrap {
        margin-right: 16px;
    }
}

@media (min-width: 768px) {
    .fact__title {
        margin-bottom: 12px;
    }
}
@media (max-width: 767px) {
    .fact__title {
        margin-bottom: 8px;
    }
}

.fact__text {
    line-height: var(--base-lh);
}

@media (min-width: 1281px) {
    .fact__text {
        width: 260px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1281px) {
    .profits-list {
        grid-template-columns: repeat(20, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profits-list {
        grid-template-columns: repeat(12, 1fr);
    }
}
@media (min-width: 1040px)  {
    .profits-list {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

.profit {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}
@media (min-width: 1281px) {
    .profit {
        height: 320px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit {
        height: 370px;
    }
}
@media (min-width: 1040px) {
    .profit {
        padding: 36px 36px 0;
    }
}
@media (max-width: 1039px) {
    .profit:not(:last-child) {
        margin-bottom: var(--y-grid-gap);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit {
        padding: 36px 0 36px 36px;
        flex-direction: row;
    }

    .profit:nth-child(1) {
        height: 190px;
    }
}
@media (max-width: 767px) {
    .profit {
        padding: 20px 20px 0;
    }
}

@media (min-width: 1281px) {
    .profit:nth-child(1) {
        grid-column: 1/13;
    }

    .profit:nth-child(2) {
        grid-column: 13/21;
    }

    .profit:nth-child(3) {
        grid-column: 1/9;
    }

    .profit:nth-child(4) {
        grid-column: 9/21;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit:nth-child(1) {
        grid-column: 1/8;
    }

    .profit:nth-child(2) {
        grid-column: 8/13;
    }

    .profit:nth-child(3) {
        grid-column: 1/6;
    }

    .profit:nth-child(4) {
        grid-column: 6/13;
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .profit:nth-child(3) .profit__title {
        width: 300px;
    }
}

@media (min-width: 1040px) {
    .profit__text-wrap {
        margin-bottom: 24px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit__text-wrap {
        width: 320px;
        flex-shrink: 0;
    }
}
@media (max-width: 767px) {
    .profit__text-wrap {
        margin-bottom: 20px;
    }
}

.profit__text {
    line-height: var(--base-lh);
}
@media (min-width: 1040px) {
    .profit:nth-child(1) .profit__text,
    .profit:nth-child(4) .profit__text {
        width: 380px;
    }
}

.profit__img {
    height: auto;
}

@media (min-width: 1281px) {
    .profit:nth-child(1) .profit__img {
        position: absolute;
        right: 36px;
        bottom: -16px;
    }

    .profit:nth-child(2) .profit__img {
        margin-left: auto;
        margin-right: auto;
    }

    .profit:nth-child(3) .profit__img {
        margin-left: auto;
        margin-right: 36px;
    }

    .profit:nth-child(4) .profit__img {
        position: absolute;
        top: 96px;
        right: 72px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit:nth-child(1) .profit__img {
        margin-left: auto;
    }

    .profit:nth-child(3) .profit__img {
        margin-left: auto;
        margin-right: auto;
    }

    .profit:nth-child(4) .profit__img {
        margin-left: auto;
        margin-right: 36px;
    }
}
@media (min-width: 1040px) {
    .profit__img {
        margin-top: auto;
    }

    .profit:nth-child(1) .profit__img {
        width: 360px;
    }

    .profit:nth-child(2) .profit__img {
        width: 408px;
        max-width: 408px;
    }

    .profit:nth-child(3) .profit__img {
        width: 308px;
        max-width: 308px;
    }

    .profit:nth-child(4) .profit__img {
        width: 336px;
        max-width: 336px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit__img {
        position: absolute;
        bottom: 0;
    }

    .profit:nth-child(1) .profit__img {
        width: 264px;
        right: 20px;
    }

    .profit:nth-child(2) .profit__img {
        width: 300px;
        top: 24px;
        right: 0;
    }

    .profit:nth-child(3) .profit__img {
        width: 244px;
        right: 36px;
    }

    .profit:nth-child(4) .profit__img {
        width: 248px;
        top: 24px;
        right: 24px;
    }
}
@media (max-width: 767px) {
    .profit__img {
        width: 250px;
        margin-left: auto;
        margin-right: auto;
    }

    .profit:nth-child(2) .profit__img {
        margin-bottom: -52px;
    }

    .profit:nth-child(3) .profit__img {
        margin-bottom: -20px;
    }

    .profit:nth-child(4) .profit__img {
        margin-bottom: -70px;
    }
}
</style>