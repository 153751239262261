<template>
    <PopupWrap @close="close">
        <FormManager
            ref="form"
            v-model="formData"
            class="popup-content"
            @submit="save"
        >
            <div class="head">
                <div class="avatar-wrap">
                    <button
                        v-if="avatar && !preloaderVisible"
                        class="delete-avatar"
                        type="button"
                        aria-label="Удалить фото"
                        @click="deleteAvatar"
                    >
                        <UIcon tiny light></UIcon>
                    </button>

                    <label
                        :class="{ 'preloader-visible': preloaderVisible }"
                        for="avatar-input"
                        class="load-avatar-label"
                    >
                        <img
                            v-if="avatar"
                            :src="avatarSrc"
                            alt=""
                            class="avatar__img"
                            @load="avatarOnload"
                        >
                        <UserAvatar
                            v-else
                            :src="false"
                            size="xl"
                            class="avatar__img"
                        ></UserAvatar>

                        <UTooltip maxWidth="162" textAlign="center">
                            <template #trigger="{ attrs, handlers }">
                                <div
                                    v-bind="attrs"
                                    class="load-avatar__text"
                                    v-on="handlers"
                                >
                                    <UIcon
                                        name="photo"
                                        big
                                        class="load-avatar__icon"
                                        aria-hidden="true"
                                    ></UIcon>
                                </div>
                            </template>

                            JPG или PNG, не&nbsp;менее 240x240 px, макс. размер 3 Мб
                        </UTooltip>

                        <LoaderProgressBar
                            v-if="preloaderVisible"
                            :progress="loadingProgress"
                            class="avatar-preloader"
                        ></LoaderProgressBar>
                    </label>

                    <input
                        id="avatar-input"
                        type="file"
                        name="avatar"
                        class="visually-hidden"
                        accept="image/png, image/jpeg"
                        @change="uploadAvatar"
                    >
                </div>

                <span class="user-name">{{ profile.first_name }} {{ lastNameFirstLetter }}</span>
            </div>

            <FormField
                name="first_name"
                label="Имя"
                validations="required"
                class="form-field-gap_bottom"
            ></FormField>

            <FormField
                name="patronymic"
                label="Отчество"
                class="form-field-gap_bottom"
            ></FormField>

            <FormField
                name="last_name"
                label="Фамилия"
            ></FormField>

            <div class="actions-wrap">
                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="close"
                >
                    Отмена
                </ButtonBlock>
                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    class="action-btn"
                >
                    Сохранить
                </ButtonBlock>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
// services
import { HTTP } from '@/http.js';
// utils
import { mapState } from 'vuex';
// mixins
import popup from '@/mixins/popup.js';
// components
import PopupWrap from '@/components/popups/PopupWrap.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import LoaderProgressBar from '@/components/LoaderProgressBar.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
const ImageCropperPopup = () => import('@/components/ImageCropperPopup.vue');


export default {
    name: 'ChangeUserInfoPopup',

    components: {
        UTooltip,
        UserAvatar,
        LoaderProgressBar,
        UIcon,
        ButtonBlock,
        FormField,
        FormManager,
        PopupWrap,
    },

    mixins: [popup],

    data() {
        return {
            formData: {
                first_name: '',
                patronymic: '',
                last_name: '',
            },
            avatar: null,
            preloaderVisible: false,
            loadingProgress : 0,
            loading: false,
            avatarLoading: false,
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),

        lastNameFirstLetter() {
            return this.profile.last_name ? this.profile.last_name.charAt(0) + '.' : '';
        },

        avatarSrc() {
            if (this.avatar) {
                if (this.avatar.url) {
                    return this.avatar.url;
                }

                if ((this.avatar.thumbnails || {}).avatar_xl) {
                    return this.$links.uploads + this.avatar.thumbnails.avatar_xl;
                }
            }

            return '';
        },
    },

    created() {
        this.formData.first_name = this.profile.first_name || '';
        this.formData.patronymic = this.profile.patronymic || '';
        this.formData.last_name = this.profile.last_name || '';
        this.avatar = this.profile.avatar || null;
    },

    methods: {
        async uploadAvatar(event) {
            let file = event.target.files[0];

            if (file) {
                if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                    this.$notify({
                        type: 'error',
                        message: 'Неверный формат файла. Возможно только JPG и PNG',
                    });
                }
                else if (file.size > 3145728) {
                    this.$notify({
                        type: 'error',
                        message: 'Размер файла больше 3 Мб',
                    });
                }
                else {
                    this.avatarLoading = true;

                    try {
                        const url = URL.createObjectURL(file);

                        file = await this.crop(url);
                    }
                    catch (error) {
                        event.target.value = '';
                        return;
                    }

                    const data = new FormData();
                    this.loadingProgress = 0;
                    this.preloaderVisible = true;
                    this.cancelToken = HTTP.CancelToken.source();

                    if (file instanceof Blob) {
                        data.append('file', file, file.name);
                    }
                    else {
                        data.append('file', file);
                    }

                    try {
                        const response = await this.$api.mediafiles.upload(data, {
                            onUploadProgress: (progressEvent) => {
                                this.loadingProgress = parseInt((progressEvent.loaded / progressEvent.total) * 100);
                            },
                            cancelToken: this.cancelToken.token,
                        });

                        this.updateAvatar(response, file);
                    }
                    catch (error) {
                        if (HTTP.isCancel(error)) return;
                        this.$store.commit('handleCommonHttpError', error);
                    }
                    finally {
                        this.avatarLoading = false;
                        this.loadingProgress = null;
                    }
                }
            }
        },

        async crop(src) {
            return new Promise((resolve, reject) => {
                const callback = file => {
                    if (file) {
                        resolve(file);
                    }
                    else {
                        reject();
                    }
                };
                const props = { callback, src, width: 240, height: 240, round: true };
                const options = { props };
                this.$popup(ImageCropperPopup, options);
            });
        },

        avatarOnload() {
            this.preloaderVisible = false;
        },

        async deleteAvatar() {
            this.avatar = null;
            // const profile = await this.changeProfile();
            // if (profile) {
            //     this.$store.commit('profile/setProfile', profile);
            // }
        },

        updateAvatar(response, file) {
            const avatar = response;
            avatar.url = URL.createObjectURL(file);
            this.avatar = avatar;
        },

        async save() {
            const errors = await this.$refs.form.validate();

            if (errors) return;

            this.loading = true;

            const data = Object.assign({
                avatar: (this.avatar || {}).uuid || null,
            }, this.formData);

            try {
                const profile = await this.$api.users.changeProfile(data);

                this.$store.commit('profile/setProfile', profile);

                this.$success('Изменения сохранены');

                this.close();
            }
            catch(error) {
                this.$refs.form.handle(error);
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 476px;
    }
}

.head {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}

.user-name {
    margin-left: 20px;
    font-size: var(--h2-fz);
    font-family: var(--f-bold);
}

.actions-wrap {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .actions-wrap {
        margin-top: auto;
    }

    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

.avatar-wrap {
    position: relative;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
}

.load-avatar-label {
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    cursor: pointer;
}

.avatar__img {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 50%;
}

.load-avatar-label.preloader-visible {
    pointer-events: none;
}

.load-avatar-label:hover .load-avatar__text {
    opacity: 1;
}

.load-avatar__hint {
    position: absolute;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    transition: opacity var(--transition);
}

.load-avatar__text {
    position: absolute;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 50%;
    transition: opacity var(--transition);
}

.load-avatar__icon {
    fill: var(--light-c);
}

.delete-avatar {
    position: absolute;
    bottom: 2px;
    right: 4px;
    z-index: 6;
    width: 16px;
    height: 16px;
    font-size: 0;
    background-color: var(--primary-color);
    border-radius: 50%;
    transition: background-color var(--transition);
}

.delete-avatar:hover {
    background-color: var(--primary-dark-color);
}

.avatar-preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
}
</style>