export const getErrorCode = error => {
    return (error || {}).status || ((error || {}).response || {}).status;
};

export class HttpError extends Error {
    constructor(message, code = null, status = null, stack = null, name = null, url = '' ) {
        super();
        this.message = message;
        this.status = status;

        this.name = name || this.constructor.name;
        this.code = code;
        this.stack = stack || null;
        this.url = url;
    }

    static normalizeError(error, code, url) {
        const { message, name, status, stack } = error;
        return new HttpError(message, code, status, stack, name, url);
    }
}