<template>
    <PopupWrap
        :loading="loading"
        closeBtn="outer"
        noPadding
        full
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <yandexMap
                :coords="centerCoordsArray"
                zoom="16"
                :controls="['zoomControl']"
                :scrollZoom="false"
                :options="{ suppressMapOpenBlock: true }"
                style="width: 100%; height: 100%;"
                @map-was-initialized="setMapCenter"
                @actionend="handleActionEnd"
            >
                <ymapMarker
                    v-for="(item, index) in shops"
                    :key="'item-' + index"
                    :ref="'item-' + index"
                    :coords="getCoordsArray(item)"
                    :icon="getYMapMarkerIcon({ active: balloon.visible && balloon.index === index })"
                    :markerId="'item-' + index"
                    @click="showBalloon({ shop: item, index })"
                ></ymapMarker>

                <ShopBalloon
                    v-if="balloon.visible"
                    :shop="balloon.shop"
                    :company="company"
                    @close="closeBalloon"
                ></ShopBalloon>
            </yandexMap>
        </div>
    </PopupWrap>
</template>


<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ShopBalloon from '@/components/ShopBalloon.vue';

export default {
    name: 'ShopsOnMapPopup',

    components: {
        ShopBalloon,
        PopupWrap,
        yandexMap,
        ymapMarker,
    },

    mixins: [ymapMarkerMixin, popup],

    props: {
        shops: Array,
        company: Object,
    },

    data() {
        return {
            mapInstance: null,
            loading: true,
            mapCenter: [],
            balloon: {
                shop: {},
                index: null,
                visible: false,
            },
        };
    },

    computed: {
        centerCoordsArray() {
            if (Array.isArray(this.coords)) {
                return this.coords;
            }
            return this.shops[0].coordinates.split(', ').map(Number);
        },
    },

    methods: {
        getCoordsArray(item) {
            return item.coordinates.split(', ').map(Number);
        },

        setMapCenter(mapInstance) {
            this.mapInstance = mapInstance;
            this.loading = false;
            if (this.shops.length > 1) {
                let pointsList = [];
                this.shops.forEach(item => {
                    pointsList.push(this.getCoordsArray(item));
                });
                mapInstance.setBounds(ymaps.util.bounds.fromPoints(pointsList));
            }
        },

        handleActionEnd(e) {
            const newCenter = e.originalEvent.map.getCenter();
            if (this.mapCenter[0] !== newCenter[0] || this.mapCenter[1] !== newCenter[1]) {
                this.mapCenter = newCenter;
            }
        },

        showBalloon({ shop, index }) {
            this.balloon.visible = true;
            this.balloon.shop = shop;
            this.balloon.index = index;
            this.$nextTick(() => {
                this.mapInstance.setCenter(this.mapCenter);
            });
        },

        closeBalloon() {
            this.balloon.visible = false;
            this.balloon.shop = {};
            this.balloon.index = null;
            this.$nextTick(() => {
                this.mapInstance.setCenter(this.mapCenter);
            });
        },
    },
};
</script>

<style scoped>
</style>
