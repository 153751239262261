<template>
    <div
        :class="{
            'input-text_type-textarea': textarea,
            'input-text_disabled': disabled,
            'input-text_focused': focusedComputed,
            'input-text_hover': hoveredComputed,
            'input-text_invalid': invalid,
            'input-text_filled': value || isFilled,
        }"
        class="input-text"
        @click="onClick"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
    >
        <textarea
            v-if="textarea"
            :id="id"
            ref="textarea"
            :name="id"
            v-bind="$attrs"
            :rows="rows"
            :value="value"
            :disabled="disabled"
            :readonly="readonly"
            class="input-text__input input-text__textarea"
            :class="[
                inputClasses,
                {
                    'input-text__textarea_default-height': !rows
                }
            ]"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            v-on="listeners"
        ></textarea>

        <input
            v-else
            :id="id"
            ref="input"
            :name="id"
            v-bind="$attrs"
            :type="type"
            :value="value"
            :disabled="disabled"
            :readonly="readonly"
            class="input-text__input"
            :class="[
                inputClasses,
                {
                    'input-text__input_center': center,
                    'input-text__input_small': small,
                    'input-text__input_invalid': invalid,
                    'input-text__input_reset-right-offset': icons.length || showAppendIcon,
                    'input-text__input_left-offset': prependIcon,
                }
            ]"
            :autocomplete="autocomplete"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            @keydown.enter="onEnter"
            @keydown="onKeyDown"
            v-on="listeners"
        >

        <span
            v-if="(placeholder && visibleMask && focusedComputed) || (!placeholder && (!label || label && focusedComputed) && visibleMask)"
            class="input-text__placeholder input-text__mask-placeholder"
            :class="{
                'input-text__placeholder_focused': focusedComputed,
                'input-text__mask-placeholder_filled': value || isFilled,
            }"
        >
            <i class="input-text__mask-placeholder-value">{{ computedMask.value }}</i>{{ computedMask.rest }}
        </span>

        <span
            v-if="(placeholder && !(value || isFilled) && !visibleMask) || (placeholder && !(value || isFilled) && visibleMask && !focusedComputed)"
            class="input-text__placeholder"
            :class="{
                'input-text__placeholder_focused': focusedComputed,
                'input-text__placeholder_in-small': small,
            }"
        >{{ placeholder }}</span>

        <span class="prepend-icon-wrap">
            <slot name="prepend-icon">
                <UIcon
                    v-if="prependIcon"
                    v-bind="prependIcon"
                ></UIcon>
            </slot>
        </span>

        <label
            v-if="label"
            :for="id"
            class="input-text__label"
        >
            {{ label }}
        </label>

        <div
            v-show="icons.length || showAppendIcon"
            class="icons-sidebar"
        >
            <component
                :is="icon"
                v-for="(icon, index) in icons"
                :key="index"
                class="icons-sidebar__item"
            ></component>

            <slot name="append-icon">
                <div
                    v-if="showAppendIcon"
                    class="icons-sidebar__item"
                >
                    <UIcon
                        v-bind="appendIcon"
                    ></UIcon>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import mask, { tokens } from '@/lib/mask.js';
import genId from '@ui/utils/genId.js';
import Spinner from '../Spinner.vue';
import normalizeSlot from '@/lib/normalizeSlot.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


export default {
    name: 'InputText',

    components: {
        UIcon,
        Spinner,
    },

    inheritAttrs: false,

    props: {
        value: [String, Number, Object],

        id: {
            type: String,
            default: () => genId(),
        },

        placeholder: [String, Number],

        textarea: Boolean,

        rows: [String, Number],

        readonly: Boolean,

        password: Boolean,
        eyeToggler: Boolean,

        loading: Boolean,

        error: String,

        invalid: Boolean,

        label: String,

        hint: {
            type: [Boolean, String],
            default: false,
        },

        clearable: {
            type: Boolean,
            default: true,
        },

        isFilled: Boolean,

        disabled: Boolean,

        autocomplete: String,

        // style
        inputClasses: [String, Array, Object],
        rightIndent: Boolean,
        small: Boolean,
        center: Boolean,

        /**
         * @param { string } name
         */
        appendIcon: Object,

        /**
         * @param { string } name
         */
        prependIcon: Object,

        // mods
        upper: Boolean,

        mask: String,
        visibleMask: Boolean,

        replace: {
            type: Array,
            validator(value) {
                return value.length === 2;
            },
        },

        trim: {
            type: Boolean,
            default: true,
        },

        hovered: Boolean,
        focused: Boolean,
    },

    data() {
        return {
            showPassword: false,
            innerHovered: false,
            innerFocused: false,

            startValue: '',
        };
    },

    computed: {
        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { blur, focus, input, keydown, change, clickAppendIcon, ...listeners } = this.$listeners;
            return listeners;
        },

        hasMods() {
            return this.upper
                || !!this.replace
                || !!this.mask
                || this.trim;
        },

        type() {
            if (this.password && !this.showPassword) {
                return 'password';
            }
            else {
                return 'text';
            }
        },

        inputEl() {
            return this.textarea
                ? this.$refs.textarea
                : this.$refs.input;
        },

        hoveredComputed() {
            return this.innerHovered || this.hovered;
        },

        focusedComputed() {
            return this.innerFocused || this.focused;
        },

        icons() {
            if (this.disabled) return [];

            if (this.loading) {
                return [this.genLoading()];
            }

            if (this.password) {
                const icons = [];

                if (this.hasHint) {
                    icons.push(this.genHint());
                }

                if (this.eyeToggler) {
                    icons.push(this.genEye());
                }

                if (
                    icons.length < 2 &&
                    this.clearable &&
                    this.value &&
                    (this.hoveredComputed || this.focusedComputed)
                ) {
                    icons.push(this.genCross(this.showPassword));
                }

                return icons;
            }

            const icons = [];
            // Если это textarea и есть значение, то крестик нужен всегда, иначе текст внутри будет прыгать
            if (this.clearable && this.value && (this.hoveredComputed || this.focusedComputed || this.textarea)) {
                icons.push(this.genCross());
            }

            if (this.hasHint) {
                icons.push(this.genHint());
            }

            return icons;
        },

        showLoading() {
            // Отключил прелоадер до тех пор пока не повешаю таймер
            // return false;
            return this.loading && !this.disabled;
        },

        showCross() {
            return this.clearable
                && this.value
                && !this.disabled
                && !this.showLoading
                && (this.hoveredComputed || this.focusedComputed);
        },

        showAppendIcon() {
            return this.appendIcon && !this.showCross;
        },

        hasHint() {
            const hintSlot = normalizeSlot('hint', {}, this);
            const hintContentSlot = normalizeSlot('hint-content', {}, this);
            return !!this.hint || !!hintSlot || !!hintContentSlot;
        },

        computedMask() {
            if (!this.mask) return;

            let restMask =  '';

            if (this.mask === '+7 (000) 000 00 00') {
                const fieldMask = '+7 (___) ___ __ __';
                restMask = fieldMask.substring(this.value.length);
            }
            else if (this.mask === '00.00.0000') {
                const fieldMask = 'дд.мм.гггг';
                restMask = fieldMask.substring(this.value.length);
            }
            else {
                restMask = this.mask.substring(this.value.length);
            }

            return {
                value: this.value,
                rest: restMask,
            };
        },
    },

    watch: {
        value: {
            handler(value) {
                if (this.hasMods && value) {
                    const newValue = this.modify(value);

                    if (newValue !== value) {
                        this.$emit('change', newValue);
                        this.$emit('input', newValue);
                    }
                }
            },
            immediate: true,
        },

        mask: {
            handler(m) {
                if (m) {
                    const oldValue = this.value;
                    const newValue = mask(oldValue, m);

                    if (oldValue !== newValue) {
                        this.$emit('change', newValue);
                    }
                }
            },
        },
    },

    methods: {
        onInput($event) {
            const el = $event.target;
            let value = el.value;

            if (this.hasMods && value) {
                const caretPosition = el.selectionStart;

                value = this.modify(value);

                const newCaretPosition = caretPosition + value.length - el.value.length;

                el.value = value;

                el.selectionStart = newCaretPosition;
                el.selectionEnd = newCaretPosition;
            }

            this.$emit('input', value);
        },

        modify(value) {
            if (this.upper) {
                value = value.toUpperCase();
            }

            if (this.replace) {
                value = value.replace(this.replace[0], this.replace[1]);
            }

            if (this.mask) {
                value = mask(value, this.mask);
            }

            return value;
        },

        onChange() {
            /*
            * Событие change работает в Safari только после
            * пользовательского действия.
            * Если значение меняется через js,
            * то событие change не возникает.
            * Поэтому здесь событие change заменяется на blur.
            * */
            const el = this.inputEl;
            let value = el.value;

            if (this.trim) {
                value = value.trim();
            }

            if (this.hasMods && value) {
                value = this.modify(value);

                el.value = value;
            }

            this.$emit('change', value);
        },

        onFocus($event) {
            this.startValue = this.inputEl.value;
            this.innerFocused = true;
            this.$emit('focus', $event);

            if (this.mask && !this.value) {
                const value = this.modify('');

                if (value !== '') {
                    this.$emit('change', value);
                    this.$emit('input', value);
                }

                setTimeout(this.focus, 10);
            }
        },

        onBlur($event) {
            this.innerFocused = false;

            const el = $event.target;
            let value = el.value;

            if (this.mask && value) {
                let index;

                for (let i = 0; i < this.mask.length; i++) {
                    const symbol = this.mask[i];

                    if (tokens[symbol]) {
                        index = i;
                        break;
                    }
                }

                if (this.mask.substr(0, index) === value) {
                    value = '';
                    el.value = '';

                    if (this.$listeners.change) {
                        this.$emit('change', '');
                    }
                    else {
                        this.$emit('input', '');
                    }
                }
            }

            if (value !== this.startValue) {
                this.onChange();
            }

            this.startValue = '';

            this.$emit('blur', $event);
        },

        onClick($event) {
            if (!(this.readonly && $event.target === this.inputEl)) {
                this.$emit('click', $event);
            }
        },

        clear() {
            if (this.$listeners.clear) {
                this.$emit('clear');
            }
            else if (this.$listeners.change) {
                this.$emit('change', '');
            }
            else {
                this.$emit('input', '');
            }

            this.inputEl.value = '';
            this.focus();
        },

        focus() {
            this.$emit('focus');
            this.innerFocused = true;
            this.inputEl.focus();
        },

        blur() {
            this.$emit('blur');
            this.innerFocused = false;
            this.inputEl.blur();
        },

        onMouseEnter() {
            this.innerHovered = true;
        },

        onMouseLeave() {
            this.innerHovered = false;
        },

        onEnter($event) {
            this.$emit('enter', $event);
        },

        onKeyDown($event) {
            this.$emit('keydown', $event);
        },

        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },

        genAppendIcon() {
            const { name, ...args } = this.appendIcon;
            const tag = this.$listeners.clickAppendIcon ? 'button' : 'div';
            const data = tag === 'button' ? {
                attrs: {
                    type: 'button',
                    tabindex: '-1',
                },
                on: {
                    click: $event => this.$emit('clickAppendIcon', $event),
                },
            } : {};

            return {
                name: 'InputTextAppendIcon',

                render: h => {
                    return h(tag, {
                        class: 'input-text__append-icon-wrap',
                        ...data,
                    }, [this.genIcon(name, ...args)]);
                },
            };
        },

        genLoading() {
            return {
                name: 'InputTextSpinner',

                render: h => {
                    return h(
                        'div',
                        {},
                        [
                            h(
                                Spinner,
                                {
                                    class: 'input-text__spinner',
                                    props: {
                                        size: 'small',
                                    },
                                },
                            ),
                        ],
                    );

                },
            };
        },

        genCross() {
            const name = this.prependIcon && this.prependIcon.name === 'search' ? 'close-round-hollow-16' : 'cross';
            return {
                name: 'InputTextCross',

                render: h => {
                    return h('button', {
                        class: 'input-text__clear-button',
                        attrs: {
                            type: 'button',
                            tabindex: '-1',
                        },
                        on: {
                            click: this.clear,
                        },
                    }, [this.genIcon(name, { light: this.prependIcon && this.prependIcon.name === 'search' })]);
                },
            };
        },

        genHint() {
            const hintContentSlot = normalizeSlot('hint-content', {}, this);
            const hintSlot = normalizeSlot('hint', {}, this);
            const content = hintSlot ? hintSlot : this.hint;

            return {
                name: 'InputTextHint',

                render: h => {
                    return h(UTooltip, {
                        props: {
                            noDetach: true,
                        },
                        scopedSlots: hintContentSlot ? {
                            content: this.$scopedSlots['hint-content'],
                        } : {
                            default: () => h('p', content),
                        },
                    });
                },
            };
        },

        genEye() {
            return {
                name: 'InputTextEye',

                render: h => {
                    return h('button', {
                        class: 'input-text__eye-button',
                        attrs: {
                            type: 'button',
                            tabindex: '-1',
                            title: this.showPassword ? 'Скрыть пароль' : 'Показать пароль',
                        },
                        on: {
                            click: this.toggleShowPassword,
                        },
                    }, [this.genIcon(this.showPassword ? 'eye-cross' : 'eye', { small: false })]);
                },
            };
        },

        genIcon(name, args) {
            return this.$createElement(UIcon, {
                class: 'input-text__icon',
                props: {
                    name,
                    ...Object.assign({
                        small: true,
                        secondary: true,
                        hovered: true,
                    }, args),
                },
            });
        },
    },
};
</script>

<style>
.input-text {
    position: relative;
    display: flex;
    align-items: stretch;
    box-sizing: border-box;
    background-color: var(--light-c);
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
    transition: border-color var(--transition), background-color var(--transition);
}
.input-text.input-text_focused,
.input-text.input-text_hover {
    border-color: var(--fields-border);
}
.input-text.input-text_invalid:not(.input-text_disabled) {
    border-color: var(--error-lightest-color);
    background-color: var(--error-brightest-color);
}
.input-text.input-text_invalid:not(.input-text_disabled):hover {
    border-color: var(--error-medium-color);
}
.input-text.input-text_invalid:not(.input-text_disabled).input-text_focused {
    border-color: var(--error-medium-color);
    background-color: var(--light-c);
}
.input-text.input-text_disabled {
    background-color: var(--bright-bg);
    border: 1px solid var(--border-light-c);
    cursor: default;
}

.input-text__label {
    position: absolute;
    top: 11px;
    left: 16px;
    z-index: 3;
    max-width: calc(100% - 32px);
    font-size: var(--base-fz);
    line-height: 20px;
    color: var(--font-secondary-color);
    background-color: var(--light-c);
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition:
        top var(--transition-fast),
        font-size var(--transition-fast),
        background-color var(--transition-fast);
}
.input-text__input.input-text__input_left-offset ~ .input-text__label {
    padding-left: 36px;
}
.input-text.input-text_filled .input-text__label,
.input-text.input-text_focused .input-text__label {
    top: -7px;
    left: 12px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
}
.input-text.input-text_disabled .input-text__label {
    background-color: var(--bright-bg);
}
.input-text.input-text_invalid:not(.input-text_disabled) .input-text__label {
    color: var(--error-medium-color);
    background-color: var(--error-brightest-color);
}
.input-text.input-text_invalid:not(.input-text_disabled).input-text_focused .input-text__label {
    background-color: var(--light-c);
}

.input-text__input {
    position: relative;
    z-index: 2;
    display: block;
    flex-grow: 1;
    max-width: 100%;
    padding: 11px 16px;
    font-size: var(--base-fz);
    line-height: 20px;
    background-color: transparent;
    border: none;
}
.input-text__input.input-text__input_small {
    padding: 7px 16px;
}
.input-text__input.input-text__input_reset-right-offset {
    padding-right: 0;
}
.input-text__input.input-text__input_left-offset {
    padding-left: 36px;
}
.input-text__input.input-text__input_center {
    text-align: center;
}

.input-text__textarea {
    line-height: var(--base-lh);
    height: auto;
}
.input-text__textarea_default-height {
    height: 108px;
}

.icons-sidebar {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-right: 4px;
}
.input-text_type-textarea .icons-sidebar {
    align-self: flex-start;
}

.icons-sidebar__item {
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
}
.input-text_type-textarea .icons-sidebar__item {
    padding: 15px 6px;
}

.prepend-icon-wrap {
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(-50%);
    z-index: 0;
    font-size: 0;
}

.input-text__placeholder {
    position: absolute;
    top: 11px;
    left: 16px;
    z-index: 1;
    width: calc(100% - 16px);
    color: var(--font-secondary-color);
    white-space: nowrap;
    overflow: hidden;
    transition: color var(--transition);
}
.input-text__placeholder.input-text__placeholder_in-small {
    top: 8px;
}
.input-text__input.input-text__input_left-offset ~ .input-text__placeholder {
    left: 36px;
    width: calc(100% - 36px);
}
.input-text__placeholder.input-text__placeholder_focused {
    color: var(--font-secondary-light-color);
}

.input-text__placeholder::after {
    content: "";
    position: absolute;
    right: 0;
    width: 16px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 100%);
}
.input-text.input-text_invalid:not(.input-text_focused) .input-text__placeholder::after {
    background: linear-gradient(to right, rgba(255, 245, 245, 0) 0%, rgba(255, 245, 245, 1) 80%, rgba(255, 245, 245, 1) 100%);
}
.input-text.input-text_disabled .input-text__placeholder::after {
    background: linear-gradient(to right, rgba(249, 250, 251, 0) 0%, rgba(249, 250, 251, 1) 80%, rgba(249, 250, 251, 1) 100%);
}

.input-text__mask-placeholder_filled:not(.input-text__placeholder_focused),
.input-text__mask-placeholder-value {
    color: transparent;
}
</style>