<template>
    <div class="form-template">
        <div class="body">
            <slot name="body"></slot>
        </div>
        <div class="footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormTemplate",
};
</script>

<style scoped>
@media (min-width: 768px) {
    .footer {
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        grid-template-areas: ". secondary primary";
        grid-gap: 20px;
        align-items: center;
        margin-top: 36px;
    }

    .button-secondary {
        grid-area: secondary;
    }

    .button-primary {
        grid-area: primary;
    }
}
@media (max-width: 767px) {
    .footer {
        margin-top: 28px;
    }

    .footer > * {
        width: 100%;
    }

    .footer > :not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>