<template>
    <div
        v-if="applicability && innerApplicability"
        class="applicability"
    >
        <div class="applicability__title">
            <h4 class="h4">
                Применяемость к авто
            </h4>

            <UTooltip maxWidth="210">
                <template #trigger="{ value, attrs, handlers }">
                    <UTooltipIcon
                        class="ml-8"
                        :active="value"
                        v-bind="attrs"
                        v-on="handlers"
                    ></UTooltipIcon>
                </template>

                Перечень марок и моделей автомобилей, для которых подходит запчасть.
            </UTooltip>
        </div>

        <div class="applicability__cars">
            <div
                v-for="(item, itemIndex) in applicability"
                :key="'applicability__manufacturer-' + itemIndex"
                class="applicability-block"
            >
                <span class="text_bold">{{ item.manufacturer.name }}: </span>
                <span
                    v-for="(family, familyIndex) in ((innerApplicability[item.manufacturer.name] || {}).families || [])"
                    :key="'applicability__family-' + familyIndex"
                    class="d-inline-block"
                >
                    <RouterLink
                        :to="{
                            name: modelName === 'part_product' ? 'market-part-applicability' : 'contract-part-applicability',
                            params: {
                                id: $route.params.id,
                            },
                            query: {
                                manufacturer: item.manufacturer.id,
                                family: family.id,
                            },
                        }"
                        class="underline"
                    >
                        {{ family.name }}
                    </RouterLink><span v-if="familyIndex !== item.families.length - 1" class="mr-4">,</span>
                </span>
                <ButtonText
                    v-if="item.families.length > 10 && !innerApplicability[item.manufacturer.name].showMore"
                    dashed
                    secondary
                    dark
                    @click="toggleList(item.manufacturer.name)"
                >+ {{ innerApplicability[item.manufacturer.name].restCount }} {{ innerApplicability[item.manufacturer.name].restCountText }}</ButtonText>
                <ButtonText
                    v-if="item.families.length > 10 && innerApplicability[item.manufacturer.name].showMore"
                    dashed
                    secondary
                    dark
                    class="ml-4"
                    @click="toggleList(item.manufacturer.name)"
                >Свернуть</ButtonText>
            </div>
        </div>
    </div>
</template>

<script>
import plural from '@/lib/plural.js';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


export default {
    name: 'MarketPartApplicabilityLinksList',

    components: {
        UTooltipIcon,
        UTooltip,
        ButtonText,
    },

    props: {
        applicability: {
            type: Object,
            default() {
                return {};
            },
        },

        modelName: {
            type: String,
            validator(value) {
                return ['part_product', 'contract_part'].includes(value);
            },
            default: 'part_product',
        },
    },

    data() {
        return {
            innerApplicability: null,
        };
    },

    mounted() {
        const innerApplicability = {};

        if (this.applicability) {
            for (const value of Object.values(this.applicability)) {
                innerApplicability[value.manufacturer.name] = {
                    originalFamilies: value.families,
                    families: value.families.length > 10 ? value.families.slice(0, 10) : value.families,
                    restCount: value.families.length > 10 ? value.families.length - 10 : 0,
                    restCountText: value.families.length > 10 ? plural(value.families.length - 10, ['модель', 'модели', 'моделей']) : 0,
                    showMore: value.families.length <= 10,
                };
            }
            this.innerApplicability = innerApplicability;
        }
    },

    methods: {
        toggleList(manufacturer) {
            this.innerApplicability[manufacturer].showMore = !this.innerApplicability[manufacturer].showMore;
            if (this.innerApplicability[manufacturer].showMore) {
                this.innerApplicability[manufacturer].families = this.innerApplicability[manufacturer].originalFamilies;
            }
            else {
                this.innerApplicability[manufacturer].families = this.innerApplicability[manufacturer].originalFamilies.slice(0, 10);
            }
        },
    },
};
</script>

<style scoped>
.applicability__title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.applicability__cars {
    line-height: var(--base-lh);
}

.applicability-block:not(:last-child) {
    margin-bottom: 8px;
}
</style>