<template>
    <div
        :class="[
            'post-editor__block',
            'post-editor__block_' + widget.type,
        ]"
    >
        <div
            :class="[
                'indent-wrap',
                widget.marginTop ? 'indent-wrap_mt' + widget.marginTop : '',
                widget.marginBottom ? 'indent-wrap_mb' + widget.marginBottom : '',
                widget.paddingTop ? 'indent-wrap_pt' + widget.paddingTop : '',
                widget.paddingBottom ? 'indent-wrap_pb' + widget.paddingBottom : '',
            ]"
        >
            <WysiwygView :value="widget.text" />
        </div>
    </div>
</template>

<script>
import WysiwygView from "./WysiwygView.vue";

export default {
    name: "PostEditorText",

    components: {WysiwygView},

    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>