<template>
    <div class="group">
        <ButtonBlock
            v-for="option in options"
            :key="getOptionKey(option)"
            low
            class="group__btn"
            :class="{
                'group__btn_active': isOptionSelected(option, value)
            }"
            @click="onChange(option)"
        >
            {{ getOptionLabel(option) }}
        </ButtonBlock>
    </div>
</template>

<script>
import options from '@/mixins/options.js';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';

export default {
    name: "BtnTogglersGroup",

    components: {
        ButtonBlock,
    },

    mixins: [
        options,
    ],

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        options: {
            type: Array,
            required: true,
        },

        value: {
            type: Array,
            required: true,
        },
    },

    methods: {
        onChange(option) {
            const isChecked = this.isOptionSelected(option, this.value);

            if (isChecked) {
                this.$emit('change', this.value.filter(item => this.getOptionKey(item) !== this.getOptionKey(option)));
            }
            else {
                this.$emit('change', [...this.value, option]);
            }
        },
    },
};
</script>

<style scoped>
.group {
    display: flex;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-bottom: -8px;
}

.group__btn {
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
}

.group__btn:hover,
.group__btn:focus {
    color: var(--primary-color);
}

.group__btn_active {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
    color: var(--primary-color);
}

.group__btn >>> .label {
    font-family: var(--f-base);
}
</style>