export default ({ HTTP }) => ({
    async get(...args) {
        let config;
        let uuid;
        if (args && args.length) {
            if (typeof args[0] === 'string') {
                uuid = args[0];
                config = args[1];
            }
            else {
                config = args[0];
            }
        }

        if (!config) config = {};

        if (uuid) {
            const url = `/orders/${ uuid }/`;
            const response = await HTTP.get(url, config);
            return response.data;
        }
        else {
            const url = '/orders/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async totalCount({ params }) {
        const config = {
            params: {
                limit: 0,
                offset: 0,
                ...params,
            },
        };
        const url = '/orders/';
        const response = await HTTP.get(url, config);
        return response.data.current_count;
    },

    async create(data) {
        const url = '/orders/create_orders/';
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async pay(uuid) {
        const url = `/orders/${ uuid }/pay_order/`;
        const response = await HTTP.post(url);
        return response.data;
    },

    async changeDeliveryType(uuid, data) {
        const url = `/orders/${ uuid }/change_delivery_type/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async changePickupPoint(uuid, data) {
        const url = `/orders/${ uuid }/change_pickup_point/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async tracking(uuid) {
        const url = `/orders/${ uuid }/tracking/`;
        const response = await HTTP.get(url);
        return response.data;
    },

    async returnOrder(uuid, data) {
        const url = `/orders/${ uuid }/return_order/`;
        const response = await HTTP.put(url, data);
        return response.data;
    },

    async changePositions(uuid, data) {
        const url = `/orders/${ uuid }/change_positions/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async cancel(uuid, data) {
        const url = `/orders/${ uuid }/cancel_order/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async cancelReturnOrder(uuid, data) {
        const url = `/orders/${ uuid }/cancel_return_order/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },
});
