<template>
    <PopupWrap
        @close="cancel"
        @transitionend="onTransitionEnd"
    >
        <div class="popup-content">
            <ImageCropper
                ref="cropper"
                :width="width"
                :height="height"
                :src="src"
                :round="round"
                class="image-cropper"
                :class="{
                    'image-cropper_hidden': imageCropperHidden
                }"
            ></ImageCropper>

            <div class="actions-wrap">
                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="cancel"
                >
                    Отмена
                </ButtonBlock>
                <ButtonBlock
                    primary
                    class="action-btn"
                    @click="confirm"
                >
                    Применить
                </ButtonBlock>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ImageCropper from '@/components/cropper/ImageCropper.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import popup from '@/mixins/popup.js';


export default {
    name: "ImageCropperPopup",

    components: {
        ButtonBlock,
        ImageCropper,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        width: Number,
        height: Number,
        src: String,
        round: Boolean,
    },

    data() {
        return {
            imageCropperHidden: true,
        };
    },

    methods: {
        onTransitionEnd() {
            this.$refs.cropper.repaint();
            this.imageCropperHidden = false;
        },

        async confirm() {
            const file = await this.$refs.cropper.crop();
            this.callback(file);
            this.close();
        },
    },
};
</script>

<style scoped>
.image-cropper_hidden {
    visibility: hidden;
}
@media (max-width: 767px) {
    .image-cropper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 24px;
    }

    .image-cropper >>> .image-cropper__outer-frame {
        flex-grow: 1;
    }
}

@media (min-width: 768px) {
    .popup-content {
        width: 376px;
    }
}

.actions-wrap {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .actions-wrap {
        margin-top: auto;
    }

    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>