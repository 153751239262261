<template>
    <div class="default-layout">
        <SimpleLayoutHeader></SimpleLayoutHeader>

        <main class="body">
            <Spinner
                v-if="!initialized"
                size="big"
                center
                class="top-gap_medium"
            ></Spinner>
            <div
                v-else
                class="container top-gap_medium"
            >
                <InfoMessage
                    v-if="computedOrders.length > 1"
                    gray
                    class="orders-count-info-message"
                >
                    Будет оформлено несколько заказов, потому что выбранные товары находятся в разных магазинах.
                </InfoMessage>

                <div class="cart-content-layout">
                    <FormManager
                        ref="form"
                        v-model="formData"
                        :formTag="false"
                        class="cart-content-layout__main"
                    >
                        <FormCollection name="orders">
                            <FormCollectionItem>
                                <template #default="{ index }">
                                    <div class="order cart-content__section layer-1">
                                        <h2 class="h1 h1_block">
                                            {{ computedOrders.length > 1 ? `${ ordinalNumeral(index + 1) } заказ` : 'Ваш заказ' }}
                                        </h2>

                                        <Washboard noPadding>
                                            <MarketItemCheckout
                                                v-for="(marketItem, marketItemIndex) in computedOrders[index].positions"
                                                :key="'order-market-item' + marketItemIndex"
                                                :item="marketItem"
                                            ></MarketItemCheckout>
                                        </Washboard>

                                        <div class="delivery-section delivery-section_mobile">
                                            <h3 class="h4 h4_block">
                                                Способ получения в <UButtonText
                                                    dashed
                                                    @click="openChangeCityPopup"
                                                >
                                                    {{ (selectedCity || {}).full_name }}
                                                </UButtonText>
                                            </h3>
                                        </div>

                                        <div class="delivery-section delivery-section_mobile selected-delivery-service">
                                            <USpinner
                                                v-if="deliveryInfoFetching"
                                                class="delivery-services-list__spinner"
                                            ></USpinner>

                                            <template v-else>
                                                <template
                                                    v-if="deliveryServices[index].services[formData.orders[index].delivery_service]"
                                                >
                                                    <p class="selected-delivery-service__value">
                                                        {{ deliveryServices[index].services[formData.orders[index].delivery_service].label }}
                                                    </p>
                                                    <p class="selected-delivery-service__description">
                                                        {{ deliveryServices[index].services[formData.orders[index].delivery_service].description }}
                                                    </p>
                                                </template>

                                                <p
                                                    v-if="formData.orders[index].delivery_service === 'ubibi'
                                                        && ubibiPoint"
                                                    class="mt-12 text_base-font"
                                                >
                                                    {{ ubibiPoint.city }},
                                                    {{ ubibiPoint.address }}
                                                    <InfoIcon
                                                        class="delivery-services-list__tooltip"
                                                        @click="showPickupPointInfoPopup"
                                                    ></InfoIcon>
                                                </p>

                                                <div class="delivery-service-block__actions">
                                                    <template
                                                        v-if="formData.orders[index].delivery_service === 'ubibi'"
                                                    >
                                                        <UButtonText
                                                            dashed
                                                            secondary
                                                            dark
                                                            class="delivery-service-block__action"
                                                            @click="showHowPickupOrderPopup"
                                                        >
                                                            Как получить заказ?
                                                        </UButtonText>
                                                    </template>

                                                    <template
                                                        v-else-if="formData.orders[index].delivery_service === 'cdek'"
                                                    >
                                                        <UButtonText
                                                            dashed
                                                            secondary
                                                            dark
                                                            class="delivery-service-block__action"
                                                            @click="showCdekPoints(index)"
                                                        >
                                                            Пункты выдачи
                                                        </UButtonText>

                                                        <UButtonText
                                                            dashed
                                                            secondary
                                                            dark
                                                            class="delivery-service-block__action"
                                                            @click="showHowGetCountryDeliveryPopup"
                                                        >Как оформить доставку?</UButtonText>
                                                    </template>
                                                </div>

                                                <UButtonText
                                                    v-if="deliveryServices[index]
                                                        && deliveryServices[index].options
                                                        && deliveryServices[index].options.length > 1"
                                                    primary
                                                    dashed
                                                    class="selected-delivery-service__change"
                                                    @click="showCheckoutSelectDeliveryServicePopup(index)"
                                                >
                                                    Изменить
                                                </UButtonText>
                                            </template>
                                        </div>

                                        <div class="delivery-section delivery-section_desktop">
                                            <h3 class="h4 h4_block">
                                                Способ получения в <UButtonText
                                                    dashed
                                                    @click="openChangeCityPopup"
                                                >
                                                    {{ (selectedCity || {}).full_name }}
                                                </UButtonText>
                                            </h3>

                                            <div class="delivery-services__wrap">
                                                <USpinner
                                                    v-if="deliveryInfoFetching"
                                                    class="delivery-services-list__spinner"
                                                ></USpinner>

                                                <FormField
                                                    v-show="!deliveryInfoFetching"
                                                    type="radio"
                                                    borders
                                                    name="delivery_service"
                                                    :options="deliveryServices[index].options"
                                                    validations="required"
                                                    class="delivery-services-list"
                                                >
                                                    <template #label="{ option }">
                                                        <div class="delivery-services-list__label">
                                                            <div class="delivery-services-list__text-wrap">
                                                                <p class="text_bold">
                                                                    {{ option.label }}
                                                                </p>
                                                                <p class="text_base-font text_secondary text_small-fz mt-4">
                                                                    {{ option.description }}
                                                                </p>
                                                            </div>

                                                            <div
                                                                v-if="option.logo.length"
                                                                class="delivery-services-list__logo-wrap"
                                                            >
                                                                <img
                                                                    v-for="(logo, logoIndex) in option.logo"
                                                                    :key="index
                                                                        + logoIndex
                                                                        + 'delivery-logo'
                                                                        + option.value"
                                                                    :src="logo"
                                                                    alt=""
                                                                    class="delivery-services-list__logo"
                                                                >
                                                            </div>
                                                        </div>

                                                        <p
                                                            v-if="option.value === 'ubibi' && ubibiPoint"
                                                            class="delivery-services-list__text"
                                                        >
                                                            {{ ubibiPoint.city }},
                                                            {{ ubibiPoint.address }}<PickupPointInfoTooltip
                                                                :point="ubibiPoint"
                                                                class="delivery-services-list__tooltip"
                                                            ></PickupPointInfoTooltip>
                                                        </p>

                                                        <div
                                                            v-if="!option.disabled"
                                                            class="delivery-service-block__actions mt-12 text_base-font"
                                                        >
                                                            <template v-if="option.value === 'ubibi'">
                                                                <UButtonText
                                                                    dashed
                                                                    secondary
                                                                    dark
                                                                    class="delivery-service-block__action"
                                                                    @click="showHowPickupOrderPopup"
                                                                >
                                                                    Как получить заказ?
                                                                </UButtonText>
                                                            </template>

                                                            <template v-else-if="option.value === 'cdek'">
                                                                <UButtonText
                                                                    dashed
                                                                    secondary
                                                                    dark
                                                                    class="delivery-service-block__action"
                                                                    @click="showCdekPoints(index)"
                                                                >
                                                                    Пункты выдачи
                                                                </UButtonText>

                                                                <UButtonText
                                                                    dashed
                                                                    secondary
                                                                    dark
                                                                    class="delivery-service-block__action"
                                                                    @click="showHowGetCountryDeliveryPopup"
                                                                >Как оформить доставку?</UButtonText>
                                                            </template>
                                                        </div>
                                                    </template>
                                                </FormField>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </FormCollectionItem>
                        </FormCollection>

                        <section class="cart-content__section layer-1">
                            <div class="cart-section__title-wrap h1_block">
                                <h2 class="h1">
                                    Получатель
                                </h2>

                                <UButtonText
                                    v-show="allCustomerInfoFill && !customerInfoShowForm"
                                    primary
                                    dashed
                                    class="cart-section__title-wrap"
                                    @click="editCustomerInfo"
                                >
                                    Изменить
                                </UButtonText>
                            </div>

                            <UButtonBlock
                                v-show="!allCustomerInfoFill"
                                primary
                                class="customer-info__fill-btn_mobile"
                                @click="showCheckoutCustomerInfoPopup"
                            >
                                Заполнить данные
                            </UButtonBlock>

                            <div
                                v-show="customerInfoShowForm"
                                class="customer-info-form-wrap_desktop"
                            >
                                <div class="customer-info-form">
                                    <FormField
                                        key="first_name"
                                        name="first_name"
                                        label="Имя"
                                        validations="required"
                                        class="customer-info-form__field"
                                    ></FormField>

                                    <FormField
                                        type="phone"
                                        name="phone"
                                        label="Телефон"
                                        visibleMask
                                        validations="required"
                                        class="customer-info-form__field"
                                    ></FormField>

                                    <FormField
                                        type="email"
                                        name="email"
                                        label="Email"
                                        validations="required"
                                        class="customer-info-form__field"
                                    ></FormField>
                                </div>
                                <InfoMessage class="customer-info-form__info-message">
                                    На&nbsp;номер телефона будет отправлен код, необходимый для получения заказа в&nbsp;магазине. E-mail нужен для оповещений о&nbsp;изменении статусов заказа и&nbsp;отправки электронного чека.
                                </InfoMessage>
                            </div>

                            <div
                                v-show="allCustomerInfoFill && !customerInfoShowForm"
                                class="customer-info-summary"
                            >
                                <p class="customer-info-summary__name">
                                    {{ formData.first_name }}
                                </p>
                                <p class="customer-info-summary__contacts">
                                    {{ formData.phone }} <br> {{ formData.email }}
                                </p>
                            </div>
                        </section>

                        <section class="cart-payment-section cart-content__section layer-1">
                            <h2 class="h1 h1_block">
                                Способ оплаты
                            </h2>

                            <div class="cart-payment-section__text-block">
                                <h3 class="h4">
                                    Картой онлайн
                                </h3>
                                <p class="cart-payment-section__text">
                                    В данный момент оплатить заказ можно только банковской картой VISA, MasterCard или МИР.
                                </p>
                                <Payments class="hidden-s"></Payments>
                            </div>
                        </section>
                    </FormManager>

                    <div
                        v-if="!isCreateOrderBtnDisabled"
                        class="check-mini"
                        :class="{
                            'check-mini_show': showStickyCheck
                        }"
                    >
                        <div>
                            <b class="check-mini__total-price">{{ cartPrice | price }}</b>
                            <span class="check-mini__total-amount">{{ totalAvailableCountString }}</span>
                        </div>

                        <UButtonBlock
                            primary
                            low
                            @click="createOrder"
                        >
                            Перейти к оплате
                        </UButtonBlock>
                    </div>

                    <Observer
                        class="cart-content-layout__aside"
                        @intersect="intersected"
                    >
                        <aside class="cart-content-layout__aside">
                            <div class="check layer-1">
                                <div class="check-section">
                                    <p class="h1 h1_block hidden-m hidden-l hidden-xl">
                                        Итого
                                    </p>
                                    <div class="check-row">
                                        <span class="check-row__key">Товары:</span>
                                        <b class="check-row__value">{{ computedCount }} шт.</b>
                                    </div>
                                </div>

                                <div class="check-section">
                                    <div class="check-row">
                                        <span class="check-row__key">Сумма:</span>
                                        <span class="check-row__value check__total-price">{{ computedPrice | price }}</span>
                                    </div>

                                    <UButtonBlock
                                        primary
                                        :loading="createOrderBtnLoading"
                                        class="check__submit-btn"
                                        :disabled="isCreateOrderBtnDisabled"
                                        @click="createOrder"
                                    >
                                        Перейти к оплате
                                    </UButtonBlock>

                                    <FormAgreement
                                        text="Перейти к оплате"
                                        class="check__agreement"
                                    ></FormAgreement>
                                </div>

                                <div class="check-section">
                                    <BackLink
                                        :to="{
                                            name: 'cart'
                                        }"
                                        text="В корзину"
                                    ></BackLink>
                                </div>
                            </div>
                        </aside>
                    </Observer>
                </div>
            </div>
        </main>

        <ChatLayer></ChatLayer>
        <SimpleLayoutFooter></SimpleLayoutFooter>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import checkout from '@/store/modules/checkout.js';
import plural from '@/lib/plural.js';
import { price as priceFormat, dt as dateFormat } from '@ui/filters/index.js';
import dictionary from '@/dictionary.js';
import getErrorCode from '@/lib/getErrorCode.js';
import breakpointKey from '@/mixins/breakpointKey.js';
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';
import SimpleLayoutFooter from '@/components/SimpleLayoutFooter.vue';
import SimpleLayoutHeader from '@/components/header/SimpleLayoutHeader.vue';
import MarketItemCheckout from '@/components/MarketItemCheckout.vue';
import Payments from '@/components/Payments.vue';
import FormAgreement from '@/components/FormAgreement.vue';
import BackLink from '@/components/project-buttons/BackLink.vue';
import Washboard from '@/components/Washboard.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormCollection from '@/components/_form/FormCollection.vue';
import FormCollectionItem from '@/components/_form/FormCollectionItem.vue';
import FormField from '@/components/_form/FormField.vue';
import Spinner from '@/components/Spinner.vue';
import Observer from '@/components/Observer.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import ChatLayer from '@/components/chat/ChatLayer.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
import UButtonBlock from '@ui/components/UButton/UButtonBlock.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import PickupPointInfoTooltip from '@/components/PickupPointInfoTooltip.vue';
import InfoIcon from '@/components/InfoIcon.vue';
const PrecalcYandexDeliveryPopup = () => import('@/components/popups/PrecalcYandexDeliveryPopup.vue');
const PrecalcCountryDeliveryPopup = () => import('@/components/popups/PrecalcCountryDeliveryPopup.vue');
const HowGetYandexDeliveryPopup = () => import('@/components/popups/HowGetYandexDeliveryPopup.vue');
const HowGetCountryDeliveryPopup = () => import('@/components/popups/HowGetCountryDeliveryPopup.vue');
const HowPickupOrderPopup = () => import('@/components/popups/HowPickupOrderPopup.vue');
const CheckoutCustomerInfoPopup = () => import('@/components/popups/CheckoutCustomerInfoPopup.vue');
const DeliveryOfficesPopup = () => import('@/components/popups/DeliveryOfficesPopup.vue');
const ChangeCityPopup = () => import('@/components/popups/ChangeCityPopup.vue');
const CheckoutSelectDeliveryServicePopup = () => import('@/components/popups/CheckoutSelectDeliveryServicePopup.vue');
const PickupPointInfoPopup = () => import('@/components/popups/PickupPointInfoPopup.vue');


export default {
    name: 'Checkout',

    components: {
        InfoIcon,
        PickupPointInfoTooltip,
        USpinner,
        UButtonBlock,
        UButtonText,
        ChatLayer,
        InfoMessage,
        Observer,
        Spinner,
        FormField,
        FormCollectionItem,
        FormCollection,
        FormManager,
        Washboard,
        BackLink,
        FormAgreement,
        SimpleLayoutHeader,
        SimpleLayoutFooter,
        Payments,
        MarketItemCheckout,
    },

    mixins: [
        handleFormErrorMixin,
        breakpointKey,
    ],

    metaInfo() {
        return {
            title: 'Оформление заказа(ов)',
        };
    },

    serverPrefetch() {
        return this.INIT();
    },

    data() {
        return {
            dictionary,
            formData: {
                orders: [
                    // {
                    //     shop: 1, // статично
                    //     delivery_type: '', // зависит от delivery_service
                    //     pickup_point: null, // для ubibi
                    //     delivery_service: '', // динамично
                    //     delivery_city: 1, // для сдэк
                    // },
                ],
                first_name: '',
                phone: '',
                email: '',
            },
            createOrderBtnLoading: false,
            customerInfoShowForm: false,
            allCustomerInfoFill: false,
            showStickyCheck: false,
        };
    },

    computed: {
        ...mapState({
            initialized: state => (state.checkout || {}).initialized,
            selectedCity: state => (state.checkout || {}).selectedCity,
            deliveryOffices: state => (state.checkout || {}).delivery.offices,
            deliveryCdekEstimates: state => (state.checkout || {}).delivery.cdek.estimates,
            cartOrders: state => state.cart.items,
            cartAvailableCount: state => state.cart.availableCount,
            cartTotalCount: state => state.cart.totalCount,
            cartPrice: state => state.cart.availablePrice,
            profile: state => state.profile.profile,
            currentCity: state => state.cities.currentCity,
        }),

        ...mapGetters({
            deliveryInfoFetching: 'checkout/deliveryInfoFetching',
            hasUbibi: 'checkout/hasUbibi',
            hasCdek: 'checkout/hasCdek',
            ubibiPoint: 'checkout/ubibiPoint',
        }),

        totalAvailableCountString() {
            const n = this.cartTotalCount;
            return `${ n } ${ plural(n, ['товар', 'товара', 'товаров']) }`;
        },

        isCreateOrderBtnDisabled() {
            return !this.formData.first_name || !this.formData.phone || !this.formData.email;
        },

        computedOrders() {
            return this.cartOrders.map(order => {
                return {
                    ...order,
                    weight: order.positions.reduce((acc, position) => {
                        const product = position.offer.product || position.offer.part_product;
                        acc += product.weight;
                        return acc;
                    }, 0),
                };
            });
        },

        computedCount() {
            return this.cartAvailableCount;
        },

        computedPrice() {
            return this.cartPrice;
        },

        deliveryServices() {
            // by orders
            const deliveryServices = [];

            this.computedOrders.forEach((order, index) => {
                const hasPickUpPoint = order.shop.is_delivery_pickup_point && this.hasUbibi;
                const hasCdekDelivery = order.shop.is_delivery_cdek && this.hasCdek;

                const services = {};

                if (hasPickUpPoint) {
                    services.ubibi = {
                        value: 'ubibi',
                        logo: [require('../assets/images/logo.svg')],
                        label: 'Пункт выдачи Ubibi: '
                            + order.shop.current_work.pickup_point_delivery.text
                            + ', бесплатно',
                        description: 'Заберите заказ из пункта выдачи Ubibi самостоятельно.',
                        show: true,
                    };
                }

                if (hasCdekDelivery && this.deliveryCdekEstimates[index]) {
                    const estimate = this.deliveryCdekEstimates[index];
                    const deadline = dateFormat(estimate.deadline, 'DD MM');
                    const price = priceFormat(estimate.total_price);

                    services.cdek = {
                        value: 'cdek',
                        logo: [require('../assets/images/cdek-logo.png')],
                        label: 'СДЭК: ' + deadline + ', от ' + price,
                        description: 'Доставка оформляется отдельно после подтверждения заказа.',
                        show: true,
                    };
                }

                deliveryServices.push({ options: Object.values(services), services });
            });

            return deliveryServices;
        },
    },

    watch: {
        breakpointKey(value) {
            if (value === 'xs' || value === 's') {
                this.customerInfoShowForm = false;
            }
        },

        selectedCity() {
            if (!this.selectedCity) return;

            const orders = this.cartOrders;

            this.UPDATE_DELIVERY_INFO({ orders }).then(() => {
                this.updateSelectedDeliveryService();
            });
        },

        cartOrders() {
            if (!this.selectedCity) return;

            const orders = this.cartOrders;

            this.UPDATE_DELIVERY_INFO({ orders }).then(() => {
                this.updateSelectedDeliveryService();
            });
        },
    },

    created() {
        this.$store.registerModule('checkout', checkout);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('checkout');
    },

    methods: {
        ...mapMutations({
            DESTROY: 'checkout/destroy',
            SET_SELECTED_CITY: 'checkout/setSelectedCity',
        }),

        ...mapActions({
            INIT: 'checkout/init',
            CREATE_CART_ORDER: 'cart/createOrder',
            UPDATE_DELIVERY_INFO: 'checkout/updateDeliveryInfo',
        }),

        intersected(value) {
            this.showStickyCheck = value !== 'in';
        },

        async init() {
            if (!this.initialized) {
                await this.INIT();
            }

            if (this.cartAvailableCount === 0 || this.cartAvailableCount !== this.cartTotalCount) {
                this.$router.push({ name: 'cart' });
            }

            this.SET_SELECTED_CITY(this.currentCity);

            this.formData.first_name = this.profile.first_name;
            this.formData.phone = this.profile.phone || '';
            this.formData.email = this.profile.email || '';
            this.allCustomerInfoFill = !!this.formData.first_name && !!this.formData.phone && !!this.formData.email;
            this.customerInfoShowForm = !this.allCustomerInfoFill;

            this.computedOrders.forEach(order => {
                this.formData.orders.push({
                    shop: order.shop.id,
                    delivery_service: 'ubibi',
                });
            });
        },

        ordinalNumeral(num) {
            const ones = ['', 'Первый', 'Второй', 'Третий', 'Четвертый', 'Пятый', 'Шестой', 'Седьмой', 'Восьмой',
                'Девятый', 'Десятый', 'Одиннадцатый', 'Двенадцатый', 'Тринадцатый', 'Четырнадцатый', 'Пятнадцатый',
                'Шестнадцатый', 'Семнадцатый', 'Восемнадцатый', 'Девятнадцатый'];
            const tens = ['', '', 'Двадцать', 'Тридцать', 'Сорок', 'Пятьдесят', 'Шестьдесят', 'Семьдесят',
                'Восемьдесят', 'Девяносто'];
            const numString = num.toString();

            if (num < 20) return ones[num];
            if (numString.length === 2) return tens[numString[0]] + ' ' + ones[numString[1]].toLowerCase();
        },

        getFormData() {
            return {
                ...this.formData,
                orders: this.formData.orders.map(order => {
                    return {
                        ...order,
                        delivery_type: order.delivery_service === 'cdek' ? 'russian_delivery' : 'pickup_ubibi',
                        delivery_city: this.selectedCity.id,
                        pickup_point: order.delivery_service === 'ubibi' ? this.ubibiPoint.id : null,
                    };
                }),
            };
        },

        async createOrder() {
            const errors = await this.$refs.form.validate();

            if (errors) return;

            this.createOrderBtnLoading = true;

            if (this.$route.name === 'checkout') {
                const data = this.getFormData();

                this.CREATE_CART_ORDER({ data })
                    .then(response => {
                        this.$router.push({
                            name: 'before-payment',
                            params: {
                                uuid: response.payment_uuid,
                            },
                        });
                    })
                    .catch(error => {
                        this.createOrderBtnLoading = false;

                        this.$refs.form.handle(error);
                        this.$store.commit('handleCommonHttpError', error);

                        const code = getErrorCode(error);
                        const data = error.response.data;

                        if (code === 400) {
                            const { offers, receipt_email, receipt_phone, delivery_service, orders } = data;

                            if (offers) {
                                this.$error('Не все позиции есть в наличии. Вернитесь в корзину и измените состав заказа.');
                            }
                            else if (orders) {
                                let message = '';
                                for (let [index, o] of orders.entries()) {
                                    if (o.delivery_type) {
                                        message = o.delivery_type[0].message + ': ' + this.computedOrders[index].shop.company.name + '(' + this.computedOrders[index].shop.address + ')';
                                        break;
                                    }
                                }
                                this.$error(message);
                            }
                            else if (receipt_email) {
                                this.$error(receipt_email[0].message);
                            }
                            else if (receipt_phone) {
                                this.$error(receipt_phone[0].message);
                            }
                            else if (delivery_service) {
                                this.$error(delivery_service[0].message);
                            }
                        }
                    });
            }
        },

        showPrecalcYandexDeliveryPopup(order) {
            const props = {
                order,
            };
            const options = { props };
            this.$popup(PrecalcYandexDeliveryPopup, options);
        },

        showHowPickupOrderPopup() {
            this.$popup(HowPickupOrderPopup);
        },

        showHowGetYandexDeliveryPopup() {
            this.$popup(HowGetYandexDeliveryPopup);
        },

        showPrecalcCountryDeliveryPopup(order) {
            const props = {
                shop: order.shop,
                order: order,
            };
            const options = { props };
            this.$popup(PrecalcCountryDeliveryPopup, options);
        },

        showHowGetCountryDeliveryPopup() {
            this.$popup(HowGetCountryDeliveryPopup);
        },

        editCustomerInfo() {
            if (this.breakpointKey === 's') {
                this.showCheckoutCustomerInfoPopup();
            }
            else {
                this.customerInfoShowForm = true;
            }
        },

        showCheckoutCustomerInfoPopup() {
            const props = {
                currentValue: {
                    first_name: this.formData.first_name,
                    phone: this.formData.phone,
                    email: this.formData.email,
                },
                callback: (value) => {
                    this.allCustomerInfoFill = !!value.first_name && !!value.phone && !!value.email;
                    this.customerInfoShowForm = !this.allCustomerInfoFill;
                    this.formData.first_name = value.first_name;
                    this.formData.phone = value.phone;
                    this.formData.email = value.email;
                },
            };
            const options = { props };
            this.$popup(CheckoutCustomerInfoPopup, options);
        },

        showCdekPoints(index) {
            const item = this.computedOrders[index];
            const city = this.selectedCity;
            const services = ['cdek'];

            const props = {
                item,
                city,
                services,
            };
            const options = { props };
            this.$popup(DeliveryOfficesPopup, options);
        },

        openChangeCityPopup() {
            const callback = (value) => {
                if (value && value.city) {
                    this.SET_SELECTED_CITY(value.city);
                }
            };
            const global = false;
            const props = { callback, global };
            const options = { props };
            this.$popup(ChangeCityPopup, options);
        },

        showCheckoutSelectDeliveryServicePopup(index) {
            const order = this.computedOrders[index];

            const props = {
                order,
                currentValue: this.formData.orders[index].delivery_service,
                options: this.deliveryServices[index].options,
                ubibiPoint: this.ubibiPoint,
                selectedCity: this.selectedCity,
                callback: (value) => {
                    this.formData.orders[index].delivery_service = value;
                },
            };
            const options = { props };
            this.$popup(CheckoutSelectDeliveryServicePopup, options);
        },

        showPickupPointInfoPopup() {
            const point = this.ubibiPoint;
            const props = { point };
            const options = { props };
            this.$popup(PickupPointInfoPopup, options);
        },

        updateSelectedDeliveryService() {
            const hasUbibi = this.hasUbibi;

            this.formData.orders.forEach(order => {
                order.delivery_service = hasUbibi ? 'ubibi' : 'cdek';
            });
        },
    },
};
</script>

<style scoped src="../assets/css/cart.css"></style>
<style scoped src="../assets/css/check.css"></style>
<style scoped>
.default-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--bright-bg);
}

.body {
    flex-grow: 1;
}

@media (min-width: 1281px) {
    .orders-count-info-message {
        width: var(--col-16);
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px){
    .orders-count-info-message {
        margin-bottom: 20px;
        margin-top: -28px;
    }
}
@media (max-width: 767px) {
    .orders-count-info-message {
        margin-bottom: 12px;
    }
}

@media (min-width: 1281px) {
    .cart-content-layout__main {
        grid-column: 3/14;
    }

    .cart-content-layout__aside {
        grid-column: 14/19;
    }
}

.order {
    margin-bottom: var(--y-grid-gap);
}

@media (min-width: 1040px) {
    .customer-info-form {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .customer-info-form {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) {
    .customer-info-form {
        display: grid;
        grid-gap: 20px;
    }
}
@media (max-width: 767px) {
    .customer-info-form-wrap_desktop {
        display: none;
    }
}

@media (max-width: 767px) {
    .customer-info-form__field:not(:last-child) {
        margin-bottom: 16px;
    }
}

.customer-info-form__info-message {
    margin-top: var(--gap-form-fields);
}

.cart-payment-section__text-block {
    position: relative;
}
@media (min-width: 768px) {
    .cart-payment-section__text-block {
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .cart-payment-section__text-block {
        padding-bottom: 16px;
    }
}

.cart-payment-section__text {
    margin-top: 8px;
    font-size: 12px;
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .cart-payment-section__text {
        margin-bottom: 16px;
    }
}

@media (min-width: 1040px) {
    .delivery-section,
    .cart-payment-section__text-block {
        margin-left: -36px;
        margin-right: -36px;
        padding-left: 36px;
        padding-right: 36px;
    }
}
@media (max-width: 1039px) {
    .delivery-section,
    .cart-payment-section__text-block {
        margin-left: calc(var(--base-card-padding-x) * -1);
        margin-right: calc(var(--base-card-padding-x) * -1);
        padding-left: var(--base-card-padding-x);
        padding-right: var(--base-card-padding-x);
    }
}

.delivery-section {
    position: relative;
    border-top: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .delivery-section {
        margin-top: 24px;
        padding-top: 24px;
    }

    .delivery-section_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .delivery-section {
        margin-top: 16px;
        padding-top: 16px;
    }

    .delivery-section_desktop {
        display: none;
    }
}

.delivery-services-list >>> .input-radio-borders__wrap {
    display: block;
}

.delivery-services-list__spinner {
    width: 100%;
    display: flex;
    justify-content: center;
}

.delivery-service-block__actions {
    display: flex;
    align-items: center;
}
@media (max-width: 767px) {
    .delivery-service-block__actions {
        flex-wrap: wrap;
        margin-top: 12px;
        margin-bottom: -12px;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (min-width: 768px) {
    .delivery-service-block__action {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .delivery-service-block__action {
        margin-bottom: 12px;
    }
}

@media (max-width: 767px) {
    .delivery-service-block__action_primary {
        flex: 1;
        align-self: stretch;
    }
}

@media (min-width: 768px) {
    .delivery-services-list__label {
        display: flex;
        align-items: flex-start;
    }

    .delivery-services-list__text-wrap {
        flex-grow: 1;
    }
}

.delivery-services-list__logo-wrap {
    display: flex;
    flex-shrink: 0;
}
@media (max-width: 767px) {
    .delivery-services-list__logo-wrap {
        margin-top: 4px;
    }
}

.delivery-services-list__logo {
    width: 60px;
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .delivery-services-list__logo {
        margin-left: 16px;
    }
}
@media (max-width: 767px) {
    .delivery-services-list__logo:not(:last-child) {
        margin-right: 16px;
    }
}

.delivery-services-list__text {
    margin-top: 12px;
    font-family: var(--f-base);
}

.delivery-services-list__tooltip {
    margin-left: 8px;
}

.selected-delivery-service__change {
    position: absolute;
    top: 16px;
    right: 16px;
}

.selected-delivery-service__value {
    padding-right: 100px;
    font-family: var(--f-bold);
}

.selected-delivery-service__description {
    margin-top: 4px;
    margin-bottom: 12px;
    padding-right: 100px;
    font-size: var(--small-fz);
    color: var(--font-secondary-color);
}

.cart-section__title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.customer-info-summary__name {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
}
@media (min-width: 768px) {
    .customer-info-summary__name {
        margin-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .customer-info-summary__name {
        margin-bottom: 4px;
    }
}

@media (min-width: 768px) {
    .customer-info__fill-btn_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .customer-info__fill-btn_mobile {
        width: 100%;
    }
}
</style>