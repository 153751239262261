export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        meta: {},
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        destroy(state) {
            state.initialized = false;
            state.meta = {};
        },
    },

    actions: {
        async init({ commit }) {
            try {
                const meta = await this.$api.staticPages.get('market-maintenance');

                commit('setMeta', meta);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },
    },
};