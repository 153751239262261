<template>
    <span
        class="info-icon-wrap"
        @click="$emit('click')"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
    >
        <UIcon
            name="info"
            small
            secondary
            class="info-icon"
            aria-label="Дополнительная информация"
        ></UIcon>
    </span>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'InfoIcon',

    components: { UIcon },

    props: {
        big: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.info-icon-wrap {
    display: inline-flex;
    cursor: pointer;
}

.info-icon-wrap:hover .info-icon {
    fill: var(--primary-color);
}
</style>