<template>
    <div class="u-collection">
        <div class="u-collection__header">
            <div class="u-collection__title">
                {{ title }}
            </div>
            <div v-if="innerValues.length" class="u-collection__count">
                {{ innerValues.length }}
            </div>
            <div class="u-collection__add">
                <UPopup>
                    <template #trigger="{ attrs, handlers }">
                        <UButtonRound
                            name="plus-16"
                            primary
                            small
                            v-bind="attrs"
                            v-on="handlers"
                        ></UButtonRound>
                    </template>

                    <template #default="{ handlers }">
                        <slot
                            name="popup"
                            v-bind="{
                                handlers: {
                                    ...handlers,
                                    save: onAdd,
                                }
                            }"
                        ></slot>
                    </template>
                </UPopup>
            </div>
        </div>

        <div class="u-collection__separator"></div>

        <div v-if="!innerValues.length" class="u-collection__empty">
            Вы еще не добавили ни одного значения
        </div>

        <div v-else class="u-collection__list">
            <div
                v-for="(item, index) in innerValues"
                :key="item.id || item.uuid"
                class="u-collection__item"
            >
                <div class="u-collection__label">
                    <slot name="label" v-bind="{ item, index }"></slot>
                </div>
                <div class="u-collection__actions">
                    <div class="u-collection__edit u-collection__action-item">
                        <UPopup>
                            <template #trigger="{ attrs, handlers }">
                                <UButtonRound
                                    name="edit-16"
                                    small
                                    v-bind="attrs"
                                    v-on="handlers"
                                ></UButtonRound>
                            </template>

                            <template #default="{ handlers }">
                                <slot
                                    name="popup"
                                    v-bind="{
                                        values: item,
                                        handlers: {
                                            ...handlers,
                                            save: e => onChange(index, item, e),
                                        }
                                    }"
                                ></slot>
                            </template>
                        </UPopup>
                    </div>

                    <div class="u-collection__remove u-collection__action-item">
                        <UButtonRound
                            name="delete-16"
                            small
                            secondary
                            @click="onRemove(index)"
                        ></UButtonRound>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import equals from '@ui/utils/equals.js';
import deepClone from '@ui/utils/deepClone.js';
import UPopup from '@ui/components/UPopup/UPopup.vue';
import UButtonRound from '@ui/components/UButton/UButtonRound.vue';


export default {
    name: 'UCollection',

    components: { UButtonRound, UPopup },

    props: {
        value: Array,
        title: String,
    },

    data() {
        return {
            innerValues: [],
        };
    },

    watch: {
        value: {
            handler(value) {
                if (value && !equals(value, this.innerValues)) {
                    this.innerValues = deepClone(value);
                }
            },

            immediate: true,
        },

        innerValues: {
            handler(values) {
                if (!equals(values, this.value)) {
                    this.emitChange();
                }
            },
        },
    },

    methods: {
        onAdd(values) {
            this.innerValues = [...this.innerValues, values];
        },

        onChange(index, item, values) {
            this.innerValues.splice(index, 1, Object.assign({}, item, values));
        },

        onRemove(index) {
            this.innerValues.splice(index, 1);
        },

        emitChange() {
            const target = this;
            const value = this.innerValues;
            this.$emit('change', { target, value });
        },
    },
};
</script>

<style>
.u-collection__header {
    display: flex;
    align-items: center;
}

.u-collection__title {
    font-size: var(--big-fz);
    font-family: var(--f-bold);
}

.u-collection__count {
    margin-left: 8px;
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
    font-family: var(--f-bold);
}

.u-collection__add {
    margin-left: auto;
}

.u-collection__separator {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
    background-color: var(--border-light-c);
}

.u-collection__empty {
    color: var(--font-secondary-color);
}

.u-collection__item {
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
}

.u-collection__item:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light-c);
    margin-bottom: 20px;
}

.u-collection__actions {
    margin-left: auto;
    display: flex;
}

.u-collection__action-item:not(:last-child) {
    margin-right: 8px;
}
</style>