<template>
    <section class="u-menu-list__wrap">
        <h6 v-if="$scopedSlots.title" class="u-menu-list__title">
            <slot name="title"></slot>
        </h6>

        <ul class="u-menu-list" v-on="$listeners">
            <slot></slot>
        </ul>
    </section>
</template>

<script>
export default {
    name: 'UMenuList',
};
</script>

<style>
.u-menu-list__wrap {
    padding: 4px;
    max-height: 296px;
    overflow: auto;
}

.u-menu-list__title {
    min-height: 36px;
    width: 100%;
    padding: 8px 12px;
    background-color: var(--light-c);
    border-radius: var(--border-radius);
    transition: background-color var(--transition);
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}
</style>