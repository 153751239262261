<script>
// utils
import px from '@ui/utils/px.js';
// mixins
import stack from '@ui/mixins/stack.js';
import position from '@ui/mixins/position.js';
import teleport from '@ui/mixins/teleport.js';
// components
import UTooltipCloud from '@ui/components/UTooltip/UTooltipCloud.vue';
import UTooltipSimpleTemplate from '@ui/components/UTooltip/UTooltipSimpleTemplate.vue';
import UTrigger from '@ui/components/UTrigger/UTrigger.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


export default {
    name: 'UTooltip',

    components: {
        UTrigger,
        UTooltipIcon,
        UTooltipCloud,
        UTooltipSimpleTemplate,
    },

    mixins: [
        stack,
        position,
        teleport,
    ],

    props: {
        visible: {
            type: Boolean,
            default: true,
        },

        // cloud
        color: {
            type: String,
            default: 'black',
            validator(value) {
                return ['black', 'white'].includes(value);
            },
        },

        // trigger
        showOnClick: Boolean,
        delayEnter: {
            type: Number,
            default: 200,
        },
        delayLeave: {
            type: Number,
            default: 100,
        },

        // template
        maxWidth: [Number, String],
        minWidth: [Number, String],
        textAlign: String,
    },

    data() {
        return {
            active: false,
            booted: false,
        };
    },

    computed: {
        style() {
            return {
                zIndex: this.stack.zIndex,
                top: px(this.position.top),
                left: px(this.position.left),
            };
        },

        scope() {
            const { position, active, style } = this;
            return { position, active, style };
        },
    },

    watch: {
        active: 'onChangeActive',
    },

    methods: {
        onChangeActive(value) {
            this.booted = true;

            if (value) {
                this.updateZIndex();
                this.addContentResizeObserver();
                this.emitOpen();
            }
            else {
                this.removeContentResizeObserver();
                this.emitClose();
            }
        },

        // handlers

        handleAfterLeave() {
            this.emitAfterClose();
        },

        // public

        show() {
            this.$refs.trigger.activate();
        },

        hide() {
            this.$refs.trigger.deactivate();
        },

        // emits

        emitOpen() {
            const target = this;
            this.$emit('open', { target });
        },

        emitClose() {
            const target = this;
            this.$emit('close', { target });
        },

        emitAfterClose() {
            const target = this;
            this.$emit('afterClose', { target });
        },

        // render

        genTemplate() {
            return this.$createElement(UTooltipSimpleTemplate, {
                style: {
                    maxWidth: px(parseInt(this.maxWidth)),
                    minWidth: px(parseInt(this.minWidth)),
                    textAlign: this.textAlign,
                },
            }, this.$scopedSlots.default());
        },

        genCloud() {
            const props = {
                color: this.color,
                shift: this.position.shift,
                placement: this.position.placement,
            };

            if (this.$scopedSlots.cloud) return this.$scopedSlots.cloud(props);

            const children = this.$scopedSlots.content
                ? this.$scopedSlots.content()
                : [this.genTemplate()];

            return this.$createElement(UTooltipCloud, { props }, children);
        },

        genLazyContent() {
            return this.visible && (this.active || this.booted)
                ? [this.genCloud()]
                : [this.$createElement()];
        },

        genContent({ value, handlers }) {
            const style = this.style;

            const children = this.genLazyContent();

            return this.$createElement('div', {
                ref: 'content',
                staticClass: 'u-tooltip__content',
                class: ['u-tooltip__content_' + this.position.placement.split('-')[0], {
                    'u-tooltip__content_active': value,
                }],
                directives: [
                    {
                        value,
                        name: 'show',
                    },
                ],
                on: handlers,
                style,
            }, children);
        },

        genTransition(payload) {
            const content = this.genContent(payload);

            return this.$createElement('transition', {
                props: {
                    name: 'fade-translate',
                    appear: true,
                },
                on: {
                    afterLeave: this.handleAfterLeave,
                },
            }, [content]);
        },

        genTriggerElement({ value, attrs, handlers }) {
            return this.$createElement(UTooltipIcon, {
                attrs,
                on: handlers,
                props: {
                    active: value,
                },
            });
        },

        genTrigger(payload) {
            return this.$scopedSlots.trigger
                ? this.$scopedSlots.trigger(payload)
                : this.genTriggerElement(payload);
        },
    },

    render(h) {
        return h(UTrigger, {
            ref: 'trigger',
            class: {
                'u-tooltip_detached': !this.noDetach,
            },
            props: {
                delayEnter: this.delayEnter,
                delayLeave: this.delayLeave,
                activateOnClick: this.showOnClick,
            },
            scopedSlots: {
                trigger: this.genTrigger,
                content: this.genTransition,
            },
            on: {
                change: ({ value }) => this.active = value,
            },
        });
    },
};
</script>

<style>
.u-tooltip_detached {
    display: none !important;
}

.u-tooltip__content {
    position: absolute;
}

.fade-translate-enter-active,
.fade-translate-leave-active {
    transition: opacity var(--transition), transform var(--transition);
}
.fade-translate-enter,
.fade-translate-leave-to {
    transform: translateY(4px);
    opacity: 0;
}
.fade-translate-enter.u-tooltip__content_top,
.fade-translate-leave-to.u-tooltip__content_top {
    transform: translateY(-4px);
}
.fade-translate-enter.u-tooltip__content_right,
.fade-translate-leave-to.u-tooltip__content_right {
    transform: translateX(4px);
}
.fade-translate-enter.u-tooltip__content_left,
.fade-translate-leave-to.u-tooltip__content_left {
    transform: translateX(-4px);
}
.fade-translate-enter-to,
.fade-translate-leave {
    transform: translateY(0);
    opacity: 1;
}
</style>