<template>
    <div class="observer">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Observer",

    props: {
        options: {
            type: Object,
            default: () => {},
        },
    },

    data: () => ({
        observer: null,
    }),

    mounted() {
        this.observer = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting) {
                this.$emit('intersect', 'in');
            }
            else {
                this.$emit('intersect', 'out');
            }
        },  this.options);

        this.observer.observe(this.$el);
    },

    beforeDestroy() {
        this.observer.disconnect();
    },
};
</script>