<template>
    <component
        :is="tag"
        class="btn"
        :class="{
            'btn_shadow': shadow,
            'btn_rotate': rotate,
        }"
        :disabled="disabled"
        :style="btnSizeStyles"
        @click="$emit('click')"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
    >
        <UIcon
            :name="'arrow-' + direction"
            class="btn__icon"
            :style="iconSizeStyles"
        ></UIcon>
    </component>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: "RoundArrowBtn",

    components: { UIcon },

    props: {
        direction: {
            type: String,
            default: '',
            validator(value) {
                return ['left', 'right', 'up', 'down'].includes(value);
            },
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        shadow: {
            type: Boolean,
            default: false,
        },

        size: {
            type: [Number, String],
            default: 24,
        },

        isButton: {
            type: Boolean,
            default: true,
        },

        rotate: Boolean,
    },

    computed: {
        tag() {
            if (this.isButton) return 'button';
            return 'span';
        },

        btnSizeStyles() {
            return {
                width: this.size + 'px',
                height: this.size + 'px',
            };
        },

        iconSizeStyles() {
            return {
                width: this.size / 2 + 'px',
                height: this.size / 2 + 'px',
            };
        },
    },
};
</script>

<style scoped>
.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: var(--font-secondary-dark-color);
    border: 1px solid var(--border-dark-c);
    border-radius: 50%;
    transition: all var(--transition);
}

.btn:hover:not(:disabled),
.btn:focus:not(:disabled) {
    background-color: var(--lightest-bg);
}

.btn:disabled {
    color: var(--light-bg);
    border-color: var(--border-light-c);
}

.btn_shadow {
    color: var(--font-secondary-color);
    background-color: var(--light-c);
    border: none;
    box-shadow: var(--base-shadow);
}

.btn_shadow:hover:not(:disabled),
.btn_shadow:focus:not(:disabled) {
    color: var(--dark-c);
    background-color: var(--light-c);
}

.btn_rotate {
    transform: rotate(180deg);
}

.btn__icon {
    fill: currentColor;
}
</style>
