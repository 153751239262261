var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",class:[
        'button',
        {
            primary: _vm.primary,
            secondary: _vm.secondary,
            circle: _vm.circle,
            small: _vm.small,
            'hover-primary': _vm.hoverPrimary,
        } ],attrs:{"disabled":_vm.disabled}},'component',_vm.attrs,false),_vm.$listeners),[(_vm.loading)?_c('Spinner',{attrs:{"size":"small"}}):[_vm._t("default",function(){return [_c('UIcon',_vm._b({},'UIcon',_vm.icon,false))]})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }