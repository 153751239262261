import dateToString from '@/lib/datetime/dateToString.js';
import isValidDate from '@ui/utils/isValidDate.js';


export default {
    data() {
        return {
            error: {
                action_start_date: '',
            },
        };
    },

    computed: {
        hasError() {
            return !!this.error.action_start_date;
        },
    },

    watch: {
        'contract.action_start_date': {
            handler() {
                this.clearError('action_start_date');
            },
        },
    },

    methods: {
        clearError(codename) {
            this.error[codename] = null;
        },

        validate() {
            return {
                action_start_date: this.checkActionStartDate(),
            };
        },

        checkActionStartDate() {
            if (this.contract.action_start_date) {
                const date = new Date(this.contract.action_start_date);
                const isValid = isValidDate(date);

                if (isValid) {
                    // todo: серверное время
                    const minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4);
                    const currentPriceDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

                    if (currentPriceDate < minDate) {
                        const prettyDate = dateToString(minDate);
                        return 'Дата начала действия полиса не\u00A0может быть ранее ' + prettyDate;
                    }
                }
            }

            return false;
        },
    },
};