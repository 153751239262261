<template>
    <PopupWrap
        fromBottomOnXS
        :loading="loading"
        @clickaway="close"
        @close="close"
    >
        <div v-if="!loading" class="popup-content">
            <div class="brand__head">
                <div :class="logo.class + '-wrap'">
                    <img
                        :src="logo.url"
                        alt=""
                        :class="logo.class"
                    >
                </div>

                <div class="brand__name-country-wrap">
                    <h2 class="h2 brand__name">
                        {{ brand.page_title }}
                    </h2>

                    <p v-if="brand.country" class="brand__country">
                        {{ brand.country }}
                    </p>
                </div>

                <div class="actions">
                    <LikeBtn
                        :id="brand.codename"
                        apiName="brand"
                        :value="brand.is_favorite"
                        withText
                        @changeFavorite="onChangeFavorite"
                    ></LikeBtn>
                </div>
            </div>

            <div
                v-if="brand.description"
                class="top-gap_mini"
            >
                <p class="d-inline p-textarea">{{ brand.description }}</p>
            </div>

            <div class="footer">
                <ButtonBlock
                    :to="{
                        name: 'market-brand',
                        params: {
                            codename: brand.codename
                        }
                    }"
                    secondary
                    class="footer__btn"
                    @click.native="close"
                >
                    На страницу бренда
                </ButtonBlock>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import { HTTP } from '@/http.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';

export default {
    name: "BrandInfoPopup",

    components: {
        LikeBtn,
        ButtonBlock,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        codename: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            brand: {},
            loading: true,
        };
    },

    computed: {
        logo() {
            let logoObj = {
                url: require('../../assets/images/logo-placeholder.svg'),
                class: 'placeholder',
            };
            let logoUrl = (((this.brand || {}).logo || {}).thumbnails || {}).brand_logo_detail;
            if (logoUrl) {
                logoObj.url = this.$links.uploads + logoUrl;
                logoObj.class = 'logo';
            }
            return logoObj;
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.loading = true;
            const response = await HTTP.get(`/brands/${ this.codename }/`);
            this.brand = response.data;
            this.loading = false;
        },

        onChangeFavorite(value) {
            this.brand.is_favorite = value;
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

@media (min-width: 1040px) {
    .brand__head {
        min-height: 120px;
        padding-right: calc(var(--popup-paddings-x) + 180px + 12px);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .brand__head {
        min-height: 80px;
        padding-right: calc(var(--popup-paddings-x) + 32px + 120px + 12px);
    }
}
@media (max-width: 767px) {
    .brand__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-right: 48px;
    }
}

@media (min-width: 1040px) {
    .logo-wrap,
    .placeholder-wrap {
        right: var(--popup-paddings-x);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo-wrap,
    .placeholder-wrap {
        right: calc(var(--popup-paddings-x) + 32px);
    }
}
@media (min-width: 768px) {
    .logo-wrap,
    .placeholder-wrap {
        position: absolute;
        top: var(--popup-paddings-y);
    }
}
@media (max-width: 767px) {
    .logo-wrap,
    .placeholder-wrap {
        margin-right: 12px;
        margin-bottom: 12px;
    }
}

.logo-wrap {
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius-x2);
}

.placeholder-wrap {
    border-radius: var(--border-radius-x2);
}

@media (min-width: 1040px) {
    .logo-wrap,
    .placeholder-wrap {
        width: 180px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo-wrap,
    .placeholder-wrap {
        width: 120px;
    }
}
@media (max-width: 767px) {
    .logo-wrap,
    .placeholder-wrap {
        width: 90px;
        height: 60px;
    }
}
@media (min-width: 1040px) {
    .logo-wrap {
        padding: 20px 30px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo-wrap {
        padding: 12px 20px;
    }
}
@media (max-width: 767px) {
    .logo-wrap {
        padding: 10px 15px;
    }
}

@media (max-width: 767px) {
    .brand__name-country-wrap {
        margin-bottom: 12px;
    }
}

.brand__country {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .brand__country {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .brand__country {
        margin-top: 4px;
    }
}

@media (min-width: 768px) {
    .actions {
        margin-top: var(--gap-y-mini);
    }
}
@media (max-width: 767px) {
    .actions {
        width: 100%;
        margin-top: 4px;
    }
}

.footer {
    margin-top: auto;
    padding-top: 36px;
}
@media (max-width: 767px) {
    .footer {
        padding-top: 16px;
    }

    .footer__btn {
        width: 100%;
    }
}
</style>