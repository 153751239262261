export const tokens = {
    0: /\d/,
    a: /[a-zA-Z]/,
    f: /[0-9a-fA-F]/,
    R: /[0-9А-ЯЁ]/,
    c: /[АВЕКМНОРСТУХавекмнорстух]/, // для автомобильных номеров
    'A': /[АВЕКМНОРСТУХавекмнорстух]/, // для автомобильных номеров
};

export default function(value, mask) {
    value = value || '';
    mask = mask || '';
    let indexMask = 0;
    let indexValue = 0;
    let maskedValue = '';

    if (value.length) {
        while (indexMask < mask.length && indexValue < value.length) {
            const charMask = mask[indexMask];
            const token = tokens[charMask];
            const charValue = value[indexValue];
            if (token) {
                if (token.test(charValue)) {
                    maskedValue += charValue;
                    indexMask++;
                }
                indexValue++;
            } else {
                maskedValue += charMask;

                if (charValue === charMask) {
                    indexValue++;
                }

                indexMask++;
            }
        }
    }
    else {
        while (indexMask < mask.length) {
            const charMask = mask[indexMask];
            const token = tokens[charMask];
            if (token) break;
            else maskedValue += charMask;
            indexMask++;
        }
    }

    return maskedValue;
}