var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"component",staticClass:"button-text",class:{
        'button-text_active': _vm.active,
        'button-text_loading': _vm.loading,
        'button-text_disabled': _vm.disabled,
        'button-text_focus': _vm.focused,
        'button-text_hover': _vm.hovered,
        'button-text_primary': _vm.primary,
        'button-text_secondary': _vm.secondary,
        'button-text_underline': _vm.underline && !_vm.loading,
        'button-text_dashed': _vm.dashed && !_vm.loading,
        'button-text_dark': _vm.dark,
        'button-text_black': _vm.black,
        'button-text_has-icon': _vm.prependIcon || _vm.appendIcon,
        'button-text_without-icon': !_vm.prependIcon && !_vm.appendIcon,
        'button-text_flex': _vm.flex,
        'button-text_interactive': _vm.tag !== 'span',
    },on:{"click":function($event){return _vm.$emit('click', $event)},"mouseenter":function($event){return _vm.$emit('mouseenter', $event)},"mouseleave":function($event){return _vm.$emit('mouseleave', $event)}}},'component',_vm.attrs,false),[(_vm.loading)?_c('USpinner',{staticClass:"btn-spin",attrs:{"absoluteCenter":!_vm.spinnerLeft}}):_vm._e(),_vm._v(" "),(_vm.prependIcon)?_vm._t("prepend-icon",function(){return [_c('UIcon',_vm._b({staticClass:"mr-8 button-text__icon"},'UIcon',_vm.prependIcon,false))]}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"button-text__label"},[_vm._t("default",function(){return [_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")]})],2),_vm._v(" "),(_vm.appendIcon)?_vm._t("append-icon",function(){return [_c('UIcon',_vm._b({staticClass:"ml-16 button-text__icon"},'UIcon',_vm.appendIcon,false))]}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }