import filter from './filter.js';
import sort from './sort.js';
import deliveryOffices from '@/store/modules/deliveryOffices.js';


const staticFilters = [
    {
        widget: 'radio',
        codename: 'in_stock',
        options: [
            {
                value: 'true',
                label: 'В наличии',
            },
            {
                value: 'false',
                label: 'Все товары, включая отсутствующие',
            },
        ],
    },
    {
        title: 'Цена, ₽',
        widget: 'range',
        codename: 'price',
        min: 0,
        max: 0,
    },
    {
        title: 'Магазины',
        widget: 'checkboxes',
        codename: 'company',
        options: [],
    },
];

export default {
    namespaced: true,

    modules: {
        filter,
        sort,
        deliveryOffices,
    },

    state: () => ({
        parts: {
            items: [],
            current_count: 0,
            hasNext: false,
        },
        initialized: false,
        loading: true,
    }),

    mutations: {
        setParts(state, { results, current_count, next }) {
            state.parts.items = results;
            state.parts.current_count = current_count;
            state.parts.hasNext = !!next;
        },

        addParts(state, { results, current_count, next }) {
            state.parts.items = [...state.parts.items, ...results];
            state.parts.current_count = current_count;
            state.parts.hasNext = !!next;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        setLoading(state, value) {
            state.loading = value;
        },

        destroy(state) {
            state.parts = {
                items: [],
                current_count: 0,
                hasNext: false,
            };
            state.initialized = false;
        },

        changeFavorite(state, { index, value }) {
            state.parts.items[index].is_favorite = value;
        },

        changeCart(state, { itemIndex, itemId, offerId, inCartStatus }) {
            if (itemIndex !== undefined) {
                const currentOffer = state.parts.items[itemIndex].offers.find(offer => offer.id === offerId);
                if (currentOffer) currentOffer.in_basket = true;
            }
            else if (itemId !== undefined) {
                const currentItem = state.parts.items.find(item => item.id === itemId);
                const currentOffer = currentItem.offers.find(item => item.id === offerId);
                if (currentOffer) currentOffer.in_basket = inCartStatus;
            }
        },
    },

    actions: {
        async init({ commit, dispatch, rootState }, { query }) {
            try {
                const params = Object.assign(
                    {
                        brand: rootState.marketBrand.brand.id,
                        get_facets: 'all',
                        limit: 36,
                        offset: 0,
                        order_by: '-rating',
                        in_stock: true,
                    },
                    query,
                );

                const [parts, delivery] = await Promise.all([
                    this.$api.partProductsOneOfferDoc.get({ params }),
                    dispatch('deliveryOffices/getItems'),
                ]);

                parts.results.forEach(part => part.delivery = delivery);

                commit('setInitialized');
                commit('setLoading', false);
                commit('setParts', parts);

                commit('filter/setChecked', []);
                commit('filter/setStaticFilters', {
                    config: staticFilters,
                    facets: parts.facets,
                    defaultFacets: rootState.marketBrand.partsDefaultFacets,
                });
                dispatch('filter/parseQuery', {
                    query,
                    filters: staticFilters,
                });
                commit('sort/parseQuery', query);
                commit('clearHttpError', null, { root: true });

                return { parts };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getParts({ rootState, commit, dispatch }, { params, cancelToken }) {
            try {
                const [parts, delivery] = await Promise.all([
                    this.$api.partProductsOneOfferDoc.get({ params, cancelToken }),
                    dispatch('deliveryOffices/getItems'),
                ]);

                parts.results.forEach(part => part.delivery = delivery);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};