<template>
    <div class="chat-popup layer-3">
        <div class="chat-popup__main">
            <ChatAuthPlaceholder
                v-if="!IS_AUTHORIZED"
                class="chat-popup__placeholder"
            ></ChatAuthPlaceholder>

            <template v-else>
                <div
                    v-if="initializing"
                    class="chat-popup__initializing"
                >
                    <USpinner big></USpinner>
                </div>

                <template v-else>
                    <ChatList
                        v-if="orderedRooms.length"
                        :user="sender"
                        :value="currentRoom"
                        :items="orderedRooms"
                        :searchShow="globalSearch.show"
                        :searchLoading="globalSearch.loading"
                        :searchChats="globalSearch.results.chats"
                        :searchMessages="globalSearch.results.messages"
                        class="chat-popup__list"
                        @select="handleSelectRoom"
                    >
                        <template #header>
                            <div class="chat-popup__header chat-popup__desktop-header">
                                <ChatSearchInput v-model="globalSearch.value"></ChatSearchInput>
                            </div>

                            <div class="chat-popup__header chat-popup__mobile-header">
                                <div
                                    v-if="globalSearch.active"
                                    class="chat-popup__mobile-search-header"
                                >
                                    <ChatSearchInput
                                        v-model="globalSearch.value"
                                        class="chat-popup__mobile-search-input"
                                    ></ChatSearchInput>

                                    <ButtonText
                                        class="chat-popup__mobile-search-cancel-button"
                                        @click="globalSearch.active = false"
                                    >
                                        Отмена
                                    </ButtonText>
                                </div>

                                <template v-else>
                                    <div class="chat-popup__mobile-header-search-button-wrap">
                                        <button
                                            v-if="orderedRooms.length"
                                            type="button"
                                            aria-label="Поиск"
                                            class="chat-popup__mobile-header-search-button"
                                            @click="globalSearch.active = true"
                                        >
                                            <UIcon
                                                name="search-24"
                                                big
                                                class="chat-popup__mobile-header-search-icon"
                                            ></UIcon>
                                        </button>
                                    </div>

                                    <div class="chat-popup__mobile-header-title">
                                        Онлайн-чат
                                    </div>

                                    <button
                                        type="button"
                                        aria-label="Закрыть чат"
                                        class="chat-popup__mobile-close-button"
                                        @click="close"
                                    >
                                        <UIcon
                                            name="close-24"
                                            big
                                            class="chat-popup__mobile-close-icon"
                                        ></UIcon>
                                    </button>
                                </template>
                            </div>
                        </template>
                    </ChatList>

                    <ChatEmptyList
                        v-else
                        @close="close"
                    ></ChatEmptyList>

                    <ChatPrivateRoom
                        v-if="currentRoom"
                        ref="room"
                        :user="sender"
                        :chat="currentRoom"
                        :name="(currentRoom || {}).name"
                        :online="(currentRoom || {}).online"
                        :searchShow="chatSearch.show"
                        :searchValue="chatSearch.value"
                        :searchLoading="chatSearch.loading"
                        :searchMessages="chatSearch.results"
                        class="chat-popup__room"
                        :class="{
                            'chat-popup__room_hidden': about.show || complaint.show
                        }"
                        @init="onInitRoom"
                        @send="handleSend"
                        @seen="handleSeen"
                        @typing="handleTypingMessage"
                        @toMessage="handleToMessage"
                        @clickImage="handleClickImage"
                        @maxSizeError="onMaxSizeError"
                        @maxFilesError="onMaxFilesError"
                        @scrollToTop="handleScrollToTop"
                        @cancelUpload="handleCancelUpload"
                        @scrollToBottom="handleScrollToBottom"
                        @changeLastMessage="handleChangeLastMessage"
                        @changeFirstMessage="handleChangeFirstMessage"
                    >
                        <template #header>
                            <div class="chat-popup__room-header">
                                <template v-if="chatSearch.show">
                                    <ChatSearchInput
                                        v-model="chatSearch.value"
                                        :loading="chatSearch.loading"
                                        class="mr-12"
                                    ></ChatSearchInput>

                                    <ButtonText
                                        class="chat-popup__room-search-cancel-button"
                                        @click="closeChatSearch"
                                    >
                                        Отмена
                                    </ButtonText>
                                </template>

                                <template v-else>
                                    <div class="chat-popup__room-header-left">
                                        <button
                                            type="button"
                                            aria-label="Выйти из комнаты"
                                            class="chat-popup__room-back-button"
                                            @click="closeRoom"
                                        >
                                            <UIcon
                                                name="back"
                                                big
                                                class="chat-popup__room-back-icon"
                                            ></UIcon>
                                        </button>
                                    </div>

                                    <div class="chat-popup__room-header-center">
                                        <div class="chat-popup__room-name-block">
                                            <!-- desktop -->
                                            <button
                                                type="button"
                                                aria-label="Информация о магазине"
                                                class="chat-popup__room-name-button chat-popup__room-name-button_desktop"
                                                @click="toShop"
                                            >
                                                <b class="chat-popup__room-name">
                                                    {{ currentRoom.shop.company.name }}
                                                </b>
                                            </button>

                                            <!-- mobile -->
                                            <UMenu>
                                                <template #trigger="{ value, handlers, attrs }">
                                                    <button
                                                        type="button"
                                                        aria-label="Открыть меню"
                                                        class="chat-popup__room-name-button chat-popup__room-name-button_mobile"
                                                        :class="{
                                                            'chat-popup__room-name-button_active': value
                                                        }"
                                                        v-bind="attrs"
                                                        v-on="handlers"
                                                    >
                                                        <b class="chat-popup__room-name">
                                                            {{ currentRoom.shop.company.name }}
                                                        </b>
                                                    </button>
                                                </template>

                                                <UMenuItem @click="toShop">
                                                    Информация о магазине
                                                </UMenuItem>
                                                <UMenuItem @click="showChatSearch">
                                                    Поиск по чату
                                                </UMenuItem>
                                                <UMenuItem @click="toComplaint">
                                                    Пожаловаться
                                                </UMenuItem>
                                            </UMenu>
                                            <span
                                                v-if="currentRoom.online"
                                                class="chat-popup__room-online"
                                            ></span>
                                        </div>

                                        <div class="chat-popup__room-status">
                                            <UDots
                                                v-if="currentRoom.typing_users.find(u => u.id !== (sender || {}).id)"
                                                class="chat-popup__typing"
                                            >
                                                Печатает
                                            </UDots>
                                            <span v-else-if="currentRoom.online">В сети</span>
                                            <span v-else>Не в сети</span>
                                        </div>
                                    </div>

                                    <div class="chat-popup__room-header-right">
                                        <!-- desktop -->
                                        <UMenu placement="bottom-end">
                                            <template #trigger="{ value, handlers, attrs }">
                                                <UIconButton
                                                    name="menu-24"
                                                    big
                                                    aria-label="Открыть меню"
                                                    class="chat-popup__room-menu-button"
                                                    :class="{
                                                        'chat-popup__room-name-button_active': value
                                                    }"
                                                    v-bind="attrs"
                                                    v-on="handlers"
                                                ></UIconButton>
                                            </template>

                                            <UMenuItem @click="toShop">
                                                Информация о магазине
                                            </UMenuItem>
                                            <UMenuItem @click="showChatSearch">
                                                Поиск по чату
                                            </UMenuItem>
                                            <UMenuItem @click="toComplaint">
                                                Пожаловаться
                                            </UMenuItem>
                                        </UMenu>

                                        <!-- mobile -->
                                        <button
                                            type="button"
                                            aria-label="Закрыть чат"
                                            class="chat-popup__mobile-close-button"
                                            @click="close"
                                        >
                                            <UIcon
                                                name="close-24"
                                                big
                                                class="chat-popup__mobile-close-icon"
                                            ></UIcon>
                                        </button>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <template #offer="{ message, offer }">
                            <div class="position-relative w100">
                                <div class="chat-popup__message-offer-image-wrap mb-12">
                                    <img
                                        v-if="offer.item.cover"
                                        :src="$links.uploads + offer.item.cover.thumbnails.market_item_cover_medium"
                                        :alt="offer.item.name"
                                        class="chat-popup__message-offer-image"
                                    >

                                    <img
                                        v-else
                                        src="@/assets/images/cover-placeholder.svg"
                                        alt=" "
                                        class="chat-popup__message-offer-image"
                                    >
                                </div>

                                <ButtonText
                                    :to="{
                                        name: offerNames[offer.offer_type],
                                        params: {
                                            id: offer.item.id,
                                        }
                                    }"
                                    class="chat-popup__message-offer-name mb-8"
                                >
                                    {{ offer.item.name }}
                                </ButtonText>

                                <div>
                                    <b v-if="offer.in_stock">{{ offer.price | price }}</b>
                                    <span
                                        v-else
                                        class="text_semi-bold text_secondary"
                                    >Нет в наличии</span>
                                </div>

                                <StarsRating
                                    showValue
                                    :value="offer.rating"
                                    class="mt-8 chat-popup__message-offer-rating"
                                ></StarsRating>

                                <div class="chat-popup__message-offer-actions-wrap">
                                    <IconAddCartBtn
                                        :offer="offer"
                                        :item="offer.item"
                                        :modelName="offer.offer_type"
                                        :disabled="!offer.in_stock"
                                        class="mr-12"
                                        @addToCart="addToCart(offer)"
                                    ></IconAddCartBtn>

                                    <LikeBtn
                                        :id="offer.item.id"
                                        :value="offer.is_favorite"
                                        :apiName="offer.offer_type"
                                        @changeFavorite="like($event, message)"
                                    ></LikeBtn>
                                </div>
                            </div>
                        </template>

                        <template #placeholder>
                            <div class="chat-popup__empty-chat">
                                <UIconRound
                                    name="review"
                                    big
                                    class="chat-popup__empty-chat-icon"
                                ></UIconRound>

                                <div class="chat-popup__empty-chat-text-wrap">
                                    <p>
                                        Здесь пока пусто
                                    </p>
                                    <p>
                                        Отправьте своё первое сообщение.
                                    </p>
                                </div>
                            </div>
                        </template>
                    </ChatPrivateRoom>

                    <ChatAbout
                        v-if="about.show"
                        :loading="about.loading"
                        :shop="about.shop"
                        :links="about.links"
                        :offers="about.offers"
                        :images="about.images"
                        :files="about.files"
                        class="chat-popup__about"
                        @close="closeShop"
                        @closeChat="close"
                        @more="moreAboutItems"
                        @toMessage="toMessage"
                        @changeFavoriteShop="onChangeFavoriteShop"
                    ></ChatAbout>

                    <ChatComplaint
                        v-if="complaint.show"
                        :chat="currentRoom"
                        :shop="complaint.shop"
                        :client="complaint.client"
                        :loading="complaint.loading"
                        class="chat-popup__complaint"
                        @closeChat="close"
                        @close="closeComplaint"
                    ></ChatComplaint>

                    <div
                        v-if="selectingRoom"
                        class="chat-popup__preloader"
                    >
                        <USpinner big></USpinner>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
// services
import { HTTP } from '@/http.js';
import socket from '@/services/web-socket';
// utils
import { mapState } from 'vuex';
import pad from '@ui/utils/pad.js';
import isValidDate from '@ui/utils/isValidDate.js';
import fileTypes from '@/components/_chat/file-types.js';
import bus from '@/bus.js';
// components
import ChatAuthPlaceholder from '@/components/chat/ChatAuthPlaceholder.vue';
import ChatList from '@/components/_chat/ChatList.vue';
import ChatPrivateRoom from '@/components/_chat/ChatPrivateRoom.vue';
import ChatAbout from '@/components/chat/ChatAbout.vue';
import ChatEmptyList from '@/components/chat/ChatEmptyList.vue';
import StarsRating from '@/components/StarsRating.vue';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import UIconRound from '@ui/components/UIcon/UIconRound.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import UDots from '@ui/components/UDots/UDots.vue';
import ChatComplaint from '@/components/chat/ChatComplaint.vue';
import ChatSearchInput from '@/components/_chat/ChatSearchInput.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import IconAddCartBtn from '@/components/project-buttons/IconAddCartBtn.vue';
import UMenu from '@ui/components/UMenu/UMenu.vue';
import UMenuItem from '@ui/components/UMenu/UMenuItem.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
const ChatImageViewPopup = () => import('@/components/chat/ChatImageViewPopup.vue');


const LIMIT = 50;

export default {
    name: 'ChatPopup',

    components: {
        UIconButton,
        UIcon,
        UMenuItem,
        UMenu,
        IconAddCartBtn,
        USpinner,
        ChatSearchInput,
        ChatComplaint,
        UDots,
        ButtonText,
        UIconRound,
        LikeBtn,
        StarsRating,
        ChatEmptyList,
        ChatAbout,
        ChatPrivateRoom,
        ChatList,
        ChatAuthPlaceholder,
    },

    filters: {
        timestamp(value) {
            if (!value) return '';

            const now = new Date();
            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return '';

            const today = now.getUTCFullYear() === date.getUTCFullYear()
                && now.getUTCMonth() === date.getUTCMonth()
                && now.getUTCDate() === date.getUTCDate();

            if (today) {
                return `${ pad(date.getHours()) }:${ pad(date.getMinutes()) }`;
            }

            return `${ pad(date.getDate()) }.${ pad(date.getMonth() + 1) }.${ date.getFullYear().toString().substring(2) }`;
        },

        number(value) {
            if (!value || !Number(value)) return '';

            const number = Number(value);

            if (number >= 10000) {
                return number.toLocaleString('ru-RU', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            }

            return number;
        },
    },

    props: {
        shop: Object,
    },

    data() {
        return {
            sender: null,
            rooms: [],
            currentRoom: null,
            currentShop: null,
            fetchingRoom: false,
            creatingRoom: false,
            checkingRoom: false,
            initializing: true,
            fetchingMessages: false,
            fetchMessagesSource: null,
            searchValue: '',
            selectingRoom: false,

            offerNames: {
                product: 'market-product-description',
                part_product: 'market-new-part-description',
                contract_part: 'market-contract-part-description',
            },

            about: {
                loading: false,
                show: false,
                shop: {},
                links: {
                    results: [],
                    next: null,
                    offset: 0,
                    fetching: false,
                },
                offers: {
                    results: [],
                    next: null,
                    offset: 0,
                    fetching: false,
                },
                images: {
                    results: [],
                    next: null,
                    offset: 0,
                    fetching: false,
                },
                files: {
                    results: [],
                    next: null,
                    offset: 0,
                    fetching: false,
                },
            },

            seen: {
                queue: [],
                timeout: null,
            },

            typing: {
                blocks: {
                    // uuid: timeout
                },
                timeouts: {
                    // uuid: {
                    //     id: timeout
                    // }
                },
            },

            complaint: {
                show: false,
                loading: false,
                shop: null,
            },

            globalSearch: {
                active: false,
                show: false,
                loading: false,
                value: '',
                results: {
                    messages: [],
                    chats: [],
                },
                timeout: null,
                source: null,
            },

            chatSearch: {
                show: false,
                loading: false,
                chat: null,
                value: '',
                currentIndex: 0,
                results: [],
                timeout: null,
                source: null,
            },
        };
    },

    computed: {
        ...mapState({
            IS_AUTHORIZED: state => state.profile.isAuthorized,
            PROFILE: state => state.profile.profile,
        }),

        roomByUuid() {
            return this.rooms.reduce((acc, room) => {
                acc[room.uuid] = room;
                return acc;
            }, {});
        },

        orderedRooms() {
            return this.rooms.filter(room => room.last_message).sort((a, b) => {
                if (!a.last_message && !b.last_message) return 0;
                if (!a.last_message) return 1;
                if (!b.last_message) return -1;

                return new Date(b.last_message.create_date) - new Date(a.last_message.create_date);
            });
        },
    },

    watch: {
        shop: {
            async handler(shop) {
                if (shop) {
                    let room;

                    for (const r of this.rooms) {
                        if (r.shop.id === shop.id) {
                            this.currentRoom = r;
                            this.$emit('update:shop', null);
                            return;
                        }
                    }

                    const response = await this.checkRoom({ shop });

                    if (response) {
                        room = response;
                    }
                    else {
                        room = await this.createRoom({ shop });
                    }

                    if (room) {
                        if (this.roomByUuid[room.uuid]) {
                            this.updateRoom(room);
                        }
                        else {
                            this.addRoom(room);
                        }

                        this.currentRoom = room;
                        this.$emit('update:shop', null);
                    }
                }
            },
        },

        'globalSearch.value': {
            handler(value) {
                if (this.globalSearch.timeout) {
                    clearTimeout(this.globalSearch.timeout);
                    this.globalSearch.timeout = null;
                }

                if (value && value.length > 1) {
                    this.globalSearch.timeout = setTimeout(() => {
                        this.fetchGlobalSearch();
                    }, 500);
                }
                else {
                    if (this.globalSearch.source) {
                        this.globalSearch.source.cancel();
                        this.globalSearch.source = null;
                    }

                    this.globalSearch.show = false;
                }
            },
        },

        'chatSearch.value': {
            handler(value) {
                if (this.chatSearch.timeout) {
                    clearTimeout(this.chatSearch.timeout);
                    this.chatSearch.timeout = null;
                }

                if (value && value.length > 1) {
                    this.chatSearch.timeout = setTimeout(() => {
                        this.fetchChatSearch();
                    }, 500);
                }
                else {
                    if (this.chatSearch.source) {
                        this.chatSearch.source.cancel();
                        this.chatSearch.source = null;
                    }
                }
            },
        },
    },

    mounted() {
        bus.$on('deleteFromCart', this.handleDeleteFromCart);

        if (socket.isOpened) {
            this.init();
        }
        else {
            socket.onopen(() => {
                this.init();
            });
        }
    },

    beforeDestroy() {
        socket.off('chat.update.not_seen_message', this.onUpdateNotSeenMessage);
        socket.off('chat.message.update.seen', this.onUpdateMessageSeen);
        socket.off('chat.message.update.link', this.onUpdateMessageLink);
        socket.off('notify.online.update', this.onChangeOnline);
        socket.off('chat.new.message', this.onNewMessage);
        socket.off('chat.typing', this.onTyping);
    },

    methods: {
        async init() {
            this.initializing = true;

            socket.on('chat.update.not_seen_message', this.onUpdateNotSeenMessage);
            socket.on('chat.message.update.seen', this.onUpdateMessageSeen);
            socket.on('chat.message.update.link', this.onUpdateMessageLink);
            socket.on('notify.online.update', this.onChangeOnline);
            socket.on('chat.new.message', this.onNewMessage);
            socket.on('chat.typing', this.onTyping);

            const rooms = await this.getChatRooms();

            this.sender = this.normalizeSender(this.PROFILE);
            this.rooms = rooms;

            if (this.shop) {
                let room;
                const shop = this.shop;
                const response = await this.checkRoom({ shop });

                if (response) {
                    room = response;
                }
                else {
                    room = await this.createRoom({ shop });
                }

                if (room) {
                    if (this.roomByUuid[room.uuid]) {
                        this.updateRoom(room);
                    }
                    else {
                        this.addRoom(room);
                    }

                    this.currentRoom = room;
                    this.$emit('update:shop', null);
                }
            }

            this.initializing = false;
        },

        close() {
            this.$emit('close', { target: this });
        },

        async changeRoom({ value: room }) {
            if (room && this.currentRoom && room.uuid === this.currentRoom.uuid) return;

            this.currentRoom = room;

            this.$nextTick(() => {
                this.$refs.room.focusInput();
            });
        },

        closeRoom() {
            this.currentRoom = null;
        },

        updateRoom(room) {
            const index = this.rooms.map(room => room.uuid).indexOf(room.uuid);

            if (index !== -1) {
                this.rooms.splice(index, 1, room);
            }
        },

        addRoom(room) {
            this.rooms = [room, ...this.rooms];
        },

        updateMessage(message) {
            const ref = this.$refs.room;

            if (ref && ref.chat.uuid === message.chat) {
                ref.updateMessage(message);
            }
        },

        normalizeSender(sender) {
            const { first_name, last_name } = sender;
            let name = first_name;
            if (last_name) name += ' ' + last_name;
            const avatar = sender.avatar ? this.$links.uploads + sender.avatar.thumbnails.avatar_s : '';
            return { ...sender, name, avatar };
        },

        normalizeImage({ mediafile, id }) {
            const miniature = mediafile.width > mediafile.height
                ? this.$links.uploads + mediafile.thumbnails.chat_horizontal_mini
                : this.$links.uploads + mediafile.thumbnails.chat_vertical_mini;

            return { ...mediafile, id, miniature };
        },

        normalizeMessage(data) {
            let file = null;
            let image = null;
            let offer = null;
            let link = null;
            const { attachments } = data;

            if (attachments) {
                if (attachments.images && attachments.images.length) {
                    image = this.normalizeImage(attachments.images[0]);
                }

                if (attachments.files && attachments.files.length) {
                    file = attachments.files[0];
                }

                if (attachments.offers && attachments.offers.length) {
                    offer = attachments.offers[0];
                }

                if (attachments.links && attachments.links.length) {
                    link = attachments.links.reverse()[0];
                }
            }

            const sender = this.normalizeSender(data.sender);

            let reply = null;

            if (data.reply) {
                reply = this.normalizeMessage(data.reply);
            }

            return { ...data, sender, image, file, reply, offer, link };
        },

        normalizeSearchMessage(message, search_value) {
            const { create_date, sender, id, text: str } = this.normalizeMessage(message);
            const room = this.normalizeRoom(message.chat);
            const chat = room.uuid;
            const text = this.cutSearchMessage(str, search_value);
            const search_message = { create_date, sender, id, chat, text, search_value };
            return { ...room, search_message };
        },

        cutSearchMessage(str, searchValue) {
            // ищем первое вхождение
            // назад 20 символов
            // вперед до первого пробела

            const index = str.indexOf(searchValue);

            if (index < 20) {
                return str;
            }

            const offset = index - 20;

            const after = str.slice(offset);

            // TODO: добавить все виды пробелов
            let spaceIndex = after.indexOf(' ');

            if (spaceIndex > 20 || spaceIndex === -1) {
                const before = str.slice(0, offset);

                spaceIndex = before.lastIndexOf();

                if (spaceIndex === -1) {
                    return str;
                }
            }
            else {
                spaceIndex += offset;
            }

            return '\u2026' + str.slice(spaceIndex + 1);
        },

        normalizeRoom(room) {
            return {
                ...room,
                typing_users: [],
                messages: [],
                online: room.shop.current_work.place.is_active,
                private: true,
                fetching: false,
                initialized: false,
                unread_count: room.not_seen_message_amount || 0,
                name: room.shop.company.name + ', ' + room.shop.address,
                last_message: room.last_message ? this.normalizeMessage(room.last_message) : null,
                first_message: room.first_message ? this.normalizeMessage(room.first_message) : null,
                full_name: room.shop.company.name + ', ' + 'г. ' + room.shop.city.name + ', ' + room.shop.address,
                avatar: room.shop.company.logo
                    ? this.$links.uploads + room.shop.company.logo.thumbnails.company_logo_micro
                    : '',
            };
        },

        toMessage({ message }) {
            const room = this.roomByUuid[message.chat];

            if (this.currentRoom && room && this.currentRoom.uuid === room.uuid) {
                this.$refs.room.toMessage(message);
            }
            else {
                this.currentRoom = room;

                this.$nextTick(() => {
                    if (this.$refs.room) {
                        this.$refs.room.toMessage(message);
                    }
                });
            }
        },

        // socket

        async onNewMessage(data) {
            const message = this.normalizeMessage(data);
            const sender = message.sender;
            const chat = this.roomByUuid[message.chat];

            if (!chat) return;

            this.updateMessage(message);

            const index = chat.typing_users.findIndex(user => user.id === sender.id);

            if (index !== -1) {
                chat.typing_users.splice(index, 1);
            }

            if (this.typing.timeouts[chat.uuid] && this.typing.timeouts[chat.uuid][sender.id]) {
                clearTimeout(this.typing.timeouts[chat.uuid][sender.id]);
                delete this.typing.timeouts[chat.uuid][sender.id];
            }

            if (chat) {
                chat.last_message = message;

                if (!chat.first_message) {
                    chat.first_message = message;
                }
            }
            else {
                try {
                    const response = await this.$api.chat.get(message.chat);
                    const room = this.normalizeRoom(response);
                    this.rooms = [...this.rooms, room];
                }
                catch (error) {
                    this.$store.commit('handleCommonHttpError', error);
                }
            }
        },

        onUpdateMessageSeen(data) {
            if (!data) return;

            const arr = Array.isArray(data) ? data : [data];

            arr.forEach(obj => {
                const id = obj.id;
                const uuid = obj.chat;
                const is_seen = obj.is_seen;
                const chat = this.roomByUuid[uuid];

                if (!chat) return;

                if (chat.last_message && chat.last_message.id === id) {
                    chat.last_message = Object.assign({}, chat.last_message, { is_seen });
                }

                if (chat.first_message && chat.first_message.id === id) {
                    chat.first_message = Object.assign({}, chat.first_message, { is_seen });
                }

                if (this.currentRoom.uuid !== uuid) return;
                if (!this.$refs.room) return;

                this.$refs.room.updateMessage(id, { is_seen });
            });
        },

        onUpdateMessageLink(data) {
            if (!data || !data.message || !data.chat || !data.links) return;

            const id = data.message;
            const uuid = data.chat;
            const link = data.links[0] || null;
            const chat = this.roomByUuid[uuid];

            if (!chat) return;

            if (chat.last_message && chat.last_message.id === id) {
                chat.last_message = Object.assign({}, chat.last_message, { link });
            }

            if (chat.first_message && chat.first_message.id === id) {
                chat.first_message = Object.assign({}, chat.first_message, { link });
            }

            if (this.currentRoom.uuid !== uuid) return;
            if (!this.$refs.room) return;

            this.$refs.room.updateMessage(id, { link });
        },

        onUpdateNotSeenMessage(data) {
            if (!data.uuid) return;

            const room = this.roomByUuid[data.uuid];

            if (!room) return;

            room.first_not_seen_message = data.first_not_seen_message || null;
            room.unread_count = data.not_seen_message_amount || 0;
        },

        onTyping(data) {
            if (!data) return;

            const room = this.roomByUuid[data.chat];

            if (!room) return;

            const sender = this.normalizeSender(data.user);

            if (!room.typing_users.find(user => user.id === sender.id)) {
                room.typing_users = [...room.typing_users, sender];
            }

            if (!this.typing.timeouts[room.uuid]) {
                this.typing.timeouts[room.uuid] = {};
            }

            this.typing.timeouts[room.uuid][sender.id] = setTimeout(() => {
                const index = room.typing_users.findIndex(user => user.id === sender.id);

                if (index !== -1) {
                    room.typing_users.splice(index, 1);
                }

                if (this.typing.timeouts[room.uuid][sender.id]) {
                    clearTimeout(this.typing.timeouts[room.uuid][sender.id]);
                    delete this.typing.timeouts[room.uuid][sender.id];
                }
            }, 6000);
        },

        onChangeOnline(data) {
            if (!data || !data.shop || data.status === undefined) return;

            const { shop, status } = data;

            for (const room of this.rooms) {
                if (room.shop.id === shop) {
                    room.online = status;
                    break;
                }
            }
        },

        // handlers

        async handleSelectRoom($event) {
            const room = $event.value;
            const message = $event.message;

            if (room && this.currentRoom && room.uuid === this.currentRoom.uuid) {
                if (message && this.$refs.room) {
                    this.$refs.room.toMessage(message);
                }

                return;
            }

            const index = this.rooms.findIndex(r => r.uuid === room.uuid);

            if (index === -1) {
                this.selectingRoom = true;
                const uuid = room.uuid;
                const response = await this.getChat(uuid);

                if (response) {
                    this.currentRoom = this.normalizeRoom(response);
                }

                this.selectingRoom = false;
            }
            else {
                this.currentRoom = this.rooms[index];
            }

            if (message) {
                // Прокидываю это свойство,
                // чтобы использовать
                // в onInitRoom
                this.currentRoom._to_message = message;
            }

            this.$nextTick(() => {
                if (this.$refs.room) {
                    this.$refs.room.focusInput();
                }
            });
        },

        async onInitRoom({ target, callback }) {
            let to;
            let messages = [];
            const chat = target.chat;

            if (chat._to_message) {
                const message = { ...chat._to_message };
                messages = await this.getMessages({ chat, message });
            }
            else if (chat.first_not_seen_message) {
                const message = { id: chat.first_not_seen_message };
                messages = await this.getMessages({ chat, message });
            }
            else if (chat.last_message) {
                const message = chat.last_message;
                const response = await this.getMessages({ chat, message, before: true });
                messages = [...response, message];
            }

            if (chat._to_message) {
                to = { ...chat._to_message };
                delete chat._to_message;
            }

            callback({ messages, to });
        },

        async handleSend({ message, target }) {
            let chat = target.chat;

            chat.last_message = message;

            if (!chat.first_message) {
                chat.first_message = message;
            }

            if (chat._new) {
                const response = await this.$api.chat.create({ shop: chat.shop.id });
                const index = this.rooms.findIndex(room => room.uuid === chat.uuid);

                if (index !== -1) {
                    this.rooms[index].uuid = response.uuid;
                    this.rooms[index]._new = false;
                }
            }

            await this.sendMessage({ message, chat });
        },

        handleSeen({ message, target }) {
            const chat = target.chat;

            this.$store.commit('chat/unread/decrement');
            if (chat.unread_count) chat.unread_count--;

            this.seen.queue.push({ chat, message });

            if (!this.seen.timeout) {
                this.seen.timeout = setTimeout(async () => {
                    await this.pushSeenQueue();
                    this.seen.queue = [];
                    clearTimeout(this.seen.timeout);
                    this.seen.timeout = null;
                }, 500);
            }
        },

        async handleToMessage({ message, target, callback }) {
            const chat = target.chat;

            const messages = await this.getMessages({ chat, message });

            callback(messages);
        },

        async handleScrollToTop({ target, message, callback }) {
            const chat = target.chat;

            const response = await this.getMessages({ chat, message, before: true });

            callback({ messages: response });
        },

        async handleScrollToBottom({ target, message, callback }) {
            const chat = target.chat;

            const response = await this.getMessages({ chat, message, after: true });

            callback({ messages: response });
        },

        handleTypingMessage({ target }) {
            const chat = target.chat;
            const uuid = chat.uuid;

            if (!uuid) return;
            if (this.typing.blocks[uuid]) return;

            this.typing.blocks[uuid] = setTimeout(() => {
                clearTimeout(this.typing.blocks[uuid]);
                delete this.typing.blocks[uuid];
            }, 5000);

            socket.emit('chat.typing', { chat: uuid });
        },

        handleCancelUpload(message) {
            if (message._source) message._source.cancel();
        },

        handleChangeLastMessage({ target, message }) {
            const uuid = target.chat.uuid;
            const room = this.roomByUuid[uuid];

            if (!room) return;

            room.last_message = message;
        },

        handleChangeFirstMessage({ target, message }) {
            const uuid = target.chat.uuid;
            const room = this.roomByUuid[uuid];

            if (!room) return;

            room.first_message = message;
        },

        onMaxFilesError() {
            console.log('onMaxFilesError');
        },

        onMaxSizeError() {
            console.log('onMaxSizeError');
        },

        handleClickImage({ target, message }) {
            const toMessage = message => target.toMessage(message);
            const normalizeMessage = this.normalizeMessage;
            const initialItem = { id: message.image.id, message, mediafile: message.image };
            const props = { initialItem, toMessage, normalizeMessage };
            const options = { props };
            this.$popup(ChatImageViewPopup, options);
        },

        like(value, message) {
            // МУТИРУЮ ОБЪЕКТ НАПРЯМУЮ
            message.offer.is_favorite = value;
        },

        addToCart(offer) {
            // МУТИРУЮ ОБЪЕКТ НАПРЯМУЮ
            offer.in_basket = true;
        },

        handleDeleteFromCart({ offerId, inCartStatus }) {
            if (!this.currentRoom) return;
            if (!this.$refs.room) return;

            const messages = this.$refs.room.innerMessages;

            for (const message of messages) {
                if (message.offer && message.offer.id === offerId) {
                    // МУТИРУЮ ОБЪЕКТ НАПРЯМУЮ
                    message.offer.in_basket = inCartStatus;
                    break;
                }
            }
        },

        // chat search

        showChatSearch() {
            this.chatSearch.show = true;
        },

        prevChatSearchResult() {
            if (this.chatSearch.currentIndex < 1) return;

            this.chatSearch.currentIndex -= 1;
            const message = this.chatSearch.results[this.chatSearch.currentIndex].search_message;
            const room = this.$refs.room;

            if (message && room) {
                room.toMessage(message);
            }
        },

        nextChatSearchResult() {
            if (this.chatSearch.currentIndex >= (this.chatSearch.results.length - 1)) return;

            this.chatSearch.currentIndex += 1;

            const message = this.chatSearch.results[this.chatSearch.currentIndex].search_message;
            const room = this.$refs.room;

            if (message && room) {
                room.toMessage(message);
            }
        },

        closeChatSearch() {
            this.chatSearch.show = false;
            this.chatSearch.value = '';
            this.chatSearch.results = [];
        },

        // fetch

        async createRoom({ shop }) {
            this.creatingRoom = true;

            try {
                const response = await this.$api.shops.get(shop.id);
                return this.normalizeRoom({ shop: response, _new: true, uuid: 1 });
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.creatingRoom = false;
            }
        },

        async checkRoom({ shop }) {
            this.checkingRoom = true;

            try {
                const params = { shop: shop.id };
                const response = await this.$api.chat.check({ params });
                return response && Object.keys(response).length ? this.normalizeRoom(response) : null;
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.checkingRoom = false;
            }
        },

        async getChatRooms() {
            this.fetchingRoom = true;

            try {
                const { results } = await this.$api.chat.get();
                return results.map(this.normalizeRoom);
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
                return [];
            }
            finally {
                this.fetchingRoom = false;
            }
        },

        async getChat(uuid) {
            try {
                return await this.$api.chat.get(uuid);
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
        },

        async sendMessage({ message, chat }) {
            const uuid = chat.uuid;
            const attachments = {};
            const { image, file, nonce, text, reply, offer } = message;

            message.chat = uuid;

            chat.last_message = message;

            const hasFile = (!!file && !!file.blob) || (!!image && !!image.blob);

            if (hasFile) {
                message._source = HTTP.CancelToken.source();

                const uploadedFile = await this.uploadFile(message);

                if (uploadedFile) {
                    let propName = 'images';

                    if (fileTypes.includes(uploadedFile.type)) {
                        propName = 'files';
                    }

                    attachments[propName] = [uploadedFile.id];
                }
                else {
                    return;
                }
            }

            const data = {
                text,
                nonce,
                chat: uuid,
            };

            if (reply) {
                data.reply = reply.id;
            }

            if (offer) {
                attachments.offers = [offer.uuid];
            }

            if (Object.keys(attachments).length) {
                data.attachments = attachments;
            }

            try {
                await this.$api.chat.sendMessage(uuid, data);
            }
            catch (error) {
                this.$set(message, '_notSentError', true);
            }
        },

        async uploadFile({ file, image, _source }) {
            const obj = image || file;

            const data = new FormData();

            data.append('file', obj.blob, obj.name);

            let request = this.$api.privateMediafiles.upload;

            if (file) {
                request = this.$api.privateFiles.upload;
            }

            const config = {
                onUploadProgress: (progressEvent) => {
                    obj.progress = parseInt((progressEvent.loaded / progressEvent.total) * 100);
                },
                cancelToken: _source.token,
            };

            try {
                const response = await request(data, config);

                obj.loaded = true;
                obj.id = response.uuid;

                return obj;
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;

                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                obj.progress = null;
                obj.loading = false;
            }
        },

        async pushSeenQueue() {
            const arr = [];
            const data = { message: [] };
            const queue = this.seen.queue;

            queue.forEach(({ message }) => {
                const chat = message.chat;
                const id = message.id;

                if (data.chat && chat !== data.chat) {
                    arr.push({ ...data });

                    data.chat = '';
                    data.message = [];
                }

                data.chat = chat;
                data.message.push(id);
            });

            arr.push({ ...data });

            try {
                await Promise.all(arr.map(data => this.$api.chat.updateSeen(data.chat, data)));
            }
            catch (error) {
                //
            }
        },

        async getMessages({ chat, message, before, after }) {
            if (!message) return;
            if (!chat) return;

            if (this.fetchMessageSource) {
                this.fetchMessageSource.cancel();
                this.fetchMessageSource = null;
            }

            this.fetchMessageSource = HTTP.CancelToken.source();
            const cancelToken = this.fetchMessageSource.token;

            chat.fetching = true;
            this.fetchingMessages = true;

            let func;

            if (before) {
                func = this.getOldMessages;
            }
            else if (after) {
                func = this.getNewMessages;
            }
            else {
                func = this.getMiddleMessages;
            }

            try {
                return await func({ chat, message, cancelToken });
            }
            catch (error) {
                if (!HTTP.isCancel(error)) {
                    this.$store.commit('handleCommonHttpError', error);
                }

                return [];
            }
            finally {
                chat.fetching = false;
                this.fetchingMessages = false;
            }
        },

        async getOldMessages({ chat, message, cancelToken }) {
            const uuid = chat.uuid;
            const limit = 50;
            const id__lt = message.id;
            const params = { limit, id__lt };
            const config = { params, cancelToken };

            const results = await this.$api.chat.messages(uuid, config);

            return results.map(this.normalizeMessage);
        },

        async getNewMessages({ chat, message, cancelToken }) {
            const uuid = chat.uuid;
            const limit = 50;
            const id__gt = message.id;
            const params = { limit, id__gt };
            const config = { params, cancelToken };

            const results = await this.$api.chat.messages(uuid, config);

            return results.map(this.normalizeMessage);
        },

        async getMiddleMessages({ chat, message, cancelToken }) {
            const uuid = chat.uuid;
            const limit = 50;
            const id__lte = message.id;
            const id__gt = message.id;
            const params1 = { limit, id__lte };
            const params2 = { limit, id__gt };
            const config1 = { params: params1, cancelToken };
            const config2 = { params: params2, cancelToken };

            const [results1, results2] = await Promise.all([
                this.$api.chat.messages(uuid, config1),
                this.$api.chat.messages(uuid, config2),
            ]);

            return [
                ...results1.map(this.normalizeMessage),
                ...results2.map(this.normalizeMessage),
            ];
        },

        async fetchGlobalSearch() {
            if (!this.globalSearch.value) return;

            this.globalSearch.loading = true;
            this.globalSearch.show = true;

            if (this.globalSearch.source) {
                this.globalSearch.source.cancel();
                this.globalSearch.source = null;
            }

            try {
                this.globalSearch.source = HTTP.CancelToken.source();
                const cancelToken = this.globalSearch.source.token;
                const q = this.globalSearch.value;
                const search_value = q.toLowerCase();
                const params = { q };
                const config = { params, cancelToken };

                const { messages, chats } = await this.$api.chat.search(config);

                const start = Date.now();
                this.globalSearch.results.messages = messages && messages.length
                    ? messages.map(message => this.normalizeSearchMessage(message, search_value))
                    : [];

                if (process.env.TARGET_ENV !== 'prod') {
                    console.log(
                        'modify search messages performance:',
                        (Date.now() - start) + 'ms for ' + messages.length + ' messages',
                    );
                }

                this.globalSearch.results.chats = chats && chats.length
                    ? chats.map(chat => {
                        return { ...this.normalizeRoom(chat), search_value: search_value };
                    })
                    : [];
            }
            catch (error) {
                if (HTTP.isCancel(error)) {
                    if (!this.globalSearch.value) {
                        this.globalSearch.show = false;
                    }

                    return;
                }

                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.globalSearch.loading = false;
            }
        },

        async fetchChatSearch() {
            if (!this.chatSearch.value) return;

            this.chatSearch.loading = true;

            if (this.chatSearch.source) {
                this.chatSearch.source.cancel();
                this.chatSearch.source = null;
            }

            try {
                this.chatSearch.source = HTTP.CancelToken.source();
                const cancelToken = this.chatSearch.source.token;
                const q = this.chatSearch.value;
                const uuid = this.currentRoom.uuid;
                const params = { q, uuid };
                const config = { params, cancelToken };

                const { messages } = await this.$api.chat.search(config);

                const search_value = q.toLowerCase();

                this.chatSearch.results = messages && messages.length
                    ? messages.map(message => ({ ...this.normalizeMessage(message), search_value }))
                    : [];

                if (!this.chatSearch.results.length) return;

                const index = this.chatSearch.results.length - 1;
                const message = this.chatSearch.results[index];
                const room = this.$refs.room;

                if (message && room) {
                    room.searchCurrentIndex = index;
                    room.toMessage(message);
                }
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;

                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.chatSearch.loading = false;
            }
        },

        // shop

        async toShop() {
            const chat = this.currentRoom;

            this.about.loading = true;
            this.about.show = true;

            const uuid = chat.uuid;
            const id = chat.shop.id;

            try {
                const [
                    shop,
                    links,
                    offers,
                    images,
                    files,
                ] = await Promise.all([
                    this.$api.shops.get(id),
                    this.$api.chat.links(uuid, { params: { limit: LIMIT, offset: this.about.links.offset } }),
                    this.$api.chat.offers(uuid, { params: { limit: LIMIT, offset: this.about.offers.offset } }),
                    this.$api.chat.images(uuid, { params: { limit: LIMIT, offset: this.about.images.offset } }),
                    this.$api.chat.files(uuid, { params: { limit: LIMIT, offset: this.about.files.offset } }),
                ]);

                this.about.shop = shop;
                this.about.links.next = links.next;
                this.about.links.results = links.results;
                this.about.offers.next = offers.next;
                this.about.offers.results = offers.results;
                this.about.images.next = images.next;
                this.about.images.results = images.results.map(obj => {
                    let { message, ...data } = obj;
                    message = this.normalizeMessage(message);
                    return { ...data, message };
                });
                this.about.files.next = files.next;
                this.about.files.results = files.results;
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.about.loading = false;
            }
        },

        async moreAboutItems(name) {
            this.about[name].fetching = true;

            const uuid = this.currentRoom.uuid;
            const limit = LIMIT;
            const offset = this.about[name].offset = this.about[name].offset + LIMIT;

            try {
                const params = { limit, offset };
                const config = { params };
                const response = await this.$api.chat[name](uuid, config);
                this.about[name].next = response.next;
                this.about[name].results = [...this.about[name].results, ...response.results];
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.about[name].fetching = false;
            }
        },

        onChangeFavoriteShop(value) {
            this.about.shop.is_favorite = value;
        },

        closeShop() {
            this.about.show = false;
            this.about.links.offset = 0;
            this.about.offers.offset = 0;
            this.about.images.offset = 0;
            this.about.files.offset = 0;
        },

        // complaint

        async toComplaint() {
            const chat = this.currentRoom;
            this.complaint.shop = chat.shop;
            this.complaint.client = this.sender;
            this.complaint.show = true;
        },

        closeComplaint() {
            this.complaint.show = false;
        },
    },
};
</script>

<style>
.chat-popup {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: var(--popup-z-index);
    background-color: var(--light-c);
}
@media (min-width: 768px) {
    .chat-popup {
        width: 428px;
        height: 600px;
        max-height: calc(100vh - 36px - 60px - 14px - 36px);
        bottom: calc(36px + 60px + 14px);
        right: 36px;
        border-radius: var(--border-radius-x2);
        overflow: hidden;
    }
}
@media (max-width: 767px) {
    .chat-popup {
        left: 0;
        top: 0;
        width: 100vw;
        min-width: 320px;
        height: 100%;
    }
}

.chat-popup__main {
    flex-grow: 1;
    position: relative;
}

.chat-popup__initializing {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-popup__list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.chat-popup__header {
    width: 100%;
    padding: 12px 24px;
}
@media (max-width: 767px) {
    .chat-popup__header {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

@media (max-width: 767px) {
    .chat-popup__desktop-header {
        display: none;
    }
}

.chat-popup__mobile-header {
    display: flex;
    justify-content: space-between;
}
@media (min-width: 768px) {
    .chat-popup__mobile-header {
        display: none;
    }
}

.chat-popup__mobile-header-search-button-wrap {
    width: 24px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.chat-popup__mobile-search-header {
    width: 100%;
    display: flex;
}

.chat-popup__mobile-search-input {
    flex-grow: 1;
    margin-right: 24px;
}

.chat-popup__mobile-search-cancel-button {
    flex-shrink: 0;
    flex-grow: 0;
}

.chat-popup__mobile-header-search-icon {
    fill: var(--dark-c);
}

.chat-popup__mobile-header-search-button:hover .chat-popup__mobile-header-search-icon,
.chat-popup__mobile-header-search-button:active .chat-popup__mobile-header-search-icon {
    fill: var(--primary-color);
}

.chat-popup__mobile-header-title {
    display: flex;
    align-items: center;
    font-family: var(--f-bold);
}

.chat-popup__mobile-close-icon {
    fill: var(--font-secondary-color);
}

.chat-popup__mobile-close-button:hover .chat-popup__mobile-close-icon,
.chat-popup__mobile-close-button:active .chat-popup__mobile-close-icon {
    fill: var(--dark-c);
}

.chat-popup__list .chat-list__item {
    position: relative;
}
@media (max-width: 767px) {
    .chat-popup__list .chat-list__item {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

.chat-popup__list .chat-list__item:first-child {
    margin-top: 4px;
}

.chat-popup__list .chat-list-item-avatar__image {
    border-radius: 0;
    height: 32px;
}

.chat-popup__list .chat-list-item-avatar__online {
    top: -5px;
    right: -5px;
}

.chat-popup__typing {
    font-style: italic;
    color: var(--font-secondary-color);
}

.chat-popup__message-preview .chat-message-preview__payload {
    color: var(--dark-c);
}

.chat-popup__room {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.chat-popup__room_hidden {
    opacity: 0;
}

.chat-popup__room-header {
    display: flex;
    width: 100%;
    padding: 12px 24px;
    height: 60px;
}
@media (max-width: 767px) {
    .chat-popup__room-header {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

.chat-popup__room-search-cancel-button {
    color: var(--dark-c);
}

.chat-popup__room-search-cancel-button:hover,
.chat-popup__room-search-cancel-button:active {
    color: var(--primary-color);
}

.chat-popup__room-header-left {
    margin-right: 4px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.chat-popup__room-back-icon {
    fill: var(--dark-c);
}

.chat-popup__room-header-center {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.chat-popup__room-name-block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-popup__room-name {
    transition: color var(--transition);
}

@media (min-width: 768px) {
    .chat-popup__room-name-button.chat-popup__room-name-button_mobile {
        display: none;
    }
}

@media (max-width: 767px) {
    .chat-popup__room-name-button.chat-popup__room-name-button_desktop {
        display: none;
    }
}

.chat-popup__room-name-button:hover .chat-popup__room-name,
.chat-popup__room-name-button:active .chat-popup__room-name,
.chat-popup__room-name-button.chat-popup__room-name-button_active .chat-popup__room-name {
    color: var(--primary-color);
}

.chat-popup__room-online {
    margin-left: 8px;
    width: 10px;
    height: 10px;
    background-color: var(--accent-green);
    border-radius: 50%;
}

.chat-popup__room-status {
    font-size: var(--small-fz);
    line-height: 1.33;
    color: var(--font-secondary-color);
}

.chat-popup__room-header-right {
    margin-left: 4px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

@media (min-width: 768px) {
    .chat-popup__room-header .chat-popup__mobile-close-button {
        display: none;
    }
}

@media (max-width: 767px) {
    .chat-popup__room-header .chat-popup__room-menu-button {
        display: none;
    }
}

.chat-popup__room-menu-button {
    fill: var(--dark-c);
}

.chat-popup__room-menu-button:hover,
.chat-popup__room-menu-button.chat-popup__room-menu-button_active .u-icon {
    fill: var(--primary-color) !important;
}

@media (max-width: 767px) {
    .chat-popup__room .chat-items-wrap {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

.chat-popup__message-offer-image-wrap {
    width: 120px;
    height: 120px;
}
@media (max-width: 767px) {
    .chat-popup__message-offer-image-wrap {
        width: 80px;
        height: 80px;
    }
}

.chat-popup__message-offer-image {
    object-fit: cover;
}

.chat-popup__message-offer-name {
    display: block;
}

.chat-popup__message-offer-name:hover,
.chat-popup__message-offer-name:active {
    color: var(--primary-color);
}

.chat-popup__message-offer-actions-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
}
@media (max-width: 767px) {
    .chat-popup__message-offer-actions-wrap {
        top: 0;
        bottom: auto;
    }
}

.chat-popup__empty-chat {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chat-popup__empty-chat-icon {
    margin-bottom: 36px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
}

.chat-popup__empty-chat-text-wrap {
    width: 296px;
    text-align: center;
}

.chat-popup__room .chat-message {
    max-width: calc(360px + 20px);
}

.chat-popup__room .chat-message.chat-message_own {
    margin-left: auto;
}

@media (max-width: 375px) {
    .chat-popup__room .chat-message.chat-message_accent::before {
        width: calc(100% + 18px + 18px);
    }
}

.chat-popup__room .chat-message.chat-message_own .chat-message__main-block {
    width: 100%;
}

.chat-popup__room .chat-message.chat-message_own .chat-message__avatar-block {
    display: none;
}

.chat-popup__room .chat-message.chat-message_own .chat-message__sender-block {
    display: none;
}

.chat-popup__room .chat-message__cloud.chat-message__cloud_own {
    border-radius: var(--border-radius-x2) var(--border-radius-x2) 0 var(--border-radius-x2);
}

.chat-popup__room .chat-message__image {
    max-height: 360px;
}

.chat-popup__room .chat-message__avatar-block + .chat-message__main-block .chat-message__image {
    max-height: 312px;
}

@media (max-width: 767px) {
    .chat-popup__room .chat-private-room__footer {
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .chat-popup__room .chat-private-room__footer {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}


@media (max-width: 767px) {
    .chat-popup__room .chat-private-room__input {
        padding-top: 14px;
    }

    .chat-popup__room .chat-private-room__input-overflow-box {
        max-height: calc(100px + 14px);
    }
}

.chat-popup__about {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--light-c);
}

.chat-popup__complaint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--light-c);
}

.chat-popup__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-c);
}
</style>