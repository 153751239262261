import capitalize from '@ui/utils/capitalize.js';
import isNumber from '@ui/utils/isNumber.js';


const defaultOptions = {
    offset: 4,
    flip: null,
    shift: null,
    margin: 0,
    placement: 'bottom',
};

export const placements = [
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-end',
    'left',
    'left-start',
];

const getFlippedPlacement = placement => {
    const flippedPlacements = {
        'top-start': 'bottom-start',
        'top': 'bottom',
        'top-end': 'bottom-end',
        'right-start': 'left-start',
        'right': 'left',
        'right-end': 'left-end',
        'bottom-end': 'top-end',
        'bottom': 'top',
        'bottom-start': 'top-start',
        'left-end': 'right-end',
        'left': 'right',
        'left-start': 'right-start',
    };

    return flippedPlacements[placement];
};

const getDimensions = el => {
    const { x, y, width, height } = el.getBoundingClientRect();
    return { x, y, width, height };
};

export const computePosition = (trigger, content, options = {}) => {
    const opt = Object.assign({}, defaultOptions, options);
    const shiftNumber = isNumber(opt.shift) ? opt.shift : 0;
    const flipNumber = isNumber(opt.flip) ? opt.flip : 0;
    const dimensions = {
        trigger: getDimensions(trigger),
        content: getDimensions(content),
    };

    const shift = 0;
    const y = dimensions.content.y;
    const x = dimensions.content.x;
    const placement = opt.placement;
    const obj = { y, x, shift, placement, dimensions };
    const container = document.documentElement;
    const isTop = placement.includes('top');
    const isBottom = placement.includes('bottom');
    const isStart = placement.includes('start');
    const isEnd = placement.includes('end');
    const isX = isTop || isBottom;
    const isInverted = isTop || placement.includes('left');
    const mainPropName = isX ? 'width' : 'height';
    const crossPropName = isX ? 'height' : 'width';
    const mainCoordName = isX ? 'x' : 'y';
    const crossCoordName = isX ? 'y' : 'x';
    const contentMainPropValue = dimensions.content[mainPropName];
    const contentCrossPropValue = dimensions.content[crossPropName];
    const triggerMainPropValue = dimensions.trigger[mainPropName];
    const triggerCrossPropValue = dimensions.trigger[crossPropName];
    const triggerMainCoordValue = dimensions.trigger[mainCoordName];
    const triggerCrossCoordValue = dimensions.trigger[crossCoordName];
    const windowMainPropValue = container['client' + capitalize(mainPropName)];
    const windowCrossPropValue = container['client' + capitalize(crossPropName)];
    const mainScroll = window['scroll' + mainCoordName.toUpperCase()];
    const crossScroll = window['scroll' + crossCoordName.toUpperCase()];
    const minMainCoordValue = mainScroll + opt.margin;
    const maxMainCoordValue = windowMainPropValue - contentMainPropValue + mainScroll - opt.margin;
    const minCrossCoordValue = flipNumber + crossScroll;
    const maxCrossCoordValue = windowCrossPropValue - contentCrossPropValue - flipNumber + crossScroll;

    obj[mainCoordName] = mainScroll + shiftNumber;
    obj[crossCoordName] = crossScroll;
    obj['flipped' + crossCoordName] = crossScroll;

    if (isStart) {
        obj[mainCoordName] += triggerMainCoordValue;
    }
    else if (isEnd) {
        obj[mainCoordName] += triggerMainCoordValue - contentMainPropValue + triggerMainPropValue;
    }
    else {
        obj[mainCoordName] += triggerMainCoordValue + (triggerMainPropValue / 2) - (contentMainPropValue / 2);
    }

    obj[crossCoordName] += isInverted
        ? triggerCrossCoordValue - contentCrossPropValue - opt.offset
        : triggerCrossCoordValue + triggerCrossPropValue + opt.offset;
    obj['flipped' + crossCoordName] += isInverted
        ? triggerCrossCoordValue + triggerCrossPropValue + opt.offset
        : triggerCrossCoordValue - contentCrossPropValue - opt.offset;

    // shift
    if (opt.shift != null) {
        if (obj[mainCoordName] < minMainCoordValue) {
            obj.shift = minMainCoordValue - obj[mainCoordName];
            obj[mainCoordName] = minMainCoordValue;
        }
        else if (obj[mainCoordName] > maxMainCoordValue) {
            obj.shift = maxMainCoordValue - obj[mainCoordName];
            obj[mainCoordName] = maxMainCoordValue;
        }
    }

    // flip
    if (opt.flip !== false) {
        if (isInverted) {
            if (
                obj[crossCoordName] < minCrossCoordValue &&
                obj['flipped' + crossCoordName] < maxCrossCoordValue
            ) {
                obj[crossCoordName] = obj['flipped' + crossCoordName];
                obj.placement = getFlippedPlacement(placement);
            }
        }
        else {
            if (
                obj[crossCoordName] > maxCrossCoordValue &&
                obj['flipped' + crossCoordName] >= minCrossCoordValue
            ) {
                obj[crossCoordName] = obj['flipped' + crossCoordName];
                obj.placement = getFlippedPlacement(placement);
            }
        }
    }

    return obj;
};