<template>
    <div
        :class="[
            'post-editor__block',
            'post-editor__block_' + widget.type,
        ]"
    >
        <div
            :class="[
                widget.marginTop ? 'indent-wrap_mt' + widget.marginTop : '',
                widget.marginBottom ? 'indent-wrap_mb' + widget.marginBottom : '',
                widget.paddingTop ? 'indent-wrap_pt' + widget.paddingTop : '',
                widget.paddingBottom ? 'indent-wrap_pb' + widget.paddingBottom : '',
            ]"
            class="indent-wrap"
        >
            <figure class="video-wrap">
                <button
                    class="play-btn"
                    aria-label="Смотреть видео"
                    @click="popup"
                >
                    <img
                        v-if="videoCover"
                        :src="videoCover"
                        alt="Обложка видео"
                        class="video-cover"
                    >
                </button>

                <figcaption
                    v-if="widget.caption"
                    :class="widget.align"
                    class="caption"
                >
                    {{ widget.caption }}
                </figcaption>
            </figure>
        </div>
    </div>
</template>

<script>
import VideoView from '../popups/VideoView.vue';

export default {
    name: "PostEditorVideo",

    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    computed: {
        videoCover() {
            const YTRegExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const VimeoRegExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
            const YTMatch = this.widget.link.match(YTRegExp);
            const VimeoMatch = this.widget.link.match(VimeoRegExp);

            if (YTMatch) {
                return `https://img.youtube.com/vi/${YTMatch[2]}/maxresdefault.jpg`;
            }
            else if (VimeoMatch) {}
        },
    },

    methods: {
        popup() {
            const videoUrl = this.widget.link;
            const props = { videoUrl };
            const options = { props };
            this.$popup(VideoView, options);
        },
    },
};
</script>

<style scoped>
.caption {
    margin-top: 12px;
    color: var(--font-secondary-color);
}

.play-btn {
    position: relative;
    display: block;
    width: 100%;
    min-height: 160px;
    background-color: var(--light-bg);
}

.play-btn::before,
.play-btn::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.play-btn::before {
    left: calc(50% + 2px);
    z-index: 2;
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-left: 13px solid var(--light-c);
    border-bottom: 9px solid transparent;
}

.play-btn::after {
    left: 50%;
    width: 60px;
    height: 60px;
    background-color: rgba(54, 64, 77, 0.5);
    border-radius: 50%;
    transition: background-color var(--transition);
}

.play-btn:hover::after,
.play-btn:focus::after {
    background-color: var(--primary-color);
}
</style>