<template>
    <ul
        ref="tabs"
        class="u-tabs__list"
    >
        <li
            v-for="item in items"
            ref="tabItem"
            :key="item.name"
            class="u-tabs__item"
        >
            <component
                :is="itemComponent"
                v-bind="getAttrs(item)"
                class="u-tabs__button"
                :class="{
                    'u-tabs__button_active': isActiveClass(item),
                    'u-tabs__button_disabled': item.disabled,
                    'u-tabs__button_invalid': item.invalid,
                }"
                @click="onClick(item)"
            >
                <span class="u-tabs__label-wrap">
                    <span class="u-tabs__label">{{ item.label }}</span><span
                        v-if="item.count"
                        class="u-tabs__count"
                    >{{ item.count }}</span>
                </span>
            </component>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'UTabs',

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        items: Array,
        value: Object,
    },

    data() {
        return {
            innerValue: null,
        };
    },

    computed: {
        mode() {
            return this.items.some(item => !!item.to) ? 'router' : 'tabs';
        },

        isRouter() {
            return this.mode === 'router';
        },

        itemComponent() {
            return this.isRouter ? 'RouterLink' : 'button';
        },
    },

    watch: {
        value: {
            handler(value) {
                if (value !== this.innerValue) {
                    this.innerValue = value;
                }
            },

            immediate: true,
        },

        innerValue: {
            handler(value) {
                if (value !== this.value) {
                    this.$emit('change', value);
                }
            },
        },
    },

    methods: {
        getAttrs(item) {
            return this.isRouter ? {
                to: item.to,
            } : {
                type: 'button',
            };
        },

        isActiveClass(item) {
            return this.isRouter
                ? item.to.name === this.$route.name
                : item.name === (this.innerValue || {}).name;
        },

        onClick(item) {
            this.innerValue = item;
            this.$emit('change', this.innerValue);
        },
    },
};
</script>

<style>
.u-tabs__list {
    position: relative;
    display: flex;
    overflow-x: auto;
}

.u-tabs__list::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    border-radius: 10px;
    background-color: var(--border-light-c);
}

.u-tabs__item {
    height: 34px;
    display: flex;
    white-space: nowrap;
}

@media (min-width: 1024px) {
    .u-tabs__item:not(:last-child) {
        margin-right: 36px;
    }
}
@media (max-width: 1023px) and (min-width: 768px) {
    .u-tabs__item:not(:last-child) {
        margin-right: 24px;
    }
}
@media (max-width: 767px) {
    .u-tabs__item:not(:last-child) {
        margin-right: 20px;
    }
}

.u-tabs__button {
    align-self: flex-start;
    display: flex;
    height: 100%;
    position: relative;
    font-family: var(--f-bold);
    color: var(--font-secondary-color);
    cursor: pointer;
    transition: color var(--transition);
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}
.u-tabs__button:hover:not(.u-tabs__button_disabled),
.u-tabs__button.u-tabs__button_active:not(.u-tabs__button_disabled) {
    color: var(--dark-c);
}
.u-tabs__button.u-tabs__button_disabled {
    cursor: default;
    pointer-events: none;
}
.u-tabs__button.u-tabs__button_disabled {
    color: var(--font-secondary-light-color);
}
.u-tabs__button.u-tabs__button_invalid {
    color: var(--primary-color);
}
.u-tabs__button.u-tabs__button_invalid:hover {
    color: var(--primary-dark-color);
}

.u-tabs__button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: transparent;
    transition: background-color var(--transition);
    z-index: 1;
}

.u-tabs__button.u-tabs__button_active:after {
    background-color: var(--primary-color);
}

.u-tabs__label-wrap {
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.u-tabs__count {
    margin-left: 8px;
    font-size: var(--small-fz);
}
</style>