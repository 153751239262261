var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header"},[_c('div',{staticClass:"header_mobile container"},[_c('ButtonIcon',{staticClass:"mobile-menu-toggler",attrs:{"icon":{
                name: _vm.isMobileMenuOpen ? 'cross' : 'list',
                big: true,
            },"small":"","secondary":false,"aria-label":_vm.isMobileMenuOpen ? 'Закрыть мобильное меню' : 'Открыть мобильное меню'},on:{"click":_vm.toggleMobileMenu}}),_vm._v(" "),_c('UbibiLogo',{staticClass:"header__ubibi-logo",attrs:{"base":""}}),_vm._v(" "),_c('RouterLink',{staticClass:"cart-btn",attrs:{"to":{
                name: 'cart',
            },"aria-label":"Перейти в корзину"}},[_c('UIcon',{staticClass:"cart-btn__icon",attrs:{"name":"cart-stroke","big":""}}),_vm._v(" "),_c('UCircleLabelCounter',{staticClass:"cart-btn__counter",attrs:{"count":_vm.cartTotalCount}})],1)],1),_vm._v(" "),_c('div',{staticClass:"header_desktop container"},[_c('div',{staticClass:"header__city-static-pages-nav-wrap"},[_c('button',{staticClass:"city link-primary-hover",on:{"click":_vm.showChangeCityPopup}},[_c('UIcon',{staticClass:"city__icon",attrs:{"name":"place-16","small":""}}),_vm._v(" "),_c('span',{staticClass:"city__value"},[_vm._v("\n                    "+_vm._s(_vm.currentCity.name)+"\n                ")])],1),_vm._v(" "),_c('nav',{staticClass:"static-pages-nav"},[_c('ul',{staticClass:"static-pages-nav__list"},_vm._l((_vm.staticPagesMenu),function(link){return _c('li',{staticClass:"static-pages-nav__item"},[_c('RouterLink',{staticClass:"static-pages-nav__link link-primary-hover",attrs:{"to":link.to}},[_vm._v("\n                            "+_vm._s(link.title)+"\n                        ")])],1)}),0)])]),_vm._v(" "),_c('div',{staticClass:"header__main"},[_c('div',{staticClass:"medium-menu"},[_c('ButtonIcon',{staticClass:"medium-menu-toggler",attrs:{"icon":{
                        name: 'list',
                        big: true,
                    },"small":"","secondary":false,"aria-label":_vm.isMobileMenuOpen ? 'Закрыть мобильное меню' : 'Открыть мобильное меню'},on:{"click":_vm.toggleMobileMenu}})],1),_vm._v(" "),_c('UbibiLogo',{staticClass:"header__ubibi-logo",attrs:{"base":""}}),_vm._v(" "),_c('nav',{staticClass:"desktop-menu"},[_c('ul',{staticClass:"site-nav__list"},[_c('li',{staticClass:"site-nav__item"},[_c('RouterLink',{staticClass:"site-nav__link link-primary-hover",attrs:{"to":{
                                name: 'market',
                            }},nativeOn:{"click":function($event){return _vm.handleYandexMetrikaGoal('header_avtozapchast')}}},[_vm._v("\n                            Запчасти и автотовары\n                        ")])],1),_vm._v(" "),_c('li',{staticClass:"site-nav__item"},[_c('RouterLink',{staticClass:"site-nav__link link-primary-hover",attrs:{"to":{
                                name: 'insurance-osago',
                            }},nativeOn:{"click":function($event){return _vm.handleYandexMetrikaGoal('header_osago')}}},[_vm._v("\n                            Страхование ОСАГО\n                        ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"all-user-actions"},[_c('OrdersPreview',{staticClass:"mr-4"}),_vm._v(" "),_c('FavoritePreview',{staticClass:"mr-4"}),_vm._v(" "),_c('CartPreview')],1),_vm._v(" "),(_vm.isAuthorized)?_c('div',{staticClass:"auth-user-actions"},[_c('NotificationsPreview',{staticClass:"mr-4",attrs:{"unreadCount":_vm.unreadNotificationsCount}}),_vm._v(" "),_c('UserBar',{staticClass:"user-bar"})],1):_c('ButtonBlock',{staticClass:"login-btn",attrs:{"secondary":"","low":""},on:{"click":_vm.openSignInPopup}},[_vm._v("\n                Войти\n            ")])],1)]),_vm._v(" "),_c('Observer',{on:{"intersect":_vm.intersected}},[(_vm.showSearchSection)?_c('div',{staticClass:"header__catalog-wrap container"},[_c('div',{staticClass:"catalog-search"},[_c('ButtonBlock',{staticClass:"catalog-btn",attrs:{"id":"desktopCatalogTrigger","to":{
                        name: 'market-products-catalog'
                    },"primary":""}},[_vm._v("\n                    Каталог\n                ")]),_vm._v(" "),_c('SearchWidget',{staticClass:"flex-grow-1"})],1),_vm._v(" "),(_vm.showTopCategories)?_c('div',{staticClass:"top-categories-block"},[(_vm.canTopCategoriesScrollLeft)?_c('div',{staticClass:"top-categories__arrow_prev-wrap",on:{"mouseenter":function($event){return _vm.startTopCategoriesScroll('left')},"mouseleave":_vm.stopTopCategoriesScroll}},[_c('RoundArrowBtn',{staticClass:"top-categories__arrow top-categories__arrow_prev",attrs:{"direction":"left","size":"20"}})],1):_vm._e(),_vm._v(" "),_c('nav',{staticClass:"top-categories top-categories_outer"},[_c('ul',{ref:"topCategoriesList",staticClass:"top-categories__list",on:{"scroll":_vm.checkTopCategoriesScroll}},_vm._l((_vm.topCategories),function(link){return _c('li',{staticClass:"top-categories__item"},[_c('RouterLink',{staticClass:"top-categories__link link-primary-hover",attrs:{"to":link.to}},[_vm._v("\n                                "+_vm._s(link.title)+"\n                            ")])],1)}),0)]),_vm._v(" "),(_vm.canTopCategoriesScrollRight)?_c('div',{staticClass:"top-categories__arrow_next-wrap",on:{"mouseenter":function($event){return _vm.startTopCategoriesScroll('right')},"mouseleave":_vm.stopTopCategoriesScroll}},[_c('RoundArrowBtn',{staticClass:"top-categories__arrow top-categories__arrow_next",attrs:{"direction":"right","size":"24"}})],1):_vm._e()]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('Portal',{attrs:{"to":"sticky-holder","order":1}},[(_vm.showSearchSection)?_c('div',{ref:"headerStickySection",staticClass:"header__sticky sticky-sections-holder__section",class:{
                'sticky-sections-holder__section_visible': _vm.isSearchSectionSticky,
            },attrs:{"id":"header-sticky-section"}},[_c('div',{staticClass:"header-sticky-container container"},[_c('ButtonBlock',{staticClass:"catalog-btn",attrs:{"id":"desktopCatalogTriggerSticky","to":{
                        name: 'market-products-catalog'
                    },"primary":""}},[_vm._v("\n                    Каталог\n                ")]),_vm._v(" "),_c('SearchWidget',{staticClass:"flex-grow-1"}),_vm._v(" "),_c('div',{staticClass:"all-user-actions"},[_c('OrdersPreview',{staticClass:"hidden-l mr-4",attrs:{"noTriggerText":""}}),_vm._v(" "),_c('FavoritePreview',{staticClass:"hidden-l mr-4",attrs:{"noTriggerText":""}}),_vm._v(" "),_c('CartPreview',{attrs:{"noTriggerText":""}})],1),_vm._v(" "),(_vm.isAuthorized)?_c('div',{staticClass:"auth-user-actions"},[_c('NotificationsPreview',{staticClass:"mr-4",attrs:{"unreadCount":_vm.unreadNotificationsCount}}),_vm._v(" "),_c('UserBar',{staticClass:"user-bar"})],1):_vm._e()],1)]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"mobile-menu-wrap"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobileMenuOpen),expression:"isMobileMenuOpen"}],staticClass:"mobile-menu-wrap"},[_c('div',{staticClass:"mobile-menu__backdrop",on:{"click":_vm.hideMobileMenu}}),_vm._v(" "),_c('div',{staticClass:"mobile-menu"},[_c('div',{staticClass:"mobile-menu__section"},[(_vm.isAuthorized)?_c('RouterLink',{staticClass:"flex align-items-center mb-20",attrs:{"to":{
                            name: 'account-settings'
                        }}},[_c('UserAvatar',{staticClass:"mr-12",attrs:{"size":"m"}}),_vm._v(" "),_c('div',[_c('p',{staticClass:"text_bold mb-4"},[_vm._v("\n                                "+_vm._s(_vm.profile.first_name)+"\n                            ")]),_vm._v(" "),_c('p',{staticClass:"text_small-fz text_semi-bold text_secondary"},[_vm._v("\n                                "+_vm._s(_vm.profile.phone)+"\n                            ")])])],1):_vm._e(),_vm._v(" "),(!_vm.isAuthorized)?_c('div',{staticClass:"registration-banner",on:{"click":function($event){_vm.openSignInPopup(); _vm.handleYandexMetrikaGoal('header_enter')}}},[_c('h4',{staticClass:"text_bold mb-4"},[_vm._v("\n                            Войдите или зарегистрируйтесь\n                        ")]),_vm._v(" "),_c('p',{staticClass:"text_base-lh"},[_vm._v("\n                            Оформляйте заказы, оставляйте отзывы и общайтесь в чате.\n                        ")]),_vm._v(" "),_c('ButtonBlock',{staticClass:"mt-12",attrs:{"primary":"","low":"","pseudo":""}},[_vm._v("\n                            Войти\n                        ")])],1):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"site-nav__list"},[_c('li',{staticClass:"site-nav__item"},[_c('RouterLink',{staticClass:"site-nav__link link-primary-hover",attrs:{"to":{
                                    name: 'market',
                                }},nativeOn:{"click":function($event){return _vm.handleYandexMetrikaGoal('header_avtozapchast')}}},[_vm._v("\n                                Запчасти и автотовары\n                                "),_c('UIcon',{attrs:{"name":"arrow-right","secondary":"","small":""}})],1)],1),_vm._v(" "),_c('li',{staticClass:"site-nav__item"},[_c('RouterLink',{staticClass:"site-nav__link link-primary-hover",attrs:{"to":{
                                    name: 'insurance-osago',
                                }},nativeOn:{"click":function($event){return _vm.handleYandexMetrikaGoal('header_osago')}}},[_vm._v("\n                                Страхование ОСАГО\n                                "),_c('UIcon',{attrs:{"name":"arrow-right","secondary":"","small":""}})],1)],1),_vm._v(" "),_c('li',{staticClass:"site-nav__item"},[_c('RouterLink',{staticClass:"mobile-menu__link link-primary-hover",attrs:{"to":{
                                    name: 'market-products-catalog'
                                }}},[_c('UIcon',{staticClass:"mobile-menu__link-icon",attrs:{"name":"list-search","big":""}}),_vm._v("\n\n                                Каталог товаров\n                            ")],1)],1)])],1),_vm._v(" "),_c('div',{staticClass:"mobile-menu__section"},[_c('button',{staticClass:"mobile-menu__link link-primary-hover",on:{"click":_vm.showChangeCityPopup}},[_c('UIcon',{staticClass:"mobile-menu__link-icon",attrs:{"name":"place-24","big":""}}),_vm._v("\n                        "+_vm._s(_vm.currentCity.name)+"\n                    ")],1)]),_vm._v(" "),(_vm.isAuthorized)?_c('div',{staticClass:"mobile-menu__section"},[_c('ul',{staticClass:"user-menu"},[_vm._l((_vm.userMenu),function(item){return _c('li',{key:item.codename,staticClass:"user-menu__item"},[_c('RouterLink',{staticClass:"mobile-menu__link link-primary-hover",attrs:{"to":{
                                    name: item.codename
                                }}},[_c('UIcon',{staticClass:"mobile-menu__link-icon",attrs:{"name":item.icon,"big":""}}),_vm._v("\n\n                                "+_vm._s(item.title)+"\n\n                                "),(item.codename === 'account-orders-active' || item.codename === 'account-notifications')?_c('UCircleLabelCounter',{staticClass:"mobile-menu__link-counter",attrs:{"count":item.codename === 'account-orders-active' ? _vm.activeOrdersTotalCount : _vm.unreadNotificationsCount}}):_vm._e()],1)],1)}),_vm._v(" "),_c('li',{staticClass:"user-menu__item"},[_c('button',{staticClass:"mobile-menu__link link-primary-hover",on:{"click":_vm.logout}},[_c('UIcon',{staticClass:"mobile-menu__link-icon",attrs:{"name":"logout","big":""}}),_vm._v("\n\n                                Выйти\n                            ")],1)])],2)]):_vm._e()])])]),_vm._v(" "),_c('Catalog',{attrs:{"hideBackdrop":_vm.isMobileMenuOpen,"isSticky":_vm.isSearchSectionSticky},on:{"close":function($event){_vm.catalogIsOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }