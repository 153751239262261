const checkRequiredError = errors => {
    return Object.keys(errors).reduce((acc, key) => {
        if (acc) {
            return acc;
        }
        else {
            const err = errors[key];

            if (err && Array.isArray(err)) {
                const isError = err.every(obj => obj.code && obj.message);

                if (isError) {
                    acc = err.some(obj => obj.code === 'required');
                }
                else {
                    acc = err.some(e => checkRequiredError(e));
                }
            }
            else if (typeof err === 'object') {
                acc = checkRequiredError(err);
            }

            return acc;
        }
    }, false);
};

export default {
    methods: {
        $handleFormErrors(errors) {
            const hasRequiredError = checkRequiredError(errors);

            if (hasRequiredError) {
                this.$error('Заполнены не все обязательные поля');
            }
            else {
                this.$error('Одно или несколько полей содержат ошибки');
            }
        },
    },
};