<template>
    <article class="market-item-column layer-1">
        <MarketItemCover
            :item="item"
            :modelName="modelName"
            coverCodename="grid"
            class="market-item-column__cover-wrap"
        ></MarketItemCover>

        <div class="market-item-column__info">
            <div class="market-item-column__product-info">
                <MarketItemName
                    :id="item.id || item.product_id || item.part_product_id"
                    :modelName="modelName"
                    :name="item.name"
                ></MarketItemName>

                <div
                    v-if="modelName !== 'product'"
                    class="mt-4"
                    :class="[withSchemeLink && 'market-item-column__sku-scheme-wrap']"
                >
                    <ButtonText
                        v-if="withSchemeLink"
                        dashed
                        secondary
                        dark
                        class="market-item-column__scheme-link"
                        @click="showPartSchemes"
                    >
                        На схеме
                    </ButtonText>
                </div>

                <MarketItemRatingReviewsLink
                    :item="item"
                    :modelName="modelName"
                    class="market-item-column__rating-wrap"
                ></MarketItemRatingReviewsLink>

                <BrandShortInfo
                    v-if="modelName !== 'contract_part'"
                    :brand="item.brand"
                    row
                    class="market-item-column__brand-short-info mt-8"
                ></BrandShortInfo>
            </div>

            <div class="market-item-column__bottom">
                <div class="market-item-column__price-wrap">
                    <span class="market-item-column__price">{{ item.price | price }}</span>
                    <span
                        v-if="modelName !== 'contract_part' && item.offers_amount === 1"
                        class="text_red text_semi-bold text_small-fz"
                    >Последний товар</span>
                </div>
                <div class="market-item-column__points-wrap">
                    <template v-if="item.delivery && item.in_stock">
                        <div class="market-item-column__points">
                            <ButtonText
                                secondary
                                dark
                                dashed
                                @click="handlePointsClick"
                            >
                                {{ item.delivery.currentCount | plural(['пункт', 'пункта', 'пунктов']) }} выдачи
                            </ButtonText>
                        </div>
                        <div
                            v-if="item.min_delivery_date"
                            class="market-item-column__delivery"
                        >
                            <span class="text_secondary">Получить:</span> {{ item.min_delivery_date }}
                        </div>
                    </template>

                    <span
                        v-else
                        class="text_semi-bold text_secondary"
                    >Нет в наличии</span>
                </div>

                <div class="market-item-column__actions-wrap">
                    <AddCartBtn
                        :offer="{ id: item.offer_id, amount: item.amount }"
                        :item="item"
                        :modelName="modelName"
                        :disabled="!item.in_stock || !item.offer_id"
                        class="market-item-column__action-btn"
                        @addToCart="$emit('changeCart')"
                        @deleteFromCart="$emit('deleteFromCart')"
                    ></AddCartBtn>

                    <LikeBtn
                        :id="item.product_id || item.part_product_id"
                        :apiName="modelName"
                        :value="item.is_favorite"
                        class="market-item-column__like-btn"
                        @changeFavorite="onChangeFavorite"
                    ></LikeBtn>

                    <ChatBtn
                        :id="(item.shop || {}).id"
                        class="market-item-column__chat-btn"
                    ></ChatBtn>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import { plural } from '@ui/filters/index.js';
import MarketItemName from './MarketItemName.vue';
import MarketItemRatingReviewsLink from './MarketItemRatingReviewsLink.vue';
import BrandShortInfo from './BrandShortInfo.vue';
import LikeBtn from './project-buttons/LikeBtn.vue';
import MarketItemCover from './MarketItemCover.vue';
import AddCartBtn from './project-buttons/AddCartBtn.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ChatBtn from '@/components/project-buttons/ChatBtn.vue';
const DeliveryOfficesPopup = () => import('@/components/popups/DeliveryOfficesPopup.vue');


export default {
    name: 'MarketItemColumn',

    components: {
        ChatBtn,
        ButtonText,
        AddCartBtn,
        MarketItemCover,
        MarketItemRatingReviewsLink,
        BrandShortInfo,
        MarketItemName,
        LikeBtn,
    },

    filters: {
        plural,
    },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },

        withSchemeLink: Boolean,
    },

    methods: {
        onChangeFavorite(value) {
            this.$emit('changeFavorite', value);
        },

        showPartSchemes() {
            this.$emit('callShowPartSchemesPopup');
        },

        handlePointsClick() {
            const props = {
                item: this.item,
                choosable: false,
            };
            const options = { props };
            this.$popup(DeliveryOfficesPopup, options);
        },
    },
};
</script>

<style scoped>
.market-item-column {
    position: relative;
    transition: box-shadow var(--transition);
}
@media (min-width: 768px) {
    .market-item-column {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

@media (min-width: 768px) {
    .market-item-column__cover-wrap {
        margin: var(--gap-micro) var(--gap-micro) 0;
    }
}
@media (max-width: 767px) {
    .market-item-column__cover-wrap {
        position: absolute;
        left: var(--gap-micro);
        top: var(--gap-micro);
        width: 64px;
        height: 64px;
    }
}

.market-item-column__brand-short-info >>> .logo {
    width: 36px;
    height: 24px;
}

.market-item-column__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.market-item-column__product-info {
    padding: var(--gap-micro);
    border-bottom: 1px solid var(--border-light-c);
    flex-grow: 1;
    flex-shrink: 0;
}
@media (max-width: 767px) {
    .market-item-column__product-info {
        min-height: 92px;
        padding-bottom: 12px;
        padding-left: calc(var(--gap-micro) + 64px + 12px);
    }
}

.market-item-column__rating-wrap {
    margin-top: 8px;
}

.market-item-column__bottom {
    padding: var(--gap-micro);
}

.market-item-column__price-wrap {

}

.market-item-column__price {
    display: block;
    font-size: var(--h3-fz);
    font-family: var(--f-bold);
}

.market-item-column__points-wrap {
    margin-top: 16px;
}

.market-item-column__delivery {
    margin-top: 4px;
}

.market-item-column__actions-wrap {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.market-item-column__action-btn {
    margin-right: 12px;
}

.market-item-column__like-btn {
    margin-right: 12px;
}
</style>
