<template>
    <div
        v-if="isOpen"
        ref="desktopCatalog"
        v-on-clickaway="close"
        class="catalog"
    >
        <ol
            v-for="(section, sectionIndex) in catalog"
            :key="'catalog-section-' + sectionIndex"
            :ref="'catalog-section-' + sectionIndex"
            class="catalog__section"
            @mouseleave="onSectionMouseleave"
        >
            <li
                v-for="category in section"
                :ref="'category-' + category.slug"
                class="catalog__item"
                @mouseenter="onSelectCategory(category, sectionIndex)"
            >
                <RouterLink
                    :to="category.to"
                    class="catalog__link"
                    :class="{
                        'catalog__link_active': category.slug === path[sectionIndex+1],
                    }"
                >
                    <UIcon
                        v-if="category.icon"
                        :name="category.icon"
                        big
                        class="mr-12"
                    ></UIcon>

                    <span class="catalog__link-text">
                        {{ category.title }}
                    </span>

                    <UIcon
                        v-if="category.children && category.children.length && sectionIndex < 3"
                        name="arrow-right"
                        small
                        class="catalog__link-arrow"
                    ></UIcon>
                </RouterLink>
            </li>
        </ol>
        <div
            v-show="loading && catalog.length < 4"
            ref="catalog-section-loading"
            class="catalog__section catalog__section_loading"
        >
            <USpinner big></USpinner>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'DesktopUI',

    components: {
        UIcon,
        USpinner,
    },

    mixins: [clickaway],

    props: {
        isOpen: Boolean,
        isSticky: Boolean,
        catalog: {
            type: Array,
            default: () => [],
        },
        path: {
            type: Array,
            default: () => [],
        },
        loading: Boolean,
    },

    watch: {
        isOpen(value) {
            if (value) {
                this.$nextTick(() => {
                    this.setCatalogPosition();
                });
            }
        },

        isSticky(value) {
            this.$nextTick(() => {
                if (value){
                    setTimeout(() => {
                        this.setCatalogPosition();
                    }, 210);
                }
                else {
                    this.setCatalogPosition();
                }
            });
        },

        catalog: {
            handler() {
                this.$nextTick(() => {
                    this.setSectionPosition();
                });
            },
            deep: true,
        },
    },

    methods: {
        close() {
            if (window.innerWidth > 1039) this.$emit('close');
        },

        onSelectCategory(category) {
            this.$emit('selectCategory', category);
        },

        onSectionMouseleave(category) {
            this.$emit('sectionMouseleave', category);
        },

        setCatalogPosition() {
            if (this.isOpen) {
                const OFFSET = this.isSticky ? 20 : 8;
                const catalog = this.$refs.desktopCatalog;
                const triggerSticky = document.getElementById('desktopCatalogTriggerSticky');
                const trigger = document.getElementById('desktopCatalogTrigger');
                const rectTrigger = this.isSticky ? triggerSticky.getBoundingClientRect() : trigger.getBoundingClientRect();

                let y;
                if (this.isSticky) {
                    y = rectTrigger.y + rectTrigger.height + OFFSET;
                }
                else {
                    y = trigger.offsetTop + rectTrigger.height + OFFSET;
                }
                let x = rectTrigger.x;

                catalog.style.position = this.isSticky ? 'fixed' : 'absolute';
                catalog.style.left = `${ x }px`;
                catalog.style.top = `${ y }px`;
            }
        },

        setSectionPosition() {
            this.path.forEach((category, index) => {
                const newSection = this.$refs[`catalog-section-${ index }`];
                const loadingSection = this.$refs['catalog-section-loading'];
                const categoryEl = this.$refs[`category-${ category }`];

                if (categoryEl && categoryEl[0]) {
                    const categoryElSection = categoryEl[0].closest('ol');
                    if (newSection && newSection[0]) {
                        newSection[0].style.top = `${ categoryElSection.offsetTop + categoryEl[0].offsetTop - 4 }px`;
                    }
                    loadingSection.style.top = `${ categoryElSection.offsetTop + categoryEl[0].offsetTop - 4 }px`;
                }
            });
        },
    },
};
</script>

<style scoped>
.catalog {
    position: absolute;
    /*position: fixed;*/
    z-index: 9;
    display: flex;
    align-items: flex-start;
    max-width: var(--container);
    filter: drop-shadow(40px 40px 60px rgba(31, 31, 31, 0.07)) drop-shadow(0px 0px 20px rgba(31, 31, 31, 0.1));
}

.catalog__section {
    position: relative;
    max-height: 90vh;
    padding: 4px;
    border-radius: var(--border-radius);
    background-color: var(--light-c);
    overflow: auto;
}
@media (min-width: 1281px) {
    .catalog__section {
        width: 280px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .catalog__section {
        width: 239px;
    }
}

.catalog__section_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 280px;
}

.catalog__section + .catalog__section {
    margin-left: -4px;
}

.catalog__link {
    display: flex;
    align-items: center;
    min-height: 44px;
    padding: 10px 12px;
    font-family: var(--f-semi-bold);
    border-radius: var(--border-radius);
    transition: background-color var(--transition);
}
.catalog__link_active,
.catalog__link:hover,
.catalog__link:focus {
    background-color: var(--lightest-bg);
}

.catalog__link-text {
    flex-grow: 1;
}

.catalog__link-arrow {
    margin-left: 12px;
    fill: var(--font-secondary-light-color);
}
.catalog__link_active .catalog__link-arrow,
.catalog__link:hover .catalog__link-arrow,
.catalog__link:focus .catalog__link-arrow {
    fill: var(--dark-c);
}
</style>