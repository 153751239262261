var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.products.length)?_c('section',{staticClass:"slider-section"},[_c('h2',{staticClass:"h2 h2_block slider-section__title"},[_vm._v("\n        Другие товары\n    ")]),_vm._v(" "),_c('VueSlickCarousel',{staticClass:"slider",attrs:{"arrows":true,"dots":false,"infinite":false,"slidesToShow":5,"slidesToScroll":5,"responsive":[
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    variableWidth: true,
                }
            },
            {
                breakpoint: 1039,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    variableWidth: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            } ]},scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('RoundArrowBtn',{staticClass:"slider-nav__arrow slider-nav__arrow_prev",attrs:{"direction":"left","size":"36","shadow":""}})]},proxy:true},{key:"nextArrow",fn:function(){return [_c('RoundArrowBtn',{staticClass:"slider-nav__arrow slider-nav__arrow_next",attrs:{"direction":"right","size":"36","shadow":""}})]},proxy:true}],null,false,1871370930)},_vm._l((_vm.products),function(product,index){return _c('div',{key:'product-' + product.id,staticClass:"slider__item-wrap"},[_c('MarketItemColumn',{staticClass:"slider__item",attrs:{"item":product,"apiName":"products","modelName":"product","hideLikeBtnOnXs":"","reviewsInPopup":""},on:{"changeFavorite":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChangeFavorite.apply(void 0, [ index ].concat( argsArray ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(product, index)}}})],1)}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }