export default ({ HTTP }) => ({
    async get() {
        const url = '/views_history/';
        const response = await HTTP.get(url);
        return response.data;
    },

    async addToHistory(data, config) {
        const url = '/views_history/add_to_history/';
        const response = await HTTP.post(url, data, config);
        return response.data;
    },
});