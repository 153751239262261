<template>
    <div>
        <InfoPageLayoutHead></InfoPageLayoutHead>

        <div class="content container_xs-external layer-1">
            <WysiwygView :value="info.text"></WysiwygView>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import WysiwygView from "../components/views/WysiwygView.vue";
import InfoPageLayoutHead from "../components/InfoPageLayoutHead.vue";

export default {
    name: "DefaultStaticPage",

    components: {
        InfoPageLayoutHead,
        WysiwygView,
    },

    computed: {
        ...mapState({
            info: state => state.staticPages.data,
        }),
    },
};
</script>

<style scoped>
@media (min-width: 1281px) {
    .content {
        width: var(--col-12);
    }
}
@media (min-width: 768px) {
    .content {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .content {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
</style>