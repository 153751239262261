import Vue from 'vue';
import App from './App.vue';
import Vuex from 'vuex';
import { createRouter } from '@/router/router.js';
import { createStore } from '@/store';
import { createApi } from '@/api/index.js';
import { sync } from 'vuex-router-sync';
import VueMeta from 'vue-meta';
import PortalVue from 'portal-vue';
import VueLazyload from 'vue-lazyload';
import popupManager from '@/plugins/popupManager.js';
import breadcrumbs from '@/plugins/breadcrumbs.js';
import variables from '@/plugins/variables.js';
import notification from '@/plugins/notification.js';
import formManagerPlugin from '@/plugins/formManagerPlugin.js';
import formManagerOptions from '@/plugins/formManagerOptions';
import filters from '@/mixins/filters.js';
import { UUI } from '/ubibi_frontend_components_library';

const isLocal = process.env.TARGET_ENV === 'local';
const isClient = process.env.VUE_ENV === 'client';

if (!isLocal) require('./sentry.js');
if (isClient) {
    const VueTouch = require('vue-touch');
    Vue.use(VueTouch, { name: 'VueTouch' });
}

// svg sprite
const icons = {};
function requireAll(r) {
    r.keys().forEach(key => {
        const re = /.\/(?<name>.*).svg/gi;
        const name = re.exec(key).groups.name;
        icons[name] = r(key);
    });
}

export function createApp(context) {
    requireAll(require.context('../ubibi_frontend_components_library/src/icons/', true, /\.svg$/));

    Vue.use(Vuex);
    Vue.use(VueMeta);
    Vue.use(PortalVue);
    Vue.use(VueLazyload, {
        observer: true,
        observerOptions: {
            rootMargin: '0px',
            threshold: 0.1,
        },
    });
    Vue.use(popupManager);
    Vue.use(variables);
    Vue.use(breadcrumbs);
    Vue.use(notification);
    Vue.use(formManagerPlugin, formManagerOptions);
    Vue.use(UUI);

    Vue.mixin({
        filters,
    });

    const api = createApi(context);

    context.api = api;
    Vue.prototype.$api = Vue.$api = api;
    Vue.prototype.$http = Vue.$http = context.HTTP;

    const store = createStore(context);
    const router = createRouter(store);

    sync(store, router);

    const app = new Vue({
        store,
        router,
        render: h => h(App),
    });

    return { app, router, store };
}
