<template>
    <div
        v-if="show"
        class="are-you-here layer-2"
    >
        <button
            class="are-you-here__close-button"
            aria-label="Закрыть попап изменения города"
            @click="confirm"
        >
            <UIcon
                name="close-24"
                big
                class="are-you-here__close-icon"
            ></UIcon>
        </button>

        <h4 class="are-you-here__title">
            Вы находитесь здесь?
        </h4>

        <p class="are-you-here__city-name">
            {{ city.full_name }}<span
                v-if="city.region"
            >, {{ city.region }}</span><span
                v-if="city.district"
            >, {{ city.district }}</span>
        </p>

        <p class="are-you-here__text">
            От выбора зависит стоимость товаров и доставки.
        </p>

        <div class="are-you-here__footer">
            <ButtonBlock
                secondary
                class="are-you-here__footer-button"
                aria-label="Выбрать другой город"
                @click="openChangeCityPopup"
            >
                Выбрать другой
            </ButtonBlock>

            <ButtonBlock
                primary
                class="are-you-here__footer-button"
                aria-label="Подтвердить текущий город"
                @click="confirm"
            >
                Да, верно
            </ButtonBlock>
        </div>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
const ChangeCityPopup = () => import('@/components/popups/ChangeCityPopup.vue');

export default {
    name: 'AreYouHerePopup',

    components: {
        ButtonBlock,
        UIcon,
    },

    data() {
        return {
            city: null,
            show: false,
        };
    },

    beforeMount() {
        this.show = this.$store.state.cities.showCityPopup;
        this.city = this.$store.state.cities.currentCity;
    },

    methods: {
        openChangeCityPopup() {
            this.$popup(ChangeCityPopup);
            this.show = false;
        },

        confirm() {
            this.$store.commit('cities/setShowCityPopup', false);
            this.show = false;
        },
    },
};
</script>

<style>
.are-you-here {
    position: fixed;
    z-index: var(--popup-z-index);
}
@media (min-width: 768px) {
    .are-you-here {
        top: 24px;
        right: 24px;
        width: 380px;
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .are-you-here {
        bottom: 0;
        width: 100%;
        padding: 20px var(--content-gap);
    }
}

.are-you-here__close-button {
    position: absolute;
    top: 12px;
    right: 12px;
}

.are-you-here__close-icon {
    fill: var(--font-secondary-color);
}
.are-you-here__close-button:hover .are-you-here__close-icon {
    fill: var(--dark-c);
}

.are-you-here__title {
    margin-bottom: 8px;
    font-size: var(--big-fz);
    font-family: var(--f-bold);
}

@media (min-width: 768px) {
    .are-you-here__city-name {
        margin-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .are-you-here__city-name {
        margin-bottom: 4px;
    }
}

.are-you-here__text {
    font-size: var(--small-fz);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .are-you-here__text {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .are-you-here__text {
        margin-bottom: 16px;
    }
}

.are-you-here__footer {
    display: flex;
}

.are-you-here__footer-button {
    flex-grow: 1;
    flex-basis: 0;
}
.are-you-here__footer-button:not(:last-child) {
    margin-right: 20px;
}
@media (max-width: 767px) {
    .are-you-here__footer-button:not(:last-child) {
        margin-right: 8px;
    }
}

.are-you-here__footer-button.button {
    padding: 8px 0 !important;
    height: 36px;
}

.are-you-here__footer-button.button .label {
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}
</style>