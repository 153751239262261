var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selector",class:[
        {
            selector_opened: _vm.opened,
            selector_disabled: _vm.disabled,
            selector_invalid: _vm.invalid,
            selector_multi: _vm.multi,
            selector_single: !_vm.multi,
            selector_small: _vm.small,
            selector_fill: _vm.inputValue.length
        } ],attrs:{"id":'selector' + _vm.id},on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[_c('div',{staticClass:"selector__input-container"},[_vm._t("input",function(){return [_c('InputText',_vm._g(_vm._b({ref:"input",staticClass:"selector__input",attrs:{"readonly":_vm.readonly,"small":_vm.small,"autocomplete":"off","clearable":false,"rightIndent":"","isFilled":!!_vm.inputValue.length || !_vm.isEmpty || (!_vm.placeholder && _vm.opened)}},'InputText',_vm.scope.input.attrs,false),_vm.scope.input.events))]},null,_vm.scope.input),_vm._v(" "),_vm._t("clear",function(){return [(_vm.showCross)?_c('button',_vm._g(_vm._b({staticClass:"selector__button selector__clear",attrs:{"type":"button","tabindex":"-1"}},'button',_vm.scope.clear.attrs,false),_vm.scope.clear.events),[_c('UIcon',{attrs:{"name":"cross","small":"","secondary":"","hovered":""}})],1):_vm._e()]},null,_vm.scope.clear),_vm._v(" "),_vm._t("toggle",function(){return [(_vm.showToggle)?_c('button',_vm._g(_vm._b({staticClass:"selector__button selector__toggle",attrs:{"type":"button","tabindex":"-1"}},'button',_vm.scope.toggle.attrs,false),_vm.scope.toggle.events),[_c('UIcon',{staticClass:"selector__arrow",class:{
                        selector__arrow_opened: _vm.opened,
                    },attrs:{"name":"arrow-down","small":"","hovered":""}})],1):_vm._e()]},null,_vm.scope.toggle),_vm._v(" "),_c('TransitionExpandFade',[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showList && (!_vm.loading || _vm.filteredOptions.length)),expression:"showList && (!loading || filteredOptions.length)"}],ref:"optionsList",staticClass:"layer-2 selector__options-list",class:{
                    'selector__options-list_content-width': _vm.checkboxOption,
                }},[_vm._l((_vm.filteredOptions),function(option,optionIndex){return _c('li',{key:_vm.getOptionKey(option),ref:"option",refInFor:true,staticClass:"selector__option-item"},[(_vm.checkboxOption)?_c('div',{ref:"optionButton",refInFor:true,staticClass:"selector__option-button",class:{
                            'selector__option-button_focus': _vm.focusIndex === optionIndex,
                        }},[_vm._t("label",function(){return [_c('UCheckbox',{attrs:{"value":_vm.isOptionSelected(option, _vm.selectedOptions)},on:{"change":function($event){return _vm.select(option)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    "+_vm._s(_vm.getOptionLabel(option))+"\n                                ")]},proxy:true}],null,true)})]},null,{ option: option })],2):_c('button',{ref:"optionButton",refInFor:true,staticClass:"selector__option-button",class:{
                            'selector__option-button_selected': _vm.isOptionSelected(option, _vm.selectedOptions),
                            'selector__option-button_focus': _vm.focusIndex === optionIndex,
                        },attrs:{"type":"button","tabindex":-1},on:{"click":function($event){_vm.selectable(option) ? _vm.select(option) : null}}},[_vm._t("label",function(){return [_vm._v("\n                            "+_vm._s(_vm.getOptionLabel(option))+"\n                        ")]},null,{ option: option })],2)])}),_vm._v(" "),(!_vm.loading && _vm.hasMore)?_c('li',{staticClass:"selector__option-item selector__option-more"},[_c('ButtonText',{ref:"more",attrs:{"secondary":"","dark":"","dashed":"","focused":_vm.focusIndex === _vm.filteredOptions.length,"tabindex":-1},on:{"click":function($event){return _vm.$emit('more')}}},[_vm._v("\n                        Показать ещё\n                    ")])],1):_vm._e(),_vm._v(" "),(!_vm.loading && !_vm.filteredOptions.length)?_c('li',{staticClass:"selector__option-item selector__option-empty",attrs:{"tabindex":-1}},[_vm._v("\n                    Ничего не найдено\n                ")]):_vm._e()],2)])],2),_vm._v(" "),(!_vm.inputValue.length)?_vm._t("value",function(){return [(_vm.multi && !_vm.checkboxOption)?_c('div',{staticClass:"selector__value-wrap"},_vm._l((_vm.selectedOptions),function(option){return _c('div',{key:'selected' + _vm.getOptionKey(option),staticClass:"selector__value"},[_vm._t("selected-option",function(){return [_c('Tag',{staticClass:"selector__tag",on:{"clear":function($event){return _vm.remove(option)}}},[_vm._t("label",function(){return [_c('span',{attrs:{"title":_vm.getOptionLabel(option)}},[_vm._v(_vm._s(_vm.getOptionLabel(option)))])]},null,{ option: option })],2)]},null,option)],2)}),0):_vm._e(),_vm._v(" "),(_vm.clearInputOnFocus)?_c('div',{staticClass:"selector__selected-option",class:{
                'selector__selected-option_empty': !_vm.selectedOptions.length,
                'selector__selected-option_multi': _vm.multi,
                'selector__selected-option_small': _vm.small,
            },on:{"click":_vm.onClickValue}},[(_vm.multi)?_c('span',{attrs:{"title":_vm.pseudoPlaceholderValue}},[_vm._v(_vm._s(_vm.pseudoPlaceholderValue))]):[_vm._t("label",function(){return [_c('span',{attrs:{"title":_vm.pseudoPlaceholderValue}},[_vm._v(_vm._s(_vm.pseudoPlaceholderValue))])]},null,{ option: _vm.selectedOptions[0] })]],2):_vm._e()]},null,_vm.scope.value):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }