export default ({ HTTP }) => ({
    async upload(data, config) {
        const url = '/private-mediafiles/upload/';

        if (!config.headers) config.headers = {};
        config.headers['Content-Type'] = 'multipart/form-data';

        const response = await HTTP.post(url, data, config);
        return response.data;
    },
});
