import deepClone from '@/lib/deepClone.js';


const initial = {
    initializing: true,
    initialized: false,
    policiesCount: 0,
    contractsCount: 0,
};

export default {
    namespaced: true,

    state() {
        return deepClone(initial);
    },

    mutations: {
        setInitializing(state, value) {
            state.initializing = value;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        setPoliciesCount(state, value) {
            state.policiesCount = value;
        },

        setContractsCount(state, value) {
            state.contractsCount = value;
        },

        destroy(state) {
            Object.entries(deepClone(initial)).forEach(([key, value]) => {
                state[key] = value;
            });
        },
    },

    actions: {
        async prefetch({ commit }) {
            commit('setInitializing', true);

            try {
                const [policies, contracts] = await Promise.all([
                    this.$api.insuranceContract.getCount(),
                    this.$api.insuranceContract.getCount(),
                ]);

                commit('setPoliciesCount', policies.current_count);
                commit('setContractsCount', contracts.current_count);
                commit('setInitialized');
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
            finally {
                commit('setInitializing', false);
            }
        },
    },
};