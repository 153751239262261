import equals from '@/lib/equals.js';


export default () => {
    return {
        originalValues: {
            handler(originalValues) {
                if (!equals(originalValues, this.innerValues)) {
                    this.innerValues = originalValues;
                }
            },

            deep: true,
            immediate: true,
        },

        innerValues: {
            handler(innerValues) {
                if (!equals(innerValues, this.originalValues)) {
                    if (this.updateParentValue && typeof this.updateParentValue === 'function') {
                        this.updateParentValue({ value: innerValues, name: this.name });
                    }

                    this.$emit('change', innerValues);
                }
            },

            deep: true,
        },

        innerErrors: {
            handler(innerErrors) {
                if (!equals(innerErrors, this.originalErrors)) {
                    if (this.updateOriginalErrors && typeof this.updateOriginalErrors === 'function') {
                        if (this.isRoot) {
                            // FormManager просто эмитит событие наверх
                            this.updateOriginalErrors();
                        }
                        else {
                            this.updateOriginalErrors({ name: this.name, errors: innerErrors });
                        }
                    }
                }
            },

            deep: true,
        },

        originalErrors: {
            handler(originalErrors) {
                // если приходит невалидный объект, то
                // надо предупредить сверху компонент
                if (!equals(originalErrors, this.innerErrors)) {
                    this.innerErrors = this.originalErrors;
                }
            },

            deep: true,
        },
    };
};