<template>
    <div class="zoomer">
        <button
            tabindex="-1"
            type="button"
            :disabled="zoomValue == 0"
            class="zoomer__button zoomer__button_minus"
            @click="onMinusClick"
        >
            <UIcon name="minus" class="zoomer__button-icon"></UIcon>
        </button>

        <div class="zoomer__range-wrap">
            <input
                v-model="zoomValue"
                type="range"
                class="zoomer__range"
                min="0"
                max="100"
                step="10"
                @change="onSliderChange"
                @input="onSliderChange"
            >
        </div>

        <button
            tabindex="-1"
            type="button"
            :disabled="zoomValue == 100"
            class="zoomer__button zoomer__button_plus"
            @click="onPlusClick"
        >
            <UIcon name="plus" class="zoomer__button-icon"></UIcon>
        </button>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

const ZOOM_STEP = 10;

export default {
    name: 'ZoomControl',

    components: { UIcon },

    data() {
        return {
            zoomValue: 0,
        };
    },

    mounted() {
        window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
        window.addEventListener('resize', this.onResize);
    },

    methods: {
        onSliderChange() {
            this.$emit('change', Number(this.zoomValue));
        },

        onMinusClick() {
            if (this.zoomValue != 0) {
                this.zoomValue = Number(this.zoomValue) - ZOOM_STEP;
                this.onSliderChange();
            }
        },

        onPlusClick() {
            if (this.zoomValue != 100) {
                this.zoomValue = Number(this.zoomValue) + ZOOM_STEP;
                this.onSliderChange();
            }
        },

        onResize() {
            this.zoomValue = 0;
            this.onSliderChange();
        },

        reset() {
            this.zoomValue = 0;
            this.onSliderChange();
        },
    },
};
</script>

<style scoped>
.zoomer {
    position: relative;
    width: 200px;
    height: 28px;
    display: flex;
    overflow: hidden;
    border-radius: 14px;
}

.zoomer::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--dark-c);
    border: 1px solid var(--fields-border);
    opacity: .4;
    border-radius: 14px;
}

.zoomer__button {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-c);
    opacity: 1;
    border-radius: var(--border-radius-x3);
    transition: opacity var(--transition);
}

.zoomer__button:disabled {
    opacity: .4;
}

.zoomer__button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    background: var(--dark-c);
    transition: opacity var(--transition);
}

.zoomer__button:hover::before {
    opacity: .1;
}

.zoomer__button:active::before {
    opacity: .2;
}

.zoomer__button_minus {
    margin-right: 4px;
}

.zoomer__button_plus {
    margin-left: 4px;
}

.zoomer__button-icon {
    fill: currentColor;
}

.zoomer__range-wrap {
    position: relative;
    z-index: 2;
    width: 104px;
    margin: 3px 0;
    font-size: 0;
    flex-shrink: 0;
    border: 1px solid rgba(162, 167, 171, 0.4);
    border-radius: var(--border-radius-x3);
}

.zoomer__range {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
}

.zoomer__range:focus {
    outline: none;
}

.zoomer__range::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--border-radius-x3);
}
.zoomer__range::-moz-range-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--border-radius-x3);
}
.zoomer__range::-ms-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--border-radius-x3);
}
.zoomer__range::-ms-fill-lower {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--border-radius-x3);
}
.zoomer__range::-ms-fill-upper {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--border-radius-x3);
}

.zoomer__range::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
    background-color: var(--light-c);
    margin-top: -2px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
}
.zoomer__range::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background-color: var(--light-c);
    margin-top: -2px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
}
.zoomer__range::-ms-thumb {
    width: 24px;
    height: 24px;
    background-color: var(--light-c);
    margin-top: -2px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
}
</style>