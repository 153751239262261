export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        newsListStaticPageInfo: {},
        item: {},
        seeAlso: {
            items: [],
            current_count: 0,
        },
    }),

    getters: {
        breadcrumbs: state => {
            return [
                {
                    to: {
                        name: 'news',
                    },
                    title: state.newsListStaticPageInfo.title,
                },
                {
                    to: {
                        name: 'news',
                    },
                    title: state.item.title,
                },
            ];
        },
    },

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.initialized = false;
            state.item = {};
        },

        setNews(state, item) {
            state.item = item;
        },

        setNewsListStaticPageInfo(state, response) {
            state.newsListStaticPageInfo = response;
        },

        setSeeAlso(state, { current_count, results }) {
            state.seeAlso.items = results.slice(0, 5);
            state.seeAlso.current_count = current_count;
        },
    },

    actions: {
        async init({ commit, dispatch }, { codename }) {
            try {
                const [news, newsListStaticPageInfo, seeAlso] = await Promise.all([
                    this.$api.news.get(codename),
                    this.$api.staticPages.get('news'),
                    dispatch('getSeeAlso', { codename }),
                ]);

                commit('setInitialized');
                commit('setNews', news);
                commit('setNewsListStaticPageInfo', newsListStaticPageInfo);
                commit('setSeeAlso', seeAlso);
                commit('clearHttpError', null, { root: true });

                return { news, newsListStaticPageInfo, seeAlso };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getSeeAlso({} , { codename }) {
            const params = {
                limit: 6,
                offset: 0,
                order_by: '-publication_date',
            };

            try {
                const { current_count, results } = await this.$api.news.get({ params });
                return {
                    results: results.filter(item => item.codename !== codename),
                    current_count,
                };
            }
            catch (error) {
                return {
                    results: [],
                    current_count: 0,
                };
            }
        },
    },
};