<template>
    <div
        v-if="breadcrumbs.length"
        class="breadcrumbs-wrap"
    >
        <div class="container">
            <ul class="breadcrumbs_desktop">
                <template v-for="(page, pageIndex) in breadcrumbs">
                    <li
                        v-if="page.to"
                        :key="'breadcrumb-' + pageIndex"
                        class="item"
                        :class="{
                            'item_last': pageIndex === breadcrumbs.length-1,
                        }"
                    >
                        <RouterLink
                            v-if="pageIndex < breadcrumbs.length-1"
                            exact
                            :to="page.to"
                            class="link underline-parent"
                        >
                            <span class="link-text underline">{{ page.title }}</span>
                        </RouterLink>
                        <span v-else class="link link-text">{{ page.title }}</span>
                    </li>
                </template>
            </ul>

            <div class="breadcrumbs_mobile">
                <RouterLink
                    exact
                    :to="breadcrumbs[breadcrumbs.length - 2].to"
                    class="back-link"
                >
                    <UIcon
                        name="arrow-left"
                        small
                        class="mr-8"
                    ></UIcon>
                    <span>{{ breadcrumbs[breadcrumbs.length - 2].title }}</span>
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script>
import bus from '../bus.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';

const main = {
    to: {
        name: 'main',
    },
    title: 'Главная',
};

export default {
    name: "Breadcrumbs",

    components: { UIcon },

    data() {
        return {
            breadcrumbs: [],
        };
    },

    mounted() {
        bus.$on('breadcrumbs', this.set);
    },

    beforeDestroy() {
        bus.$off('breadcrumbs', this.set);
    },

    methods: {
        set(breadcrumbs) {
            if (breadcrumbs.length) {
                this.breadcrumbs = [main, ...breadcrumbs];
            }
            else {
                this.breadcrumbs = [];
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .breadcrumbs-wrap {
        padding-top: 20px;
    }
}
@media (max-width: 767px) {
    .breadcrumbs-wrap {
        padding-top: 16px;
    }
}

.breadcrumbs_desktop {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    margin-bottom: -8px;
}

.item {
    margin-bottom: 8px;
}

.item:not(:last-child) .link::after {
    content: '/';
    margin-left: 8px;
    margin-right: 8px;
}

.item_last .link {
    color: var(--font-secondary-color);
}

.link-text {
    white-space: nowrap;
}

.breadcrumbs_mobile {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.back-link {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
}

@media (min-width: 768px) {
    .breadcrumbs_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .breadcrumbs_desktop {
        display: none;
    }
}
</style>