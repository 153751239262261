export default {
    namespaced: true,

    state: () => ({
        value: '',
        car: {},
    }),

    getters: {
        carProductionDate: state => {
            if (state.car.prod_date) {
                let str = state.car.prod_date.year;
                if (state.car.prod_date.month) {
                    str += '-';
                    str += state.car.prod_date.month.toString().padStart(2, '0');

                    if (state.car.prod_date.day) {
                        str += '-';
                        str += state.car.prod_date.day.toString().padStart(2, '0');
                    }
                }
                return str;
            }
            else {
                return '';
            }
        },
    },

    mutations: {
        setValue(state, value) {
            state.value = value;
        },

        setCar(state, info) {
            state.car = info;
            localStorage.setItem('UbibiVuexGlobalSearchCar', JSON.stringify(info));
        },
    },
};