<template>
    <PopupWrap
        :loading="initLoading"
        noPadding
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="reviews-popup__header">
                <div class="reviews-popup__cover-block">
                    <MarketItemCover
                        :item="item"
                        :modelName="modelName"
                        coverCodename="small"
                    ></MarketItemCover>
                </div>

                <div class="reviews-popup__name-block">
                    <MarketItemName
                        :id="id"
                        :modelName="modelName"
                        :name="item.name"
                        class="reviews-popup__name"
                    ></MarketItemName>

                    <div class="market-item-row__rating">
                        {{ item.reviews_amount | plural(['отзыв', 'отзыва', 'отзывов']) }}
                    </div>
                </div>
            </div>

            <div class="reviews-popup__tools">
                <span class="reviews-popup__sort-label">Сортировка:</span>
                <UMenu
                    class="reviews-popup__sort"
                    :shift="-16"
                >
                    <template #trigger="{ handlers, attrs, value }">
                        <button
                            type="button"
                            class="reviews-popup__sort-input"
                            v-bind="attrs"
                            v-on="handlers"
                        >
                            <span
                                :key="sort.value.value"
                                class="reviews-popup__sort-value"
                            >{{ sort.value.name }}</span>
                            <UIcon
                                name="arrow-down"
                                tiny
                                class="reviews-popup__sort-arrow-icon"
                                :class="{ opened: value }"
                            ></UIcon>
                        </button>
                    </template>

                    <UMenuItem
                        v-for="sortOption in sort.options"
                        :key="'sort-option-' + sortOption.value"
                        :selected="sort.value.value === sortOption.value"
                        @click="onChangeSort(sortOption)"
                    >
                        {{ sortOption.name }}
                    </UMenuItem>
                </UMenu>
            </div>

            <div class="reviews-popup__list">
                <USpinner
                    v-if="listLoading"
                    big
                    center
                ></USpinner>

                <template v-else>
                    <PagesReview
                        v-for="(review, index) in reviews.items"
                        :key="'product-review-' + index"
                        :product="product"
                        :part="part"
                        :review="review"
                        class="reviews-popup__item"
                        @update="handleUpdate"
                    ></PagesReview>

                    <div
                        v-if="reviews.next"
                        class="reviews-popup__next-wrap"
                    >
                        <UButtonText
                            dashed
                            secondary
                            dark
                            :loading="nextLoading"
                            @click="handleNextClick"
                        >
                            Показать ещё
                        </UButtonText>
                    </div>
                </template>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import { plural } from '@ui/filters/index.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import PagesReview from '@/components/PagesReview.vue';
import MarketItemCover from '@/components/MarketItemCover.vue';
import MarketItemName from '@/components/MarketItemName.vue';
import UMenu from '@ui/components/UMenu/UMenu.vue';
import UMenuItem from '@ui/components/UMenu/UMenuItem.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';


export default {
    name: 'ReviewsPopup',

    components: {
        UButtonText,
        USpinner,
        UIcon, UMenuItem, UMenu,
        MarketItemName,
        MarketItemCover,
        PagesReview,
        PopupWrap,
    },

    filters: {
        plural,
    },

    mixins: [
        popup,
    ],

    props: {
        item: Object,
        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
        },
    },

    data() {
        return {
            initLoading: false,
            reviews: {
                items: [],
                count: 0,
                next: '',
            },
            sort: {
                value: {
                    name: 'По дате',
                    value: '-create_date',
                },
                options: [
                    {
                        name: 'По рейтингу',
                        value: '-rating',
                    },
                    {
                        name: 'По дате',
                        value: '-create_date',
                    },
                ],
            },
            offset: 0,
            limit: 20,
            nextLoading: false,
            listLoading: false,
        };
    },

    computed: {
        id() {
            return this.item.product_id || this.item.part_product_id;
        },

        product() {
            if (this.modelName === 'product') return this.item;
            return null;
        },

        part() {
            if (['part_product', 'contract_part'].includes(this.modelName)) return this.item;
            return null;
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.initLoading = true;

            try {
                const response = await this.getReviews();
                this.reviews.items = response.results;
                this.reviews.count = response.current_count;
                this.reviews.next = response.next;
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error, { root: true });
                this.close();
            }
            finally {
                this.initLoading = false;
            }
        },

        getParams() {
            return {
                limit: this.limit,
                offset: this.offset,
                order_by: this.sort.value.value,
            };
        },

        async getReviews() {
            const id = this.id;
            const params = this.getParams();
            const config = { params };
            return await this.$api.products.reviews.get(id, config);
        },

        handleUpdate() {
            //
        },

        async onChangeSort(option) {
            this.listLoading = true;

            this.sort.value = option;
            this.offset = 0;

            try {
                const response = await this.getReviews();
                this.reviews.items = response.results;
                this.reviews.count = response.current_count;
                this.reviews.next = response.next;
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                this.listLoading = false;
            }
        },

        async handleNextClick() {
            this.nextLoading = true;

            this.offset += this.limit;

            try {
                const response = await this.getReviews();
                this.reviews.items.push(response.results);
                this.reviews.next = response.next;
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                this.nextLoading = false;
            }
        },
    },
};
</script>

<style>
.popup-content {
    display: flex;
    flex-direction: column;
}
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (max-width: 1039px) and (min-width: 768px) {
    .popup-content {
        width: 708px;
    }
}

.reviews-popup__header {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 20px var(--popup-paddings-x);
    display: flex;
    border-bottom: 1px solid var(--border-light-c);
}

.reviews-popup__cover-block {
    margin-right: 20px;
}

.reviews-popup__name-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reviews-popup__name.market-item-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 20px;
}
@media (max-width: 767px) {
    .reviews-popup__name.market-item-name {
        width: calc(100% - 36px);
        font-size: 18px;
    }
}

.market-item-row__rating {
    margin-top: 8px;
    color: var(--font-secondary-color);
    font-family: var(--f-semi-bold);
    font-size: var(--adaptive-big-fz);
}
@media (max-width: 767px) {
    .market-item-row__rating {
        margin-top: 4px;
    }
}

.reviews-popup__tools {
    flex-shrink: 0;
    flex-grow: 0;
    height: 52px;
    padding-left: var(--popup-paddings-x);
    padding-right: var(--popup-paddings-x);
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-light-c);
}

.reviews-popup__list {
    overflow: auto;
    flex: 1;
    padding: 20px var(--popup-paddings-x) var(--popup-paddings-y);
}

.reviews-popup__sort {
    position: relative;
    font-size: var(--base-fz);
}

.reviews-popup__sort-label {
    margin-right: 12px;
    color: var(--font-secondary-color);
}
@media (max-width: 767px) {
    .reviews-popup__sort-label {
        display: none;
    }
}

.reviews-popup__sort-input {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: left;
    color: var(--dark-c);
}

.reviews-popup__sort-input:hover,
.reviews-popup__sort-input:focus {
    color: var(--primary-color);
    transition: color var(--transition);
}

.reviews-popup__sort-arrow-icon {
    margin-left: 8px;
    fill: currentColor;
    transition: fill var(--transition), transform var(--transition);
}

.reviews-popup__sort-arrow-icon.opened {
    transform: rotate(180deg);
}

.reviews-popup__item:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .reviews-popup__item:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .reviews-popup__item:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
    }
}

.reviews-popup__next-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>