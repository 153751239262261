<template>
    <FormTemplate>
        <template slot="body">
            <h2 class="h2 h2_block">
                Срок действия полиса
            </h2>

            <FormManager
                ref="form"
                v-model="formData"
                :formTag="false"
                class="form-grid"
                :errors="innerErrors"
                @error="onError"
            >
                <FormField
                    name="action_start_date"
                    type="calendar"
                    :min="minActionStartDate"
                    :max="maxActionStartDate"
                    label="Начало действия полиса"
                    :validations="`required|min:${ minActionStartDateIso }|max:${ maxActionStartDateIso }`"
                    class="action_start_date"
                    @change="patch('action_start_date')"
                ></FormField>

                <FormField
                    name="action_end_date"
                    type="date"
                    label="Окончание действия полиса"
                    disabled
                    class="action_end_date"
                ></FormField>
            </FormManager>

            <InfoMessage class="mt-20">
                Период действия полиса — 1 календарный год с указанной вами даты начала действия полиса.
            </InfoMessage>
        </template>

        <template slot="footer">
            <ButtonBlock
                secondary
                class="button-secondary"
                @click="back"
            >
                Назад
            </ButtonBlock>
            <ButtonBlock
                primary
                class="button-primary"
                @click="next"
            >
                Следующий шаг
            </ButtonBlock>
        </template>
    </FormTemplate>
</template>

<script>
// utils
import deepClone from '@/lib/deepClone.js';
// use
import useNavigationMethods from '@/pages/insurance/osago/_uuid/useNavigationMethods.js';
import usePatchData from '@/pages/insurance/osago/_uuid/usePatchData.js';
import usePatchComputed from '@/pages/insurance/osago/_uuid/usePatchComputed.js';
import usePatchWatch from '@/pages/insurance/osago/_uuid/usePatchWatch.js';
import usePatchMethods from '@/pages/insurance/osago/_uuid/usePatchMethods.js';
import usePolicyData from '@/pages/insurance/osago/_uuid/usePolicyData.js';
import usePolicyComputed from '@/pages/insurance/osago/_uuid/usePolicyComputed.js';
import usePolicyWatch from '@/pages/insurance/osago/_uuid/usePolicyWatch.js';
// mixins
import lastPathMixin from '@/pages/insurance/osago/_uuid/lastPathMixin.js';
// components
import InfoMessage from '@/components/InfoMessage.vue';
import FormTemplate from '../FormTemplate.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';


export default {
    name: 'InsuranceOsagoRequestStep2Page',

    components: {
        ButtonBlock,
        FormManager,
        InfoMessage,
        FormTemplate,
        FormField,
    },

    mixins: [
        lastPathMixin,
    ],

    props: {
        errors: Object,
    },

    data() {
        return {
            ...usePatchData(),
            ...usePolicyData(),
        };
    },

    computed: {
        ...usePatchComputed(),
        ...usePolicyComputed(),
    },

    watch: {
        ...usePatchWatch(),
        ...usePolicyWatch(),
    },

    mounted() {
        // предполагаю, что к этому моменту данные по контракту получены
        this.patchedData = deepClone(this.filteredStoreData);
        // add data

        if (!this.formData.action_start_date) {
            this.formData.action_start_date = this.minActionStartDateIso;
        }
    },

    methods: {
        ...usePatchMethods(),
        ...useNavigationMethods({
            prev: 'insurance-osago-request-step-1',
            next: 'insurance-osago-request-step-3',
            yandex: 'osago_price_step2',
        }),
    },
};
</script>

<style scoped>
.action_start_date {
    grid-area: action_start_date;
}

.action_end_date {
    grid-area: action_end_date;
}

@media (min-width: 1040px) {
    .form-grid {
        grid-template-areas:
            "action_start_date action_end_date .";
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .form-grid {
        grid-template-areas:
            "action_start_date action_end_date";
    }
}
</style>