<template>
    <AccountLayout
        title="Настройки профиля"
        mobileTitle="Настройки"
    >
        <div>
            <section class="account-settings layer-1">
                <div class="row row_center">
                    <div class="label">
                        Профиль
                    </div>
                    <div class="info user-avatar-name">
                        <UserAvatar
                            size="xs"
                            emptyIcon
                            class="avatar"
                        ></UserAvatar>
                        <span class="name">
                            {{ profile.first_name }} {{ lastNameFirstLetter }}
                        </span>
                    </div>
                    <div class="action">
                        <button
                            class="dashed text_secondary-dark"
                            @click="showChangeUserInfoPopup"
                        >
                            Редактировать
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="label">
                        Телефон
                    </div>
                    <div
                        v-if="profile.phone"
                        class="info"
                    >
                        {{ profile.phone }}
                    </div>
                    <p
                        v-else
                        class="info text_secondary"
                    >
                        Не указан
                    </p>
                    <div class="action">
                        <button
                            class="dashed text_secondary-dark"
                            @click="showChangePhonePopup"
                        >
                            {{ !profile.phone ? 'Добавить' : 'Изменить' }}
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="label">
                        E-mail
                    </div>
                    <div class="info">
                        <p
                            v-if="!profile.email && !profile.email_unconfirmed"
                            class="text_secondary-light"
                        >
                            Не указан
                        </p>

                        <p v-if="profile.email">
                            {{ profile.email }}
                        </p>
                    </div>

                    <div class="action">
                        <button
                            class="dashed text_secondary-dark"
                            @click="showChangeEmailPopup"
                        >
                            {{ !profile.email && !profile.email_unconfirmed ? 'Добавить' : 'Изменить' }}
                        </button>
                    </div>
                </div>
            </section>
        </div>
    </AccountLayout>
</template>

<script>
import { mapState } from 'vuex';
import AccountLayout from '@/pages/account/AccountLayout.vue';
import UserAvatar from '@/components/UserAvatar.vue';
const ChangeUserInfoPopup = () => import('./ChangeUserInfoPopup.vue');
const ChangePhonePopup = () => import('./ChangePhonePopup.vue');
const ChangeEmailPopup = () => import('./ChangeEmailPopup.vue');

export default {
    name: 'ProfileSettings',

    components: { UserAvatar, AccountLayout },

    metaInfo() {
        return {
            title: 'ЛК / Настройки профиля',
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),

        lastNameFirstLetter() {
            return this.profile.last_name ? this.profile.last_name.charAt(0) + '.' : '';
        },
    },

    mounted() {
        this.$breadcrumbs([]);
    },

    methods: {
        showChangeUserInfoPopup() {
            this.$popup(ChangeUserInfoPopup);
        },

        showChangePhonePopup() {
            this.$popup(ChangePhonePopup);
        },

        showChangeEmailPopup() {
            this.$popup(ChangeEmailPopup);
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .account-settings {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .account-settings {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .account-settings {
        padding: 16px;
    }
}

.row {
    display: flex;
    align-items: baseline;
}
@media (max-width: 1039px) {
    .row {
        flex-wrap: wrap;
    }
}

.row_center {
    align-items: center;
}

.row:not(:first-child) {
    padding-top: 20px;
}

.row:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light-c);
}

.label {
    flex-shrink: 0;
    color: var(--font-secondary-color);
}
@media (min-width: 1281px) {
    .label {
        width: 244px;
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .label {
        width: 108px;
    }
}
@media (min-width: 768px) {
    .label {
        margin-right: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .label {
        order: 1;
        width: 50%;
    }
}

.info {
    flex-grow: 1;
}
@media (min-width: 768px) {
    .info {
        margin-right: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .info {
        width: 100%;
        margin-top: 8px;
        order: 3;
    }
}

.info p:not(:last-child) {
    margin-bottom: 8px;
}

.action {
    flex-shrink: 0;
    text-align: right;
}
@media (min-width: 1281px) {
    .action {
        width: 144px;
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .action {
        width: 120px;
    }
}
@media (max-width: 767px) {
    .action {
        margin-left: auto;
        order: 2;
    }
}

.user-avatar-name {
    display: flex;
    align-items: center;
}

.avatar {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: var(--bright-bg);
}
</style>