<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <FormManager
            ref="form"
            v-model="formData"
            class="popup-content"
            @submit="submit"
        >
            <h2 class="h2">
                Отменить доставку
            </h2>

            <h3 class="h4 h4_block top-gap_small">
                Укажите причину отмены:
            </h3>

            <FormField
                type="radio"
                name="reason"
                :options="reasonOptions"
                class="reason-radio"
            ></FormField>

            <FormField
                type="text"
                name="customReason"
                label="Причина отмены"
                :validations="formData.reason === 'custom' ? 'required' : ''"
                :disabled="formData.reason !== 'custom'"
            ></FormField>

            <InfoMessage class="mt-36">
                {{ infoMessage }}
            </InfoMessage>

            <div class="actions-wrap">
                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="close"
                >
                    Нет
                </ButtonBlock>
                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    class="action-btn"
                >
                    Подтвердить
                </ButtonBlock>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';


export default {
    name: 'CancelYandexDeliveryPopup',

    components: { ButtonBlock, FormField, FormManager, InfoMessage, PopupWrap },

    mixins: [popup, handleFormErrorMixin],

    props: {
        claim: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            formData: {
                reason: '',
                customReason: '',
            },
            reasonOptions: [
                {
                    value: 'Доставка оформлена по ошибке',
                    label: 'Доставка оформлена по ошибке',
                },
                {
                    value: 'Курьер не был назначен',
                    label: 'Курьер не был назначен',
                },
                {
                    value: 'Курьер не приехал',
                    label: 'Курьер не приехал',
                },
                {
                    value: 'Не подходят условия доставки',
                    label: 'Не подходят условия доставки',
                },
                {
                    value: 'Водитель попросил отменить заявку',
                    label: 'Водитель попросил отменить заявку',
                },
                {
                    value: 'custom',
                    label: 'Другая причина',
                },
            ],

            loading: false,
        };
    },

    computed: {
        infoMessage() {
            const statusesMap = {
                'new': 'fullRefund',
                'estimating': 'fullRefund',
                'estimating_failed': 'fullRefund',
                'ready_for_approval': 'fullRefund',
                'accepted': 'fullRefund',
                'performer_lookup': 'fullRefund',
                'performer_draft': 'fullRefund',

                'performer_found': 'partialRefund',
                'performer_not_found': 'partialRefund',
                'pickup_arrived': 'partialRefund',
                'ready_for_pickup_confirmation': 'partialRefund',

                'pickuped': 'noRefund',
                'delivery_arrived': 'noRefund',
                'ready_for_delivery_confirmation': 'noRefund',
            };

            const currentStatusStep = statusesMap[this.claim.claim_status];

            const statusStepMap = {
                'fullRefund': 'После подтверждения отмены, деньги за оплату доставки будут возвращены на вашу банковскую карту.',
                'partialRefund': 'После подтверждения отмены, с вас будет удержана комиссия в размере минимальной стоимости тарифа, остаток средств будет возвращён на вашу банковскую карту.',
                'noRefund': 'Заказ уже был передан курьеру для доставки по указанному адресу, деньги за доставку не будут возвращены на вашу банковскую карту. Курьер отвезёт заказ обратно в магазин. Вы сможете оформить доставку в другое удобное время или забрать заказ самостоятельно в магазине.',
            };

            return statusStepMap[currentStatusStep];
        },
    },

    methods: {
        async submit() {
            const errors = await this.$refs.form.validate();

            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.loading = true;
                const data = {
                    cancel_reason: this.formData.reason === 'custom' ? this.formData.customReason : this.formData.reason,
                };

                this.$api.delivery.yandex.cancel({ id: this.claim.id, data })
                    .then(response => {
                        this.close();
                        this.callback(response);
                        this.$success('Доставка заказа отменена');
                    })
                    .catch(error => {
                        this.$refs.form.handle(error);
                        this.$store.commit('handleCommonHttpError', error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.reason-radio {
    margin-bottom: 20px;
}

.actions-wrap {
    padding-top: 20px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .actions-wrap {
        margin-top: auto;
    }

    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>