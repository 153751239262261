<template>
    <PopupWrap
        :loading="initLoading"
        noPadding
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <MarketItemInOffersPopup
                :item="item"
                :modelName="modelName"
            ></MarketItemInOffersPopup>

            <div class="toolbar">
                <p class="toolbar-title">
                    Выберите пункт выдачи
                </p>

                <RadioButtonsGroup
                    :value="view"
                    name="offers-desktop"
                    type="text"
                    :options="[
                        {
                            label: 'Список',
                            value: 'list',
                        },
                        {
                            label: 'Карта',
                            value: 'map',
                        },
                    ]"
                    class="view-button-desktop"
                    @change="onChangeView"
                ></RadioButtonsGroup>

                <ButtonBlock
                    v-if="view === 'map'"
                    low
                    secondary
                    class="view-button-mobile view-button"
                    @click="onChangeView('list')"
                >
                    Списком
                </ButtonBlock>

                <ButtonBlock
                    v-if="view === 'list'"
                    low
                    secondary
                    class="view-button-mobile view-button"
                    @click="onChangeView('map')"
                >
                    На карте
                </ButtonBlock>
            </div>

            <div class="content-wrap">
                <Spinner
                    v-show="loading"
                    absoluteCenter
                    size="big"
                ></Spinner>

                <div
                    v-if="view === 'list'"
                    class="view_list"
                >
                    <ol
                        class="offers-list"
                        :class="{
                            'offers-list_loading': loading,
                        }"
                    >
                        <li
                            v-for="(offer, index) in offers"
                            :key="'offer-' + offer.id"
                            class="offer-wrap"
                        >
                            <MarketItemOffer
                                :item="item"
                                :offer="offer"
                                :modelName="modelName"
                                class="list-item__offer"
                                @clickChat="close"
                                @addToCart="onChangeCart(index)"
                                @deleteFromCart="onDeleteFromCart(index)"
                            ></MarketItemOffer>
                        </li>
                    </ol>

                    <ButtonText
                        v-if="hasNext"
                        dashed
                        secondary
                        dark
                        class="offers-more-btn"
                        @click="load"
                    >
                        Показать ещё
                    </ButtonText>
                </div>

                <div
                    v-else
                    ref="map"
                    class="map offers-map"
                    :class="{
                        'offers-map_loading': loading,
                    }"
                >
                    <yandexMap
                        ref="map"
                        :coords="offers[0].coords"
                        zoom="13"
                        :controls="['zoomControl']"
                        :scrollZoom="false"
                        :options="{ suppressMapOpenBlock: true }"
                        style="width: 100%; height: 100%;"
                        @map-was-initialized="setMapCenter"
                        @actionend="handleActionEnd"
                    >
                        <ymapMarker
                            v-for="(offer, index) in offers"
                            :key="'offer-' + offer.id"
                            :ref="'offer-' + offer.id"
                            :coords="offer.coords"
                            :icon="getYMapMarkerIcon({ active: balloon.visible && balloon.index === index })"
                            :markerId="'offer-' + offer.id"
                            @click="showBalloon({ offer, index })"
                        ></ymapMarker>
                    </yandexMap>

                    <OfferBalloon
                        v-if="balloon.visible"
                        :offer="balloon.offer"
                        :modelName="modelName"
                        @close="closeBalloon"
                        @addToCart="onChangeCart(balloon.offer.index, balloon.offer.id)"
                        @deleteFromCart="onDeleteFromCart(balloon.offer.index, balloon.offer.id)"
                    ></OfferBalloon>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import { mapState } from 'vuex';
import { HTTP } from '@/http.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import popup from '@/mixins/popup.js';
import MarketItemOffer from '../MarketItemOffer.vue';
import Spinner from '@/components/Spinner.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import RadioButtonsGroup from '@/components/RadioButtonsGroup.vue';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import OfferBalloon from '@/components/OfferBalloon.vue';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
const MarketItemInOffersPopup = () => import('@/components/MarketItemInOffersPopup.vue');

export default {
    name: 'OffersPopup',

    components: {
        ButtonBlock,
        OfferBalloon,
        RadioButtonsGroup,
        ButtonText,
        Spinner,
        MarketItemInOffersPopup,
        PopupWrap,
        MarketItemOffer,
        yandexMap,
        ymapMarker,
    },

    mixins: [popup, ymapMarkerMixin],

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        modelName: {
            type: String,
            default: '',
        },

        changeCartCallback: {
            type: Function,
            default: () => {},
        },
    },

    data() {
        return {
            initLoading: true,
            mapInstance: null,
            mapCenter: [],
            isOpen: false,
            view: 'list',
            loading: false,
            offers: [],
            rangeData: {
                limit: 50,
                offset: 0,
            },
            hasNext: null,
            source: null,
            balloon: {
                offer: {},
                index: null,
                visible: false,
            },
        };
    },

    computed: {
        ...mapState({
            currentCity: state => state.cities.currentCity,
        }),
    },

    created() {
        this.reload();
    },

    methods: {
        async onChangeSort(value) {
            this.sortData = value;
            await this.reload();
        },

        async fetch() {
            this.loading = true;
            if (this.source) {
                this.source.cancel();
            }
            this.source = HTTP.CancelToken.source();

            const { company, price__gte, price__lte } = this.$route.query;
            const payload = {
                params: {
                    [this.modelName]: this.item.id,
                    in_stock: true,
                    company, price__gte, price__lte,
                    // is_open: this.isOpen,
                    order_by: 'price',
                    limit: this.rangeData.limit,
                    offset: this.rangeData.offset,
                    city: this.currentCity.id,
                },
                cancelToken: this.source.token,
            };

            try {
                const response = await this.getOffers(payload);
                this.source = null;
                this.loading = false;
                return response;
            }
            catch (e) {
                console.error(e);
            }
        },

        async getOffers({ params, cancelToken }) {
            let requestFunction;
            if (this.modelName === 'product') {
                requestFunction = this.$api.esProductsOffers.get;
            }
            else if (this.modelName === 'part_product') {
                requestFunction = this.$api.esPartProductsOffers.get;
            }

            return await requestFunction({ params, cancelToken });
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            this.add(response);
            return response;
        },

        add({ results, next }) {
            const offers = results.map(offer => {
                offer.coords = offer.shop.coordinates.split(', ').map(Number);
                return offer;
            });
            this.offers = [...this.offers, ...offers];
            this.hasNext = next;
        },

        async reload() {
            this.rangeData.offset = 0;
            const response = await this.fetch();
            this.set(response);
            this.initLoading = false;
            return response;
        },

        set({ results, next }) {
            this.offers = results.map(offer => {
                offer.coords = offer.shop.coordinates.split(', ').map(Number);
                return offer;
            });
            this.hasNext = next;
        },

        onChangeCart(index) {
            this.offers[index].in_basket = true;
        },

        onDeleteFromCart(index) {
            this.offers[index].in_basket = false;
        },

        async onChangeView(value) {
            if (value === 'map') {
                this.rangeData.limit = null;
            }
            else {
                this.rangeData.limit = 5;
            }

            await this.reload();
            this.view = value;
        },

        setMapCenter(mapInstance) {
            this.mapInstance = mapInstance;
            if (this.offers.length > 1) {
                const pointsList = this.offers.map(item => item.coords);
                mapInstance.setBounds(ymaps.util.bounds.fromPoints(pointsList));
            }
        },

        handleActionEnd(e) {
            const newCenter = e.originalEvent.map.getCenter();
            if (this.mapCenter[0] !== newCenter[0] || this.mapCenter[1] !== newCenter[1]) {
                this.mapCenter = newCenter;
            }
        },

        showBalloon({ offer, index }) {
            this.closeBalloon();
            this.balloon.visible = true;
            this.balloon.offer = offer;
            this.balloon.index = index;
            this.$nextTick(() => {
                if (this.mapInstance && this.mapInstance.length === 2) this.mapInstance.setCenter(this.mapCenter);
            });
        },

        closeBalloon() {
            this.balloon.visible = false;
            this.balloon.offer = {};
            this.balloon.offerId = null;
            this.balloon.index = null;
            this.$nextTick(() => {
                if (this.mapInstance && this.mapInstance.length === 2) this.mapInstance.setCenter(this.mapCenter);
            });
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 976px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.content-wrap {
    position: relative;
    min-height: 120px;
    overflow: hidden;
}
@media (max-width: 767px) {
    .content-wrap {
        display: flex;
        flex: 1;
    }
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px var(--popup-paddings-x);
}

.toolbar-title {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
    line-height: 1.38;
}
@media (max-width: 767px) {
    .toolbar-title {
        line-height: 1.43;
    }
}

.view-button.button {
    padding-left: 20px;
    padding-right: 20px;
}

.view-button.button >>> .label {
    font-family: var(--f-base);
}

@media (max-width: 767px) {
    .view-button-desktop {
        display: none;
    }
}

@media (min-width: 768px) {
    .view-button-mobile {
        display: none;
    }
}

.view_list {
    width: 100%;
    padding: 0 var(--popup-paddings-x) var(--popup-paddings-y);
    border-top: 1px solid var(--border-light-c);
}

.offer-wrap:last-child {
    margin-bottom: -20px;
}
.offer-wrap:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}

.offers-more-btn {
    display: block;
    margin: 20px auto 0;
}

.offers-list_loading {
    opacity: .3;
}

.offers-list {
    transition: opacity var(--transition);
}

.map {
    background-color: var(--light-bg);
    opacity: 1;
    transition: opacity var(--transition-fastest);
    border-bottom-left-radius: var(--border-radius-x2);
    border-bottom-right-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .map {
        height: 600px;
    }
}
@media (max-width: 767px) {
    .map {
        flex: 1;
    }
}

.map >>> .ymaps-2-1-79-map,
.map >>> .ymaps-2-1-79-inner-panes {
    border-bottom-left-radius: var(--border-radius-x2);
    border-bottom-right-radius: var(--border-radius-x2);
}
</style>