import { mapMutations } from 'vuex';
import { HTTP } from '@/http.js';
import equals from '@/lib/equals.js';
import deepClone from '@/lib/deepClone.js';
import isValidDate from '@ui/utils/isValidDate.js';
import pad from '@ui/utils/pad.js';


export default function() {
    return {
        ...mapMutations({
            update: 'insuranceOsago/updateContract',
        }),

        onError(errors) {
            this.errors = errors;
        },

        async confirm() {
            if (equals(this.formData, this.initFormData)) {
                this.close();
            }
            else {
                const errors = await this.$refs.form.validate();

                if (errors) return;

                this.loading = true;

                try {
                    const url = `/insurance_contract/${ this.uuid }/`;
                    const data = deepClone(this.formData);

                    if (data.vehicle_model) {
                        data.vehicle_model = data.vehicle_model.full_name;
                    }

                    if (data.drivers) {
                        data.drivers = data.drivers.map(driver => {
                            const keys = [
                                'last_name',
                                'first_name',
                                'middle_name',
                                'birth_date',
                                'license_number',
                                'experience_start_date',
                                'prev_license',
                            ];

                            if (driver.prev_license) {
                                keys.push(
                                    'prev_last_name',
                                    'prev_first_name',
                                    'prev_middle_name',
                                    'prev_license_number',
                                    'prev_license_date',
                                );
                            }

                            if (driver.uuid) {
                                keys.push('uuid');
                            }

                            return Object.entries(driver).reduce((acc, [key, value]) => {
                                if (keys.includes(key)) {
                                    acc[key] = value;
                                }

                                return acc;
                            }, {});
                        });
                    }

                    await HTTP.patch(url, data);

                    this.update(this.formData);

                    if (data.action_start_date) {
                        const date = new Date(this.formData.action_start_date);
                        const isValid = isValidDate(date);

                        if (isValid) {
                            this.update({
                                action_end_date: `${ date.getFullYear() + 1 }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`,
                            });
                        }
                    }

                    this.callback(true);
                    this.close();
                }
                catch (error) {
                    this.$refs.form.handle(error);
                    this.$store.commit('handleCommonHttpError', error);
                }
                finally {
                    this.loading = false;
                }
            }
        },
    };
};