<template>
    <div ref="content">
        <RadioButtonsGroup
            v-model="mode"
            name="select-mode"
            :options="computedModeOptions"
            mobileAtomic
            class="select-mode"
        ></RadioButtonsGroup>

        <div class="tabs">
            <component
                :is="animationTag"
                v-bind="animationTagAttrs"
            >
                <div
                    v-if="mode === 'garage'"
                    ref="tab-garage"
                    :key="'mode-garage'"
                    class="tab-content"
                >
                    <component
                        :is="animationTag"
                        v-bind="animationTagAttrs"
                    >
                        <div
                            v-if="isAuthorized && garageCars.length && !garageCarsLoading"
                            key="garage-list"
                        >
                            <h3 class="h4 h4_block">
                                {{ garageCars.length === 1 ? 'Ваш автомобиль' : 'Ваши автомобили' }}
                            </h3>

                            <CarsList
                                v-model="selectedCar"
                                :options="garageCars"
                                listName="garageCars"
                            ></CarsList>

                            <div
                                ref="stickyBlock"
                                class="find__actions sticky-block"
                                :class="{ 'sticky-block_fixed': stickyBlockFixed }"
                            >
                                <ButtonText
                                    underline
                                    secondary
                                    dark
                                    :to="{ name: 'account-garage' }"
                                    class="find__action-link d-inline-block"
                                >Перейти в гараж</ButtonText>
                                <ButtonBlock
                                    primary
                                    class="find__action-btn"
                                    :disabled="!hasSelectedCar"
                                    @click="selectCar"
                                >
                                    Выбрать
                                </ButtonBlock>
                            </div>
                        </div>

                        <div
                            v-if="!isAuthorized"
                            key="garage-authorized"
                        >
                            <h3 class="h4 h4_block">
                                Вы не авторизованы
                            </h3>
                            <div class="find-empty layer-1">
                                <p class="text_base-lh">
                                    <ButtonText
                                        underline
                                        primary
                                        class="d-inline-block"
                                        @click="openSignInPopup"
                                    >Войдите</ButtonText>, чтобы выбрать автомобиль из вашего гаража
                                </p>
                            </div>
                        </div>

                        <div
                            v-if="isAuthorized && garageCars.length === 0 && !garageCarsLoading"
                            key="garage-empty"
                        >
                            <h3 class="h4 h4_block">
                                Здесь пока пусто
                            </h3>
                            <div class="find-empty layer-1">
                                <p>
                                    <ButtonText
                                        dashed
                                        primary
                                        @click="turnOnInnerAddGarageMode"
                                    >Добавьте в гараж</ButtonText> свой первый автомобиль
                                </p>
                            </div>
                        </div>

                        <Spinner
                            v-if="isAuthorized && garageCarsLoading"
                            key="garage-spinner"
                            center
                        ></Spinner>
                    </component>
                </div>

                <div
                    v-if="mode === 'grz'"
                    ref="tab-number"
                    :key="'mode-number'"
                    class="tab-content"
                >
                    <component
                        :is="animationTag"
                        v-bind="animationTagAttrs"
                    >
                        <div v-if="showFindForm">
                            <div class="promo promo_grz">
                                <p class="promo__text">
                                    Новый способ определения авто! Точный и&nbsp;быстрый, как через VIN/Frame, только проще.
                                </p>
                            </div>

                            <h3 class="h4 h4_block">
                                Введите гос. номер автомобиля
                            </h3>

                            <InfoMessage
                                flex
                                class="info-message"
                            >
                                Доступно для марок: Toyota, Nissan, Lexus
                            </InfoMessage>

                            <FormManager
                                ref="numberForm"
                                v-model="numberForm"
                                class="grz-form"
                                @submit="findByNumber"
                            >
                                <FormField
                                    name="carNumber"
                                    upper
                                    visibleMask
                                    mask="A000AA 000"
                                    offModifyLabel
                                    validations="required|re"
                                    :validationRules="{
                                        re: ({ value }) => {
                                            return !value ? false : !/([АВЕКМНОРСТУХ]{1})(\d{3})([АВЕКМНОРСТУХ]{2})\s(\d{2,3})/g.test(value);
                                        }
                                    }"
                                    class="grz-form__input"
                                ></FormField>

                                <div
                                    ref="stickyBlock"
                                    class="sticky-block"
                                    :class="{ 'sticky-block_fixed': stickyBlockFixed }"
                                >
                                    <ButtonBlock
                                        submit
                                        primary
                                        class="grz-form__btn"
                                        :loading="btnLoading"
                                        :disabled="!isGrzFormValid"
                                    >
                                        Определить авто
                                    </ButtonBlock>
                                </div>
                            </FormManager>
                        </div>

                        <div v-if="!showFindForm && foundCars.length">
                            <h3 class="h4 h4_block">
                                Найден автомобиль
                            </h3>

                            <CarsList
                                v-model="selectedCar"
                                :options="foundCars"
                                listName="modelCars"
                            ></CarsList>

                            <div
                                v-if="isAuthorized && hasSelectedCar && !isSelectedCarFromGarage && !addGarageMode"
                                class="mt-20"
                            >
                                <CheckboxBase
                                    id="addToGarage"
                                    v-model="addToGarage"
                                    label="Добавить автомобиль в гараж"
                                ></CheckboxBase>
                            </div>

                            <div
                                ref="stickyBlock"
                                class="find__actions sticky-block"
                                :class="{ 'sticky-block_fixed': stickyBlockFixed }"
                            >
                                <ButtonBlock
                                    secondary
                                    class="find__action-btn"
                                    @click="clearFoundCars"
                                >
                                    Назад
                                </ButtonBlock>
                                <ButtonBlock
                                    primary
                                    class="find__action-btn"
                                    :disabled="!hasSelectedCar"
                                    @click="selectCar"
                                >
                                    Всё верно
                                </ButtonBlock>
                            </div>
                        </div>

                        <div v-if="!showFindForm && foundCars.length === 0">
                            <h3 class="h4 h4_block">
                                Автомобиль не найден
                            </h3>
                            <div class="find-empty layer-1">
                                <p>
                                    <button
                                        class="dashed text_red"
                                        @click="clearFoundCars()"
                                    >
                                        Попробуйте ещё раз
                                    </button> с другим гос. номером.
                                </p>
                            </div>
                        </div>
                    </component>
                </div>

                <div
                    v-if="mode === 'vin_frame'"
                    ref="tab-vin"
                    :key="'mode-vin'"
                    class="tab-content"
                >
                    <component
                        :is="animationTag"
                        v-bind="animationTagAttrs"
                    >
                        <div v-if="showFindForm">
                            <div class="promo promo_vin">
                                <p class="promo__text">
                                    Самый точный и&nbsp;надежный способ определения авто&nbsp;&mdash; ввести уникальный заводской номер.
                                </p>
                            </div>

                            <h3 class="h4 h4_block">
                                Введите VIN или Frame номер автомобиля
                            </h3>

                            <InfoMessage
                                flex
                                class="info-message"
                            >
                                Доступно для марок: Toyota, Nissan, Lexus
                            </InfoMessage>

                            <FormManager
                                ref="vinForm"
                                v-model="vinForm"
                                class="form-flex"
                                @submit="findByVin"
                            >
                                <div class="form-flex__fields">
                                    <FormField
                                        ref="vinFormField"
                                        name="vinNumber"
                                        maxLength="17"
                                        label="VIN/Frame"
                                        offModifyLabel
                                        validations="required|re"
                                        :validationRules="{
                                            re: ({ value }) => {
                                                return !value ? false : !/^[A-z\d]{9,17}$/.test(value)
                                            },
                                        }"
                                        upper
                                    ></FormField>

                                    <p class="form-flex__hint">
                                        Например: <span class="text_bold">XW7BZYHK70S102850</span>. Без&nbsp;дефисов и&nbsp;пробелов.
                                    </p>
                                </div>

                                <div class="form-flex__actions">
                                    <ButtonText
                                        dashed
                                        secondary
                                        dark
                                        class="form-flex__action-link"
                                        @click="showAboutVinFramePopup"
                                    >
                                        Что такое VIN/Frame?
                                    </ButtonText>
                                    <div
                                        ref="stickyBlock"
                                        class="sticky-block"
                                        :class="{ 'sticky-block_fixed': stickyBlockFixed }"
                                    >
                                        <ButtonBlock
                                            submit
                                            primary
                                            class="form-flex__action-btn"
                                            :loading="btnLoading"
                                            :disabled="!isVinFormValid"
                                        >
                                            Определить авто
                                        </ButtonBlock>
                                    </div>
                                </div>
                            </FormManager>
                        </div>

                        <div v-if="!showFindForm && foundCars.length">
                            <h3 class="h4 h4_block">
                                {{ foundCars.length === 1 ? 'Найден автомобиль' : 'Найдены автомобили' }}<span
                                    v-if="foundCars.length > 1"
                                    class="h4-sub"
                                >{{ foundCars.length | number }}</span>
                            </h3>

                            <CarsList
                                v-model="selectedCar"
                                :options="foundCars"
                                listName="modelCars"
                            ></CarsList>

                            <div
                                v-if="isAuthorized && hasSelectedCar && !isSelectedCarFromGarage && !addGarageMode"
                                class="mt-20"
                            >
                                <CheckboxBase
                                    id="addToGarage"
                                    v-model="addToGarage"
                                    label="Добавить автомобиль в гараж"
                                ></CheckboxBase>
                            </div>

                            <div
                                ref="stickyBlock"
                                class="find__actions sticky-block"
                                :class="{ 'sticky-block_fixed': stickyBlockFixed }"
                            >
                                <ButtonBlock
                                    secondary
                                    class="find__action-btn"
                                    @click="clearFoundCars"
                                >
                                    Назад
                                </ButtonBlock>
                                <ButtonBlock
                                    primary
                                    class="find__action-btn"
                                    :disabled="!hasSelectedCar"
                                    @click="selectCar"
                                >
                                    Всё верно
                                </ButtonBlock>
                            </div>
                        </div>

                        <div v-if="!showFindForm && foundCars.length === 0">
                            <h3 class="h4 h4_block">
                                Автомобиль не найден
                            </h3>
                            <div class="find-empty layer-1">
                                <p>
                                    <ButtonText
                                        dashed
                                        primary
                                        @click="clearFoundCars()"
                                    >Попробуйте ещё раз</ButtonText> с другим VIN/Frame.
                                </p>
                            </div>
                        </div>
                    </component>
                </div>

                <div
                    v-if="mode === 'catalog'"
                    ref="tab-catalog"
                    key="mode-catalog"
                    class="tab-content"
                >
                    <component
                        :is="animationTag"
                        v-bind="animationTagAttrs"
                    >
                        <div v-if="showManufacturer">
                            <div class="promo promo_catalog">
                                <p class="promo__text">
                                    Классический способ определения авто. Если вы&nbsp;хорошо знаете параметры своей машины.
                                </p>
                            </div>

                            <h3 class="h4 h4_block">
                                Выберите марку автомобиля
                            </h3>

                            <TransitionHeightGroup>
                                <Spinner
                                    v-if="carManufacturerOptionsLoading"
                                    key="mode-catalog-spinner"
                                    center
                                ></Spinner>
                                <ul
                                    v-else
                                    key="mode-catalog-manufacturers"
                                    class="manufacturers-list"
                                >
                                    <li
                                        v-for="manufacturer in carManufacturerOptions"
                                        :key="'market-part-applicability-manufacturer-' + manufacturer.id"
                                        class="manufacturer-item"
                                    >
                                        <button
                                            type="button"
                                            class="manufacturer-wrap"
                                            @click="handleCarManufacturerSelect(manufacturer)"
                                        >
                                            <CarManufacturerLogo
                                                v-if="manufacturer.codename"
                                                :manufacturer="manufacturer"
                                                color
                                                size="micro"
                                                class="manufacturer-logo"
                                            ></CarManufacturerLogo>
                                            <span class="manufacturer-name">{{ manufacturer.name }}</span>
                                        </button>
                                    </li>
                                </ul>
                            </TransitionHeightGroup>
                        </div>

                        <div v-if="showFindForm">
                            <h3 class="h4 h4_block">
                                Выберите автомобиль из каталога {{ carManufacturer.name }}
                            </h3>

                            <TransitionHeightGroup>
                                <Spinner
                                    v-if="carModelOptionsLoading"
                                    center
                                ></Spinner>
                                <form
                                    v-else
                                    class="form"
                                    @submit.prevent="findByModel"
                                >
                                    <div class="form__first-row">
                                        <Selector
                                            v-model="carModel"
                                            label="Модель"
                                            :options="carModelOptions"
                                            optionLabel="name"
                                            :emptyValue="{}"
                                            :disabled="!carManufacturer.codename"
                                            @change="handleCarModelSelect"
                                        ></Selector>

                                        <Selector
                                            v-model="carYear"
                                            label="Год выпуска"
                                            :options="carYearsOptions"
                                            optionLabel="name"
                                            :emptyValue="{}"
                                            :disabled="!carModel.name"
                                            :loading="carYearsOptionsLoading"
                                            @change="handleCarYearSelect"
                                        ></Selector>

                                        <Selector
                                            v-for="item in attrsSelects"
                                            :key="item.codename"
                                            v-model="attrsValues[item.codename]"
                                            :label="item.name"
                                            :options="item.options"
                                            :getOptionLabel="option => `${ option.value && option.value !== option.name && option.value !== '__not_set__' ? option.value + ' / ' : '' }${ option.name }`"
                                            :emptyValue="{}"
                                            :clearable="item.options.length > 1 || !!userActionsLog.find(action => action.field === item.codename)"
                                            :disabled="!canSearchCarByModel || item.options.length === 1 && !(userActionsLog.find(action => action.field === item.codename))"
                                            @change="handleCarAttrsSelect(item.codename, ...arguments)"
                                        ></Selector>
                                    </div>

                                    <div
                                        ref="stickyBlock"
                                        class="find__actions sticky-block"
                                        :class="{ 'sticky-block_fixed': stickyBlockFixed }"
                                    >
                                        <ButtonBlock
                                            secondary
                                            class="find__action-btn"
                                            @click="handleCarManufacturerSelect({})"
                                        >
                                            Назад
                                        </ButtonBlock>
                                        <ButtonBlock
                                            submit
                                            primary
                                            :loading="btnLoading"
                                            :disabled="!canSearchCarByModel"
                                            class="find__action-btn"
                                        >
                                            Определить авто
                                        </ButtonBlock>
                                    </div>
                                </form>
                            </TransitionHeightGroup>
                        </div>

                        <div v-if="!showManufacturer && !showFindForm && foundCars.length">
                            <h3 class="h4 h4_block">
                                {{ foundCars.length === 1 ? 'Найден автомобиль' : 'Найдены автомобили' }}<span
                                    v-if="foundCars.length > 1"
                                    class="h4-sub"
                                >{{ foundCars.length | number }}</span>
                            </h3>

                            <CarsList
                                v-model="selectedCar"
                                :options="foundCars"
                                listName="modelCars"
                            ></CarsList>

                            <div
                                v-if="isAuthorized && hasSelectedCar && !isSelectedCarFromGarage && !addGarageMode"
                                class="mt-20"
                            >
                                <CheckboxBase
                                    id="addToGarage"
                                    v-model="addToGarage"
                                    label="Добавить автомобиль в гараж"
                                ></CheckboxBase>
                            </div>

                            <div
                                ref="stickyBlock"
                                class="find__actions sticky-block"
                                :class="{ 'sticky-block_fixed': stickyBlockFixed }"
                            >
                                <ButtonBlock
                                    secondary
                                    class="find__action-btn"
                                    @click="clearFoundCars"
                                >
                                    Назад
                                </ButtonBlock>
                                <ButtonBlock
                                    primary
                                    class="find__action-btn"
                                    :loading="btnLoading"
                                    :disabled="!hasSelectedCar"
                                    @click="selectCar"
                                >
                                    Выбрать
                                </ButtonBlock>
                            </div>
                        </div>

                        <div v-if="!showManufacturer && !showFindForm && foundCars.length === 0">
                            <h3 class="h4 h4_block">
                                Автомобиль не найден
                            </h3>
                            <div class="find-empty layer-1">
                                <p>
                                    <ButtonText
                                        dashed
                                        primary
                                        @click="clearFoundCars()"
                                    >Попробуйте ещё раз</ButtonText> с другими параметрами.
                                </p>
                            </div>
                        </div>
                    </component>
                </div>
            </component>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getErrorCode } from '@/lib/errors.js';
import equals from '@/lib/equals.js';
import { mainAttrsArrForSelectCarByModel } from '@/settings.js';
import breakpointKey from '@/mixins/breakpointKey.js';
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';
import RadioButtonsGroup from '@/components/RadioButtonsGroup.vue';
import CarsList from '@/components/CarsList.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import Selector from '@/components/api-inputs/SelectorApi.vue';
import FormField from '@/components/_form/FormField.vue';
import FormManager from '@/components/_form/FormManager.vue';
import CheckboxBase from '@/components/fields/CheckboxBase.vue';
import Spinner from '@/components/Spinner.vue';
import CarManufacturerLogo from '@/components/CarManufacturerLogo.vue';
import TransitionFade from '@/components/_transitions/TransitionFade.vue';
import TransitionHeightGroup from '@/components/_transitions/TransitionHeightGroup.vue';
import InfoMessage from '@/components/InfoMessage.vue';
const AboutVinFramePopup = () => import('@/components/popups/AboutVinFramePopup.vue');
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');

let requestEsCarsParams = {};

export default {
    name: 'SelectCarForm',

    components: {
        InfoMessage,
        TransitionFade,
        CarManufacturerLogo,
        Spinner,
        CheckboxBase,
        FormManager,
        FormField,
        Selector,
        ButtonText,
        ButtonBlock,
        TransitionHeightGroup,
        RadioButtonsGroup,
        CarsList,
    },

    mixins: [
        breakpointKey,
        handleFormErrorMixin,
    ],

    props: {
        modeOptions: {
            type: Array,
            default() {
                return [
                    {
                        label: 'По гос. номеру',
                        value: 'grz',
                    },
                    {
                        label: 'VIN/Frame',
                        value: 'vin_frame',
                    },
                    // {
                    //     label: 'По модели',
                    //     value: 'catalog',
                    // },
                    {
                        label: 'Из гаража',
                        value: 'garage',
                    },
                ];
            },
        },
        outerAddGarageMode: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            mode: 'grz',

            numberForm: {
                carNumber: '',
            },

            vinForm: {
                vinNumber: '',
            },

            addToGarage: false,

            carManufacturer: '',
            carManufacturerOptions: [],
            carManufacturerOptionsLoading: false,

            carModel: '',
            carModelOptions: [],
            carModelOptionsLoading: false,

            canSearchYear: false,
            carYear: '',
            carYearsOptions: [],
            carYearsOptionsLoading: false,

            attrsSelects: [],
            attrsValues: {},
            attrsSelectsLoading: false,

            userActionsLog: [],

            foundCars: [],
            selectedCar: {},

            showManufacturer: true,
            showFindForm: true,
            selectTimeout: null,
            source: null,

            btnLoading: false,
            garageCarsLoading: false,
            innerAddGarageMode: false,

            stickyBlockFixed: false,
        };
    },

    computed: {
        ...mapState({
            isAuthorized: state => state.profile.isAuthorized,
            garageCars: state => state.profileCars.cars,
        }),

        animationTag() {
            if (this.breakpointKey === 's') {
                return 'TransitionFade';
            }
            return 'TransitionHeightGroup';
        },

        animationTagAttrs() {
            if (this.breakpointKey === 's') {
                return {
                    name: 'fade',
                };
            }
            return {};
        },

        isGrzFormValid() {
            return /([АВЕКМНОРСТУХ]{1})(\d{3})([АВЕКМНОРСТУХ]{2})\s(\d{2,3})/g.test(this.numberForm.carNumber);
        },

        isVinFormValid() {
            return /^[A-z\d]{9,17}$/.test(this.vinForm.vinNumber);
        },

        hasSelectedCar() {
            return this.selectedCar && !!this.selectedCar.id;
        },

        canSearchCarByModel() {
            return this.carModel && this.carYear.codename;
        },

        addGarageMode() {
            return this.innerAddGarageMode || this.outerAddGarageMode;
        },

        computedModeOptions() {
            if (this.addGarageMode) {
                return this.modeOptions.filter(item => item.value !== 'garage');
            }
            return this.modeOptions;
        },

        isSelectedCarFromGarage() {
            if (this.isAuthorized && this.hasSelectedCar) {
                let foundCar = this.garageCars.find(item => {
                    if (item.car.id === this.selectedCar.id) return item;
                    else return false;
                });
                return ((foundCar || {}).car || {}).id || false;
            }
            return false;
        },
    },

    watch: {
        mode(newValue, oldValue) {
            if (newValue === 'garage') {
                if (!this.garageCars.length) this.getGarageCars();
            }

            if (newValue === 'catalog') {
                this.showManufacturer = true;
                if(this.carManufacturerOptions.length === 0) {
                    this.getManufacturers();
                }
            }

            if (oldValue === 'catalog') {
                this.clearSelectCarByModelData();
            }

            this.clearFoundCars();
            this.showFindForm = newValue !== 'catalog';
            this.$nextTick(() => {
                this.calcFixedBlockPosition();
            });
        },

        showFindForm() {
            this.$nextTick(() => {
                this.calcFixedBlockPosition();
            });
        },
    },

    async created() {
        if (this.isAuthorized && this.modeOptions.find(item => item.value === 'garage')) {
            if (!this.garageCars.length) {
                await this.GET_CARS();
            }
        }
    },

    mounted() {
        if (this.isAuthorized && this.modeOptions.find(item => item.value === 'garage') && this.garageCars.length) {
            this.mode = 'garage';
        }
        this.calcFixedBlockPosition();
        window.visualViewport.addEventListener('resize', this.calcFixedBlockPosition);
    },

    beforeDestroy() {
        window.visualViewport.removeEventListener('resize', this.calcFixedBlockPosition);
    },

    methods: {
        ...mapActions({
            GET_CARS: 'profileCars/getCars',
            SAVE_CAR: 'profileCars/saveCar',
        }),

        async getManufacturers() {
            this.carManufacturerOptionsLoading = true;
            const params = {
                order_by: 'name',
            };

            const { results } = await this.$api.manufacturers.get({ params });
            if (results) this.carManufacturerOptions = results;
            this.carManufacturerOptionsLoading = false;
        },

        async handleCarManufacturerSelect(option) {
            this.carModel = '';
            this.carModelOptions = [];
            this.canSearchYear = false;
            this.carYear = '';
            this.carYearsOptions = [];
            this.attrsSelects = [];
            this.attrsValues = {};
            this.userActionsLog = [];

            if (option.codename) {
                this.showManufacturer = false;
                this.showFindForm = true;
                this.carManufacturer = option;
                this.carModelOptionsLoading = true;

                const { facets } = await this.requestEsCars('families,characteristics');
                if (facets.families) this.carModelOptions = facets.families;
                if (facets.characteristics) {
                    const mainAttrs = mainAttrsArrForSelectCarByModel[this.carManufacturer.codename];
                    mainAttrs.forEach((attr) => {
                        const selectFromFacets = facets.characteristics.find(facet => facet.codename === attr.codename);
                        if (selectFromFacets) {
                            selectFromFacets.name = attr.label;
                            this.attrsSelects.push(selectFromFacets);
                        }
                    });
                }
                this.carModelOptionsLoading = false;
            }
            else {
                this.showFindForm = false;
                this.showManufacturer = true;
                this.carManufacturer = '';
            }
        },

        async handleCarModelSelect(option) {
            this.carYear = '';
            this.carYearsOptions = [];
            this.attrsValues = {};
            this.userActionsLog = [];

            if (option.id) {
                this.carModel = option;
                this.carYearsOptionsLoading = true;
                this.canSearchYear = true;

                const { facets } = await this.requestEsCars('years');
                if (facets.years) this.carYearsOptions = facets.years;
                this.carYearsOptionsLoading = false;
            }
            else {
                this.carModel = '';
                this.canSearchYear = false;
            }
        },

        async handleCarYearSelect(option) {
            this.attrsValues = {};
            this.userActionsLog = [];

            this.carYear = option;
            if (option.codename) {
                this.attrsSelectsLoading = true;
                this.userActionsLog.push({
                    field: 'carYear',
                    blocked: [],
                });

                await this.handleCarAttrsChange('carYear', option);
                this.attrsSelectsLoading = false;
            }
        },

        handleCarAttrsSelect(fieldCodename, option) {
            this.attrsValues[fieldCodename] = option;

            const fieldIndexInLog = this.userActionsLog.findIndex(action => action.field === fieldCodename);
            // если поле уже есть в логе
            if (fieldIndexInLog > -1) {
                // выбираю все селекторы, которые идут после текущего в логе и очищаю у них выбранные значения и options
                for (let i = fieldIndexInLog; i < this.userActionsLog.length; i++) {
                    let { blocked } = this.userActionsLog[i];

                    blocked.forEach(item => {
                        let select = this.attrsSelects.find(select => select.codename === item);
                        select.options = [];
                        this.attrsValues[item] = '';
                    });
                }
                let startIndex = option.name ? fieldIndexInLog + 1 : fieldIndexInLog;
                this.userActionsLog.splice(startIndex, 100); // очищаю лог
            }
            else {
                this.userActionsLog.push({
                    field: fieldCodename,
                    blocked: [],
                });
            }

            this.handleCarAttrsChange(fieldCodename, option);
        },

        async handleCarAttrsChange(fieldCodename, option) {
            const { facets } = await this.requestEsCars('characteristics');

            if (facets.characteristics) {
                facets.characteristics.forEach((select) => {
                    const fieldIndexInLog = this.userActionsLog.findIndex(action => action.field === select.codename);
                    // Фильтрую и обновляю опции, если селектор не выбран пользователем
                    if (fieldIndexInLog === -1 && select.name) {
                        select.options = select.options.filter(option => option.name && (option.value || option.id));
                        let currentSelect = this.attrsSelects.find(attrsSelect => attrsSelect.codename === select.codename);
                        if (currentSelect) currentSelect.options = select.options;
                    }
                });

                if (facets.characteristics.length) {
                    this.attrsSelects = this.attrsSelects.reduce((acc, select) => {
                        const fieldIndexInLog = this.userActionsLog.findIndex(action => action.field === select.codename);
                        const selectInFacets = facets.characteristics.findIndex(facet => facet.codename === select.codename);
                        if (fieldIndexInLog > -1 || selectInFacets > -1) acc.push(select);

                        return acc;
                    }, []);
                }

                this.attrsSelects.forEach(select => {
                    if (select.options.length === 1 && select.codename !== fieldCodename) {
                        const fieldIndexInLog = this.userActionsLog.findIndex(action => action.field === select.codename);
                        if (fieldIndexInLog === -1) {
                            this.$set(this.attrsValues, select.codename, select.options[0]);
                            let blockedCount = 0;
                            this.userActionsLog.forEach(action => {
                                let isAlreadyBlocked = action.blocked.some(field => field === select.codename);
                                if (isAlreadyBlocked) blockedCount += 1;
                            });
                            if (blockedCount === 0) {
                                let userAction = this.userActionsLog.find(action => action.field === fieldCodename);
                                userAction.blocked.push(select.codename);
                            }
                        }
                    }
                });

                let newParams = {
                    manufacturer: this.carManufacturer.codename,
                    mark_model: this.carManufacturer.name + ' ' + this.carModel.name,
                    start_year__lte: this.carYear.codename,
                    end_year__gte: this.carYear.codename,
                };

                for (let key in this.attrsValues) {
                    if (hasOwnProperty.call(this.attrsValues, key)) {
                        if (this.attrsValues[key].value !== '__not_set__') {
                            newParams[key] = this.attrsValues[key].value;
                        }
                    }
                }

                if (!equals(requestEsCarsParams, newParams)) await this.handleCarAttrsChange(fieldCodename, option);
            }
        },

        async findByModel() {
            this.btnLoading = true;
            const cars = await this.requestEsCars('', 50);
            if (cars) {
                this.foundCars = cars.results;
                this.selectedCar = cars.results.length === 1 ? cars.results[0] : {};
            }
            else  {
                this.foundCars = [];
                this.selectedCar = {};
            }
            this.showFindForm = false;
            this.btnLoading = false;
        },

        async requestEsCars(facets = 'all', limit = 0) {
            let params = {
                manufacturer: this.carManufacturer.codename,
                start_year__lte: this.carYear.codename,
                end_year__gte: this.carYear.codename,
            };

            if (facets === 'years' || facets === 'characteristics' || facets === 'all' || facets === '') {
                params.mark_model = this.carManufacturer.name + ' ' + this.carModel.name;
            }
            else {
                params.manufacturer = this.carManufacturer.codename;
                params.family = this.carModel.id;
            }

            for (let key in this.attrsValues) {
                if (hasOwnProperty.call(this.attrsValues, key)) {
                    if (this.attrsValues[key].value !== '__not_set__') {
                        params[key] = this.attrsValues[key].value;
                    }
                }
            }

            requestEsCarsParams = params;

            let requestParams = Object.assign({
                limit,
            }, params);

            if (facets) requestParams.get_facets = facets;

            try {
                return await this.$api.esCars.get({ params: requestParams });
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
        },

        async findByNumber() {
            const errors = await this.$refs.numberForm.validate();
            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                const data = {
                    number: this.numberForm.carNumber.replace(' ', ''),
                };

                this.btnLoading = true;
                this.$api.cars.grzSearch(data)
                    .then(response => {
                        this.foundCars = response;
                        this.selectedCar = response.length === 1 ? response[0] : {};
                        this.showFindForm = false;
                    })
                    .catch(error => {
                        this.$store.commit('handleCommonHttpError', error);
                        const code = getErrorCode(error);
                        const data = error.response.data;

                        if (code === 400) {
                            const { number } = data;
                            if (number) {
                                this.$error(number[0].message);
                            }
                        }
                        this.foundCars = [];
                        this.selectedCar = {};
                    })
                    .finally(() => {
                        this.btnLoading = false;
                    });
            }
        },

        async findByVin() {
            const errors = await this.$refs.vinForm.validate();
            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                const data = {
                    number: this.vinForm.vinNumber,
                };

                this.btnLoading = true;
                this.$api.cars.vinSearch(data)
                    .then(response => {
                        this.foundCars = response;
                        this.selectedCar = response.length === 1 ? response[0] : {};
                    })
                    .catch(() => {
                        this.foundCars = [];
                        this.selectedCar = {};
                    })
                    .finally(() => {
                        this.showFindForm = false;
                        this.btnLoading = false;
                    });
            }
        },

        async selectCar() {
            if (this.addGarageMode) {
                await this.saveCarInGarage();
            }
            else {
                if (this.addToGarage) {
                    await this.saveCarInGarage();
                }
                const data = Object.assign({
                    grz: this.numberForm.carNumber,
                    vin_frame: this.vinForm.vinNumber || this.selectedCar.vin_frame || '',
                    prod_date: this.mode === 'catalog' ? { day: null, month: null, year: this.carYear.name } : this.selectedCar.prod_date,
                }, this.selectedCar);
                this.$emit('select', data);
            }
        },

        clearSelectCarByModelData() {
            this.carModel = '';
            this.carManufacturer = '';
            this.canSearchYear = false;
            this.carYear = '';
            this.carYearsOptions = [];
            this.attrsSelects = [];
            this.attrsValues = {};

            this.userActionsLog = [];
            requestEsCarsParams = {};
        },

        clearFoundCars() {
            this.selectedCar = {};
            this.foundCars = [];
            this.showFindForm = true;
            this.numberForm.carNumber = '';
            this.vinForm.vinNumber = '';
        },

        showAboutVinFramePopup() {
            this.$popup(AboutVinFramePopup);
        },

        async getGarageCars() {
            this.garageCarsLoading = true;
            await this.$store.dispatch('profileCars/getCars');
            setTimeout(() => {
                this.garageCarsLoading = false;
            }, 500);
        },

        turnOnInnerAddGarageMode() {
            this.innerAddGarageMode = true;
            this.mode = 'grz';
            this.$emit('turnOnInnerAddGarageMode');
        },

        async saveCarInGarage() {
            this.btnLoading = true;
            try {
                const data = {
                    car: this.selectedCar.id,
                    grz: this.numberForm.carNumber,
                    vin_frame: this.vinForm.vinNumber || this.selectedCar.vin_frame || '',
                    prod_date: this.mode === 'catalog' ? { day: null, month: null, year: this.carYear.name } : this.selectedCar.prod_date,
                };
                const response = await this.SAVE_CAR(data);
                this.$store.commit('profileCars/addCar', { car: response });
                this.$notify({
                    type: 'success',
                    message: 'Автомобиль добавлен в гараж',
                });

                if (this.outerAddGarageMode) {
                    this.$emit('select', this.selectedCar);
                }

                if (this.innerAddGarageMode) {
                    this.innerAddGarageMode = false;
                    this.mode = 'garage';
                }
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);

                const code = getErrorCode(error);
                const data = error.response.data;

                if (code === 400) {
                    const { car } = data;
                    if (car) {
                        this.$error('Произошла ошибка, попробуйте позже');
                    }
                }
            }
            this.btnLoading = false;
        },

        calcFixedBlockPosition() {
            if (window.innerWidth < 768) {
                setTimeout(() => {
                    if (this.$refs.content && this.$refs.stickyBlock) {
                        const contentHeight = this.$refs.content.offsetHeight;
                        const contentOffsetTop = this.$refs.content.offsetTop;
                        const stickyBlockHeight = this.$refs.stickyBlock.offsetHeight;
                        this.stickyBlockFixed = contentHeight + contentOffsetTop + stickyBlockHeight <= window.visualViewport.height;
                    }
                }, 250);
            }
            else {
                this.stickyBlockFixed = false;
            }
        },

        openSignInPopup() {
            const callback = () => {
                if (this.modeOptions.find(item => item.value === 'garage')) {
                    if (!this.garageCars.length) {
                        this.GET_CARS();
                    }
                }
            };
            const props = { callback };
            const options = { props };

            this.$popup(SignInPopup, options);
        },
    },
};
</script>

<style scoped>
@media (max-width: 767px) {
    .sticky-block {
        position: sticky;
        bottom: 0;
        z-index: 2;
        width: calc(100% + var(--content-gap) * 2);
        margin-top: auto !important;
        margin-bottom: -36px;
        margin-left: var(--content-gap-negative);
        padding: 20px var(--content-gap);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 15%);
    }

    .sticky-block_fixed {
        position: fixed;
        width: 100%;
        /*bottom: 20px;*/
        margin-bottom: 0;
        z-index: 2;
    }
}

.select-mode {
    margin-bottom: var(--gap-y-mini);
}
@media (max-width: 767px) {
    .select-mode.radio-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--grid-gap);
    }
}

.promo {
    position: relative;
    overflow: hidden;
    background-color: var(--bright-bg);
    border: 1px solid var(--grey-light-color);
    border-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .promo {
        margin-bottom: 36px;
        padding: 20px;
    }

    .promo::after {
        width: 240px;
        height: 112px;
        right: 24px;
    }
}
@media (max-width: 767px) {
    .promo {
        margin-bottom: 20px;
        padding: 16px;
    }

    .promo::after {
        width: 170px;
        height: 80px;
        right: -30px;
    }

    .promo_vin::after {
        width: 113px;
        height: 80px;
        right: -10px;
        background-image: url(../../assets/images/select-car-vin-image-xs.jpg);
    }
}
@media (min-width: 375px) {
    .promo {
        display: flex;
        align-items: center;
        min-height: 80px;
    }

    .promo::after {
        content: "";
        position: absolute;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;
    }
}

.promo_grz::after {
    background-image: url(../../assets/images/select-car-number-image.jpg);
}
.promo_vin::after {
    background-image: url(../../assets/images/select-car-vin-image.jpg);
}
.promo_catalog::after {
    background-image: url(../../assets/images/select-car-model-image.jpg);
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
    .promo_grz::after {
        background-image: url(../../assets/images/select-car-number-image-x2.jpg);
    }

    .promo_vin::after {
        background-image: url(../../assets/images/select-car-vin-image-x2.jpg);
    }

    @media (max-width: 767px) {
        .promo_vin::after {
            background-image: url(../../assets/images/select-car-vin-image-xs-x2.jpg);
        }
    }

    .promo_catalog::after {
        background-image: url(../../assets/images/select-car-model-image-x2.jpg);
    }
}

.promo__text {
    position: relative;
    z-index: 2;
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .promo__text {
        width: 272px;
    }
}
@media (max-width: 767px) {
    .promo__text {
        font-size: var(--small-fz);
        line-height: 1.5;
    }
}
@media (min-width: 375px) and (max-width: 767px) {
    .promo__text {
        width: calc(100% - 170px + 30px);
    }

    .promo_vin .promo__text {
        width: calc(100% - 113px + 10px);
    }
}

.info-message {
    margin-bottom: 16px;
}
@media (max-width: 767px) {
    .info-message {
        margin-bottom: 12px;
    }
}

.manufacturers-list {
    display: grid;
    grid-gap: 16px var(--grid-gap);
    font-family: var(--f-semi-bold);
}
@media (min-width: 768px) {
    .manufacturers-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 767px) {
    .manufacturers-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

.manufacturer-wrap {
    display: flex;
    align-items: center;
}

.manufacturer-logo {
    margin-right: 16px;
}

@media (min-width: 768px) {
    .find-empty {
        padding: 20px;
    }
}
@media (max-width: 767px) {
    .find-empty {
        padding: 12px 16px;
    }
}

.find-empty p {
    line-height: var(--base-lh);
}

.find__actions {
    margin-top: 36px;
}
@media (min-width: 768px) {
    .form-flex__actions,
    .find__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .form-flex__actions .form-flex__action-btn:not(:last-child),
    .find__actions .find__action-btn:not(:last-child) {
        margin-right: 20px;
    }

    .form-flex__action-link,
    .find__action-link {
        margin-right: auto;
    }
}
@media (max-width: 767px) {
    .find__action-link {
        display: block;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
    }

    .form-flex__action-btn,
    .find__action-btn {
        width: 100%;
    }

    .form-flex__actions :not(:last-child),
    .find__actions :not(:last-child) {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) {
    .form-flex__fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--grid-gap);
        align-items: center;
    }
}

@media (min-width: 768px) {
    .form-flex__actions {
        margin-top: 36px;
    }
}
@media (max-width: 767px) {
    .form-flex__actions {
        margin-top: 20px;
    }
}

.form-flex__hint {
    font-size: var(--small-fz);
    line-height: var(--small-lh);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .form-flex__hint {
        width: 230px;
    }
}
@media (max-width: 767px) {
    .form-flex__hint {
        margin-top: 8px;
    }
}

@media (min-width: 768px) {
    .form__first-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .form__first-row > :not(:last-child) {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) {
    .grz-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .grz-form__btn {
        width: 100%;
    }
}
</style>