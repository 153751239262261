var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.initialized)?_c('Spinner',{staticClass:"top-gap_medium",attrs:{"size":"big","center":""}}):[_c('section',{staticClass:"header layer-1"},[_c('div',{staticClass:"header-main"},[_c('div',{class:_vm.logoClass + '-wrap'},[_c('ShopLogo',{class:['logo', _vm.logoClass],attrs:{"shop":_vm.shop,"coverCodename":"detail","noLink":""}})],1),_vm._v(" "),_c('h1',{staticClass:"h1 mb-8 shop__name"},[_vm._v("\n                    "+_vm._s(_vm.shop.page_title)+"\n                ")]),_vm._v(" "),_c('ShopAddress',{staticClass:"shop__address shop__address_desktop mb-8",attrs:{"shop":_vm.shop}}),_vm._v(" "),_c('div',{staticClass:"shop__address_mobile mb-8"},[_c('ShopAddress',{staticClass:"mb-4",attrs:{"shop":_vm.shop,"showOnMap":false}}),_vm._v(" "),_c('ShopAddress',{attrs:{"shop":_vm.shop,"dashed":"","secondary":"","dark":""}},[_vm._v("Показать на карте")])],1),_vm._v(" "),_c('ShopOpenCloseTime',{attrs:{"shopId":_vm.shop.id,"status":_vm.shop.current_work,"tooltipData":_vm.shop}}),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('LikeBtn',{staticClass:"actions__btn",attrs:{"id":_vm.shop.id,"value":_vm.shop.is_favorite,"apiName":"shop","withText":"","noTextOnXS":""},on:{"changeFavorite":_vm.onChangeFavorite}}),_vm._v(" "),_c('ChatBtn',{staticClass:"actions__btn",attrs:{"id":_vm.shop.id,"isOnline":_vm.shop.current_work.place.is_active,"disabled":!_vm.shop.is_chat_active,"withText":"","btnText":"Чат с магазином"}})],1)],1),_vm._v(" "),_c('MarketItemNavigation',{attrs:{"items":[
                    {
                        to: {
                            name: 'market-shop-stock',
                            params: _vm.$route.params
                        },
                        label: 'Ассортимент',
                        nested: ['market-shop-products', 'market-shop-new-parts', 'market-shop-contract-parts'],
                    },
                    {
                        to: {
                            name: 'market-shop-reviews',
                            params: {
                                codename: _vm.shop.company.codename,
                                id: _vm.shop.id,
                            }
                        },
                        label: 'Отзывы',
                        amount: _vm.shop.reviews_amount,
                    },
                    {
                        to: {
                            name: 'market-shop-about',
                        },
                        label: 'Информация',
                    } ]}})],1),_vm._v(" "),_c('RouterView'),_vm._v(" "),_c('SeoBlock',{scopedSlots:_vm._u([(_vm.shop.seo_description)?{key:"seo",fn:function(){return [_vm._v(_vm._s(_vm.shop.seo_description))]},proxy:true}:null],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }