<template>
    <div class="post-editor">
        <div
            v-for="block in content"
            :class="'post-editor__section_' + block.classSuffix"
        >
            <div
                class="indent-wrap"
                :class="[
                    block.marginTop ? 'indent-wrap_mt' + block.marginTop : '',
                    block.marginBottom ? 'indent-wrap_mb' + block.marginBottom : '',
                    block.paddingTop ? 'indent-wrap_pt' + block.paddingTop : '',
                    block.paddingBottom ? 'indent-wrap_pb' + block.paddingBottom : '',
                ]"
            >
                <div
                    v-for="(column, columnIndex) in block.columns"
                    :key="'post-editor-column-' + columnIndex"
                    class="post-editor-column"
                >
                    <PostEditorWidgetSwitcher
                        v-for="(widget, widgetIndex) in column.widgets"
                        :key="'post-editor-column-' + columnIndex + '-widget-' + widgetIndex"
                        :widget="widget"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PostEditorWidgetSwitcher from './PostEditorWidgetSwitcher.vue';

export default {
    name: "PostEditorView",

    components: {
        PostEditorWidgetSwitcher,
    },

    props: {
        content: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>

<style>
@import '../../assets/css/post-editor.css';
</style>