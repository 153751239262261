<template>
    <div
        :class="[
            'post-editor__block',
            'post-editor__block_' + widget.type,
        ]"
    >
        <div
            :class="[
                widget.marginTop ? 'indent-wrap_mt' + widget.marginTop : '',
                widget.marginBottom ? 'indent-wrap_mb' + widget.marginBottom : '',
                widget.paddingTop ? 'indent-wrap_pt' + widget.paddingTop : '',
                widget.paddingBottom ? 'indent-wrap_pb' + widget.paddingBottom : '',
            ]"
            class="indent-wrap"
        >
            <p
                v-if="widget.title"
                class="h2"
            >
                {{ widget.title }}
            </p>
            <p
                v-if="widget.descriptor"
                class="widget__descriptor"
            >
                {{ widget.descriptor }}
            </p>

            <ButtonBlock
                v-if="widget.buttonType === 'popup'"
                primary
                class="widget__btn"
                @click="showPopup"
            >
                {{ widget.label }}
            </ButtonBlock>
            <ButtonBlock
                v-if="widget.buttonType === 'link'"
                :href="widget.link"
                primary
                target="_blank"
                class="widget__btn"
            >
                {{ widget.label }}
            </ButtonBlock>
        </div>
    </div>
</template>

<script>
import ButtonBlock from "../_buttons/ButtonBlock.vue";

export default {
    name: "PostEditorButton",

    components: {ButtonBlock},

    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    methods: {
        popupComponent(popupName) {
            const popups = {
                WriteUsPopup: () => import('../popups/WriteUsPopup.vue'),
            };
            return popups[popupName];
        },

        showPopup() {
            this.$popup(this.popupComponent(this.widget.popupCodename));
        },
    },
};
</script>

<style scoped>
.post-editor__block_button {
    margin: 0;
    background-color: var(--bright-bg);
    border: 1px solid var(--border-light-c);
    box-sizing: border-box;
    border-radius: var(--border-radius-x2);
}
@media (min-width: 1040px) {
    .post-editor__block_button {
        padding: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .post-editor__block_button {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .post-editor__block_button {
        padding: 20px;
    }
}

.widget__descriptor {
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .widget__descriptor:not(:first-child) {
        margin-top: 20px;
    }

    .widget__btn:not(:first-child) {
        margin-top: 24px;
    }
}
@media (max-width: 767px) {
    .widget__descriptor:not(:first-child) {
        margin-top: 12px;
    }
}

@media (max-width: 767px) {
    .widget__btn:not(:first-child) {
        margin-top: 20px;
    }

    .widget__btn {
        width: 100%;
    }
}
</style>