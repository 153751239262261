<template>
    <transition name="fade">
        <div
            v-show="isVisible"
            class="btn-to-top-wrap"
        >
            <button
                class="btn-to-top"
                aria-label="Наверх"
                @click="scrollTop"
            >
                <UIcon
                    name="arrow-up"
                    class="btn-to-top__icon"
                ></UIcon>
            </button>
        </div>
    </transition>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'ScrollTopBtn',

    components: { UIcon },

    data() {
        return {
            isVisible: false,
            lastScrollTop: 0,
        };
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },

    beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            const minScrollDelta = 70;
            const windowScroll = window.pageYOffset;
            const windowHeight = window.outerHeight;
            let st = window.pageYOffset;

            if (st === 0) this.showStickyHeaderOnTopScroll = false;

            if (Math.abs(this.lastScrollTop - st) <= minScrollDelta) return;
            if (windowScroll < windowHeight/2) {
                this.isVisible = false;
                return;
            }

            if (st > this.lastScrollTop) { // Scroll Down
                this.isVisible = false;
            }
            else { // Scroll Up
                this.isVisible = st <= this.lastScrollTop;
            }

            this.lastScrollTop = st;
        },

        scrollTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.isVisible = false;
        },
    },
};
</script>

<style scoped>
.btn-to-top-wrap {
    position: fixed;
    bottom: 24px;
    left: 24px;
    z-index: var(--scroll-top-btn);
}

.btn-to-top {
    background-color: rgba(70, 74, 80, 0.4);
    border-radius: 50%;
    transition: background-color var(--transition);
}
@media (min-width: 768px) {
    .btn-to-top {
        width: 60px;
        height: 60px;
        padding: 18px;
    }
}
@media (max-width: 767px) {
    .btn-to-top {
        width: 48px;
        height: 48px;
        padding: 14px;
    }
}

.btn-to-top:hover {
    background-color: rgba(70, 74, 80, 0.6);
}

.btn-to-top__icon {
    fill: var(--light-c);
}

@media (min-width: 768px) {
    .btn-to-top__icon {
        width: 24px;
        height: 24px;
    }
}
@media (max-width: 767px) {
    .btn-to-top__icon {
        width: 20px;
        height: 20px;
    }
}
</style>