<template>
    <div class="about-brand-page">
        <section class="bottom-gap_medium">
            <h1 class="h2 h2_block">
                О бренде
            </h1>

            <div class="about-brand-page__section layer-1">
                <p class="about-brand-page__content p-textarea">{{ brand.description }}</p>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: 'MarketBrandAboutPage',

    computed: {
        ...mapState({
            brand: state => state.marketBrand.brand,
        }),
    },
};
</script>

<style scoped>
.about-brand-page__section {
    padding: var(--base-card-padding);
}

@media (min-width: 1281px) {
    .about-brand-page__content {
        max-width: 1000px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .about-brand-page__content {
        max-width: 780px;
    }
}
</style>