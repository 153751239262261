<template>
    <AccountLayout title="Отзывы">
        <template>
            <section>
                <div
                    v-if="!initialized"
                    class="spinner-wrap"
                >
                    <Spinner
                        center
                        size="big"
                    ></Spinner>
                </div>

                <template v-else>
                    <MarketItemNavigation
                        class="layer-1 bottom-gap_grid"
                        small
                        :items="[
                            {
                                to: {
                                    name: 'account-reviews-parts'
                                },
                                label: 'Запчасти',
                                amount: partsCount
                            },
                            {
                                to: {
                                    name: 'account-reviews-products'
                                },
                                label: 'Автотовары',
                                amount: productsCount
                            },
                            {
                                to: {
                                    name: 'account-reviews-shops'
                                },
                                label: 'Магазины',
                                amount: shopsCount
                            },
                        ]"
                    ></MarketItemNavigation>

                    <transition
                        name="fade-page"
                        mode="out-in"
                    >
                        <RouterView></RouterView>
                    </transition>
                </template>
            </section>
        </template>
    </AccountLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import profileReviews from '@/store/modules/profileReviews.js';
import MarketItemNavigation from "@/components/MarketItemNavigation.vue";
import Spinner from "@/components/Spinner.vue";
import AccountLayout from "@/pages/account/AccountLayout.vue";


export default {
    name: 'ProfileReviews',

    components: {
        AccountLayout,
        Spinner,
        MarketItemNavigation,
    },

    serverPrefetch() {
        return this.INIT();
    },

    computed: {
        ...mapState({
            initialized: state => state.profileReviews.initialized,
            partsCount: state => state.profileReviews.parts.current_count,
            productsCount: state => state.profileReviews.products.current_count,
            shopsCount: state => state.profileReviews.shops.current_count,
        }),

        ...mapGetters({
            priorityRoute: 'profileReviews/priorityRoute',
        }),
    },

    created() {
        this.$store.registerModule('profileReviews', profileReviews);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('profileReviews');
    },

    methods: {
        ...mapMutations({
            DESTROY: 'profileReviews/destroy',
        }),

        ...mapActions({
            INIT: 'profileReviews/init',
        }),

        async init() {
            if (!this.initialized) await this.INIT();

            if (this.$route.name === 'account-reviews') {
                this.redirect();
            }
        },

        redirect() {
            this.$router.replace({
                name: this.priorityRoute,
            });
        },
    },
};
</script>

<style scoped>
.spinner-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}
</style>