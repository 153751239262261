<template>
    <div class="u-field-checkbox">
        <UCheckbox
            :invalid="invalid"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template #label>
                <slot name="label"></slot>
            </template>
        </UCheckbox>

        <UFormFieldError
            :show="invalid"
            class="u-field-checkbox__error"
        >
            {{ error }}
        </UFormFieldError>
    </div>
</template>

<script>
import fieldMixin from '@ui/components/UForm/fields/fieldMixin.js';
import UCheckbox from '@ui/components/UCheckbox/UCheckbox.vue';
import UFormFieldError from '@ui/components/UForm/UFormFieldError.vue';


export default {
    name: 'UFieldCheckbox',

    components: { UFormFieldError, UCheckbox },

    mixins: [fieldMixin],

    data() {
        return {
            initialValue: false,
        };
    },
};
</script>

<style scoped>
.u-field-checkbox__error {
    margin-top: 8px;
}
</style>