import reviews from '@/api/parts/reviews.js';


export default ({ HTTP }) => ({
    reviews: reviews({ HTTP }),

    async get(...args) {
        let config;
        let id;
        if (args && args.length) {
            if (typeof args[0] === 'string' || typeof args[0] === 'number') {
                id = args[0];
                config = args[1];
            }
            else {
                config = args[0];
            }
        }

        if (!config) config = {};

        if (id) {
            const url = `/parts/${ id }/`;
            const response = await HTTP.get(url, config);
            return response.data;
        }
        else {
            const url = '/parts/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async applicability(id, config) {
        const url = `/parts/${ id }/applicability/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },
});
