export default {
    namespaced: true,

    state: {
        count: 0,
    },

    mutations: {
        increment(state) {
            state.count++;
        },

        decrement(state) {
            if (state.count) state.count--;
        },

        set(state, value) {
            state.count = value;
        },
    },
};