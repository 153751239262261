export default {
    namespaced: true,

    state: () => ({
        cars: [],
        initialized: false,
    }),

    mutations: {
        setCars(state, cars) {
            state.cars = cars.map(item => {
                item.car = Object.assign({
                    grz: item.grz,
                    vin_frame: item.vin_frame,
                    prod_date: item.prod_date,
                }, item.car);
                return item;
            });
        },

        addCar(state, { car }) {
            car.car = Object.assign({
                grz: car.grz,
                vin_frame: car.vin_frame,
                prod_date: car.prod_date,
            }, car.car);

            state.cars.push(car);
        },

        removeCar(state, { index }) {
            state.cars.splice(index, 1);
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.initialized = false;
            state.cars = [];
        },
    },

    actions: {
        async init({ commit, dispatch }) {
            try {
                const [cars, policies] = await Promise.all([this.$api.users.getCars(), dispatch('getPolicies')]);

                if (policies.results.length) {
                    policies.results.forEach(policy => {
                        const car = cars.find(car => car.grz === policy.contract.vehicle_license_plate);
                        if (car) {
                            car.policy_number = policy.policy_number;
                        }
                    });
                }

                commit('setCars', cars);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { cars };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getCars({ commit }) {
            try {
                const cars = await this.$api.users.getCars();
                commit('setCars', cars);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async saveCar({}, data) {
            return await this.$api.users.saveCar({ data });
        },

        async removeCar({ commit }, { uuid, index }) {
            try {
                await this.$api.users.removeCar({ uuid });
                commit('removeCar', { index: index });
                return uuid;
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getPolicies() {
            return await this.$api.insuranceOffer.get();
        },
    },
};