<template>
    <div
        class="name-wrap"
        :class="[
            base && 'name-wrap_base',
        ]"
    >
        <component
            :is="tag"
            v-bind="attrs"
            :class="[
                'name',
                !noLink && 'link-primary-hover',
            ]"
            @click="handleClick"
        >
            <p>
                {{ shop.company.name }}
            </p>

            <InfoIcon
                v-if="showPopupToggler"
                class="ml-8"
            ></InfoIcon>
        </component>

        <ShopInfoTooltip
            v-if="hint && !showPopupToggler"
            :shop="shop"
            class="ml-8"
        ></ShopInfoTooltip>
    </div>
</template>

<script>
import ShopOpenCloseTime from '@/components/ShopOpenCloseTime.vue';
import ShopAddress from '@/components/ShopAddress.vue';
import ShopWorkSchedule from '@/components/ShopWorkSchedule.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InfoIcon from '@/components/InfoIcon.vue';
import breakpointKey from '@/mixins/breakpointKey.js';
import ShopInfoTooltip from '@/components/ShopInfoTooltip.vue';
const ShopInfoPopup = () => import('@/components/popups/ShopInfoPopup.vue');


export default {
    name: 'ShopName',

    components: {
        ShopInfoTooltip,
        InfoIcon,
        ButtonBlock,
        ShopWorkSchedule,
        ShopAddress,
        ShopOpenCloseTime,
    },

    mixins: [breakpointKey],

    props: {
        shop: {
            type: Object,
            default: () => {},
        },

        base: Boolean,

        noLink: Boolean,
        target: {
            type: String,
            default: '_blank',
        },
        hint: Boolean,

        mobilePopup: Boolean,
        popup: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        showPopupToggler() {
            return this.mobilePopup && (this.breakpointKey === 's');
        },

        tag() {
            if (this.showPopupToggler) return 'button';
            else if (this.noLink) return 'div';
            return 'RouterLink';
        },

        attrs() {
            if (this.tag === 'RouterLink') {
                return {
                    to: {
                        name: 'market-shop-about',
                        params: {
                            codename: this.shop.company.codename,
                            id: this.shop.id,
                        },
                    },
                    target: this.target,
                };
            }
            else {
                return {};
            }
        },
    },

    methods: {
        handleClick() {
            if (this.showPopupToggler) {
                const props = {
                    id: this.shop.id,
                    order: (this.popup || {}).order,
                };
                const options = { props };
                this.$popup(ShopInfoPopup, options);
            }
            else {
                this.$emit('click');
            }
        },
    },
};
</script>

<style scoped>
.name-wrap {
    display: flex;
    align-items: center;
    font-size: var(--adaptive-big-fz);
}

.name-wrap_base {
    font-size: var(--base-fz);
}

.name {
    display: flex;
    align-items: center;
    font-family: var(--f-bold);
}
</style>