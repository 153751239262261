export default (name, extension) => {
    if (!extension) return name;

    if (extension === 'jpeg') {
        extension = 'jpe?g';
    }

    const re = new RegExp('\\.' + extension + '(?!.*\\.' + extension + ')', 'gi');

    return name.replace(re, '');
};