<script>
import InputRadioTabs from '@/components/_inputs/InputRadioTabs.vue';
import InputRadioSimple from '@/components/_inputs/InputRadioSimple.vue';
import InputRadioBorders from '@/components/_inputs/InputRadioBorders.vue';

export default {
    name: "InputRadio",

    functional: true,

    props: {
        tabs: Boolean,
        borders: Boolean,
    },

    render(h, context) {
        return h(
            context.props.tabs ? InputRadioTabs : context.props.borders ? InputRadioBorders : InputRadioSimple,
            context.data,
            context.children,
        );
    },
};
</script>