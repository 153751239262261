import { HTTP } from '@/http.js';


const delay = () => new Promise(resolve => {
    setTimeout(() => {
        resolve(null);
    }, 300);
});

const merge = (vehicle, card) => {
    if (!vehicle) vehicle = {};
    if (!card) card = {};

    const { sts_receive, ...card_info } = card;
    const data = Object.assign({}, vehicle, card_info);

    if (sts_receive && !vehicle.sts_receive) {
        data.sts_receive = sts_receive;
    }

    return data;
};

export default (options = { isPopup: false }) => {
    const isPopup = options.isPopup;
    const isForm = !isPopup;

    return {
        checkVehicleLicensePlate() {
            const value = this.formData.vehicle_license_plate;
            this.vehicle_license_plate.showHint = !value || !this.vehicle_license_plate.re.test(value);
            this.vehicle_license_plate.failedGetInfo = false;
            this.vehicle_license_plate.failedNumber = '';
        },

        async onInputVehicleLicensePlate(value) {
            await this.getVehicleByLicensePlate(value);
        },

        async getVehicleByLicensePlate(value) {
            if (!this.vehicle_license_plate.re.test(value)) {
                if (!this.vehicle_license_plate.showHint) {
                    this.vehicle_license_plate.failedGetInfo = false;
                    this.vehicle_license_plate.failedNumber = '';
                    this.vehicle_license_plate.showHint = true;
                }

                return;
            }

            this.vehicle_license_plate.loading = true;

            const [data] = await Promise.all([
                this.getVehicleInfo({
                    type: 'number', // 'vin'
                    number: value,
                }),
                delay(),
            ]);

            this.updateFormDataAndPatch(data);

            this.vehicle_license_plate.failedGetInfo
                = this.vehicle_license_plate.showHint
                = !Object.entries(data).filter(([key, value]) => {
                        return key !== 'car_number' && value;
                    }).length;

            if (this.vehicle_license_plate.failedGetInfo) {
                this.vehicle_license_plate.failedNumber = value;
            }

            this.vehicle_license_plate.loading = false;
        },

        async getVehicleInfo(params) {
            if (this.getVehicleInfoSource) {
                this.getVehicleInfoSource.cancel();
                this.getVehicleInfoSource = null;
            }

            this.getVehicleInfoSource = HTTP.CancelToken.source();

            try {
                return await this.$api.insuranceVehicle.getInfo({
                    params,
                    cancelToken: this.getVehicleInfoSource.token,
                });
            }
            catch (error) {
                // если не получается получить данные, то забей
                return {};
            }
            finally {
                this.getVehicleInfoSource = null;
            }
        },

        async onInputVehicleIdNumber(value) {
            if (this.formData.vehicle_id_type_code === 'vin' && value.length === 17) {
                await this.getVehicleByVin(value);
            }
        },

        async getVehicleByVin(value) {
            if (value && value.length === 17) {
                this.vehicle_id_number.loading = true;

                const [vehicle, card] = await Promise.all([
                    this.getVehicleInfo({
                        type: 'vin', // 'vin'
                        number: value,
                    }),
                ]);

                const data = merge(vehicle, card);

                if (Object.keys(data).length) {
                    this.updateFormDataAndPatch(data);
                }

                this.vehicle_id_number.loading = false;
            }
        },

        updateFormDataAndPatch(data) {
            if (!data || !Object.keys(data).length) return;

            const {
                model,
                manufacturer,
                power,
                year,
                vin,
                body_number,
                chassis_number,
                car_document,
                sts_receive,
                car_number,
            } = data;

            if (car_number) {
                const re = /^([АВЕКМНОРСТУХ]{1}\d{3}[АВЕКМНОРСТУХ]{2})(\d{2,3})$/;
                const vehicle_license_plate = car_number.replace(re, '$1 $2');

                if (vehicle_license_plate !== this.formData.vehicle_license_plate) {
                    this.formData.vehicle_license_plate = vehicle_license_plate;
                }
            }

            this.formData.vehicle_year = year || null;
            this.formData.vehicle_engine_power = power || null;
            this.formData.vehicle_model = model || null;
            this.formData.vehicle_manufacturer = manufacturer || null;
            this.formData.vehicle_id_type_code = body_number ? 'bodyNumber' : chassis_number ? 'chassisNumber' : 'vin';
            this.formData.vehicle_id_number = body_number || chassis_number || vin || '';
            this.formData.vehicle_doc_type_code = (car_document || {}).type || 'STS';
            this.formData.vehicle_doc_number = (car_document || {}).number || '';
            this.formData.vehicle_doc_date = sts_receive || null;

            if (isForm) {
                this.patch(
                    'vehicle_license_plate',
                    'vehicle_year',
                    'vehicle_engine_power',
                    'vehicle_model',
                    'vehicle_manufacturer',
                    'vehicle_id_type_code',
                    'vehicle_id_number',
                    'vehicle_doc_date',
                );
            }
        },

        onChangeVehicleManufacturer() {
            this.formData.vehicle_model = null;
            this.formData.vehicle_year = null;
            this.formData.vehicle_engine_power = null;

            if (isForm) {
                this.patch('vehicle_manufacturer');
            }
        },

        onChangeVehicleModel() {
            this.formData.vehicle_year = null;
            this.formData.vehicle_engine_power = null;

            if (isForm) {
                this.patch('vehicle_model');
            }
        },

        validateVehicleDocNumber({ value }) {
            const re = this.vehicle_doc_number.re[this.formData.vehicle_doc_type_code];

            return value
                ? re && !re.test(value)
                : false;
        },
    };
};