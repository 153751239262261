<template>
    <div class="main-market-page">
        <TransitionFade>
            <div
                v-if="!initialized"
                key="loading"
                class="loading"
            >
                <USpinner big></USpinner>
            </div>

            <div
                v-else
                key="content"
            >
                <div class="site-top-sections-grid bottom-gap_medium">
                    <section class="site-top-sections-grid__item site-section_desktop parts-categories-card layer-1">
                        <h1 class="h1 h1_block">
                            Автозапчасти
                        </h1>

                        <ul class="parts-categories">
                            <li
                                v-for="c in partsCategories.results"
                                class="parts-categories__item"
                            >
                                <RouterLink
                                    :to="{
                                        name: 'market-parts-catalog-category',
                                        params: {
                                            slug: c.slug
                                        }
                                    }"
                                    class="parts-categories__link link-primary-hover"
                                >
                                    {{ c.name }}
                                </RouterLink>
                            </li>
                        </ul>

                        <ButtonBlock
                            :to="{
                                name: 'market-parts-catalog'
                            }"
                            primary
                            class="parts-categories__btn"
                        >
                            Смотреть все
                        </ButtonBlock>
                    </section>
                    <RouterLink
                        :to="{
                            name: 'market-parts-catalog'
                        }"
                        class="site-top-sections-grid__item site-section_mobile parts-categories-card link-primary-hover layer-1"
                    >
                        <h2 class="h1">
                            Автозапчасти
                        </h2>
                    </RouterLink>

                    <SelectionInMarketIndex class="site-top-sections-grid__item site-section_desktop selection-card layer-1"></SelectionInMarketIndex>
                    <RouterLink
                        :to="{
                            name: 'market-products-category',
                            params: {
                                slug: 'shinyi-i-diski',
                            },
                        }"
                        class="site-top-sections-grid__item site-section_mobile selection-mobile-card link-primary-hover layer-1"
                    >
                        <h2 class="h1">
                            Шины и диски
                        </h2>
                    </RouterLink>
                </div>

                <div class="site-second-sections-grid bottom-gap_medium">
                    <ul class="site-second-sections-grid__item products-mini-categories-grid">
                        <li
                            v-for="category in meta.product_categories"
                            :key="category.id"
                            class="products-mini-category"
                        >
                            <RouterLink
                                :to="{
                                    name: 'market-products-category',
                                    params: {
                                        slug: category.slug,
                                    },
                                }"
                                class="products-mini-category__link layer-1 link-primary-hover"
                            >
                                <img
                                    :src="$links.uploads + category.cover.thumbnails.category_cover_mp_index"
                                    alt=""
                                    class="products-mini-category__cover"
                                    width="72"
                                    height="72"
                                >
                                <span>
                                    {{ category.name }}
                                </span>
                            </RouterLink>
                        </li>
                        <li class="products-mini-category products-mini-category_catalog">
                            <RouterLink
                                :to="{
                                    name: 'market-products-catalog'
                                }"
                                class="products-mini-category__link layer-1 link-primary-hover"
                            >
                                <UIcon
                                    name="list-search"
                                    big
                                    class="mr-12"
                                ></UIcon>
                                <span class="mr-auto">
                                    Каталог товаров
                                </span>

                                <UIcon
                                    name="arrow-right"
                                    secondary
                                    small
                                    class="ml-auto"
                                ></UIcon>
                            </RouterLink>
                        </li>
                    </ul>

                    <section class="site-second-sections-grid__item site-section_desktop maintenance-card layer-1">
                        <h2 class="h2 h2_block">
                            <RouterLink
                                :to="{
                                    name: 'market-maintenance'
                                }"
                            >
                                Товары для ТО
                            </RouterLink>
                        </h2>

                        <div class="maintenance-categories">
                            <div class="maintenance-categories__col">
                                <h3 class="h4 h4_block">
                                    Популярные
                                </h3>
                                <ul>
                                    <li
                                        v-for="c in technicalInspectionMeta.popular_categories"
                                        class="maintenance-category"
                                    >
                                        <button
                                            type="button"
                                            class="maintenance-category__link link-primary-hover"
                                            @click="handleToCategoryClick(c)"
                                        >
                                            {{ c.name }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="maintenance-categories__col">
                                <h3 class="h4 h4_block">
                                    Может потребоваться
                                </h3>
                                <ul>
                                    <li
                                        v-for="c in technicalInspectionMeta.additional_categories"
                                        class="maintenance-category"
                                    >
                                        <button
                                            type="button"
                                            class="maintenance-category__link link-primary-hover"
                                            @click="handleToCategoryClick(c)"
                                        >
                                            {{ c.name }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <RouterLink
                        :to="{
                            name: 'market-maintenance'
                        }"
                        class="site-second-sections-grid__item site-section_mobile maintenance-card link-primary-hover layer-1"
                    >
                        <h2 class="h2">
                            Товары для ТО
                        </h2>
                        <ButtonBlock
                            primary
                            pseudo
                            low
                            class="mt-24"
                        >
                            Смотреть
                        </ButtonBlock>
                    </RouterLink>
                </div>

                <section
                    v-if="brands.length === 8"
                    class="brands-section layer-1"
                >
                    <h2 class="h2 h2_block brands-section__title">
                        {{ brandsCountString }} на&nbsp;Ubibi
                    </h2>

                    <ul class="brands-list">
                        <li
                            v-for="brand in brands"
                            :key="'brand-' + brand.id"
                            class="brand-item"
                        >
                            <RouterLink
                                v-lazy-container="{ selector: 'img' }"
                                :to="{
                                    name: 'market-brand',
                                    params: {
                                        codename: brand.codename,
                                    },
                                }"
                                class="brand-link"
                                :aria-label="'Перейти на страницу бренда ' + brand.name"
                            >
                                <img
                                    :data-src="$links.uploads + brand.logo.thumbnails.brand_logo_big"
                                    :alt="'Логотип бренда ' + brand.name"
                                    class="brand-logo"
                                    width="90"
                                    height="60"
                                >
                            </RouterLink>
                        </li>
                    </ul>

                    <ButtonText
                        :to="{
                            name: 'market-brands'
                        }"
                        underline
                        secondary
                        dark
                        class="brands-section__link"
                    >
                        Все бренды
                    </ButtonText>
                </section>
            </div>
        </TransitionFade>

        <section class="profits-section">
            <h2 class="visually-hidden">
                Преимущества сервиса Ubibi
            </h2>

            <ul class="profits-list layer-1">
                <li class="profit">
                    <div class="profit__text-wrap">
                        <h3 class="h4 profit__title">
                            Безопасная сделка
                        </h3>
                        <p class="profit__text">
                            Ваши деньги находятся на&nbsp;нашем счёте и&nbsp;поступят продавцу только после того, как вы&nbsp;получите товар.
                        </p>
                    </div>
                    <img
                        src="../../assets/images/market/profit-1.jpg"
                        alt=""
                        class="profit__img"
                        width="136"
                        height="130"
                    >
                </li>
                <li class="profit">
                    <div class="profit__text-wrap">
                        <h3 class="h4 profit__title">
                            Онлайн-чаты с&nbsp;магазинами
                        </h3>
                        <p class="profit__text">
                            Если появились вопросы, напрямую задавайте их&nbsp;менеджерам магазинов.
                        </p>
                    </div>
                    <img
                        src="../../assets/images/market/profit-2.jpg"
                        alt=""
                        class="profit__img"
                        width="136"
                        height="130"
                    >
                </li>
                <li class="profit">
                    <div class="profit__text-wrap">
                        <h3 class="h4 profit__title">
                            Бронирование и&nbsp;самовывоз
                        </h3>
                        <p class="profit__text">
                            Магазин упакует и&nbsp;подготовит товар для выдачи к&nbsp;вашему приезду. Без очередей и&nbsp;ожидания.
                        </p>
                    </div>
                    <img
                        src="../../assets/images/market/profit-3.jpg"
                        alt=""
                        class="profit__img"
                        width="136"
                        height="130"
                    >
                </li>
                <li class="profit">
                    <div class="profit__text-wrap">
                        <h3 class="h4 profit__title">
                            Экспресс-доставка
                        </h3>
                        <p class="profit__text">
                            Доставим ваш заказ из&nbsp;магазина по&nbsp;городу в&nbsp;течение часа с&nbsp;Яндекс&nbsp;Go.
                        </p>
                    </div>
                    <img
                        src="../../assets/images/market/profit-4.jpg"
                        alt=""
                        class="profit__img"
                        width="136"
                        height="130"
                    >
                </li>
            </ul>
        </section>

        <PartnerConnectSection></PartnerConnectSection>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import bus from '@/bus.js';
import plural from '@/lib/plural.js';
import { safeRouterPush } from '@/router/router.js';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import TransitionFade from '@/components/_transitions/TransitionFade.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import SelectionInMarketIndex from '@/components/selection/SelectionInMarketIndex.vue';
import PartnerConnectSection from '@/components/PartnerConnectSection.vue';
const SelectCarPopup = () => import('@/components/popups/SelectCarPopup.vue');


export default {
    name: 'MarketMainPage',

    metaInfo() {
        return {
            title: this.meta.meta_title,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content: this.meta.meta_title },
                { property: 'og:description', content: this.meta.meta_description },
                {
                    property: 'og:image',
                    content: this.meta.og_image
                        ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default
                        : '',
                },
            ],
        };
    },

    components: {
        PartnerConnectSection,
        SelectionInMarketIndex,
        UIcon,
        TransitionFade,
        USpinner,
        ButtonBlock,
        ButtonText,
    },

    serverPrefetch() {
        return this.INIT();
    },

    computed: {
        ...mapState({
            car: state => state.searchWidget.car,
            initialized: state => state.market.initialized,
            meta: state => state.market.meta,
            partsCategories: state => state.market.partsCategories,
            technicalInspectionMeta: state => state.market.technicalInspectionMeta,
            brands: state => state.market.brands.items,
            brandsCount: state => state.market.brands.current_count,
            rimSelectionCategory: state => state.settings.rim_selection_category,
            tireSelectionCategory: state => state.settings.tire_selection_category,
            selectedCar: state => state.searchWidget.car,
        }),

        brandsCountString() {
            // Логика такая:
            // - Если брендов меньше 100, выводим точное кол-во.
            // - Если меньше 1000, округляем до десятков и в конце добавляем +, например: 670+.
            // - Если меньше 10000, округляем до сотен и в конце добавляем +, например: 6700+.
            // - Если меньше 100000, округляем до тысяч и в конце добавляем +, например: 67000+.
            const initialCount = this.brandsCount;
            let addPlus = false;
            let roundBrandsCount = initialCount;
            if (initialCount > 100 && initialCount < 1000) {
                roundBrandsCount = Math.floor(initialCount / 10) * 10;
                addPlus = roundBrandsCount !== initialCount;
            }
            else if (initialCount > 1000 && initialCount < 10000) {
                roundBrandsCount = Math.floor(initialCount / 100) * 100;
                addPlus = roundBrandsCount !== initialCount;
            }
            else if (initialCount >= 10000) {
                roundBrandsCount = Math.floor(initialCount / 1000) * 1000;
                addPlus = roundBrandsCount !== initialCount;
            }

            return this.$options.filters.number(roundBrandsCount) + (addPlus ? '+ ' : ' ') + plural(roundBrandsCount, ['бренд', 'бренда', 'брендов']);
        },

        hasSelectedCar() {
            return this.selectedCar && !!this.selectedCar.id;
        },
    },

    async mounted() {
        this.$breadcrumbs([]);

        if (!this.initialized) {
            await this.INIT();
        }
    },

    beforeDestroy() {
        this.DESTROY();
    },

    methods: {
        ...mapActions({
            INIT: 'market/init',
        }),

        ...mapMutations({
            DESTROY: 'market/destroy',
        }),

        showCatalog() {
            bus.$emit('showCatalog');
        },

        async handleToCategoryClick(category) {
            try {
                await this.checkSelectCar();
            }
            catch (e) {
                return;
            }

            this.toCategory(category);
        },

        toCategory(category) {
            safeRouterPush({
                name: (category || {}).category_type === 'product_category'
                    ? 'market-products-category'
                    : 'market-parts-catalog-category',
                params: {
                    slug: category.slug,
                },
            });
        },

        async checkSelectCar() {
            if (this.hasSelectedCar) return;

            await this.showSelectCarPopup();
        },

        showSelectCarPopup() {
            return new Promise((resolve, reject) => {
                const props = {
                    modeOptions: [
                        {
                            label: 'По гос. номеру',
                            value: 'grz',
                        },
                        {
                            label: 'VIN/Frame',
                            value: 'vin_frame',
                        },
                        {
                            label: 'Из гаража',
                            value: 'garage',
                        },
                    ],
                    outerAddGarageMode: false,
                    callback: car => {
                        if (car) {
                            resolve();
                        }
                        else {
                            reject();
                        }
                    },
                };
                const options = { props };
                this.$popup(SelectCarPopup, options);
            });
        },
    },
};
</script>

<style scoped>
.main-market-page {
    margin-top: var(--gap-y-medium);
}

.loading {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 1040px) {
    .site-top-sections-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--grid-gap);
    }
}
@media (max-width: 1039px) {
    .site-top-sections-grid__item:not(:last-child) {
        margin-bottom: 12px;
    }
}

.parts-categories-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.parts-categories {
    margin-bottom: 36px;
}
@media (min-width: 1160px) {
    .parts-categories {
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width: 1040px) and (max-width: 1159px) {
    .parts-categories {
        grid-template-columns: 1fr;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .parts-categories {
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width: 768px) {
    .parts-categories {
        display: grid;
        grid-gap: 12px var(--grid-gap);
        width: 100%;
    }
}

@media (min-width: 1040px) and (max-width: 1280px) {
    .parts-categories__item:nth-child(n+8) {
        display: none;
    }
}
@media (max-width: 1039px) {
    .parts-categories__item:nth-child(n+9) {
        display: none;
    }
}

.parts-categories__btn {
    margin-top: auto;
}

@media (min-width: 1281px) {
    .site-second-sections-grid {
        grid-template-columns: var(--col-8) var(--col-12);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .site-second-sections-grid {
        grid-template-columns: var(--col-5) var(--col-7);
    }
}
@media (min-width: 1040px) {
    .site-second-sections-grid {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

@media (min-width: 360px) {
    .products-mini-categories-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--grid-gap);
    }
}
@media (max-width: 1039px) {
    .products-mini-categories-grid {
        margin-bottom: var(--gap-y-medium);
    }
}

@media (max-width: 359px) {
    .products-mini-category:not(:last-child) {
        margin-bottom: 12px;
    }
}

.products-mini-category__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    text-align: center;
    font-family: var(--f-bold);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .products-mini-category__link {
        flex-direction: row;
        justify-content: flex-start;
    }
}
@media (max-width: 359px) {
    .products-mini-category__link {
        flex-direction: row;
        justify-content: flex-start;
        text-align: left;
    }
}

.products-mini-category__cover {
    flex-shrink: 0;
}
@media (min-width: 1040px) {
    .products-mini-category__cover {
        width: 72px;
        height: 72px;
        margin-bottom: 4px;
    }
}
@media (max-width: 1039px) {
    .products-mini-category__cover {
        width: 48px;
        height: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .products-mini-category__cover {
        margin-right: 12px;
    }
}
@media (min-width: 360px) and (max-width: 767px) {
    .products-mini-category__cover {
        margin-bottom: 12px;
    }
}
@media (max-width: 359px) {
    .products-mini-category__cover {
        margin-right: 12px;
    }
}

.products-mini-category_catalog {
    grid-column: 1/3;
}
@media (min-width: 1040px) {
    .products-mini-category_catalog {
        display: none;
    }
}

.products-mini-category_catalog .products-mini-category__link {
    flex-direction: row;
    padding: 16px 20px;
}

@media (min-width: 768px) {
    .site-section_desktop {
        padding: 36px;
    }
    .site-section_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .site-section_desktop {
        display: none;
    }
    .site-section_mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        min-height: 96px;
        padding: 20px;
        overflow: hidden;
    }
}


.maintenance-categories {
    display: grid;
    grid-template-columns: repeat(2, 200px);
    grid-column-gap: var(--grid-gap);
    align-items: flex-start;
}
@media (min-width: 1281px) {
    .maintenance-categories {
        grid-template-columns: repeat(2, 280px);
    }
}

.maintenance-category:not(:last-child) {
    margin-bottom: 12px;
}

.parts-categories-card,
.selection-mobile-card,
.maintenance-card {
    position: relative;
    overflow: hidden;
}
.parts-categories-card > *,
.selection-mobile-card > *,
.maintenance-card > * {
    position: relative;
    z-index: 1;
}
.parts-categories-card::after,
.selection-mobile-card::after,
.maintenance-card::after {
    content: "";
    position: absolute;
    z-index: 0;
    background-size: contain;
    background-repeat: no-repeat;
}

.parts-categories-card::after {
    background-image: url(../../assets/images/market/parts.jpg);
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .parts-categories-card::after {
        background-image: url(../../assets/images/market/parts-x2.jpg);
    }
}
@media (min-width: 768px) {
    .parts-categories-card::after {
        width: 150px;
        height: 150px;
        bottom: -58px;
        right: 36px;
    }
}
@media (max-width: 767px) {
    .selection-mobile-card::after,
    .parts-categories-card::after {
        width: 120px;
        height: 120px;
        top: 16px;
    }
}
@media (min-width: 414px) and (max-width: 767px) {
    .selection-mobile-card::after,
    .parts-categories-card::after {
        right: 30px;
    }
}
@media (max-width: 413px) {
    .selection-mobile-card::after,
    .parts-categories-card::after {
        left: 230px;
    }
}

.selection-mobile-card::after {
    background-image: url(../../assets/images/market/tires.jpg);
}

@media (min-width: 1040px) and (max-width: 1280px) {
    .maintenance-card::after {
        content: none;
    }
}
@media (min-width: 768px) {
    .maintenance-card::after {
        width: 136px;
        height: 96px;
        bottom: 36px;
        right: 36px;
        background-image: url(../../assets/images/market/maintenance-desktop.jpg);
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .maintenance-card::after {
            background-image: url(../../assets/images/market/maintenance-desktop-x2.jpg);
        }
    }
}
@media (max-width: 767px) {
    .maintenance-card::after {
        width: 170px;
        height: 170px;
        top: 16px;
        right: -20px;
        background-image: url(../../assets/images/market/maintenance-mobile.jpg);
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .maintenance-card::after {
            background-image: url(../../assets/images/market/maintenance-mobile-x2.jpg);
        }
    }
}

.brands-section {
    margin-bottom: var(--gap-y-medium);
}
@media (min-width: 768px) {
    .brands-section {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .brands-section {
        padding: 24px;
    }
}

.brands-section__title {
    text-align: center;
}

.brands-section__link {
    display: block;
    width: max-content;
    margin: var(--gap-y-mini) auto 0;
}

.brands-list {
    display: grid;
    margin-top: var(--gap-y-mini);
    justify-content: center;
}

@media (min-width: 1281px) {
    .brands-list {
        grid-template-columns: repeat(8, 90px);
        grid-gap: 48px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .brands-list {
        grid-template-columns: repeat(6, 90px);
        grid-gap: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .brands-list {
        grid-template-columns: repeat(4, 90px);
        grid-gap: 20px 72px;
    }
}
@media (max-width: 767px) {
    .brands-list {
        grid-template-columns: repeat(3, 54px);
        grid-gap: 16px 36px;
    }
}

@media (min-width: 1040px) and (max-width: 1280px) {
    .brand-item:nth-child(n+7) {
        display: none;
    }
}
@media (max-width: 767px) {
    .brand-item:nth-child(n+7) {
        display: none;
    }
}

.brand-link {
    display: block;
}

.brand-logo {
    margin-left: auto;
    margin-right: auto;
    font-size: 0;
}
@media (min-width: 1281px) {
    .brand-logo {
        width: 90px;
        height: 60px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .brand-logo {
        width: 72px;
        height: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .brand-logo {
        width: 90px;
        height: 60px;
    }
}
@media (max-width: 767px) {
    .brand-logo {
        width: 54px;
        height: 36px;
    }
}

.profits-section {
    margin-bottom: var(--gap-y-medium);
}

.profits-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
@media (min-width: 1281px) {
    .profits-list {
        padding: 48px;
        grid-gap: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profits-list {
        grid-template-columns: 1fr;
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .profits-list {
        padding: 36px;
        grid-gap: 36px;
    }
}
@media (max-width: 767px) {
    .profits-list {
        padding: 20px;
        grid-gap: 20px;
    }
}

.profit {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .profit {
        flex-direction: row-reverse;
    }
}
@media (max-width: 767px) {
    .profit {
        flex-direction: column-reverse;
        text-align: center;
        justify-content: flex-end;
    }
}

.profit__text {
    margin-top: var(--indent-h4-text);
    line-height: var(--base-lh);
}
@media (max-width: 767px) {
    .profit__text {
        display: none;
    }
}

@media (min-width: 1281px) {
    .profit__img {
        margin-right: 36px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profit__img {
        margin-right: 24px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profit__img {
        margin-right: 36px;
    }
}
@media (min-width: 768px) {
    .profit__img {
        width: 136px;
        height: 130px;
    }
}
@media (max-width: 767px) {
    .profit__img {
        width: 76px;
        height: 72px;
        margin-bottom: 8px;
    }
}
</style>