import equals from '@/lib/equals.js';
import deepClone from '@/lib/deepClone.js';


export default () => {
    return {
        formData: {
            handler() {
                if (!equals(this.formData, this.filteredStoreData)) {
                    this.update(this.formData);
                }
            },

            deep: true,
        },

        filteredStoreData: {
            handler(data) {
                if (!equals(data, this.formData)) {
                    this.formData = deepClone(data);
                }
            },

            immediate: true,
            deep: true,
        },

        innerErrors: {
            handler(errors) {
                this.$emit('error', errors);
            },
        },
    };
};