export default {
    namespaced: true,

    state: () => ({
        allPromotions: [],
        viewedPromotions: [],
        currentPromotion: {},
        isPromotionVisible: false,
    }),

    mutations: {
        setAllPromotions(state, { results } ) {
            state.allPromotions = results;
        },

        setViewedPromotions(state, items) {
            state.viewedPromotions = items;
            localStorage.setItem('UbibiVuexViewedPromotions', JSON.stringify(items));
        },

        setCurrentPromotion(state, item) {
            state.currentPromotion = item;
        },

        setPromotionVisibility(state, status) {
            state.isPromotionVisible = status;
        },
    },

    actions: {
        async get({ commit }) {
            try {
                const promotions = await this.$api.notices.get();
                commit('setAllPromotions', promotions);
                return { promotions };
            }
            catch (error) {
                // console.log('getPromotions', error);
            }
        },
    },
};