export default {
    namespaced: true,

    state: () => ({
        value: {},
        options: [
            {
                name: 'По рейтингу',
                value: '-rating',
            },
            {
                name: 'По возрастанию цены',
                value: 'price',
            },
            {
                name: 'По убыванию цены',
                value: '-price',
            },
        ],
    }),

    getters: {
        query: state => {
            return { order_by: state.value.value };
        },
    },

    mutations: {
        setValue(state, value) {
            state.value = value;
        },

        setOptions(state, { type, options }) {
            if (type === 'offers') {
                state.options = [
                    {
                        name: 'По рейтингу',
                        value: '-rating',
                    },
                    {
                        name: 'По возрастанию цены',
                        value: 'price',
                    },
                    {
                        name: 'По убыванию цены',
                        value: '-price',
                    },
                ];
            }
            else if (type === 'items') {
                state.options = [
                    {
                        name: 'По рейтингу',
                        value: '-rating',
                    },
                    {
                        name: 'По возрастанию цены',
                        value: 'price',
                    },
                    {
                        name: 'По убыванию цены',
                        value: '-price',
                    },
                ];
            }
            else if (type === 'rating') {
                state.options = [
                    {
                        name: 'По рейтингу',
                        value: '-rating',
                    },
                    {
                        name: 'По дате',
                        value: '-create_date',
                    },
                ];
            }
            else {
                state.options = options;
            }
        },


        parseQuery(state, query) {
            const value = query.order_by;
            const index = value ? state.options.map(option => option.value).indexOf(value) : -1;
            state.value = index === -1 ? state.options[0] : state.options[index];
        },
    },
};