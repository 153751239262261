<template>
    <PopupWrap
        @close="close"
    >
        <FormTemplate class="popup-content">
            <template slot="body">
                <h2 class="h2 h2_block">
                    Автомобиль
                </h2>

                <FormManager
                    ref="form"
                    v-model="formData"
                    :formTag="false"
                    class="form-grid"
                    @error="onError"
                >
                    <!-- Кириллические символы приравнять к латинским -->
                    <FormField
                        name="vehicle_license_plate"
                        type="licensePlate"
                        label="Гос. номер"
                        class="vehicle_license_plate"
                        :validations="formData.vehicle_doc_type_code === 'STS' ? 'required' : ''"
                        visibleMask
                        :loading="vehicle_license_plate.loading"
                        :disabled="vehicle_id_number.loading"
                        @input="onInputVehicleLicensePlate"
                    ></FormField>

                    <div
                        class="note"
                        :class="{
                            wide: vehicle_license_plate.failedGetInfo,
                        }"
                    >
                        <template v-if="vehicle_license_plate.showHint">
                            <span v-if="vehicle_license_plate.failedGetInfo">Данные по&nbsp;номеру {{ vehicle_license_plate.failedNumber }}&nbsp;не найдены. Проверьте, возможно, номер введен с&nbsp;ошибкой, либо заполните данные самостоятельно.</span>
                            <span v-else>Введите номер автомобиля и&nbsp;мы&nbsp;заполним данные за&nbsp;вас</span>
                        </template>
                    </div>

                    <FormField
                        name="vehicle_manufacturer"
                        type="select"
                        label="Марка"
                        options="/insurance_vehicle_manufacturer/"
                        searchParam="q"
                        :limit="null"
                        optionLabel="name"
                        validations="required"
                        :disabled="vehicle_license_plate.loading || vehicle_id_number.loading"
                        class="vehicle_manufacturer"
                        @change="onChangeVehicleManufacturer"
                    ></FormField>

                    <FormField
                        name="vehicle_model"
                        type="select"
                        label="Модель"
                        options="/insurance_vehicle_model/"
                        searchParam="q"
                        :limit="null"
                        :params="formData.vehicle_manufacturer ? {
                            manufacturer: formData.vehicle_manufacturer.id,
                        } : {}"
                        :disabled="vehicle_license_plate.loading ||
                            vehicle_id_number.loading ||
                            (!formData.vehicle_manufacturer && !formData.vehicle_model)"
                        optionLabel="name"
                        validations="required"
                        class="vehicle_model"
                        @change="onChangeVehicleModel"
                    ></FormField>

                    <FormField
                        name="vehicle_year"
                        type="select"
                        label="Год выпуска"
                        :options="(formData.vehicle_model || {}).year || []"
                        :disabled="vehicle_license_plate.loading ||
                            vehicle_id_number.loading ||
                            (!formData.vehicle_model && !formData.vehicle_year)"
                        validations="required"
                        class="vehicle_year"
                    ></FormField>

                    <FormField
                        name="vehicle_engine_power"
                        type="select"
                        label="Мощность двигателя"
                        options="/insurance_vehicle/load_power/"
                        :disabled="vehicle_license_plate.loading ||
                            vehicle_id_number.loading ||
                            (!formData.vehicle_year && !formData.vehicle_engine_power)"
                        :params="{
                            model: (formData.vehicle_model || {}).id,
                            year: formData.vehicle_year,
                        }"
                        :getOptionLabel="option => option + ' л. с.'"
                        :getCurrentCount="response => response.length"
                        :getItems="response => response"
                        validations="required"
                        class="vehicle_engine_power"
                    ></FormField>

                    <FormField
                        name="vehicle_id_type_code"
                        type="tabs"
                        :options="[
                            {
                                value: 'vin',
                                label: 'VIN',
                            },
                            {
                                value: 'bodyNumber',
                                label: 'Кузов',
                            },
                            {
                                value: 'chassisNumber',
                                label: 'Шасси',
                            },
                        ]"
                        flex
                        validations="required"
                        :disabled="vehicle_license_plate.loading || vehicle_id_number.loading"
                        class="vehicle_id_type_code"
                    ></FormField>

                    <FormField
                        name="vehicle_id_number"
                        :maxLength="formData.vehicle_id_type_code === 'vin' ? 17 : 13"
                        :label="vehicle_id_number.labels[formData.vehicle_id_type_code]"
                        :validations="'required|' + (isVin ? 'isVin' : 'isBodyNumber')"
                        :validationRules="{
                            isVin: ({ value }) => {
                                // TODO: уточнить regexp
                                return !value ? false : !/^[A-z\d]{17}$/.test(value)
                            },
                            isBodyNumber: ({ value }) => {
                                // предположение, что номер кузова и номер шасси совпадает
                                return !value ? false : !/^[A-z\d]{9,13}$/.test(value)
                            },
                        }"
                        upper
                        :disabled="vehicle_license_plate.loading"
                        :loading="vehicle_id_number.loading"
                        class="vehicle_id_number"
                        @input="onInputVehicleIdNumber"
                    ></FormField>

                    <h4 class="h4 vehicle-doc-title">
                        Документ на авто
                    </h4>

                    <FormField
                        name="vehicle_doc_type_code"
                        type="tabs"
                        :options="[
                            {
                                value: 'STS',
                                label: 'СТС',
                            },
                            {
                                value: 'PTS',
                                label: 'ПТС',
                            },
                            {
                                value: 'EPTS',
                                label: 'ЕПТС',
                            },
                        ]"
                        flex
                        class="vehicle_doc_type_code"
                        :disabled="vehicle_license_plate.loading"
                    ></FormField>

                    <FormField
                        name="vehicle_doc_number"
                        :label="vehicle_doc_number.labels[formData.vehicle_doc_type_code]"
                        :mask="vehicle_doc_number.masks[formData.vehicle_doc_type_code]"
                        validations="required|re"
                        :validationRules="{
                            re: validateVehicleDocNumber,
                        }"
                        :disabled="vehicle_license_plate.loading || vehicle_id_number.loading"
                        class="vehicle_doc_number"
                    ></FormField>

                    <!-- min vehicle_year max now -->
                    <FormField
                        name="vehicle_doc_date"
                        type="date"
                        visibleMask
                        :label="vehicle_doc_date.labels[formData.vehicle_doc_type_code]"
                        :validations="`required|min:${ minVehicleDocDateIso }|max:${ maxVehicleDocDateIso }`"
                        :disabled="vehicle_license_plate.loading || vehicle_id_number.loading"
                        class="vehicle_doc_date"
                    ></FormField>
                </FormManager>

                <InfoMessage class="mt-20">
                    После изменения данных стоимость страхового полиса может быть пересчитана.
                </InfoMessage>
            </template>

            <template slot="footer">
                <ButtonBlock
                    secondary
                    class="button-secondary"
                    @click="cancel"
                >
                    Отмена
                </ButtonBlock>
                <ButtonBlock
                    primary
                    :disabled="invalid || vehicle_license_plate.loading || vehicle_id_number.loading"
                    :loading="loading"
                    class="button-primary"
                    @click="confirm"
                >
                    Сохранить
                </ButtonBlock>
            </template>
        </FormTemplate>
    </PopupWrap>
</template>

<script>
// utils
import deepClone from '@/lib/deepClone.js';
// mixins
import popup from '@/mixins/popup.js';
// use
import useChangePopupData from '@/pages/insurance/osago/_uuid/useChangePopupData.js';
import useChangePopupProps from '@/pages/insurance/osago/_uuid/useChangePopupProps.js';
import useChangePopupComputed from '@/pages/insurance/osago/_uuid/useChangePopupComputed.js';
import useChangePopupMethods from '@/pages/insurance/osago/_uuid/useChangePopupMethods.js';
import useVehicleComputed from '@/pages/insurance/osago/_uuid/useVehicleComputed.js';
import useVehicleMethods from '@/pages/insurance/osago/_uuid/useVehicleMethods.js';
import useVehicleData from '@/pages/insurance/osago/_uuid/useVehicleData.js';
import useVehicleWatch from '@/pages/insurance/osago/_uuid/useVehicleWatch.js';
// components
import PopupWrap from '@/components/popups/PopupWrap.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import FormTemplate from './FormTemplate.vue';
import FormField from '@/components/_form/FormField.vue';
import FormManager from '@/components/_form/FormManager.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';


export default {
    name: 'InsurerOsagoChangeCarPopup',

    components: {
        ButtonBlock,
        FormManager,
        FormField,
        PopupWrap,
        InfoMessage,
        FormTemplate,
    },

    mixins: [
        popup,
    ],

    props: {
        ...useChangePopupProps(),
    },

    data() {
        return {
            ...useChangePopupData(),
            ...useVehicleData(),
        };
    },

    computed: {
        ...useChangePopupComputed(),
        ...useVehicleComputed(),
    },

    watch: {
        ...useVehicleWatch(),
    },

    mounted() {
        this.formData = deepClone(this.initFormData);

        this.checkVehicleLicensePlate();
    },

    beforeDestroy() {
        if (this.getVehicleInfoSource) this.getVehicleInfoSource.cancel();
        // if (this.getDiagnosticCardInfoSource) this.getDiagnosticCardInfoSource.cancel();
    },

    methods: {
        ...useChangePopupMethods(),
        ...useVehicleMethods({ isPopup: true }),
    },
};
</script>

<style scoped>
.vehicle_license_plate {
    grid-area: vehicle_license_plate;
}

.note {
    grid-area: note;
    grid-column-start: 2;
    grid-column-end: 3;
    font-size: 12px;
    color: var(--font-secondary-color);
    display: flex;
    align-items: center;
}
.note.wide {
    grid-column-start: 2;
    grid-column-end: 4;
}
@media (max-width: 767px) {
    .note {
        margin-top: -8px;
    }
}

.vehicle_manufacturer {
    grid-area: vehicle_manufacturer;
}

.vehicle_model {
    grid-area: vehicle_model;
}

.vehicle_year {
    grid-area: vehicle_year;
}

.vehicle_engine_power {
    grid-area: vehicle_engine_power;
}

.vehicle_id_type_code {
    grid-area: vehicle_id_type_code;
    width: 100%;
}

.vehicle_id_number {
    grid-area: vehicle_id_number;
}

.vehicle-doc-title {
    grid-area: vehicle-doc-title;
    margin: 0;
}

.vehicle_doc_type_code {
    grid-area: vehicle_doc_type_code;
}

.vehicle_doc_number {
    grid-area: vehicle_doc_number;
}

.vehicle_doc_date {
    grid-area: vehicle_doc_date;
}

/*.diagnostic-card-title {*/
/*    grid-area: diagnostic-card-title;*/
/*    margin: 0;*/
/*}*/

/*.diagnostic_card_exp {*/
/*    grid-area: diagnostic_card_exp;*/
/*}*/

/*.diagnostic_card_number {*/
/*    grid-area: diagnostic_card_number;*/
/*}*/

.h4 {
    margin-top: 4px;
    display: flex;
}
@media (max-width: 767px) {
    .h4 {
        margin-bottom: 12px;
    }
}

@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

@media (min-width: 1040px) {
    .form-grid {
        grid-template-areas:
            "vehicle_license_plate note note"
            "vehicle_manufacturer vehicle_model vehicle_year"
            "vehicle_engine_power vehicle_id_type_code vehicle_id_number"
            "vehicle-doc-title vehicle-doc-title vehicle-doc-title"
            "vehicle_doc_type_code vehicle_doc_number vehicle_doc_date";
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .form-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "vehicle_license_plate note"
            "vehicle_manufacturer vehicle_model"
            "vehicle_year vehicle_engine_power"
            "vehicle_id_type_code vehicle_id_number"
            "vehicle-doc-title vehicle-doc-title"
            "vehicle_doc_type_code ."
            "vehicle_doc_number vehicle_doc_date";
    }
}

@media (max-width: 767px) {
    .popup-content >>> .footer {
        margin-top: auto;
        padding-top: 36px;
    }
}
</style>