<template>
    <component
        :is="tag"
        v-bind="attrs"
        class="button-text"
        :class="{
            'button-text_active': active,
            'button-text_loading': loading,
            'button-text_disabled': disabled,
            'button-text_focus': focused,
            'button-text_hover': hovered,
            'button-text_primary': primary,
            'button-text_secondary': secondary,
            'button-text_underline': underline && !loading,
            'button-text_dashed': dashed && !loading,
            'button-text_dark': dark,
            'button-text_black': black,
            'button-text_has-icon': prependIcon || appendIcon,
            'button-text_without-icon': !prependIcon && !appendIcon,
            'button-text_flex': flex,
            'button-text_interactive': tag !== 'span',
        }"
        @click="$emit('click', $event)"
        @mouseenter="$emit('mouseenter', $event)"
        @mouseleave="$emit('mouseleave', $event)"
    >
        <USpinner
            v-if="loading"
            class="btn-spin"
            :absoluteCenter="!spinnerLeft"
        ></USpinner>

        <slot
            v-if="prependIcon"
            name="prepend-icon"
        >
            <UIcon
                v-bind="prependIcon"
                class="mr-8 button-text__icon"
            ></UIcon>
        </slot>

        <span class="button-text__label">
            <slot>
                {{ label }}
            </slot>
        </span>

        <slot
            v-if="appendIcon"
            name="append-icon"
        >
            <UIcon
                v-bind="appendIcon"
                class="ml-16 button-text__icon"
            ></UIcon>
        </slot>
    </component>
</template>

<script>
import buttonMixin from '@ui/mixins/button.js';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'UButtonText',

    components: {
        UIcon,
        USpinner,
    },

    mixins: [
        buttonMixin,
    ],

    // TODO: переделать на render
    props: {
        primary: Boolean,
        secondary: Boolean,
        dark: Boolean,
        dashed: Boolean,
        black: Boolean,
        underline: Boolean,
        appendIcon: { type: [Object, Boolean], default: false },
        prependIcon: { type: [Object, Boolean], default: false },
        flex: Boolean,
        spinnerLeft: Boolean,
    },
};
</script>

<style>
.button-text {
    position: relative;
    flex-shrink: 0;
    line-height: var(--small-lh);
    color: var(--dark-c);
    fill: var(--dark-c);
    text-align: start;
    transition: color var(--transition);
}

.button-text.button-text_disabled,
.button-text:disabled {
    color: var(--font-secondary-color);
    fill: var(--font-secondary-color);
}

.button-text.button-text_has-icon {
    display: inline-flex;
    align-items: center;
    max-width: 100%;
}

.button-text__label {
    position: relative;
    max-width: 100%;
    font-size: inherit;
    line-height: inherit;
    transition: color var(--transition);
}

.button-text.button-text_primary {
    color: var(--primary-color);
    fill: var(--primary-color);
}

.button-text.button-text_primary:disabled,
.button-text.button-text_primary.button-text_disabled {
    color: var(--primary-light-color);
    fill: var(--primary-light-color);
}

.button-text.button-text_black {
    color: var(--dark-c);
    fill: var(--dark-c);
}
.button-text.button-text_black:hover,
.button-text.button-text_black:active,
.button-text.button-text_black.button-text_active {
    color: var(--primary-color);
    fill: var(--primary-color);
}

.button-text.button-text_secondary {
    color: var(--font-secondary-color);
    fill: var(--font-secondary-color);
}

.button-text.button-text_secondary.button-text_dark {
    color: var(--font-secondary-dark-color);
    fill: var(--font-secondary-dark-color);
}

.button-text:disabled.button-text_secondary,
.button-text.button-text_secondary.button-text_disabled {
    color: var(--font-secondary-light-color);
    fill: var(--font-secondary-light-color);
}

.button-text.button-text_underline {
    position: relative;
}

.button-text.button-text_without-icon.button-text_underline::after,
.button-text.button-text_without-icon.button-text_dashed::after,
.button-text.button-text_has-icon.button-text_underline .button-text__label::after,
.button-text.button-text_has-icon.button-text_dashed .button-text__label::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    transition: border-bottom-color var(--transition);
}

.button-text.button-text_underline.button-text_without-icon::after,
.button-text.button-text_underline.button-text_has-icon .button-text__label::after {
    border-bottom: 1px solid currentColor;
}

.button-text.button-text_dashed.button-text_without-icon::after,
.button-text.button-text_dashed.button-text_has-icon .button-text__label::after {
    border-bottom: 1px dashed currentColor;
}

.button-text.button-text_has-icon.button-text_underline.button-text_hover .button-text__label::after,
.button-text.button-text_has-icon.button-text_underline:hover .button-text__label::after,
.button-text.button-text_has-icon.button-text_underline.button-text_focus .button-text__label::after,
.button-text.button-text_has-icon.button-text_underline:focus .button-text__label::after,
.button-text.button-text_has-icon.button-text_dashed.button-text_hover .button-text__label::after,
.button-text.button-text_has-icon.button-text_dashed:hover .button-text__label::after,
.button-text.button-text_has-icon.button-text_dashed.button-text_focus .button-text__label::after,
.button-text.button-text_has-icon.button-text_dashed:focus .button-text__label::after,
.button-text.button-text_without-icon.button-text_underline.button-text_hover::after,
.button-text.button-text_without-icon.button-text_underline:hover::after,
.button-text.button-text_without-icon.button-text_underline.button-text_focus::after,
.button-text.button-text_without-icon.button-text_underline:focus::after,
.button-text.button-text_without-icon.button-text_dashed.button-text_hover::after,
.button-text.button-text_without-icon.button-text_dashed:hover::after,
.button-text.button-text_without-icon.button-text_dashed.button-text_focus::after,
.button-text.button-text_without-icon.button-text_dashed:focus::after {
    border-bottom-color: transparent;
}

.button-text.button-text_secondary.button-text_underline.button-text_hover,
.button-text.button-text_secondary.button-text_underline:hover,
.button-text.button-text_secondary.button-text_underline.button-text_focus,
.button-text.button-text_secondary.button-text_underline:focus,
.button-text.button-text_secondary.button-text_dashed.button-text_hover,
.button-text.button-text_secondary.button-text_dashed:hover,
.button-text.button-text_secondary.button-text_dashed.button-text_focus,
.button-text.button-text_secondary.button-text_dashed:focus {
    color: var(--font-secondary-color);
    fill: var(--font-secondary-color);
}

.button-text.button-text_secondary.button-text_dark.button-text_underline:hover,
.button-text.button-text_secondary.button-text_dark.button-text_underline.button-text_hover,
.button-text.button-text_secondary.button-text_dark.button-text_underline.button-text_focus,
.button-text.button-text_secondary.button-text_dark.button-text_underline:focus,
.button-text.button-text_secondary.button-text_dark.button-text_dashed.button-text_hover,
.button-text.button-text_secondary.button-text_dark.button-text_dashed:hover,
.button-text.button-text_secondary.button-text_dark.button-text_dashed.button-text_focus,
.button-text.button-text_secondary.button-text_dark.button-text_dashed:focus {
    color: var(--font-secondary-dark-color);
    fill: var(--font-secondary-dark-color);
}

.button-text.button-text_loading > *:not(.btn-spin) {
    opacity: 0;
}

.btn-spin {
    position: absolute;
}
</style>