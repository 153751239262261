var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-collection"},[_c('div',{staticClass:"u-collection__header"},[_c('div',{staticClass:"u-collection__title"},[_vm._v("\n            "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),(_vm.innerValues.length)?_c('div',{staticClass:"u-collection__count"},[_vm._v("\n            "+_vm._s(_vm.innerValues.length)+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"u-collection__add"},[_c('UPopup',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var attrs = ref.attrs;
var handlers = ref.handlers;
return [_c('UButtonRound',_vm._g(_vm._b({attrs:{"name":"plus-16","primary":"","small":""}},'UButtonRound',attrs,false),handlers))]}},{key:"default",fn:function(ref){
var handlers = ref.handlers;
return [_vm._t("popup",null,null,{
                            handlers: Object.assign({}, handlers,
                                {save: _vm.onAdd})
                        })]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"u-collection__separator"}),_vm._v(" "),(!_vm.innerValues.length)?_c('div',{staticClass:"u-collection__empty"},[_vm._v("\n        Вы еще не добавили ни одного значения\n    ")]):_c('div',{staticClass:"u-collection__list"},_vm._l((_vm.innerValues),function(item,index){return _c('div',{key:item.id || item.uuid,staticClass:"u-collection__item"},[_c('div',{staticClass:"u-collection__label"},[_vm._t("label",null,null,{ item: item, index: index })],2),_vm._v(" "),_c('div',{staticClass:"u-collection__actions"},[_c('div',{staticClass:"u-collection__edit u-collection__action-item"},[_c('UPopup',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                        var attrs = ref.attrs;
                        var handlers = ref.handlers;
return [_c('UButtonRound',_vm._g(_vm._b({attrs:{"name":"edit-16","small":""}},'UButtonRound',attrs,false),handlers))]}},{key:"default",fn:function(ref){
                        var handlers = ref.handlers;
return [_vm._t("popup",null,null,{
                                    values: item,
                                    handlers: Object.assign({}, handlers,
                                        {save: function (e) { return _vm.onChange(index, item, e); }})
                                })]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"u-collection__remove u-collection__action-item"},[_c('UButtonRound',{attrs:{"name":"delete-16","small":"","secondary":""},on:{"click":function($event){return _vm.onRemove(index)}}})],1)])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }