<template>
    <div class="chat-template-header">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ChatTemplateHeader',
};
</script>

<style>
.chat-template-header {
    height: 60px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--border-light-c);
}
</style>