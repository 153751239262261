<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'InfoMessage',

    components: {
        UIcon,
    },

    props: {
        error: {
            type: Boolean,
            default: false,
        },

        flex: {
            type: Boolean,
            default: false,
        },

        yellow: Boolean,
        gray: Boolean,
        green: Boolean,
        red: Boolean,
    },

    methods: {
        normalizeContent(el) {
            if (el.children) {
                return el.children;
            }
            else if (el.text) {
                return el.text;
            }
            else {
                return null;
            }
        },
    },

    render(h) {
        const title = this.$scopedSlots.title ? this.$scopedSlots.title().map(el => {
            const normalizedContent = this.normalizeContent(el);

            if (normalizedContent) {
                return h(
                    'p',
                    {
                        class: [
                            'text',
                            'title',
                        ],
                    },
                    normalizedContent,
                );
            }
        }) : [];

        const text = (this.$scopedSlots.text ? this.$scopedSlots.text() : this.$slots.default).map(el => {
            const normalizedContent = this.normalizeContent(el);

            if (normalizedContent) {
                return h(
                    'p',
                    {
                        class: 'text',
                    },
                    normalizedContent,
                );
            }
        });

        return h(
            'div',
            {
                class: [
                    'info-message',
                    {
                        error: this.error,
                        gray: this.gray,
                        yellow: this.yellow,
                        green: this.green,
                        red: this.red,
                    },
                ],
            },
            [
                h(
                    UIcon,
                    {
                        props: {
                            small: true,
                            name: 'info',
                        },
                        class: 'icon',
                    },
                ),
                h(
                    'div',
                    {
                        class: [
                            'text-wrap',
                            {
                                flex: this.flex,
                            }],
                    },
                    [
                        ...title,
                        ...text,
                    ],
                ),
            ],
        );
    },
};
</script>

<style scoped>
.info-message {
    display: flex;
    align-items: flex-start;
    padding: 12px 16px;
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-dark-color);
    fill: var(--font-secondary-dark-color);
    background-color: var(--accent-yellow-super-light);
    border-radius: var(--border-radius);
}

.info-message.yellow .icon {
    fill: var(--accent-yellow);
}

.info-message.yellow .title {
    color: var(--accent-yellow);
}

.info-message.green .icon {
    fill: var(--accent-green);
}

.info-message.green .title {
    color: var(--accent-green);
}

.info-message.red .icon {
    fill: var(--primary-color);
}

.info-message.red .title {
    color: var(--primary-color);
}

.info-message.error {
    color: var(--primary-color);
    fill: var(--primary-color);
    background-color: var(--primary-lightest-color);
}

.info-message.gray {
    color: var(--light-c);
    background-color: var(--font-secondary-color);
}

.info-message.gray .icon {
    fill: var(--light-c);
}

.icon {
    margin-right: 8px;
    flex-shrink: 0;
}

.title {
    margin-bottom: 12px;
    font-size: var(--small-fz);
    line-height: 1.5;
}

.text {
    font-size: var(--small-fz);
    line-height: 1.5;
}

.text:not(:last-child) {
    margin-bottom: 12px;
}

.text-wrap.flex {
    display: flex;
}
</style>