<template>
    <PopupWrap
        verticalCenterNotXS
        :loading="initLoading"
        @close="close"
    >
        <FormManager
            v-if="!initLoading"
            ref="form"
            v-model="formData"
            :formTag="false"
            class="popup-content"
        >
            <h2 class="h2 h2_block popup-title">
                Оформить доставку СДЭК
            </h2>

            <UDropdown
                ref="dropdown"
                :options="searchOptions"
                :initialized="searchInitialized"
                class="dropdown dropdown_city"
                @select="onSelectCity"
            >
                <template #default="{ handlers }">
                    <UInputSearch
                        :value="searchValue"
                        :loading="searching"
                        label="Куда доставить? *"
                        :clearInputOnFocus="!!currentCity"
                        autocomplete="off"
                        :invalid="cityFieldHasError"
                        @blur="handlers.blur(); $nextTick(onBlurSearchValue)"
                        @focus="handlers.focus(); $nextTick(onFocus)"
                        @input="onChangeSearchValue"
                        @keydown="handlers.keydown"
                        @click:clear="onClear"
                    ></UInputSearch>
                </template>

                <template #label="{ option }">
                    <p class="ellipsis">
                        {{ option.full_name }}
                    </p>
                </template>
            </UDropdown>

            <FormField
                type="radio"
                name="receiving_method"
                :options="[
                    {
                        value: 'pickup',
                        label: 'Забрать из пункта выдачи',
                    },
                    {
                        value: 'courier',
                        label: 'Доставить курьером',
                    }
                ]"
                class="form-field-gap_top"
            ></FormField>

            <section class="top-gap_small">
                <template v-if="formData.receiving_method === 'courier'">
                    <FormField
                        name="address"
                        type="address"
                        label="Адрес доставки"
                        validations="required|house|flat"
                        :validationRules="{
                            house: ({ value }) => !(value || {}).house,
                            flat: ({ value }) => !(value || {}).flat,
                        }"
                        :validationMessages="{
                            house: 'Укажите адрес полностью, включая дом, квартиру или офис, если есть.',
                            flat: 'Укажите адрес полностью, квартиру или офис, если есть.',
                        }"
                        :queryOptions="{
                            location_city: (currentCity || {}).name,
                        }"
                        :reduceResponse="addressFieldReducer"
                        @change="onSelectCourierAddress"
                    ></FormField>
                    <p class="text_small-fz text_secondary mt-8">
                        Укажите адрес полностью, включая квартиру или офис, если есть.
                    </p>

                    <template v-if="formData.address && isAddressValid">
                        <USpinner
                            v-if="fetchingCourierData"
                            center
                            class="mt-20"
                        ></USpinner>

                        <InfoMessage
                            v-else-if="!courierData"
                            class="mt-20"
                        >
                            Курьерская доставка заказов по выбранному адресу недоступна.
                            Пожалуйста, выберите другой адрес рядом с вами.
                        </InfoMessage>

                        <div
                            v-else
                            class="form-field-gap_top courier-option"
                        >
                            <img
                                :src="$links.uploads + courierData.service.logo.thumbnails.delivery_service_logo_big"
                                alt=""
                                class="transport__logo"
                            >
                            <b
                                class="d-block"
                            >
                                {{ courierData.deadline | dt('DD MMMM') }} /
                                {{ courierData.price | price }}
                            </b>
                        </div>
                    </template>
                </template>

                <template v-if="formData.receiving_method === 'pickup'">
                    <div
                        v-if="selectedPickupPoint"
                        class="selected-pickup-point"
                    >
                        <div class="selected-pickup-point__section selected-pickup-point__section_main">
                            <img
                                :src="$links.uploads
                                    + selectedPickupPoint.service.logo.thumbnails.delivery_service_logo_big"
                                alt=""
                                class="selected-pickup-point__logo"
                            >
                            <p>
                                {{ selectedPickupPoint.city }},
                                {{ selectedPickupPoint.address }}
                            </p>
                            <ButtonText
                                dashed
                                secondary
                                dark
                                class="selected-pickup-point__reset-btn"
                                @click="resetSelectedPickupPoint(); getDeliveryServiceData()"
                            >
                                Изменить
                            </ButtonText>
                        </div>
                        <div class="selected-pickup-point__section">
                            <div class="schedule">
                                <div
                                    v-for="(item, index) in selectedPickupPoint.work_times_merged"
                                    :key="`day-${ index }`"
                                    class="schedule-row"
                                >
                                    <div class="schedule-td">
                                        {{ item.day_name }}
                                    </div>
                                    <div
                                        v-if="item.is_day_off"
                                        class="schedule-td text_red"
                                    >
                                        Выходной
                                    </div>
                                    <div
                                        v-else
                                        class="schedule-td"
                                    >
                                        {{ item.start_time.slice(0,-3) }} – {{ item.end_time.slice(0,-3) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="selected-pickup-point__section">
                            <USpinner
                                v-if="selectedPickupPoint.loading"
                                center
                            ></USpinner>
                            <b v-else>
                                {{ selectedPickupPoint.deadline | dt('DD MMMM') }} /
                                {{ selectedPickupPoint.price | price }}
                            </b>
                        </div>
                    </div>
                    <template v-else>
                        <div
                            class="pickup-section_desktop"
                            :class="{
                                'pickup-section_desktop_hidden': !selectPickupPointMobileView
                            }"
                        >
                            <div class="select-head pickup-section_desktop__head">
                                <UButtonIcon
                                    :icon="{
                                        name: 'arrow-left',
                                        big: true,
                                    }"
                                    :secondary="false"
                                    class="select-head__back-btn"
                                    @click="selectPickupPointMobileView = false"
                                ></UButtonIcon>
                                <h4 class="h4 select-head__title">
                                    Выбор пункта выдачи
                                </h4>
                            </div>

                            <div class="select-body">
                                <UDropdown
                                    ref="address-dropdown"
                                    :options="pointSearchOptions"
                                    class="dropdown"
                                    @select="onSelectSearchPoint"
                                >
                                    <template #default="{ handlers }">
                                        <UInputSearch
                                            :value="pointSearchValue"
                                            placeholder="Адрес пункта выдачи"
                                            :clearInputOnFocus="!!pointSearch"
                                            autocomplete="off"
                                            @input="onChangePointSearchValue"
                                            @blur="handlers.blur();"
                                            @focus="handlers.focus();"
                                            @keydown="handlers.keydown"
                                        ></UInputSearch>
                                    </template>

                                    <template #label="{ option }">
                                        <p class="ellipsis">
                                            {{ option.address }}
                                        </p>
                                    </template>
                                </UDropdown>

                                <div class="map-wrap map-wrap_desktop">
                                    <div
                                        v-if="getDeliveryServiceDataLoading"
                                        class="spinner-wrap"
                                    >
                                        <USpinner
                                            big
                                            class="spinner"
                                        ></USpinner>
                                    </div>

                                    <yandexMap
                                        ref="map"
                                        class="map"
                                        :coords="mapDefaultCenter"
                                        zoom="13"
                                        :controls="['zoomControl']"
                                        :scrollZoom="false"
                                        :options="{ suppressMapOpenBlock: true }"
                                        :showAllMarkers="true"
                                        :clusterOptions="{
                                            cdek: {
                                                clusterDisableClickZoom: false,
                                                clusterOpenBalloonOnClick: false,
                                                clusterIconColor: '#00B33C',
                                            },
                                        }"
                                        @map-was-initialized="setMapCenter"
                                        @actionend="handleActionEnd"
                                    >
                                        <ymapMarker
                                            v-for="(point, index) in deliveryService.pickup_points"
                                            :key="'point-' + point.code"
                                            :ref="'point-' + point.code"
                                            :icon="getYMapMarkerIcon({
                                                type: point.service.codename,
                                                active: balloon.visible && balloon.index === index
                                            })"
                                            :coords="point.coordinates"
                                            :markerId="'point-' + point.code"
                                            :clusterName="point.service.codename"
                                            @click="showBalloon(point, index)"
                                        ></ymapMarker>
                                    </yandexMap>

                                    <PickupPointBalloon
                                        v-if="balloon.visible"
                                        :point="balloon.data"
                                        :orderUUID="order.uuid"
                                        :city="currentCity"
                                        :order="order"
                                        @getData="handlePointUpdateData"
                                        @close="resetBalloon"
                                        @select="selectPickupPoint"
                                    ></PickupPointBalloon>
                                </div>

                                <InfoMessage class="pick-up-points-note">
                                    Если вы не нашли свой пункт выдачи, скорее всего,
                                    заказ не&nbsp;может быть доставлен туда
                                    из-за ограничений на&nbsp;максимальный вес отправления.
                                </InfoMessage>
                            </div>
                        </div>

                        <div class="pickup-section_mobile">
                            <div
                                class="map-wrap map-wrap_mobile"
                                @click="selectPickupPointMobileView = true"
                            >
                                <img
                                    v-if="currentCity && currentCity.coordinates"
                                    :src="`https://static-maps.yandex.ru/1.x/?ll=${ currentCityCoordinatesArr[1] },${ currentCityCoordinatesArr[0] }&z=12&size=650,200&l=map&lang=ru_RU`"
                                    class="pickup-section__pseudo-map"
                                    alt=""
                                >
                                <UButtonBlock
                                    primary
                                    low
                                    class="pickup-section__btn"
                                >Выбрать пункт выдачи</UButtonBlock>
                            </div>
                        </div>
                    </template>
                </template>
            </section>

            <section class="top-gap_small">
                <h3 class="h4 h4_block">
                    Получатель
                </h3>

                <div class="receiver-form">
                    <FormField
                        name="last_name"
                        label="Фамилия"
                        validations="required"
                        class="receiver-form__field"
                    ></FormField>
                    <FormField
                        name="first_name"
                        label="Имя"
                        validations="required"
                        class="receiver-form__field"
                    ></FormField>
                    <FormField
                        name="patronymic"
                        label="Отчество"
                        validations="required"
                        class="receiver-form__field"
                    ></FormField>
                    <FormField
                        type="phone"
                        name="phone"
                        label="Телефон"
                        visibleMask
                        validations="required"
                        class="receiver-form__field"
                    ></FormField>
                    <FormField
                        type="text"
                        name="comment"
                        label="Комментарий для службы доставки"
                        class="receiver-form__field receiver-form__field_comment"
                    ></FormField>
                </div>
            </section>

            <div class="footer">
                <p class="text_small-fz text_secondary">
                    К оплате принимаются карты VISA, MasterCard и МИР.
                    Для совершения оплаты вы будете перенаправлены на безопасную страницу ПАО «Сбербанк».
                    Для дополнительной аутентификации держателя карты используется защищенный протокол 3D Secure.
                </p>

                <div class="actions-wrap">
                    <UButtonBlock
                        secondary
                        class="action-btn"
                        @click="close"
                    >
                        Отмена
                    </UButtonBlock>
                    <UButtonBlock
                        primary
                        class="action-btn"
                        :loading="submitLoading"
                        :disabled="disabledSubmitButton"
                        @click="getDeliveryPaymentLink"
                    >
                        Оформить<template v-if="deliveryPrice">
                            за {{ deliveryPrice | price }}
                        </template>
                    </UButtonBlock>
                </div>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import { mapState } from 'vuex';
import { HTTP } from '@/http.js';
import { price, dt } from '@ui/filters/index.js';
import dictionary from '@/dictionary.js';
import equals from '@/lib/equals.js';
import { getErrorCode } from '@/lib/errors.js';
import breakpointKey from '@/mixins/breakpointKey.js';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import popup from '@/mixins/popup.js';
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import FormField from '@/components/_form/FormField.vue';
import FormManager from '@/components/_form/FormManager.vue';
import UDropdown from '@/components/UDropdownDeprecated/UDropdownDeprecated.vue';
import UInputSearch from '@/components/InputSearch.vue';
import PickupPointBalloon from '@/components/PickupPointBalloon.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UButtonBlock from '@ui/components/UButton/UButtonBlock.vue';
import UButtonIcon from '@ui/components/UButton/UButtonIcon.vue';


export default {
    name: 'CdekDeliveryPopup',

    components: {
        UButtonIcon,
        UButtonBlock,
        USpinner,
        InfoMessage,
        ButtonText,
        PickupPointBalloon,
        yandexMap,
        ymapMarker,
        FormField,
        UInputSearch,
        UDropdown,
        FormManager,
        PopupWrap,
    },

    filters: {
        price,
        dt,
    },

    mixins: [ymapMarkerMixin, popup, handleFormErrorMixin, breakpointKey],

    props: {
        order: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            dictionary,
            initLoading: true,

            searchValue: '',
            searchOptions: [],
            searchSource: null,
            searchTimeout: null,
            searching: false,
            searchInitialized: false,
            confirming: false,
            currentCity: null,
            cityFieldHasError: false,
            fetchingCurrentCity: false,

            pointSearchValue: '',
            pointSearch: null,

            formData: {
                receiving_method: 'pickup',
                address: '',
                last_name: '',
                first_name: '',
                patronymic: '',
                phone: '',
                comment: '',
            },
            submitLoading: false,

            deliveryService: {},
            selectedPickupPoint: null,
            getDeliveryServiceDataLoading: false,

            courierData: null,
            fetchingCourierData: false,

            mapInstance: null,
            mapCenter: [],
            mapDefaultCenter: [],
            balloon: {
                data: null,
                visible: false,
                index: null,
            },

            selectPickupPointMobileView: true,
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),

        currentCityCoordinatesArr() {
            return this.currentCity ? this.currentCity.coordinates.split(', ') : [];
        },

        isAddressValid() {
            return !this.$refs.form.innerErrors.address.length;
        },

        pointSearchOptions() {
            if (this.pointSearchValue) {
                const re = new RegExp(this.pointSearchValue, 'i');
                return this.deliveryService.pickup_points.filter(item => re.test(item.address));
            }

            return this.deliveryService.pickup_points;
        },

        deliveryPrice() {
            if (this.formData.receiving_method === 'pickup' && this.selectedPickupPoint) {
                return this.selectedPickupPoint.price;
            }
            else if (this.formData.receiving_method === 'courier' && this.courierData) {
                return this.courierData.price;
            }

            return null;
        },

        disabledSubmitButton() {
            if (this.formData.receiving_method === 'pickup') {
                return !this.selectedPickupPoint;
            }
            else if (this.formData.receiving_method === 'courier') {
                return !this.courierData;
            }

            return true;
        },
    },

    watch: {
        breakpointKey: {
            handler (value){
                this.selectPickupPointMobileView = value !== 's';
            },
            immediate: true,
        },

        currentCity: {
            handler(value) {
                if (value) {
                    this.updateMapDefaultCenter();
                    this.formData.address = '';
                }
            },

            immediate: true,
        },
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        clearTimeout(this.searchTimeout);
        if (this.searchSource) this.searchSource.cancel();
    },

    methods: {
        async init() {
            this.formData.last_name = this.profile.last_name || '';
            this.formData.first_name = this.profile.first_name || '';
            this.formData.patronymic = this.profile.patronymic || '';
            this.formData.phone = this.profile.phone || '';

            this.deliveryService = {
                codename: 'cdek',
                name: 'СДЭК',
                logo: require('../../../../assets/images/cdek-logo.png'),
                price: null,
                days: null,
                disabled: false,
                pickup_points: [],
            };

            const [deliveryServices, city] = await Promise.all([
                this.$api.delivery.services(),
                this.$api.cities.get(this.order.delivery_city.id),
            ]);

            deliveryServices.results.forEach(item => {
                if (item.codename === 'cdek') {
                    this.deliveryService = {
                        ...this.deliveryService,
                        ...item,
                    };
                }
            });

            this.onSelectCity({ value: city });

            this.initLoading = false;
        },

        updateMapDefaultCenter() {
            if (this.currentCity && this.currentCity.coordinates.length) {
                this.mapDefaultCenter = this.currentCity.coordinates.split(', ').map(_ => Number(_));
            }
        },

        onSelectCity({ value: option }) {
            this.currentCity = option;
            this.searchValue = option.full_name;
            this.cityFieldHasError = false;
            this.resetBalloon();
            this.resetSelectedPickupPoint();
            this.getDeliveryServiceData();
        },

        onClear() {
            this.searchInitialized = false;
            this.cityFieldHasError = false;
            // ждём, пока не закончится анимация
            setTimeout(() => {
                this.searchOptions = [];
            }, 200);
        },

        onFocus() {
            if (this.currentCity || !this.searchValue) return;
            this.fetchCities();
        },

        async fetchCities() {
            if (this.searchSource) {
                this.searchSource.cancel();
                this.searchSource = null;
            }

            const search = this.searchValue;

            if (!search || search.length < 2) return;

            this.searching = true;
            const limit = 6;
            const params = { search, limit };
            this.searchSource = HTTP.CancelToken.source();
            const cancelToken = this.searchSource.token;
            const config = { params, cancelToken };

            try {
                const { results } = await this.$api.cities.get(config);

                this.searchOptions = results;
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;

                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.searching = false;

                // ждём, пока options обновится в UDropdown
                // чтобы корректно отработала анимация
                this.$nextTick(() => {
                    this.searchInitialized = true;
                });
            }
        },

        async setFetchCitiesTimeout() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }

            if (this.searchValue) {
                this.searchTimeout = setTimeout(() => {
                    this.fetchCities();
                }, 500);
            }
            else {
                if (this.searchSource) {
                    this.searchSource.cancel();
                    this.searchSource = null;
                }
            }
        },

        onChangeSearchValue({ value }) {
            if (value === this.searchValue) return;

            this.searchValue = value;
            this.setFetchCitiesTimeout();
            // this.currentCity = null;
        },

        onBlurSearchValue() {
            this.cityFieldHasError = this.searchValue && !this.currentCity;
            this.searchInitialized = false;
        },

        onChangePointSearchValue({ value }) {
            if (value === this.pointSearchValue) return;
            this.pointSearchValue = value;
            this.pointSearch = null;
        },

        onSelectSearchPoint({ value: option }) {
            this.pointSearch = option;
            this.pointSearchValue = this.currentCity.full_name + ', ' + option.address;
            this.showBalloon(option);
        },

        async getDeliveryServiceData() {
            // 1. Запрос на пункты выдачи => анализ наличия возможности забрать из ПВЗ
            // 2. Запрос на предрасчёт цен и сроков по всем СД (by_city) => анализ наличия курьерской доставки

            // ПВЗ
            // 1. Отображение карты с ПВЗ
            // 2. Отображение балуна ПВЗ
            // 3. Поиск ПВЗ по адресу
            // 4. Выбор ПВЗ
            // 5. Изменение ПВЗ
            // 6. Оплата

            // Курьер
            // 1. Получение СД(цен и сроков) по выбранном адресу (by_order)
            // 2. Отображение СД
            // 3. Выбор СД
            // 4. Оплата

            this.getDeliveryServiceDataLoading = true;

            const responses = await Promise.all([
                this.$api.delivery.offices({ params: {
                    city: this.currentCity.id,
                    service__codename__in: 'cdek',
                } }),
                this.$api.delivery.estimate.byCity({
                    service: 'cdek',
                    city_from: this.order.shop.city.id,
                    shop_from: this.order.shop.id,
                    city_to: this.currentCity.id,
                    weight: this.order.weight,
                }),
            ]);

            const deliveryOffices = responses[0].results;
            const estimate = responses[1];

            deliveryOffices.forEach(item => {
                item.coordinates.reverse();

                this.deliveryService.pickup_points.push(item);
            });

            this.deliveryService = Object.assign(
                this.deliveryService,
                {
                    pickupPointsAmount: this.deliveryService.pickup_points.length,
                    tariff_code: estimate.tariff_code,
                    price: estimate.total_price,
                    disabled: false,
                },
            );

            this.getDeliveryServiceDataLoading = false;
        },

        setMapCenter(mapInstance) {
            this.mapInstance = mapInstance;
            if (this.deliveryService.pickup_points.length > 1) {
                const pointsList = this.deliveryService.pickup_points.map(item => item.coordinates);
                mapInstance.setBounds(ymaps.util.bounds.fromPoints(pointsList),{ checkZoomRange: true });
            }
        },

        handleActionEnd(e) {
            const newCenter = e.originalEvent.map.getCenter();
            if (this.mapCenter[0] !== newCenter[0] || this.mapCenter[1] !== newCenter[1]) {
                this.mapCenter = newCenter;
            }
        },

        showBalloon(point, index) {
            this.resetBalloon();
            this.balloon.visible = true;
            this.balloon.data = point;
            this.balloon.index = index;
            this.$nextTick(() => {
                if (this.mapInstance && this.mapInstance.length === 2) this.mapInstance.setCenter(this.mapCenter);
            });
        },

        resetBalloon() {
            this.balloon.visible = false;
            this.balloon.data = null;
            this.balloon.index = null;
            this.$nextTick(() => {
                if (this.mapInstance && this.mapInstance.length === 2) this.mapInstance.setCenter(this.mapCenter);
            });
        },

        selectPickupPoint({ point, priceData }) {
            this.selectedPickupPoint = Object.assign({
                price: priceData.total_price,
                tariff_code: priceData.tariff_code,
                deadline: priceData.deadline,
                loading: false,
            }, point);
        },

        handlePointUpdateData(data) {
            if (data) {
                const point = this.deliveryService.pickup_points.find(item => {
                    return item.code === data.code;
                });

                if (point) {
                    point.priceData = data.priceData || {};
                }
            }
        },

        resetSelectedPickupPoint() {
            this.pointSearch = null;
            this.pointSearchValue = '';
            this.selectedPickupPoint = null;
            Object.assign(
                this.deliveryService,
                {
                    pickup_points: [],
                    pickupPointsAmount: 0,
                    tariff_code: null,
                    price: null,
                    disabled: true,
                },
            );
        },

        addressFieldReducer(data) {
            return data.reduce((acc, item) => {
                const fullAddress = [];
                if (item.settlement_with_type) fullAddress.push(item.settlement_with_type);
                if (item.street_with_type) fullAddress.push(item.street_with_type);
                if (item.house) fullAddress.push(item.house_type + ' ' + item.house);
                if (item.block) fullAddress.push(item.block_type + ' ' + item.block);
                if (item.flat) fullAddress.push(item.flat_type + ' ' + item.flat);
                acc.push({
                    ...item,
                    value: fullAddress.join(', '),
                });
                return acc;
            }, []);
        },

        onSelectCourierAddress(option) {
            if (!equals(this.formData.address, option)) {
                this.courierData = null;
                if (option) {
                    this.$nextTick(() => {
                        if (this.isAddressValid) {
                            this.getCourierOptionsPriceByAddress(option.value);
                        }
                    });
                }
            }
        },

        getCourierOptionsPriceByAddress(address_to) {
            this.fetchingCourierData = true;

            const data = {
                service: 'cdek',
                order: this.order.uuid,
                address_to,
                office_to: '',
                city_to: this.currentCity.id,
            };

            this.$api.delivery.estimate.byOrder(data)
                .then(res => {
                    const courierOption = {
                        value: 'cdek',
                        price: res.total_price,
                        deadline: res.deadline,
                        service: this.deliveryService,
                    };

                    if (!res.tariff_code) courierOption.disabled = true;

                    this.courierData = courierOption;
                })
                .catch(() => {
                    //
                })
                .finally(() => {
                    this.fetchingCourierData = false;
                });
        },

        async getDeliveryPaymentLink() {
            const errors = await this.$refs.form.validate();
            if (errors) return;

            if (!this.currentCity) {
                this.cityFieldHasError = true;
                this.$error('Укажите город для доставки.');
                return;
            }

            if (this.formData.receiving_method === 'pickup') {
                if (!this.selectedPickupPoint) {
                    this.$error('Выберите пункт выдачи заказа.');
                    return;
                }
            }

            this.submitLoading = true;

            const data = {
                address_to: '',
                address_full_to: null,
                office_to: '',

                order: this.order.uuid,
                city_to: this.currentCity.id,
                first_name_to: this.formData.first_name,
                second_name_to: this.formData.patronymic,
                last_name_to: this.formData.last_name,
                phone_to: this.formData.phone,
                user_comment: this.formData.comment,
            };

            if (this.formData.receiving_method === 'courier') {
                data.address_to = this.formData.address.value;
                data.address_full_to = {
                    location: this.formData.address.settlement || '',
                    street: this.formData.address.street,
                    house: this.formData.address.house,
                    building: this.formData.address.block || '',
                    corpus: this.formData.address.block || '',
                    office: this.formData.address.flat,
                    room: this.formData.address.flat,
                };
            }
            else {
                data.office_to = this.selectedPickupPoint.id;
            }

            try {
                const { payment_url } = await this.$api.delivery.cdek.paymentLink(data);
                window.location.href = payment_url;
            }
            catch (error) {
                const code = getErrorCode(error);

                if (code === 400) {
                    const data = error.response.data;
                    const { non_field_errors } = data;
                    if (non_field_errors && non_field_errors.find(_ => _.code === 'not_estimated')) {
                        this.$error('Расчёт стоимости доставки устарел. Оформить доставку можно после обновления цены.');

                        if (this.selectedPickupPoint) {
                            const data = {
                                service: 'cdek',
                                order: this.order.uuid,
                                address_to: '',
                                office_to: this.selectedPickupPoint.id,
                            };
                            this.selectedPickupPoint.loading = true;
                            this.$api.delivery.estimate.byOrder(data)
                                .then(res => {
                                    this.selectedPickupPoint = Object.assign(this.selectedPickupPoint, {
                                        price: res.total_price,
                                        deadline: res.deadline,
                                    });
                                })
                                .catch(() => {
                                })
                                .finally(() => {
                                    this.selectedPickupPoint.loading = false;
                                });
                        }
                        else {
                            const data = {
                                service: 'cdek',
                                order: this.order.uuid,
                                address_to: this.formData.address,
                                office_to: '',
                            };
                            this.$api.delivery.estimate.byOrder(data)
                                .then(res => {
                                    this.courierData = Object.assign(this.courierData, {
                                        price: res.total_price,
                                        deadline: res.deadline,
                                    });
                                })
                                .catch(() => {
                                    //
                                })
                                .finally(() => {
                                    //
                                });
                        }
                    }
                    else {
                        this.$store.commit('handleCommonHttpError', error);
                    }
                }
                else {
                    this.$store.commit('handleCommonHttpError', error);
                }
            }
            this.submitLoading = false;
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 780px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 636px;
    }
}

.courier-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid var(--border-light-c);
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    font-family: var(--f-semi-bold);
}

@media (min-width: 768px) {
    .receiver-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }

    .receiver-form__field_comment {
        grid-column: 1/3;
    }
}
@media (max-width: 767px) {
    .receiver-form__field:not(:last-child) {
        margin-bottom: 16px;
    }
}

.footer {
    padding-top: 36px;
}
@media (max-width: 767px) {
    .footer {
        margin-top: auto;
    }
}

.actions-wrap {
    margin-top: 20px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

.transport__logo {
    width: 60px;
    height: 40px;
}

.map-wrap {
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
}
@media (min-width: 768px) {
    .map-wrap {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .map-wrap_desktop {
        width: calc(100% + var(--content-gap) * 2);
        max-height: 617px;
        height: 100%;
        margin-left: var(--content-gap-negative);
        margin-top: 8px;
    }

    .map-wrap_mobile {
        height: 100px;
    }
}

.spinner-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    opacity: .5;
    z-index: 1;
}

.map {
    width: 100%;
}
@media (min-width: 768px) {
    .map {
        height: 580px;
    }
}
@media (max-width: 767px) {
    .map {
        height: 100%;
        max-height: 617px;
    }
}

.pickup-section__pseudo-map {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    max-width: 515px;
    min-height: 100px;
    object-fit: cover;
}

@media (max-width: 767px) {
    .pickup-section_desktop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: calc(var(--popup-close-button-z-index) + 1);
        display: flex;
        flex-direction: column;
        background-color: var(--light-c);
    }

    .pickup-section_desktop_hidden {
        opacity: 0;
        z-index: -1;
    }

    .select-body {
        flex-grow: 1;
        padding-top: 20px;
        padding-left: var(--content-gap);
        padding-right: var(--content-gap);
    }
}

.pickup-section_mobile {
    display: none;
}
@media (max-width: 767px) {
    .pickup-section_mobile {
        display: block;
    }
}

.pickup-section__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
}

.select-head {
    display: none;
}
@media (max-width: 767px) {
    .select-head {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: calc(var(--content-gap) - 6px);
        padding-right: calc(var(--content-gap) + 36px - 6px);
        border-bottom: 1px solid var(--border-light-c);
    }
}

.select-head__back-btn {

}
.select-head__title {
    margin: auto;
}

@media (min-width: 768px) {
    .pick-up-points-note {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .pick-up-points-note {
        position: absolute;
        z-index: 2;
        left: var(--content-gap);
        right: var(--content-gap);
        bottom: 20px;
    }
}

.selected-pickup-point {
    border-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .selected-pickup-point {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.selected-pickup-point__section {
    position: relative;
    padding: 20px;
    border: 1px solid var(--border-light-c);
}
.selected-pickup-point__section_main {
    border-bottom: none;
    border-radius: var(--border-radius-x2) var(--border-radius-x2) 0 0;
}
@media (min-width: 768px) {
    .selected-pickup-point__section:nth-child(2) {
        border-right: none;
        border-bottom-left-radius: var(--border-radius-x2);
    }

    .selected-pickup-point__section:nth-child(3) {
        text-align: right;
        border-bottom-right-radius: var(--border-radius-x2);
    }
    .selected-pickup-point__section:nth-child(3) .spinner {
        margin-left: auto;
    }
    .selected-pickup-point__section_main {
        grid-column: 1/3;
        border-bottom: none;
    }
}
@media (max-width: 767px) {
    .selected-pickup-point__section:nth-child(2) {
        border-bottom: none;
    }

    .selected-pickup-point__section:nth-child(3) {
        border-radius: 0 0 var(--border-radius-x2) var(--border-radius-x2);
    }
}

.selected-pickup-point__logo {
    width: 60px;
    height: 40px;
    margin-bottom: 12px;
}

.selected-pickup-point__reset-btn {
    position: absolute;
    top: 20px;
    right: 10px;
}

.schedule-row {
    display: flex;
}

.schedule-row:not(:last-child) {
    margin-bottom: 8px;
}

.schedule-row::after {
    content: "";
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 5px;
    order: 2;
    align-self: flex-end;
    flex-grow: 1;
    border-bottom: 1px dotted var(--border-dark-c);
}

.schedule-td:first-child {
    order: 1;
}

.schedule-td:last-child {
    order: 3;
}
</style>