export default context => {
    return {
        namespaced: true,

        state: () => ({
            info_menu: [],
            footer_menu: [],
            vkontakte: '',
            instagram: '',
            facebook: '',
            requisites: '',
            phone: '',
            email: '',
            disable_text: '',
            disable_title: '',
            tire_selection_category: '',
            rim_selection_category: '',
            additional_meta_tags: '',
            office_address: '',
            mail_address: '',
            company_name: '',
        }),

        mutations: {
            set(state, data) {
                state.info_menu = data.info_menu;
                state.footer_menu = data.footer_menu;
                state.vkontakte = data.vkontakte;
                state.instagram = data.instagram;
                state.facebook = data.facebook;
                state.requisites = data.requisites;
                state.phone = data.phone;
                state.email = data.email;
                state.disable_text = data.disable_text;
                state.disable_title = data.disable_title;
                state.tire_selection_category = data.tire_selection_category;
                state.rim_selection_category = data.rim_selection_category;
                state.additional_meta_tags = data.additional_meta_tags;
                state.office_address = data.office_address;
                state.mail_address = data.mail_address;
                state.company_name = data.company_name;
            },
        },

        actions: {
            async get({ commit }) {
                try {
                    const response = await this.$api.settings.get();
                    const { yandex_count, google_count, roistat_count, ...settings } = response;
                    context.counters = {
                        yandex_count, google_count, roistat_count,
                    };

                    commit('set', settings);
                    return { settings };
                }
                catch (error) {
                    commit('handleInitError', error, { root: true });
                }
            },
        },
    };
};