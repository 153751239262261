<template>
    <div class="review-wrap">
        <div class="review__main">
            <div class="review-author">
                <UserAvatar
                    :user="review.user"
                    size="m"
                    class="user-avatar flex-initial"
                ></UserAvatar>

                <div class="flex-1">
                    <div class="user-name">
                        {{ review.user.first_name }} {{ review.user.last_name }}
                    </div>

                    <div class="date-rating-wrap">
                        <StarsRating
                            class="mr-12"
                            :value="review.rating"
                            :showValue="false"
                        ></StarsRating>

                        <span class="date">
                            {{ review.create_date | dt }}
                        </span>
                    </div>
                </div>

                <UMenu
                    v-if="!review.is_owner
                        || review.response
                        || (review.is_editable && review.is_owner && !review.response)"
                    noDetach
                    placement="left"
                    class="review-menu flex-initial"
                >
                    <UMenuItem
                        v-if="!review.is_owner"
                        @click="handleClickComplainReview"
                    >
                        Пожаловаться
                    </UMenuItem>
                    <UMenuItem
                        v-if="review.response"
                        @click="handleClickComplainResponse"
                    >
                        Пожаловаться на ответ
                    </UMenuItem>
                    <UMenuItem
                        v-if="review.is_editable && review.is_owner && !review.response"
                        @click="handleClickEditReview"
                    >
                        Редактировать отзыв
                    </UMenuItem>
                    <UMenuItem
                        v-if="review.is_editable && review.is_owner && !review.response"
                        @click="handleClickRemoveReview"
                    >
                        <span class="text_red">Удалить отзыв</span>
                    </UMenuItem>
                </UMenu>
            </div>

            <div class="main">
                <div
                    v-if="review.usage_time"
                    class="review-section"
                >
                    <h3 class="title">
                        Срок использования:
                    </h3>
                    <p class="review-text">
                        {{ review.usage_time_name }}
                    </p>
                </div>
                <div
                    v-if="review.advantages"
                    class="review-section"
                >
                    <h3 class="title">
                        Достоинства:
                    </h3>
                    <p class="review-text p-textarea">{{ review.advantages }}</p>
                </div>
                <div
                    v-if="review.disadvantages"
                    class="review-section"
                >
                    <h3 class="title">
                        Недостатки:
                    </h3>
                    <p class="review-text p-textarea">{{ review.disadvantages }}</p>
                </div>
                <div
                    v-if="review.comment"
                    class="review-section"
                >
                    <h3
                        v-if="!shop"
                        class="title"
                    >
                        Комментарий:
                    </h3>
                    <p class="review-text p-textarea">{{ review.comment }}</p>
                </div>

                <div
                    v-if="review.photos && review.photos.length"
                    class="review-section photos-row"
                >
                    <button
                        v-for="(item, index) in review.photos"
                        :key="'photo-' + item.uuid"
                        class="photo-btn"
                        @click="showPhotoViewerPopup(index)"
                    >
                        <img
                            :src="$links.uploads + item.thumbnails.review_photo_mini"
                            alt=""
                            class="photo"
                        >
                    </button>
                </div>
            </div>
        </div>

        <div
            v-if="review.response"
            class="answer-wrap"
        >
            <div class="shop-title-date-wrap">
                <span class="answer-title">Ответ магазина</span>
                <span class="answer-date">
                    {{ review.response.create_date | dt }}
                </span>
            </div>
            <p class="p-textarea">{{ review.response.comment }}</p>
        </div>
    </div>
</template>

<script>
import StarsRating from './StarsRating.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import UMenu from '@ui/components/UMenu/UMenu.vue';
import UMenuItem from '@ui/components/UMenu/UMenuItem.vue';
const ComplainReviewPopup = () => import('@/pages/account/reviews/ComplainReviewPopup.vue');
const ComplainReviewResponsePopup = () => import('@/pages/market/shops/_id/ComplainReviewResponsePopup.vue');
const RemoveReviewPopup = () => import('@/pages/account/reviews/RemoveReviewPopup.vue');
const MarketItemReviewPopup = () => import('@/components/popups/MarketItemReviewPopup.vue');
const ShopReviewPopup = () => import('@/components/popups/ShopReviewPopup.vue');
const PhotoViewer = () => import('@/components/popups/PhotoViewer.vue');

export default {
    name: 'PagesReview',

    components: {
        UMenuItem,
        UMenu,
        UserAvatar,
        StarsRating,
    },

    props: {
        shop: Object,
        review: Object,
        part: Object,
        product: Object,
    },

    methods: {
        showPhotoViewerPopup(index) {
            const props = {
                photos: this.review.photos,
                index: index,
                fullCoverCodename: 'review_photo_pv',
                miniCoverCodename: 'review_photo_mini',
            };
            const options = { props };
            this.$popup(PhotoViewer, options);
        },

        handleClickComplainReview() {
            const shop = this.shop;
            const part = this.part;
            const product = this.product;
            const review = this.review;
            const props = { shop, part, product, review };
            const options = { props };
            this.$popup(ComplainReviewPopup, options);
        },

        handleClickComplainResponse() {
            const shop = this.shop;
            const review = this.review;
            const props = { shop, review };
            const options = { props };
            this.$popup(ComplainReviewResponsePopup, options);
        },

        handleClickEditReview() {
            const callback = value => value && this.$emit('update');

            const props = {
                callback,
                shop: this.shop,
                part: this.part,
                product: this.product,
                review: this.review,
                edit: true,
            };

            const options = { props };
            this.$popup(this.shop ? ShopReviewPopup : MarketItemReviewPopup, options);
        },

        handleClickRemoveReview() {
            const callback = value => value && this.$emit('update');
            const shop = this.shop;
            const part = this.part;
            const product = this.product;
            const review = this.review;
            const props = { shop, part, product, review, callback };
            const options = { props };
            this.$popup(RemoveReviewPopup, options);
        },
    },
};
</script>

<style scoped>
.review-wrap {
    position: relative;
}

.review-author {
    display: flex;
}
@media (min-width: 768px) {
    .review-author {
        margin-bottom: 16px;
    }
}
@media (max-width: 767px) {
    .review-author {
        margin-bottom: 12px;
    }
}

@media (min-width: 768px) {
    .user-avatar {
        width: 48px;
        height: 48px;
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .user-avatar {
        width: 36px;
        height: 36px;
        margin-right: 12px;
    }
}

.user-name {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
}

@media (min-width: 768px) {
    .review-menu {
        margin-left: 20px;
    }
}
@media (max-width: 767px) {
    .review-menu {
        margin-left: 12px;
    }
}

@media (min-width: 768px) {
    .main {
        margin-left: 68px;
    }
}

@media (min-width: 768px) {
    .review-section:not(:first-child) {
        margin-top: 16px;
    }
}
@media (max-width: 767px) {
    .review-section:not(:first-child) {
        margin-top: 12px;
    }
}

.title {
    margin-bottom: 4px;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}

.review-text {
    line-height: var(--base-lh);
}

.photos-row {
    font-size: 0;
}

.photo-btn {
    position: relative;
    width: 64px;
    height: 64px;
    background-color: var(--light-bg);
    border-radius: var(--border-radius);
    overflow: hidden;
}
.photo-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-active-bg);
    opacity: 0;
    transition: opacity var(--transition);
}
.photo-btn:hover::before,
.photo-btn:focus::before {
    opacity: .4;
}

.photo-btn:not(:last-child) {
    margin-right: 8px;
}

.date-rating-wrap {
    margin-top: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.date {
    color: var(--font-secondary-color);
    font-size: var(--adaptive-small-fz);
}

.answer-wrap {
    position: relative;
    margin-top: var(--gap-y-mini);
    background-color: var(--bright-bg);
    border-radius: var(--border-radius);
}
@media (min-width: 768px) {
    .answer-wrap {
        margin-left: 68px;
    }
}
@media (min-width: 768px) {
    .answer-wrap {
        padding: 20px 24px;
    }
}
@media (max-width: 767px) {
    .answer-wrap {
        padding: 16px;
    }
}

.shop-title-date-wrap {
    font-size: var(--adaptive-small-fz);
    color: var(--font-secondary-color);
}
@media (min-width: 414px) {
    .shop-title-date-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
    }
}

.answer-title {
    display: block;
    font-family: var(--f-bold);
}

@media (max-width: 414px) {
    .answer-date {
        display: block;
        margin-top: 4px;
    }
}
</style>