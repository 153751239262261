var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AccountLayout',{attrs:{"title":"Избранное"}},[[_c('section',[(!_vm.initialized)?_c('div',{staticClass:"spinner-wrap"},[_c('Spinner',{attrs:{"center":"","size":"big"}})],1):[_c('MarketItemNavigation',{staticClass:"layer-1 bottom-gap_grid",attrs:{"small":"","items":[
                        {
                            to: {
                                name: 'account-favorites-parts'
                            },
                            label: 'Запчасти',
                            amount: _vm.parts.current_count,
                        },
                        {
                            to: {
                                name: 'account-favorites-products'
                            },
                            label: 'Автотовары',
                            amount: _vm.products.current_count,
                        },
                        // {
                        //     to: {
                        //         name: 'account-favorites-contract-parts'
                        //     },
                        //     label: 'Контр. запчасти',
                        //     amount: contractParts.current_count,
                        // },
                        {
                            to: {
                                name: 'account-favorites-brands'
                            },
                            label: 'Бренды',
                            amount: _vm.brands.current_count,
                        },
                        {
                            to: {
                                name: 'account-favorites-shops'
                            },
                            label: 'Магазины',
                            amount: _vm.shops.current_count,
                        } ]}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade-page","mode":"out-in"}},[_c('RouterView')],1)]],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }