export default ({ HTTP }) => ({
    async get(payload) {
        if (!payload || typeof payload === 'object') {
            const config = Object.assign({ params: { order_by: 'name' } }, payload || {});
            const url = '/insurance_company/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async lastOffers(config) {
        const url = '/insurance_company/last_offers/';
        const response = await HTTP.get(url, config);
        return response.data;
    },
});