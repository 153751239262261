<template>
    <AccountLayout
        class="account-notifications-page"
        title="Уведомления"
        mobileTitle="Уведомления"
    >
        <template #btn>
            <ButtonText
                v-if="hasUnread"
                dark
                secondary
                dashed
                class="hidden-s"
                @click="setIsReadForAll"
            >
                Отметить все прочитанными
            </ButtonText>
        </template>

        <section>
            <div class="layer-1">
                <div v-if="initializing" class="spinner-block">
                    <USpinner big></USpinner>
                </div>

                <template v-else-if="notifications.length">
                    <div v-if="hasUnread" class="set-all-is-read-button-wrap hidden-xl hidden-l hidden-m">
                        <ButtonText
                            dark
                            secondary
                            dashed
                            @click="setIsReadForAll"
                        >
                            Отметить все прочитанными
                        </ButtonText>
                    </div>

                    <div class="notifications-list">
                        <div
                            v-for="notification in notifications"
                            :key="notification.uuid"
                            role="button"
                            tabindex="0"
                            class="notification-container"
                            @click="onClickNotification($event, notification)"
                        >
                            <div class="notification-wrap">
                                <div class="notification__title">
                                    {{ notification.title }}<span
                                        v-if="!notification.is_read"
                                        class="notification__unread"
                                    ></span>
                                </div>
                                <p class="notification__text">
                                    {{ notification.text }}
                                </p>
                                <div class="notification__date">
                                    {{ notification.create_date | date }}
                                </div>
                            </div>
                        </div>

                        <div v-if="hasMore" class="more-wrap">
                            <ButtonText
                                secondary
                                dark
                                dashed
                                :loading="fetching"
                                class="more-button"
                                @click="more"
                            >
                                Показать ещё
                            </ButtonText>
                        </div>
                    </div>
                </template>

                <EmptyBlock
                    v-else
                    icon="notification"
                    title="Нет уведомлений"
                    :hasBtn="false"
                    class="empty-block"
                >
                    <template #text>
                        <p class="empty__text">
                            Здесь будут отображаться все уведомления,
                            <br>
                            связанные с вашей активностью.
                        </p>
                    </template>
                </EmptyBlock>
            </div>
        </section>
    </AccountLayout>
</template>

<script>
// services
import socket from '@/services/web-socket';
import bus from '@/bus.js';
// utils
import isValidDate from '@ui/utils/isValidDate.js';
import { mapActions, mapMutations, mapState } from 'vuex';
import accountNotifications from '@/store/modules/accountNotifications.js';
// components
import AccountLayout from '@/pages/account/AccountLayout.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';


export default {
    name: 'AccountNotificationsPage',

    components: {
        EmptyBlock,
        USpinner,
        ButtonText,
        AccountLayout,
    },

    filters: {
        date(value) {
            if (!value) return value;

            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return value;

            const d = date.toLocaleString('ru-RU', {
                month: 'long',
                day: 'numeric',
            });
            const year = date.getFullYear();
            const t = date.toLocaleString('ru-RU', {
                hour: '2-digit',
                minute: '2-digit',
            });

            return d + ' ' + year + ', ' + t;
        },
    },

    metaInfo() {
        return {
            title: 'ЛК / Уведомления',
        };
    },

    serverPrefetch() {
        return this.prefetch();
    },

    computed: {
        ...mapState({
            fetching: state => state.accountNotifications.fetching,
            initialized: state => state.accountNotifications.initialized,
            initializing: state => state.accountNotifications.initializing,
            currentCount: state => state.accountNotifications.currentCount,
            notifications: state => state.accountNotifications.notifications,
            unreadCount: state => state.accountNotifications.unreadCount,
        }),

        hasMore() {
            return this.notifications.length < this.currentCount;
        },

        hasUnread() {
            return !!this.unreadCount;
        },
    },

    created() {
        this.$store.registerModule('accountNotifications', accountNotifications);
    },

    mounted() {
        if (!this.initialized) {
            this.prefetch();
        }

        socket.onopen(() => {
            socket.on('notifications.count.message', this.onChangeUnreadCount);
        });
        bus.$on('notifications:setisread', this.onSetIsRead);

        this.$breadcrumbs([]);
    },

    beforeDestroy() {
        this.destroy();

        socket.off('notifications.count.message', this.onChangeUnreadCount);
        bus.$off('notifications:setisread', this.onSetIsRead);
        this.$store.unregisterModule('accountNotifications');
    },

    methods: {
        ...mapMutations({
            destroy: 'accountNotifications/destroy',
            setUnreadCount: 'accountNotifications/setUnreadCount',
        }),

        ...mapActions({
            more: 'accountNotifications/more',
            prefetch: 'accountNotifications/prefetch',
            setIsRead: 'accountNotifications/setIsRead',
            setIsReadForAll: 'accountNotifications/setIsReadForAll',
        }),

        onClickNotification($event, notification) {
            if (!notification.is_read) {
                const notifications = [notification.uuid];
                const data = { notifications };
                this.setIsRead(data);
            }

            const url = new URL(notification.url);

            if (url.hostname === window.location.hostname && (!$event.metaKey && !$event.ctrlKey)) {
                this.$router.push(url.pathname);
            }
            else {
                window.open(url.href);
            }
        },

        onChangeUnreadCount(data) {
            if (data && data.message_amount !== undefined) {
                this.setUnreadCount(data.message_amount);

                if (data.message_amount === 0) {
                    this.$store.commit('accountNotifications/setIsReadForAll');
                }
            }
        },

        onSetIsRead(data) {
            this.$store.commit('accountNotifications/setIsRead', data);
        },
    },
};
</script>

<style scoped>
.spinner-block {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.set-all-is-read-button-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    border-bottom: 1px solid var(--border-light-c);
}

@media (min-width: 768px) {
    .notifications-list {
        padding: 4px 0;
    }
}

.notification-container {
    cursor: pointer;
    outline: none;
}
@media (min-width: 1040px) {
    .notification-container {
        padding: 0 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .notification-container {
        padding: 0 24px;
    }
}
@media (max-width: 767px) {
    .notification-container {
        padding: 0 16px;
    }
}

@media (min-width: 768px) {
    .notification-wrap {
        padding: 20px 0;
    }
}
@media (max-width: 767px) {
    .notification-wrap {
        padding: 16px 0;
    }
}

.notification-container + .notification-container .notification-wrap {
    border-top: 1px solid var(--border-light-c);
}

.notification__title {
    margin-bottom: 4px;
    font-family: var(--f-bold);
    transition: color var(--transition);
}
.notification-container:hover .notification__title {
    color: var(--primary-color);
}

.notification__unread {
    margin-left: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary-color);
    vertical-align: center;
}

.notification__text {
    margin-bottom: 4px;
    line-height: var(--base-lh);
}

.notification__date {
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
}

.more-wrap {
    padding: 4px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 767px) {
    .more-wrap {
        padding-top: 0;
        padding-bottom: 16px;
    }
}

.empty-block {
    height: 400px;
}
</style>