export default {
    props: {
        to: [Object, String],
        href: String,
        pseudo: Boolean,
        download: [Boolean, String],
        target: String,
        submit: Boolean,
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: Boolean,
        hovered: Boolean,
        focused: Boolean,
        label: String,
        active: Boolean,
        tabindex: {
            type: [Number, String],
            default: 0,
        },
    },

    data() {
        return {
            innerHovered: false,
        };
    },

    computed: {
        tag() {
            if (this.to) return 'RouterLink';
            if (this.href) return 'a';
            if (this.pseudo) return 'span';
            return 'button';
        },

        attrs() {
            if (this.pseudo) return {};

            const attrs = {
                disabled: this.disabled || this.loading,
                'aria-disabled': this.disabled,
                tabindex: this.tabindex,
            };

            if (this.target) {
                attrs.target = this.target;
            }

            if (this.tag === 'RouterLink') {
                attrs.to = this.to;
            }
            else if (this.tag === 'a') {
                attrs.href = this.href;
                attrs.download = this.download;
            }
            else if (this.tag === 'button') {
                attrs.type = this.submit ? 'submit' : 'button';
            }

            return attrs;
        },
    },
}