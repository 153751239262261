export default () => {
    return {
        formData: {
            unlimited_drivers_amount: false,
            drivers: [
                {
                    last_name: '',
                    first_name: '',
                    middle_name: '',
                    birth_date: null,
                    license_number: '',
                    experience_start_date: null,
                    prev_license: false,
                    prev_last_name: '',
                    prev_first_name: '',
                    prev_middle_name: '',
                    prev_license_number: '',
                    prev_license_date: null,
                },
            ],
        },

        keys: [
            'unlimited_drivers_amount',
            'drivers',
        ],

        // todo: серверное время
        minBirthDate: new Date(1900, 0, 1),
        maxBirthDate: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
        maxLicenseExpDate: new Date(new Date().getFullYear() + 10, new Date().getMonth(), new Date().getDate()),
        maxExperienceStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        maxPrevLicenseDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    };
};