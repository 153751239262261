import deepClone from '@/lib/deepClone.js';
import equals from '@/lib/equals.js';


export const isValidInsuranceContractRouteName = (routeName, contract) => {
    const offer = (contract.active || {}).offer_obj;
    const status = contract.status;
    const hasOrder = contract.has_order;

    if (status === 'new') {
        return [
            'insurance-osago-request-step-1',
            'insurance-osago-request-step-2',
            'insurance-osago-request-step-3',
            'insurance-osago-request-step-4',
        ].includes(routeName);
    }
    else if (status === 'pre_calc') {
        if (!offer) {
            return [
                'insurance-osago-request-step-1',
                'insurance-osago-request-step-2',
                'insurance-osago-request-step-3',
                'insurance-osago-request-step-4',
                'insurance-osago-offers',
            ].includes(routeName);
        }
        else if (hasOrder) {
            return ['insurance-osago-edit', 'insurance-osago-offers'].includes(routeName);
        }
        else {
            return ['insurance-osago-checkout', 'insurance-osago-offers'].includes(routeName);
        }
    }
    else if (status === 'final_calc') {
        return [
            'insurance-osago-confirmation',
            'insurance-osago-confirmation-failed',
            'insurance-osago-edit',
        ].includes(routeName);
    }
    else if (status === 'wait_payment') {
        return 'insurance-osago-confirmation-success' === routeName;
    }
    else if (['completed', 'wait_docs', 'get_docs'].includes(status)) {
        return 'insurance-osago-payment-success' === routeName;
    }

    return true;
};

export const getInsuranceContractRouteName = contract => {
    const status = contract.status;
    const lastPath = contract.last_path;
    const hasOrder = contract.has_order;

    if (status === 'new') {
        if (!lastPath || lastPath === '/request/step-1/') {
            return 'insurance-osago-request-step-1';
        }
        else if (lastPath === '/request/step-2/') {
            return 'insurance-osago-request-step-2';
        }
        else if (lastPath === '/request/step-3/') {
            return 'insurance-osago-request-step-3';
        }
        else if (lastPath === '/offers/') {
            return 'insurance-osago-offers';
        }
        else {
            return 'insurance-osago-request-step-4';
        }
    }
    else if (status === 'pre_calc') {
        if (!(contract.active || {}).offer_obj) {
            return 'insurance-osago-offers';
        }
        else if (hasOrder) {
            return 'insurance-osago-edit';
        }
        else {
            return 'insurance-osago-checkout';
        }
    }
    else if (status === 'final_calc') {
        const offer = contract.active.offer_obj;

        if (!offer || offer.status === 'fail_confirm') {
            return 'insurance-osago-confirmation-failed';
        }
        else {
            return 'insurance-osago-confirmation';
        }
    }
    else if (status === 'wait_payment') {
        return 'insurance-osago-confirmation-success';
    }
    else if (['completed', 'wait_docs', 'get_docs'].includes(status)) {
        return 'insurance-osago-payment-success';
    }
};

export default () => {
    return {
        async checkUuid() {
            if (this.contract && this.contract.uuid) {
                const { name, params, query } = this.$route;
                const oldLocation = { name, params: { ...(params || {}) }, query: { ...(query || {}) } };
                const newLocation = deepClone(oldLocation);

                newLocation.params.uuid = this.contract.uuid;

                if (!isValidInsuranceContractRouteName(newLocation.name, this.contract)) {
                    newLocation.name = getInsuranceContractRouteName(this.contract) || newLocation.name;
                    console.log('checkUuid', newLocation.name);
                }

                if (!equals(oldLocation, newLocation)) {
                    await this.$router.replace(newLocation);
                }
            }
        },
    };
};