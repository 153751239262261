<template>
    <FilterWidgetWrap
        v-if="config.options.length || options.length"
        class="wrap"
        :codename="config.codename"
        :title="config.title"
        :hint="config.hint"
        :hasBorder="!!valuesComputed.length"
        :value="valuesComputed"
        :amount="amount"
        :collapseOnMount="config.isDynamic"
        canCollapse
        withAdaptation
        @reset="reset"
        @deleteFilter="onDeleteFilter"
    >
        <InputText
            v-if="hasSearch"
            v-model.trim="searchQuery"
            placeholder="Поиск"
            :prependIcon="{
                name: 'search',
                secondary: true,
                small: true,
            }"
            small
            class="search-field"
            autocomplete="off"
        ></InputText>

        <div class="options-list_before"></div>
        <div
            v-show="optionsComputed.length"
            class="options-list"
        >
            <CheckboxBase
                v-for="(option, indexOption) in optionsComputed"
                :id="'filter-checkboxes-' + config.codename + '-index-' + indexOption + '-id-' + (option.id || option)"
                :key="'filter-checkboxes-' + config.codename + '-index-' + indexOption + '-id-' + (option.id || option)"
                :model="valuesComputed"
                :value="option"
                class="options-list__item"
                :blocked="option.count === 0 && !valuesComputed.find(item => item.id === option.id)"
                @change="onChange"
            >
                <span
                    :class="{
                        text_secondary: option.count && option.count === 0,
                    }"
                >{{ option.name }}</span>
                <span
                    v-if="option.count"
                    class="ml-4 text_small-fz text_secondary"
                >{{ option.count | number }}</span>
            </CheckboxBase>
        </div>
        <div class="options-list_after"></div>

        <p
            v-show="!optionsComputed.length"
            class="mt-16 text_secondary"
        >Ничего не найдено</p>

        <ButtonText
            v-show="valuesComputed.length"
            dashed
            primary
            class="mt-16 hidden-s"
            @click="reset"
        >
            Сбросить
        </ButtonText>
    </FilterWidgetWrap>
</template>

<script>
import FilterWidgetWrap from './FilterWidgetWrap.vue';
import CheckboxBase from "./fields/CheckboxBase.vue";
import ButtonText from "@/components/_buttons/ButtonText.vue";
import InputText from '@/components/_inputs/InputText.vue';

export default {
    name: 'FilterCheckboxes',

    components: {
        InputText,
        ButtonText,
        CheckboxBase,
        FilterWidgetWrap,
    },

    props: {
        config: {
            type: Object,
            default() {
                return {
                    title: '',
                    codename: '',
                    options: [],
                    count: 0,
                };
            },
        },

        value: {
            type: Array,
            required: true,
        },

        index: {
            type: Number,
            default: 0,
        },

        amount: Number,

        loading: Boolean,
    },

    data() {
        return {
            show: false,

            offset: 0,

            searchQuery: '',
            timeout: null,

            options: [],
        };
    },

    computed: {
        optionsComputed() {
            if (this.searchQuery) {
                const re = new RegExp(this.searchQuery, 'i');
                return this.config.options.filter(item => re.test(item.name));
            }
            return this.config.options;
        },

        valuesComputed: {
            get() {
                return this.value || [];
            },

            set(value) {
                this.$emit('change', value);
            },
        },

        hasSearch() {
            return this.config.options.length > 20;
        },
    },

    methods: {
        onDeleteFilter(filter) {
            filter.codename = this.config.codename;
            filter.widget = 'checkboxes';
            this.$emit('deleteFilter', filter);
        },

        onChange(value) {
            this.valuesComputed = value;
            this.$emit('reload');
        },

        reset() {
            this.valuesComputed = [];
            this.searchQuery = '';
            this.$emit('reload');
        },
    },
};
</script>

<style scoped>
.options-list {
    position: relative;
    overflow: auto;
}
@media (min-width: 768px) {
    .options-list {
        max-height: 228px;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-top: -16px;
        margin-bottom: -16px;
    }
}
@media (max-width: 767px) {
    .options-list {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: -20px;
        margin-bottom: -20px;
    }
}

.options-list_before,
.options-list_after {
    position: relative;
    z-index: 2;
    flex-shrink: 0;
}
.options-list_before {
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
}
.options-list_after {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
}
@media (min-width: 768px) {
    .options-list_before {
        margin-top: -16px;
    }
    .options-list_after {
        margin-bottom: -16px;
    }

    .search-field ~ .options-list_before {
        margin-top: 0;
    }

    .options-list_before,
    .options-list_after {
        height: 16px;
    }
}
@media (max-width: 767px) {
    .options-list_before:first-child {
        margin-top: -20px;
    }

    .options-list_before,
    .options-list_after {
        height: 20px;
    }
}

.options-list__item {
    position: relative;
}

.options-list__item >>> .label {
    width: 100%;
}
@media (min-width: 768px) {
    .options-list__item:not(:first-child) >>> .label {
        padding-top: 8px;
    }

    .options-list__item:not(:last-child) >>> .label {
        padding-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .options-list__item >>> .label {
        min-height: 48px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .options-list__item >>> .label .icon,
    .options-list__item >>> .label .text {
        margin-top: 2px;
    }

    .options-list__item:not(:last-child) {
        border-bottom: 1px solid var(--border-light-c);
    }
}
</style>