export default({ HTTP }) => ({
    async get(config) {
        const url = '/insurance_vehicle/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async getInfo(config) {
        const url = '/insurance_vehicle/get_info/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async getCard(config) {
        const url = '/insurance_vehicle/get_card/';
        const response = await HTTP.get(url, config);
        return response.data;
    },
});