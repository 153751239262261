<template>
    <ul class="insurance-factoids">
        <UFactoid
            icon="safe"
            class="insurance-factoids__item"
        >
            <template #title>
                Гарантия
            </template>
            <template #descriptor>
                наличия полиса в базе РСА
            </template>
        </UFactoid>

        <UFactoid
            icon="ruble"
            class="insurance-factoids__item"
        >
            <template #title>
                Оплата полиса
            </template>
            <template #descriptor>
                на сайте страховой компании
            </template>
        </UFactoid>

        <UFactoid
            icon="email-open"
            class="insurance-factoids__item"
        >
            <template #title>
                Получение документов
            </template>
            <template #descriptor>
                по электронной почте
            </template>
        </UFactoid>

        <UFactoid
            icon="computer"
            class="insurance-factoids__item"
        >
            <template #title>
                Быстрое продление
            </template>
            <template #descriptor>
                в вашем личном кабинете
            </template>
        </UFactoid>
    </ul>
</template>

<script>
import UFactoid from '@/components/UFactoids/UFactoid.vue';

export default {
    name: 'InsuranceFactoids',

    components: { UFactoid },
};
</script>

<style scoped>
.insurance-factoids {
    margin-top: var(--gap-y-max);
}
@media (min-width: 1160px) {
    .insurance-factoids {
        grid-template-columns: repeat(4, auto);
        grid-gap: 20px;
        margin-bottom: -60px;
    }
}
@media (min-width: 1040px) and (max-width: 1159px) {
    .insurance-factoids {
        grid-gap: 36px 72px;
        margin-bottom: -36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .insurance-factoids {
        grid-gap: 36px 64px;
        margin-bottom: -28px;
    }
}
@media (min-width: 768px) and (max-width: 1159px) {
    .insurance-factoids {
        grid-template-columns: repeat(2, auto);
        justify-content: center;
    }
}
@media (min-width: 768px) {
    .insurance-factoids {
        display: grid;
        padding-top: 36px;
    }

    .insurance-factoids::before {
        content: "";
        width: 100%;
        height: 36px;
        margin-top: -36px;
        position: absolute;
        left: 0;
        border-top: 1px solid var(--border-light-c);
    }
}

@media (max-width: 767px) {
    .insurance-factoids__item:not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>