<template>
    <div
        :class="[
            'post-editor__block_' + widget.type,
            widget.align,
        ]"
    >
        <div
            :class="[
                widget.marginTop ? 'indent-wrap_mt' + widget.marginTop : '',
                widget.marginBottom ? 'indent-wrap_mb' + widget.marginBottom : '',
                widget.paddingTop ? 'indent-wrap_pt' + widget.paddingTop : '',
                widget.paddingBottom ? 'indent-wrap_pb' + widget.paddingBottom : '',
            ]"
            class="indent-wrap"
        >
            <div :class="`slider slider_format_${ widget.slider_format }`">
                <div class="slider-container">
                    <div
                        v-for="slide in widget.slides.slides"
                        class="slide"
                        :data-description="slide.description"
                    >
                        <img
                            :src="$links.uploads + slide.image.large_url"
                            :alt="slide.description"
                        >
                    </div>
                </div>

                <div class="slider-bottom">
                    <div class="post-editor__block_caption slider-caption">{{ widget.slides.slides[0].description }}</div>

                    <div
                        v-if="widget.slides.slides.length > 1"
                        class="slider-nav"
                    >
                        <button class="slider-arrow slider-arrow_prev arrow-btn arrow-btn_dark arrow-btn_prev active">
                            <UIcon
                                name="arrow-left"
                                class="arrow-btn__icon"
                            ></UIcon>
                        </button>
                        <div class="slider-counter active">
                            <span>1</span>&nbsp;/&nbsp;<span>{{ widget.slides.slides.length }}</span>
                        </div>
                        <button class="slider-arrow slider-arrow_next arrow-btn arrow-btn_dark arrow-btn_next active">
                            <UIcon
                                name="arrow-right"
                                class="arrow-btn__icon"
                            ></UIcon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'PostEditorSlider',

    components: { UIcon },

    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style scoped>
.slider {
    position: relative;
}

.slider-container {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    padding: 0;
    opacity: 0;
    z-index: 1;
}

.slide:first-child {
    opacity: 1;
    z-index: 2;
}

.slide.active {
    position: relative;
    z-index: 2;
}

.slider-nav {
    display: flex;
    align-items: center;
}

.slider-nav .slider-counter {
    margin-right: 32px;
}

.slider-counter {
    display: none;
    text-align: center;
    min-width: 60px;
    font-size: var(--base-fz);
}

.slider-counter.active {
    display: block;
}

.post-editor__block_slider .slide {
    cursor: pointer;
}

.slider-bottom {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.arrow-btn {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%
}

.slider-arrow_prev {
    margin-right: 32px;
}

.arrow-btn__icon {
    position: relative;
    top: 1px;
    width: 14px;
    height: 14px;
}
</style>