<template>
    <li
        class="u-factoid"
        :class="[
            hasDescriptor && 'u-factoid_with-descriptor'
        ]"
    >
        <div
            class="u-factoid__icon-wrap"
            :class="[
                primaryIcon && 'u-factoid__icon-wrap_primary'
            ]"
        >
            <UIcon
                :name="icon"
                class="u-factoid__icon"
            ></UIcon>
        </div>

        <div class="u-factoid__text-wrap">
            <p class="u-factoid__title">
                <slot name="title">
                    {{ title }}
                </slot>
            </p>

            <p v-if="hasDescriptor" class="u-factoid__descriptor">
                <slot name="descriptor">
                    {{ descriptor }}
                </slot>
            </p>
        </div>
    </li>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'UFactoid',

    components: { UIcon },

    props: {
        icon: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        descriptor: {
            type: String,
            default: '',
        },

        primaryIcon: Boolean,
    },

    computed: {
        hasDescriptor() {
            return this.descriptor || this.$scopedSlots.descriptor || this.$slots.descriptor;
        },
    },
};
</script>

<style scoped>
.u-factoid {
    display: flex;
    align-items: center;
}

.u-factoid__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin-right: 16px;
    color: var(--font-secondary-dark-color);
    background-color: var(--grey-light-color);
    border-radius: 50%;
}

.u-factoid__icon-wrap_primary {
    background-color: var(--primary-color);
    color: var(--light-c);
}

.u-factoid__icon {
    width: 24px;
    height: 24px;
    fill: currentColor;
}

@media (max-width: 767px) {
    .u-factoid:not(.u-factoid_with-descriptor) .u-factoid__icon-wrap {
        width: 36px;
        height: 36px;
    }

    .u-factoid:not(.u-factoid_with-descriptor) .u-factoid__icon {
        width: 20px;
        height: 20px;
    }
}

.u-factoid__title {
    font-family: var(--f-bold);
}

@media (min-width: 1040px) {
    .u-factoid__title {
        font-size: 16px;
    }
}
@media (max-width: 1039px) {
    .u-factoid__title {
        font-size: 14px;
    }
}

.u-factoid_with-descriptor .u-factoid__title {
    font-size: var(--base-fz);
}

.u-factoid__descriptor {
    margin-top: 4px;
}
</style>