<template>
    <ul class="profits-list">
        <li class="profit">
            <div class="profit__text-wrap">
                <h3 class="h2 h2_text">
                    Безопасная сделка
                </h3>
                <p class="profit__text">
                    Ваши деньги находятся на&nbsp;нашем счёте и&nbsp;поступят продавцу только после того, как вы&nbsp;получите товар.
                </p>
            </div>
            <img
                srcset="../assets/images/market/profit-1-2x.png 2x,
                    ../assets/images/market/profit-1.png"
                src="../assets/images/market/profit-1-2x.png"
                alt=""
                class="profit__img"
            >
        </li>
        <li class="profit">
            <div class="profit__text-wrap">
                <h3 class="h2 h2_text">
                    Онлайн-чаты с&nbsp;магазинами
                </h3>
                <p class="profit__text">
                    Если появились вопросы, напрямую задавайте их&nbsp;менеджерам магазинов.
                </p>
            </div>
            <img
                srcset="../assets/images/market/profit-2-2x.png 2x,
                    ../assets/images/market/profit-2.png"
                src="../assets/images/market/profit-2.png"
                alt=""
                class="profit__img"
            >
        </li>
        <li class="profit">
            <div class="profit__text-wrap">
                <h3 class="h2 h2_text">
                    Бронирование и&nbsp;самовывоз
                </h3>
                <p class="profit__text">
                    Магазин упакует и&nbsp;подготовит товар для выдачи к&nbsp;вашему приезду. Без очередей и&nbsp;ожидания.
                </p>
            </div>
            <img
                srcset="../assets/images/market/profit-3-2x.png 2x,
                    ../assets/images/market/profit-3.png"
                src="../assets/images/market/profit-3.png"
                alt=""
                class="profit__img"
            >
        </li>
        <li class="profit">
            <div class="profit__text-wrap">
                <h3 class="h2 h2_text">
                    Экспресс-доставка
                </h3>
                <p class="profit__text">
                    Доставим ваш заказ из&nbsp;магазина по&nbsp;городу в&nbsp;течение часа с&nbsp;Яндекс&nbsp;Go.
                </p>
            </div>
            <img
                srcset="../assets/images/market/profit-4-2x.png 2x,
                    ../assets/images/market/profit-4.png"
                src="../assets/images/market/profit-4.png"
                alt=""
                class="profit__img"
            >
        </li>
    </ul>
</template>

<script>
export default {
    name: 'MarketProfitsList',
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .profits-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 48px 72px;
    }
}

.profit {
    display: flex;
    align-items: center;
}

@media (min-width: 768px) and (max-width: 1039px) {
    .profit:not(:last-child) {
        margin-bottom: 36px;
    }
}
@media (min-width: 768px) {
    .profit {
        flex-direction: row-reverse;
    }
}
@media (max-width: 767px) {
    .profit {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .profit:not(:last-child) {
        margin-bottom: 24px;
    }
}

.profit__text {
    line-height: var(--base-lh);
}

@media (min-width: 768px) {
    .profit__img {
        width: 160px;
        height: 153px;
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .profit__img {
        width: 76px;
        height: 72px;
        margin-bottom: 8px;
    }
}
</style>