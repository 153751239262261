import CryptoJS from 'crypto-js';
import { API_URL_PREFIX, KEY } from '../settings.js';
import nu from './normalizeUrl.js';


export default (o, t) => CryptoJS.MD5(
    o.method.toUpperCase() +
    API_URL_PREFIX +
    nu(o.url) +
    t +
    KEY,
).toString();