<template>
    <div class="u-field-tabs">
        <URadioTabs
            :invalid="invalid"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template #label>
                <slot name="label"></slot>
            </template>
        </URadioTabs>

        <UFormFieldError
            :show="invalid"
            class="u-field-tabs__error"
        >
            {{ error }}
        </UFormFieldError>
    </div>
</template>

<script>
import fieldMixin from '@ui/components/UForm/fields/fieldMixin.js';
import URadioTabs from '@ui/components/URadio/presets/URadioTabs.vue';
import UFormFieldError from '@ui/components/UForm/UFormFieldError.vue';


export default {
    name: 'UFieldTabs',

    components: { UFormFieldError, URadioTabs },

    mixins: [fieldMixin],

    data() {
        return {
            initialValue: '',
        };
    },
};
</script>

<style scoped>
.u-field-tabs__error .u-form-field-error {
    margin-top: 8px;
}
</style>