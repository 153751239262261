<template>
    <div class="u-dropdown">
        <div class="u-dropdown__source">
            <slot v-bind="scope"></slot>
        </div>

        <TransitionExpandFade>
            <ul
                v-show="initialized && show"
                ref="optionsList"
                class="u-dropdown__options-list"
            >
                <li
                    v-for="(option, optionIndex) in options"
                    :key="getOptionKey(option)"
                    ref="option"
                    class="u-dropdown__option-item"
                >
                    <button
                        ref="optionButton"
                        type="button"
                        class="u-dropdown__option-button"
                        :class="{
                            'u-dropdown__option-button_selected': isOptionSelected(option, selectedOptions),
                            'u-dropdown__option-button_focus': focusIndex === optionIndex,
                        }"
                        :tabindex="-1"
                        @click="isSelectable(option) ? select(option) : null"
                    >
                        <slot
                            name="label"
                            v-bind="{ option }"
                        >
                            {{ getOptionLabel(option) }}
                        </slot>
                    </button>
                </li>

                <li
                    v-if="hasMore"
                    class="u-dropdown__option-item u-dropdown__option-more"
                >
                    <ButtonText
                        ref="more"
                        secondary
                        dark
                        dashed
                        :focused="focusIndex === options.length"
                        :tabindex="-1"
                        @click="emitMore"
                    >
                        Показать ещё
                    </ButtonText>
                </li>

                <li
                    v-if="!options.length && hasEmpty"
                    class="u-dropdown__option-item u-dropdown__option-empty"
                    :tabindex="-1"
                >
                    Ничего не найдено
                </li>
            </ul>
        </TransitionExpandFade>
    </div>
</template>

<script>
// mixins
import options from '@/mixins/options.js';
// components
import TransitionExpandFade from '@/components/_transitions/TransitionExpandFade.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';


export default {
    name: 'UDropdown',

    components: {
        ButtonText,
        TransitionExpandFade,
    },

    mixins: [options],

    props: {
        // show: Boolean,
        options: Array,
        selectedOptions: {
            type: Array,
            default: () => ([]),
        },
        loadingMore: Boolean,
        hasMore: Boolean,
        initialized: {
            type: Boolean,
            default: true,
        },
        hasEmpty: {
            type: Boolean,
            default: true,
        },

        isSelectable: {
            type: Function,
            default(option) {
                return !this.isOptionSelected(option, this.selectedOptions);
            },
        },
    },

    data() {
        return {
            show: false,
            focusIndex: null,
        };
    },

    computed: {
        scope() {
            const keydown = this.onInputKeyDown;
            const focus = this.onInputFocus;
            const blur = this.onInputBlur;

            const handlers = {
                keydown,
                focus,
                blur,
            };

            return {
                handlers,
            };
        },
    },

    methods: {
        open() {
            if (this.hasEmpty || this.options.length) {
                this.show = true;
                this.emitOpen();
            }
        },

        close() {
            this.show = false;
            this.focusIndex = null;
            this.emitClose();
        },

        select(value) {
            const target = this;
            this.$emit('select', { value, target });
        },

        up() {
            let hasSelectable = !!this.options.length;

            if (this.focusIndex === null) {
                // если focusIndex не null, то значит, что опции точно есть
                // и не надо перебирать массив
                hasSelectable = !!this.options.filter(option => this.isSelectable(option)).length;
            }

            if (!hasSelectable) return;

            let index = this.focusIndex === null
                ? this.options.length + Number(this.hasMore) - 1
                : this.focusIndex - 1;

            const valid = index => {
                return index === this.options.length || this.isSelectable(this.options[index]);
            };

            while (index >= 0 && !valid(index)) {
                index -= 1;
            }

            if (index === -1) {
                index = this.options.length + Number(this.hasMore) - 1;
            }

            this.focusIndex = index;

            this.updateScroll();
        },

        down() {
            let hasSelectable = !!this.options.length;

            if (this.focusIndex === null) {
                // если focusIndex не null, то значит, что опции точно есть
                // и не надо перебирать массив
                hasSelectable = !!this.options.filter(option => this.isSelectable(option)).length;
            }

            if (!hasSelectable) return;

            let index = this.focusIndex === null ? 0 : this.focusIndex + 1;
            const max = this.options.length + Number(this.hasMore) - 1;

            const valid = index => {
                return index === this.options.length || this.isSelectable(this.options[index]);
            };

            while (index <= max && !valid(index)) {
                index += 1;
            }

            if (index > max) {
                index = 0;
            }

            this.focusIndex = index;

            this.updateScroll();
        },

        updateScroll() {
            const index = this.focusIndex;

            if (index === null) return;

            const el = index === this.options.length ? this.$refs.more : this.$refs.option[index];
            const list = this.$refs.optionsList;
            let block;

            if (
                ((el.offsetTop + el.offsetHeight) > (list.scrollTop + list.offsetHeight)) ||
                (el.offsetTop < list.scrollTop)
            ) {
                block = 'nearest';
            }

            if (block) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block,
                });
            }
        },

        // handlers

        onInputKeyDown($event) {
            const keyCode = $event.keyCode;
            const handlers = {
                9: () => this.onTab(),
                13: () => this.onEnter(),
                27: () => this.onEsc($event),
                38: () => this.onUp($event),
                40: () => this.onDown($event),
            };

            if (handlers[keyCode]) {
                handlers[keyCode]($event);
            }
        },

        onInputFocus() {
            this.open();
        },

        onInputBlur() {
            this.close();
        },

        // keys

        onTab() {
            if (!this.show || !this.initialized) return;

            this.close();
        },

        onEnter() {
            if (!this.show) {
                this.show = true;
            }

            if (!this.initialized) return;

            const isMoreButton = this.focusIndex === this.options.length;

            if (isMoreButton) {
                this.$emit('more');
            }
            else if (this.focusIndex > -1) {
                const option = this.options[this.focusIndex];

                if (option) {
                    this.select(option);
                    this.close();
                }
            }
        },

        onEsc($event) {
            if (!this.show || !this.initialized) return;

            $event.stopPropagation();
            this.close();
        },

        onUp($event) {
            if (!this.show || !this.initialized) return;

            $event.preventDefault();
            this.up();
        },

        onDown($event) {
            if (!this.show || !this.initialized) return;

            $event.preventDefault();
            this.down();
        },

        // emits

        emitMore() {
            const target = this;
            this.$emit('more', { target });
        },

        emitOpen() {
            const target = this;
            this.$emit('open', { target });
        },

        emitClose() {
            const target = this;
            this.$emit('close', { target });
        },
    },
};
</script>

<style>
.u-dropdown {
    position: relative;
}

.u-dropdown__source {

}

.u-dropdown__options-list {
    position: absolute;
    top: calc(100% + 4px);
    z-index: var(--dropdown-z-index);
    width: 100%;
    max-height: 232px;
    padding: 4px;
    overflow: auto;
    outline: none;
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
    border-radius: var(--border-radius);
}

.u-dropdown__option-button {
    display: block;
    width: 100%;
    padding: 8px 12px;
    border-radius: var(--border-radius);
    text-align: left;
    transition: background-color var(--transition);
}

.u-dropdown__option-button:hover:not(.u-dropdown__option-button_selected),
.u-dropdown__option-button.u-dropdown__option-button_focus,
.u-dropdown__option-button:focus {
    background-color: var(--bright-bg);
}

.u-dropdown__option-button.u-dropdown__option-button_selected {
    color: var(--font-secondary-color);
    cursor: default;
    pointer-events: none;
}

.u-dropdown__option-empty,
.u-dropdown__option-more {
    padding: 8px 12px;
}
</style>