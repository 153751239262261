<template>
    <span class="chat-online-status"></span>
</template>

<script>
export default {
    name: 'ChatOnlineStatus',
};
</script>

<style>
.chat-online-status {
    width: 8px;
    height: 8px;
    background-color: var(--accent-green);
    border: 2px solid var(--light-c);
    border-radius: 50%;
    box-sizing: content-box;
}
</style>