<template>
    <div class="map-wrap">
        <Spinner
            v-show="mapLoading"
            absoluteCenter
        ></Spinner>

        <yandexMap
            :coords="markers[0].coords"
            :useObjectManager="true"
            :controls="mapControls"
            :behaviors="[
                'drag',
                'dblClickZoom',
                'multiTouch',
                'rightMouseButtonMagnifier',
            ]"
            zoom="13"
            :scrollZoom="false"
            :options="{ suppressMapOpenBlock: true }"
            :style="mapSize"
            :class="['map', {
                map_loading: mapLoading,
            }]"
            @map-was-initialized="initHandler"
            @click="clickMapHandler"
        >
            <slot>
                <ymapMarker
                    v-for="(marker, index) in markers"
                    :key="index"
                    :coords="marker.coords"
                    :icon="getYMapMarkerIcon(marker)"
                    :markerId="index"
                ></ymapMarker>
            </slot>
        </yandexMap>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import { geocode } from './api.js';
import Spinner from '../Spinner.vue';
import { mapState } from 'vuex';

const toArray = str => str ? str.replace(' ', '').split(',').map(Number) : null;

export default {
    name: 'Map',

    components: {
        Spinner,
        yandexMap,
        ymapMarker,
    },

    mixins: [ymapMarkerMixin],

    props: {
        // bbox
        city: Object,

        markers: {
            type: Array,
            default: () => [],
        },

        mapSize: {
            type: Object,
            default: () => {
                return {
                    width: '100%',
                    height: '100%',
                };
            },
        },

        returnValue: {
            type: String,
            // validator(value) {
            //     return ['text', 'coords'].includes(value);
            // },
            default: '',
        },

        mapControls: {
            type: Array,
            default: () => ['fullscreenControl', 'zoomControl'],
        },

        initialMapValue: String,

        parseLabel: {
            type: Function,
            default(member) {
                if (this.bbox) {
                    const components = member.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components;
                    const arr = components.filter(obj => {
                        if (this.name && obj.kind === 'locality') {
                            return obj.name.toLowerCase() !== this.name.toLowerCase();
                        }

                        return ['locality', 'street', 'house', 'other'].includes(obj.kind);
                    });

                    if (arr.length) return arr.map(obj => obj.name).join(', ');
                    else return components[components.length - 1].name;
                }

                return member.GeoObject.metaDataProperty.GeocoderMetaData.text;
            },
        },
    },

    data() {
        return {
            mapLoading: true,
            mapInstance: null,

            cancelToken: null,
        };
    },

    computed: {
        ...mapState({
            currentCity: state => state.cities.currentCity,
        }),

        markersCoordsList() {
            return this.markers.reduce((acc, item) => {
                if (item.coords.length) {
                    acc.push(item.coords);
                    return acc;
                }
            }, []);
        },

        initialMapCoordsArray() {
            if (this.initialMapValue) return toArray(this.initialMapValue);
            return toArray(this.currentCity.coordinates);
        },

        name() {
            if (this.city && typeof this.city === 'object' && this.city.name) {
                return this.city.name;
            }

            return '';
        },

        bbox() {
            if (this.city && typeof this.city === 'object') {
                const { x1, x2, y1, y2 } = this.city;

                if (x1 && x2 && y1 && y2) {
                    return `${ x1 },${ y1 }~${ x2 },${ y2 }`;
                }
            }

            return '';
        },
    },

    methods: {
        initHandler(mapInstance) {
            this.mapInstance = mapInstance;
            this.setMapCenter();
            this.mapLoading = false;
        },

        setMapCenter() {
            if (this.markersCoordsList.length > 1 && this.mapInstance) {
                this.mapInstance.setBounds(ymaps.util.bounds.fromPoints(this.markersCoordsList), {
                    checkZoomRange: true,
                    zoomMargin: 56,
                });
            }
        },

        async clickMapHandler(obj) {
            const coords = obj.get('coords');
            const name = await this.getNameFromCoords(coords);

            if (this.returnValue === 'text') {
                this.$emit('change', name);
            }
            else if (this.returnValue === 'coords') {
                this.$emit('change', coords.reverse());
            }
            else {
                this.$emit('change', {
                    name,
                    coords: coords.reverse(),
                });
            }
        },

        async getNameFromCoords(coords) {
            if (this.cancelToken) this.cancelToken.cancel();
            this.cancelToken = HTTP.CancelToken.source();

            const stringCoords = (coords.reverse()).toString();
            try {
                const { response } = await geocode({
                    coords: stringCoords,
                    cancelToken: this.cancelToken.token,
                });
                const member = (response.GeoObjectCollection.featureMember || [])[0];
                return this.parseLabel(member);
            }
            catch (error) {
                this.errorHandler(error);
                return '';
            }
        },

        errorHandler(error) {
            const errorFromYandex = error
                && error.response
                && error.response.data
                && error.response.data.error
                && error.response.data.error.status;

            if (errorFromYandex) {
                const status = error.response.data.error.status;
                const codename = this.codename;
                let message = 'Ошибка Яндекс.Карт';

                if (status == 429) {
                    message = 'Превышен лимит запросов';
                }
                else {
                    message = error.response.data.error.message;
                }

                this.$emit('setError', { message, codename });
            }
            else {
                console.warn(error);
            }
        },
    },
};
</script>

<style>
.map-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--light-bg);
}

.map {
    transition: opacity var(--transition);
}

.map_loading {
    opacity: 0;
}
</style>