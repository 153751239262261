<template>
    <section
        v-if="itemsWithoutCurrent.length"
        class="slider-section"
    >
        <h2 class="h2 h2_block slider-section__title">
            Вы недавно смотрели
        </h2>

        <VueSlickCarousel
            class="slider"
            :arrows="true"
            :dots="false"
            :infinite="false"
            :slidesToShow="4"
            :slidesToScroll="4"
            :responsive="[
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        variableWidth: true,
                    }
                },
                {
                    breakpoint: 1039,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        variableWidth: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]"
        >
            <div
                v-for="(item, index) in itemsWithoutCurrent"
                :key="'last-see-item-' + index"
                class="slider__item-wrap"
            >
                <MarketItemMinimal
                    class="slider__item"
                    :item="item[item.item_type]"
                    :modelName="item.item_type"
                ></MarketItemMinimal>
            </div>

            <template #prevArrow>
                <RoundArrowBtn
                    direction="left"
                    size="36"
                    shadow
                    class="slider-nav__arrow slider-nav__arrow_prev"
                ></RoundArrowBtn>
            </template>
            <template #nextArrow>
                <RoundArrowBtn
                    direction="right"
                    size="36"
                    shadow
                    class="slider-nav__arrow slider-nav__arrow_next"
                ></RoundArrowBtn>
            </template>
        </VueSlickCarousel>
    </section>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import { mapState } from 'vuex';
import deepClone from '@/lib/deepClone.js';
import VueSlickCarousel from 'vue-slick-carousel';
import MarketItemMinimal from './MarketItemMinimal.vue';
import RoundArrowBtn from './project-buttons/RoundArrowBtn.vue';

export default {
    name: 'LastSee',

    components: {
        RoundArrowBtn,
        VueSlickCarousel,
        MarketItemMinimal,
    },

    computed: {
        ...mapState({
            items: state => state.viewsHistory.items,
        }),

        itemsWithoutCurrent() {
            let clonedItems = deepClone(this.items);
            const currentPageItem = clonedItems.find(item => {
                if (item[item.item_type].id === Number(this.$route.params.id)) return item;
            });
            const currentPageItemIndex = clonedItems.indexOf(currentPageItem);
            if (currentPageItemIndex > -1) {
                clonedItems.splice(currentPageItemIndex, 1);
                return clonedItems;
            }
            else {
                return this.items;
            }
        },
    },
};
</script>

<style scoped>
.slider-section {
    margin-top: calc(var(--gap-y-medium) - 24px);
}

@media (min-width: 1040px) and (max-width: 1280px) {
    .slider__item-wrap {
        width: 300px !important;
    }
}
@media (max-width: 767px) {
    .slider__item-wrap {
        width: 298px !important;
    }
}
</style>