import deepClone from '@/lib/deepClone.js';


const initial = {
    initializing: false,
    initialized: false,
    notifications: [],
    currentCount: 0,
    fetching: false,
    offset: 0,
    limit: 50,
    unreadCount: 0,
};

export default {
    namespaced: true,

    state: () => deepClone(initial),

    mutations: {
        setInitializing(state, value) {
            state.initializing = value;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        setNotifications(state, value) {
            state.notifications = value;
        },

        addNotifications(state, notifications) {
            state.notifications = [...state.notifications, ...notifications];
        },

        setCurrentCount(state, count) {
            state.currentCount = count;
        },

        setFetching(state, value) {
            state.fetching = value;
        },

        incrementOffset(state) {
            state.offset += state.limit;
        },

        setIsRead(state, payload) {
            const uuids = Array.isArray(payload) ? payload : [payload];

            state.notifications = state.notifications.map(notification => {
                const is_read = notification.is_read || uuids.includes(notification.uuid);
                return { ...notification, is_read };
            });
        },

        setIsReadForAll(state) {
            state.notifications = state.notifications.map(notification => {
                return { ...notification, is_read: true };
            });
        },

        setUnreadCount(state, value) {
            state.unreadCount = value;
        },

        destroy(state) {
            Object.entries(deepClone(initial)).forEach(([key, value]) => {
                state[key] = value;
            });
        },
    },

    actions: {
        async prefetch({ commit, dispatch }) {
            commit('setInitializing', true);

            try {
                const [{ results, current_count }] = await Promise.all([
                    dispatch('get'),
                    dispatch('fetchUnreadCount'),
                ]);

                commit('setNotifications', results);
                commit('setCurrentCount', current_count);
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
            finally {
                commit('setInitializing', false);
                commit('setInitialized', true);
            }
        },

        async get({ state }) {
            const limit = state.limit;
            const offset = state.offset;
            const params = { limit, offset };
            const config = { params };
            return await this.$api.notifications.get(config);
        },

        async more({ commit, dispatch }) {
            commit('setFetching', true);
            commit('incrementOffset');

            try {
                const { results, current_count } = await dispatch('get');

                commit('addNotifications', results);
                commit('setCurrentCount', current_count);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                commit('setFetching', false);
            }
        },

        async fetchUnreadCount({ commit }) {
            const limit = 0;
            const is_read = false;
            const params = { limit, is_read };
            const config = { params };
            const { current_count } = await this.$api.notifications.get(config);
            commit('setUnreadCount', current_count);
        },

        async setIsRead({ commit }, data) {
            try {
                await this.$api.notifications.setIsRead(data);
                commit('setIsRead', data.notifications);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async setIsReadForAll({ state, commit }) {
            try {
                await this.$api.notifications.setIsReadForAll();
                const unreadNotifications = state.notifications
                    .filter(notification => !notification.is_read)
                    .map(notification => notification.uuid);

                commit('setIsRead', unreadNotifications);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};