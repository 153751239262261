<script>
import FilterCheckboxes from './FilterCheckboxes.vue';
import FilterRadioGroup from './FilterRadioGroup.vue';
import FilterRangePrice from './FilterRangePrice.vue';
import FilterCheckbox from './FilterCheckbox.vue';
import FilterRating from './FilterRating.vue';
import FilterCategories from './FilterCategories.vue';

const components = {
    checkboxes: FilterCheckboxes,
    radio: FilterRadioGroup,
    radioBoolean: FilterRadioGroup,
    range: FilterRangePrice,
    rating: FilterRating,
    checkbox: FilterCheckbox,
    categories: FilterCategories,
};

export default {
    name: 'FilterSwitcher',

    functional: true,

    props: {
        filters: {
            type: Array,
            default() {
                return [];
            },
        },

        value: {
            type: Object,
            default() {
                return {};
            },
        },

        amount: {
            type: Number,
            default: 0,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },

    render(h, context) {
        return context.props.filters.map((filter, filterIndex) => {
            const value = context.props.value[filter.codename];

            return value === undefined ? null : h(
                components[filter.widget],
                {
                    props: {
                        config: filter,
                        value,
                        amount: context.props.amount,
                        loading: context.props.loading,
                        index: filterIndex,
                    },
                    on: {
                        change: newValue => {
                            const oldData = context.props.value;
                            const oldValue = oldData[filter.codename];
                            const data = Object.assign({}, oldData, { [filter.codename]: newValue });
                            const payload = { filter, newValue, data, oldValue };
                            context.listeners.change(payload);
                        },
                        deleteFilter: context.listeners.deleteFilter || function() {},
                        submit: context.listeners.submit || function() {},
                        reload: context.listeners.reload,
                    },
                    key: 'filter-' + filter.codename + '-index-' + filterIndex,
                },
            );
        });
    },
};
</script>