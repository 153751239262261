<template>
    <div class="error-5xx-page">
        <div class="error-5xx-page__container">
            <div class="logo-wrap">
                <img
                    src="../assets/images/logo.svg"
                    alt="Логотип Ubibi"
                    class="logo"
                    width="80"
                    height="24"
                >
            </div>

            <div class="error-5xx-main">
                <div class="image-5xx-wrap">
                    <span class="error-code">{{ error }}</span>
                    <img
                        src="../assets/images/car-red.png"
                        alt="Ubibi"
                        class="car-image"
                        width="264"
                        height="98"
                    >
                </div>

                <h1 class="h1 h1_text">
                    Что-то пошло не так
                </h1>
                <p class="error-5xx-page__text">
                    {{ computedText }}
                </p>
                <p
                    v-if="sentryErrorHash"
                    class="error-5xx-page__sentry-text"
                >
                    Код ошибки: {{ sentryErrorHash }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error5xxPage',

    metaInfo() {
        return {
            title: this.computedMetaTitle,
        };
    },

    props: {
        error: {
            type: Number,
            default: 500,
        },

        sentryErrorHash: {
            type: [String, Number],
            default: '',
        },
    },

    computed: {
        computedMetaTitle() {
            let text = 'Ошибка сервера';
            if (this.error === 500) text = 'Внутренняя ошибка сервера';
            else if (this.error === 504) text = 'Сервер не отвечает';
            return text;
        },

        computedText() {
            let text = 'Ошибка сервера, попробуйте повторить попытку позже.';
            if (this.error === 500) text = 'Внутренняя ошибка сервера, попробуйте повторить попытку позже.';
            else if (this.error === 504) text = 'Сервер не отвечает, попробуйте повторить попытку позже.';
            return text;
        },
    },
};
</script>

<style scoped>
.error-5xx-page {
    background-color: var(--bright-bg);
}

.error-5xx-page__container {
    position: relative;
    width: var(--container);
    padding-top: var(--gap-y-big);
    padding-bottom: var(--gap-y-big);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.error-5xx-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--gap-y-big);
    margin-bottom: var(--gap-y-big);
}

.logo-wrap {
    position: absolute;
    top: var(--gap-y-big);
    left: 0;
}

@media (min-width: 768px) {
    .logo {
        width: 131px;
        height: 40px;
    }
}
@media (max-width: 767px) {
    .logo {
        width: 80px;
        height: 24px;
    }
}

.image-5xx-wrap {
    position: relative;
    margin-bottom: 20px;
}

.error-code {
    display: block;
    line-height: 1;
    font-family: var(--f-extra-bold);
}
@media (min-width: 1040px) {
    .error-code {
        height: 300px;
        font-size: 300px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .error-code {
        height: 225px;
        font-size: 225px;
    }
}
@media (max-width: 767px) {
    .error-code {
        height: 135px;
        font-size: 135px;
    }
}

.car-image {
    margin-left: auto;
    margin-right: auto;
}
@media (min-width: 1040px) {
    .car-image {
        width: 542px;
        height: 200px;
        margin-top: -150px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .car-image {
        width: 406px;
        height: 150px;
        margin-top: -112px;
    }
}
@media (max-width: 767px) {
    .car-image {
        width: 266px;
        height: 98px;
        margin-top: -70px;
    }
}

.error-5xx-page__text {
    font-size: var(--adaptive-big-fz);
    line-height: var(--base-lh);
    text-align: center;
}
@media (min-width: 1040px) {
    .error-5xx-page__text {
        max-width: 780px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .error-5xx-page__text {
        width: var(--col-10);
    }
}

.error-5xx-page__sentry-text {
    font-size: var(--base-fz);
    color: var(--font-secondary-color);
    line-height: var(--base-lh);
    text-align: center;
}
@media (min-width: 768px) {
    .error-5xx-page__sentry-text {
        margin-top: 20px;
    }
}
@media (max-width: 767px) {
    .error-5xx-page__sentry-text {
        margin-top: 8px;
    }
}
</style>