<template>
    <div class="market-item">
        <div class="market-item__cover-title-wrap">
            <MarketItemCover
                :item="item.offer[modelName]"
                :modelName="modelName"
                coverCodename="micro"
                class="market-item__cover-wrap"
            ></MarketItemCover>

            <MarketItemName
                :id="item.offer[modelName].id"
                :modelName="modelName"
                :name="item.offer[modelName].name"
                class="market-item__title"
            ></MarketItemName>
        </div>

        <span
            v-if="item.deleted"
            class="market-item__deleted-text"
        >Товар удалён</span>
        <div
            v-else
            class="market-item__counter-wrap"
        >
            <span
                v-if="modelName === 'contract_part'"
                class="market-item__amount"
            >1 шт.</span>
            <Counter
                v-else
                :value="item.amount"
                :min="0"
                :max="item.confirmed_amount"
                class="market-item__counter"
                @change="handleCounterChange"
            ></Counter>

            <UTooltip v-if="modelName === 'contract_part' || item.confirmed_amount">
                <template #trigger="{ attrs, handlers, value }">
                    <UTooltipIcon
                        class="market-item__counter-tooltip"
                        :active="value"
                        v-bind="attrs"
                        v-on="handlers"
                    ></UTooltipIcon>
                </template>

                <p v-if="modelName === 'contract_part'">
                    Контрактные автозапчасти продаются в единственном экземпляре.
                </p>
                <p v-else>
                    В наличии есть {{ item.confirmed_amount }} шт.
                </p>
            </UTooltip>
        </div>

        <b class="market-item__price">
            {{ item.total_price | price }}
        </b>

        <UTooltip
            v-if="item.deleted"
            noDetach
            class="market-item__action-btn"
        >
            <template #trigger="{ value, attrs, handlers }">
                <ButtonIcon
                    hoverPrimary
                    :icon="{
                        name: 'restore',
                    }"
                    :active="value"
                    aria-label="Вернуть товар"
                    v-bind="attrs"
                    v-on="handlers"
                    @click="handleRevert"
                ></ButtonIcon>
            </template>

            Вернуть товар в заказ
        </UTooltip>

        <UTooltip
            v-if="!item.deleted"
            noDetach
            class="market-item__action-btn"
        >
            <template #trigger="{ value, attrs, handlers }">
                <ButtonIcon
                    hoverPrimary
                    :icon="{
                        name: 'delete',
                    }"
                    aria-label="Удалить товар из заказа"
                    :active="value"
                    v-bind="attrs"
                    v-on="handlers"
                    @click="handleDelete"
                ></ButtonIcon>
            </template>

            Удалить товар из заказа
        </UTooltip>
    </div>
</template>

<script>
import Counter from "@/components/Counter.vue";
import MarketItemCover from "@/components/MarketItemCover.vue";
import MarketItemName from "@/components/MarketItemName.vue";
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


export default {
    name: 'MarketItemEditOrder',

    components: {
        UTooltipIcon,
        UTooltip,
        ButtonIcon,
        MarketItemName,
        MarketItemCover,
        Counter,
    },

    props: {
        item: {
            type: Object,
            default: () => {},
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },
    },

    methods: {
        handleCounterChange(value) {
            if (value === 0) {
                this.handleDelete();
            }
            else {
                this.$emit('amountChange', value);
            }
        },

        handleDelete() {
            this.$emit('delete');
        },

        handleRevert() {
            this.$emit('revert');
        },
    },
};
</script>

<style scoped>
.market-item {
    position: relative;
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .market-item {
        flex-wrap: wrap;
    }
}

.market-item__cover-title-wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
@media (min-width: 768px) {
    .market-item__cover-title-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .market-item__cover-title-wrap {
        width: 100%;
        order: 1;
        margin-bottom: 12px;
    }
}

@media (min-width: 768px){
    .market-item__cover-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .market-item__cover-wrap {
        margin-right: 12px;
    }
}

.market-item__title {
    flex-grow: 1;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}

.market-item__amount {
    width: 106px;
    flex-shrink: 0;
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .market-item__amount {
        text-align: right;
    }
}

.market-item__counter-wrap {
    width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
}
@media (max-width: 767px) {
    .market-item__counter-wrap {
        order: 3;
    }
}

.market-item__counter-tooltip {
    flex-shrink: 0;
    margin-left: 12px;
}

.market-item__price {
    flex-shrink: 0;
    text-align: right;
}
@media (min-width: 768px) {
    .market-item__price {
        width: 100px;
        margin-left: 20px;
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .market-item__price {
        margin-left: auto;
        order: 4;
    }
}

@media (max-width: 767px) {
    .market-item__action-btn {
        order: 5;
        margin-left: 20px;
    }
}

.market-item__deleted-text {
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .market-item__deleted-text {
        width: 136px;
        flex-shrink: 0;
        text-align: center;
    }
}
@media (max-width: 767px) {
    .market-item__deleted-text {
        order: 3;
    }
}
</style>
