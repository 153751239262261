var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AccountLayout',{staticClass:"account-insurance-page",attrs:{"title":"Страховые полисы","mobileTitle":"Страховые полисы"}},[_c('div',[_c('div',{staticClass:"tabs-wrap layer-1"},[_c('UTabs',{attrs:{"items":[
                    {
                        label: 'Полисы ОСАГО',
                        name: 'policies',
                        to: {
                            name: 'account-insurance-policies'
                        },
                        count: _vm.policiesCount,
                    },
                    {
                        label: 'Сохранённые расчёты',
                        name: 'drafts',
                        to: {
                            name: 'account-insurance-drafts'
                        },
                        count: _vm.contractsCount,
                    }
                ]}})],1),_vm._v(" "),_c('RouterView')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }