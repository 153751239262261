<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_block popup-title">
                Предложения от страховых компаний
            </h2>

            <Washboard noPadding>
                <div
                    v-for="offer in offersComputed"
                    :key="'insurance-option-' + offer.company.code"
                    class="row"
                >
                    <div class="company-wrap">
                        <InsuranceCompanyLogo
                            :logo="offer.company.logo"
                            :name="offer.company.page_title"
                            class="logo"
                        ></InsuranceCompanyLogo>

                        <div class="company">
                            <div class="company-name">
                                {{ offer.company.name }}
                            </div>

                            <div class="text_secondary">
                                {{ offer.company.license }}
                            </div>
                        </div>
                    </div>

                    <div class="offer-price">
                        <span class="price">{{ offer.price | floorPrice }}</span>

                        <ButtonBlock
                            primary
                            class="button-apply button-apply_desktop"
                            :loading="loading"
                            @click="selectOffer(offer)"
                        >
                            Выбрать
                        </ButtonBlock>
                        <ButtonBlock
                            primary
                            low
                            class="button-apply button-apply_mobile"
                            :loading="loading"
                            @click="selectOffer(offer)"
                        >
                            Выбрать
                        </ButtonBlock>
                    </div>
                </div>
            </Washboard>
        </div>
    </PopupWrap>
</template>

<script>
import { mapActions } from 'vuex';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import deepClone from '@/lib/deepClone.js';
import InsuranceCompanyLogo from "@/pages/insurance/InsuranceCompanyLogo.vue";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import Washboard from "@/components/Washboard.vue";


export default {
    name: "InsuranceOsagoOtherOffersPopup",

    components: {
        Washboard,
        ButtonBlock,
        InsuranceCompanyLogo,
        PopupWrap,
    },

    mixins: [
        popup,
    ],

    props: {
        offers: {
            type: Array,
            default() {
                return [];
            },
        },

        companies: {
            type: Array,
            default() {
                return [];
            },
        },

        isCompleted: Boolean,
    },

    data() {
        return {
            loading: false,
        };
    },

    computed: {
        flatCompanies() {
            return this.companies.reduce((acc, company) => {
                acc[company.code] = company;
                return acc;
            }, {});
        },

        offersComputed() {
            const offers = deepClone(this.offers);

            return offers.map(offer => {
                if (this.flatCompanies[offer.company]) {
                    offer.company = this.flatCompanies[offer.company];
                }

                return offer;
            });
        },
    },

    methods: {
        ...mapActions({
            updateActiveOffer: 'insuranceOsago/updateActiveOffer',
        }),

        async selectOffer(offer) {
            this.loading = true;

            try {
                await this.updateActiveOffer(offer);

                this.close();
            }
            catch (error) {}
            finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

@media (min-width: 768px) {
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (min-width: 768px) {
    .company-wrap {
        display: flex;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .company-wrap {
        margin-bottom: 16px;
    }
}

@media (min-width: 1040px) {
    .logo {
        margin-right: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .logo {
        margin-bottom: 8px;
    }
}

.company-name {
    margin-bottom: 4px;
    font-family: var(--f-bold);
}

.offer-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (min-width: 1040px) {
    .offer-price {
        margin-left: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .offer-price {
        display: block;
        margin-left: 20px;
        text-align: right;
    }
}

.price {
    display: block;
    font-size: var(--h3-fz);
    font-family: var(--f-bold);
}
@media (min-width: 1040px) {
    .price {
        margin-right: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .price {
        margin-bottom: 12px;
        text-align: right;
    }
}

@media (min-width: 1040px) {
    .button-apply_mobile {
        display: none;
    }
}
@media (max-width: 1039px) {
    .button-apply_desktop {
        display: none;
    }
}
</style>