var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.withoutTooltip)?_c('div',{staticClass:"time",class:{
        'time_open': _vm.status.place.is_active,
        'time_close': !_vm.status.place.is_active,
    }},[_vm._v("\n    "+_vm._s(_vm.status.place.is_active ? 'Открыто' : 'Закрыто')+" до "+_vm._s(_vm.status.place.text)+"\n")]):_c('UTooltip',{on:{"open":_vm.init,"afterClose":_vm.afterClose},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
    var handlers = ref.handlers;
    var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"time",class:{
                'time_open': _vm.status.place.is_active,
                'time_close': !_vm.status.place.is_active,
            }},'p',attrs,false),handlers),[_vm._v("\n            "+_vm._s(_vm.status.place.is_active ? 'Открыто' : 'Закрыто')+" до "+_vm._s(_vm.status.place.text)+"\n        ")])]}}])},[_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"flex align-items-center justify-content-center"},[_c('USpinner',{attrs:{"light":""}})],1):_c('ShopWorkSchedule',{staticClass:"tooltip-schedule",attrs:{"work":(_vm.tooltipData && _vm.tooltipData.work_times) || _vm.shopInfo.work_times,"holidays":(_vm.tooltipData && _vm.tooltipData.holidays) || _vm.shopInfo.holidays,"onDarkBg":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }