<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_text">
                Удалить отзыв
            </h2>

            <p>
                Вы действительно хотите удалить отзыв?
            </p>

            <div class="actions-wrap">
                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="close"
                >
                    Нет
                </ButtonBlock>
                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    class="action-btn"
                    @click="confirm"
                >
                    Подтвердить
                </ButtonBlock>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';


export default {
    name: 'RemoveReviewPopup',

    components: { ButtonBlock, PopupWrap },

    mixins: [popup],

    props: {
        review: Object,
        shop: Object,
        product: Object,
        part: Object,
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        async confirm() {
            this.loading = true;

            try {
                const rq = this.shop
                    ? this.$api.shops.reviews.deleteReview
                    : this.part
                        ? this.$api.parts.reviews.deleteReview
                        : this.$api.products.reviews.deleteReview;
                const id = (this.shop || this.part || this.product).id;
                await rq(id, this.review.uuid);
                this.$success();
                this.callback(true);
                this.close();
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 476px;
    }
}

.actions-wrap {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .actions-wrap {
        margin-top: auto;
    }

    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>