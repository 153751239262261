<template>
    <transition
        appear
        name="slide-from-bottom"
    >
        <div class="balloon layer-1">
            <CloseBtn
                :shadow="false"
                class="close-btn close-btn_desktop"
                @click="closeBalloon"
            ></CloseBtn>

            <CloseBtn
                class="close-btn close-btn_mobile"
                @click="closeBalloon"
            ></CloseBtn>

            <div class="balloon__head">
                <ShopName
                    :shop="shop"
                    noLink
                    class="text_big-fz"
                ></ShopName>

                <ShopAddress
                    name="shop-name-popup"
                    :shop="shop"
                    :showOnMap="false"
                    class="mt-8"
                ></ShopAddress>
            </div>

            <ShopOpenCloseTime
                :shopId="shop.id"
                :status="shop.current_work"
                withoutTooltip
                class="mt-4"
            ></ShopOpenCloseTime>

            <MarketItemRatingReviewsLink
                :item="shop"
                modelName="shop"
                class="mt-16"
            ></MarketItemRatingReviewsLink>

            <div class="market-item__actions mt-16">
                <LikeBtn
                    :id="shop.id"
                    :value="shop.is_favorite"
                    apiName="shop"
                    withText
                    class="market-item__action-btn"
                    @changeFavorite="onChangeFavorite"
                ></LikeBtn>

                <ChatBtn
                    :id="shop.id"
                    :isOnline="shop.current_work.place.is_active"
                    :disabled="!shop.is_chat_active"
                    btnTextAlwaysVisible
                    withText
                    class="market-item__action-btn"
                    @click="$emit('clickChat')"
                ></ChatBtn>
            </div>

            <DeliveryInfo
                :currentWork="shop.current_work"
                list
                class="mt-16"
            ></DeliveryInfo>

            <ShopWorkSchedule
                v-if="shop.work_times"
                :work="shop.work_times"
                :holidays="shop.holidays"
                class="mt-16"
            ></ShopWorkSchedule>

            <ButtonBlock
                v-if="!fullInfo"
                :to="{
                    name: 'market-shop-stock',
                    params: {
                        codename: company.codename,
                        id: shop.id,
                    },
                }"
                secondary
                w100
                class="mt-16"
            >
                На страницу магазина
            </ButtonBlock>
        </div>
    </transition>
</template>

<script>
import ShopAddress from "./ShopAddress.vue";
import ShopOpenCloseTime from "./ShopOpenCloseTime.vue";
import ShopWorkSchedule from "./ShopWorkSchedule.vue";
import ChatBtn from "./project-buttons/ChatBtn.vue";
import LikeBtn from "./project-buttons/LikeBtn.vue";
import ShopName from '@/components/ShopName.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import DeliveryInfo from '@/components/DeliveryInfo.vue';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';
import CloseBtn from '@/components/project-buttons/CloseBtn.vue';

export default {
    name: 'ShopBalloon',

    components: {
        CloseBtn,
        MarketItemRatingReviewsLink,
        DeliveryInfo,
        ButtonBlock,
        ShopName,
        LikeBtn,
        ShopAddress,
        ShopOpenCloseTime,
        ShopWorkSchedule,
        ChatBtn,
    },

    props: {
        shop: {
            type: Object,
            default: () => {},
        },
        company: {
            type: Object,
            default: () => {},
        },
        fullInfo: Boolean,
    },

    methods: {
        closeBalloon() {
            this.$emit('close');
        },

        onChangeFavorite(value) {
            this.$emit('changeFavorite', value);
        },
    },
};
</script>

<style scoped>
.balloon {
    z-index: 2;
    font-size: var(--base-fz);
    font-family: var(--f-base);
    transition: all var(--transition);
}
@media (min-width: 768px) {
    .balloon {
        width: 380px;
        padding: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .slide-from-bottom-enter,
    .slide-from-bottom-leave-to {
        opacity: 0;
    }

    .slide-from-bottom-enter-to,
    .slide-from-bottom-leave {
        opacity: 1
    }
}
@media (max-width: 767px) {
    .balloon {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 90vh;
        padding: 20px var(--content-gap);
        box-shadow: var(--base-shadow);
        border-radius: var(--border-radius-x3) var(--border-radius-x3) 0 0;
        overflow: auto;
    }

    .slide-from-bottom-enter,
    .slide-from-bottom-leave-to {
        transform: translateY(100%);
    }
}

@media (min-width: 768px) {
    .balloon__head {
        padding-right: 12px;
    }
}
@media (max-width: 767px) {
    .balloon__head {
        padding-right: 48px;
    }
}

@media (min-width: 768px) {
    .close-btn {
        position: absolute;
        top: 4px;
        right: 4px;
    }

    .close-btn_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .close-btn_desktop {
        display: none;
    }
}
</style>