import deepClone from '@/lib/deepClone.js';


export const serializeContract = data => {
    // для стора

    const newData = deepClone(data);
    const isNew = !newData.status || newData.status === 'new';

    if (!data.unlimited_drivers_amount && isNew && !data.drivers.length) {
        newData.drivers = [{
            last_name: '',
            first_name: '',
            middle_name: '',
            birth_date: '',
            license_number: '',
            experience_start_date: '',
            prev_license: false,
            prev_last_name: '',
            prev_first_name: '',
            prev_middle_name: '',
            prev_license_number: '',
            prev_license_date: '',
        }];
    }

    newData.owner_is_insurer =
        newData.insurer_last_name === newData.owner_last_name &&
        newData.insurer_first_name === newData.owner_first_name &&
        newData.insurer_middle_name === newData.owner_middle_name &&
        newData.insurer_birth_date === newData.owner_birth_date &&
        newData.insurer_doc_number === newData.owner_doc_number &&
        newData.insurer_doc_date === newData.owner_doc_date &&
        (newData.insurer_address_obj || {}).coords === (newData.owner_address_obj || {}).coords &&
        newData.insurer_address_apartment_number === newData.owner_address_apartment_number;

    return newData;
};

export const deserializeContract = data => {
    // для отправки на бэк
    const keys = [
        'vehicle_license_plate',
        'vehicle_id_type_code',
        'vehicle_id_number',
        'vehicle_manufacturer',
        'vehicle_model',
        'vehicle_year',
        'vehicle_engine_power',
        'vehicle_doc_type_code',
        'vehicle_doc_number',
        'vehicle_doc_date',
        'diagnostic_card_number',
        'diagnostic_card_exp',

        'action_start_date',
        'action_end_date',

        'unlimited_drivers_amount',
        'drivers',

        'owner_last_name',
        'owner_first_name',
        'owner_middle_name',
        'owner_birth_date',
        'owner_doc_number',
        'owner_doc_date',
        'owner_address_obj',
        'owner_address_apartment_number',
        'owner_is_insurer',
        'insurer_last_name',
        'insurer_first_name',
        'insurer_middle_name',
        'insurer_birth_date',
        'insurer_doc_number',
        'insurer_doc_date',
        'insurer_address_obj',
        'insurer_address_apartment_number',
        'insurer_email',
        'insurer_phone',
    ];

    const newData = Object.entries(data).reduce((acc, [key, value]) => {
        if (keys.includes(key)) {
            acc[key] = value;
        }

        return acc;
    }, {});

    if (newData.unlimited_drivers_amount) {
        newData.drivers = [];
    }

    // очистка пустых полей у водителей
    if (newData.drivers && newData.drivers.length) {
        newData.drivers = newData.drivers.map(driver => {
            const keys = [
                'last_name',
                'first_name',
                'middle_name',
                'birth_date',
                'license_number',
                'experience_start_date',
                'prev_license',
            ];

            if (driver.prev_license) {
                keys.push(
                    'prev_last_name',
                    'prev_first_name',
                    'prev_middle_name',
                    'prev_license_number',
                    'prev_license_date',
                );
            }

            if (driver.uuid) {
                keys.push('uuid');
            }

            return Object.entries(driver).reduce((acc, [key, value]) => {
                if (keys.includes(key)) {
                    acc[key] = value;
                }

                return acc;
            }, {});
        });
    }

    return newData;
};

export default {
    namespaced: true,

    state: () => ({
        initialized: false,

        contract: {
            uuid: '',
            status: '',

            vehicle_license_plate: '',
            vehicle_id_type_code: 'vin',
            vehicle_id_number: '',
            vehicle_manufacturer: null,
            vehicle_model: null,
            vehicle_year: null,
            vehicle_engine_power: null,
            vehicle_doc_type_code: 'STS',
            vehicle_doc_number: '',
            vehicle_doc_date: null,
            diagnostic_card_number: '',
            diagnostic_card_exp: null,

            action_start_date: null,
            action_end_date: null,

            unlimited_drivers_amount: false,
            drivers: [{
                last_name: '',
                first_name: '',
                middle_name: '',
                birth_date: null,
                license_number: '',
                experience_start_date: null,
                prev_license: false,
                prev_last_name: '',
                prev_first_name: '',
                prev_middle_name: '',
                prev_license_number: '',
                prev_license_date: null,
            }],

            owner_last_name: '',
            owner_first_name: '',
            owner_middle_name: '',
            owner_birth_date: null,
            owner_doc_number: '',
            owner_doc_date: null,
            owner_address_obj: '',
            owner_address_apartment_number: null,
            owner_is_insurer: true,
            insurer_last_name: '',
            insurer_first_name: '',
            insurer_middle_name: '',
            insurer_birth_date: null,
            insurer_doc_number: '',
            insurer_doc_date: null,
            insurer_address_obj: '',
            insurer_address_apartment_number: null,
            insurer_email: '',
            insurer_phone: '',

            offers: [],
            active: {
                company: null, // selectedCompany
                offer_obj: null,
            },
            // Флаг для понимания, доходил ли пользователь до order.
            // Это нужно на странице offers.
            has_order: false,

            // Поле для сохранения последнего роута.
            // Нужно для писем.
            last_path: '',

            // Предположительные поля
            confirmed_phone: '',
            confirmed_phone_status: '',
            confirmed_phone_second_expire: null,

            need_to_create_replicate: false,
        },

        companies: [],
        calculateIsCompleted: false,

        loading: false,
        timeout: null,
        interval: '',

        pollingOffers: {
            loading: false,
            interval: null,
            timeout: null,
        },

        isCreated: false,
    }),

    getters: {
        offersByCompany: state => {
            return (state.contract.offers || []).reduce((acc, offer) => {
                const key = offer.company;
                acc[key] = offer;
                return acc;
            }, {});
        },

        companiesByCode: state => {
            return (state.companies || []).reduce((acc, company) => {
                const key = company.code;
                acc[key] = company;
                return acc;
            }, {});
        },

        formData: state => keys => keys.reduce((acc, key) => {
            acc[key] = state.contract[key];
            return acc;
        }, {}),

        filteredOffers: (state, getters) => {
            return state.contract.offers.map(offer => {
                return Object.assign({}, offer, { company: getters.companiesByCode[offer.company] });
            }).filter(offer => offer.status !== 'fail_confirm' && offer.status !== 'canceled');
        },
    },

    mutations: {
        setCompanies(state, companies) {
            state.companies = companies;
        },

        setIsCompleted(state, value) {
            state.calculateIsCompleted = value;
        },

        setOffers(state, offers) {
            state.contract.offers = offers;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        updateContract(state, data) {
            state.contract = Object.assign({}, state.contract, deepClone(data));
        },

        setUuid(state, uuid) {
            state.contract.uuid = uuid;
        },

        setPollingOffersLoading(state, value) {
            state.pollingOffers.loading = value;
        },

        setPollingOffersTimeout(state, instance) {
            state.pollingOffers.timeout = instance;
        },

        setPollingOffersInterval(state, instance) {
            state.pollingOffers.interval = instance;
        },

        endPollingOffers(state) {
            clearInterval(state.pollingOffers.interval);
            state.pollingOffers.interval = null;
            clearTimeout(state.pollingOffers.timeout);
            state.pollingOffers.timeout = null;
            state.pollingOffers.loading = false;
        },

        reset(state) {
            state.initialized = false;

            state.contract = {
                uuid: '',
                offers: [],
                active: {
                    company: {},
                    offer: '',
                    offer_obj: {},
                },
            };

            state.companies = [];
            state.calculateIsCompleted = false;

            state.loading = false;
            state.timeout = null;
            state.interval = null;

            state.pollingOffers = {
                loading: false,
                interval: null,
                timeout: null,
            };
        },

        setActiveOfferObj(state, offer) {
            state.contract.active.offer_obj = offer;
        },

        setActiveCompany(state, company) {
            state.contract.active.company = company;
        },

        setHasOrder(state) {
            state.contract.has_order = true;
        },
    },

    actions: {
        async serverPrefetch({ dispatch, commit }, payload) {
            try {
                await dispatch('init', payload);
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async browserPrefetch({ state, dispatch, commit }, payload) {
            if (state.initialized) return;

            try {
                await dispatch('init', payload);
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async init({ commit }, { uuid, requiredOffers = false }) {
            if (uuid) {
                let contract = await this.$api.insuranceContract.get(uuid);

                if (contract.need_to_create_replicate) {
                    contract = await this.$api.insuranceContract.replicate(uuid);
                    uuid = contract.uuid;
                }

                const requests = [];

                // догрузка компаний
                requests.push(this.$api.insuranceCompany.get());

                // calculate
                if (requiredOffers && !contract.offers.length) {
                    // Условия для выполнения этого кода:
                    // 1. Это дубликат -> 2. Cтёрты оферы
                    // 3. Страница оферов и далее
                    requests.push(this.$api.insuranceContract.calculate(uuid, contract));
                }
                else {
                    requests.push(Promise.resolve());
                }

                const responses = await Promise.all(requests);

                if (responses[0]) {
                    // companies
                    const { results } = responses[0];
                    commit('setCompanies', results);
                }

                if (responses[1]) {
                    // получение флага is_completed если был calculate
                    // для понимания нужно ли делать полинг
                    const { is_completed, offers } = await this.$api.insuranceContract.offers(uuid);
                    commit('setIsCompleted', is_completed);
                    contract.offers = offers;
                }

                // установка активного офера
                if (
                    !contract.active.offer_obj &&
                    contract.active.company &&
                    contract.offers &&
                    contract.offers.length
                ) {
                    const index = contract.offers.map(offer => offer.company).indexOf(contract.active.company.code);

                    if (index !== -1) {
                        const offer = contract.offers[index];
                        const uuid = offer.uuid;

                        await this.$api.insuranceOffer.setActive(uuid);

                        contract.active.offer_obj = contract.offers[index];
                    }
                }

                contract = serializeContract(contract);

                commit('updateContract', contract);
                commit('setInitialized');

                return { contract };
            }
            else {
                const { contract: uuid } = await this.$api.insuranceContract.new();

                commit('setUuid', uuid);

                return { uuid };
            }
        },

        async fetchOffers({ state, commit }) {
            const uuid = state.contract.uuid;

            try {
                const { is_completed, offers } = await this.$api.insuranceContract.offers(uuid);
                commit('setIsCompleted', is_completed);
                commit('setOffers', offers);
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        /**
         * @param commit
         * @param { object } offer
         * @param { object } offer.company
         * @param { string } offer.uuid
         * @returns { Promise<void> }
         */
        async updateActiveOffer({ commit }, offer) {
            try {
                await this.$api.insuranceOffer.setActive(offer.uuid);
                commit('setActiveOfferObj', offer);
                commit('setActiveCompany', offer.company);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
                throw error;
            }
        },

        startPollingOffers({ state, commit, dispatch }) {
            if (state.calculateIsCompleted) return;

            commit('setPollingOffersLoading', true);
            commit('setPollingOffersTimeout', setTimeout(() => {
                commit('endPollingOffers');
            }, 300000));
            dispatch('pollingOffersRequest');
        },

        async pollingOffersRequest({ state, commit, dispatch }) {
            const { uuid, active } = state.contract;
            const { company } = active;

            commit('setPollingOffersInterval', setTimeout(async () => {
                const { is_completed, offers } = await this.$api.insuranceContract.offers(uuid);

                commit('setOffers', offers);
                commit('setIsCompleted', is_completed);

                if (company) {
                    const index = offers.map(offer => offer.company).indexOf(company.code);

                    if (index !== -1) {
                        try {
                            const offer = offers[index];
                            const uuid = offer.uuid;
                            await this.$api.insuranceOffer.setActive(uuid);
                            commit('setActiveOfferObj', offer);
                        }
                        catch (error) {
                            commit('handleCommonHttpError', error, { root: true });
                        }
                    }
                }

                if (is_completed) {
                    commit('endPollingOffers');
                }
                else {
                    dispatch('pollingOffersRequest');
                }
            }, 5000));
        },

        async calculate({ state, commit }) {
            const { contract } = state;
            const { uuid, active } = contract;

            const data = deserializeContract(contract);

            try {
                let { companies } = await this.$api.insuranceContract.calculate(uuid, data);

                if (!companies || !companies.length) {
                    const { results } = await this.$api.insuranceCompany.get();
                    companies = results;
                }

                if (active.offer_obj) {
                    commit('setActiveOfferObj', null);
                }

                if (companies && Array.isArray(companies) && companies.length) {
                    commit('setCompanies', companies);
                }

                commit('updateContract', { status: 'pre_calc' });
                commit('setOffers', []);
                commit('setActiveOfferObj', null);
                commit('setIsCompleted', false);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
                throw error;
            }
        },

        async order({ state, commit }, force) {
            const uuid = state.contract.active.offer_obj.uuid;

            if (uuid) {
                if (force) {
                    const offer_obj = { ...state.contract.active.offer_obj, is_completed: false };
                    const active = { ...state.contract.active, offer_obj };
                    commit('updateContract', { active });
                }

                const data = deserializeContract(state.contract);

                try {
                    await this.$api.insuranceOffer.order(uuid, data);

                    commit('updateContract', { status: 'final_calc' });
                    commit('setHasOrder');
                }
                catch (error) {
                    commit('handleCommonHttpError', error, { root: true });
                    throw error;
                }
            }
        },
    },
};