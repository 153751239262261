<template>
    <div class="car__characteristics-list">
        <template v-for="(characteristic, index) in characteristics">
            <span
                v-if="characteristic.value || characteristic.values"
                class="car__characteristic"
            >
                <span class="car__characteristic-value">
                    <template
                        v-if="characteristic.value"
                    >{{ characteristic.value }}</template><template
                        v-if="characteristic.values"
                    >{{ joinValues(characteristic.values) }}</template><template
                        v-if="index !== characteristics.length-1"
                    >, </template>
                </span>

                <CarCharacteristicsTableTooltip
                    v-if="showTooltip && index === characteristics.length-1"
                    class="car__characteristic-tooltip"
                    :car="car"
                    :dynamicContent="true"
                ></CarCharacteristicsTableTooltip>
            </span>
        </template>
    </div>
</template>

<script>
import CarCharacteristicsTableTooltip from './CarCharacteristicsTableTooltip.vue';
import { mainAttrsArrForSelectCarByModel } from '@/settings.js';


export default {
    name: 'CarCharacteristicsWithTooltip',

    components: { CarCharacteristicsTableTooltip },

    props: {
        car: {
            type: Object,
            default: () => {},
        },

        showTooltip: {
            type: Boolean,
            default: true,
        },
        dynamicContent: Boolean,
    },

    computed: {
        characteristics() {
            const mainAttrs = mainAttrsArrForSelectCarByModel[this.car.manufacturer.codename];
            const frameAttr = mainAttrs[0];
            const mainAttrsArr = [];
            const restAttrsArr = [];

            this.car.characteristics.forEach(item => {
                const attr = mainAttrs.find(attr => attr.codename === item.codename);
                if (attr) {
                    if (attr.codename !== frameAttr.codename) {
                        item.name = attr.label;
                        mainAttrsArr.push(item);
                    }
                }
                else {
                    restAttrsArr.push(item);
                }
            });

            return [
                ...mainAttrsArr.filter(item => item.name && (item.value || item.values)),
                ...restAttrsArr.filter(item => item.name && (item.value || item.values)),
            ];
        },
    },

    methods: {
        joinValues(values) {
            return values.map((e) => e.value).join('/');
        },
    },
};
</script>

<style scoped>
.car__characteristics-list {
    font-size: 0;
}

.car__characteristic {
    display: inline-block;
}

.car__characteristic-value {
    font-size: var(--base-fz);
}

.car__characteristic:not(:last-child) {
    margin-right: 4px;
}

.car__characteristic-tooltip {
    display: inline-block;
    margin-left: 8px;
    vertical-align: -2px;
    font-size: 0;
}
</style>