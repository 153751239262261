<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="content__text-wrap">
                <h2 class="h2 h2_block popup-title">
                    Как подобрать шины?
                </h2>
                <ol class="content__list">
                    <li class="content__item">
                        <h3 class="content__title">
                            1. Ширина профиля, мм
                        </h3>
                        <p class="content__text">
                            Первое число в маркировке шины. Например, 165/70 R14, где ширина равна 165 мм. При выборе шины следует придерживаться рекомендаций производителя автомобиля.
                        </p>
                    </li>
                    <li class="content__item">
                        <h3 class="content__title">
                            2. Высота профиля, %
                        </h3>
                        <p class="content__text">
                            Второе число в маркировке шины. Например, 165/70 R14, где 70 — высота, выраженная в процентном отношении к ширине шины (165). Высота профиля шины напрямую влияет на управляемость и комфорт при движении.
                        </p>
                    </li>
                    <li class="content__item">
                        <h3 class="content__title">
                            3. Диаметр, ″
                        </h3>
                        <p class="content__text">
                            Монтажный диаметр шины, измеренный в дюймах. При выборе шины по этому параметру следует придерживаться рекомендаций производителя автомобиля.
                        </p>
                    </li>
                </ol>
            </div>

            <img
                src="../../assets/images/tire-selection-info-scheme.jpg"
                alt="Иллюстрация к подбору шин"
                class="illustration"
            >
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';


export default {
    name: "TireSelectionInfoPopup",

    components: {
        PopupWrap,
    },

    mixins: [popup],
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        display: flex;
        align-items: center;
        width: 780px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

@media (min-width: 1040px) {
    .content__text-wrap {
        flex-shrink: 0;
        margin-right: 72px;
    }

    .content__list {
        width: 380px;
    }
}

.content__item:not(:last-child) {
    margin-bottom: 20px;
}

.content__title {
    margin-bottom: 8px;
    font-size: 16px;
    font-family: var(--f-bold);
}

.content__text {
    font-size: var(--base-fz);
    line-height: var(--base-lh);
}

@media (min-width: 1040px) {
    .illustration {
        width: 283px;
        height: 384px;
    }
}
@media (max-width: 1039px) {
    .illustration {
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .illustration {
        width: 50%;
    }
}
@media (max-width: 767px) {
    .illustration {
        max-width: 290px;
    }
}
</style>