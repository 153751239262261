<template>
    <TransitionExpand>
        <div
            v-if="show"
            key="field-error"
            class="field-error"
        >
            <slot></slot>
        </div>
    </TransitionExpand>
</template>

<script>
import TransitionExpand from '@/components/_transitions/TransitionExpand.vue';


export default {
    name: 'FieldError',

    components: { TransitionExpand },

    props: {
        show: Boolean,
    },
};
</script>

<style>
.field-error {
    font-size: var(--small-fz);
    color: var(--primary-color);
    font-family: var(--f-semi-bold);
}
</style>