<template>
    <div
        ref="previewWrap"
        v-on-clickaway="hide"
        @click="showOnClick"
        @mouseenter="showOnHover"
        @mouseleave="hideOnHover"
    >
        <div ref="trigger">
            <slot name="trigger" v-bind="{ isOpen }"></slot>
        </div>

        <transition name="preview-animation">
            <div
                v-if="hasContentSlot"
                v-show="isOpen"
                ref="arrow"
                class="arrow"
            ></div>
        </transition>

        <transition name="preview-animation">
            <div
                v-if="hasContentSlot"
                v-show="isOpen"
                key="preview"
                ref="previewContentWrap"
                class="preview-content-wrap"
            >
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import breakpointKey from '@/mixins/breakpointKey.js';

export default {
    name: 'PreviewComponent',

    mixins: [clickaway, breakpointKey],

    data() {
        return {
            isLeave: false,
            leaveTimeout: null,
            isEnter: false,
            enterTimeout: null,
            isOpen: false,
            resetPositionsTimeout: null,
        };
    },

    computed: {
        hasContentSlot() {
            return !!this.$slots.content || !!this.$scopedSlots.content;
        },
    },

    watch: {
        '$route.name'() {
            this.hide();
        },

        isOpen: 'handleChangeIsOpen',
    },

    methods: {
        handleChangeIsOpen(value) {
            if (value) {
                this.addScrollListener();
            }
            else {
                this.removeScrollListener();
            }
        },

        addScrollListener() {
            addEventListener('scroll', this.handleScroll, true);
        },

        removeScrollListener() {
            removeEventListener('scroll', this.handleScroll, true);
        },

        handleScroll($event) {
            if (!this.$refs.previewContentWrap || this.$refs.previewContentWrap.contains($event.target)) return;

            this.hide();
        },

        showOnHover() {
            if (this.breakpointKey === 'l' || this.breakpointKey === 'xl') {
                this.isLeave = false;
                this.isEnter = true;

                if (!this.enterTimeout) {
                    this.enterTimeout = setTimeout(() => {
                        if (this.isEnter) {
                            this.show();
                        }

                        this.enterTimeout = null;
                    }, 200);
                }
            }
        },

        hideOnHover() {
            if (this.breakpointKey === 'l' || this.breakpointKey === 'xl') {
                this.isLeave = true;
                this.isEnter = false;

                if (this.leaveTimeout) {
                    clearTimeout(this.leaveTimeout);
                    this.leaveTimeout = null;
                }

                this.leaveTimeout = setTimeout(() => {
                    if (this.isLeave) {
                        this.hide();
                    }

                    this.leaveTimeout = null;
                }, 100);
            }
        },

        showOnClick() {
            if (this.$refs.trigger) {
                const isTriggerTagButton = this.$refs.trigger.querySelector('button');

                if (this.breakpointKey === 'm' && isTriggerTagButton) {
                    this.show();
                }
            }
        },

        show() {
            if (this.hasContentSlot) {
                if (!this.isOpen) {
                    clearTimeout(this.resetPositionsTimeout);
                    this.$emit('show');
                    this.isOpen = true;
                    this.$nextTick(() => {
                        this.setContentPosition();
                    });
                }
            }
        },

        hide() {
            if (this.hasContentSlot) {
                if (this.isOpen) {
                    this.$emit('hide');
                    this.isOpen = false;
                    this.resetPositionsTimeout = setTimeout(() => {
                        this.resetPositions();
                    }, 300);
                }
            }
        },

        setContentPosition() {
            const MIN_GAP = 24;
            const clientWidth = document.documentElement.clientWidth;
            const content = this.$refs.previewContentWrap;
            const rectContent = content.getBoundingClientRect();
            const icon = this.$refs.trigger;
            const rectIcon = icon.getBoundingClientRect();
            const rectArrow = this.$refs.arrow.getBoundingClientRect();

            let x = rectIcon.x - rectContent.width / 2 + rectIcon.width / 2;
            if (x + rectContent.width + MIN_GAP > clientWidth) {
                x = clientWidth - rectContent.width - MIN_GAP;
            }
            else if (x < MIN_GAP) {
                x = MIN_GAP;
            }

            // let y = icon.offsetTop + rectIcon.height + rectArrow.height / 2 + window.pageYOffset;
            let y = rectIcon.y + rectIcon.height + rectArrow.height / 2;

            content.style.left = `${ x }px`;
            content.style.top = `${ y }px`;
            this.setArrowPosition();
        },

        setArrowPosition() {
            const icon = this.$refs.trigger;
            const rectIcon = icon.getBoundingClientRect();
            const arrow = this.$refs.arrow;
            const rectArrow = arrow.getBoundingClientRect();

            let x = rectIcon.x + rectIcon.width / 2 - rectArrow.width / 2;

            // let y = icon.offsetTop + rectIcon.height - rectArrow.height / 2 + window.pageYOffset;
            let y = rectIcon.y + rectIcon.height - rectArrow.height / 2;

            arrow.style.top = `${ y }px`;
            arrow.style.left = `${ x }px`;
        },

        resetPositions() {
            if (this.$refs.previewContentWrap) {
                const content = this.$refs.previewContentWrap;
                content.style.left = null;
                content.style.top = null;

                const arrow = this.$refs.arrow;
                arrow.style.top = null;
                arrow.style.left = null;
            }
        },
    },
};
</script>

<style scoped>
.preview-content-wrap {
    position: fixed;
    z-index: var(--preview-z-index);
    transition: opacity var(--transition), transform var(--transition);
}

.arrow {
    position: fixed;
    display: block;
    width: 16px;
    height: 16px;
    border: 8px solid transparent;
    border-bottom-color: var(--light-c);
    z-index: calc(var(--preview-z-index) + 1);
    transition: all var(--transition);
}

.preview-animation-enter,
.preview-animation-leave-to {
    opacity: 0;
    transform: translateY(4px);
}
</style>