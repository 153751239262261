var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopupWrap',{on:{"clickaway":_vm.close,"close":_vm.close}},[_c('div',{staticClass:"popup-content"},[_c('h2',{staticClass:"h2 h2_block popup-title"},[_c('CarName',{attrs:{"car":_vm.selectedCar,"block":false,"noFrame":""}})],1),_vm._v(" "),_c('CarCharacteristicsTable',{attrs:{"car":_vm.selectedCar,"big":""}}),_vm._v(" "),_c('div',{staticClass:"actions"},[(_vm.isAuthorized && !_vm.isSelectedCarFromGarage)?_c('ButtonBlock',{staticClass:"action-btn action-btn_add-garage",attrs:{"primary":"","loading":_vm.addGarageLoading},on:{"click":_vm.addSelectedCarToGarage}},[_vm._v("\n                Добавить в гараж\n            ")]):_vm._e(),_vm._v(" "),_c('ButtonBlock',{staticClass:"action-btn",attrs:{"secondary":""},on:{"click":_vm.showSelectCarPopup}},[_vm._v("\n                Изменить\n            ")]),_vm._v(" "),_c('ButtonBlock',{staticClass:"action-btn",attrs:{"secondary":""},on:{"click":_vm.resetSelectedCar}},[_vm._v("\n                Сбросить\n            ")]),_vm._v(" "),(_vm.isAuthorized && _vm.isSelectedCarFromGarage)?_c('p',{staticClass:"text_secondary in-garage-text"},[_vm._v("\n                Автомобиль добавлен в "),_c('ButtonText',{attrs:{"underline":"","secondary":"","to":{ name: 'account-garage' }}},[_vm._v("гараж")])],1):_vm._e(),_vm._v(" "),(!_vm.isAuthorized)?_c('p',{staticClass:"signin-text"},[_c('UButtonText',{attrs:{"underline":"","primary":""},on:{"click":_vm.openSignInPopup}},[_vm._v("Войдите")]),_vm._v(", чтобы добавить автомобиль в гараж\n            ")],1):_vm._e()],1),_vm._v(" "),(false)?_c('div',{staticClass:"subactions"},[(_vm.$route.name === 'market-parts-catalogs-manufacturer-modification'
                    && _vm.$route.params.manufacturer === _vm.selectedCar.manufacturer.codename
                    && _vm.$route.params.modification === _vm.selectedCar.id)?_c('ButtonText',{attrs:{"dashed":"","secondary":"","dark":""},on:{"click":_vm.close}},[_vm._v("\n                Подобрать запчасти по схемам\n            ")]):_c('ButtonText',{attrs:{"to":{
                    name: 'market-parts-catalogs-manufacturer-modification',
                    params: {
                        manufacturer: _vm.selectedCar.manufacturer.codename,
                        modification: _vm.selectedCar.id
                    },
                },"dashed":"","secondary":"","dark":""}},[_vm._v("\n                Подобрать запчасти по схемам\n            ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }