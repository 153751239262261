<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <FormManager
            ref="form"
            v-model="formData"
            class="popup-content"
            @submit="submit"
        >
            <div class="review-popup__head">
                <h2 class="h2 popup-title">
                    Оставить отзыв
                </h2>

                <p class="popup-subtitle">
                    {{ (part || product).name }}
                </p>
            </div>

            <FormField
                type="rating"
                name="rating"
                label="Общая оценка"
                validations="required"
                offModifyLabel
                class="form-field-gap_bottom"
            ></FormField>

            <div class="bottom-gap_small">
                <h4 class="h4 h4_block review-field__label">
                    Срок использования
                </h4>
                <RadioButtonsGroup
                    v-model="formData.usage_time"
                    name="usage_time"
                    mobileAtomic
                    mobileAtomicFlow
                    :options="[
                        {
                            label: 'Меньше месяца',
                            value: 'less_month'
                        },
                        {
                            label: 'Несколько месяцев',
                            value: 'several_months'
                        },
                        {
                            label: 'Около полугода',
                            value: 'near_year'
                        },
                        {
                            label: 'Больше года',
                            value: 'over_year'
                        },
                    ]"
                ></RadioButtonsGroup>
            </div>

            <FormField
                type="text"
                name="advantages"
                label="Достоинства"
                class="form-field-gap_bottom"
            ></FormField>

            <FormField
                type="text"
                name="disadvantages"
                label="Недостатки"
                class="form-field-gap_bottom"
            ></FormField>

            <FormField
                type="text"
                name="comment"
                label="Комментарий"
                validations="required"
                class="form-field-gap_bottom"
            ></FormField>

            <FormField
                name="photos"
                type="loader"
                label="Фотографии"
                :accept="['jpg', 'jpeg']"
                :maxItems="3"
                class="bottom-gap_small"
                sizeKey="file_size"
                idKey="uuid"
                api="/mediafiles/upload/"
            ></FormField>

            <div>
                <h4 class="h4 h4_block">
                    Порекомендовали бы товар друзьям?
                </h4>
                <RadioButtonsGroup
                    v-model="formData.is_recommend"
                    name="is_recommend"
                    type="text"
                    :options="[
                        {
                            label: 'Да',
                            value: 'true'
                        },
                        {
                            label: 'Нет',
                            value: 'false'
                        },
                    ]"
                ></RadioButtonsGroup>
            </div>

            <div class="form__actions">
                <FormAgreement
                    text="Отправить"
                    class="form__agreement"
                ></FormAgreement>

                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    class="form__submit"
                >
                    {{ edit ? 'Сохранить' : 'Отправить' }}
                </ButtonBlock>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import RadioButtonsGroup from '../RadioButtonsGroup.vue';
import FormAgreement from '../FormAgreement.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';


export default {
    name: 'MarketItemReviewPopup',

    components: {
        ButtonBlock,
        FormField,
        FormManager,
        FormAgreement,
        RadioButtonsGroup,
        PopupWrap,
    },

    mixins: [
        popup,
    ],

    inheritAttrs: false,

    props: {
        part: Object,
        product: Object,
        review: Object,
        edit: Boolean,
    },

    data() {
        return {
            formData: {
                usage_time: 'less_month',
                rating: '',
                advantages: '',
                disadvantages: '',
                comment: '',
                photos: [],
                is_recommend: true,
            },
            loading: false,
        };
    },

    computed: {
        item() {
            return this.part || this.product;
        },
    },

    beforeMount() {
        if (this.edit) {
            this.formData.usage_time = this.review.usage_time;
            this.formData.rating = this.review.rating;
            this.formData.advantages = this.review.advantages;
            this.formData.disadvantages = this.review.disadvantages;
            this.formData.comment = this.review.comment;
            this.formData.photos = this.review.photos;
            this.formData.is_recommend = this.review.is_recommend;
        }
    },

    methods: {
        async submit() {
            const errors = await this.$refs.form.validate();

            if (errors) return;

            this.loading = true;
            const { photos, ...data } = this.formData;
            data.photos = photos.map(photo => photo.uuid);

            try {
                const rq = this.part ? this.$api.parts.reviews : this.$api.products.reviews;

                if (this.edit) {
                    await rq.updateReview(this.item.id, this.review.uuid, data);
                    this.$success('Изменения сохранены');
                }
                else {
                    await rq.addReview(this.item.id, data);
                    this.$success('Отзыв опубликован');
                }

                this.callback(true);
                this.close();
            }
            catch (error) {
                this.$refs.form.handle(error);
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 776px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.popup-subtitle {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .popup-subtitle {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .popup-subtitle {
        margin-top: 4px;
    }
}
@media (max-width: 767px) {
    .popup-subtitle {
        width: var(--col-5);
    }
}

.review-popup__head {
    margin-bottom: var(--gap-y-mini);
}

.form__actions {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .form__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .form__actions {
        margin-top: auto;
    }
}

@media (max-width: 767px) {
    .form__submit {
        width: 100%;
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .form__agreement {
        margin-right: 20px;
    }
}
</style>