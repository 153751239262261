var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desktop-menu"},_vm._l((_vm.menu),function(section,sectionIndex){return _c('ul',{key:'info-page-section-' + sectionIndex,staticClass:"menu-outer-list"},[_c('li',{staticClass:"menu-outer-item"},[_c('h2',{staticClass:"heading"},[_vm._v("\n                "+_vm._s(section.name)+"\n            ")]),_vm._v(" "),_c('ul',_vm._l((section.pages),function(page,pageIndex){return _c('li',{key:'info-page-section-' + sectionIndex + '-page-' + pageIndex,staticClass:"menu-inner-item"},[(page.type === 'static-pages')?_c('RouterLink',{staticClass:"menu-inner-link",attrs:{"exactActiveClass":"menu-inner-link_active","title":page.name,"to":{
                            name: page.entity.codename,
                        },"target":page.target_blank ? '_blank' : '_self'}},[_vm._v("\n                        "+_vm._s(page.name)+"\n                    ")]):_vm._e(),_vm._v(" "),(page.type === 'additional-pages')?_c('RouterLink',{staticClass:"menu-inner-link",attrs:{"exactActiveClass":"menu-inner-link_active","title":page.name,"to":{
                            name: 'additional-page',
                            params: {
                                codename: page.entity.codename,
                            }
                        },"target":page.target_blank ? '_blank' : '_self'}},[_vm._v("\n                        "+_vm._s(page.name)+"\n                    ")]):_vm._e(),_vm._v(" "),(page.type === 'link')?_c('a',{staticClass:"menu-inner-link",attrs:{"href":page.url,"title":page.name,"target":"_blank","rel":"noopener"}},[_vm._v("\n                        "+_vm._s(page.name)+"\n                    ")]):_vm._e()],1)}),0)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }