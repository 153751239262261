import isBrowser from '@/lib/isBrowser.js';
import { YANDEX_METRIKA_ID } from '@/settings.js';


export default function({ next, prev, yandex }) {
    return {
        async back() {
            await this.$router.push({
                name: prev,
                params: {
                    uuid: this.uuid,
                },
                query: this.$route.query,
            });
        },

        async next() {
            const errors = await this.$refs.form.validate();

            if (errors) return;

            if (yandex && isBrowser && window.ym) {
                ym(YANDEX_METRIKA_ID, 'reachGoal', yandex);
            }

            await this.$router.push({
                name: next,
                params: {
                    uuid: this.uuid,
                },
                query: this.$route.query,
            });
        },
    };
}