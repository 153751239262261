<template>
    <UMenu
        ref="menu"
        showOnFocus
        :shift="false"
        placement="bottom"
        :hideOnBlur="false"
        contentWidthByTrigger
        :hideOnClickContent="false"
        class="u-input-date"
    >
        <template #trigger="{ value, attrs, handlers }">
            <UInputField
                ref="input"
                :value="inputValue"
                mask="00.00.0000"
                v-bind="{
                    ...attrs,
                    ...$attrs
                }"
                v-on="{
                    ...handlers,
                    ...listeners
                }"
            >
                <template #append-inner>
                    <UIconButton
                        name="calendar"
                        small
                        secondary
                        class="u-input-date__icon"
                        :class="{
                            'u-input-date__icon_active': value
                        }"
                        @click="onClickIcon"
                    ></UIconButton>
                </template>
            </UInputField>
        </template>

        <template #content>
            <UDatePicker
                :min="min"
                :max="max"
                :value="pickerValue"
                :stepByStep="stepByStep"
                :initialDate="initialDate"
                @click:day="onClickDay"
                @change="onChangePicker"
            ></UDatePicker>
        </template>
    </UMenu>
</template>

<script>
import { dt } from '../../../filters';
import isValidDate from '../../../utils/isValidDate.js';
import UDatePicker from '../../UDatePicker/UDatePicker.vue';
import UInputField from '../UInputField.vue';
import UIconButton from '../../UIconButton/UIconButton.vue';
import UMenu from '../../UMenu/UMenu.vue';


export default {
    name: 'UInputDate',

    components: {
        UMenu,
        UIconButton,
        UInputField,
        UDatePicker,
    },

    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'change:model',
    },

    props: {
        value: String,
        calendar: Boolean,

        // state
        min: [Date, Number, String],
        max: [Date, Number, String],
        initialDate: [Date, String, Number],

        // behaviour
        stepByStep: Boolean,
    },

    data() {
        return {
            inputValue: '',
            pickerValue: null,
        };
    },

    computed: {
        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { input, ...listeners } = this.$listeners;

            return {
                ...listeners,
                input: this.onInput,
            };
        },
    },

    watch: {
        value: {
            handler(value) {
                if (value !== this.inputValue) {
                    this.inputValue = value;
                }
            },

            immediate: true,
        },
    },

    methods: {
        onInput($event) {
            const { value } = $event;
            this.inputValue = value;

            this.emitInput();

            const re = /(\d{2}).(\d{2}).(\d{4})/;

            if (!re.test(value)) return;

            const matched = re.exec(value);
            const day = Number(matched[1]);
            const month = Number(matched[2]) - 1;
            const year = Number(matched[3]);
            this.pickerValue = new Date(year, month, day);
        },

        onChangePicker({ value }) {
            this.pickerValue = value;

            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return;

            this.inputValue = dt(date, 'DD.MM.YYYY');
        },

        onClickDay() {
            this.$refs.menu.hide();
        },

        onClickIcon() {
            this.$refs.input.focus();
        },

        emitInput() {
            const target = this;
            const value = this.inputValue;
            this.$emit('input', { value, target });
        },
    },
};
</script>

<style>
.u-input-date__icon.u-icon-button:hover .u-icon-button__icon,
.u-input-date__icon.u-input-date__icon_active .u-icon-button__icon {
    fill: var(--primary-color);
}
</style>