export default {
    namespaced: true,

    state: () => ({
        products: {
            current_count: 0,
            items: [],
            fetching: false,
        },

        parts: {
            current_count: 0,
            items: [],
            fetching: false,
        },

        shops: {
            current_count: 0,
            items: [],
            fetching: false,
        },

        initialized: false,
    }),

    getters: {
        priorityRoute: state => {
            if (state.products.current_count) {
                return 'account-reviews-products';
            }
            else if (state.parts.current_count) {
                return 'account-reviews-parts';
            }
            else if (state.shops.current_count) {
                return 'account-reviews-shops';
            }
            else {
                return 'account-reviews-products';
            }
        },
    },

    mutations: {
        setProductsFetching(state, value) {
            state.products.fetching = value;
        },

        setPartsFetching(state, value) {
            state.parts.fetching = value;
        },

        setShopsFetching(state, value) {
            state.shops.fetching = value;
        },

        setProducts(state, { results, current_count }) {
            state.products.items = results;
            state.products.current_count = current_count;
        },

        setParts(state, { results, current_count }) {
            state.parts.items = results;
            state.parts.current_count = current_count;
        },

        setShops(state, { results, current_count }) {
            state.shops.items = results;
            state.shops.current_count = current_count;
        },

        addProducts(state, { results, current_count }) {
            state.products.items = [...state.products.items, ...results];
            state.products.current_count = current_count;
        },

        addParts(state, { results, current_count }) {
            state.parts.items = [...state.parts.items, ...results];
            state.parts.current_count = current_count;
        },

        addShops(state, { results, current_count }) {
            state.shops.items = [...state.shops.items, ...results];
            state.shops.current_count = current_count;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.initialized = false;

            state.products = {
                items: [],
                current_count: 0,
            };
            state.parts = {
                items: [],
                current_count: 0,
            };
            state.shops = {
                items: [],
                current_count: 0,
            };
        },
    },

    actions: {
        async init({ commit }) {
            try {
                const params = {
                    limit: 20,
                    offset: 0,
                    order_by: '-create_date',
                };

                const [products, parts, shops] = await Promise.all([
                    this.$api.users.productsReviews.get({ params }),
                    this.$api.users.partsReviews.get({ params }),
                    this.$api.users.shopsReviews.get({ params }),
                ]);

                commit('setProducts', products);
                commit('setParts', parts);
                commit('setShops', shops);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { products, parts, shops };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async fetchProductsReviews({ commit, dispatch }, config) {
            commit('setProductsFetching', true);
            const response = await dispatch('getProductsReviews', config);
            response && commit('setProducts', response);
            commit('setProductsFetching', false);
        },

        async fetchPartsReviews({ commit, dispatch }, config) {
            commit('setPartsFetching', true);
            const response = await dispatch('getPartsReviews', config);
            response && commit('setParts', response);
            commit('setPartsFetching', false);
        },

        async fetchShopsReviews({ commit, dispatch }, config) {
            commit('setShopsFetching', true);
            const response = await dispatch('getShopsReviews', config);
            response && commit('setShops', response);
            commit('setShopsFetching', false);
        },

        async getProductsReviews({ commit }, config) {
            try {
                return await this.$api.users.productsReviews.get(config);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getPartsReviews({ commit }, config) {
            try {
                return await this.$api.users.partsReviews.get(config);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getShopsReviews({ commit }, config) {
            try {
                return await this.$api.users.shopsReviews.get(config);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};