<script>
export default {
    name: 'UHighlight',

    props: {
        queries: [Array, Object],
        text: String,
    },

    methods: {
        genHighlight({ children, tag = 'span', name }) {
            if (!children) return;

            return this.$createElement(tag,
                Object.assign(
                    {
                        class: [
                            // 'u-highlight__item',
                            {
                                ['u-highlight__' + name]: name,
                            },
                        ],
                    },
                    tag === 'a' ? {
                        attrs: {
                            href: children,
                            target: '_blank',
                            title: children,
                        },
                    } : {},
                ),
                children,
            );
        },

        normalizeQueries(queries) {
            // + clone
            if (Array.isArray(queries)) return [...queries.map(q => ({ ...q, indices: [] }))];
            if (typeof queries === 'object') return [{ ...queries, indices: [] }];
            throw new TypeError(`Queries - это ${ typeof queries }. Queries может быть только объектом или массивом`);
        },

        getChildren() {
            if (!this.queries || !this.queries.length) return this.text;
            if (!this.text) return [];

            const original = this.text;
            const queries = this.normalizeQueries(this.queries);
            const root = { children: original, start: 0, end: original.length };

            queries.forEach(query => {
                const { value, name, tag } = query;
                const indices = []; // start end match
                const str = original;
                const re = new RegExp(value, 'gi');
                let matches = re.exec(str);

                // находим координаты всех вхождений
                while (matches) {
                    const start = matches.index;
                    const match = matches[0];
                    const length = match.length;
                    const end = start + length;
                    indices.push({ start, end, match });
                    matches = re.exec(str);
                }

                // рекурсивно создаем детей
                indices.forEach(({ start, end }) => {
                    const modify = obj => {
                        if (obj.start < end && start < obj.end) {
                            if (!obj.children) return;

                            if (Array.isArray(obj.children)) {
                                // копаем глубже
                                obj.children.forEach(child => {
                                    modify(child);
                                });
                            }
                            else if (typeof obj.children === 'string') {
                                // наш пациент
                                const children = [];

                                if (obj.start < start) {
                                    // before
                                    children.push({
                                        start: obj.start,
                                        end: start,
                                        children: original.slice(obj.start, start),
                                    });
                                }

                                children.push({
                                    start: Math.max(start, obj.start),
                                    end: Math.min(end, obj.end),
                                    tag,
                                    name,
                                    children: original.slice(Math.max(start, obj.start), Math.min(end, obj.end)),
                                });

                                if (end < obj.end) {
                                    // after
                                    children.push({
                                        start: end,
                                        end: obj.end,
                                        children: original.slice(end, obj.end),
                                    });
                                }

                                obj.children = children;
                            }
                        }
                    };

                    modify(root);
                });
            });

            const transform = arr => {
                if (!Array.isArray(arr)) return arr;

                return arr.map(obj => {
                    const { tag, name } = obj;
                    const children = transform(obj.children);

                    return this.genHighlight({
                        tag,
                        name,
                        children,
                    });
                });
            };

            return transform(root.children);
        },
    },

    render(h) {
        const children = this.getChildren();

        return h('span', { class: 'u-highlight' }, children);
    },
};
</script>