var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_vm._v(" "),(!_vm.initialized)?_c('USpinner',{attrs:{"big":"","center":""}}):(_vm.categories.current_count)?_c('div',{staticClass:"categories-list"},_vm._l((_vm.categories.items),function(category){return _c('div',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],key:'category-' + category.slug,staticClass:"category layer-1"},[_c('RouterLink',{staticClass:"cover-wrap",attrs:{"to":{
                    name: 'market-products-category',
                    params: {
                        slug: category.slug
                    }
                }}},[_c('img',{staticClass:"cover",attrs:{"data-src":_vm.$links.uploads + category.cover.thumbnails.category_cover_list,"alt":"","loading":"lazy"}})]),_vm._v(" "),_c('div',{staticClass:"category-content"},[_c('h2',{staticClass:"h2 h2_block category__title"},[_c('RouterLink',{staticClass:"link-primary-hover",attrs:{"to":{
                            name: 'market-products-category',
                            params: {
                                slug: category.slug
                            }
                        }}},[_vm._v("\n                        "+_vm._s(category.name)+"\n                    ")])],1),_vm._v(" "),(category.children.length)?_c('ul',{staticClass:"children"},_vm._l((category.children),function(child){return _c('li',{key:'child-' + child.slug,staticClass:"child"},[_c('RouterLink',{staticClass:"child-link link-primary-hover",attrs:{"to":{
                                name: 'market-products-category',
                                params: {
                                    slug: child.slug
                                }
                            }}},[_vm._v("\n                            "+_vm._s(child.name)+"\n                        ")])],1)}),0):_vm._e()])],1)}),0):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-gap_medium"},[_c('h1',{staticClass:"h1 h1_block"},[_vm._v("\n            Каталог автотоваров\n        ")])])}]

export { render, staticRenderFns }