import { render, staticRenderFns } from "./OrdersPreview.vue?vue&type=template&id=2abdd1d6&scoped=true&"
import script from "./OrdersPreview.vue?vue&type=script&lang=js&"
export * from "./OrdersPreview.vue?vue&type=script&lang=js&"
import style0 from "./OrdersPreview.vue?vue&type=style&index=0&id=2abdd1d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2abdd1d6",
  null
  
)

export default component.exports