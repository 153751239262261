import has from '@/lib/has.js';
import genId from '@ui/utils/genId.js';


export default (obj, id) => {
    if (!has(obj, id)) {
        return Object.defineProperty(obj, '__id', Object.assign(Object.create(null), { value: id || genId() }));
        // return obj.__id = id || genId();
    }

    return obj;
};