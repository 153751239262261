var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'notification',
        'notification_' + _vm.position,
        _vm.type === 'cart' ? 'notification_cart' : 'notification_message',
        _vm.show && 'visible' ],on:{"mouseover":_vm.onMouseover,"mouseleave":_vm.onMouseleave}},[(_vm.type === 'cart')?_c('div',{class:[
            'cart-notification',
            'cart-notification_with-' + _vm.cover.classModificator ]},[_c('CloseBtn',{staticClass:"cart-notification__close",attrs:{"size":24,"shadow":false},on:{"click":_vm.close}}),_vm._v(" "),_c('p',{staticClass:"cart-notification__title"},[_vm._v("\n            Товар добавлен в корзину\n        ")]),_vm._v(" "),_c('div',{staticClass:"market-item"},[_c('img',{class:[
                    'market-item__cover',
                    'market-item__cover_' + _vm.cover.classModificator ],attrs:{"src":_vm.cover.src,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"market-item__info"},[_c('p',{staticClass:"market-item__title"},[_vm._v("\n                    "+_vm._s(_vm.item.name)+"\n                ")]),_vm._v(" "),_c('b',{staticClass:"market-item__price"},[_vm._v("\n                    "+_vm._s(_vm._f("price")(_vm.item.price))+"\n                ")])])]),_vm._v(" "),_c('ButtonBlock',{staticClass:"cart-notification__btn",attrs:{"to":{
                name: 'cart'
            },"primary":"","low":""},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v("\n            Перейти в корзину\n        ")])],1):_c('div',{class:[
            'message-notification',
            _vm.type
        ]},[_c('div',{staticClass:"message-notification__text-wrap"},_vm._l((_vm.message),function(item,index){return _c('p',{key:index,staticClass:"message-notification__text"},[_vm._v("\n                "+_vm._s(item)+"\n            ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }