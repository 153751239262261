var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('NavigationTabs',{staticClass:"navigation__btns-wrap",class:{
        'navigation__btns-wrap_small': _vm.small,
        'navigation__btns-wrap_no-desktop-scroll': _vm.noDesktopScroll,
        'navigation__btns-wrap_no-scroll': _vm.noScroll,
    },attrs:{"currentIndex":_vm.currentIndex,"noScroll":_vm.noScroll,"noDesktopScroll":_vm.noDesktopScroll,"noUnderline":_vm.noUnderline}},_vm._l((_vm.items),function(item,index){return _c('RouterLink',{key:'navigation-' + index,staticClass:"link",class:{
            active: _vm.isCurrent(item, index),
            'no-underline': _vm.noUnderline
        },attrs:{"to":item.to}},[_vm._v("\n        "+_vm._s(item.label)+" "),(item.amount)?_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm._f("number")(item.amount)))]):_vm._e()])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }