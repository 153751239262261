import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes.js';
import equals from '../lib/equals.js';


Vue.use(Router);

let router;

export function createRouter(store) {
    router = new Router({
        mode: 'history',
        routes: routes(store),
        scrollBehavior (to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }
            if (to.name !== from.name || !equals(to.params, from.params)) {
                return { x: 0, y: 0 };
            }
            else {
                return undefined;
            }
        },
    });

    router.beforeEach(async (to, from, next) => {
        let e;

        if (!store.state.appInitialized && !store.state.appInitializing) {
            try {
                await store.dispatch('appInit');
            }
            catch (error) {
                e = error;
            }
        }

        if (store.httpErrorCode) {
            store.commit('clearHttpError');
        }

        if (e) {
            next(e);
        }
        else {
            next();
        }
    });

    router.onError(error => {
        const pattern = /Loading chunk (\d)+ failed/g;
        const isChunkLoadFailed = error.message.match(pattern);
        const target = router.history.pending.fullPath;

        if (isChunkLoadFailed) {
            window.location.assign(window.location.origin + target);
        }
    });

    return router;
}

export const safeRouterPush = async location => {
    const toPath = router.resolve(location).route.fullPath;
    const normalize = str => {
        if (!str) return str;

        const lastChar = str[str.length - 1];

        if (lastChar === '/') return str;
        else return str + '/';
    };

    if (normalize(router.currentRoute.fullPath) !== normalize(toPath)) {
        await router.push(location);
    }
};