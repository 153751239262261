<template>
    <FilterWidgetWrap
        codename="rating"
        title="Отзывы с оценкой"
    >
        <ol>
            <li
                v-for="option in config.options"
                :key="'filter-rating-item-' + option.value"
                class="option"
            >
                <CheckboxBase
                    :id="'rating-' + option.value"
                    v-model="valueComputed"
                    :value="option.value"
                    :blocked="option.blocked"
                >
                    <div class="flex">
                        <StarsRating
                            :showValue="false"
                            :value="option.value"
                            :blocked="option.blocked"
                        ></StarsRating>
                        <span class="ml-12">{{ option.label }}</span>
                    </div>
                </CheckboxBase>
            </li>
        </ol>
    </FilterWidgetWrap>
</template>

<script>
import CheckboxBase from './fields/CheckboxBase.vue';
import StarsRating from './StarsRating.vue';
import FilterWidgetWrap from "@/components/FilterWidgetWrap.vue";

export default {
    name: "FilterRating",

    components: {
        FilterWidgetWrap,
        CheckboxBase,
        StarsRating,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },

        config: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        valueComputed: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('change', value);
                this.$emit('reload');
            },
        },
    },
};
</script>

<style scoped>
.option:not(:last-child) {
    margin-bottom: 16px;
}
</style>