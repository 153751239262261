<template>
    <div class="top-gap_medium">
        <h1 class="h1 h1_block">
            Товары для ТО
        </h1>

        <section
            v-if="meta.popular_categories"
            class="bottom-gap_medium"
        >
            <h2 class="h2 h2_block">
                Популярные
            </h2>

            <MarketCatalog
                :categories="meta.popular_categories"
            ></MarketCatalog>
        </section>

        <section v-if="meta.additional_categories">
            <h2 class="h2 h2_block">
                Может потребоваться
            </h2>

            <MarketCatalog
                :categories="meta.additional_categories"
            ></MarketCatalog>
        </section>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import technicalInspections from '@/store/modules/technicalInspections.js';
import MarketCatalog from '@/components/MarketCatalog.vue';

export default {
    name: 'Maintenance',

    components: { MarketCatalog },

    metaInfo() {
        return {
            title: this.meta.meta_title,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content: this.meta.meta_title },
                { property: 'og:description', content: this.meta.meta_description },
                { property: 'og:image', content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        return this.INIT();
    },

    computed: {
        ...mapState({
            initialized: state => state.technicalInspections.initialized,
            meta: state => state.technicalInspections.meta,
        }),
    },

    created() {
        this.$store.registerModule('technicalInspections', technicalInspections);
    },

    async mounted() {
        this.$breadcrumbs([
            {
                to: {
                    name: 'market',
                },
                title: 'Запчасти и автотовары',
            },
            {
                to: {
                    name: 'market',
                },
                title: 'Товары для ТО',
            },
        ]);

        if (!this.initialized) {
            this.INIT();
        }
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('technicalInspections');
    },

    methods: {
        ...mapActions({
            INIT: 'technicalInspections/init',
        }),

        ...mapMutations({
            DESTROY: 'technicalInspections/destroy',
        }),
    },
};
</script>