var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-widget-wrap_outer"},[_c('div',{class:[
            _vm.withAdaptation && 'filter-widget-wrap_desktop'
        ]},[_c('div',{class:[
                'filter-widget-wrap',
                _vm.hasBorder && 'filter-widget-wrap_has-border' ]},[(_vm.title || _vm.canCollapse)?_c('div',{class:[
                    'head',
                    _vm.canCollapse && 'head_clickable' ],on:{"click":_vm.toggleWidget}},[_c('div',{staticClass:"title-hint-wrap"},[_c('h4',{staticClass:"filter-widget-wrap__title"},[_vm._v("\n                        "+_vm._s(_vm.title)+"\n                    ")]),_vm._v(" "),(_vm.hint &&!_vm.isHintEmpty)?_c('UTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                var value = ref.value;
                var attrs = ref.attrs;
                var handlers = ref.handlers;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}}],null,false,752151382)},[_vm._v(" "),_c('WysiwygView',{attrs:{"value":_vm.hint,"smallSize":""}})],1):_vm._e()],1),_vm._v(" "),(_vm.canCollapse)?_c('UIcon',{class:[
                        'icon',
                        _vm.isOpen && 'icon_rotate' ],attrs:{"name":"arrow-down","small":"","secondary":"","hovered":""}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('TransitionExpand',[(_vm.isOpen)?_c('div',{key:"filter-widget",staticClass:"filter-widget__content",class:{
                        'filter-widget__content_without-head': !_vm.title && !_vm.canCollapse,
                    }},[_vm._t("default")],2):_vm._e()])],1)]),_vm._v(" "),(_vm.withAdaptation)?_c('div',{staticClass:"filter-widget-wrap_mobile"},[_c('div',{staticClass:"filter-widget-wrap"},[_c('div',{staticClass:"filter-widget-wrap__main"},[_c('div',{staticClass:"head head_clickable",on:{"click":_vm.openWidget}},[_c('div',[_c('div',{staticClass:"title-hint-wrap"},[_c('h4',{staticClass:"filter-widget-wrap__title"},[_vm._v("\n                                "+_vm._s(_vm.title)+"\n                            ")]),_vm._v(" "),(_vm.hint &&!_vm.isHintEmpty)?_c('UTooltip',{attrs:{"showOnClick":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                    var value = ref.value;
                    var attrs = ref.attrs;
                    var handlers = ref.handlers;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"id":("filter-widget-" + _vm.codename),"active":value}},'UTooltipIcon',attrs,false),handlers))]}}],null,false,3483641481)},[_vm._v(" "),_c('WysiwygView',{attrs:{"value":_vm.hint,"smallSize":""}})],1):_vm._e()],1),_vm._v(" "),(_vm.widget === 'categories')?_c('div',{staticClass:"mt-4"},[_vm._v("\n                            "+_vm._s(_vm.value.id ? _vm.value.name : 'Все автотовары')+"\n                        ")]):_vm._e()]),_vm._v(" "),_c('UIcon',{staticClass:"icon",attrs:{"name":"arrow-right","small":"","secondary":"","hovered":""}})],1),_vm._v(" "),(_vm.hasResetBtn && _vm.value.length)?_c('div',{staticClass:"head__value-wrap"},_vm._l((_vm.value),function(v,index){return _c('ButtonPill',{key:'value-' + index,staticClass:"head__value-btn",on:{"click":function($event){return _vm.$emit('deleteFilter', v)}}},[_vm._v("\n                        "+_vm._s(v.name)+"\n                    ")])}),1):_vm._e()]),_vm._v(" "),_c('Portal',{attrs:{"to":"mobileFilter"}},[_c('transition',{attrs:{"name":"menu-back"}},[(_vm.isOpen)?_c('div',{staticClass:"menu-back"}):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"menu"}},[(_vm.isOpen)?_c('div',{staticClass:"filter-widget-wrap__second"},[_c('div',{staticClass:"second-view__head"},[_c('div',{staticClass:"second-view__head-btn-wrap"},[_c('ButtonIcon',{staticClass:"second-view__head-btn second-view__head-btn_back",attrs:{"secondary":false,"icon":{
                                        name: 'arrow-left',
                                        big: true,
                                    },"type":"button"},on:{"click":_vm.closeFilter}})],1),_vm._v(" "),_c('h3',{staticClass:"h4 text_center ellipsis"},[_vm._v("\n                                "+_vm._s(_vm.title)+"\n                            ")]),_vm._v(" "),_c('div',{staticClass:"second-view__head-btn-wrap second-view__head-btn-wrap_shrink"})]),_vm._v(" "),(_vm.hasResetBtn)?_c('div',{staticClass:"second-view__summary"},[(_vm.value.length)?_c('span',{staticClass:"text_secondary text_semi-bold"},[_vm._v("Выбрано: "+_vm._s(_vm.value.length))]):_c('span',{staticClass:"text_secondary text_semi-bold"},[_vm._v("Ничего не выбрано")]),_vm._v(" "),(_vm.value.length)?_c('ButtonText',{attrs:{"dashed":"","primary":""},on:{"click":_vm.reset}},[_vm._v("Сбросить")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"second-view__content"},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"second-view__footer"},[_c('ButtonBlock',{attrs:{"primary":"","w100":""},on:{"click":_vm.closeFilter}},[_vm._v("\n                                Готово\n                            ")])],1)]):_vm._e()])],1)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }