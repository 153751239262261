<script>
import PostEditorAccordion from './PostEditorAccordion.vue';
import PostEditorCode from './PostEditorCode.vue';
import PostEditorImage from './PostEditorImage.vue';
import PostEditorQuote from './PostEditorQuote.vue';
import PostEditorHr from './PostEditorHr.vue';
import PostEditorText from './PostEditorText.vue';
import PostEditorVideo from './PostEditorVideo.vue';
import PostEditorSlider from './PostEditorSlider.vue';
import PostEditorButton from './PostEditorButton.vue';

const components = {
    accordion: PostEditorAccordion,
    code: PostEditorCode,
    image: PostEditorImage,
    quote: PostEditorQuote,
    hr: PostEditorHr,
    text: PostEditorText,
    video: PostEditorVideo,
    slider: PostEditorSlider,
    button: PostEditorButton,
};

export default {
    name: "PostEditorWidgetSwitcher",

    functional: true,

    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    render(h, context) {
        const widget = context.props.widget;
        const component = components[widget.type];
        return h(
            component,
            {
                props: {
                    widget,
                },
            },
        );
    },
};
</script>