<template>
    <PortalTarget
        id="sticky-sections-holder"
        ref="StickySectionsHolder"
        name="sticky-holder"
        multiple
        class="sticky-sections-holder"
        :class="{
            'sticky-sections-holder_no-shadow': isEmpty
        }"
        @change="handleUpdate"
    >
    </PortalTarget>
</template>

<script>
export default {
    name: 'StickySectionsHolder',

    data() {
        return {
            isEmpty: true,
        };
    },

    methods: {
        handleUpdate(newContent) {
            this.isEmpty = newContent;
            this.$nextTick(() => {
                if (newContent) {
                    const allChildren = document.querySelectorAll('#sticky-sections-holder .sticky-sections-holder__section_visible');
                    const allHeight = [...allChildren].reduce((acc, item) => {
                        acc += item.offsetHeight;
                        return acc;
                    }, 0);
                    document.documentElement.style.setProperty('--StickySectionsHolderHeight', allHeight + 'px');
                }
                else {
                    document.documentElement.style.setProperty('--StickySectionsHolderHeight',  '0px');
                }
            });
        },
    },
};
</script>

<style>
:root {
    --StickySectionsHolderHeight: 0;
}
</style>
<style>
.sticky-sections-holder {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index-sticky-header);
    width: 100%;
    height: var(--StickySectionsHolderHeight);
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
    transition: height var(--transition);
}
.sticky-sections-holder:not(.sticky-sections-holder_no-shadow)::after {
    content: "";
    display: block;
    box-shadow: var(--base-shadow);
}

.sticky-sections-holder__section_visible ~ .sticky-sections-holder__section {
    border-top: 1px solid var(--border-light-c);
}
</style>