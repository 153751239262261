export default () => {
    return {
        // TODO: Серверное время
        // +4
        minActionStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 4),
        // +40 or diagnostic_card_exp
        // maxActionStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 40),

        formData: {
            action_start_date: null,
            action_end_date: null,
        },

        keys: [
            'action_start_date',
            'action_end_date',
        ],
    };
};