<template>
    <PopupWrap
        verticalCenterNotXS
        @close="close"
        @clickaway="close"
    >
        <div class="popup-content">
            <div class="content">
                <h2 class="h2 h2_block popup-title">
                    Узнать стоимость и срок доставки
                </h2>

                <UDropdown
                    ref="dropdown"
                    :options="searchOptions"
                    :initialized="searchInitialized"
                    class="dropdown"
                    @select="onSelectCity"
                >
                    <template #default="{ handlers }">
                        <UInputSearch
                            :value="searchValue"
                            :loading="searching"
                            label="Куда доставить?"
                            placeholder="Город, посёлок или деревня"
                            :clearInputOnFocus="!!currentCity"
                            autocomplete="off"
                            @blur="handlers.blur(); searchInitialized = false"
                            @focus="handlers.focus(); $nextTick(onFocus)"
                            @input="onChangeSearchValue"
                            @keydown="handlers.keydown"
                            @click:clear="onClear"
                        ></UInputSearch>
                    </template>

                    <template #label="{ option }">
                        <p class="ellipsis">
                            {{ option.full_name }}
                        </p>
                        <p class="option-description ellipsis">
                            <span
                                v-if="option.region"
                            >{{ option.region }}</span><span
                                v-if="option.region && option.district"
                            >,</span>
                            <span v-if="option.district">{{ option.district }}</span>
                        </p>
                    </template>
                </UDropdown>

                <div
                    v-if="offer"
                    class="offer top-gap_small"
                >
                    <MarketItemCover
                        noLink
                        :item="offer[modelName]"
                        coverCodename="micro"
                        class="offer__cover"
                    ></MarketItemCover>

                    <p class="offer__title">
                        {{ offer[modelName].name }}
                    </p>

                    <Counter
                        v-model="offerCounter"
                        :min="1"
                        :max="99"
                        class="offer__counter"
                        @change="getDeliveryPrice"
                    ></Counter>
                </div>
                <p
                    v-if="offer"
                    class="mt-8 text_secondary text_small-fz"
                >
                    Количество товара влияет на&nbsp;стоимость и срок доставки.
                </p>

                <TransitionExpand>
                    <div
                        v-if="currentCity"
                        key="delivery-data"
                        class="top-gap_small"
                    >
                        <div
                            v-for="item in transportPrices"
                            class="transport"
                            :class="{ 'muted-block': item.disabled }"
                        >
                            <img
                                :src="item.logo"
                                alt=""
                                class="transport__logo"
                            >
                            <div class="transport__main">
                                <b class="transport__name">{{ item.name }}</b>

                                <div class="transport__price-wrap">
                                    <Spinner
                                        v-if="item.loading"
                                        class="transport__spinner"
                                    ></Spinner>

                                    <span
                                        v-else
                                        class="transport__price"
                                    >
                                        <template v-if="item.disabled">
                                            Расчёт недоступен
                                        </template>
                                        <template v-else>
                                            от {{ item.price | price }}
                                            / {{ item.days === 1 ? '1 день' : 'до ' + item.days + ' дней' }}
                                        </template>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <InfoMessage
                            v-if="somePrices"
                            class="mt-20"
                        >
                            Расчёт является предварительным.
                            Выбрать службу доставки и&nbsp;узнать окончательную стоимость и&nbsp;сроки
                            вы&nbsp;сможете после оплаты, подтверждения и&nbsp;комплектации заказа,
                            в&nbsp;момент оформления доставки.
                        </InfoMessage>
                    </div>
                </TransitionExpand>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import { HTTP } from '@/http.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import Spinner from '../Spinner.vue';
import InfoMessage from '../InfoMessage.vue';
import TransitionExpand from '../_transitions/TransitionExpand.vue';
import UDropdown from '@/components/UDropdownDeprecated/UDropdownDeprecated.vue';
import UInputSearch from '@/components/InputSearch.vue';
import MarketItemCover from '@/components/MarketItemCover.vue';
import Counter from '@/components/Counter.vue';


export default {
    name: 'PrecalcCountryDeliveryPopup',

    components: {
        Counter,
        MarketItemCover,
        UInputSearch,
        UDropdown,
        TransitionExpand,
        InfoMessage,
        Spinner,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        shop: {
            type: Object,
            default: () => {},
        },

        offer: {
            type: Object,
            default: () => {},
        },

        order: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            searchValue: '',
            searchOptions: [],
            searchSource: null,
            searchTimeout: null,
            searching: false,
            searchInitialized: false,
            confirming: false,
            currentCity: null,
            fetchingCurrentCity: false,

            transportPrices: {},
            getDeliveryPriceLoading: true,

            offerCounter: 1,
        };
    },

    computed: {
        somePrices() {
            return Object.values(this.transportPrices).some(item => !item.disabled && !item.loading);
        },

        modelName() {
            return this.offer
                ? this.offer.product
                    ? 'product'
                    : this.offer.part_product
                        ? 'part_product'
                        : 'contract_part'
                : false;
        },

        orderTotalWeight() {
            if (this.order) {
                return this.order.positions.reduce((acc, position) => {
                    acc += (position.offer.product || position.offer.part_product).weight;
                    return acc;
                }, 0);
            }
            else if (this.offer) {
                return this.offer[this.modelName].weight;
            }
            return 0;
        },
    },

    created() {
        this.getTransports();
    },

    beforeDestroy() {
        clearTimeout(this.searchTimeout);
        if (this.searchSource) this.searchSource.cancel();
    },

    methods: {
        onSelectCity({ value: option }) {
            this.currentCity = option;
            this.searchValue = option.full_name;
            this.getDeliveryPrice();
        },

        onChangeSearchValue({ value }) {
            if (value === this.searchValue) return;

            this.searchValue = value;
            this.setFetchCitiesTimeout();
            this.currentCity = null;
        },

        onClear() {
            this.searchInitialized = false;

            // ждём, пока не закончится анимация
            setTimeout(() => {
                this.searchOptions = [];
            }, 200);
        },

        onFocus() {
            if (this.currentCity || !this.searchValue) return;
            this.fetchCities();
        },

        async fetchCities() {
            if (this.searchSource) {
                this.searchSource.cancel();
                this.searchSource = null;
            }

            const search = this.searchValue;

            if (!search || search.length < 2) return;

            this.searching = true;
            const limit = 6;
            const params = { search, limit };
            this.searchSource = HTTP.CancelToken.source();
            const cancelToken = this.searchSource.token;
            const config = { params, cancelToken };

            try {
                const { results } = await this.$api.cities.get(config);

                this.searchOptions = results;
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;

                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.searching = false;

                // ждём, пока options обновится в UDropdown
                // чтобы корректно отработала анимация
                this.$nextTick(() => {
                    this.searchInitialized = true;
                });
            }
        },

        async setFetchCitiesTimeout() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }

            if (this.searchValue) {
                this.searchTimeout = setTimeout(() => {
                    this.fetchCities();
                }, 500);
            }
            else {
                if (this.searchSource) {
                    this.searchSource.cancel();
                    this.searchSource = null;
                }
            }
        },

        getTransports() {
            const transports = {};
            if (this.shop.is_delivery_pochtaru) {
                transports.pochtaru = {
                    codename: 'pochtaru',
                    name: 'Почта России',
                    logo: require('../../assets/images/pochtaru-logo.png'),
                    price: null,
                    days: null,
                    disabled: false,
                    loading: true,
                };
            }
            if (this.shop.is_delivery_cdek) {
                transports.cdek = {
                    codename: 'cdek',
                    name: 'СДЭК',
                    logo: require('../../assets/images/cdek-logo.png'),
                    price: null,
                    days: null,
                    disabled: false,
                    loading: true,
                };
            }
            this.transportPrices = transports;
        },

        getDeliveryPrice() {
            if (this.currentCity) {
                Object.values(this.transportPrices).forEach(item => {
                    item.loading = true;
                    let response = {};
                    const data = {
                        service: item.codename,
                        shop_from: this.shop.id,
                        city_from: this.shop.city.id,
                        city_to: this.currentCity.id,
                        weight: this.orderTotalWeight,
                    };
                    this.$api.delivery.estimate.byCity(data)
                        .then(res => {
                            response = {
                                price: res.total_price,
                                days: res.days_max,
                                disabled: false,
                            };
                            if (!res.tariff_code) response.disabled = true;
                        })
                        .catch(() => {
                            response = {
                                price: null,
                                days: null,
                                disabled: true,
                            };
                        })
                        .finally(() => {
                            response.loading = false;
                            this.transportPrices[item.codename] = Object.assign(this.transportPrices[item.codename], response);
                        });
                });
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        position: relative;
        width: 676px;
    }
}

.dropdown >>> .u-dropdown__options-list {
    max-height: 320px;
}

.option-description {
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
    line-height: 1.33;
}

.transport {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .transport:not(:last-child) {
        margin-bottom: 12px;
    }
}
@media (max-width: 767px) {
    .transport:not(:last-child) {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) {
    .transport__main {
        display: flex;
        align-items: flex-end;
        flex-grow: 1;
    }

    .transport__main::after {
        content: '';
        order: 2;
        flex-grow: 1;
        border-bottom: 1px dotted var(--border-dark-c);
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 4px;
    }
}

.transport__name {
    display: flex;
    align-items: center;
    order: 1;
    flex-shrink: 0;
}

.transport__logo {
    width: 60px;
    height: 40px;
    flex-shrink: 0;
    margin-right: var(--gap-micro);
}

.transport__name {
    font-family: var(--f-semi-bold);
}

@media (min-width: 768px) {
    .transport__price-wrap {
        flex-shrink: 0;
        order: 3;
        font-family: var(--f-bold);
    }
}
@media (max-width: 767px) {
    .transport__price-wrap {
        margin-top: 4px;
    }
}

.offer {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius-x2);
}
@media (max-width: 767px) {
    .offer {
        flex-wrap: wrap;
    }
}

.offer__cover {
    margin-right: 20px;
}

.offer__title {
    font-family: var(--f-bold);
    flex-grow: 1;
}
@media (max-width: 767px) {
    .offer__title {
        max-width: calc(100% - 20px - 48px);
    }
}

@media (min-width: 768px) {
    .offer__counter {
        margin-left: 20px;
    }
}
@media (max-width: 767px) {
    .offer__counter {
        margin-top: 16px;
    }
}
</style>