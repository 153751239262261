<template>
    <div class="market-item-row">
        <MarketItemCover
            :item="item"
            :modelName="modelName"
            coverCodename="small"
            class="cover-wrap"
        ></MarketItemCover>

        <div class="market-item-row__main-info">
            <slot name="name">
                <MarketItemName
                    :id="item.id"
                    :modelName="modelName"
                    :name="item.name"
                    class="market-item-row__name"
                ></MarketItemName>
            </slot>

            <p class="market-item-row__addresses_amount">
                {{ item.offers_amount }}
                {{ item.offers_amount | plural(['пункт выдачи', 'пункта выдачи', 'пунктов выдачи']) }}
            </p>
        </div>
    </div>
</template>

<script>
import MarketItemName from './MarketItemName.vue';
import MarketItemCover from './MarketItemCover.vue';

export default {
    name: 'MarketItemInOffersPopup',

    components: {
        MarketItemCover,
        MarketItemName,
    },

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },
    },
};
</script>

<style scoped>
.market-item-row {
    display: flex;
    align-items: center;
    padding: 20px var(--popup-paddings-x) 20px var(--popup-paddings-x);
    border-bottom: 1px solid var(--border-light-c);
}

.cover-wrap {
    margin-right: var(--gap-micro);
    align-self: flex-start;
    flex-shrink: 0;
}

.market-item-row__name {
    font-size: var(--h3-fz);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .market-item-row__name {
        margin-right: 16px;
    }
}
@media (max-width: 767px) {
    .market-item-row__name {
        margin-right: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.market-item-row__addresses_amount {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .market-item-row__addresses_amount {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .market-item-row__addresses_amount {
        margin-top: 4px;
    }
}
</style>
