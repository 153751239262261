<template>
    <PopupWrap
        :loading="loading"
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="main">
                <img
                    srcset="../../assets/images/authorization-popup-picture-2x.jpg 2x,
                    ../../assets/images/authorization-popup-picture.jpg"
                    src="../../assets/images/authorization-popup-picture.jpg"
                    width="260"
                    height="200"
                    class="popup-decor"
                    @load="loading = false"
                >

                <h2 class="h2">
                    <slot name="title">
                        {{ typeText.title }}
                    </slot>
                </h2>

                <p class="text">
                    <slot name="text">
                        {{ typeText.text }}
                    </slot>
                </p>
            </div>

            <div class="footer">
                <ButtonBlock
                    :to="{
                        name: 'signin'
                    }"
                    primary
                    class="action-btn"
                >
                    Войти
                </ButtonBlock>

                <p class="text">
                    Нет учётной записи?
                    <ButtonText
                        :to="{
                            name: 'signup'
                        }"
                        primary
                        underline
                    >
                        Зарегистрироваться
                    </ButtonText>
                </p>
            </div>

        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';


export default {
    name: 'AuthorizationPopup',

    components: { ButtonText, ButtonBlock, PopupWrap },

    mixins: [popup],

    props: {
        place: {
            type: String,
            default: 'parts-request',
        },
    },

    data() {
        return {
            loading: true,
        };
    },

    computed: {
        typeText() {
            const textMap = {
                'parts-request': {
                    title: 'Авторизуйтесь',
                    text: 'Пользоваться сервисом по подбору контрактных автозапчастей могут только авторизованные пользователи.',
                },
                'cart': {
                    title: 'Войдите, чтобы оформить заказ',
                    text: 'А ещё, вы сможете оставлять отзывы,  добавлять товары в избранное и общаться с магазинами в чате.',
                },
            };
            return textMap[this.place];
        },
    },
};
</script>

<style scoped>
.popup-content {
    text-align: center;
}
@media (min-width: 768px) {
    .popup-content {
        width: 576px;
    }
}

@media (max-width: 767px) {
    .main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.footer {
    margin-top: var(--gap-main-action);
}
@media (max-width: 767px) {
    .footer {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }
}

.popup-decor {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--gap-y-mini);
    height: auto;
}
@media (min-width: 376px) {
    .popup-decor {
        max-width: 260px;
    }
}
@media (max-width: 375px) {
    .popup-decor {
        max-width: 200px;
    }
}

.text {
    line-height: var(--base-lh);
}

@media (min-width: 768px) {
    .action-btn {
        margin-bottom: 16px;
    }
}
@media (max-width: 767px) {
    .action-btn {
        width: 100%;
        margin-top: 16px;
    }
}
</style>