<template>
    <NavigationTabs
        class="navigation__btns-wrap"
        :class="{
            'navigation__btns-wrap_small': small,
            'navigation__btns-wrap_no-desktop-scroll': noDesktopScroll,
            'navigation__btns-wrap_no-scroll': noScroll,
        }"
        :currentIndex="currentIndex"
        :noScroll="noScroll"
        :noDesktopScroll="noDesktopScroll"
        :noUnderline="noUnderline"
    >
        <RouterLink
            v-for="(item, index) in items"
            :key="'navigation-' + index"
            :to="item.to"
            class="link"
            :class="{
                active: isCurrent(item, index),
                'no-underline': noUnderline
            }"
        >
            {{ item.label }} <span
                v-if="item.amount"
                class="amount"
            >{{ item.amount | number }}</span>
        </RouterLink>
    </NavigationTabs>
</template>

<script>
import equals from '@/lib/equals.js';
import breakpointKey from '@/mixins/breakpointKey.js';
import NavigationTabs from '@/components/NavigationTabs.vue';

export default {
    name: 'MarketItemNavigation',

    components: { NavigationTabs },

    mixins: [breakpointKey],

    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },

        small: {
            type: Boolean,
            default: false,
        },

        noUnderline: {
            type: Boolean,
            default: false,
        },

        noScroll: Boolean,
        noDesktopScroll: Boolean,
    },

    data() {
        return {
            currentIndex: 0,
        };
    },

    methods: {
        isCurrent(item, index) {
            if (item.nested) {
                let inNested = item.nested.find(route => this.$route.name === route);
                const isCurrent = (item.to.name === this.$route.name) || inNested;
                if (isCurrent) {
                    this.currentIndex = index;
                }
                return isCurrent;
            }

            if (item.to.params) {
                const isCurrent = item.to.name === this.$route.name
                    && equals(item.to.params, this.$route.params);
                if (isCurrent) {
                    this.currentIndex = index;
                }
                return isCurrent;
            }

            const isCurrent = item.to.name === this.$route.name;
            if (isCurrent) {
                this.currentIndex = index;
            }
            return isCurrent;
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .navigation__btns-wrap >>> .navigation::before,
    .navigation__btns-wrap >>> .navigation::after {
        flex: 0 0 36px;
        content: "";
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .navigation__btns-wrap >>> .navigation::before,
    .navigation__btns-wrap >>> .navigation::after {
        flex: 0 0 24px;
        content: "";
    }
}
@media (max-width: 767px) {
    .navigation__btns-wrap >>> .navigation::before,
    .navigation__btns-wrap >>> .navigation::after {
        flex: 0 0 16px;
        content: "";
    }
}

.navigation__btns-wrap_no-scroll >>> .navigation::before,
.navigation__btns-wrap_no-scroll >>> .navigation::after {
    content: none;
}
@media (min-width: 768px) {
    .navigation__btns-wrap_no-desktop-scroll >>> .navigation::before,
    .navigation__btns-wrap_no-desktop-scroll >>> .navigation::after {
        content: none;
    }
}

.link {
    position: relative;
    display: flex;
    align-items: baseline;
    padding-top: 14px;
    padding-bottom: 14px;
    color: var(--font-secondary-color);
    font-family: var(--f-bold);
    white-space: nowrap;
}

.link.no-underline {
    padding-top: 0;
    padding-bottom: 0;
}

@media (min-width: 768px) {
    .link {
        font-size: 16px;
    }

    .link:not(:last-child) {
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .link:not(:last-child) {
        margin-right: 20px;
    }
}

.link.active,
.link:hover,
.link:focus {
    color: var(--dark-c);
}

.amount {
    margin-left: 8px;
    font-size: calc(100% - 2px);
}

@media (min-width: 1040px) {
    .navigation__btns-wrap_small .link:not(:last-child) {
        margin-right: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .navigation__btns-wrap_small .link:not(:last-child) {
        margin-right: 24px;
    }
}

.navigation__btns-wrap_small .link {
    font-size: 14px;
}
</style>