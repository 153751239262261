<template>
    <PopupWrap
        :loading="initLoading"
        @clickaway="handleClose"
        @close="handleClose"
    >
        <div
            v-if="!initLoading"
            class="popup-content"
            :class="{
                'short-scheme-popup-layout': catalogSchemesCount > 1,
            }"
        >
            <template v-if="!catalogSchemesCount">
                <h4 class="h4 text_center">
                    Ничего не найдено
                </h4>
            </template>

            <template v-if="catalogSchemesCount">
                <div
                    v-show="!selectSchemeMode"
                    class="main"
                >
                    <div class="head">
                        <BackLink
                            v-if="catalogSchemesCount > 1"
                            class="back-to-schemes"
                            @click="backToSchemes"
                        >Вернуться к схемам</BackLink>

                        <h2
                            v-if="part.name"
                            class="h2 popup-title"
                        >
                            {{ part.name }}
                        </h2>

                        <div class="actions">
                            <ButtonText
                                v-if="manufacturerCodename && partOnScheme.groups && carId"
                                :to="{
                                    name: 'market-parts-catalogs-manufacturer-modification',
                                    params: {
                                        manufacturer: manufacturerCodename,
                                        modification: carId,
                                        group: partOnScheme.groups[partOnScheme.groups.length-1].id,
                                    },
                                    query: Object.assign({
                                        s: currentScheme.id,
                                        part_id: part.part_id,

                                    }, carProductionDate ? {
                                        production_date: carProductionDate
                                    } : {})
                                }"
                                underline
                                primary
                                class="to-catalog-btn"
                            >Показать схему в каталоге {{ (car.manufacturer || {}).name || '' }}</ButtonText>
                            <ButtonBlock
                                :to="{
                                    name: 'market-parts-search',
                                    query: {
                                        part_product: partId,
                                    }
                                }"
                                primary
                                low
                                class="to-price-btn"
                                @click.native="close"
                            >
                                Показать цены
                            </ButtonBlock>
                        </div>
                    </div>

                    <div class="content">
                        <div
                            v-if="currentScheme.image"
                            class="scheme-wrap"
                        >
                            <div
                                ref="imageInnerWrap"
                                class="scheme-inner-wrap"
                            >
                                <Spinner
                                    v-show="!schemeImageLoad || !partOnSchemeLoaded"
                                    absoluteCenter
                                ></Spinner>

                                <img
                                    ref="imageDefault"
                                    :key="'imageDefault-' + currentScheme.image"
                                    :src="$links.uploads + currentScheme.image"
                                    alt=""
                                    class="scheme-img scheme-img_default"
                                >
                                <img
                                    ref="image"
                                    :key="'image-' + currentScheme.image"
                                    :src="$links.uploads + currentScheme.image"
                                    alt=""
                                    class="scheme-img scheme-img_zoom"
                                    :class="{
                                        'scheme-img_hidden': !partOnSchemeLoaded,
                                        'scheme-img_zoomer': schemeZoomValue > 0,
                                    }"
                                    :style="schemeImageStyles"
                                    @load="handleSchemeImageLoad"
                                    @mousedown="onSchemeImageMouseDown"
                                >

                                <template v-if="schemeImageLoad && partOnSchemeLoaded">
                                    <span
                                        v-for="(coordinates, coordinates_index) in partOnScheme.coordinates"
                                        :key="`${ coordinates_index }-${ coordinates.y1 }`"
                                        :style="partsOnSchemeStyles[coordinates_index]"
                                        class="scheme-node-point"
                                        :class="{
                                            'scheme-node-point-wrap_moving': moving,
                                        }"
                                    ></span>
                                </template>
                            </div>

                            <ZoomControl
                                ref="zoomControl"
                                class="scheme-zoom-control"
                                @change="updateImageZoom"
                            ></ZoomControl>
                        </div>
                    </div>
                </div>

                <template v-if="catalogSchemesCount > 1">
                    <h2
                        class="h2 h2_text schemes-list__title"
                        :class="{
                            'schemes-list__title_hidden': !selectSchemeMode,
                        }"
                    >
                        Выберите схему
                    </h2>

                    <div
                        class="schemes-list"
                        :class="{
                            'schemes-list_hidden': !selectSchemeMode,
                        }"
                    >
                        <div
                            v-if="(breakpointKey === 'xl' || breakpointKey === 'l') && canScrollTop"
                            class="navigation__arrow_prev-wrap"
                            @mouseenter="startScroll('top')"
                            @mouseleave="stopScroll"
                        >
                            <RoundArrowBtn
                                direction="up"
                                size="48"
                                shadow
                                class="navigation__arrow navigation__arrow_prev"
                            ></RoundArrowBtn>
                        </div>

                        <div
                            ref="list"
                            class="schemes-list_inner"
                            @scroll="checkScroll"
                        >
                            <div
                                v-for="(scheme, index) in catalogSchemes"
                                :key="scheme.id + index"
                                class="schemes-list__item"
                                :class="{
                                    'schemes-list__item_current': scheme.id === currentScheme.id,
                                }"
                            >
                                <SchemeGridItem
                                    :scheme="scheme"
                                    @schemeClick="handleSchemeClick(scheme)"
                                ></SchemeGridItem>
                            </div>
                        </div>

                        <div
                            v-if="(breakpointKey === 'xl' || breakpointKey === 'l') && canScrollBottom"
                            class="navigation__arrow_next-wrap"
                            @mouseenter="startScroll('bottom')"
                            @mouseleave="stopScroll"
                        >
                            <RoundArrowBtn
                                direction="down"
                                size="48"
                                shadow
                                class="navigation__arrow navigation__arrow_next"
                            ></RoundArrowBtn>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </PopupWrap>
</template>

<script>
import throttle from '@/lib/throttle.js';
import breakpointKey from '@/mixins/breakpointKey.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import SchemeGridItem from '@/components/SchemeGridItem.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import BackLink from '@/components/project-buttons/BackLink.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import Spinner from '@/components/Spinner.vue';
import ZoomControl from '@/components/ZoomControl.vue';
import RoundArrowBtn from '@/components/project-buttons/RoundArrowBtn.vue';

let shiftX = 0;
let shiftY = 0;
export default {
    name: "ShortSchemePopup",

    components: {
        RoundArrowBtn,
        ZoomControl,
        Spinner,
        ButtonBlock,
        BackLink,
        ButtonText,
        SchemeGridItem,
        PopupWrap,
    },

    mixins: [popup, breakpointKey],

    props: {
        partId: {
            type: [Number, String],
            default: '',
        },

        part: {
            type: Object,
            default() {
                return {};
            },
        },

        car: {
            type: Object,
            default() {
                return {};
            },
        },

        carId: {
            type: [Number, String],
            default: '',
        },

        item: {
            type: Object,
            default() {
                return {
                    // groups: [],
                };
            },
        },

        manufacturerCodename: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            initLoading: true,
            catalogSchemesCount: 0,
            catalogSchemes: [],
            currentScheme: {},
            partOnScheme: {},
            schemeImageLoad: false,
            imageScale: '',
            imageOffsetLeft: '',
            imageOffsetTop: '',
            selectSchemeMode: false,
            partOnSchemeLoaded: false,

            originalImageWidth: 0,
            originalImageHeight: 0,
            schemeImageStyles: {},
            schemeZoomValue: 0,
            partsOnSchemeStyles: [],
            moving: false,

            scrollInterval: null,
            canScrollTop: false,
            canScrollBottom: false,
        };
    },

    computed: {
        carProductionDate() {
            if (this.car.prod_date) {
                let str = this.car.prod_date.year;
                if (this.car.prod_date.month) {
                    str += '-';
                    str += this.car.prod_date.month.toString().padStart(2, '0');

                    if (this.car.prod_date.day) {
                        str += '-';
                        str += this.car.prod_date.day.toString().padStart(2, '0');
                    }
                }
                return str;
            }
            else {
                return '';
            }
        },
    },

    async mounted() {
        await this.getSchemes();
        if (this.catalogSchemesCount > 1) {
            if (window.innerWidth < 1040) this.selectSchemeMode = true;
            window.addEventListener('resize', this.handleResize);
        }
    },

    beforeDestroy() {
        clearInterval(this.scrollInterval);
        window.removeEventListener('resize', this.handleResize);
        if (this.$refs.image) {
            this.$refs.image.removeEventListener('touchstart', this.onSchemeImageTouchStart);
            this.$refs.image.removeEventListener('touchmove', this.onSchemeImageTouchMove);
            this.$refs.image.removeEventListener('touchend', this.onSchemeImageTouchEnd);
        }
    },

    methods: {
        handleSchemeImageLoad() {
            this.schemeImageLoad = true;
            this.originalImageWidth = this.$refs.image.naturalWidth;
            this.originalImageHeight = this.$refs.image.naturalHeight;
            this.calcPartsOnSchemeStyles();
            this.$refs.image.addEventListener('touchstart', this.onSchemeImageTouchStart);
            this.$refs.image.addEventListener('touchmove', this.onSchemeImageTouchMove);
            this.$refs.image.addEventListener('touchend', this.onSchemeImageTouchEnd);
        },

        async getSchemes() {
            this.schemeImageLoad = false;

            const params = {
                car_id: this.carId,
                part_product_id: this.partId,
                order_by: '-start_at',
            };

            await this.$api.catalogSchemes.get({ params })
                .then(({ current_count, results }) => {
                    this.catalogSchemesCount = current_count;
                    this.catalogSchemes = results;
                    if (this.catalogSchemesCount === 1) {
                        this.currentScheme = results[0];
                        this.getSchemePart();
                    }
                    else if (this.catalogSchemesCount > 1 && window.innerWidth >= 1040) {
                        this.currentScheme = results[0];
                        this.getSchemePart();
                    }
                    else {
                        this.partOnSchemeLoaded = true;
                        this.initLoading = false;
                        this.$nextTick(() => {
                            this.checkScroll();
                        });
                    }
                })
                .catch(e => {
                    console.log('getSchemes error:', e);
                });
        },

        getSchemePart() {
            const params = {
                part_product_id__in: this.partId,
                car_id: this.carId,
                scheme_id: this.currentScheme.id,
                order_by: 'number',
            };
            this.partOnSchemeLoaded = false;

            this.$api.catalogSchemeParts.get({ params })
                .then(response => {
                    if (response.results.length) {
                        this.partOnScheme = response.results[0];
                        this.calcPartsOnSchemeStyles();
                    }
                })
                .finally(() => {
                    this.partOnSchemeLoaded = true;
                    this.initLoading = false;
                    this.$nextTick(() => {
                        this.checkScroll();
                    });
                });
        },

        handleSchemeClick(scheme) {
            if (this.$refs.zoomControl) this.$refs.zoomControl.reset();
            if (this.currentScheme.image !== scheme.image) {
                this.schemeImageLoad = false;
            }
            this.currentScheme = scheme;
            this.selectSchemeMode = false;
            this.getSchemePart();
        },

        handleResize() {
            this.calcPartsOnSchemeStyles();

            if (window.innerWidth >= 1040) {
                this.selectSchemeMode = false;
                if (!this.currentScheme.id) {
                    this.currentScheme = this.catalogSchemes[0];
                    this.getSchemePart();
                }
                this.checkScroll();
            }

            this.schemeImageStyles = {
                'max-width': '100%',
                'max-height': '100%',
                'width': 'auto',
                'height': 'auto',
            };
        },

        calcPartsOnSchemeStyles() {
            if (this.schemeImageLoad) {
                this.partsOnSchemeStyles = [];

                const imageScale = this.$refs.image.offsetWidth / this.currentScheme.width;
                const imageOffsetLeft = this.$refs.image.offsetLeft;
                const imageOffsetTop = this.$refs.image.offsetTop;

                if (this.partOnScheme.coordinates && this.partOnScheme.coordinates.length) {
                    this.partOnScheme.coordinates.forEach((coordinates) => {
                        this.partsOnSchemeStyles.push({
                            top: coordinates.y1 * imageScale + imageOffsetTop + 'px',
                            left: coordinates.x1 * imageScale + imageOffsetLeft + 'px',
                            width: (coordinates.x2 - coordinates.x1) * imageScale + 'px',
                            height: (coordinates.y2 - coordinates.y1) * imageScale + 'px',
                        });
                    });
                }
            }
        },

        handleClose() {
            this.close();
            this.callback();
        },

        backToSchemes() {
            this.selectSchemeMode = true;
            this.currentScheme = {};
        },

        updateImageZoom(value) {
            this.schemeZoomValue = value;
            let styles = {};
            if (value === 0) {
                styles = {
                    'max-width': '100%',
                    'max-height': '100%',
                    'top': null,
                    'left': null,
                    'width': 'auto',
                    'height': 'auto',
                };
            }
            else {
                styles = {
                    'max-width': 'initial',
                    'max-height': 'initial',
                };

                const imageWrapRect = this.$refs.imageInnerWrap.getBoundingClientRect();
                const imageRect = this.$refs.image.getBoundingClientRect();
                const widthDiff = this.originalImageWidth - this.$refs.imageDefault.offsetWidth;
                const widthValue = widthDiff * value / 100 + this.$refs.imageDefault.offsetWidth;
                styles.width = widthValue + 'px';

                const heightDiff = this.originalImageHeight - this.$refs.imageDefault.offsetHeight;
                const heightValue = heightDiff * value / 100 + this.$refs.imageDefault.offsetHeight;
                styles.height = heightValue + 'px';

                let newTopValue = (imageRect.height - heightValue) / 2 + this.$refs.image.offsetTop;
                if (heightValue > imageWrapRect.height) {
                    if (newTopValue > 0) {
                        newTopValue = 0;
                    }
                    else if (Math.abs(newTopValue) > heightValue - imageWrapRect.height) {
                        newTopValue = imageWrapRect.height - heightValue;
                    }
                }
                else {
                    if (newTopValue < 0) {
                        newTopValue = 0;
                    }
                    else if (newTopValue + heightValue > imageWrapRect.height) {
                        newTopValue = imageWrapRect.height - heightValue;
                    }
                }
                styles.top = newTopValue + 'px';

                let newLeftValue = (imageRect.width - widthValue) / 2 + this.$refs.image.offsetLeft;
                if (widthValue > imageWrapRect.width) {
                    if (newLeftValue > 0) {
                        newLeftValue = 0;
                    }
                    else if (Math.abs(newLeftValue) > widthValue - imageWrapRect.width) {
                        newLeftValue = imageWrapRect.width - widthValue;
                    }
                }
                else {
                    if (newLeftValue < 0) {
                        newLeftValue = 0;
                    }
                    else if (newLeftValue + widthValue > imageWrapRect.width) {
                        newLeftValue = imageWrapRect.width - widthValue;
                    }
                }
                styles.left = newLeftValue + 'px';
            }

            this.schemeImageStyles = styles;
            setTimeout(() => {
                this.calcPartsOnSchemeStyles();
            });
        },

        move(shiftX, shiftY, e) {
            const image = this.$refs.image;
            const imageWrapRect = this.$refs.imageInnerWrap.getBoundingClientRect();
            const imageRect = image.getBoundingClientRect();
            let xValue = e.pageX - imageWrapRect.left - shiftX;
            let yValue = e.pageY - imageWrapRect.top - shiftY;

            if (imageRect.width > imageWrapRect.width) {
                if (xValue > 0) {
                    xValue = 0;
                }
                else if (Math.abs(xValue) > imageRect.width - imageWrapRect.width) {
                    xValue = imageWrapRect.width - imageRect.width;
                }
            }
            else {
                if (xValue < 0) {
                    xValue = 0;
                }
                else if (xValue + imageRect.width > imageWrapRect.width) {
                    xValue = imageWrapRect.width - imageRect.width;
                }
            }

            if (imageRect.height > imageWrapRect.height) {
                if (yValue > 0) {
                    yValue = 0;
                }
                else if (Math.abs(yValue) > imageRect.height - imageWrapRect.height) {
                    yValue = imageWrapRect.height - imageRect.height;
                }
            }
            else {
                if (yValue < 0) {
                    yValue = 0;
                }
                else if (yValue + imageRect.height > imageWrapRect.height) {
                    yValue = imageWrapRect.height - imageRect.height;
                }
            }

            image.style.left = xValue + 'px';
            image.style.top = yValue + 'px';
        },

        onSchemeImageMouseDown($event) {
            if (this.schemeZoomValue > 0) {
                this.moving = true;
                const image = this.$refs.image;
                const imageRect = image.getBoundingClientRect();

                const shiftX = $event.pageX - imageRect.left;
                const shiftY = $event.pageY - imageRect.top;

                const t = e => throttle(this.move(shiftX, shiftY, e), 10);

                document.addEventListener('mousemove', t);

                document.addEventListener('mouseup', () => {
                    document.removeEventListener('mousemove', t);
                    this.calcPartsOnSchemeStyles();
                    this.moving = false;
                }, { once: true });

                image.ondragstart = function () {
                    return false;
                };
            }
        },

        onSchemeImageTouchStart($event) {
            if (this.schemeZoomValue > 0) {
                this.moving = true;
                const image = this.$refs.image;
                const imageRect = image.getBoundingClientRect();

                const touch = $event.touches[0] || $event.changedTouches[0];
                shiftX = touch.pageX - imageRect.left;
                shiftY = touch.pageY - imageRect.top;

                // image.ondragstart = function () {
                //     return false;
                // };
            }
        },

        onSchemeImageTouchMove($event) {
            const touch = $event.touches[0] || $event.changedTouches[0];
            throttle(this.move(shiftX, shiftY, touch), 10);
        },

        onSchemeImageTouchEnd() {
            this.calcPartsOnSchemeStyles();
            this.moving = false;
        },

        checkScroll() {
            if (this.$refs.list) {
                let blockHeight = this.$refs.list.offsetHeight;
                let scrollHeight = this.$refs.list.scrollHeight;
                let scrollValue = this.$refs.list.scrollTop;

                this.canScrollBottom = scrollValue < scrollHeight - blockHeight;
                this.canScrollTop = scrollValue > 0;
            }
        },

        startScroll(direction) {
            if (this.$refs.list) {
                let blockHeight = this.$refs.list.offsetHeight;
                let scrollHeight = this.$refs.list.scrollHeight;
                let scrollValue = this.$refs.list.scrollTop;

                this.scrollInterval = setInterval(() => {
                    if (direction === 'top') {
                        if (scrollValue > 0) {
                            scrollValue -= 1;
                        }
                        else {
                            clearInterval(this.scrollInterval);
                        }
                    }
                    else {
                        if (scrollValue < scrollHeight - blockHeight) {
                            scrollValue += 1;
                        }
                        else {
                            clearInterval(this.scrollInterval);
                        }
                    }

                    this.$refs.list.scrollTop = scrollValue;
                }, 1);
            }
        },

        stopScroll() {
            clearInterval(this.scrollInterval);
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

@media (min-width: 1281px) {
    .short-scheme-popup-layout {
        width: 1076px;
        grid-template-columns: 780px 180px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .short-scheme-popup-layout {
        width: var(--container);
        grid-template-columns: 648px 180px;
    }
}
@media (min-width: 1040px) {
    .short-scheme-popup-layout {
        display: grid;
        grid-gap: 20px;
    }
}

.head {
    margin-bottom: 16px;
}

@media (min-width: 768px) {
    .actions {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .actions {
        margin-top: 4px;
    }
}
@media (min-width: 768px) {
    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (min-width: 768px) {
    .to-price-btn {
        margin-left: auto;
    }
}
@media (max-width: 767px) {
    .to-catalog-btn {
        display: block;
        width: max-content;
    }

    .to-price-btn {
        width: 100%;
        margin-top: 20px;
    }
}

.content {
    display: flex;
    max-width: 100%;
}

.scheme-inner-wrap {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius-x2);
    overflow: hidden;
}
@media (min-width: 1040px) {
    .scheme-inner-wrap {
        width: 780px;
        height: 780px;
    }
}

@media (min-width: 1281px) {
    .short-scheme-popup-layout .scheme-inner-wrap {
        width: 780px;
        height: 780px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .short-scheme-popup-layout .scheme-inner-wrap {
        width: 648px;
        height: 648px;
    }
}

.scheme-img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.scheme-img_default {
    max-height: 100%;
}
.scheme-img_hidden,
.scheme-img_default {
    opacity: 0;
}
.scheme-img_zoom {
    position: absolute;
    max-height: 100%;
}
.scheme-img_zoomer {
    cursor: move;
}

.scheme-node-point {
    position: absolute;
    box-sizing: content-box;
    z-index: 1;
    border: 2px solid var(--primary-color);
    border-radius: 1px;
}
.scheme-node-point-wrap_moving {
    opacity: 0;
}

.schemes-list {
    position: relative;
}
@media (min-width: 1040px) {
    .schemes-list_inner {
        max-height: 880px;
        overflow: auto;
    }

    .schemes-list__item:not(:last-child) {
        margin-bottom: 20px;
    }
}
@media (max-width: 1039px) {
    .schemes-list_inner {
        display: grid;
        grid-gap: var(--grid-gap);
    }

    .schemes-list__title_hidden,
    .schemes-list_hidden {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .schemes-list_inner {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 767px) {
    .schemes-list_inner {
        grid-template-columns: 1fr 1fr;
    }
}

.schemes-list__item {
    border-radius: var(--border-radius-x2);
    border: 2px solid transparent;
    transition: border-color var(--transition);
}
.schemes-list__item_current {
    border-color: var(--primary-color);
}

@media (min-width: 1040px) {
    .schemes-list__title {
        display: none;
    }
}

.back-to-schemes {
    margin-bottom: 20px;
}
@media (min-width: 1040px) {
    .back-to-schemes {
        display: none;
    }
}

.scheme-zoom-control {
    margin: 24px auto 0;
}

.navigation__arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.navigation__arrow_prev {
    top: -24px;
}

.navigation__arrow_next {
    bottom: -24px;
}

.navigation__arrow_prev-wrap::before,
.navigation__arrow_next-wrap::before {
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    width: 180px;
    height: 64px;
}

.navigation__arrow_prev-wrap::before {
    top: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}

.navigation__arrow_next-wrap::before {
    bottom: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}
</style>