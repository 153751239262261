<template>
    <PopupWrap
        :loading="loading"
        @clickaway="close"
        @close="close"
    >
        <TransitionHeightGroup class="popup-content sign-in-popup__body">
            <div
                v-if="step === 1"
                key="step1"
                class="sign-in-popup__content"
            >
                <img
                    v-if="hasCover"
                    srcset="../../assets/images/authorization-popup-picture-2x.jpg 2x,
                        ../../assets/images/authorization-popup-picture.jpg"
                    src="../../assets/images/authorization-popup-picture.jpg"
                    width="195"
                    height="150"
                    class="sign-in-popup__cover"
                    @load="loading = false"
                >

                <div class="sign-in-popup__title-wrap">
                    <h1 class="sign-in-popup__title">
                        {{ title }}
                    </h1>

                    <p class="sign-in-popup__description">
                        На указанный номер телефона придёт SMS с&nbsp;кодом подтверждения
                    </p>
                </div>

                <UFormProvide
                    ref="form1"
                    v-model="formData1"
                    class="sign-in-popup__form"
                >
                    <UFormField
                        type="string"
                        name="phone"
                        visibleMask
                        keepValue
                        mask="+7 (000) 000 00 00"
                        validations="required|phone"
                        class="sign-in-popup__form-field"
                    >
                        <template #label>
                            Телефон
                        </template>
                    </UFormField>

                    <div class="sign-in-popup__form-footer">
                        <FormAgreement
                            text="Получить код"
                            class="sign-in-popup__agreement"
                        ></FormAgreement>

                        <UButtonBlock
                            primary
                            :loading="sending"
                            class="sign-in-popup__submit-btn"
                            @click="send"
                        >
                            Получить код
                        </UButtonBlock>
                    </div>
                </UFormProvide>
            </div>

            <div
                v-else-if="step === 2"
                key="step2"
                class="sign-in-popup__content"
            >
                <div class="sign-in-popup__title-wrap">
                    <h1 class="sign-in-popup__title">
                        <UIconButton
                            name="arrow-left"
                            round
                            tiny
                            class="sign-in-popup__return-btn"
                            @click="step--"
                        ></UIconButton>

                        {{ formData1.phone | phone }}
                    </h1>

                    <p class="sign-in-popup__description">
                        Мы отправили SMS с кодом подтверждения
                    </p>
                </div>

                <UFormProvide
                    ref="form2"
                    v-model="formData2"
                    class="sign-in-popup__form"
                >
                    <UFormField
                        type="string"
                        name="code"
                        label="Код из СМС"
                        mask="0000"
                        validations="required"
                        class="sign-in-popup__form-field"
                    >
                        <template #label>
                            Код из СМС
                        </template>
                    </UFormField>

                    <p
                        v-if="interval"
                        class="sign-in-popup__repeat-btn"
                    >
                        Отправить код повторно можно через {{ countdownValue }} сек.
                    </p>

                    <UButtonText
                        v-if="!interval"
                        dashed
                        secondary
                        dark
                        class="sign-in-popup__repeat-btn"
                        @click="send"
                    >
                        Отправить код повторно
                    </UButtonText>

                    <div class="sign-in-popup__form-footer">
                        <UButtonBlock
                            primary
                            :loading="confirming"
                            class="sign-in-popup__submit-btn"
                            @click="confirm"
                        >
                            Проверить код
                        </UButtonBlock>
                    </div>
                </UFormProvide>
            </div>
        </TransitionHeightGroup>
    </PopupWrap>
</template>

<script>
import { mapActions } from 'vuex';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import FormAgreement from '@/components/FormAgreement.vue';
import TransitionHeightGroup from '@ui/components/_transitions/TransitionHeightGroup.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
import UFormProvide from '@ui/components/UForm/UFormProvide.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
import UButtonBlock from '@ui/components/UButton/UButtonBlock.vue';
import UFormField from '@ui/components/UForm/UFormField.vue';


export default {
    name: 'SignInPopup',

    components: {
        UFormField,
        UButtonBlock,
        UButtonText,
        UFormProvide,
        UIconButton,
        TransitionHeightGroup,
        FormAgreement,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        title: {
            type: String,
            default: 'Вход',
        },

        hasCover: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,

            step: 1,

            formData1: {
                phone: '',
            },

            formData2: {
                code: '',
            },

            uuid: '',

            sending: false,
            confirming: false,
            sentAt: null,
            interval: null,
            countdownValue: 0,
        };
    },

    watch: {
        // 'formData1.phone': {
        //     handler(value) {
        //         if (phoneReg.test(value)) {
        //             this.send();
        //         }
        //     },
        // },

        'formData2.code': {
            handler(value) {
                if (/\d{4}/.test(value)) {
                    this.confirm();
                }
            },
        },
    },

    beforeDestroy() {
        this.destroyCountdown();
    },

    methods: {
        ...mapActions({
            sendLoginCode: 'profile/sendLoginCode',
            confirmLoginCode: 'profile/confirmLoginCode',
        }),

        createCountdown() {
            this.sentAt = new Date();
            this.interval = setInterval(() => {
                const countdownValue = 60 - parseInt((new Date() - this.sentAt) / 1000);

                if (countdownValue > 0) {
                    this.countdownValue = countdownValue;
                }
                else {
                    this.destroyCountdown();
                }

            }, 1000);
        },

        destroyCountdown() {
            this.sentAt = null;
            this.countdownValue = 0;
            clearInterval(this.interval);
            this.interval = null;
        },

        async send() {
            const errors = await this.$refs.form1.validate();

            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.sending = true;

                try {
                    const { uuid } = await this.sendLoginCode(this.formData1);
                    this.createCountdown();
                    this.uuid = uuid;

                    if (this.formData1.phone) {
                        this.step++;
                    }
                }
                catch (error) {
                    this.$refs.form1.handle(error);
                    this.$store.commit('handleCommonHttpError', error);
                }
                finally {
                    this.sending = false;
                }
            }
        },

        async confirm() {
            const errors = await this.$refs.form2.validate();

            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.confirming = true;

                try {
                    const data = {
                        ...this.formData1,
                        ...this.formData2,
                        uuid: this.uuid,
                    };

                    const { isAuthorized } = await this.confirmLoginCode(data);

                    if (isAuthorized) {
                        await this.$store.dispatch('cart/getCart');
                    }

                    this.callback(isAuthorized);
                    this.close();
                }
                catch (error) {
                    this.$refs.form2.handle(error);
                    this.$store.commit('handleCommonHttpError', error);
                }
                finally {
                    this.confirming = false;
                }
            }
        },
    },
};
</script>

<style>
.sign-in-popup__body {
    box-sizing: content-box;
}
@media (min-width: 768px) {
    .sign-in-popup__body {
        width: 380px;
    }
}

@media (min-width: 768px) {
    .sign-in-popup__content {
        width: 380px;
    }
}
@media (max-width: 767px) {
    .sign-in-popup__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}

.sign-in-popup__cover {
    margin-bottom: 24px;
    max-width: 190px;
    height: auto;
}
@media (min-width: 768px) {
    .sign-in-popup__cover {
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 767px) {
    .sign-in-popup__cover {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .sign-in-popup__title-wrap {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .sign-in-popup__title-wrap {
        margin-bottom: 16px;
    }
}

.sign-in-popup__title {
    display: flex;
    align-items: center;
    font-family: var(--f-extra-bold);
}
@media (min-width: 768px) {
    .sign-in-popup__title {
        font-size: 24px;
    }
}
@media (max-width: 767px) {
    .sign-in-popup__title {
        font-size: 18px;
    }
}

.sign-in-popup__return-btn {
    margin-right: 20px;
}

.sign-in-popup__description {
    margin-top: 12px;
}

@media (max-width: 767px) {
    .sign-in-popup__form {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}

.sign-in-popup__form-field:not(:first-child) {
    margin-top: var(--gap-form-fields);
}

.sign-in-popup__form-footer {
    margin-top: var(--gap-main-action);
}
@media (max-width: 767px) {
    .sign-in-popup__form-footer {
        margin-top: auto;
    }
}

.sign-in-popup__agreement {
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .sign-in-popup__agreement {
        margin-bottom: 16px;
    }
}

.sign-in-popup__repeat-btn {
    margin-top: 20px;
}
@media (max-width: 767px) {
    .sign-in-popup__repeat-btn {
        margin-top: 16px;
    }
}

.sign-in-popup__submit-btn {
    width: 100%;
}
</style>