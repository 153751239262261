<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2">
                Как вернуть товар?
            </h2>

            <p class="popup-text">
                В&nbsp;соответствии с&nbsp;законом о&nbsp;защите прав потребителей, товар, который не&nbsp;подошел или не&nbsp;понравился, можно вернуть в&nbsp;течение 14&nbsp;дней, обратившись в&nbsp;магазин, в&nbsp;котором была совершена покупка. Подробная информация опубликована
                <RouterLink
                    :to="{ name: 'return-and-refund' }"
                    class="underline text_red"
                >
                    здесь
                </RouterLink>.
            </p>

            <ul class="factoids">
                <li class="factoid">
                    <div class="factoid-icon-wrap">
                        <UIcon
                            name="computer"
                            big
                            light
                        ></UIcon>
                    </div>

                    <p class="factoid-text">
                        <b>Создайте заявку на возврат</b>
                        <span>Не позднее 14 дней с момента получения заказа товаров, которые хотите вернуть в магазин.</span>
                    </p>
                </li>
                <li class="factoid">
                    <div class="factoid-icon-wrap">
                        <UIcon
                            name="shop"
                            big
                            light
                        ></UIcon>
                    </div>

                    <p class="factoid-text">
                        <b>Привезите товар в магазин</b>
                        <span>Для того, чтобы сотрудник магазина принял товар, назовите ему номер заказа. Так он найдет вашу заявку и оформит возврат.</span>
                    </p>
                </li>
                <li class="factoid">
                    <div class="factoid-icon-wrap">
                        <UIcon
                            name="envelope-stroke"
                            big
                            light
                        ></UIcon>
                    </div>

                    <p class="factoid-text">
                        <b>Подтвердите возврат</b>
                        <span>После того, как магазин подтвердит вашу заявку на возврат, вы получите SMS от Ubibi. Ответьте на него любым непустым сообщением.</span>
                    </p>
                </li>
                <li class="factoid">
                    <div class="factoid-icon-wrap">
                        <UIcon
                            name="ruble"
                            big
                            light
                        ></UIcon>
                    </div>

                    <p class="factoid-text">
                        <b>Получите деньги</b>
                        <span>Деньги на вашу карту будут возвращены максимум в течение 10 рабочих дней.</span>
                    </p>
                </li>
            </ul>

            <p class="popup-text">
                Если что-то пошло не так, <button
                    class="dashed text_red"
                    @click="showWriteUsPopup"
                >
                    напишите нам
                </button>.
            </p>
        </div>
    </PopupWrap>
</template>

<script>
import popup from "@/mixins/popup.js";
import PopupWrap from "@/components/popups/PopupWrap.vue";
import UIcon from '@ui/components/UIcon/UIcon.vue';
const WriteUsPopup = () => import('@/components/popups/WriteUsPopup.vue');

export default {
    name: "HowReturnOrderPopup",

    components: {
        UIcon,
        PopupWrap,
    },

    mixins: [popup],

    methods: {
        showWriteUsPopup() {
            this.close();
            this.$popup(WriteUsPopup);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.popup-text {
    line-height: var(--base-lh);
}

.factoids {
    margin-top: 24px;
    margin-bottom: 24px;
}

.factoid {
    display: flex;
    align-items: flex-start;
}

.factoid:not(:last-child) {
    margin-bottom: 20px;
}

.factoid-text {
    line-height: var(--base-lh);
}

.factoid-text span {
    display: block;
    margin-top: 4px;
}

.factoid-icon-wrap {
    display: block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    flex-shrink: 0;
    padding: 12px;
    background-color: var(--primary-color);
    border-radius: 50%;
}
</style>