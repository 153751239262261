/* eslint-disable */
export const email = /^(?:[A-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;
export const phone = /^\+7\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}$/;
export const url = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-)[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-)[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S)?$/i;
export const year = /(?:19|20)(?:[0-9][0-9])/;
export const date = /^(?:(?:31\.(?:0[13578]|1[02]))\.|(?:(?:29|30)\.(?:0[1,3-9]|1[0-2])\.))(?:\d{4})$|^(?:29\.02\.(?:19|20)(?:(?:0[48]|[2468][048]|[13579][26])|00))$|^(?:0[1-9]|1\d|2[0-8])\.(?:(?:0[1-9])|(?:1[0-2]))\.(?:\d{4})$/;
export const datetime = /^(?:(?:31\.(?:0[13578]|1[02]))\.|(?:(?:29|30)\.(?:0[1,3-9]|1[0-2])\.))(?:(?:19|20)\d{2})\s(?:0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$|^(?:29\.02\.(?:19|20)(?:(?:0[48]|[2468][048]|[13579][26])|00))\s(?:0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$|^(?:0[1-9]|1\d|2[0-8])\.(?:(?:0[1-9])|(?:1[0-2]))\.(?:(?:19|20)\d{2})\s(?:0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
export const time = /^(?:[01][0-9]|2[0-3])(?::[0-5][0-9])(?::[0-5][0-9])?$/;
export const number = /\d+/;
export const licensePlate = /^([АВЕКМНОРСТУХ]{1})(\d{3})([АВЕКМНОРСТУХ]{2})(\s{1})(\d{2,3})$/;
export const password = /^[0-9a-zA-Z`?!@#$%^&*()–_\-=+[\]{};:"|/,.~]{6,}$/;

export default {
    email,
    phone,
    url,
    year,
    date,
    datetime,
    time,
    number,
    licensePlate,
    password,
};