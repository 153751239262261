<template>
    <div
        class="attrs-table"
        :class="{
            'attrs-table_small': small,
            'attrs-table_big': big,
        }"
    >
        <div
            v-for="(characteristic, idx) in carCharacteristics"
            :key="'characteristic-' + idx"
            class="attrs-table__row"
        >
            <div class="attrs-table__td">
                {{ characteristic.name }}
            </div>
            <div
                v-if="characteristic.value"
                class="attrs-table__td"
            >
                {{ characteristic.value }}
                <template v-if="characteristic.value !== characteristic.value_name && characteristic.value_name">
                    / {{ characteristic.value_name }}
                </template>
            </div>
            <div
                v-else-if="characteristic.values"
                class="attrs-table__td"
            >
                <ul>
                    <li
                        v-for="(nested_characteristic, nested_idx) in characteristic.values"
                        :key="'characteristic-' + nested_idx"
                    >
                        {{ nested_characteristic.value }}
                        <template v-if="nested_characteristic.value !== nested_characteristic.value_name">
                            / {{ nested_characteristic.value_name }}
                        </template>
                    </li>
                </ul>
            </div>
            <div
                v-else
                class="attrs-table__td"
            >
                —
            </div>
        </div>
    </div>
</template>

<script>
import { month, monthsDeclination } from '@/lib/datetime/names.js';
import { mainAttrsArrForSelectCarByModel } from '@/settings.js';

export default {
    name: 'CarCharacteristicsTable',

    props: {
        car: {
            type: Object,
            default: () => {
            },
        },

        small: Boolean,
        big: Boolean,
    },

    computed: {
        carCharacteristics() {
            let car = this.car;
            const data = [];

            if (car.grz) data.push({ name: 'Гос. номер', value: this.$options.filters.carNumber(car.grz) });
            if (car.vin_frame) data.push({ name: 'VIN/Frame', value: car.vin_frame });
            if (car.prod_date && (car.prod_date.day || car.prod_date.month)) {
                let dtString = '';
                if (car.prod_date.day) {
                    dtString += car.prod_date.day;
                    dtString += ' ';
                }

                if (car.prod_date.month) {
                    if (car.prod_date.day) {
                        dtString += monthsDeclination[car.prod_date.month - 1];
                    }
                    else {
                        dtString += month[car.prod_date.month - 1];
                    }
                    dtString += ' ';
                }

                dtString += ' ';
                dtString += car.prod_date.year;
                data.push({ name: 'Дата выпуска', value: dtString });
            }

            const mainAttrs = mainAttrsArrForSelectCarByModel[car.manufacturer.codename];
            const mainAttrsArr = [];
            const restAttrsArr = [];

            car.characteristics.forEach(item => {
                const attr = mainAttrs.find(attr => attr.codename === item.codename);
                if (attr) {
                    item.name = attr.label;
                    mainAttrsArr.push(item);
                }
                else {
                    restAttrsArr.push(item);
                }
            });

            return [
                ...data,
                ...mainAttrsArr.filter(item => item.name && (item.value || item.values)),
                ...restAttrsArr.filter(item => item.name && (item.value || item.values)),
            ];
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .attrs-table_small {
        font-size: var(--small-fz);
        font-family: var(--f-semi-bold);
    }
}

@media (min-width: 768px) {
    .attrs-table__row {
        display: flex;
        align-items: baseline;
    }
}

.attrs-table__row:not(:last-child) {
    margin-bottom: 12px;
}

@media (min-width: 768px) {
    .attrs-table__row::after {
        content: "";
        min-width: 12px;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 5px;
        order: 2;
        flex-grow: 1;
        border-bottom: 1px dotted var(--border-dark-c);
    }

    .attrs-table_big .attrs-table__row::after {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.attrs-table__td:first-child {
    order: 1;
    color: var(--font-secondary-color);
}

.attrs-table__td:last-child {
    order: 3;
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .attrs-table__td:last-child {
        width: 50%;
    }
}
@media (max-width: 767px) {
    .attrs-table__td:last-child {
        margin-top: 4px;
    }
}
</style>