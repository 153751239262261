<template>
    <div class="pay-loading-page">
        <BackLink
            text="Изменить данные"
            class="back-link"
            :to="{
                name: 'insurance-osago-checkout',
                params: {
                    uuid,
                },
            }"
        ></BackLink>

        <h1 class="h1 h1_text title text_center">
            Оформление полиса ОСАГО
        </h1>

        <div class="description">
            <template v-if="timeIsOver">
                <p>
                    К&nbsp;сожалению, мы&nbsp;не&nbsp;получили ответ от&nbsp;выбранной страховой компании и,
                    в&nbsp;настоящий момент, нет других подходящих предложений.
                </p>

                <p>
                    Возможно, дело в&nbsp;неверных данных или технической ошибке.
                    Попробуйте отправить запрос ещё раз через некоторое время.
                </p>
            </template>

            <p v-else>
                Пожалуйста, подождите... Мы&nbsp;отправили ваши данные в&nbsp;страховую компанию.
                Сейчас происходит проверка информации и&nbsp;формирование электронного полиса ОСАГО.
                Это может занять несколько минут.
            </p>
        </div>

        <div
            v-if="timeIsOver"
            class="buttons-wrap"
        >
            <ButtonText
                primary
                dashed
                class="button repeat-button"
                @click="repeat"
            >
                Повторить запрос
            </ButtonText>

            <ButtonText
                secondary
                dark
                dashed
                class="button"
                :to="{
                    name: 'insurance-osago-checkout',
                    params: {
                        uuid,
                    },
                }"
            >
                Изменить данные
            </ButtonText>
        </div>

        <BackTimerText
            v-else
            :seconds="239"
            class="timer"
            withSeconds
            @timeOver="onTimeOver"
        ></BackTimerText>

        <img
            srcset="../../../../assets/images/insurance/pay-loading-x2.jpg 2x,
                ../../../../assets/images/insurance/pay-loading.jpg"
            src="../../../../assets/images/insurance/pay-loading.jpg"
            width="400"
            height="300"
            alt=""
            class="image"
        >
    </div>
</template>

<script>
// store
import insuranceOsago from '@/store/modules/insuranceOsago.js';
// utils
import { mapState, mapMutations, mapActions } from 'vuex';
// use
import useCheckRouteMethods from '@/pages/insurance/osago/_uuid/useCheckRouteMethods.js';
// mixins
import YandexMetrika from '@/mixins/yandex-metrika.js';
// components
import BackTimerText from '@/components/BackTimerText.vue';
import BackLink from '@/components/project-buttons/BackLink.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';


export default {
    name: 'InsuranceOsagoLoadingPage',

    components: {
        ButtonText,
        BackLink,
        BackTimerText,
    },

    mixins: [
        YandexMetrika,
    ],

    metaInfo() {
        return {
            title: 'Оформление полиса ОСАГО',
        };
    },

    serverPrefetch() {
        this.$store.registerModule('insuranceOsago', insuranceOsago);

        return this.serverPrefetch({ uuid: this.uuid });
    },

    beforeRouteLeave(to, from, next) {
        if (to.name === 'insurance-osago-checkout' && this.pollingLoading) {
            try {
                this.$api.insuranceOffer.cancel(this.offer.uuid);
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
        }

        next();
    },

    data() {
        return {
            timeout: null,
            timeIsOver: false,
            pollingLoading: false,
        };
    },

    computed: {
        ...mapState({
            contract: state => (state.insuranceOsago || {}).contract || {},
            offer: state => (state.insuranceOsago.contract.active || {}).offer_obj || {},
        }),

        uuid() {
            return this.$route.params.uuid;
        },
    },

    watch: {
        uuid: {
            handler() {
                this.createBreadcrumbs();
            },
        },
    },

    beforeMount() {
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago, { preserveState: true });
        }
    },

    mounted() {
        this.init();
        this.createBreadcrumbs();
    },

    beforeDestroy() {
        this.endPolling();
    },

    methods: {
        ...useCheckRouteMethods(),
        ...mapMutations({
            setOffers: 'insuranceOsago/setOffers',
            setActiveOfferObj: 'insuranceOsago/setActiveOfferObj',
            updateContract: 'insuranceOsago/updateContract',
        }),

        ...mapActions({
            fetchOffers: 'insuranceOsago/fetchOffers',
            serverPrefetch: 'insuranceOsago/serverPrefetch',
            browserPrefetch: 'insuranceOsago/browserPrefetch',
            order: 'insuranceOsago/order',
        }),

        async init() {
            await this.browserPrefetch({ uuid: this.uuid });

            await this.checkUuid();

            if (this.offer && Object.keys(this.offer).length) {
                const { is_completed, payment_url, status } = this.offer;

                if (is_completed && status !== 'wait_confirm') {
                    if (payment_url) {
                        await this.$router.push({
                            name: 'insurance-osago-confirmation-success',
                            params: {
                                uuid: this.uuid,
                            },
                        });
                    }
                    else {
                        await this.$router.push({
                            name: 'insurance-osago-confirmation-failed',
                            params: {
                                uuid: this.uuid,
                            },
                        });
                    }
                }
                else {
                    await this.polling();
                }
            }
            else {
                await this.$router.push({
                    name: 'insurance-osago-offers',
                    params: {
                        uuid: this.uuid,
                    },
                });
            }
        },

        startPolling() {
            this.pollingLoading = true;
            this.polling();
        },

        async polling() {
            const uuid = this.offer.uuid;

            if (uuid) {
                try {
                    const offer = await this.$api.insuranceOffer.get(uuid);

                    if ((offer || {}).is_completed && (offer || {}).status !== 'wait_confirm') {
                        this.endPolling();

                        this.setActiveOfferObj(offer);

                        if (offer.payment_url) {
                            const offers = this.contract.offers;
                            const newOffers = offers.map(obj => offer.uuid === obj.uuid ? offer : obj);
                            this.setOffers(newOffers);
                            this.updateContract({ status: 'wait_payment' });

                            await this.$router.push({
                                name: 'insurance-osago-confirmation-success',
                                params: {
                                    uuid: this.uuid,
                                },
                            });
                        }
                        else {
                            await this.fetchOffers();

                            await this.$router.push({
                                name: 'insurance-osago-confirmation-failed',
                                params: {
                                    uuid: this.uuid,
                                },
                            });
                        }
                    }
                    else {
                        this.timeout = setTimeout(this.polling, 3000);
                    }
                }
                catch (error) {
                    this.$store.commit('handleCommonHttpError', error);
                }
            }
        },

        endPolling() {
            this.pollingLoading = false;

            clearTimeout(this.timeout);

            this.timeout = null;
        },

        createBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'insurance-osago',
                    },
                    title: 'Автострахование',
                },
                {
                    to: {
                        name: this.$route.name,
                        params: {
                            uuid: this.uuid,
                        },
                    },
                    title: 'Оформление ОСАГО',
                },
            ]);
        },

        async repeat() {
            this.handleYandexMetrikaGoal('osago_oformlenie_again');

            this.timeIsOver = false;

            try {
                await this.order();
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }

            await this.polling();
        },

        onTimeOver() {
            this.endPolling();
            this.timeIsOver = true;
        },
    },
};
</script>

<style scoped>
.pay-loading-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.back-link {
    margin-top: 24px;
    order: 1;
    align-self: flex-start;
}
@media (min-width: 1281px) {
    .back-link {
        margin-bottom: 8px;
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .back-link {
        margin-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .back-link {
        margin-bottom: 36px;
    }
}

.image {
    order: 1;
    margin-bottom: var(--gap-y-medium);
}
@media (min-width: 1040px) {
    .image {
        width: 400px;
    }
}
@media (max-width: 1039px) {
    .image {
        width: 290px;
        height: 218px;
    }
}

.buttons-wrap {
    order: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 767px) {
    .buttons-wrap {
        flex-direction: column;
    }
}

.timer {
    order: 4;
    font-size: 16px;
    font-family: var(--f-bold);
    color: var(--primary-color);
}

.title {
    order: 2;
}

.description {
    order: 3;
    margin-bottom: var(--gap-y-small);
}
@media (min-width: 1281px) {
    .description {
        width: 588px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .description {
        width: 680px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .description {
        width: var(--col-10);
    }
}

.description p {
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .description p:not(:last-child) {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .description p:not(:last-child) {
        margin-bottom: 12px;
    }
}

@media (min-width: 768px) {
    .repeat-button {
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .repeat-button {
        margin-bottom: 20px;
    }
}
</style>