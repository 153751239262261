<template>
    <RouterLink
        :to="{
            name: 'main',
        }"
        class="ubibi-logo"
        aria-label="Перейти на главную страницу сервиса"
    >
        <img
            src="../assets/images/logo.svg"
            alt="Логотип Ubibi"
            class="ubibi-logo__img"
            :class="{
                'ubibi-logo__img_base': base,
                'ubibi-logo__img_big': big,
            }"
            width="80"
            height="24"
        >
    </RouterLink>
</template>

<script>
export default {
    name: 'UbibiLogo',

    props: {
        base: Boolean,
        big: Boolean,
    },
};
</script>

<style scoped>
.ubibi-logo {
    display: block;
    width: max-content;
    flex-shrink: 0;
    font-size: 0;
}

.ubibi-logo__img_base {
    width: 80px;
    height: 24px;
}

@media (min-width: 768px) {
    .ubibi-logo__img_big {
        width: 131px;
        height: 40px;
    }
}
@media (max-width: 767px) {
    .ubibi-logo__img_big {
        width: 80px;
        height: 24px;
    }
}
</style>