var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-gap_medium"},[(!_vm.initialized)?_c('Spinner',{attrs:{"size":"big","center":""}}):[(_vm.$route.params.slug === _vm.tireSelectionCategory.slug)?_c('div',{staticClass:"flex align-items-center"},[_c('h1',{staticClass:"h1 h1_block right-gap_medium flex-shrink-0"},[_c('span',[_vm._v("Шины")]),(_vm.products.current_count)?_c('span',{staticClass:"h1-sub"},[_vm._v(_vm._s(_vm._f("number")(_vm.products.current_count)))]):_vm._e()]),_vm._v(" "),_c('RouterLink',{staticClass:"h1 h1_block text_secondary link-dark-hover flex-shrink-0",attrs:{"to":{
                    name: 'market-products-category',
                    params: {
                        slug: _vm.rimSelectionCategory.slug,
                    },
                    query: _vm.carQuery,
                }}},[_c('span',[_vm._v("Диски")]),(_vm.parallelCategoryCurrentCount)?_c('span',{staticClass:"h1-sub"},[_vm._v(_vm._s(_vm._f("number")(_vm.parallelCategoryCurrentCount)))]):_vm._e()])],1):(_vm.$route.params.slug === _vm.rimSelectionCategory.slug)?_c('div',{staticClass:"flex align-items-center"},[_c('RouterLink',{staticClass:"h1 h1_block right-gap_medium text_secondary link-dark-hover flex-shrink-0",attrs:{"to":{
                    name: 'market-products-category',
                    params: {
                        slug: _vm.tireSelectionCategory.slug,
                    },
                    query: _vm.carQuery,
                }}},[_c('span',[_vm._v("Шины")]),(_vm.parallelCategoryCurrentCount)?_c('span',{staticClass:"h1-sub"},[_vm._v(_vm._s(_vm._f("number")(_vm.parallelCategoryCurrentCount)))]):_vm._e()]),_vm._v(" "),_c('h1',{staticClass:"h1 h1_block flex-shrink-0"},[_c('span',[_vm._v("Диски")]),(_vm.products.current_count)?_c('span',{staticClass:"h1-sub"},[_vm._v(_vm._s(_vm._f("number")(_vm.products.current_count)))]):_vm._e()])],1):_c('h1',{staticClass:"h1 h1_block"},[_c('span',[_vm._v(_vm._s(_vm.category.page_title))]),(_vm.category.is_leaf && _vm.products.current_count)?_c('span',{staticClass:"h1-sub"},[_vm._v(_vm._s(_vm._f("number")(_vm.products.current_count)))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.category.is_leaf)?[(_vm.products.total_count)?_c('SelectionInCategory',{attrs:{"value":_vm.filterData,"filters":_vm.selectionFilters,"flatFilters":_vm.flatFilters,"resultCurrentCount":_vm.products.current_count,"resultLoading":_vm.loading},on:{"selectSizeTemplate":_vm.getParallelCategoryCount,"change":_vm.onChangeFilter,"reload":_vm.reload}}):_vm._e(),_vm._v(" "),_c('MarketItemsListWithOffers',{attrs:{"apiName":"products","modelName":"product","items":_vm.products.items,"amount":_vm.products.current_count,"totalAmount":_vm.products.total_count,"staticFilters":_vm.staticFilters,"dynamicFilters":_vm.dynamicFilters,"checkedFilters":_vm.checkedFilters,"filterData":_vm.filterData,"sortData":_vm.sortData,"sortOptions":_vm.sortOptions,"hasNext":_vm.hasNext,"loading":_vm.loading,"reviewsInPopup":""},on:{"changeFilter":_vm.onChangeFilter,"resetFilters":_vm.onResetFilters,"deleteFilter":_vm.onDeleteFilter,"changeSort":_vm.onChangeSort,"reload":_vm.reload,"load":_vm.load,"changeFavorite":_vm.onChangeFavorite},scopedSlots:_vm._u([{key:"inStockEmpty",fn:function(){return [_c('EmptyBlock',{attrs:{"icon":"empty-box","title":"Нет предложений","btnText":"Перейти в каталог","btnUrl":{
                                name: 'market-products-catalog',
                            }},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("\n                                В категории "),_c('b',[_vm._v("«"+_vm._s(_vm.category.name)+"»")]),_vm._v(" нет актуальных предложений.\n                            ")]},proxy:true}],null,false,3017647212)})]},proxy:true},{key:"totalEmpty",fn:function(){return [_c('EmptyBlock',{attrs:{"icon":"empty-box","title":"Пустая категория","btnText":"Перейти в каталог","btnUrl":{
                                name: 'market-products-catalog',
                            }},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("\n                                В категории "),_c('b',[_vm._v("«"+_vm._s(_vm.category.name)+"»")]),_vm._v(" нет товаров.\n                            ")]},proxy:true}],null,false,1700529349)})]},proxy:true}],null,false,3153953323)})]:_c('MarketCatalog',{attrs:{"categories":_vm.categoryChildren}})],2),_vm._v(" "),(_vm.category.is_leaf)?_c('LastSee'):_vm._e(),_vm._v(" "),_c('SeoBlock',{scopedSlots:_vm._u([(_vm.category.seo_description)?{key:"seo",fn:function(){return [_vm._v(_vm._s(_vm.category.seo_description))]},proxy:true}:null],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }