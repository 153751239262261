<template>
    <div
        class="geo-input"
        :class="{
            'geo-input_with-map': map,
        }"
    >
        <!-- autocomplete убирает стрелку -->

        <Selector
            ref="input"
            :value="inputValue"
            :label="label"
            :loading="inputLoading"
            :options="options"
            class="geo-input__input"
            optionKey="coords"
            optionLabel="label"
            :clearInputOnBlur="false"
            :clearInputOnFocus="false"
            :clearInputOnSelect="false"
            autocomplete
            isAsync
            :invalid="invalid"
            :error="error"
            :disabled="disabled"
            @input="onInput"
            @open="onOpen"
            @close="onClose"
            @select="onSelect"
            @change="onChange"
            @blur="onBlur"
            @clear="onClear"
        ></Selector>

        <div
            v-if="map"
            class="geo-input__map-wrap"
        >
            <div
                v-show="loading"
                class="geo-input__loading"
            >
                <Spinner></Spinner>
            </div>
            <yandexMap
                v-if="showMap"
                :options="{
                    suppressMapOpenBlock: true
                }"
                class="geo-input__map"
                :class="{
                    'geo-input__map_hidden': loading,
                }"
                :coords="mapValue"
                :zoom="13"
                :scrollZoom="false"
                :controls="[
                    'fullscreenControl',
                    'zoomControl'
                ]"
                :behaviors="[
                    'drag',
                    'dblClickZoom',
                    'rightMouseButtonMagnifier',
                ]"
                useObjectManager
                ymapClass="ymap"
                @map-was-initialized="onMap"
                @boundschange="changeMap"
                @click="onClickMap"
            >
                <ymapMarker
                    v-if="markerValue"
                    :coords="markerValue"
                    :icon="getYMapMarkerIcon({})"
                    :markerId="id + '_marker'"
                ></ymapMarker>
            </yandexMap>
        </div>
    </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import Spinner from '@/components/Spinner.vue';
import genId from '@ui/utils/genId.js';
import axios from 'axios';
import Selector from '@/components/_inputs/Selector.vue';
import equals from '@/lib/equals.js';
import deepClone from '@/lib/deepClone.js';


const toArray = str => str ? str.replace(' ', '').split(',').map(Number) : null;
const toString = arr => arr ? arr.join(', ') : '';

export default {
    name: 'GeoInput',

    components: {
        Selector,
        Spinner,
        yandexMap,
        ymapMarker,
    },

    mixins: [ymapMarkerMixin],

    props: {
        id: {
            type: String,
            default: () => genId(),
        },

        value: {
            type: String,
            default: '',
        },

        label: {
            type: String,
            default: 'Адрес для определения точки на карте *',
        },

        // bbox
        city: Object,

        map: {
            type: Boolean,
            default: true,
        },

        // center
        initialMapValue: String,

        invalid: Boolean,
        error: String,
        disabled: Boolean,

        parseLabel: {
            type: Function,
            default(member) {
                if (this.bbox) {
                    const components = member.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components;
                    const arr = components.filter(obj => {
                        if (this.name && obj.kind === 'locality') {
                            return obj.name.toLowerCase() !== this.name.toLowerCase();
                        }

                        return ['locality', 'street', 'house', 'other'].includes(obj.kind);
                    });

                    if (arr.length) return arr.map(obj => obj.name).join(', ');
                    else return components[components.length - 1].name;
                }

                return member.GeoObject.metaDataProperty.GeocoderMetaData.text;
            },
        },

        parseOptions: {
            type: Function,
            default(obj) {
                if (((obj || {}).GeoObjectCollection || {}).featureMember) {
                    const members = obj.GeoObjectCollection.featureMember;

                    return members.map(member => {
                        const coords = this.parseCoords(member);
                        const label = this.parseLabel(member);

                        if (coords && label) {
                            return { coords, label };
                        }
                    }).filter(_ => _);
                }
                else {
                    return [];
                }
            },
        },
    },

    data() {
        return {
            loading: true,
            initialized: false,
            mapInstance: null,
            options: [],
            timeout: null,
            source: false,

            axios: null,

            // coords: [55.76, 37.64],
            inputValue: '', // str
            mapValue: [55.76, 37.64], // coords
            markerValue: null, // ??? coords
            // innerValue: '', // coords
            // selectValue: null, // obj
            inputLoading: false,
            open: null, // функция пробрасываемая из селектора для асинхронного открытия списка
            showMap: false,
        };
    },

    computed: {
        innerValue() {
            if (this.map) {
                return this.mapValue;
            }

            return this.inputValue;
        },

        normalizedPropValue() {
            if (this.map) {
                // coords
                return toArray(this.value);
            }

            // address
            return this.value;
        },

        outputValue() {
            if (this.map) {
                // coords
                return toString(this.innerValue);
            }

            // address
            return this.innerValue;
        },

        bbox() {
            if (this.city && typeof this.city === 'object') {
                const { x1, x2, y1, y2 } = this.city;

                if (x1 && x2 && y1 && y2) {
                    return `${ x1 },${ y1 }~${ x2 },${ y2 }`;
                }
            }

            return '';
        },

        name() {
            if (this.city && typeof this.city === 'object' && this.city.name) {
                return this.city.name;
            }

            return '';
        },

        params() {
            const params = {
                apikey: this.$formManager.options.geo.yandexApiKey,
                format: 'json',
                results: this.$formManager.options.geo.results,
            };

            if (this.bbox) {
                params.bbox = this.bbox;
                params.rspn = 1;
            }

            return params;
        },

        url() {
            return 'https://geocode-maps.yandex.ru/1.x/';
        },
    },

    watch: {
        normalizedPropValue: {
            handler(value) {
                if (!equals(value, this.innerValue)) {
                    if (this.map) {
                        if (value) {
                            this.mapValue = [...value];
                        }

                        this.markerValue = deepClone(value);
                    }
                    else {
                        this.inputValue = value;
                    }
                }
            },

            immediate: true,
        },

        initialMapValue: {
            deep: true,
            handler(newValue) {
                if (this.map) {
                    this.mapValue = toArray(newValue);
                }
            },
        },
    },

    mounted() {
        this.axios = axios.create({
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        this.axios.CancelToken = axios.CancelToken;
        this.axios.isCancel = axios.isCancel;

        if (this.map) {
            if (this.normalizedPropValue) {
                this.mapValue = [...this.normalizedPropValue];
                this.markerValue = [...this.normalizedPropValue];
            }
            else if (this.initialMapValue) {
                this.mapValue = toArray(this.initialMapValue);
            }
        }

        this.showMap = true;
    },

    methods: {
        async onMap(map) {
            this.mapInstance = map;

            if (this.map && this.normalizedPropValue) {
                await this.updateAddress();

                // this.initialized = true;
                // this.loading = false;
            }
            // НЕ УДАЛЯТЬ
            // else {
            //     try {
            //         const response = await ymaps.geolocation.get();
            //         this.coords = response.geoObjects.position;
            //     }
            //     catch (error) {
            //         // нужна обработка ошибки
            //         this.initialized = true;
            //         this.loading = false;
            //     }
            // }

            this.initialized = true;
            this.loading = false;
        },

        changeMap($event) {
            if (!this.initialized) {
                const newCenter = $event.get('newCenter');

                if (
                    this.mapValue[0].toFixed() === newCenter[0].toFixed() &&
                    this.mapValue[1].toFixed() === newCenter[1].toFixed()
                ) {
                    this.initialized = true;
                    this.loading = false;
                }
            }
        },

        async onClickMap($event) {
            const coords = $event.get('coords');
            this.mapValue = coords;
            this.markerValue = coords;
            await this.updateAddress();
            const option = {
                label: this.inputValue,
                coords,
            };
            this.$emit('change', this.outputValue);
            this.$emit('select', option);
        },

        onSelect(option) {
            if (option) {
                const coords = option.coords.split(' ').map(Number);
                this.mapValue = [coords[1], coords[0]];
                this.markerValue = [coords[1], coords[0]];
                this.inputValue = option.label;
                this.$nextTick(() => {
                    this.$emit('change', this.outputValue);
                    this.$emit('select', option);
                });
            }
            else {
                this.$nextTick(() => {
                    this.$emit('change', '');
                });
            }
        },

        onChange() {
            // this.innerValue = value;
        },

        parseCoords(member) {
            return member.GeoObject.Point.pos;
        },

        async updateAddress() {
            try {
                const url = this.url;
                const params = {
                    ...this.params,
                    geocode: toString([this.mapValue[1], this.mapValue[0]]),
                };

                const response = await this.axios.get(url, { params });

                const member = (response.data.response.GeoObjectCollection.featureMember || [])[0];

                if (member) {
                    this.inputValue = this.parseLabel(member);
                }
            }
            catch (error) {
                // нужна обработка ошибки
                console.error(error);
            }
        },

        onClose() {
            this.options = [];

            this.$emit('close');
        },

        async onOpen(open) {
            this.open = open;

            if (this.inputValue) {
                this.fetchOptions();
            }

            this.$emit('open');
        },

        onInput(value) {
            this.inputValue = value;
            this.markerValue = null;

            if (value) {
                this.fetchOptions();
            }
            else {
                this.onClear();
            }

            if (!this.map) {
                this.$emit('input', value);
            }
        },

        fetchOptions() {
            if (this.inputValue) {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }

                this.timeout = setTimeout(async () => {
                    const url = this.url;
                    const params = {
                        ...this.params,
                        geocode: this.inputValue,
                    };

                    if (this.source) {
                        this.source.cancel();
                    }

                    this.source = this.axios.CancelToken.source();
                    const cancelToken = this.source.token;

                    try {
                        const { data: { response } } = await this.axios.get(url, { params, cancelToken });
                        this.options = this.parseOptions(response);

                        setTimeout(() => {
                            if (this.open && typeof this.open === 'function') {
                                this.open();
                            }
                        });
                    }
                    catch (error) {
                        if (this.axios.isCancel(error)) return;
                        this.$formManager.options.geo.handleHttpError(error);
                    }
                }, this.$formManager.options.geo.debounce);
            }
        },

        onBlur() {
            this.$emit('blur');
        },

        onClear() {
            this.$emit('clear');
        },
    },
};
</script>

<style>
.geo-input {
    position: relative;
}

.geo-input.geo-input_with-map {
    min-height: 372px;
}

.geo-input__map-wrap {
    position: relative;
}

.geo-input__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: var(--lightest-bg);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
}

.geo-input__input {
    margin-bottom: 8px;
}

.geo-input__map.geo-input__map_hidden {
    visibility: hidden;
}

.geo-input__map,
.ymap {
    width: 100%;
    height: 100%;
}

.ymap .ymaps-2-1-77-inner-panes {
    border-radius: var(--border-radius);
}

.ymap .ymaps-2-1-77-map {
    border-radius: var(--border-radius);
}
</style>