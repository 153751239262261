var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"catalog"}},[(_vm.isOpen)?_c('div',{staticClass:"catalog"},[_c('div',{staticClass:"catalog__backdrop",class:{
                'catalog__backdrop_transparent': _vm.hideBackdrop,
            },on:{"click":_vm.close}}),_vm._v(" "),_c('div',{staticClass:"catalog-screen"},[_c('div',{staticClass:"catalog-screen__head"},[_c('div',{staticClass:"second-view__head-btn-wrap"},[(_vm.parent.parentCategoryCodename)?_c('ButtonIcon',{staticClass:"second-view__head-btn second-view__head-btn_back",attrs:{"small":"","secondary":false,"icon":{
                            name: 'arrow-left',
                            big: true,
                        },"type":"button"},on:{"click":_vm.back}}):_vm._e()],1),_vm._v(" "),_c('h3',{staticClass:"text_big-fz text_bold"},[_vm._v("\n                    "+_vm._s(_vm.parent.title)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"second-view__head-btn-wrap"},[_c('ButtonIcon',{staticClass:"second-view__head-btn second-view__head-btn_close",attrs:{"small":"","secondary":false,"icon":{
                            name: 'cross',
                            big: true,
                            secondary: true,
                        },"type":"button"},on:{"click":_vm.close}})],1)]),_vm._v(" "),_c('div',{staticClass:"catalog-screen__body"},[(!_vm.loading)?_c('ol',{staticClass:"catalog__section"},_vm._l((_vm.section),function(category){return _c('li',{staticClass:"catalog__item"},[(category.is_leaf)?_c('RouterLink',{staticClass:"catalog__link",attrs:{"to":category.to}},[(category.icon)?_c('UIcon',{staticClass:"mr-12",attrs:{"name":category.icon,"big":""}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"catalog__link-text"},[_vm._v("\n                                "+_vm._s(category.title)+"\n                            ")])],1):_c('button',{staticClass:"catalog__link",on:{"click":function($event){return _vm.onSelectCategory(category)}}},[(category.icon)?_c('UIcon',{staticClass:"mr-12",attrs:{"name":category.icon,"big":""}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"catalog__link-text"},[_vm._v("\n                                "+_vm._s(category.title)+"\n                            ")]),_vm._v(" "),(category.children && category.children.length)?_c('UIcon',{staticClass:"catalog__link-arrow",attrs:{"name":"arrow-right","small":""}}):_vm._e()],1)],1)}),0):_vm._e(),_vm._v(" "),(_vm.loading)?_c('USpinner',{attrs:{"absoluteCenter":"","big":""}}):_vm._e()],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }