var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-spinner",class:{
        'u-spinner_small': _vm.small,
        'u-spinner_big': _vm.big,
        'u-spinner_light': _vm.light,
        'u-spinner_dark': _vm.dark,
        'u-spinner_center': _vm.center,
        'u-spinner_absolute-center': _vm.absoluteCenter,
    }},[_c('UIcon',{staticClass:"u-spinner__icon",attrs:{"name":"spinner"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }