<template>
    <DefaultSelector
        ref="selector"
        :value="value"
        :options="innerOptions"
        :hasMore="hasMore"
        :filterable="filterable"
        :isAsync="withApi"
        v-bind="$attrs"
        @open="onOpen"
        @blur="onBlur"
        @input="onInput"
        @change="onChange"
        @select="onSelect"
        @more="onMore"
        v-on="listeners"
    >
        <template #label="scope">
            <slot
                name="label"
                v-bind="scope"
            ></slot>
        </template>
    </DefaultSelector>
</template>

<script>
import DefaultSelector from '@/components/_inputs/Selector.vue';
import { DEBOUNCE_DURATION, SELECTOR_LIMIT, SELECTOR_SEARCH_PARAM } from '@/settings.js';
import { HTTP } from '@/http.js';
import isNumber from '@/lib/isNumber.js';


export default {
    name: 'Selector',

    components: {
        DefaultSelector,
    },

    inheritAttrs: false,

    model: {
        event: 'change',
        prop: 'value',
    },

    props: {
        value: {
            required: true,
        },

        reduceResponse: {
            type: Function,
            default: function(response) {
                return response.data;
            },
        },

        options: [Array, String, Function],

        currentCountKey: {
            type: String,
            default: 'current_count',
        },

        getCurrentCount: {
            type: Function,
            default(response) {
                return response[this.currentCountKey];
            },
        },

        itemsKey: {
            type: String,
            default: 'results',
        },

        getItems: {
            type: Function,
            default(response) {
                return response[this.itemsKey];
            },
        },

        searchParam: {
            type: String,
            default: SELECTOR_SEARCH_PARAM,
        },

        limit: {
            type: [Number, Object],
            default: SELECTOR_LIMIT,
        },

        params: Object,
        autocomplete: Boolean,

        reloadParams: {
            type: String,
            default: 'id__in',
        },
    },

    data() {
        return {
            source: null,
            loading: false,
            currentCount: 0,
            innerOptions: [],
            debounce: null,
            offset: 0,
            opened: false,
            axios: null,
            inputValue: '',
        };
    },

    computed: {
        withApi() {
            return typeof this.options === 'string'
                || typeof this.options === 'function';
        },

        withCreateRequestFunction() {
            return typeof this.options === 'string';
        },

        url() {
            return this.withCreateRequestFunction ? this.options : null;
        },

        filterable() {
            return !this.withApi;
        },

        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { change, more, input, select, ...listeners } = this.$listeners;
            return listeners;
        },

        hasMore() {
            return this.innerOptions.length < this.currentCount;
        },

        axiosComputed() {
            if (this.axios) {
                return this.axios;
            }

            return HTTP;
        },
    },

    watch: {
        options: {
            handler(options) {
                if (!this.withApi && options && Array.isArray(options)) {
                    this.innerOptions = options;
                }
            },

            deep: true,
            immediate: true,
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            if (this.withApi) {
                // Все опции в селекторе с апи должны быть объектами
                const value = Array.isArray(this.value) ? this.value : [this.value];
                const needReload = value.filter(_ => _).some(option => typeof option !== 'object');

                if (needReload) {
                    this.reloadOptions();
                }
            }
        },

        onChange(value) {
            this.$emit('change', value);
        },

        onInput(value) {
            this.inputValue = value;

            if (this.withApi && this.opened) {
                this.offset = 0;

                if (this.debounce) {
                    clearTimeout(this.debounce);
                    this.debounce = null;
                }

                this.debounce = setTimeout(() => {
                    const params = this.createParams();

                    this.getOptions({ params }).then(({ options, currentCount }) => {
                        this.innerOptions = options;
                        this.currentCount = currentCount;
                    });

                }, DEBOUNCE_DURATION);
            }
        },

        async getOptions({ params }) {
            this.loading = true;

            try {
                if (this.source) {
                    this.source.cancel();
                }

                const axios = this.axiosComputed;

                this.source = axios.CancelToken.source();
                let response;

                if (this.withCreateRequestFunction) {
                    response = await axios.get(this.url, { params, cancelToken: this.source.token });
                }

                const reducedResponse = this.reduceResponse(response);
                const currentCount = this.getCurrentCount(reducedResponse);
                const options = this.getItems(reducedResponse);
                return { currentCount, options };
            }
            catch (error) {
                this.handleError(error);

                return { currentCount: 0, options: [] };
            }
            finally {
                this.loading = false;
            }
        },

        handleError(error) {
            if (!HTTP.isCancel(error)) {
                // console.error(error);
                this.$store.commit('handleCommonHttpError', error);
            }
        },

        onMore() {
            this.offset += this.limit;

            const params = this.createParams();

            this.getOptions({ params }).then(({ options }) => {
                this.innerOptions.splice(this.innerOptions.length, 0, ...options);
            });

            this.$emit('more');
        },

        onSelect(value) {
            this.$emit('select', value);
        },

        onOpen(open) {
            this.opened = true;

            if (this.withApi) {
                if (this.offset !== 0) this.offset = 0;

                const params = this.createParams();

                this.getOptions({ params }).then(({ options, currentCount }) => {
                    this.innerOptions = options;
                    this.currentCount = currentCount;
                    setTimeout(open);
                });
            }
            else {
                this.innerOptions = this.options;
            }

            this.$emit('open');
        },

        createParams() {
            const params = {
                offset: this.offset,
                order_by: 'name',
            };

            if (this.limit != null) {
                params.limit = this.selectorLimit;
            }

            if (this.inputValue) {
                params[this.searchParam] = this.inputValue;
            }

            if (this.params) {
                Object.assign(params, this.params);
            }

            return params;
        },

        // эквивалентно закрытию списка
        onBlur() {
            this.$emit('blur');

            // анимация
            setTimeout(() => {
                this.opened = false;
                // this.innerOptions = [];
                this.inputValue = '';
            }, 300);
        },

        async reloadOptions() {
            try {
                const value = Array.isArray(this.value) ? this.value : [this.value];

                const filteredValue = value.filter(item => typeof item === 'string' || typeof item === 'number');

                const params = this.createParams();
                params[this.reloadParams] = filteredValue.join(',');

                const { options } = await this.getOptions({ params });
                const ids = options.map(option => option[this.optionKey]);

                this.$refs.selector.selectedOptions = this.value.map(item => {
                    if (typeof item === 'string' || typeof item === 'number') {
                        const id = isNumber(Number(item)) ? Number(item) : item;
                        const index = ids.indexOf(id);

                        if (index !== -1) {
                            return options[index];
                        }
                    }

                    return item;
                });
            }
            catch (error) {
                // return this.catchError(error);
            }
            finally {
                // this.innerLoading = false;
            }
        },
    },
};
</script>