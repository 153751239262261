export default ({ HTTP }) => ({
    async getTariffsPrice({ data, cancelToken }) {
        const response = await HTTP.post('/delivery/yandex/estimate_tariffs/', data, { cancelToken });
        return response.data;
    },

    async getPrice({ data, cancelToken }) {
        const response = await HTTP.post('/delivery/yandex/estimate_price/', data, { cancelToken });
        return response.data;
    },

    async getClaim({ id, cancelToken }) {
        const response = await HTTP.get(`/delivery/yandex/${ id }/`, { cancelToken });
        return response.data;
    },

    async getDriverPhone({ id, cancelToken }) {
        const response = await HTTP.get(`/delivery/yandex/${ id }/driver_phone/`, { cancelToken });
        return response.data;
    },

    async cancel({ id, data }) {
        const response = await HTTP.post(`/delivery/yandex/${ id }/cancel/`, data);
        return response.data;
    },

    async getPaymentLink({ data, cancelToken }) {
        const response = await HTTP.post('/delivery/yandex/payment_link/', data, { cancelToken });
        return response.data;
    },

    async driverPosition(id, config) {
        const response = await HTTP.get(`/delivery/yandex/${ id }/driver_position/`, config);
        return response.data;
    },
});
