var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-gap_medium"},[_c('h1',{staticClass:"h1 h1_block"},[_vm._v("\n        Каталоги оригинальных запчастей\n    ")]),_vm._v(" "),(!_vm.initialized)?_c('Spinner',{staticClass:"top-gap_medium",attrs:{"center":"","size":"big"}}):_c('div',{staticClass:"grid layer-1"},[(_vm.globalSearchSelectedCar.id)?_c('InfoMessage',{staticClass:"select-car-info-message"},[_c('p',[_vm._v("\n                Подберите запчасти, совместимые с автомобилем\n                "),_c('CarName',{staticClass:"select-car-info-message__car-name",attrs:{"car":_vm.globalSearchSelectedCar,"block":false}}),_vm._v(".\n                "),_c('ButtonText',{attrs:{"to":{
                        name: 'market-parts-catalogs-manufacturer-modification',
                        params: {
                            manufacturer: _vm.globalSearchSelectedCar.manufacturer.codename,
                            modification: _vm.globalSearchSelectedCar.id
                        },
                        query: Object.assign({}, _vm.carProductionDate ? {
                            production_date: _vm.carProductionDate
                        } : {})
                    },"primary":"","underline":""}},[_vm._v("\n                    Перейти к схемам\n                ")])],1)]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"all-manufacturers"},_vm._l((_vm.itemsByGroups),function(group){return _c('li',{key:'group-' + group.letter,staticClass:"group"},[_c('div',{staticClass:"h2 h2_block letter"},[_vm._v("\n                    "+_vm._s(group.letter)+"\n                ")]),_vm._v(" "),_c('ul',{staticClass:"group__list"},[_c('li',{staticClass:"group__list-item"},_vm._l((group.data),function(manufacturer){return _c('RouterLink',{key:'manufacturer-' + manufacturer.id,staticClass:"manufacturer link-primary-hover",attrs:{"to":{
                                name: 'market-parts-catalogs-manufacturer',
                                params: {
                                    manufacturer: manufacturer.codename
                                }
                            }}},[_c('img',{staticClass:"logo",attrs:{"src":manufacturer.logo,"alt":""}}),_vm._v(" "),_c('span',{staticClass:"name"},[_vm._v(_vm._s(manufacturer.name))])])}),1)])])}),0)],1),_vm._v(" "),_c('LastSee')],1)}
var staticRenderFns = []

export { render, staticRenderFns }