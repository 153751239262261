import deliveryOffices from '@/store/modules/deliveryOffices.js';

export default {
    namespaced: true,

    modules: {
        deliveryOffices,
    },

    state: () => ({
        initialized: false,
        selectedCity: null,
        delivery: {
            offices: {
                fetching: 0,
                loaded: false,
                items: [],
                count: 0,
            },
            cdek: {
                fetching: 0,
                loaded: false,
                estimates: [],
            },
        },
    }),

    getters: {
        hasUbibi(state) {
            const offices = state.delivery.offices.items;
            return offices.some(office => office.service.codename === 'ubibi');
        },

        ubibiPoint(state, getters) {
            if (getters.hasUbibi) {
                const offices = state.delivery.offices.items;
                return offices.find(office => office.service.codename === 'ubibi');
            }

            return null;
        },

        hasCdek(state) {
            const offices = state.delivery.offices.items;
            return offices.some(office => office.service.codename === 'cdek');
        },

        deliveryInfoFetching(state) {
            return state.delivery.offices.fetching > 0 || state.delivery.cdek.fetching > 0;
        },
    },

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setSelectedCity(state, city) {
            state.selectedCity = city;
        },

        setDeliveryOffices(state, offices) {
            state.delivery.offices.items = offices;
        },

        setDeliveryOfficesCount(state, value) {
            state.delivery.offices.count = value;
        },

        deliveryOfficesFetchingIncrement(state) {
            state.delivery.offices.fetching += 1;
        },

        deliveryOfficesFetchingDecrement(state) {
            state.delivery.offices.fetching = Math.max(0, state.delivery.offices.fetching - 1);
        },

        setDeliveryOfficesLoaded(state, value) {
            state.delivery.offices.loaded = value;
        },

        setDeliveryCdekEstimates(state, estimates) {
            state.delivery.cdek.estimates = estimates;
        },

        deliveryCdekFetchingIncrement(state) {
            state.delivery.cdek.fetching += 1;
        },

        deliveryCdekFetchingDecrement(state) {
            state.delivery.cdek.fetching = Math.max(0, state.delivery.cdek.fetching - 1);
        },

        setDeliveryCdekLoaded(state, value) {
            state.delivery.cdek.loaded = value;
        },

        destroy(state) {
            state.initialized = false;
        },
    },

    actions: {
        async init({ commit, dispatch }) {
            try {
                await dispatch('cart/getCart', null, { root: true });

                commit('setInitialized');
                commit('clearHttpError', null, { root: true });
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        /**
         * @param dispatch
         * @param {object} payload
         * @param {array} payload.orders
         * @returns {Promise<void>}
         */
        async updateDeliveryInfo({ dispatch }, payload) {
            await Promise.all([
                dispatch('fetchDeliveryOffices', payload),
                dispatch('fetchDeliveryCdekEstimates', payload),
            ]);
        },

        async fetchDeliveryOffices({ state, commit }) {
            try {
                commit('deliveryOfficesFetchingIncrement');

                const limit = 1000;
                const type = 'pvz';
                const service__codename__in = 'ubibi,cdek';
                const order_by = 'service__order_weight';
                const city = state.selectedCity.id;
                const params = { service__codename__in, order_by, city, type, limit };
                const { results: items, current_count: currentCount } = await this.$api.delivery.offices({ params });

                commit('setDeliveryOffices', items);
                commit('setDeliveryOfficesCount', currentCount);
                commit('setDeliveryOfficesLoaded', true);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                commit('deliveryOfficesFetchingDecrement');
            }
        },

        async fetchDeliveryCdekEstimates({ state, commit }, { orders }) {
            try {
                commit('deliveryCdekFetchingIncrement');

                const requests = orders.map(order => {
                    const weight = order.positions.reduce((acc, position) => {
                        acc += (position.offer.product || position.offer.part_product).weight;
                        return acc;
                    }, 0);

                    const data = {
                        service: 'cdek',
                        shop_from: order.shop.id,
                        city_from: order.shop.city.id,
                        city_to: state.selectedCity.id,
                        weight: weight,
                    };

                    return this.$api.delivery.estimate.byCity(data);
                });

                const estimates = await Promise.all(requests);

                commit('setDeliveryCdekEstimates', estimates);
                commit('setDeliveryCdekLoaded', true);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                commit('deliveryCdekFetchingDecrement');
            }
        },
    },
};