<template>
    <div class="desktop-menu">
        <ul
            v-for="(section, sectionIndex) in menu"
            :key="'info-page-section-' + sectionIndex"
            class="menu-outer-list"
        >
            <li class="menu-outer-item">
                <h2 class="heading">
                    {{ section.name }}
                </h2>

                <ul>
                    <li
                        v-for="(page, pageIndex) in section.pages"
                        :key="'info-page-section-' + sectionIndex + '-page-' + pageIndex"
                        class="menu-inner-item"
                    >
                        <RouterLink
                            v-if="page.type === 'static-pages'"
                            exactActiveClass="menu-inner-link_active"
                            :title="page.name"
                            :to="{
                                name: page.entity.codename,
                            }"
                            :target="page.target_blank ? '_blank' : '_self'"
                            class="menu-inner-link"
                        >
                            {{ page.name }}
                        </RouterLink>

                        <RouterLink
                            v-if="page.type === 'additional-pages'"
                            exactActiveClass="menu-inner-link_active"
                            :title="page.name"
                            :to="{
                                name: 'additional-page',
                                params: {
                                    codename: page.entity.codename,
                                }
                            }"
                            :target="page.target_blank ? '_blank' : '_self'"
                            class="menu-inner-link"
                        >
                            {{ page.name }}
                        </RouterLink>

                        <a
                            v-if="page.type === 'link'"
                            :href="page.url"
                            :title="page.name"
                            class="menu-inner-link"
                            target="_blank"
                            rel="noopener"
                        >
                            {{ page.name }}
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'InfoPageLayoutDesktopMenu',

    props: {
        menu: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped>
@media (min-width: 1281px) {
    .desktop-menu {
        width: var(--col-5);
    }
}

@media (min-width: 1040px) and (max-width: 1280px) {
    .desktop-menu {
        width: var(--col-3);
    }
}
@media (min-width: 1040px) {
    .desktop-menu {
        margin-right: var(--grid-gap);
        margin-top: 8px;
        flex-shrink: 0;
    }
}
@media (max-width: 1039px) {
    .desktop-menu {
        display: none;
    }
}

.heading {
    margin-bottom: 8px;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

.menu-outer-list:not(:last-child) {
    margin-bottom: 20px;
}

.menu-inner-link {
    display: inline-block;
    margin-left: -16px;
    padding: 8px 16px;
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
    border-radius: var(--border-radius);
    transition: color var(--transition), background-color var(--transition);
}

.menu-inner-link_active {
    color: var(--dark-c);
    background-color: var(--light-bg);
}

.menu-inner-link:hover,
.menu-inner-link:focus {
    color: var(--dark-c);
}
</style>