<template>
    <UTooltip noDetach>
        <template #trigger="{ attrs, handlers }">
            <button
                ref="button"
                type="button"
                v-bind="attrs"
                class="chat-btn btn btn_icon"
                :class="{
                    'chat-btn_disabled': !id || disabled,
                    'chat-btn_online': isOnline && !disabled,
                    'chat-btn_with-text': withText,
                    'chat-btn_always-with-text': btnTextAlwaysVisible,
                }"
                :disabled="!id || disabled"
                @click="onClick"
                v-on="handlers"
            >
                <UIcon
                    name="chat-btn-stroke"
                    class="chat-btn__icon"
                ></UIcon>

                <span
                    v-if="withText"
                    class="chat-text"
                    :class="{
                        'chat-text_always-visible': btnTextAlwaysVisible
                    }"
                ><slot>{{ btnText }}</slot></span>
            </button>
        </template>

        <span v-if="disabled">
            Продавец отключил онлайн-чат
        </span>
        <span v-else>
            Написать продавцу в онлайн-чате
        </span>
    </UTooltip>
</template>

<script>
// utils
import bus from '@/bus.js';
// components
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
const AuthorizationPopup = () => import('@/components/popups/AuthorizationPopup.vue');


export default {
    name: 'ChatBtn',

    components: { UIcon, UTooltip },

    props: {
        id: {
            type: [Number, String],
        },

        isOnline: Boolean,

        disabled: {
            type: Boolean,
            default: false,
        },

        btnText: {
            type: String,
            default: 'Чат',
        },

        withText: {
            type: Boolean,
            default: false,
        },

        btnTextAlwaysVisible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    computed: {
        isAuthorized() {
            return this.$store.state.profile.isAuthorized;
        },
    },

    methods: {
        async onClick($event) {
            if (!this.id) return;

            if (this.isAuthorized) {
                this.create();
                this.$emit('click', $event);
            }
            else {
                this.$popup(AuthorizationPopup, {
                    scopedSlots: {
                        title: () => this.$createElement('span', 'Войдите, чтобы начать диалог'),
                        text: () => this.$createElement(
                            'span',
                            'Обращайтесь напрямую с магазинами или задайте вопрос менеджеру Ubibi',
                        ),
                    },
                });
            }
        },

        create() {
            if (!this.isAuthorized) return;

            const shop = { id: this.id };

            bus.$emit('chat:open', { shop });
        },
    },
};
</script>

<style scoped>
.chat-btn {
    position: relative;
    background-color: var(--light-c);
    border: 1px solid var(--border-dark-c);
}

.chat-btn.chat-btn_disabled,
.chat-btn.chat-btn_disabled:hover {
    color: var(--grey-color);
    background-color: var(--light-c);
    border: 1px solid var(--border-light-c);
}

.chat-btn:hover,
.chat-btn:focus {
    color: var(--primary-color);
}

.chat-btn_online::after {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    width: 14px;
    height: 14px;
    border: 2px solid var(--light-c);
    border-radius: 50%;
    background-color: var(--accent-green);
}

@media (min-width: 768px) {
    .chat-btn_with-text {
        padding-left: 16px;
        padding-right: 16px;
    }
}
@media (max-width: 767px) {
    .chat-btn_always-with-text {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.chat-text {
    margin-left: 8px;
    font-size: var(--base-fz);
    font-family: var(--f-semi-bold);
}
@media (max-width: 767px) {
     .chat-text:not(.chat-text_always-visible) {
        display: none;
    }
}

.chat-btn__icon {
    fill: currentColor;
}
</style>