<template>
    <span
        class="car-name"
        :class="{ 'car-name_block': block }"
        :title="`${ nameString } ${ yearString } ${ frameString }`"
    >
        <span class="car-name__main-wrap">
            <span class="car-name__main">{{ nameString }},</span>
            <span class="car-name__year">{{ yearString }}</span>
        </span>
        <span
            v-if="frameString"
            class="car-name__frame"
        > ({{ frameString }})</span>
    </span>
</template>

<script>
import { mainAttrsArrForSelectCarByModel } from '@/settings.js';

export default {
    name: "CarName",

    props: {
        car: {
            type: Object,
            default() {
                return {};
            },
        },

        noManufacturerName: Boolean,

        noFrame: Boolean,

        block: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        nameString() {
            let str = '';

            if (!this.noManufacturerName) {
                str += this.car.manufacturer.name;
            }

            if (this.car.family) {
                str += ` ${ this.car.family.name }`;
            }

            return str;
        },

        yearString() {
            let str = '';

            if (this.car.prod_date && this.car.prod_date.year) {
                str += this.car.prod_date.year;
            }
            else {
                str += `${ this.car.start_year } – ${ this.car.end_year === 9999 || !this.car.end_year ? 'н.в.' : this.car.end_year }`;
            }

            return str;
        },

        frameString() {
            if (this.noFrame) return;

            if (this.car.characteristics) {
                const frameAttr = mainAttrsArrForSelectCarByModel[this.car.manufacturer.codename][0];
                const frame = this.car.characteristics.find(char => char.codename === frameAttr.codename);
                return frame.value;
            }

            return this.car.frame || '';
        },
    },
};
</script>

<style scoped>
.car-name {
    color: var(--font-secondary-color);
}

.car-name_block {
    display: block;
    font-family: var(--f-bold);
}

.car-name__main-wrap {
    color: var(--dark-c);
}

.car-name__year {
    white-space: nowrap;
}
</style>