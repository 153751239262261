<template>
    <div class="info-page-layout__main">
        <InfoPageLayoutHead></InfoPageLayoutHead>

        <transition-group
            tag="div"
            name="fade-page"
            mode="out-in"
            appear
            class="position-relative"
        >
            <Spinner
                v-if="!initialized || loading"
                key="loading"
                size="big"
                class="content-loader"
            ></Spinner>

            <div
                v-else
                key="content"
            >
                <template v-if="current_count">
                    <div
                        v-for="category in items"
                        :key="'category-' + category.id"
                        class="category layer-1"
                    >
                        <h2 class="h3 h3_block">
                            {{ category.name }}
                        </h2>

                        <ul
                            v-if="category.articles.length"
                            class="articles-list"
                        >
                            <li
                                v-for="article in category.articles"
                                :key="'article-' + article.id"
                                class="article"
                            >
                                <RouterLink
                                    :to="{
                                        name: $route.name === 'help-mp' ? 'help-mp-article' : 'help-pp-article',
                                        params: {
                                            codename: article.codename,
                                        },
                                    }"
                                    class="link-primary-hover"
                                >
                                    {{ article.name }}
                                </RouterLink>
                            </li>
                        </ul>
                    </div>
                </template>

                <EmptyBlock
                    v-else
                    icon="document"
                    title="Раздел пока пуст"
                    text="Работаем над созданием и оформлением контента."
                    :hasBtn="false"
                ></EmptyBlock>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import helpCategories from '@/store/modules/helpCategories.js';
import InfoPageLayoutHead from '@/components/InfoPageLayoutHead.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import Spinner from '@/components/Spinner.vue';


export default {
    name: 'HelpCategoriesPage',

    components: { Spinner, EmptyBlock, InfoPageLayoutHead },

    serverPrefetch() {
        const params = {
            service__in: this.$route.name === 'help-mp' ? 'mp' : 'pp',
        };

        return this.INIT({ params });
    },

    computed: {
        ...mapState({
            items: state => state.helpCategories.items,
            current_count: state => state.helpCategories.current_count,
            initialized: state => state.helpCategories.initialized,
            loading: state => state.helpCategories.loading,
        }),
    },

    watch: {
        '$route.name': {
            handler() {
                const params = {
                    service__in: this.$route.name === 'help-mp' ? 'mp' : 'pp',
                };

                this.INIT({ params });
            },
        },
    },

    created() {
        this.$store.registerModule('helpCategories', helpCategories);
    },

    mounted() {
        if (!this.initialized) {
            const params = {
                service__in: this.$route.name === 'help-mp' ? 'mp' : 'pp',
            };

            this.INIT({ params });
        }
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('helpCategories');
    },

    methods: {
        ...mapMutations({
            DESTROY: 'helpCategories/destroy',
        }),

        ...mapActions({
            INIT: 'helpCategories/init',
        }),
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .category {
        padding: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .category {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .category {
        padding: 20px;
    }
}
.category:not(:last-child) {
    margin-bottom: var(--y-grid-gap);
}

@media (min-width: 1160px) {
    .articles-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1159px) {
    .articles-list {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) {
    .articles-list {
        display: grid;
        grid-gap: 16px 20px;
    }
}

@media (max-width: 767px) {
    .article:not(:last-child) {
        margin-bottom: 16px;
    }
}

@media (min-width: 1040px) {
    .content-loader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (max-width: 1039px) {
    .content-loader {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>