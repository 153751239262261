<template>
    <UInputField
        v-bind="attrs"
        v-on="listeners"
    >
        <template #append-inner="{ clear }">
            <USpinner
                v-if="loading"
                key="spinner"
                small
            ></USpinner>

            <button
                v-else-if="value"
                key="close"
                type="button"
                tabindex="-1"
                class="input-search__button"
                @click="clear(); $emit('click:clear')"
                @mousedown.stop=""
            >
                <UIcon
                    name="close-round-hollow-16"
                    small
                    class="input-search__icon"
                ></UIcon>
            </button>
        </template>
    </UInputField>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UInputField from '@ui/components/UInput/UInputField.vue';


export default {
    name: 'InputSearch',

    components: {
        USpinner,
        UInputField,
        UIcon,
    },

    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'change:model',
    },

    props: {
        value: String,
        loading: Boolean,
    },

    computed: {
        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { input, ...listeners } = this.$listeners;

            return {
                ...listeners,
                input: this.onInput,
            };
        },

        attrs() {
            const value = this.value;
            return { ...this.$attrs, value };
        },
    },

    methods: {
        onInput($event) {
            this.$emit('change:model', $event.value);
            this.$emit('input', $event);
        },
    },
};
</script>

<style>
.input-search__button {
    display: flex;
    align-items: center;
}

.input-search__icon {
    fill: var(--font-secondary-light-color);
}
.input-search__button:hover .input-search__icon {
    fill: var(--font-secondary-color);
}
</style>