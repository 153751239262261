<template>
    <PopupWrap
        fromBottomOnXS
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <CarName
                :car="car"
                class="h4 car-name"
            ></CarName>
            <CarCharacteristicsTable
                :car="car"
                small
            ></CarCharacteristicsTable>
        </div>
    </PopupWrap>
</template>

<script>
import PopupWrap from '@/components/popups/PopupWrap.vue';
import popup from '@/mixins/popup.js';
import breakpointKey from '@/mixins/breakpointKey.js';
import CarCharacteristicsTable from '@/components/CarCharacteristicsTable.vue';
import CarName from '@/components/CarName.vue';

export default {
    name: 'CarCharacteristicsPopup',

    components: { CarName, CarCharacteristicsTable, PopupWrap },

    mixins: [popup, breakpointKey],

    props: {
        car: {
            type: Object,
            default: () => {},
        },
    },

    watch: {
        breakpointKey(value) {
            if (value !== 'xs' && value !== 's') {
                this.close();
            }
        },
    },
};
</script>

<style scoped>
.car-name {
    margin-right: 56px;
    margin-bottom: 16px;
}
</style>