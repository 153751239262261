<template>
    <p class="market-offers-price-info">
        <template v-if="amount">
            {{ amountComputed }}
            <span class="hidden-s">
                <template
                    v-if="max !== min"
                >от&nbsp;</template>
                <template
                    v-else
                >за&nbsp;</template>{{ min | price }}
            </span>
        </template>
        <template v-else>
            Нет предложений
        </template>
    </p>
</template>

<script>
import plural from '../lib/plural.js';

export default {
    name: "MarketOffersPriceInfo",

    props: {
        amount: {
            type: Number,
            default: 0,
        },

        min: {
            type: Number,
            default: null,
        },

        max: {
            type: Number,
            default: null,
        },
    },

    computed: {
        amountComputed() {
            return this.amount.valueOf()+ ' ' + plural(this.amount, ['предложение', 'предложения', 'предложений']);
        },
    },
};
</script>

<style scoped>
.market-offers-price-info {
    display: inline-block;
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
</style>