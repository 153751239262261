<template>
    <div class="top-gap_medium">
        <h1 class="h1 h1_text">
            {{ computedTitle }}
        </h1>

        <p
            v-if="status === 'success' && paymentType === 'order'"
            class="after-payment__description"
        >
            <template v-if="orders.count === 1">
                Дождитесь подтверждения заказа от магазина. Вы можете самостоятельно отслеживать изменение статуса заказа
            </template>
            <template v-else>
                Дождитесь подтверждения заказов от магазинов. Вы можете самостоятельно отслеживать изменение статусов заказов
            </template>
            в <ButtonText
                :to="{
                    name: 'account-orders'
                }"
                underline
                primary
                class="d-inline-block"
            >личном кабинете</ButtonText>. Если потребуются уточнения, мы&nbsp;свяжемся с&nbsp;вами по&nbsp;телефону и&nbsp;электронной почте.
        </p>
        <p
            v-if="status === 'success' && (paymentType === 'yandex-delivery' || paymentType === 'world-delivery')"
            class="after-payment__description"
        >
            Вы можете самостоятельно отслеживать изменение статусов и трекинг своих заказов в <ButtonText
                :to="{
                    name: 'account-orders'
                }"
                underline
                primary
                class="d-inline-block"
            >личном кабинете</ButtonText>. Если потребуются уточнения, мы&nbsp;свяжемся с&nbsp;вами по&nbsp;телефону и&nbsp;электронной почте.
        </p>
        <p
            v-if="status === 'failed'"
            class="after-payment__description"
        >
            Во время оплаты произошла ошибка. <br>
            Если проблема возникла не по вашей вине,
            <ButtonText
                dashed
                primary
                class="d-inline-block"
                @click="showWriteUsPopup"
            >напишите нам.</ButtonText>
        </p>

        <div class="after-payment__content">
            <ul v-if="paymentType === 'order'">
                <li
                    v-for="(order, orderIndex) in orders.items"
                    :key="'cart-order' + orderIndex"
                    class="cart-orders-list__item layer-1"
                >
                    <RouterLink
                        :to="{
                            name: 'account-order-item',
                            params: {
                                uuid: order.uuid
                            }
                        }"
                        class="order-head"
                    >
                        <h3 class="h3">
                            Заказ № {{ order.number }}
                        </h3>
                    </RouterLink>

                    <div class="order-body">
                        <Washboard>
                            <div
                                v-for="(marketItem, marketItemIndex) in order.positions"
                                :key="'order-market-item' + marketItemIndex"
                            >
                                <MarketItemCheckout
                                    :item="marketItem"
                                    coverSmall
                                ></MarketItemCheckout>
                            </div>

                            <div>
                                <p class="mb-4 text_bold">
                                    Получатель:
                                </p>
                                <p>
                                    {{ [order.last_name, order.first_name, order.patronymic].filter(_ => _).join(' ') }}, {{ order.phone }}, {{ order.email }}
                                </p>
                            </div>

                            <div v-if="order.comment">
                                <p class="mb-4 text_bold">
                                    Комментарий для магазина:
                                </p>
                                <p class="p-textarea">{{ order.comment }}</p>
                            </div>

                            <div v-if="order.delivery_service">
                                <p class="mb-4 text_bold">
                                    Способ получения:
                                </p>

                                <p v-if="order.delivery_service.codename === 'ubibi'">
                                    Пункт выдачи Ubibi ({{ order.pickup_point.city }}, {{ order.pickup_point.address }})
                                </p>

                                <p v-else-if="order.delivery_service.codename === 'cdek'">
                                    СДЭК ({{ order.delivery_city.full_name }})
                                </p>
                            </div>

<!--                            <div v-if="order.delivery_type === 'pickup_point'">-->
<!--                                <p class="mb-4 text_bold">-->
<!--                                    Пункт выдачи / постамат:-->
<!--                                </p>-->

<!--                                <p>-->
<!--                                    {{ order.pickup_point.city }}, {{ order.pickup_point.address }}-->
<!--                                </p>-->
<!--                            </div>-->
                        </Washboard>
                    </div>

                    <div class="order-footer">
                        <div class="order-total-price-block">
                            <span class="order-total-price-block__key">{{ order.total_amount | plural(['товар', 'товара', 'товаров']) }} на сумму:</span>
                            <span class="order-total-price-block__value">{{ order.total_price | price }}</span>
                        </div>
                    </div>
                </li>
            </ul>

            <div
                v-if="paymentType === 'yandex-delivery'"
                class="layer-1"
            >
                <div class="order-head">
                    <h3 class="h3">
                        Доставка заказа № {{ yandexDelivery.order.number }}
                    </h3>
                </div>

                <div class="order-body">
                    <Washboard>
                        <div>
                            <p class="text_bold">
                                Доставка по городу (Яндекс&nbsp;Go)
                            </p>
                        </div>

                        <div class="point point_a">
                            <div class="point__content">
                                <p class="text_bold mb-4">
                                    {{ yandexDelivery.order.shop.company.name }}
                                </p>
                                <p>{{ yandexDelivery.address_from }}</p>
                            </div>

                            <ShopLogo
                                :shop="yandexDelivery.order.shop"
                                coverCodename="medium"
                                class="point__shop-logo"
                            ></ShopLogo>
                        </div>

                        <div class="point point_b">
                            <div class="point__content">
                                <p class="text_bold mb-4">
                                    Адрес доставки:
                                </p>
                                <p>{{ yandexDelivery.address_to }}</p>
                            </div>
                        </div>

                        <div>
                            <div>
                                <p class="text_bold mb-4">
                                    Тариф:
                                </p>
                                <p>{{ dictionary.taxi_class.obj[yandexDelivery.taxi_class].name }}</p>
                            </div>

                            <div
                                v-if="yandexDelivery.cargo_loaders"
                                class="mt-16"
                            >
                                <p class="text_bold mb-4">
                                    Дополнительно:
                                </p>
                                <p>{{ cargoLoadersText }}</p>
                            </div>
                        </div>

                        <div v-if="yandexDelivery.user_comment">
                            <p class="text_bold mb-4">
                                Комментарий для курьера:
                            </p>
                            <p class="p-textarea">{{ yandexDelivery.user_comment }}</p>
                        </div>
                    </Washboard>
                </div>

                <div class="order-footer">
                    <div class="order-total-price-block">
                        <span class="order-total-price-block__key">Стоимость доставки:</span>
                        <span class="order-total-price-block__value">{{ yandexDelivery.price_payment | price }}</span>
                    </div>
                </div>
            </div>

            <div
                v-if="paymentType === 'world-delivery'"
                class="layer-1"
            >
                <div class="order-head">
                    <h3 class="h3">
                        Доставка заказа № {{ worldDelivery.orderNumber }}
                    </h3>
                </div>

                <div class="order-body">
                    <Washboard>
                        <div class="flex align-items-center justify-content-between">
                            <p class="text_bold">
                                {{ worldDelivery.service.name }} ({{ worldDelivery.city_to }})
                            </p>

                            <img
                                v-if="worldDelivery.service.logo"
                                :src="$links.uploads + worldDelivery.service.logo.thumbnails.delivery_service_logo_detail"
                                alt=""
                                class="transport__logo"
                            >
                        </div>

                        <div v-if="worldDelivery.office_to">
                            <p class="text_bold mb-4">
                                Пункт выдачи / постамат ({{ worldDelivery.approximate_delivery_date }}):
                            </p>
                            <p>{{ worldDelivery.office_to.address_full }}</p>
                        </div>

                        <div v-else-if="worldDelivery.address_to">
                            <p class="text_bold mb-4">
                                Доставка курьером ({{ worldDelivery.approximate_delivery_date }}):
                            </p>
                            <p>{{ worldDelivery.address_to }}</p>
                        </div>

                        <div v-if="worldDelivery.user_comment">
                            <p class="text_bold mb-4">
                                Комментарий для службы доставки:
                            </p>
                            <p class="p-textarea">{{ worldDelivery.user_comment }}</p>
                        </div>
                    </Washboard>
                </div>

                <div
                    v-if="paymentPrice"
                    class="order-footer"
                >
                    <div class="order-total-price-block">
                        <span class="order-total-price-block__key">Стоимость доставки:</span>
                        <span class="order-total-price-block__value">{{ paymentPrice | price }}</span>
                    </div>
                </div>
            </div>
        </div>

        <ButtonBlock
            v-if="status === 'failed' && paymentType === 'order'"
            primary
            class="after-payment__btn"
            :loading="paymentLoading"
            @click="createPayment"
        >
            Повторить оплату
        </ButtonBlock>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { dt, plural } from '@ui/filters';
import payment from '@/store/modules/payment.js';
import { getErrorCode } from '@/lib/errors.js';
import MarketItemCheckout from '../components/MarketItemCheckout.vue';
import ShopLogo from '../components/ShopLogo.vue';
import dictionary from '@/dictionary.js';
import Washboard from '../components/Washboard.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
const WriteUsPopup = () => import('@/components/popups/WriteUsPopup.vue');

export default {
    name: 'AfterPaymentContent',

    filters: {
        dt,
        plural,
    },

    components: {
        ButtonText,
        ButtonBlock,
        Washboard,
        ShopLogo,
        MarketItemCheckout,
    },

    metaInfo() {
        return {
            title: this.computedTitle,
        };
    },

    props: {
        status: {
            type: String,
            default: '',
            validator(value) {
                return ['success', 'failed'].includes(value);
            },
        },
    },

    serverPrefetch() {
        this.$store.registerModule('payment', payment);
        return this.INIT({ query: this.$route.query });
    },

    data() {
        return {
            dictionary,
            paymentLoading: false,
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.payment.initialized,
            paymentType: state => state.payment.paymentType,
            paymentPrice: state => state.payment.paymentPrice,
            orders: state => state.payment.orders,
            yandexDelivery: state => state.payment.yandexDelivery,
            worldDelivery: state => state.payment.worldDelivery,
        }),

        computedTitle() {
            return this.status === 'success' ? 'Оплата прошла успешно' : 'Оплата не прошла';
        },

        cargoLoadersText() {
            if (this.paymentType === 'yandex-delivery' && this.yandexDelivery.cargo_loaders) {
                let cargoLoadersText;
                switch (this.yandexDelivery.cargo_loaders) {
                case 1:
                    cargoLoadersText = 'Помощь одного грузчика';
                    break;
                case 2:
                    cargoLoadersText = 'Помощь двух грузчиков';
                    break;
                case 3:
                    cargoLoadersText = 'Помощь трёх грузчиков';
                    break;
                default:
                    cargoLoadersText = '';
                }
                return cargoLoadersText;
            }
            else {
                return '';
            }
        },
    },

    created() {
        this.$store.registerModule('payment', payment, { preserveState: true });
    },

    mounted() {
        this.$breadcrumbs([]);
        if (!this.initialized) this.INIT({ query: this.$route.query });
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('payment');
    },

    methods: {
        ...mapActions({
            INIT: 'payment/afterPaymentInit',
        }),

        ...mapMutations({
            DESTROY: 'payment/destroy',
        }),

        showWriteUsPopup() {
            this.$popup(WriteUsPopup);
        },

        async createPayment() {
            this.paymentLoading = true;
            try {
                const { payment_uuid } = await this.$api.orders.pay(this.order.uuid);
                this.$router.push({
                    name: 'before-payment',
                    params: {
                        uuid: payment_uuid,
                    },
                });
            }
            catch (error) {
                const code = getErrorCode(error);
                const data = error.response.data;

                if (code === 400) {
                    const { non_field_errors } = data;
                    if (non_field_errors) {
                        this.$error(non_field_errors[0].message);
                    }
                    else {
                        this.$error('Не удалось получить ссылку на оплату.');
                    }
                }
                else if (code === 404) {
                    this.$error('Запрашиваемый адрес не существует.');
                }
                else if (code === 500) {
                    this.$error(data.detail);
                }
            }
            this.paymentLoading = false;
        },
    },
};
</script>

<style scoped>
@media (min-width: 1281px) {
    .after-payment__content {
        width: 892px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .after-payment__content {
        width: 880px;
    }
}

.after-payment__description {
    margin-bottom: var(--gap-y-mini);
    line-height: var(--base-lh);
}
@media (min-width: 1281px) {
    .after-payment__description {
        width: 892px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .after-payment__description {
        width: 880px;
    }
}

.cart-orders-list__item:not(:last-child) {
    margin-bottom: 20px;
}

.order-head {
    display: block;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .order-head {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .order-head {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .order-head {
        padding: 16px;
    }
}

.order-footer {
    border-top: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .order-footer {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .order-footer {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .order-footer {
        padding: 16px;
    }
}

@media (min-width: 768px) {
    .order-shop {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.order-shop__logo {
    margin-left: 20px;
}
@media (max-width: 767px) {
    .order-shop__logo.cover-wrap {
        display: none;
    }
}

.order-total-price-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (min-width: 768px) {
    .order-total-price-block {
        justify-content: flex-end;
        text-align: right;
    }
}

.order-total-price-block__value {
    font-size: 20px;
    font-family: var(--f-bold);
}
@media (min-width: 768px) {
    .order-total-price-block__value {
        margin-left: 20px;
    }
}

.point {
    display: flex;
    align-items: flex-start;
}

.point::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 12px;
    margin-top: 2px;
    flex-shrink: 0;
    border: 4px solid;
    border-radius: 50%;
}

.point_a::before {
    border-color: var(--font-secondary-dark-color);
}

.point_b::before {
    border-color: var(--primary-color);
}

.point__shop-logo {
    width: 72px;
    height: 48px;
    margin-left: auto;
}

.after-payment__btn {
    margin-top: var(--gap-y-small);
}
@media (max-width: 767px) {
    .after-payment__btn {
        width: 100%;
    }
}

.transport__logo {
    width: 72px;
    height: 48px;
    margin-left: 20px;
    flex-shrink: 0;
}
@media (max-width: 375px) {
    .transport__logo {
        display: none;
    }
}
</style>