<template>
    <div class="insurance-payment-success">
        <h1 class="h1 h1_text">
            Оплата прошла успешно
        </h1>

        <p class="page-descriptor">
            <template v-if="contract.status === 'completed'">
                Полис сформирован страховой компанией и&nbsp;доступен для скачивания в&nbsp;вашем
            </template>
            <template v-else>
                В&nbsp;течение 10&nbsp;минут полис будет сформирован страховой компанией 
                и&nbsp;станет доступен для скачивания в&nbsp;вашем
            </template>
            <ButtonText
                primary
                underline
                :to="{
                    name: 'account-insurance'
                }"
            >
                личном кабинете
            </ButtonText>. За&nbsp;несколько дней до&nbsp;окончания периода действия полиса, 
            мы&nbsp;отправим вам уведомление.
        </p>

        <div class="info-container layer-1">
            <div class="info-container__main">
                <div class="police">
                    <h3 class="h3">
                        Полис ОСАГО <span v-if="offer.policy_number">№ {{ offer.policy_number }}</span>
                    </h3>

                    <div class="company">
                        <InsuranceCompanyLogo
                            :logo="company.logo"
                            :name="company.page_title"
                            class="company__logo-wrap"
                        ></InsuranceCompanyLogo>

                        <div class="company__text-data">
                            <h4 class="company__name">
                                {{ company.name }}
                            </h4>

                            <p class="company__license">
                                {{ company.license }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="data">
                    <div class="data__column">
                        <h4 class="data__label">
                            Срок действия полиса:
                        </h4>
                        <p class="data__text">
                            {{ contract.action_start_date | date }} – {{ contract.action_end_date | date }}
                        </p>
                    </div>

                    <div class="data__column">
                        <h4 class="data__label">
                            Автомобиль:
                        </h4>
                        <p class="data__text">
                            {{ contract.vehicle_model.fullname }}, 
                            {{ contract.vehicle_year }} ({{ contract.vehicle_engine_power }} л. с.)
                            <template v-if="contract.vehicle_license_plate">
                                <br>
                                {{ contract.vehicle_license_plate | carNumber }}
                            </template>
                        </p>
                    </div>

                    <div class="data__column">
                        <h4 class="data__label">
                            Страхователь:
                        </h4>
                        <p class="data__text">
                            {{ contract.insurer_last_name }} 
                            {{ contract.insurer_first_name }} {{ contract.insurer_middle_name }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="info-container__total">
                <span class="total__text">Стоимость полиса:</span>
                <span class="total__price">{{ offer.price | price }}</span>
            </div>
        </div>
    </div>
</template>

<script>
// store
import insuranceOsago from '@/store/modules/insuranceOsago.js';
import { mapState, mapActions } from 'vuex';
// components
import InsuranceCompanyLogo from '@/pages/insurance/InsuranceCompanyLogo.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';


export default {
    name: 'InsuranceCheckoutSuccessPage',

    components: {
        ButtonText,
        InsuranceCompanyLogo,
    },

    metaInfo() {
        return {
            title: 'Оплата прошла успешно',
        };
    },

    serverPrefetch() {
        this.$store.registerModule('insuranceOsago', insuranceOsago);

        return this.serverPrefetch({ uuid: this.uuid });
    },

    computed: {
        ...mapState({
            contract: state => state.insuranceOsago.contract,
            offer: state => (state.insuranceOsago.contract.active || {}).offer_obj || {},
            company: state => state.insuranceOsago.contract.active.company || {},
        }),

        uuid() {
            return this.$route.params.uuid;
        },
    },

    watch: {
        uuid: {
            handler() {
                this.createBreadcrumbs();
            },

            immediate: true,
        },
    },

    beforeMount() {
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago, { preserveState: true });
        }
    },

    mounted() {
        this.init();
        this.createBreadcrumbs();
    },

    methods: {
        ...mapActions({
            serverPrefetch: 'insuranceOsago/serverPrefetch',
            browserPrefetch: 'insuranceOsago/browserPrefetch',
        }),

        async init() {
            await this.browserPrefetch({ uuid: this.uuid });
        },

        createBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: this.$route.name,
                        params: this.$route.params,
                    },
                    title: 'Оплата прошла успешно',
                },
            ]);
        },
    },
};
</script>

<style scoped>
.insurance-payment-success {
    margin-top: var(--gap-y-medium);
}

.page-descriptor {
    margin-bottom: var(--gap-y-mini);
    line-height: var(--base-lh);
}
@media (min-width: 1281px) {
    .page-descriptor {
        width: var(--col-8);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .page-descriptor {
        width: 880px;
    }
}

.info-container {
    max-width: 880px;
}

.info-container__main {
    padding: 24px;
    border-bottom: 1px solid var(--border-light-c);
}

.police {
    padding-bottom: var(--gap-y-mini);
    border-bottom: 1px solid var(--border-light-c);
}

.company {
    margin-top: 20px;
}
@media (min-width: 768px) {
    .company {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }

    .company__text-data {
        margin-right: 20px;
    }
}

.company__name {
    margin-bottom: 4px;
    font-family: var(--f-semi-bold);
}

.company__license {
    color: var(--font-secondary-color);
}

@media (max-width: 767px) {
    .company__logo-wrap {
        margin-bottom: 8px;
    }
}

.data {
    margin-top: var(--gap-y-mini);
}
@media (min-width: 768px) {
    .data {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }
}
@media (max-width: 767px) {
    .data__column:not(:last-child) {
        margin-bottom: 20px;
    }
}

.data__label {
    display: block;
    margin-bottom: 4px;
    font-family: var(--f-semi-bold);
}

.info-container__total {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .info-container__total {
        padding: 24px;
        justify-content: flex-end;
        text-align: right;
    }
}
@media (max-width: 767px) {
    .info-container__total {
        justify-content: space-between;
        padding: 20px 24px 24px;
    }
}

.total__price {
    margin-left: 20px;
    font-size: var(--h3-fz);
    font-family: var(--f-bold);
}
</style>