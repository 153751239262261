export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        // newPartOffers: {
        //     items: [],
        //     current_count: 0,
        // },
        // contractPartOffers: {
        //     items: [],
        //     current_count: 0,
        // },
        reviews: {
            items: [],
            current_count: 0,
            amounts: [],
        },
        applicability: {},
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        // setNewPartOffers(state, { current_count, results }) {
        //     state.newPartOffers.items = results;
        //     state.newPartOffers.current_count = current_count;
        // },
        //
        // setContractPartOffers(state, { current_count, results }) {
        //     state.contractPartOffers.items = results;
        //     state.contractPartOffers.current_count = current_count;
        // },

        setReviews(state, { current_count, results }) {
            state.reviews.items = results;
            state.reviews.current_count = current_count;
        },

        setReviewsAmounts(state, amounts) {
            state.reviews.amounts = amounts;
        },

        setApplicability(state, { facets }) {
            const manufacturers = facets.manufacturers;
            if (manufacturers.length) {
                const families = facets.families;

                let applicability = {};
                manufacturers.families = [];
                manufacturers.forEach(item => {
                    applicability[item.id] = {
                        manufacturer: {
                            name: item.name,
                            id: item.id,
                        },
                        families: [],
                    };
                });

                families.forEach(item => {
                    applicability[item.manufacturer_id].families.push({
                        name: item.name,
                        id: item.id,
                    });
                });
                state.applicability = applicability;
            }
            else {
                state.applicability = null;
            }
        },

        // changeInCartStatus(state, { index }) {
        //     state.newPartOffers.items[index].in_basket = true;
        // },

        destroy(state) {
            state.initialized = false;
            // state.newPartOffers = {
            //     items: [],
            //     current_count: 0,
            // };
            // state.contractPartOffers = {
            //     items: [],
            //     current_count: 0,
            // };
            state.reviews = {
                items: [],
                current_count: 0,
                amounts: [],
            };
            state.applicability = {};
        },
    },

    actions: {
        async init({ commit, dispatch, rootState }, { id }) {
            try {
                const [reviews, reviewsAmounts, applicability] = await Promise.all(
                    [
                        // dispatch('getNewPartOffers', { id }),
                        // dispatch('getContractPartOffers', { id, oem }),
                        dispatch('getReviews', { id }),
                        dispatch('getReviewsAmounts', { id }),
                        dispatch('getApplicability', rootState.marketNewPart.newPart),
                    ],
                );

                commit('setReviews', reviews);
                commit('setReviewsAmounts', reviewsAmounts);
                commit('setApplicability', applicability);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { reviews, reviewsAmounts, applicability };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        // async getNewPartOffers({ rootState }, { id }) {
        //     const params = {
        //         part_product: id,
        //         in_stock: true,
        //         get_facets: 'all',
        //         limit: 5,
        //         offset: 0,
        //         order_by: 'price',
        //         city: rootState.cities.currentCityId,
        //     };
        //
        //     return await getPartOffers({ params });
        // },
        // async getContractPartOffers({ rootState }, { id }) {
        //     const params = {
        //         original_part: id,
        //         get_facets: 'all',
        //         limit: 3,
        //         offset: 0,
        //         order_by: 'price',
        //         city: rootState.cities.currentCityId,
        //     };
        //
        //     return await getContractParts({ params });
        // },

        async getReviews({}, { id }) {
            const params = {
                limit: 3,
                offset: 0,
                order_by: '-create_date',
            };

            return await this.$api.parts.reviews.get(id, { params });
        },

        async getReviewsAmounts({}, { id }) {
            return await this.$api.parts.reviews.amounts(id);
        },

        async updateReviews({ dispatch, commit }, { id }) {
            try {
                const [reviews, reviewsAmounts] = await Promise.all(
                    [
                        dispatch('getReviews', { id }),
                        dispatch('getReviewsAmounts', { id }),
                    ],
                );
                commit('setReviews', reviews);
                commit('setReviewsAmounts', reviewsAmounts);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getApplicability({}, { is_original, sku, originals }) {
            let params = {
                get_facets: 'manufacturers,families',
                limit: 0,
                offset: 0,
            };

            if (is_original) params.oem = sku;
            else params.part_product__in = originals.join('__');

            return await this.$api.oems.get({ params });
        },
    },
};