<template>
    <div class="filter-widget-wrap_outer">
        <div
            :class="[
                withAdaptation && 'filter-widget-wrap_desktop'
            ]"
        >
            <div
                :class="[
                    'filter-widget-wrap',
                    hasBorder && 'filter-widget-wrap_has-border',
                ]"
            >
                <div
                    v-if="title || canCollapse"
                    :class="[
                        'head',
                        canCollapse && 'head_clickable',
                    ]"
                    @click="toggleWidget"
                >
                    <div class="title-hint-wrap">
                        <h4 class="filter-widget-wrap__title">
                            {{ title }}
                        </h4>

                        <UTooltip v-if="hint &&!isHintEmpty">
                            <template #trigger="{ value, attrs, handlers }">
                                <UTooltipIcon
                                    class="ml-8"
                                    :active="value"
                                    v-bind="attrs"
                                    v-on="handlers"
                                ></UTooltipIcon>
                            </template>

                            <WysiwygView
                                :value="hint"
                                smallSize
                            ></WysiwygView>
                        </UTooltip>
                    </div>

                    <UIcon
                        v-if="canCollapse"
                        name="arrow-down"
                        small
                        secondary
                        hovered
                        :class="[
                            'icon',
                            isOpen && 'icon_rotate',
                        ]"
                    ></UIcon>
                </div>

                <TransitionExpand>
                    <div
                        v-if="isOpen"
                        key="filter-widget"
                        class="filter-widget__content"
                        :class="{
                            'filter-widget__content_without-head': !title && !canCollapse,
                        }"
                    >
                        <slot></slot>
                    </div>
                </TransitionExpand>
            </div>
        </div>

        <div
            v-if="withAdaptation"
            class="filter-widget-wrap_mobile"
        >
            <div class="filter-widget-wrap">
                <div class="filter-widget-wrap__main">
                    <div
                        class="head head_clickable"
                        @click="openWidget"
                    >
                        <div>
                            <div class="title-hint-wrap">
                                <h4 class="filter-widget-wrap__title">
                                    {{ title }}
                                </h4>

                                <UTooltip
                                    v-if="hint &&!isHintEmpty"
                                    showOnClick
                                >
                                    <template #trigger="{ value, attrs, handlers }">
                                        <UTooltipIcon
                                            :id="`filter-widget-${ codename }`"
                                            class="ml-8"
                                            :active="value"
                                            v-bind="attrs"
                                            v-on="handlers"
                                        ></UTooltipIcon>
                                    </template>

                                    <WysiwygView
                                        :value="hint"
                                        smallSize
                                    ></WysiwygView>
                                </UTooltip>
                            </div>
                            <div
                                v-if="widget === 'categories'"
                                class="mt-4"
                            >
                                {{ value.id ? value.name : 'Все автотовары' }}
                            </div>
                        </div>

                        <UIcon
                            name="arrow-right"
                            small
                            secondary
                            hovered
                            class="icon"
                        ></UIcon>
                    </div>

                    <div
                        v-if="hasResetBtn && value.length"
                        class="head__value-wrap"
                    >
                        <ButtonPill
                            v-for="(v, index) in value"
                            :key="'value-' + index"
                            class="head__value-btn"
                            @click="$emit('deleteFilter', v)"
                        >
                            {{ v.name }}
                        </ButtonPill>
                    </div>
                </div>

                <Portal to="mobileFilter">
                    <transition name="menu-back">
                        <div
                            v-if="isOpen"
                            class="menu-back"
                        ></div>
                    </transition>

                    <transition name="menu">
                        <div
                            v-if="isOpen"
                            class="filter-widget-wrap__second"
                        >
                            <div class="second-view__head">
                                <div class="second-view__head-btn-wrap">
                                    <ButtonIcon
                                        :secondary="false"
                                        :icon="{
                                            name: 'arrow-left',
                                            big: true,
                                        }"
                                        type="button"
                                        class="second-view__head-btn second-view__head-btn_back"
                                        @click="closeFilter"
                                    ></ButtonIcon>
                                </div>

                                <h3 class="h4 text_center ellipsis">
                                    {{ title }}
                                </h3>

                                <div class="second-view__head-btn-wrap second-view__head-btn-wrap_shrink"></div>
                            </div>

                            <div
                                v-if="hasResetBtn"
                                class="second-view__summary"
                            >
                                <span
                                    v-if="value.length"
                                    class="text_secondary text_semi-bold"
                                >Выбрано: {{ value.length }}</span>
                                <span
                                    v-else
                                    class="text_secondary text_semi-bold"
                                >Ничего не выбрано</span>
                                <ButtonText
                                    v-if="value.length"
                                    dashed
                                    primary
                                    @click="reset"
                                >Сбросить</ButtonText>
                            </div>

                            <div class="second-view__content">
                                <slot></slot>
                            </div>

                            <div class="second-view__footer">
                                <ButtonBlock
                                    primary
                                    w100
                                    @click="closeFilter"
                                >
                                    Готово
                                </ButtonBlock>
                            </div>
                        </div>
                    </transition>
                </Portal>
            </div>
        </div>
    </div>
</template>

<script>
import eventPath from '@/lib/eventPath.js';
import breakpointKey from '@/mixins/breakpointKey.js';
import WysiwygView from './views/WysiwygView.vue';
import TransitionExpand from './_transitions/TransitionExpand.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonPill from '@/components/_buttons/ButtonPill.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


export default {
    name: 'FilterWidgetWrap',

    components: {
        UTooltipIcon,
        UTooltip,
        UIcon,
        ButtonBlock,
        ButtonIcon,
        ButtonPill,
        ButtonText,
        TransitionExpand,
        WysiwygView,
    },

    mixins: [breakpointKey],

    props: {
        codename: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        hint: {
            type: String,
            default: '',
        },

        hasBorder: {
            type: Boolean,
            default: false,
        },

        value: {
            type: Array,
            default: () => [],
        },

        widget: {
            type: String,
            default: '',
        },

        loading: Boolean,

        withAdaptation: Boolean,

        canCollapse: {
            type: Boolean,
            default: false,
        },

        hasResetBtn: {
            type: Boolean,
            default: true,
        },

        collapseOnMount: Boolean,
    },

    data() {
        return {
            isOpen: false,
            showLocalPreloader: false,
            initialValueForSecondView: 0,
        };
    },

    computed: {
        isHintEmpty() {
            return this.hint.toString() === '<p>​</p>';
        },
    },

    watch: {
        breakpointKey() {
            if (this.withAdaptation) {
                this.checkWindowWidth();
            }
        },
    },

    mounted() {
        this.initialValueForSecondView = this.value.length;
        if (this.withAdaptation) {
            if (this.breakpointKey !== 's' && !this.isOpen && !this.collapseOnMount) this.isOpen = true;
            this.checkWindowWidth();
        }
        else {
            if (!this.collapseOnMount) this.isOpen = true;
        }
    },

    methods: {
        checkWindowWidth() {
            if (this.breakpointKey !== 's' && !this.isOpen) this.isOpen = true;
            else if (this.breakpointKey === 's' && this.isOpen) this.isOpen = false;
        },

        toggleWidget() {
            if (this.canCollapse) this.isOpen = !this.isOpen;
        },

        openWidget($event) {
            const path = eventPath($event);
            if (path) {
                const isTooltip = path.some(el => {
                    return el && el.id === `filter-widget-${ this.codename }`;
                });

                if (!isTooltip) {
                    this.isOpen = true;
                    this.initialValueForSecondView = this.value.length;
                }
            }
        },

        closeFilter() {
            if (this.initialValueForSecondView !== this.value.length && !this.showLocalPreloader) {
                this.showLocalPreloader = true;
                setTimeout(() => {
                    this.isOpen = false;
                    this.showLocalPreloader = false;
                    this.initialValueForSecondView = this.value.length;
                }, 500);
            }
            else {
                this.isOpen = false;
            }
        },

        reset() {
            this.$emit('reset');
        },
    },
};
</script>

<style scoped>
.menu-enter,
.menu-leave-to {
    transform: translateX(100%);
}
.menu-enter-active {
    transition: all var(--transition) .1s;
}
.menu-leave-to {
    transition: all var(--transition);
}

.menu-back-enter-active,
.menu-back-leave-to {
    opacity: 1;
    transition: all var(--transition-slow);
}
.menu-back-enter,
.menu-back-leave-to {
    opacity: 0;
}

.menu-back {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
}

@media (min-width: 768px) {
    .filter-widget-wrap {
        border-left: 3px solid transparent;
    }

    .filter-widget-wrap_has-border {
        border-left: 3px solid var(--primary-color);
    }
}

@media (min-width: 1040px) {
    .filter-widget-wrap_outer:not(:last-child) {
        border-bottom: 1px solid var(--border-light-c);
    }
}
@media (max-width: 1039px) {
    .filter-widget-wrap_outer {
        border-bottom: 1px solid var(--border-light-c);
    }
}

.head {
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .head {
        padding: 20px 20px 20px 17px;
    }
}
@media (max-width: 767px) {
    .head {
        padding: 16px var(--content-gap);
    }
}

.head_clickable {
    cursor: pointer;
}

.head__value-wrap {
    margin-top: -4px;
    margin-bottom: 4px;
}
@media (max-width: 767px) {
    .head__value-wrap {
        margin-right: calc(var(--content-gap) - 8px);
        margin-left: var(--content-gap);
    }
}

.head__value-btn {
    margin-right: 8px;
    margin-bottom: 12px;
}

.title-hint-wrap {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
}

.filter-widget-wrap__title {
    display: inline;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}

.icon {
    margin-left: auto;
    transition: fill var(--transition), transform var(--transition), margin-top var(--transition);
}
.head_clickable:hover .icon {
    fill: var(--dark-c);
}
.icon_rotate {
    margin-top: 2px;
    transform: rotate(180deg);
}

@media (min-width: 768px) {
    .filter-widget__content {
        position: relative;
        top: -4px;
        padding: 0 20px 16px 17px;
    }
}
@media (max-width: 767px) {
    .filter-widget__content {
        padding: 0 var(--content-gap) 16px;
    }
}

.filter-widget__content_without-head {
    margin-top: 0;
    padding-top: 20px;
}

@media (min-width: 768px) {
    .filter-widget-wrap_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .filter-widget-wrap_desktop {
        display: none;
    }
}

.filter-widget-wrap__second {
    position: fixed;
    top: 0;
    left: 0;
    z-index: calc(var(--menu-z-index) + 1);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--light-c);
}

.second-view__head {
    position: relative;
    /*z-index: 5;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light-c);
}

.second-view__head-btn_back {
    width: 100%;
    height: 56px;
}
@media (max-width: 767px) {
    .second-view__head-btn_back {
        padding-left: var(--content-gap);
    }
}

.second-view__head-btn-wrap {
    flex-shrink: 0;
}
.second-view__head-btn-wrap_shrink {
    flex-shrink: 6;
}
@media (min-width: 768px) {
    .second-view__head-btn-wrap {
        width: 68px;
    }
}
@media (max-width: 767px) {
    .second-view__head-btn-wrap {
        width: calc(24px + var(--content-gap));
    }
}

.second-view__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
}
@media (min-width: 768px) {
    .second-view__content {
        padding: 20px 16px 0;
    }
}
@media (max-width: 767px) {
    .second-view__content {
        padding: 16px var(--content-gap) 0;
    }
}

.second-view__footer {
    position: relative;
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .second-view__footer {
        padding: 0 16px 20px;
    }
}
@media (max-width: 767px) {
    .second-view__footer {
        padding: 0 var(--content-gap) 20px;
    }
}

.second-view__footer::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,1) 100%);
}

.second-view__summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (min-width: 768px) {
    .second-view__summary {
        padding: 20px 16px 0;
    }
}
@media (max-width: 767px) {
    .second-view__summary {
        padding: 16px var(--content-gap) 0;
    }
}
</style>