<template>
    <section
        v-if="products.length"
        class="slider-section"
    >
        <h2 class="h2 h2_block slider-section__title">
            Другие товары
        </h2>

        <VueSlickCarousel
            class="slider"
            :arrows="true"
            :dots="false"
            :infinite="false"
            :slidesToShow="5"
            :slidesToScroll="5"
            :responsive="[
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        variableWidth: true,
                    }
                },
                {
                    breakpoint: 1039,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        variableWidth: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]"
        >
            <div
                v-for="(product, index) in products"
                :key="'product-' + product.id"
                class="slider__item-wrap"
            >
                <MarketItemColumn
                    :item="product"
                    apiName="products"
                    modelName="product"
                    hideLikeBtnOnXs
                    reviewsInPopup
                    class="slider__item"
                    @changeFavorite="onChangeFavorite(index, ...arguments)"
                    @callShowOffersPopup="showOffersPopup(product, index)"
                ></MarketItemColumn>
            </div>

            <template #prevArrow>
                <RoundArrowBtn
                    direction="left"
                    size="36"
                    shadow
                    class="slider-nav__arrow slider-nav__arrow_prev"
                ></RoundArrowBtn>
            </template>
            <template #nextArrow>
                <RoundArrowBtn
                    direction="right"
                    size="36"
                    shadow
                    class="slider-nav__arrow slider-nav__arrow_next"
                ></RoundArrowBtn>
            </template>
        </VueSlickCarousel>
    </section>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import { mapState } from 'vuex';
import VueSlickCarousel from 'vue-slick-carousel';
import MarketItemColumn from './MarketItemColumn.vue';
import RoundArrowBtn from './project-buttons/RoundArrowBtn.vue';
const OffersPopup = () => import('@/components/popups/OffersPopup.vue');

export default {
    name: 'OtherProducts',

    components: {
        RoundArrowBtn,
        VueSlickCarousel,
        MarketItemColumn,
    },

    data() {
        return {
            products: [],
        };
    },

    computed: {
        ...mapState({
            currentCityId: state => state.cities.currentCityId,
        }),
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            const [products, deliveryOffices] = await Promise.all([
                this.$api.products.similarProducts(this.$route.params.id),
                this.$api.delivery.offices({
                    params: {
                        limit: 1000,
                        type: 'pvz',
                        service__codename__in: 'ubibi,cdek',
                        city: this.currentCityId,
                    },
                }),
            ]);

            this.products = products.map(product => {
                return {
                    ...product,
                    delivery: {
                        items: deliveryOffices.results,
                        currentCount: deliveryOffices.current_count,
                    },
                };
            });
        },

        onChangeFavorite(index, value) {
            this.products[index].is_favorite = value;
        },

        showOffersPopup(item, itemIndex) {
            const props = {
                item: item,
                itemIndex: itemIndex,
                modelName: 'product',
                changeFavoriteCallback: (value) => this.onChangeFavorite(itemIndex, value),
            };
            const options = { props };
            this.$popup(OffersPopup, options);
        },
    },
};
</script>

<style scoped>
.slider__item-wrap >>> .market-item-column__info {
    height: 100%;
}

@media (max-width: 767px) {
    .slider__item-wrap {
        width: 298px !important;
    }
}
</style>