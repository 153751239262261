var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-gap_medium"},[(_vm.fetching || _vm.initializing)?_c('Spinner',{attrs:{"size":"big","center":""}}):[_c('h1',{staticClass:"h1 h1_block"},[_vm._v("\n            "+_vm._s(_vm.category.page_title)+"\n        ")]),_vm._v(" "),(_vm.category.is_leaf && _vm.characteristics.current_count)?_c('ol',{staticClass:"grid"},_vm._l((_vm.characteristics.results),function(characteristic,index){return _c('li',{key:'item-' + characteristic.part_name_id + index,staticClass:"grid-item layer-1"},[_c('RouterLink',{staticClass:"grid-item__main-link grid-item__main-link_full h3 link-primary-hover",attrs:{"to":{
                        name: 'market-part-name',
                        params: {
                            slug: _vm.category.slug,
                            id: characteristic.part_name_id,
                        }
                    }}},[_vm._v("\n                    "+_vm._s(characteristic.part_name)+"\n                ")])],1)}),0):(!_vm.category.is_leaf && _vm.categoryChildren.current_count)?_c('ol',{staticClass:"grid"},_vm._l((_vm.categoryChildren.results),function(childCategory){return _c('li',{key:childCategory.id,staticClass:"grid-item layer-1"},[_c('div',{staticClass:"grid-item__cover-wrap"},[(childCategory.cover)?_c('img',{staticClass:"grid-item__cover",attrs:{"src":_vm.$links.uploads + childCategory.cover.thumbnails.category_cover_list,"alt":""}}):_c('img',{staticClass:"grid-item__cover",attrs:{"src":require("../../../../assets/images/cover-placeholder.svg"),"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"grid-item__name-wrap"},[_c('h2',{staticClass:"h3"},[(childCategory.children.length)?_c('RouterLink',{staticClass:"grid-item__main-link link-primary-hover",attrs:{"to":{
                                name: 'market-parts-catalog-category',
                                params: {
                                    slug: childCategory.slug,
                                }
                            }}},[_vm._v("\n                            "+_vm._s(childCategory.name)+"\n                        ")]):_c('button',{staticClass:"grid-item__main-link link-primary-hover",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleToCategoryClick(childCategory)}}},[_vm._v("\n                            "+_vm._s(childCategory.name)+"\n                        ")])],1),_vm._v(" "),(childCategory.children.length)?_c('ul',{staticClass:"children-list"},[_vm._l((childCategory.children.slice(0, 3)),function(child){return _c('li',{key:'child-' + child.id,staticClass:"children-list__item"},[_c('button',{staticClass:"children-list__link link-primary-hover",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleToCategoryClick(child)}}},[_vm._v(_vm._s(child.name))])])}),_vm._v(" "),(childCategory.children.length > 3)?_c('li',{staticClass:"children-list__item children-list__item_more"},[_c('RouterLink',{staticClass:"link-primary-hover",attrs:{"to":{
                                    name: 'market-parts-catalog-category',
                                    params: {
                                        slug: childCategory.slug,
                                    }
                                }}},[_vm._v("\n                                ... и ещё +"+_vm._s(childCategory.children.length - 3)+"\n                            ")])],1):_vm._e()],2):_vm._e()])])}),0):_c('EmptyBlock',{attrs:{"icon":"parts","title":"Пустая категория","text":"Попробуйте изменить условия поиска.","hasBtn":false},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("\n                В категории "),_c('b',[_vm._v("«"+_vm._s(_vm.category.page_title)+"»")]),_vm._v(" нет запчастей.\n            ")]},proxy:true}])}),_vm._v(" "),_c('LastSee'),_vm._v(" "),_c('SeoBlock',{scopedSlots:_vm._u([(_vm.category.seo_description)?{key:"seo",fn:function(){return [_vm._v(_vm._s(_vm.category.seo_description))]},proxy:true}:null],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }