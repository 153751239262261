import './assets/css/main.css';
import { initHttpService } from '@/http.js';
import { createApp } from './app.js';
import Context from '@/context.js';
import socket from '@/services/web-socket';

const context = new Context();

// важно инициализировать до createApp
const HTTP = initHttpService(context);
context.HTTP = HTTP;

export const { app, router, store } = createApp(context);

context.app = app;
context.router = router;
context.store = store;

if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
}

if (document.cookie.city) {
    const city = document.cookie.city;
    store.commit('cities/setCurrentCityId', city);
}

socket.init({
    url: process.env.WEBSOCKET_URL,
});

if (store.state.profile.isAuthorized) {
    socket.connect();
    // on notifications
    // on chat
}
// Сокет зависит от авторизованности пользователя
store.subscribe(mutation => {
    const { type, payload: value } = mutation;

    if (type === 'profile/setIsAuthorized') {
        if (value) {
            socket.connect();
        }
        else {
            socket.disconnect();
        }
    }
});

router.onReady(() => {
    app.$mount('#app');
});