import pad from '@ui/utils/pad.js';
import isValidDate from '@ui/utils/isValidDate.js';
import { mapState } from 'vuex';


export default () => {
    return {
        ...mapState({
            isAuthorized: state => state.profile.isAuthorized,
            profile: state => state.profile.profile,
        }),

        phoneIsDone() {
            if (this.isAuthorized) return false;

            return this.phoneConfirmed && this.phoneEqual;
        },

        phoneStatus() {
            return this.contract.confirmed_phone_status;
        },

        phoneConfirmed() {
            return this.phoneStatus === 'confirmed'
                || this.phoneStatus === 'new_confirmed';
        },

        phoneEqual() {
            return this.formData.insurer_phone === this.contract.confirmed_phone;
        },

        minBirthDateIso() {
            const date = this.minBirthDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        maxBirthDateIso() {
            const date = this.maxBirthDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        maxDocDateIso() {
            const date = this.maxDocDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        minOwnerDocDate() {
            const value = this.formData.owner_birth_date;

            if (value && /(\d{4})-(\d{2})-(\d{2})/g.test(value)) {
                const date = new Date(value);
                const isValid = isValidDate(date);

                if (isValid) {
                    return new Date(date.getFullYear() + 14, date.getMonth(), date.getDate());
                }
            }

            return this.minBirthDate;
        },

        minOwnerDocDateIso() {
            const date = this.minOwnerDocDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        minInsurerDocDate() {
            const value = this.formData.insurer_birth_date;

            if (value && /(\d{4})-(\d{2})-(\d{2})/g.test(value)) {
                const date = new Date(value);
                const isValid = isValidDate(date);

                if (isValid) {
                    return new Date(date.getFullYear() + 14, date.getMonth(), date.getDate());
                }
            }

            return this.minBirthDate;
        },

        minInsurerDocDateIso() {
            const date = this.minInsurerDocDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },
    };
};