<template>
    <div>
        <div class="main-wrap bottom-gap_medium layer-1">
            <div class="main">
                <div class="photo-block">
                    <MarketItemPhotoBlock
                        :mainCover="newPart.cover"
                        :photos="newPart.photos"
                    ></MarketItemPhotoBlock>
                </div>

                <div class="main-info">
                    <BrandShortInfo
                        border
                        :brand="newPart.brand"
                        class="brand mb-0"
                    ></BrandShortInfo>

                    <MarketItemAttributesGroup
                        :attributes="attributes"
                        :item="newPart"
                        class="attributes"
                        valueWidth="216px"
                    ></MarketItemAttributesGroup>

                    <MarketPartApplicabilityLinksList
                        v-if="initialized"
                        :applicability="applicability"
                        modelName="part_product"
                        class="applicability"
                    ></MarketPartApplicabilityLinksList>

                    <div
                        v-if="newPart.description"
                        class="hidden_only_l description"
                    >
                        <h3 class="h4 h4_block hidden-s">
                            Описание запчасти
                        </h3>
                        <DescriptionWithPopup
                            :description="newPart.description"
                            :popupTitle="newPart.page_title"
                        ></DescriptionWithPopup>
                    </div>
                </div>
            </div>

            <div
                v-if="newPart.description"
                class="visible_only_l description"
            >
                <h3 class="h4 h4_block hidden-s">
                    Описание запчасти
                </h3>
                <DescriptionWithPopup
                    :description="newPart.description"
                    :popupTitle="newPart.page_title"
                ></DescriptionWithPopup>
            </div>
        </div>

        <Spinner
            v-if="!initialized"
            size="big"
            center
            class="top-gap_medium"
        ></Spinner>
        <template v-else>
            <!--            <MarketItemOffersListWithoutFilter-->
            <!--                :item="newPart"-->
            <!--                :offers="newPartOffers"-->
            <!--                apiName="parts"-->
            <!--                modelName="part_product"-->
            <!--                @changeCart="onChangeCart"-->
            <!--            >-->
            <!--                <template #empty>-->
            <!--                    <EmptyBlock-->
            <!--                        icon="parts"-->
            <!--                        title="Нет предложений"-->
            <!--                        text="Нет предложений по этой запчасти."-->
            <!--                    >-->
            <!--                        <template slot="button">-->
            <!--                            <ButtonBlock-->
            <!--                                v-if="globalSearchSelectedCar.id"-->
            <!--                                :to="{-->
            <!--                                    name: 'market-parts-catalogs-manufacturer-modification',-->
            <!--                                    params: {-->
            <!--                                        manufacturer: globalSearchSelectedCar.manufacturer.codename,-->
            <!--                                        modification: globalSearchSelectedCar.id-->
            <!--                                    },-->
            <!--                                }"-->
            <!--                                primary-->
            <!--                                low-->
            <!--                                class="empty__btn"-->
            <!--                            >-->
            <!--                                Перейти в каталог-->
            <!--                            </ButtonBlock>-->
            <!--                            <ButtonBlock-->
            <!--                                v-else-->
            <!--                                :to="{-->
            <!--                                    name: 'market-parts-catalogs',-->
            <!--                                }"-->
            <!--                                low-->
            <!--                                primary-->
            <!--                                class="empty__btn"-->
            <!--                            >-->
            <!--                                Перейти в каталог-->
            <!--                            </ButtonBlock>-->
            <!--                        </template>-->
            <!--                    </EmptyBlock>-->
            <!--                </template>-->
            <!--            </MarketItemOffersListWithoutFilter>-->

            <div class="bottom-gap_medium">
                <section
                    v-if="analogs.current_count"
                    class="analogs-section slider-section"
                >
                    <div class="analogs-section__head slider-section__title">
                        <h2 class="h2">
                            <span>Аналоги</span><span class="h2-sub">{{ analogs.current_count }}</span>
                        </h2>
                        <ButtonText
                            :to="{
                                name: 'market-new-part-analogs',
                                params: {
                                    id: newPart.id,
                                },
                            }"
                            dashed
                            secondary
                            dark
                        >
                            Смотреть все
                        </ButtonText>
                    </div>

                    <VueSlickCarousel
                        class="slider"
                        :arrows="true"
                        :dots="false"
                        :slidesToShow="4"
                        :slidesToScroll="4"
                        :responsive="[
                            {
                                breakpoint: 1039,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                }
                            },
                            {
                                breakpoint: 767,
                                settings: {
                                    infinite: false,
                                    variableWidth: true,
                                    arrows: false,
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                }
                            },
                        ]"
                    >
                        <div
                            v-for="(item, index) in analogs.results"
                            :key="'product-' + item.id"
                            class="slider__item-wrap"
                        >
                            <MarketItemColumn
                                :item="item"
                                apiName="parts"
                                modelName="part_product"
                                hideLikeBtnOnXs
                                class="slider__item"
                                @changeFavorite="onChangeFavorite(index, ...arguments)"
                                @callShowOffersPopup="showOffersPopup(item, index)"
                            ></MarketItemColumn>
                        </div>
                    </VueSlickCarousel>
                </section>
            </div>

            <section class="bottom-gap_medium">
                <h2 class="h2 h2_block">
                    <span>Отзывы</span><span
                        v-if="newPart.reviews_amount"
                        class="h2-sub"
                    >{{ newPart.reviews_amount }}</span>
                </h2>

                <div
                    v-if="newPart.reviews_amount"
                    class="reviews-section-content layer-1"
                >
                    <div class="reviews-layout">
                        <div class="reviews-aside">
                            <InfoMessage v-if="isAuthorized && newPart.has_review">
                                Вы уже оставили отзыв к&nbsp;этой запчасти.
                            </InfoMessage>
                            <ButtonBlock
                                v-if="!newPart.has_review"
                                primary
                                :disabled="!isAuthorized"
                                class="reviews-aside__action-btn"
                                @click="showMarketItemReviewPopup"
                            >
                                Оставить отзыв
                            </ButtonBlock>
                            <p
                                v-if="!isAuthorized"
                                class="mt-16"
                            >
                                <UButtonText
                                    primary
                                    underline
                                    @click="openSignInPopup"
                                >Войдите</UButtonText>, чтобы оставить отзыв
                            </p>

                            <div class="reviews-filter">
                                <ul class="reviews-filter__list">
                                    <li
                                        v-for="index in 5"
                                        :key="'reviews-filter__item-' + index"
                                        class="reviews-filter__item"
                                    >
                                        <div class="reviews-filter__option">
                                            <StarsRating
                                                :showValue="false"
                                                :value="6 - index"
                                                :blocked="reviews.amounts[5 - index].reviews_amount === 0"
                                            ></StarsRating>
                                            <span
                                                class="reviews-filter__option-label text_secondary"
                                            >{{ reviewsAmountText(5 - index) }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="reviews-main">
                            <div class="reviews-list">
                                <PagesReview
                                    v-for="item in reviews.items"
                                    :key="'reviews-list-item-' + item.id"
                                    :part="newPart"
                                    :review="item"
                                    class="reviews-list-item"
                                ></PagesReview>
                            </div>

                            <ButtonBlock
                                v-if="reviews.items.length > 3"
                                :to="{
                                    name: 'market-new-part-reviews',
                                    params: {
                                        id: $route.params.id,
                                    },
                                }"
                                secondary
                                class="reviews-section-link"
                            >
                                Все отзывы
                            </ButtonBlock>
                        </div>
                    </div>
                </div>

                <EmptyBlock
                    v-else
                    icon="review"
                    title="Отзывов ещё нет"
                    text="Будьте первым, кто поделится мнением об этой запчасти."
                >
                    <template #button>
                        <ButtonBlock
                            primary
                            low
                            class="empty__btn"
                            :disabled="!isAuthorized"
                            @click="showMarketItemReviewPopup"
                        >
                            Оставить отзыв
                        </ButtonBlock>
                        <p
                            v-if="!isAuthorized"
                            class="empty__btn empty__login"
                        >
                            <UButtonText
                                primary
                                underline
                                @click="openSignInPopup"
                            >Войдите</UButtonText>, чтобы оставить отзыв
                        </p>
                    </template>
                </EmptyBlock>
            </section>
        </template>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import bus from '@/bus.js';
import plural from '@/lib/plural.js';
import StarsRating from '@/components/StarsRating.vue';
import PagesReview from '@/components/PagesReview.vue';
// import MarketItemOffersListWithoutFilter from '@/components/MarketItemOffersListWithoutFilter.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import BrandShortInfo from '@/components/BrandShortInfo.vue';
import MarketItemAttributesGroup from '@/components/MarketItemAttributesGroup.vue';
import MarketItemPhotoBlock from '@/components/MarketItemPhotoBlock.vue';
import Spinner from '@/components/Spinner.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import MarketPartApplicabilityLinksList from '@/components/MarketPartApplicabilityLinksList.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import MarketItemColumn from '@/components/MarketItemColumn.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import DescriptionWithPopup from '@/components/DescriptionWithPopup.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const MarketItemReviewPopup = () => import('@/components/popups/MarketItemReviewPopup.vue');
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');

export default {
    name: 'MarketNewPartDescriptionPage',

    components: {
        UButtonText,
        VueSlickCarousel,
        MarketItemColumn,
        ButtonText,
        MarketPartApplicabilityLinksList,
        DescriptionWithPopup,
        InfoMessage,
        ButtonBlock,
        Spinner,
        MarketItemPhotoBlock,
        MarketItemAttributesGroup,
        BrandShortInfo,
        EmptyBlock,
        // MarketItemOffersListWithoutFilter,
        PagesReview,
        StarsRating,
    },

    serverPrefetch() {
        return this.INIT( { id: this.$route.params.id });
    },

    computed: {
        ...mapState({
            newPart: state => state.marketNewPart.newPart,
            initialized: state => state.marketNewPartDescription.initialized,
            analogs: state => state.marketNewPart.analogs,
            applicability: state => state.marketNewPartDescription.applicability,
            // newPartOffers: state => state.marketNewPartDescription.newPartOffers.items,
            // contractPartOffers: state => state.marketNewPartDescription.contractPartOffers.items,
            reviews: state => state.marketNewPartDescription.reviews,
            isAuthorized: state => state.profile.isAuthorized,
            globalSearchSelectedCar: state => state.searchWidget.car,
        }),

        attributes() {
            if (this.newPart.properties && this.newPart.properties.length) {
                return this.newPart.properties.reduce((acc, group) => {
                    acc.push(...group.attributes.filter(attribute => attribute.is_main));
                }, []);
            }
            else {
                return [];
            }
        },
    },

    async mounted() {
        // bus.$on('deleteFromCart', this.onChangeCart);

        if (!this.initialized) {
            await this.INIT( { id: this.$route.params.id });
        }
        else if (this.newPart.reviews_amount && this.reviews.current_count === 0) {
            await this.UPDATE_REVIEWS({ id: this.newPart.id });
        }
    },

    // beforeDestroy() {
    //     bus.$off('deleteFromCart', this.onChangeCart);
    // },

    methods: {
        // ...mapMutations({
        //     CHANGE_CART_STATUS: 'marketNewPartDescription/changeInCartStatus',
        // }),

        ...mapActions({
            INIT: 'marketNewPartDescription/init',
            UPDATE_NEW_PART: 'marketNewPart/updateNewPart',
            UPDATE_REVIEWS: 'marketNewPartDescription/updateReviews',
        }),

        showMarketItemReviewPopup() {
            const props = {
                part: this.newPart,
                callback: this.updateReviews,
            };
            const options = { props };
            this.$popup(MarketItemReviewPopup, options);
        },

        updateReviews() {
            this.UPDATE_REVIEWS({ id: this.newPart.id });
            this.UPDATE_NEW_PART({ id: this.newPart.id });
        },

        reviewsAmountText(index) {
            let n = this.reviews.amounts[index].reviews_amount;
            if (n === 0) {
                return 'Нет отзывов';
            }
            else {
                return n + ' ' + plural(n, ['отзыв', 'отзыва', 'отзывов']);
            }
        },

        // onChangeCart(data) {
        //     this.CHANGE_CART_STATUS(data);
        // },

        openSignInPopup() {
            this.$popup(SignInPopup);
        },
    },
};
</script>

<style scoped>
.visible_only_l {
    display: none;
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .hidden_only_l {
        display: none;
    }

    .visible_only_l {
        display: block;
    }
}

.main-wrap {
    position: relative;
    padding: var(--base-card-padding);
}

@media (min-width: 1040px) {
    .main {
        display: flex;
    }
}

@media (min-width: 1040px) {
    .main-info {
        flex-grow: 1;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .main-info {
        margin-top: var(--gap-y-small);
    }
}

@media (min-width: 1040px) {
    .photo-block {
        margin-right: 36px;
    }
}
@media (max-width: 767px) {
    .photo-block {
        display: none;
    }
}

.applicability {
    margin-top: var(--gap-y-small);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .applicability {
        margin-top: 0;
    }
}

.attributes {
    margin-top: var(--gap-y-small);
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .attributes >>> .key {
        max-width: 40%;
    }
    .attributes >>> .value {
        max-width: 160px;
    }
}

.description {
    max-width: 780px;
    margin-top: var(--gap-y-small);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .description {
        margin-top: 24px;
    }
}
@media (max-width: 767px) {
    .description {
        margin-top: 16px;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
        border-top: 1px solid var(--border-light-c);
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .brand {
        position: absolute;
        top: var(--base-card-padding-y);
        right: var(--base-card-padding-x);
        width: 260px;
    }
}
@media (max-width: 767px) {
    .brand {
        width: 100%;
    }
}

.reviews-section-content {
    padding: var(--base-card-padding);
}

.reviews-section-link {
    margin-top: var(--gap-y-small);
}

@media (min-width: 1040px) {
    .reviews-layout {
        display: flex;
        flex-direction: row-reverse;
    }
}

.reviews-list-item:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .reviews-list-item:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .reviews-list-item:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
    }
}

@media (min-width: 1040px) {
    .reviews-main {
        flex-grow: 1;
    }
}

@media (min-width: 1281px) {
    .reviews-aside {
        width: 280px;
        flex-shrink: 0;
        margin-left: 120px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .reviews-aside {
        width: 220px;
        flex-shrink: 0;
        margin-left: 48px;
    }
}
@media (max-width: 1039px) {
    .reviews-aside {
        display: none;
    }
}

@media (min-width: 1040px) {
    .reviews-aside__action-btn {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .reviews-aside__action-btn {
        width: 100%;
    }
}

@media (max-width: 1039px) {
    .reviews-filter {
        display: none;
    }
}

@media (min-width: 1040px) {
    .reviews-filter__list {
        margin-top: 36px;
    }
}

.reviews-filter__item:not(:last-child) {
    margin-bottom: 16px;
}

.reviews-filter__option {
    display: flex;
}

.reviews-filter__option-label {
    margin-left: 12px;
}

.analogs-section__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--indent-h2-block);
}

.slider__item-wrap >>> .market-item-column__info {
    height: 100%;
}

@media (max-width: 767px) {
    .slider__item-wrap {
        width: 298px !important;
    }
}
</style>