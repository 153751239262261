<template>
    <div
        ref="searchWidget"
        v-on-clickaway="hideSearchResults"
        class="search-widget"
    >
        <div class="form-wrap">
            <SelectCarBtn></SelectCarBtn>

            <UTooltip
                ref="tooltip"
                placement="top-start"
                :offset="10"
            >
                <template #trigger>
                    <form
                        ref="searchFormDesktop"
                        class="search-form search-form_desktop"
                        @submit.prevent="enterHandler"
                    >
                        <UIcon
                            name="search-16"
                            secondary
                            small
                            class="search-from__icon"
                        ></UIcon>

                        <input
                            ref="searchField"
                            v-model.trim="searchValue"
                            type="search"
                            name="search"
                            placeholder="Название или номер запчасти"
                            class="search-form__input"
                            autocapitalize="off"
                            autocomplete="off"
                            spellcheck="false"
                            maxlength="255"
                            @input="search"
                            @focus="searchFocusHandler"
                            @keyup.down="setResultFocus(1)"
                            @keyup.up="setResultFocus(-1)"
                        >

                        <button
                            v-show="searchValue.length"
                            type="reset"
                            class="search-form__btn"
                            aria-label="Очистить поле поиска"
                            @click="clearSearch"
                        >
                            <UIcon
                                name="cross"
                                class="search-form__btn-icon"
                            ></UIcon>
                        </button>
                        <button
                            v-show="searchValue.length === 0"
                            type="button"
                            class="search-form__btn"
                            aria-label="О поиске"
                            @click="showSearchCapabilitiesPopup"
                        >
                            <UIcon
                                name="info"
                                small
                                class="search-form__btn-icon"
                            ></UIcon>
                        </button>
                    </form>
                </template>

                Выберите один из предложенных вариантов
            </UTooltip>

            <div class="search-form search-form_mobile">
                <UIcon
                    name="search-16"
                    secondary
                    small
                    class="search-from__icon"
                ></UIcon>
                <p
                    class="search-form__pseudo-input"
                    @click="toggleMobileSearch"
                >
                    {{ hasSelectedCar ? 'Название или номер запчасти' : 'Номер запчасти' }}
                </p>
                <button
                    type="button"
                    class="search-form__btn"
                    aria-label="О поиске"
                    @click="showSearchCapabilitiesPopup"
                >
                    <UIcon
                        name="info"
                        small
                        class="search-form__btn-icon"
                    ></UIcon>
                </button>
            </div>
        </div>

        <div
            class="search-widget__dropdown"
            :class="{
                'search-widget__dropdown_highlight': highlightSearch,
                'search-widget__dropdown_mobile-open': mobileSearchIsOpen,
            }"
        >
            <div
                v-if="mobileSearchIsOpen"
                class="search-form-wrap_mobile"
            >
                <button
                    class="mr-16 flex-shrink-0"
                    aria-label="Назад"
                    @click="clearCloseSearch"
                >
                    <UIcon
                        name="arrow-direction-left"
                        big
                    ></UIcon>
                </button>
                <form
                    class="search-form"
                    @submit.prevent="enterHandler"
                >
                    <input
                        ref="mobileSearchField"
                        v-model.trim="searchValue"
                        type="search"
                        name="search"
                        :placeholder="`${ hasSelectedCar ? 'Название или номер запчасти' : 'Номер запчасти' }`"
                        class="search-form__input"
                        autocapitalize="off"
                        autocomplete="off"
                        spellcheck="false"
                        maxlength="255"
                        @input="search"
                        @focus="searchFocusHandler"
                    >

                    <button
                        v-show="searchValue.length"
                        type="reset"
                        class="search-form__btn"
                        aria-label="Очистить поле поиска"
                        @click="clearSearch"
                    >
                        <UIcon
                            name="cross"
                            class="search-form__btn-icon"
                        ></UIcon>
                    </button>
                    <button
                        v-show="searchValue.length === 0"
                        type="button"
                        class="search-form__btn"
                        aria-label="О поиске"
                        @click="showSearchCapabilitiesPopup"
                    >
                        <UIcon
                            name="info"
                            small
                            class="search-form__btn-icon"
                        ></UIcon>
                    </button>
                </form>
            </div>
            <div
                v-show="showResultBlock"
                class="results"
            >
                <Spinner
                    v-show="loading"
                    small
                    center
                    class="mt-16 mb-16"
                ></Spinner>

                <div
                    v-show="!loading && hasResults"
                    class="result-section"
                    :class="{ 'result-section_visible': hasResults }"
                >
                    <div
                        v-if="selectedPartName"
                        class="results-list results-list_attr"
                    >
                        <button
                            v-for="(attr, index) in selectedPartName.additional_attrs"
                            :key="'attr' + attr.id"
                            type="button"
                            class="pill-btn mr-8 mb-8"
                            :class="{
                                'pill-btn_focus': focusIndex === index,
                            }"
                            @click="selectAdditionalAttr(attr)"
                        >
                            <span class="pill-btn__label">
                                {{ attr.name }}
                            </span>
                        </button>
                    </div>
                    <div
                        v-else
                        class="results-list"
                    >
                        <div
                            v-for="(searchGroup, searchGroupIndex) in filteredPresearchResults"
                            v-if="searchGroup.items.length"
                            :key="searchGroup.name"
                            class="results-list"
                        >
                            <h4 class="result-section__title">
                                {{ groupsTitles[searchGroup.name] }}
                            </h4>

                            <ol class="results-group">
                                <li
                                    v-for="(item, index) in searchGroup.items"
                                    :key="'search-result-' + index"
                                >
                                    <button
                                        class="result"
                                        :class="{
                                            'result_focus': flattenPresearchResults[focusIndex] === item,
                                        }"
                                        @click="selectResult(item)"
                                    >
                                        <span class="result__main">
                                            <span class="result__cover-wrap">
                                                <template v-if="searchGroup.name === 'category'">
                                                    <img
                                                        v-if="item.cover"
                                                        :src="$links.uploads + item.cover.thumbnails.presearch"
                                                        alt=""
                                                        width="40"
                                                        height="40"
                                                        class="result__cover result__cover_category"
                                                    >

                                                    <img
                                                        v-else
                                                        src="../../assets/images/cover-placeholder.svg"
                                                        alt=""
                                                        width="40"
                                                        height="40"
                                                        class="result__cover result__cover_category"
                                                    >
                                                </template>

                                                <template v-else-if="searchGroup.name === 'brand'">
                                                    <img
                                                        v-if="item.logo"
                                                        :src="$links.uploads + item.logo.thumbnails.brand_logo_micro"
                                                        alt=""
                                                        width="40"
                                                        height="27"
                                                        class="result__cover result__cover_brand"
                                                    >

                                                    <img
                                                        v-else
                                                        src="../../assets/images/logo-placeholder.svg"
                                                        alt=""
                                                        width="40"
                                                        height="27"
                                                        class="result__cover result__cover_brand"
                                                    >
                                                </template>

                                                <template v-else-if="searchGroup.name === 'part'">
                                                    <img
                                                        v-if="item.category.cover"
                                                        :src="$links.uploads + item.category.cover.thumbnails.presearch"
                                                        alt=""
                                                        width="40"
                                                        height="40"
                                                        class="result__cover result__cover_part"
                                                    >

                                                    <img
                                                        v-else
                                                        src="../../assets/images/cover-placeholder.svg"
                                                        alt=""
                                                        width="40"
                                                        height="40"
                                                        class="result__cover result__cover_part"
                                                    >
                                                </template>

                                                <template v-else>
                                                    <img
                                                        v-if="item.cover"
                                                        :src="$links.uploads + item.cover.thumbnails.presearch"
                                                        alt=""
                                                        width="40"
                                                        height="40"
                                                        class="result__cover result__cover_product"
                                                    >

                                                    <img
                                                        v-else
                                                        src="../../assets/images/cover-placeholder.svg"
                                                        alt=""
                                                        width="40"
                                                        height="40"
                                                        class="result__cover result__cover_product"
                                                    >
                                                </template>
                                            </span>
                                            <span
                                                class="result__name"
                                                :title="item.name"
                                            >
                                                {{ item.name }}
                                            </span>
                                            <span
                                                v-if="searchGroup.name === 'part_product'"
                                                class="result__part-brand"
                                            >
                                                <span
                                                    class="result__part-brand-name"
                                                    :title="item.name"
                                                >
                                                    {{ item.brand.name }}
                                                </span>
                                                <span
                                                    class="result__brand-cover-wrap"
                                                    :title="item.brand.name"
                                                >
                                                    <img
                                                        v-if="item.brand.logo"
                                                        :src="$links.uploads
                                                            + item.brand.logo.thumbnails.brand_logo_micro"
                                                        alt=""
                                                        width="36"
                                                        height="24"
                                                        class="result__cover result__cover_brand"
                                                    >

                                                    <img
                                                        v-else
                                                        src="../../assets/images/logo-placeholder.svg"
                                                        alt=""
                                                        width="36"
                                                        height="24"
                                                        class="result__cover result__cover_brand"
                                                    >
                                                </span>
                                            </span>
                                            <span
                                                v-if="item.products_amount != null"
                                                class="result__count"
                                            >
                                                <span
                                                    class="result__count-value"
                                                    :title="item.products_amount"
                                                >
                                                    {{ item.products_amount | plural(['товар', 'товара', 'товаров']) }}
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                </li>

                                <li
                                    v-if="searchGroup.name === 'part_product'
                                        && presearchResults[searchGroupIndex].items.length > 3"
                                    class="results__more-wrap"
                                >
                                    <UButtonText
                                        dashed
                                        secondary
                                        dark
                                        :to="{
                                            name: 'market-parts-search',
                                            query: {
                                                sku: searchValue,
                                            }
                                        }"
                                        class="results__more"
                                    >
                                        Показать все запчасти
                                    </UButtonText>
                                </li>

                                <li
                                    v-if="searchGroup.name === 'product'
                                        && presearchResults[searchGroupIndex].items.length > 3"
                                    class="results__more-wrap"
                                >
                                    <UButtonText
                                        dashed
                                        secondary
                                        dark
                                        :to="{
                                            name: 'market-products-search',
                                            query: {
                                                search: searchValue,
                                            }
                                        }"
                                        class="results__more"
                                    >
                                        Показать все автотовары
                                    </UButtonText>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div
                    v-show="!loading && emptyBlockContent.showBlock"
                    class="result-section result-section_empty"
                    :class="{ 'result-section_visible': emptyBlockContent.showBlock }"
                >
                    <h4 class="text_semi-bold mb-4">
                        {{ emptyBlockContent.title }}
                    </h4>
                    <p>
                        {{ emptyBlockContent.text }}
                    </p>
                </div>

                <div
                    v-show="!loading && showLastSearchBlock"
                    class="result-section"
                    :class="{ 'result-section_visible': showLastSearchBlock }"
                >
                    <h4 class="result-section__title">
                        Вы недавно искали
                    </h4>
                    <div class="results-list">
                        <ol class="results-group">
                            <li
                                v-for="(item, index) in lastSearchHistoryItems"
                                :key="'last-search-result-' + index"
                            >
                                <button
                                    :ref="'last-search-item' + index"
                                    class="result"
                                    :class="{
                                        'result_focus': focusIndex === index,
                                    }"
                                    @click="selectResult(item)"
                                >
                                    <span class="result__main">
                                        <span class="result__cover-wrap">
                                            <template v-if="item.item_type === 'category'">
                                                <img
                                                    v-if="item.cover"
                                                    :src="$links.uploads + item.cover.thumbnails.category_cover_mp_index"
                                                    alt=""
                                                    width="40"
                                                    height="40"
                                                    class="result__cover result__cover_category"
                                                >

                                                <img
                                                    v-else
                                                    src="../../assets/images/cover-placeholder.svg"
                                                    alt=""
                                                    width="40"
                                                    height="40"
                                                    class="result__cover result__cover_category"
                                                >
                                            </template>

                                            <template v-else-if="item.item_type === 'brand'">
                                                <img
                                                    v-if="item.logo"
                                                    :src="$links.uploads + item.logo.thumbnails.brand_logo_micro"
                                                    alt=""
                                                    width="40"
                                                    height="27"
                                                    class="result__cover result__cover_brand"
                                                >

                                                <img
                                                    v-else
                                                    src="../../assets/images/logo-placeholder.svg"
                                                    alt=""
                                                    width="40"
                                                    height="27"
                                                    class="result__cover result__cover_brand"
                                                >
                                            </template>

                                            <template v-else-if="item.item_type === 'part'">
                                                <img
                                                    v-if="item.category && item.category.cover"
                                                    :src="$links.uploads + item.category.cover.thumbnails.presearch"
                                                    alt=""
                                                    width="40"
                                                    height="40"
                                                    class="result__cover result__cover_part"
                                                >

                                                <img
                                                    v-else
                                                    src="../../assets/images/cover-placeholder.svg"
                                                    alt=""
                                                    width="40"
                                                    height="40"
                                                    class="result__cover result__cover_part"
                                                >
                                            </template>

                                            <template v-else>
                                                <img
                                                    v-if="item.cover"
                                                    :src="$links.uploads + item.cover.thumbnails.market_item_cover_micro"
                                                    alt=""
                                                    width="40"
                                                    height="40"
                                                    class="result__cover result__cover_product"
                                                >

                                                <img
                                                    v-else
                                                    src="../../assets/images/cover-placeholder.svg"
                                                    alt=""
                                                    width="40"
                                                    height="40"
                                                    class="result__cover result__cover_product"
                                                >
                                            </template>

                                        </span>
                                        <span
                                            class="result__name"
                                            :title="item.name"
                                        >
                                            {{ item.name }}

                                            <CarName
                                                v-if="item.car && item.car.id !== selectedCar.id"
                                                :car="item.car"
                                                class="result__car"
                                            ></CarName>
                                        </span>
                                        <span
                                            v-if="item.item_type === 'part_product'"
                                            class="result__part-brand"
                                        >
                                            <span
                                                class="result__part-brand-name"
                                                :title="item.name"
                                            >
                                                {{ item.brand.name }}
                                            </span>
                                            <span
                                                class="result__brand-cover-wrap"
                                                :title="item.brand.name"
                                            >
                                                <img
                                                    v-if="item.brand.logo"
                                                    :src="$links.uploads + item.brand.logo.thumbnails.brand_logo_micro"
                                                    alt=""
                                                    width="36"
                                                    height="24"
                                                    class="result__cover result__cover_brand"
                                                >

                                                <img
                                                    v-else
                                                    src="../../assets/images/logo-placeholder.svg"
                                                    alt=""
                                                    width="36"
                                                    height="24"
                                                    class="result__cover result__cover_brand"
                                                >
                                            </span>
                                        </span>
                                        <span
                                            v-if="item.products_amount"
                                            class="result__count"
                                        >
                                            <span
                                                class="result__count-value"
                                                :title="item.products_amount"
                                            >
                                                {{ item.products_amount | plural(['товар', 'товара', 'товаров']) }}
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import bus from '@/bus.js';
import breakpointKey from '@/mixins/breakpointKey.js';
import { safeRouterPush } from '@/router/router.js';
import { plural } from '@ui/filters/index.js';
import CarName from '@/components/CarName.vue';
import Spinner from '@/components/Spinner.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import SelectCarBtn from '@/components/searchWidget/SelectCarBtn.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const SelectCarPopup = () => import('@/components/popups/SelectCarPopup.vue');
const SearchCapabilitiesPopup = () => import('@/components/popups/SearchCapabilitiesPopup.vue');


const SEARCH_HIGHLIGHT_TIME = 2000;

export default {
    name: 'SearchWidget',

    components: {
        UButtonText,
        UTooltip,
        SelectCarBtn,
        UIcon,
        Spinner,
        CarName,
    },

    filters: {
        plural,
    },

    mixins: [clickaway, breakpointKey],

    data() {
        return {
            searchValue: '',

            highlightSearchTooltipTimeout: null,
            highlightSearch: false,
            highlightSearchTimeout: null,
            loading: false,
            searchingTimeout: null,
            cancelToken: null,
            focusOnSearchField: false,
            focusIndex: -1,
            mobileSearchIsOpen: false,
            lastSearch: {
                query: '',
                results: [],
                car: '',
            },

            presearchResults: [],
            selectedPartName: null,
            hasResults: false,
        };
    },

    computed: {
        ...mapState({
            isAuthorized: state => state.profile.isAuthorized,
            garageCars: state => state.profileCars.cars,
            searchHistoryItems: state => state.searchHistory.items,
            searchHistoryCount: state => state.searchHistory.count,
            hasSearchHistory: state => state.searchHistory.hasSearchHistory,
            selectedCar: state => state.searchWidget.car,
            currentCityId: state => state.cities.currentCityId,
        }),

        ...mapGetters({
            carProductionDate: 'searchWidget/carProductionDate',
        }),

        hasSelectedCar() {
            return this.selectedCar && !!this.selectedCar.id;
        },

        emptyBlockContent() {
            let content = {
                title: `По запросу «${ this.searchValue }» ничего не найдено`,
                text: 'Попробуйте изменить условия поиска.',
                showBlock: false,
            };
            content.showBlock = this.searchValue.length > 1 && !this.hasResults;
            return content;
        },

        showResultBlock() {
            return this.focusOnSearchField && (this.searchHistoryCount > 0 || this.searchValue.length > 1);
        },

        showLastSearchBlock() {
            return this.searchHistoryCount && (this.searchValue.length < 2
                || (this.searchValue.length > 1 && !this.hasResults));
        },

        lastSearchHistoryItems() {
            let items = [];
            this.searchHistoryItems.forEach(item => {
                if (item && item[item.item_type]) {
                    let obj = {
                        id: item[item.item_type].id,
                        name: item[item.item_type].name,
                        car: item.car,
                        car_id: item.car_id,
                        item_type: item.item_type,
                    };

                    if (item.car) {
                        item.car.grz = item.grz;
                        item.car.vin_frame = item.vin_frame;
                        item.car.prod_date = item.prod_date;
                    }

                    if (item.item_type === 'part_product') {
                        obj.to = {
                            name: 'market-new-part-description',
                            params: {
                                id: item.id,
                            },
                            // query: {
                            //     part_product: item.part.id,
                            // },
                        };
                        if (!item.part_product.name) {
                            obj.name = item.part_product.sku;
                        }

                        obj.pnc = item.part.pnc;
                        obj.part_id = item.part.id;
                        obj.manufacturer = item.car.manufacturer;
                        obj.manufacturer_codename = item.car.manufacturer.codename;
                        obj.car_id = item.car.id;
                        obj.brand = item.brand;
                        // if (!this.hasSelectedCar) {
                        //     obj.to.query.car = item.car.id;
                        // }
                        // if (item.part_product.sku) {
                        //     obj.to.query.sku = item.part_product.sku;
                        // }
                        this.searchHistoryItems.forEach(r => {
                            if (r.item_type === 'part_product' && r.part_product.name === item.part_product.name && r.pnc !== item.pnc) {
                                obj._name = item.part_product.name || item.part_product.sku + ' (' + item.pnc + ')';
                            }
                        });
                    }
                    else if (item.item_type === 'part') {
                        obj.to = {
                            // name: 'market-part-name',
                            name: 'market-parts-search',
                            // params: {
                            //     slug: item.part.category.slug,
                            //     id: item.part.part_names[0].id,
                            // },
                            query: {
                                part_product: item.part.id,
                            },
                        };

                        obj.pnc = item.part.pnc;
                        obj.part_id = item.part.id;
                        obj.manufacturer = item.car.manufacturer;
                        obj.manufacturer_codename = item.car.manufacturer.codename;
                        obj.car_id = item.car.id;

                        if (!this.hasSelectedCar) {
                            obj.to.query.car = item.car.id;
                        }

                        this.searchHistoryItems.forEach(r => {
                            if (r.item_type === 'part' && r.part.name === item.part.name && r.pnc !== item.pnc) {
                                obj._name = item.part.name + ' (' + item.pnc + ')';
                            }
                        });
                    }
                    else if (item.item_type === 'product') {
                        obj.to = {
                            name: 'market-product-description',
                            params: {
                                id: item.product.id,
                            },
                        };

                        obj.cover = item.product.cover;
                    }
                    else if (item.item_type === 'category') {
                        obj.to = {
                            name: 'market-products-category',
                            params: {
                                slug: item.category.slug,
                            },
                        };

                        obj.cover = item.category.cover;
                    }
                    else if (item.item_type === 'brand') {
                        obj.to = {
                            name: 'market-brand',
                            params: {
                                codename: item.brand.codename,
                            },
                        };

                        obj.logo = item.brand.logo;
                    }

                    items.push(obj);
                }
            });

            if (this.searchValue.length > 1 && this.hasSelectedCar) {
                return items.slice(0, 1);
            }
            else {
                return items.slice(0, 6);
            }
        },

        filteredPresearchResults() {
            const groupLimits = {
                part: 3,
                brand: 1,
                category: 3,
                part_product: 3,
                product: 3,
            };

            const singleLimits = {
                part: 6,
                brand: 3,
                category: 6,
                part_product: 6,
                product: 6,
            };

            const hasSameGroups = this.presearchResults.filter(group => !group.items.length).length > 1;
            const limits = hasSameGroups ? groupLimits : singleLimits;

            return this.presearchResults.map(group => {
                return {
                    ...group,
                    items: group.items.slice(0, limits[group.name]),
                };
            });
        },

        flattenPresearchResults() {
            return this.filteredPresearchResults.reduce((acc, group) => [...acc, ...group.items], []);
        },

        groupsTitles() {
            return {
                brand: 'Бренды',
                category: 'Категории автотоваров',
                part: 'Запчасти',
                product: 'Автотовары',
                part_product: 'Запчасти',
            };
        },
    },

    watch: {
        '$route.name': {
            handler() {
                this.clearSearch();
                this.hideSearchResults();
                this.clearSelectedPartName();
            },
        },

        '$route.query': {
            handler() {
                this.clearSearch();
                this.hideSearchResults();
                this.clearSelectedPartName();
            },
        },

        '$route.params': {
            handler() {
                this.clearSearch();
                this.hideSearchResults();
                this.clearSelectedPartName();
            },
        },

        breakpointKey(value) {
            if (value !== 'xs' && value !== 's') {
                this.mobileSearchIsOpen = false;
            }
        },
    },

    mounted() {
        bus.$on('setSearchFieldFocus', this.setSearchFocus);
        bus.$on('changeCar', this.handleChangeCar);

        if (localStorage.getItem('UbibiVuexGlobalSearchCar')) {
            this.setCar(JSON.parse(localStorage.getItem('UbibiVuexGlobalSearchCar')));
        }
    },

    beforeDestroy() {
        bus.$off('setSearchFieldFocus', this.setSearchFocus);
        bus.$off('changeCar', this.handleChangeCar);
        this.hideSearchResults();
        clearTimeout(this.highlightSearchTooltipTimeout);
        clearTimeout(this.highlightSearchTimeout);
        clearTimeout(this.searchingTimeout);
        if (this.cancelToken) this.cancelToken.cancel();
    },

    methods: {
        ...mapMutations({
            setCar: 'searchWidget/setCar',
            resetSearchHistory: 'searchHistory/reset',
        }),

        ...mapActions({
            GET_SEARCH_HISTORY: 'searchHistory/get',
            SET_SEARCH_HISTORY: 'searchHistory/set',
        }),

        async search() {
            if (!this.focusOnSearchField) this.focusOnSearchField = true;

            clearTimeout(this.searchingTimeout);
            if (this.cancelToken) this.cancelToken.cancel();

            this.focusIndex = -1;
            this.clearSearchResult();

            if (this.searchValue.length > 1) {
                if (this.lastSearch.query !== this.searchValue || this.lastSearch.car !== this.selectedCar.id) {
                    this.loading = true;
                    this.searchingTimeout = setTimeout(async () => {
                        this.cancelToken = HTTP.CancelToken.source();

                        let resultParams = {
                            search: this.searchValue,
                            order_by: '-score',
                            limit: 6,
                            offset: 0,
                        };
                        if (this.selectedPartName) {
                            resultParams.category = this.selectedPartName.category.id;
                        }
                        if (this.hasSelectedCar) {
                            resultParams.car = this.selectedCar.id;
                            resultParams.production_date = this.carProductionDate;
                        }
                        try {
                            const response = await this.$api.search.suggest({
                                params: resultParams,
                                cancelToken: this.cancelToken.token,
                            });
                            this.handleSearchResult(response);

                            this.lastSearch.query = this.searchValue;
                            this.lastSearch.results = response;
                            this.lastSearch.car = this.selectedCar.id;
                        }
                        catch (error) {
                            if (!HTTP.isCancel(error)) {
                                this.resetLastSearch();
                                console.log('search error: ', error);
                            }
                        }
                        this.loading = false;
                    }, 300);
                }
                else {
                    this.handleSearchResult(this.lastSearch.results);
                }
            }
            else {
                this.loading = false;
                this.resetLastSearch();
            }
        },

        handleSearchResult(results) {
            const order = [
                {
                    name: 'part',
                    pluralName: 'parts',
                    route: 'market-part-name',
                    getParams: item => {
                        return {
                            slug: item.category.slug,
                            id: item.part_names[0].id,
                        };
                    },
                },
                {
                    name: 'part_product',
                    pluralName: 'part_products',
                    route: 'market-new-part-description',
                    getParams: item => {
                        return {
                            id: item.id,
                        };
                    },
                },
                {
                    name: 'category',
                    pluralName: 'categories',
                    route: 'market-products-category',
                    getParams: item => {
                        return {
                            slug: item.slug,
                        };
                    },
                },
                {
                    name: 'brand',
                    pluralName: 'brands',
                    route: 'market-brand',
                    getParams: item => {
                        return {
                            codename: item.codename,
                        };
                    },
                },
                {
                    name: 'product',
                    pluralName: 'products',
                    route: 'market-product-description',
                    getParams: item => {
                        return {
                            id: item.id,
                        };
                    },
                },
            ];

            this.presearchResults = order.map(group => {
                const items = (results[group.pluralName] || []).map(item => {
                    let name = item.name;

                    if (group.name === 'part') {
                        name = item.category.part_singular_name;

                        if (item.detected_attrs.length) {
                            const detectedAttrs = item.detected_attrs.reduce((acc, item) => {
                                acc.push(item.name.toLowerCase());
                                return acc;
                            }, []);

                            name += ' ' + detectedAttrs.join(' ');
                        }
                    }

                    return {
                        ...item,
                        group: group.name,
                        name,
                        to: {
                            name: group.route,
                            params: group.getParams(item),
                        },
                    };
                });

                return {
                    name: group.name,
                    items,
                };
            });

            if (this.selectedPartName) {
                this.selectedPartName = this.presearchResults[0].items[0];

                if (!this.selectedPartName.additional_attrs.length) {
                    this.selectResult(this.selectedPartName);
                }
            }

            this.hasResults = !!this.presearchResults.some(group => group.items.length);
        },

        clearSelectedPartName() {
            this.selectedPartName = null;
        },

        async enterHandler() {
            if (this.focusIndex > -1) {
                if (this.selectedPartName) {
                    await this.selectAdditionalAttr(this.selectedPartName.additional_attrs[this.focusIndex]);
                }
                else {
                    await this.selectResult(this.flattenPresearchResults[this.focusIndex]);
                }
            }
            else if (!this.loading && (this.showLastSearchBlock || this.hasResults)) {
                if (window.innerWidth >= 768) {
                    if (!this.highlightSearch) {
                        this.$refs.tooltip.show();
                    }
                    clearTimeout(this.highlightSearchTooltipTimeout);
                    this.highlightSearchTooltipTimeout = setTimeout(() => {
                        this.$refs.tooltip.hide();
                    }, SEARCH_HIGHLIGHT_TIME);
                }
                else {
                    this.$notify({
                        type: 'dark',
                        position: 'bottom',
                        message: 'Выберите один из вариантов',
                    });
                }

                clearTimeout(this.highlightSearchTimeout);
                this.highlightSearch = true;
                this.highlightSearchTimeout = setTimeout(() => {
                    this.highlightSearch = false;
                }, SEARCH_HIGHLIGHT_TIME);
            }
        },

        async selectAdditionalAttr(attr) {
            this.searchValue += ' ' + attr.name.toLowerCase();
            await this.search();
        },

        async selectResult(item) {
            if (item.to) {
                if (item.to.name === 'market-part-name') {
                    try {
                        await this.checkSelectCar();
                    }
                    catch (e) {
                        return;
                    }

                    if (item.additional_attrs.length) {
                        this.selectedPartName = item;
                        this.searchValue = item.name;
                        return await this.search();
                    }
                }

                this.addItemToViewHistory(item);
                await safeRouterPush(item.to);
                this.selectedPartName = null;
            }
            else {
                if (this.breakpointKey === 's') {
                    setTimeout(() => {
                        this.mobileSearchIsOpen = true;

                        setTimeout(() => {
                            this.$refs.mobileSearchField.focus();
                        });
                    });
                }
                else {
                    this.setSearchFocus();
                }
            }
        },

        async checkSelectCar() {
            if (this.hasSelectedCar) return;

            await this.showSelectCarPopup();
        },

        hideSearchResults() {
            this.mobileSearchIsOpen = false;
            this.focusOnSearchField = false;
            if (this.cancelToken) this.cancelToken.cancel();
            clearTimeout(this.searchingTimeout);
            clearTimeout(this.highlightSearchTimeout);
            clearTimeout(this.highlightSearchTooltipTimeout);
            this.highlightSearch = false;
            this.$refs.tooltip.hide();
            this.clearSearchResult();
        },

        clearCloseSearch() {
            this.clearSearch();
            this.toggleMobileSearch();
        },

        toggleMobileSearch() {
            this.mobileSearchIsOpen = !this.mobileSearchIsOpen;
            if (this.mobileSearchIsOpen) {
                setTimeout(() => {
                    this.$refs.mobileSearchField.focus();
                });
            }
        },

        clearSearch() {
            this.clearSearchQuery();
            this.clearSearchResult();
            this.clearSelectedPartName();
            this.cancelToken = null;
        },

        clearSearchQuery() {
            this.searchValue = '';
        },

        clearSearchResult() {
            this.presearchResults = [];
            this.hasResults = false;
            this.focusIndex = -1;
        },

        resetLastSearch() {
            this.lastSearch = {
                query: '',
                results: [],
                car: '',
            };
        },

        setSearchFocus() {
            // Задержка для отработки clickaway
            setTimeout(() => {
                this.$refs.searchField.focus();
            });
        },

        searchFocusHandler() {
            this.focusOnSearchField = true;
            this.focusIndex = -1;

            if (this.searchValue.length > 1) {
                this.search();
            }
            else if (!this.hasSearchHistory) {
                this.GET_SEARCH_HISTORY( {
                    selectedCarId: this.hasSelectedCar ? this.selectedCar.id : '',
                });
            }
        },

        setResultFocus(value) {
            if (this.flattenPresearchResults.length) {
                if (this.focusIndex + value < 0) this.focusIndex = this.flattenPresearchResults.length;
                else if (this.focusIndex + value > this.flattenPresearchResults.length) this.focusIndex = 0;
                else this.focusIndex = this.focusIndex + value;
            }
            else if (this.searchHistoryCount) {
                if (this.focusIndex + value < 0) this.focusIndex = this.searchHistoryCount - 1;
                else if (this.focusIndex + value === this.searchHistoryCount) this.focusIndex = 0;
                else this.focusIndex = this.focusIndex + value;
            }
        },

        showSelectCarPopup() {
            return new Promise((resolve, reject) => {
                const props = {
                    modeOptions: [
                        {
                            label: 'По гос. номеру',
                            value: 'grz',
                        },
                        {
                            label: 'VIN/Frame',
                            value: 'vin_frame',
                        },
                        {
                            label: 'Из гаража',
                            value: 'garage',
                        },
                    ],
                    outerAddGarageMode: false,
                    callback: car => {
                        if (car) {
                            resolve();
                        }
                        else {
                            reject();
                        }
                    },
                };
                const options = { props };
                this.$popup(SelectCarPopup, options);
            });
        },

        handleChangeCar() {
            this.clearSearchResult();
            this.resetSearchHistory();
        },

        addItemToViewHistory(item) {
            if (item.group === 'part') return;

            const data = {
                [item.group]: item.id,
            };

            // if (item.group === 'part') {
            //     data[item.group] = item.part_names[0];
            // }

            if (this.selectedCar && this.selectedCar.id) {
                data.car = this.selectedCar.id;
                data.grz = this.selectedCar.grz;
                data.vin_frame = this.selectedCar.vin_frame;
                data.prod_date = this.selectedCar.prod_date;
            }

            this.SET_SEARCH_HISTORY(data);
        },

        showSearchCapabilitiesPopup() {
            this.$popup(SearchCapabilitiesPopup);
        },
    },
};
</script>

<style scoped>
.search-widget {
    position: relative;
}

.form-wrap {
    position: relative;
}
@media (min-width: 768px) {
    .form-wrap {
        display: flex;
        align-items: stretch;
        padding: 4px;
        background-color: var(--light-c);
        border: 1px solid var(--border-dark-c);
        border-radius: var(--border-radius);
    }
}

.search-form {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 16px;
}

@media (min-width: 768px) {
    .search-form_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .search-form {
        background-color: var(--light-c);
        border: 1px solid var(--border-dark-c);
        border-radius: var(--border-radius);
        overflow: hidden;
    }

    .search-form_desktop {
        display: none;
    }

    .search-form_mobile {
        cursor: pointer;
    }
}

.search-form-wrap_mobile {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 16px;
    flex-shrink: 0;
}

.search-form__pseudo-input {
    height: 42px;
    line-height: 40px;
    align-self: center;
    flex-grow: 1;
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
    white-space: nowrap;
}

.search-from__icon {
    margin-right: 8px;
}

.search-form__input {
    font-size: var(--base-fz);
    font-family: var(--f-semi-bold);
    background-color: var(--light-c);
    border: none;
}
@media (min-width: 768px) {
    .search-form__input {
        height: 36px;
        flex-grow: 1;
    }
}
@media (max-width: 767px) {
    .search-form__input {
        height: 42px;
    }
}

.search-form__btn {
    position: relative;
    flex-shrink: 0;
    font-size: 0;
    color: var(--font-secondary-color);
    transition: color var(--transition);
}
.search-form__btn:hover,
.search-form__btn:focus {
    color: var(--dark-c);
}
@media (min-width: 768px) {
    .search-form__btn {
        width: 36px;
        height: 36px;
    }
}
@media (max-width: 767px) {
    .search-form__btn {
        width: 42px;
        height: 42px;
        position: absolute;
        right: 0;
        background: linear-gradient(270deg, #FFFFFF 85%, rgba(255, 255, 255, 0) 100%);
    }
}

.search-form__btn-icon {
    fill: currentColor;
}

.search-widget__dropdown {
    z-index: calc(var(--presearch-z-index) - 1);
    width: 100%;
    background-color: var(--light-c);
}
@media (min-width: 1040px) {
    .search-widget__dropdown {
        width: calc(100% - 284px);
        margin-left: 284px;
    }
}
@media (min-width: 768px) {
    .search-widget__dropdown {
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        box-shadow: var(--base-shadow);
        border-radius: var(--border-radius-x2);
    }

    .search-widget__dropdown_highlight {
        animation: unhighlight 2s linear forwards;
    }

    @keyframes unhighlight {
        0% {
            box-shadow: var(--base-shadow), inset 0 0 0 2px var(--font-secondary-color);
        }

        6% {
            box-shadow: var(--base-shadow), inset 0 0 0 2px var(--font-secondary-color);
        }

        100% {
            box-shadow: var(--base-shadow), inset 0 0 0 2px var(--light-c);
        }
    }
}
@media (max-width: 767px) {
    .search-widget__dropdown {
        display: none;
    }
    .search-widget__dropdown_mobile-open {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        padding-top: 16px;
    }

    .results {
        overflow: auto;
        padding-bottom: 12px;
    }
}

.result-section_visible ~ .result-section {
    border-top: 1px solid var(--border-light-c);
}

.result-section_empty {
    padding-top: 16px;
    padding-bottom: 16px;
}
@media (min-width: 768px) {
    .result-section_empty {
        padding-left: 24px;
        padding-right: 24px;
    }
}
@media (max-width: 767px) {
    .result-section_empty {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.result-section__title {
    font-size: var(--base-fz);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .result-section__title {
        margin-top: 16px;
        margin-left: 20px;
    }
}
@media (max-width: 767px) {
    .result-section__title {
        margin-left: 20px;
        margin-bottom: 12px;
    }
}

.results-list:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}

.results-list_attr {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 16px 20px 8px;
}

@media (min-width: 768px) {
    .results-group {
        padding: 4px 0;
    }
}

.result {
    width: 100%;
    text-align: left;
    transition: background-color var(--transition);
}
@media (min-width: 768px) {
    .result {
        padding: 12px 20px 12px 16px;
    }

    .result:hover,
    .result_focus {
        background-color: var(--lightest-bg);
    }
}
@media (max-width: 767px) {
    .result {
        padding: 12px;
    }

    .result-wrap:first-child .result {
        padding-top: 0;
    }
}

.pill-btn {
    display: inline-block;
    padding: 8px 12px;
    background-color: var(--lightest-bg);
    border-radius: 42px;
    transition: background-color var(--transition);
}
.pill-btn_focus,
.pill-btn:hover,
.pill-btn:focus {
    background-color: var(--light-bg);
}

.pill-btn__label {
    font-size: var(--base-fz);
    line-height: var(--mini-lh);
    color: var(--dark-c);
}

.result__name {
    display: block;
    font-family: var(--f-semi-bold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.result__car {
    display: block;
    margin-top: 4px;
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
    font-family: var(--f-base);
}

.result__car >>> .car-name__main-wrap {
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
    font-family: var(--f-base);
}

.result__main {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.result__cover-wrap {
    margin-right: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.result__cover {
    border-radius: var(--border-radius);
}

.result__cover_part {
    width: 40px;
    height: 40px;
}

.result__cover_category {
    width: 40px;
    height: 40px;
}

.result__cover_brand {
    width: 40px;
    height: 27px;
}
.result__brand-cover-wrap .result__cover_brand {
    width: 36px;
    height: 24px;
}

.result__cover_product {
    width: 40px;
    height: 40px;
}

.result__part-brand {
    margin-left: auto;
    display: flex;
}

.result__part-brand-name {
    margin-right: 12px;
    display: inline-flex;
    align-items: center;
    font-family: var(--f-base);
}

.result__count {
    margin-left: auto;
    display: flex;
}

.result__count-value {
    margin-right: 12px;
    display: inline-flex;
    align-items: center;
    font-family: var(--f-base);
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
}

.results__more-wrap {
    padding: 12px 20px;
}
</style>