<template>
    <RouterLink
        :to="{
            name: modelName === 'product' ? 'market-product-description' : modelName === 'part' ? 'market-new-part-description' : 'market-contract-part-description',
            params: {
                id: item.id,
            },
        }"
        class="product layer-1"
    >
        <MarketItemCover
            :item="item"
            :modelName="modelName"
            coverCodename="mini"
            noLink
            class="cover"
        ></MarketItemCover>

        <div
            class="title"
            :title="item.name"
        >
            {{ item.name }}
        </div>
    </RouterLink>
</template>

<script>
import MarketItemCover from '@/components/MarketItemCover.vue';

export default {
    name: 'MarketItemMinimal',

    components: { MarketItemCover },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part'].includes(value);
            },
            default: 'product',
        },
    },

    computed: {
        coverSrc() {
            const imageLink = ((this.item.cover || {}).thumbnails || {}).market_item_cover_mini;
            return imageLink
                ? this.$links.uploads + imageLink
                : require('../assets/images/product-placeholder.svg');
        },
    },
};
</script>

<style scoped>
.product {
    display: flex;
    align-items: center;
    padding: var(--gap-micro);
    transition: color var(--transition), box-shadow var(--transition);
}

.product:hover,
.product:focus {
    color: var(--primary-color);
    box-shadow: var(--hover-light-shadow);
}

.cover {
    margin-right: var(--gap-micro);
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    font-family: var(--f-bold);
}
</style>
