<template>
    <div class="layer-1">
        <FilterSwitcher
            :filters="filters"
            :value="value"
            :amount="amount"
            :loading="loading"
            @change="change"
            @submit="submit"
            @reload="reload"
        ></FilterSwitcher>

        <div
            v-if="checkedFilters.length"
            class="footer"
        >
            <ButtonText
                type="reset"
                primary
                dashed
                @click="reset"
            >
                Сбросить фильтры
            </ButtonText>
        </div>
    </div>
</template>

<script>
import FilterSwitcher from '@/components/FilterSwitcher.vue';
import ButtonText from "@/components/_buttons/ButtonText.vue";

export default {
    name: 'DesktopUI',

    components: {
        ButtonText,
        FilterSwitcher,
    },

    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },

        filters: {
            type: Array,
            default() {
                return [];
            },
        },

        checkedFilters: {
            type: Array,
            default: () => [],
        },

        amount: Number,

        loading: Boolean,
    },

    methods: {
        reset() {
            this.$emit('reset');
        },

        submit() {
            this.$emit('submit');
        },

        reload() {
            this.$emit('reload');
        },

        change(payload) {
            this.$emit('change', payload);
        },
    },
};
</script>

<style scoped>
.footer {
    padding: 20px;
    background-color: var(--light-c);
    text-align: center;
}
</style>