var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InputRadioBase',_vm._g({attrs:{"id":_vm.id,"options":_vm.options,"value":_vm.valueComputed,"label":_vm.label,"optionKey":_vm.optionKey,"getOptionKey":_vm.getOptionKey,"optionLabel":_vm.optionLabel,"getOptionLabel":_vm.getOptionLabel,"optionComparator":_vm.optionComparator,"isOptionSelected":_vm.isOptionSelected,"reduce":_vm.reduce,"wrapClasses":"input-radio-simple__wrap","itemClasses":"input-radio-simple__item","inputClasses":"input-radio-simple__input","labelClasses":[
        'input-radio-simple__label',
        {
            'input-radio-simple__label_center': _vm.center,
        }
    ]},scopedSlots:_vm._u([{key:"label",fn:function(ref){
    var option = ref.option;
    var id = ref.id;
    var checked = ref.checked;
return [_vm._t("label",function(){return [_c('label',{staticClass:"input-radio-simple__label",class:_vm.labelClasses,attrs:{"for":id}},[_vm._v("\n                "+_vm._s(_vm.getOptionLabel(option))+"\n            ")])]},null,{ option: option, id: id, classes: 'input-radio-simple__label', checked: checked })]}}],null,true)},_vm.listeners))}
var staticRenderFns = []

export { render, staticRenderFns }