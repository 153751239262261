<template>
    <section class="account-insurance-policies">
        <div
            v-if="!initialized"
            class="spinner-wrap"
        >
            <Spinner
                center
                size="big"
            ></Spinner>
        </div>

        <div
            v-else
            class="profile__main"
        >
            <EmptyBlock
                v-if="!policies.length"
                icon="document"
                title="Нет активных страховых полисов"
            >
                <template #button>
                    <ButtonBlock
                        :to="{
                            name: 'insurance-osago'
                        }"
                        primary
                        low
                        class="empty__btn"
                    >
                        Оформить страховку
                    </ButtonBlock>
                </template>

                <template #text>
                    Оформите полис ОСАГО онлайн
                    <br>
                    без переплат, быстро и безопасно.
                </template>
            </EmptyBlock>

            <template v-else>
                <ol>
                    <li
                        v-for="(policy, policyIndex) in policies"
                        :key="'policy-' + policyIndex"
                        class="policy layer-1"
                    >
                        <div class="policy__head">
                            <InsuranceCompanyLogo
                                v-if="policy.company.logo"
                                :logo="policy.company.logo"
                                :name="policy.company.name"
                                class="policy__logo"
                            ></InsuranceCompanyLogo>

                            <div class="policy__company">
                                <h3 class="h3">
                                    Полис ОСАГО<span v-if="policy.policy_number"> №&nbsp;{{ policy.policy_number }}</span>
                                </h3>
                                <div class="secondary-info mt-4">
                                    Оформлен <template v-if="policy.is_paid && policy.datetime_paid">
                                        {{ policy.datetime_paid | dt }}
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="policy__body">
                            <div class="policy__car">
                                <div class="policy__label">
                                    Автомобиль:
                                </div>

                                <p>
                                    {{ (policy.contract.vehicle_model || {}).fullname }},<template
                                        v-if="policy.contract.vehicle_year"
                                    > {{ policy.contract.vehicle_year }}</template><template
                                        v-if="policy.contract.vehicle_engine_power"
                                    >
                                        ({{ policy.contract.vehicle_engine_power }} л. с.)</template><template
                                        v-if="policy.contract.vehicle_license_plate"
                                    >, {{ policy.contract.vehicle_license_plate }}</template><template
                                        v-else-if="policy.contract.vehicle_doc_number"
                                    >{{ policy.contract.vehicle_doc_number }}</template>
                                </p>
                            </div>

                            <div class="policy__period">
                                <div class="policy__label">
                                    Период действия:
                                </div>
                                <p
                                    :class="{
                                        accent_red: policy.days_before_ends,
                                    }"
                                >
                                    {{ policy.contract.action_start_date | date }} – {{ policy.contract.action_end_date | date }}
                                    <span v-if="policy.days_before_ends"> (истекает через {{ policy.days_before_ends }})</span>
                                </p>
                            </div>

                            <div class="policy__insurer">
                                <div class="policy__label">
                                    Страхователь:
                                </div>

                                <p>
                                    {{ policy.contract.insurer_last_name }} {{ policy.contract.insurer_first_name }} {{ policy.contract.insurer_middle_name }}
                                </p>
                            </div>
                        </div>

                        <div class="policy__footer">
                            <InfoMessage
                                v-if="!policy.is_paid && policy.seconds_to_pay_expiration > 0"
                                error
                                class="policy__info-message"
                            >
                                Страховая компания проверила ваши данные, осталось только оплатить полис.
                            </InfoMessage>

                            <InfoMessage
                                v-if="!policy.is_paid && policy.seconds_to_pay_expiration === 0"
                                class="policy__info-message"
                                error
                            >
                                Время на оплату истекло, скоро страховой полис будет автоматически отменён.
                            </InfoMessage>

                            <InfoMessage
                                v-if="policy.is_paid && (!policy.document_file || !policy.document_file.url)"
                                class="policy__info-message"
                            >
                                Идёт оформление полиса, мы уведомим вас по email, когда полис станет доступен для скачивания.
                            </InfoMessage>

                            <div class="policy__actions">
                                <ButtonBlock
                                    v-if="!policy.is_paid && policy.seconds_to_pay_expiration > 0"
                                    :href="policy.payment_url"
                                    target="_blank"
                                    primary
                                    low
                                    class="policy__action-btn"
                                >
                                    Оплатить
                                </ButtonBlock>
                                <!--                                <ButtonBlock-->
                                <!--                                    primary-->
                                <!--                                    low-->
                                <!--                                    class="policy__action-btn"-->
                                <!--                                    @click="extend"-->
                                <!--                                >-->
                                <!--                                    Продлить-->
                                <!--                                </ButtonBlock>-->
                                <ButtonBlock
                                    v-if="policy.is_paid && policy.document_file && policy.document_file.url"
                                    :href="$links.uploads + policy.document_file.url"
                                    :download="`Полис ОСАГО ${ policy.policy_number }`"
                                    target="_blank"
                                    secondary
                                    low
                                    class="policy__action-btn"
                                >
                                    Скачать полис
                                </ButtonBlock>
                            </div>
                        </div>
                    </li>
                </ol>

                <div class="more-wrap">
                    <ButtonText
                        v-if="policies.length < currentCount"
                        dark
                        dashed
                        secondary
                        :loading="fetching"
                        @click="more"
                    >
                        Показать ещё
                    </ButtonText>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
// utils
import { mapActions, mapMutations, mapState } from 'vuex';
import accountInsurancePolicies from '@/store/modules/accountInsurancePolicies.js';
// components
import Spinner from '@/components/Spinner.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InsuranceCompanyLogo from '@/pages/insurance/InsuranceCompanyLogo.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';


export default {
    name: 'AccountInsurancePolicies',

    components: {
        ButtonText,
        InfoMessage,
        InsuranceCompanyLogo,
        ButtonBlock,
        EmptyBlock,
        Spinner,
    },

    serverPrefetch() {
        return this.prefetch();
    },

    metaInfo() {
        return {
            title: 'ЛК / Мои полисы ОСАГО',
        };
    },

    computed: {
        ...mapState({
            currentCount: state => state.accountInsurancePolicies.currentCount,
            initialized: state => state.accountInsurancePolicies.initialized,
            fetching: state => state.accountInsurancePolicies.fetching,
            policies: state => state.accountInsurancePolicies.policies,
        }),

        // policiesComputed() {
        //     return this.policies.items.map(policy => {
        //         const actionEndDate = new Date(policy.contract.action_end_date);
        //
        //         if (actionEndDate) {
        //             //  TODO: переделать на серверное время
        //             const now = new Date();
        //             policy.is_end = now > actionEndDate;
        //
        //             if (actionEndDate > now) {
        //                 const daysLag =
        //                     Math.ceil(Math.abs(actionEndDate.valueOf() - now.valueOf()) / (1000 * 3600 * 24)) - 1;
        //
        //                 if (daysLag <= 10) {
        //                     policy.days_before_ends = daysLag + ' ' + plural(daysLag, ['день', 'дня', 'дней']);
        //                 }
        //             }
        //         }
        //
        //         return policy;
        //     });
        // },
    },

    created() {
        this.$store.registerModule('accountInsurancePolicies', accountInsurancePolicies);
    },

    mounted() {
        if (!this.initialized) {
            this.prefetch();
        }
    },

    beforeDestroy() {
        this.destroy();
        this.$store.unregisterModule('accountInsurancePolicies');
    },

    methods: {
        ...mapMutations({
            destroy: 'accountInsurancePolicies/destroy',
        }),

        ...mapActions({
            prefetch: 'accountInsurancePolicies/prefetch',
            more: 'accountInsurancePolicies/more',
        }),

        extend() {
            console.log('extend');
        },
    },
};
</script>

<style scoped src="../../../assets/css/profile.css"></style>
<style scoped>
.spinner-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

@media (min-width: 1040px) {
    .policy__head {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .policy__head {
        padding: 24px;
    }
}
@media (min-width: 768px) {
    .policy__head {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: flex-start;
    }
}
@media (max-width: 767px) {
    .policy__head {
        padding: 16px;
    }
}

.policy__body {
    border-top: 1px solid var(--border-light-c);
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .policy__body {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .policy__body {
        padding: 24px;
    }
}
@media (min-width: 768px) {
    .policy__body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px 20px;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .policy__body {
        padding: 16px;
    }
}

.policy__label {
    margin-bottom: 4px;
    font-family: var(--f-bold);
}

@media (min-width: 768px) and (max-width: 1039px) {
    .policy__car {
        margin-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .policy__car {
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) {
    .policy__period {
        text-align: right;
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .policy__insurer {
        margin-top: 24px;
    }
}
@media (max-width: 767px) {
    .policy__insurer {
        margin-top: 16px;
    }
}

@media (min-width: 768px) {
    .policy__logo {
        margin-left: 16px;
    }
}
@media (max-width: 767px) {
    .policy__logo {
        margin-bottom: 8px;
    }
}

@media (min-width: 1040px) {
    .policy__footer {
        padding: 24px 36px;
    }
}
@media (max-width: 1039px) {
    .policy__footer {
        padding: var(--base-card-padding);
    }
}
@media (min-width: 768px) {
    .policy__footer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .policy__actions {
        display: flex;
    }
}

.policy__label {
    font-family: var(--f-semi-bold);
}

@media (min-width: 768px) {
    .policy__action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .policy__action-btn {
        width: 100%;
    }
    .policy__action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

.more-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
</style>