<template>
    <FilterWidgetWrap
        v-if="config.categories.length"
        codename="category"
        title="Категория"
        :value="[value]"
        withAdaptation
        widget="categories"
        :hasResetBtn="false"
        class="filter-categories"
    >
        <button
            class="category__name-wrap link-primary-hover"
            :class="{
                'category__name-wrap_active': !value.id,
            }"
            :disabled="!value.id"
            @click="clear"
        >
            <span class="category__name-text">
                Все автотовары <span class="category__name-counter">{{ totalAmount | number }}</span>
            </span>
        </button>

        <div class="body">
            <FilterCategoriesItem
                :value="value"
                :categories="config.categories"
                :config="config"
                @change="change"
            ></FilterCategoriesItem>
        </div>
    </FilterWidgetWrap>
</template>

<script>
import FilterCategoriesItem from './FilterCategoriesItem.vue';
import FilterWidgetWrap from '@/components/FilterWidgetWrap.vue';

export default {
    name: 'FilterCategories',

    components: {
        FilterWidgetWrap,
        FilterCategoriesItem,
    },

    props: {
        value: {
            type: Object,
            required: true,
        },

        config: {
            type: Object,
            default() {
                return {
                    label: '',
                    codename: '',
                    id: '',
                    name: '',
                };
            },
        },
    },

    data() {
        return {
            loading: true,
            categories: [],
        };
    },

    computed: {
        totalAmount() {
            return this.config.categories.reduce((accumulator, category) => {
                return accumulator + category.count;
            }, 0);
        },
    },

    // mounted() {
    //     if (this.config.categories) {
    //         this.categories = this.config.categories;
    //     } else {
    //         this.fetch();
    //     }
    // },

    methods: {
        async fetch() {
            const url = this.config.api;

            try {
                this.categories = await this.$http.get(url);
                this.loading = false;
            }
            catch (e) {
                console.error(e);
            }
        },

        change($event) {
            this.$emit('change', $event);
            this.$emit('reload');
        },

        clear() {
            this.$emit('change', {});
            this.$emit('reload');
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .filter-categories >>> .head {
        display: none;
    }

    .filter-categories >>> .filter-widget__content {
        margin-top: 0;
        padding-top: 20px;
    }
}

.category__name-wrap {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.category__name-counter {
    margin-left: 4px;
    font-size: var(--small-fz);
    color: var(--font-secondary-color);
    transition: color var(--transition);
}

.category__name-wrap:hover .category__name-counter,
.category__name-wrap:focus .category__name-counter {
    color: var(--primary-color);
}

@media (min-width: 768px) {
    .category__name-wrap {
        margin-bottom: 12px;
        padding-bottom: 4px;
    }

    .category__name-wrap_active {
        font-family: var(--f-semi-bold);
    }
}
@media (max-width: 767px) {
    .category__name-wrap {
        display: flex;
        align-items: flex-start;
        margin-top: -12px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .category__name-wrap::before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-right: 12px;
        border-radius: 50%;
        border: 1px solid var(--border-dark-c);
        background-color: var(--light-c);
        transition: border-color var(--transition-fastest);
    }

    .category__name-wrap:not(.category__name-wrap_active):hover::before {
        border-color: var(--fields-border);
        transition: border-color var(--transition);
    }

    .category__name-wrap_active::before {
        border-width: 6px;
        border-color: var(--primary-color);
        transition: border-color var(--transition-fastest);
    }

    .body {
        margin-bottom: 8px;
    }
}
</style>