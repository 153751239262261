<template>
    <div class="profile__main_inner">
        <USpinner
            v-if="initLoading"
            big
            center
            class="mt-20"
        ></USpinner>

        <template v-else>
            <EmptyBlock
                v-if="ordersCount === 0"
                icon="cart-stroke"
                title="Нет заказов"
                text="Здесь будут отображаться все выполненные и отмененные заказы."
                :hasBtn="false"
            ></EmptyBlock>

            <template v-else>
                <ArchiveOrder
                    v-for="order in orders"
                    :key="'order-' + order.uuid"
                    :initialOrder="order"
                    class="order layer-1"
                    @initReturn="handleInitReturn"
                ></ArchiveOrder>

                <ButtonText
                    v-if="hasNext"
                    class="load-more-btn"
                    dashed
                    :loading="loading"
                    @click="load"
                >
                    Показать ещё
                </ButtonText>
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ArchiveOrder from "./components/ArchiveOrder.vue";
import ButtonText from "@/components/_buttons/ButtonText.vue";
import USpinner from '@ui/components/USpinner/USpinner.vue';

export default {
    name: "ProfileOrdersArchive",

    components: {
        USpinner,
        ButtonText,
        ArchiveOrder,
        EmptyBlock,
    },

    metaInfo() {
        return {
            title: 'ЛК / Мои завершенные заказы',
        };
    },

    data() {
        return {
            initLoading: true,
            loading: false,
            rangeData: {
                limit: 20,
                offset: 0,
            },
        };
    },

    computed: {
        ...mapState({
            orders: state => state.profileOrders.archivedOrders.items,
            ordersCount: state => state.profileOrders.archivedOrders.current_count,
            hasNext: state => state.profileOrders.archivedOrders.hasNext,
        }),
    },

    mounted() {
        this.$breadcrumbs([]);
        this.init();
    },

    methods: {
        ...mapMutations({
            SET: 'profileOrders/setArchivedOrders',
            ADD: 'profileOrders/addArchivedOrders',
            ADD_ACTIVE: 'profileOrders/addActiveOrders',
            REMOVE: 'profileOrders/removeArchivedOrder',
            DESTROY: 'profileOrders/destroy',
        }),

        ...mapActions({
            GET: 'profileOrders/getArchivedOrders',
        }),

        async init() {
            this.rangeData.offset = 0;
            const response = await this.GET(this.rangeData);
            if (response) this.SET(response);
            this.initLoading = false;
        },

        async load() {
            this.loading = true;
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.GET(this.rangeData);
            if (response) this.ADD(response);
            this.loading = false;
        },

        handleInitReturn(order) {
            this.$router.push({ name: 'account-orders-active' });
            this.REMOVE({ uuid: order.uuid });
        },
    },
};
</script>