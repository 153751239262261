<template>
    <ChatTemplate class="chat-empty-list__template">
        <template #header>
            <div class="chat-empty-list__header">
                <span class="chat-empty-list__name">Онлайн-чат</span>

                <button
                    type="button"
                    aria-label="Закрыть чат"
                    class="chat-empty-list__close-button"
                    @click="$emit('close')"
                >
                    <UIcon
                        name="close-24"
                        big
                        class="chat-empty-list__close-icon"
                    ></UIcon>
                </button>
            </div>
        </template>

        <template #body>
            <ChatPlaceholderTemplate
                :icon="{
                    name: 'review',
                    big: true,
                }"
            >
                <template #text>
                    <p>
                        Общайтесь напрямую с магазинами или задайте вопрос нашему менеджеру Ubibi.
                    </p>

                    <p>
                        Здесь будет отображаться список чатов.
                    </p>
                </template>
            </ChatPlaceholderTemplate>
        </template>
    </ChatTemplate>
</template>

<script>
import ChatPlaceholderTemplate from '@/components/chat/ChatPlaceholderTemplate.vue';
import ChatTemplate from '@/components/_chat/ChatTemplate.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'ChatEmptyList',

    components: {
        UIcon,
        ChatTemplate,
        ChatPlaceholderTemplate,
    },
};
</script>

<style>
@media (min-width: 768px) {
    .chat-empty-list__template .chat-template__header {
        display: none;
    }
}

.chat-empty-list__header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 1;
}
@media (max-width: 767px) {
    .chat-empty-list__header {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}

.chat-empty-list__name {
    font-family: var(--f-bold);
    font-size: var(--big-fz);
    line-height: 1.37;
    margin-left: auto;
    margin-right: auto;
}

.chat-empty-list__close-button {
    position: absolute;
    right: var(--popup-paddings-x);
    display: flex;
}

.chat-empty-list__close-icon {
    fill: var(--font-secondary-color);
}
.chat-empty-list__close-button:hover .chat-empty-list__close-icon,
.chat-empty-list__close-button:active .chat-empty-list__close-icon {
    fill: var(--dark-c);
}
</style>