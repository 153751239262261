export default ({ HTTP }) => ({
    async get(config) {
        const url = '/search_history/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async addToHistory(data, config) {
        const url = '/search_history/add_to_history/';
        const response = await HTTP.post(url, data, config);
        return response.data;
    },
});