<template>
    <button
        tabindex="-1"
        type="button"
        :class="{
            'chat-fly-button_show': show
        }"
        class="chat-fly-button"
        @click="$emit('click')"
    >
        <UIcon
            name="close-24"
            :class="{
                'chat-fly-button__icon_show': show
            }"
            class="chat-fly-button__icon chat-fly-button__icon-cross"
            big
        ></UIcon>

        <UIcon
            name="chat-fill-24"
            :class="{
                'chat-fly-button__icon_show': !show
            }"
            class="chat-fly-button__icon chat-fly-button__icon-chat"
            big
        ></UIcon>

        <UCircleLabelCounter
            :count="unreadChatMessagesCount"
            class="chat-fly-button__counter"
        ></UCircleLabelCounter>
    </button>
</template>

<script>
import socket from '@/services/web-socket';
import UCircleLabelCounter from '@ui/components/UBadge/UBadge.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: 'ChatFlyButton',

    components: {
        UIcon,
        UCircleLabelCounter,
    },

    props: {
        show: Boolean,
    },

    computed: {
        unreadChatMessagesCount() {
            return this.$store.state.chat.unread.count;
        },
    },

    mounted() {
        socket.onopen(() => {
            socket.on('notify.chat.not_seen_message_amount', this.onChangeUnreadChatMessagesCount);
        });

        // TODO: перенести на subscribe setIsAuthorized во vuex
        this.fetchNotSeenMessageAmount();
    },

    beforeDestroy() {
        socket.off('notify.chat.not_seen_message_amount', this.onChangeUnreadChatMessagesCount);
    },

    methods: {
        onChangeUnreadChatMessagesCount(data) {
            if (data && data.not_seen_messages_amount !== undefined) {
                this.$store.commit('chat/unread/set', data.not_seen_messages_amount);
            }
        },

        async fetchNotSeenMessageAmount() {
            if (this.$store.state.profile.isAuthorized) {
                try {
                    const { not_seen_message_amount } = await this.$api.users.chatMessageAmount();
                    this.$store.commit('chat/unread/set', not_seen_message_amount);
                }
                catch (error) {
                    this.$store.commit('handleCommonHttpError', error);
                }
            }
        },
    },
};
</script>

<style>
.chat-fly-button {
    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: var(--chat-fly-btn);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: var(--blue-medium);
    border-radius: 50%;
    box-shadow: var(--base-shadow);
    transition: background-color var(--transition);
}

.chat-fly-button__counter {
    position: absolute;
    top: 0;
    right: -4px;
    opacity: 1;
    transition: opacity var(--transition-fast);
}

.chat-fly-button_show.chat-fly-button_show .chat-fly-button__counter {
    opacity: 0;
}

.chat-fly-button:hover {
    background-color: var(--blue-dark);
}

.chat-fly-button:active {
    background-color: var(--blue-darkest);
}

.chat-fly-button__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    fill: var(--light-c);
}

.chat-fly-button__icon-cross {
    transform: rotate(0deg);
    opacity: 0;
    transition: fill var(--transition), opacity var(--transition), transform var(--transition);
}

.chat-fly-button__icon-cross.chat-fly-button__icon_show {
    transform: rotate(90deg);
    opacity: 1;
}

.chat-fly-button__icon-chat {
    opacity: 0;
    transition: fill var(--transition), opacity var(--transition);
}

.chat-fly-button__icon-chat.chat-fly-button__icon_show {
    opacity: 1;
}
</style>