<template>
    <div
        :class="['placeholder-icon', 'placeholder-icon_' + size]"
        aria-hidden="true"
    >
        <UIcon
            :name="icon"
            class="placeholder-icon__icon"
        ></UIcon>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: "PlaceholderIcon",

    components: {UIcon},

    props: {
        icon: {
            type: String,
            default: '',
        },

        size: {
            type: String,
            default: 'big',
        },
    },
};
</script>

<style scoped>
.placeholder-icon {
    position: relative;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
}

.placeholder-icon_big {
    width: 120px;
    height: 120px;
    padding: 34px;
}

.placeholder-icon_big .placeholder-icon__icon {
    width: 52px;
    height: 52px;
}

@media (min-width: 768px) {
    .placeholder-icon_small {
        width: 72px;
        height: 72px;
        padding: 20px;
    }

    .placeholder-icon_small .placeholder-icon__icon {
        width: 32px;
        height: 32px;
    }
}
@media (max-width: 767px) {
    .placeholder-icon_small {
        width: 48px;
        height: 48px;
        padding: 12px;
    }

    .placeholder-icon_small .placeholder-icon__icon {
        width: 24px;
        height: 24px;
    }
}

.placeholder-icon::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(315deg, #D2D5D9 0%, #F4F6F8 100%);
    opacity: 0.6;
}

.placeholder-icon__icon {
    position: relative;
    z-index: 1;
    fill: var(--font-secondary-color);
    opacity: 1;
}
</style>