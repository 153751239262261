import { time as timeRe, year as yearRe } from '@ui/utils/regs.js';


export const time = value => {
    if (value && timeRe.test(value)) {
        // split + join быстрее regexp
        return value.split(':').slice(0, 2).join(':');
    }

    return value || '';
};

export const date = value => {
    const re = /(\d{4})-(\d{2})-(\d{2})/;

    if (value && re.test(value)) {
        return value.replace(re, '$3.$2.$1');
    }

    return value || '';
};

export const datetime = value => {
    if (value) {
        const date = new Date(value);

        if (!isNaN(date)) {
            const [, year, month, day, time] = /(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2})/.exec(value);
            return `${ day }.${ month }.${ year } ${ time }`;
        }
    }

    return value || '';
};

export const calendar = value => {
    const re = /(\d{4})-(\d{2})-(\d{2})/;

    if (value && re.test(value)) {
        return value.replace(re, '$3.$2.$1');
    }

    return value || '';
};

export const year = value => {
    if (value && yearRe.test(value)) {
        return value.toString();
    }

    return value || '';
};

export const checkbox = value => {
    if (typeof value === 'boolean') return value;
    return false;
};

export const humanizers = {
    time,
    date,
    datetime,
    calendar,
    year,
    checkbox,
};

export default (value, format) => {
    if (humanizers[format]) {
        return humanizers[format](value);
    }

    return value;
};