<template>
    <div class="info-page-layout__main">
        <InfoPageLayoutHead></InfoPageLayoutHead>

        <transition-group
            tag="div"
            name="fade-page"
            mode="out-in"
            appear
            class="position-relative"
        >
            <Spinner
                v-if="!initialized"
                key="loading"
                size="big"
                class="content-loader"
            ></Spinner>

            <div
                v-else
                key="content"
            >
                <ol v-if="current_count" class="list">
                    <li
                        v-for="(item, index) in items"
                        :key="'mention-item-' + index"
                        class="item-wrap"
                    >
                        <a
                            v-lazy-container="{ selector: 'img' }"
                            :href="item.url"
                            class="item layer-1"
                            target="_blank"
                            rel="noopener"
                        >
                            <div
                                class="cover-wrap"
                            >
                                <img
                                    :data-src="$links.uploads + item.cover.thumbnails.publications_cover_list"
                                    alt=""
                                    class="cover"
                                    height="150"
                                >
                            </div>

                            <div class="info">
                                <time class="date">{{ item.publication_date | dt }}</time>
                                <h2 class="title">
                                    {{ item.title }}
                                </h2>

                                <p class="link">{{ item.source }}</p>
                            </div>
                        </a>
                    </li>
                </ol>

                <ButtonBlock
                    v-if="hasNext && current_count"
                    class="load-more-btn"
                    :loading="loading"
                    @click="load"
                >
                    Показать ещё
                </ButtonBlock>

                <EmptyBlock
                    v-if="current_count === 0"
                    icon="document"
                    title="Нет публикаций"
                    :hasBtn="false"
                ></EmptyBlock>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import publications from '@/store/modules/publications.js';
import { HTTP } from '@/http.js';
import EmptyBlock from "@/components/EmptyBlock.vue";
import InfoPageLayoutHead from "@/components/InfoPageLayoutHead.vue";
import Spinner from "@/components/Spinner.vue";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";

export default {
    name: "PublicationsPage",

    components: {
        ButtonBlock,
        Spinner,
        InfoPageLayoutHead,
        EmptyBlock,
    },

    serverPrefetch() {
        return this.INIT({ params: this.rangeData });
    },

    data() {
        return {
            rangeData: {
                limit: 20,
                offset: 0,
                order_by: '-publication_date',
            },
            source: null,
            loadingTimeout: null,
            loading: false,
        };
    },

    computed: {
        ...mapState({
            items: state => state.publications.items,
            current_count: state => state.publications.current_count,
            initialized: state => state.publications.initialized,
        }),

        hasNext() {
            return (this.rangeData.offset + this.rangeData.limit) < this.current_count;
        },
    },

    created() {
        this.$store.registerModule('publications', publications);
    },

    mounted() {
        if (!this.initialized) this.INIT({ params: this.rangeData });
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('publications');
    },

    methods: {
        ...mapMutations({
            ADD_ITEMS: 'publications/addItems',
            DESTROY: 'publications/destroy',
        }),

        ...mapActions({
            INIT: 'publications/get',
            GET_ITEMS: 'publications/getItems',
        }),

        async fetch() {
            this.loadingTimeout = setTimeout(() => {
                this.loading = true;
            }, 300);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                params: this.rangeData,
                cancelToken: this.source.token,
            };

            const response = await this.GET_ITEMS(payload);
            if (response) {
                this.source = null;
                clearTimeout(this.loadingTimeout);
                return response;
            }

            this.loading = false;
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) this.ADD_ITEMS(response);
        },
    },
};
</script>

<style scoped>
.item-wrap:not(:last-child) {
    margin-bottom: var(--y-grid-gap);
}

.item {
    display: block;
    overflow: hidden;
    transition: var(--transition), box-shadow var(--transition);
}
@media (min-width: 768px) {
    .item {
        display: flex;
    }
}

.item:hover,
.item:focus {
    transform: translateY(-4px);
    box-shadow: var(--hover-light-shadow);
}

.item:hover .title,
.item:focus .title {
    color: var(--primary-color)
}

.cover-wrap {
    display: block;
    flex-shrink: 0;
    background-color: var(--light-bg);
}
@media (min-width: 1040px) {
    .cover-wrap {
        width: 280px;
        min-height: 150px;
    }
}

.cover {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

@media (min-width: 768px) {
    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .info {
        padding: 20px;
    }
}

.date {
    display: block;
    margin-bottom: 8px;
    font-size: 12px;
    color: var(--font-secondary-color);
}

.title {
    margin-bottom: 12px;
    font-size: var(--big-fz);
    font-family: var(--f-bold);
    transition: color var(--transition);
}

.link {
    color: var(--font-secondary-dark-color);
    font-size: 12px;
}
@media (min-width: 768px) {
    .link {
        margin-top: auto;
    }
}

@media (min-width: 1040px) {
    .content-loader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (max-width: 1039px) {
    .content-loader {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>