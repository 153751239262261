export default {
    namespaced: true,

    state: () => ({
        items: [],
        current_count: 0,
        initialized: false,
    }),

    mutations: {
        setItems(state, { results, current_count }) {
            state.items = results;
            state.current_count = current_count;
        },

        addItems(state, { results, current_count }) {
            state.items = [...state.items, ...results];
            state.current_count = current_count;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.items = [];
            state.current_count = 0;
            state.initialized = false;
        },
    },

    actions: {
        async get({ commit }, { params }) {
            try {
                const items = await this.$api.publications.get({ params });

                commit('setItems', items);
                commit('setInitialized');
                commit('clearHttpError', null, {root: true});

                return {items};
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getItems({ commit }, { params, cancelToken }) {
            try {
                return await this.$api.publications.get({ params, cancelToken });
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};