import { paramsSerializer } from '@/lib/paramsSerializer.js';


export default ({ HTTP }) => ({
    async get(config) {
        const response = await HTTP.get('/es_products/', {
            ...config,
            paramsSerializer,
        });
        return response.data;
    },
});