<template>
    <UIconButton
        name="menu-24"
        :secondary="secondary"
        big
        class="u-menu-icon"
        v-bind="$attrs"
        v-on="$listeners"
    ></UIconButton>
</template>

<script>
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';


export default {
    name: 'UMenuIcon',

    components: { UIconButton },

    inheritAttrs: false,

    props: {
        secondary: {
            type: Boolean,
            default: true,
        },
    },
};
</script>