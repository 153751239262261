export default {
    data() {
        return {
            stack: {
                zIndex: 10000,
            },
        };
    },

    methods: {
        updateZIndex() {
            const classNames = '.u-tooltip__content_active, .u-menu__content_active, .u-popup_active';
            const elements = document.getElementsByClassName(classNames);
            const zIndexes = [...elements].map(el => Number(el.dataset.zIndex));

            if (zIndexes.length) {
                this.zIndex = Math.max(...zIndexes) + 1;
            }
        },
    },
};