var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('UTooltip',{attrs:{"noDetach":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var handlers = ref.handlers;
var attrs = ref.attrs;
return [(_vm.value)?_c('button',_vm._g(_vm._b({ref:"likeActive",staticClass:"like-btn like-btn_active btn btn_icon",class:{
                'like-btn_with-text': _vm.withText,
                'like-btn_without-text-on-xs': _vm.noTextOnXS
            },on:{"click":_vm.removeFromFavorites}},'button',attrs,false),handlers),[_c('UIcon',{staticClass:"like-icon like-icon_fill",attrs:{"name":"heart-btn-fill"}}),_vm._v(" "),(_vm.withText)?_c('span',{staticClass:"like-text",class:{
                    'hidden-s': _vm.noTextOnXS
                }},[_vm._v("В избранном")]):_vm._e()],1):_vm._e()]}}],null,false,464214768)},[_vm._v("\n\n    Убрать из избранного\n")]):_c('UTooltip',{attrs:{"maxWidth":_vm.isAuthorized ? 180 : null,"noDetach":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                var handlers = ref.handlers;
                var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({ref:"likeNotActive",staticClass:"like-btn btn btn_icon",class:{
                'like-btn_with-text': _vm.withText,
                'like-btn_no-authorised': !_vm.isAuthorized,
                'like-btn_without-text-on-xs': _vm.noTextOnXS,
            },on:{"click":_vm.addToFavorites}},'button',attrs,false),handlers),[_c('UIcon',{staticClass:"like-icon like-icon_stroke",attrs:{"name":"heart-btn-stroke"}}),_vm._v(" "),(_vm.withText)?_c('span',{staticClass:"like-text",class:{
                    'hidden-s': _vm.noTextOnXS
                }},[_vm._v("В избранное")]):_vm._e()],1)]}}])},[_vm._v(" "),(_vm.isAuthorized)?_c('span',[_vm._v("\n        Добавить в избранное\n    ")]):_c('span',[_vm._v("\n        Только авторизованные пользователи могут добавлять в избранное\n    ")])])}
var staticRenderFns = []

export { render, staticRenderFns }