<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <FormManager
            ref="form"
            v-model="formData"
            class="popup-content"
            @submit="submit"
        >
            <h2 class="h2">
                Отменить заявку на возврат
            </h2>

            <h3 class="h4 h4_block top-gap_small">
                Укажите причину отмены:
            </h3>

            <FormField
                type="radio"
                name="reason"
                :options="reasonOptions"
                class="reason-radio"
            ></FormField>

            <FormField
                type="text"
                name="customReason"
                label="Причина отмены"
                :validations="formData.reason === 'custom' ? 'required' : ''"
                :disabled="formData.reason !== 'custom'"
            ></FormField>

            <div class="actions-wrap">
                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="close"
                >
                    Нет
                </ButtonBlock>
                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    class="action-btn"
                >
                    Подтвердить
                </ButtonBlock>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import FormManager from "@/components/_form/FormManager.vue";
import FormField from "@/components/_form/FormField.vue";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";


export default {
    name: "CancelReturnOrderPopup",

    components: { ButtonBlock, FormField, FormManager, PopupWrap },

    mixins: [popup],

    props: {
        uuid: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            formData: {
                reason: '',
                customReason: '',
            },
            reasonOptions: [
                {
                    value: 'Возврат оформлен по ошибке',
                    label: 'Возврат оформлен по ошибке',
                },
                {
                    value: 'Передумал возвращать товар',
                    label: 'Передумал возвращать товар',
                },
                {
                    value: 'Магазин не принял товары',
                    label: 'Магазин не принял товары',
                },
                {
                    value: 'custom',
                    label: 'Другая причина',
                },
            ],

            loading: false,
        };
    },

    computed: {
        $form() {
            return this.$refs.form;
        },
    },

    methods: {
        async submit() {
            const errors = await this.$form.validate();

            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.loading = true;
                const data = {
                    cancel_reason: this.formData.reason === 'custom'
                        ? this.formData.customReason
                        : this.formData.reason,
                };

                this.$api.orders.cancelReturnOrder(this.uuid, data)
                    .then(response => {
                        this.callback(response);
                        this.close();
                        this.$success('Заявка на возврат отменена');
                    })
                    .catch(error => {
                        this.$form.handle(error);
                        this.$store.commit('handleCommonHttpError', error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.reason-radio {
    margin-bottom: 20px;
}

.actions-wrap {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .actions-wrap {
        margin-top: auto;
    }

    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>