<template>
    <div
        v-if="hasSlots"
        class="seo-block"
    >
        <template v-if="showMarketItemNote">
            <p class="market-item-text">Информация о технических характеристиках, комплекте поставки, стране изготовления и внешнем виде товара носит справочный характер. Перед покупкой уточняйте характеристики и комплектацию у продавца.</p>
            <p class="market-item-text">Сроки и стоимость доставки из других городов и регионов приблизительные.</p>
            <p class="market-item-text">Товары в разделе «Другие товары» подобраны автоматически. Совместимость товаров уточняйте у продавца или производителя.</p>
        </template>
        <p
            v-if="hasSeoSlot"
            class="seo-text p-textarea"
        ><slot name="seo" /></p>
    </div>
</template>

<script>
export default {
    name: "SeoBlock",

    props: {
        showMarketItemNote: Boolean,
    },

    computed: {
        hasSlots() {
            return !!this.$slots.default || !!this.$slots.seo || this.showMarketItemNote;
        },

        hasSeoSlot() {
            return !!this.$slots.seo;
        },
    },
};
</script>

<style scoped>
.seo-block {
    margin-top: var(--gap-y-medium);
    line-height: var(--base-lh);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .seo-block {
        padding-top: var(--gap-y-small);
    }
}

@media (min-width: 768px) {
    .seo-block::before {
        content: "";
        display: block;
        width: 100vw;
        height: var(--gap-y-small);
        position: absolute;
        left: 0;
        margin-top: calc(var(--gap-y-small) * -1);
        border-top: 1px solid var(--border-light-c);
    }
}

.market-item-text {
    font-size: var(--adaptive-small-fz);
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .market-item-text + .market-item-text {
        margin-top: 12px;
    }
}
@media (max-width: 767px) {
    .market-item-text + .market-item-text {
        margin-top: 8px;
    }
}

.seo-text {
    font-size: 12px;
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .seo-text:not(:first-child) {
        margin-top: 36px;
    }
}
@media (max-width: 767px) {
    .seo-text:not(:first-child) {
        margin-top: 28px;
    }
}
</style>