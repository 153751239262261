export const timestampType = date => {
    const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
    const now = new Date();
    const yesterday = new Date(now.setDate(now.getDate() - 1));

    if (
        now.getFullYear() === date.getFullYear() &&
        now.getMonth() === date.getMonth() &&
        now.getDate() === date.getDate()
    ) {
        return 'today';
    }
    else if (
        yesterday.getFullYear() === date.getFullYear() &&
        yesterday.getMonth() === date.getMonth() &&
        yesterday.getDate() === date.getDate()
    ) {
        return 'yesterday';
    }
    else if (Math.floor(now.getTime() / ONE_WEEK) === Math.floor(date.getTime() / ONE_WEEK)) {
        return 'week';
    }
    else if (now.getFullYear() === date.getFullYear()) {
        return 'year';
    }
    else {
        return null;
    }
};

export default {
    timestampType,
};