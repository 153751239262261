import filter from './filter.js';
import sort from './sort.js';
import deliveryOffices from '@/store/modules/deliveryOffices.js';


const staticFilters = [
    {
        widget: 'radio',
        codename: 'in_stock',
        options: [
            {
                value: 'true',
                label: 'В наличии',
            },
            {
                value: 'false',
                label: 'Все товары, включая отсутствующие',
            },
        ],
    },
    {
        title: 'Цена, ₽',
        widget: 'range',
        codename: 'price',
        min: 0,
        max: 0,
    },
    {
        title: 'Производители',
        widget: 'checkboxes',
        codename: 'brand',
        options: [],
    },
];

export default {
    namespaced: true,

    modules: {
        filter,
        sort,
        deliveryOffices,
    },

    state: () => ({
        newParts: {
            items: [],
            current_count: 0,
            hasNext: false,
        },
        initialized: false,
        loading: true,
    }),

    mutations: {
        setNewParts(state, { results, current_count, next }) {
            state.newParts.items = results;
            state.newParts.current_count = current_count;
            state.newParts.hasNext = !!next;
        },

        addNewParts(state, { results, current_count, next }) {
            state.newParts.items = [...state.newParts.items, ...results];
            state.newParts.current_count = current_count;
            state.newParts.hasNext = !!next;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        setLoading(state, value) {
            state.loading = value;
        },

        destroy(state) {
            state.newParts = {
                items: [],
                current_count: 0,
                hasNext: false,
            };
            state.initialized = false;
        },

        changeFavorite(state, { index, value }) {
            state.newParts.items[index].part_product.is_favorite = value;
        },

        changeCart(state, { index }) {
            state.newParts.items[index].in_basket = true;
        },
    },

    actions: {
        async init({ commit, dispatch, rootState }, { query }) {
            try {
                commit('setLoading', true);

                const params = Object.assign(
                    {
                        shop: rootState.marketShop.shop.id,
                        get_facets: 'all',
                        limit: 36,
                        offset: 0,
                        in_stock: true,
                        order_by: 'price',
                    },
                    query,
                );

                const [newParts, delivery] = await Promise.all([
                    this.$api.partProductsOneOfferDoc.get({ params }),
                    dispatch('deliveryOffices/getItems'),
                ]);

                newParts.results.forEach(part => part.delivery = delivery);

                commit('setInitialized');
                commit('setLoading', false);
                commit('setNewParts', newParts);

                commit('filter/setChecked', []);
                commit('sort/setOptions', { type: 'offers' });
                commit('filter/setStaticFilters', {
                    config: staticFilters,
                    facets: newParts.facets,
                    defaultFacets: rootState.marketShop.newPartsDefaultFacets,
                });
                dispatch('filter/parseQuery', {
                    query,
                    filters: staticFilters,
                });
                commit('sort/parseQuery', query);
                commit('clearHttpError', null, { root: true });

                return { newParts };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getNewParts({ commit, dispatch }, { params, cancelToken }) {
            try {
                const [parts, delivery] = await Promise.all([
                    this.$api.partProductsOneOfferDoc.get({ params, cancelToken }),
                    dispatch('deliveryOffices/getItems'),
                ]);

                parts.results.forEach(part => part.delivery = delivery);

                return parts;
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};