export default {
    namespaced: true,

    state: () => {
        return {
            initialized: false,
            initializing: false,
            fetching: false,
            partCategories: [],
            meta: {},
        };
    },

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setInitializing(state, value) {
            state.initializing = value;
        },

        setFetching(state, value) {
            state.fetching = value;
        },

        setPartCategories(state, value) {
            state.partCategories = value;
        },

        setMeta(state, value) {
            state.meta = value;
        },

        destroy(state) {
            state.initialized = false;
            state.initializing = false;
            state.fetching = false;
            state.partCategories = [];
        },
    },

    actions: {
        async serverPrefetch({ commit }) {
            try {
                const meta = await this.$api.staticPages.get('market-parts-catalog');

                commit('setMeta', meta);
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async browserPrefetch({ commit, dispatch }, config) {
            try {
                commit('setInitializing', true);

                const [{ results }] = await Promise.all([
                    this.$api.partCategories.get(config),
                    dispatch('viewsHistory/get', null, { root: true }),
                ]);

                commit('setPartCategories', results);
                commit('setInitialized');
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
            finally {
                commit('setInitializing', false);
            }
        },

        async fetchCategories({ commit }, config) {
            try {
                commit('setFetching', true);

                const { results } = await this.$api.partCategories.get(config);

                commit('setPartCategories', results);
                commit('setInitialized');
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                commit('setFetching', false);
            }
        },
    },
};