import pad from '@ui/utils/pad.js';
import isValidDate from '@ui/utils/isValidDate.js';


export default () => {
    return {
        maxActionStartDate() {
            const diagnosticCardExp = this.contract.diagnostic_card_exp;
            const defaultMaxActionStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 40);

            if (diagnosticCardExp && /(\d{4})-(\d{2})-(\d{2})/g.test(diagnosticCardExp)) {
                const date = new Date(diagnosticCardExp);
                const isValid = isValidDate(date);

                if (isValid) {
                    const d = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);

                    if (defaultMaxActionStartDate > d) {
                        return d;
                    }
                }
            }

            return defaultMaxActionStartDate;
        },

        minActionStartDateIso() {
            const date = this.minActionStartDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },

        maxActionStartDateIso() {
            const date = this.maxActionStartDate;
            return `${ date.getFullYear() }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;
        },
    };
};