<template>
    <div class="top-gap_medium">
        <h1 class="h1 h1_block">
            <span>Результаты поиска по&nbsp;автотоварам</span><span
                v-if="products.current_count"
                class="h1-sub"
            >{{ products.current_count | number }}</span>
        </h1>

        <Spinner
            v-if="!initialized"
            center
            size="big"
        ></Spinner>

        <MarketItemsListWithOffers
            v-else
            apiName="products"
            modelName="product"
            :items="products.items"
            :amount="products.current_count"
            :totalAmount="productsTotalCount"
            :staticFilters="staticFilters"
            :dynamicFilters="dynamicFilters"
            :checkedFilters="checkedFilters"
            :filterData="filterData"
            :sortData="sortData"
            :sortOptions="sortOptions"
            :hasNext="hasNext"
            :loading="loading"
            reviewsInPopup
            @changeFilter="onChangeFilter"
            @resetFilters="onResetFilters"
            @deleteFilter="onDeleteFilter"
            @changeSort="onChangeSort"
            @reload="reload"
            @load="load"
            @changeFavorite="onChangeFavorite"
        >
            <template #inStockEmpty>
                <EmptyBlock
                    icon="empty-box"
                    title="Нет предложений"
                >
                    <template #text>
                        По запросу <b>{{ searchQuery.search }}</b> нет актуальных предложений, попробуйте изменить условия поиска.
                    </template>

                    <template #button>
                        <ButtonBlock
                            primary
                            low
                            class="empty__btn"
                            @click="callSetSearchFieldFocus"
                        >
                            Искать иначе
                        </ButtonBlock>
                        <ButtonBlock
                            secondary
                            low
                            class="empty__btn"
                            :to="{
                                name: 'market-products-catalog',
                            }"
                        >Перейти в каталог</ButtonBlock>
                    </template>
                </EmptyBlock>
            </template>
            <template #totalEmpty>
                <EmptyBlock
                    icon="empty"
                    title="Ничего не найдено"
                >
                    <template
                        v-if="searchQuery.search"
                        #text
                    >
                        По запросу <span class="text_bold">«{{ searchQuery.search }}»</span> нет товаров, попробуйте изменить условия поиска.
                    </template>

                    <template #button>
                        <ButtonBlock
                            primary
                            low
                            class="empty__btn"
                            @click="callSetSearchFieldFocus"
                        >
                            Искать иначе
                        </ButtonBlock>
                        <ButtonBlock
                            secondary
                            low
                            class="empty__btn"
                            :to="{
                                name: 'market-products-catalog',
                            }"
                        >Перейти в каталог</ButtonBlock>
                    </template>
                </EmptyBlock>
            </template>
        </MarketItemsListWithOffers>

        <LastSee></LastSee>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import marketProductsSearch from '@/store/modules/marketProductsSearch.js';
import bus from '@/bus.js';
import { HTTP } from '@/http.js';
import { animationDurationFast } from '@/plugins/animationSettings.js';
import equals from '@/lib/equals.js';
import MarketItemsListWithOffers from '@/components/MarketItemsListWithOffers.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import Spinner from '@/components/Spinner.vue';
import LastSee from '@/components/LastSee.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';

const getCategory = (arr, itemId) => (
    arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === itemId) return item;
        if (item.children) return getCategory(item.children, itemId);
    }, null)
);

export default {
    name: 'MarketProductSearchPage',

    metaInfo() {
        return {
            title: 'Результаты поиска по автотоварам',
        };
    },

    components: {
        ButtonBlock,
        LastSee,
        Spinner,
        EmptyBlock,
        MarketItemsListWithOffers,
    },

    serverPrefetch() {
        return this.INIT({
            query: this.$route.query,
            route: this.$route,
        });
    },

    data() {
        return {
            rangeData: {
                limit: 36,
                offset: 0,
            },
            source: null,
        };
    },

    computed: {
        ...mapState({
            products: state => state.marketProductsSearch.products,
            productsTotalCount: state => state.marketProductsSearch.productsTotalCount,
            initialized: state => state.marketProductsSearch.initialized,
            loading: state => state.marketProductsSearch.loading,
            staticFilters: state => state.marketProductsSearch.filter.staticFilters,
            dynamicFilters: state => state.marketProductsSearch.filter.dynamicFilters,
            checkedFilters: state => state.marketProductsSearch.filter.checked,
            productsDefaultFacets: state => state.marketProductsSearch.productsDefaultFacets,
            filterData: state => state.marketProductsSearch.filter.value,
            sortOptions: state => state.marketProductsSearch.sort.options,
            sortData: state => state.marketProductsSearch.sort.value,
            searchValue: state => state.searchWidget.value,
        }),

        ...mapGetters({
            filterQuery: 'marketProductsSearch/filter/query',
            sortQuery: 'marketProductsSearch/sort/query',
        }),

        searchQuery() {
            return this.searchValue ? { search: this.searchValue } : {};
        },

        hasNext() {
            return (this.rangeData.offset + this.rangeData.limit) < this.products.current_count;
        },

        location() {
            return {
                name: this.$route.name,
                query: Object.assign(
                    {
                        search: this.$route.query.search,
                    },
                    this.filterQuery,
                    this.sortQuery,
                    this.searchQuery,
                ),
                meta: {
                    savePosition: true,
                },
            };
        },

        fetchParams() {
            return Object.assign(
                {
                    search: this.$route.query.search,
                    get_facets: 'all',
                },
                this.filterQuery,
                this.sortQuery,
                this.rangeData,
                this.searchQuery,
            );
        },
    },

    watch: {
        '$route.query.search': {
            handler() {
                this.DESTROY();
                this.init();
            },
        },
    },

    created() {
        this.$store.registerModule('marketProductsSearch', marketProductsSearch);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('marketProductsSearch');
    },

    methods: {
        ...mapMutations({
            DELETE_FILTER: 'marketProductsSearch/filter/deleteFilter',
            RESET_FILTERS: 'marketProductsSearch/filter/reset',
            SET_SORT_VALUE: 'marketProductsSearch/sort/setValue',
            SET_PRODUCTS: 'marketProductsSearch/setProducts',
            ADD_PRODUCTS: 'marketProductsSearch/addProducts',
            CHANGE_FAVORITE: 'marketProductsSearch/changeFavorite',
            CHANGE_CART: 'marketProductsSearch/changeCart',
            SET_LOADING: 'marketProductsSearch/setLoading',
            DESTROY: 'marketProductsSearch/destroy',
        }),

        ...mapActions({
            INIT: 'marketProductsSearch/init',
            GET_PRODUCTS: 'marketProductsSearch/getProducts',
            CHANGE_FILTER: 'marketProductsSearch/filter/change',
            GET_DYNAMIC_FILTER: 'marketProductsSearch/filter/getDynamicFiltersInCategory',
        }),

        callSetSearchFieldFocus() {
            bus.$emit('setSearchFieldFocus');
        },

        async init() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'market',
                    },
                    title: 'Запчасти и автотовары',
                },
                {
                    to: {
                        name: 'market-products-catalog',
                    },
                    title: 'Каталог автотоваров',
                },
                {
                    to: {
                        name: 'market-products-search',
                    },
                    title: 'Результаты поиска',
                },
            ]);

            if (!this.initialized) {
                await this.INIT({
                    query: this.$route.query,
                    route: this.$route,
                });
            }

            this.replaceQuery();
        },

        async fetch() {
            this.SET_LOADING(true);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                params: this.fetchParams,
                cancelToken: this.source.token,
            };

            const response = await this.GET_PRODUCTS(payload);
            if (response) {
                this.source = null;
                await this.updateQuery();
                return response;
            }
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) {
                this.ADD_PRODUCTS(response);
                this.updateFiltersOptions(this.dynamicFilters, response.facets, response.current_count);
                this.SET_LOADING(false);
            }
        },

        async reload() {
            this.rangeData.offset = 0;
            const response = await this.fetch();
            if (response) {
                let categoryFilters = {
                    dynamicFilters: [],
                };

                if (this.$route.query.category) {
                    let category = getCategory(response.facets.categories, Number(this.$route.query.category));
                    if (category) {
                        categoryFilters = await this.GET_DYNAMIC_FILTER({
                            slug: category.slug,
                            routeName: this.$route.name,
                        });
                    }
                }

                this.updateFiltersOptions(categoryFilters.dynamicFilters, response.facets, response.current_count);
                this.$store.commit('marketProductsSearch/filter/updateValue', categoryFilters.dynamicFilters);

                // Двойной по веремени timeout для того, чтобы сначала точно завершилась анимация фильтров,
                // и только потом произошел ререндер списка
                setTimeout(() => {
                    this.SET_PRODUCTS(response);
                    this.SET_LOADING(false);
                }, animationDurationFast * 2);
            }
        },

        onChangeFilter(payload) {
            this.CHANGE_FILTER(payload);
        },

        onResetFilters() {
            this.RESET_FILTERS();
            this.reload();
        },

        onDeleteFilter(filter) {
            this.DELETE_FILTER(filter);
            this.reload();
        },

        updateFiltersOptions(dynamicFilters, facets, current_count) {
            this.$store.commit('marketProductsSearch/filter/setStaticFilters', {
                config: this.staticFilters,
                facets,
                defaultFacets: this.productsDefaultFacets,
            });
            this.$store.commit('marketProductsSearch/filter/setDynamicFilters', {
                config: dynamicFilters,
                facets,
                defaultFacets: this.productsDefaultFacets,
                current_count,
            });
        },

        isNewRoute() {
            const toPath = this.$router.resolve(this.location).route.fullPath;
            return this.$route.fullPath !== toPath;
        },

        async updateQuery() {
            if (this.isNewRoute()) {
                return await this.$router.push(this.location);
            }
            else {
                return '';
            }
        },

        async replaceQuery() {
            if (this.isNewRoute()) {
                return await this.$router.replace(this.location);
            }
            else {
                return '';
            }
        },

        onChangeFavorite(data) {
            this.CHANGE_FAVORITE(data);
        },

        onChangeSort(value) {
            this.SET_SORT_VALUE(value);
            this.reload();
        },
    },

    beforeRouteUpdate(to, from, next) {
        if (!equals(to.params, from.params)) {
            this.DESTROY();
            this.rangeData.offset = 0;
        }
        next();
    },
};
</script>