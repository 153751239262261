import reviews from '@/api/products/reviews.js';
import { paramsSerializer } from '@/lib/paramsSerializer.js';


export default ({ HTTP }) => ({
    reviews: reviews({ HTTP }),

    async get(...args) {
        let config;
        let id;
        if (args && args.length) {
            if (typeof args[0] === 'string' || typeof args[0] === 'number') {
                id = args[0];
                config = args[1];
            }
            else {
                config = args[0];
            }
        }

        if (!config) config = {};

        if (id) {
            const url = `/products/${ id }/`;
            const response = await HTTP.get(url, config);
            return response.data;
        }
        else {
            const url = '/products/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async similarProducts(id, config) {
        const response = await HTTP.get(`/products/${ id }/similar_products/`, {
            ...config,
            paramsSerializer,
        });
        return response.data;
    },
});