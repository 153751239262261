<template>
    <div
        ref="input"
        class="u-simple-select"
    >
        <button
            type="button"
            aria-haspopup="listbox"
            class="u-simple-select__button"
            @click="toggle"
        >
            <span class="u-simple-select__value">
                {{ getOptionLabel(value) }}
            </span>

            <UIcon
                name="arrow-down"
                small
                class="u-simple-select__icon"
                :class="{
                    'u-simple-select__icon_upend': opened,
                }"
            ></UIcon>
        </button>

        <TransitionExpandFade>
            <ul
                v-show="opened"
                role="listbox"
                class="u-simple-select__list"
            >
                <li
                    v-for="option in options"
                    :key="'option-' + getOptionKey(option)"
                    role="listitem"
                    class="u-simple-select__item"
                    :class="{
                        'u-simple-select__item_selected': optionComparator(value, option),
                    }"
                    @click="onItemClick"
                >
                    {{ getOptionLabel(option) }}
                </li>
            </ul>
        </TransitionExpandFade>
    </div>
</template>

<script>
import options from '@ui/mixins/options.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import TransitionExpandFade from '@/components/_transitions/TransitionExpandFade.vue';


export default {
    name: 'USimpleSelect',

    components: {
        TransitionExpandFade,
        UIcon,
    },

    mixins: [options],

    props: {
        options: Array,
        value: [Object, Number, String],
    },

    data() {
        return {
            innerValue: null,
            opened: false,
        };
    },

    watch: {
        value: {
            handler(value) {
                if (this.optionComparator(value, this.innerValue)) return;

                this.innerValue = value;
            },

            immediate: true,
        },

        innerValue() {
            if (this.optionComparator(this.value, this.innerValue)) return;

            this.emitInput();
        },
    },

    mounted() {
        this.addClickOutListener();
    },

    beforeDestroy() {
        this.removeClickOutListener();
    },

    methods: {
        // handlers

        onItemClick(option) {
            this.innerValue = option;
        },

        addClickOutListener() {
            addEventListener('click', this.onClickOut, true);
        },

        removeClickOutListener() {
            removeEventListener('click', this.onClickOut, true);
        },

        handleClickOut($event) {
            if (this.$refs.input.contains($event.target)) return;

            this.close();
        },

        // emits

        emitInput() {
            const value = this.innerValue;
            const target = this.$refs.input;
            this.$emit('input', { value, target });
            this.$emit('change:model', value);
        },

        // public

        open() {
            this.opened = true;
        },

        close() {
            this.opened = false;
        },

        toggle() {
            if (this.opened) {
                this.close();
            }
            else {
                this.open();
            }
        },
    },
};
</script>

<style>
.u-simple-select {
    position: relative;
}

.u-simple-select__button {
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
    outline: none;
    transition: border-color var(--transition), background-color var(--transition);
}
.u-simple-select__button:hover {
    border-color: var(--fields-border);
}

.u-simple-select__icon {
    margin-left: 8px;
    transition: transform var(--transition-fastest);
}
.u-simple-select__icon.u-simple-select__icon_upend {
    transform: rotate(180deg);
}

.u-simple-select__list {
    overflow: auto;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    max-height: 296px;
    padding: 4px;
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
    border-radius: var(--border-radius);
}

.u-simple-select__item {
    width: 100%;
    min-height: 36px;
    padding: 8px 12px;
    display: flex;
    background-color: var(--light-c);
    border-radius: var(--border-radius);
    transition: background-color var(--transition);
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    text-align: left;
    white-space: nowrap;
}
.u-simple-select__item:hover,
.u-simple-select__item:focus {
    background-color: var(--lightest-bg);
}
.u-simple-select__item:disabled,
.u-simple-select__item.u-simple-select__item_selected {
    pointer-events: none;
    cursor: default;
    color: var(--font-secondary-color);
    background-color: var(--light-c);
}
</style>