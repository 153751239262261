export default {
    namespaced: true,

    state: () => ({
        items: [],
        current_count: 0,
        initialized: false,
        loading: false,
    }),

    mutations: {
        setItems(state, { results, current_count }) {
            state.items = results;
            state.current_count = current_count;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.items = [];
            state.current_count = 0;
            state.initialized = false;
        },

        setLoading(state, value) {
            state.loading = value;
        },
    },

    actions: {
        async init({ commit }, { params = {} }) {
            commit('setLoading', true);

            try {
                const response = await this.$api.help.categories({
                    params: Object.assign({ order_by: 'name' }, params),
                });
                commit('setItems', response);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });
                return response;
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
            finally {
                commit('setLoading', false);
            }
        },
    },
};