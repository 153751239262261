import { mapGetters, mapState } from 'vuex';


export default () => {
    return {
        ...mapState({
            contract: state => state.insuranceOsago.contract,
        }),

        ...mapGetters({
            data: 'insuranceOsago/formData',
        }),

        filteredStoreData() {
            return this.data(this.keys);
        },

        uuid() {
            return this.$route.params.uuid;
        },

        company() {
            return this.$route.query.company;
        },
    };
};