import axios from 'axios';
import InputText from '@/components/_inputs/InputText.vue';
import InputNumber from '@/components/_inputs/InputNumber.vue';
import GeoInput from '@/components/_inputs/GeoInput.vue';
import Selector from '@/components/_inputs/Selector.vue';
import FileLoader from '@/components/_inputs/FileLoader.vue';
import InputRadioTabs from '@/components/_inputs/InputRadioTabs.vue';
import InputRadio from '@/components/_inputs/InputRadio.vue';
import UCheckbox from '@ui/components/UCheckbox/UCheckbox.vue';
import UCheckboxes from '@ui/components/UCheckbox/UCheckboxes.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import Stars from '@/components/_inputs/Stars.vue';
import InputAddress from '@/components/_inputs/InputAddress.vue';
import UInputDate from '@ui/components/UInput/presets/UInputDate.vue';


class Form {
    constructor() {
        this.options = {};
        this.defaults = {
            widgets: {
                string: InputText,
                text: InputText,
                date: InputText,
                time: InputText,
                datetime: InputText,
                year: InputText,
                phone: InputText,
                email: InputText,
                url: InputText,
                licensePlate: InputText,
                password: InputText,
                number: InputNumber,
                geo: GeoInput,
                geocode: GeoInput,
                select: Selector,
                loader: FileLoader,
                tabs: InputRadioTabs,
                radio: InputRadio,
                checkbox: UCheckbox,
                checkboxes: UCheckboxes,
                button: ButtonBlock,
                calendar: UInputDate,
                stars: Stars,
                address: InputAddress,
            },

            // загрузчик
            loader: {
                instanceAxios: axios,
                handleHttpError: error => {
                    console.error(error);
                },
                cropper: null,
            },

            address: {
                yandexApiKey: '',
                results: 5,
                delay: 300, // ms
                debounce: 300, // ms
                handleHttpError: error => {
                    console.error(error);
                },
            },

            geo: {
                yandexApiKey: '',
                results: 5,
                delay: 300, // ms
                debounce: 300, // ms
                handleHttpError: error => {
                    console.error(error);
                },
            },

            field: {
                handleInputErrors: {
                    loader(errors, field) {
                        field.updateErrors(errors);
                    },
                },

                handleHttpErrors: {
                    loader(errors, field) {
                        field.updateErrors(errors);
                    },
                },
            },

            // обработка ошибок
            handleHttpError: (errors, component) => {
                component.setErrors(errors);
            },
            handleFormValidateErrors: errors => errors,
            plugins: [],
        };
    }

    install(Vue, options = {}) {
        Vue.prototype.$formManager = this;

        this.options = this.defaults;

        if (options.plugins && Array.isArray(options.plugins) && options.plugins.length) {
            this.options.plugins = [...this.options.plugins, ...options.plugins];
        }

        this.options.plugins.forEach(plugin => (typeof plugin === 'function') ? plugin(this) : null);

        if (options.widgets) {
            this.options.widgets = Object.assign({}, this.options.widgets, options.widgets);
        }
    }
}

export default new Form();