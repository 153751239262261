export default ({ blob, url, name }) => {
    if (blob) {
        url = URL.createObjectURL(blob);
    }

    const el = document.createElement('a');
    el.href = url;
    el.target = '_blank';
    el.download = name;
    el.click();
    el.remove();
};