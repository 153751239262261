<template>
    <PopupWrap
        class="wrap"
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_block popup-title">
                Коэффициент безаварийной езды (КБМ, «бонус-малус»)
            </h2>

            <p class="description">
                Это система, которая считает понижающий и&nbsp;повышающий коэффициент к&nbsp;стоимости страховки. Коэффициент зависит от&nbsp;количества аварий, совершенных по&nbsp;вашей вине. Чем больше аварий, тем ниже класс безопасности и&nbsp;тем дороже обойдется новая страховка.
            </p>
            <p class="description">
                При заключении договора ОСАГО коэффициент определяется автоматически по&nbsp;запросу в&nbsp;информационную систему российского союза автостраховщиков (РСА).
            </p>

            <h3 class="h4 h4_text top-gap_small">
                Таблица коэффициентов
            </h3>

            <div class="table">
                <div class="table-header">
                    <div class="table-row">
                        <div class="table-cell mark">
                            <p class="table-header-title">
                                <span>Класс на начало годового срока страхования</span>
                            </p>
                        </div>
                        <div class="table-cell k">
                            <p class="table-header-title">
                                <span>Коэффициент</span> <br>
                                (КБМ)
                            </p>
                        </div>
                        <div class="table-cell crash-amounts">
                            <p class="table-header-title">
                                <span>Количество страховы выплат</span> <br>
                                (аварий, виновником которых были Вы)
                            </p>

                            <div class="table-row table-subrow">
                                <div class="table-cell crash-amount">
                                    <p class="table-header-subtitle">
                                        0
                                    </p>
                                </div>
                                <div class="table-cell crash-amount">
                                    <p class="table-header-subtitle">
                                        1
                                    </p>
                                </div>
                                <div class="table-cell crash-amount">
                                    <p class="table-header-subtitle">
                                        2
                                    </p>
                                </div>
                                <div class="table-cell crash-amount">
                                    <p class="table-header-subtitle">
                                        3
                                    </p>
                                </div>
                                <div class="table-cell crash-amount">
                                    <p class="table-header-subtitle">
                                        4 и более
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-body">
                    <div
                        v-for="(item, itemIndex) in items"
                        :key="'table-row-' + itemIndex"
                        class="table-row"
                        :class="{
                            'table-row_current': item.current,
                        }"
                    >
                        <div class="table-cell mark">
                            <p>
                                {{ item.mark }}
                            </p>
                        </div>
                        <div class="table-cell k">
                            <p>
                                {{ item.k | decimal }} <span
                                    v-if="item.current"
                                    class="your-k-label"
                                >Ваш КБМ</span>
                            </p>
                        </div>
                        <div class="table-cell crash-amount">
                            <p>
                                {{ item.crash_0_amount }}
                            </p>
                        </div>
                        <div class="table-cell crash-amount">
                            <p>
                                {{ item.crash_1_amount }}
                            </p>
                        </div>
                        <div class="table-cell crash-amount">
                            <p>
                                {{ item.crash_2_amount }}
                            </p>
                        </div>
                        <div class="table-cell crash-amount">
                            <p>
                                {{ item.crash_3_amount }}
                            </p>
                        </div>
                        <div class="table-cell crash-amount">
                            <p>
                                {{ item.crash_4_amount }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';


export default {
    name: "BonusMalusPopup",

    components: {
        PopupWrap,
    },

    mixins: [popup],

    props: {
        k: {
            type: [String, Number],
            default: '',
        },
    },

    data() {
        return {
            items: [
                {
                    mark: 'M',
                    k: 2.45,
                    crash_0_amount: 0,
                    crash_1_amount: 'M',
                    crash_2_amount: 'M',
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '0',
                    k: 2.3,
                    crash_0_amount: 1,
                    crash_1_amount: 'M',
                    crash_2_amount: 'M',
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '1',
                    k: 1.55,
                    crash_0_amount: 2,
                    crash_1_amount: 'M',
                    crash_2_amount: 'M',
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '2',
                    k: 1.4,
                    crash_0_amount: 3,
                    crash_1_amount: 1,
                    crash_2_amount: 'M',
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '3',
                    k: 1.0,
                    crash_0_amount: 4,
                    crash_1_amount: 1,
                    crash_2_amount: 'M',
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '4',
                    k: 0.95,
                    crash_0_amount: 5,
                    crash_1_amount: 2,
                    crash_2_amount: 1,
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '5',
                    k: 0.9,
                    crash_0_amount: 6,
                    crash_1_amount: 3,
                    crash_2_amount: 1,
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '6',
                    k: 0.85,
                    crash_0_amount: 7,
                    crash_1_amount: 4,
                    crash_2_amount: 2,
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '7',
                    k: 0.8,
                    crash_0_amount: 8,
                    crash_1_amount: 4,
                    crash_2_amount: 2,
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '8',
                    k: 0.75,
                    crash_0_amount: 9,
                    crash_1_amount: 5,
                    crash_2_amount: 2,
                    crash_3_amount: 'M',
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '9',
                    k: 0.7,
                    crash_0_amount: 10,
                    crash_1_amount: 5,
                    crash_2_amount: 2,
                    crash_3_amount: 1,
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '10',
                    k: 0.65,
                    crash_0_amount: 11,
                    crash_1_amount: 6,
                    crash_2_amount: 3,
                    crash_3_amount: 1,
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '11',
                    k: 0.6,
                    crash_0_amount: 12,
                    crash_1_amount: 6,
                    crash_2_amount: 3,
                    crash_3_amount: 1,
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '12',
                    k: 0.55,
                    crash_0_amount: 13,
                    crash_1_amount: 6,
                    crash_2_amount: 3,
                    crash_3_amount: 1,
                    crash_4_amount: 'M',
                    current: false,
                },
                {
                    mark: '13',
                    k: 0.5,
                    crash_0_amount: 13,
                    crash_1_amount: 7,
                    crash_2_amount: 3,
                    crash_3_amount: 1,
                    crash_4_amount: 'M',
                    current: false,
                },
            ],
        };
    },

    mounted() {
        for (let item of this.items) {
            if (item.mark === this.k) {
                item.current = true;
                break;
            }
        }
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.description {
    line-height: var(--base-lh);
}
@media (min-width: 768px) {
    .description + .description {
        margin-top: 12px;
    }
}
@media (max-width: 767px) {
    .description + .description {
        margin-top: 8px;
    }
}

.table {
    overflow: auto;
}

.table-header {
    width: 780px;
    color: var(--font-secondary-color);
}
@media (min-width: 1040px) {
    .table-header {
        margin-bottom: 16px;
    }
}
@media (max-width: 1039px) {
    .table-header {
        margin-bottom: 8px;
    }
}

.table-header-subtitle {
    font-size: 12px;
    line-height: var(--mini-lh);
}

.table-row {
    position: relative;
    display: flex;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
}

.table-row_current {
    font-family: var(--f-bold);
    border: 2px solid var(--blue-medium);
    border-radius: 5px;
}

.table-body {
    width: 780px;
}

.table-body .table-row:nth-of-type(2n + 1) {
    background-color: var(--bright-bg);
}

.table-subrow {
    margin-top: 4px;
}

.table-body .table-cell:first-child {
    margin-left: 18px;
    width: 180px;
}

.table-cell:not(:last-child) {
    margin-right: 18px;
}

.table-body .table-cell {
    margin-top: 16px;
    margin-bottom: 16px;
}

.table-cell {
    flex-shrink: 0;
}

.mark {
    width: 200px;
}

.k {
    width: 140px;
}

.crash-amounts {
    width: 400px;
}

.crash-amount {
    width: 64px;
}

.your-k-label {
    margin-left: 8px;
    padding: 6px 12px;
    font-size: 12px;
    font-family: var(--f-semi-bold);
    color: var(--light-c);
    background-color: var(--blue-medium);
    border-radius: var(--border-radius);
}
</style>