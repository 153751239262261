<template>
    <span
        v-if="count"
        class="u-badge"
    >{{ computedCount }}</span>
</template>

<script>
export default {
    name: 'UBadge',

    props: {
        count: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        computedCount() {
            return this.count > 99 ? '99+' : this.count;
        },
    },
};
</script>

<style>
.u-badge {
    display: inline-block;
    height: 20px;
    min-width: 20px;
    flex-shrink: 0;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 10px;
    font-size: var(--small-fz);
    line-height: 16px;
    font-family: var(--f-semi-bold);
    color: var(--light-c);
    white-space: nowrap;
    text-align: center;
    background-color: var(--primary-color);
    border: 2px solid var(--light-c);
}
</style>