<template>
    <div class="info-page-layout__main">
        <InfoPageLayoutHead></InfoPageLayoutHead>

        <transition-group
            tag="div"
            name="fade-page"
            mode="out-in"
            appear
            class="position-relative"
        >
            <Spinner
                v-if="!initialized"
                key="loading"
                size="big"
                class="content-loader"
            ></Spinner>

            <div
                v-else
                key="content"
            >
                <div
                    v-if="current_count"
                    class="grid"
                >
                    <GridItem
                        v-for="item in items"
                        :key="'news-item-' + item.id"
                        :item="item"
                    ></GridItem>
                </div>

                <ButtonBlock
                    v-if="hasNext && current_count"
                    class="load-more-btn"
                    :loading="loading"
                    @click="load"
                >
                    Показать ещё
                </ButtonBlock>

                <EmptyBlock
                    v-if="current_count === 0"
                    icon="document"
                    title="Нет новостей"
                    :hasBtn="false"
                ></EmptyBlock>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { mapActions, mapMutations, mapState } from 'vuex';
import EmptyBlock from '@/components/EmptyBlock.vue';
import InfoPageLayoutHead from '@/components/InfoPageLayoutHead.vue';
import GridItem from './GridItem.vue';
import Spinner from '@/components/Spinner.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import newsList from '@/store/modules/newsList.js';

export default {
    name: 'NewsListPage',

    components: {
        ButtonBlock,
        Spinner,
        GridItem,
        InfoPageLayoutHead,
        EmptyBlock,
    },

    serverPrefetch() {
        return this.INIT({ params: this.rangeData });
    },

    data() {
        return {
            rangeData: {
                limit: 30,
                offset: 0,
                order_by: '-publication_date',
            },
            source: null,
            loadingTimeout: null,
        };
    },

    computed: {
        ...mapState({
            items: state => state.newsList.items,
            current_count: state => state.newsList.current_count,
            loading: state => state.newsList.loading,
            initialized: state => state.newsList.initialized,
        }),

        hasNext() {
            return (this.rangeData.offset + this.rangeData.limit) < this.current_count;
        },
    },

    created() {
        this.$store.registerModule('newsList', newsList);
    },

    async mounted() {
        if (!this.initialized) {
            await this.INIT({ params: this.rangeData });
        }
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('newsList');
    },

    methods: {
        ...mapMutations({
            ADD_ITEMS: 'newsList/addItems',
            SET_LOADING: 'newsList/setLoading',
            DESTROY: 'newsList/destroy',
        }),

        ...mapActions({
            INIT: 'newsList/init',
            GET_ITEMS: 'newsList/getItems',
        }),

        async fetch() {
            this.loadingTimeout = setTimeout(() => {
                this.SET_LOADING(true);
            }, 300);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                params: this.rangeData,
                cancelToken: this.source.token,
            };

            const response = await this.GET_ITEMS(payload);
            if (response) {
                this.source = null;
                clearTimeout(this.loadingTimeout);
                this.SET_LOADING(false);
                return response;
            }
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) this.ADD_ITEMS(response);
        },
    },
};
</script>

<style scoped>
@media (min-width: 1400px) {
    .grid {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1399px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) {
    .grid {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

@media (min-width: 1040px) {
    .content-loader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (max-width: 1039px) {
    .content-loader {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>