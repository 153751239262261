<template>
    <div
        class="selection-block"
        :class="['selection-block_' + category]"
    >
        <div class="selection-block__head">
            <div class="selection-block__title-wrap">
                <ButtonText
                    class="h1 selection-block__title"
                    :secondary="category !== 'tire'"
                    :dashed="category !== 'tire'"
                    @click="category = 'tire'"
                >
                    Шины
                </ButtonText>

                <span class="selection-block__title-separator">/</span>

                <ButtonText
                    class="h1 selection-block__title"
                    :secondary="category !== 'rim'"
                    :dashed="category !== 'rim'"
                    @click="category = 'rim'"
                >
                    Диски
                </ButtonText>
            </div>

            <div class="hidden-m">
                <NavigationTabs
                    class="navigation__btns-wrap"
                    :currentIndex="mode === 'params' ? 0 : 1"
                    noScroll
                >
                    <button
                        class="navigation__btn"
                        :class="{
                            'active': mode === 'params'
                        }"
                        @click="mode = 'params'"
                    >
                        По параметрам
                    </button>
                    <button
                        class="navigation__btn"
                        :class="{
                            'active': mode === 'car'
                        }"
                        @click="mode = 'car'"
                    >
                        По авто
                    </button>
                </NavigationTabs>
            </div>

            <RadioButtonsGroup
                v-model="mode"
                name="mode"
                type="text"
                :options="[
                    {
                        label: 'По параметрам',
                        value: 'params',
                    },
                    {
                        label: 'По авто',
                        value: 'car',
                    },
                ]"
                class="hidden-l hidden-xl"
            ></RadioButtonsGroup>
        </div>

        <Spinner
            v-if="!initialized"
            center
        ></Spinner>
        <div
            v-if="initialized"
            class="selection-block__body"
        >
            <form
                v-show="mode === 'car'"
                class="car-selection-form"
            >
                <Selector
                    :value="carData.manufacturer.value"
                    label="Марка"
                    :options="carData.manufacturer.options"
                    optionLabel="name"
                    filterable
                    :emptyValue="{}"
                    :loading="carData.manufacturer.loading"
                    @change="onChangeCar({ value: $event, codename: 'manufacturer' })"
                ></Selector>

                <Selector
                    :value="carData.model.value"
                    label="Модель"
                    :options="carData.model.options"
                    optionLabel="name"
                    filterable
                    :emptyValue="{}"
                    :disabled="!carData.manufacturer.value"
                    :loading="carData.model.loading"
                    @change="onChangeCar({ value: $event, codename: 'model' })"
                ></Selector>

                <Selector
                    :value="carData.year.value"
                    label="Год выпуска"
                    :options="carData.year.options"
                    optionLabel="name"
                    filterable
                    :emptyValue="{}"
                    :disabled="!carData.manufacturer.value || !carData.model.value"
                    :loading="carData.year.loading"
                    @change="onChangeCar({ value: $event, codename: 'year' })"
                ></Selector>

                <Selector
                    :value="carData.modification.value"
                    label="Комплектация"
                    :options="carData.modification.options"
                    optionLabel="name"
                    filterable
                    :emptyValue="{}"
                    :disabled="!carData.manufacturer.value || !carData.model.value || !carData.year.value"
                    :loading="carData.modification.loading"
                    @change="onChangeCar({ value: $event, codename: 'modification' })"
                ></Selector>
            </form>
            <form
                v-show="category === 'tire' && mode === 'params'"
                class="tire-selection-form"
            >
                <Selector
                    id="tire-selection-width"
                    v-model="paramsData.tire_width"
                    label="Ширина, мм"
                    :options="facets.tire.tire_width"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                    class="tire-selection__input"
                ></Selector>

                <Selector
                    id="tire-selection-height"
                    v-model="paramsData.tire_height"
                    label="Высота, %"
                    :options="facets.tire.tire_height"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                    class="tire-selection__input"
                ></Selector>

                <Selector
                    id="tire-selection-diameter"
                    v-model="paramsData.tire_diameter"
                    label="Диаметр, ″"
                    :options="facets.tire.tire_diameter"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                    class="tire-selection__input"
                ></Selector>
            </form>
            <form
                v-show="category === 'rim' && mode === 'params'"
                class="rim-selection-form"
            >
                <Selector
                    id="rim-selection-width"
                    v-model="paramsData.rim_width"
                    label="Ширина, мм"
                    :options="facets.rim.rim_width"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                ></Selector>

                <Selector
                    id="rim-selection-diameter"
                    v-model="paramsData.rim_diameter"
                    label="Диаметр, ″"
                    :options="facets.rim.rim_diameter"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                ></Selector>

                <Selector
                    id="rim-selection-et"
                    v-model="paramsData.rim_et"
                    label="Вылет, мм"
                    :options="facets.rim.rim_et"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                    class="rim-selection__input"
                ></Selector>

                <Selector
                    id="rim-selection-pcd"
                    v-model="paramsData.rim_pcd"
                    label="Сверловка (PCD)"
                    :options="facets.rim.rim_pcd"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                    class="rim-selection__input"
                ></Selector>

                <Selector
                    id="rim-selection-dia"
                    v-model="paramsData.rim_dia"
                    label="Ступица (DIA)"
                    :options="facets.rim.rim_dia"
                    optionLabel="name"
                    optionKey="id"
                    :emptyValue="{}"
                    filterable
                    class="rim-selection__input"
                ></Selector>
            </form>
        </div>

        <ButtonBlock
            :to="to"
            primary
            class="selection-block__btn"
        >
            Показать предложения
        </ButtonBlock>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import Selector from '@/components/_inputs/Selector.vue';
import RadioButtonsGroup from '@/components/RadioButtonsGroup.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import Spinner from '@/components/Spinner.vue';
import NavigationTabs from '@/components/NavigationTabs.vue';

export default {
    name: 'SelectionInMarketIndex',

    components: {
        NavigationTabs,
        Spinner,
        ButtonText,
        RadioButtonsGroup,
        ButtonBlock,
        Selector,
    },

    data() {
        return {
            initialized: false,
            category: 'tire',
            mode: 'params',

            carData: {
                manufacturer: {
                    value: null,
                    options: [],
                    loading: false,
                },

                model: {
                    value: null,
                    options: [],
                    loading: false,
                },

                year: {
                    value: null,
                    options: [],
                    loading: false,
                },

                modification: {
                    value: null,
                    options: [],
                    loading: false,
                },
            },

            paramsData: {
                tire_width: null,
                tire_height: null,
                tire_diameter: null,
                tire_season: null,
                tire_spikes: null,
                rim_width: null,
                rim_diameter: null,
                rim_et: null,
                rim_pcd: null,
                rim_dia: null,
            },
            facets: {
                tire: {},
                rim: {},
            },
        };
    },

    computed: {
        ...mapState({
            currentCityId: state => state.cities.currentCityId,
            tireSelectionCategory: state => state.settings.tire_selection_category,
            rimSelectionCategory: state => state.settings.rim_selection_category,
        }),

        carQuery() {
            const query = {};
            if (this.carData.manufacturer.value) query.car_manufacturer = this.carData.manufacturer.value.slug;
            if (this.carData.model.value) query.car_model = this.carData.model.value.slug;
            if (this.carData.year.value) query.car_year = this.carData.year.value.slug.toString();
            if (this.carData.modification.value) query.car_modification = this.carData.modification.value.slug;
            return query;
        },

        paramsQuery() {
            return Object.entries(this.paramsData).reduce((acc, cur) => {
                if (cur[1]) {
                    acc[cur[0]] = cur[1].id;
                }
                return acc;
            }, {});
        },

        to() {
            return {
                name: 'market-products-category',
                params: {
                    slug: this.category === 'tire' ? this.tireSelectionCategory.slug : this.rimSelectionCategory.slug,
                },
                query: {
                    ...this.paramsQuery,
                    ...this.carQuery,
                },
            };
        },
    },

    mounted() {
        this.getManufacturers();
        Promise.all([
            this.$api.esProducts.get({
                params: {
                    category: this.tireSelectionCategory.id,
                    get_facets: 'characteristics',
                    limit: 0,
                    offset: 0,
                    city: this.currentCityId,
                },
            }),
            this.$api.esProducts.get({
                params: {
                    category: this.rimSelectionCategory.id,
                    get_facets: 'characteristics',
                    limit: 0,
                    offset: 0,
                    city: this.currentCityId,
                },
            }),
        ]).then(responses => {
            responses[0].facets.characteristics.forEach(item => {
                this.$set(this.facets.tire, item.codename, item.options);
            });
            responses[1].facets.characteristics.forEach(item => {
                this.$set(this.facets.rim, item.codename, item.options);
            });
            this.initialized = true;
        });
    },

    methods: {
        async onChangeCar({ value, codename }) {
            if (codename === 'manufacturer') await this.handleCarManufacturerSelect(value);
            if (codename === 'model') await this.handleCarModelSelect(value);
            if (codename === 'year') await this.handleCarYearSelect(value);
            if (codename === 'modification') await this.handleCarModificationSelect(value);
        },

        async getManufacturers() {
            this.carData.manufacturer.loading = true;

            const params = {
                order_by: 'name',
            };

            const { results } = await this.$api.wheelsize.getManufacturers({ params });
            if (results) this.carData.manufacturer.options = results;

            this.carData.manufacturer.loading = false;
        },

        async handleCarManufacturerSelect(option) {
            this.carData.manufacturer.value = null;
            this.carData.model.value = null;
            this.carData.model.options = [];
            this.carData.model.loading = false;
            this.carData.year.value = null;
            this.carData.year.options = [];
            this.carData.year.loading = false;
            this.carData.modification.value = null;
            this.carData.modification.options = [];
            this.carData.modification.loading = false;

            if (option && option.slug) {
                this.carData.manufacturer.value = option;
                this.carData.model.loading = true;

                const params = {
                    order_by: 'name',
                    manufacturer: option.slug,
                };

                const { results } = await this.$api.wheelsize.getModels({ params });
                if (results) this.carData.model.options = results;

                this.carData.model.loading = false;
            }
        },

        async handleCarModelSelect(option) {
            this.carData.model.value = null;
            this.carData.year.value = null;
            this.carData.year.options = [];
            this.carData.modification.value = null;
            this.carData.modification.options = [];

            if (option && option.slug) {
                this.carData.model.value = option;
                this.carData.year.loading = true;

                const params = {
                    order_by: '-year',
                    manufacturer: this.carData.manufacturer.value.slug,
                    model: option.slug,
                };

                const { results } = await this.$api.wheelsize.getYears({ params });
                if (results) {
                    this.carData.year.options = results.map(item => {
                        return { 'name': item.year, 'slug': item.year };
                    });
                }

                this.carData.year.loading = false;
            }
        },

        async handleCarYearSelect(option) {
            this.carData.year.value = null;
            this.carData.modification.value = null;
            this.carData.modification.options = [];

            if (option && option.slug) {
                this.carData.year.value = option;
                this.carData.modification.loading = true;

                const params = {
                    order_by: 'name,engine__power,generation__start',
                    manufacturer: this.carData.manufacturer.value.slug,
                    model: this.carData.model.value.slug,
                    year: option.slug,
                };

                const { results } = await this.$api.wheelsize.getModifications({ params });
                if (results) {
                    const resultsMap = {};
                    results.forEach((item, index) => {
                        if (resultsMap[item.name]) resultsMap[item.name].push(index);
                        else resultsMap[item.name] = [index];
                    });

                    this.carData.modification.options = results.map(item => {
                        let name = item.name;
                        if (item.engine.power) name = name + ', ' + item.engine.power + ' л.с.';
                        if (item.generation.start) {
                            name = name + ', ' + item.generation.start;
                            name = name + (item.generation.end ? ' – ' + item.generation.end : 'н.в.');
                        }
                        item.name = name;
                        return item;
                    });
                }

                this.carData.modification.loading = false;
            }
        },

        async handleCarModificationSelect(option) {
            this.carData.modification.value = null;
            if (option && option.slug) {
                this.carData.modification.value = option;
            }
        },
    },
};
</script>

<style scoped>
.selection-block {
    position: relative;
    display: flex;
    flex-direction: column;
}
.selection-block::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 36px;
    z-index: 0;
    width: 150px;
    height: 92px;
    background-size: 150px 150px;
    background-repeat: no-repeat;
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .selection-block::after {
        content: none;
    }
}
.selection-block_tire::after {
    background-image: url(../../assets/images/market/tires.jpg);
}
.selection-block_rim::after {
    background-image: url(../../assets/images/market/rims.jpg);
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .selection-block_tire::after {
        background-image: url(../../assets/images/market/tires-x2.jpg);
    }
    .selection-block_rim::after {
        background-image: url(../../assets/images/market/rims-x2.jpg);
    }
}

.selection-block__head {
    margin-bottom: 24px;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .selection-block__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.selection-block__title-wrap {
    display: flex;
    align-items: center;
}
@media (min-width: 1040px) {
    .selection-block__title-wrap {
        margin-bottom: 24px;
    }
}

.selection-block__title {
    line-height: var(--mini-lh);
}

.selection-block__title-separator {
    margin-left: 12px;
    margin-right: 12px;
    font-size: 24px;
    font-family: var(--f-extra-bold);
}

.selection-block__body {
    margin-bottom: 36px;
}

.selection-block__btn {
    margin-top: auto;
    align-self: flex-start;
}

@media (min-width: 1040px) {
    .navigation__btns-wrap {
        position: relative;
    }

    .navigation__btns-wrap::after {
        position: absolute;
        bottom: 0;
        left: -36px;
        width: calc(100% + 72px);
        border-bottom: 1px solid var(--border-light-c);
        content: "";
    }
}

.navigation__btn {
    position: relative;
    margin-right: 36px;
    padding-bottom: 12px;
    color: var(--font-secondary-color);
    font-size: var(--big-fz);
    font-family: var(--f-bold);
    transition: color var(--transition);
}

.navigation__btn.active,
.navigation__btn:hover,
.navigation__btn:focus {
    color: var(--dark-c);
}

.tire-selection-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--grid-gap);
}

@media (min-width: 1281px) {
    .car-selection-form {
        display: flex;
        flex-wrap: wrap;
        grid-gap: var(--grid-gap);
    }

    .car-selection-form > :nth-child(1) {
        width: calc(50% - 10px);
    }
    .car-selection-form > :nth-child(2) {
        width: calc(50% - 10px);
    }
    .car-selection-form > :nth-child(3) {
        width: calc(100% / 3 - 40px / 3);
    }
    .car-selection-form > :nth-child(4) {
        width: calc(100% / 3 * 2 - 40px / 3);
    }
}
@media (min-width: 1160px) and (max-width: 1280px) {
    .car-selection-form {
        grid-template-columns: repeat(12, 1fr);
    }

    .car-selection-form > :nth-child(1) {
        grid-column: 1/7;
    }
    .car-selection-form > :nth-child(2) {
        grid-column: 7/13;
    }
    .car-selection-form > :nth-child(3) {
        grid-column: 1/5;
    }
    .car-selection-form > :nth-child(4) {
        grid-column: 5/13;
    }
}
@media (min-width: 768px) and (max-width: 1159px) {
    .car-selection-form {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1280px) {
    .car-selection-form {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

.rim-selection-form {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
}
.rim-selection-form > :nth-child(1) {
    grid-column: 1/7;
}
.rim-selection-form > :nth-child(2) {
    grid-column: 7/13;
}
.rim-selection-form > :nth-child(3) {
    grid-column: 1/5;
}
.rim-selection-form > :nth-child(4) {
    grid-column: 5/9;
}
.rim-selection-form > :nth-child(5) {
    grid-column: 9/13;
}

@media (min-width: 1040px) and (max-width: 1280px) {
    .rim-selection-form > :nth-child(3) {
        grid-column: 1/7;
    }
    .rim-selection-form > :nth-child(4) {
        grid-column: 7/13;
    }
    .rim-selection-form > :nth-child(5) {
        grid-column: 1/13;
    }
}
</style>