<template>
    <div class="info-page-layout__main">
        <div v-if="initialized" class="news-item-page">
            <article class="article">
                <header
                    v-lazy-container="{ selector: 'img' }"
                    class="head"
                >
                    <img
                        :data-src="src"
                        alt=""
                        class="cover"
                    >

                    <div class="head-info">
                        <time class="date">{{ news.publication_date | dt }}</time>
                        <h1 class="h1">
                            {{ news.title }}
                        </h1>
                    </div>
                </header>

                <div class="content">
                    <PostEditorView :content="content"></PostEditorView>
                </div>

                <footer class="footer">
                    <ShareBlock></ShareBlock>
                </footer>
            </article>

            <Portal to="infoPageLayoutAfter">
                <ReadAlso
                    v-if="seeAlso.current_count"
                    :items="seeAlso.items"
                ></ReadAlso>
            </Portal>
        </div>

        <USpinner
            v-else
            big
            center
        ></USpinner>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import newsItem from '@/store/modules/newsItem.js';
import PostEditorView from '@/components/views/PostEditorView.vue';
import ShareBlock from '@/components/ShareBlock.vue';
import ReadAlso from './ReadAlso.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';

export default {
    name: "NewsItemPage",

    metaInfo() {
        return {
            title: this.news.meta_title,
            meta: [
                { name: 'description', content: this.news.meta_description },
                { name: 'keywords', content: this.news.meta_keywords },
                { property: 'og:title', content: this.news.meta_title ? this.news.meta_title : this.news.title },
                { property: 'og:description', content: this.news.meta_description },
                { property: 'og:image', content: this.news.og_image ? this.$links.uploads + this.news.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        USpinner,
        ReadAlso,
        PostEditorView,
        ShareBlock,
    },

    serverPrefetch() {
        return this.INIT({ codename: this.$route.params.codename });
    },

    computed: {
        ...mapState({
            initialized: state => state.newsItem.initialized,
            news: state => state.newsItem.item,
            seeAlso: state => state.newsItem.seeAlso,
        }),

        ...mapGetters({
            breadcrumbs: 'newsItem/breadcrumbs',
        }),

        content() {
            return this.news.content || [];
        },

        src() {
            return ((this.news.cover || {}).thumbnails || {}).news_cover_detail
                ? this.$links.uploads + ((this.news.cover || {}).thumbnails || {}).news_cover_detail
                : null;
        },
    },

    watch: {
        '$route.params.codename': {
            handler() {
                this.DESTROY();
                this.init();
            },
        },
    },

    created() {
        if (!this.$store.hasModule('newsItem')) {
            this.$store.registerModule('newsItem', newsItem);
        }
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
    },

    methods: {
        ...mapMutations({
            DESTROY: 'newsItem/destroy',
        }),

        ...mapActions({
            INIT: 'newsItem/init',
        }),

        async init() {
            if (!this.initialized) await this.INIT({ codename: this.$route.params.codename });

            this.$breadcrumbs([
                ...this.breadcrumbs,
            ]);
        },
    },
};
</script>

<style scoped>
.news-item-page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.article {
    background-color: var(--light-c);
    overflow: hidden;
}
@media (min-width: 1281px) {
    .article {
        width: var(--col-12);
    }
}
@media (min-width: 768px) {
    .article {
        box-shadow: var(--light-shadow);
        border-radius: var(--border-radius-x2);
    }
}
@media (max-width: 767px) {
    .article {
        width: 100vw;
        margin-left: var(--content-gap-negative);
        margin-right: var(--content-gap-negative);
    }
}

.head {
    position: relative;
}

.head:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    color: var(--light-c);
    background-color: rgba(23, 32, 38, 0.5);
}

.head-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index: 3;
    height: 320px;
    width: 100%;
    color: var(--light-c);
}
@media (min-width: 768px) {
    .head-info {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .head-info {
        padding: 20px var(--content-gap);
    }
}

.cover {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    height: 320px;
    min-width: 100%;
    max-width: initial;
}

@media (min-width: 768px) {
    .date {
        margin-bottom: 12px;
    }
}
@media (max-width: 767px) {
    .date {
        margin-bottom: 8px;
    }
}

@media (min-width: 768px) {
    .content,
    .footer {
        margin: 36px;
    }
}
@media (max-width: 767px) {
    .content,
    .footer {
        margin: 20px var(--content-gap);
    }
}
</style>