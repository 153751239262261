<template>
    <div class="chat-message-avatar">
        <img
            v-if="src"
            :src="src"
            :alt="name"
            class="chat-message-avatar__image"
        >

        <div v-else class="chat-message-avatar__placeholder">
            {{ name[0] }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatMessageAvatar',

    props: {
        src: String,
        name: String,
    },
};
</script>

<style>
.chat-message-avatar__placeholder,
.chat-message-avatar__image {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.chat-message-avatar__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
    background-color: var(--img-bg);
    color: var(--light-c);
}
</style>