<template>
    <div class="u-field-email">
        <UCollection
            :invalid="invalid"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template
                v-for="slotName in Object.keys($scopedSlots)"
                v-slot:[slotName]="payload"
            >
                <slot :name="slotName" v-bind="payload"></slot>
            </template>
        </UCollection>

        <UFormFieldError
            :show="invalid"
            class="u-field-collection__error"
        >
            {{ error }}
        </UFormFieldError>
    </div>
</template>

<script>
import fieldMixin from '@ui/components/UForm/fields/fieldMixin.js';
import UCollection from '@ui/components/UCollection/UCollection.vue';
import UFormFieldError from '@ui/components/UForm/UFormFieldError.vue';


export default {
    name: 'UFieldCollection',

    components: {
        UFormFieldError,
        UCollection,
    },

    mixins: [
        fieldMixin,
    ],

    data() {
        return {
            initialValue: [],
        };
    },
};
</script>

<style>
.u-field-collection__error .u-form-field-error {
    margin-top: 8px;
}
</style>