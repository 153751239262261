<template>
    <div
        role="list"
        class="u-key-value-dotted-list"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'UKeyValueDottedList',
};
</script>

<style>
.u-key-value-dotted-list .u-key-value-dotted-item:not(:last-child) {
    margin-bottom: 12px;
}

.u-key-value-dotted-list + .u-key-value-dotted-list,
.u-key-value-dotted-list + .u-key-value-list {
    margin-top: 20px;
}
</style>