export default ({ HTTP }) => ({
    async login(data) {
        const response = await HTTP.post('/auth/login/', data);
        return response.data;
    },

    async logout() {
        const response = await HTTP.post('/auth/logout/');
        return response.data;
    },

    async registration(data) {
        const response = await HTTP.post('/auth/registration/', data);
        return response.data;
    },

    async registrationConfirm(data) {
        const response = await HTTP.post('/auth/registration_confirm/', data);
        return response.data;
    },

    async passwordRecovery(data) {
        const response = await HTTP.post('/auth/password_recovery/', data);
        return response.data;
    },

    async passwordRecoveryConfirm(data) {
        const response = await HTTP.post('/auth/password_recovery_confirm/', data);
        return response.data;
    },

    async passwordRecoveryComplete(data) {
        const response = await HTTP.post('/auth/password_recovery_complete/', data);
        return response.data;
    },

    async sendLoginCode(data) {
        const response = await HTTP.post('/auth/send_login_code/', data);
        return response.data;
    },

    async confirmLoginCode(data) {
        const response = await HTTP.post('/auth/confirm_login_code/', data);
        return response.data;
    },
});
