<template>
    <span :class="['car-manufacturer-logo-wrap', 'car-manufacturer-logo-wrap_' + size ]">
        <img
            :src="logoSrc"
            alt=""
            class="car-manufacturer-logo"
        >
    </span>
</template>

<script>
export default {
    name: "CarManufacturerLogo",

    props: {
        manufacturer: {
            type: Object,
            default() {
                return {};
            },
        },

        size: {
            type: String,
            validator(value) {
                return ['micro', 'small', 'big'].includes(value);
            },
            default: 'small',
        },

        mono: Boolean,
        color: Boolean,
    },

    computed: {
        logoSrc() {
            if (this.mono && this.manufacturer.mono_logo) {
                return this.size === 'small'
                    ? this.$links.uploads + this.manufacturer.mono_logo.thumbnails.manufacturer_mono_logo_small
                    : this.$links.uploads + this.manufacturer.mono_logo.thumbnails.manufacturer_mono_logo_detail;
            }
            else if (this.color && this.manufacturer.logo) {
                return this.$links.uploads + (this.manufacturer.logo.thumbnails['manufacturer_logo_' + this.size ] || this.manufacturer.logo.thumbnails.manufacturer_logo_medium);
            }
            else if (this.manufacturer.codename) {
                return require(`../assets/images/manufactures/mono/${ this.manufacturer.codename }.svg`);
            }
            else {
                return '';
            }
        },
    },
};
</script>

<style scoped>
.car-manufacturer-logo-wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 0;
}

.car-manufacturer-logo-wrap_big {
    width: 54px;
    height: 36px;
}

.car-manufacturer-logo-wrap_small {
    width: 30px;
    height: 20px;
}

.car-manufacturer-logo-wrap_micro {
    width: 24px;
    height: 16px;
}

.car-manufacturer-logo {
    max-height: 100%;
}
</style>