var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"cloud",staticClass:"u-tooltip-cloud",class:{
        'u-tooltip-cloud_black': _vm.color === 'black',
        'u-tooltip-cloud_white': _vm.color === 'white',
        'u-tooltip-cloud_top': _vm.isTop,
        'u-tooltip-cloud_right': _vm.isRight,
        'u-tooltip-cloud_bottom': _vm.isBottom,
        'u-tooltip-cloud_left': _vm.isLeft,
        'u-tooltip-cloud_start': _vm.isStart,
        'u-tooltip-cloud_end': _vm.isEnd,
    }},[_c('div',{staticClass:"u-tooltip-cloud__arrow-wrap",class:{
            'u-tooltip-cloud__arrow-wrap_top': _vm.isTop,
            'u-tooltip-cloud__arrow-wrap_right': _vm.isRight,
            'u-tooltip-cloud__arrow-wrap_bottom': _vm.isBottom,
            'u-tooltip-cloud__arrow-wrap_left': _vm.isLeft,
            'u-tooltip-cloud__arrow-wrap_start': _vm.isStart,
            'u-tooltip-cloud__arrow-wrap_end': _vm.isEnd,
        },style:(_vm.arrowWrapStyle)},[_c('div',{staticClass:"u-tooltip-cloud__arrow",class:{
                'u-tooltip-cloud__arrow_top': _vm.isBottom,
                'u-tooltip-cloud__arrow_right': _vm.isLeft,
                'u-tooltip-cloud__arrow_bottom': _vm.isTop,
                'u-tooltip-cloud__arrow_left': _vm.isRight,
            }})]),_vm._v(" "),_c('div',{staticClass:"u-tooltip-cloud__content"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }