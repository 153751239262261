<template>
    <FilterWidgetWrap
        :codename="config.codename"
        :title="config.title"
        :hint="config.hint"
        :hasBorder="hasBorder"
    >
        <div
            class="filter-range-price"
            :class="{
                'filter-range-price_disabled': config.max === null || config.max === 0
            }"
        >
            <InputNumber
                :value="value[0]"
                :min="config.min || 0"
                :placeholder="minPlaceholder"
                :disabled="config.max === null || config.max === 0"
                small
                class="input-range__min"
                @change="onChangeMin"
                @enter="onChangeMin"
            ></InputNumber>

            <InputNumber
                :value="value[1]"
                :max="config.max || 0"
                :placeholder="maxPlaceholder"
                :disabled="config.max === null || config.max === 0"
                small
                class="input-range__max"
                @change="onChangeMax"
                @enter="onChangeMax"
            ></InputNumber>
        </div>
    </FilterWidgetWrap>
</template>

<script>
import FilterWidgetWrap from './FilterWidgetWrap.vue';
import InputNumber from '@/components/_inputs/InputNumber.vue';

export default {
    name: "FilterRangePrice",

    components: {
        InputNumber,
        FilterWidgetWrap,
    },

    props: {
        config: {
            type: Object,
            default() {
                return {
                    codename: '',
                    min: null,
                    max: null,
                    title: '',
                };
            },
        },

        value: {
            type: Array,
            validator(value) {
                return value.length === 2;
            },
            required: true,
        },
    },

    computed: {
        minPlaceholder() {
            return 'от ' + (this.config.min ? this.$options.filters.decimal(this.config.min).toString() : '');
        },

        maxPlaceholder() {
            return 'до ' + (this.config.max ? this.$options.filters.decimal(this.config.max).toString() : '');
        },

        hasBorder() {
            return !!this.value[0] || !!this.value[1];
        },
    },

    methods: {
        onChangeMin(value) {
            this.$emit('change', [value, this.value[1]]);
            this.$emit('reload');
        },

        onChangeMax(value) {
            this.$emit('change', [this.value[0], value]);
            this.$emit('reload');
        },
    },
};
</script>

<style scoped>
.filter-range-price {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filter-range-price::after {
    content: "";
    width: 1px;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    background-color: var(--border-dark-c);
}

.filter-range-price_disabled::after {
    background-color: var(--border-light-c);
    z-index: 2;
}

.filter-range-price_disabled .input-range__min,
.filter-range-price_disabled .input-range__max {
    background-color: var(--bright-bg);
}

.input-range__min {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-range__max {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
}

.input-range__min:not(:hover):not(:focus):not(.input-text_hover):not(.input-text_focused) {
    border-right-color: transparent;
}
.input-range__max:not(:hover):not(:focus):not(.input-text_hover):not(.input-text_focused) {
    border-left-color: transparent;
}

.input-range__min,
.input-range__max {
    position: relative;
    z-index: 2;
    width: 131px;
    flex-grow: 1;
    background-color: transparent;
}
</style>