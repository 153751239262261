<template>
    <Portal to="sticky-holder" :order="2">
        <div
            ref="MarketItemStickyHeader"
            class="header sticky-sections-holder__section"
            :class="{
                'sticky-sections-holder__section_visible': show
            }"
        >
            <div class="header-container container">
                <div class="header-container__cover-wrap">
                    <MarketItemCover
                        :item="item"
                        :modelName="modelName"
                        coverCodename="micro"
                        noLink
                    ></MarketItemCover>
                </div>

                <p class="title">
                    {{ item.page_title }}
                </p>

                <MarketItemNavigation
                    :items="nav"
                    noDesktopScroll
                    noUnderline
                    class="sticky-header__navigation"
                ></MarketItemNavigation>
            </div>
        </div>
    </Portal>
</template>

<script>
import MarketItemNavigation from './MarketItemNavigation.vue';
import MarketItemCover from "@/components/MarketItemCover.vue";

export default {
    name: "MarketItemStickyHeader",

    components: {
        MarketItemCover,
        MarketItemNavigation,
    },

    props: {
        item: {
            type: Object,
            default: () => {},
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },

        nav: {
            type: Array,
            default: () => [],
        },

        show: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        show: {
            handler(value) {
                const previousSibling = this.$refs.MarketItemStickyHeader.previousElementSibling;
                if (previousSibling) {
                    if (value) {
                        document.documentElement.style.setProperty('--market-item-sticky-header-top', previousSibling.offsetTop + previousSibling.offsetHeight + 'px');
                    }
                    else {
                        document.documentElement.style.setProperty('--market-item-sticky-header-top', '-' + this.$refs.MarketItemStickyHeader.offsetHeight + 'px');
                    }
                }
                else {
                    if (value) {
                        document.documentElement.style.setProperty('--market-item-sticky-header-top', '0px');
                    }
                    else {
                        document.documentElement.style.setProperty('--market-item-sticky-header-top', '-' + this.$refs.MarketItemStickyHeader.offsetHeight + 'px');
                    }
                }
            },
        },
    },
};
</script>

<style>
:root {
    --market-item-sticky-header-top: -100%
}
</style>
<style scoped>
.header {
    position: absolute;
    left: 0;
    z-index: calc(var(--z-index-sticky-header) - 1);
    width: 100%;
    background-color: var(--light-c);
    will-change: transform;
    transform: translateY(var(--market-item-sticky-header-top));
    transition: top var(--transition), transform var(--transition);
}

@media (min-width: 1040px) {
    .header-container {
        padding: 18px 0;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .header-container {
        width: 100vw;
        padding: 8px 32px;
    }
}
@media (min-width: 768px) {
    .header-container {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .header-container {
        width: 100vw;
    }
}

@media (min-width: 768px){
    .header-container__cover-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .header-container__cover-wrap {
        display: none;
    }
}

.title {
    font-family: var(--f-bold);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
@media (min-width: 1040px) {
    .title {
        margin-right: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .title {
        margin-right: 16px;
    }
}
@media (max-width: 767px) {
    .title {
        display: none;
    }
}

.sticky-header__navigation >>> .link {
    font-size: 14px;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .sticky-header__navigation >>> .link:not(:last-child) {
        margin-right: 24px;
    }
}
@media (min-width: 768px) {
    .sticky-header__navigation {
        margin-left: auto;
        flex-shrink: 0;
    }
}
@media (max-width: 767px) {
    .sticky-header__navigation >>> .link {
        padding-top: 14px;
        padding-bottom: 14px;
    }
}
</style>