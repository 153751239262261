<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <FormManager
            ref="form"
            v-model="formData"
            class="popup-content"
            @submit="save"
        >
            <h2 class="h2 h2_block popup-title">
                Получатель
            </h2>

            <FormField
                key="first_name"
                name="first_name"
                label="Имя"
                validations="required"
                class="form-field-gap_bottom"
            ></FormField>

            <FormField
                type="phone"
                name="phone"
                label="Телефон"
                visibleMask
                validations="required"
                class="form-field-gap_bottom"
            ></FormField>

            <FormField
                type="email"
                name="email"
                label="Email"
                validations="required"
                class="form-field-gap_bottom"
            ></FormField>

            <InfoMessage>
                На&nbsp;номер телефона будет отправлен код, необходимый для получения заказа в&nbsp;магазине, а&nbsp;E-mail используется для оповещений об&nbsp;изменениях статусов заказа.
            </InfoMessage>

            <div class="form__actions">
                <ButtonBlock
                    submit
                    primary
                    class="form__action"
                >
                    Сохранить
                </ButtonBlock>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';
import popup from "@/mixins/popup.js";
import PopupWrap from "@/components/popups/PopupWrap.vue";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import FormField from '@/components/_form/FormField.vue';
import FormManager from '@/components/_form/FormManager.vue';
import InfoMessage from '@/components/InfoMessage.vue';

export default {
    name: 'CheckoutCustomerInfoPopup',

    components: {
        InfoMessage,
        FormManager,
        FormField,
        PopupWrap,
        ButtonBlock,
    },

    mixins: [popup, handleFormErrorMixin],

    props: {
        currentValue: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            formData: {},
        };
    },

    mounted() {
        this.$set(this, 'formData', this.currentValue);
    },

    methods: {
        async save() {
            const errors = await this.$refs.form.validate();
            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.callback(this.formData);
                this.close();
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.form__actions {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .form__actions {
        display: flex;
        justify-content: flex-end;
    }
}
@media (max-width: 767px) {
    .form__actions {
        margin-top: auto;
    }

    .form__action {
        width: 100%;
    }
}
</style>