<template>
    <transition
        appear
        name="slide-from-bottom"
    >
        <div class="balloon layer-1">
            <TransitionHeightGroup>
                <div
                    v-if="loading"
                    key="loading"
                    class="balloon__loading"
                >
                    <Spinner center></Spinner>
                </div>
                <div
                    v-else
                    key="content"
                >
                    <ButtonIcon
                        :icon="{
                            name: 'cross',
                            big: true,
                            secondary: true,
                            hovered: true
                        }"
                        :secondary="false"
                        class="close-btn"
                        @click="close"
                    ></ButtonIcon>

                    <div class="balloon__main">
                        <img
                            :src="$links.uploads + point.service.logo.thumbnails.delivery_service_logo_big"
                            alt=""
                            class="balloon__logo"
                        >

                        <p class="mb-20">
                            {{ point.service.codename === 'pochtaru' ? point.code + ',' : '' }}
                            {{ city.full_name }},
                            {{ point.address }}
                        </p>

                        <template v-for="phone in point.phones">
                            <a
                                v-if="phone.length && phone[0]"
                                :href="`tel:${ phone[0] }${ phone[1] ? ',,' + phone[1] : '' }`"
                                class="balloon__point-contact"
                            >
                                <UIcon
                                    name="phone"
                                    secondary
                                    small
                                    class="mr-8"
                                ></UIcon>
                                <span>{{ phone[0] | phone }} <template
                                    v-if="phone[1]"
                                > (доб.: {{ phone[1] }})</template></span>
                            </a>
                        </template>

                        <p
                            v-if="point.email"
                            class="balloon__point-contact"
                        >
                            <UIcon
                                name="envelope-fill"
                                secondary
                                small
                                class="mr-8"
                            ></UIcon>
                            <span>{{ point.email }}</span>
                        </p>

                        <div class="schedule">
                            <div
                                v-for="(item, index) in point.work_times_merged"
                                :key="`day-${ index }`"
                                class="schedule-row"
                            >
                                <div class="schedule-td">
                                    {{ item.day_name }}
                                </div>
                                <div
                                    v-if="item.is_day_off"
                                    class="schedule-td text_red"
                                >
                                    Выходной
                                </div>
                                <div
                                    v-else
                                    class="schedule-td"
                                >
                                    {{ item.start_time.slice(0,-3) }} – {{ item.end_time.slice(0,-3) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="balloon__footer">
                        <p
                            v-if="priceData"
                            class="mb-16"
                        >
                            <b>{{ priceData.deadline | dt('DD MMMM') }} / {{ priceData.total_price | price }}</b>
                        </p>

                        <p
                            v-if="point.service.codename === 'pochtaru' && order.delivery_service_orders.length > 1"
                            class="balloon__description mb-16"
                        >
                            Заказ укомплектован в&nbsp;несколько упаковок и&nbsp;будет отправлен отдельными посылками.
                        </p>

                        <ButtonBlock
                            primary
                            w100
                            :disabled="!priceData"
                            @click="select"
                        >
                            Заберу отсюда
                        </ButtonBlock>
                    </div>
                </div>
            </TransitionHeightGroup>
        </div>
    </transition>
</template>

<script>
import plural from '@/lib/plural.js';
import { dt } from '@ui/filters/index.js';
import getErrorCode from '@/lib/getErrorCode.js';
import ButtonIcon from './_buttons/ButtonIcon.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import TransitionHeightGroup from '@/components/_transitions/TransitionHeightGroup.vue';
import Spinner from '@/components/Spinner.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'PickupPointBalloon',

    filters: {
        dt,
    },

    components: {
        UIcon,
        Spinner,
        TransitionHeightGroup,
        ButtonBlock,
        ButtonIcon,
    },

    props: {
        point: {
            type: Object,
            default: () => {},
        },
        orderUUID: {
            type: String,
            default: '',
        },
        city: {
            type: Object,
            default: () => {},
        },
        order: Object,
    },

    data() {
        return {
            priceData: null,
            loading: true,
        };
    },

    created() {
        if (!this.point.priceData) this.showBalloon();
        else this.priceData = this.point.priceData;
    },

    methods: {
        async showBalloon() {
            this.loading = true;
            const data = {
                service: this.point.service.codename,
                order: this.orderUUID,
                office_to: this.point.id,
                address_to: null,
                city_to: this.city.id,
            };
            try {
                this.priceData = await this.$api.delivery.estimate.byOrder(data);
                this.priceData.days_max_text = this.priceData.days_max
                    + ' '
                    + plural(this.priceData.days_max, ['день', 'дня', 'дней']);
                this.$emit('getData', this.priceData);
            }
            catch (error) {
                this.priceData = null;
                const code = getErrorCode(error);
                const data = error.response.data;

                if (code === 400) {
                    const { office_to, non_field_errors } = data;
                    if (office_to) {
                        this.$error(office_to[0].message);
                    }
                    else if (non_field_errors) {
                        this.$error(non_field_errors[0].message);
                    }
                }
            }
            this.loading = false;
        },

        select() {
            this.$emit('select', { point: this.point, priceData: this.priceData });
            this.close();
        },

        close() {
            this.$emit('close');
            this.shop = {};
        },
    },
};
</script>

<style scoped>
.balloon {
    z-index: 9;
}
@media (min-width: 768px) {
    .balloon {
        width: 380px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .slide-from-bottom-enter,
    .slide-from-bottom-leave-to {
        opacity: 0;
    }

    .slide-from-bottom-enter-to,
    .slide-from-bottom-leave {
        opacity: 1
    }
}
@media (max-width: 767px) {
    .balloon {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 90vh;
        padding: 20px var(--content-gap);
        box-shadow: var(--base-shadow);
        border-radius: var(--border-radius-x3) var(--border-radius-x3) 0 0;
        overflow: auto;
    }

    .slide-from-bottom-enter,
    .slide-from-bottom-leave-to {
        transform: translateY(100%);
    }
}

.balloon__loading {
    display: flex;
    justify-content: center;
    padding: 24px;
}

@media (min-width: 768px) {
    .close-btn {
        position: absolute;
        top: 6px;
        right: 6px;
    }
}
@media (max-width: 767px) {
    .close-btn {
        position: absolute;
        top: -14px;
        right: -14px;
    }
}

@media (min-width: 768px) {
    .balloon__main {
        padding: 24px 24px 20px;
    }
}
@media (max-width: 767px) {
    .balloon__main {
        padding-bottom: 20px;
    }
}

.balloon__logo {
    width: 60px;
    height: 40px;
}
@media (min-width: 768px) {
    .balloon__logo {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .balloon__logo {
        margin-bottom: 12px;
    }
}

.balloon__footer {
    border-top: 1px solid var(--border-light-c);
}
@media (min-width: 768px) {
    .balloon__footer {
        padding: 20px 24px 24px;
    }
}
@media (max-width: 767px) {
    .balloon__footer {
        padding-top: 20px;
    }
}

.schedule {
    margin-top: 20px;
}

.schedule-row {
    display: flex;
}

.schedule-row:not(:last-child) {
    margin-bottom: 8px;
}

.schedule-row::after {
    content: "";
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 5px;
    order: 2;
    align-self: flex-end;
    flex-grow: 1;
    border-bottom: 1px dotted var(--border-dark-c);
}

.schedule-td:first-child {
    order: 1;
}

.schedule-td:last-child {
    order: 3;
}

.balloon__point-contact {
    display: flex;
    align-items: center;
}

.balloon__point-contact:not(:last-child) {
    margin-bottom: 8px;
}

.balloon__description {
    margin-bottom: 16px;
    color: var(--font-secondary-color);
    line-height: 1.5;
    font-size: var(--small-fz);
}
</style>