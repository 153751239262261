<template>
    <section class="market-item-small-header layer-1">
        <MarketItemSecondaryPageHeader
            :item="newPart"
            modelName="part_product"
            class="main"
            @changeFavorite="onChangeFavorite"
        ></MarketItemSecondaryPageHeader>

        <MarketItemNavigation
            :items="nav"
        ></MarketItemNavigation>
    </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import MarketItemSecondaryPageHeader from '@/components/MarketItem/presets/MarketItemSecondaryPageHeader.vue';
import MarketItemNavigation from '@/components/MarketItemNavigation.vue';

export default {
    name: 'MarketPartSmallHeader',

    components: {
        MarketItemNavigation,
        MarketItemSecondaryPageHeader,
    },

    computed: {
        ...mapState({
            newPart: state => state.marketNewPart.newPart,
            analogPartsTotalCount: state => state.marketNewPart.analogs.current_count,
            // newPartOffersAmount: state => state.marketNewPartOffers.newPartOffersTotalCount,
            // contractPartOffersAmount: state => state.marketNewPartOffers.contractPartOffersTotalCount,
        }),

        nav() {
            let nav = [
                {
                    to: {
                        name: 'market-new-part-description',
                        params: {
                            id: this.$route.params.id,
                        },
                    },
                    label: 'Описание',
                },
                // {
                //     to: {
                //         name: 'market-new-part-offers-new-items',
                //         params: {
                //             id: this.$route.params.id,
                //         },
                //     },
                //     nested: ['market-new-part-offers-new-items', 'market-new-part-offers-contract-items'],
                //     label: 'Цены',
                //     amount: this.newPartOffersAmount + this.contractPartOffersAmount,
                // },
                {
                    to: {
                        name: 'market-part-applicability',
                        params: {
                            id: this.$route.params.id,
                        },
                    },
                    label: 'Применяемость',
                },
                {
                    to: {
                        name: 'market-new-part-reviews',
                        params: {
                            id: this.$route.params.id,
                        },
                    },
                    label: 'Отзывы',
                    amount: this.newPart.reviews_amount,
                },
            ];

            if (this.analogPartsTotalCount) {
                nav.splice(2, 0, {
                    to: {
                        name: 'market-new-part-analogs',
                        params: {
                            id: this.$route.params.id,
                        },
                    },
                    label: 'Аналоги',
                    amount: this.analogPartsTotalCount,
                });
            }
            return nav;
        },
    },

    methods: {
        ...mapMutations({
            CHANGE_FAVORITE: 'marketNewPart/changeFavorite',
        }),

        onChangeFavorite(value) {
            this.CHANGE_FAVORITE(value);
        },
    },
};
</script>

<style scoped>
.market-item-small-header {
    margin-top: var(--gap-y-medium);
    margin-bottom: var(--gap-y-medium);
}

.main {
    position: relative;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .main {
        padding: 20px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .main {
        padding: 20px 24px;
    }
}
@media (max-width: 767px) {
    .main {
        padding: 16px;
    }
}
</style>