<template>
    <component
        :is="tag"
        v-bind="attrs"
        class="u-button-block"
        :class="{
            'u-button-block_disabled': disabled,
            'u-button-block_primary': primary,
            'u-button-block_secondary': secondary,
            'u-button-block_blue': blue,
            'u-button-block_loading': loading,
            'u-button-block_active': active,
            'u-button-block_low': low,
            'u-button-block_narrow': narrow || narrowComputed,
            'u-button-block_fixed': fixed,
        }"
        @click="$emit('click', $event)"
    >
        <USpinner
            :light="primary"
            :dark="secondary"
            :small="low"
            class="u-button-block__spinner"
            :class="{
                'u-button-block__spinner_loading': loading,
            }"
        ></USpinner>

        <span
            class="u-button-block__label"
            :class="{
                'u-button-block__label_loading': loading
            }"
        >
            <slot name="prepend-icon">
                <UIcon
                    v-if="prependIcon"
                    v-bind="prependIcon"
                    class="mr-8 u-button-block__icon u-button-block__prepend-icon"
                ></UIcon>
            </slot>

            <slot>
                {{ label }}
            </slot>

            <slot name="append-icon">
                <UIcon
                    v-if="appendIcon"
                    v-bind="appendIcon"
                    class="ml-8 u-button-block__icon u-button-block__append-icon"
                ></UIcon>
            </slot>
        </span>
    </component>
</template>

<script>
import buttonMixin from '@ui/mixins/button.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';


export default {
    name: 'UButtonBlock',

    components: {
        USpinner,
        UIcon,
    },

    mixins: [
        buttonMixin,
    ],

    props: {
        primary: Boolean,
        secondary: Boolean,
        blue: Boolean,
        low: Boolean,
        narrow: Boolean,
        appendIcon: Object,
        prependIcon: Object,
        fixed: Boolean,
    },

    computed: {
        narrowComputed() {
            return !!this.appendIcon
                || !!this.prependIcon
                || !!(this.$scopedSlots.appendIcon && this.$scopedSlots.appendIcon())
                || !!(this.$scopedSlots.prependIcon && this.$scopedSlots.prependIcon());
        },
    },
};
</script>

<style>
.u-button-block {
    position: relative;
    padding: 10px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    font-size: var(--big-fz);
    line-height: 1.3;
    font-family: var(--f-semi-bold);
    text-decoration: none;
    white-space: nowrap;
    border-radius: var(--border-radius);
    transition:
        background-color var(--transition),
        border-color var(--transition),
        color var(--transition),
        fill var(--transition);
}

.u-button-block.u-button-block_low {
    padding: 7px 16px;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

@media (min-width: 568px) {
    .u-button-block.u-button-block_fixed {
        width: 240px;
    }
}
@media (max-width: 567px) {
    .u-button-block.u-button-block_fixed {
        width: 100%;
    }
}

.u-button-block__prepend-icon {
    margin-right: 8px;
}

.u-button-block__append-icon {
    margin-left: 8px;
}

.u-button-block__label {
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity var(--transition-fastest);
}

.u-button-block__label.u-button-block__label_loading {
    opacity: 0;
}

.u-button-block__spinner {
    position: absolute !important;
    margin: auto;
    opacity: 0;
    display: none !important;
    transition: opacity var(--transition-fastest);
}

.u-button-block__spinner.u-button-block__spinner_loading {
    opacity: 1;
    display: block !important;
}


.u-button-block.u-button-block_blue {
    color: var(--light-c);
    fill: var(--light-c);
    background-color: var(--blue-medium);
    border: 2px solid var(--blue-medium);
}

.u-button-block.u-button-block_blue:hover,
.u-button-block.u-button-block_blue:focus {
    background-color: var(--blue-dark);
    border-color: var(--blue-dark);
}

.u-button-block.u-button-block_blue:active {
    background-color: var(--blue-darkest);
    border-color: var(--blue-darkest);
}

.u-button-block.u-button-block_blue:disabled,
.u-button-block.u-button-block_blue.u-button-block_disabled {
    background-color: var(--blue-inactive);
    border-color: var(--blue-inactive);
}

.u-button-block.u-button-block_blue.u-button-block_active {
    background-color: var(--blue-darkest);
    border-color: var(--blue-darkest);
}

.u-button-block.u-button-block_blue.u-button-block_active:hover,
.u-button-block.u-button-block_blue.u-button-block_active:focus {
    background-color: var(--blue-darkest);
    border-color: var(--blue-darkest);
}



.u-button-block.u-button-block_primary {
    color: var(--light-c);
    fill: var(--light-c);
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.u-button-block.u-button-block_primary:hover,
.u-button-block.u-button-block_primary:focus {
    background-color: var(--primary-dark-color);
    border-color: var(--primary-dark-color);
}

.u-button-block.u-button-block_primary:active {
    background-color: var(--primary-darkest-color);
    border-color: var(--primary-darkest-color);
}

.u-button-block.u-button-block_primary:disabled:not(.u-button-block_loading),
.u-button-block.u-button-block_primary.u-button-block_disabled:not(.u-button-block_loading) {
    background-color: var(--primary-light-color);
    border-color: var(--primary-light-color);
}

.u-button-block.u-button-block_primary.u-button-block_active {
    color: var(--primary-color);
    fill: var(--primary-color);
    background-color: var(--light-c);
    border-color: var(--primary-color);
}

.u-button-block.u-button-block_primary.u-button-block_active:hover,
.u-button-block.u-button-block_primary.u-button-block_active:focus {
    color: var(--primary-dark-color);
    fill: var(--primary-dark-color);
    background-color: var(--light-c);
    border-color: var(--primary-dark-color);
}

.u-button-block.u-button-block_primary.u-button-block_low {
    padding: 6px 16px;
}



.u-button-block.u-button-block_secondary {
    padding: 11px 24px;
    color: var(--dark-c);
    fill: var(--dark-c);
    background-color: var(--light-c);
    border: 1px solid var(--border-dark-c);
}

.u-button-block.u-button-block_secondary.u-button-block_low {
    padding: 7px 16px;
}

.u-button-block.u-button-block_secondary .u-button-block__icon {
    fill: var(--dark-c)
}

.u-button-block.u-button-block_secondary:hover,
.u-button-block.u-button-block_secondary:focus {
    background-color: var(--lightest-bg);
}

.u-button-block.u-button-block_secondary.u-button-block_active,
.u-button-block.u-button-block_secondary:active {
    background-color: var(--light-bg);
    border-color: var(--border-dark-c);
}

.u-button-block.u-button-block_secondary:disabled:not(.u-button-block_loading),
.u-button-block.u-button-block_secondary.u-button-block_disabled:not(.u-button-block_loading) {
    color: var(--font-secondary-light-color);
    fill: var(--font-secondary-light-color);
    border-color: var(--border-light-c);
}


.u-button-block.u-button-block_narrow {
    padding-left: 12px;
    padding-right: 12px;
}
</style>