var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"geo-input",class:{
        'geo-input_with-map': _vm.map,
    }},[_c('Selector',{ref:"input",staticClass:"geo-input__input",attrs:{"value":_vm.inputValue,"label":_vm.label,"loading":_vm.inputLoading,"options":_vm.options,"optionKey":"coords","optionLabel":"label","clearInputOnBlur":false,"clearInputOnFocus":false,"clearInputOnSelect":false,"autocomplete":"","isAsync":"","invalid":_vm.invalid,"error":_vm.error,"disabled":_vm.disabled},on:{"input":_vm.onInput,"open":_vm.onOpen,"close":_vm.onClose,"select":_vm.onSelect,"change":_vm.onChange,"blur":_vm.onBlur,"clear":_vm.onClear}}),_vm._v(" "),(_vm.map)?_c('div',{staticClass:"geo-input__map-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"geo-input__loading"},[_c('Spinner')],1),_vm._v(" "),(_vm.showMap)?_c('yandexMap',{staticClass:"geo-input__map",class:{
                'geo-input__map_hidden': _vm.loading,
            },attrs:{"options":{
                suppressMapOpenBlock: true
            },"coords":_vm.mapValue,"zoom":13,"scrollZoom":false,"controls":[
                'fullscreenControl',
                'zoomControl'
            ],"behaviors":[
                'drag',
                'dblClickZoom',
                'rightMouseButtonMagnifier' ],"useObjectManager":"","ymapClass":"ymap"},on:{"map-was-initialized":_vm.onMap,"boundschange":_vm.changeMap,"click":_vm.onClickMap}},[(_vm.markerValue)?_c('ymapMarker',{attrs:{"coords":_vm.markerValue,"icon":_vm.getYMapMarkerIcon({}),"markerId":_vm.id + '_marker'}}):_vm._e()],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }