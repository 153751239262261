<template>
    <PopupWrap
        closeBtn="outer"
        noPadding
        full
        @clickaway="close"
        @close="close"
    >
        <div class="video-view">
            <Spinner
                v-if="!isLoaded"
            ></Spinner>

            <div
                id="player"
                ref="player"
            >
                <iframe
                    v-if="video.player=== 'vimeo'"
                    :src="`https://player.vimeo.com/video/${ video.id }`"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    @load="isLoaded = true"
                ></iframe>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import Spinner from "../Spinner.vue";


export default {
    components: { Spinner, PopupWrap },

    mixins: [popup],

    props: {
        videoUrl: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLoaded: false,
            youtubeCallbackName: 'onYouTubeIframeAPIReady',
            youtubeExistsFlag:   '$isYoutubeFrameAPIReady',
        };
    },

    computed: {
        video() {
            const YTRegExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const VimeoRegExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
            const YTMatch = this.videoUrl.match(YTRegExp);
            const VimeoMatch = this.videoUrl.match(VimeoRegExp);

            if (YTMatch) {
                return {
                    player: 'youtube',
                    id: YTMatch[2],
                };
            }
            else if (VimeoMatch) {
                return {
                    player: 'vimeo',
                    id: VimeoMatch[5],
                };
            }
            else {
                return { player: 'none' };
            }
        },
    },

    mounted() {
        if (this.video.player === 'youtube') {
            if (!this.hasYoutubeFrameAPI()) {
                this.injectYoutubeFrameAPI();
            }
            this.whenYoutubeAPIReady()
                .then(() => {
                    this.YTPLayer = new YT.Player(this.$refs.player.id, {
                        videoId: this.video.id,
                        height: '0',
                        width: '0',
                        events: {
                            'onReady': this.onPlayerReady,
                        },
                    });
                },
                error => {
                    console.error(error);
                });
        }

        window.addEventListener('resize', this.setVideoSizes);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.setVideoSizes);
    },

    methods: {
        whenYoutubeAPIReady() {
            const existsFlag = this.youtubeExistsFlag;
            return new Promise(function(resolve, reject){
                let elapsed = 0;
                let intervalHandle;
                let checker = function() {
                    elapsed += 48;
                    if(window[existsFlag]){
                        clearTimeout(intervalHandle);
                        resolve();
                    }
                    else{
                        if(elapsed <= 15000){
                            intervalHandle = setTimeout(checker, 48);
                        }
                        else{
                            reject("timeout");
                        }
                    }
                };

                setTimeout(checker,48);
            });
        },
        hasYoutubeFrameAPI() {
            if(!this.hasYTFrame) {
                this.hasYTFrame = !!(document.getElementsByClassName('.yt-frame-api').length);
            }
            return this.hasYTFrame;
        },
        injectYoutubeFrameAPI() {
            const youtubeExistsFlag   = this.youtubeExistsFlag;
            const youtubeCallbackName = this.youtubeCallbackName;

            window[this.youtubeCallbackName] = window[this.youtubeCallbackName] || function() {
                window[youtubeExistsFlag] = true;
                window[youtubeCallbackName] = null;
                delete window[youtubeCallbackName];
            };

            let tag = document.createElement('script');
            let first = document.getElementsByTagName('script')[0];
            tag.src = "https://www.youtube.com/iframe_api";
            tag.className = "yt-frame-api";
            first.parentNode.insertBefore(tag, first);
        },
        onPlayerReady(event) {
            event.target.playVideo();
            this.isLoaded = true;
            this.setVideoSizes();
        },
        setVideoSizes() {
            let player = document.querySelector('#player'),
                maxPlayerWidth = window.innerWidth - 600,
                maxPlayerHeight = window.innerHeight - 300,
                playerHeight = maxPlayerWidth / 1.777;

            if (playerHeight < maxPlayerHeight) {
                player.width = maxPlayerWidth;
                player.height = playerHeight;
            }
            else {
                player.height = maxPlayerHeight;
                player.width = maxPlayerHeight * 1.777;
            }
        },
    },
};
</script>

<style scoped>
.video-view {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
