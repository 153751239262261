<template>
    <div class="u-field-password">
        <UInputPassword
            :invalid="invalid"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template
                v-for="slotName in Object.keys($scopedSlots)"
                v-slot:[slotName]="payload"
            >
                <slot :name="slotName" v-bind="payload"></slot>
            </template>
        </UInputPassword>

        <UFormFieldError
            :show="invalid"
            class="u-field-password__error"
        >
            {{ error }}
        </UFormFieldError>
    </div>
</template>

<script>
import fieldMixin from './fieldMixin.js';
import UInputPassword from '../../UInput/presets/UInputPassword.vue';
import UFormFieldError from '../../UForm/UFormFieldError.vue';


export default {
    name: 'UFieldPassword',

    components: {
        UFormFieldError,
        UInputPassword,
    },

    mixins: [
        fieldMixin,
    ],

    data() {
        return {
            initialValue: '',
        };
    },
};
</script>

<style>
.u-field-password__error .u-form-field-error {
    margin-top: 8px;
}
</style>