var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"insurance-osago-error"},[_c('BackLink',{staticClass:"back-link",attrs:{"to":{
            name: 'insurance-osago-edit',
            params: {
                uuid: _vm.uuid
            }
        },"text":"Изменить данные"}}),_vm._v(" "),_c('h1',{staticClass:"h1"},[_vm._v("\n        Оформление полиса невозможно\n    ")]),_vm._v(" "),(_vm.offers.length)?[_c('p',{staticClass:"descriptor"},[_vm._v("\n            К сожалению, оформление полиса ОСАГО в выбранной компании сейчас невозможно. \n            Вы можете ознакомиться с предложениями от других страховых компаний.\n        ")]),_vm._v(" "),_c('div',{staticClass:"top-gap_mini"},_vm._l((_vm.offers),function(item){return _c('InsuranceOffer',{key:'insurance-option-' + (item.company || {}).code,attrs:{"offer":item,"activeOffer":_vm.activeOffer,"loading":_vm.activateLoading},on:{"select":function($event){return _vm.selectCompany(item)}}})}),1)]:_c('EmptyBlock',{staticClass:"empty-block",attrs:{"icon":"empty","title":"Данные не подтверждены"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('p',[_vm._v("\n                К сожалению, оформление полиса ОСАГО в выбранной компании \n                сейчас невозможно и нет других подходящих предложений.\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n                Возможно, дело в неверных данных или технической ошибке. \n                Попробуйте отправить запрос ещё раз через некоторое время.\n            ")])]},proxy:true},{key:"button",fn:function(){return [_c('ButtonText',{staticClass:"repeat-button",attrs:{"primary":"","dashed":""},on:{"click":_vm.repeat}},[_vm._v("Повторить запрос")]),_vm._v(" "),_c('ButtonText',{attrs:{"secondary":"","dark":"","dashed":"","to":{
                    name: 'insurance-osago-edit',
                    params: _vm.$route.params,
                }}},[_vm._v("Изменить данные")])]},proxy:true}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }