<template>
    <div class="default-layout">
        <DefaultLayoutHeader></DefaultLayoutHeader>

        <div class="error-4xx-page container">
            <div class="image-404-wrap">
                <span class="error-code">{{ error }}</span>
                <img
                    src="../assets/images/car-black.png"
                    alt="Ubibi"
                    class="car-image"
                >
            </div>

            <h1 class="h1 h1_text">
                {{ computedTitle }}
            </h1>

            <p
                v-if="error === 404"
                class="text"
            >
                К сожалению, такой страницы нет!<br>Попробуйте вернуться назад или поищите что-нибудь другое.
            </p>
            <p
                v-if="error === 403"
                class="text"
            >
                У вас недостаточно прав для доступа к запрашиваемой странице.
            </p>
            <p
                v-if="error === 429"
                class="text"
            >
                Превышен лимит запросов, попробуйте повторить попытку позже.
            </p>

            <ButtonBlock
                :to="{
                    name: 'main'
                }"
                primary
                class="error-4xx-page__btn"
            >
                На главную
            </ButtonBlock>
        </div>

        <DefaultLayoutFooter></DefaultLayoutFooter>
    </div>
</template>

<script>
import DefaultLayoutHeader from "../components/header/DefaultLayoutHeader.vue";
import DefaultLayoutFooter from "../components/DefaultLayoutFooter.vue";
import ButtonBlock from "../components/_buttons/ButtonBlock.vue";

export default {
    name: "Error4xxPage",

    components: { ButtonBlock, DefaultLayoutFooter, DefaultLayoutHeader },

    metaInfo() {
        return {
            title: this.computedTitle,
        };
    },

    props: {
        error: {
            type: Number,
            default: 404,
        },
    },

    computed: {
        computedTitle() {
            let text = '';
            if (this.error === 404) text = 'Страница не найдена';
            else if (this.error === 403 || this.error === 429) text = 'Доступ запрещен';
            return text;
        },
    },

    watch: {
        '$route.name': {
            handler() {
                this.$store.commit('clearHttpError');
            },
        },
    },
};
</script>

<style scoped>
.default-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--bright-bg);
}

.error-4xx-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--gap-y-max);
    flex-grow: 1;
}

.image-404-wrap {
    position: relative;
    margin-bottom: 20px;
}

.error-code {
    display: block;
    line-height: 1;
    font-family: var(--f-extra-bold);
    color: var(--primary-color);
}
@media (min-width: 1040px) {
    .error-code {
        height: 300px;
        font-size: 300px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .error-code {
        height: 225px;
        font-size: 225px;
    }
}
@media (max-width: 767px) {
    .error-code {
        height: 135px;
        font-size: 135px;
    }
}

.car-image {
    margin-left: auto;
    margin-right: auto;
}
@media (min-width: 1040px) {
    .car-image {
        width: 542px;
        height: 200px;
        margin-top: -150px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .car-image {
        width: 406px;
        height: 150px;
        margin-top: -112px;
    }
}
@media (max-width: 767px) {
    .car-image {
        width: 266px;
        height: 98px;
        margin-top: -70px;
    }
}

.text {
    margin-bottom: var(--gap-y-small);
    font-size: var(--adaptive-big-fz);
    line-height: var(--base-lh);
    text-align: center;
}
@media (min-width: 1040px) {
    .text {
        max-width: 780px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .text {
        width: var(--col-10);
    }
}

@media (max-width: 767px) {
    .error-4xx-page__btn {
        width: 100%;
    }
}
</style>