<template>
    <RouterLink
        :to="{
            name: modelName === 'product'
                ? 'market-product-description'
                : modelName === 'part_product' || modelName === 'part'
                    ? 'market-new-part-description'
                    : 'market-contract-part-description',
            params: {
                id: id,
            },
        }"
        class="market-item-name"
    >
        <slot>{{ name }}</slot>
    </RouterLink>
</template>

<script>
export default {
    name: 'MarketItemName',

    props: {
        modelName: {
            type: String,
            default: '',
        },

        id: {
            type: [Number, String],
            default: '',
        },

        name: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped>
.market-item-name {
    display: block;
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
}

.market-item-name:hover,
.market-item-name:focus {
    color: var(--primary-color);
}
</style>