export function changeDays(date, days) {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + days);
    return endDate;
}

export function changeHours(date, hours) {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    endDate.setHours(startDate.getHours() + hours);
    return endDate;
}