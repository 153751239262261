import Vue from 'vue';
import deepClone from '@/lib/deepClone.js';
import normalizeDynamicFilters from '@/lib/normalizeDynamicFilters.js';
import normalizeSelectionFilters from '@/lib/normalizeSelectionFilters.js';
import { decimal } from '@ui/filters';

const selectionFields = [
    'tire_width',
    'tire_height',
    'tire_diameter',
    'tire_season',
    'tire_spikes',
    'rim_width',
    'rim_diameter',
    'rim_et',
    'rim_pcd',
    'rim_dia',
];

const normalizeChecked = function(widget, value) {
    if (widget === 'checkboxes' || widget === 'multiselect') {
        return value.map(v => v.name).join(', ');
    }
    else if (widget === 'range') {
        const from = decimal(value[0]);
        const to = decimal(value[1]);

        if (from && to) {
            return from + '–' + to;
        }
        else if (from) {
            return 'от ' + from;
        }
        else if (to) {
            return 'до ' + to;
        }
    }
};

const setDefaultEmptyValues = function(filters, defaultValue, currentValue) {
    const data = deepClone(defaultValue);

    filters.forEach(filter => {
        const widget = filter.widget;
        const codename = filter.codename;

        if (codename === 'in_stock') {
            data[codename] = currentValue.in_stock;
        }
        else if (data[codename] === undefined) {
            if (widget === 'checkboxes' || widget === 'rating' || widget === 'multiselect') {
                data[codename] = [];
            }
            else if (widget === 'range') {
                data[codename] = [null, null];
            }
            else if (widget === 'checkbox' || widget === 'radioBoolean') {
                data[codename] = false;
            }
            else if (widget === 'categories' || widget === 'select') {
                data[codename] = {};
            }
            else {
                data[codename] = '';
            }
        }
    });

    return data;
};

const diffIndex = (arr1, arr2) => {
    for (let i = 0; i < arr1.length; i++) {
        if (!arr2.some(item => item.id === arr1[i].id)) {
            return { id: arr1[i].id, index: i };
        }
    }
};

const emptyValue = ({ widget }) => {
    if (widget === 'checkboxes' || widget === 'rating' || widget === 'multiselect') {
        return [];
    }
    else if (widget === 'range') {
        return [null, null];
    }
    else if (widget === 'checkbox' || widget === 'radioBoolean') {
        return false;
    }
    else if (widget === 'categories' || widget === 'select') {
        return {};
    }
    else {
        return '';
    }
};

const empty = arr => arr.every(v => v === '' || v === null);
const some = arr => arr.some(v => v !== '' || v !== null);

const combineDefaultAndCurrentOptionsCount = (defaultOptions, currentOptions) => {
    if (defaultOptions && currentOptions) {
        const activeOptions = [];
        const disabledOptions = [];
        defaultOptions.forEach(defaultOption => {
            let newOption = Object.assign({}, defaultOption);

            const currentInDefault = currentOptions.find(item => item.id === defaultOption.id);
            if (currentInDefault) {
                newOption.count = currentInDefault.count;
                activeOptions.push(newOption);
            }
            else {
                newOption.count = 0;
                disabledOptions.push(newOption);
            }

            return newOption;
        });
        return [...activeOptions, ...disabledOptions];
    }
    else {
        return [];
    }
};

export default {
    namespaced: true,

    state: () => ({
        checked: [],
        value: {},
        defaultValue: {
            in_stock: 'true',
        },
        url: '',
        staticFilters: [],
        dynamicFilters: [],
        selectionFilters: [],
    }),

    getters: {
        items: state => {
            return [...state.staticFilters, ...state.dynamicFilters];
        },

        query: state => {
            const query = {};
            const filters = [...state.staticFilters, ...state.dynamicFilters, ...state.selectionFilters];


            filters.forEach(filter => {
                const { codename, widget } = filter;
                if (!query[codename]) {
                    const value = state.value[codename];

                    if (widget === 'range') {
                        if (value[0] !== null) {
                            const minField = codename + '__gte';
                            query[minField] = value[0];
                        }

                        if (value[1] !== null) {
                            const maxField = codename + '__lte';
                            query[maxField] = value[1];
                        }
                    }
                    else if (widget === 'checkboxes' || widget === 'multiselect') {
                        if (value.length) {
                            query[codename] = value.map(v => v.id);
                        }
                    }
                    else if (widget === 'radioBoolean' || widget === 'checkbox') {
                        if (value) {
                            query[codename] = value;
                        }
                    }
                    else if (widget === 'radio') {
                        if (value) {
                            query[codename] = value.id !== undefined ? value.id : value;
                        }
                    }
                    else if (widget === 'rating') {
                        if (value.length) {
                            query[codename] = value.join(',');
                        }
                    }
                    else if (widget === 'categories' || widget === 'select') {
                        if (value.id) {
                            query[codename] = value.id;
                        }
                    }
                }
            });

            return query;
        },

        flat: state => {
            const obj = {};
            const filters = [...state.staticFilters, ...state.dynamicFilters, ...state.selectionFilters];

            filters.forEach((filter, index) => {
                obj[filter.codename] = filter;
                obj[filter.codename].index = index;
            });

            return obj;
        },
    },

    mutations: {
        deleteFilter(state, { codename, widget, id }) {
            if (widget === 'checkboxes' || widget === 'multiselect') {
                state.value[codename] = state.value[codename].filter(item => item.id !== id);
                state.checked = state.checked.filter(item => !(item.id === id && item.codename === codename));
            }
            else if (widget === 'range') {
                state.value[codename] = [null, null];
                state.checked = state.checked.filter(filter => filter.codename !== codename);
            }
            else if (widget === 'select') {
                state.value[codename] = {};
                state.checked = state.checked.filter(filter => filter.codename !== codename);
            }
            else if (widget === 'radio') {
                state.value[codename] = '';
                state.checked = state.checked.filter(filter => filter.codename !== codename);
            }
        },

        reset(state) {
            state.checked = [];
            state.value = setDefaultEmptyValues(
                [
                    ...state.staticFilters,
                    ...state.dynamicFilters,
                    ...state.selectionFilters,
                ],
                state.defaultValue,
                state.value,
            );
        },

        setOptions(state, { options, currentCount, index }) {
            state.items[index].options = options;
            state.items[index].currentCount = currentCount;
        },

        setChecked(state, checked) {
            state.checked = checked;
        },

        addChecked(state, payload) {
            const index = state.checked.length;
            if (Array.isArray(payload)) {
                state.checked.splice(index, 0, ...payload);
            }
            else {
                state.checked.splice(index, 0, payload);
            }
        },

        changeChecked(state, { filter, item }) {
            const index = state.checked.map(item => item.codename).indexOf(filter.codename);
            state.checked.splice(index, 1, item);
        },

        setValue(state, value) {
            state.value = value;
        },

        setDefaultValue(state, value) {
            state.defaultValue = value;
        },

        setStaticFilters(state, { config, facets, defaultFacets }) {
            const staticFilters = deepClone(config);

            const staticFiltersInStock = staticFilters.find(item => item.codename === 'in_stock');
            const staticFiltersPrice = staticFilters.find(item => item.codename === 'price');
            const staticFiltersBrands = staticFilters.find(item => item.codename === 'brand');
            const staticFiltersCompanies = staticFilters.find(item => item.codename === 'company');
            const staticFiltersCategories = staticFilters.find(item => item.codename === 'category');

            if (defaultFacets) {
                if (staticFiltersInStock) {
                    staticFiltersInStock.options[0].count = defaultFacets.in_stock_count;
                    staticFiltersInStock.options[1].count = defaultFacets.not_in_stock_count;
                }
                staticFiltersPrice.min = ((defaultFacets || {}).prices || {}).min_price;
                staticFiltersPrice.max = ((defaultFacets || {}).prices || {}).max_price;
                if (staticFiltersBrands) staticFiltersBrands.options = defaultFacets.brands;
                if (staticFiltersCompanies) staticFiltersCompanies.options = defaultFacets.companies;
                if (staticFiltersCategories) staticFiltersCategories.categories = defaultFacets.categories;
            }

            if (staticFiltersInStock) {
                staticFiltersInStock.options[0].count = facets.in_stock_count;
                staticFiltersInStock.options[1].count = facets.not_in_stock_count;
            }
            if (facets.prices) {
                staticFiltersPrice.min = facets.prices.min_price;
                staticFiltersPrice.max = facets.prices.max_price;
            }
            if (staticFiltersBrands) staticFiltersBrands.options = combineDefaultAndCurrentOptionsCount(staticFiltersBrands.options, facets.brands);
            if (staticFiltersCompanies) staticFiltersCompanies.options = combineDefaultAndCurrentOptionsCount(staticFiltersCompanies.options, facets.companies);
            if (staticFiltersCategories) staticFiltersCategories.categories = facets.categories;

            state.staticFilters = staticFilters;
        },

        setDynamicFilters(state, { config, facets, defaultFacets, current_count = 0 }) {
            if (config) {
                let dynamicFilters = deepClone(config);

                if (defaultFacets) {
                    dynamicFilters.forEach(filter => {
                        const currentFacet = defaultFacets.characteristics.find(item => item.codename === filter.codename);
                        if (currentFacet) filter.options = currentFacet.options;
                    });
                }

                dynamicFilters.forEach(filter => {
                    const currentFacet = facets.characteristics.find(item => item.codename === filter.codename);
                    if (currentFacet) {
                        if (filter.options.length) {
                            filter.options = combineDefaultAndCurrentOptionsCount(filter.options, currentFacet.options);
                        }
                        else {
                            filter.options = currentFacet.options;
                        }
                    }
                    else {
                        if (!current_count) {
                            filter.options = combineDefaultAndCurrentOptionsCount(filter.options, []);
                        }
                    }

                    filter.isDynamic = true;
                });

                state.dynamicFilters = dynamicFilters;
            }
            else  {
                state.dynamicFilters = [];
            }
        },

        setSelectionFilters(state, { config, facets, defaultFacets }) {
            let selectionFilters = deepClone(config);

            if (defaultFacets) {
                selectionFilters.forEach(filter => {
                    const currentFacet = defaultFacets.characteristics.find(item => item.codename === filter.codename);
                    if (currentFacet) filter.options = currentFacet.options;
                });
            }

            selectionFilters.forEach(filter => {
                const currentFacet = facets.characteristics.find(item => item.codename === filter.codename);
                if (filter.codename !== 'tire_season' && filter.codename !== 'tire_spikes') {
                    filter.options = combineDefaultAndCurrentOptionsCount(filter.options, (currentFacet || {}).options || []);
                }
            });
            state.selectionFilters = selectionFilters;
        },

        updateValue(state, filters) {
            const value = {};
            const allFilters = [...filters, ...state.staticFilters];
            const codenames = allFilters.map(filter => filter.codename);

            // очистка значений удалённых полей
            for (let codename of Object.keys(state.value)) {
                if (codenames.includes(codename)) {
                    value[codename] = state.value[codename];
                }
            }

            // установка дефолтных значений для новых полей
            allFilters.forEach(filter => {
                const codename = filter.codename;

                if (!value[codename]) {
                    value[codename] = emptyValue(filter);
                }
            });

            state.value = value;
        },
    },

    actions: {
        async getDynamicFiltersInCategory({ rootState }, { slug, routeName }) {
            // dynamicFilters только в конечной категорий и на странице результатов поиска
            // selectionFilters только в конечной категории шин и дисков

            let dynamicFilters = [];
            let selectionFilters = [];

            const { filters } = await this.$api.goodsCategories.filters(slug);

            const hasSelection = routeName === 'market-products-category' &&
                (rootState.settings.tire_selection_category.slug === slug || rootState.settings.rim_selection_category.slug === slug);

            if (hasSelection) {
                selectionFilters = normalizeSelectionFilters(
                    filters.filter(filter => selectionFields.includes(filter.codename)),
                );
            }

            dynamicFilters = normalizeDynamicFilters(filters);

            return { dynamicFilters, selectionFilters };
        },

        async change({ state, commit }, { filter = {}, newValue, oldValue, data, replace = false }) {
            commit('setValue', data);

            const item = {
                title: filter.title,
                codename: filter.codename,
                widget: filter.widget,
            };

            // replace – сбрасывает все текущие значения на новое
            if (replace) {
                item.value = normalizeChecked(filter.widget, newValue);
                commit('setChecked', state.checked.filter(item => item.codename !== filter.codename));
                commit('addChecked', newValue.map(v => {
                    return Object.assign({
                        id: v.id,
                        value: v.name,
                    }, item);
                }));
            }
            else {
                if (filter.widget === 'range') {
                    if (empty(newValue) && some(oldValue)) {
                        // removed
                        commit('setChecked', state.checked.filter(item => item.codename !== filter.codename));
                    }
                    else if (some(newValue) && some(oldValue)) {
                        // changed
                        item.value = normalizeChecked(filter.widget, newValue);
                        commit('changeChecked', { filter, item });
                    }
                    else if (some(newValue) && empty(oldValue)) {
                        // added
                        item.value = normalizeChecked(filter.widget, newValue);
                        commit('addChecked', item);
                    }
                }
                else if (filter.widget === 'checkboxes' || filter.widget === 'multiselect') {
                    if (oldValue.length && !newValue.length) {
                        // removed
                        commit('setChecked', state.checked.filter(item => item.codename !== filter.codename));
                    }
                    else if (oldValue.length && newValue.length) {
                        // changed
                        if (newValue.length > oldValue.length) {
                            const value = newValue[newValue.length - 1];
                            item.id = value.id;
                            item.value = value.name;
                            commit('addChecked', item);
                        }
                        else {
                            if (oldValue && oldValue.some(item => !item.id)) {
                                // После заполнения данных в компоненте фильтра
                                // приходят объекты вместо массива id
                                commit('addChecked', newValue.map(v => {
                                    return Object.assign({
                                        id: v.id,
                                        value: v.name,
                                    }, item);
                                }));
                            }
                            else {
                                const diff = diffIndex(oldValue, newValue);
                                if (diff) {
                                    commit('setChecked', state.checked.filter(item => item.id !== diff.id));
                                }
                            }
                        }
                    }
                    else if (!oldValue.length && newValue.length) {
                        // added
                        const value = newValue[newValue.length - 1];
                        item.id = value.id;
                        item.value = value.name;
                        commit('addChecked', item);
                    }
                }
                else if (filter.widget === 'select' || filter.widget === 'radio') {
                    if (oldValue.id && !newValue.id) {
                        // removed
                        commit('setChecked', state.checked.filter(item => item.codename !== filter.codename));
                    }
                    else if (oldValue.id && newValue.id) {
                        // changed
                        if (oldValue.id !== newValue.id) {
                            const value = newValue;
                            item.id = value.id;
                            item.value = value.name;
                            commit('changeChecked', { filter, item });
                        }
                    }
                    else if (!oldValue.id && newValue.id) {
                        // added
                        const value = newValue;
                        item.id = value.id;
                        item.value = value.name;
                        commit('addChecked', item);
                    }
                }
            }
        },

        parseQuery({ state, getters, commit } , { filters, query }) {
            filters.forEach(filter => {
                const { widget, codename } = filter;
                if (!state.value[codename]) {
                    let value;

                    if (widget === 'checkboxes' || widget === 'multiselect') {
                        // query[codename] – строка или массив строк
                        if (query[codename]) {
                            if (Array.isArray(query[codename])) {
                                value = query[codename].reduce((acc, v) => {
                                    const id = Number(v);

                                    for (let option of getters.flat[codename].options) {
                                        if (option.id === id) {
                                            acc.push(option);

                                            let checkedObj = {
                                                codename: codename,
                                                id: option.id,
                                                title: filter.title,
                                                value: option.name,
                                                widget: widget,
                                            };
                                            commit('addChecked', checkedObj);
                                            return acc;
                                        }
                                    }

                                    return acc;
                                }, []);
                            }
                            else {
                                for (let option of getters.flat[codename].options) {
                                    if (option.id === Number(query[codename])) {
                                        value = [option];

                                        let checkedObj = {
                                            codename: codename,
                                            id: option.id,
                                            title: filter.title,
                                            value: option.name,
                                            widget: widget,
                                        };
                                        commit('addChecked', checkedObj);

                                        break;
                                    }
                                }
                            }
                        }
                        else {
                            value = state.defaultValue[codename] || [];
                        }
                    }
                    else if (widget === 'range') {
                        const minField = codename + '__gte';
                        const maxField = codename + '__lte';

                        if (query[minField] || query[maxField]) {
                            const minValue = query[minField] || null;
                            const maxValue = query[maxField] || null;
                            value = [minValue, maxValue];

                            let checkedObj = {
                                codename: codename,
                                title: filter.title,
                                value: normalizeChecked('range', value),
                                widget: 'range',
                            };
                            commit('addChecked', checkedObj);
                        }
                        else {
                            value = state.defaultValue[codename] || [null, null];
                        }
                    }
                    else if (widget === 'radio') {
                        value = query[codename] || state.defaultValue[codename] || '';

                        if (codename !== 'in_stock') {
                            for (let option of getters.flat[codename].options) {
                                if (option.id === Number(query[codename])) {
                                    let checkedObj = {
                                        codename: codename,
                                        id: option.id,
                                        title: filter.title,
                                        value: option.name,
                                        widget: 'radio',
                                    };
                                    commit('addChecked', checkedObj);
                                }
                            }
                        }
                    }
                    else if (widget === 'checkbox' || widget === 'radioBoolean') {
                        if (query[codename] === undefined) {
                            value = state.defaultValue[codename] || false;
                        }
                        else {
                            value = query[codename] === 'false'
                                ? false
                                : query[codename] === 'true';
                        }
                    }
                    else if (widget === 'rating') {
                        value = query[codename]
                            ? query[codename].toString().split(',').map(v => Number(v))
                            : state.defaultValue[codename] || [];
                    }
                    else if (widget === 'categories') {
                        value = query[codename] ? { id: Number(query[codename]) } : state.defaultValue[codename] || {};
                    }
                    else if (widget === 'select') {
                        value = query[codename] ? { id: Number(query[codename]) } : state.defaultValue[codename] || {};

                        for (let option of getters.flat[codename].options) {
                            if (option.id === Number(query[codename])) {
                                let checkedObj = {
                                    codename: codename,
                                    id: option.id,
                                    title: filter.title,
                                    value: option.name,
                                    widget: 'select',
                                };
                                commit('addChecked', checkedObj);
                            }
                        }
                    }

                    Vue.set(state.value, codename, value);
                }
            });

            if (!query.in_stock) {
                Vue.set(state.value, 'in_stock', state.defaultValue.in_stock);
            }
        },
    },
};