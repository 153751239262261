<template>
    <div>
        <div class="top-gap_medium">
            <h1 class="h1 h1_block">
                Каталог автотоваров
            </h1>
        </div>

        <USpinner
            v-if="!initialized"
            big
            center
        ></USpinner>
        <div
            v-else-if="categories.current_count"
            class="categories-list"
        >
            <div
                v-for="category in categories.items"
                :key="'category-' + category.slug"
                v-lazy-container="{ selector: 'img' }"
                class="category layer-1"
            >
                <RouterLink
                    :to="{
                        name: 'market-products-category',
                        params: {
                            slug: category.slug
                        }
                    }"
                    class="cover-wrap"
                >
                    <img
                        :data-src="$links.uploads + category.cover.thumbnails.category_cover_list"
                        alt=""
                        class="cover"
                        loading="lazy"
                    >
                </RouterLink>

                <div class="category-content">
                    <h2 class="h2 h2_block category__title">
                        <RouterLink
                            :to="{
                                name: 'market-products-category',
                                params: {
                                    slug: category.slug
                                }
                            }"
                            class="link-primary-hover"
                        >
                            {{ category.name }}
                        </RouterLink>
                    </h2>

                    <ul
                        v-if="category.children.length"
                        class="children"
                    >
                        <li
                            v-for="child in category.children"
                            :key="'child-' + child.slug"
                            class="child"
                        >
                            <RouterLink
                                :to="{
                                    name: 'market-products-category',
                                    params: {
                                        slug: child.slug
                                    }
                                }"
                                class="child-link link-primary-hover"
                            >
                                {{ child.name }}
                            </RouterLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import marketProductsCatalog from '@/store/modules/marketProductsCatalog.js';
import USpinner from '@ui/components/USpinner/USpinner.vue';

export default {
    name: 'PartsCatalogParentPage',

    components: { USpinner },

    metaInfo() {
        return {
            title: this.meta.meta_title || this.meta.name,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content: this.meta.meta_title },
                { property: 'og:description', content: this.meta.meta_description },
                { property: 'og:image', content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        return this.INIT();
    },

    computed: {
        ...mapState({
            initialized: state => state.marketProductsCatalog.initialized,
            meta: state => state.marketProductsCatalog.meta,
            categories: state => state.marketProductsCatalog.categories,
        }),
    },

    created() {
        this.$store.registerModule('marketProductsCatalog', marketProductsCatalog);
    },

    async mounted() {
        this.$breadcrumbs([
            {
                to: {
                    name: 'market',
                },
                title: 'Запчасти и автотовары',
            },
            {
                to: {
                    name: 'market-products-catalog',
                },
                title: 'Каталог автотоваров',
            },
        ]);
        if (!this.initialized) await this.INIT();
    },

    beforeDestroy() {
        this.$store.unregisterModule('marketProductsCatalog');
    },

    methods: {
        ...mapActions({
            INIT: 'marketProductsCatalog/init',
        }),
    },
};
</script>

<style scoped>
.category {
    display: flex;
}
.category:not(:last-child) {
    margin-bottom: var(--grid-gap);
}
@media (min-width: 768px) {
    .category {
        align-items: flex-start;
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .category {
        align-items: center;
        padding: 16px;
    }
}

.cover-wrap {
    display: block;
    flex-shrink: 0;
}
.cover {
    aspect-ratio: 1/1;
}
@media (min-width: 1040px) {
    .cover {
        width: 160px;
        height: 160px;
        margin-right: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .cover {
        width: 120px;
        height: 120px;
        margin-right: 24px;
    }
}
@media (max-width: 767px) {
    .cover {
        width: 48px;
        height: 48px;
        margin-right: 12px;
    }
}

@media (min-width: 768px) {
    .category-content {
        flex-grow: 1;
        padding: 12px 0;
    }
}

@media (max-width: 767px) {
    .category__title {
        margin-bottom: 0;
        font-size: 14px;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        word-break: break-word;
    }
}

@media (min-width: 1160px) {
    .children {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1159px) {
    .children {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) {
    .children {
        display: grid;
        grid-gap: 16px var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .children {
        display: none;
    }
}

.child-link {
    font-size: var(--adaptive-big-fz);
}
</style>