const postfixes = [
    'last_name',
    'first_name',
    'middle_name',
    'birth_date',
    'doc_number',
    'doc_date',
    'address_obj',
    'address_apartment_number',
];

const getInsurerCodename = postfix => 'insurer_' + postfix;
const getOwnerCodename = postfix => 'owner_' + postfix;

export const ownerCodenames = postfixes.map(getOwnerCodename);
export const insurerCodenames = postfixes.map(getInsurerCodename);

export default () => {
    return {
        formData: {
            owner_last_name: '',
            owner_first_name: '',
            owner_middle_name: '',
            owner_birth_date: null,
            owner_doc_number: '',
            owner_doc_date: null,
            owner_address_obj: '',
            owner_address_apartment_number: null,
            owner_is_insurer: true,
            insurer_last_name: '',
            insurer_first_name: '',
            insurer_middle_name: '',
            insurer_birth_date: null,
            insurer_doc_number: '',
            insurer_doc_date: null,
            insurer_address_obj: '',
            insurer_address_apartment_number: null,
            insurer_email: '',
            insurer_phone: '',
        },
        keys: [
            ...ownerCodenames,
            'owner_is_insurer',
            ...insurerCodenames,
            'insurer_email',
            'insurer_phone',
        ],

        address: {
            hint: 'Введите адрес регистрации, как в паспорте, с указанием города, улицы и номера дома. Например: г. Москва, ул. Библиотечная, д. 19. Если вы живете в квартире, введите ее номер в отдельном поле.',
        },

        sms_code: {
            value: '',
            invalid: false,
            error: '',
        },
        confirmLoading: false,
        sendingLoading: false,
        intervalInstance: null,
        seconds: null,

        // todo: серверное время
        minBirthDate: new Date(1900, 0, 1),
        maxBirthDate: new Date(new Date().getFullYear() - 14, new Date().getMonth(), new Date().getDate()),
        maxDocDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    };
};