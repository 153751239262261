import { render, staticRenderFns } from "./InsuranceOffer.vue?vue&type=template&id=5b4ac7c8&scoped=true&"
import script from "./InsuranceOffer.vue?vue&type=script&lang=js&"
export * from "./InsuranceOffer.vue?vue&type=script&lang=js&"
import style0 from "./InsuranceOffer.vue?vue&type=style&index=0&id=5b4ac7c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b4ac7c8",
  null
  
)

export default component.exports