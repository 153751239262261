<template>
    <div
        v-if="description"
        class="description"
    >
        <p class="d-inline p-textarea">{{ descriptionComputed }}</p>
        <ButtonText
            v-if="isDescriptionLong"
            dashed
            secondary
            dark
            @click="showFullDescriptionPopup"
        >
            Читать далее
        </ButtonText>
    </div>
</template>

<script>
import ButtonText from '@/components/_buttons/ButtonText.vue';
const DescriptionPopup = () => import('@/components/popups/DescriptionPopup.vue');

export default {
    name: 'DescriptionWithPopup',

    components: { ButtonText },

    props: {
        description: {
            type: String,
            default: '',
        },

        popupTitle: {
            type: String,
            default: '',
        },
    },

    computed: {
        descriptionComputed() {
            return this.description.length > 200
                ? this.description.slice(0, 200) + ' ...'
                : this.description;
        },

        isDescriptionLong() {
            return this.description.length > 200;
        },
    },

    methods: {
        showFullDescriptionPopup() {
            const props = {
                title: this.popupTitle,
                text: this.description,
            };
            const options = { props };
            this.$popup(DescriptionPopup, options);
        },
    },
};
</script>