export default ({ HTTP }) => ({
    async get(...args) {
        let config;
        let id;

        if (args && args.length) {
            if (typeof args[0] === 'string' || typeof args[0] === 'number') {
                id = args[0];
                config = args[1];
            }
            else {
                config = args[0];
            }
        }

        if (!config) config = {};

        if (id) {
            const url = `/cars/${ id }/`;
            const response = await HTTP.get(url, config);
            return response.data;
        }
        else {
            const url = '/cars/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async grzSearch(data, config) {
        const url = '/cars/grz_search/';
        const response = await HTTP.post(url, data, config);
        return response.data;
    },

    async vinSearch(data, config) {
        const url = '/cars/vin_search/';
        const response = await HTTP.post(url, data, config);
        return response.data;
    },
});