<template>
    <div class="profile__main">
        <EmptyBlock
            v-if="current_count === 0"
            icon="heart-btn-stroke"
            title="Нет избранных магазинов"
            :hasBtn="false"
        >
            <template slot="text">
                <p class="profile-empty-block__text">
                    Добавляйте магазины в избранное с помощью <span class="like-btn-wrap"><LikeBtn></LikeBtn></span>
                </p>
            </template>
        </EmptyBlock>

        <template v-else>
            <ol class="items-grid">
                <li
                    v-for="(item, index) in shops"
                    :key="'shop-' + item.id"
                    class="item layer-1"
                >
                    <ShopLogo
                        :shop="item"
                        target="_self"
                        coverCodename="medium"
                        class="item__logo-wrap"
                    ></ShopLogo>

                    <ShopName
                        :shop="item"
                        target="_self"
                    ></ShopName>

                    <ShopAddress
                        :shop="item"
                        :showOnMap="false"
                        class="item__shop-address"
                    ></ShopAddress>

                    <MarketItemRatingReviewsLink
                        :item="item"
                        modelName="shop"
                        class="mt-auto"
                    ></MarketItemRatingReviewsLink>

                    <LikeBtn
                        :id="item.id"
                        apiName="shop"
                        :value="true"
                        class="item__like-btn"
                        @changeFavorite="onChangeFavorite(index)"
                    ></LikeBtn>
                </li>
            </ol>

            <ButtonText
                v-if="hasNext"
                class="load-more-btn"
                dashed
                :loading="loading"
                @click="load"
            >
                Показать ещё
            </ButtonText>
        </template>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { mapActions, mapMutations, mapState } from 'vuex';
import EmptyBlock from '@/components/EmptyBlock.vue';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ShopLogo from '@/components/ShopLogo.vue';
import ShopName from '@/components/ShopName.vue';
import ShopAddress from '@/components/ShopAddress.vue';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';

export default {
    name: 'ProfileFavoritesShops',

    metaInfo () {
        return {
            title: 'ЛК / Мои избранные магазины',
        };
    },

    components: {
        MarketItemRatingReviewsLink,
        ShopAddress,
        ShopName,
        ShopLogo,
        ButtonText,
        LikeBtn,
        EmptyBlock,
    },

    data() {
        return {
            rangeData: {
                limit: 30,
                offset: 0,
                order_by: '-create_date',
            },
            source: null,
            loadingTimeout: null,
            loading: false,
        };
    },

    computed: {
        ...mapState({
            shops: state => state.profileFavorites.shops.items,
            current_count: state => state.profileFavorites.shops.current_count,
        }),

        hasNext() {
            return (this.rangeData.offset + this.rangeData.limit) < this.current_count;
        },
    },

    mounted() {
        this.$breadcrumbs([]);
    },

    methods: {
        ...mapMutations({
            ADD_ITEMS: 'profileFavorites/addShops',
            SET_LOADING: 'profileFavorites/setLoading',
        }),

        ...mapActions({
            GET_ITEMS: 'profileFavorites/getShops',
        }),

        onChangeFavorite(index) {
            this.$store.commit('profileFavorites/remove', { index, type: 'shops' });
        },

        async fetch() {
            this.loadingTimeout = setTimeout(() => {
                this.loading = true;
            }, 300);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                params: this.rangeData,
                cancelToken: this.source.token,
            };

            const response = await this.GET_ITEMS(payload);
            if (response) {
                this.source = null;
                clearTimeout(this.loadingTimeout);
                return response;
            }
            this.loading = false;
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) this.ADD_ITEMS(response);
        },
    },
};
</script>

<style scoped src="../../../assets/css/profile.css"></style>
<style scoped>
@media (min-width: 1281px) {
    .items-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .items-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .items-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .items-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 480px) {
    .items-grid {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

.item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
@media (min-width: 768px) {
     .item {
        padding: 20px;
    }
}
@media (max-width: 767px) {
     .item {
        padding: 16px;
    }
}
@media (max-width: 479px) {
    .item:not(:last-child) {
        margin-bottom: var(--grid-gap);
    }
}

.item__logo-wrap {
    display: inline-block;
}
@media (min-width: 768px) {
    .item__logo-wrap {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .item__logo-wrap {
        margin-bottom: 16px;
    }
}

.item__like-btn {
    position: absolute;
}
@media (min-width: 768px) {
    .item__like-btn {
        top: 20px;
        right: 20px;
    }
}
@media (max-width: 767px) {
    .item__like-btn {
        top: 16px;
        right: 16px;
    }
}

.item__shop-address {
    margin-bottom: 16px;
}
@media (min-width: 768px) {
    .item__shop-address {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .item__shop-address {
        margin-top: 4px;
    }
}
</style>