<template>
    <div class="top-gap_medium">
        <h1 class="h1 h1_block">
            {{ partName.page_title }}
        </h1>

        <template v-if="hasCar">
            <div
                v-if="showCharacteristicsBar"
                class="characteristics-bar hidden-s layer-1"
            >
                <div
                    v-for="c in characteristicsWithOptions"
                    :key="c.codename"
                    class="characteristics-bar__item"
                >
                    <RadioButtonsGroup
                        v-model="characteristicsWithOptionValues[c.codename]"
                        :name="c.codename"
                        mobileAtomic
                        :options="c.options"
                        @change="onCharacteristicsChange"
                    ></RadioButtonsGroup>
                </div>
            </div>

            <div
                v-if="showCharacteristicsBar"
                class="characteristics-bar_mobile hidden-m hidden-l hidden-xl layer-1"
            >
                <USelect
                    v-for="c in characteristicsWithOptions"
                    :key="'select-' + c.codename"
                    :value="characteristicsWithOptionsValuesForSelect[c.codename]"
                    :options="c.options"
                    optionKey="id"
                    optionLabel="name"
                    :searchable="false"
                    class="characteristics-bar__item_mobile"
                    @select="onSelectCharacteristic({ ...$event, codename: c.codename })"
                ></USelect>
            </div>

            <Spinner
                v-if="!initializedData"
                size="big"
                center
            ></Spinner>

            <template v-else>
                <template v-if="totalDefaultCount">
                    <MarketItemListUpper
                        v-if="initializedFilter"
                        class="mb-0"
                        :checkedFilters="checkedFilters"
                        @resetFilters="onResetFilters"
                        @deleteFilter="onDeleteFilter"
                    >
                        <template #sort>
                            <SimpleSelectBase
                                :value="sortData"
                                :options="sortOptions"
                                @change="onChangeSort"
                            ></SimpleSelectBase>
                        </template>

                        <template #view>
                            <RadioButtonsGroup
                                v-model="view"
                                name="catalog-view"
                                type="icon"
                                :options="[
                                    {
                                        label: '',
                                        value: 'list',
                                        icon: 'list-toggler'
                                    },
                                    {
                                        label: '',
                                        value: 'grid',
                                        icon: 'grid'
                                    },
                                ]"
                            ></RadioButtonsGroup>
                        </template>

                        <template #filterToggler>
                            <ButtonIcon
                                :icon="{
                                    name: 'filter-btn',
                                }"
                                hoverPrimary
                                class="filter-toggler-btn"
                                aria-label="Показать фильтр"
                                @click="openFilter"
                            ></ButtonIcon>
                        </template>
                    </MarketItemListUpper>

                    <MarketItemListContent
                        v-if="initializedData"
                        class="market-parts-search-new-items__content"
                        :class="{
                            'market-parts-search-new-items__content_empty': !defaultData.originalPartsCount || !totalCurrentCount,
                        }"
                    >
                        <template #main>
                            <!-- Вообще предложения есть, но по условиям фильтра нет -->
                            <section
                                v-if="!totalCurrentCount"
                                class="filter-empty-block"
                            >
                                <Spinner
                                    v-if="originalPartsLoading || analoguePartsLoading || analoguePartsIsPartLoading || analoguePartsIncludeLoading"
                                    center
                                    size="big"
                                ></Spinner>

                                <template v-else>
                                    <EmptyBlock
                                        v-if="checkedFilters.length"
                                        icon="filter"
                                        title="Нет подходящих предложений"
                                        text="Попробуйте изменить условия поиска."
                                        :hasBtn="false"
                                        hasResetFilters
                                        @resetFilters="onResetFilters"
                                    ></EmptyBlock>
                                    <EmptyBlock
                                        v-else
                                        icon="parts"
                                        title="Нет предложений"
                                        btnText="Перейти в каталог"
                                        :btnUrl="{
                                            name: 'market-parts-catalog',
                                        }"
                                    >
                                        <template #text>
                                            Для запчасти <b>«{{ partName.page_title }}»</b> нет актуальных предложений.
                                        </template>
                                    </EmptyBlock>
                                </template>
                            </section>

                            <template v-else>
                                <section
                                    v-if="defaultData.originalPartsCount && originalParts.current_count"
                                    class="result-section"
                                >
                                    <div class="title-wrap">
                                        <h2 class="h2">
                                            <span class="mr-8">Оригинальные запчасти</span><UTooltip maxWidth="240">
                                                Предложения магазинов по оригинальным запчастям,
                                                подходящим для вашего автомобиля.
                                            </UTooltip>
                                        </h2>
                                    </div>

                                    <div
                                        class="result-section__content"
                                        :class="{
                                            'result-section__content_loading': originalPartsLoading,
                                        }"
                                    >
                                        <div
                                            v-if="originalPartsLoading"
                                            class="result-section__spinner-wrap"
                                        >
                                            <Spinner
                                                center
                                                size="big"
                                                class="result-section__spinner"
                                            ></Spinner>
                                        </div>

                                        <ol
                                            v-if="originalParts.results.length"
                                            :class="'items-' + view"
                                        >
                                            <li
                                                v-for="(originalPartItem, originalPartItemIndex) in originalParts.results"
                                                :key="'item-' + originalPartItem.part_product_id"
                                                class="item"
                                                :class="{
                                                    'item_view-list': view === 'list',
                                                    'item_view-grid-list': view === 'grid',
                                                }"
                                            >
                                                <MarketItemRowListItem
                                                    v-if="view === 'list'"
                                                    :item="originalPartItem"
                                                    modelName="part_product"
                                                    withSchemeLink
                                                    reviewsInPopup
                                                    @changeFavorite="changeFavorite(originalPartItemIndex, ...arguments)"
                                                    @callShowOffersPopup="showOffersPopup({
                                                        item: originalPartItem,
                                                        itemIndex: originalPartItemIndex,
                                                        partType: 'original'
                                                    })"
                                                    @callShowPartSchemesPopup="showPartSchemes(originalPartItem)"
                                                ></MarketItemRowListItem>

                                                <MarketItemColumn
                                                    v-if="view === 'grid'"
                                                    :item="originalPartItem"
                                                    apiName="parts"
                                                    modelName="part_product"
                                                    withSchemeLink
                                                    reviewsInPopup
                                                    @changeFavorite="changeFavorite(originalPartItemIndex, ...arguments)"
                                                    @callShowOffersPopup="showOffersPopup({
                                                        item: originalPartItem,
                                                        itemIndex: originalPartItemIndex,
                                                        partType: 'original'
                                                    })"
                                                    @callShowPartSchemesPopup="showPartSchemes(originalPartItem)"
                                                ></MarketItemColumn>
                                            </li>
                                        </ol>
                                    </div>
                                </section>

                                <section
                                    v-if="defaultData.analoguePartsCount && analogueParts.current_count"
                                    class="result-section"
                                >
                                    <div class="title-wrap">
                                        <h2 class="h2">
                                            <span class="mr-8">Совместимые аналоги</span><UTooltip maxWidth="240">
                                                Предложения магазинов по запчастям
                                                от сторонних производителей (аналогам),
                                                подходящим для вашего автомобиля.
                                            </UTooltip>
                                        </h2>
                                    </div>

                                    <div
                                        class="result-section__content"
                                        :class="{
                                            'result-section__content_loading': analoguePartsLoading,
                                        }"
                                    >
                                        <div
                                            v-if="analoguePartsLoading"
                                            class="result-section__spinner-wrap"
                                        >
                                            <Spinner
                                                center
                                                size="big"
                                                class="result-section__spinner"
                                            ></Spinner>
                                        </div>

                                        <ol :class="'items-' + view">
                                            <li
                                                v-for="(analoguePart, analoguePartIndex) in analogueParts.results"
                                                :key="'item-' + analoguePart.part_product_id + analoguePartIndex + 'analogueParts'"
                                                class="item"
                                                :class="{
                                                    'item_view-list': view === 'list',
                                                    'item_view-grid-list': view === 'grid',
                                                }"
                                            >
                                                <MarketItemRowListItem
                                                    v-if="view === 'list'"
                                                    :item="analoguePart"
                                                    modelName="part_product"
                                                    withSchemeLink
                                                    reviewsInPopup
                                                    @changeFavorite="changeFavorite(analoguePartIndex, ...arguments, 'equal')"
                                                    @callShowOffersPopup="showOffersPopup({
                                                        item: analoguePart,
                                                        itemIndex: analoguePartIndex,
                                                        partType: 'equal'
                                                    })"
                                                    @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                                ></MarketItemRowListItem>

                                                <MarketItemColumn
                                                    v-if="view === 'grid'"
                                                    :item="analoguePart"
                                                    apiName="parts"
                                                    modelName="part_product"
                                                    withSchemeLink
                                                    reviewsInPopup
                                                    @changeFavorite="changeFavorite(analoguePartIndex, ...arguments, 'equal')"
                                                    @callShowOffersPopup="showOffersPopup({
                                                        item: analoguePart,
                                                        itemIndex: analoguePartIndex,
                                                        partType: 'equal'
                                                    })"
                                                    @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                                ></MarketItemColumn>
                                            </li>
                                        </ol>

                                        <ButtonText
                                            v-if="!!analogueParts.next"
                                            class="load-more-btn"
                                            dashed
                                            dark
                                            secondary
                                            :loading="analoguePartsAddLoading"
                                            center
                                            @click="load('equal')"
                                        >
                                            Показать ещё
                                        </ButtonText>
                                    </div>
                                </section>

                                <section
                                    v-if="defaultData.analoguePartsCount && analoguePartsIsPart.current_count"
                                    class="result-section"
                                >
                                    <div class="title-wrap">
                                        <h2 class="h2">
                                            <span class="mr-8">Входят в состав искомой запчасти</span><UTooltip
                                                maxWidth="240"
                                            >
                                                Предложения магазинов по запчастям
                                                от сторонних производителей (аналогам),
                                                подходящим для вашего автомобиля,
                                                которые входят в состав оригинальной запчасти.
                                            </UTooltip>
                                        </h2>
                                    </div>

                                    <div
                                        class="result-section__content"
                                        :class="{
                                            'result-section__content_loading': analoguePartsIsPartLoading,
                                        }"
                                    >
                                        <div
                                            v-if="analoguePartsIsPartLoading"
                                            class="result-section__spinner-wrap"
                                        >
                                            <Spinner
                                                center
                                                size="big"
                                                class="result-section__spinner"
                                            ></Spinner>
                                        </div>

                                        <ol :class="'items-' + view">
                                            <li
                                                v-for="(analoguePart, analoguePartIndex) in analoguePartsIsPart.results"
                                                :key="'item-' + analoguePart.part_product_id + analoguePartIndex + 'analoguePartsIsPart'"
                                                class="item"
                                                :class="{
                                                    'item_view-list': view === 'list',
                                                    'item_view-grid-list': view === 'grid',
                                                }"
                                            >
                                                <MarketItemRowListItem
                                                    v-if="view === 'list'"
                                                    :item="analoguePart"
                                                    modelName="part_product"
                                                    reviewsInPopup
                                                    @changeFavorite="changeFavorite(analoguePartIndex, ...arguments, 'is_part')"
                                                    @callShowOffersPopup="showOffersPopup({
                                                        item: analoguePart,
                                                        itemIndex: analoguePartIndex,
                                                        partType: 'is_part'
                                                    })"
                                                    @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                                ></MarketItemRowListItem>

                                                <MarketItemColumn
                                                    v-if="view === 'grid'"
                                                    :item="analoguePart"
                                                    apiName="parts"
                                                    modelName="part_product"
                                                    reviewsInPopup
                                                    @changeFavorite="changeFavorite(analoguePartIndex, ...arguments, 'is_part')"
                                                    @callShowOffersPopup="showOffersPopup({
                                                        item: analoguePart,
                                                        itemIndex: analoguePartIndex,
                                                        partType: 'is_part'
                                                    })"
                                                    @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                                ></MarketItemColumn>
                                            </li>
                                        </ol>

                                        <ButtonText
                                            v-if="!!analoguePartsIsPart.next"
                                            class="load-more-btn"
                                            dashed
                                            dark
                                            secondary
                                            :loading="analoguePartsIsPartAddLoading"
                                            center
                                            @click="load('is_part')"
                                        >
                                            Показать ещё
                                        </ButtonText>
                                    </div>
                                </section>

                                <section
                                    v-if="defaultData.analoguePartsCount && analoguePartsInclude.current_count"
                                    class="result-section"
                                >
                                    <div class="title-wrap">
                                        <h2 class="h2">
                                            <span class="mr-8">Включают искомую запчасть</span><UTooltip maxWidth="240">
                                                Предложения магазинов по запчастям
                                                от сторонних производителей (аналогам),
                                                подходящим для вашего автомобиля,
                                                которые включают в себя оригинальную запчасть.
                                            </UTooltip>
                                        </h2>
                                    </div>

                                    <div
                                        class="result-section__content"
                                        :class="{
                                            'result-section__content_loading': analoguePartsIncludeLoading,
                                        }"
                                    >
                                        <div
                                            v-if="analoguePartsIncludeLoading"
                                            class="result-section__spinner-wrap"
                                        >
                                            <Spinner
                                                center
                                                size="big"
                                                class="result-section__spinner"
                                            ></Spinner>
                                        </div>

                                        <ol :class="'items-' + view">
                                            <li
                                                v-for="(analoguePart, analoguePartIndex) in analoguePartsInclude.results"
                                                :key="'item-' + analoguePart.part_product_id + analoguePartIndex + 'analoguePartsInclude'"
                                                class="item"
                                                :class="{
                                                    'item_view-list': view === 'list',
                                                    'item_view-grid-list': view === 'grid',
                                                }"
                                            >
                                                <MarketItemRowListItem
                                                    v-if="view === 'list'"
                                                    :item="analoguePart"
                                                    modelName="part_product"
                                                    reviewsInPopup
                                                    @changeFavorite="changeFavorite(analoguePartIndex, ...arguments, 'include')"
                                                    @callShowOffersPopup="showOffersPopup({
                                                        item: analoguePart,
                                                        itemIndex: analoguePartIndex,
                                                        partType: 'include'
                                                    })"
                                                    @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                                ></MarketItemRowListItem>

                                                <MarketItemColumn
                                                    v-if="view === 'grid'"
                                                    :item="analoguePart"
                                                    apiName="parts"
                                                    modelName="part_product"
                                                    reviewsInPopup
                                                    @changeFavorite="changeFavorite(analoguePartIndex, ...arguments, 'include')"
                                                    @callShowOffersPopup="showOffersPopup({
                                                        item: analoguePart,
                                                        itemIndex: analoguePartIndex,
                                                        partType: 'include'
                                                    })"
                                                    @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                                ></MarketItemColumn>
                                            </li>
                                        </ol>

                                        <ButtonText
                                            v-if="!!analoguePartsInclude.next"
                                            class="load-more-btn"
                                            dashed
                                            dark
                                            secondary
                                            :loading="analoguePartsIncludeAddLoading"
                                            center
                                            @click="load('include')"
                                        >
                                            Показать ещё
                                        </ButtonText>
                                    </div>
                                </section>
                            </template>
                        </template>

                        <template #aside>
                            <FilterComponent
                                :value="filterData"
                                :filters="staticFilters"
                                @change="onChangeFilter"
                                @reset="onResetFilters"
                                @reload="reload"
                            ></FilterComponent>
                        </template>
                    </MarketItemListContent>
                </template>
                <template v-else>
                    <EmptyBlock
                        icon="parts"
                        title="Нет предложений"
                        btnText="Перейти в каталог"
                        :btnUrl="{
                            name: 'market',
                        }"
                    >
                        <template
                            v-if="showCharacteristicsBar"
                            #text
                        >
                            Измените расположение или выберите другую запчасть.
                        </template>
                        <template
                            v-else
                            #text
                        >
                            Выберите другую запчасть.
                        </template>
                    </EmptyBlock>
                </template>
            </template>
        </template>

        <EmptyBlock
            v-if="!hasCar"
            icon="car"
            title="Автомобиль не выбран"
            text="Выберите автомобиль, чтобы увидеть подходящие запчасти."
        >
            <template slot="button">
                <ButtonBlock
                    primary
                    low
                    class="empty__btn"
                    @click="showSelectCarPopup"
                >
                    Выбрать авто
                </ButtonBlock>
            </template>
        </EmptyBlock>
    </div>
</template>

<script>
import bus from '@/bus.js';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import marketPartName from '@/store/modules/marketPartName.js';
import { HTTP } from '@/http.js';
import isEmpty from '@/lib/isEmpty.js';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import MarketItemRowListItem from '@/components/MarketItemRowListItem.vue';
import Spinner from '@/components/Spinner.vue';
import MarketItemListUpper from '@/components/MarketItemListUpper.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import RadioButtonsGroup from '@/components/RadioButtonsGroup.vue';
import SimpleSelectBase from '@/components/SimpleSelectBase.vue';
import MarketItemColumn from '@/components/MarketItemColumn.vue';
import MarketItemListContent from '@/components/MarketItemListContent.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import USelect from '@ui/components/UInput/presets/USelect.vue';
import USimpleSelect from '@ui/components/UInput/presets/USimpleSelect.vue';

const OffersPopup = () => import('@/components/popups/OffersPopup.vue');
const ShortSchemePopup = () => import('@/components/popups/ShortSchemePopup.vue');
const SelectCarPopup = () => import('@/components/popups/SelectCarPopup.vue');


export default {
    name: 'PartNamePage',

    metaInfo() {
        return {
            title: this.partName.meta_title || this.partName.name,
            meta: [
                { name: 'description', content: this.partName.meta_description },
                { name: 'keywords', content: this.partName.meta_keywords },
                { property: 'og:title', content: this.partName.meta_title ? this.partName.meta_title : this.partName.name },
                { property: 'og:description', content: this.partName.meta_description },
                { property: 'og:image', content: this.partName.og_image ? this.$links.uploads + this.partName.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        return this.serverPrefetch(this.$route);
    },

    components: {
        USimpleSelect,
        USelect,
        UTooltip,
        ButtonBlock,
        ButtonText,
        ButtonIcon,
        MarketItemRowListItem,
        Spinner,
        MarketItemListUpper,
        FilterComponent,
        RadioButtonsGroup,
        SimpleSelectBase,
        MarketItemColumn,
        MarketItemListContent,
        EmptyBlock,
    },

    data() {
        return {
            analoguePartsRangeData: {
                limit: 36,
                offset: 0,
            },
            analoguePartsIsPartRangeData: {
                limit: 36,
                offset: 0,
            },
            analoguePartsIncludeRangeData: {
                limit: 36,
                offset: 0,
            },

            originalPartsSource: null,
            analoguePartsSource: null,
            analoguePartsIsPartSource: null,
            analoguePartsIncludeSource: null,

            analoguePartsAddLoading: false,
            analoguePartsIsPartAddLoading: false,
            analoguePartsIncludeAddLoading: false,

            view: 'list',
            characteristicsWithOptionValues: {},
        };
    },

    computed: {
        ...mapState({
            car: state => state.searchWidget.car,

            partName: state => state.marketPartName.partName,
            partNameCategory: state => state.marketPartName.partNameCategory,
            categoryCharacteristics: state => (state.marketPartName || {}).categoryCharacteristics,

            initialized: state => state.marketPartName.initialized,
            initializedData: state => state.marketPartName.initializedData,
            initializedFilter: state => state.marketPartName.initializedFilter,

            defaultData: state => state.marketPartName.defaultData,

            originalParts: state => state.marketPartName.originalParts,
            originalPartsLoading: state => state.marketPartName.originalPartsLoading,

            analogueParts: state => state.marketPartName.analogueParts,
            analoguePartsLoading: state => state.marketPartName.analoguePartsLoading,
            analoguePartsIsPart: state => state.marketPartName.analoguePartsIsPart,
            analoguePartsIsPartLoading: state => state.marketPartName.analoguePartsIsPartLoading,
            analoguePartsInclude: state => state.marketPartName.analoguePartsInclude,
            analoguePartsIncludeLoading: state => state.marketPartName.analoguePartsIncludeLoading,

            staticFilters: state => state.marketPartName.filter.staticFilters,
            checkedFilters: state => state.marketPartName.filter.checked,
            filterData: state => state.marketPartName.filter.value,
            sortOptions: state => state.marketPartName.sort.options,
            sortData: state => state.marketPartName.sort.value,
        }),

        ...mapGetters({
            totalDefaultCount: 'marketPartName/totalDefaultCount',
            totalCurrentCount: 'marketPartName/totalCurrentCount',
            filterQuery: 'marketPartName/filter/query',
            sortQuery: 'marketPartName/sort/query',
        }),

        hasCar() {
            return this.car && !!this.car.id;
        },

        characteristicsParamsObj() {
            const params = {};

            for (const [key, value] of Object.entries(this.characteristicsWithOptionValues)) {
                params[key] = value;
            }

            return params;
        },

        showCharacteristicsBar() {
            return this.characteristicsWithOptions && this.characteristicsWithOptions.length;
        },

        location() {
            const query = Object.assign(
                {},
                this.filterQuery,
                this.sortQuery,
            );

            return {
                name: this.$route.name,
                query,
                meta: {
                    savePosition: true,
                },
            };
        },

        fetchParams() {
            return Object.assign(
                {},
                this.filterQuery,
                this.sortQuery,
            );
        },

        characteristicsWithOptions() {
            if (this.categoryCharacteristics) {
                return this.categoryCharacteristics.filter(category => category.options && category.options.length > 1);
            }

            return [];
        },

        characteristicsOptions() {
            return this.characteristicsWithOptions.reduce((acc, characteristic) => {
                acc[characteristic.codename] = characteristic.options;
                return acc;
            }, {});
        },

        characteristicsWithOptionsValuesForSelect() {
            return Object.entries(this.characteristicsWithOptionValues).reduce((acc, entry) => {
                const [key, value] = entry;
                acc[key] = this.characteristicsOptions[key].find(option => option.id === value);
                return acc;
            }, {});
        },
    },

    watch: {
        car(value, oldValue) {
            if (value.id !== oldValue.id) {
                this.DESTROY();
                this.init();
            }
        },

        '$route.params.id'() {
            this.DESTROY();
            this.init();
        },

        characteristicsWithOptions: {
            handler() {
                this.setDefaultCharacteristicsWithOptionsValues();
            },

            immediate: true,
        },
    },

    created() {
        this.$store.registerModule('marketPartName', marketPartName);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('marketPartName');
    },

    methods: {
        ...mapMutations({
            setInitializedData: 'marketPartName/setInitializedData',
            setInitializedFilter: 'marketPartName/setInitializedFilter',
            DELETE_FILTER: 'marketPartName/filter/deleteFilter',
            RESET_FILTERS: 'marketPartName/filter/reset',
            SET_SORT_VALUE: 'marketPartName/sort/setValue',
            SET_LOADING: 'marketPartName/setLoading',
            ADD_PARTS: 'marketPartName/addParts',
            CHANGE_FAVORITE: 'marketPartName/changeFavorite',
            DESTROY: 'marketPartName/destroy',
        }),

        ...mapActions({
            serverPrefetch: 'marketPartName/serverPrefetch',
            browserPrefetch: 'marketPartName/browserPrefetch',

            fetchPartName: 'marketPartName/fetchPartName',
            fetchCategoryCharacteristics: 'marketPartName/fetchCategoryCharacteristics',
            initData: 'marketPartName/initData',
            initFilters: 'marketPartName/initFilters',
            fetchOriginalParts: 'marketPartName/fetchOriginalParts',
            fetchAnalogueParts: 'marketPartName/fetchAnalogueParts',
            getAnalogueParts: 'marketPartName/getAnalogueParts',

            CHANGE_FILTER: 'marketPartName/filter/change',
            UPDATE_STATIC_FILTERS: 'marketPartName/updateStaticFilters',
        }),

        async init() {
            if (!this.initialized) {
                await this.serverPrefetch(this.$route);
            }

            if (this.hasCar) {
                await this.browserPrefetch(this.$route.query);
                this.updateQuery();
            }

            this.updateBreadcrumbs();
        },

        setDefaultCharacteristicsWithOptionsValues() {
            const characteristics = this.characteristicsWithOptions;

            if (characteristics && characteristics.length) {
                this.characteristicsWithOptionValues = characteristics.reduce((acc, item) => {
                    acc[item.codename] = this.partName[item.codename].id;
                    return acc;
                }, {});
            }
            else {
                this.characteristicsWithOptionValues = {};
            }
        },

        updateBreadcrumbs() {
            const parents = this.partNameCategory.parents.reduce((acc, item) => {
                acc.push({
                    to: {
                        name: 'market-parts-catalog-category',
                        params: {
                            slug: item.slug,
                        },
                    },
                    title: item.name,
                });
                return acc;
            }, []);
            const breadcrumbs = [
                {
                    to: {
                        name: 'market',
                    },
                    title: 'Запчасти и автотовары',
                },
                {
                    to: {
                        name: 'market-parts-catalog',
                    },
                    title: 'Автозапчасти',
                },
                ...parents,
            ];

            if (this.showCharacteristicsBar) {
                breadcrumbs.push({
                    to: {
                        name: 'market-parts-catalog-category',
                        params: {
                            slug: this.partName.category.slug,
                        },
                    },
                    title: this.partName.category.name,
                });
            }

            breadcrumbs.push({
                to: {
                    name: 'market-parts-catalog-category',
                    params: {
                        slug: this.partName.category.slug,
                    },
                },
                title: this.partName.name,
            });

            this.$breadcrumbs(breadcrumbs);
        },

        onSelectCharacteristic({ value, codename }) {
            this.characteristicsWithOptionValues[codename] = value.id;
            this.onCharacteristicsChange();
        },

        async onCharacteristicsChange() {
            const config = {
                params: {
                    car_id: this.car.id,
                    category_id: this.partNameCategory.id,
                    get_facets: 'all',
                    ...this.characteristicsParamsObj,
                },
            };

            const categoryCharacteristics = await this.$api.partNameDoc.get(config);

            if (categoryCharacteristics.current_count) {
                this.setInitializedData(false);
                this.setInitializedFilter(false);
                await this.fetchPartName(categoryCharacteristics.results[0].part_name_id);
                this.$router.replace({
                    name: 'market-part-name',
                    params: {
                        slug: this.partName.category.slug,
                        id: categoryCharacteristics.results[0].part_name_id,
                    },
                    query: this.$route.query,
                });
                await this.initData(this.$route.query);
                await this.initFilters(this.$route.query);
                this.updateBreadcrumbs();
            }
        },

        async load(type) {
            let params = {};

            if (type === 'equal') {
                if (this.analoguePartsSource) this.analoguePartsSource.cancel();
                this.analoguePartsSource = HTTP.CancelToken.source();
                this.analoguePartsAddLoading = true;
                this.analoguePartsRangeData.offset += this.analoguePartsRangeData.limit;
                params = Object.assign(
                    {
                        relation_type: 'equal',
                        cancelToken: this.analoguePartsSource.token,
                    },
                    this.fetchParams,
                    this.analoguePartsRangeData,
                );
            }
            else if (type === 'is_part') {
                if (this.analoguePartsIsPartSource) this.analoguePartsIsPartSource.cancel();
                this.analoguePartsIsPartSource = HTTP.CancelToken.source();
                this.analoguePartsIsPartAddLoading = true;
                this.analoguePartsIsPartRangeData.offset += this.analoguePartsIsPartRangeData.limit;
                params = Object.assign(
                    {
                        relation_type: 'is_part',
                        cancelToken: this.analoguePartsIsPartSource.token,
                    },
                    this.fetchParams,
                    this.analoguePartsIsPartRangeData,
                );
            }
            else if (type === 'include') {
                if (this.analoguePartsIncludeSource) this.analoguePartsIncludeSource.cancel();
                this.analoguePartsIncludeSource = HTTP.CancelToken.source();
                this.analoguePartsIncludeAddLoading = true;
                this.analoguePartsIncludeRangeData.offset += this.analoguePartsIncludeRangeData.limit;
                params = Object.assign(
                    {
                        relation_type: 'include',
                        cancelToken: this.analoguePartsIncludeSource.token,
                    },
                    this.fetchParams,
                    this.analoguePartsIncludeRangeData,
                );
            }

            await this.getAnalogueParts({ query: params })
                .then(response => {
                    this.ADD_PARTS({ value: response, type });
                })
                .finally(() => {
                    this.analoguePartsAddLoading = false;
                    this.analoguePartsIsPartAddLoading = false;
                    this.analoguePartsIncludeAddLoading = false;
                });
        },

        async reload() {
            this.analoguePartsRangeData.offset = 0;
            this.analoguePartsIsPartRangeData.offset = 0;
            this.analoguePartsIncludeRangeData.offset = 0;

            if (this.originalPartsSource) this.originalPartsSource.cancel();
            this.originalPartsSource = HTTP.CancelToken.source();

            if (this.analoguePartsSource) this.analoguePartsSource.cancel();
            this.analoguePartsSource = HTTP.CancelToken.source();

            const [originalPartsFacets, analoguePartsFacets] = await Promise.all([
                this.fetchOriginalParts({
                    query: this.fetchParams,
                    cancelToken: this.originalPartsSource.token,
                }),
                this.fetchAnalogueParts({
                    query: { ...this.fetchParams, ...this.analoguePartsRangeData },
                    cancelToken: this.analoguePartsSource.token,
                }),
            ]);

            if (originalPartsFacets || analoguePartsFacets) {
                let newFacets = [originalPartsFacets.originalParts.facets];
                if (analoguePartsFacets) {
                    const { analogueParts, analoguePartsIsPart, analoguePartsInclude } = analoguePartsFacets;
                    newFacets = [...newFacets, analogueParts.facets || null, analoguePartsIsPart.facets || null, analoguePartsInclude.facets || null];
                }
                this.UPDATE_STATIC_FILTERS({
                    newFacets,
                    staticFilters: this.staticFilters,
                });
            }

            await this.updateQuery();
        },

        onChangeFilter(payload) {
            this.CHANGE_FILTER(payload);
        },

        onResetFilters() {
            this.RESET_FILTERS();
            this.reload();
        },

        onDeleteFilter(filter) {
            this.DELETE_FILTER(filter);
            this.reload();
        },

        onChangeSort(value) {
            this.SET_SORT_VALUE(value);
            this.reload();
        },

        async updateQuery() {
            const toPath = this.$router.resolve(this.location).route.fullPath;
            const isNewRoute = this.$route.fullPath !== toPath;

            if (isNewRoute) {
                try {
                    return await this.$router.push(this.location);
                }
                catch (error) {
                    console.error(error);
                }
            }
            else {
                return '';
            }
        },

        showOffersPopup({ item, itemIndex, partType }) {
            const props = {
                item,
                itemIndex,
                modelName: 'part_product',
                changeFavoriteCallback: value => {
                    this.changeFavorite(itemIndex, value, partType);
                },
            };
            const options = { props };
            this.$popup(OffersPopup, options);
        },

        changeFavorite(index, value, type) {
            this.CHANGE_FAVORITE({ index, value, type });
        },

        openFilter() {
            bus.$emit('openFilter');
        },

        showPartSchemes(item) {
            const props = {
                part: item,
                partId: item.part_product_id,
                car: this.car,
                carId: this.car.id,
                manufacturerCodename: this.car.manufacturer.codename,
            };
            const options = { props };
            this.$popup(ShortSchemePopup, options);
        },

        showSelectCarPopup() {
            const props = {
                modeOptions: [
                    {
                        label: 'По гос. номеру',
                        value: 'grz',
                    },
                    {
                        label: 'VIN/Frame',
                        value: 'vin_frame',
                    },
                    {
                        label: 'Из гаража',
                        value: 'garage',
                    },
                ],
            };
            const options = { props };
            this.$popup(SelectCarPopup, options);
        },
    },
};
</script>

<style scoped>
.characteristics-bar {
    display: flex;
    margin-bottom: 20px;
    padding: 20px;
}

.characteristics-bar__item:not(:last-child) {
    margin-right: 36px;
}

.characteristics-bar_mobile {
    margin-bottom: 20px;
    padding: 20px;
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
}

.characteristics-bar__item_mobile {
    min-width: 140px;
}

.market-parts-search-new-items__content {
    margin-top: calc(var(--gap-y-medium) + var(--h2-fz) * var(--mini-lh) + var(--indent-h2-block));
}
.market-parts-search-new-items__content_empty {
    margin-top: var(--y-grid-gap);
}

.result-section {
    margin-top: var(--gap-y-medium);
}
.result-section:first-child {
    margin-top: 0;
}
.result-section:first-child .title-wrap {
    margin-top: calc(-1 * var(--h2-fz) * var(--mini-lh) - var(--indent-h2-block));
}

.result-section__content {
    position: relative;
    min-height: 220px;
}

.result-section__content::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: var(--light-c);
    opacity: 0;
    transition: opacity var(--transition);
}

.result-section__content_loading::after {
    opacity: .5;
    z-index: 2;
}

.result-section__spinner-wrap {
    position: sticky;
    top: 0;
    height: 0;
    width: 100%;
    z-index: 3;
}

.result-section__spinner {
    position: relative;
    top: 92px;
}

.title-wrap {
    margin-bottom: var(--indent-h2-block);
}

.title__hint {
    margin-left: 4px;
    font-size: 0;
    vertical-align: -2px;
}

.title__hint-content {
    max-width: 240px;
}

.items-list .item:not(:last-child) {
    margin-bottom: var(--grid-gap);
}

.items-grid {
    width: 100%;
}
@media (min-width: 1281px) {
    .items-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .items-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .items-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) {
    .items-grid {
        display: grid;
        gap: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .items-grid .item:not(:last-child) {
        margin-bottom: var(--grid-gap);
    }
}
</style>