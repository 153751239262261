export default {
    namespaced: true,

    state: () => ({
        parts: {
            current_count: 0,
            items: [],
        },

        contractParts: {
            current_count: 0,
            items: [],
        },

        products: {
            current_count: 0,
            items: [],
        },

        brands: {
            current_count: 0,
            items: [],
        },

        shops: {
            current_count: 0,
            items: [],
        },

        initialized: false,
    }),

    getters: {
        priorityRoute: state => {
            if (state.products.current_count) {
                return 'account-favorites-products';
            }
            else if (state.parts.current_count) {
                return 'account-favorites-parts';
            }
            // else if (state.contractParts.current_count) {
            //     return 'account-favorites-contract-parts';
            // }
            else if (state.brands.current_count) {
                return 'account-favorites-brands';
            }
            else if (state.shops.current_count) {
                return 'account-favorites-shops';
            }
            else {
                return 'account-favorites-products';
            }
        },
    },

    mutations: {
        setParts(state, { results, current_count }) {
            state.parts.items = results;
            state.parts.current_count = current_count;
        },

        addParts(state, { results, current_count }) {
            state.parts.items = [...state.parts.items, ...results];
            state.parts.current_count = current_count;
        },

        setContractParts(state, { results, current_count }) {
            state.contractParts.items = results;
            state.contractParts.current_count = current_count;
        },

        addContractParts(state, { results, current_count }) {
            state.contractParts.items = [...state.contractParts.items, ...results];
            state.contractParts.current_count = current_count;
        },

        setProducts(state, { results, current_count }) {
            state.products.items = results;
            state.products.current_count = current_count;
        },

        addProducts(state, { results, current_count }) {
            state.products.items = [...state.products.items, ...results];
            state.products.current_count = current_count;
        },

        setBrands(state, { results, current_count }) {
            state.brands.items = results;
            state.brands.current_count = current_count;
        },

        addBrands(state, { results, current_count }) {
            state.brands.items = [...state.brands.items, ...results];
            state.brands.current_count = current_count;
        },

        setShops(state, { results, current_count }) {
            state.shops.items = results;
            state.shops.current_count = current_count;
        },

        addShops(state, { results, current_count }) {
            state.shops.items = [...state.shops.items, ...results];
            state.shops.current_count = current_count;
        },

        remove(state, { index, type }) {
            state[type].items.splice(index, 1);
            state[type].current_count = state[type].current_count - 1;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.initialized = false;

            state.parts = {
                items: [],
                current_count: 0,
            };
            state.contractParts = {
                items: [],
                current_count: 0,
            };
            state.products = {
                items: [],
                current_count: 0,
            };
            state.brands = {
                items: [],
                current_count: 0,
            };
            state.shops = {
                items: [],
                current_count: 0,
            };
        },
    },

    actions: {
        async init({ commit }) {
            try {
                const params = {
                    limit: 30,
                    offset: 0,
                    order_by: '-create_date',
                };

                const [
                    parts,
                    products,
                    brands,
                    shops,
                ] = await Promise.all([
                    this.$api.users.partsFavorites({ params }),
                    this.$api.users.productsFavorites({ params }),
                    this.$api.users.brandFavorites({ params }),
                    this.$api.users.shopsFavorites({ params }),
                ]);

                commit('setParts', parts);
                commit('setProducts', products);
                commit('setBrands', brands);
                commit('setShops', shops);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { parts, products, brands, shops };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getParts({ commit }, { params }) {
            try {
                return await this.$api.users.partsFavorites({ params });
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getContractParts({ commit }, { params }) {
            try {
                return await this.$api.users.contractPartsFavorites({ params });
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getProducts({ commit }, { params }) {
            try {
                return await this.$api.users.productsFavorites({ params });
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getBrands({ commit }, { params }) {
            try {
                return await this.$api.users.brandFavorites({ params });
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getShops({ commit }, { params }) {
            try {
                return await this.$api.users.shopsFavorites({ params });
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};