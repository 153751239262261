<template>
    <div
        v-if="show"
        class="selection-block"
    >
        <div
            v-if="carData.modification.value"
            class="selected-car layer-1"
        >
            <p class="h4 mb-8">
                {{ carData.manufacturer.value.name }} {{ carData.model.value.name }} {{ carData.year.value.name }}, {{ carData.modification.value.name }}
            </p>

            <ButtonText
                dashed
                secondary
                dark
                @click="showMobile = true"
            >Изменить</ButtonText>
        </div>

        <div
            v-if="!carData.modification.value && category === 'tire'"
            class="mobile-block mobile-block_tire layer-1"
            @click="showMobile = true"
        >
            <h2 class="h4 h4_block mobile-block__title">
                Подберите шины на&nbsp;свой автомобиль
            </h2>
            <ButtonBlock
                primary
                low
            >Подобрать</ButtonBlock>
        </div>

        <div
            v-if="!carData.modification.value && category === 'rim'"
            class="mobile-block mobile-block_rim layer-1"
            @click="showMobile = true"
        >
            <h2 class="h4 h4_block mobile-block__title">
                Подберите диски на&nbsp;свой автомобиль
            </h2>
            <ButtonBlock
                primary
                low
            >Подобрать</ButtonBlock>
        </div>

        <DesktopUI
            :category="category"
            :carData="desktopCarData"
            :paramsValues="computedValue"
            :paramsOptions="paramsOptions"
            class="hidden-s"
            @changeCar="onChangeCar"
            @changeParam="onChangeParam"
            @selectTemplateSize="onSelectTemplateSize"
        ></DesktopUI>

        <div class="hidden-m hidden-l hidden-xl">
            <MobileUI
                :category="category"
                :dataset="mobileData"
                :show="showMobile"
                :resultCurrentCount="resultCurrentCount"
                @changeCar="onChangeCar"
                @changeParam="onChangeParam"
                @close="closeMobile"
            ></MobileUI>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import equals from '@/lib/equals.js';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import MobileUI from '@/components/selection/MobileUI.vue';
import DesktopUI from '@/components/selection/DesktopUI.vue';
import { selectionComponentFields } from '@/settings.js';
import deepClone from '@/lib/deepClone.js';
import ButtonText from '@/components/_buttons/ButtonText.vue';

export default {
    name: 'SelectionInCategory',

    components: {
        ButtonText,
        DesktopUI,
        MobileUI,
        ButtonBlock,
    },

    props: {
        value: {
            type: Object,
            default() {
                return {};
            },
        },

        filters: {
            type: Array,
            default() {
                return [];
            },
        },

        flatFilters: {
            type: Object,
            default() {
                return {};
            },
        },

        resultCurrentCount: {
            type: Number,
            required: true,
        },

        resultLoading: Boolean,
    },

    data() {
        return {
            showMobile: false,

            carData: {
                manufacturer: {
                    value: null,
                    options: [],
                    loading: false,
                },

                model: {
                    value: null,
                    options: [],
                    loading: false,
                },

                year: {
                    value: null,
                    options: [],
                    loading: false,
                },

                modification: {
                    value: null,
                    options: [],
                    loading: false,
                },

                template_sizes: {
                    value: null,
                    options: [],
                    options_desktop: [],
                    options_mobile: [],
                    loading: false,
                    show: false,
                },
            },

            paramsOptions: {
                tire_width: [],
                tire_height: [],
                tire_diameter: [],
                tire_season: [],
                tire_spikes: [],
                rim_width: [],
                rim_diameter: [],
                rim_et: [],
                rim_pcd: [],
                rim_dia: [],
            },
        };
    },

    computed: {
        ...mapState({
            tireSelectionCategory: state => state.settings.tire_selection_category,
            rimSelectionCategory: state => state.settings.rim_selection_category,
        }),

        show() {
            return this.tireSelectionCategory.slug === this.$route.params.slug
                || this.rimSelectionCategory.slug === this.$route.params.slug;
        },

        category() {
            if (this.$route.params.slug === this.tireSelectionCategory.slug) return 'tire';
            else return 'rim';
        },

        computedValue() {
            const value = {};
            selectionComponentFields.forEach(codename => {
                const codenameValue = this.value[codename];
                if (codenameValue) {
                    if (codename === 'tire_season' || codename === 'tire_spikes') value[codename] = codenameValue;
                    else value[codename] = codenameValue.find(item => item.source && item.source === 'selection') || [];
                }
            });
            return value;
        },

        desktopCarData() {
            const carData = deepClone(this.carData);
            carData.template_sizes.options = carData.template_sizes.options_desktop;
            delete carData.template_sizes.options_desktop;
            delete carData.template_sizes.options_mobile;
            return carData;
        },

        mobileData() {
            const paramsData = {};
            selectionComponentFields.forEach(codename => {
                const codenameValue = this.value[codename];
                if (codenameValue) {
                    if (codename === 'tire_season' || codename === 'tire_spikes') {
                        paramsData[codename] = {
                            value: codenameValue || [],
                            options: this.paramsOptions[codename],
                        };
                    }
                    else {
                        const value = codenameValue.find(item => item.source && item.source === 'selection');
                        paramsData[codename] = {
                            value: value || null,
                            options: this.paramsOptions[codename],
                        };
                    }
                }
            });
            const carData = deepClone(this.carData);
            carData.template_sizes.options = carData.template_sizes.options_mobile;
            delete carData.template_sizes.options_desktop;
            delete carData.template_sizes.options_mobile;
            return Object.assign({}, carData, paramsData);
        },

        carQuery() {
            const query = {};
            if (this.carData.manufacturer.value) query.car_manufacturer = this.carData.manufacturer.value.slug;
            if (this.carData.model.value) query.car_model = this.carData.model.value.slug;
            if (this.carData.year.value) query.car_year = this.carData.year.value.slug.toString();
            if (this.carData.modification.value) query.car_modification = this.carData.modification.value.slug;
            return query;
        },

        location() {
            const currentQueryWithoutCar = Object.assign(
                {},
                this.$route.query,
            );

            delete currentQueryWithoutCar.car_manufacturer;
            delete currentQueryWithoutCar.car_model;
            delete currentQueryWithoutCar.car_year;
            delete currentQueryWithoutCar.car_modification;

            return {
                name: this.$route.name,
                query: Object.assign(
                    {},
                    currentQueryWithoutCar,
                    this.carQuery,
                ),
                meta: {
                    savePosition: true,
                },
            };
        },
    },

    async mounted() {
        if (this.filters.length) {
            this.filters.forEach(filter => {
                this.paramsOptions[filter.codename] = filter.options;
            });

            this.fillUp();
        }
        await this.getManufacturers();
        await this.parseQuery();
    },

    methods: {
        closeMobile() {
            this.showMobile = false;
            this.carData.model.loading = false;
            this.carData.year.loading = false;
            this.carData.modification.loading = false;
            this.carData.template_sizes.loading = false;
        },

        onChangeParam({ value, codename }) {
            const filter = this.flatFilters[codename];
            if (filter) {
                const oldValue = this.value[codename];

                let newValue = deepClone(this.value[codename]);
                const isChecked = oldValue.findIndex(v => v.id === value.id || (v.source && v.source === 'selection'));
                if (isChecked > -1) {
                    newValue.splice(isChecked, 1);
                }

                if (Array.isArray(value)) {
                    newValue = value.length ? value : [];
                }
                else {
                    if (value.id) {
                        newValue.push(Object.assign({ source: 'selection' }, value));
                    }
                    else {
                        newValue = {};
                    }
                }

                const data = Object.assign({}, this.value, { [codename]: newValue });
                const payload = { filter, newValue, data, oldValue };

                if (!equals(oldValue, newValue)) {
                    this.$emit('change', payload);
                    this.$emit('reload');
                }
            }
        },

        onSelectTemplateSize({ template, type }) {
            this.carData.template_sizes.value = template;
            if (template) {
                let currentCategoryFilters;
                const filtersMap = {
                    'tire_width': 'tire_width',
                    'tire_height': 'tire_aspect_ratio',
                    'tire_diameter': 'rim_diameter',
                    'rim_width': 'rim_width',
                    'rim_diameter': 'rim_diameter',
                    'rim_et': 'rim_offset',
                };
                if (type === 'tire') {
                    currentCategoryFilters = ['tire_width', 'tire_height', 'tire_diameter'];
                }
                else if (type === 'rim') {
                    currentCategoryFilters = ['rim_width', 'rim_diameter', 'rim_et'];
                }

                const newData = currentCategoryFilters.reduce((acc, filter) => {
                    const option = this.flatFilters[filter].options.find(item => item.name == template[filtersMap[filter]]);
                    if (option) {
                        acc[filter] = [Object.assign({ source: 'selection' }, option)];
                    }
                    return acc;
                }, {});

                currentCategoryFilters.forEach(filter => {
                    const value = this.flatFilters[filter].options.find(item => item.name == template[filtersMap[filter]]);
                    if (value) {
                        let newValue = [];
                        if (Array.isArray(value)) {
                            newValue = value;
                        }
                        else if (value.id) {
                            newValue.push(Object.assign({ source: 'selection' }, value));
                        }

                        const oldValue = this.value[filter];
                        const data = Object.assign({}, this.value, newData);
                        const payload = { 'filter': this.flatFilters[filter], newValue, data, oldValue, replace: true };
                        this.$emit('change', payload);
                    }
                });

                this.$emit('reload');
                this.$emit('selectSizeTemplate', template);
            }
        },

        fillUp() {
            const filledValue = {};

            for (let filter of this.filters) {
                if (filter.widget === 'select' || filter.widget === 'checkboxes') {
                    const codename = filter.codename;
                    const currentValue = this.value[codename];
                    if (currentValue.id && !currentValue.name) {
                        filledValue[codename] = this.paramsOptions[codename].filter(v => v.id === currentValue.id)[0];
                    }
                }
            }

            this.$emit('change', { data: Object.assign({}, this.value, filledValue), filter: {} });
        },

        async parseQuery() {
            const carManufacturerQueryValue = this.$route.query.car_manufacturer;
            if (carManufacturerQueryValue) {
                const option = this.carData.manufacturer.options.find(item => item.slug === carManufacturerQueryValue);
                if (option) {
                    await this.handleCarManufacturerSelect(option);
                }
            }

            const carModelQueryValue = this.$route.query.car_model;
            if (carModelQueryValue) {
                const option = this.carData.model.options.find(item => item.slug === carModelQueryValue);
                if (option) {
                    await this.handleCarModelSelect(option);
                }
            }

            const carYearQueryValue = this.$route.query.car_year;
            if (carYearQueryValue) {
                const option = this.carData.year.options.find(item => item.slug === Number(carYearQueryValue));
                if (option) {
                    await this.handleCarYearSelect(option);
                }
            }

            const carModificationQueryValue = this.$route.query.car_modification;
            if (carModificationQueryValue) {
                const option = this.carData.modification.options.find(item => item.slug === carModificationQueryValue);
                if (option) {
                    await this.handleCarModificationSelect(option);
                }
            }
        },

        async onChangeCar({ value, codename }) {
            if (codename === 'template_sizes') {
                await this.onSelectTemplateSize({ template: value, type: this.category });
            }
            else {
                if (codename === 'manufacturer') await this.handleCarManufacturerSelect(value);
                if (codename === 'model') await this.handleCarModelSelect(value);
                if (codename === 'year') await this.handleCarYearSelect(value);
                if (codename === 'modification') await this.handleCarModificationSelect(value);

                if (!equals(this.location.query, this.$route.query)) {
                    this.$router.replace(this.location);
                }
            }
        },

        async getManufacturers() {
            this.carData.manufacturer.loading = true;

            const params = {
                order_by: 'name',
            };

            const { results } = await this.$api.wheelsize.getManufacturers({ params });
            if (results) this.carData.manufacturer.options = results;

            this.carData.manufacturer.loading = false;
        },

        async handleCarManufacturerSelect(option) {
            this.carData.manufacturer.value = null;
            this.carData.model.value = null;
            this.carData.model.options = [];
            this.carData.model.loading = false;
            this.carData.year.value = null;
            this.carData.year.options = [];
            this.carData.year.loading = false;
            this.carData.modification.value = null;
            this.carData.modification.options = [];
            this.carData.modification.loading = false;
            this.carData.template_sizes.value = null;
            this.carData.template_sizes.options = [];
            this.carData.template_sizes.loading = false;
            this.carData.template_sizes.show = false;

            if (option && option.slug) {
                this.carData.manufacturer.value = option;
                this.carData.model.loading = true;

                const params = {
                    order_by: 'name',
                    manufacturer: option.slug,
                };

                const { results } = await this.$api.wheelsize.getModels({ params });
                if (results) this.carData.model.options = results;

                this.carData.model.loading = false;
            }
        },

        async handleCarModelSelect(option) {
            this.carData.model.value = null;
            this.carData.year.value = null;
            this.carData.year.options = [];
            this.carData.modification.value = null;
            this.carData.modification.options = [];
            this.carData.template_sizes.value = null;
            this.carData.template_sizes.options = [];
            this.carData.template_sizes.loading = false;
            this.carData.template_sizes.show = false;

            if (option && option.slug) {
                this.carData.model.value = option;
                this.carData.year.loading = true;

                const params = {
                    order_by: '-year',
                    manufacturer: this.carData.manufacturer.value.slug,
                    model: option.slug,
                };

                const { results } = await this.$api.wheelsize.getYears({ params });
                if (results) {
                    this.carData.year.options = results.map(item => {
                        return { 'name': item.year, 'slug': item.year };
                    });
                }

                this.carData.year.loading = false;
            }
        },

        async handleCarYearSelect(option) {
            this.carData.year.value = null;
            this.carData.modification.value = null;
            this.carData.modification.options = [];
            this.carData.template_sizes.value = null;
            this.carData.template_sizes.options = [];
            this.carData.template_sizes.loading = false;
            this.carData.template_sizes.show = false;

            if (option && option.slug) {
                this.carData.year.value = option;
                this.carData.modification.loading = true;

                const params = {
                    order_by: 'name,engine__power,generation__start',
                    manufacturer: this.carData.manufacturer.value.slug,
                    model: this.carData.model.value.slug,
                    year: option.slug,
                };

                const { results } = await this.$api.wheelsize.getModifications({ params });
                if (results) {
                    const resultsMap = {};
                    results.forEach((item, index) => {
                        if (resultsMap[item.name]) resultsMap[item.name].push(index);
                        else resultsMap[item.name] = [index];
                    });

                    this.carData.modification.options = results.map(item => {
                        let name = item.name;
                        if (item.engine.power) name = name + ', ' + item.engine.power + ' л.с.';
                        if (item.generation.start) {
                            name = name + ', ' + item.generation.start;
                            name = name + (item.generation.end ? ' – ' + item.generation.end : 'н.в.');
                        }
                        item.name = name;
                        return item;
                    });
                }

                this.carData.modification.loading = false;
            }
        },

        async handleCarModificationSelect(option) {
            this.carData.modification.value = null;
            this.carData.template_sizes.value = null;
            this.carData.template_sizes.options = [];

            if (option && option.slug) {
                this.carData.modification.value = option;
                this.carData.template_sizes.loading = true;
                this.carData.template_sizes.show = true;

                const params = {
                    order_by: 'name',
                    manufacturer: this.carData.manufacturer.value.slug,
                    model: this.carData.model.value.slug,
                    year: this.carData.year.value.slug,
                    modification: option.slug,
                };

                const { results } = await this.$api.wheelsize.getWheels({ params });
                if (results) {
                    const templateSizesArr = results.reduce((acc, item) => {
                        const codename = (item.front[this.category]) + (item.rear ? item.rear[this.category] : '');
                        if (!acc[codename]) {
                            acc[codename] = {};
                            if (item.front) {
                                item.front.name = item.front[this.category];
                                acc[codename].front = item.front;
                                acc[codename].front.is_front = true;
                                acc[codename].front.has_pair = item.rear !== null;
                            }
                            if (item.rear) {
                                item.rear.name = item.rear[this.category];
                                acc[codename].rear = item.rear;
                                acc[codename].rear.is_rear = true;
                                acc[codename].rear.has_pair = true;
                            }
                            acc[codename].has_pair = item.rear !== null;
                            acc[codename].is_stock = item.is_stock;
                        }
                        return acc;
                    }, {});
                    this.carData.template_sizes.options_mobile = Object.values(templateSizesArr);

                    const templateSizesObj = results.reduce((acc, item) => {
                        const codename = (item.front[this.category]) + (item.rear ? item.rear[this.category] : '');
                        if (!acc[codename]) {
                            if (item.front) {
                                item.front.name = item.front[this.category];
                            }
                            if (item.rear) {
                                item.rear.name = item.rear[this.category];
                            }
                            acc[codename] = item;
                        }
                        return acc;
                    }, {});
                    this.carData.template_sizes.options_desktop = Object.values(templateSizesObj);
                }

                this.carData.template_sizes.loading = false;
            }
        },
    },
};
</script>

<style scoped>
.selection-block {
    margin-bottom: var(--y-grid-gap);
}

.mobile-block {
    position: relative;
    padding: var(--gap-micro);
    overflow: hidden;
}
@media (min-width: 768px) {
    .mobile-block {
        display: none;
    }
}
.mobile-block::after {
    content: "";
    position: absolute;
    width: 170px;
    height: 170px;
    top: 24px;
    right: -38px;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
}
.mobile-block_tire::after {
    background-image: url(../../assets/images/selection-tire-image.jpg);
}
.mobile-block_rim::after {
    background-image: url(../../assets/images/selection-rim-image.jpg);
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
    .mobile-block_tire::after {
        background-image: url(../../assets/images/selection-tire-image-x2.jpg);
    }
    .mobile-block_rim::after {
        background-image: url(../../assets/images/selection-rim-image-x2.jpg);
    }
}

.mobile-block__title {
    max-width: calc(100% - 108px);
}

.selected-car {
    margin-bottom: var(--y-grid-gap);
    padding: var(--gap-micro);
}
@media (min-width: 768px) {
    .selected-car {
        display: none;
    }
}
</style>