import { insurerCodenames, ownerCodenames } from '@/pages/insurance/osago/_uuid/useOwnerData.js';


const createWatchers = () => {
    return ownerCodenames.reduce((acc, codename, index) => {
        acc['formData.' + codename] = {
            handler(value) {
                if (this.formData.owner_is_insurer) {
                    this.formData[insurerCodenames[index]] = value;
                }
            },
        };

        return acc;
    }, {});
};

export default (options = { isPopup: false }) => {
    const isForm = !options.isPopup;

    return {
        'formData.owner_is_insurer': {
            handler(value) {
                if (value) {
                    for (let i = 0; i < insurerCodenames.length; i++) {
                        const insurerCodename = insurerCodenames[i];
                        const ownerCodename = ownerCodenames[i];
                        this.formData[insurerCodename] = this.formData[ownerCodename];
                    }
                }
                else {
                    for (let codename of insurerCodenames) {
                        const isNull =
                            codename === 'insurer_birth_date' ||
                            codename === 'insurer_doc_date' ||
                            codename === 'insurer_address_obj' ||
                            codename === 'insurer_address_apartment_number';
                        this.formData[codename] = isNull ? null : '';
                    }
                }

                if (isForm) {
                    this.patch(...insurerCodenames);
                }
            },
        },

        ...createWatchers(),

        'sms_code.value': {
            handler() {
                this.sms_code.invalid = false;
                this.sms_code.error = '';
                this.confirmCode();
            },
        },
    };
};