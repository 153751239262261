<template>
    <div class="about-page">
        <section class="page-header about-page__section bg-section">
            <div class="page-header__text-wrap">
                <h1 class="extra-h1 extra-h1_text">
                    Онлайн-сервис <br> <span class="text_red">для автомобилистов</span>
                </h1>
                <p class="page-header__description">
                    Мы&nbsp;собрали в&nbsp;одном месте все необходимые услуги и&nbsp;магазины, чтобы вы&nbsp;без усилий могли поддерживать свой автомобиль на&nbsp;ходу.
                </p>
            </div>

            <picture class="page-header__img-wrap">
                <source
                    srcset="../../assets/images/about-page/header-xl.jpg,
                            ../../assets/images/about-page/header-xl-x2.jpg 2x"
                    media="(min-width: 1040px)"
                    type="image/jpeg"
                >
                <source
                    srcset="../../assets/images/about-page/header-xs.jpg,
                            ../../assets/images/about-page/header-xs-x2.jpg 2x"
                    media="(max-width: 1039px)"
                    type="image/jpeg"
                >
                <img
                    src="../../assets/images/about-page/header-xl-x2.jpg"
                    alt=""
                    width="780"
                    height="632"
                    class="page-header__img"
                >
            </picture>

            <div class="page-header__future">
                <h3 class="future-features-title">
                    Скоро на Ubibi
                </h3>
                <ul class="future-features-list">
                    <li class="future-feature">
                        <UIcon
                            name="car-shine"
                            big
                            secondary
                            class="future-feature__icon"
                        ></UIcon>

                        <div class="future-feature__text-wrap">
                            <h4 class="future-feature__title">
                                Автосервисы и мойки
                            </h4>
                            <p class="future-feature__text">
                                Запись на удобное время
                            </p>
                        </div>
                    </li>
                    <li class="future-feature">
                        <UIcon
                            name="check-list"
                            big
                            secondary
                            class="future-feature__icon"
                        ></UIcon>

                        <div class="future-feature__text-wrap">
                            <h4 class="future-feature__title">
                                Проверка авто
                            </h4>
                            <p class="future-feature__text">
                                ДТП, ремонт, залоги и пр.
                            </p>
                        </div>
                    </li>
                    <li class="future-feature">
                        <UIcon
                            name="ruble-check"
                            big
                            secondary
                            class="future-feature__icon"
                        ></UIcon>

                        <div class="future-feature__text-wrap">
                            <h4 class="future-feature__title">
                                Штрафы ГИБДД
                            </h4>
                            <p class="future-feature__text">
                                Бесплатная проверка и оплата
                            </p>
                        </div>
                    </li>
                    <li class="future-feature">
                        <UIcon
                            name="tow-truck"
                            big
                            secondary
                            class="future-feature__icon"
                        ></UIcon>

                        <div class="future-feature__text-wrap">
                            <h4 class="future-feature__title">
                                Помощь на дороге
                            </h4>
                            <p class="future-feature__text">
                                Выручим в трудной ситуации
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>

        <section class="market about-page__section">
            <div class="market__head">
                <h2 class="extra-h2">
                    Покупка запчастей и&nbsp;автотоваров
                </h2>
                <ButtonBlock
                    primary
                    :to="{ name: 'market' }"
                    class="market__head-btn market__head-btn_desktop"
                    @click.native="handleYandexMetrikaGoal('main_buy_zapchast')"
                >
                    Найти запчасти
                </ButtonBlock>
            </div>

            <MarketProfitsList></MarketProfitsList>

            <ButtonBlock
                primary
                :to="{ name: 'market' }"
                class="market__head-btn market__head-btn_mobile"
                @click.native="handleYandexMetrikaGoal('main_buy_zapchast')"
            >
                Найти запчасти
            </ButtonBlock>
        </section>

        <section class="insurance about-page__section bg-section">
            <div class="insurance-head">
                <img
                    srcset="../../assets/images/about-page/insurance-head.jpg,
                                ../../assets/images/about-page/insurance-head-x2.jpg 2x"
                    src="../../assets/images/about-page/insurance-head-x2.jpg"
                    alt=""
                    class="insurance-head__img"
                    width="440"
                    height="368"
                >
                <div class="insurance-head__text-wrap">
                    <h2 class="h1 h1_text">
                        Оформление ОСАГО онлайн
                    </h2>
                    <p class="insurance__description">
                        Чтобы оформить полис, заполните заявку и&nbsp;выберите с&nbsp;какой страховой компанией хотите&nbsp;работать.
                    </p>
                    <div class="insurance__actions-wrap">
                        <ButtonBlock
                            primary
                            class="insurance__action-btn"
                            @click="createOsago"
                        >
                            Оформить полис
                        </ButtonBlock>
                        <ButtonText
                            underline
                            secondary
                            dark
                            :to="{
                                name: 'insurance-osago'
                            }"
                            class="insurance__action-link"
                            @click.native="handleYandexMetrikaGoal('main_osago_details')"
                        >
                            Подробнее
                        </ButtonText>
                    </div>
                </div>
            </div>

            <InsuranceProfitsList></InsuranceProfitsList>
        </section>

        <section class="profile about-page__section">
            <div class="profile__head">
                <div class="profile__text-wrap">
                    <h2 class="h1 h1_text">
                        Функциональный личный кабинет
                    </h2>
                    <p class="profile__description">
                        Данные о&nbsp;ваших автомобилях и&nbsp;страховках, история заказов, заявок и&nbsp;отзывов, избранные товары, а&nbsp;также записи в&nbsp;сервис и&nbsp;на&nbsp;мойку.
                    </p>
                </div>

                <div class="profile__actions-wrap">
                    <ButtonBlock
                        v-if="isAuthorized"
                        :to="{ name: 'account-settings' }"
                        primary
                        class="profile__action-btn"
                        @click.native="handleYandexMetrikaGoal('main_private_room')"
                    >
                        Перейти в личный кабинет
                    </ButtonBlock>

                    <UButtonBlock
                        v-else
                        primary
                        class="profile__action-btn"
                        @click="openSignInPopup"
                    >
                        Войти
                    </UButtonBlock>
                </div>
            </div>

            <div class="profile__screenshot-wrap">
                <picture class="profile__screenshot">
                    <source
                        srcset="../../assets/images/about-page/profile-desktop.jpg,
                                ../../assets/images/about-page/profile-desktop-x2.jpg 2x"
                        media="(min-width: 768px)"
                        type="image/jpeg"
                    >
                    <source
                        srcset="../../assets/images/about-page/profile-mobile.jpg,
                                ../../assets/images/about-page/profile-mobile-x2.jpg 2x"
                        media="(max-width: 767px)"
                        type="image/jpeg"
                    >
                    <img
                        src="../../assets/images/about-page/profile-desktop-x2.jpg"
                        alt=""
                    >
                </picture>
            </div>
        </section>

        <section class="slogan about-page__section">
            <div class="slogan-text-wrap">
                <p class="slogan__description">
                    Надеемся, что вместе с&nbsp;Ubibi обслуживание вашего автомобиля станет проще и&nbsp;приятнее!
                </p>
            </div>
        </section>

        <section class="about-page__section">
            <h2 class="h1 h1_block text_center">
                Скоро на Ubibi
            </h2>

            <ul class="future-services-list">
                <li class="future-service layer-1">
                    <div class="future-service__text-wrap">
                        <UIcon
                            name="car-shine"
                            primary
                            class="future-service__icon"
                        ></UIcon>
                        <h3 class="h2 h2_text future-service__title">
                            Автосервисы и&nbsp;мойки
                        </h3>
                        <p class="future-service__text">
                            Выбор подходящего сервиса и&nbsp;запись на&nbsp;удобное время к&nbsp;своему мастеру. Единая сервисная книжка для вашего авто.
                        </p>
                    </div>

                    <picture class="future-service__img-wrap">
                        <source
                            srcset="../../assets/images/about-page/car-service-big.jpg,
                                    ../../assets/images/about-page/car-service-big-x2.jpg 2x"
                            media="(min-width: 1040px)"
                            type="image/jpeg"
                        >
                        <source
                            srcset="../../assets/images/about-page/car-service-small.jpg,
                                    ../../assets/images/about-page/car-service-small-x2.jpg 2x"
                            media="(max-width: 1039px)"
                            type="image/jpeg"
                        >
                        <img
                            src="../../assets/images/about-page/car-service-big-x2.jpg"
                            alt=""
                            width="400"
                            height="200"
                            class="future-service__img"
                        >
                    </picture>
                </li>
                <li class="future-service layer-1">
                    <UIcon
                        name="ruble-check"
                        primary
                        class="future-service__icon"
                    ></UIcon>
                    <h3 class="h2 h2_text future-service__title">
                        Штрафы ГИБДД
                    </h3>
                    <p class="future-service__text">
                        Бесплатная проверка и&nbsp;оплата со&nbsp;скидкой штрафов, выписанных за&nbsp;превышение скорости, неправильную парковку и&nbsp;другие нарушения.
                    </p>
                </li>
                <li class="future-service layer-1">
                    <UIcon
                        name="check-list"
                        primary
                        class="future-service__icon"
                    ></UIcon>
                    <h3 class="h2 h2_text future-service__title">
                        Проверка авто
                    </h3>
                    <p class="future-service__text">
                        ДТП, ремонт, залоги, ограничения, использование автомобиля в&nbsp;такси и&nbsp;другие данные из&nbsp;официальных источников.
                    </p>
                </li>
                <li class="future-service layer-1">
                    <UIcon
                        name="tow-truck"
                        primary
                        class="future-service__icon"
                    ></UIcon>
                    <h3 class="h2 h2_text future-service__title">
                        Помощь на&nbsp;дороге
                    </h3>
                    <p class="future-service__text">
                        Вызов эвакуатора, зарядка АКБ, отогрев авто, вскрытие замков, вызов аварийного комиссара и&nbsp;др.
                    </p>
                </li>
            </ul>
        </section>

        <section class="app-block layer-1">
            <div class="app-block-content">
                <h2 class="h2 h2_text app-block__title">
                    Все сервисы Ubibi в&nbsp;мобильном приложении
                </h2>
                <p class="app-block__description">
                    Помощник в&nbsp;обслуживании авто, который всегда под рукой. Геолокация позволит находить поблизости магазины, автосервисы и&nbsp;мойки, уведомления&nbsp;&mdash; не&nbsp;пропустить запись, а&nbsp;поддержка Apple Pay&nbsp;/ Google Pay&nbsp;&mdash; легко и&nbsp;безопасно оплачивать покупки и&nbsp;услуги.
                </p>

                <div class="subscribe-app-block">
                    <h3 class="subscribe-app-block__title">
                        Пришлем ссылку, когда приложение будет готово!
                    </h3>
                    <FormManager
                        ref="subscribeForm"
                        v-model="subscribeFormData"
                        class="subscribe-app-form"
                        @submit="subscribeFormSubmit"
                    >
                        <FormField
                            type="phone"
                            name="phone"
                            placeholder="Телефон"
                            visibleMask
                            validations="required"
                            class="subscribe-app-form__field"
                        ></FormField>

                        <ButtonBlock
                            submit
                            primary
                            :loading="subscribeFormLoading"
                            class="subscribe-app-form__btn"
                        >
                            Подписаться
                        </ButtonBlock>
                    </FormManager>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { mapMutations, mapState } from 'vuex';
import aboutPage from '@/store/modules/aboutPage.js';
import insuranceOsago from '@/store/modules/insuranceOsago.js';
import { serializeContract } from '@/store/modules/insuranceOsago.js';
import YandexMetrika from '@/mixins/yandex-metrika.js';
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InsuranceProfitsList from '@/components/InsuranceProfitsList.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import MarketProfitsList from '@/components/MarketProfitsList.vue';
import UButtonBlock from '@ui/components/UButton/UButtonBlock.vue';
const CreateRequestPartsOffersPopup = () => import('@/components/popups/CreateRequestPartsOffersPopup.vue');
const AuthorizationPopup = () => import('@/components/popups/AuthorizationPopup.vue');
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');

export default {
    name: 'AboutPage',

    metaInfo() {
        return {
            title: this.meta.meta_title || this.meta.name,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content: this.meta.meta_title ? this.meta.meta_title : this.meta.name },
                { property: 'og:description', content: this.meta.meta_description },
                { property: 'og:image', content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        UButtonBlock,
        MarketProfitsList,
        ButtonText,
        FormField,
        FormManager,
        InsuranceProfitsList,
        ButtonBlock,
        UIcon,
    },

    mixins: [
        YandexMetrika,
        handleFormErrorMixin,
    ],

    serverPrefetch() {
        return this.$store.dispatch('aboutPage/init');
    },

    data() {
        return {
            records: 'shops',
            subscribeFormData: {
                phone: '',
            },
            subscribeFormLoading: false,
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.aboutPage.initialized,
            meta: state => state.aboutPage.meta,
            isAuthorized: state => state.profile.isAuthorized,
            profile: state => state.profile.profile,
        }),
    },

    created() {
        this.$store.registerModule('aboutPage', aboutPage);
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago);
        }
    },

    async mounted() {
        this.$breadcrumbs([]);
        if (this.isAuthorized) {
            this.subscribeFormData.phone = this.profile.phone;
        }
        if (!this.initialized) {
            await this.$store.dispatch('aboutPage/init');
        }
    },

    beforeDestroy() {
        this.$store.unregisterModule('aboutPage');
    },

    methods: {
        ...mapMutations({
            updateContract: 'insuranceOsago/updateContract',
            setInitialized: 'insuranceOsago/setInitialized',
            setUuid: 'insuranceOsago/setUuid',
        }),

        showCreateRequestPopup() {
            this.handleYandexMetrikaGoal('main_create_zapros_zapchast');
            if (this.isAuthorized) this.$popup(CreateRequestPartsOffersPopup);
            else this.$popup(AuthorizationPopup);
        },

        async createOsago() {
            this.handleYandexMetrikaGoal('main_arrange_osago_down');

            const { data: { contract: uuid } } = await HTTP.post('/insurance_contract/new/');
            const { data: contract } = await HTTP.get(`/insurance_contract/${ uuid }/`);
            this.updateContract(serializeContract(contract));
            this.setUuid(uuid);
            this.setInitialized();

            await this.$router.push({
                name: 'insurance-osago-request-step-1',
                params: {
                    uuid,
                },
            });
        },

        async subscribeFormSubmit() {
            const errors = await this.$refs.subscribeForm.validate();

            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.subscribeFormLoading = true;

                await HTTP.post('/reminder/', this.subscribeFormData )
                    .then(() => {
                        this.$success('Телефон сохранен, мы оповестим вас, когда приложение будет готово');
                        this.subscribeFormData.phone = '';
                    })
                    .catch(error => {
                        this.$refs.subscribeForm.handle(error);
                        this.$store.commit('handleCommonHttpError', error);
                    })
                    .finally(() => {
                        this.subscribeFormLoading = false;
                    });
            }
        },

        openSignInPopup() {
            this.$popup(SignInPopup);
        },
    },
};
</script>

<style scoped>
.bg-section {
    margin-left: var(--content-gap-negative);
    margin-right: var(--content-gap-negative);
    padding: var(--gap-y-big) var(--content-gap);
    background-color: var(--lightest-bg);
}

.about-page__section {
    margin-bottom: var(--gap-y-big);
}

.page-header {
    position: relative;
    overflow: hidden;
    padding-top: var(--gap-y-max);
    padding-bottom: var(--gap-y-max);
}

.page-header__img-wrap {
    display: block;
}
@media (min-width: 1281px) {
    .page-header__img-wrap {
        width: 780px;
        height: 632px;
        margin-top: -220px;
        margin-bottom: -144px;
        margin-left: auto;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .page-header__img-wrap {
        width: 780px;
        height: 632px;
        margin-top: -228px;
        margin-left: calc(var(--col-5) + var(--grid-gap));
        margin-bottom: -130px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .page-header__img-wrap {
        width: 468px;
        height: 378px;
        margin-top: -60px;
        margin-right: -60px;
        margin-left: auto;
    }
}
@media (min-width: 768px) {
    .page-header__img-wrap {
        position: relative;
        z-index: 1;
    }
}
@media (max-width: 767px) {
    .page-header__img-wrap {
        width: 100%;
        margin-top: 20px;
    }
}

.page-header__img {
    max-height: 100%;
    max-width: initial;
    width: auto;
    height: auto;
    position: relative;
    z-index: 2;
}
@media (max-width: 767px) {
    .page-header__img {
        max-width: 100%;
    }
}

.page-header__text-wrap {
    position: relative;
    z-index: 2;
}

.page-header__description {
    font-size: var(--adaptive-big-fz);
    line-height: var(--base-lh);
}
@media (min-width: 1281px) {
    .page-header__description {
        width: 512px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .page-header__description {
        width: 480px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .page-header__description {
        width: 408px;
    }
}

.page-header__future {
    position: relative;
    z-index: 2;
}
@media (min-width: 1040px) {
    .page-header__future {
        width: 540px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .page-header__future {
        position: absolute;
        bottom: 64px;
    }
}
@media (max-width: 767px) {
    .page-header__future {
        display: none;
    }
}

.future-features-title {
    margin-bottom: 16px;
    font-size: var(--big-fz);
    font-family: var(--f-bold);
}

.future-features-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .future-features-list {
        grid-template-columns: 260px;
    }
}

.future-feature {
    display: flex;
    align-items: center;
    color: var(--font-secondary-color);
}

.future-feature__icon {
    margin-right: 20px;
}

.future-feature__title {
    margin-bottom: 4px;
    font-family: var(--f-bold);
}

.future-feature__text {
    font-size: var(--small-fz);
}

@media (min-width: 1281px) {
    .market {
        width: var(--col-18);
        margin-left: auto;
        margin-right: auto;
    }
}

.market__head {
    position: relative;
}
@media (min-width: 1040px) {
    .market__head {
        margin-bottom: 48px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .market__head {
        margin-bottom: 36px;
    }
}
@media (min-width: 768px) {
    .market__head {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}
@media (max-width: 767px) {
    .market__head {
        margin-bottom: 24px;
    }
}

@media (min-width: 768px) {
    .market__head-btn_mobile {
        display: none;
    }
}
@media (max-width: 767px) {
    .market__head-btn_desktop {
        display: none;
    }
    .market__head-btn {
        width: 100%;
        margin-top: 24px;
    }
}

.insurance {
    position: relative;
}

.insurance-head {
    margin-bottom: var(--gap-y-medium);
}
@media (max-width: 767px) {
    .insurance-head {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .insurance-head {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.insurance-head__img {
    flex-shrink: 0;
    height: auto;
}
@media (min-width: 1281px) {
    .insurance-head__img {
        width: var(--col-8);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .insurance-head__img {
        width: 440px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .insurance-head__img {
        width: 316px;
    }
}
@media (max-width: 767px) {
    .insurance-head__img {
        width: 100%;
        margin-bottom: 36px;
    }
}
@media (min-width: 414px) and (max-width: 767px) {
    .insurance-head__img {
        width: var(--col-4);
        margin-left: auto;
        margin-right: auto;
    }
}

.insurance-head__text-wrap {
    flex-shrink: 0;
}
@media (min-width: 1281px) {
    .insurance-head__text-wrap {
        width: var(--col-9);
        margin-left: auto;
        margin-right: calc(var(--col-1) + var(--grid-gap));
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .insurance-head__text-wrap {
        width: var(--col-6);
        margin-left: auto;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .insurance-head__text-wrap {
        width: 368px;
        margin-left: 24px;
    }
}

.insurance__description {
    font-size: var(--adaptive-big-fz);
    line-height: var(--base-lh);
}

.insurance__actions-wrap {
    margin-top: var(--gap-y-small);
}
@media (min-width: 768px) {
    .insurance__actions-wrap {
        display: flex;
        align-items: center;
    }

    .insurance__action-link {
        margin-left: var(--gap-y-small);
    }
}
@media (max-width: 767px) {
    .insurance__action-btn {
        width: 100%;
    }

    .insurance__action-link {
        display: block;
        width: max-content;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
    }
}

.slogan {
    margin-left: var(--content-gap-negative);
    margin-right: var(--content-gap-negative);
    padding: var(--gap-y-big) var(--content-gap);
    color: var(--light-c);
    text-align: center;
    background-color: var(--dark-c);
}

.slogan-text-wrap {
    position: relative;
}

@media (min-width: 1040px) {
    .slogan-text-wrap {
        width: 680px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .slogan-text-wrap {
        width: var(--col-8);
    }
}
@media (min-width: 768px) {
    .slogan-text-wrap {
        margin-left: auto;
        margin-right: auto;
    }
}

.slogan-text-wrap::before,
.slogan-text-wrap::after {
    content: "";
    position: absolute;
    top: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: .1;
}
.slogan-text-wrap::before {
    background-image: url(../../assets/images/quotation.svg);
    transform: translateY(-50%)
}
.slogan-text-wrap::after {
    background-image: url(../../assets/images/quotation.svg);
    transform: translateY(-50%) rotate(180deg);
}
@media (min-width: 1040px) {
    .slogan-text-wrap::before,
    .slogan-text-wrap::after {
        width: 135px;
        height: 96px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .slogan-text-wrap::before,
    .slogan-text-wrap::after {
        width: 90px;
        height: 64px;
    }
}
@media (min-width: 768px) {
    .slogan-text-wrap::before {
        left: -60px;
    }

    .slogan-text-wrap::after {
        right: -60px;
    }
}
@media (max-width: 767px) {
    .slogan-text-wrap::before,
    .slogan-text-wrap::after {
        width: 90px;
        height: 64px;
    }

    .slogan-text-wrap::before {
        left: 0;
    }

    .slogan-text-wrap::after {
        right: 0;
    }
}

.slogan__description {
    font-family: var(--f-semi-bold);
}
@media (min-width: 768px) {
    .slogan__description {
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    .slogan__description {
        font-size: 16px;
    }
}

.profile {
    position: relative;
    overflow: hidden;
    margin-left: var(--content-gap-negative);
    margin-right: var(--content-gap-negative);
    padding-left: var(--content-gap);
    padding-right: var(--content-gap);
}

.profile__head {
    position: relative;
    margin-bottom: var(--gap-y-small);
}
@media (min-width: 1160px) {
    .profile__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
}

@media (max-width: 1280px) {
    .profile__actions-wrap {
        margin-top: var(--gap-y-small);
    }
}
@media (min-width: 768px) {
    .profile__actions-wrap {
        display: flex;
    }
}

@media (min-width: 768px)  {
    .profile__action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .profile__action-btn {
        width: 100%;
    }
    .profile__action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

.profile__description {
    font-size: var(--adaptive-big-fz);
    line-height: var(--base-lh);
}

@media (min-width: 1281px) {
    .profile__text-wrap {
        width: var(--col-11);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .profile__text-wrap {
        width: 680px;
    }
}

.profile__screenshot {
    position: relative;
    flex-shrink: 0;
}
.profile__screenshot img {
    max-width: initial;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
@media (min-width: 1040px) {
    .profile__screenshot img {
        width: 1520px;
        height: 424px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .profile__screenshot img {
        width: 962px;
        height: 268px;
    }
}
@media (max-width: 767px) {
    .profile__screenshot img {
        width: 743px;
        height: 308px;
    }
}

@media (min-width: 1040px) {
    .future-services-list {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: var(--grid-gap);
    }
}

.future-service {
    position: relative;
    overflow: hidden;
}
@media (min-width: 1040px) {
    .future-service {
        padding: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .future-service {
        padding: 36px 28px;
    }
}
@media (max-width: 767px) {
    .future-service {
        padding: 20px;
    }
}
@media (min-width: 1040px) {
    .future-service:nth-child(1) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        grid-column: 1/13;
        grid-row: 1/2;
    }

    .future-service:nth-child(2) {
        grid-column: 1/5;
        grid-row: 2/3;
    }

    .future-service:nth-child(3) {
        grid-column: 5/9;
        grid-row: 2/3;
    }

    .future-service:nth-child(4) {
        grid-column: 9/13;
        grid-row: 2/3;
    }
}
@media (max-width: 1039px) {
    .future-service:not(:last-child) {
        margin-bottom: var(--y-grid-gap);
    }
}

.future-service__icon {
    width: 36px;
    height: 36px;
}
@media (min-width: 1040px) {
    .future-service__icon {
        margin-bottom: 24px;
    }
}
@media (max-width: 1039px) {
    .future-service__icon {
        position: absolute;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .future-service__icon {
        top: 36px;
        left: 36px;
    }
}
@media (max-width: 767px) {
    .future-service__icon {
        top: 24px;
        left: 24px;
    }
}

.future-service__text {
    line-height: var(--base-lh);
}
@media (max-width: 1039px) {
    .future-service__title,
    .future-service__text {
        margin-left: 56px;
    }
}

.future-service__img-wrap {
    display: block;
    flex-shrink: 0;
}

@media (min-width: 1281px) {
    .future-service__img {
        margin-top: -36px;
    }
}
@media (min-width: 1040px) {
    .future-service__img-wrap {
        margin-right: 48px;
        margin-left: 8px;
    }
}
@media (min-width: 1040px) {
    .future-service__img {
        width: 536px;
        height: 268px;
    }
}
@media (max-width: 1039px) {
    .future-service__img-wrap {
        margin-top: 20px;
    }

    .future-service__img {
        width: 400px;
        height: 200px;
        max-width: initial;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) {
    .future-service__img {
        margin-bottom: -36px;
    }
}
@media (max-width: 767px) {
    .future-service__img-wrap {
        margin-bottom: -20px;
        margin-left: -20px;
        margin-right: -20px;
    }

    .future-service__img {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}


.app-block {
    position: relative;
    overflow: hidden;
}
@media (min-width: 1281px) {
    .app-block::after {
        width: 636px;
        left: 752px;
        background-image: url(../../assets/images/about-page/app-xl.jpg);
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        .app-block::after {
            background-image: url(../../assets/images/about-page/app-xl-x2.jpg);
        }
    }
}
@media (min-width: 1040px) and (max-width: 1159px) {
    .app-block::after {
        left: 660px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .app-block::after {
        width: 460px;
        background-image: url(../../assets/images/about-page/app-l.jpg);
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        .app-block::after {
            background-image: url(../../assets/images/about-page/app-l-x2.jpg);
        }
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .app-block::after {
        width: var(--col-5);
        background-image: url(../../assets/images/about-page/app-m.jpg);
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        .app-block::after {
            background-image: url(../../assets/images/about-page/app-m-x2.jpg);
        }
    }
}
@media (min-width: 768px) {
    .app-block {
        padding: 36px;
    }

    .app-block::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right bottom;
    }
}
@media (max-width: 767px) {
    .app-block {
        padding: 20px 20px 0;
    }

    .app-block::after {
        content: "";
        position: relative;
        right: -20px;
        display: block;
        width: var(--col-5);
        margin-left: auto;
        margin-top: 16px;
        padding-bottom: calc(var(--col-5) / 2);
        background-image: url(../../assets/images/about-page/app-mobile.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .app-block::after {
            background-image: url(../../assets/images/about-page/app-mobile-x2.jpg);
        }
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .app-block__title {
        width: 432px;
    }
}

.app-block-content {
    position: relative;
    z-index: 2;
}
.app-block__description {
    line-height: var(--base-lh);
}
@media (min-width: 1040px) {
    .app-block__description {
        width: 620px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .app-block__description {
        width: 432px;
    }
}

@media (min-width: 1040px) {
    .subscribe-app-form {
        display: flex;
        align-items: flex-start;
    }

    .subscribe-app-form__field {
        margin-right: 20px;
    }
}
@media (min-width: 1040px) {
    .subscribe-app-form__field {
        width: 280px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .subscribe-app-form__field {
        margin-bottom: 20px;
    }

    .subscribe-app-form__field,
    .subscribe-app-form__btn {
        width: 280px;
    }
}
@media (max-width: 767px) {
    .subscribe-app-form__field {
        margin-bottom: 16px;
    }

    .subscribe-app-form__field,
    .subscribe-app-form__btn {
        width: 100%;
    }
}

.subscribe-app-block__title {
    margin-top: var(--gap-y-small);
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .subscribe-app-block__title {
        width: 280px;
    }
}
@media (min-width: 768px) {
    .subscribe-app-block__title {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .subscribe-app-block__title {
        margin-bottom: 16px;
    }
}
</style>