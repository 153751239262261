<script>
// mixins
import teleport from '@ui/mixins/teleport.js';
import stack from '@ui/mixins/stack.js';
// components
import UTrigger from '@ui/components/UTrigger/UTrigger.vue';


// FIXME: stop scroll
export default {
    name: 'UPopup',

    mixins: [
        teleport,
        stack,
    ],

    props: {
        closeOnClickOut: Boolean,
        noDetach: Boolean,
    },

    data() {
        return {
            active: false,
        };
    },

    computed: {
        style() {
            return {
                zIndex: this.stack.zIndex,
            };
        },
    },

    watch: {
        active: 'onChangeActive',
    },

    methods: {
        onChangeActive(value) {
            if (value) {
                this.updateZIndex();
                this.emitOpen();
            }
            else {
                this.emitClose();
            }
        },

        onClose() {
            this.$refs.trigger.deactivate();
        },

        // public

        open() {
            this.$refs.trigger.activate();
        },

        close() {
            this.$refs.trigger.deactivate();
        },

        // handlers

        handleClickOnOverlay($event) {
            if ($event.target === this.$refs.overlay) {
                this.$refs.trigger.deactivate();
            }
        },

        // emits

        emitOpen() {
            const target = this;
            this.$emit('open', { target });
        },

        emitClose() {
            const target = this;
            this.$emit('close', { target });
        },

        // gen

        genContent() {
            const close = this.onClose;
            const handlers = { close };
            return this.$scopedSlots.default({ handlers });
        },

        genOverlay() {
            return this.$createElement('div', {
                ref: 'overlay',
                staticClass: 'u-popup__overlay',
                on: this.closeOnClickOut ? {
                    click: this.handleClickOnOverlay,
                } : {},
            });
        },

        genPopup({ value, handlers }) {
            const style = this.style;

            const children = this.active ? [
                this.genOverlay(),
                this.genContent(),
            ] : [this.$createElement()];

            return this.$createElement('div', {
                ref: 'content',
                staticClass: 'u-popup',
                class: {
                    'u-popup_active': this.active,
                },
                directives: [
                    {
                        value,
                        name: 'show',
                    },
                ],
                on: handlers,
                style,
            }, children);
        },

        genTrigger(payload) {
            return this.$scopedSlots.trigger ? this.$scopedSlots.trigger(payload) : this.$createElement();
        },
    },

    render(h) {
        return h(UTrigger, {
            ref: 'trigger',
            staticClass: 'u-popup__trigger',
            class: {
                'u-popup__trigger_hidden': !this.noDetach,
            },
            props: {
                delayEnter: 0,
                delayLeave: 0,
                activateOnClick: true,
                deactivateOnResize: false,
                deactivateOnScroll: false,
                deactivateOnClickOut: false,
            },
            scopedSlots: {
                trigger: this.genTrigger,
                content: this.genPopup,
            },
            on: {
                change: ({ value }) => this.active = value,
            },
        });
    },
};
</script>

<style>
.u-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1280px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.u-popup__trigger.u-popup__trigger_hidden {
    display: none;
}

.u-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: -1;
}
</style>