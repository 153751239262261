<template>
    <FormTemplate>
        <template slot="body">
            <FormManager
                ref="form"
                v-model="formData"
                :formTag="false"
                @error="onError"
            >
                <div class="flex align-items-center">
                    <FormField
                        name="unlimited_drivers_amount"
                        type="checkbox"
                        label="Без ограничения на водителей"
                        class="unlimited_drivers_amount"
                        @change="patch('unlimited_drivers_amount')"
                    ></FormField>

                    <UTooltip>
                        <template #trigger="{ value, attrs, handlers }">
                            <UTooltipIcon
                                class="ml-8"
                                :active="value"
                                v-bind="attrs"
                                v-on="handlers"
                            ></UTooltipIcon>
                        </template>

                        Неограниченное количество лиц, допущенных к управлению автомобилем.
                    </UTooltip>
                </div>

                <template v-if="!formData.unlimited_drivers_amount">
                    <FormCollection name="drivers">
                        <FormCollectionItem>
                            <template #default="{ index, item }">
                                <div
                                    :class="{
                                        'form-grid_short': !item.prev_license
                                    }"
                                    class="form-grid mt-36"
                                >
                                    <div class="title-block">
                                        <h3 class="h3">
                                            <span class="capitalize">{{ (index + 1) | ordinalNumeral }}</span> водитель
                                        </h3>

                                        <ButtonText
                                            v-if="index > 0"
                                            primary
                                            dashed
                                            @click="removeDriver(index)"
                                        >
                                            Удалить
                                        </ButtonText>
                                    </div>

                                    <FormField
                                        name="last_name"
                                        label="Фамилия"
                                        validations="required"
                                        class="last_name"
                                        @blur="patchDrivers"
                                    ></FormField>

                                    <FormField
                                        name="first_name"
                                        label="Имя"
                                        validations="required"
                                        class="first_name"
                                        @blur="patchDrivers"
                                    ></FormField>

                                    <FormField
                                        name="middle_name"
                                        label="Отчество"
                                        validations="required"
                                        class="middle_name"
                                        @blur="patchDrivers"
                                    ></FormField>

                                    <FormField
                                        name="birth_date"
                                        type="calendar"
                                        label="Дата рождения"
                                        stepByStep
                                        :min="minBirthDate"
                                        :max="maxBirthDate"
                                        :validations="`required|min:${ minBirthDateIso }|max:${ maxBirthDateIso }`"
                                        class="birth_date"
                                        @blur="patchDrivers"
                                    ></FormField>

                                    <h4 class="h4 h4_text license-number-title">
                                        Водительское удостоверение
                                    </h4>

                                    <!-- re на ВУ -->
                                    <FormField
                                        name="license_number"
                                        label="Серия и номер ВУ"
                                        mask="0000 000000"
                                        validations="required|re"
                                        upper
                                        :validationRules="{
                                            re: ({ value }) => {
                                                return value ? !/(\d{4})\s(\d{6})/g.test(value) : false;
                                            },
                                        }"
                                        class="license_number"
                                        @blur="patchDrivers"
                                    ></FormField>

                                    <!-- min birth_date + 18 max now -->
                                    <FormField
                                        name="experience_start_date"
                                        type="date"
                                        visibleMask
                                        label="Дата начала стажа"
                                        :validations="`required|min:${ getMinExperienceStartDateIso(index) }|max:${ maxExperienceStartDateIso }`"
                                        hint="Укажите дату выдачи первого водительского удостоверения. Если вы производили замену прав и не помните дату выдачи первых прав, укажите дату 01.01.****, где ****  – год выдачи первых прав."
                                        class="experience_start_date"
                                        @blur="patchDrivers"
                                    ></FormField>

                                    <InfoMessage class="info-message">
                                        Если у водителя была замена прав в течение года, история вождения (КБМ), скорее всего, не перенесена на новое ВУ. Рекомендуем указать данные предыдущего ВУ, чтобы расчёт стоимости учитывал скидку за КБМ. Данные предыдущего ВУ указаны на обороте новых прав.
                                    </InfoMessage>

                                    <FormField
                                        name="prev_license"
                                        type="checkbox"
                                        label="Менялось водительское удостоверение за последний год"
                                        class="prev_license"
                                        @change="patchDrivers"
                                    ></FormField>

                                    <template v-if="item.prev_license">
                                        <h4 class="h4 h4_text prev-license-number-title">
                                            Старое водительское удостоверение
                                        </h4>

                                        <FormField
                                            name="prev_last_name"
                                            label="Фамилия"
                                            validations="required"
                                            class="prev_last_name"
                                            @blur="patchDrivers"
                                        ></FormField>

                                        <FormField
                                            name="prev_first_name"
                                            label="Имя"
                                            validations="required"
                                            class="prev_first_name"
                                            @blur="patchDrivers"
                                        ></FormField>

                                        <FormField
                                            name="prev_middle_name"
                                            label="Отчество"
                                            validations="required"
                                            class="prev_middle_name"
                                            @blur="patchDrivers"
                                        ></FormField>

                                        <!-- re на ВУ -->
                                        <FormField
                                            name="prev_license_number"
                                            label="Серия и номер ВУ"
                                            mask="00RR 000000"
                                            validations="required|re"
                                            upper
                                            :validationRules="{
                                                re: ({ value }) => {
                                                    return value ? !/(\d{2})([\dА-Я]{2})\s(\d{6})/g.test(value) : false;
                                                },
                                            }"
                                            class="prev_license_number"
                                            @blur="patchDrivers"
                                        ></FormField>

                                        <!-- min birth_date + 18 max now -->
                                        <FormField
                                            name="prev_license_date"
                                            type="date"
                                            visibleMask
                                            label="Дата выдачи ВУ"
                                            :validations="`required|min:${ getMinPrevLicenseDateIso(index) }|max:${ maxPrevLicenseDateIso }`"
                                            class="prev_license_date"
                                            @blur="patchDrivers"
                                        ></FormField>
                                    </template>
                                </div>
                            </template>
                        </FormCollectionItem>
                    </FormCollection>

                    <ButtonText
                        v-if="formData.drivers.length < 5"
                        secondary
                        dark
                        dashed
                        class="add-driver"
                        @click="addDriver()"
                    >
                        + Добавить ещё одного водителя
                    </ButtonText>
                </template>
            </FormManager>
        </template>

        <template slot="footer">
            <ButtonBlock
                secondary
                class="button-secondary"
                @click="back"
            >
                Назад
            </ButtonBlock>
            <ButtonBlock
                primary
                class="button-primary"
                @click="next"
            >
                Следующий шаг
            </ButtonBlock>
        </template>
    </FormTemplate>
</template>

<script>
// services
import { HTTP } from '@/http.js';
// utils
import deepClone from '@/lib/deepClone.js';
import getErrorCode from '@/lib/getErrorCode.js';
import equals from '@/lib/equals.js';
// use
import usePatchData from '@/pages/insurance/osago/_uuid/usePatchData.js';
import usePatchComputed from '@/pages/insurance/osago/_uuid/usePatchComputed.js';
import usePatchWatch from '@/pages/insurance/osago/_uuid/usePatchWatch.js';
import usePatchMethods from '@/pages/insurance/osago/_uuid/usePatchMethods.js';
import useNavigationMethods from '@/pages/insurance/osago/_uuid/useNavigationMethods.js';
import useDriversData from '@/pages/insurance/osago/_uuid/useDriversData.js';
import useDriversComputed from '@/pages/insurance/osago/_uuid/useDriversComputed.js';
import useDriversWatch from '@/pages/insurance/osago/_uuid/useDriversWatch.js';
import useDriversMethods from '@/pages/insurance/osago/_uuid/useDriversMethods.js';
// mixins
import lastPathMixin from '@/pages/insurance/osago/_uuid/lastPathMixin.js';
// components
import InfoMessage from '@/components/InfoMessage.vue';
import FormTemplate from '../FormTemplate.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import FormCollection from '@/components/_form/FormCollection.vue';
import FormCollectionItem from '@/components/_form/FormCollectionItem.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


export default {
    name: 'InsuranceOsagoRequestStep3Page',

    components: {
        UTooltipIcon,
        UTooltip,
        ButtonBlock,
        ButtonText,
        FormCollectionItem,
        FormCollection,
        FormManager,
        FormTemplate,
        InfoMessage,
        FormField,
    },

    mixins: [
        lastPathMixin,
    ],

    beforeRouteLeave(to, from, next) {
        const isOsago = to.name === 'insurance-osago-request-step-4';
        const ownerIsEmpty =
            !this.contract.owner_last_name &&
            !this.contract.owner_first_name &&
            !this.contract.owner_middle_name &&
            !this.contract.owner_birth_date;

        if (isOsago && !this.formData.unlimited_drivers_amount && !!this.formData.drivers.length && ownerIsEmpty) {
            const driver = this.formData.drivers[0];
            const { last_name, first_name, middle_name, birth_date } = driver;
            const owner_is_insurer = this.contract.owner_is_insurer;
            let data = {
                owner_last_name: last_name,
                owner_first_name: first_name,
                owner_middle_name: middle_name,
                owner_birth_date: birth_date,
            };

            if (owner_is_insurer) {
                data = {
                    ...data,
                    insurer_last_name: last_name,
                    insurer_first_name: first_name,
                    insurer_middle_name: middle_name,
                    insurer_birth_date: birth_date,
                };
            }

            this.update(data);
            const url = `/insurance_contract/${ this.uuid }/`;
            HTTP.patch(url, data).catch(() => {
                //
            });
        }

        next();
    },

    data() {
        return {
            ...usePatchData(),
            ...useDriversData(),
        };
    },

    computed: {
        ...usePatchComputed(),
        ...useDriversComputed(),
    },

    watch: {
        ...usePatchWatch(),
        ...useDriversWatch(),
    },

    mounted() {
        this.patchedData = deepClone(this.filteredStoreData);
    },

    methods: {
        ...usePatchMethods(),
        ...useDriversMethods(),
        ...useNavigationMethods({
            prev: 'insurance-osago-request-step-2',
            next: 'insurance-osago-request-step-4',
            yandex: 'osago_price_step3',
        }),

        patchDrivers() {
            setTimeout(async () => {
                if (!equals(this.patchedData.drivers, this.formData.drivers)) {
                    const dirty = this.formData.drivers;
                    // очистка
                    const drivers = dirty.map(driver => {
                        const keys = [
                            'last_name',
                            'first_name',
                            'middle_name',
                            'birth_date',
                            'license_number',
                            'experience_start_date',
                            'prev_license',
                        ];

                        if (driver.prev_license) {
                            keys.push(
                                'prev_last_name',
                                'prev_first_name',
                                'prev_middle_name',
                                'prev_license_number',
                                'prev_license_date',
                            );
                        }

                        if (driver.uuid) {
                            keys.push('uuid');
                        }

                        return Object.entries(driver).reduce((acc, [key, value]) => {
                            if (keys.includes(key)) {
                                acc[key] = value;
                            }

                            return acc;
                        }, {});
                    });
                    // проверка
                    const isValid = drivers.every((driver, index) => {
                        const keys = Object.keys(driver);

                        return keys.every(key => {
                            const value = driver[key];
                            const isEmpty = value == null || value === '';
                            const errors = (((this.innerErrors || {}).drivers || [])[index] || [])[key] || [];
                            const invalid = !!errors.length;
                            return !isEmpty && !invalid;
                        });
                    });

                    if (isValid) {
                        try {
                            const url = `/insurance_contract/${ this.uuid }/`;
                            const response = await HTTP.patch(url, { drivers });
                            this.formData.drivers = response.data.drivers;
                            this.patchedData.drivers = deepClone(this.formData.drivers);
                        }
                        catch (error) {
                            const code = getErrorCode(error);

                            if (code === 400) {
                                this.$refs.form.setErrors(error.response.data);
                            }

                            this.$store.commit('handleCommonHttpError', error);
                        }
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
.last_name {
    grid-area: last_name;
}

.first_name {
    grid-area: first_name;
}

.middle_name {
    grid-area: middle_name;
}

.birth_date {
    grid-area: birth_date;
}

.license_number {
    grid-area: license_number;
}

.experience_start_date {
    grid-area: experience_start_date;
}

.prev_license {
    grid-area: prev_license;
}

.prev_last_name {
    grid-area: prev_last_name;
}

.prev_first_name {
    grid-area: prev_first_name;
}

.prev_middle_name {
    grid-area: prev_middle_name;
}

.prev_license_number {
    grid-area: prev_license_number;
}

.prev_license_date {
    grid-area: prev_license_date;
}

.title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-area: title-block;
}

.license-number-title {
    grid-area: license-number-title;
}

.prev-license-number-title {
    grid-area: prev-license-number-title;
}

.h4 {
    margin-top: 4px;
}
@media (min-width: 768px) {
    .h4 {
        margin-bottom: -4px;
    }
}
@media (max-width: 767px) {
    .h4 {
        margin-bottom: 16px;
    }
}

.info-message {
    grid-area: info-message;
}

.add-driver {
    width: max-content;
    margin-top: 36px;
}

@media (min-width: 1040px) {
    .form-grid:not(.form-grid_short) {
        grid-template-areas:
        "title-block title-block title-block"
        "last_name first_name middle_name"
        "birth_date . ."
        "license-number-title license-number-title license-number-title"
        "license_number experience_start_date ."
        "info-message info-message info-message"
        "prev_license prev_license prev_license"
        "prev-license-number-title prev-license-number-title prev-license-number-title"
        "prev_last_name prev_first_name prev_middle_name"
        "prev_license_number prev_license_date ."
    }

    .form-grid_short {
        grid-template-areas:
        "title-block title-block title-block"
        "last_name first_name middle_name"
        "birth_date . ."
        "license-number-title license-number-title license-number-title"
        "license_number experience_start_date ."
        "info-message info-message info-message"
        "prev_license prev_license prev_license"
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .form-grid:not(.form-grid_short) {
        grid-template-areas:
        "title-block title-block"
        "last_name first_name"
        "middle_name birth_date"
        "license-number-title license-number-title"
        "license_number experience_start_date"
        "info-message info-message"
        "prev_license prev_license"
        "prev-license-number-title prev-license-number-title"
        "prev_last_name prev_first_name"
        "prev_middle_name ."
        "prev_license_number prev_license_date"
    }

    .form-grid_short {
        grid-template-areas:
        "title-block title-block"
        "last_name first_name"
        "middle_name birth_date"
        "license-number-title license-number-title"
        "license_number experience_start_date"
        "info-message info-message"
        "prev_license prev_license"
    }
}
</style>