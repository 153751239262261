import reviews from '@/api/shops/reviews.js';


export default ({ HTTP }) => ({
    reviews: reviews({ HTTP }),

    async get(payload) {
        if (typeof payload === 'number' || typeof payload === 'string') {
            const id = payload;
            const url = `/shops/${ id }/`;
            const response = await HTTP.get(url);
            return response.data;
        }
    },

    async getWorkTime(id, data) {
        const url = `/shops/${ id }/get_work_time/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async addReview(id, data, config) {
        const url = `/shops/${ id }/add_review/`;
        const response = await HTTP.post(url, data, config);
        return response.data;
    },

    async updateReview(id, data, config) {
        const url = `/shops/${ id }/update_review/`;
        const response = await HTTP.post(url, data, config);
        return response.data;
    },

    async deleteReview(id) {
        const url = `/shops/${ id }/delete_review/`;
        const response = await HTTP.delete(url);
        return response.data;
    },
});