var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.buttons)?_c('div',{staticClass:"delivery-info_buttons"},[_vm._l((_vm.buttonItems),function(item){return _c('span',{key:item.key,staticClass:"chip"},[_c('span',{staticClass:"chip__label"},[_c('span',{staticClass:"chip__label-title"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),_c('span',{staticClass:"chip__label-text"},[_vm._v(" "+_vm._s(item.dateShortText))])])])}),_vm._v(" "),(_vm.currentWork.delivery.is_active && (_vm.offer || _vm.order) && _vm.hasWeight)?_c('UTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var value = ref.value;
var attrs = ref.attrs;
var handlers = ref.handlers;
return [_c('button',_vm._g(_vm._b({staticClass:"chip",class:{
                    chip_active: value
                },attrs:{"type":"button","tabindex":"-1"},on:{"click":_vm.calcCountryDelivery}},'button',attrs,false),handlers),[_c('span',{staticClass:"chip__label"},[_c('span',{staticClass:"chip__label-title"},[_vm._v("Доставка по России")])])])]}}],null,false,137287033)},[_vm._v("\n\n        Нажмите, чтобы рассчитать сроки и стоимость доставки.\n        Оформление и оплата доставки происходит после получения подтверждения\n        и комплектации заказа от магазина.\n    ")]):_vm._e()],2):(_vm.list)?_c('ul',{staticClass:"delivery-info_list",class:{ 'delivery-info_list_small': _vm.small }},[_vm._l((_vm.listItems),function(item){return _c('li',{staticClass:"receive-type"},[_c('span',{staticClass:"text_secondary"},[_vm._v(_vm._s(item.label)+":")]),_vm._v(" "+_vm._s(item.dateText)+"\n    ")])}),_vm._v(" "),(_vm.currentWork.delivery.is_active && (_vm.offer || _vm.order) && _vm.hasWeight)?_c('li',{staticClass:"receive-type"},[_c('span',{staticClass:"text_secondary"},[_vm._v("Доставка по России")]),_vm._v(" "),_c('ButtonText',{attrs:{"dashed":"","secondary":"","dark":""},on:{"click":_vm.calcCountryDelivery}},[_vm._v("\n            рассчитать\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.currentWork.delivery.is_active && !_vm.offer && !_vm.order)?_c('li',{staticClass:"receive-type"},[_c('span',{staticClass:"text_secondary"},[_vm._v("Доставка по России")]),_c('UTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                var value = ref.value;
                var attrs = ref.attrs;
                var handlers = ref.handlers;
return [_c('UTooltipIcon',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"active":value}},'UTooltipIcon',attrs,false),handlers))]}}],null,false,752151382)},[_vm._v("\n\n            Чтобы рассчитать сроки и стоимость доставки товаров из этого магазина в свой город,\n            добавьте их в корзину.\n            Оформление и оплата доставки происходит после получения подтверждения\n            и комплектации заказа от магазина.\n        ")])],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }