<template>
    <FilterWidgetWrap
        :codename="config.codename"
        :title="config.title"
        :hint="config.hint"
        :loading="loading"
        :collapseOnMount="config.isDynamic"
    >
        <RadioBase
            v-for="(option, indexOption) in config.options"
            :id="'filter-radio-' + indexOption"
            :key="'filter-radio-' + indexOption"
            v-model="valueComputed"
            :value="option.value"
            class="filter-radio"
        >
            {{ option.label }} <span
                v-if="option.count"
                class="ml-4 text_small-fz text_secondary"
            >{{ option.count | number }}</span>
        </RadioBase>
    </FilterWidgetWrap>
</template>

<script>
import RadioBase from './fields/RadioBase.vue';
import FilterWidgetWrap from './FilterWidgetWrap.vue';

export default {
    name: "FilterRadioGroup",

    components: {
        RadioBase,
        FilterWidgetWrap,
    },

    props: {
        config: {
            type: Object,
            default() {
                return {
                    options: [
                        {
                            value: '',
                            label: '',
                        },
                    ],
                    label: '',
                    codename: '',
                };
            },
        },

        value: {
            type: String,
            required: true,
        },
        loading: Boolean,
    },

    computed: {
        valueComputed: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('change', value);
                this.$emit('reload');
            },
        },
    },
};
</script>
