var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopupWrap',{attrs:{"noPadding":"","loading":_vm.loading},on:{"clickaway":_vm.close,"close":_vm.close}},[_c('div',{staticClass:"popup-content"},[_c('div',{staticClass:"head head_desktop"},[_c('ShopLogo',{staticClass:"logo",attrs:{"shop":{ company: _vm.company },"coverCodename":"big","noLink":""}}),_vm._v(" "),_c('div',[_c('h2',{staticClass:"h2 mb-8"},[_vm._v("\n                    Магазины компании "+_vm._s(_vm.company.name)+"\n                ")]),_vm._v(" "),_c('span',{staticClass:"text_secondary text_semi-bold text_adaptive-big-fz"},[_vm._v(_vm._s(_vm.branchesAmountText))])])],1),_vm._v(" "),_c('div',{staticClass:"head head_mobile"},[_c('h2',{staticClass:"h2 popup-title"},[_vm._v("\n                Компания "+_vm._s(_vm.company.name)+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"mt-8 text_secondary text_semi-bold"},[_vm._v(_vm._s(_vm.branchesAmountText))])]),_vm._v(" "),_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"view-toggler"},[_c('RadioButtonsGroup',{attrs:{"name":"offers","type":"text","options":[
                        {
                            label: 'Список',
                            value: 'list',
                        },
                        {
                            label: 'Карта',
                            value: 'map',
                        } ]},on:{"change":_vm.onChangeView},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}})],1)]),_vm._v(" "),_c('div',{staticClass:"content-wrap",class:{ 'content-wrap_list': _vm.view === 'list' }},[(_vm.view === 'list')?_c('ol',{staticClass:"branches-list view_list"},_vm._l((_vm.shops),function(item,itemIndex){return _c('li',{key:("shop-" + (item.id)),staticClass:"branch"},[_c('div',{staticClass:"flex justify-content-between mb-12"},[_c('div',{staticClass:"shop-address-wrap"},[_c('button',{staticClass:"shop-address-btn link-primary-hover",attrs:{"type":"button"},on:{"click":function($event){return _vm.showOfferOnMap({ shop: item, index: itemIndex })}}},[_vm._v("\n                                г. "+_vm._s(item.city.name)+", "+_vm._s(item.address)+"\n                            ")]),_vm._v(" "),_c('ShopOpenCloseTime',{attrs:{"shopId":item.id,"status":item.current_work}})],1),_vm._v(" "),_c('div',{staticClass:"market-item__actions ml-12"},[_c('LikeBtn',{staticClass:"market-item__action-btn",attrs:{"id":item.id,"value":item.is_favorite,"apiName":"shop"},on:{"changeFavorite":function($event){return _vm.onChangeFavorite($event, itemIndex)}}}),_vm._v(" "),_c('ChatBtn',{staticClass:"market-item__action-btn",attrs:{"id":item.id,"isOnline":(((item.current_work) || {}).place || {}).is_active,"disabled":!item.is_chat_active},on:{"click":_vm.close}})],1)]),_vm._v(" "),_c('div',{staticClass:"rating-link-wrap"},[_c('MarketItemRatingReviewsLink',{staticClass:"rating-wrap",attrs:{"item":item,"modelName":"shop"}}),_vm._v(" "),_c('ButtonText',{staticClass:"rating-link-wrap__link",attrs:{"to":{
                                name: 'market-shop-stock',
                                params: {
                                    codename: _vm.company.codename,
                                    id: item.id,
                                },
                            },"secondary":"","dark":"","underline":""}},[_vm._v("\n                            На страницу магазина\n                        ")])],1)])}),0):_vm._e(),_vm._v(" "),(_vm.view === 'map')?_c('div',{staticClass:"map-wrap"}):_vm._e(),_vm._v(" "),(_vm.shops && _vm.shops.length)?_c('div',{ref:"map",staticClass:"map",class:{ 'visually-hidden': _vm.view === 'list' }},[_c('yandexMap',{ref:"mapComponent",staticStyle:{"width":"100%","height":"100%"},attrs:{"coords":_vm.getCoordsArray(_vm.shops[0]),"zoom":"10","controls":['zoomControl'],"scrollZoom":false,"options":{ suppressMapOpenBlock: true }},on:{"map-was-initialized":_vm.setMapCenter,"actionend":_vm.handleActionEnd}},_vm._l((_vm.shops),function(item,index){return _c('ymapMarker',{key:'item-' + index,ref:'item-' + index,refInFor:true,attrs:{"coords":_vm.getCoordsArray(item),"icon":_vm.getYMapMarkerIcon({ active: _vm.balloon.visible && _vm.balloon.index === index }),"markerId":'item-' + index},on:{"click":function($event){return _vm.showBalloon({ shop: item, index: index })}}})}),1),_vm._v(" "),(_vm.balloon.visible)?_c('ShopBalloon',{attrs:{"shop":_vm.balloon.shop,"company":_vm.companyDetails},on:{"close":_vm.closeBalloon,"clickChat":_vm.close,"changeFavorite":function($event){return _vm.onChangeFavorite($event, _vm.balloon.index)}}}):_vm._e()],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }