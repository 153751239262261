<template>
    <div class="default-layout">
        <LinePromotion class="line-promotion"></LinePromotion>
        <DefaultLayoutHeader></DefaultLayoutHeader>
        <Breadcrumbs></Breadcrumbs>

        <main class="body">
            <div class="container">
                <RouterView></RouterView>
            </div>
        </main>

        <DefaultLayoutFooter></DefaultLayoutFooter>

        <ChatLayer></ChatLayer>
    </div>
</template>

<script>
import DefaultLayoutHeader from '../components/header/DefaultLayoutHeader.vue';
import DefaultLayoutFooter from '../components/DefaultLayoutFooter.vue';
import Breadcrumbs from '../components/Breadcrumbs.vue';
import LinePromotion from '../components/LinePromotion.vue';
import ChatLayer from '@/components/chat/ChatLayer.vue';


export default {
    name: 'DefaultLayout',

    components: {
        ChatLayer,
        LinePromotion,
        Breadcrumbs,
        DefaultLayoutHeader,
        DefaultLayoutFooter,
    },

    mounted() {
        return Promise.all([
            this.$store.dispatch('promotions/get'),
        ]);
    },
};
</script>

<style scoped>
.default-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--bright-bg);
}

.body {
    flex-grow: 1;
}
</style>
