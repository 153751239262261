export default {
    namespaced: true,

    state: () => ({
        initialized: false,

        activeOrders: {
            items: [],
            current_count: 0,
        },

        archivedOrders: {
            items: [],
            current_count: 0,
            hasNext: false,
        },
    }),

    getters: {
        ordersRoute: state => {
            if (state.activeOrders.current_count) {
                return 'account-orders-active';
            }
            else if (state.archivedOrders.current_count) {
                return 'account-orders-archive';
            }
            else {
                return 'account-orders';
            }
        },
    },

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setActiveOrders(state, { current_count, results }) {
            if (results) state.activeOrders.items = results;
            state.activeOrders.current_count = current_count || 0;
        },

        addActiveOrders(state, { results }) {
            state.activeOrders.items = [... state.activeOrders.items, ...results];
            state.activeOrders.current_count += results.length;
        },

        removeActiveOrder(state, { uuid }) {
            state.activeOrders.items = state.activeOrders.items.filter(item => item.uuid !== uuid);
            state.activeOrders.current_count -= 1;
        },

        setArchivedOrders(state, { current_count, results, next }) {
            if (results) state.archivedOrders.items = results;
            state.archivedOrders.current_count = current_count || 0;
            state.archivedOrders.hasNext = !!next;
        },

        addArchivedOrders(state, { results, next }) {
            state.archivedOrders.items = [... state.archivedOrders.items, ...results];
            state.archivedOrders.current_count += results.length;
            state.archivedOrders.hasNext = !!next;
        },

        removeArchivedOrder(state, { uuid }) {
            state.archivedOrders.items = state.archivedOrders.items.filter(item => item.uuid !== uuid);
            state.archivedOrders.current_count -= 1;
        },

        changeOrderDeliveryType(state, { number, data }) {
            state.activeOrders.items.find(order => {
                if (order.number === number) {
                    order.delivery_type = data.delivery_type;
                }
            });
        },

        changeOrderStatus(state, { number, data }) {
            state.activeOrders.items.find(order => {
                if (order.number === number) {
                    if (data.status) order.status = data.status;
                    order.status_name = data.status_name;
                }
            });
        },

        destroy(state) {
            state.loading = false;
            state.initialized = false;

            // state.activeOrders = {
            //     items: [],
            //     current_count: 0,
            // };
            state.archivedOrders = {
                items: [],
                current_count: 0,
                hasNext: false,
            };
        },
    },

    actions: {
        async init({ commit, dispatch }) {
            try {
                const rangeParams = {
                    limit: 0,
                    offset: 0,
                };
                const [activeOrders, archivedOrders] = await Promise.all(
                    [
                        dispatch('getActiveOrders', rangeParams),
                        dispatch('getArchivedOrders', rangeParams),
                    ],
                );

                commit('setActiveOrders', { current_count: activeOrders.current_count });
                commit('setArchivedOrders', { current_count: archivedOrders.current_count });
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { activeOrders, archivedOrders };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getActiveOrders({}, rangeParams = { limit: 1000, offset: 0 }) {
            const config = {
                params: {
                    order_by: '-id',
                    is_active: true,
                    ...rangeParams,
                },
            };

            return await this.$api.orders.get(config);
        },

        async fetchActiveOrdersCount({ commit }) {
            const config = {
                params: {
                    order_by: '-id',
                    limit: 1000,
                    offset: 0,
                    is_active: true,
                },
            };

            const activeOrders = await this.$api.orders.get(config);
            commit('setActiveOrders', activeOrders);
        },

        async getArchivedOrders({ commit }, rangeParams) {
            const config = {
                params: {
                    order_by: '-id',
                    is_active: false,
                    ...rangeParams,
                },
            };

            try {
                return await this.$api.orders.get(config);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};