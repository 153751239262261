<template>
    <component
        :is="tag"
        class="u-icon-button"
        :class="{
            'u-icon-button_active': active,
            'u-icon-button_disabled': disabled,
            'u-icon-button_border': (round || square) && !shadow,
            'u-icon-button_round': round,
            'u-icon-button_square': square,
            'u-icon-button_shadow': shadow,
            'u-icon-button_primary': primary,
            'u-icon-button_secondary': secondary,
            'u-icon-button_red': red,
            'u-icon-button_yellow': yellow,
            'u-icon-button_used': used,
            'u-icon-button_tiny': tiny,
        }"
        v-bind="{
            ...$attrs,
            ...attrs,
        }"
        v-on="$listeners"
    >
        <USpinner
            v-if="loading"
            v-bind="{
                ...spinner,
            }"
        ></USpinner>
        <UIcon
            v-else
            :name="name"
            :tiny="tiny"
            :small="small"
            :big="big"
            class="u-icon-button__icon"
        ></UIcon>
    </component>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';
import buttonMixin from '@ui/mixins/button.js';
import USpinner from '@ui/components/USpinner/USpinner.vue';


export default {
    name: 'UIconButton',

    components: {
        USpinner,
        UIcon,
    },

    mixins: [buttonMixin],

    inheritAttrs: false,

    props: {
        loading: Boolean,
        spinner: {
            type: Object,
            default: () => {},
        },

        // icon
        name: String,
        tiny: Boolean,
        small: Boolean,
        big: Boolean,

        // style
        primary: Boolean,
        secondary: Boolean,
        red: Boolean,
        yellow: Boolean,
        green: Boolean,

        used: Boolean,

        shadow: Boolean,

        // form
        round: Boolean,
        square: Boolean,
    },
};
</script>

<style>
.u-icon-button {
    display: inline;
    font-size: 0;
    vertical-align: sub;
    transition: background-color var(--transition), border-color var(--transition), box-shadow var(--transition);
}
.u-icon-button.u-icon-button_disabled {
    pointer-events: none;
}

.u-icon-button.u-icon-button_border {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-dark-c);
}

.u-icon-button.u-icon-button_border.u-icon-button_tiny {
    width: 24px;
    height: 24px;
}

.u-icon-button.u-icon-button_shadow {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
}

.u-icon-button.u-icon-button_shadow:hover,
.u-icon-button.u-icon-button_shadow:active,
.u-icon-button.u-icon-button_shadow.u-icon-button_active {
    box-shadow: var(--hover-base-shadow);
}

.u-icon-button:hover .u-icon-button__icon,
.u-icon-button:active .u-icon-button__icon,
.u-icon-button.u-icon-button_active .u-icon-button__icon {
    fill: var(--primary-color);
}

.u-icon-button.u-icon-button_secondary .u-icon-button__icon {
    fill: var(--font-secondary-color);
}

.u-icon-button.u-icon-button_secondary.u-icon-button_disabled .u-icon-button__icon {
    fill: var(--grey-light-color);
}

.u-icon-button.u-icon-button_secondary:hover:not(.u-icon-button_disabled) .u-icon-button__icon,
.u-icon-button.u-icon-button_secondary:active:not(.u-icon-button_disabled) .u-icon-button__icon,
.u-icon-button.u-icon-button_secondary.u-icon-button_active:not(.u-icon-button_disabled) .u-icon-button__icon {
    fill: var(--dark-c);
}

.u-icon-button.u-icon-button_secondary.u-icon-button_border:hover:not(.u-icon-button_disabled) .u-icon-button__icon,
.u-icon-button.u-icon-button_secondary.u-icon-button_border:active:not(.u-icon-button_disabled) .u-icon-button__icon,
.u-icon-button.u-icon-button_secondary.u-icon-button_border.u-icon-button_active:not(.u-icon-button_disabled) .u-icon-button__icon {
    fill: var(--primary-color);
}

.u-icon-button.u-icon-button_primary .u-icon-button__icon,
.u-icon-button.u-icon-button_red .u-icon-button__icon {
    fill: var(--primary-color);
}

.u-icon-button.u-icon-button_primary:hover .u-icon-button__icon,
.u-icon-button.u-icon-button_primary:active .u-icon-button__icon,
.u-icon-button.u-icon-button_primary.u-icon-button_active .u-icon-button__icon,
.u-icon-button.u-icon-button_red:hover .u-icon-button__icon,
.u-icon-button.u-icon-button_red:active .u-icon-button__icon,
.u-icon-button.u-icon-button_red.u-icon-button_active .u-icon-button__icon {
    fill: var(--primary-dark-color);
}

.u-icon-button.u-icon-button_yellow .u-icon-button__icon {
    fill: var(--accent-yellow);
}

.u-icon-button.u-icon-button_round {
    border-radius: 50%;
}

.u-icon-button.u-icon-button_square {
    border-radius: var(--border-radius);
}

.u-icon-button.u-icon-button_border.u-icon-button_primary {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.u-icon-button.u-icon-button_border.u-icon-button_secondary {
    border-color: var(--font-secondary-color);
    background-color: var(--font-secondary-color);
}

.u-icon-button.u-icon-button_border.u-icon-button_primary:hover,
.u-icon-button.u-icon-button_border.u-icon-button_primary:active,
.u-icon-button.u-icon-button_border.u-icon-button_primary.u-icon-button_active {
    border-color: var(--primary-dark-color);
    background-color: var(--primary-dark-color);
}

.u-icon-button.u-icon-button_border.u-icon-button_secondary:hover,
.u-icon-button.u-icon-button_border.u-icon-button_secondary:active,
.u-icon-button.u-icon-button_border.u-icon-button_secondary.u-icon-button_active {
    border-color: var(--font-secondary-dark-color);
    background-color: var(--font-secondary-dark-color);
}

.u-icon-button.u-icon-button_border.u-icon-button_primary .u-icon-button__icon,
.u-icon-button.u-icon-button_border.u-icon-button_primary:hover .u-icon-button__icon,
.u-icon-button.u-icon-button_border.u-icon-button_primary:active .u-icon-button__icon,
.u-icon-button.u-icon-button_border.u-icon-button_primary.u-icon-button_active .u-icon-button__icon,
.u-icon-button.u-icon-button_border.u-icon-button_secondary .u-icon-button__icon,
.u-icon-button.u-icon-button_border.u-icon-button_secondary:hover .u-icon-button__icon,
.u-icon-button.u-icon-button_border.u-icon-button_secondary:active .u-icon-button__icon,
.u-icon-button.u-icon-button_border.u-icon-button_secondary.u-icon-button_active .u-icon-button__icon {
    fill: var(--light-c);
}

.u-icon-button.u-icon-button_border.u-icon-button_primary.u-icon-button_used {
    color: var(--primary-color);
    background-color: var(--light-c);
    border-width: 2px;
}

.u-icon-button.u-icon-button_border.u-icon-button_primary.u-icon-button_used .u-icon-button__icon {
    animation: zoomIn .4s ease-in-out forwards;
    fill: var(--primary-color);
}

@keyframes zoomIn {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    50% {
        transform: scale(0.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.u-icon-button__icon {
    fill: var(--dark-c);
}
</style>