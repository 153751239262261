export default {
    namespaced: true,

    state: () => ({
        article: {},
        initialized: false,
    }),

    mutations: {
        setArticle(state, article) {
            state.article = article;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.article = {};
            state.initialized = false;
        },
    },

    actions: {
        async init({ commit }, { codename }) {
            try {
                const article = await this.$api.help.articles(codename);

                commit('setArticle', article);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { article };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },
    },
};