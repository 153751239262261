<template>
    <div class="top-gap_medium">
        <h1 class="h1 h1_block">
            Производители запчастей и&nbsp;автотоваров
        </h1>

        <Spinner
            v-if="!initialized"
            size="big"
            center
        ></Spinner>

        <div
            v-else
            ref="listWrap"
            class="wrap layer-1"
        >
            <Portal to="sticky-holder" :order="2">
                <div
                    ref="LetterListStickyHeader"
                    class="letter-list_sticky sticky-sections-holder__section"
                    :class="{
                        'sticky-sections-holder__section_visible': showStickyHeader
                    }"
                >
                    <ol class="letter-list">
                        <li
                            v-for="(group, index) in itemsByGroups"
                            :key="'btn-' + group.letter"
                            class="letter-list__item"
                        >
                            <button
                                class="letter-list__btn"
                                :class="{
                                    'letter-list__btn_current': index === currentGroup,
                                }"
                                @click="scrollToGroup(index)"
                            >
                                {{ group.letter }}
                            </button>
                        </li>
                    </ol>
                </div>
            </Portal>

            <Observer @intersect="intersected">
                <div class="letter-list_outer">
                    <ol class="letter-list">
                        <li
                            v-for="(group, index) in itemsByGroups"
                            :key="'btn-' + group.letter"
                            class="letter-list__item"
                        >
                            <button
                                class="letter-list__btn"
                                :class="{
                                    'letter-list__btn_current': index === currentGroup,
                                }"
                                @click="scrollToGroup(index)"
                            >
                                {{ group.letter }}
                            </button>
                        </li>
                    </ol>
                </div>
            </Observer>

            <div class="grid">
                <div
                    v-for="group in itemsByGroups"
                    :id="'group-' + group.letter"
                    :key="'group-' + group.letter"
                    ref="group"
                    class="group"
                >
                    <div class="h2 h2_block">
                        {{ group.letter }}
                    </div>

                    <div class="list">
                        <RouterLink
                            v-for="brand in group.data"
                            :key="'brand-' + brand.id"
                            v-lazy-container="{ selector: 'img' }"
                            :to="{
                                name: 'market-brand',
                                params: {
                                    codename: brand.codename,
                                },
                            }"
                            class="brand link-primary-hover"
                        >{{ brand.name }}</RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import marketBrands from '@/store/modules/marketBrands.js';
import Observer from '@/components/Observer.vue';
import Spinner from '@/components/Spinner.vue';

export default {
    name: "MarketBrandsPage",

    components: {
        Spinner,
        Observer,
    },

    metaInfo() {
        return {
            title: this.meta.meta_title,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content: this.meta.meta_title },
                { property: 'og:description', content: this.meta.meta_description },
                { property: 'og:image', content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        return this.INIT();
    },

    data() {
        return {
            currentGroup: 0,
            showStickyHeader: false,
        };
    },

    computed: {
        ...mapState({
            initialized: state => state.marketBrands.initialized,
            meta: state => state.marketBrands.meta,
            items: state => state.marketBrands.brands.items,
        }),

        itemsByGroups() {
            return Object.values(
                this.items.reduce((result, brand) => {
                    let firstLetter = brand.name[0].toLocaleUpperCase();
                    const isFirstLetterNumber = (/[0-9]/g).test(firstLetter);
                    const isFirstLetterRu = (/[а-яёА-ЯЁ]/g).test(firstLetter);

                    if (isFirstLetterNumber) {
                        if (!result['0-9']) result['0-9'] = { letter: '0–9', data: [] };
                        result['0-9'].data.push(brand);
                    }
                    else if (isFirstLetterRu) {
                        if (!result['А-Я']) result['А-Я'] = { letter: 'А–Я', data: [] };
                        result['А-Я'].data.push(brand);
                    }
                    else if (!result[firstLetter]) {
                        result[firstLetter] = { letter: firstLetter, data: [brand] };
                    }
                    else {
                        result[firstLetter].data.push(brand);
                    }

                    return result;
                }, {}),
            );
        },
    },

    created() {
        this.$store.registerModule('marketBrands', marketBrands);
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.init();
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        this.$store.unregisterModule('marketBrands');
    },

    methods: {
        ...mapMutations({
            DESTROY: 'marketBrands/destroy',
        }),

        ...mapActions({
            INIT: 'marketBrands/init',
        }),

        async init() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'market',
                    },
                    title: 'Запчасти и автотовары',
                },
                {
                    to: {
                        name: 'market-brands',
                    },
                    title: 'Производители запчастей и автотоваров',
                },
            ]);

            if (!this.initialized) {
                await this.INIT();
            }
        },

        scrollToGroup(index) {
            if (index === -1) {
                window.scrollTo({ top: this.$refs.listWrap.offsetTop, behavior: 'smooth' });
            }
            else {
                window.scrollTo({ top: this.$refs.group[index].offsetTop - this.$refs.stickyHeaderOuter.offsetHeight, behavior: 'smooth' });
            }
        },

        intersected(value) {
            this.showStickyHeader = value !== 'in';
            if (this.$refs.LetterListStickyHeader) {
                const previousSibling = this.$refs.LetterListStickyHeader.previousElementSibling;
                if (previousSibling) {
                    if (this.showStickyHeader) {
                        document.documentElement.style.setProperty('--letter-list-sticky-header-top', previousSibling.offsetTop + previousSibling.offsetHeight + 'px');
                    }
                    else {
                        document.documentElement.style.setProperty('--letter-list-sticky-header-top', '-' + this.$refs.LetterListStickyHeader.offsetHeight + 'px');
                    }
                }
                else {
                    if (this.showStickyHeader) {
                        document.documentElement.style.setProperty('--letter-list-sticky-header-top', '0px');
                    }
                    else {
                        document.documentElement.style.setProperty('--letter-list-sticky-header-top', '-' + this.$refs.LetterListStickyHeader.offsetHeight + 'px');
                    }
                }
            }
        },

        handleScroll() {
            let current = 0;
            this.$refs.group.forEach((group, index) => {
                const groupTop = group.offsetTop;
                const groupHeight = group.clientHeight;
                if (pageYOffset >= groupTop - groupHeight / 3) {
                    current = index;
                }
            });
            this.currentGroup = current;
        },
    },
};
</script>

<style>
:root {
    --letter-list-sticky-header-top: -100%
}
</style>
<style scoped>
.wrap {
    padding: var(--gap-y-small);
}

.letter-list {
    display: flex;
    align-items: center;
    margin-right: -4px;
}
.letter-list_sticky {
    position: absolute;
    left: 0;
    z-index: calc(var(--z-index-sticky-header) - 1);
    width: 100%;
    background-color: var(--light-c);
    overflow: hidden;
    will-change: transform;
    transform: translateY(var(--letter-list-sticky-header-top));
    transition: top var(--transition), transform var(--transition);
}
@media (min-width: 768px) {
    .letter-list {
        margin-bottom: -8px;
        flex-wrap: wrap;
    }

    .letter-list_sticky {
        padding: 12px var(--content-gap);
    }
}
@media (max-width: 767px) {
    .letter-list_outer {
        overflow: hidden;
        margin-left: calc(var(--gap-y-small) * -1);
        margin-right: calc(var(--gap-y-small) * -1);
    }

    .letter-list {
        overflow: auto;
        padding-bottom: 40px;
        margin-bottom: -40px;
    }

    .letter-list::before,
    .letter-list::after {
        content: "";
        display: block;
        flex-shrink: 0;
        width: calc(var(--gap-y-small) - 12px);
        height: 1px;
    }

    .letter-list_outer_sticky {
        overflow: hidden;
    }

    .letter-list_sticky {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.letter-list__item {
    margin-right: 4px;
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .letter-list__item {
        margin-bottom: 8px;
    }
}

.letter-list__btn {
    display: block;
    min-width: 32px;
    padding: 6px 12px;
    text-align: center;
    background-color: transparent;
    border-radius: 16px;
    transition: background-color var(--transition);
}

.letter-list__btn_current,
.letter-list__btn:hover {
    background-color: var(--lightest-bg);
}

.group {
    padding-top: var(--gap-y-small);
}

.list {
    display: grid;
    grid-gap: var(--grid-gap);
    align-items: start;
}
@media (min-width: 1040px) {
    .list {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 767px) {
    .list {
        grid-template-columns: repeat(2, 1fr);
    }
}

.brand {
    display: block;
    font-size: var(--big-fz);
    word-break: break-word;
}
</style>