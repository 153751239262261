<template>
    <div class="market-item">
        <div class="market-item__photo-title-wrap">
            <MarketItemCover
                :item="item.offer[modelName]"
                :modelName="modelName"
                coverCodename="micro"
                class="cover-wrap"
                isButton
                @click="showOfferInfo"
            ></MarketItemCover>

            <div class="title-wrap">
                <h3 class="title">
                    <button
                        class="title-btn link-primary-hover"
                        @click="showOfferInfo"
                    >
                        {{ item.offer[modelName] && item.offer[modelName].name }}
                    </button>
                </h3>

                <p
                    v-if="orderStatus === 'partially_confirmed' && item.confirmed_amount >= item.total_amount"
                    class="text_green status mt-4"
                >
                    Есть в наличии
                </p>
                <p
                    v-if="orderStatus === 'partially_confirmed'
                        && !!item.confirmed_amount && item.confirmed_amount < item.total_amount"
                    class="status status_red mt-4"
                >
                    В наличии есть только {{ item.confirmed_amount | pieces }}
                </p>
                <p
                    v-if="(orderStatus === 'canceled_not_confirmed' || orderStatus === 'partially_confirmed')
                        && !!item.confirmed_amount === 0"
                    class="status status_red mt-4"
                >
                    Нет в наличии
                </p>

                <p
                    v-if="item.returned_quantity"
                    class="status status_red mt-4"
                >
                    Товар возвращён
                    <template v-if="item.returned_quantity < item.confirmed_amount">
                        {{ item.returned_quantity | pieces }}
                    </template>
                </p>
            </div>
        </div>

        <div class="market-item__amount-price-wrap">
            <span
                class="amount text_secondary text_semi-bold"
            >{{ item.total_amount | pieces }}</span>

            <span class="price">
                {{ item.total_price | price }}
            </span>
        </div>
    </div>
</template>

<script>
import MarketItemCover from "@/components/MarketItemCover.vue";
const MarketItemInfoWithOfferPopup = () => import('@/components/popups/MarketItemInfoWithOfferPopup.vue');


export default {
    name: 'ProfileOrderPosition',

    components: { MarketItemCover },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        orderStatus: {
            type: String,
            default: '',
        },
    },

    computed: {
        modelName() {
            return this.item.offer.offer_type;
        },
    },

    methods: {
        showOfferInfo() {
            const props = {
                offer: this.item.offer,
                marketItemId: this.item.offer[this.modelName].id,
                modelName: this.modelName,
            };
            this.$popup(MarketItemInfoWithOfferPopup, { props });
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .market-item {
        display: flex;
        align-items: center;
    }
}

.market-item__photo-title-wrap {
    display: flex;
    align-items: center;
}
@media (max-width: 767px) {
    .market-item__photo-title-wrap {
        margin-bottom: 12px;
    }
}

.market-item__amount-price-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

@media (min-width: 768px){
    .cover-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .cover-wrap {
        margin-right: 12px;
    }
}

@media (min-width: 768px) {
    .title-wrap {
        flex-grow: 1;
        margin-right: 24px;
    }
}

.title {
    display: inline-block;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}

.title-btn {
    text-align: left;
}

.amount {
    flex-shrink: 0;
}
@media (min-width: 768px) {
    .amount {
        width: 60px;
        text-align: right;
        margin-left: auto;
    }
}

.status {
    font-size: 12px;
    font-family: var(--f-semi-bold);
}

.status_red {
    color: var(--primary-color-original, var(--primary-color, red));
}

.price {
    font-family: var(--f-bold);
    text-align: right;
}
@media (min-width: 768px) {
    .price {
        width: 92px;
        flex-shrink: 0;
        margin-left: 36px;
    }
}
</style>
