export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        order: {},
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        set(state, order) {
            state.order = order;
        },

        destroy(state) {
            state.initialized = false;
            state.order = {};
        },
    },

    actions: {
        async init({ commit }, { uuid }) {
            try {
                const order = await this.$api.orders.get(uuid);

                commit('set', order);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { order };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },
    },
};