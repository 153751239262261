<template>
    <transition
        appear
        :name="fromBottomOnXS && breakpointKey === 's' ? 'slide-from-bottom' : 'fade'"
        @afterEnter="onTransitionEnd"
    >
        <div
            :class="[
                'popup-wrap',
                full && 'popup-wrap_full',
                semiFull && 'popup-wrap_semi-full',
                !noPadding && 'popup-wrap_padding',
                fromBottomOnXS && 'popup-wrap_xs-from-bottom',
                loading && 'popup-wrap_loading',
            ]"
        >
            <div class="outer">
                <div
                    class="back"
                    @click.self="$emit('clickaway')"
                ></div>

                <div class="popup">
                    <ButtonIcon
                        v-if="!loading"
                        :icon="{
                            name: 'cross',
                            big: true,
                            secondary: true,
                            hovered: true
                        }"
                        :secondary="false"
                        aria-label="Закрыть попап"
                        class="close-btn"
                        :class="{
                            'close-btn_shadow': closeBtn === 'outer',
                        }"
                        @click="$emit('close')"
                    ></ButtonIcon>

                    <USpinner
                        v-if="loading"
                        big
                        absoluteCenter
                    ></USpinner>

                    <slot
                        v-if="!fromBottomOnXS || fromBottomOnXS && (breakpointKey !== 'xs' && breakpointKey !== 's')"
                    ></slot>

                    <div
                        v-if="fromBottomOnXS && breakpointKey === 's'"
                        :key="breakpointKey"
                        class="content-from-bottom"
                    >
                        <ButtonIcon
                            v-if="!loading"
                            :icon="{
                                name: 'cross',
                                big: true,
                                secondary: true,
                                hovered: true
                            }"
                            :secondary="false"
                            aria-label="Закрыть попап"
                            class="close-btn_shadow popup-wrap_xs-from-bottom__close-btn"
                            @click="$emit('close')"
                        ></ButtonIcon>

                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import breakpointKey from '@/mixins/breakpointKey.js';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';


export default {
    name: 'PopupWrap',

    components: { USpinner, ButtonIcon },

    mixins: [breakpointKey],

    props: {
        closeBtn: {
            type: String,
            default: 'inner',
        },

        noPadding: {
            type: Boolean,
            default: false,
        },

        full: {
            type: Boolean,
            default: false,
        },

        semiFull: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        fromBottomOnXS: {
            type: Boolean,
            default: false,
        },
    },

    created() {
        this.getBreakpointKey();
    },

    methods: {
        onTransitionEnd($event) {
            this.$emit('transitionend', $event);
        },
    },
};
</script>

<style scoped>
.popup-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: var(--popup-z-index);
    overflow: auto;
}

.popup-wrap_loading >>> .popup-content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: -1;
}
.popup-wrap_padding >>> .popup-content {
    padding: var(--popup-paddings);
}
.popup-wrap_full >>> .popup-content {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100%;
}
@media (max-width: 767px) {
    .popup-wrap:not(.popup-wrap_full) >>> .popup-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        height: max-content;
    }
}

.outer {
    position: relative;
    min-height: 100%;
    width: 100%;
}
@media (min-width: 768px) {
    .outer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.popup {
    position: relative;
    min-height: 140px;
    min-width: 140px;
    z-index: 2;
    background-color: var(--light-c);
}
@media (min-width: 768px) {
    .popup {
        margin: 24px 0;
        border-radius: var(--border-radius-x2);
        box-shadow: var(--base-shadow);
    }
}
@media (max-width: 767px) {
    .popup {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
}


.popup-wrap_full .popup {
    width: 100%;
    margin: 0;
    align-self: stretch;
    border-radius: 0;
    flex-grow: 1;
}


.popup-wrap_semi-full .outer {
    height: 100%;
}
@media (min-width: 1040px) {
    .popup-wrap_semi-full .outer {
        padding: 48px 72px;
    }
}
.popup-wrap_semi-full .popup {
    width: 100%;
    height: 100%;
    margin: 0;
    align-self: stretch;
}


@media (max-width: 767px) {
    .popup-wrap_xs-from-bottom .outer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        background-color: transparent;
    }

    .popup-wrap_xs-from-bottom .popup {
        height: auto;
        max-height: calc(100% - 48px);
        padding: 0;
        box-shadow: var(--base-shadow);
        border-radius: var(--border-radius-x3) var(--border-radius-x3) 0 0;
        overflow: auto;
    }

    .popup-wrap_xs-from-bottom .back {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .popup-wrap_xs-from-bottom .close-btn {
        display: none;
    }

    .content-from-bottom {
        position: relative;
    }

    .content-from-bottom >>> .popup-content {
        overflow: auto;
        padding: 20px var(--content-gap);
    }

    .popup-wrap_xs-from-bottom__close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
    }
}


.back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
@media (min-width: 768px) {
    .back {
        background-color: rgba(0, 0, 0, 0.4);
    }
}
@media (max-width: 767px) {
    .back {
        display: none;
    }

    .popup-wrap_xs-from-bottom .back {
        display: block;
    }
}

.close-btn {
    z-index: var(--popup-close-button-z-index);
    width: 36px;
    height: 36px;
    padding: 6px;
}

.close-btn:hover,
.close-btn:focus {
    color: var(--dark-c);
}

.close-btn_shadow {
    background-color: var(--light-c);
    border-radius: 50%;
    box-shadow: var(--base-shadow);
    transform: scale(1);
    transition: all var(--transition-fastest);
}

.close-btn_shadow:hover,
.close-btn_shadow:focus {
    box-shadow: var(--hover-base-shadow);
    transform: scale(1.2);
}

@media (min-width: 768px) {
    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
@media (max-width: 767px) {
    .close-btn {
        position: fixed;
        top: 16px;
        right: 16px;
        background-color: var(--light-c);
        border-radius: 50%;
        box-shadow: var(--base-shadow);
        transform: scale(1);
        transition: all var(--transition-fastest);
    }
}


.fade-leave-to,
.fade-enter {
    opacity: 0;
}

.fade-enter .popup {
    transform: scale(0.5);
}

.fade-enter-to,
.fade-leave {
    opacity: 1
}

.fade-enter-to .popup,
.fade-leave .popup {
    transform: scale(1);
}

.fade-leave-active,
.fade-enter-active {
    transition: opacity .2s ease-out;
}

.fade-enter-active .popup {
    transition: transform .2s ease-out;
}


.slide-from-bottom-enter-active .back,
.slide-from-bottom-leave-active .back {
    transition: opacity var(--transition);
}

.slide-from-bottom-enter .back,
.slide-from-bottom-leave-to .back {
    opacity: 0;
}

.slide-from-bottom-enter .popup,
.slide-from-bottom-leave-to .popup {
    transform: translateY(100%);
}

.slide-from-bottom-enter-active,
.slide-from-bottom-leave-to,
.slide-from-bottom-enter-active .popup,
.slide-from-bottom-leave-to .popup {
    transition: all var(--transition);
}
</style>
