export default {
    namespaced: true,

    state: () => ({
        isInitialized: false,
        currentCity: {},
        currentCityId: null, // устанавливается до рендера
        cities: {},
        showCityPopup: false,
    }),

    mutations: {
        setCurrentCity(state, city) {
            state.currentCity = city;
        },

        setCities(state, cities) {
            state.cities = cities;
        },

        setIsInitialized(state) {
            state.isInitialized = true;
        },

        setCurrentCityId(state, id) {
            state.currentCityId = Number(id) || null;
        },

        setShowCityPopup(state, value) {
            state.showCityPopup = value;
        },
    },

    actions: {
        async prefetch({ state, commit }) {
            const id = state.currentCityId;
            const currentCity = state.currentCity;

            if (id && currentCity.id !== id) {
                try {
                    const city = await this.$api.cities.get(id);
                    commit('setCurrentCity', city);
                    commit('setIsInitialized');
                }
                catch (error) {
                    commit('handleInitError', error, { root: true });
                }
            }
        },

        async fetchCities({ commit }, config) {
            try {
                const { results } = await this.$api.cities.get(config);
                commit('setCities', results);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};