<template>
    <div class="top-gap_medium">
        <h1 class="h1 h1_block">
            Магазины
        </h1>

        <Spinner
            v-if="!initialized"
            size="big"
            center
        ></Spinner>

        <template v-else>
            <div
                v-if="items.length"
                class="grid"
            >
                <CompanyGridItem
                    v-for="company in items"
                    :key="'company-' + company.id"
                    :company="company"
                    class="grid__item"
                ></CompanyGridItem>
            </div>
            <EmptyBlock
                v-else
                icon="shop"
                title="Нет магазинов"
            >
                <template #text>
                    В <span class="text_bold">г. {{ currentCity.name }}</span> нет ни одного магазина, подключенного к&nbsp;Ubibi.
                </template>
                <template #button>
                    <ButtonBlock
                        primary
                        low
                        class="empty__btn"
                        @click="showChangeCityPopup"
                    >
                        Выбрать город или регион
                    </ButtonBlock>
                </template>
            </EmptyBlock>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import marketShops from '@/store/modules/marketShops.js';
import CompanyGridItem from "./CompanyGridItem.vue";
import Spinner from '@/components/Spinner.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
const ChangeCityPopup = () => import('@/components/popups/ChangeCityPopup.vue');

export default {
    name: "MarketShopsPage",

    components: {
        ButtonBlock,
        EmptyBlock,
        Spinner,
        CompanyGridItem,
    },

    metaInfo() {
        return {
            title: this.meta.meta_title,
            meta: [
                { name: 'description', content: this.meta.meta_description },
                { name: 'keywords', content: this.meta.meta_keywords },
                { property: 'og:title', content: this.meta.meta_title },
                { property: 'og:description', content: this.meta.meta_description },
                { property: 'og:image', content: this.meta.og_image ? this.$links.uploads + this.meta.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        return this.INIT();
    },

    computed: {
        ...mapState({
            currentCity: state => state.cities.currentCity,
            initialized: state => state.marketShops.initialized,
            meta: state => state.marketShops.meta,
            items: state => state.marketShops.shops.items,
        }),
    },

    created() {
        this.$store.registerModule('marketShops', marketShops);
    },

    mounted() {
        this.$breadcrumbs([
            {
                to: {
                    name: 'market',
                },
                title: 'Запчасти и автотовары',
            },
            {
                to: {
                    name: 'market-shops',
                },
                title: 'Магазины',
            },
        ]);

        if (!this.initialized) {
            this.INIT();
        }
    },

    beforeDestroy() {
        this.$store.unregisterModule('marketShops');
    },

    methods: {
        ...mapActions({
            INIT: 'marketShops/init',
        }),

        showChangeCityPopup() {
            this.$popup(ChangeCityPopup);
        },
    },
};
</script>

<style scoped>
.grid {
    display: grid;
    grid-gap: var(--y-grid-gap);
}
@media (min-width: 1040px) {
    .grid {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 767px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>