<template functional>
    <div class="post-editor__block">
        <div
            :class="[
                props.widget.marginTop ? 'indent-wrap_mt' + props.widget.marginTop : '',
                props.widget.marginBottom ? 'indent-wrap_mb' + props.widget.marginBottom : '',
                props.widget.paddingTop ? 'indent-wrap_pt' + props.widget.paddingTop : '',
                props.widget.paddingBottom ? 'indent-wrap_pb' + props.widget.paddingBottom : '',
            ]"
            class="indent-wrap"
        >
            <div class="quote">
                <p class="quote__text">
                    {{ props.widget.text }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PostEditorQuote",

    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style scoped>
.quote {
    display: flex;
    align-items: flex-start;
    font-family: var(--f-bold);
}

.quote::before {
    content: "“";
    flex-shrink: 0;
    font-family: var(--f-bold);
    line-height: 1;
    color: var(--primary-color);
}
@media (min-width: 768px) {
    .quote::before {
        margin-right: 16px;
        font-size: 36px;
    }
}
@media (max-width: 767px) {
    .quote::before {
        margin-right: 12px;
        font-size: 24px;
    }
}

.quote__text {
    line-height: var(--small-lh);
}
@media (min-width: 768px) {
    .quote__text {
        font-size: 18px;
    }
}
@media (max-width: 767px) {
    .quote__text {
        font-size: 16px;
    }
}
</style>