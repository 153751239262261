<template>
    <div class="payments">
        <img
            src="../assets/images/visa.svg"
            alt="Логотип платежной системы Visa"
            class="payment-logo"
            width="54"
            height="16"
        >
        <img
            src="../assets/images/mastercard.svg"
            alt="Логотип платежной системы Mastercard"
            class="payment-logo"
            width="28"
            height="16"
        >

        <img
            src="../assets/images/mir.svg"
            alt="Логотип платежной системы МИР"
            class="payment-logo"
            width="58"
            height="16"
        >
    </div>
</template>

<script>
export default {
    name: "Payments",
};
</script>

<style scoped>
.payments {
    display: flex;
    align-items: center;
}

.payment-logo:not(:last-child) {
    margin-right: 24px;
}
</style>