<template>
    <div class="top-gap_medium">
        <Spinner
            v-if="!initialized"
            size="big"
            center
        ></Spinner>

        <template v-else>
            <div
                v-if="$route.params.slug === tireSelectionCategory.slug"
                class="flex align-items-center"
            >
                <h1 class="h1 h1_block right-gap_medium flex-shrink-0">
                    <span>Шины</span><span
                        v-if="products.current_count"
                        class="h1-sub"
                    >{{ products.current_count | number }}</span>
                </h1>
                <RouterLink
                    :to="{
                        name: 'market-products-category',
                        params: {
                            slug: rimSelectionCategory.slug,
                        },
                        query: carQuery,
                    }"
                    class="h1 h1_block text_secondary link-dark-hover flex-shrink-0"
                >
                    <span>Диски</span><span
                        v-if="parallelCategoryCurrentCount"
                        class="h1-sub"
                    >{{ parallelCategoryCurrentCount | number }}</span>
                </RouterLink>
            </div>
            <div
                v-else-if="$route.params.slug === rimSelectionCategory.slug"
                class="flex align-items-center"
            >
                <RouterLink
                    :to="{
                        name: 'market-products-category',
                        params: {
                            slug: tireSelectionCategory.slug,
                        },
                        query: carQuery,
                    }"
                    class="h1 h1_block right-gap_medium text_secondary link-dark-hover flex-shrink-0"
                >
                    <span>Шины</span><span
                        v-if="parallelCategoryCurrentCount"
                        class="h1-sub"
                    >{{ parallelCategoryCurrentCount | number }}</span>
                </RouterLink>
                <h1 class="h1 h1_block flex-shrink-0">
                    <span>Диски</span><span
                        v-if="products.current_count"
                        class="h1-sub"
                    >{{ products.current_count | number }}</span>
                </h1>
            </div>
            <h1
                v-else
                class="h1 h1_block"
            >
                <span>{{ category.page_title }}</span><span
                    v-if="category.is_leaf && products.current_count"
                    class="h1-sub"
                >{{ products.current_count | number }}</span>
            </h1>

            <div class="content">
                <template v-if="category.is_leaf">
                    <SelectionInCategory
                        v-if="products.total_count"
                        :value="filterData"
                        :filters="selectionFilters"
                        :flatFilters="flatFilters"
                        :resultCurrentCount="products.current_count"
                        :resultLoading="loading"
                        @selectSizeTemplate="getParallelCategoryCount"
                        @change="onChangeFilter"
                        @reload="reload"
                    ></SelectionInCategory>

                    <MarketItemsListWithOffers
                        apiName="products"
                        modelName="product"
                        :items="products.items"
                        :amount="products.current_count"
                        :totalAmount="products.total_count"
                        :staticFilters="staticFilters"
                        :dynamicFilters="dynamicFilters"
                        :checkedFilters="checkedFilters"
                        :filterData="filterData"
                        :sortData="sortData"
                        :sortOptions="sortOptions"
                        :hasNext="hasNext"
                        :loading="loading"
                        reviewsInPopup
                        @changeFilter="onChangeFilter"
                        @resetFilters="onResetFilters"
                        @deleteFilter="onDeleteFilter"
                        @changeSort="onChangeSort"
                        @reload="reload"
                        @load="load"
                        @changeFavorite="onChangeFavorite"
                    >
                        <template #inStockEmpty>
                            <EmptyBlock
                                icon="empty-box"
                                title="Нет предложений"
                                btnText="Перейти в каталог"
                                :btnUrl="{
                                    name: 'market-products-catalog',
                                }"
                            >
                                <template #text>
                                    В категории <b>«{{ category.name }}»</b> нет актуальных предложений.
                                </template>
                            </EmptyBlock>
                        </template>
                        <template #totalEmpty>
                            <EmptyBlock
                                icon="empty-box"
                                title="Пустая категория"
                                btnText="Перейти в каталог"
                                :btnUrl="{
                                    name: 'market-products-catalog',
                                }"
                            >
                                <template #text>
                                    В категории <b>«{{ category.name }}»</b> нет товаров.
                                </template>
                            </EmptyBlock>
                        </template>
                    </MarketItemsListWithOffers>
                </template>

                <MarketCatalog
                    v-else
                    :categories="categoryChildren"
                ></MarketCatalog>
            </div>

            <LastSee v-if="category.is_leaf"></LastSee>

            <SeoBlock>
                <template
                    v-if="category.seo_description"
                    #seo
                >{{ category.seo_description }}</template>
            </SeoBlock>
        </template>
    </div>
</template>

<script>
import { HTTP } from '@/http.js';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import marketProductsCategory from '@/store/modules/marketProductsCategory.js';
import { animationDurationFast } from '@/plugins/animationSettings.js';
import equals from '@/lib/equals.js';
import MarketItemsListWithOffers from '@/components/MarketItemsListWithOffers.vue';
import MarketCatalog from '@/components/MarketCatalog.vue';
import SelectionInCategory from '@/components/selection/SelectionInCategory.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import Spinner from '@/components/Spinner.vue';
import SeoBlock from '@/components/SeoBlock.vue';
import LastSee from '@/components/LastSee.vue';
const ReviewsPopup = () => import('@/components/popups/ReviewsPopup.vue');


let parallelCategoryFilterOptions;

export default {
    name: 'ProductCategoryPage',

    metaInfo() {
        return {
            title: this.category.meta_title,
            meta: [
                { name: 'description', content: this.category.meta_description },
                { name: 'keywords', content: this.category.meta_keywords },
                { property: 'og:title', content: this.category.meta_title },
                { property: 'og:description', content: this.category.meta_description },
                { property: 'og:image', content: this.category.og_image ? this.$links.uploads + this.category.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        LastSee,
        SeoBlock,
        Spinner,
        EmptyBlock,
        MarketItemsListWithOffers,
        MarketCatalog,
        SelectionInCategory,
    },

    serverPrefetch() {
        return this.INIT({ route: this.$route });
    },

    beforeRouteUpdate(to, from, next) {
        if (!equals(to.params, from.params)) {
            this.DESTROY();
            parallelCategoryFilterOptions = null;
            this.rangeData.offset = 0;
        }
        next();
    },

    data() {
        return {
            rangeData: {
                limit: 36,
                offset: 0,
            },
            source: null,

            parallelCategoryCurrentCount: 0,
        };
    },

    computed: {
        ...mapState({
            tireSelectionCategory: state => state.settings.tire_selection_category,
            rimSelectionCategory: state => state.settings.rim_selection_category,
            category: state => state.marketProductsCategory.category.item,
            categoryChildren: state => state.marketProductsCategory.category.children.items,
            products: state => state.marketProductsCategory.products,
            hasNext: state => state.marketProductsCategory.products.hasNext,
            initialized: state => state.marketProductsCategory.initialized,
            loading: state => state.marketProductsCategory.loading,
            staticFilters: state => state.marketProductsCategory.filter.staticFilters,
            dynamicFilters: state => state.marketProductsCategory.filter.dynamicFilters,
            selectionFilters: state => state.marketProductsCategory.filter.selectionFilters,
            checkedFilters: state => state.marketProductsCategory.filter.checked,
            filterData: state => state.marketProductsCategory.filter.value,
            sortOptions: state => state.marketProductsCategory.sort.options,
            sortData: state => state.marketProductsCategory.sort.value,
            currentCity: state => state.cities.currentCity,
        }),

        ...mapGetters({
            breadcrumbs: 'marketProductsCategory/breadcrumbs',
            filterQuery: 'marketProductsCategory/filter/query',
            sortQuery: 'marketProductsCategory/sort/query',
            flatFilters: 'marketProductsCategory/filter/flat',
        }),

        carQuery() {
            return {
                car_manufacturer: this.$route.query.car_manufacturer,
                car_model: this.$route.query.car_model,
                car_year: this.$route.query.car_year,
                car_modification: this.$route.query.car_modification,
            };
        },

        location() {
            return {
                name: this.$route.name,
                query: Object.assign(
                    {},
                    this.filterQuery,
                    this.sortQuery,
                    this.carQuery,
                ),
                meta: {
                    savePosition: true,
                },
            };
        },

        fetchParams() {
            return Object.assign(
                {
                    category: this.category.id,
                    get_facets: 'all',
                },
                this.filterQuery,
                this.sortQuery,
                this.rangeData,
            );
        },
    },

    watch: {
        '$route.params.slug': {
            handler() {
                this.DESTROY();
                parallelCategoryFilterOptions = null;
                this.init();
            },
        },
    },

    created() {
        this.$store.registerModule('marketProductsCategory', marketProductsCategory);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        parallelCategoryFilterOptions = null;
        this.$store.unregisterModule('marketProductsCategory');
    },

    methods: {
        ...mapMutations({
            DELETE_FILTER: 'marketProductsCategory/filter/deleteFilter',
            RESET_FILTERS: 'marketProductsCategory/filter/reset',
            SET_SORT_VALUE: 'marketProductsCategory/sort/setValue',
            SET_PRODUCTS: 'marketProductsCategory/setProducts',
            ADD_PRODUCTS: 'marketProductsCategory/addProducts',
            CHANGE_FAVORITE: 'marketProductsCategory/changeFavorite',
            CHANGE_CART: 'marketProductsCategory/changeCart',
            SET_LOADING: 'marketProductsCategory/setLoading',
            DESTROY: 'marketProductsCategory/destroy',
            setStaticFilters: 'marketProductsCategory/filter/setStaticFilters',
            setDynamicFilters: 'marketProductsCategory/filter/setDynamicFilters',
            setSelectionFilters: 'marketProductsCategory/filter/setSelectionFilters',
        }),

        ...mapActions({
            INIT: 'marketProductsCategory/init',
            GET_PRODUCTS: 'marketProductsCategory/getProducts',
            CHANGE_FILTER: 'marketProductsCategory/filter/change',
        }),

        async init() {
            if (!this.initialized) await this.INIT({ route: this.$route });

            this.updateBreadcrumbs();

            if (this.category.is_leaf) {
                this.replaceQuery();
                this.getParallelCategoryCount();
            }
        },

        async fetch() {
            this.SET_LOADING(true);

            if (this.source) {
                this.source.cancel();
            }

            this.source = HTTP.CancelToken.source();

            const payload = {
                params: this.fetchParams,
                cancelToken: this.source.token,
            };

            try {
                const response = await this.GET_PRODUCTS(payload);
                this.source = null;
                await this.updateQuery();
                return response;
            }
            catch (e) {
                console.error(e);
            }

            return '';
        },

        async load() {
            this.rangeData.offset += this.rangeData.limit;
            const response = await this.fetch();
            if (response) {
                this.SET_LOADING(false);
                this.updateFiltersOptions(response.facets, response.current_count);
                this.ADD_PRODUCTS(response);
            }
        },

        async reload() {
            this.rangeData.offset = 0;
            const response = await this.fetch();
            if (response) {
                this.updateFiltersOptions(response.facets, response.current_count);

                // Двойной по веремени timeout для того, чтобы сначала точно завершилась анимация фильтров,
                // и только потом произошел ререндер списка
                setTimeout(() => {
                    this.SET_LOADING(false);
                    this.SET_PRODUCTS(response);
                }, animationDurationFast * 2);
            }
        },

        onChangeFilter(payload) {
            this.CHANGE_FILTER(payload);
        },

        onResetFilters() {
            this.RESET_FILTERS();
            this.reload();
        },

        onDeleteFilter(filter) {
            this.DELETE_FILTER(filter);
            this.reload();
        },

        updateFiltersOptions(facets, current_count) {
            this.setStaticFilters({ config: this.staticFilters, facets });
            this.setDynamicFilters({ config: this.dynamicFilters, facets, current_count });
            this.setSelectionFilters({ config: this.selectionFilters, facets });
        },

        isNewRoute() {
            const toPath = this.$router.resolve(this.location).route.fullPath;
            return this.$route.fullPath !== toPath;
        },

        async updateQuery() {
            if (this.isNewRoute()) {
                try {
                    return await this.$router.push(this.location);
                }
                catch (error) {
                    console.error(error);
                }
            }
            else {
                return '';
            }
        },

        async replaceQuery() {
            if (this.isNewRoute()) {
                try {
                    return await this.$router.replace(this.location);
                }
                catch (error) {
                    console.error(error);
                }
            }
            else {
                return '';
            }
        },

        onChangeFavorite(data) {
            this.CHANGE_FAVORITE(data);
        },

        onChangeSort(value) {
            this.SET_SORT_VALUE(value);
            this.reload();
        },

        updateBreadcrumbs() {
            this.$breadcrumbs([
                ...this.breadcrumbs,
                {
                    to: {
                        name: 'market-products-category',
                        params: {
                            slug: this.$route.params.slug,
                        },
                    },
                    title: this.category.name,
                },
            ]);
        },

        async getParallelCategoryCount(outerValues) {
            let currentCategory;
            let parallelCategory = {};
            let parallelCategoryFiltersCodenames;
            const filtersMap = {
                'tire_width': 'tire_width',
                'tire_aspect_ratio': 'tire_height',
                'tire_diameter': 'tire_diameter',
                'rim_width': 'rim_width',
                'rim_diameter': 'rim_diameter',
                'rim_et': 'rim_offset',
            };

            if (this.$route.params.slug === this.tireSelectionCategory.slug) {
                currentCategory = 'tire';
                parallelCategory = this.rimSelectionCategory;
                parallelCategoryFiltersCodenames = ['rim_width', 'rim_diameter', 'rim_et'];
            }
            else if (this.$route.params.slug === this.rimSelectionCategory.slug) {
                currentCategory = 'rim';
                parallelCategory = this.tireSelectionCategory;
                parallelCategoryFiltersCodenames = ['tire_width', 'tire_aspect_ratio', 'tire_diameter'];
            }

            if (currentCategory) {
                let params = {
                    category: parallelCategory.id,
                    limit: 0,
                    city: this.currentCity.id,
                    in_stock: true,
                };

                if (outerValues) {
                    if (!parallelCategoryFilterOptions) {
                        // Получаю опции из фасетов,
                        // потому что значения из блока подбора, не есть id нашего апи
                        const { facets } = await this.$api.esProducts.get({
                            params: {
                                category: parallelCategory.id,
                                get_facets: 'all',
                                limit: 0,
                                city: this.currentCity.id,
                                in_stock: true,
                            },
                        });
                        parallelCategoryFilterOptions = facets.characteristics;
                    }
                    parallelCategoryFiltersCodenames.forEach(codename => {
                        const filter = parallelCategoryFilterOptions.find(item => item.codename === codename);
                        if (filter) {
                            const value = filter.options.find(item => item.name == outerValues[filtersMap[codename]]);
                            if (value) {
                                params[codename] = value.id;
                            }
                        }
                    });
                }

                const response = await this.$api.esProducts.get({ params });
                if (response) this.parallelCategoryCurrentCount = response.current_count;
            }
        },
    },
};
</script>