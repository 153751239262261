<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <FormManager
            ref="form"
            v-model="formData"
            class="popup-content"
            @submit="save"
        >
            <h2 class="h2 h2_block">
                E-mail
            </h2>

            <FormField
                type="email"
                name="email"
                label="E-mail"
                offModifyLabel
                validations="required|email"
            ></FormField>

            <div class="actions-wrap">
                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="close"
                >
                    Отмена
                </ButtonBlock>
                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    :disabled="isEmailsEquals"
                    class="action-btn"
                >
                    Сохранить
                </ButtonBlock>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import { mapState } from 'vuex';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import handleFormErrorMixin from '@/mixins/handleFormErrorMixin.js';

export default {
    name: 'ChangeEmailPopup',

    components: { FormField, FormManager, ButtonBlock, PopupWrap },

    mixins: [popup, handleFormErrorMixin],

    data() {
        return {
            formData: {
                email: '',
            },
            loading: false,
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),

        isEmailsEquals() {
            return this.profile.email ===  this.formData.email;
        },
    },

    created() {
        this.formData.email = this.profile.email || '';
    },

    methods: {
        async save() {
            const errors = await this.$refs.form.validate();
            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.loading = true;
                await this.$api.users.changeEmail(this.formData)
                    .then(response => {
                        this.close();
                        this.$success('Изменения сохранены');
                        this.$store.commit('profile/setProfile', response);
                    })
                    .catch(error => {
                        this.$refs.form.handle(error);
                        this.$store.commit('handleCommonHttpError', error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 476px;
    }
}

.actions-wrap {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .actions-wrap {
        margin-top: auto;
    }

    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}
</style>