export default {
    namespaced: true,

    state: () => ({
        items: [],
        totalPrice: 0,
        availablePrice: 0,
        totalCount: 0,
        availableCount: 0,
        initialized: false,
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        set(state, data) {
            state.items = data.orders;
            state.totalPrice = data.total_price;
            state.availablePrice = data.total_available_price;
            state.totalCount = data.total_amount;
            state.availableCount = data.total_available_amount;
        },

        clearCart(state) {
            state.items = [];
            state.totalPrice = 0;
            state.availablePrice = 0;
            state.totalCount = 0;
            state.availableCount = 0;
        },

        setTotalCount(state, count) {
            state.totalCount = count;
        },

        changeFavorite(state, { id, type, value }) {
            state.items.forEach(order => {
                order.positions.find(item => {
                    if (item.offer.offer_type === type && item.offer[type].id === id) {
                        item.offer[type].is_favorite = value;
                    }
                });
            });
        },

        destroy(state) {
            state.initialized = false;
        },
    },

    actions: {
        async init({ commit }) {
            try {
                const cart = await this.$api.basket.get();
                commit('set', cart);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
                return error;
            }
        },

        async getCart({ commit }) {
            try {
                const cart = await this.$api.basket.get();
                commit('set', cart);
                return { cart };
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
                return error;
            }
        },

        async clearCart({ commit }) {
            try {
                await this.$api.basket.clearCart();
                commit('clearCart');
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
                return error;
            }
        },

        async addToCart({ commit }, { data, cancelToken }) {
            try {
                const cart = await this.$api.basket.addToBasket(data, { cancelToken });
                commit('set', cart);
                return { cart };
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
                throw error;
            }
        },

        async deleteFromCart({ commit }, { data }) {
            try {
                const cart = await this.$api.basket.deleteFromBasket(data);
                commit('set', cart);
                return { cart };
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
                return error;
            }
        },

        async createOrder({ commit }, { data }) {
            try {
                return await this.$api.orders.create(data);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
                throw error;
            }
        },
    },
};