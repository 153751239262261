export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        categories: {
            items: [],
            current_count: 0,
        },

        meta: {},
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setCategories(state, { current_count, results }) {
            state.categories.items = results;
            state.categories.current_count = current_count;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },
    },

    actions: {
        async init({ commit, dispatch }) {
            try {
                const [categories, meta] = await Promise.all(
                    [
                        dispatch('getCategories'),
                        dispatch('getMeta'),
                    ],
                );

                commit('setCategories', categories);
                commit('setMeta', meta);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { categories, meta };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getCategories() {
            const params = {
                parent_id__isnull: 1,
                limit: 100000,
                offset: 0,
            };

            return await this.$api.goodsCategories.get({ params });
        },

        async getMeta() {
            return await this.$api.staticPages.get('market-products-catalog');
        },
    },
};