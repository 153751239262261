export default {
    namespaced: true,

    state: () => ({
        // Это значение синхронизировано с localStorage через vuex-persistedstate
        value: null,
        // Если флаг меняется на true, то должна произойти перезагрузка
        trigger: false,
    }),

    mutations: {
        // Перед запуском checkValue надо присвоить значение через setValue,
        // чтобы защитить текущую вкладку от перезагрузки
        setValue(state, value) {
            state.value = value;
        },

        // Эта мутация расшарена на всех вкладки с помощью vuex-shared-mutations
        checkValue(state, value) {
            if (state.value !== value) {
                state.trigger = true;
            }
        },
    },

    actions: {
        reloadInactiveTabs({ commit }) {
            const value = Math.random();
            commit('setValue', value);
            commit('checkValue', value);
        },
    },
};