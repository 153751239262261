<template>
    <div class="navigation-block">
        <RoundArrowBtn
            direction="left"
            size="48"
            shadow
            class="navigation__arrow navigation__arrow_prev"
            :class="{
                'navigation__arrow_visible': canScrollLeft,
            }"
            @mouseenter="startScroll('left')"
            @mouseleave="stopScroll"
        ></RoundArrowBtn>

        <div class="navigation_outer">
            <div
                ref="navigation"
                class="navigation"
                @scroll="checkScroll"
            >
                <slot></slot>
            </div>
        </div>

        <RoundArrowBtn
            direction="right"
            size="48"
            shadow
            class="navigation__arrow navigation__arrow_next"
            :class="{
                'navigation__arrow_visible': canScrollRight,
            }"
            @mouseenter="startScroll('right')"
            @mouseleave="stopScroll"
        ></RoundArrowBtn>
    </div>
</template>

<script>
import RoundArrowBtn from '@/components/project-buttons/RoundArrowBtn.vue';
import equals from '@/lib/equals.js';

export default {
    name: 'ScrollSlider',

    components: { RoundArrowBtn },


    data() {
        return {
            interval: null,
            canScrollLeft: false,
            canScrollRight: false,
        };
    },

    mounted() {
        this.checkScroll();
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    methods: {
        checkScroll() {
            let blockWidth = this.$refs.navigation.offsetWidth;
            let scrollWidth = this.$refs.navigation.scrollWidth;
            let scrollValue = this.$refs.navigation.scrollLeft;

            this.canScrollRight = scrollValue < scrollWidth - blockWidth;
            this.canScrollLeft = scrollValue > 0;
        },

        startScroll(direction) {
            let blockWidth = this.$refs.navigation.offsetWidth;
            let scrollWidth = this.$refs.navigation.scrollWidth;
            let scrollValue = this.$refs.navigation.scrollLeft;

            this.interval = setInterval(() => {
                if (direction === 'left') {
                    if (scrollValue > 0) {
                        scrollValue -= 1;
                    }
                    else {
                        clearInterval(this.interval);
                    }
                }
                else {
                    if (scrollValue < scrollWidth - blockWidth) {
                        scrollValue += 1;
                    }
                    else {
                        clearInterval(this.interval);
                    }
                }

                this.$refs.navigation.scrollLeft = scrollValue;
            }, 1);
        },

        stopScroll() {
            clearInterval(this.interval);
        },
    },
};
</script>

<style scoped>
.navigation-block {
    position: relative;
}

.navigation_outer {
    overflow: hidden;
}

.navigation {
    display: flex;
    margin-bottom: -40px;
    padding-bottom: 40px;
    overflow: auto;
}

.navigation__arrow {
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    background-color: var(--light-c);
    visibility: hidden;
    opacity: 0;
    transition: opacity var(--transition), visibility var(--transition);
}
@media (max-width: 767px) {
    .navigation__arrow {
        display: none;
    }
}

.navigation__arrow_visible {
    opacity: 1;
    visibility: visible;
}

.navigation__arrow_prev {
    left: -24px;
}

.navigation__arrow_next {
    right: -24px;
}
</style>