var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pay-loading-page"},[_c('BackLink',{staticClass:"back-link",attrs:{"text":"Изменить данные","to":{
            name: 'insurance-osago-checkout',
            params: {
                uuid: _vm.uuid,
            },
        }}}),_vm._v(" "),_c('h1',{staticClass:"h1 h1_text title text_center"},[_vm._v("\n        Оформление полиса ОСАГО\n    ")]),_vm._v(" "),_c('div',{staticClass:"description"},[(_vm.timeIsOver)?[_c('p',[_vm._v("\n                К сожалению, мы не получили ответ от выбранной страховой компании и,\n                в настоящий момент, нет других подходящих предложений.\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n                Возможно, дело в неверных данных или технической ошибке.\n                Попробуйте отправить запрос ещё раз через некоторое время.\n            ")])]:_c('p',[_vm._v("\n            Пожалуйста, подождите... Мы отправили ваши данные в страховую компанию.\n            Сейчас происходит проверка информации и формирование электронного полиса ОСАГО.\n            Это может занять несколько минут.\n        ")])],2),_vm._v(" "),(_vm.timeIsOver)?_c('div',{staticClass:"buttons-wrap"},[_c('ButtonText',{staticClass:"button repeat-button",attrs:{"primary":"","dashed":""},on:{"click":_vm.repeat}},[_vm._v("\n            Повторить запрос\n        ")]),_vm._v(" "),_c('ButtonText',{staticClass:"button",attrs:{"secondary":"","dark":"","dashed":"","to":{
                name: 'insurance-osago-checkout',
                params: {
                    uuid: _vm.uuid,
                },
            }}},[_vm._v("\n            Изменить данные\n        ")])],1):_c('BackTimerText',{staticClass:"timer",attrs:{"seconds":239,"withSeconds":""},on:{"timeOver":_vm.onTimeOver}}),_vm._v(" "),_c('img',{staticClass:"image",attrs:{"srcset":require("../../../../assets/images/insurance/pay-loading-x2.jpg") + " 2x, " + require("../../../../assets/images/insurance/pay-loading.jpg"),"src":require("../../../../assets/images/insurance/pay-loading.jpg"),"width":"400","height":"300","alt":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }