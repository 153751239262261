var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-text",class:{
        'input-text_type-textarea': _vm.textarea,
        'input-text_disabled': _vm.disabled,
        'input-text_focused': _vm.focusedComputed,
        'input-text_hover': _vm.hoveredComputed,
        'input-text_invalid': _vm.invalid,
        'input-text_filled': _vm.value || _vm.isFilled,
    },on:{"click":_vm.onClick,"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[(_vm.textarea)?_c('textarea',_vm._g(_vm._b({ref:"textarea",staticClass:"input-text__input input-text__textarea",class:[
            _vm.inputClasses,
            {
                'input-text__textarea_default-height': !_vm.rows
            }
        ],attrs:{"id":_vm.id,"name":_vm.id,"rows":_vm.rows,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur}},'textarea',_vm.$attrs,false),_vm.listeners)):_c('input',_vm._g(_vm._b({ref:"input",staticClass:"input-text__input",class:[
            _vm.inputClasses,
            {
                'input-text__input_center': _vm.center,
                'input-text__input_small': _vm.small,
                'input-text__input_invalid': _vm.invalid,
                'input-text__input_reset-right-offset': _vm.icons.length || _vm.showAppendIcon,
                'input-text__input_left-offset': _vm.prependIcon,
            }
        ],attrs:{"id":_vm.id,"name":_vm.id,"type":_vm.type,"disabled":_vm.disabled,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter.apply(null, arguments)},_vm.onKeyDown]}},'input',_vm.$attrs,false),_vm.listeners)),_vm._v(" "),((_vm.placeholder && _vm.visibleMask && _vm.focusedComputed) || (!_vm.placeholder && (!_vm.label || _vm.label && _vm.focusedComputed) && _vm.visibleMask))?_c('span',{staticClass:"input-text__placeholder input-text__mask-placeholder",class:{
            'input-text__placeholder_focused': _vm.focusedComputed,
            'input-text__mask-placeholder_filled': _vm.value || _vm.isFilled,
        }},[_c('i',{staticClass:"input-text__mask-placeholder-value"},[_vm._v(_vm._s(_vm.computedMask.value))]),_vm._v(_vm._s(_vm.computedMask.rest)+"\n    ")]):_vm._e(),_vm._v(" "),((_vm.placeholder && !(_vm.value || _vm.isFilled) && !_vm.visibleMask) || (_vm.placeholder && !(_vm.value || _vm.isFilled) && _vm.visibleMask && !_vm.focusedComputed))?_c('span',{staticClass:"input-text__placeholder",class:{
            'input-text__placeholder_focused': _vm.focusedComputed,
            'input-text__placeholder_in-small': _vm.small,
        }},[_vm._v(_vm._s(_vm.placeholder))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"prepend-icon-wrap"},[_vm._t("prepend-icon",function(){return [(_vm.prependIcon)?_c('UIcon',_vm._b({},'UIcon',_vm.prependIcon,false)):_vm._e()]})],2),_vm._v(" "),(_vm.label)?_c('label',{staticClass:"input-text__label",attrs:{"for":_vm.id}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.icons.length || _vm.showAppendIcon),expression:"icons.length || showAppendIcon"}],staticClass:"icons-sidebar"},[_vm._l((_vm.icons),function(icon,index){return _c(icon,{key:index,tag:"component",staticClass:"icons-sidebar__item"})}),_vm._v(" "),_vm._t("append-icon",function(){return [(_vm.showAppendIcon)?_c('div',{staticClass:"icons-sidebar__item"},[_c('UIcon',_vm._b({},'UIcon',_vm.appendIcon,false))],1):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }