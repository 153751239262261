<template>
    <TransitionExpandFade>
        <div
            v-if="open"
            ref="dropdownMenuWrap"
            v-on-clickaway="close"
            class="options-list-wrap"
        >
            <div
                class="pseudo-btn"
                @click="close"
            ></div>

            <ul
                ref="dropdownMenu"
                class="options-list"
                role="listbox"
                @click="close"
            >
                <li
                    v-for="option in options"
                    :key="option.codename"
                    role="option"
                    class="options-list__item"
                >
                    <div
                        v-if="selectedOption.codename === option.codename"
                        class="option option_selected"
                    >
                        <UIcon
                            :name="option.icon"
                            big
                            class="option__icon"
                        ></UIcon>
                        <span>
                            {{ option.title }}
                        </span>
                    </div>

                    <RouterLink
                        v-else
                        :to="{
                            name: option.codename
                        }"
                        class="option link-primary-hover"
                    >
                        <UIcon
                            :name="option.icon"
                            big
                            class="option__icon"
                        ></UIcon>
                        <span>
                            {{ option.title }}
                        </span>
                    </RouterLink>
                </li>
            </ul>
        </div>
    </TransitionExpandFade>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import TransitionExpandFade from "./_transitions/TransitionExpandFade.vue";
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: "SelectMenu",

    components: { UIcon, TransitionExpandFade },

    mixins: [clickaway],

    props: {
        title: {
            type: String,
            default: '',
        },

        options: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            open: false,
        };
    },

    computed: {
        selectedOption() {
            let selectedOption;
            this.options.forEach(option => {
                if (option.codename === this.$route.name) {
                    selectedOption = option;
                }
                else if (option.children) {
                    option.children.forEach(child => {
                        if (child === this.$route.name) selectedOption = option;
                    });
                }
            });
            return selectedOption;
        },
    },

    methods: {
        toggleDropdown() {
            this.open = !this.open;
            this.$emit('change', this.open);
        },

        close() {
            this.open = false;
            this.$emit('change', this.open);
        },
    },
};
</script>

<style scoped>
.options-list-wrap {
    position: absolute;
    z-index: 4;
    overflow: auto;
    outline: none;
    box-shadow: var(--hover-light-shadow);
    border-radius: var(--border-radius-x2);
}
@media (min-width: 768px) {
    .options-list-wrap {
        background-color: var(--light-c);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .options-list-wrap {
        top: calc(100% + 8px);
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (max-width: 767px) {
    .options-list-wrap {
        width: var(--container);
        top: 0;
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .options-list {
        width: 260px;
    }
}
@media (max-width: 767px) {
    .options-list {
        padding-top: 4px;
        padding-bottom: 4px;
        border-top: 1px solid var(--border-light-c);
        background-color: var(--light-c);
    }
}

.option {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    text-align: left;
    font-family: var(--f-semi-bold);
}

.option__icon {
    margin-right: 12px;
    fill: currentColor;
}

@media (min-width: 768px) {
    .pseudo-btn {
        display: none;
    }
}
@media (max-width: 767px) {
    .pseudo-btn {
        height: 56px;
        opacity: 0;
        cursor: pointer;
    }
}
</style>