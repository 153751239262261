<template>
    <div class="u-select">
        <UDropdown
            ref="dropdown"
            :options="filteredOptions"
            :hasMore="hasMore"
            :loadingMore="loadingMore"
            :initialized="initialized"
            :optionKey="optionKey"
            :optionLabel="optionLabel"
            :getOptionKey="getOptionKey"
            :getOptionLabel="getOptionLabel"
            :selectedOptions="[innerValue]"
            @select="onSelect"
            @open="opened = true; emitOpen()"
            @close="opened = false; emitClose()"
            v-on="listeners"
        >
            <template #default="{ handlers }">
                <UInputField
                    v-if="searchable"
                    ref="input"
                    :value="inputValue"
                    :small="small"
                    :disabled="disabled"
                    clearInputOnBlur
                    @input="onInput"
                    @change="onChange"
                    @focus="focused = true; $emit('focus', $event)"
                    @blur="focused = false; $emit('blur', $event)"
                    @mouseenter.native="hovered = true"
                    @mouseleave.native="hovered = false"
                    @focusin.native="handlers.focusin"
                >
                    <template v-if="label" #label-element="{ attrs }">
                        <ULabel
                            v-bind="attrs"
                            :active="focused || !!innerValue || !!inputValue"
                        >
                            {{ label }}
                        </ULabel>
                    </template>

                    <template #append-inner>
                        <USpinner
                            v-if="loading"
                            small
                        ></USpinner>

                        <UIconButton
                            v-else-if="innerValue && active"
                            name="close-16"
                            small
                            secondary
                            tabindex="-1"
                            aria-label="Очистить поле"
                            class="u-select__clear"
                            @click="onClickClear"
                            @focusin.native.stop=""
                        ></UIconButton>

                        <UIconButton
                            v-else
                            name="arrow-down-16"
                            small
                            secondary
                            tabindex="-1"
                            :aria-label="opened ? 'Показать список значений' : 'Скрыть список значений'"
                            class="u-select__arrow"
                            :class="{
                                'u-select__arrow_up': opened,
                            }"
                            @click="toggle"
                            @focusin.native.stop=""
                        ></UIconButton>
                    </template>
                </UInputField>

                <button
                    v-else
                    type="button"
                    class="u-select__pseudo-input"
                    @focus="focused = true; $emit('focus', $event)"
                    @blur="focused = false; $emit('blur', $event)"
                    @mouseenter="hovered = true"
                    @mouseleave="hovered = false"
                    @click="toggle"
                >
                    <span class="u-select__pseudo-label">{{ getOptionLabel(innerValue) }}</span>

                    <UIcon
                        name="arrow-filled-down-16"
                        small
                        secondary
                        class="u-select__arrow u-select__pseudo-arrow"
                        :class="{
                            'u-select__arrow_up': opened,
                        }"
                    ></UIcon>
                </button>
            </template>

            <template #label="payload">
                <slot name="list-label" v-bind="payload">
                    <slot name="label" :item="payload.option">
                        {{ getOptionLabel(payload.option) }}
                    </slot>
                </slot>
            </template>
        </UDropdown>

        <span
            v-if="!inputValue && innerValue && searchable"
            class="u-select__value"
            :class="{
                'u-select__value_inactive': focused,
            }"
            @click="onClickValue"
        >
            <slot name="value-label" :value="innerValue">
                <slot name="label" :item="innerValue">
                    {{ getOptionLabel(innerValue) }}
                </slot>
            </slot>
        </span>
    </div>
</template>

<script>
import options from '@ui/mixins/options.js';
import UInputField from '@ui/components/UInput/UInputField.vue';
import UDropdown from '@ui/components/UDropdown/UDropdown.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
import ULabel from '@ui/components/ULabel/ULabel.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'USelect',

    components: {
        UIcon,
        ULabel,
        UIconButton,
        USpinner,
        UDropdown,
        UInputField,
    },

    mixins: [options],

    props: {
        // attrs
        placeholder: String,

        // state
        value: [String, Number, Object],
        disabled: Boolean,

        // options
        options: Array,
        hasMore: Boolean,
        loading: Boolean,
        initialized: {
            type: Boolean,
            default: true,
        },
        loadingMore: Boolean,

        // label
        label: String,

        // behaviour
        searchable: { // возможность вводить данные в инпут
            type: Boolean,
            default: true,
        },
        filterable: Boolean, // внутренний фильтр

        // style
        small: Boolean,
    },

    data() {
        return {
            innerValue: null,
            inputValue: '',
            opened: false,
            focused: false,
            hovered: false,
        };
    },

    computed: {
        filteredOptions() {
            if (this.filterable && this.inputValue) {
                return this.filter(this.options, this.inputValue);
            }

            return this.options;
        },

        active() {
            return this.opened || this.focused || this.hovered;
        },

        inputLabel() {
            if (this.innerValue) {
                return this.getOptionLabel(this.innerValue);
            }

            return '';
        },

        listeners() {
            const { open, close, select, ...listeners } = this.$listeners;
            return listeners;
        },
    },

    watch: {
        value: {
            handler(value) {
                if (!this.equal(value, this.innerValue)) {
                    this.innerValue = value;
                }
            },

            immediate: true,
        },

        innerValue: {
            handler(value) {
                if (!this.equal(value, this.value)) {
                    this.emitChange();
                }
            },
        },
    },

    methods: {
        equal(opt1, opt2) {
            return this.optionComparator(opt1, opt2);
        },

        filterBy(label, search) {
            return (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1;
        },

        filter(options, search) {
            return options.filter(option => {
                let label = this.getOptionLabel(option);

                if (typeof label === 'number') {
                    label = label.toString();
                }

                return this.filterBy(label, search);
            });
        },

        // public

        toggle() {
            this.$refs.dropdown.toggle();
        },

        open() {

        },

        close() {

        },

        // handlers

        onInput($event) {
            this.inputValue = $event.value;
        },

        onChange({ value }) {
            this.inputValue = value;
        },

        onSelect($event) {
            this.innerValue = $event.value;
            this.$emit('select', $event);
        },

        onClickValue() {
            this.$refs.input.focus();
        },

        onClickClear() {
            this.inputValue = '';
            this.innerValue = null;
            this.emitChange();
            this.$refs.input.focus();
        },

        // events

        emitOpen() {
            const target = this;
            this.$emit('open', { target });
        },

        emitChange() {
            const target = this;
            const value = this.innerValue;
            this.$emit('change', { target, value });
        },

        emitClose() {
            const target = this;
            this.$emit('close', { target });
        },

        emitInput() {
            const target = this;
            const value = this.inputValue;
            this.$emit('input', { target, value });
        },
    },
};
</script>

<style>
.u-select {
    position: relative;
}

.u-select__value {
    position: absolute;
    top: 12px;
    left: 16px;
    width: calc(100% - 16px - 36px);
}
.u-select__value_inactive * {
    color: var(--font-secondary-light-color) !important;
}

.u-select__arrow {
    transition: transform var(--transition);
}
.u-select__arrow.u-select__arrow_up {
    transform: rotate(180deg);
}

.u-select__pseudo-input {
    position: relative;
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    cursor: pointer;
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
    transition: border-color var(--transition), background-color var(--transition);
}
.u-select__pseudo-input:hover {
    border-color: var(--fields-border);
}

.u-select__pseudo-label {
    margin-right: 8px;
}

.u-select__pseudo-arrow {
    margin-left: auto;
}
</style>