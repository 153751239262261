<template>
    <UTooltip noDetach>
        <template #trigger="{ attrs, handlers }">
            <UIconButton
                v-bind="attrs"
                primary
                square
                :used="offer.in_basket"
                :name="offer.in_basket ? 'done' : 'cart-fill'"
                :loading="loading"
                :disabled="disabled"
                :spinner="{
                    small: true,
                    light: !offer.in_basket,
                }"
                v-on="handlers"
                @click="addToCart"
            ></UIconButton>
        </template>

        <p v-if="disabled">
            Товар отсутствует
        </p>
        <p v-else-if="offer.in_basket">
            Перейти в корзину
        </p>
        <p v-else>
            Добавить в корзину
        </p>
    </UTooltip>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { getErrorCode } from '@/lib/errors.js';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';

export default {
    name: 'IconAddCartBtn',

    components: { UIconButton, UTooltip },

    props: {
        item: {
            type: Object,
            default: () => {},
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },

        offer: {
            type: Object,
            default: () => {},
        },

        withText: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        ...mapActions({
            ADD_TO_CART: 'cart/addToCart',
        }),

        ...mapMutations({
            SET_CART: 'cart/set',
        }),

        async addToCart() {
            this.loading = true;

            const data = {
                offer: this.offer.id,
                amount: 1,
            };

            this.ADD_TO_CART({ data })
                .then(() => {
                    this.$notify({
                        type: 'cart',
                        item: this.item,
                        modelName: this.modelName,
                        offer: this.offer,
                    });
                    this.$emit('addToCart');
                })
                .catch(error => {
                    this.loading = false;
                    const code = getErrorCode(error);
                    const data = error.response.data;

                    if (code === 400) {
                        const { amount, offer, non_field_errors } = data;
                        if (amount) {
                            this.$error(amount[0].message);
                        }
                        else if (offer) {
                            this.$error(offer[0].message);
                        }
                        else if (non_field_errors) {
                            this.$error(non_field_errors[0].message);
                        }
                        else {
                            this.$error('Не удалось добавить в корзину');
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>