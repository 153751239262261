<template>
    <transition name="menu">
        <div
            v-show="isOpen"
            class="menu-wrap"
        >
            <div
                class="menu-back"
                @click="close"
            ></div>

            <div
                :class="['menu', 'menu_' + position]"
            >
                <ButtonIcon
                    :icon="{
                        name: 'cross',
                        big: true,
                        secondary: true,
                        hovered: true
                    }"
                    :secondary="false"
                    circle
                    class="close-btn"
                    aria-label="Скрыть меню"
                    @click="close"
                ></ButtonIcon>

                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
import bus from "../bus.js";
import ButtonIcon from "@/components/_buttons/ButtonIcon.vue";

export default {
    name: "MobileMenu",

    components: { ButtonIcon },

    props: {
        position: {
            type: String,
            default: 'left',
        },

        openBreakpoint: {
            type: String,
            default: '1040',
        },

        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        window.addEventListener('resize', this.checkWindowWidth);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.checkWindowWidth);
    },

    methods: {
        close() {
            bus.$emit('closeMobileMenu');
        },

        open() {
            bus.$emit('openMobileMenu');
        },

        checkWindowWidth() {
            if (window.innerWidth >= this.openBreakpoint && this.isOpen) this.close();
            // else if (this.isOpen) this.close();
        },
    },
};
</script>

<style scoped>
.menu-enter,
.menu-leave-to {
    transition: all var(--transition);
}

.menu-enter .menu_left,
.menu-leave-to .menu_left {
    transform: translateX(-100%);
}

.menu-enter .menu_right,
.menu-leave-to .menu_right {
    transform: translateX(100%);
}

.menu-enter .menu-back,
.menu-leave-to .menu-back {
    opacity: 0;
}

@media (min-width: 1040px) {
    .menu-wrap {
        display: block !important;
    }
}
@media (max-width: 1039px) {
    .menu-wrap {
        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--menu-z-index);
        width: 100%;
        height: 100%;
    }
}

.menu {
    position: relative;
    z-index: 2;
    transition: transform var(--transition);
}
@media (max-width: 1039px) {
    .menu {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--light-c);
        overflow: auto;
    }

    .menu_right {
        right: 0;
        left: auto;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .menu {
        max-width: 320px;
    }
}

.menu-back {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity var(--transition);
}
@media (min-width: 1040px) {
    .menu-back,
    .close-btn {
        display: none !important;
    }
}

.close-btn {
    position: fixed;
    top: 14px;
    z-index: 2;
}
@media (min-width: 768px) and (max-width: 1039px) {
    .menu_right .close-btn {
        right: 16px;
    }

    .menu_left .close-btn {
        left: 274px;
    }
}
@media (max-width: 767px) {
    .close-btn {
        right: calc(var(--content-gap) - 6px);
    }
}
</style>