var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopupWrap',{on:{"close":_vm.close}},[_c('div',{staticClass:"popup-content"},[_c('FormManager',{ref:"form",on:{"submit":_vm.submit},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[_c('h2',{staticClass:"h2 h2_block"},[_vm._v("\n                Оставить жалобу на ответ магазина\n            ")]),_vm._v(" "),_c('h4',{staticClass:"h4 h4_block"},[_vm._v("\n                Укажите причину жалобы:\n            ")]),_vm._v(" "),_c('FormField',{staticClass:"mb-20",attrs:{"name":"reason","type":"radio","options":[
                    {
                        label: 'Оскорбительный контент',
                        value: 'abuse',
                    },
                    {
                        label: 'Ошибки в тексте отзыва',
                        value: 'mistake',
                    },
                    {
                        label: 'Другая причина',
                        value: 'custom',
                    } ]}}),_vm._v(" "),_c('FormField',{attrs:{"name":"complaint","type":"text","label":"Причина жалобы","disabled":_vm.formData.reason !== 'custom',"validations":_vm.formData.reason === 'custom' ? 'required' : ''}})],1),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('InfoMessage',{staticClass:"info-message"},[_c('p',[_vm._v("\n                    Мы внимательно рассмотрим жалобу и снимем с публикации на сайте отзыв,\n                    если он не соответствует "),_c('ButtonText',{attrs:{"primary":"","underline":""}},[_vm._v("Пользовательскому соглашению")]),_vm._v(".\n                ")],1)]),_vm._v(" "),_c('div',{staticClass:"actions-wrap"},[_c('ButtonBlock',{staticClass:"action-btn",attrs:{"secondary":""},on:{"click":_vm.cancel}},[_vm._v("\n                    Отмена\n                ")]),_vm._v(" "),_c('ButtonBlock',{staticClass:"action-btn",attrs:{"submit":"","primary":"","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("\n                    Отправить\n                ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }