import genId from '@ui/utils/genId.js';


export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        meta: {},
        companies: [],
        offers: [],
    }),

    mutations: {
        setMeta(state, meta) {
            state.meta = meta;
        },

        setCompanies(state, companies) {
            state.companies = companies;
        },

        setOffers(state, offers) {
            state.offers = offers.map(offer => {
                offer.__id = genId();
                return offer;
            });
        },

        setInitialized(state, value) {
            state.initialized = value;
        },

        destroy(state) {
            state.initialized = false;
            state.meta = {};
            state.companies = [];
            state.offers = [];
        },
    },

    actions: {
        async init({ commit }, { route }) {
            const company = route.params.company;

            try {
                const [meta, companies, offers] = await Promise.all(
                    [
                        this.$api.staticPages.get('insurance-osago'),
                        this.$api.insuranceCompany.get(),
                        this.$api.insuranceCompany.lastOffers(company ? {
                            params: { company },
                        } : {}),
                    ],
                );

                commit('setMeta', meta);
                commit('setCompanies', companies);
                commit('setOffers', offers);
                commit('setInitialized', true);
                commit('clearHttpError', null, { root: true });

                return { meta, companies };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async getMeta({ commit }) {
            try {
                return await this.$api.staticPages.get('insurance-osago');
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },
    },
};