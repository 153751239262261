<template>
    <PreviewComponent
        ref="preview"
        @show="onShow"
        @hide="onHide"
    >
        <template #trigger="{ isOpen }">
            <component
                :is="trigger.tag"
                v-bind="trigger.attrs"
                class="preview-trigger"
                :class="{
                    'preview-trigger_active': isOpen
                }"
            >
                <UIcon
                    name="notification"
                    big
                    class="preview-trigger__icon"
                ></UIcon>

                <UCircleLabelCounter
                    :count="unreadCount"
                    class="preview-trigger__counter"
                ></UCircleLabelCounter>
            </component>
        </template>

        <template #content>
            <div class="layer-2">
                <div
                    v-if="fetching"
                    class="spinner-wrap"
                >
                    <USpinner
                        big
                        class="spinner"
                    ></USpinner>
                </div>

                <template v-else>
                    <div
                        v-if="!unreadNotifications.length"
                        class="placeholder"
                    >
                        <UIconRound
                            name="notification"
                            class="mr-24 flex-initial"
                        ></UIconRound>

                        <div>
                            <h4 class="text_bold mb-4">
                                Нет уведомлений
                            </h4>
                            <p class="text_base-lh">
                                В настоящий момент у вас нет новых уведомлений.
                            </p>
                        </div>
                    </div>

                    <div
                        v-else
                        class="notifications-container"
                    >
                        <div class="notifications-header">
                            <h4 class="h4">
                                Уведомления
                            </h4>

                            <ButtonText
                                v-if="unreadCount"
                                secondary
                                dark
                                dashed
                                class="set-all-is-read-button"
                                @click="setAllIsRead"
                            >
                                Отметить все прочитанными
                            </ButtonText>
                        </div>

                        <div class="notifications-body">
                            <ul>
                                <li
                                    v-for="notification in unreadNotifications"
                                    :key="notification.uuid"
                                    class="notification-item"
                                >
                                    <div
                                        role="button"
                                        tabindex="0"
                                        class="notification"
                                        @click="onClickNotification($event, notification)"
                                    >
                                        <NotificationsPreviewRenderTitle
                                            :title="notification.title"
                                            :isRead="notification.is_read"
                                            class="notification-title"
                                        ></NotificationsPreviewRenderTitle>
                                        <p class="notification-text">
                                            {{ notification.text }}
                                        </p>
                                        <div class="notification-footer">
                                            {{ notification.create_date | date }}
                                        </div>
                                    </div>
                                </li>

                                <li
                                    v-if="hasMore"
                                    class="notification-see-all-button hidden-m"
                                >
                                    <ButtonText
                                        secondary
                                        dark
                                        underline
                                        :to="{
                                            name: 'account-notifications',
                                        }"
                                    >
                                        Показать все уведомления
                                    </ButtonText>
                                </li>
                            </ul>
                        </div>

                        <div class="notifications-footer hidden-xl hidden-l">
                            <ButtonBlock
                                secondary
                                low
                                :to="{
                                    name: 'account-notifications',
                                }"
                            >
                                Показать все уведомления
                            </ButtonBlock>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </PreviewComponent>
</template>

<script>
// services
import { HTTP } from '@/http';
import bus from '@/bus.js';
// utils
import isValidDate from '@ui/utils/isValidDate.js';
// mixins
import breakpointKey from '@/mixins/breakpointKey.js';
// components
import UIcon from '@ui/components/UIcon/UIcon.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import UIconRound from '@ui/components/UIcon/UIconRound.vue';
import PreviewComponent from '@/components/header/PreviewComponent.vue';
import UCircleLabelCounter from '@ui/components/UBadge/UBadge.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import NotificationsPreviewRenderTitle from '@/components/header/NotificationsPreviewRenderTitle.vue';


export default {
    name: 'NotificationsPreview',

    components: {
        NotificationsPreviewRenderTitle,
        ButtonBlock,
        UCircleLabelCounter,
        PreviewComponent,
        UIconRound,
        ButtonText,
        USpinner,
        UIcon,
    },

    filters: {
        date(value) {
            if (!value) return value;

            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return value;

            const d = date.toLocaleString('ru-RU', {
                month: 'long',
                day: 'numeric',
            });
            const year = date.getFullYear();
            const t = date.toLocaleString('ru-RU', {
                hour: '2-digit',
                minute: '2-digit',
            });

            return d + ' ' + year + ', ' + t;
        },
    },

    mixins: [breakpointKey],

    props: {
        unreadCount: Number,
    },

    data() {
        return {
            fetching: false,
            source: null,
            unreadNotifications: [],
        };
    },

    computed: {
        trigger() {
            if (this.breakpointKey === 'm') {
                if (this.unreadCount) {
                    return {
                        tag: 'button',
                        attrs: {},
                    };
                }
                else {
                    return {
                        tag: 'RouterLink',
                        attrs: {
                            to: {
                                name: 'account-notifications',
                            },
                            'aria-label': 'Перейти на страницу уведомлений',
                        },
                    };
                }
            }
            else {
                return {
                    tag: 'RouterLink',
                    attrs: {
                        to: {
                            name: 'account-notifications',
                        },
                        'aria-label': 'Перейти на страницу уведомлений',
                    },
                };
            }
        },

        hasMore() {
            return this.unreadNotifications.length < this.unreadCount;
        },
    },

    methods: {
        onShow() {
            if (this.unreadCount) {
                this.fetchUnreadNotifications();
            }
            else {
                this.unreadNotifications = [];
            }
        },

        onHide() {
            if (this.source) {
                this.source.cancel();
                this.source = null;
            }
        },

        onClickNotification($event, notification) {
            const notifications = [notification.uuid];
            const data = { notifications };
            this.setIsRead(data);
            this.$refs.preview.hide();

            const url = new URL(notification.url);

            if (url.hostname === window.location.hostname && (!$event.metaKey && !$event.ctrlKey)) {
                this.$router.push(url.pathname);
            }
            else {
                window.open(url.href);
            }
        },

        async get() {
            if (this.source) {
                this.source.cancel();
                this.source = null;
            }

            try {
                this.source = HTTP.CancelToken.source();
                const cancelToken = this.source.token;
                const order_by = '-create_date';
                const limit = 15;
                const is_read = false;
                const params = { order_by, limit, is_read };
                const config = { params, cancelToken };
                return await this.$api.notifications.get(config);
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;

                // handleCommonHttpError(error);
            }
        },

        async fetchUnreadNotifications() {
            this.fetching = true;

            const response = await this.get();

            if (response) {
                const { results } = response;
                this.unreadNotifications = results;
            }

            this.fetching = false;

            this.$nextTick(this.$refs.preview.setContentPosition);
        },

        async setIsRead(data) {
            try {
                await this.$api.notifications.setIsRead(data);
                bus.$emit('notifications:setisread', data.notifications);
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError');
            }
        },

        async setAllIsRead() {
            try {
                await this.$api.notifications.setIsReadForAll();
                this.$refs.preview.hide();
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError');
            }
        },
    },
};
</script>

<style scoped>
.spinner-wrap {
    width: 380px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.placeholder {
    display: flex;
    align-items: center;
    width: 380px;
    padding: 20px 24px;
}

.notifications-container {
    width: 480px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
}

.notifications-header {
    padding: 20px 24px;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light-c);
}

.notifications-body {
    flex: 1;
    padding: 0 24px;
    overflow: auto;
}

.notification-item {
    padding: 20px 0;
    cursor: pointer;
    font-family: var(--f-base);
    font-size: var(--base-fz);
}
.notification-item + .notification-item {
    border-top: 1px solid var(--border-light-c);
}

.notification-see-all-button {
    margin-bottom: 20px;
}

.notification {
    outline: none;
    cursor: pointer;
}

.notification-title {
    margin-bottom: 4px;
    font-family: var(--f-bold);
    transition: color var(--transition);
}
.notification-item:hover .notification-title {
    color: var(--primary-color);
}

.notification-text {
    margin-bottom: 4px;
    line-height: var(--base-lh);
}

.notification-footer {
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
}

.preview-trigger {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    transition: color var(--transition);
}

.preview-trigger_active,
.preview-trigger:hover,
.preview-trigger:focus {
    color: var(--primary-color);
}

.preview-trigger__icon {
    display: block;
    fill: currentColor;
}

.preview-trigger__counter {
    position: absolute;
    top: 0;
    left: 18px;
}

.notifications-footer {
    padding: 20px 24px;
    flex-grow: 0;
    border-top: 1px solid var(--border-light-c);
}
</style>