export default {
    namespaced: true,

    state: () => ({
        data: {},
        initialized: false,
    }),

    mutations: {
        set(state, response) {
            state.data = response;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.data = {};
            state.initialized = false;
        },
    },

    actions: {
        async init({ commit }, { codename }) {
            if (
                codename !== 'help-mp-article' &&
                codename !== 'help-pp-article' &&
                codename !== 'news-item' &&
                codename !== 'additional-page'
            ) {
                try {
                    const response = await this.$api.staticPages.get(codename);
                    commit('set', response);
                    commit('clearHttpError', null, { root: true });
                    return response;
                }
                catch (error) {
                    commit('handleInitError', error, { root: true });
                }
            }
        },
    },
};