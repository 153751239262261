<template>
    <div
        :class="wrapClasses"
        class="input-radio-base"
    >
        <div
            v-for="option in options"
            :key="getOptionKey(option)"
            :class="itemClasses"
        >
            <input
                :id="getOptionKey(option) + id"
                v-model="valueComputed"
                :value="reduce(option)"
                type="radio"
                :disabled="option.disabled"
                class="visually-hidden"
                :class="inputClasses"
                v-on="listeners"
            >

            <slot
                name="label"
                v-bind="{
                    checked: valueComputed,
                    value: reduce(option),
                    option,
                    id: getOptionKey(option) + id
                }"
            >
                <label
                    :class="labelClasses"
                    :for="getOptionKey(option) + id"
                >
                    {{ getOptionLabel(option) }}
                </label>
            </slot>
        </div>
    </div>
</template>

<script>
import optionsPropsMixin from '@/mixins/options.js';
import genId from '@ui/utils/genId.js';


export default {
    name: "InputRadioBase",

    mixins: [
        optionsPropsMixin,
    ],

    props: {
        id: {
            type: String,
            default: () => genId(),
        },

        value: {
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        options: {
            type: Array,
            required: true,
        },

        wrapClasses: [String, Array, Object],
        itemClasses: [String, Array, Object],
        inputClasses: [String, Array, Object],
        labelClasses: [String, Array, Object],

        reduce: {
            type: Function,
            default(value) {
                return this.getOptionKey(value);
            },
        },
    },

    computed: {
        valueComputed: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('change', value);
            },
        },

        listeners() {
            const { change, ...listeners } = this.$listeners;
            return listeners;
        },
    },
};
</script>

<style>
.input-radio-base {
    position: relative;
}
</style>