<template>
    <transition mode="out-in" name="fade">
        <slot></slot>
    </transition>
</template>

<script>
export default {
    name: 'TransitionFade',
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .2s ease-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>