<template>
    <ul class="categories-list">
        <li
            v-for="category in categories"
            :key="'category-' + category.id"
            class="category layer-1"
        >
            <RouterLink
                :to="{
                    name: category.category_type
                        ? category.category_type === 'product_category'
                            ? 'market-products-category'
                            : 'market-parts-catalog-category'
                        : 'market-products-category',
                    params: {
                        slug: category.slug
                    }
                }"
                class="category-link"
            >
                <img
                    v-if="category.cover"
                    :src="$links.uploads + category.cover.thumbnails.category_cover_list"
                    alt=""
                    class="cover"
                >
                <img
                    v-else
                    src="../assets/images/cover-placeholder.svg"
                    alt=""
                    class="cover"
                >
                <h2 class="title">
                    {{ category.name }}
                </h2>
            </RouterLink>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'MarketCatalog',

    props: {
        categories: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped>
@media (min-width: 1281px) {
    .categories-list {
        grid-template-columns: repeat(5, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .categories-list {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .categories-list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) {
    .categories-list {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .categories-list {
        display: grid;
        grid-gap: var(--grid-gap);
    }
}

.category-link {
    display: block;
    height: 100%;
    transition: color var(--transition), box-shadow var(--transition);
}
@media (min-width: 768px) {
    .category-link {
        padding: 24px
    }
}
@media (max-width: 767px) {
    .category-link {
        display: flex;
        align-items: center;
        padding: 12px;
    }

    .category-link:not(:last-child) {
        margin-bottom: 12px;
    }
}

.category-link:hover,
.category-link:focus {
    box-shadow: var(--hover-light-shadow);
    color: var(--primary-color);
}

.cover {
    display: block;
    aspect-ratio: 1/1;
}
@media (min-width: 1040px) {
    .cover {
        width: 160px;
        height: 160px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .cover {
        width: 120px;
        height: 120px;
    }
}
@media (min-width: 768px) {
    .cover {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16px;
    }
}
@media (max-width: 767px) {
    .cover {
        width: 48px;
        height: 48px;
        margin-right: 12px;
    }
}

.title {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-bold);
    text-align: center;
    color: currentColor;
}
@media (max-width: 767px) {
    .title {
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        word-break: break-word;
    }
}
</style>