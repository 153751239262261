<template>
    <div class="insurance-osago-checkout">
        <h1 class="h1 h1_block insurance-osago__title">
            Оформление полиса ОСАГО
        </h1>

        <div class="form-container container_xs-external layer-1">
            <div class="offer-info">
                <div class="offer-info__grid">
                    <InsuranceCompanyLogo
                        :logo="selectedCompany.logo"
                        :name="selectedCompany.page_title"
                        class="offer-info__logo"
                    ></InsuranceCompanyLogo>

                    <div class="offer-info__block">
                        <div class="offer-info__header">
                            Компания
                        </div>
                        <div class="offer-info__value offer-info__company">
                            {{ selectedCompany.name }}
                        </div>
                    </div>

                    <div class="offer-info__block">
                        <div class="offer-info__header">
                            Лицензия
                        </div>
                        <div class="offer-info__value offer-info__license">
                            {{ selectedCompany.license }}
                        </div>
                    </div>

                    <div class="offer-info__block">
                        <div class="offer-info__header">
                            Стоимость страховки
                        </div>
                        <div
                            v-if="pollingLoading && !price"
                            class="flex align-items-center"
                        >
                            <Spinner class="mr-12 offer-info__spinner"></Spinner>
                            <span class="offer-info__polling">Идёт расчёт</span>
                        </div>

                        <div
                            v-else-if="price"
                            class="offer-info__price"
                        >
                            {{ price | floorPrice }}
                        </div>

                        <div
                            v-else
                            class="offer-info__unavailable"
                        >
                            Расчёт недоступен
                        </div>
                    </div>
                </div>

                <button
                    v-if="otherOffers.length"
                    class="dashed text_secondary-dark other-offers_desktop"
                    @click="toOtherOffers"
                >
                    <span 
                        v-if="otherOffers.length === 1"
                    >Ещё одно предложение за {{ maxOffersPrice | floorPrice }}</span>
                    <span 
                        v-else
                    >Ещё {{ offersAmount }}: от {{ minOffersPrice | number }} 
                        до {{ maxOffersPrice | floorPrice }}</span>
                </button>

                <div
                    v-if="otherOffers.length"
                    class="other-offers_mobile"
                >
                    <p class="text_secondary-dark">
                        <span 
                            v-if="otherOffers.length === 1"
                        >Ещё одно предложение за {{ maxOffersPrice | floorPrice }}</span>
                        <span 
                            v-else
                        >Ещё {{ offersAmount }}: от {{ minOffersPrice | number }} 
                            до {{ maxOffersPrice | floorPrice }}</span>
                    </p>
                    <ButtonBlock
                        secondary
                        low
                        class="other-offers_mobile__btn mt-8"
                        @click="toOtherOffers"
                    >
                        Смотреть
                    </ButtonBlock>
                </div>
            </div>

            <InfoMessage
                v-if="calculateIsComplete && !price"
                error
                class="error-unavailable mt-20"
            >
                <div class="error-unavailable__content">
                    <div
                        v-if="otherOffers.length"
                        class="error-unavailable__text"
                    >
                        Оформление полиса ОСАГО в&nbsp;выбранной компании сейчас невозможно. 
                        Вы&nbsp;можете ознакомиться с&nbsp;предложениями от&nbsp;других страховых компаний.
                    </div>
                    <div
                        v-else
                        class="error-unavailable__text"
                    >
                        Оформление полиса ОСАГО в&nbsp;выбранной компании сейчас невозможно 
                        и&nbsp;нет других подходящих предложений. 
                        Проверьте, правильно&nbsp;ли вы&nbsp;заполнили данные ниже.
                    </div>
                    <ButtonBlock
                        primary
                        low
                        class="error-unavailable__button"
                        @click="toOtherOffers"
                    >
                        Все предложения
                    </ButtonBlock>
                </div>
            </InfoMessage>

            <div class="form-content-wrap">
                <InsuranceOsagoSummary
                    :contract="contract"
                    :error="error"
                    class="order-summary"
                    @popup="popup"
                ></InsuranceOsagoSummary>

                <InfoMessage class="form-content-wrap__info-message">
                    Расчёт является предварительным. 
                    Итоговая стоимость будет показана после проверки ваших данных страховой компанией.
                </InfoMessage>

                <div class="footer">
                    <FormAgreement
                        text="Подтвердить"
                        class="agreement"
                    ></FormAgreement>

                    <ButtonBlock
                        secondary
                        class="button-secondary"
                        @click="back"
                    >
                        Назад
                    </ButtonBlock>

                    <ButtonBlock
                        primary
                        :disabled="!price || hasError"
                        class="button-primary"
                        :loading="ordering || calculating"
                        @click="confirm"
                    >
                        Подтвердить
                    </ButtonBlock>
                </div>
            </div>
        </div>

        <InsuranceFactoids></InsuranceFactoids>
    </div>
</template>

<script>
// utils
import { mapState, mapGetters, mapActions } from 'vuex';
import insuranceOsago from '@/store/modules/insuranceOsago.js';
import plural from '@/lib/plural.js';
import deepClone from '@/lib/deepClone.js';
import getErrorCode from '@/lib/getErrorCode.js';
// use
import useCheckRouteMethods from '@/pages/insurance/osago/_uuid/useCheckRouteMethods.js';
import useNavigationMethods from '@/pages/insurance/osago/_uuid/useNavigationMethods.js';
// mixins
import validateSummaryMixin from '@/pages/insurance/osago/_uuid/validateSummaryMixin.js';
import YandexMetrika from '@/mixins/yandex-metrika.js';
import lastPathMixin from '@/pages/insurance/osago/_uuid/lastPathMixin.js';
// components
import InfoMessage from '@/components/InfoMessage.vue';
import InsuranceCompanyLogo from '@/pages/insurance/InsuranceCompanyLogo.vue';
import Spinner from '@/components/Spinner.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InsuranceOsagoSummary from '@/pages/insurance/osago/_uuid/Summary.vue';
import FormAgreement from '@/components/FormAgreement.vue';
import InsuranceFactoids from '@/components/UFactoids/presets/InsuranceFactoids.vue';
const OtherOffersPopup = () => import('./OtherOffersPopup.vue');
const ChangeCarPopup = () => import('@/pages/insurance/osago/_uuid/ChangeCarPopup.vue');
const ChangeOwnerAndInsurerPopup = () => import('@/pages/insurance/osago/_uuid/ChangeOwnerPopup.vue');
const ChangeDriversPopup = () => import('@/pages/insurance/osago/_uuid/ChangeDriversPopup.vue');
const ChangePolicyPopup = () => import('@/pages/insurance/osago/_uuid/ChangePolicyPopup.vue');


const popups = {
    car: ChangeCarPopup,
    owner: ChangeOwnerAndInsurerPopup,
    insurer: ChangeOwnerAndInsurerPopup,
    drivers: ChangeDriversPopup,
    policy: ChangePolicyPopup,
    contacts: ChangeOwnerAndInsurerPopup,
};

export default {
    name: 'InsuranceOsagoCheckoutPage',

    components: {
        InsuranceFactoids,
        FormAgreement,
        InsuranceOsagoSummary,
        ButtonBlock,
        Spinner,
        InsuranceCompanyLogo,
        InfoMessage,
    },

    metaInfo() {
        return {
            title: 'Оформление полиса ОСАГО',
        };
    },

    serverPrefetch() {
        this.$store.registerModule('insuranceOsago', insuranceOsago);
                    
        return this.serverPrefetch({ uuid: this.uuid, requiredOffers: true });
    },

    mixins: [
        validateSummaryMixin,
        YandexMetrika,
        lastPathMixin,
    ],

    data() {
        return {
            ordering: false,
            calculating: false,
        };
    },

    computed: {
        ...mapState({
            contract: state => (state.insuranceOsago || {}).contract || {},
            companies: state => state.insuranceOsago.companies || [],
            calculateIsComplete: state => state.insuranceOsago.calculateIsCompleted,
            selectedCompany: state => (state.insuranceOsago.contract.active || {}).company || {},
            pollingLoading: state => state.insuranceOsago.pollingOffers.loading,
        }),

        ...mapGetters({
            offersByCompany: 'insuranceOsago/offersByCompany',
        }),

        offerBySelectedCompany() {
            const code = this.selectedCompany.code;
            return this.offersByCompany[code];
        },

        uuid() {
            return this.$route.params.uuid;
        },

        company() {
            return ((this.contract || {}).active || {}).company || {};
        },

        offers() {
            return (this.contract || {}).offers;
        },

        price() {
            return (this.offerBySelectedCompany || {}).price;
        },

        otherOffers() {
            return this.offers.filter(offer => offer.company !== this.company.code) || [];
        },

        minOffersPrice() {
            if (this.otherOffers.length) {
                const offers = deepClone(this.otherOffers);
                const sorted = offers.sort((a, b) => a.price - b.price);
                const offer = sorted[0];
                return offer.price;
            }
            else {
                return null;
            }
        },

        maxOffersPrice() {
            if (this.otherOffers.length) {
                const offers = deepClone(this.otherOffers);
                const sorted = offers.sort((a, b) => b.price - a.price);
                const offer = sorted[0];
                return offer.price;
            }
            else {
                return null;
            }
        },

        offersAmount() {
            const n = this.otherOffers.length;
            return `${ n } ${ plural(n, ['предложение', 'предложения', 'предложений']) }`;
        },
    },

    watch: {
        '$route.params.uuid': {
            handler() {
                this.createBreadcrumbs();
            },
        },
    },

    beforeMount() {
        if (!this.$store.hasModule('insuranceOsago')) {
            this.$store.registerModule('insuranceOsago', insuranceOsago, { preserveState: true });
        }
    },

    mounted() {
        this.init();
        this.createBreadcrumbs();
    },

    methods: {
        ...useCheckRouteMethods(),
        ...useNavigationMethods({
            prev: 'insurance-osago-offers',
        }),
        ...mapActions({
            startPolling: 'insuranceOsago/startPollingOffers',
            serverPrefetch: 'insuranceOsago/serverPrefetch',
            browserPrefetch: 'insuranceOsago/browserPrefetch',
            updateActiveOffer: 'insuranceOsago/updateActiveOffer',
            calculate: 'insuranceOsago/calculate',
            order: 'insuranceOsago/order',
        }),

        createBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'insurance-osago',
                    },
                    title: 'Автострахование',
                },
                {
                    to: {
                        name: this.$route.name,
                        params: this.$route.params,
                    },
                    title: 'Оформление ОСАГО',
                },
            ]);
        },

        async init() {
            await this.browserPrefetch({ uuid: this.uuid, requiredOffers: true });

            await this.checkUuid();

            if (this.calculateIsCompleted) return;

            this.startPolling();
        },

        async toOtherOffers() {
            this.$popup(OtherOffersPopup, {
                props: {
                    offers: this.otherOffers,
                    companies: this.companies,
                    isCompleted: this.calculateIsComplete,
                },
            });
        },

        popup(view) {
            const popup = popups[view];
            let callback = () => {};

            if (view === 'car' || view === 'drivers' || view === 'policy') {
                callback = async value => {
                    if (value && !this.hasError) {
                        this.calculating = true;

                        try {
                            await this.calculate();
                            await this.startPolling();
                        }
                        catch (error) {
                            //
                        }
                        finally {
                            this.calculating = false;
                        }
                    }
                };
            }

            const options = {
                props: {
                    contract: this.contract,
                    callback,
                },
            };

            this.$popup(popup, options);
        },

        async confirm() {
            this.error = this.validate();

            if (this.hasError) {
                this.$error('Одно или несколько полей содержат ошибки');
                return;
            }

            this.ordering = true;

            try {
                this.handleYandexMetrikaGoal('osago_oformlenie_step3');

                await this.order();

                await this.$router.push({
                    name: 'insurance-osago-confirmation',
                    params: {
                        uuid: this.uuid,
                    },
                });
            }
            catch (error) {
                const code = getErrorCode(error);

                if (code === 400) {
                    const obj = error.response.data;

                    if (obj.action_start_date) {
                        const err = obj.action_start_date;

                        if (err[0] && err[0].message) {
                            this.errors.action_start_date = err[0].message;
                        }
                    }
                }
            }
            finally {
                this.ordering = false;
            }
        },
    },
};
</script>

<style scoped>
.insurance-osago-checkout {
    margin-top: var(--gap-y-medium);
}

@media (min-width: 768px) {
    .insurance-osago__title {
        text-align: center;
    }
}

@media (min-width: 1040px) {
    .form-container {
        width: 940px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 768px) {
    .form-container {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .form-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.offer-info {
    background-color: var(--bright-bg);
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius-x2);
}
@media (min-width: 1040px) {
    .offer-info {
        padding: 20px 24px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .offer-info {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .offer-info {
        padding: 16px;
    }
}

@media (min-width: 1040px) {
    .offer-info__block {
        margin-top: 12px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .offer-info__block:not(:last-child) {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .offer-info__block:not(:last-child) {
        margin-bottom: 12px;
    }
}

.offer-info__grid {
    position: relative;
}
@media (min-width: 1040px) {
    .offer-info__grid {
        display: grid;
        grid-template-columns: 120px 220px repeat(2, 1fr);
        grid-column-gap: 20px;
    }
}

@media (min-width: 1040px) {
    .offer-info__grid:not(:last-child) {
        margin-bottom: 12px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .offer-info__grid:not(:last-child) {
        margin-bottom: 20px;
    }
}
@media (max-width: 767px) {
    .offer-info__grid:not(:last-child) {
        margin-bottom: 12px;
    }
}

.offer-info__header {
    font-size: 12px;
    line-height: var(--mini-lh);
    color: var(--font-secondary-color);
    margin-bottom: 4px;
}

.offer-info__value {
    font-size: 16px;
    line-height: 22px;
}

@media (min-width: 768px) and (max-width: 1039px) {
    .offer-info__logo {
        position: absolute;
        right: 0;
        top: 0;
    }
}
@media (max-width: 767px) {
    .offer-info__logo {
        margin-bottom: 8px;
    }
}

.offer-info__price {
    font-size: 16px;
    line-height: 22px;
    font-family: var(--f-bold);
}

.offer-info__polling {
    color: var(--primary-color);
    font-size: 14px;
    font-family: var(--f-semi-bold);
}

.offer-info__unavailable {
    color: var(--primary-color);
    font-size: 14px;
    font-family: var(--f-semi-bold);
}

@media (min-width: 1040px) {
    .error-unavailable  >>> .text {
        display: flex;
    }
}

.error-unavailable__button {
    flex-shrink: 0;
}
@media (min-width: 1040px) {
    .error-unavailable__button {
        margin-left: 20px;
    }
}
@media (max-width: 1039px) {
    .error-unavailable__button {
        margin-top: 12px;
        margin-left: -24px;
    }
}
@media (min-width: 376px) {
    .error-unavailable__button {
        width: 180px;
    }
}
@media (max-width: 375px) {
    .error-unavailable__button {
        width: calc(100% + 24px);
    }
}

@media (min-width: 768px) {
    .other-offers_mobile {
        display: none;
    }
}
.other-offers_desktop {
    white-space: nowrap;
}
@media (max-width: 767px) {
    .other-offers_desktop {
        display: none;
    }
}

@media (max-width: 767px) {
    .other-offers_mobile__btn {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .form-content-wrap {
        margin-top: 36px;
    }
}
@media (max-width: 767px) {
    .form-content-wrap {
        margin-top: 28px;
    }
}

@media (min-width: 768px) {
    .form-content-wrap__info-message {
        margin-top: 20px;
    }
}
@media (min-width: 767px) {
    .form-content-wrap__info-message {
        margin-top: 16px;
    }
}

@media (min-width: 768px) {
    .footer {
        grid-template-areas: "agreement secondary primary";
    }
}
@media (min-width: 768px) {
    .footer {
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        grid-gap: 20px;
        align-items: center;
        margin-top: 36px;
    }

    .button-secondary {
        grid-area: secondary;
    }

    .button-primary {
        grid-area: primary;
    }
}
@media (max-width: 767px) {
    .footer {
        margin-top: 28px;
    }

    .footer > * {
        width: 100%;
    }

    .footer > :not(:last-child) {
        margin-bottom: 16px;
    }
}

.agreement {
    grid-area: agreement;
}
</style>