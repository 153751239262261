<template>
    <FormTemplate>
        <template slot="body">
            <h2 class="h2 h2_block">
                Автомобиль
            </h2>

            <FormManager
                ref="form"
                v-model="formData"
                :formTag="false"
                class="form-grid"
                @error="onError"
            >
                <!-- Кириллические символы приравнять к латинским -->
                <FormField
                    name="vehicle_license_plate"
                    type="licensePlate"
                    label="Гос. номер"
                    visibleMask
                    :validations="formData.vehicle_doc_type_code === 'STS' ? 'required' : ''"
                    :loading="vehicle_license_plate.loading"
                    :disabled="vehicle_id_number.loading"
                    class="vehicle_license_plate"
                    @input="onInputVehicleLicensePlate"
                    @change="patch('vehicle_license_plate')"
                ></FormField>

                <div
                    class="note"
                    :class="{
                        wide: vehicle_license_plate.failedGetInfo,
                    }"
                >
                    <template v-if="vehicle_license_plate.showHint">
                        <span 
                            v-if="vehicle_license_plate.failedGetInfo"
                        >Данные по&nbsp;номеру <b>{{ vehicle_license_plate.failedNumber }}</b>&nbsp;не найдены. 
                            Проверьте, возможно, номер введен с&nbsp;ошибкой, 
                            либо заполните данные самостоятельно.</span>
                        <span v-else>Введите номер и&nbsp;мы&nbsp;заполним часть данных за&nbsp;вас.</span>
                    </template>
                </div>

                <FormField
                    name="vehicle_manufacturer"
                    type="select"
                    label="Марка"
                    options="/insurance_vehicle_manufacturer/"
                    :limit="null"
                    searchParam="q"
                    optionLabel="name"
                    validations="required"
                    :disabled="vehicle_license_plate.loading || vehicle_id_number.loading"
                    class="vehicle_manufacturer"
                    @change="onChangeVehicleManufacturer"
                ></FormField>

                <FormField
                    name="vehicle_model"
                    type="select"
                    label="Модель"
                    options="/insurance_vehicle_model/"
                    searchParam="q"
                    :limit="null"
                    :params="formData.vehicle_manufacturer ? {
                        manufacturer: formData.vehicle_manufacturer.id,
                    } : {}"
                    :disabled="vehicle_license_plate.loading ||
                        vehicle_id_number.loading ||
                        (!formData.vehicle_manufacturer && !formData.vehicle_model)"
                    optionLabel="name"
                    validations="required"
                    class="vehicle_model"
                    @change="onChangeVehicleModel"
                ></FormField>

                <FormField
                    name="vehicle_year"
                    type="select"
                    label="Год выпуска"
                    :options="(formData.vehicle_model || {}).year || []"
                    :disabled="vehicle_license_plate.loading ||
                        vehicle_id_number.loading ||
                        (!formData.vehicle_model && !formData.vehicle_year)"
                    validations="required"
                    class="vehicle_year"
                    @change="patch('vehicle_year')"
                ></FormField>

                <FormField
                    name="vehicle_engine_power"
                    type="select"
                    label="Мощность двигателя"
                    options="/insurance_vehicle/load_power/"
                    :disabled="vehicle_license_plate.loading ||
                        vehicle_id_number.loading ||
                        (!formData.vehicle_year && !formData.vehicle_engine_power)"
                    :params="{
                        model: (formData.vehicle_model || {}).id,
                        year: formData.vehicle_year,
                    }"
                    :getOptionLabel="option => option + ' л. с.'"
                    :getCurrentCount="response => response.length"
                    :getItems="response => response"
                    validations="required"
                    class="vehicle_engine_power"
                    @change="patch('vehicle_engine_power')"
                ></FormField>

                <FormField
                    name="vehicle_id_type_code"
                    type="tabs"
                    :options="[
                        {
                            value: 'vin',
                            label: 'VIN',
                        },
                        {
                            value: 'bodyNumber',
                            label: 'Кузов',
                        },
                        {
                            value: 'chassisNumber',
                            label: 'Шасси',
                        },
                    ]"
                    flex
                    :disabled="vehicle_license_plate.loading || vehicle_id_number.loading"
                    validations="required"
                    class="vehicle_id_type_code"
                    @change="patch('vehicle_id_type_code', 'vehicle_id_number')"
                ></FormField>

                <FormField
                    name="vehicle_id_number"
                    :maxLength="formData.vehicle_id_type_code === 'vin' ? 17 : 13"
                    :label="vehicle_id_number.labels[formData.vehicle_id_type_code]"
                    :validations="'required|' + (isVin ? 'isVin' : 'isBodyNumber')"
                    :validationRules="{
                        isVin: ({ value }) => {
                            // TODO: уточнить regexp
                            return !value ? false : !/^[A-z\d]{17}$/.test(value)
                        },
                        isBodyNumber: ({ value }) => {
                            // предположение, что номер кузова и номер шасси совпадает
                            return !value ? false : !/^[A-z\d]{9,13}$/.test(value)
                        },
                    }"
                    upper
                    :disabled="vehicle_license_plate.loading"
                    :loading="vehicle_id_number.loading"
                    class="vehicle_id_number"
                    @blur="patch('vehicle_id_number')"
                    @input="onInputVehicleIdNumber"
                ></FormField>

                <h4 class="h4 vehicle-doc-title">
                    Документ на авто
                </h4>

                <FormField
                    name="vehicle_doc_type_code"
                    type="tabs"
                    :options="[
                        {
                            value: 'STS',
                            label: 'СТС',
                        },
                        {
                            value: 'PTS',
                            label: 'ПТС',
                        },
                        {
                            value: 'EPTS',
                            label: 'ЕПТС',
                        },
                    ]"
                    flex
                    class="vehicle_doc_type_code"
                    :disabled="vehicle_license_plate.loading || vehicle_id_number.loading"
                    @change="patch('vehicle_doc_type_code', 'vehicle_doc_number', 'vehicle_doc_date')"
                ></FormField>

                <FormField
                    name="vehicle_doc_number"
                    :label="vehicle_doc_number.labels[formData.vehicle_doc_type_code]"
                    :mask="vehicle_doc_number.masks[formData.vehicle_doc_type_code]"
                    validations="required|re"
                    :validationRules="{
                        re: validateVehicleDocNumber,
                    }"
                    :disabled="vehicle_license_plate.loading || vehicle_id_number.loading"
                    class="vehicle_doc_number"
                    @blur="patch('vehicle_doc_number')"
                ></FormField>

                <!-- min vehicle_year max now -->
                <FormField
                    name="vehicle_doc_date"
                    type="date"
                    visibleMask
                    :label="vehicle_doc_date.labels[formData.vehicle_doc_type_code]"
                    :validations="`required|min:${ minVehicleDocDateIso }|max:${ maxVehicleDocDateIso }`"
                    :disabled="vehicle_license_plate.loading || vehicle_id_number.loading"
                    class="vehicle_doc_date"
                    @blur="patch('vehicle_doc_date')"
                ></FormField>
            </FormManager>

            <InfoMessage class="mt-20">
                Оформление электронного полиса ОСАГО на&nbsp;Ubibi доступно для легковых автомобилей категории&nbsp;В, 
                которые не&nbsp;сдаются в&nbsp;аренду и&nbsp;не&nbsp;используются в&nbsp;качестве такси.
            </InfoMessage>
        </template>

        <template slot="footer">
            <ButtonBlock
                primary
                class="button-primary"
                @click="next"
            >
                Следующий шаг
            </ButtonBlock>
        </template>
    </FormTemplate>
</template>

<script>
// utils
import deepClone from '@/lib/deepClone.js';
// use
import usePatchData from '@/pages/insurance/osago/_uuid/usePatchData.js';
import usePatchMethods from '@/pages/insurance/osago/_uuid/usePatchMethods.js';
import usePatchWatch from '@/pages/insurance/osago/_uuid/usePatchWatch.js';
import useNavigationMethods from '@/pages/insurance/osago/_uuid/useNavigationMethods.js';
import usePatchComputed from '@/pages/insurance/osago/_uuid/usePatchComputed.js';
import useVehicleData from '@/pages/insurance/osago/_uuid/useVehicleData.js';
import useVehicleMethods from '@/pages/insurance/osago/_uuid/useVehicleMethods.js';
import useVehicleComputed from '@/pages/insurance/osago/_uuid/useVehicleComputed.js';
import useVehicleWatch from '@/pages/insurance/osago/_uuid/useVehicleWatch.js';
// mixins
import lastPathMixin from '@/pages/insurance/osago/_uuid/lastPathMixin.js';
// components
import FormTemplate from '../FormTemplate.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';


export default {
    name: 'InsuranceOsagoRequestStep1Page',

    components: {
        ButtonBlock,
        FormField,
        FormManager,
        InfoMessage,
        FormTemplate,
    },

    mixins: [
        lastPathMixin,
    ],

    beforeRouteLeave(to, from, next) {
        const uuid = this.uuid;
        const createDate = Date.now();

        if (this.formData.vehicle_license_plate) {
            const licensePlate = this.formData.vehicle_license_plate;
            const item = localStorage.getItem('insurance_osago_license_plates');
            let licensePlates = item ? JSON.parse(item) : {};
            const entries = Object.entries(licensePlates);

            if (entries.length >= 2 && !licensePlates[licensePlate]) {
                entries.sort((a, b) => Number(a.createDate) - Number(b.createDate));
                licensePlates = { [entries[0][0]]: entries[0][1] };
            }

            licensePlates[licensePlate] = { createDate, uuid };

            localStorage.setItem('insurance_osago_license_plates', JSON.stringify(licensePlates));
        }

        localStorage.setItem('insurance_osago_draft', JSON.stringify({ createDate, uuid }));

        next();
    },

    data() {
        return {
            ...usePatchData(),
            ...useVehicleData(),
        };
    },

    computed: {
        ...usePatchComputed(),
        ...useVehicleComputed(),
    },

    watch: {
        ...useVehicleWatch(),
        ...usePatchWatch(),
    },

    mounted() {
        // предполагаю, что к этому моменту данные по контракту получены
        this.patchedData = deepClone(this.filteredStoreData);

        this.checkVehicleLicensePlate();
    },

    beforeDestroy() {
        if (this.getVehicleInfoSource) this.getVehicleInfoSource.cancel();
    },

    methods: {
        ...useVehicleMethods(),
        ...usePatchMethods(),
        ...useNavigationMethods({
            next: 'insurance-osago-request-step-2',
            yandex: 'osago_price_step1',
        }),
    },
};
</script>

<style scoped>
.vehicle_license_plate {
    grid-area: vehicle_license_plate;
}

.note {
    grid-area: note;
    grid-column-start: 2;
    grid-column-end: 3;
    font-size: 12px;
    color: var(--font-secondary-color);
    display: flex;
    align-items: center;
}
.note.wide {
    grid-column-start: 2;
    grid-column-end: 4;
}
@media (max-width: 767px) {
    .note {
        margin-top: -8px;
    }
}

.vehicle_manufacturer {
    grid-area: vehicle_manufacturer;
}

.vehicle_model {
    grid-area: vehicle_model;
}

.vehicle_year {
    grid-area: vehicle_year;
}

.vehicle_engine_power {
    grid-area: vehicle_engine_power;
}

.vehicle_id_type_code {
    grid-area: vehicle_id_type_code;
    width: 100%;
}

.vehicle_id_number {
    grid-area: vehicle_id_number;
}

.vehicle-doc-title {
    grid-area: vehicle-doc-title;
    margin: 0;
}

.vehicle_doc_type_code {
    grid-area: vehicle_doc_type_code;
}

.vehicle_doc_number {
    grid-area: vehicle_doc_number;
}

.vehicle_doc_date {
    grid-area: vehicle_doc_date;
}

.vehicle-doc-title {
    margin-top: 4px;
}
@media (max-width: 767px) {
    .vehicle-doc-title {
        margin-bottom: 12px;
    }
}

@media (min-width: 1040px) {
    .form-grid {
        grid-template-areas:
            "vehicle_license_plate note note"
            "vehicle_manufacturer vehicle_model vehicle_year"
            "vehicle_engine_power vehicle_id_type_code vehicle_id_number"
            "vehicle-doc-title vehicle-doc-title vehicle-doc-title"
            "vehicle_doc_type_code vehicle_doc_number vehicle_doc_date";
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .form-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "vehicle_license_plate note"
            "vehicle_manufacturer vehicle_model"
            "vehicle_year vehicle_engine_power"
            "vehicle_id_type_code vehicle_id_number"
            "vehicle-doc-title vehicle-doc-title"
            "vehicle_doc_type_code ."
            "vehicle_doc_number vehicle_doc_date";
    }
}
</style>
