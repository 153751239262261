var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"market-items-list-with-offers"},[(_vm.totalAmount)?[_c('MarketItemListUpper',{attrs:{"checkedFilters":_vm.checkedFilters},on:{"resetFilters":_vm.onResetFilters,"deleteFilter":_vm.onDeleteFilter},scopedSlots:_vm._u([{key:"sort",fn:function(){return [_c('UMenu',{staticClass:"sort",attrs:{"shift":-16},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var handlers = ref.handlers;
var attrs = ref.attrs;
var value = ref.value;
return [_c('button',_vm._g(_vm._b({staticClass:"sort__pseudo-input",attrs:{"type":"button"}},'button',attrs,false),handlers),[_c('span',{key:_vm.sortData.value,staticClass:"sort__value"},[_vm._v(_vm._s(_vm.sortData.name))]),_vm._v(" "),_c('UIcon',{staticClass:"sort__arrow-icon",class:{ opened: value },attrs:{"name":"arrow-down","tiny":""}})],1)]}}],null,false,2289344292)},[_vm._v(" "),_vm._l((_vm.sortOptions),function(sortOption){return _c('UMenuItem',{key:'sort-option-' + sortOption.value,attrs:{"selected":_vm.sortData.value === sortOption.value},on:{"click":function($event){return _vm.onChangeSort(sortOption)}}},[_vm._v("\n                        "+_vm._s(sortOption.name)+"\n                    ")])})],2)]},proxy:true},{key:"view",fn:function(){return [_c('RadioButtonsGroup',{attrs:{"name":"catalog-view","type":"icon","options":[
                        {
                            label: '',
                            value: 'list',
                            icon: 'list-toggler',
                        },
                        {
                            label: '',
                            value: 'grid',
                            icon: 'grid',
                        } ]},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}})]},proxy:true},{key:"filterToggler",fn:function(){return [_c('div',{staticClass:"filter-toggler-btn"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkedFilters.length),expression:"checkedFilters.length"}],staticClass:"filter-toggler-btn__counter"},[_vm._v(_vm._s(_vm.checkedFilters.length))]),_vm._v(" "),_c('ButtonIcon',{attrs:{"icon":{
                            name: 'filter-btn',
                        },"hoverPrimary":""},on:{"click":_vm.openFilter}})],1)]},proxy:true}],null,false,982569711)}),_vm._v(" "),_vm._t("title"),_vm._v(" "),_c('MarketItemListContent',{scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner-wrap"},[_c('Spinner',{attrs:{"size":"big","center":"","page":""}})],1),_vm._v(" "),(_vm.items.length)?_c('ol',{staticClass:"items-container",class:[
                        'items-' + _vm.view,
                        _vm.loading && 'items-container_loading' ]},_vm._l((_vm.items),function(item,itemIndex){return _c('li',{key:'item-' + (item.product_id || item.part_product_id),staticClass:"item",class:{
                            'item_view-list': _vm.view === 'list',
                            'item_view-grid-list': _vm.view === 'grid',
                        }},[(_vm.view === 'list')?_vm._t("listItem",function(){return [_c('MarketItemRowListItem',{attrs:{"item":item,"modelName":_vm.modelName,"reviewsInPopup":_vm.reviewsInPopup},on:{"changeFavorite":function($event){
                        var i = arguments.length, argsArray = Array(i);
                        while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChangeFavorite.apply(void 0, [ itemIndex ].concat( argsArray ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(item, _vm.modelName, itemIndex)}}})]},null,{ item: item, itemIndex: itemIndex }):_vm._e(),_vm._v(" "),(_vm.view === 'grid')?_vm._t("gridItem",function(){return [_c('MarketItemColumn',{attrs:{"item":item,"apiName":_vm.apiName,"modelName":_vm.modelName,"reviewsInPopup":_vm.reviewsInPopup},on:{"changeFavorite":function($event){
                        var i = arguments.length, argsArray = Array(i);
                        while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChangeFavorite.apply(void 0, [ itemIndex ].concat( argsArray ))},"callShowOffersPopup":function($event){return _vm.showOffersPopup(item, _vm.modelName, itemIndex)}}})]},null,{ item: item, itemIndex: itemIndex }):_vm._e()],2)}),0):_vm._e(),_vm._v(" "),(_vm.hasNext && _vm.amount)?_c('ButtonText',{staticClass:"load-more-btn",attrs:{"dashed":"","dark":"","secondary":""},on:{"click":_vm.load}},[_vm._v("\n                    Показать ещё\n                ")]):_vm._e(),_vm._v(" "),(_vm.checkedFilters.length === 0 && !_vm.amount && !_vm.loading)?_vm._t("inStockEmpty",function(){return [_c('EmptyBlock',{attrs:{"icon":"empty-box","title":("Нет " + (_vm.modelName === 'product' ? 'автотоваров' : _vm.modelName === 'part_product' ? 'новых запчастей' : 'контрактных запчастей')),"text":"Нет информации об актуальных предложениях магазина.","btnText":"Перейти в каталог","btnUrl":{
                            name: 'market-products-catalog',
                        }}})]}):_vm._e(),_vm._v(" "),(_vm.checkedFilters.length && !_vm.amount && !_vm.loading)?_c('EmptyBlock',{attrs:{"icon":"filter","title":"Нет подходящих предложений","text":"Попробуйте изменить условия поиска.","hasBtn":false,"hasResetFilters":""},on:{"resetFilters":_vm.onResetFilters}}):_vm._e()]},proxy:true},{key:"aside",fn:function(){return [_c('FilterComponent',{attrs:{"value":_vm.filterData,"filters":_vm.staticFilters.concat( _vm.dynamicFilters),"checkedFilters":_vm.checkedFilters,"amount":_vm.amount,"loading":_vm.loading},on:{"change":_vm.onChangeFilter,"deleteFilter":_vm.onDeleteFilter,"reset":_vm.onResetFilters,"reload":_vm.onReload}})]},proxy:true}],null,true)})]:_vm._t("totalEmpty")],2)}
var staticRenderFns = []

export { render, staticRenderFns }