var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('svg',_vm._g({staticClass:"u-icon",class:{
        'u-icon_tiny': _vm.tiny,
        'u-icon_small': _vm.small,
        'u-icon_big': _vm.big,
        'u-icon_huge': _vm.huge,
        'u-icon_extra': _vm.extra,
        'u-icon_primary': _vm.primary,
        'u-icon_white': _vm.white,
        'u-icon_secondary': _vm.secondary,
        'u-icon_secondary-light': _vm.secondary && _vm.light,
        'u-icon_yellow': _vm.yellow,
        'u-icon_green': _vm.green,
        'u-icon_hovered': _vm.hovered,
        'u-icon_in-disabled-button': _vm.inDisabledBtn,
    },style:(_vm.computedSizeStyles)},_vm.$listeners),[_c('use',{attrs:{"xlink:href":'#' + _vm.name}})])}
var staticRenderFns = []

export { render, staticRenderFns }