<template>
    <div class="chat-image-view__back">
        <!-- none 1023 -->
        <button class="chat-image-view__close-button" @click="close">
            <UIcon
                name="close-24"
                class="chat-image-view__close-icon"
            ></UIcon>
        </button>

        <!-- none 1023 -->
        <div
            v-if="currentIndex > 0 || prevLink"
            role="button"
            tabindex="0"
            class="chat-image-view__nav-block chat-image-view__to-prev-block"
            @click="prev"
        >
            <UIcon
                name="arrow-left"
                small
                class="chat-image-view__nav-icon"
            ></UIcon>
        </div>

        <!-- none 1023 -->
        <div
            v-if="currentIndex < (innerItems.length - 1) || nextLink"
            role="button"
            tabindex="0"
            class="chat-image-view__nav-block chat-image-view__to-next-block"
            @click="next"
        >
            <UIcon
                name="arrow-right"
                small
                class="chat-image-view__nav-icon"
            ></UIcon>
        </div>

        <div class="chat-image-view__flex-box">
            <!-- mobile header -->
            <div class="chat-image-view__mobile-header">
                <button class="chat-image-view__mobile-button chat-image-view__mobile-item" @click="close">
                    <UIcon
                        name="back-24"
                        class="chat-image-view__mobile-icon"
                    ></UIcon>
                </button>

                <div class="chat-image-view__mobile-item chat-image-view__mobile-image-wrap">
                    <span
                        v-if="currentItem"
                        class="chat-image-view__mobile-image-name"
                    >{{ currentItem.mediafile.name }}</span>
                </div>

                <UMenu>
                    <template #trigger="{ value, attrs, handlers }">
                        <UMenuIcon
                            class="chat-image-view__mobile-item chat-image-view__mobile-button"
                            :active="value"
                            v-bind="attrs"
                            v-on="handlers"
                        ></UMenuIcon>
                    </template>

                    <UMenuItem @click="download">
                        Скачать
                    </UMenuItem>
                    <UMenuItem @click="onClickToMessage(currentItem.message)">
                        Перейти к сообщению
                    </UMenuItem>
                </UMenu>
            </div>

            <div class="chat-image-view__content">
                <div v-if="initialFetching || fetching" class="chat-image-view__spinner">
                    <USpinner big></USpinner>
                </div>

                <VueTouch
                    v-else
                    class="chat-image-view__image-block"
                    @swiperight="prev"
                    @swipeleft="next"
                >
                    <img
                        v-if="currentItem.mediafile"
                        ref="image"
                        :src="$links.uploads + currentItem.mediafile.original_url"
                        alt=" "
                        class="chat-image-view__image"
                    >

                    <img
                        v-else
                        src="../../assets/images/cover-placeholder.svg"
                        alt=" "
                        class="chat-image-view__image"
                    >
                </VueTouch>

                <!-- none 1023 -->
                <div v-if="currentItem && !fetching" class="chat-image-view__message-block">
                    <div class="chat-image-view__sender-block">
                        <div class="chat-image-view__avatar-block">
                            <img
                                v-if="currentItem.message.sender.avatar"
                                :src="currentItem.message.sender.avatar"
                                :alt="currentItem.message.sender.name"
                                class="chat-image-view__avatar-image"
                                @load="$emit('load')"
                            >
                            <span
                                v-else
                                class="chat-image-view__avatar-empty-image"
                            >
                                {{ currentItem.message.sender.name[0] }}
                            </span>
                        </div>
                        <div class="chat-image-view__name-block">
                            <b class="chat-image-view__name">{{ currentItem.message.sender.name }}</b>
                            <span class="chat-image-view__date">{{ currentItem.message.create_date | date }}, {{ currentItem.message.create_date | time }}</span>
                        </div>
                    </div>
                    <div class="chat-image-view__text-block">
                        <div v-if="currentItem.message.text" class="chat-image-view__text-wrap">
                            <span class="chat-image-view__text">{{ currentItem.message.text }}</span>
                        </div>
                    </div>
                    <div class="chat-image-view__buttons-block">
                        <ButtonText
                            underline
                            :prependIcon="{
                                name: 'back-16',
                                small: true
                            }"
                            class="chat-image-view__to-message-button chat-image-view__button"
                            @click="onClickToMessage(currentItem.message)"
                        >
                            Перейти к сообщению
                        </ButtonText>

                        <ButtonText
                            underline
                            :prependIcon="{
                                name: 'download-circle',
                                small: true
                            }"
                            class="chat-image-view__button"
                            @click="download"
                        >
                            Скачать
                        </ButtonText>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// utils
import isValidDate from '@ui/utils/isValidDate.js';
import pad from '@ui/utils/pad.js';
import { timestampType } from '@/components/_chat/format.js';
import download from '@/lib/download.js';
// components
import ButtonText from '@/components/_buttons/ButtonText.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import UMenu from '@ui/components/UMenu/UMenu.vue';
import UMenuItem from '@ui/components/UMenu/UMenuItem.vue';
import UMenuIcon from '@ui/components/UMenu/UMenuIcon.vue';


export default {
    name: 'ChatImageViewPopup',

    components: { UMenuIcon, UMenuItem, UMenu, UIcon, USpinner, ButtonText },

    filters: {
        date(value) {
            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return value;

            const type = timestampType(date);

            if (type === 'today') {
                return 'Сегодня';
            }
            else if (type === 'yesterday') {
                return 'Вчера';
            }
            else if (type === 'week') {
                const options = { weekday: 'long' };
                return new Intl.DateTimeFormat('ru-RU', options).format(date);
            }
            else if (type === 'year') {
                const options = { day: 'numeric', month: 'long' };
                return new Intl.DateTimeFormat('ru-RU', options).format(date);
            }
            else {
                const options = { day: 'numeric', month: 'long', year: 'numeric' };
                return new Intl.DateTimeFormat('ru-RU', options).format(date);
            }
        },

        time(value) {
            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return value;

            return `${ pad(date.getHours()) }:${ pad(date.getMinutes()) }`;
        },
    },

    props: {
        toMessage: Function,
        initialItems: Array,
        initialIndex: Number,
        initialItem: Object,
        initialPrevLink: String,
        initialNextLink: String,
        normalizeMessage: Function,
        _index: Number,
    },

    data() {
        return {
            innerItems: [
                // {
                //     message: {},
                //     mediafile: {},
                // },
            ],
            nextLink: '',
            prevLink: '',
            currentIndex: 0,
            initialFetching: false,
            fetching: false,
        };
    },

    computed: {
        currentItem() {
            return this.innerItems[this.currentIndex];
        },
    },

    beforeMount() {
        this.init();

        // request
        // this.innerImages = [...this.images];
    },

    methods: {
        async init() {
            if (!this.initialItems) {
                this.initialFetching = true;
                const uuid = this.initialItem.message.chat;
                const id = this.initialItem.id;
                const order_by = 'id';
                const id__lte = id;
                const id__gt = id;
                const limit = 25;

                try {
                    const responses = await Promise.all([
                        this.$api.chat.images(uuid, {
                            params: {
                                order_by,
                                id__gt,
                                limit,
                            },
                        }),

                        this.$api.chat.images(uuid, {
                            params: {
                                id__lte,
                                limit,
                            },
                        }),
                    ]);

                    this.innerItems = [
                        ...responses[0].results.reverse().map(obj => {
                            let { message, ...data } = obj;
                            message = this.normalizeMessage(message);
                            return { ...data, message };
                        }),
                        ...responses[1].results.map(obj => {
                            let { message, ...data } = obj;
                            message = this.normalizeMessage(message);
                            return { ...data, message };
                        }),
                    ];
                    this.prevLink = responses[0].next;
                    this.nextLink = responses[1].next;
                    this.currentIndex = this.innerItems.findIndex(item => item.id === id);
                }
                catch (error) {
                    this.$store.commit('handleCommonHttpError', error);
                }
                finally {
                    this.initialFetching = false;
                }
            }
            else {
                this.innerItems = [...this.initialItems];
                this.currentIndex = this.initialIndex;
                this.prevLink = this.initialPrevLink;
                this.nextLink = this.initialNextLink;
            }
        },

        async prev() {
            if (this.currentIndex === 0) {
                if (this.prevLink) {
                    if (this.fetching) return;

                    this.fetching = true;

                    try {
                        const uuid = this.innerItems[0].message.chat;
                        const limit = 25;
                        const order_by = 'id';
                        const id__lt = this.innerItems[0].id;
                        const params = { limit, id__lt, order_by };
                        const config = { params };
                        const response = await this.$api.chat.images(uuid, config);

                        this.innerItems = [...response.results.reverse().map(obj => {
                            let { message, ...data } = obj;
                            message = this.normalizeMessage(message);
                            return { ...data, message };
                        }), ...this.innerItems];
                        this.currentIndex = response.results.length - 1;
                        this.prevLink = response.next;
                    }
                    catch (error) {
                        this.$store.commit('handleCommonHttpError', error);
                    }
                    finally {
                        this.fetching = false;
                    }
                }
            }
            else {
                this.currentIndex -= 1;
            }
        },

        async next() {
            if (this.currentIndex === (this.innerItems.length - 1)) {
                if (this.nextLink) {
                    if (this.fetching) return;

                    this.fetching = true;

                    try {
                        const uuid = this.innerItems[0].message.chat;
                        const limit = 25;
                        const id__gt = this.innerItems[0].id;
                        const params = { limit, id__gt };
                        const config = { params };
                        const response = await this.$api.chat.images(uuid, config);

                        this.innerItems = [...this.innerItems, ...response.results.map(obj => {
                            let { message, ...data } = obj;
                            message = this.normalizeMessage(message);
                            return { ...data, message };
                        })];
                        this.currentIndex += 1;
                        this.nextLink = response.next;
                    }
                    catch (error) {
                        this.$store.commit('handleCommonHttpError', error);
                    }
                    finally {
                        this.fetching = false;
                    }
                }
            }
            else {
                this.currentIndex += 1;
            }
        },

        download() {
            const url = this.$refs.image.src;
            const name = this.currentItem.mediafile.name;
            download({ url, name });
        },

        close() {
            this.$nextTick(() => {
                this.$emit('close', this._index);
            });
        },

        onClickToMessage(message) {
            this.toMessage(message);
            this.close();
        },

        handleMobileMenu({ value }) {
            if (!value) return;

            if (value.name === 'download') {
                this.download();
            }
            else if (value.name === 'toMessage') {
                this.onClickToMessage();
            }
        },
    },
};
</script>

<style>
.chat-image-view__back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-width: 320px;
    height: 100vh;
    z-index: var(--popup-z-index);
}

.chat-image-view__close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
@media (max-width: 1039px) {
    .chat-image-view__close-button {
        display: none;
    }
}

.chat-image-view__close-icon {
    fill: var(--font-secondary-color);
}

.chat-image-view__close-button:hover .chat-image-view__close-icon {
    fill: var(--light-c);
}

.chat-image-view__back::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: .8;
    z-index: -1;
}

.chat-image-view__nav-block {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 56px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
}
@media (max-width: 1039px) {
    .chat-image-view__nav-block {
        display: none;
    }
}

.chat-image-view__nav-block::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--font-secondary-dark-color);
    opacity: 0;
    transition: opacity var(--transition);
    z-index: 1;
}

.chat-image-view__to-prev-block {
    left: 0;
}

.chat-image-view__to-prev-block::after {
    border-top-right-radius: var(--border-radius-x2);
    border-bottom-right-radius: var(--border-radius-x2);
}

.chat-image-view__to-next-block {
    right: 0;
}

.chat-image-view__to-next-block::after {
    border-top-left-radius: var(--border-radius-x2);
    border-bottom-left-radius: var(--border-radius-x2);
}

.chat-image-view__to-prev-block:hover::after {
    opacity: .2;
}

.chat-image-view__to-next-block:hover::after {
    opacity: .2;
}

.chat-image-view__nav-icon {
    fill: var(--font-secondary-color);
}

.chat-image-view__nav-block:hover .chat-image-view__nav-icon {
    fill: var(--light-c);
}

.chat-image-view__flex-box {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chat-image-view__mobile-header {
    flex-shrink: 0;
    padding: 22px 26px;
    display: flex;
    align-items: center;
    background-color: var(--dark-c);
    color: var(--light-c);
}
@media (min-width: 1040px) {
    .chat-image-view__mobile-header {
        display: none;
    }
}
@media (max-width: 767px) {
    .chat-image-view__mobile-header {
        padding-left: var(--content-gap);
        padding-right: var(--content-gap);
    }
}

.chat-image-view__mobile-item:not(:last-child) {
    margin-right: 24px;
}

.chat-image-view__mobile-button {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.chat-image-view__mobile-button .u-icon,
.chat-image-view__mobile-icon {
    fill: var(--font-secondary-color);
}

.chat-image-view__mobile-button:hover .chat-image-view__mobile-icon,
.chat-image-view__mobile-button:active .chat-image-view__mobile-icon,
.chat-image-view__mobile-button:hover .u-icon,
.chat-image-view__mobile-button:active .u-icon {
    fill: var(--light-c) !important;
}

.chat-image-view__mobile-image-wrap {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--base-fz);
    line-height: var(--base-lh);
}

.chat-image-view__content {
    flex-grow: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (min-width: 1040px) {
    .chat-image-view__content {
        padding: 24px;
    }
}

.chat-image-view__spinner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-image-view__image-block {
    flex-grow: 1;
    position: relative;
    min-height: 0;
}
@media (min-width: 1040px) {
    .chat-image-view__image-block {
        margin-bottom: 36px;
    }
}
@media (max-width: 1039px) {
    .chat-image-view__image-block {
        background-color: var(--dark-c);
    }
}

.chat-image-view__image {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.chat-image-view__message-block {
    width: 100%;
    min-height: 56px;
    display: grid;
    grid-template-columns: 1fr minmax(max-content, 480px) 1fr;
    flex-shrink: 0;
    flex-grow: 0;
}
@media (max-width: 1039px) {
    .chat-image-view__message-block {
        display: none;
    }
}

.chat-image-view__sender-block {
    margin-right: 36px;
    display: flex;
}

.chat-image-view__avatar-block {
    margin-right: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-color);
    border-radius: 50%;
    overflow: hidden;
}

.chat-image-view__avatar-image {
    /*object-fit: cover;*/
    /*border-radius: 50%;*/
}

.chat-image-view__avatar-empty-image {
    font-family: var(--f-bold);
    color: var(--light-c);
    text-transform: uppercase;
}

.chat-image-view__name-block {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chat-image-view__name {
    color: var(--font-secondary-color);
}

.chat-image-view__date {
    color: var(--font-secondary-color);
    text-transform: capitalize;
}

.chat-image-view__text-block {
    max-width: 480px;
    margin-right: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-image-view__text-wrap {
    padding: 8px 12px;
    border-radius: var(--border-radius);
    background-color: var(--dark-c);
}

.chat-image-view__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--light-c);
}

.chat-image-view__buttons-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.chat-image-view__button {
    color: var(--light-c);
    fill: var(--light-c);
}

.chat-image-view__to-message-button {
    margin-right: 24px;
}
</style>