<template>
    <PopupWrap
        simple
        :loading="loading"
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h3">
                Отслеживание заказа
            </h2>
            <p v-if="subtitle" class="subtitle">
                {{ subtitle }}
            </p>

            <EmptyBlock
                v-if="!tracking.length"
                icon="box"
                text="Пока нет данных о перемещениях заказа."
                :hasBtn="false"
                class="empty-block"
            ></EmptyBlock>

            <div
                v-if="tracking.length > 1"
                class="packs-buttons-nav navigation-block"
            >
                <div
                    v-show="canScrollLeft"
                    class="navigation__arrow_prev-wrap"
                    @mouseenter="startScroll('left')"
                    @mouseleave="stopScroll"
                >
                    <UIconButton
                        name="arrow-left"
                        secondary
                        shadow
                        round
                        class="navigation__arrow navigation__arrow_prev"
                    ></UIconButton>
                </div>

                <div class="navigation_outer">
                    <ol
                        ref="navigation"
                        class="packs-buttons-list navigation"
                        @scroll="checkScroll"
                    >
                        <li
                            v-for="(item, index) in tracking"
                            :key="'button-' + item.barcode"
                            class="packs-buttons-list__item"
                        >
                            <button
                                type="button"
                                class="packs-button"
                                :class="{
                                    'packs-button_selected': item.barcode === currentPack,
                                }"
                                @click="currentPack = item.barcode"
                            >
                                <span class="packs-button__title">№ {{ item.barcode }}</span>
                                <span class="d-block text_secondary text_small-fz">Отправление {{ index + 1 }}</span>
                            </button>
                        </li>
                    </ol>
                </div>

                <div
                    v-show="canScrollRight"
                    class="navigation__arrow_next-wrap"
                    @mouseenter="startScroll('right')"
                    @mouseleave="stopScroll"
                >
                    <UIconButton
                        name="arrow-right"
                        secondary
                        shadow
                        round
                        class="navigation__arrow navigation__arrow_next"
                    ></UIconButton>
                </div>
            </div>

            <div
                v-for="track in tracking"
                v-show="track.barcode === currentPack"
                :key="'log-' + track.barcode"
                class="mt-20"
            >
                <UTimeline v-if="track.tracking_logs.length">
                    <UTimelineItem
                        v-for="item in track.tracking_logs"
                        :key="item.operation_date"
                        :icon="item.operation_code === '2' || item.operation_code === 'DELIVERED' ? 'done' : ''"
                        :dotColor="item.operation_code === '2' || item.operation_code === 'DELIVERED' ? 'green' : 'grey'"
                    >
                        <p class="text_small-lh">
                            {{ item.attribute_name || item.operation_name }}
                        </p>
                        <p class="mt-4 text_secondary text_small-lh">
                            {{ item.operation_date | datetime }}, {{ item.address_description }}
                        </p>
                    </UTimelineItem>
                </UTimeline>
                <EmptyBlock
                    v-else
                    icon="box"
                    text="Пока нет данных о перемещениях заказа."
                    :hasBtn="false"
                    class="empty-block"
                ></EmptyBlock>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import plural from '@/lib/plural.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
import UTimeline from '@/components/UTimeline/UTimeline.vue';
import UTimelineItem from '@/components/UTimeline/UTimelineItem.js';
import EmptyBlock from '@/components/EmptyBlock.vue';

export default {
    name: 'TrackWorldDeliveryPopup',

    components: {
        EmptyBlock,
        UTimelineItem,
        UTimeline,
        UIconButton,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        claim: {
            type: Object,
            default: () => {},
        },
        order: {
            type: String,
            default: '',
        },
        trackNumber: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            loading: true,
            tracking: [],
            currentPack: '',

            scrollInterval: null,
            canScrollLeft: false,
            canScrollRight: false,
        };
    },

    computed: {
        subtitle() {
            if (this.tracking.length) {
                const pluralText = this.tracking.length
                    + ' '
                    + plural(this.tracking.length, ['отправление', 'отправления', 'отправлений']);

                return `${ this.claim.service.name } / ${ this.tracking.length > 1 ? pluralText : '№ ' + this.tracking[0].barcode }`;
            }
            return '';
        },
    },

    async mounted() {
        this.tracking = await this.$api.orders.tracking(this.order);

        if (this.tracking.length) {
            if (this.trackNumber) {
                this.currentPack = this.trackNumber;
            }
            else {
                this.currentPack = this.tracking[0].barcode;
            }
        }

        this.loading = false;

        if (this.tracking.length > 1) {
            this.$nextTick(() => {
                this.checkScroll();
            });
        }
    },

    beforeDestroy() {
        clearInterval(this.scrollInterval);
    },

    methods: {
        checkScroll() {
            let blockWidth = this.$refs.navigation.offsetWidth;
            let scrollWidth = this.$refs.navigation.scrollWidth;
            let scrollValue = this.$refs.navigation.scrollLeft;

            this.canScrollRight = scrollValue < scrollWidth - blockWidth;
            this.canScrollLeft = scrollValue > 0;
        },

        startScroll(direction) {
            let blockWidth = this.$refs.navigation.offsetWidth;
            let scrollWidth = this.$refs.navigation.scrollWidth;
            let scrollValue = this.$refs.navigation.scrollLeft;

            this.scrollInterval = setInterval(() => {
                if (direction === 'left') {
                    if (scrollValue > 0) {
                        scrollValue -= 2;
                    }
                    else {
                        clearInterval(this.scrollInterval);
                    }
                }
                else {
                    if (scrollValue < scrollWidth - blockWidth) {
                        scrollValue += 2;
                    }
                    else {
                        clearInterval(this.scrollInterval);
                    }
                }

                this.$refs.navigation.scrollLeft = scrollValue;
            }, 1);
        },

        stopScroll() {
            clearInterval(this.scrollInterval);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.empty-block {
    box-shadow: none;
}

.subtitle {
    margin-top: 4px;
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}

.packs-buttons-nav {
    margin-top: 20px;
}

.packs-buttons-list {
    display: flex;
    overflow: auto;
}

.packs-buttons-list__item {
    flex-shrink: 0;
}

.packs-buttons-list__item:not(:last-child) {
    margin-right: 8px;
}

.packs-button {
    padding: 8px 16px;
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius);
    text-align: left;
    transition: border-color var(--transition);
}

.packs-button:hover,
.packs-button:focus,
.packs-button_selected {
    border-color: var(--primary-color);
}

.packs-button__title {
    display: block;
    transition: color var(--transition);
}

.packs-button_selected .packs-button__title {
    color: var(--primary-color);
}


.navigation-block {
    position: relative;
}

.navigation_outer {
    overflow: hidden;
}

.navigation__arrow_prev-wrap,
.navigation__arrow_next-wrap {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 52px;
    height: 100%;
    display: flex;
    align-items: center;
}

.navigation__arrow_prev-wrap {
    left: 0;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.navigation__arrow_next-wrap {
    justify-content: flex-end;
    right: 0;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.navigation__arrow {
    position: relative;
}

.navigation__arrow_prev {
    left: -18px;
}

.navigation__arrow_next {
    right: -18px;
}
</style>
