<template>
    <header class="header">
        <div class="header-container container">
            <UbibiLogo base></UbibiLogo>

            <UserBar
                name
                class="user-bar"
            ></UserBar>
        </div>
    </header>
</template>

<script>
import UserBar from '@/components/header/UserBar.vue';
import UbibiLogo from '@/components/UbibiLogo.vue';

export default {
    name: 'SimpleLayoutHeader',

    components: { UbibiLogo, UserBar },
};
</script>

<style scoped>
.header {
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
}
@media (min-width: 768px) {
    .header {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}
@media (max-width: 767px) {
    .header {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-bar {
    margin-right: -8px;
}
</style>