export default {
    namespaced: true,

    state: () => ({
        initialized: false,
        meta: {},
        partsCategories: {},
        technicalInspectionMeta: {},
        popularPartCategories: {
            items: [],
            loading: true,
        },
        brands: {
            current_count: 0,
            items: [],
        },
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setPopularPartCategories(state, value) {
            state.popularPartCategories.items = value;
        },

        setPopularPartCategoriesFetching(state, value) {
            state.popularPartCategories.loading = value;
        },

        setBrandsCount(state, { current_count }) {
            state.brands.current_count = current_count;
        },

        setBrandsItems(state, { results }) {
            state.brands.items = results;
        },

        setPartsCategories(state, partsCategories) {
            state.partsCategories = partsCategories;
        },

        setMeta(state, { meta, technicalInspectionMeta }) {
            state.meta = meta;
            state.technicalInspectionMeta = technicalInspectionMeta;
        },

        destroy(state) {
            state.initialized = false;
            state.meta = {};
            state.partsCategories = {};
            state.technicalInspectionMeta = {};
            state.brands = {
                current_count: 0,
                items: [],
            };
        },
    },

    actions: {
        async init({ commit }) {
            try {
                const [brandsCount, brandsList, meta, partsCategories, technicalInspectionMeta] = await Promise.all(
                    [
                        this.$api.brands.get({ params:{
                            limit: 1,
                            offset: 0,
                            order_by: '?',
                        } }),
                        this.$api.brands.get({ params: {
                            limit: 8,
                            offset: 0,
                            order_by: '?',
                            is_visible_on_main_page: true,
                            has_logo: 'true',
                        } }),
                        this.$api.staticPages.get('market'),
                        this.$api.partCategories.get({
                            params: {
                                parent_id__isnull: 1,
                                order_by: 'tree_id',
                                limit: 10,
                            },
                        }),
                        this.$api.staticPages.get('market-maintenance'),
                    ],
                );

                commit('setBrandsCount', brandsCount);
                commit('setBrandsItems', brandsList);
                commit('setPartsCategories', partsCategories);
                commit('setMeta', { meta, technicalInspectionMeta });
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async fetchPopularPartCategories({ commit }, config) {
            try {
                commit('setPopularPartCategoriesFetching', true);

                const { results } = await this.$api.popularPartCategories.get(config);

                commit('setPopularPartCategories', results);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
            finally {
                commit('setPopularPartCategoriesFetching', false);
            }
        },
    },
};