export default {
    namespaced: true,

    state: () => ({
        car: {},
        catalogGroupsTree: [],
        initialized: false,
    }),

    getters: {
        productionDate: state => {
            if (state.car.prod_date) {
                let str = state.car.prod_date.year;
                if (state.car.prod_date.month) {
                    str += '-';
                    str += state.car.prod_date.month.toString().padStart(2, '0');

                    if (state.car.prod_date.day) {
                        str += '-';
                        str += state.car.prod_date.day.toString().padStart(2, '0');
                    }
                }
                return str;
            }
            else {
                return '';
            }
        },
    },

    mutations: {
        setCar(state, data) {
            state.car = data;
        },

        setCatalogGroupsTree(state, { results }) {
            state.catalogGroupsTree = results;
        },

        setInitialized(state) {
            state.initialized = true;
        },

        destroy(state) {
            state.car = {};
            state.catalogGroupsTree = [];
            state.initialized = false;
        },
    },

    actions: {
        async init({ commit, dispatch }, { id, production_date }) {
            try {
                const car = await dispatch('getCar', { id });
                if (production_date) {
                    const dateArr = production_date.split('-');
                    car.prod_date = {
                        year: dateArr[0],
                        month: dateArr[1],
                        day: dateArr[2],
                    };
                }
                const catalogGroupsTree = await dispatch('getCatalogGroupsTree', { car });

                commit('setCar', car);
                commit('setCatalogGroupsTree', catalogGroupsTree);
                commit('setInitialized');
                return { car, catalogGroupsTree };
            }
            catch (error) {
                commit('handleApiError', error, { root: true });
            }
        },

        async getCar({}, { id }) {
            return await this.$api.cars.get(id);
        },

        async getCatalogGroupsTree({}, { car }) {
            const params = {
                generation_id: car.generation.id,
                car_id: car.id,
                manufacturer: car.manufacturer.codename,
                parent_id__isnull: 1,
                limit: 100,
                offset: 0,
                order_by: 'name',
            };
            return await this.$api.catalogGroups.get({ params });
        },
    },
};