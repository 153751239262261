<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <FormManager
            ref="form"
            v-model="formData"
            class="popup-content"
            @submit="submit"
        >
            <h2 class="h2 h2_block popup-title">
                Написать нам
            </h2>

            <div class="form-body">
                <FormField
                    name="sender"
                    label="ФИО"
                    validations="required"
                    class="form-field-gap_bottom"
                ></FormField>

                <div class="email-phone-grid-wrap">
                    <FormField
                        type="email"
                        name="email"
                        label="E-mail"
                        validations="required"
                        class="form-field-gap_bottom"
                    ></FormField>

                    <FormField
                        type="phone"
                        name="phone"
                        visibleMask
                        label="Телефон"
                        class="form-field-gap_bottom"
                    ></FormField>
                </div>

                <FormField
                    name="subject"
                    label="Тема"
                    validations="required"
                    class="form-field-gap_bottom"
                ></FormField>

                <FormField
                    type="text"
                    name="message"
                    label="Вопрос или предложение"
                    validations="required"
                ></FormField>
            </div>

            <div class="footer">
                <FormAgreement
                    text="Отправить"
                    class="agreement"
                ></FormAgreement>

                <ButtonBlock
                    submit
                    primary
                    :loading="loading"
                    class="footer__btn"
                >
                    Отправить
                </ButtonBlock>
            </div>
        </FormManager>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import FormAgreement from '../FormAgreement.vue';
import ButtonBlock from '../_buttons/ButtonBlock.vue';
import FormManager from '@/components/_form/FormManager.vue';
import FormField from '@/components/_form/FormField.vue';


export default {
    name: 'WriteUsPopup',

    components: {
        FormField,
        FormManager,
        ButtonBlock,
        FormAgreement,
        PopupWrap,
    },

    mixins: [popup],

    data() {
        return {
            formData: {
                sender: '',
                phone: '',
                email: '',
                subject: '',
                message: '',
            },

            loading: false,
        };
    },

    methods: {
        async submit() {
            const errors = await this.$refs.form.validate();

            if (errors) return;

            this.loading = true;

            this.$api.feedback.post(this.formData)
                .then(() => {
                    this.close();
                    this.$success('Сообщение отправлено, мы свяжемся с вами в ближайшее время');
                })
                .catch(error => {
                    this.$refs.form.handle(error);
                    this.$store.commit('handleCommonHttpError', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

@media (min-width: 768px) {
    .email-phone-grid-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
}

.footer {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .footer {
        margin-top: auto;
    }
}

@media (max-width: 767px) {
    .footer__btn {
        width: 100%;
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .agreement {
        max-width: 480px;
        margin-right: 20px;
    }
}
</style>