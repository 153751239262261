<template>
    <div
        v-if="withoutTooltip"
        class="time"
        :class="{
            'time_open': status.place.is_active,
            'time_close': !status.place.is_active,
        }"
    >
        {{ status.place.is_active ? 'Открыто' : 'Закрыто' }} до {{ status.place.text }}
    </div>

    <UTooltip
        v-else
        @open="init"
        @afterClose="afterClose"
    >
        <template #trigger="{ handlers, attrs }">
            <p
                v-bind="attrs"
                class="time"
                :class="{
                    'time_open': status.place.is_active,
                    'time_close': !status.place.is_active,
                }"
                v-on="handlers"
            >
                {{ status.place.is_active ? 'Открыто' : 'Закрыто' }} до {{ status.place.text }}
            </p>
        </template>

        <div v-if="loading" class="flex align-items-center justify-content-center">
            <USpinner light></USpinner>
        </div>

        <ShopWorkSchedule
            v-else
            :work="(tooltipData && tooltipData.work_times) || shopInfo.work_times"
            :holidays="(tooltipData && tooltipData.holidays) || shopInfo.holidays"
            onDarkBg
            class="tooltip-schedule"
        ></ShopWorkSchedule>
    </UTooltip>
</template>

<script>
import { HTTP } from '@/http.js';
import ShopWorkSchedule from './ShopWorkSchedule.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';


export default {
    name: 'ShopOpenCloseTime',

    components: { USpinner, UTooltip, ShopWorkSchedule },

    props: {
        status: {
            type: Object,
            default: () => {},
        },

        shopId: {
            type: Number,
            default: 0,
        },

        tooltipData: {
            type: Object,
            default: () => {},
        },

        withoutTooltip: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            shopInfo: {},
            loading: false,
            source: null,
        };
    },

    methods: {
        async init() {
            if (!this.tooltipData && !this.shopInfo.id) {
                this.loading = true;

                if (this.source) {
                    this.source.cancel();
                    this.source = null;
                }

                this.source = HTTP.CancelToken.source();
                const cancelToken = this.source.token;
                const config = { cancelToken };

                try {
                    this.shopInfo = await this.$api.shops.get(this.shopId, config);
                }
                catch (error) {
                    this.$store.commit('handleCommonHttpError', error);
                }
                finally {
                    this.loading = false;
                    this.source = null;
                }
            }
        },

        afterClose() {
            if (this.source) {
                this.source.cancel();
                this.cancelToken = null;
                this.shopInfo = {};
            }
        },
    },
};
</script>

<style scoped>
.time {
    display: inline-block;
    font-size: var(--base-fz);
    cursor: default;
}

.time_open {
    color: var(--accent-green);
}

.time_close {
    color: var(--primary-color);
}

.tooltip-schedule {
    width: 200px;
}
</style>