<template>
    <AccountLayout title="Избранное">
        <template>
            <section>
                <div
                    v-if="!initialized"
                    class="spinner-wrap"
                >
                    <Spinner
                        center
                        size="big"
                    ></Spinner>
                </div>

                <template v-else>
                    <MarketItemNavigation
                        class="layer-1 bottom-gap_grid"
                        small
                        :items="[
                            {
                                to: {
                                    name: 'account-favorites-parts'
                                },
                                label: 'Запчасти',
                                amount: parts.current_count,
                            },
                            {
                                to: {
                                    name: 'account-favorites-products'
                                },
                                label: 'Автотовары',
                                amount: products.current_count,
                            },
                            // {
                            //     to: {
                            //         name: 'account-favorites-contract-parts'
                            //     },
                            //     label: 'Контр. запчасти',
                            //     amount: contractParts.current_count,
                            // },
                            {
                                to: {
                                    name: 'account-favorites-brands'
                                },
                                label: 'Бренды',
                                amount: brands.current_count,
                            },
                            {
                                to: {
                                    name: 'account-favorites-shops'
                                },
                                label: 'Магазины',
                                amount: shops.current_count,
                            },
                        ]"
                    ></MarketItemNavigation>

                    <transition
                        name="fade-page"
                        mode="out-in"
                    >
                        <RouterView></RouterView>
                    </transition>
                </template>
            </section>
        </template>
    </AccountLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import MarketItemNavigation from "@/components/MarketItemNavigation.vue";
import Spinner from "@/components/Spinner.vue";
import AccountLayout from "@/pages/account/AccountLayout.vue";
import profileFavorites from '@/store/modules/profileFavorites.js';


export default {
    name: 'ProfileFavorites',

    components: { AccountLayout, Spinner, MarketItemNavigation },

    serverPrefetch() {
        return this.INIT();
    },

    beforeRouteUpdate(to, from, next) {
        if (to.name !== 'account-favorites') {
            next();
        }
    },

    computed: {
        ...mapState({
            initialized: state => state.profileFavorites.initialized,
            parts: state => state.profileFavorites.parts,
            contractParts: state => state.profileFavorites.contractParts,
            products: state => state.profileFavorites.products,
            brands: state => state.profileFavorites.brands,
            shops: state => state.profileFavorites.shops,
        }),

        ...mapGetters({
            priorityRoute: 'profileFavorites/priorityRoute',
        }),
    },

    created() {
        this.$store.registerModule('profileFavorites', profileFavorites);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('profileFavorites');
    },

    methods: {
        ...mapMutations({
            DESTROY: 'profileFavorites/destroy',
        }),

        ...mapActions({
            INIT: 'profileFavorites/init',
        }),

        async init() {
            if (!this.initialized) await this.INIT();

            if (this.$route.name === 'account-favorites') {
                this.redirect();
            }
        },

        redirect() {
            this.$router.replace({
                name: this.priorityRoute,
            });
        },
    },
};
</script>

<style scoped>
.spinner-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}
</style>