import isValidDate from '@ui/utils/isValidDate.js';
import pad from '@ui/utils/pad.js';


export const empty = value => {
    if (value == null || value === '') {
        return '---';
    }
    else {
        return value;
    }
};

export const price = value => {
    const number = Number(value);

    if (value == null || isNaN(number)) return value;

    const decimal = Number(number % 1);
    const negative = number < 0;

    return (negative ? '– ' : '') + Math.abs(number).toLocaleString(
        'ru-RU',
        {
            useGrouping: true,
            minimumFractionDigits: decimal ? 2 : 0,
            maximumFractionDigits: decimal ? 2 : 0,
        }) + '\xa0₽';
};

export const pieces = value => {
    const number = Number(value);

    if (value == null || isNaN(number)) return value;

    return number.toLocaleString(
        'ru-RU',
        {
            useGrouping: true,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }) + '\xa0шт.';
};

export const packs = value => {
    const number = Number(value);

    if (value == null || isNaN(number)) return value;

    return number.toLocaleString(
        'ru-RU',
        {
            useGrouping: true,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }) + '\xa0уп.';
};

export const integer = value => {
    const number = Number(value);

    if (value == null || isNaN(number)) return value;

    const negative = number < 0;

    return (negative ? '– ' : '') + Math.abs(number).toLocaleString(
        'ru-RU',
        {
            useGrouping: true,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
};

export const decimal = value => {
    const number = Number(value);
    if (value == null || isNaN(number)) return value;

    const decimal = Number(number % 1);
    return Number(number).toLocaleString('ru-RU',
        {
            minimumFractionDigits: decimal ? 2 : 0,
            maximumFractionDigits: decimal ? 2 : 0,
        });
};

export const plural = (n, titles) => {
    const title = titles[n % 10 === 1 && n % 100 !== 11
        ? 0
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
            ? 1
            : 2];

    return integer(n) + ' ' + title;
};

export const dt = (value, format) => {
    if (!value) return value;

    const date = new Date(value);
    const isValid = isValidDate(date);

    if (!isValid) return value;

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    if (format === 'DD.MM.YYYY в HH:mm') {
        return `${ day }.${ month }.${ year } в ${ hours }:${ minutes }`;
    }
    else if (format === 'DD.MM.YYYY, HH:mm') {
        return `${ day }.${ month }.${ year }, ${ hours }:${ minutes }`;
    }
    else if (format === 'DD.MM.YYYY HH:mm') {
        return `${ day }.${ month }.${ year } ${ hours }:${ minutes }`;
    }
    else if (format === 'DD.MM.YYYY') {
        return `${ day }.${ month }.${ year }`;
    }
    else if (format === 'MM.YYYY') {
        return `${ month }.${ year }`;
    }
    else if (format === 'DD MMMM') {
        return date.toLocaleString('ru-RU', {
            day: '2-digit',
            month: 'long',
        });
    }
    else if (format === 'DD MM') {
        const str = date.toLocaleString('ru-RU', {
            day: '2-digit',
            month: 'short',
        });

        return str.slice(0, -1);
    }
    else if (format === 'DD MM, dd') {
        const str = date.toLocaleString('ru-RU', {
            day: '2-digit',
            month: 'short',
            weekday: 'short',
        });

        const arr = str.split(', ');
        return arr[1].slice(0, -1) + ', ' + arr[0];
    }

    return value;
};

export const phone = value => {
    if (value) {
        const pattern = /(\+\d)(\d{3})(\d{3})(\d{2})(\d{2})/g;
        return value.replace(pattern, '$1 ($2) $3-$4-$5');
    }

    return value;
};
