var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UTooltip',{attrs:{"noDetach":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var attrs = ref.attrs;
var handlers = ref.handlers;
return [_c('button',_vm._g(_vm._b({ref:"button",staticClass:"chat-btn btn btn_icon",class:{
                'chat-btn_disabled': !_vm.id || _vm.disabled,
                'chat-btn_online': _vm.isOnline && !_vm.disabled,
                'chat-btn_with-text': _vm.withText,
                'chat-btn_always-with-text': _vm.btnTextAlwaysVisible,
            },attrs:{"type":"button","disabled":!_vm.id || _vm.disabled},on:{"click":_vm.onClick}},'button',attrs,false),handlers),[_c('UIcon',{staticClass:"chat-btn__icon",attrs:{"name":"chat-btn-stroke"}}),_vm._v(" "),(_vm.withText)?_c('span',{staticClass:"chat-text",class:{
                    'chat-text_always-visible': _vm.btnTextAlwaysVisible
                }},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.btnText))]})],2):_vm._e()],1)]}}],null,true)},[_vm._v(" "),(_vm.disabled)?_c('span',[_vm._v("\n        Продавец отключил онлайн-чат\n    ")]):_c('span',[_vm._v("\n        Написать продавцу в онлайн-чате\n    ")])])}
var staticRenderFns = []

export { render, staticRenderFns }