<template>
    <div class="navigation-block">
        <div
            v-if="breakpointKey === 'l' && canScrollLeft"
            class="navigation__arrow_prev-wrap"
            @mouseenter="startScroll('left')"
            @mouseleave="stopScroll"
        >
            <RoundArrowBtn
                direction="left"
                size="24"
                class="navigation__arrow navigation__arrow_prev"
            ></RoundArrowBtn>
        </div>

        <div class="navigation_outer">
            <div
                ref="navigation"
                class="navigation"
                :class="{
                    'navigation_no-desktop-scroll': noDesktopScroll,
                    'navigation_no-scroll': noScroll,
                }"
                @scroll="updateScroll"
            >
                <slot></slot>
            </div>
        </div>

        <span
            v-if="!noUnderline"
            ref="cursor"
            class="cursor"
        ></span>

        <div
            v-if="breakpointKey === 'l' && canScrollRight"
            class="navigation__arrow_next-wrap"
            @mouseenter="startScroll('right')"
            @mouseleave="stopScroll"
        >
            <RoundArrowBtn
                direction="right"
                size="24"
                class="navigation__arrow navigation__arrow_next"
            ></RoundArrowBtn>
        </div>
    </div>
</template>

<script>
import RoundArrowBtn from '@/components/project-buttons/RoundArrowBtn.vue';
import breakpointKey from '@/mixins/breakpointKey.js';

export default {
    name: 'NavigationTabs',

    components: { RoundArrowBtn },

    mixins: [breakpointKey],

    props: {
        currentIndex: {
            type: Number,
            default: 0,
        },

        noUnderline: {
            type: Boolean,
            default: false,
        },

        noScroll: Boolean,
        noDesktopScroll: Boolean,
    },

    data() {
        return {
            interval: null,
            canScrollLeft: false,
            canScrollRight: false,
        };
    },

    watch: {
        currentIndex() {
            this.setCursorPosition();
        },
    },

    mounted() {
        this.updateScroll();
        window.addEventListener('resize', this.updateScroll);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.updateScroll);
        clearInterval(this.interval);
    },

    methods: {
        updateScroll() {
            this.checkScroll();
            this.setCursorPosition();
        },

        checkScroll() {
            let blockWidth = this.$refs.navigation.offsetWidth;
            let scrollWidth = this.$refs.navigation.scrollWidth;
            let scrollValue = this.$refs.navigation.scrollLeft;

            this.canScrollRight = scrollValue < scrollWidth - blockWidth;
            this.canScrollLeft = scrollValue > 0;
        },

        startScroll(direction) {
            let blockWidth = this.$refs.navigation.offsetWidth;
            let scrollWidth = this.$refs.navigation.scrollWidth;
            let scrollValue = this.$refs.navigation.scrollLeft;

            this.interval = setInterval(() => {
                if (direction === 'left') {
                    if (scrollValue > 0) {
                        scrollValue -= 1;
                    }
                    else {
                        clearInterval(this.interval);
                    }
                }
                else {
                    if (scrollValue < scrollWidth - blockWidth) {
                        scrollValue += 1;
                    }
                    else {
                        clearInterval(this.interval);
                    }
                }

                this.$refs.navigation.scrollLeft = scrollValue;
            }, 1);
        },

        stopScroll() {
            clearInterval(this.interval);
        },

        setCursorPosition() {
            if (!this.noUnderline && this.$refs.navigation) {
                const currentLeft = this.$refs.navigation.children[this.currentIndex].offsetLeft;
                const currentWidth = this.$refs.navigation.children[this.currentIndex].clientWidth;
                this.$refs.cursor.style.left = currentLeft - this.$refs.navigation.scrollLeft + 'px';
                this.$refs.cursor.style.width = currentWidth + 'px';
            }
        },
    },
};
</script>

<style scoped>
.navigation-block {
    position: relative;
}

.navigation_outer {
    overflow: hidden;
}

.navigation {
    display: flex;
}

.navigation:not(.navigation_no-scroll) {
    margin-bottom: -40px;
    padding-bottom: 40px;
    overflow: auto;
}

.navigation_no-scroll::before,
.navigation_no-scroll::after {
    content: none;
}
@media (min-width: 768px) {
    .navigation_no-desktop-scroll::before,
    .navigation_no-desktop-scroll::after {
        content: none;
    }
}

.cursor {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 0;
    height: 2px;
    background-color: var(--primary-color);
    border-radius: var(--border-radius);
    transition: all var(--transition);
}

.navigation__arrow_prev-wrap,
.navigation__arrow_next-wrap {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 132px;
    height: 100%;
    transform: translateY(-50%);
}

.navigation__arrow_prev-wrap {
    left: 0;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.navigation__arrow_next-wrap {
    right: 0;
    justify-content: flex-end;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.navigation__arrow {
    position: relative;
    background-color: var(--light-c);
}

.navigation__arrow_prev {
    left: -12px;
}

.navigation__arrow_next {
    right: -12px;
}
</style>