<script>
export default {
    name: 'UTrigger',

    props: {
        activateOnClick: Boolean,
        activateOnFocus: Boolean,
        deactivateOnBlur: Boolean,
        deactivateOnHoverContent: Boolean,
        deactivateOnClickContent: Boolean,
        deactivateOnResize: {
            type: Boolean,
            default: true,
        },
        deactivateOnClickOut: {
            type: Boolean,
            default: true,
        },
        deactivateOnScroll: {
            type: Boolean,
            default: true,
        },
        delayEnter: {
            type: Number,
            default: 200,
        },
        delayLeave: {
            type: Number,
            default: 100,
        },
    },

    data() {
        return {
            value: false,
            triggerNode: null,
            triggerElement: null,
            contentNode: null,
            enterTimeout: null,
            leaveTimeout: null,
        };
    },

    computed: {
        attrs() {
            const attrs = {
                'aria-haspopup': true,
                'aria-expanded': String(this.value),
            };

            if (this.activateOnClick) {
                attrs.role = 'button';
            }

            return attrs;
        },

        handlers() {
            const handlers = {};

            if (this.activateOnClick) {
                handlers.click = this.handleClick;
            }
            else if (this.activateOnFocus) {
                handlers.focusin = this.handleFocusIn;

                if (this.deactivateOnBlur) {
                    handlers.focusout = this.handleFocusOut;
                }
            }
            else {
                handlers.mouseenter = this.handleMouseEnter;
                handlers.mouseleave = this.handleMouseLeave;
            }

            return handlers;
        },

        contentAttrs() {
            return {};
        },

        contentHandlers() {
            const handlers = {};

            if (this.value) {
                if (this.activateOnFocus) {
                    if (this.deactivateOnBlur) {
                        handlers.focusout = this.handleFocusOut;
                    }
                }
                else if (this.activateOnClick) {
                    //
                }
                else {
                    handlers.mouseenter = this.handleMouseEnterContent;
                    handlers.mouseleave = this.handleMouseLeaveContent;
                }

                if (this.deactivateOnClickContent) {
                    handlers.click = this.handleClickContent;
                }
            }

            return handlers;
        },

        content() {
            const attrs = this.contentAttrs;
            const handlers = this.contentHandlers;

            return { attrs, handlers };
        },
    },

    watch: {
        value: 'onChangeValue',
    },

    methods: {
        getTriggerElement($event) {
            if (this.triggerElement) return;
            this.triggerElement = $event.currentTarget || $event.target;
        },

        onChangeValue(value) {
            const target = this;
            this.$emit('change', { target, value });

            if (value) {
                this.deactivateOnClickOut && this.addClickOutListener();
                this.deactivateOnResize && this.addResizeListener();
                this.deactivateOnScroll && this.addScrollListener();
            }
            else {
                this.deactivateOnClickOut && this.removeClickOutListener();
                this.deactivateOnResize && this.removeResizeListener();
                this.deactivateOnScroll && this.removeScrollListener();
            }
        },

        addClickOutListener() {
            addEventListener('click', this.handleClickOut, true);
        },

        addResizeListener() {
            addEventListener('resize', this.handleResize);
        },

        addScrollListener() {
            addEventListener('scroll', this.handleScroll, true);
        },

        removeClickOutListener() {
            removeEventListener('click', this.handleClickOut, true);
        },

        removeResizeListener() {
            removeEventListener('resize', this.handleResize);
        },

        removeScrollListener() {
            removeEventListener('scroll', this.handleScroll, true);
        },

        // public

        toggle() {
            if (this.value) {
                this.deactivate();
            }
            else {
                this.activate();
            }
        },

        async activate() {
            if (this.value) return;

            if (this.$listeners['beforeActivate']) {
                await this.emitBeforeActivated();
            }

            this.value = true;

            this.emitActivated();
        },

        deactivate() {
            if (!this.value) return;

            this.value = false;

            this.emitDeactivated();
        },

        // handlers

        handleClickOut($event) {
            const trigger = this.triggerNode.map(node => node.elm);
            const content = this.contentNode.map(node => node.elm);
            const elements = [...content, ...trigger];

            if (!elements.some(el => el.contains($event.target))) {
                this.value = false;
            }
        },

        handleResize() {
            this.deactivate();
        },

        handleScroll($event) {
            if (this.contentNode.some(vNode => vNode.elm.contains($event.target))) return;

            this.deactivate();
        },

        handleClick($event) {
            this.getTriggerElement($event);
            this.toggle();
        },

        handleClickContent() {
            this.value = false;
        },

        handleMouseEnter($event) {
            this.getTriggerElement($event);

            if (this.value) {
                if (this.leaveTimeout) {
                    clearTimeout(this.leaveTimeout);
                    this.leaveTimeout = null;
                }
            }
            else {
                this.enterTimeout = setTimeout(() => {
                    this.value = true;
                }, this.delayEnter);
            }
        },

        handleMouseEnterContent() {
            if (this.leaveTimeout) {
                clearTimeout(this.leaveTimeout);
                this.leaveTimeout = null;
            }
        },

        handleMouseLeave($event) {
            this.getTriggerElement($event);

            if (this.value) {
                this.leaveTimeout = setTimeout(() => {
                    this.value = false;
                }, this.delayLeave);
            }
            else {
                if (this.enterTimeout) {
                    clearTimeout(this.enterTimeout);
                    this.enterTimeout = null;
                }
            }
        },

        handleMouseLeaveContent() {
            this.leaveTimeout = setTimeout(() => {
                this.value = false;
            }, this.delayLeave);
        },

        handleFocusIn($event) {
            this.getTriggerElement($event);
            this.value = true;
        },

        handleFocusOut($event) {
            const target = $event.relatedTarget;

            if (!target) {
                this.value = false;
                return;
            }

            const elements = [...this.contentNode, ...this.triggerNode];

            if (elements.some(node => node && node.elm && node.elm.contains(target))) return;

            this.value = false;
        },

        // emits

        emitBeforeActivated() {
            let resolve;

            const promise = new Promise(res => {
                resolve = res;
            });
            const done = resolve;
            const target = this;
            this.$emit('beforeActivate', { target, done });

            return promise;
        },

        emitActivated() {
            const target = this;
            this.$emit('activated', { target });
        },

        emitDeactivated() {
            const target = this;
            this.$emit('deactivated', { target });
        },

        // render

        genTrigger() {
            if (!this.$scopedSlots.trigger) return;

            const { value, handlers, attrs } = this;

            const node = this.$scopedSlots.trigger({
                value,
                attrs,
                handlers,
            });

            this.triggerNode = node;

            return node;
        },

        genContent() {
            if (!this.$scopedSlots.content) return;

            const value = this.value;
            const { handlers, attrs } = this.content;

            const node = this.$scopedSlots.content({
                value,
                attrs,
                handlers,
            });

            this.contentNode = node;

            return node;
        },
    },

    render(h) {
        const trigger = this.genTrigger();
        const content = this.genContent();

        return h('div', {
            staticClass: 'u-trigger',
        }, [trigger, content]);
    },
};
</script>

<style>
.u-trigger {
    display: inline-block;
}
</style>