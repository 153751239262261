var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-gap_medium"},[(!_vm.initialized)?_c('Spinner',{attrs:{"center":"","size":"big"}}):[_c('h1',{staticClass:"h1 h1_block"},[_vm._v("\n            "+_vm._s(_vm.manufacturer.page_title)+"\n        ")]),_vm._v(" "),_c('section',{staticClass:"car-select-section layer-1"},[_c('h2',{staticClass:"h3"},[_vm._v("\n                Выберите автомобиль\n            ")]),_vm._v(" "),(_vm.globalSearchSelectedCar.id)?_c('p',{staticClass:"mt-8 mb-36"},[_c('ButtonText',{attrs:{"to":{
                        name: 'market-parts-catalogs-manufacturer-modification',
                        params: {
                            manufacturer: _vm.globalSearchSelectedCar.manufacturer.codename,
                            modification: _vm.globalSearchSelectedCar.id
                        },
                        query: Object.assign({}, _vm.carProductionDate ? {
                            production_date: _vm.carProductionDate
                        } : {})
                    },"primary":"","dashed":""}},[_vm._v("\n                    Выбрать\n                    "),_c('CarName',{staticClass:"select-car-info-message__car-name",attrs:{"car":_vm.globalSearchSelectedCar,"block":false}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"car-select__layout"},[_c('form',{staticClass:"car-select-form"},[_c('div',{staticClass:"car-select-form__fields"},[_c('Selector',{staticClass:"car-select-form__field",attrs:{"label":"Модель","options":_vm.carModelOptions,"optionLabel":"name","emptyValue":{},"loading":_vm.carModelOptionsLoading},on:{"change":_vm.handleCarModelSelect},model:{value:(_vm.carModel),callback:function ($$v) {_vm.carModel=$$v},expression:"carModel"}}),_vm._v(" "),_c('Selector',{staticClass:"car-select-form__field",attrs:{"label":"Год выпуска","options":_vm.yearsOptions,"optionLabel":"name","emptyValue":{},"disabled":!_vm.canSearchYear,"loading":_vm.carYearsOptionsLoading},on:{"change":_vm.handleCarYearSelect},model:{value:(_vm.carYear),callback:function ($$v) {_vm.carYear=$$v},expression:"carYear"}}),_vm._v(" "),(_vm.showAttrsSelects)?_vm._l((_vm.attrsSelects),function(item){return _c('Selector',{key:item.codename,staticClass:"car-select-form__field",attrs:{"label":item.name,"options":item.options,"getOptionLabel":function (option) { return ("" + (option.value && option.value !== option.name && option.value !== '__not_set__' ? option.value + ' / ' : '') + (option.name)); },"emptyValue":{},"clearable":item.options.length > 1 || !!_vm.userActionsLog.find(function (action) { return action.field === item.codename; }),"disabled":item.options.length === 1 && !(_vm.userActionsLog.find(function (action) { return action.field === item.codename; }))},on:{"change":function($event){
                    var i = arguments.length, argsArray = Array(i);
                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleCarAttrsSelect.apply(void 0, [ item.codename ].concat( argsArray ))}},model:{value:(_vm.attrsValues[item.codename]),callback:function ($$v) {_vm.$set(_vm.attrsValues, item.codename, $$v)},expression:"attrsValues[item.codename]"}})}):_vm._e()],2)]),_vm._v(" "),_c('div',{staticClass:"car-select__result"},[(!_vm.canSearchCarByModel)?_c('div',{staticClass:"empty-block"},[_c('PlaceholderIcon',{staticClass:"empty-block__icon-wrap",attrs:{"icon":"car"}}),_vm._v(" "),_c('p',{staticClass:"empty-block__text text_base-lh"},[_vm._v("\n                            Укажите основные параметры автомобиля,\n                            "),_c('br'),_vm._v("\n                            затем выберите модификацию\n                        ")])],1):_vm._e(),_vm._v(" "),(_vm.canSearchCarByModel && _vm.searchLoading)?_c('div',{staticClass:"car-select__result-spinner"},[_c('Spinner',{attrs:{"size":"big","absoluteCenter":""}})],1):_vm._e(),_vm._v(" "),(_vm.canSearchCarByModel && !_vm.searchLoading)?[(_vm.foundCars.length)?_c('CarsList',{staticClass:"car-select__result-list",attrs:{"options":_vm.foundCars,"listName":"modelCars","noManufacturerName":""},on:{"change":_vm.onSelectCar}}):_c('div',{staticClass:"empty-block"},[_c('PlaceholderIcon',{staticClass:"empty-block__icon-wrap",attrs:{"icon":"empty"}}),_vm._v(" "),_c('p',{staticClass:"empty-block__text text_base-lh"},[_vm._v("\n                                Модификации не найдены, "),_c('br'),_vm._v(" попробуйте изменить параметры автомобиля\n                            ")])],1)]:_vm._e()],2)])]),_vm._v(" "),_c('SeoBlock',{scopedSlots:_vm._u([(_vm.manufacturer.seo_description)?{key:"seo",fn:function(){return [_vm._v(_vm._s(_vm.manufacturer.seo_description))]},proxy:true}:null],null,true)})],_vm._v(" "),_c('LastSee')],2)}
var staticRenderFns = []

export { render, staticRenderFns }