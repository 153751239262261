import {
    date as dateRe,
    datetime as datetimeRe,
    time as timeRe,
    year as yearRe,
} from '@/components/_form/utils/regs.js';
import parseDateTime from '@/lib/datetime/parseDateTime.js';
import dateTimeToIso from '@/lib/datetime/dateTimeToIso.js';


export const time = value => {
    if (value && timeRe.test(value)) {
        return value + ':00';
    }

    return value || null;
};

export const date = value => {
    if (value && dateRe.test(value)) {
        return value.replace(/(\d{2}).(\d{2}).(\d{4})/, '$3-$2-$1');
    }

    return value || null;
};

export const calendar = value => {
    if (value && dateRe.test(value)) {
        // if date
        return value.replace(/(\d{2}).(\d{2}).(\d{4})/, '$3-$2-$1');
    }

    return value || null;
};

export const datetime = value => {
    if (value && datetimeRe.test(value)) {
        const date = parseDateTime(value);

        if (!isNaN(date)) {
            return dateTimeToIso(date);
        }
    }

    return value || null;
};

export const year = value => {
    if (value && yearRe.test(value)) {
        return Number(value);
    }

    return value || null;
};

export const converters = {
    time,
    date,
    calendar,
    datetime,
    year,
};

export default (value, format) => {
    if (converters[format]) {
        return converters[format](value);
    }

    return value;
};