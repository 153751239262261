<template>
    <PopupWrap
        @close="close"
    >
        <FormTemplate class="popup-content">
            <template slot="body">
                <h2 class="h2 h2_block">
                    Срок действия полиса
                </h2>

                <FormManager
                    ref="form"
                    v-model="formData"
                    :formTag="false"
                    class="form-grid"
                    @error="onError"
                >
                    <FormField
                        name="action_start_date"
                        type="calendar"
                        :min="minActionStartDate"
                        :max="maxActionStartDate"
                        label="Начало действия полиса"
                        :validations="`required|min:${ minActionStartDateIso }|max:${ maxActionStartDateIso }`"
                        class="action_start_date"
                    ></FormField>

                    <FormField
                        name="action_end_date"
                        type="date"
                        label="Окончание действия полиса"
                        disabled
                        class="action_end_date"
                    ></FormField>
                </FormManager>

                <InfoMessage class="mt-20">
                    После изменения данных стоимость страхового полиса может быть пересчитана.
                </InfoMessage>
            </template>

            <template slot="footer">
                <ButtonBlock
                    secondary
                    class="button-secondary"
                    @click="cancel"
                >
                    Отмена
                </ButtonBlock>
                <ButtonBlock
                    primary
                    :disabled="invalid"
                    :loading="loading"
                    class="button-primary"
                    @click="confirm"
                >
                    Сохранить
                </ButtonBlock>
            </template>
        </FormTemplate>
    </PopupWrap>
</template>

<script>
// utils
import deepClone from '@/lib/deepClone.js';
// mixins
import popup from '@/mixins/popup.js';
// use
import useChangePopupData from '@/pages/insurance/osago/_uuid/useChangePopupData.js';
import useChangePopupProps from '@/pages/insurance/osago/_uuid/useChangePopupProps.js';
import useChangePopupComputed from '@/pages/insurance/osago/_uuid/useChangePopupComputed.js';
import useChangePopupMethods from '@/pages/insurance/osago/_uuid/useChangePopupMethods.js';
import usePolicyData from '@/pages/insurance/osago/_uuid/usePolicyData.js';
import usePolicyComputed from '@/pages/insurance/osago/_uuid/usePolicyComputed.js';
import usePolicyWatch from '@/pages/insurance/osago/_uuid/usePolicyWatch.js';
// components
import PopupWrap from '@/components/popups/PopupWrap.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import FormTemplate from './FormTemplate.vue';
import FormField from '@/components/_form/FormField.vue';
import FormManager from '@/components/_form/FormManager.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';


export default {
    name: "InsurerOsagoChangePolicyPopup",

    components: {
        ButtonBlock,
        FormManager,
        PopupWrap,
        InfoMessage,
        FormTemplate,
        FormField,
    },

    mixins: [
        popup,
    ],

    props: {
        ...useChangePopupProps(),
    },

    data() {
        return {
            ...useChangePopupData(),
            ...usePolicyData(),
        };
    },

    computed: {
        ...usePolicyComputed(),
        ...useChangePopupComputed(),
    },

    watch: {
        ...usePolicyWatch({ isPopup: true }),
    },

    mounted() {
        this.formData = deepClone(this.initFormData);
    },

    methods: {
        ...useChangePopupMethods(),
    },
};
</script>

<style scoped>
.action_start_date {
    grid-area: action_start_date;
}

.action_end_date {
    grid-area: action_end_date;
}

@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

@media (min-width: 1040px) {
    .form-grid {
        grid-template-areas:
            "action_start_date action_end_date";
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .form-grid {
        grid-template-areas:
            "action_start_date action_end_date";
    }
}

@media (max-width: 767px) {
    .popup-content >>> .footer {
        margin-top: auto;
        padding-top: 28px;
    }
}
</style>