export default ({ HTTP }) => ({
    async get(payload) {
        if (typeof payload === 'string') {
            const uuid = payload;
            const url = `/chat/${ uuid }`;
            const response = await HTTP.get(url);
            return response.data;
        }
        else {
            const config = payload;
            const url = '/chat/';
            const response = await HTTP.get(url, config);
            return response.data;
        }
    },

    async create(data) {
        const url = '/chat/';
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async complaint(uuid, data) {
        const url = `/chat/${ uuid }/complaint/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async images(uuid, config) {
        const url = `/chat/${ uuid }/images/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async offers(uuid, config) {
        const url = `/chat/${ uuid }/offers/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async links(uuid, config) {
        const url = `/chat/${ uuid }/links/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async files(uuid, config) {
        const url = `/chat/${ uuid }/files/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async messages(uuid, config) {
        const url = `/chat/${ uuid }/messages/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async sendMessage(uuid, data) {
        const url = `/chat/${ uuid }/messages/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async check(config) {
        const url = '/chat/check/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async search(config) {
        const url = '/chat/search/';
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async updateOnline() {
        const url = '/chat/update_online/';
        const response = await HTTP.patch(url);
        return response.data;
    },

    async updateSeen(uuid, data) {
        const url = `/chat/${ uuid }/messages/update_seen/`;
        const response = await HTTP.patch(url, data);
        return response.data;
    },
});