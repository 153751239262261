<template>
    <section class="account-insurance-drafts">
        <div v-if="initializing" class="spinner">
            <USpinner big></USpinner>
        </div>

        <template v-else>
            <EmptyBlock
                v-if="!filteredContracts.length"
                icon="document"
                title="Нет незавершённых расчётов"
            >
                <template #button>
                    <ButtonBlock
                        :to="{
                            name: 'insurance-osago'
                        }"
                        primary
                        low
                        class="empty__btn"
                    >
                        Оформить страховку
                    </ButtonBlock>
                </template>

                <template #text>
                    Оформите полис ОСАГО онлайн
                    <br>
                    без переплат, быстро и безопасно.
                </template>
            </EmptyBlock>

            <template v-else>
                <ol>
                    <li
                        v-for="(contract, index) in filteredContracts"
                        :key="contract.uuid"
                        class="contract layer-1"
                    >
                        <div class="contract__header">
                            <div>
                                <div class="h3 mb-4">
                                    Расчёт ОСАГО
                                </div>
                                <div class="contract__edit-date">
                                    {{ contract.edit_date | dt }}
                                </div>
                            </div>
                            <div class="contract__percent">
                                <RoundProgress :value="getProgress(contract)"></RoundProgress>
                            </div>
                        </div>
                        <div class="contract__body">
                            <div v-if="contract.vehicle_model" class="contract__car">
                                <div class="text_bold mb-4">
                                    Автомобиль:
                                </div>

                                <div class="contract__value">
                                    {{ (contract.vehicle_model || {}).fullname }},<template
                                        v-if="contract.vehicle_year"
                                    > {{ contract.vehicle_year }}</template><template
                                        v-if="contract.vehicle_engine_power"
                                    >
                                        ({{ contract.vehicle_engine_power }} л. с.)</template><template
                                        v-if="contract.vehicle_license_plate"
                                    >, {{ contract.vehicle_license_plate }}</template><template
                                        v-else-if="contract.vehicle_doc_number"
                                    >{{ contract.vehicle_doc_number }}</template>
                                </div>
                            </div>
                            <div v-if="contract.action_start_date" class="contract__period">
                                <div class="text_bold mb-4">
                                    Период действия:
                                </div>

                                <div class="contract__value">
                                    {{ contract.action_start_date | date }} – {{ contract.action_end_date | date }}
                                </div>
                            </div>
                        </div>
                        <div class="contract__footer">
                            <div class="contract__continue">
                                <ButtonBlock
                                    low
                                    primary
                                    :loading="initializingContract === index"
                                    class="contract__continue-button"
                                    @click="onClickContinue(contract, index)"
                                >
                                    Продолжить оформление
                                </ButtonBlock>
                            </div>

                            <div v-if="contract.offers.length" class="contract__price">
                                от {{ getMinPrice(contract) | price }}
                            </div>
                        </div>
                    </li>
                </ol>

                <div class="more-wrap">
                    <ButtonText
                        v-if="contracts.length < currentCount"
                        dark
                        dashed
                        secondary
                        :loading="fetching"
                        @click="more"
                    >
                        Показать ещё
                    </ButtonText>
                </div>
            </template>
        </template>
    </section>
</template>

<script>
// utils
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import accountInsuranceDrafts from '@/store/modules/accountInsuranceDrafts.js';
import { getInsuranceContractRouteName } from '@/pages/insurance/osago/_uuid/useCheckRouteMethods.js';
// components
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import RoundProgress from '@/components/RoundProgress.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import EmptyBlock from '@/components/EmptyBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';


export default {
    name: 'AccountInsuranceDraftsPage',

    components: {
        ButtonText,
        EmptyBlock,
        USpinner,
        RoundProgress,
        ButtonBlock,
    },

    metaInfo() {
        return {
            title: 'ЛК / Сохранённые расчёты',
        };
    },

    serverPrefetch() {
        return this.prefetch();
    },

    data() {
        return {
            initializingContract: null,
        };
    },

    computed: {
        ...mapState({
            initializing: state => state.accountInsuranceDrafts.initializing,
            currentCount: state => state.accountInsuranceDrafts.currentCount,
            initialized: state => state.accountInsuranceDrafts.initialized,
            contracts: state => state.accountInsuranceDrafts.contracts,
            fetching: state => state.accountInsuranceDrafts.fetching,
        }),

        ...mapGetters({
            hasMore: 'accountInsuranceDrafts/hasMore',
        }),

        filteredContracts() {
            return this.contracts.filter(contract => contract.vehicle_model);
        },
    },

    created() {
        this.$store.registerModule('accountInsuranceDrafts', accountInsuranceDrafts);
    },

    mounted() {
        if (!this.initialized) {
            this.prefetch();
        }
    },

    beforeDestroy() {
        this.destroy();
        this.$store.unregisterModule('accountInsuranceDrafts');
    },

    methods: {
        ...mapMutations({
            destroy: 'accountInsuranceDrafts/destroy',
        }),

        ...mapActions({
            prefetch: 'accountInsuranceDrafts/prefetch',
            more: 'accountInsuranceDrafts/more',
            initContract: 'insuranceOsago/init',
        }),

        getMinPrice(contract) {
            const offers = contract.offers;
            const prices = offers.map(offer => offer.price);
            return Math.min(...prices);
        },

        getProgress(contract) {
            const map = {
                '/request/step-2/': 20,
                '/request/step-3/': 40,
                '/request/step-4/': 60,
            };

            const lastPath = contract.last_path;

            if (!lastPath) return 0;

            return map[lastPath] || 80;
        },

        async onClickContinue(contract, index) {
            this.initializingContract = index;

            const uuid = contract.uuid;
            const response = await this.initContract({ uuid });

            const routeName = getInsuranceContractRouteName(response.contract) || 'insurance-osago-request-step-1';

            const location = {
                name: routeName,
                params: {
                    uuid: contract.uuid,
                },
            };

            await this.$router.push(location);

            this.initializingContract = null;
        },
    },
};
</script>

<style scoped>
.spinner {
    display: flex;
    justify-content: center;
}

.contract:not(:first-child) {
    margin-top: 20px;
}

.contract__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .contract__header {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .contract__header {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .contract__header {
        padding: 16px;
    }
}

.contract__edit-date {
    color: var(--font-secondary-color);
    font-family: var(--f-semi-bold);
    font-size: var(--small-fz);
}

.contract__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .contract__body {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .contract__body {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .contract__body {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 16px;
    }
}

@media (max-width: 767px) {
    .contract__car {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .contract__period {
        text-align: right;
    }
}

.contract__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (min-width: 1040px) {
    .contract__footer {
        padding: 24px 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .contract__footer {
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .contract__footer {
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

@media (max-width: 767px) {
    .contract__continue {
        order: 2;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .contract__continue-button {
        width: 100%;
    }
}

.contract__price {
    font-family: var(--f-bold);
    font-size: var(--adaptive-big-fz);
}
@media (max-width: 767px) {
    .contract__price {
        margin-bottom: 16px;
        order: 1;
    }
}

.more-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
</style>