var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"u-checkbox__wrap",class:{
        'u-checkbox__wrap_disabled': _vm.disabled,
        'u-checkbox__wrap_invalid': _vm.invalid,
        'u-checkbox__wrap_border': _vm.border,
        'u-checkbox__wrap_checked': _vm.value,
    }},[_c('input',{staticClass:"visually-hidden u-checkbox__input",class:{
            'u-checkbox__input_indeterminate': _vm.indeterminate
        },attrs:{"id":_vm.id,"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":_vm.value},on:{"click":_vm.toggle}}),_vm._v(" "),_c('span',{staticClass:"u-checkbox__icon-wrap"},[(_vm.indeterminate)?_c('UIcon',{staticClass:"u-checkbox__icon u-checkbox__icon_minus",attrs:{"name":"minus","light":"","small":""}}):_c('UIcon',{staticClass:"u-checkbox__icon u-checkbox__icon_check",attrs:{"name":"done","light":"","small":""}})],1),_vm._v(" "),_c('span',{staticClass:"u-checkbox__label"},[_vm._t("label",function(){return [_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }