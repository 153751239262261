<template>
    <div
        class="wysiwyg-container"
        :class="{
            'wysiwyg-container_small': smallSize,
        }"
    >
        <div
            v-if="value"
            v-html="value"
        ></div>
        <slot v-else></slot>
    </div>
</template>

<script>
export default {
    name: "WysiwygView",

    props: {
        value: {
            type: String,
            default: '',
        },

        smallSize: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style src="../../assets/css/wysiwyg.css"></style>