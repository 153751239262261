<template>
    <span class="chat-message-preview__content">
        <span v-if="message.file" class="chat-message-preview__file chat-message-preview__payload">
            <span class="chat-message-preview__file-name">{{ clearFileName(message.file.name, message.file.extension) }}</span>
            <span v-if="message.file.extension" class="chat-message-preview__file-extension">.{{ message.file.extension }}</span>
        </span>
        <span v-else-if="message.image" class="chat-message-preview__payload">Изображение</span>
        <span v-else-if="message.offer" class="chat-message-preview__ellipsis chat-message-preview__payload">{{ message.offer.item.name }}</span>
        <span v-else class="chat-message-preview__text chat-message-preview__ellipsis">{{ message.text }}</span>
    </span>
</template>

<script>
import clearFileName from '@/lib/clearFileName.js';


export default {
    name: 'ChatMessagePreview',

    props: {
        message: Object,
    },

    methods: {
        clearFileName,
    },
};
</script>

<style>
.chat-message-preview__content {
    min-width: 0;
    display: inline-flex;
}

.chat-message-preview__payload {
    color: var(--font-secondary-color);
}

.chat-message-preview__file-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-message-preview__ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-message-preview__file {
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
}

.chat-message-preview__file-extension {
    flex-shrink: 0;
    text-transform: lowercase;
}

.chat-message-preview__text {
    width: 100%;
}
</style>