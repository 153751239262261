<template>
    <div
        class="u-input-field"
        :class="{
            'u-input-field_disabled': disabled,
            'u-input-field_focused': isFocused,
            'u-input-field_invalid': invalid,
            'u-input-field_micro': micro,
            'u-input-field_small': small,
            'u-input-field_mini': mini,
            'u-input-field_big': big,
        }"
    >
        <div
            v-if="$scopedSlots['prepend-outer']"
            class="u-input-field__icon-box u-input-field__prepend-outer"
        >
            <slot name="prepend-outer"></slot>
        </div>

        <div
            ref="border"
            class="u-input-field__border-box"
            @click="onClick"
            @mousedown="onMouseDown"
        >
            <div
                v-if="$scopedSlots['prepend-inner']"
                class="u-input-field__icon-box u-input-field__prepend-inner"
            >
                <slot
                    name="prepend-inner"
                    v-bind="{ ...scope.attrs, ...scope.methods }"
                ></slot>
            </div>

            <UInput
                class="u-input-field__input"
                v-bind="scope.attrs"
                v-on="scope.handlers"
            ></UInput>

            <slot
                v-bind="labelScope"
                name="label-element"
            >
                <ULabel
                    v-if="label || $scopedSlots.label"
                    :active="!!value || isFocused"
                    v-bind="labelScope.attrs"
                >
                    <slot name="label">
                        {{ label }}
                    </slot>
                </ULabel>
            </slot>

            <div
                v-if="$scopedSlots['append-inner']"
                class="u-input-field__icon-box u-input-field__append-inner"
            >
                <slot
                    name="append-inner"
                    v-bind="{ ...scope.attrs, ...scope.methods }"
                ></slot>
            </div>
        </div>

        <div
            v-if="$scopedSlots['append-outer']"
            class="u-input-field__icon-box u-input-field__append-outer"
        >
            <slot name="append-outer"></slot>
        </div>
    </div>
</template>

<script>
// utils
import genId from '@ui/utils/genId.js';
// components
import UInput from '@ui/components/UInput/UInput.vue';
import ULabel from '@ui/components/ULabel/ULabel.vue';


export default {
    name: 'UInputField',

    components: {
        ULabel,
        UInput,
    },

    model: {
        prop: 'value',
        event: 'change:model',
    },

    props: {
        // attrs
        id: {
            type: String,
            default: () => genId(),
        },
        name: String,
        type: String,
        value: [String, Number],
        disabled: Boolean,
        placeholder: String,
        autocomplete: String,

        // state
        invalid: Boolean,
        error: String,

        // label
        label: String,

        // size
        micro: Boolean,
        mini: Boolean,
        small: Boolean,
        big: Boolean,

        // value mods
        mask: String,
        trim: Boolean,
        upper: Boolean,
        replace: Function,

        // behaviour
        clearInputOnBlur: Boolean,
        clearInputOnFocus: Boolean,

        // presets
        integer: Boolean,
        decimal: Boolean,

        // other
        visibleMask: Boolean,
    },

    data() {
        return {
            isFocused: false,
        };
    },

    computed: {
        scope() {
            // input ?
            const ref = 'input';
            const id = this.id;
            const name = this.name;
            const type = this.type || 'text';
            const value = this.value;
            const disabled = this.disabled;
            const placeholder = this.placeholder;
            const autocomplete = this.autocomplete;
            const label = this.label;
            const mask = this.mask;
            const visibleMask = this.visibleMask;
            const trim = this.trim;
            const upper = this.upper;
            const replace = this.replace;
            const clearInputOnBlur = this.clearInputOnBlur;
            const clearInputOnFocus = this.clearInputOnFocus;
            const integer = this.integer;

            const attrs = {
                ref,
                id,
                name,
                type,
                value,
                disabled,
                placeholder,
                autocomplete,
                label,
                mask,
                visibleMask,
                trim,
                upper,
                replace,
                clearInputOnBlur,
                clearInputOnFocus,
                integer,
            };

            // eslint-disable-next-line no-unused-vars
            const { focus, blur, input, ...listeners } = this.$listeners;

            const handlers = {
                ...listeners,
                focus: this.onFocus,
                blur: this.onBlur,
                input: this.onInput,
            };

            const clear = this.clear;

            const methods = {
                clear,
                focus: this.focus,
                blur: this.blur,
            };

            return {
                attrs,
                handlers,
                methods,
            };
        },

        labelScope() {
            return {
                attrs: {
                    id: this.id,
                    invalid: this.invalid,
                    disabled: this.disabled,
                },
            };
        },
    },

    methods: {
        // public

        clear() {
            if (this.$refs.input) {
                this.$refs.input.clear();
            }
        },

        focus() {
            if (this.$refs.input) {
                this.$refs.input.focus();
            }
        },

        blur() {
            if (this.$refs.blur) {
                this.$refs.input.blur();
            }
        },

        // handlers

        onClick() {
            this.focus();
        },

        onMouseDown($event) {
            // чтобы не терялся фокус на инпуте
            if ($event.target === this.$refs.border) {
                $event.preventDefault();
            }
        },

        onFocus($event) {
            this.isFocused = true;
            this.$emit('focus', $event);
        },

        onBlur($event) {
            this.isFocused = false;
            this.$emit('blur', $event);
        },

        onInput($event) {
            this.$emit('input', $event);
            this.$emit('change:model', $event.value);
        },
    },
};
</script>

<style>
.u-input-field {
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    cursor: text;
}
.u-input-field.u-input-field_disabled {
    cursor: default;
}

.u-input-field__icon-box {
    display: flex;
    align-items: center;
}

.u-input-field__border-box {
    position: relative;
    padding: 12px;
    display: flex;
    align-items: center;
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
    transition: border-color var(--transition), background-color var(--transition);
}
.u-input-field.u-input-field_focused .u-input-field__border-box,
.u-input-field__border-box:hover {
    border-color: var(--fields-border);
}
.u-input-field.u-input-field_disabled .u-input-field__border-box {
    border: 1px solid var(--border-light-c);
    background-color: var(--bright-bg);
}
.u-input-field.u-input-field_small .u-input-field__border-box {
    max-height: 36px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.u-input-field.u-input-field_invalid .u-input-field__border-box {
    border-color: var(--primary-color);
}

.u-input-field__prepend-inner {
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 8px;
}

.u-input-field__input {
    flex-grow: 1;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.u-input-field__append-inner {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 8px;
}
</style>