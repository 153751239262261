<template>
    <svg
        class="u-icon"
        :class="{
            'u-icon_tiny': tiny,
            'u-icon_small': small,
            'u-icon_big': big,
            'u-icon_huge': huge,
            'u-icon_extra': extra,
            'u-icon_primary': primary,
            'u-icon_white': white,
            'u-icon_secondary': secondary,
            'u-icon_secondary-light': secondary && light,
            'u-icon_yellow': yellow,
            'u-icon_green': green,
            'u-icon_hovered': hovered,
            'u-icon_in-disabled-button': inDisabledBtn,
        }"
        :style="computedSizeStyles"
        v-on="$listeners"
    >
        <use :xlink:href="'#' + name"></use>
    </svg>
</template>

<script>
export default {
    name: 'UIcon',

    props: {
        name: {
            type: String,
            default: 'cross',
        },

        micro: Boolean,
        tiny: Boolean,
        small: Boolean,
        big: Boolean,
        huge: Boolean,
        extra: Boolean,
        size: [Number, String],

        primary: Boolean,
        secondary: Boolean,
        white: Boolean,
        light: Boolean,
        yellow: Boolean,
        green: Boolean,
        red: Boolean,
        hovered: Boolean,

        inDisabledBtn: Boolean,
    },

    computed: {
        computedSizeStyles() {
            return this.size ? {
                width: this.size + 'px',
                height: this.size + 'px',
            } : {};
        },
    },
};
</script>

<style>
.u-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    font-size: 0;
    transition: fill var(--transition);
    will-change: fill;
}

.u-icon_extra {
    width: 52px;
    height: 52px;
}

.u-icon_huge {
    width: 40px;
    height: 40px;
}

.u-icon_big {
    width: 24px;
    height: 24px;
}

.u-icon_small {
    width: 16px;
    height: 16px;
}

.u-icon_tiny {
    width: 12px;
    height: 12px;
}

.u-icon_secondary {
    fill: var(--font-secondary-color);
}

.u-icon_secondary.u-icon_hovered:hover {
    fill: var(--dark-c);
}

.u-icon_secondary-light {
    fill: var(--font-secondary-light-color);
}

.u-icon_secondary-light.u-icon_hovered:hover {
    fill: var(--font-secondary-color);
}

.u-icon_primary {
    fill: var(--primary-color);
}

.u-icon_primary.u-icon_hovered:hover {
    fill: var(--primary-dark-color);
}

.u-icon_white {
    fill: var(--light-c);
}

.u-icon_yellow {
    fill: var(--accent-yellow);
}

.u-icon_green {
    fill: var(--accent-green);
}

.u-icon_in-disabled-button {
    fill: var(--blue-inactive);
}
</style>