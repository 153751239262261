var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"component",class:[
        'button',
        {
            disabled: _vm.disabled,
            primary: _vm.primary,
            secondary: _vm.secondary,
            blue: _vm.blue,
            loading: _vm.loading,
            active: _vm.active,
            low: _vm.low,
            mini: _vm.mini,
            big: _vm.big,
            narrow: _vm.narrow,
            w100: _vm.w100,
        }
    ],attrs:{"disabled":_vm.disabled || _vm.loading},on:{"click":function($event){return _vm.$emit('click', $event)}}},'component',_vm.attrs,false),[(_vm.loading)?_c('Spinner',{staticClass:"btn-spin",attrs:{"light":_vm.primary,"dark":_vm.secondary,"absoluteCenter":"","size":_vm.low ? 'small' : 'base'}}):_vm._e(),_vm._v(" "),_vm._t("prepend-icon",function(){return [(_vm.prependIcon)?_c('UIcon',_vm._b({staticClass:"prepend-icon icon"},'UIcon',_vm.prependIcon,false)):_vm._e()]}),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._t("default",function(){return [_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")]})],2),_vm._v(" "),_vm._t("append-icon",function(){return [(_vm.appendIcon)?_c('UIcon',_vm._b({staticClass:"icon append-icon"},'UIcon',_vm.appendIcon,false)):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }