<template>
    <UInputField
        v-bind="attrs"
        v-on="listeners"
    >
        <template #label>
            <slot name="label"></slot>
        </template>

        <template #append-inner="{ clear }">
            <UIconButton
                v-if="value"
                key="close"
                name="close-16"
                small
                secondary
                tabindex="-1"
                @click="clear"
                @mousedown.native.stop=""
            ></UIconButton>
        </template>
    </UInputField>
</template>

<script>
import inputMixin from '@ui/components/UInput/presets/inputMixin.js';
import UInputField from '@ui/components/UInput/UInputField.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';


export default {
    name: 'UInputEmail',

    components: {
        UIconButton,
        UInputField,
    },

    mixins: [
        inputMixin,
    ],

    computed: {
        attrs() {
            const value = this.value;
            const type = 'email';
            return { ...this.$attrs, value, type };
        },
    },
};
</script>