export default {
    namespaced: true,

    state: () => ({
        fromRoute: {},
    }),

    mutations: {
        setFromRoute(state, from) {
            state.fromRoute = from;
        },
    },
};