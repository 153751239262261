<template>
    <div></div>
</template>

<script>
import isNode from '@/lib/isNode.js';
import isBrowser from '@/lib/isBrowser.js';


export default {
    name: 'ShortLinkPage',

    serverPrefetch() {
        return this.init();
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            const hash = this.$route.params.hash;

            try {
                const { url } = await this.$api.shortlink.get(hash);

                if (isNode) {
                    throw { url };
                }

                if (isBrowser) {
                    try {
                        window.location.replace(url);
                    }
                    catch (error) {}
                }
            }
            catch (error) {
                this.$store.commit('handleInitError', error);
            }
        },
    },
};
</script>