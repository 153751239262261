<template>
    <div class="brand-page">
        <Spinner
            v-if="!initialized"
            size="big"
            center
            class="top-gap_medium"
        ></Spinner>

        <template v-else>
            <section class="header layer-1">
                <div class="header-main">
                    <div :class="logo.class + '-wrap'">
                        <img
                            :src="logo.url"
                            alt=""
                            :class="logo.class"
                        >
                    </div>

                    <div class="brand__name-country-wrap">
                        <h1 class="h1 brand__name">
                            {{ brand.page_title }}
                        </h1>

                        <p v-if="brand.country" class="brand__country">
                            {{ brand.country }}
                        </p>
                    </div>

                    <div class="actions">
                        <LikeBtn
                            :id="brand.codename"
                            apiName="brand"
                            :value="brand.is_favorite"
                            withText
                            noTextOnXS
                            @changeFavorite="onChangeFavorite"
                        ></LikeBtn>
                    </div>
                </div>

                <MarketItemNavigation
                    :items="navigationItems"
                ></MarketItemNavigation>
            </section>

            <RouterView></RouterView>

            <SeoBlock>
                <template
                    v-if="brand.seo_description"
                    #seo
                >{{ brand.seo_description }}</template>
            </SeoBlock>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import marketBrand from '@/store/modules/marketBrand.js';
import LikeBtn from "@/components/project-buttons/LikeBtn.vue";
import SeoBlock from "@/components/SeoBlock.vue";
import Spinner from "@/components/Spinner.vue";
import MarketItemNavigation from '@/components/MarketItemNavigation.vue';

export default {
    name: "MarketBrandPage",

    components: {
        MarketItemNavigation,
        Spinner,
        SeoBlock,
        LikeBtn,
    },

    metaInfo() {
        return {
            title: this.brand.meta_title,
            meta: [
                { name: 'description', content: this.brand.meta_description },
                { name: 'keywords', content: this.brand.meta_keywords },
                { property: 'og:title', content: this.brand.meta_title },
                { property: 'og:description', content: this.brand.meta_description },
                { property: 'og:image', content: this.brand.og_image ? this.$links.uploads + this.brand.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    beforeRouteEnter (to, from, next) {
        if (to.name === 'market-brand') {
            next(vm => {
                vm.replaceQuery();
            });
        }
        else {
            next();
        }
    },

    serverPrefetch() {
        return this.INIT({ codename: this.$route.params.codename });
    },

    computed: {
        ...mapState({
            brand: state => state.marketBrand.brand,
            initialized: state => state.marketBrand.initialized,
        }),

        ...mapGetters({
            availableRoutes: 'marketBrand/availableRoutes',
        }),

        navigationItems() {
            const nav = [
                {
                    to: {
                        name: 'market-brand-stock',
                        params: this.$route.params,
                    },
                    label: 'Ассортимент',
                    nested: ['market-brand-products', 'market-brand-parts'],
                },
            ];
            if (this.brand.description) {
                nav.push({
                    to: {
                        name: 'market-brand-about',
                    },
                    label: 'Информация',
                });
            }
            return nav;
        },

        logo() {
            let logoObj = {
                url: require('../../../../assets/images/logo-placeholder.svg'),
                class: 'placeholder',
            };
            let logoUrl = (((this.brand || {}).logo || {}).thumbnails || {}).brand_logo_detail;
            if (logoUrl) {
                logoObj.url = this.$links.uploads + logoUrl;
                logoObj.class = 'logo';
            }
            return logoObj;
        },
    },

    watch: {
        '$route.params.codename': {
            handler() {
                this.DESTROY();
                this.init();
            },
        },
    },

    created() {
        this.$store.registerModule('marketBrand', marketBrand);
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.$store.unregisterModule('marketBrand');
    },

    methods: {
        ...mapMutations({
            CHANGE_FAVORITE: 'marketBrand/changeFavorite',
            DESTROY: 'marketBrand/destroy',
        }),

        ...mapActions({
            INIT: 'marketBrand/init',
        }),

        async init() {
            if (!this.initialized) {
                await this.INIT({ codename: this.$route.params.codename });
            }
            this.updateBreadcrumbs();
        },

        updateBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: 'market',
                    },
                    title: 'Запчасти и автотовары',
                },
                {
                    to: {
                        name: 'market-brands',
                    },
                    title: 'Производители запчастей и автотоваров',
                },
                {
                    to: {
                        name: 'market-brand',
                        params: {
                            codename: this.$route.params.codename,
                        },
                    },
                    title: this.brand.name,
                },
            ]);
        },

        async replaceQuery() {
            const location = {
                name: 'market-brand-stock',
                params: this.$route.params,
                query: this.$route.query,
            };
            if (this.availableRoutes.length) {
                location.name = this.availableRoutes[0];
            }

            const toPath = this.$router.resolve(location).route.fullPath;
            if (this.$route.fullPath !== toPath) {
                await this.$router.replace(location);
            }
        },

        onChangeFavorite(value) {
            this.CHANGE_FAVORITE(value);
        },
    },
};
</script>

<style scoped>
.header {
    position: relative;
    margin-top: var(--gap-y-medium);
    margin-bottom: var(--gap-y-medium);
}

.header-main {
    border-bottom: 1px solid var(--border-light-c);
}
@media (min-width: 1040px) {
    .header-main {
        min-height: 192px;
        padding: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .header-main {
        min-height: 168px;
        padding: 24px;
    }
}
@media (max-width: 767px) {
    .header-main {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 16px 64px 4px 16px;
    }
}

.logo-wrap,
.placeholder-wrap {
    flex-shrink: 0;
}
@media (min-width: 1040px) {
    .logo-wrap,
    .placeholder-wrap {
        top: 36px;
        right: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo-wrap,
    .placeholder-wrap {
        top: 24px;
        right: 24px;
    }
}
@media (min-width: 768px) {
    .logo-wrap,
    .placeholder-wrap {
        position: absolute;
    }
}
@media (max-width: 767px) {
    .logo-wrap,
    .placeholder-wrap {
        margin-right: 12px;
        margin-bottom: 12px;
    }
}

.logo-wrap {
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius-x2);
}

.placeholder-wrap {
    border-radius: var(--border-radius-x2);
}

@media (min-width: 1040px) {
    .logo-wrap,
    .placeholder-wrap {
        width: 180px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo-wrap,
    .placeholder-wrap {
        width: 120px;
    }
}
@media (max-width: 767px) {
    .logo-wrap,
    .placeholder-wrap {
        width: 90px;
        height: 60px;
    }
}
@media (min-width: 1040px) {
    .logo-wrap {
        padding: 20px 30px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .logo-wrap {
        padding: 12px 20px;
    }
}
@media (max-width: 767px) {
    .logo-wrap {
        padding: 10px 15px;
    }
}

@media (max-width: 767px) {
    .brand__name-country-wrap {
        margin-bottom: 12px;
    }
}

@media (min-width: 768px) {
    .brand__name {
        margin-right: 200px;
    }
}

.brand__country {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .brand__country {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .brand__country {
        margin-top: 4px;
    }
}

@media (min-width: 768px) {
    .actions {
        margin-top: 24px;
    }
}
@media (max-width: 767px) {
    .actions {
        position: absolute;
        top: 16px;
        right: 16px;
    }
}
</style>