<template>
    <URadio
        class="u-radio-tabs"
        v-bind="$attrs"
        v-on="listeners"
        @change="onChange"
    >
        <template #label="{ id, label }">
            <label :for="id" class="u-radio-tabs__label">
                {{ label }}
            </label>
        </template>
    </URadio>
</template>

<script>
import URadio from '@ui/components/URadio/URadio.vue';


export default {
    name: 'URadioTabs',

    components: { URadio },

    inheritAttrs: false,

    model: {
        event: 'change:model',
        props: 'value',
    },

    computed: {
        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { change, ...listeners } = this.$listeners;
            return listeners;
        },
    },

    methods: {
        onChange($event) {
            this.$emit('change:model', $event.value);
            this.$emit('change', $event);
        },
    },
};
</script>

<style>
.u-radio-tabs.u-radio__list {
    display: inline-flex;
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
}

.u-radio-tabs .u-radio__item {
    flex: 1 1 0;
    text-align: center;
    height: 100%;
}
.u-radio-tabs .u-radio__item:not(:last-child) {
    margin-bottom: 0;
}

.u-radio__item:first-child .u-radio-tabs__label {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}
.u-radio__item:not(:last-child) .u-radio-tabs__label {
    border-right-color: var(--border-dark-c);
}
.u-radio__item:last-child .u-radio-tabs__label {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.u-radio-tabs__label {
    padding: 8px 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    white-space: nowrap;
    transition: color var(--transition), fill var(--transition);
}
.u-radio__input:checked + .u-radio-tabs__label {
    color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
    border-color: var(--primary-color);
}
.u-radio-tabs__label:hover {
    color: var(--primary-color);
    fill: var(--primary-color);
}
</style>