<template>
    <div class="top-gap_medium">
        <Spinner
            v-if="!initializedData"
            page
            size="big"
        ></Spinner>

        <template v-else-if="totalAnaloguePartsAmount || totalOriginalSubstitutesPartsAmount">
            <MarketItemListUpper
                v-if="initializedFilter"
                class="mb-0"
                :checkedFilters="checkedFilters"
                @resetFilters="onResetFilters"
                @deleteFilter="onDeleteFilter"
            >
                <template #sort>
                    <SimpleSelectBase
                        :value="sortData"
                        :options="sortOptions"
                        @change="onChangeSort"
                    ></SimpleSelectBase>
                </template>

                <template #view>
                    <RadioButtonsGroup
                        v-model="view"
                        name="catalog-view"
                        type="icon"
                        :options="[
                            {
                                label: '',
                                value: 'list',
                                icon: 'list-toggler',
                            },
                            {
                                label: '',
                                value: 'grid',
                                icon: 'grid',
                            },
                        ]"
                    ></RadioButtonsGroup>
                </template>

                <template #filterToggler>
                    <ButtonIcon
                        :icon="{
                            name: 'filter-btn',
                        }"
                        hoverPrimary
                        class="filter-toggler-btn"
                        aria-label="Показать фильтр"
                        @click="openFilter"
                    ></ButtonIcon>
                </template>
            </MarketItemListUpper>

            <MarketItemListContent
                v-if="initializedData"
                class="market-parts-search__content"
                :class="{
                    'market-parts-search__content_not-empty': analogueParts.current_count || analoguePartsIsPart.current_count || analoguePartsInclude.current_count || originalSubstitutesParts.current_count,
                }"
            >
                <template #main>
                    <section
                        v-if="(totalAnaloguePartsAmount && !analogueParts.current_count && !analoguePartsIsPart.current_count && !analoguePartsInclude.current_count) || (totalOriginalSubstitutesPartsAmount && !originalSubstitutesParts.current_count)"
                        class="filter-empty-block"
                    >
                        <Spinner
                            v-if="originalSubstitutesPartsLoading || analoguePartsLoading"
                            center
                            size="big"
                        ></Spinner>

                        <template v-else>
                            <EmptyBlock
                                v-if="checkedFilters.length"
                                icon="filter"
                                title="Нет подходящих предложений"
                                text="Попробуйте изменить условия поиска."
                                :hasBtn="false"
                                hasResetFilters
                                @resetFilters="onResetFilters"
                            ></EmptyBlock>

                            <EmptyBlock
                                v-else
                                icon="parts"
                                title="Нет предложений"
                                btnText="Перейти в каталог"
                                :btnUrl="{
                                    name: 'market-parts-catalog',
                                }"
                            >
                                <template #text>
                                    Для запчасти <b>«{{ newPart.name }}»</b> нет актуальных предложений.
                                </template>
                            </EmptyBlock>
                        </template>
                    </section>

                    <template v-else>
                        <section
                            v-if="originalSubstitutesParts.current_count"
                            class="result-section"
                        >
                            <div class="title-wrap">
                                <h2 class="h2">
                                    Оригинальные <span class="d-inline-block">
                                        заменители<UTooltip maxWidth="240">
                                            <template #trigger="{ value, attrs, handlers }">
                                                <UTooltipIcon
                                                    class="title__hint"
                                                    :active="value"
                                                    v-bind="attrs"
                                                    v-on="handlers"
                                                ></UTooltipIcon>
                                            </template>

                                            Предложения магазинов по оригинальным заменителям запчасти
                                            {{ newPart.sku }} от {{ newPart.brand.name }}.
                                        </UTooltip>
                                    </span>
                                </h2>
                            </div>

                            <div
                                class="result-section__content"
                                :class="{
                                    'result-section__content_loading': originalSubstitutesPartsLoading,
                                }"
                            >
                                <div
                                    v-if="originalSubstitutesPartsLoading"
                                    class="result-section__spinner-wrap"
                                >
                                    <Spinner
                                        center
                                        size="big"
                                        class="result-section__spinner"
                                    ></Spinner>
                                </div>

                                <ol
                                    v-if="originalSubstitutesParts.current_count"
                                    :class="'items-' + view"
                                >
                                    <li
                                        v-for="(originalSubstitutesPartItem, originalSubstitutesPartItemIndex) in originalSubstitutesParts.items"
                                        :key="'item-' + originalSubstitutesPartItem.id"
                                        class="item"
                                        :class="{
                                            'item_view-list': view === 'list',
                                            'item_view-grid-list': view === 'grid',
                                        }"
                                    >
                                        <MarketItemRowListItem
                                            v-if="view === 'list'"
                                            :item="originalSubstitutesPartItem"
                                            modelName="part_product"
                                            withSchemeLink
                                            reviewsInPopup
                                            @changeFavorite="changePartsFavorite(originalSubstitutesPartItemIndex, ...arguments, 'substitutes')"
                                            @callShowOffersPopup="showOffersPopup(originalSubstitutesPartItem)"
                                            @callShowPartSchemesPopup="showPartSchemes(originalSubstitutesPartItem)"
                                        ></MarketItemRowListItem>

                                        <MarketItemColumn
                                            v-if="view === 'grid'"
                                            :item="originalSubstitutesPartItem"
                                            apiName="parts"
                                            modelName="part_product"
                                            withSchemeLink
                                            reviewsInPopup
                                            @changeFavorite="changePartsFavorite(originalSubstitutesPartItemIndex, ...arguments, 'substitutes')"
                                            @callShowOffersPopup="showOffersPopup(originalSubstitutesPartItem)"
                                            @callShowPartSchemesPopup="showPartSchemes(originalSubstitutesPartItem)"
                                        ></MarketItemColumn>
                                    </li>
                                </ol>

                                <ButtonText
                                    v-if="originalSubstitutesPartsHasNext"
                                    class="load-more-btn"
                                    dashed
                                    dark
                                    secondary
                                    :loading="originalSubstitutesPartsAddLoading"
                                    center
                                    @click="load('substitutes')"
                                >
                                    Показать ещё
                                </ButtonText>
                            </div>
                        </section>

                        <Spinner
                            v-if="analoguePartsLoading && analogueParts.current_count === 0 && analoguePartsIsPart.current_count === 0 && analoguePartsInclude.current_count === 0"
                            center
                            size="big"
                            class="result-section__spinner"
                        ></Spinner>

                        <section
                            v-if="analogueParts.current_count"
                            class="result-section"
                        >
                            <div class="title-wrap">
                                <h2 class="h2">
                                    <span class="mr-8">Совместимые аналоги</span><UTooltip maxWidth="240">
                                        <template #trigger="{ value, attrs, handlers }">
                                            <UTooltipIcon
                                                class="title__hint"
                                                :active="value"
                                                v-bind="attrs"
                                                v-on="handlers"
                                            ></UTooltipIcon>
                                        </template>

                                        Предложения магазинов по запчастям от сторонних производителей (аналогам),
                                        совместимым с запчастью {{ newPart.sku }} от {{ newPart.brand.name }}.
                                    </UTooltip>
                                </h2>
                            </div>

                            <div
                                class="result-section__content"
                                :class="{
                                    'result-section__content_loading': analoguePartsLoading,
                                }"
                            >
                                <div
                                    v-if="analoguePartsLoading"
                                    class="result-section__spinner-wrap"
                                >
                                    <Spinner
                                        center
                                        size="big"
                                        class="result-section__spinner"
                                    ></Spinner>
                                </div>

                                <ol :class="'items-' + view">
                                    <li
                                        v-for="(analoguePart, analoguePartIndex) in analogueParts.items"
                                        :key="'item-' + analoguePart.id"
                                        class="item"
                                        :class="{
                                            'item_view-list': view === 'list',
                                            'item_view-grid-list': view === 'grid',
                                        }"
                                    >
                                        <MarketItemRowListItem
                                            v-if="view === 'list'"
                                            :item="analoguePart"
                                            modelName="part_product"
                                            withSchemeLink
                                            reviewsInPopup
                                            @changeFavorite="changePartsFavorite(analoguePartIndex, ...arguments, 'equal')"
                                            @callShowOffersPopup="showOffersPopup(analoguePart)"
                                            @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                        ></MarketItemRowListItem>

                                        <MarketItemColumn
                                            v-if="view === 'grid'"
                                            :item="analoguePart"
                                            apiName="parts"
                                            modelName="part_product"
                                            withSchemeLink
                                            reviewsInPopup
                                            @changeFavorite="changePartsFavorite(analoguePartIndex, ...arguments, 'equal')"
                                            @callShowOffersPopup="showOffersPopup(analoguePart)"
                                            @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                        ></MarketItemColumn>
                                    </li>
                                </ol>

                                <ButtonText
                                    v-if="analoguePartsHasNext"
                                    class="load-more-btn"
                                    dashed
                                    dark
                                    secondary
                                    :loading="analoguePartsAddLoading"
                                    center
                                    @click="load('equal')"
                                >
                                    Показать ещё
                                </ButtonText>
                            </div>
                        </section>

                        <section
                            v-if="analoguePartsIsPart.current_count"
                            class="result-section"
                        >
                            <div class="title-wrap">
                                <h2 class="h2">
                                    Входят в состав искомой запчасти<UTooltip maxWidth="240">
                                        <template #trigger="{ value, attrs, handlers }">
                                            <UTooltipIcon
                                                class="title__hint"
                                                :active="value"
                                                v-bind="attrs"
                                                v-on="handlers"
                                            ></UTooltipIcon>
                                        </template>

                                        Предложения магазинов по запчастям от сторонних производителей (аналогам),
                                        совместимым с запчастью {{ newPart.sku }} от {{ newPart.brand.name }},
                                        которые входят в состав оригинальной запчасти.
                                    </UTooltip>
                                </h2>
                            </div>

                            <div
                                class="result-section__content"
                                :class="{
                                    'result-section__content_loading': analoguePartsLoading,
                                }"
                            >
                                <div
                                    v-if="analoguePartsLoading"
                                    class="result-section__spinner-wrap"
                                >
                                    <Spinner
                                        center
                                        size="big"
                                        class="result-section__spinner"
                                    ></Spinner>
                                </div>

                                <ol :class="'items-' + view">
                                    <li
                                        v-for="(analoguePart, analoguePartIndex) in analoguePartsIsPart.items"
                                        :key="'item-' + analoguePart.id"
                                        class="item"
                                        :class="{
                                            'item_view-list': view === 'list',
                                            'item_view-grid-list': view === 'grid',
                                        }"
                                    >
                                        <MarketItemRowListItem
                                            v-if="view === 'list'"
                                            :item="analoguePart"
                                            modelName="part_product"
                                            withSchemeLink
                                            reviewsInPopup
                                            @changeFavorite="changePartsFavorite(analoguePartIndex, ...arguments, 'is_part')"
                                            @callShowOffersPopup="showOffersPopup(analoguePart)"
                                            @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                        ></MarketItemRowListItem>

                                        <MarketItemColumn
                                            v-if="view === 'grid'"
                                            :item="analoguePart"
                                            apiName="parts"
                                            modelName="part_product"
                                            withSchemeLink
                                            reviewsInPopup
                                            @changeFavorite="changePartsFavorite(analoguePartIndex, ...arguments, 'is_part')"
                                            @callShowOffersPopup="showOffersPopup(analoguePart)"
                                            @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                        ></MarketItemColumn>
                                    </li>
                                </ol>

                                <ButtonText
                                    v-if="analoguePartsIsPartHasNext"
                                    class="load-more-btn"
                                    dashed
                                    dark
                                    secondary
                                    :loading="analoguePartsIsPartAddLoading"
                                    center
                                    @click="load('is_part')"
                                >
                                    Показать ещё
                                </ButtonText>
                            </div>
                        </section>

                        <section
                            v-if="analoguePartsInclude.current_count"
                            class="result-section"
                        >
                            <div class="title-wrap">
                                <h2 class="h2">
                                    Включают искомую запчасть<UTooltip maxWidth="240">
                                        <template #trigger="{ value, attrs, handlers }">
                                            <UTooltipIcon
                                                class="title__hint"
                                                :active="value"
                                                v-bind="attrs"
                                                v-on="handlers"
                                            ></UTooltipIcon>
                                        </template>

                                        Предложения магазинов по запчастям от сторонних производителей (аналогам),
                                        совместимым с запчастью {{ newPart.sku }} от {{ newPart.brand.name }},
                                        которые включают в себя оригинальную запчасть.
                                    </UTooltip>
                                </h2>
                            </div>

                            <div
                                class="result-section__content"
                                :class="{
                                    'result-section__content_loading': analoguePartsLoading,
                                }"
                            >
                                <div
                                    v-if="analoguePartsLoading"
                                    class="result-section__spinner-wrap"
                                >
                                    <Spinner
                                        center
                                        size="big"
                                        class="result-section__spinner"
                                    ></Spinner>
                                </div>

                                <ol :class="'items-' + view">
                                    <li
                                        v-for="(analoguePart, analoguePartIndex) in analoguePartsInclude.items"
                                        :key="'item-' + analoguePart.id"
                                        class="item"
                                        :class="{
                                            'item_view-list': view === 'list',
                                            'item_view-grid-list': view === 'grid',
                                        }"
                                    >
                                        <MarketItemRowListItem
                                            v-if="view === 'list'"
                                            :item="analoguePart"
                                            modelName="part_product"
                                            withSchemeLink
                                            reviewsInPopup
                                            @changeFavorite="changePartsFavorite(analoguePartIndex, ...arguments, 'include')"
                                            @callShowOffersPopup="showOffersPopup(analoguePart)"
                                            @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                        ></MarketItemRowListItem>

                                        <MarketItemColumn
                                            v-if="view === 'grid'"
                                            :item="analoguePart"
                                            apiName="parts"
                                            modelName="part_product"
                                            withSchemeLink
                                            reviewsInPopup
                                            @changeFavorite="changePartsFavorite(analoguePartIndex, ...arguments, 'include')"
                                            @callShowOffersPopup="showOffersPopup(analoguePart)"
                                            @callShowPartSchemesPopup="showPartSchemes(analoguePart)"
                                        ></MarketItemColumn>
                                    </li>
                                </ol>

                                <ButtonText
                                    v-if="analoguePartsIncludeHasNext"
                                    class="load-more-btn"
                                    dashed
                                    dark
                                    secondary
                                    :loading="analoguePartsIncludeAddLoading"
                                    center
                                    @click="load('include')"
                                >
                                    Показать ещё
                                </ButtonText>
                            </div>
                        </section>
                    </template>
                </template>

                <template #aside>
                    <FilterComponent
                        :value="filterData"
                        :filters="staticFilters"
                        @change="onChangeFilter"
                        @reset="onResetFilters"
                        @reload="reload"
                    ></FilterComponent>
                </template>
            </MarketItemListContent>
        </template>

        <EmptyBlock
            v-else
            icon="search"
            title="Ничего не найдено"
        >
            <template #text>
                Для запчасти <b>«{{ newPart.name }}»</b> нет аналогов.
            </template>
        </EmptyBlock>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import marketNewPartAnalogs from '@/store/modules/marketNewPartAnalogs.js';
import { HTTP } from '@/http.js';
import bus from '@/bus.js';
import EmptyBlock from '@/components/EmptyBlock.vue';
import Spinner from '@/components/Spinner.vue';
import MarketItemListUpper from '@/components/MarketItemListUpper.vue';
import SimpleSelectBase from '@/components/SimpleSelectBase.vue';
import RadioButtonsGroup from '@/components/RadioButtonsGroup.vue';
import MarketItemListContent from '@/components/MarketItemListContent.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import MarketItemColumn from '@/components/MarketItemColumn.vue';
import MarketItemRowListItem from '@/components/MarketItemRowListItem.vue';
import ButtonIcon from '@/components/_buttons/ButtonIcon.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';
const OffersPopup = () => import('@/components/popups/OffersPopup.vue');
const ShortSchemePopup = () => import('@/components/popups/ShortSchemePopup.vue');


export default {
    name: 'MarketPartAnalogsPage',

    components: {
        UTooltipIcon,
        UTooltip,
        Spinner,
        EmptyBlock,
        ButtonText,
        ButtonIcon,
        MarketItemRowListItem,
        MarketItemListUpper,
        FilterComponent,
        RadioButtonsGroup,
        SimpleSelectBase,
        MarketItemColumn,
        MarketItemListContent,
    },

    data() {
        return {
            originalSubstitutesPartsRangeData: {
                limit: 36,
                offset: 0,
            },
            analoguePartsRangeData: {
                limit: 36,
                offset: 0,
            },
            analoguePartsIsPartRangeData: {
                limit: 36,
                offset: 0,
            },
            analoguePartsIncludeRangeData: {
                limit: 36,
                offset: 0,
            },

            originalSubstitutesPartsSource: null,
            analoguePartsSource: null,
            analoguePartsIsPartSource: null,
            analoguePartsIncludeSource: null,

            originalSubstitutesPartsAddLoading: false,
            analoguePartsAddLoading: false,
            analoguePartsIsPartAddLoading: false,
            analoguePartsIncludeAddLoading: false,

            view: 'list',
        };
    },

    computed: {
        ...mapState({
            newPart: state => state.marketNewPart.newPart,

            initialized: state => state.marketNewPartAnalogs.initialized,
            initializedData: state => state.marketNewPartAnalogs.initializedData,
            initializedFilter: state => state.marketNewPartAnalogs.initializedFilter,

            originalSubstitutesParts: state => state.marketNewPartAnalogs.originalSubstitutesParts,
            originalSubstitutesPartsHasNext: state => state.marketNewPartAnalogs.originalSubstitutesParts.hasNext,
            originalSubstitutesPartsLoading: state => state.marketNewPartAnalogs.originalSubstitutesPartsLoading,
            totalOriginalSubstitutesPartsAmount: state => state.marketNewPartAnalogs.totalOriginalSubstitutesPartsAmount,

            totalAnaloguePartsAmount: state => state.marketNewPartAnalogs.totalAnaloguePartsAmount,

            analoguePartsLoading: state => state.marketNewPartAnalogs.analoguePartsLoading,
            analogueParts: state => state.marketNewPartAnalogs.analogueParts,
            analoguePartsHasNext: state => state.marketNewPartAnalogs.analogueParts.hasNext,

            analoguePartsIsPart: state => state.marketNewPartAnalogs.analoguePartsIsPart,
            analoguePartsIsPartHasNext: state => state.marketNewPartAnalogs.analoguePartsIsPart.hasNext,

            analoguePartsInclude: state => state.marketNewPartAnalogs.analoguePartsInclude,
            analoguePartsIncludeHasNext: state => state.marketNewPartAnalogs.analoguePartsInclude.hasNext,

            staticFilters: state => state.marketNewPartAnalogs.filter.staticFilters,
            checkedFilters: state => state.marketNewPartAnalogs.filter.checked,
            filterData: state => state.marketNewPartAnalogs.filter.value,
            sortOptions: state => state.marketNewPartAnalogs.sort.options,
            sortData: state => state.marketNewPartAnalogs.sort.value,

            globalSearchSelectedCar: state => state.searchWidget.car,
            currentCityId: state => state.cities.currentCityId,
        }),

        ...mapGetters({
            carProductionDate: 'searchWidget/carProductionDate',
            filterQuery: 'marketNewPartAnalogs/filter/query',
            sortQuery: 'marketNewPartAnalogs/sort/query',
        }),

        isNewPartOriginal() {
            return this.newPart.is_original;
        },

        hasSelectedCar() {
            return this.globalSearchSelectedCar && !!this.globalSearchSelectedCar.id;
        },

        location() {
            const query = Object.assign(
                this.filterQuery,
                this.sortQuery,
            );

            return {
                name: this.$route.name,
                query,
                meta: {
                    savePosition: true,
                },
            };
        },

        fetchParams() {
            return Object.assign(
                {},
                this.filterQuery,
                this.sortQuery,
            );
        },

        originalSubstitutesPartsFetchParams() {
            return Object.assign(
                {
                    // part_product: this.newPart.id,
                    get_facets: 'all',
                },
                this.fetchParams,
                this.originalSubstitutesPartsRangeData,
            );
        },

        analoguePartsFetchParams_results() {
            return Object.assign(
                {},
                this.fetchParams,
            );
        },

        analoguePartsFetchParams_facets() {
            return Object.assign(
                {
                    get_facets: 'all',
                    limit: 0,
                },
                this.fetchParams,
            );
        },
    },

    created() {
        this.$store.registerModule('marketNewPartAnalogs', marketNewPartAnalogs);
    },

    mounted() {
        if (!this.initialized) {
            this.INIT(this.$route.query);
        }
    },

    beforeDestroy() {
        this.$store.unregisterModule('marketNewPartAnalogs');
    },

    methods: {
        ...mapMutations({
            SET_INITIALIZED: 'marketNewPartAnalogs/setInitialized',
            SET_ORIGINAL_SUBSTITUTES_PARTS: 'marketNewPartAnalogs/setOriginalSubstitutesParts',
            SET_ANALOGUE_PARTS: 'marketNewPartAnalogs/setAnalogueParts',
            SET_LOADING: 'marketNewPartAnalogs/setLoading',
            ADD_PARTS: 'marketNewPartAnalogs/addParts',
            CHANGE_PARTS_FAVORITE: 'marketNewPartAnalogs/changePartsFavorite',
            DELETE_FILTER: 'marketNewPartAnalogs/filter/deleteFilter',
            RESET_FILTERS: 'marketNewPartAnalogs/filter/reset',
            SET_SORT_VALUE: 'marketNewPartAnalogs/sort/setValue',
        }),

        ...mapActions({
            INIT: 'marketNewPartAnalogs/init',
            GET_ORIGINAL_SUBSTITUTES_PARTS: 'marketNewPartAnalogs/getOriginalSubstitutes',
            GET_ANALOGUE_PARTS: 'marketNewPartAnalogs/getAnalogueParts',
            CHANGE_FILTER: 'marketNewPartAnalogs/filter/change',
            UPDATE_FILTERS: 'marketNewPartAnalogs/updateFilters',
        }),

        async fetch(partType, params, analogueType) {
            let response;

            try {
                if (partType === 'substitutes') {
                    this.SET_LOADING({ codename: 'originalSubstitutesPartsLoading', value: true });
                    if (this.originalSubstitutesPartsSource) this.originalSubstitutesPartsSource.cancel();
                    this.originalSubstitutesPartsSource = HTTP.CancelToken.source();

                    const payload = {
                        query: params,
                        cancelToken: this.originalSubstitutesPartsSource.token,
                    };
                    response = await this.GET_ORIGINAL_SUBSTITUTES_PARTS(payload);

                    this.originalSubstitutesPartsSource = null;
                    this.SET_LOADING({ codename: 'originalSubstitutesPartsLoading', value: false });
                }
                else if (partType === 'analogue') {
                    let cancelToken;

                    if (analogueType === 'equal') {
                        this.SET_LOADING({ codename: 'analoguePartsLoading', value: true });
                        if (this.analoguePartsSource) this.analoguePartsSource.cancel();
                        this.analoguePartsSource = HTTP.CancelToken.source();
                        cancelToken = this.analoguePartsSource.token;
                    }
                    else if (analogueType === 'is_part') {
                        this.SET_LOADING({ codename: 'analoguePartsIsPartLoading', value: true });
                        if (this.analoguePartsIsPartSource) this.analoguePartsIsPartSource.cancel();
                        this.analoguePartsIsPartSource = HTTP.CancelToken.source();
                        cancelToken = this.analoguePartsIsPartSource.token;
                    }
                    else if (analogueType === 'include') {
                        this.SET_LOADING({ codename: 'analoguePartsIncludeLoading', value: true });
                        if (this.analoguePartsIncludeSource) this.analoguePartsIncludeSource.cancel();
                        this.analoguePartsIncludeSource = HTTP.CancelToken.source();
                        cancelToken = this.analoguePartsIncludeSource.token;
                    }

                    const payload = {
                        query: params,
                        cancelToken: cancelToken,
                    };
                    response = await this.GET_ANALOGUE_PARTS(payload);

                    if (analogueType === 'equal') {
                        this.analoguePartsSource = null;
                        this.SET_LOADING({ codename: 'analoguePartsLoading', value: false });
                    }
                    else if (analogueType === 'is_part') {
                        this.analoguePartsIsPartSource = null;
                        this.SET_LOADING({ codename: 'analoguePartsIsPartLoading', value: false });
                    }
                    else if (analogueType === 'include') {
                        this.analoguePartsIncludeSource = null;
                        this.SET_LOADING({ codename: 'analoguePartsIncludeLoading', value: false });
                    }
                }
                return response;
            }
            catch (error) {
                if (!HTTP.isCancel(error)) {
                    console.log('fetch error: ', error);
                }
            }
        },

        async load(type) {
            let params = {};

            if (type === 'substitutes') {
                this.originalSubstitutesPartsAddLoading = true;
                this.originalSubstitutesPartsRangeData.offset += this.originalSubstitutesPartsRangeData.limit;
                params = this.originalSubstitutesPartsFetchParams;
            }
            else if (type === 'equal') {
                this.analoguePartsAddLoading = true;
                this.analoguePartsRangeData.offset += this.analoguePartsRangeData.limit;
                params = Object.assign({
                    relation_type: 'equal',
                }, this.analoguePartsFetchParams_results, this.analoguePartsRangeData);
            }
            else if (type === 'is_part') {
                this.analoguePartsIsPartAddLoading = true;
                this.analoguePartsIsPartRangeData.offset += this.analoguePartsIsPartRangeData.limit;
                params = Object.assign({
                    relation_type: 'is_part',
                }, this.analoguePartsFetchParams_results, this.analoguePartsIsPartRangeData);
            }
            else if (type === 'include') {
                this.analoguePartsIncludeAddLoading = true;
                this.analoguePartsIncludeRangeData.offset += this.analoguePartsIncludeRangeData.limit;
                params = Object.assign({
                    relation_type: 'include',
                }, this.analoguePartsFetchParams_results, this.analoguePartsIncludeRangeData);
            }

            if (type === 'substitutes') {
                const response = await this.fetch( 'substitutes', params);
                if (response) {
                    this.ADD_PARTS({ data: response, type });
                }
                this.originalSubstitutesPartsAddLoading = false;
            }
            else {
                const response = await this.fetch('analogue', params, type);
                if (response) {
                    this.ADD_PARTS({ data: response, type });
                }
                this.analoguePartsAddLoading = false;
                this.analoguePartsIsPartAddLoading = false;
                this.analoguePartsIncludeAddLoading = false;
            }
        },

        async reload() {
            this.originalSubstitutesPartsRangeData.offset = 0;
            this.analoguePartsRangeData.offset = 0;
            this.analoguePartsIsPartRangeData.offset = 0;
            this.analoguePartsIncludeRangeData.offset = 0;

            if (this.isNewPartOriginal) {
                const [equal, is_part, include] = await Promise.all([
                    this.fetch('analogue', Object.assign({
                        relation_type: 'equal',
                    }, this.analoguePartsFetchParams_results, this.analoguePartsRangeData), 'equal'),
                    this.fetch('analogue', Object.assign({
                        relation_type: 'is_part',
                    }, this.analoguePartsFetchParams_results, this.analoguePartsIsPartRangeData), 'is_part'),
                    this.fetch('analogue', Object.assign({
                        relation_type: 'include',
                    }, this.analoguePartsFetchParams_results, this.analoguePartsIncludeRangeData), 'include'),
                ]);

                this.SET_ANALOGUE_PARTS({ equal, is_part, include });

                const analogueParts_facets = await this.fetch('analogue', this.analoguePartsFetchParams_facets);
                this.UPDATE_FILTERS({
                    facets: [analogueParts_facets.facets],
                });
            }
            else {
                const substitutes = await this.fetch('substitutes', this.originalSubstitutesPartsFetchParams);

                this.SET_ORIGINAL_SUBSTITUTES_PARTS(substitutes);
                this.UPDATE_FILTERS({
                    facets: [substitutes.facets],
                });
            }
            await this.updateQuery();
        },

        onChangeFilter(payload) {
            this.CHANGE_FILTER(payload);
        },

        onResetFilters() {
            this.RESET_FILTERS();
            this.reload();
        },

        onDeleteFilter(filter) {
            this.DELETE_FILTER(filter);
            this.reload();
        },

        async updateQuery() {
            const toPath = this.$router.resolve(this.location).route.fullPath;
            if (this.$route.fullPath !== toPath) {
                try {
                    await this.$router.push(this.location);
                }
                catch (error) {
                    console.error('updateQuery', error);
                }
            }
        },

        onChangeSort(value) {
            this.SET_SORT_VALUE(value);
            this.reload();
        },

        openFilter() {
            bus.$emit('openFilter');
        },

        showOffersPopup(item) {
            const props = {
                item,
                modelName: 'part_product',
            };
            const options = { props };
            this.$popup(OffersPopup, options);
        },

        showPartSchemes(item) {
            const props = {
                part: item,
                partId: item.id,
                car: this.globalSearchSelectedCar,
                carId: this.globalSearchSelectedCar.id,
                manufacturerCodename: this.globalSearchSelectedCar.manufacturer.codename,
            };
            const options = { props };
            this.$popup(ShortSchemePopup, options);
        },

        changePartsFavorite(index, value, type) {
            this.CHANGE_PARTS_FAVORITE({ index, value, type });
        },
    },
};
</script>

<style scoped>
.market-parts-search__content {
    margin-top: var(--y-grid-gap);
}

.market-parts-search__content_not-empty {
    margin-top: calc(var(--gap-y-medium) + var(--h2-fz) * var(--mini-lh) + var(--indent-h2-block));
}

.result-section {
    margin-top: var(--gap-y-medium);
}
.result-section:first-child {
    margin-top: 0;
}
.result-section:first-child .title-wrap {
    margin-top: calc(-1 * var(--h2-fz) * var(--mini-lh) - var(--indent-h2-block));
}

.result-section__content {
    position: relative;
    min-height: 220px;
}

.result-section__content::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: var(--light-c);
    opacity: 0;
    transition: opacity var(--transition);
}

.result-section__content_loading::after {
    opacity: .5;
    z-index: 2;
}

.result-section__spinner-wrap {
    position: sticky;
    top: 0;
    height: 0;
    width: 100%;
    z-index: 3;
}

.result-section__spinner {
    position: relative;
    top: 92px;
}

.title-wrap {
    margin-bottom: var(--indent-h2-block);
}

.title__hint {
    margin-left: 4px;
    font-size: 0;
    vertical-align: -2px;
}

.items-list .item:not(:last-child) {
    margin-bottom: var(--grid-gap);
}

.items-grid {
    width: 100%;
}
@media (min-width: 1281px) {
    .items-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .items-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .items-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 768px) {
    .items-grid {
        display: grid;
        gap: var(--grid-gap);
    }
}
@media (max-width: 767px) {
    .items-grid .item:not(:last-child) {
        margin-bottom: var(--grid-gap);
    }
}
</style>