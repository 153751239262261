<template>
    <div class="top-gap_medium">
        <Spinner
            v-if="!initialized"
            center
            size="big"
        ></Spinner>

        <template v-else>
            <h1 class="h1 h1_block">
                {{ manufacturer.page_title }}
            </h1>

            <section class="car-select-section layer-1">
                <h2 class="h3">
                    Выберите автомобиль
                </h2>

                <p
                    v-if="globalSearchSelectedCar.id"
                    class="mt-8 mb-36"
                >
                    <ButtonText
                        :to="{
                            name: 'market-parts-catalogs-manufacturer-modification',
                            params: {
                                manufacturer: globalSearchSelectedCar.manufacturer.codename,
                                modification: globalSearchSelectedCar.id
                            },
                            query: Object.assign({}, carProductionDate ? {
                                production_date: carProductionDate
                            } : {})
                        }"
                        primary
                        dashed
                    >
                        Выбрать
                        <CarName
                            :car="globalSearchSelectedCar"
                            :block="false"
                            class="select-car-info-message__car-name"
                        ></CarName>
                    </ButtonText>
                </p>

                <div class="car-select__layout">
                    <form class="car-select-form">
                        <div class="car-select-form__fields">
                            <Selector
                                v-model="carModel"
                                label="Модель"
                                :options="carModelOptions"
                                optionLabel="name"
                                :emptyValue="{}"
                                :loading="carModelOptionsLoading"
                                class="car-select-form__field"
                                @change="handleCarModelSelect"
                            ></Selector>

                            <Selector
                                v-model="carYear"
                                label="Год выпуска"
                                :options="yearsOptions"
                                optionLabel="name"
                                :emptyValue="{}"
                                :disabled="!canSearchYear"
                                :loading="carYearsOptionsLoading"
                                class="car-select-form__field"
                                @change="handleCarYearSelect"
                            ></Selector>

                            <template v-if="showAttrsSelects">
                                <Selector
                                    v-for="item in attrsSelects"
                                    :key="item.codename"
                                    v-model="attrsValues[item.codename]"
                                    :label="item.name"
                                    :options="item.options"
                                    :getOptionLabel="option => `${ option.value && option.value !== option.name && option.value !== '__not_set__' ? option.value + ' / ' : '' }${ option.name }`"
                                    :emptyValue="{}"
                                    :clearable="item.options.length > 1 || !!userActionsLog.find(action => action.field === item.codename)"
                                    :disabled="item.options.length === 1 && !(userActionsLog.find(action => action.field === item.codename))"
                                    class="car-select-form__field"
                                    @change="handleCarAttrsSelect(item.codename, ...arguments)"
                                ></Selector>
                            </template>
                        </div>
                    </form>

                    <div class="car-select__result">
                        <div
                            v-if="!canSearchCarByModel"
                            class="empty-block"
                        >
                            <PlaceholderIcon
                                icon="car"
                                class="empty-block__icon-wrap"
                            ></PlaceholderIcon>
                            <p class="empty-block__text text_base-lh">
                                Укажите основные параметры автомобиля,
                                <br>
                                затем выберите модификацию
                            </p>
                        </div>

                        <div
                            v-if="canSearchCarByModel && searchLoading"
                            class="car-select__result-spinner"
                        >
                            <Spinner
                                size="big"
                                absoluteCenter
                            ></Spinner>
                        </div>

                        <template v-if="canSearchCarByModel && !searchLoading">
                            <CarsList
                                v-if="foundCars.length"
                                :options="foundCars"
                                listName="modelCars"
                                noManufacturerName
                                class="car-select__result-list"
                                @change="onSelectCar"
                            ></CarsList>

                            <div
                                v-else
                                class="empty-block"
                            >
                                <PlaceholderIcon
                                    icon="empty"
                                    class="empty-block__icon-wrap"
                                ></PlaceholderIcon>
                                <p class="empty-block__text text_base-lh">
                                    Модификации не найдены, <br> попробуйте изменить параметры автомобиля
                                </p>
                            </div>
                        </template>
                    </div>
                </div>
            </section>

            <SeoBlock>
                <template
                    v-if="manufacturer.seo_description"
                    #seo
                >{{ manufacturer.seo_description }}</template>
            </SeoBlock>
        </template>

        <LastSee></LastSee>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import manufacturerItem from '@/store/modules/manufacturerItem.js';
import equals from '@/lib/equals.js';
import LastSee from '@/components/LastSee.vue';
import PlaceholderIcon from '@/components/PlaceholderIcon.vue';
import CarsList from '@/components/CarsList.vue';
import Selector from '@/components/api-inputs/SelectorApi.vue';
import Spinner from '@/components/Spinner.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import SeoBlock from '@/components/SeoBlock.vue';
import CarName from '@/components/CarName.vue';

let requestEsCarsParams = {};

export default {
    name: 'ManufacturerPartsSelectCar',

    metaInfo() {
        return {
            title: this.manufacturer.meta_title,
            meta: [
                { name: 'description', content: this.manufacturer.meta_description },
                { name: 'keywords', content: this.manufacturer.meta_keywords },
                { property: 'og:title', content:this.manufacturer.meta_title },
                { property: 'og:description', content: this.manufacturer.meta_description },
                { property: 'og:image', content: this.manufacturer.og_image ? this.$links.uploads + this.manufacturer.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        return this.INIT({ codename: this.$route.params.manufacturer });
    },

    components: {
        CarName,
        SeoBlock,
        ButtonText,
        Spinner,
        Selector,
        CarsList,
        PlaceholderIcon,
        LastSee,
    },

    data() {
        return {
            carManufacturer: '',

            carModel: '',
            carModelOptions: [],
            carModelOptionsLoading: false,

            canSearchYear: false,
            carYear: '',
            yearsOptions: [],
            carYearsOptionsLoading: false,

            showAttrsSelects: false,
            attrsSelects: [],
            attrsValues: {},

            userActionsLog: [],

            searchLoading: false,
            foundCars: [],

            selectTimeout: null,
            source: null,
        };
    },

    computed: {
        ...mapState({
            manufacturer: state => state.manufacturerItem.item,
            initialized: state => state.manufacturerItem.initialized,
            globalSearchSelectedCar: state => state.searchWidget.car,
        }),

        ...mapGetters({
            carProductionDate: 'searchWidget/carProductionDate',
        }),

        canSearchCarByModel() {
            return this.carModel.id && this.carYear.codename;
        },
    },

    created() {
        this.$store.registerModule('manufacturerItem', manufacturerItem);
    },

    async mounted() {
        if (!this.initialized) {
            await this.INIT({ codename: this.$route.params.manufacturer });
        }
        this.$breadcrumbs([
            {
                to: {
                    name: 'market',
                },
                title: 'Запчасти и автотовары',
            },
            {
                to: {
                    name: 'market-parts-catalogs',
                },
                title: 'Производители запчастей и автотоваров',
            },
            {
                to: {
                    name: 'market-parts-catalogs-manufacturer',
                },
                title: this.manufacturer.name,
            },
        ]);
        this.carManufacturer = this.manufacturer;
        this.getCarModelOptions();
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('manufacturerItem');
    },

    methods: {
        ...mapActions({
            INIT: 'manufacturerItem/init',
        }),

        ...mapMutations({
            DESTROY: 'manufacturerItem/destroy',
        }),

        async getCarModelOptions() {
            this.carModelOptionsLoading = true;

            const { facets } = await this.requestEsCars('families');
            if (facets.families) this.carModelOptions = facets.families;
            this.carModelOptionsLoading = false;
        },

        async handleCarModelSelect(option) {
            this.carYear = '';
            this.yearsOptions = [];
            this.showAttrsSelects = false;
            this.attrsSelects = [];
            this.attrsValues = {};
            this.userActionsLog = [];

            if (option.id) {
                this.carModel = option;
                this.carYearsOptionsLoading = true;
                this.canSearchYear = true;

                const { facets } = await this.requestEsCars('years');
                if (facets.years) this.yearsOptions = facets.years;
                this.carYearsOptionsLoading = false;
            }
            else {
                this.carModel = '';
                this.canSearchYear = false;
            }
        },

        async handleCarYearSelect(option) {
            this.showAttrsSelects = !!option.codename;
            this.attrsSelects = [];
            this.attrsValues = {};
            this.userActionsLog = [];

            this.carYear = option;
            if (option.codename) {
                this.userActionsLog.push({
                    field: 'carYear',
                    blocked: [],
                });
                this.handleCarAttrsChange('carYear', option);
            }
        },

        handleCarAttrsSelect(fieldCodename, option) {
            this.attrsValues[fieldCodename] = option;

            const fieldIndexInLog = this.userActionsLog.findIndex(action => action.field === fieldCodename);
            // если поле уже есть в логе
            if (fieldIndexInLog > -1) {
                // выбираю все селекторы, которые идут после текущего в логе и очищаю у них выбранные значения и options
                for (let i = fieldIndexInLog; i < this.userActionsLog.length; i++) {
                    let { blocked } = this.userActionsLog[i];

                    blocked.forEach(item => {
                        let select = this.attrsSelects.find(select => select.codename === item);
                        select.options = [];
                        this.attrsValues[item] = '';
                    });
                }
                let startIndex = option.name ? fieldIndexInLog + 1 : fieldIndexInLog;
                this.userActionsLog.splice(startIndex, 100); // очищаю лог
            }
            else {
                this.userActionsLog.push({
                    field: fieldCodename,
                    blocked: [],
                });
            }

            this.handleCarAttrsChange(fieldCodename, option);
        },

        async handleCarAttrsChange(fieldCodename, option) {
            const { facets, results } = await this.requestEsCars('characteristics', 50);
            this.foundCars = results;

            if (facets.characteristics) {
                facets.characteristics.forEach((select) => {
                    const fieldIndexInLog = this.userActionsLog.findIndex(action => action.field === select.codename);
                    // Фильтрую и обновляю опции, если селектор не выбран пользователем
                    if (fieldIndexInLog === -1 && select.name) {
                        select.options = select.options.filter(option => option.name && (option.value || option.id));
                        let currentSelect = this.attrsSelects.find(attrsSelect => attrsSelect.codename === select.codename);
                        if (currentSelect) currentSelect.options = select.options;
                        else this.attrsSelects.push(select);
                    }
                });

                if (facets.characteristics.length) {
                    const frameFacetIndex = this.attrsSelects.findIndex(item => item.codename === 'frame');
                    if (frameFacetIndex > -1) {
                        this.attrsSelects.splice(0, 0, this.attrsSelects.splice(frameFacetIndex, 1)[0]);
                    }
                    this.attrsSelects = this.attrsSelects.reduce((acc, select) => {
                        const fieldIndexInLog = this.userActionsLog.findIndex(action => action.field === select.codename);
                        const selectInFacets = facets.characteristics.findIndex(facet => facet.codename === select.codename);
                        if (fieldIndexInLog > -1 || selectInFacets > -1) acc.push(select);

                        return acc;
                    }, []);
                }

                this.attrsSelects.forEach(select => {
                    if (select.options.length === 1 && select.codename !== fieldCodename) {
                        const fieldIndexInLog = this.userActionsLog.findIndex(action => action.field === select.codename);
                        if (fieldIndexInLog === -1) {
                            this.$set(this.attrsValues, select.codename, select.options[0]);
                            let blockedCount = 0;
                            this.userActionsLog.forEach(action => {
                                let isAlreadyBlocked = action.blocked.some(field => field === select.codename);
                                if (isAlreadyBlocked) blockedCount += 1;
                            });
                            if (blockedCount === 0) {
                                let userAction = this.userActionsLog.find(action => action.field === fieldCodename);
                                userAction.blocked.push(select.codename);
                            }
                        }
                    }
                });

                let newParams = {
                    manufacturer: this.carManufacturer.codename,
                    mark_model: this.carManufacturer.name + ' ' + this.carModel.name,
                    start_year__lte: this.carYear.codename,
                    end_year__gte: this.carYear.codename,
                };

                for (let key in this.attrsValues) {
                    if (hasOwnProperty.call(this.attrsValues, key)) {
                        if (this.attrsValues[key].value !== '__not_set__') {
                            newParams[key] = this.attrsValues[key].value;
                        }
                    }
                }

                if (!equals(requestEsCarsParams, newParams)) await this.handleCarAttrsChange(fieldCodename, option);
            }
        },

        async requestEsCars(facets = 'all', limit = 0) {
            this.searchLoading = true;
            let params = {
                manufacturer: this.carManufacturer.codename,
                start_year__lte: this.carYear.codename,
                end_year__gte: this.carYear.codename,
            };

            if (facets === 'years' || facets === 'characteristics' || facets === 'all') {
                params.mark_model = this.carManufacturer.name + ' ' + this.carModel.name;
            }
            else {
                params.manufacturer = this.carManufacturer.codename;
                params.family = this.carModel.id;
            }

            for (let key in this.attrsValues) {
                if (hasOwnProperty.call(this.attrsValues, key)) {
                    if (this.attrsValues[key].value !== '__not_set__') {
                        params[key] = this.attrsValues[key].value;
                    }
                }
            }

            requestEsCarsParams = params;

            let requestParams = Object.assign({
                limit,
            }, params);

            if (facets) requestParams.get_facets = facets;

            try {
                return await this.$api.esCars.get({ params: requestParams });
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                setTimeout(() => {
                    this.searchLoading = false;
                }, 500);
            }
        },

        clearSelectCarByModelData() {
            this.carModel = '';
            this.canSearchYear = false;
            this.carYear = '';
            this.yearsOptions = [];
            this.showAttrsSelects = false;
            this.attrsSelects = [];
            this.attrsValues = {};

            this.userActionsLog = [];
            requestEsCarsParams = {};
        },

        onSelectCar(value) {
            const location = {
                name: 'market-parts-catalogs-manufacturer-modification',
                params: {
                    manufacturer: this.manufacturer.codename,
                    modification: value.id,
                },
                query: {},
            };
            if (this.carYear.codename) location.query.production_date = this.carYear.codename;
            this.$router.push(location);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .car-select-section {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .car-select-section {
        padding: 24px;
    }
}

.car-select__layout {
    margin-top: 20px;
}
@media (min-width: 1281px) {
    .car-select__layout {
        grid-template-columns: 360px auto;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .car-select__layout {
        grid-template-columns: 320px auto;
    }
}
@media (min-width: 1040px) {
    .car-select__layout {
        display: grid;
        grid-column-gap: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1039px) {
    .car-select-form__fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
}

.car-select__result {
    position: relative;
}
@media (max-width: 1039px) {
    .car-select__result {
        margin-top: var(--gap-y-small);
    }
}

@media (max-width: 1039px) {
    .car-select__result-spinner {
        position: relative;
        height: 36px;
    }
}

@media (min-width: 1040px) {
    .car-select-form__field:not(:last-child) {
        margin-bottom: 20px;
    }
    .car-select-form__field:nth-child(3) {
        margin-top: 36px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .car-select-form__field:nth-child(3),
    .car-select-form__field:nth-child(4) {
        margin-top: 16px;
    }
}

@media (max-width: 767px) {
    .car-select-form__field:not(:last-child) {
        margin-bottom: 16px;
    }
}

.car-select-form__field:last-child {
    margin-bottom: 0;
}

.empty-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}
@media (min-width: 1040px) {
    .empty-block {
        margin-top: -16px;
        padding-bottom: 36px;
    }
}

.empty-block__icon-wrap {
    margin-bottom: var(--gap-y-small);
}

.empty-block__text {
    max-width: 380px;
}

.select-car-info-message__car-name,
.select-car-info-message__car-name >>> .car-name__main-wrap {
    color: inherit;
}
</style>