var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filteredAttributes.length)?_c('div',{staticClass:"market-item-attributes-group"},[_vm._t("preTable"),_vm._v(" "),_c('div',_vm._l((_vm.filteredAttributes),function(attribute){
var _obj;
return _c('div',{key:attribute.codename,staticClass:"row"},[_c('div',{staticClass:"key",attrs:{"title":attribute.name}},[_c('span',{staticClass:"key__text"},[_vm._v("\n                    "+_vm._s(attribute.name)+"\n                ")]),_vm._v(" "),(attribute.unit)?_c('span',{staticClass:"flex-shrink-0"},[_vm._v("\n                    , "+_vm._s(attribute.unit)+"\n                ")]):_vm._e(),_vm._v(" "),(attribute.hint && !_vm.isHintEmpty(attribute.hint))?_c('UTooltip',{staticClass:"key__hint",attrs:{"noDetach":""}},[_c('WysiwygView',{attrs:{"value":attribute.hint,"smallSize":""}})],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"value",style:({
                    flexBasis: _vm.valueWidth
                })},[(attribute.data_type === 'many'
                        && attribute.is_filter_option
                        && _vm.modelName === 'product')?[_vm._l((_vm.item[attribute.codename]),function(attributeValue,attributeValueIndex){
                        var _obj;
return [_c('ButtonText',{key:attributeValueIndex,attrs:{"to":{
                                name: 'market-products-category',
                                params: {
                                    slug: _vm.item.category.slug,
                                },
                                query: ( _obj = {}, _obj[attribute.codename] = attributeValue.id, _obj ),
                            },"target":"_blank","underline":"","black":""}},[_vm._v(_vm._s(attributeValue.name))]),(attributeValueIndex < _vm.item[attribute.codename].length - 1)?[_vm._v("\n                            ,\n                        ")]:_vm._e()]})]:(attribute.is_filter_option && _vm.modelName === 'product' && _vm.item[attribute.codename].id)?_c('ButtonText',{attrs:{"target":"_blank","to":{
                        name: 'market-products-category',
                        params: {
                            slug: _vm.item.category.slug,
                        },
                        query: ( _obj = {}, _obj[attribute.codename] = _vm.item[attribute.codename].id, _obj ),
                    },"underline":"","black":""}},[_vm._v("\n                    "+_vm._s(_vm.item[attribute.codename].name || _vm.item[attribute.codename])+"\n                ")]):[(attribute.data_type === 'many')?[_vm._l((_vm.item[attribute.codename]),function(attributeValue,attributeValueIndex){return [_vm._v("\n                            "+_vm._s(attributeValue.name)),(attributeValueIndex < _vm.item[attribute.codename].length - 1)?[_vm._v("\n                                ,\n                            ")]:_vm._e()]})]:[_vm._v("\n                        "+_vm._s(_vm.item[attribute.codename].name || _vm.item[attribute.codename])+"\n                    ")]]],2)])}),0),_vm._v(" "),_vm._t("actions")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }