<template>
    <PopupWrap
        :loading="initLoading"
        @clickaway="close"
        @close="close"
    >
        <div
            v-if="!initLoading"
            class="popup-content"
        >
            <h2 class="h2 h2_block popup-title">
                Забрать заказ самостоятельно
            </h2>

            <div class="shop-wrap">
                <ShopLogo
                    :shop="shop"
                    noLink
                    coverCodename="medium"
                    class="shop-wrap__logo"
                ></ShopLogo>

                <div>
                    <ShopName
                        :shop="shop"
                        noLink
                        class="text_adaptive-big-fz"
                    ></ShopName>

                    <ShopAddress
                        :shop="shop"
                        :showOnMap="false"
                        class="shop__address mt-8"
                    ></ShopAddress>

                    <ShopOpenCloseTime
                        :shopId="shop.id"
                        :status="shop.current_work"
                        class="mt-4"
                    ></ShopOpenCloseTime>

                    <ChatBtn
                        :id="shop.id"
                        :isOnline="shop.current_work.place.is_active"
                        :disabled="!shop.is_chat_active"
                        withText
                        btnTextAlwaysVisible
                        btnText="Чат с магазином"
                        class="shop__chat-btn"
                        @click="close"
                    ></ChatBtn>
                </div>
            </div>

            <ShopWorkSchedule
                :work="shop.work_times"
                :holidays="shop.holidays"
            ></ShopWorkSchedule>

            <PointOnMapPreviewBtn
                :coords="shop.coordinates"
                class="mt-16"
                @click="openMap"
            ></PointOnMapPreviewBtn>

            <div class="footer">
                <InfoMessage>
                    Способ получения заказа будет изменён на Самовывоз из магазина. Обратите внимание, вам необходимо получить заказ самостоятельно в рабочее время магазина до {{ getOrderCancelDate | dayMonth }}, иначе он будет автоматически отменён.
                </InfoMessage>

                <div class="actions-wrap">
                    <ButtonBlock
                        secondary
                        class="action-btn"
                        @click="close"
                    >
                        Отмена
                    </ButtonBlock>
                    <ButtonBlock
                        primary
                        :loading="loading"
                        class="action-btn"
                        @click="confirm"
                    >
                        Подтвердить
                    </ButtonBlock>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import { DAYS_TO_RECEIVE_ORDER } from '@/settings.js';
import { getErrorCode } from '@/lib/errors.js';
import { changeDays } from '@/lib/dateTime.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import ChatBtn from '@/components/project-buttons/ChatBtn.vue';
import ShopLogo from '@/components/ShopLogo.vue';
import ShopWorkSchedule from '@/components/ShopWorkSchedule.vue';
import ShopAddress from '@/components/ShopAddress.vue';
import ShopName from '@/components/ShopName.vue';
import ShopOpenCloseTime from '@/components/ShopOpenCloseTime.vue';
import PointOnMapPreviewBtn from '@/components/project-buttons/PointOnMapPreviewBtn.vue';
const PointOnMapPopup = () => import('@/components/popups/PointOnMapPopup.vue');

export default {
    name: 'PickupOrderPopup',

    components: {
        PointOnMapPreviewBtn,
        ShopOpenCloseTime,
        ShopName,
        ShopAddress,
        ShopWorkSchedule,
        ShopLogo,
        ChatBtn,
        InfoMessage,
        ButtonBlock,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        order: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            initLoading: true,
            loading: false,
            shop: {},
        };
    },

    computed: {
        getOrderCancelDate() {
            return changeDays(this.order.collected_at, DAYS_TO_RECEIVE_ORDER);
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.initLoading = true;
            this.shop = await this.$api.shops.get(this.order.shop.id);
            this.initLoading = false;
        },

        confirm() {
            this.loading = true;
            const data = {
                delivery_type: 'pickup',
            };

            this.$api.orders.changeDeliveryType(this.order.uuid, data)
                .then(() => {
                    this.close();
                    this.callback(data);
                    this.$success('Изменения сохранены');
                })
                .catch(error => {
                    const code = getErrorCode(error);
                    const data = error.response.data;

                    if (code === 400) {
                        const { non_field_errors } = data;
                        if (non_field_errors) {
                            this.$error(non_field_errors[0].message);
                        }
                    }
                    else if (code === 404) {
                        this.$error('Запрашиваемый адрес не существует.');
                    }
                    else if (code === 500) {
                        this.$error(data.detail);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        openMap() {
            const props = {
                shop: this.shop,
                coords: this.shop.coordinates,
            };
            const options = { props };
            this.$popup(PointOnMapPopup, options);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.shop-wrap {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
}

.shop-wrap__logo {
    margin-left: 20px;
}

.footer {
    padding-top: 36px;
}
@media (max-width: 767px) {
    .footer {
        margin-top: auto;
    }
}

@media (min-width: 768px) {
    .actions-wrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .action-btn:not(:last-child) {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .actions-wrap {
        margin-top: 16px;
    }

    .action-btn {
        width: 100%;
    }

    .action-btn:not(:last-child) {
        margin-bottom: 16px;
    }
}

.shop__chat-btn {
    display: block;
    margin-top: 16px;
}
</style>