<template>
    <div class="info-page-layout__main">
        <transition-group
            tag="div"
            name="fade-page"
            mode="out-in"
            appear
        >
            <Spinner
                v-if="!initialized"
                key="loading"
                size="big"
                class="content-loader"
            ></Spinner>

            <div
                v-else
                key="content"
            >
                <InfoPageLayoutHead :customInfo="article"></InfoPageLayoutHead>

                <div class="content layer-1 container_xs-external">
                    <PostEditorView :content="article.content"></PostEditorView>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import helpArticle from '@/store/modules/helpArticle.js';
const WriteUsPopup = () => import('@/components/popups/WriteUsPopup.vue');
import PostEditorView from "@/components/views/PostEditorView.vue";
import InfoPageLayoutHead from "@/components/InfoPageLayoutHead.vue";
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'HelpArticlePage',

    metaInfo() {
        return {
            title: this.article.meta_title,
            meta: [
                { name: 'description', content: this.article.meta_description },
                { name: 'keywords', content: this.article.meta_keywords },
                { property: 'og:title', content: this.article.meta_title ? this.article.meta_title : this.article.title },
                { property: 'og:description', content: this.article.meta_description },
                { property: 'og:image', content: this.article.og_image ? this.$links.uploads + this.article.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    components: {
        Spinner,
        InfoPageLayoutHead,
        PostEditorView,
    },

    serverPrefetch() {
        return this.INIT({ codename: this.$route.params.codename });
    },

    computed: {
        ...mapState({
            article: state => state.helpArticle.article,
            initialized: state => state.helpArticle.initialized,
        }),
    },

    created() {
        this.$store.registerModule('helpArticle', helpArticle);
    },

    watch: {
        '$route.params.codename': {
            handler () {
                this.DESTROY();
                this.init();
            },
        },
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
        this.$store.unregisterModule('helpArticle');
    },

    methods: {
        ...mapMutations({
            DESTROY: 'helpArticle/destroy',
        }),

        ...mapActions({
            INIT: 'helpArticle/init',
        }),

        async init() {
            if (!this.initialized) {
                await this.INIT({ codename: this.$route.params.codename });
            }

            this.updateBreadcrumbs();
        },

        updateBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: {
                        name: this.$route.name === 'help-mp-article' ? 'help-mp' : 'help-pp',
                    },
                    title: 'Помощь',
                },
                {
                    to: {
                        name: this.$route.name,
                        params: {
                            codename: this.article.codename,
                        },
                    },
                    title: this.article.name,
                },
            ]);
        },

        popup() {
            this.$popup(WriteUsPopup);
        },
    },
};
</script>

<style scoped>
@media (min-width: 1281px) {
    .content {
        width: var(--col-12);
    }
}
@media (min-width: 768px) {
    .content {
        padding: 36px;
    }
}
@media (max-width: 767px) {
    .content {
        border-radius: 0;
        padding: 20px var(--content-gap);
    }
}

@media (min-width: 1040px) {
    .content-loader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (max-width: 1039px) {
    .content-loader {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>