import { HTTP } from '@/http.js';
import filter from './filter.js';
import sort from './sort.js';
import deliveryOffices from '@/store/modules/deliveryOffices.js';


const staticFilters = [
    {
        widget: 'radio',
        codename: 'in_stock',
        options: [
            {
                value: 'true',
                label: 'В наличии',
            },
            {
                value: 'false',
                label: 'Все товары, включая отсутствующие',
            },
        ],
    },
    {
        title: 'Цена, ₽',
        widget: 'range',
        codename: 'price',
        min: 0,
        max: 0,
    },
    {
        title: 'Производители',
        widget: 'checkboxes',
        codename: 'brand',
        options: [],
    },
    {
        title: 'Магазины',
        widget: 'checkboxes',
        codename: 'company',
        options: [],
    },
];

function getMergedFacets(facetsArray) {
    const cleanFacetsArray = facetsArray.filter(el => el != null);
    let facets = {
        in_stock_count: 0,
        not_in_stock_count: 0,
    };
    const minPrices = [];
    const maxPrices = [];
    cleanFacetsArray.forEach(facet => {
        facets.in_stock_count += facet.in_stock_count;
        facets.not_in_stock_count += facet.not_in_stock_count;
        if (facet.prices.min_price) {
            minPrices.push(facet.prices.min_price);
        }
        if (facet.prices.max_price) {
            maxPrices.push(facet.prices.max_price);
        }
    });
    facets.prices = {
        min_price: minPrices.length ? Math.min(...minPrices) : 0,
        max_price: maxPrices.length ? Math.max(...maxPrices) : 0,
    };

    const filterKeys = ['brands', 'companies'];
    const facetsMap = {};
    filterKeys.forEach(item => {
        facetsMap[item] = cleanFacetsArray.reduce((acc, a) => {
            if (a[item] && a[item].length) acc.push(a[item]);
            return acc;
        }, []).flat();
    });
    for (let [key, value] of Object.entries(facetsMap)) {
        facets[key] = Object.values(value.reduce((acc, item) => {
            if (acc[item.id]) {
                acc[item.id].count += item.count;
            }
            else {
                acc[item.id] = item;
            }
            return acc;
        }, {}));

        facets[key].sort((a, b) => a.name.localeCompare(b.name));
    }

    return facets;
}

export default {
    namespaced: true,

    modules: {
        filter,
        sort,
        deliveryOffices,
    },

    state: () => ({
        partName: {},
        partNameCategory: {},
        categoryCharacteristics: [],

        defaultData: {
            originalPartsIds: [],
            originalPartsCount: 0,
            originalPartsFacets: null,
            analoguePartsCount: 0,
            analoguePartsFacets: null,
        },
        originalParts: {},
        analogueParts: {},
        analoguePartsIsPart: {},
        analoguePartsInclude: {},

        originalPartsLoading: false,
        analoguePartsLoading: false,
        analoguePartsIsPartLoading: false,
        analoguePartsIncludeLoading: false,

        initialized: false,
        initializedData: false,
        initializedFilter: false,
    }),

    getters: {
        totalDefaultCount: state => {
            return state.defaultData.originalPartsCount + state.defaultData.analoguePartsCount;
        },

        totalCurrentCount: state => {
            return state.originalParts.current_count + state.analogueParts.current_count + state.analoguePartsIsPart.current_count + state.analoguePartsInclude.current_count;
        },
    },

    mutations: {
        setPartName(state, value) {
            state.partName = value;
        },
        setPartNameCategory(state, value) {
            state.partNameCategory = value;
        },
        setCategoryCharacteristics(state, value) {
            state.categoryCharacteristics = value;
        },

        setDefaultData(state, { originalPartsIds, originalPartsCount, originalPartsFacets, analoguePartsCount, analoguePartsFacets }) {
            if (originalPartsIds) state.defaultData.originalPartsIds = originalPartsIds;
            if (originalPartsCount) state.defaultData.originalPartsCount = originalPartsCount;
            if (originalPartsFacets) state.defaultData.originalPartsFacets = originalPartsFacets;
            if (analoguePartsCount) state.defaultData.analoguePartsCount = analoguePartsCount;
            if (analoguePartsFacets) state.defaultData.analoguePartsFacets = analoguePartsFacets;
        },
        setOriginalParts(state, value) {
            state.originalParts = value;
        },
        setAnalogueParts(state, { analogueParts, analoguePartsIsPart, analoguePartsInclude }) {
            if (analogueParts) {
                state.analogueParts = analogueParts;
            }

            if (analoguePartsIsPart) {
                state.analoguePartsIsPart = analoguePartsIsPart;
            }

            if (analoguePartsInclude) {
                state.analoguePartsInclude = analoguePartsInclude;
                state.analoguePartsInclude.results = [...analoguePartsInclude.results, ...analoguePartsInclude.results, ...analoguePartsInclude.results];
                state.analoguePartsInclude.next = 'true';
            }
        },
        addParts(state, { value, type }) {
            if (type === 'equal') {
                state.analogueParts.results = [...state.analogueParts.results, ...value.results];
                state.analogueParts.current_count = value.current_count;
                state.analogueParts.next = value.next;
            }
            else if (type === 'is_part') {
                state.analoguePartsIsPart.results = [...state.analoguePartsIsPart.results, ...value.results];
                state.analoguePartsIsPart.current_count = value.current_count;
                state.analoguePartsIsPart.next = value.next;
            }
            else if (type === 'include') {
                state.analoguePartsInclude.results = [...state.analoguePartsInclude.results, ...value.results];
                state.analoguePartsInclude.current_count = value.current_count;
                state.analoguePartsInclude.next = value.next;
            }
        },

        changeFavorite(state, { index, value, type }) {
            if (type === 'original') {
                state.originalParts.results[index].is_favorite = value;
            }
            if (type === 'equal') {
                state.analogueParts.results[index].is_favorite = value;
            }
            else if (type === 'is_part') {
                state.analoguePartsIsPart.results[index].is_favorite = value;
            }
            else if (type === 'include') {
                state.analoguePartsInclude.results[index].is_favorite = value;
            }
        },

        setLoading(state, { codename, value }) {
            state[codename] = value;
        },

        setInitialized(state, value = true) {
            state.initialized = value;
        },
        setInitializedData(state, value = true) {
            state.initializedData = value;
        },
        setInitializedFilter(state, value = true) {
            state.initializedFilter = value;
        },

        destroy(state) {
            state.partName = {};
            state.partNameCategory = {};
            state.categoryCharacteristics = [];

            state.defaultData = {
                originalPartsIds: [],
                originalPartsCount: 0,
                originalPartsFacets: null,
                analoguePartsCount: 0,
                analoguePartsFacets: null,
            };
            state.originalParts = {};
            state.analogueParts = {};
            state.analoguePartsIsPart = {};
            state.analoguePartsInclude = {};

            state.originalPartsLoading = false;
            state.analoguePartsLoading = false;
            state.analoguePartsIsPartLoading = false;
            state.analoguePartsIncludeLoading = false;

            state.initialized = false;
            state.initializedData = false;
            state.initializedFilter = false;
        },
    },

    actions: {
        async serverPrefetch({ commit, dispatch }, route) {
            try {
                await dispatch('fetchPartName', route.params.id);
                await dispatch('fetchPartNameCategory');
                commit('setInitialized');
            }
            catch (error) {
                commit('handleApiError', error, { root: true });
            }
        },

        async browserPrefetch({ commit, dispatch }, query = {}) {
            try {
                dispatch('fetchCategoryCharacteristics');
                await dispatch('initData', { in_stock: true, ...query });
                dispatch('initFilters', query);
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async initData({ commit, dispatch }, query = {}) {
            try {
                // get default counts and facets
                const [originalParts] = await Promise.all([
                    dispatch('getOriginalParts', {}),
                    dispatch('deliveryOffices/fetchDeliveryOffices'),
                ]);
                commit('setDefaultData', {
                    originalPartsIds: originalParts.results.reduce((acc, item) => {
                        acc.push(item.part_product_id);
                        return acc;
                    }, []),
                    originalPartsCount: originalParts.current_count,
                    originalPartsFacets: originalParts.facets,
                });

                if (originalParts.current_count) {
                    const analogueParts = await dispatch('getAnalogueParts', { query: { limit: 0 } });
                    commit('setDefaultData', {
                        analoguePartsCount: analogueParts.current_count,
                        analoguePartsFacets: analogueParts.facets,
                    });
                }

                // get data by query
                await Promise.all([
                    dispatch('fetchOriginalParts', { query }),
                    dispatch('fetchAnalogueParts', { query }),
                ])
                    .then(() => {
                        commit('setInitializedData');
                        commit('clearHttpError', null, { root: true });
                    });
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async initFilters({ state, commit, dispatch }, query) {
            commit('filter/setChecked', []);
            commit('filter/setStaticFilters', {
                config: staticFilters,
                facets: getMergedFacets([state.originalParts.facets, state.analogueParts.facets, state.analoguePartsIsPart.facets, state.analoguePartsInclude.facets]),
                defaultFacets: getMergedFacets([state.defaultData.originalPartsFacets, state.defaultData.analoguePartsFacets]),
            });

            dispatch('filter/parseQuery', {
                query,
                filters: staticFilters,
            });
            commit('sort/parseQuery', query);
            commit('setInitializedFilter');
        },

        async fetchPartName({ commit }, id) {
            const partName = await this.$api.partName.get(id);
            commit('setPartName', partName);
        },

        async fetchPartNameCategory({ commit, state }) {
            const partNameCategory = await this.$api.partCategories.get(state.partName.category.slug);
            commit('setPartNameCategory', partNameCategory);
        },

        async fetchCategoryCharacteristics({ state, rootState, commit }) {
            const config = {
                params: {
                    car_id: rootState.searchWidget.car.id,
                    category_id: state.partNameCategory.id,
                    get_facets: 'all',
                    limit: 0,
                },
            };
            const categoryCharacteristics = await this.$api.partNameDoc.get(config);
            commit('setCategoryCharacteristics', categoryCharacteristics.facets.characteristics);
        },

        async getOriginalParts({ state, rootState, rootGetters }, { query = {}, cancelToken }) {
            const parts = await this.$api.partProductsOneOfferDoc.get({
                params: {
                    car: rootState.searchWidget.car.id,
                    production_date: rootGetters['searchWidget/carProductionDate'],
                    part_name: state.partName.id,
                    is_original: true,
                    get_facets: 'all',
                    offset: 0,
                    ...query,
                },
                cancelToken,
            });

            parts.results.forEach(part => part.delivery = state.deliveryOffices);

            return parts;
        },

        async fetchOriginalParts({ commit, dispatch }, { query = {}, cancelToken }) {
            commit('setLoading', { codename: 'originalPartsLoading', value: true });
            try {
                const originalParts = await dispatch('getOriginalParts', { query, cancelToken });
                commit('setOriginalParts', originalParts);
                commit('setLoading', { codename: 'originalPartsLoading', value: false });
                return { originalParts };
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;
                commit('setLoading', { codename: 'originalPartsLoading', value: false });
            }
        },

        async getAnalogueParts({ state, rootState }, { query = {}, cancelToken }) {
            const params = {
                original_part__in: state.defaultData.originalPartsIds.join('__'),
                is_original: false,
                get_facets: 'all',
                offset: 0,
                ...query,
            };

            const parts = await this.$api.partProductsOneOfferDoc.get({ params, cancelToken });

            parts.results.forEach(part => part.delivery = state.deliveryOffices);

            return parts;
        },

        async fetchAnalogueParts({ state, dispatch, commit }, { query = {}, cancelToken }) {
            if (state.defaultData.originalPartsIds.length) {
                commit('setLoading', { codename: 'analoguePartsLoading', value: true });
                commit('setLoading', { codename: 'analoguePartsIsPartLoading', value: true });
                commit('setLoading', { codename: 'analoguePartsIncludeLoading', value: true });

                let analogueParts, analoguePartsIsPart, analoguePartsInclude;

                const getEquals = () => dispatch('getAnalogueParts', {
                    query: {
                        relation_type: 'equal',
                        ...query,
                    },
                    cancelToken,
                })
                    .then(response => {
                        analogueParts = response;
                        commit('setAnalogueParts', { analogueParts: response });
                        commit('setLoading', { codename: 'analoguePartsLoading', value: false });
                    })
                    .catch((error) => {
                        if (HTTP.isCancel(error)) return;
                        commit('setLoading', { codename: 'analoguePartsLoading', value: false });
                    });

                const getIsPart = () => dispatch('getAnalogueParts', {
                    query: {
                        relation_type: 'is_part',
                        ...query,
                    },
                    cancelToken,
                })
                    .then(response => {
                        analoguePartsIsPart = response;
                        commit('setAnalogueParts', { analoguePartsIsPart: response });
                        commit('setLoading', { codename: 'analoguePartsIsPartLoading', value: false });
                    })
                    .catch((error) => {
                        if (HTTP.isCancel(error)) return;
                        commit('setLoading', { codename: 'analoguePartsIsPartLoading', value: false });
                    });

                const getIncludes = () => dispatch('getAnalogueParts', {
                    query: {
                        relation_type: 'include',
                        ...query,
                    },
                    cancelToken,
                })
                    .then(response => {
                        analoguePartsInclude = response;
                        commit('setAnalogueParts', { analoguePartsInclude: response });
                        commit('setLoading', { codename: 'analoguePartsIncludeLoading', value: false });
                    })
                    .catch((error) => {
                        if (HTTP.isCancel(error)) return;
                        commit('setLoading', { codename: 'analoguePartsIncludeLoading', value: false });
                    });

                await Promise.all([
                    getEquals(),
                    getIsPart(),
                    getIncludes(),
                ]);

                return { analogueParts, analoguePartsIsPart, analoguePartsInclude };
            }
        },

        updateStaticFilters({ commit, state }, { newFacets, staticFilters }) {
            commit('filter/setStaticFilters', {
                config: staticFilters,
                facets: getMergedFacets(newFacets),
                defaultFacets: getMergedFacets([state.defaultData.originalPartsFacets, state.defaultData.analoguePartsFacets]),
            });
        },
    },
};