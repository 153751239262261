<template>
    <FilterWidgetWrap
        class="wrap"
        :codename="config.codename"
    >
        <UCheckbox
            v-bind="attrs"
            :id="config.id || 'filter-checkbox-' + config.codename"
            :value="value"
            :label="config.label"
            v-on="{
                ...listeners,
                ...handlers,
            }"
        ></UCheckbox>
    </FilterWidgetWrap>
</template>

<script>
import FilterWidgetWrap from '@/components/FilterWidgetWrap.vue';
import UCheckbox from '@ui/components/UCheckbox/UCheckbox.vue';

export default {
    name: 'FilterCheckbox',

    components: { UCheckbox, FilterWidgetWrap },

    inheritAttrs: false,

    props: {
        value: {
            type: Boolean,
            required: true,
        },

        config: {
            type: Object,
            default() {
                return {
                    label: '',
                    codename: '',
                    id: '',
                    name: '',
                };
            },
        },
    },

    data() {
        return {
            innerValue: false,
        };
    },

    computed: {
        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { change, ...listeners } = this.$listeners;
            return listeners;
        },

        handlers() {
            return {
                change: this.onChange,
            };
        },

        attrs() {
            const value = this.innerValue;

            return {
                ...this.$attrs,
                value,
            };
        },
    },

    watch: {
        value: {
            handler(value) {
                if (this.value !== this.innerValue) {
                    this.innerValue = value;
                }
            },

            immediate: true,
        },

        innerValue(value) {
            if (this.value !== value) {
                this.emitChange();
                this.emitSubmit();
            }
        },
    },

    methods: {
        onChange({ value }) {
            this.innerValue = value;
        },

        // emits

        emitChange() {
            const target = this;
            const value = this.innerValue;
            this.$emit('change', { target, value });
            this.$emit('reload');
        },

        emitSubmit() {
            const target = this;
            this.$emit('submit', { target });
        },

        // public

        clearInnerValue() {
            this.innerValue = false;
        },

        setInnerValue(value) {
            if ([true, false].includes(value)) {
                this.innerValue = value;
            }
        },

        onChangeCheckbox($event) {
            const value = $event.target.checked;
            this.$emit('change', value);
            this.$emit('reload');
        },
    },
};
</script>