export default ({ HTTP }) => ({
    async get(id, config) {
        const url = `/shops/${ id }/reviews/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async amounts(id, config) {
        const url = `/shops/${ id }/reviews/amounts/`;
        const response = await HTTP.get(url, config);
        return response.data;
    },

    async addReview(id, data, config) {
        const url = `/shops/${ id }/reviews/add_review/`;
        const response = await HTTP.post(url, data, config);
        return response.data;
    },

    async updateReview(id, uuid, data, config) {
        const url = `/shops/${ id }/reviews/${ uuid }/update_review/`;
        const response = await HTTP.post(url, data, config);
        return response.data;
    },

    async deleteReview(id, uuid) {
        const url = `/shops/${ id }/reviews/${ uuid }/delete_review/`;
        const response = await HTTP.delete(url);
        return response.data;
    },

    async addComplaint(id, uuid, data) {
        const url = `/shops/${ id }/reviews/${ uuid }/add_complaint/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async addResponseComplaint(id, uuid, data) {
        const url = `/shops/${ id }/reviews/${ uuid }/add_response_complaint/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },
});
