<template>
    <PopupWrap
        :loading="loading"
        closeBtn="outer"
        noPadding
        full
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <yandexMap
                :coords="coordsArray"
                zoom="16"
                :controls="['zoomControl']"
                :scrollZoom="false"
                :options="{ suppressMapOpenBlock: true }"
                style="width: 100%; height: 100%;"
                @map-was-initialized="mapInitHandler"
            >
                <ymapMarker
                    :coords="coordsArray"
                    :icon="getYMapMarkerIcon({ size: 'big' })"
                    :markerId="coordsString"
                ></ymapMarker>
            </yandexMap>

            <div
                v-if="shop"
                class="info-block layer-1"
            >
                <div class="info-block__main">
                    <ShopName
                        :shop="shop"
                        bigName
                    ></ShopName>

                    <p class="mt-8">
                        г. {{ shop.city.name }}, {{ shop.address }}
                    </p>

                    <ShopOpenCloseTime
                        :shopId="shop.id"
                        :status="shop.current_work"
                        class="mt-4"
                    ></ShopOpenCloseTime>
                </div>

                <ShopLogo
                    :shop="shop"
                    coverCodename="small"
                    class="info-block__logo"
                ></ShopLogo>
            </div>

            <div
                v-if="pickupPoint"
                class="info-block layer-1"
            >
                <div class="info-block__main">
                    <p
                        v-if="pickupPoint.service"
                        class="h3"
                    >
                        {{ pickupPoint.service.name }}
                    </p>
                    <p class="mt-4 text_small-fz text_secondary">
                        Пункт выдачи / постамат
                    </p>
                    <p class="mt-8">
                        {{ office.city }}, {{ office.address }}
                    </p>
                </div>

                <img
                    v-if="pickupPoint.service"
                    :src="$links.uploads + pickupPoint.service.logo.thumbnails.delivery_service_logo_big"
                    alt=""
                    class="info-block__logo"
                >
            </div>
        </div>
    </PopupWrap>
</template>


<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import ymapMarkerMixin from '@/mixins/ymapMarkerMixin.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ShopOpenCloseTime from '@/components/ShopOpenCloseTime.vue';
import ShopLogo from '@/components/ShopLogo.vue';

export default {
    name: 'PointOnMapPopup',

    components: {
        ShopLogo,
        ShopOpenCloseTime,
        ShopName: () => import('@/components/ShopName.vue'),
        PopupWrap,
        yandexMap,
        ymapMarker,
    },

    mixins: [ymapMarkerMixin, popup],

    props: {
        coords: [Array, String],
        shop: {
            type: Object,
            default: () => {},
        },
        pickupPoint: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            mapInstance: null,
            loading: true,
        };
    },

    computed: {
        coordsArray() {
            if (Array.isArray(this.coords)) {
                return this.coords;
            }
            return this.coords.split(', ').map(Number);
        },

        coordsString() {
            if (Array.isArray(this.coords)) {
                return this.coords.join();
            }
            return this.coords;
        },

        office() {
            return this.pickupPoint.office_to || this.pickupPoint;
        },
    },

    methods: {
        mapInitHandler(mapInstance) {
            this.mapInstance = mapInstance;
            this.loading = false;
        },
    },
};
</script>

<style scoped>
.info-block {
    position: absolute;
    z-index: 2;
}
@media (min-width: 768px) {
    .info-block {
        display: flex;
        align-items: flex-start;
        left: 50%;
        transform: translateX(-50%);
        bottom: 24px;
        width: 480px;
        padding: 20px 24px;
    }
}
@media (max-width: 767px) {
    .info-block {
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 20px var(--content-gap);
        background-color: var(--light-c);
        box-shadow: var(--base-shadow);
        border-radius: var(--border-radius-x3) var(--border-radius-x3) 0 0;
    }
}

@media (min-width: 768px) {
    .info-block__main {
        flex-grow: 1;
    }
}

.info-block__logo {
    width: 60px;
    height: 40px;
}
@media (min-width: 768px) {
    .info-block__logo {
        margin-left: 20px;
    }
}
@media (max-width: 767px) {
    .info-block__logo {
        display: none;
    }
}
</style>
