<template>
    <div
        class="scheme"
        @click="$emit('schemeClick', scheme.id)"
    >
        <div class="scheme__cover-wrap">
            <UIcon
                name="search"
                big
                class="scheme__cover-icon"
            ></UIcon>
            <img
                :src="$links.uploads + scheme.image"
                alt=""
                class="scheme__cover"
            >
        </div>

        <p
            v-if="scheme.start_date"
            class="scheme__name"
        >
            {{ scheme.start_date }} – {{ scheme.end_date === '99.9999' || !scheme.end_date ? 'н.в.' : scheme.end_date }}
        </p>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';

export default {
    name: "SchemeGridItem",

    components: { UIcon },

    props: {
        scheme: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style scoped>
.scheme {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius);
    cursor: pointer;
}
@media (min-width: 768px) {
    .scheme {
        padding: 20px;
    }
}
@media (max-width: 767px) {
    .scheme {
        padding: 12px;
    }
}

.scheme__cover-wrap {
    position: relative;
    padding-bottom: 100%;
}

.scheme__cover {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 100%;
    transform: translate(-50%, -50%);
    transition: opacity var(--transition);
}

.scheme:hover .scheme__cover {
    opacity: .3;
}

.scheme__cover-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    opacity: 0;
    fill: var(--dark-c);
    transition: opacity var(--transition);
}

.scheme:hover .scheme__cover-icon {
    opacity: 1;
}

.scheme__name {
    font-family: var(--f-semi-bold);
    word-break: break-word;
    transition: color var(--transition);
}
@media (min-width: 768px) {
    .scheme__name {
        margin-top: 16px;
    }
}
@media (max-width: 767px) {
    .scheme__name {
        margin-top: 12px;
        font-size: var(--small-fz);
    }
}
.scheme:hover .scheme__name {
    color: var(--primary-color);
}
</style>