export default filters => {
    return filters.map(data => {
        const filter = {};
        const type = data.data_type;
        filter.title = data.name + (data.unit ? ', ' + data.unit : '');
        filter.hint = data.hint;
        filter.codename = data.codename;

        if (type === 'one' || type === 'many' || type === 'name') {
            filter.widget = 'checkboxes';
            filter.api = 'choices';
            filter.params = {
                attribute_id__in: data.id,
            };
            filter.options = data.options || [];
            filter.limit = 10;
            filter.offset = 0;
            filter.isAttribute = true;
        }
        else if (type === 'float') {
            filter.widget = 'range';
            filter.min = 0;
            filter.max = 0;
            filter.isAttribute = true;
        }

        return filter;

    }).filter(filter => !!Object.keys(filter).length);
};