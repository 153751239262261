<template>
    <div class="top-gap_medium">
        <h1 class="h1 h1_block">
            Корзина
        </h1>

        <div>
            <Spinner
                v-if="!initialized"
                page
            ></Spinner>

            <EmptyBlock
                v-else-if="cartTotalCount === 0"
                icon="cart-stroke"
                title="Ваша корзина пуста"
                text="Чтобы подобрать подходящие товары и добавить их в корзину, воспользуйтесь поиском или каталогом."
                btnText="Подобрать товары"
                :btnUrl="{
                    name: 'market'
                }"
            ></EmptyBlock>

            <div
                v-else
                class="cart-content-layout"
            >
                <div class="cart-content-layout__main">
                    <div
                        v-show="updating"
                        class="spinner-wrap"
                    >
                        <Spinner
                            size="big"
                            center
                            page
                        ></Spinner>
                    </div>

                    <ul
                        class="cart-orders-list"
                        :class="{
                            'cart-orders-list_loading': updating
                        }"
                    >
                        <li
                            v-for="(order, orderIndex) in orders"
                            :key="'cart-order' + order.shop.id + orderIndex"
                            class="cart-content__section layer-1"
                        >
                            <Washboard noPadding>
                                <MarketItemCart
                                    v-for="(marketItem, marketItemIndex) in order.positions"
                                    :key="'order-market-item' + marketItemIndex"
                                    :item="marketItem"
                                    :modelName="marketItem.offer.offer_type"
                                ></MarketItemCart>
                            </Washboard>
                        </li>
                    </ul>
                </div>

                <div
                    v-if="availableCount !== 0"
                    class="check-mini"
                    :class="{
                        'check-mini_show': showStickyCheck
                    }"
                >
                    <div>
                        <b class="check-mini__total-price">{{ totalAvailablePrice | price }}</b>
                        <span class="check-mini__total-amount">{{ totalAvailableCountString }}</span>
                    </div>

                    <ButtonBlock
                        v-if="isAuthorized"
                        :to="{
                            name: 'checkout',
                        }"
                        primary
                        low
                        :disabled="availableCount === 0 || cartTotalCount > availableCount && availableCount > 0"
                        @click="handleToCheckoutClick"
                    >
                        Оформить заказ
                    </ButtonBlock>
                    <ButtonBlock
                        v-if="!isAuthorized"
                        primary
                        low
                        @click="showAuthorizationPopup"
                    >
                        Оформить заказ
                    </ButtonBlock>
                </div>

                <Observer
                    class="cart-content-layout__aside"
                    @intersect="intersected"
                >
                    <aside class="check layer-1">
                        <div class="check-section">
                            <div
                                v-if="availableCount !== 0"
                                class="check-row"
                            >
                                <span
                                    class="check-row__key"
                                >Товары<template
                                    v-if="cartTotalCount > availableCount"
                                > в наличии</template>:</span>
                                <b class="check-row__value">{{ availableCount }} шт.</b>
                            </div>
                            <div
                                v-else
                                class="check-row"
                            >
                                <span class="check-row__key text_red">Товаров нет в&nbsp;наличии</span>
                            </div>

                            <template v-if="cartTotalCount > availableCount && availableCount > 0">
                                <p class="check__error-text">
                                    Не&nbsp;все товары есть в&nbsp;наличии в&nbsp;достаточном количестве.
                                </p>
                                <p class="check__error-text">
                                    Отсутствующие товары не&nbsp;могут быть добавлены в&nbsp;заказ.
                                </p>
                            </template>

                            <p
                                v-if="availableCount === 0"
                                class="check__error-text"
                            >
                                Отсутствующие товары не&nbsp;могут быть добавлены в&nbsp;заказ.
                            </p>
                            <ButtonText
                                v-if="availableCount === 0 || cartTotalCount > availableCount && availableCount > 0"
                                dashed
                                secondary
                                dark
                                class="mt-16"
                                @click="deleteNotAvailable"
                            >
                                Удалить отсутствующие
                            </ButtonText>
                        </div>

                        <div class="check-section">
                            <div class="check-row">
                                <span class="check-row__key">Сумма:</span>
                                <span
                                    class="check-row__value check__total-price"
                                >{{ totalAvailablePrice | price }}</span>
                            </div>


                            <ButtonBlock
                                v-if="isAuthorized"
                                :to="{
                                    name: 'checkout',
                                }"
                                primary
                                class="check__submit-btn"
                                :disabled="availableCount === 0
                                    || (cartTotalCount > availableCount && availableCount > 0)"
                                @click="handleToCheckoutClick"
                            >
                                Оформить заказ
                            </ButtonBlock>
                            <ButtonBlock
                                v-if="!isAuthorized"
                                primary
                                class="check__submit-btn"
                                @click="showAuthorizationPopup"
                            >
                                Оформить заказ
                            </ButtonBlock>
                        </div>
                    </aside>
                </Observer>
            </div>
        </div>

        <LastSee></LastSee>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import plural from '../lib/plural.js';
import yandexMetrika from '@/mixins/yandex-metrika.js';
import LastSee from '../components/LastSee.vue';
import EmptyBlock from '../components/EmptyBlock.vue';
import MarketItemCart from '../components/MarketItemCart.vue';
import Washboard from '../components/Washboard.vue';
import Spinner from '../components/Spinner.vue';
import Observer from '../components/Observer.vue';
import ButtonBlock from '../components/_buttons/ButtonBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import { safeRouterPush } from '@/router/router.js';
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');

export default {
    name: 'Cart',

    components: {
        ButtonText,
        ButtonBlock,
        Observer,
        Spinner,
        Washboard,
        MarketItemCart,
        EmptyBlock,
        LastSee,
    },

    mixins: [yandexMetrika],

    metaInfo() {
        return {
            title: 'Корзина',
        };
    },

    serverPrefetch() {
        return this.INIT();
    },

    data() {
        return {
            updating: false,
            showStickyCheck: false,
        };
    },

    computed: {
        ...mapState({
            orders: state => state.cart.items,
            totalPrice: state => state.cart.totalPrice,
            totalAvailablePrice: state => state.cart.availablePrice,
            availableCount: state => state.cart.availableCount,
            cartTotalCount: state => state.cart.totalCount,
            isAuthorized: state => state.profile.isAuthorized,
            initialized: state => state.cart.initialized,
        }),

        totalAvailableCountString() {
            const n = this.availableCount;
            return `${ n } ${ plural(n, ['товар', 'товара', 'товаров']) }`;
        },
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.DESTROY();
    },

    methods: {
        ...mapMutations({
            SET_CART: 'cart/set',
            DESTROY: 'cart/destroy',
        }),

        ...mapActions({
            INIT: 'cart/init',
            DELETE_FROM_CART: 'cart/deleteFromCart',
            CLEAR_CART: 'cart/clearCart',
        }),

        async init() {
            this.$breadcrumbs([]);
            if (!this.initialized) await this.INIT();
        },

        deleteNotAvailable() {
            this.updating = true;

            const notAvailablePositions = [];
            this.orders.forEach(order => {
                order.positions.forEach(position => {
                    if (position.offer.amount === 0 || !position.offer.is_visible) {
                        notAvailablePositions.push({ offer: position.offer.id });
                    }
                });
            });

            this.DELETE_FROM_CART({ data: notAvailablePositions })
                .then(() => {})
                .finally(() => {
                    this.updating = false;
                });
        },

        intersected(value) {
            this.showStickyCheck = value !== 'in';
        },

        showAuthorizationPopup() {
            const callback = () => {
                this.handleYandexMetrikaGoal('oformitzakaz');
                safeRouterPush({ name: 'checkout' });
            };
            const props = {
                callback,
                hasCover: true,
                title: 'Войдите и оформите заказ',
            };
            const options = { props };
            this.$popup(SignInPopup, options);
        },

        handleToCheckoutClick() {
            this.handleYandexMetrikaGoal('oformitzakaz');
        },
    },
};
</script>

<style scoped src="../assets/css/cart.css"></style>
<style scoped src="../assets/css/check.css"></style>
<style scoped>
.spinner-wrap {
    position: sticky;
    top: 0;
    height: 0;
    z-index: 1;
    padding-top: 100px;
    padding-bottom: 100px;
}

.cart-orders-list {
    opacity: 1;
    transition: opacity .1s ease-out;
}

.cart-orders-list_loading {
    margin-top: -200px;
    opacity: .3;
    transition: opacity .2s ease-in;
}
</style>