var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",staticClass:"u-icon-button",class:{
        'u-icon-button_active': _vm.active,
        'u-icon-button_disabled': _vm.disabled,
        'u-icon-button_border': (_vm.round || _vm.square) && !_vm.shadow,
        'u-icon-button_round': _vm.round,
        'u-icon-button_square': _vm.square,
        'u-icon-button_shadow': _vm.shadow,
        'u-icon-button_primary': _vm.primary,
        'u-icon-button_secondary': _vm.secondary,
        'u-icon-button_red': _vm.red,
        'u-icon-button_yellow': _vm.yellow,
        'u-icon-button_used': _vm.used,
        'u-icon-button_tiny': _vm.tiny,
    }},'component',Object.assign({}, _vm.$attrs,
        _vm.attrs),false),_vm.$listeners),[(_vm.loading)?_c('USpinner',_vm._b({},'USpinner',Object.assign({}, _vm.spinner),false)):_c('UIcon',{staticClass:"u-icon-button__icon",attrs:{"name":_vm.name,"tiny":_vm.tiny,"small":_vm.small,"big":_vm.big}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }