<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_block popup-title">
                Где вы находитесь?
            </h2>

            <UDropdown
                ref="dropdown"
                :options="searchOptions"
                :initialized="searchInitialized"
                class="dropdown mb-24"
                @select="onSelectCity"
            >
                <template #default="{ handlers }">
                    <USearchInput
                        :value="searchValue"
                        :loading="searching"
                        placeholder="Город, поселок или деревня"
                        :clearInputOnFocus="!!currentCity"
                        autocomplete="off"
                        searchParam="search"
                        @blur="handlers.blur(); searchInitialized = false"
                        @focus="handlers.focus(); $nextTick(onFocus)"
                        @input="onChangeSearchValue"
                        @keydown="handlers.keydown"
                        @click:clear="onClear"
                    ></USearchInput>
                </template>

                <template #label="{ option }">
                    <p class="ellipsis">
                        {{ option.full_name }}
                    </p>
                    <p class="option-description ellipsis">
                        <span
                            v-if="option.region"
                        >{{ option.region }}</span><span
                            v-if="option.region && option.district"
                        >,</span>
                        <span v-if="option.district">{{ option.district }}</span>
                    </p>
                </template>
            </UDropdown>

            <InfoMessage class="info-message">
                Мы доставляем товары везде, где работает транспортная компания СДЭК. Если вы не нашли свой город, попробуйте выбрать другой населённый пункт рядом.
            </InfoMessage>

            <div class="image-wrap mb-24">
                <img
                    src="../../assets/images/map-x2.jpg"
                    class="image"
                >
            </div>

            <div class="footer">
                <ButtonText
                    dashed
                    secondary
                    dark
                    :loading="fetchingCurrentCity"
                    class="current-city-button"
                    @click="fetchCurrentCity"
                >
                    Определить автоматически
                </ButtonText>

                <ButtonBlock
                    primary
                    :loading="confirming"
                    :disabled="!currentCity"
                    class="confirm-button"
                    @click="confirm"
                >
                    Подтвердить
                </ButtonBlock>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
// services
import { HTTP } from '@/http.js';
// utils
import { mapMutations } from 'vuex';
// mixins
import popup from '@/mixins/popup.js';
// components
import PopupWrap from '@/components/popups/PopupWrap.vue';
import USearchInput from '@/components/InputSearch.vue';
import UDropdown from '@/components/UDropdownDeprecated/UDropdownDeprecated.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import InfoMessage from '@/components/InfoMessage.vue';


export default {
    name: 'ChangeCityPopup',

    components: {
        InfoMessage,
        ButtonBlock,
        ButtonText,
        UDropdown,
        USearchInput,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        global: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            searchValue: '',
            searchOptions: [],
            searchSource: null,
            searchTimeout: null,
            searching: false,
            searchInitialized: false,
            confirming: false,
            currentCity: null,
            fetchingCurrentCity: false,
        };
    },

    methods: {
        ...mapMutations({
            SET_CURRENT_CITY: 'cities/setCurrentCity',
            SET_CURRENT_CITY_ID: 'cities/setCurrentCityId',
        }),

        onSelectCity({ value: option }) {
            this.currentCity = option;
            this.searchValue = option.full_name;
        },

        onChangeSearchValue({ value }) {
            if (value === this.searchValue) return;

            this.searchValue = value;
            this.setFetchCitiesTimeout();
            this.currentCity = null;
        },

        onClear() {
            this.searchInitialized = false;

            // ждём, пока не закончится анимация
            setTimeout(() => {
                this.searchOptions = [];
            }, 200);
        },

        onFocus() {
            if (this.currentCity || !this.searchValue) return;
            this.fetchCities();
        },

        async fetchCities() {
            if (this.searchSource) {
                this.searchSource.cancel();
                this.searchSource = null;
            }

            const search = this.searchValue;

            if (!search || search.length < 2) return;

            this.searching = true;
            const limit = 6;
            const params = { search, limit };
            this.searchSource = HTTP.CancelToken.source();
            const cancelToken = this.searchSource.token;
            const config = { params, cancelToken };

            try {
                const { results } = await this.$api.cities.get(config);

                this.searchOptions = results;
            }
            catch (error) {
                if (HTTP.isCancel(error)) return;

                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.searching = false;

                // ждём, пока options обновится в UDropdown
                // чтобы корректно отработала анимация
                this.$nextTick(() => {
                    this.searchInitialized = true;
                });
            }
        },

        async setFetchCitiesTimeout() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }

            if (this.searchValue) {
                this.searchTimeout = setTimeout(() => {
                    this.fetchCities();
                }, 500);
            }
            else {
                if (this.searchSource) {
                    this.searchSource.cancel();
                    this.searchSource = null;
                }
            }
        },

        async fetchCurrentCity() {
            this.fetchingCurrentCity = true;

            try {
                this.currentCity = await this.$api.cities.current();
                this.searchValue = this.currentCity.name;
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.fetchingCurrentCity = false;
            }
        },

        async confirm() {
            const city = this.currentCity;

            if (this.global) {
                if (city.id === this.$store.state.cities.currentCityId) {
                    this.close();
                    return;
                }

                this.confirming = true;
                const data = { city };

                try {
                    await this.$api.cities.setCity(data);
                    this.SET_CURRENT_CITY_ID(city.id);
                }
                catch (error) {
                    this.$store.commit('handleCommonHttpError', error);
                }
            }
            else {
                this.callback({ city });
                this.close();
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 576px;
    }
}

.dropdown {
    flex-shrink: 0;
    flex-grow: 0;
}

.dropdown >>> .u-dropdown__options-list {
    max-height: 320px;
}

.option-description {
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
    line-height: 1.33;
}

.info-message {
    margin-top: 20px;
}
@media (max-width: 767px) {
    .info-message {
        margin-top: 16px;
    }
}

.image-wrap {
    width: 100%;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 767px) {
    .image-wrap {
        flex-grow: 1;
    }
}

.image {
    width: 260px;
    height: 200px;
}

.footer {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 767px) {
    .footer {
        flex-direction: column;
    }

    .current-city-button {
        margin-bottom: 16px;
    }

    .confirm-button {
        align-self: stretch;
    }
}
</style>