<template>
    <div
        :class="[
            'spinner',
            'spinner_' + size,
            light && 'spinner_light',
            dark && 'spinner_dark',
            page && 'spinner_page',
            page && 'spinner_big',
            center && 'spinner_center',
            absoluteCenter && 'spinner_absolute-center',
        ]"
    >
        <UIcon
            name="spinner"
            class="spinner__icon"
        ></UIcon>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'Spinner',

    components: { UIcon },

    props: {
        size: {
            type: String,
            default: 'base',
            validator(value) {
                return [ 'base', 'small', 'medium', 'big'].includes(value);
            },
        },

        light: Boolean,
        dark: Boolean,

        page: {
            type: Boolean,
            default: false,
        },

        center: Boolean,
        absoluteCenter: Boolean,
    },
};
</script>

<style scoped>
.spinner {
    position: relative;
    display: block;
    flex-shrink: 0;
    color: var(--primary-color);
    background-color: transparent;
    font-size: 0;
    pointer-events: none;
}

.spinner_light {
    color: var(--light-c);
}

.spinner_dark {
    color: var(--dark-c);
}

.spinner_base {
    width: 20px;
    height: 20px;
}

.spinner_small {
    width: 16px;
    height: 16px;
}

.spinner_big {
    width: 36px;
    height: 36px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner_page {
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translateY(-50%);
}

.spinner_center {
    margin-left: auto;
    margin-right: auto;
}

.spinner_absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.spinner__icon {
    width: 100%;
    height: 100%;
    stroke: currentColor;
    vertical-align: baseline;
    animation: spinner 1s infinite ease-out;
}
</style>