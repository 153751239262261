<script>
// utils
import deepClone from '@ui/utils/deepClone.js';
import equals from '@ui/utils/equals.js';
// use
import useForm from '@/components/_form/useForm.js';
import useFormMethods from '@/components/_form/useFormMethods.js';
import useFormProviders from '@/components/_form/useFormProviders.js';
import useFormWatch from '@/components/_form/useFormWatch.js';
// components
import UTabs from '@ui/components/UTabs/UTabs.vue';


// TODO: добавит функцию get для получения чистых данных
// настраивать то, какие данные должен возвращать виджет
// для формы, можно будет в плагинах
export default {
    name: 'FormManager',

    provide() {
        return {
            ...useFormProviders(this),
            refRoot: this.refRoot,
            registerTab: this.registerTab,
            unregisterTab: this.unregisterTab,
        };
    },

    model: {
        prop: 'values',
        event: 'change',
    },

    props: {
        formTag: {
            type: Boolean,
            default: true,
        },

        values: { // originalValues
            type: Object,
            required: true,
        },

        errors: Object, // originalErrors
    },

    data() {
        return {
            ...useForm(this),
            initialValues: {},
            innerErrors: {},
            currentTab: null,
            tabs: [],
        };
    },

    computed: {
        originalValues() {
            return this.values;
        },

        originalErrors() {
            return this.errors;
        },

        isRoot() {
            return true;
        },
    },

    watch: {
        ...useFormWatch(),

        innerErrors: {
            handler(errors) {
                if (!equals(this.innerErrors, this.errors)) {
                    this.$emit('error', errors);
                }
            },
        },

        currentTab: {
            handler(value) {
                if (value) {
                    this.tabs.forEach((component, index) => {
                        component.show = index === value.name;
                    });
                }
            },

            immediate: true,
        },
    },

    created() {
        this.initialValues = deepClone(this.values);
    },

    methods: {
        ...useFormMethods(),

        registerTab(component) {
            this.tabs = [...this.tabs, component];
        },

        unregisterTab(component) {
            const index = this.tabs.findIndex(tab => tab === component);

            if (index !== -1) {
                this.tabs.splice(index, 1);
            }
        },

        refRoot(calleeComponent, callerComponent) {
            const caller = callerComponent.name;

            if (this.fields[caller]) {
                if (this.dependencies[caller]) {
                    this.dependencies[caller] = [...this.dependencies[caller], calleeComponent];
                }
                else {
                    this.dependencies[caller] = [calleeComponent];
                }

                return this.fields[caller].innerValue;
            }
            else {
                return {};
            }
        },

        // public functions

        async validate() {
            // возвращает innerValues если есть ошибка
            // на странице должны быть помощники по обработке объекта ошибки

            // сначала посмотреть, есть ли ошибка вообще
            // если есть ошибка, то есть ли ошибка среди required
            // иначе вывести другую формулировку

            return new Promise(resolve => {
                this.runRules();
                const invalid = this.getInvalid();

                if (invalid) {
                    this.show();

                    setTimeout(() => {
                        this.$formManager.options.handleFormValidateErrors(this.innerErrors);
                        resolve(this.innerErrors);
                    });
                }
                else {
                    resolve(false);
                }
            });
        },

        handle(error) {
            this.$formManager.options.handleHttpError(error, this);
        },

        updateInitialValues() {
            this.$nextTick(() => {
                this.initialValues = deepClone(this.values);
            });
        },

        updateOriginalErrors() {
            if (this.originalErrors !== undefined) {
                this.$emit('error', this.innerErrors);
            }
        },

        onKeypress($event) {
            const keyCode = $event.keyCode;

            // enter
            if (keyCode === 13) {
                this.$emit('enter', $event);
            }
        },

        onSubmit($event) {
            $event.preventDefault();
            this.$emit('submit', $event);
        },

        getItems(tabs) {
            return tabs.map((component, index) => {
                const { innerInvalid, title } = component;

                return {
                    name: index,
                    label: title,
                    invalid: innerInvalid,
                };
            });
        },

        genPanel() {
            const tabs = this.tabs;
            const items = this.getItems(tabs);

            if (this.currentTab === null) {
                this.currentTab = items[0];
            }

            const props = {
                value: this.currentTab,
                items,
            };

            return this.$createElement(UTabs, {
                props,
                class: 'form-manager__tabs',
                on: {
                    change: value => {
                        this.currentTab = value;
                    },
                },
            });
        },
    },

    render(h) {
        const options = {
            on: {
                keypress: this.onKeypress,
                submit: this.onSubmit,
            },
        };

        const children = this.$scopedSlots.default();

        if (!this.tabs.length) return h(this.formTag ? 'form' : 'div', options, children);

        const panel = this.genPanel();

        // TODO: добавить переход через transition
        return h(this.formTag ? 'form' : 'div', options, [panel, ...children]);
    },
};
</script>

<style>
.form-manager__tabs.u-tabs__list {
    position: sticky;
    top: 0;
    margin-bottom: 36px;
    padding-top: 20px;
    z-index: 4;
    background-color: var(--light-c);
}
</style>