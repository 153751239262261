<template>
    <div></div>
</template>

<script>
import { mapState } from 'vuex';
import { getErrorCode } from '@/lib/errors.js';

export default {
    name: 'ConfirmEmailPage',

    metaInfo() {
        return {
            title: 'Подтверждение e-mail',
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),
    },

    mounted() {
        if (this.profile) {
            this.confirm();
        }
        else {
            this.$router.push({ name: 'signin' });
        }
    },

    methods: {
        confirm() {
            const data = {
                confirm_code: this.$route.params.code,
            };

            this.$api.users.changeEmailConfirm(data)
                .then(() => {
                    this.$store.dispatch('profile/get')
                        .then(() => {
                            this.$router.push({ name: 'account-settings' });
                        });
                })
                .catch(error => {
                    const code = getErrorCode(error);
                    const data = error.response.data;

                    if (code === 400) {
                        const { non_field_errors } = data;
                        if (non_field_errors) {
                            this.$error(non_field_errors[0].message);
                        }
                        else {
                            this.$error('Не удалось подтвердить email.');
                        }
                    }
                    else if (code === 404) {
                        this.$error('Запрашиваемый адрес не существует.');
                    }
                    else if (code === 500) {
                        this.$error(data.detail);
                    }
                });
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.commit('fromRoute/setFromRoute', from);
        });
    },
};
</script>