<template>
    <div class="market-item">
        <div class="market-item__info">
            <MarketItemCover
                :item="item.offer[modelName]"
                :modelName="modelName"
                coverCodename="micro"
                class="cover-wrap"
                isButton
                @click="showOfferInfo"
            ></MarketItemCover>

            <h3 class="title">
                <button
                    class="title-btn link-primary-hover"
                    @click="showOfferInfo"
                >
                    {{ item.offer[modelName] && item.offer[modelName].name }}
                </button>
            </h3>
        </div>

        <div class="actions">
            <span
                v-if="modelName !== 'contract_part'"
                class="amount"
            >{{ computedAmount }} шт.</span>

            <span class="price">
                {{ item.total_available_price || item.total_price | price }}
            </span>
        </div>
    </div>
</template>

<script>
import MarketItemCover from "./MarketItemCover.vue";
const MarketItemInfoWithOfferPopup = () => import('@/components/popups/MarketItemInfoWithOfferPopup.vue');

export default {
    name: "MarketItemCheckout",

    components: { MarketItemCover },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        modelName() {
            return this.item.offer.offer_type;
        },

        computedAmount() {
            const amount = this.item.amount_in_basket || this.item.amount;
            return amount > this.item.offer.amount ? this.item.offer.amount : amount;
        },
    },

    methods: {
        showOfferInfo() {
            const props = {
                offer: this.item.offer,
                marketItemId: this.item.offer[this.modelName].id,
                modelName: this.modelName,
            };
            this.$popup(MarketItemInfoWithOfferPopup, { props });
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .market-item {
        display: flex;
        align-items: center;
    }
}

.market-item__info,
.actions {
    display: flex;
    align-items: center;
}

.actions {
    flex-grow: 1;
}
@media (max-width: 767px) {
    .actions {
        margin-top: 12px;
        justify-content: space-between;
    }
}

@media (min-width: 768px){
    .cover-wrap {
        margin-right: 20px;
    }
}
@media (max-width: 767px) {
    .cover-wrap {
        margin-right: 12px;
    }
}

.title {
    display: inline-block;
    flex-grow: 1;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
}
@media (min-width: 768px) {
    .title {
        margin-right: 20px;
    }
}

.title-btn {
    text-align: left;
}

.amount {
    flex-shrink: 0;
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .amount {
        width: 60px;
        margin-left: auto;
        text-align: right;
    }
}

.price {
    margin-left: 20px;
    font-family: var(--f-bold);
    text-align: right;
}
@media (min-width: 768px) {
    .price {
        width: 100px;
        flex-shrink: 0;
    }
}
</style>
