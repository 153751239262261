import deliveryOffices from '@/store/modules/deliveryOffices.js';


export default {
    namespaced: true,

    modules: {
        deliveryOffices,
    },

    state: () => ({
        initialized: false,
        newPart: {},
        analogs: {
            current_count: 0,
        },
    }),

    mutations: {
        setInitialized(state) {
            state.initialized = true;
        },

        setAnalogs(state, data) {
            state.analogs = data;
        },

        destroy(state) {
            state.initialized = false;
            state.newPart = {};
            state.analogs = {
                current_count: 0,
            };
        },

        setNewPart(state, newPart) {
            state.newPart = newPart;
        },

        changeFavorite(state, value) {
            state.newPart.is_favorite = value;
        },
    },

    actions: {
        async init({ commit, dispatch }, { id }) {
            try {
                dispatch('viewsHistory/get', null, { root: true });

                const [newPart, delivery] = await Promise.all([
                    this.$api.parts.get(id),
                    dispatch('deliveryOffices/getItems'),
                ]);

                newPart.delivery = delivery;

                commit('setNewPart', newPart);
                const analogs = await dispatch('getAnalogs');
                commit('setAnalogs', analogs);
                commit('setInitialized');
                commit('clearHttpError', null, { root: true });

                return { newPart };
            }
            catch (error) {
                commit('handleInitError', error, { root: true });
            }
        },

        async updateNewPart({ commit }, { id }) {
            try {
                const newPart = await this.$api.parts.get(id);
                commit('setNewPart', newPart);
            }
            catch (error) {
                commit('handleCommonHttpError', error, { root: true });
            }
        },

        async getAnalogs({ state, rootState }) {
            if (state.newPart.is_original) {
                const params = {
                    original_part__in: state.newPart.id,
                    is_original: false,
                    limit: 4,
                    offset: 0,
                    city: rootState.cities.currentCityId,
                };

                return await this.$api.partProductsDoc.get( { params });
            }
            else {
                const params = {
                    analog_part: state.newPart.id,
                    limit: 4,
                    offset: 0,
                    city: rootState.cities.currentCityId,
                };

                return await this.$api.partProductsDoc.get( { params });
            }
        },
    },
};