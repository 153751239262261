<template>
    <PopupWrap
        noPadding
        :loading="initLoading"
        @clickaway="hidePopup"
        @close="hidePopup"
    >
        <div
            v-if="!initLoading"
            class="popup-content"
        >
            <div
                ref="popupHead"
                class="head"
                :class="{
                    'head_border': view === 'list',
                }"
            >
                <h2
                    v-if="schemeData.groups"
                    class="h2 popup-title"
                >
                    {{ schemeData.groups[schemeData.groups.length - 1].name }}
                </h2>
                <p
                    v-if="schemeData.start_date"
                    class="subtitle"
                >
                    {{ schemeData.start_date }} – {{ schemeData.end_date === '99.9999' || !schemeData.end_date ? 'н.в.' : schemeData.end_date }}
                </p>

                <div class="view-toggler">
                    <RadioButtonsGroup
                        v-model="view"
                        name="offers"
                        type="text"
                        :options="[
                            {
                                label: 'Схема',
                                value: 'scheme',
                            },
                            {
                                label: 'Список',
                                value: 'list',
                            },
                        ]"
                    ></RadioButtonsGroup>
                </div>
            </div>

            <div
                ref="content"
                class="content"
            >
                <ul
                    ref="partsListDesktop"
                    class="parts-list"
                    :class="{
                        'parts-list_hidden': view === 'scheme',
                    }"
                >
                    <template
                        v-for="(item, index) in parts"
                    >
                        <li
                            v-if="item.part"
                            :ref="'part-item-' + index"
                            class="part"
                        >
                            <button
                                class="part-btn"
                                @click="showPartPrice(index, item)"
                                @mouseenter="hoveredNode = index"
                                @mouseleave="hoveredNode = null"
                            >
                                <b class="part__id">{{ item.part.pnc }}</b>
                                <span class="flex align-items-center">
                                    <span
                                        class="part__name"
                                        :class="{
                                            'part__name_hover': index === openedNode || index === hoveredNode,
                                        }"
                                    >
                                        {{ item.part.name }}
                                    </span>
                                    <UIcon
                                        name="arrow-down"
                                        small
                                        secondary
                                        class="ml-8 part__arrow"
                                        :class="{
                                            'part__arrow_rotate': index === openedNode
                                        }"
                                    ></UIcon>
                                </span>
                            </button>

                            <TransitionExpand>
                                <div
                                    v-if="!subPartsLoaded && index === openedNode"
                                    key="spinner"
                                >
                                    <Spinner
                                        class="part__modifications-list_spinner"
                                    ></Spinner>
                                </div>

                                <div
                                    v-if="subPartsLoaded && index === openedNode"
                                    key="modifications-list"
                                >
                                    <div
                                        v-if="partPricesCount"
                                        class="part__modifications-list"
                                    >
                                        <div
                                            v-for="info in partPrices"
                                            class="subpart"
                                        >
                                            <div class="subpart__main-wrap">
                                                <div class="subpart__main">
                                                    <div>
                                                        <span class="text_semi-bold">{{ info.oem }}</span>, {{ info.production_period_start }} – {{ info.production_period_end === '99.9999' || !info.production_period_end ? 'н.в.' : info.production_period_end }},
                                                        <span class="subpart__amount-hint-wrap">
                                                            {{ info.quantity }} шт.<UTooltip color="white" maxWidth="396">
                                                                <template #trigger="{ handlers, value, attrs }">
                                                                    <UTooltipIcon
                                                                        class="subpart__hint"
                                                                        :active="value"
                                                                        v-bind="attrs"
                                                                        v-on="handlers"
                                                                    ></UTooltipIcon>
                                                                </template>

                                                                <div class="attrs-table">
                                                                    <div class="attrs-table__row">
                                                                        <div class="attrs-table__td">
                                                                            Оригинальный OEM-код
                                                                        </div>
                                                                        <div class="attrs-table__td">
                                                                            {{ info.oem }}
                                                                        </div>
                                                                    </div>
                                                                    <div class="attrs-table__row">
                                                                        <div class="attrs-table__td">
                                                                            Период производства
                                                                        </div>
                                                                        <div class="attrs-table__td">
                                                                            {{ info.production_period_start }} – {{ info.production_period_end === '99.9999' || !info.production_period_end ? 'н.в.' : info.production_period_end }}
                                                                        </div>
                                                                    </div>
                                                                    <div class="attrs-table__row">
                                                                        <div class="attrs-table__td">
                                                                            Кол-во использований в авто
                                                                        </div>
                                                                        <div class="attrs-table__td">
                                                                            {{ info.quantity }}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        v-if="info.description"
                                                                        class="attrs-table__row"
                                                                    >
                                                                        <div class="attrs-table__td">
                                                                            Примечание
                                                                        </div>
                                                                        <div class="attrs-table__td">
                                                                            {{ info.description }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </UTooltip></span>
                                                    </div>
                                                    <p
                                                        v-if="info.description"
                                                        class="subpart__description"
                                                    >
                                                        {{ info.description }}
                                                    </p>
                                                </div>

                                                <ButtonBlock
                                                    v-if="item.part && item.part.id"
                                                    :to="{
                                                        name: 'market-parts-search',
                                                        query: {
                                                            sku: info.oem,
                                                            car: selectedCar.id,
                                                            part: item.part.id
                                                        }
                                                    }"
                                                    primary
                                                    low
                                                    class="subpart__btn"
                                                >
                                                    Цены
                                                </ButtonBlock>
                                            </div>

                                            <div class="subpart__on-scheme-btn mt-16">
                                                <ButtonText
                                                    primary
                                                    dashed
                                                    @click="showOnScheme(index)"
                                                >
                                                    Показать на схеме
                                                </ButtonText>
                                            </div>
                                        </div>
                                    </div>

                                    <p
                                        v-if="!partPricesCount"
                                        class="part__modifications-list_empty"
                                    >
                                        Запчасти не найдены
                                    </p>
                                </div>
                            </TransitionExpand>
                        </li>

                        <li
                            v-else
                            :ref="'part-item-' + index"
                            class="part"
                        >
                            <button
                                class="part-btn"
                                :class="{
                                    'part-btn_disabled': !item.group,
                                }"
                                @click="changeGroup(item.group, index)"
                            >
                                <span class="part__id">{{ item.group ? item.number : 'OEM-код' }}</span>
                                <span class="flex align-items-center">
                                    <span
                                        class="part__name"
                                        :class="{
                                            'part__name_hover': index === openedNode || index === hoveredNode,
                                        }"
                                        @mouseenter="hoveredNode = index"
                                        @mouseleave="hoveredNode = null"
                                    >
                                        {{ item.group ? item.group.name : item.number }}
                                    </span>

                                    <UTooltip>
                                        <template #trigger="{ attrs, handlers }">
                                            <UIcon
                                                name="external"
                                                small
                                                secondary
                                                class="part__external-icon ml-8"
                                                v-bind="attrs"
                                                v-on="handlers"
                                            ></UIcon>
                                        </template>

                                        Перейти к схеме этого узла
                                    </UTooltip>
                                </span>
                            </button>
                        </li>
                    </template>
                </ul>

                <div
                    ref="imageWrap"
                    :key="view"
                    class="scheme-wrap"
                    :class="{
                        'scheme-wrap_hidden': view === 'list',
                    }"
                >
                    <div
                        ref="imageInnerWrap"
                        class="scheme-inner-wrap"
                    >
                        <img
                            ref="imageDefault"
                            :src="$links.uploads + schemeData.image"
                            alt=""
                            class="scheme-img scheme-img_default"
                        >
                        <img
                            ref="image"
                            :src="$links.uploads + schemeData.image"
                            alt=""
                            class="scheme-img scheme-img_zoom"
                            :class="{
                                'scheme-img_zoomer': schemeZoomValue > 0,
                            }"
                            :style="schemeImageStyles"
                            @load="handleSchemeImageLoad"
                            @mousedown="onSchemeImageMouseDown"
                        >

                        <template v-if="schemeImageLoad">
                            <template v-for="(item, index) in parts">
                                <UTooltip
                                    v-for="(coordinates, coordinates_index) in item.coordinates"
                                    :key="`${ coordinates_index }-${ coordinates.y1 }`"
                                >
                                    <template #trigger="{ attrs, handlers }">
                                        <button
                                            type="button"
                                            class="scheme-node-point-wrap"
                                            :class="{
                                                'scheme-node-point-wrap_clickable': (item.group || {}).id || (item.part || {}).id,
                                                'scheme-node-point-wrap_moving': moving,
                                            }"
                                            :style="partsOnSchemeStyles[index][coordinates_index].position"
                                            v-bind="attrs"
                                            @click="handleSchemePartClick(index, item)"
                                            @mouseenter="hoveredNode = index; handlers.mouseenter($event)"
                                            @mouseleave="hoveredNode = null; handlers.mouseleave($event)"
                                        >
                                            <span
                                                class="scheme-node-point"
                                                :class="{
                                                    'scheme-node-point_hover': index === openedNode
                                                        || index === hoveredNode,
                                                }"
                                                :style="partsOnSchemeStyles[index][coordinates_index].size"
                                            ></span>
                                        </button>
                                    </template>

                                    {{ item.part
                                        ? item.part.name
                                        : item.group
                                            ? item.group.name
                                            : 'OEM-код: ' + item.number }}
                                </UTooltip>
                            </template>
                        </template>
                    </div>

                    <ZoomControl
                        class="scheme-zoom-control"
                        @change="updateImageZoom"
                    ></ZoomControl>
                </div>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import throttle from '@/lib/throttle.js';
import popup from "@/mixins/popup.js";
import PopupWrap from '@/components/popups/PopupWrap.vue';
import TransitionExpand from "@/components/_transitions/TransitionExpand.vue";
import RadioButtonsGroup from "@/components/RadioButtonsGroup.vue";
import ButtonText from "@/components/_buttons/ButtonText.vue";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import UIcon from '@ui/components/UIcon/UIcon.vue';
import ZoomControl from '@/components/ZoomControl.vue';
import Spinner from '@/components/Spinner.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


let shiftX = 0;
let shiftY = 0;
export default {
    name: 'SchemePopup',

    components: {
        UTooltipIcon,
        UTooltip,
        Spinner,
        ZoomControl,
        UIcon,
        ButtonBlock,
        ButtonText,
        RadioButtonsGroup,
        TransitionExpand,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        schemeId: [Number, String],
        setSchemeData: Function,

        selectedCar: {
            type: Object,
            default() {
                return {};
            },
        },
        productionDate: String,
    },

    data() {
        return {
            initLoading: true,
            schemeImageLoad: false,
            schemeData: {},
            parts: [],
            openedNode: null,
            hoveredNode: null,
            partPrices: [],
            partPricesCount: null,
            subPartsLoaded: true,
            view: 'scheme',

            originalImageWidth: 0,
            originalImageHeight: 0,
            schemeImageStyles: {},
            schemeZoomValue: 0,
            partsOnSchemeStyles: [],
            moving: false,
        };
    },

    watch: {
        view(value) {
            this.updateImageZoom(0);
            this.calcHeight();
            if (value === 'scheme') this.schemeImageLoad = false;
        },

        '$route.query.s'(value) {
            if (!value) {
                this.close();
            }
        },
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.init();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        if (this.$refs.image) {
            this.$refs.image.removeEventListener('touchstart', this.onSchemeImageTouchStart);
            this.$refs.image.removeEventListener('touchmove', this.onSchemeImageTouchMove);
            this.$refs.image.removeEventListener('touchend', this.onSchemeImageTouchEnd);
        }
    },

    methods: {
        async init() {
            this.schemeData = await this.getSchemeData(this.schemeId);
            const { results } = await this.getSchemeParts();
            this.parts = results;
            this.initLoading = false;
            this.setSchemeData(this.schemeData);

            if (this.$route.query.part_id) {
                setTimeout(() => {
                    const index = this.parts.findIndex(item => item.part && item.part.id === Number(this.$route.query.part_id));
                    if (index > -1) {
                        this.showPartPrice(index, { part: { id: this.$route.query.part_id } });
                    }
                });
            }
        },

        handleSchemeImageLoad() {
            this.schemeImageLoad = true;
            this.originalImageWidth = this.$refs.image.naturalWidth;
            this.originalImageHeight = this.$refs.image.naturalHeight;
            this.calcPartsOnSchemeStyles();
            this.$refs.image.addEventListener('touchstart', this.onSchemeImageTouchStart);
            this.$refs.image.addEventListener('touchmove', this.onSchemeImageTouchMove);
            this.$refs.image.addEventListener('touchend', this.onSchemeImageTouchEnd);
        },

        updateImageZoom(value) {
            this.schemeZoomValue = value;
            let styles = {};
            if (value === 0) {
                styles = {
                    'max-width': '100%',
                    'max-height': '100%',
                    'top': null,
                    'left': null,
                    'width': 'auto',
                    'height': 'auto',
                };
            }
            else {
                styles = {
                    'max-width': 'initial',
                    'max-height': 'initial',
                };

                const imageWrapRect = this.$refs.imageInnerWrap.getBoundingClientRect();
                const imageRect = this.$refs.image.getBoundingClientRect();
                const widthDiff = this.originalImageWidth - this.$refs.imageDefault.offsetWidth;
                const widthValue = widthDiff * value / 100 + this.$refs.imageDefault.offsetWidth;
                styles.width = widthValue + 'px';

                const heightDiff = this.originalImageHeight - this.$refs.imageDefault.offsetHeight;
                const heightValue = heightDiff * value / 100 + this.$refs.imageDefault.offsetHeight;
                styles.height = heightValue + 'px';

                let newTopValue = (imageRect.height - heightValue) / 2 + this.$refs.image.offsetTop;
                if (heightValue > imageWrapRect.height) {
                    if (newTopValue > 0) {
                        newTopValue = 0;
                    }
                    else if (Math.abs(newTopValue) > heightValue - imageWrapRect.height) {
                        newTopValue = imageWrapRect.height - heightValue;
                    }
                }
                else {
                    if (newTopValue < 0) {
                        newTopValue = 0;
                    }
                    else if (newTopValue + heightValue > imageWrapRect.height) {
                        newTopValue = imageWrapRect.height - heightValue;
                    }
                }
                styles.top = newTopValue + 'px';

                let newLeftValue = (imageRect.width - widthValue) / 2 + this.$refs.image.offsetLeft;
                if (widthValue > imageWrapRect.width) {
                    if (newLeftValue > 0) {
                        newLeftValue = 0;
                    }
                    else if (Math.abs(newLeftValue) > widthValue - imageWrapRect.width) {
                        newLeftValue = imageWrapRect.width - widthValue;
                    }
                }
                else {
                    if (newLeftValue < 0) {
                        newLeftValue = 0;
                    }
                    else if (newLeftValue + widthValue > imageWrapRect.width) {
                        newLeftValue = imageWrapRect.width - widthValue;
                    }
                }
                styles.left = newLeftValue + 'px';
            }

            this.schemeImageStyles = styles;
            setTimeout(() => {
                this.calcPartsOnSchemeStyles();
            });
        },

        async getSchemeData(schemeId) {
            const params = {
                limit: 100,
                offset: 0,
                order_by: 'name',
            };

            try {
                return await this.$api.catalogSchemes.get(schemeId, { params });
            }
            catch (error) {
                this.hidePopup();
            }
        },

        async getSchemeParts() {
            const params = {
                car_id: this.selectedCar.id,
                scheme_id: this.schemeData.id,
                limit: 100,
                offset: 0,
                order_by: 'number',
            };
            if (this.productionDate) params.production_date = this.productionDate;

            return await this.$api.catalogSchemeParts.get({ params });
        },

        showPartPrice(index, itemPart) {
            const partId = (itemPart.part || {}).id || '';
            const groupId = (itemPart.group || {}).id || '';
            if (partId) {
                if (index === this.openedNode) {
                    this.openedNode = null;
                }
                else {
                    if (window.innerWidth < 1040) {
                        this.view = 'list';
                    }

                    this.openedNode = index;
                    this.scrollList(index);
                    this.partPrices = [];
                    this.subPartsLoaded = false;
                    const params = {
                        manufacturer: this.selectedCar.manufacturer.codename,
                        car_id: this.selectedCar.id,
                        part_id: partId,
                        limit: 100,
                        offset: 0,
                        order_by: '-production_start_date',
                    };

                    if (this.productionDate) params.production_date = this.productionDate;

                    this.$api.catalogPartCodes.get({ params })
                        .then(response => {
                            this.partPrices = response.results;
                            this.partPricesCount = response.current_count;
                            this.subPartsLoaded = true;
                        });
                }
            }
            if (groupId) {
                this.hidePopup();
                this.callback(itemPart.group);
            }
        },

        hidePopup() {
            if (this.$route.query.s) {
                const location = {
                    name: 'market-parts-catalogs-manufacturer-modification',
                    params: {
                        manufacturer: this.$route.params.manufacturer,
                        modification: this.$route.params.modification,
                        group: this.$route.params.group,
                    },
                    query: {},
                };

                if (this.$route.query.production_date) {
                    location.query.production_date = this.$route.query.production_date;
                }

                this.$router.replace(location);
            }
            this.close();
        },

        calcPartsOnSchemeStyles() {
            if (this.schemeImageLoad) {
                this.partsOnSchemeStyles = [];
                const imageScale = this.$refs.image.offsetWidth / this.originalImageWidth;
                const imageInnerWrapRect = this.$refs.imageInnerWrap.getBoundingClientRect();
                const imageRect = this.$refs.image.getBoundingClientRect();
                const shiftX = imageRect.left - imageInnerWrapRect.left;
                const shiftY = imageRect.top - imageInnerWrapRect.top;

                this.parts.forEach((part, partIndex) => {
                    this.partsOnSchemeStyles[partIndex] = [];
                    part.coordinates.forEach((coordinates) => {
                        this.partsOnSchemeStyles[partIndex].push({
                            position: {
                                top: Math.round(coordinates.y1 * imageScale)  + shiftY + 'px',
                                left: Math.round(coordinates.x1 * imageScale) + shiftX + 'px',
                            },
                            size: {
                                width: Math.round((coordinates.x2 - coordinates.x1) * imageScale) + 'px',
                                height: Math.round((coordinates.y2 - coordinates.y1) * imageScale) + 'px',
                            },
                        });
                    });
                });
            }
        },

        move(shiftX, shiftY, e) {
            const image = this.$refs.image;
            const imageWrapRect = this.$refs.imageInnerWrap.getBoundingClientRect();
            const imageRect = image.getBoundingClientRect();
            let xValue = e.pageX - imageWrapRect.left - shiftX;
            let yValue = e.pageY - imageWrapRect.top - shiftY;

            if (imageRect.width > imageWrapRect.width) {
                if (xValue > 0) {
                    xValue = 0;
                }
                else if (Math.abs(xValue) > imageRect.width - imageWrapRect.width) {
                    xValue = imageWrapRect.width - imageRect.width;
                }
            }
            else {
                if (xValue < 0) {
                    xValue = 0;
                }
                else if (xValue + imageRect.width > imageWrapRect.width) {
                    xValue = imageWrapRect.width - imageRect.width;
                }
            }

            if (imageRect.height > imageWrapRect.height) {
                if (yValue > 0) {
                    yValue = 0;
                }
                else if (Math.abs(yValue) > imageRect.height - imageWrapRect.height) {
                    yValue = imageWrapRect.height - imageRect.height;
                }
            }
            else {
                if (yValue < 0) {
                    yValue = 0;
                }
                else if (yValue + imageRect.height > imageWrapRect.height) {
                    yValue = imageWrapRect.height - imageRect.height;
                }
            }

            image.style.left = xValue + 'px';
            image.style.top = yValue + 'px';
        },

        onSchemeImageMouseDown($event) {
            if (this.schemeZoomValue > 0) {
                this.moving = true;
                const image = this.$refs.image;
                const imageRect = image.getBoundingClientRect();

                const shiftX = $event.pageX - imageRect.left;
                const shiftY = $event.pageY - imageRect.top;

                const t = e => throttle(this.move(shiftX, shiftY, e), 10);

                document.addEventListener('mousemove', t);

                document.addEventListener('mouseup', () => {
                    document.removeEventListener('mousemove', t);
                    this.calcPartsOnSchemeStyles();
                    this.moving = false;
                }, { once: true });

                image.ondragstart = function () {
                    return false;
                };
            }
        },

        onSchemeImageTouchStart($event) {
            if (this.schemeZoomValue > 0) {
                this.moving = true;
                const image = this.$refs.image;
                const imageRect = image.getBoundingClientRect();

                const touch = $event.touches[0] || $event.changedTouches[0];
                shiftX = touch.pageX - imageRect.left;
                shiftY = touch.pageY - imageRect.top;

                // image.ondragstart = function () {
                //     return false;
                // };
            }
        },

        onSchemeImageTouchMove($event) {
            const touch = $event.touches[0] || $event.changedTouches[0];
            throttle(this.move(shiftX, shiftY, touch), 10);
        },

        onSchemeImageTouchEnd() {
            this.calcPartsOnSchemeStyles();
            this.moving = false;
        },

        scrollList(index) {
            const list = this.$refs.partsListDesktop;
            const item = this.$refs['part-item-' + index][0];

            if (list && item && list.scrollHeight > list.clientHeight) {
                let scrollBottom = list.clientHeight + list.scrollTop;
                let itemBottom = item.offsetTop + item.offsetHeight;

                if (itemBottom > scrollBottom || item.offsetTop < list.scrollTop) {
                    list.scrollTop = item.offsetTop;
                }
            }

            this.hoveredNode = index;
        },

        showOnScheme(index) {
            this.view = 'scheme';
            this.hoveredNode = index;
            this.openedNode = index;
        },

        handleResize() {
            this.calcPartsOnSchemeStyles();

            if (window.innerWidth >= 1040) {
                this.view = 'scheme';
            }

            this.schemeImageStyles = {
                'max-width': '100%',
                'max-height': '100%',
                'width': 'auto',
                'height': 'auto',
            };

            this.calcHeight();
        },

        calcHeight() {
            if (window.innerWidth >= 1040) {
                this.$refs.content.style.height = null;
                this.$refs.partsListDesktop.style.height = null;
            }
            else if (window.innerWidth >= 568) {
                this.$refs.partsListDesktop.style.height = null;
                this.$refs.content.style.height = this.$refs.imageWrap.offsetHeight + 'px';
            }
            else {
                this.$refs.content.style.height = null;
                this.$refs.partsListDesktop.style.height = window.innerHeight
                    - this.$refs.popupHead.offsetHeight + 'px';
            }
        },

        async changeGroup(group, index) {
            if (group && group.id) {
                await this.hidePopup();
                this.callback(group);
            }
            else {
                this.showOnScheme(index);
            }
        },

        handleSchemePartClick(index, item) {
            this.scrollList(index);
            this.showPartPrice(index, item);
        },
    },
};
</script>

<style scoped>
@media (min-width: 1281px) {
    .popup-content {
        width: calc(var(--col-18) + 96px);
        max-height: calc(100vh - 48px);
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .popup-content {
        width: calc(var(--col-11) + 96px);
        max-height: calc(100vh - 48px);
    }
}
@media (min-width: 1040px) {
    .popup-content {
        display: flex;
        flex-direction: column;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.head {
    padding-top: 36px;
    padding-left: var(--popup-paddings-x);
    padding-right: var(--popup-paddings-x);
}
@media (min-width: 1040px) {
    .head {
        padding-bottom: 36px;
        box-shadow: var(--base-shadow);
    }
}
@media (max-width: 1039px) {
    .head {
        border-bottom: 1px solid transparent;
    }
    .head_border {
        border-bottom-color: var(--border-light-c);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .head {
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .head {
        padding-bottom: 20px;
    }
}

.subtitle {
    font-size: var(--adaptive-big-fz);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}
@media (min-width: 768px) {
    .subtitle {
        margin-top: 8px;
    }
}
@media (max-width: 767px) {
    .subtitle {
        margin-top: 4px;
    }
}

.content {
    position: relative;
}
@media (min-width: 1281px) {
    .content {
        grid-template-columns: calc(var(--col-6) + 20px) auto;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .content {
        grid-template-columns: calc(var(--col-4) + 20px) auto;
    }
}
@media (min-width: 1040px) {
    .content {
        display: grid;
        overflow: hidden;
    }
}

.scheme-wrap {
    position: relative;
    flex-shrink: 0;
}
@media (min-width: 1040px) {
    .scheme-wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        padding: 20px var(--popup-paddings-x) 20px 0;
    }
}

.scheme-inner-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius);
    overflow: hidden;
}
@media (min-width: 1281px) {
    .scheme-inner-wrap {
        min-height: 680px;
    }
}
@media (min-width: 1040px) and (max-width: 1280px) {
    .scheme-inner-wrap {
        min-height: 500px;
    }
}
@media (min-width: 1040px) {
    .scheme-inner-wrap {
        flex-grow: 1;
    }
}
@media (max-width: 1039px) {
    .scheme-wrap_hidden {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }

    .scheme-wrap {
        padding-left: var(--popup-paddings-x);
        padding-right: var(--popup-paddings-x);
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .scheme-wrap {
        padding-bottom: 24px;
    }
}
@media (max-width: 767px) {
    .scheme-wrap {
        padding-bottom: 20px;
    }
}

.scheme-img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.scheme-img_default {
    opacity: 0;
}
.scheme-img_zoom {
    position: absolute;
    max-height: 100%;
}
@media (min-width: 1040px) {
    .scheme-img_default {
        max-height: 100%;
    }
}
@media (max-width: 1039px) {
    .scheme-img_default {
        max-height: 515px;
    }
}
.scheme-img_zoomer {
    cursor: move;
}

.scheme-node-point-wrap {
    position: absolute;
    z-index: 3;
}
.scheme-node-point-wrap_clickable {
    cursor: pointer;
}
.scheme-node-point-wrap_moving {
    opacity: 0;
}

.scheme-node-point {
    display: block;
    border: 2px solid transparent;
    border-radius: 1px;
    transition: border-color var(--transition);
}

.scheme-node-point_hover,
.scheme-node-point:hover {
    border-color: var(--primary-color);
}

.parts-list {
    position: relative;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
}
@media (min-width: 1040px) {
    .parts-list {
        padding: 20px 20px 20px var(--popup-paddings-x);
    }
}
@media (max-width: 1039px) {
    .parts-list {
        padding: 20px var(--popup-paddings-x);
    }

    .parts-list_hidden {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }
}

.subpart:not(:first-child),
.part:not(:first-child) .part-btn {
    padding-top: 12px;
}

.subpart:not(:last-child),
.part-btn {
    padding-bottom: 12px;
}

.subpart:not(:last-child),
.part:not(:last-child) {
    border-bottom: 1px solid var(--border-light-c);
}

.part-btn {
    display: block;
    width: 100%;
    text-align: left;
}

.part-btn_disabled {
    cursor: default;
}

.part__id {
    display: block;
    font-size: 12px;
    color: var(--font-secondary-color);
}

.part__name {
    display: inline-block;
    font-size: var(--base-fz);
    font-family: var(--f-bold);
    flex-grow: 1;
    transition: color var(--transition);
}

.part__name_hover,
.part-btn:hover .part__name {
    color: var(--primary-color);
}
.part-btn:hover .part__external-icon {
    fill: var(--primary-color);
}

.part__arrow {
    transition: transform var(--transition);
}

.part__arrow_rotate {
    transform: rotate(180deg);
}

.part__modifications-list {
    margin-top: 4px;
    margin-bottom: 12px;
}

.part__modifications-list_spinner {
    margin-bottom: 12px;
}

.subpart__main-wrap {
    display: flex;
    align-items: flex-start;
}

.subpart__main {
    flex-grow: 1;
    font-size: var(--small-fz);
}

.subpart__hint {
    margin-left: 8px;
    flex-shrink: 288;
    font-size: 0;
    vertical-align: -4px;
}

.subpart__amount-hint-wrap {
    display: inline-block;
}


@media (min-width: 768px) {
    .attrs-table {
        width: 376px;
    }

    .attrs-table__row {
        display: flex;
        align-items: baseline;
    }

    .attrs-table__row:not(:last-child) {
        margin-bottom: 8px;
    }

    .attrs-table__row::after {
        content: "";
        min-width: 8px;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 5px;
        order: 2;
        flex-grow: 1;
        border-bottom: 1px dotted var(--border-dark-c);
    }
}
@media (max-width: 767px) {
    .attrs-table__row:not(:last-child) {
        margin-bottom: 16px;
    }
}

.attrs-table__td:first-child {
    order: 1;
    color: var(--font-secondary-color);
}

.attrs-table__td:last-child {
    order: 3;
}
@media (min-width: 768px) {
    .attrs-table__td:last-child {
        width: 128px;
        flex-shrink: 0;
    }
}
@media (max-width: 767px) {
    .attrs-table__td:last-child {
        margin-top: 4px;
    }
}

.subpart__btn {
    margin-left: 12px;
    flex-shrink: 0;
}

.subpart__description {
    margin-top: 4px;
    font-size: var(--small-fz);
    word-break: break-word;
}

.part__modifications-list_empty {
    margin-top: 4px;
    margin-bottom: 16px;
    font-size: var(--small-fz);
}

@media (min-width: 1040px) {
    .subpart__on-scheme-btn {
        display: none;
    }
}

.view-toggler {
    margin-top: 20px;
}
@media (min-width: 1040px) {
    .view-toggler {
        display: none;
    }
}

.scheme-zoom-control {
    margin: 24px auto 0;
}
</style>