var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-icon-round__wrap",class:{
        'u-icon-round__wrap_micro': _vm.micro,
        'u-icon-round__wrap_mini': _vm.mini,
        'u-icon-round__wrap_small': _vm.small,
        'u-icon-round__wrap_base': _vm.base,
        'u-icon-round__wrap_big': _vm.big,
        'u-icon-round__wrap_primary': _vm.primary,
    }},[_vm._t("icon",function(){return [_c('UIcon',_vm._b({staticClass:"u-icon-round__icon",attrs:{"small":_vm.micro,"big":_vm.small,"extra":_vm.big}},'UIcon',_vm.$attrs,false))]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }