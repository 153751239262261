<template>
    <AfterPaymentContent
        status="success"
    ></AfterPaymentContent>
</template>

<script>
import AfterPaymentContent from '@/components/AfterPaymentContent.vue';

export default {
    name: 'PaymentSuccess',

    components: { AfterPaymentContent },
};
</script>