import { licensePlate } from '@/components/_form/utils/regs.js';


export default () => {
    return {
        formData: {
            vehicle_license_plate: '',
            vehicle_id_type_code: 'vin',
            vehicle_id_number: '',
            vehicle_manufacturer: null,
            vehicle_model: null,
            vehicle_year: null,
            vehicle_engine_power: null,
            vehicle_doc_type_code: 'STS',
            vehicle_doc_number: '',
            vehicle_doc_date: null,
            // diagnostic_card_number: '',
            // diagnostic_card_exp: null,
        },

        vehicle_license_plate: {
            loading: false,
            re: licensePlate,
            showHint: false,
            failedGetInfo: false,
            failedNumber: '',
        },

        vehicle_id_number: {
            labels: {
                vin: 'VIN',
                bodyNumber: 'Номер кузова',
                chassisNumber: 'Номер шасси',
            },
            loading: false,
            vin: '',
            bodyNumber: '',
            chassisNumber: '',
        },

        vehicle_model: {
            loading: false,
        },

        vehicle_doc_number: {
            labels: {
                STS: 'Серия и номер СТС',
                PTS: 'Серия и номер ПТС',
                EPTS: 'Серия и номер ЕПТС',
            },
            masks: {
                STS: '00RR 000000',
                PTS: '00RR 000000',
                EPTS: '000000000000000',
            },
            re: {
                STS: /(\d{2})([0-9А-ЯЁ]{2}\s(\d{6}))/,
                PTS: /(\d{2})([0-9А-ЯЁ]{2}\s(\d{6}))/,
                EPTS: /(\d{15})/,
            },
            STS: '',
            PTS: '',
            EPTS: '',
        },

        vehicle_doc_date: {
            labels: {
                STS: 'Дата выдачи СТС',
                PTS: 'Дата выдачи ПТС',
                EPTS: 'Дата выдачи ЕПТС',
            },
            STS: null,
            PTS: null,
            EPTS: null,
        },

        // todo: серверное время
        maxVehicleDocDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        // maxDiagnosticCardExpDate: new Date(new Date().getFullYear() + 2, new Date().getMonth(), new Date().getDate()),

        getVehicleInfoSource: null,
        // getDiagnosticCardInfoSource: null,

        keys: [
            'vehicle_license_plate',
            'vehicle_id_type_code',
            'vehicle_id_number',
            'vehicle_manufacturer',
            'vehicle_model',
            'vehicle_year',
            'vehicle_engine_power',
            'vehicle_doc_type_code',
            'vehicle_doc_number',
            'vehicle_doc_date',
            // 'diagnostic_card_number',
            // 'diagnostic_card_exp',
        ],
    };
};