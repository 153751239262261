<template>
    <ChatTemplate>
        <template #header>
            <slot name="header">
                <slot name="search-input">
                    <div class="chat-list__search">
                        <ChatSearchInput v-model="innerSearchValue"></ChatSearchInput>
                    </div>
                </slot>
            </slot>
        </template>

        <template #body>
            <div class="chat-list__body">
                <div
                    ref="overflow"
                    class="chat-list__overflow-box"
                >
                    <div class="chat-list__scroll-box">
                        <div
                            v-if="searchShow"
                            class="chat-list__search-wrap"
                        >
                            <slot
                                v-if="!searchLoading
                                    && !(searchChats || {}).length
                                    && !(searchMessages || {}).length"
                                name="search-placeholder"
                            >
                                <div class="chat-list__search-placeholder-wrap">
                                    <div class="chat-list__search-placeholder-content">
                                        <slot name="search-placeholder-icon">
                                            <UIconRound
                                                name="search-24"
                                                big
                                                class="chat-list__search-placeholder-icon"
                                            ></UIconRound>
                                        </slot>

                                        <slot name="search-placeholder-text">
                                            <p class="chat-list__search-placeholder-text">
                                                Ничего не найдено, попробуйте изменить условия поиска.
                                            </p>
                                        </slot>
                                    </div>
                                </div>
                            </slot>

                            <div
                                v-else
                                class="chat-list__search-results"
                            >
                                <div
                                    v-if="searchChats && searchChats.length"
                                    class="chat-list__search-chats"
                                >
                                    <div class="chat-list__search-title">
                                        Чаты
                                    </div>
                                    <div
                                        v-for="item in searchChats"
                                        :key="'search-chat-' + item.uuid"
                                        class="chat-list__item"
                                        :class="{
                                            'chat-list__item_active': value && value.uuid === item.uuid,
                                        }"
                                        @click="select($event, item)"
                                    >
                                        <div class="chat-list__item-avatar-wrap">
                                            <ChatListItemAvatar
                                                :src="item.avatar"
                                                :online="item.online"
                                                :name="item.full_name"
                                                class="chat-list__item-avatar"
                                            ></ChatListItemAvatar>
                                        </div>

                                        <div
                                            class="chat-list__item-main-block"
                                            :class="{
                                                'chat-list__item-main-block_center': !item.description,
                                            }"
                                        >
                                            <div class="chat-list__item-name-block">
                                                <div class="chat-list__item-name-wrap">
                                                    <UHighlight
                                                        :text="item.name"
                                                        :queries="[
                                                            {
                                                                value: item.search_value,
                                                                name: 'search',
                                                            }
                                                        ]"
                                                        class="chat-list__item-name class-list__highlight"
                                                    ></UHighlight>
                                                </div>

                                                <UCircleLabelCounter
                                                    :count="item.unread_count"
                                                    class="chat-list__item-unread-count-block"
                                                ></UCircleLabelCounter>
                                            </div>

                                            <div
                                                v-if="item.description"
                                                class="chat-list__item-description-block"
                                            >
                                                <div class="chat-list__item-description-wrap">
                                                    {{ item.description }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="searchMessages && searchMessages.length"
                                    class="chat-list__search-chats"
                                >
                                    <div class="chat-list__search-title">
                                        Сообщения
                                    </div>

                                    <div
                                        v-for="item in searchMessages"
                                        :key="'search-message-' + item.search_message.id"
                                        class="chat-list__item"
                                        @click="select($event, item, item.search_message)"
                                    >
                                        <div class="chat-list__item-avatar-wrap">
                                            <ChatListItemAvatar
                                                :src="item.avatar"
                                                :online="item.online"
                                                :name="item.full_name"
                                                class="chat-list__item-avatar"
                                            ></ChatListItemAvatar>
                                        </div>

                                        <div class="chat-list__item-main-block">
                                            <div class="chat-list__item-name-block">
                                                <div class="chat-list__item-name-wrap">
                                                    <span class="chat-list__item-name">{{ item.name }}</span>
                                                </div>
                                                <div class="chat-list__item-timestamp-wrap">
                                                    <span
                                                        class="chat-list__item-timestamp"
                                                    >{{ item.search_message.create_date | timestamp }}</span>
                                                </div>
                                            </div>

                                            <div class="chat-list__item-message-block">
                                                <div class="chat-list__item-message-wrap">
                                                    <span
                                                        v-if="item.search_message && own(item.search_message)"
                                                        class="chat-page__item-you"
                                                    >Вы:{{ '\u00A0' }}</span><UHighlight
                                                        :text="item.search_message.text"
                                                        :queries="[
                                                            {
                                                                value: item.search_message.search_value,
                                                                name: 'search',
                                                            }
                                                        ]"
                                                        class="class-list__highlight class-list__highlight_ellipsis"
                                                    ></UHighlight>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            v-else
                            class="chat-list__items-wrap"
                        >
                            <div
                                v-for="item in items"
                                :key="item.uuid"
                                class="chat-list__item"
                                :class="{
                                    'chat-list__item_active': value === item,
                                }"
                                @click="select($event, item)"
                            >
                                <div class="chat-list__item-avatar-wrap">
                                    <ChatListItemAvatar
                                        :src="item.avatar"
                                        :online="item.online"
                                        :name="item.full_name"
                                        class="chat-list__item-avatar"
                                    ></ChatListItemAvatar>
                                </div>

                                <div class="chat-list__item-main-block">
                                    <div class="chat-list__item-name-block">
                                        <div class="chat-list__item-name-wrap">
                                            <span class="chat-list__item-name">{{ item.name }}</span>
                                        </div>
                                        <div class="chat-list__item-timestamp-wrap">
                                            <span
                                                v-if="item.last_message"
                                                class="chat-list__item-timestamp"
                                            >{{ item.last_message.create_date | timestamp }}</span>
                                        </div>
                                    </div>

                                    <div class="chat-list__item-message-block">
                                        <div class="chat-list__item-content-block">
                                            <div class="chat-list__item-message-wrap">
                                                <UDots
                                                    v-if="isTyping(item, user)"
                                                    class="chat-page__item-typing"
                                                >
                                                    Печатает
                                                </UDots>

                                                <template v-else>
                                                    <span
                                                        v-if="item.last_message && own(item.last_message)"
                                                        class="chat-page__item-you"
                                                    >Вы:{{ '\u00A0' }}</span><ChatMessagePreview
                                                        v-if="item.last_message"
                                                        :message="item.last_message"
                                                        class="chat-list__chat-message-preview"
                                                    ></ChatMessagePreview>
                                                </template>
                                            </div>

                                            <div
                                                v-if="item.description"
                                                class="chat-list__item-description-wrap"
                                            >
                                                {{ item.description }}
                                            </div>
                                        </div>

                                        <UCircleLabelCounter
                                            :count="item.unread_count"
                                            class="chat-list__item-unread-count-block"
                                        ></UCircleLabelCounter>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="searchLoading"
                            class="chat-list__search-preloader"
                        >
                            <USpinner big></USpinner>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ChatTemplate>
</template>

<script>
// utils
import isValidDate from '@ui/utils/isValidDate.js';
import pad from '@ui/utils/pad.js';
// components
import ChatTemplate from '@/components/_chat/ChatTemplate.vue';
import ChatSearchInput from '@/components/_chat/ChatSearchInput.vue';
import ChatListItemAvatar from '@/components/_chat/ChatListItemAvatar.vue';
import UDots from '@ui/components/UDots/UDots.vue';
import ChatMessagePreview from '@/components/_chat/ChatMessagePreview.vue';
import UIconRound from '@ui/components/UIcon/UIconRound.vue';
import USpinner from '@ui/components/USpinner/USpinner.vue';
import UHighlight from '@ui/components/UHighLight/UHighlight.vue';
import UCircleLabelCounter from '@ui/components/UBadge/UBadge.vue';


export default {
    name: 'ChatList',

    components: {
        UCircleLabelCounter,
        UHighlight,
        USpinner,
        UIconRound,
        ChatMessagePreview,
        UDots,
        ChatListItemAvatar,
        ChatSearchInput,
        ChatTemplate,
    },

    filters: {
        timestamp(value) {
            if (!value) return '';

            const now = new Date();
            const date = new Date(value);
            const isValid = isValidDate(date);

            if (!isValid) return '';

            const today = now.getUTCFullYear() === date.getUTCFullYear()
                && now.getUTCMonth() === date.getUTCMonth()
                && now.getUTCDate() === date.getUTCDate();

            if (today) {
                return `${ pad(date.getHours()) }:${ pad(date.getMinutes()) }`;
            }

            return `${ pad(date.getDate()) }.${ pad(date.getMonth() + 1) }.${ date.getFullYear().toString().substring(2) }`;
        },
    },

    props: {
        user: Object,
        items: Array,
        value: Object,
        searchShow: Boolean,
        searchChats: Array,
        searchLoading: Boolean,
        searchMessages: Array,

        own: {
            type: Function,
            default(message) {
                if (!this.user) return false;
                return ((message || {}).sender || {}).id === this.user.id;
            },
        },

        foreign: {
            type: Function,
            default(message) {
                if (!this.user) return false;
                return ((message || {}).sender || {}).id !== this.user.id;
            },
        },

        isTyping: {
            type: Function,
            default(chat, user) {
                return chat.typing_users.find(u => u && user &&  u.id !== user.id);
            },
        },
    },

    data() {
        return {
            innerSearchValue: '',
        };
    },

    watch: {
        innerSearchValue: {
            handler() {
                this.emitChangeSearchValue();
            },
        },
    },

    methods: {
        select(nativeEvent, value, message) {
            const target = this;
            this.$emit('select', { target, value, message, nativeEvent });
        },

        emitChangeSearchValue() {
            const target = this;
            const value = this.innerSearchValue;
            this.$emit('changeSearchValue', { value, target });
        },
    },
};
</script>

<style>
.chat-list__search {
    padding: 12px 24px;
    width: 100%;
}

.chat-list__body {
    position: relative;
    width: 100%;
    height: 100%;
}

.chat-list__overflow-box {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.chat-list__scroll-box {

}

.chat-list__search-placeholder-content {
    margin-top: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-list__search-placeholder-text {
    margin-top: 36px;
    width: 280px;
    text-align: center;
}

.chat-list__item {
    padding: 16px 24px;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid var(--border-light-c);
    transition: background-color var(--transition);
}

.chat-list__item:hover,
.chat-list__item:focus {
    background-color: var(--bright-bg);
}

.chat-list__item:active,
.chat-list__item.chat-list__item_active {
    background-color: var(--lightest-bg);
}

.chat-list__item-avatar-wrap {
    flex-shrink: 0;
    margin-right: 12px;
}

.chat-list__item-avatar .chat-list-item-avatar__image {
    user-select:none;
}

.chat-list__item-main-block {
    flex-grow: 1;
    overflow: hidden;
}

.chat-list__item-main-block_center {
    align-self: center;
}

.chat-list__item-name-block {
    display: flex;
    align-items: center;
}

.chat-list__item-name-block:not(:last-child) {
    margin-bottom: 4px;
}

.chat-list__item-name-wrap {
    flex-grow: 1;
    margin-right: 12px;
}

.chat-list__item-name {
    font-family: var(--f-bold);
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}

.chat-list__item-timestamp-wrap {
    flex-shrink: 0;
    align-self: flex-start;
    text-align: right;
}

.chat-list__item-timestamp {
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
    line-height: 1.4;
}

.chat-list__item-message-block {
    display: flex;
    min-width: 0;
}

.chat-list__item-content-block {
    flex-grow: 1;
    min-width: 0;
}

.chat-list__item-content-block:not(:last-child) {
    margin-right: 12px;
}

.chat-list__item-message-wrap {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.chat-list__item-message-wrap:not(:last-child) {
    margin-bottom: 4px;
}

.chat-page__item-typing {
    font-style: italic;
    color: var(--font-secondary-color);
}

.chat-page__item-you {
    color: var(--font-secondary-color);
    flex-grow: 0;
    flex-shrink: 0;
}

.chat-list__chat-message-preview .chat-message-preview__payload {
    color: var(--dark-c);
}

.chat-list__item-description-block {
    display: flex;
}

.chat-list__item-description-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--font-secondary-color);
    font-size: var(--small-fz);
    line-height: var(--small-lh);
}

.chat-list__search-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-c);
}

.chat-list__search-title {
    font-family: var(--f-semi-bold);
    font-size: var(--small-fz);
    line-height: 1.33;
    color: var(--font-secondary-color);
    margin: 16px 24px 0;
}

.class-list__highlight_ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.class-list__highlight .u-highlight__search {
    color: var(--primary-color);
    font-family: var(--f-semi-bold);
}
</style>