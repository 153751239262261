import genId from '@ui/utils/genId.js';

export default {
    namespaced: true,

    state: () => ({
        error:  [
            // {
            //     id: null,
            //     code: '',
            //     message: '',
            // },
        ],

        success: [
            // {
            //     id: null,
            //     messages: [],
            // },
        ],

        // maxItems - 3
        queue: [],
    }),

    getters: {
    },

    mutations: {
        setError(state, { code, message }) {
            const errorObject = {
                id: genId(),
                code,
                message,
            };
            state.error.push(errorObject);
            state.queue.splice(-1, 1, errorObject);
        },

        removeItem(state, index) {
            state.queue.splice(index, 1);
        },
    },
};