<template>
    <div>
        <div class="info-page-layout">
            <InfoPageLayoutDesktopMenu :menu="filteredMenu"></InfoPageLayoutDesktopMenu>

            <transition-group
                :key="$route.path"
                name="fade-page"
                mode="out-in"
                appear
                class="info-page-layout__content"
            >
                <Spinner
                    v-if="loading"
                    key="loading"
                    size="big"
                    class="info-page-layout__loader"
                ></Spinner>
                <RouterView
                    v-if="!loading"
                    key="page"
                ></RouterView>
            </transition-group>
        </div>

        <PortalTarget name="infoPageLayoutAfter"></PortalTarget>

        <ChatLayer></ChatLayer>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import InfoPageLayoutDesktopMenu from '../components/InfoPageLayoutDesktopMenu.vue';
import ChatLayer from '@/components/chat/ChatLayer.vue';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'InfoPageLayout',

    components: { Spinner, ChatLayer, InfoPageLayoutDesktopMenu },

    metaInfo() {
        return {
            title: this.info.meta_title || this.info.title,
            meta: [
                { name: 'description', content: this.info.meta_description },
                { name: 'keywords', content: this.info.meta_keywords },
                { property: 'og:title', content: this.info.meta_title ? this.info.meta_title : this.info.title },
                { property: 'og:description', content: this.info.meta_description },
                { property: 'og:image', content: this.info.og_image ? this.$links.uploads + this.info.og_image.thumbnails.og_image_default : '' },
            ],
        };
    },

    serverPrefetch() {
        const codename = this.$route.name;
        if (
            codename !== 'help-pp-article' &&
            codename !== 'help-mp-article' &&
            codename !== 'news-item' &&
            codename !== 'additional-page'
        ) {
            return this.INIT({ codename });
        }
    },

    data() {
        return {
            loading: true,
        };
    },

    computed: {
        ...mapState({
            info: state => state.staticPages.data,
        }),

        menu() {
            return this.$store.state.settings.info_menu || [];
        },

        filteredMenu() {
            return this.menu.map(section => {
                const { pages, ...args } = section;
                const filteredPages = pages.filter(page => page.is_active);

                if (filteredPages.length) {
                    return { ...args, pages: filteredPages };
                }
            }).filter(_ => _);
        },
    },

    watch: {
        '$route.params.codename': {
            handler() {
                this.DESTROY();
                this.init();
            },
        },

        '$route.name': {
            handler() {
                this.DESTROY();
                this.init();
            },
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        ...mapMutations({
            DESTROY: 'staticPages/destroy',
        }),

        ...mapActions({
            INIT: 'staticPages/init',
        }),

        async init() {
            const codename = this.$route.name;
            if (
                codename !== 'help-pp-article' &&
                codename !== 'help-mp-article' &&
                codename !== 'news-item' &&
                codename !== 'additional-page'
            ) {
                this.loading = true;
                await this.INIT({ codename });
                this.setBreadcrumbs();
                this.loading = false;
            }
            else {
                this.loading = false;
            }
        },

        setBreadcrumbs() {
            this.$breadcrumbs([
                {
                    to: this.$route,
                    title: this.info.title,
                },
            ]);
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .info-page-layout {
        display: flex;
        margin-top: var(--gap-y-medium);
    }
}
@media (max-width: 1039px) {
    .info-page-layout {
        margin-top: 24px;
    }
}

.info-page-layout__content {
    position: relative;
    flex-grow: 1;
}

@media (min-width: 1040px) {
    .info-page-layout__loader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (max-width: 1039px) {
    .info-page-layout__loader {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
