var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UTooltip',{ref:"tooltip",staticClass:"shop-tooltip-wrap ml-8",attrs:{"noDetach":"","showOnClick":"","maxWidth":"380"},on:{"open":_vm.init},scopedSlots:_vm._u([{key:"cloud",fn:function(ref){
var shift = ref.shift;
var placement = ref.placement;
return [_c('UTooltipCloud',{staticClass:"tooltip-cloud",attrs:{"shift":shift,"placement":placement,"color":"white"}},[_c('div',{staticClass:"shop-tooltip"},[_c('ShopAddress',{attrs:{"shop":_vm.shop,"showOnMap":false}}),_vm._v(" "),_c('ShopOpenCloseTime',{staticClass:"mt-4",attrs:{"shopId":_vm.shop.id,"status":_vm.shop.current_work,"withoutTooltip":""}}),_vm._v(" "),_c('MarketItemRatingReviewsLink',{staticClass:"mt-16",attrs:{"item":_vm.shop,"modelName":"shop"}}),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('LikeBtn',{staticClass:"actions__btn",attrs:{"id":_vm.shop.id,"value":_vm.shop.is_favorite,"apiName":"shop","withText":""}}),_vm._v(" "),_c('ChatBtn',{staticClass:"actions__btn",attrs:{"id":_vm.shop.id,"isOnline":_vm.shop.current_work.place.is_active,"disabled":!_vm.shop.is_chat_active,"withText":"","btnTextAlwaysVisible":""}})],1),_vm._v(" "),_c('TransitionExpand',[(_vm.shopSchedule)?_c('ShopWorkSchedule',{key:"schedule",staticClass:"mt-16",attrs:{"work":_vm.shopSchedule.work_times,"holidays":_vm.shopSchedule.holidays}}):_vm._e()],1),_vm._v(" "),_c('PointOnMapPreviewBtn',{staticClass:"mt-16",attrs:{"coords":_vm.shop.coordinates},on:{"click":_vm.openMap}}),_vm._v(" "),_c('ButtonBlock',{staticClass:"mt-16",attrs:{"to":{
                        name: 'market-shop-stock',
                        params: {
                            codename: _vm.shop.company.codename,
                            id: _vm.shop.id,
                        }
                    },"secondary":"","w100":""}},[_vm._v("На страницу магазина")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }