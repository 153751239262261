<template>
    <div
        :class="{
            stars__wrap_selectable: selectable,
            stars__wrap_disabled: disabled,
        }"
        class="stars__wrap"
    >
        <component
            :is="tag"
            v-for="index in 5"
            :key="index"
            v-bind="getAttrs(index)"
            class="stars__value"
            v-on="events"
        >
            <UIcon
                :name="index > value ? 'star-none' : 'star-fill'"
                class="stars__star"
                :class="[index > value ? 'stars__star_none' : 'stars__star_fill']"
            >
            </UIcon>
        </component>
    </div>
</template>

<script>
import UIcon from '@ui/components/UIcon/UIcon.vue';


export default {
    name: 'Stars',

    components: {
        UIcon,
    },

    props: {
        value: {
            type: [Number, Object],
            default: null,
        },

        disabled: Boolean,
    },

    computed: {
        selectable() {
            return !!this.$listeners.click;
        },

        tag() {
            return this.selectable ? 'button' : 'div';
        },

        scope() {
            return {
                attrs: this.tag === 'button' ? { type: 'button' } : {},
                events: this.tag === 'button' ? { click: this.setValue } : {},
            };
        },

        events() {
            const events = {};

            if (this.selectable) {
                events.click = this.setValue;
            }

            return events;
        },
    },

    methods: {
        setValue($event) {
            const index = $event.target.value;
            const value = 5 - index + 1;
            this.$emit('change', value);
        },

        getAttrs(index) {
            const attrs = {
                value: index,
            };

            if (this.selectable) {
                attrs.type = 'button';
            }

            return attrs;
        },
    },
};
</script>

<style>
.stars__wrap {
    display: inline-flex;
    align-items: center;
}

.stars__value {
    font-size: 0;
}

.stars__value:not(:last-child) {
    margin-right: 2px;
}

.stars__star {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stars__star_none {
    fill: var(--border-light-c);
}

.stars__star_fill {
    fill: var(--accent-yellow);
}

.stars__star:not(:last-child) {
    margin-right: 2px;
}

.stars__wrap_selectable .stars__value:hover ~ .stars__value .stars__star,
.stars__wrap_selectable .stars__value:focus ~ .stars__value .stars__star  {
    fill: var(--accent-yellow);
}

.stars__wrap_selectable .stars__value:hover .stars__star,
.stars__wrap_selectable .stars__value:focus .stars__star  {
    fill: var(--accent-yellow);
}

.stars__disabled .stars__star_fill {
    fill: var(--accent-yellow-light);
}
</style>