<template>
    <div class="u-field-select">
        <USelect
            :invalid="invalid"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template #label="payload">
                <slot name="label" v-bind="payload"></slot>
            </template>
        </USelect>

        <UFormFieldError
            :show="invalid"
            class="u-field-select__error"
        >
            {{ error }}
        </UFormFieldError>
    </div>
</template>

<script>
import fieldMixin from '@ui/components/UForm/fields/fieldMixin.js';
import USelect from '@ui/components/UInput/presets/USelect.vue';
import UFormFieldError from '@ui/components/UForm/UFormFieldError.vue';


export default {
    name: 'UFieldSelect',

    components: { UFormFieldError, USelect },

    mixins: [fieldMixin],

    data() {
        return {
            initialValue: null,
        };
    },
};
</script>

<style scoped>
.u-field-select__error .u-form-field-error {
    margin-top: 8px;
}
</style>