<script>
// utils
import px from '@ui/utils/px.js';
// mixins
import stack from '@ui/mixins/stack.js';
import position from '@ui/mixins/position.js';
import teleport from '@ui/mixins/teleport.js';
// components
import UMenuList from '@ui/components/UMenu/UMenuList.vue';
import UTrigger from '@ui/components/UTrigger/UTrigger.vue';
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
import UMenuIcon from './UMenuIcon.vue';


export default {
    name: 'UMenu',

    components: {
        UMenuList,
        UTrigger,
        UIconButton,
        UMenuIcon,
    },

    mixins: [
        stack,
        position,
        teleport,
    ],

    props: {
        placement: {
            type: String,
            default: 'bottom-start',
            validator(value) {
                return [
                    'top-start',
                    'top',
                    'top-end',
                    'right-start',
                    'right',
                    'right-end',
                    'bottom-end',
                    'bottom',
                    'bottom-start',
                    'left-end',
                    'left',
                    'left-start',
                ].includes(value);
            },
        },
        disabled: Boolean, // TODO
        visible: {
            type: Boolean,
            default: true,
        },
        contentWidthByTrigger: Boolean,
        showOnFocus: Boolean,
        hideOnBlur: {
            type: Boolean,
            default: true,
        },
        hideOnClickContent: {
            type: Boolean,
            default: true,
        },
        transition: {
            type: String,
            default: 'fade',
            validator(value) {
                return ['fade', 'expand-fade'].includes(value);
            },
        },
        lazy: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            list: null,
            items: null,
            active: false,
            width: null,
            booted: false,
        };
    },

    computed: {
        style() {
            return {
                zIndex: this.stack.zIndex,
                top: px(this.position.top),
                left: px(this.position.left),
            };
        },
    },

    watch: {
        active: 'onChangeActive',
    },

    methods: {
        onChangeActive(value) {
            this.booted = true;

            if (value) {
                this.updateZIndex();
                this.emitOpen();
            }
            else {
                this.emitClose();
            }
        },

        getItems() {
            return this.items.filter(vNode => {
                return vNode.componentOptions
                    && vNode.componentOptions.tag === 'UMenuItem'
                    && vNode.elm.firstChild
                    && ['BUTTON', 'A'].includes(vNode.elm.firstChild.tagName);
            }).map(vNode => vNode.elm.firstChild);
        },

        down($event) {
            const elements = this.getItems();

            if (!elements.length) return;

            let index = elements.findIndex(el => el === $event.target);

            index++;

            if (index >= elements.length) index = 0;

            elements[index].focus();

            $event.preventDefault();
        },

        up($event) {
            const elements = this.getItems();

            if (!elements.length) return;

            let index = elements.findIndex(el => el === $event.target);

            index--;

            if (index < 0) index = elements.length - 1;

            elements[index].focus();

            $event.preventDefault();
        },

        esc($event) {
            $event.target.blur();
        },

        tab($event) {
            // next widget
            if (this.$refs.trigger.triggerNode.some(vNode => vNode.elm.contains($event.target))) return;

            $event.preventDefault();
            this.$refs.trigger.triggerElement.focus();
            // FIXME: имитация таба не работает
            this.$refs.trigger.triggerElement.dispatchEvent(new KeyboardEvent('keydown', {
                keyCode: 9,
                bubbles: true,
                cancelable: true,
                code: 'Tab',
                composed: true,
                isTrusted: true,
                which: 9,
                key: 'Tab',
            }));
        },

        // public

        show() {
            this.$refs.trigger.activate();
        },

        hide() {
            this.$refs.trigger.deactivate();
        },

        toggle() {
            this.$refs.trigger.toggle();
        },

        // handlers

        handleKeyDown($event) {
            if (!(this.items || []).length) return;

            /**
             * Ответственность за навигацию по списку лежит на UMenu
             * Условия для навигации:
             * ul в качестве корня контента
             * focusable элемент в качестве первого ребёнка у li
             * focus реальный, поэтому стилизацию кнопок можно делать через псевдокласс focus
             * Навигация предусматривает кнопки up, down, tab, esc, enter
             */

            const map = {
                Tab: 'tab',
                Escape: 'esc',
                ArrowUp: 'up',
                ArrowDown: 'down',
            };

            const { up, esc, tab, down } = this;
            const handlers = { up, esc, tab, down };
            const action = map[$event.key];
            action && handlers[action]($event);
        },

        handleBeforeEnter() {
            if (this.contentWidthByTrigger) {
                if (this.$refs.trigger.triggerNode) {
                    this.width = this.$refs.trigger.triggerNode.reduce((acc, node) => {
                        const { width } = node.elm.getBoundingClientRect();
                        return acc > width ? acc : width;
                    }, 0);
                }
                else {
                    const { width } = this.$el.getBoundingClientRect();
                    this.width = width;
                }
            }
        },

        handleEnter(el, done) {
            el.style.visibility = 'hidden';

            setTimeout(() => {
                this.updatePosition();

                if (this.transition === 'expand-fade') {
                    el.style.height = 0;
                    el.style.overflow = 'hidden';
                    getComputedStyle(el).height;
                    requestAnimationFrame(() => {
                        el.style.height = this.position.dimensions.content.height + 'px';
                        done();
                    });
                }

                el.style.visibility = null;
            });
        },

        handleAfterEnter(el) {
            if (this.transition === 'expand-fade') {
                el.style.height = 'auto';
                el.style.overflow = null;
            }
        },

        handleAfterLeave() {
            this.emitAfterClose();
        },

        // emits

        emitOpen() {
            const target = this;
            this.$emit('open', { target });
        },

        emitClose() {
            const target = this;
            this.$emit('close', { target });
        },

        emitAfterClose() {
            const target = this;
            this.$emit('afterClose', { target });
        },

        // render

        genMenuItems() {
            if (this.$scopedSlots.default) {
                const items = this.$scopedSlots.default();

                this.items = items;

                return items;
            }
        },

        genMenuList() {
            return this.$createElement('UMenuList', {
                style: {
                    width: px(this.width),
                },
                scopedSlots: {
                    title: this.$scopedSlots.title,
                    default: this.genMenuItems,
                },
                on: {
                    keydown: this.handleKeyDown,
                },
            });
        },

        genContentSlot() {
            const { active, width } = this;
            const style = { width: px(width) };

            const content = this.$scopedSlots.content({ active, style });

            if (content[0].componentOptions && content[0].componentOptions.tag === 'UMenuList') {
                // li has button/a
                // items
            }

            return content;
        },

        genLazyContentSlot() {
            return this.lazy && !this.active && !this.booted
                ? [this.$createElement()]
                : this.$scopedSlots.content
                    ? this.genContentSlot()
                    : [this.genMenuList()];
        },

        genContent({ value, handlers }) {
            const style = this.style;

            const children = this.genLazyContentSlot();

            return this.$createElement('div', {
                ref: 'content',
                staticClass: 'u-menu__content',
                class: {
                    'u-menu__content_active': value && this.visible,
                },
                directives: [
                    {
                        name: 'show',
                        value: value && this.visible,
                    },
                ],
                on: handlers,
                style,
            }, children);
        },

        genTransition(payload) {
            const content = this.genContent(payload);

            return this.$createElement('transition', {
                props: {
                    name: this.transition,
                    appear: true,
                },
                on: {
                    beforeEnter: this.handleBeforeEnter,
                    enter: this.handleEnter,
                    afterEnter: this.handleAfterEnter,
                    afterLeave: this.handleAfterLeave,
                },
            }, [content]);
        },

        genTriggerElement({ value, attrs, handlers }) {
            return this.$createElement(UMenuIcon, {
                attrs: {
                    ...attrs,
                    disabled: this.disabled,
                },
                on: handlers,
                props: {
                    active: value,
                },
            });
        },

        genTrigger(payload) {
            const keydown = this.handleKeyDown;
            const { handlers, ...args } = payload;

            return this.$scopedSlots.trigger
                ? this.$scopedSlots.trigger({ handlers: { ...handlers, keydown }, ...args })
                : this.genTriggerElement({ handlers: { ...handlers, keydown }, ...args });
        },
    },

    render(h) {
        return h(UTrigger, {
            ref: 'trigger',
            class: {
                'u-menu_detached': !this.noDetach,
            },
            props: {
                delayEnter: 0,
                delayLeave: 0,
                activateOnFocus: this.showOnFocus,
                activateOnClick: !this.showOnFocus,
                deactivateOnBlur: this.hideOnBlur,
                deactivateOnClickContent: this.hideOnClickContent,
            },
            scopedSlots: {
                trigger: this.genTrigger,
                content: this.genTransition,
            },
            on: {
                change: ({ value }) => this.active = value,
            },
        });
    },
};
</script>

<style>
.u-menu_detached {
    display: none;
}

.u-menu__content {
    position: absolute;
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
    border-radius: var(--border-radius);
}
</style>