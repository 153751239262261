<template>
    <article class="market-item-row layer-1">
        <div
            class="market-item-row__product-info"
            :class="{
                'market-item-row__product-info_has-rating': !!item.rating
            }"
        >
            <div class="market-item-row__cover-block">
                <MarketItemCover
                    :item="item"
                    :modelName="modelName"
                    coverCodename="list"
                    class="market-item-row__cover-wrap"
                ></MarketItemCover>

                <MarketItemRatingReviewsLink
                    :item="item"
                    :modelName="modelName"
                    :inPopup="reviewsInPopup"
                    class="market-item-row__rating-wrap market-item-row__rating-wrap-mobile"
                ></MarketItemRatingReviewsLink>
            </div>

            <div class="market-item-row__name-block">
                <MarketItemName
                    :id="item.product_id || item.part_product_id"
                    :modelName="modelName"
                    :name="item.name"
                ></MarketItemName>

                <MarketItemRatingReviewsLink
                    :item="item"
                    :modelName="modelName"
                    :inPopup="reviewsInPopup"
                    class="market-item-row__rating-wrap market-item-row__rating-wrap-desktop"
                ></MarketItemRatingReviewsLink>

                <div class="market-item-row__characteristics-block market-item-row__characteristics-block-desktop">
                    <UKeyValueDottedList class="market-item-row__dotted-list">
                        <UKeyValueDottedItem class="market-item-row__dotted-item">
                            <template #key>
                                Производитель
                            </template>
                            <template #value>
                                <BrandShortInfo
                                    v-if="modelName !== 'contract_part'"
                                    :brand="item.brand"
                                    row
                                    class="market-item-row__brand market-item-row__brand-desktop"
                                ></BrandShortInfo>
                            </template>
                        </UKeyValueDottedItem>

                        <UKeyValueDottedItem
                            v-for="characteristic in characteristics"
                            :key="`characteristic_${ characteristic.codename }`"
                            class="market-item-row__dotted-item"
                        >
                            <template #key>
                                {{ characteristic.name }}
                            </template>
                            <template #value>
                                {{ characteristic.value }}
                            </template>
                        </UKeyValueDottedItem>
                    </UKeyValueDottedList>
                </div>
            </div>

            <BrandShortInfo
                v-if="modelName !== 'contract_part'"
                :brand="item.brand"
                row
                class="market-item-row__brand market-item-row__brand-mobile"
            ></BrandShortInfo>

            <div class="market-item-row__characteristics-block market-item-row__characteristics-block-mobile">
                <div
                    v-for="characteristic in characteristics"
                    :key="`mobile_characteristic_${ characteristic.codename }`"
                    class="market-item-row__key-value"
                >
                    <span class="market-item-row__key">{{ characteristic.name }}: </span>
                    <span class="market-item-row__value">{{ characteristic.value }}</span>
                </div>
            </div>
        </div>

        <div class="market-item-row__offer-info">
            <div class="market-item-row__price-block flex flex-column">
                <span class="market-item-row__price">{{ item.price | price }}</span>
                <span
                    v-if="modelName !== 'contract_part' && item.offers_amount === 1"
                    class="text_red text_semi-bold text_small-fz"
                >Последний товар</span>
            </div>

            <div class="market-item-row__points-info">
                <template v-if="item.in_stock && item.delivery">
                    <ButtonText
                        secondary
                        dark
                        dashed
                        class="market-item-row__points"
                        @click="handlePointsClick(item)"
                    >
                        {{ item.delivery.currentCount | plural(['пункт', 'пункта', 'пунктов']) }} выдачи
                    </ButtonText>
                    <div
                        v-if="item.min_delivery_date"
                        class="market-item-row__delivery market-item-row__key-value"
                    >
                        <span class="market-item-row__key">Получить: </span>
                        <span class="market-item-row__value">{{ item.min_delivery_date }}</span>
                    </div>
                </template>

                <div
                    v-else
                    class="market-item-row__no-items text_semi-bold text_secondary"
                >
                    Нет в наличии
                </div>
            </div>

            <div class="market-item-row__actions-block">
                <AddCartBtn
                    :offer="{ id: item.offer_id, amount: item.amount }"
                    :item="item"
                    :modelName="modelName"
                    :disabled="!item.in_stock"
                    class="market-item-row__action-btn"
                    @addToCart="$emit('changeCart')"
                    @deleteFromCart="$emit('deleteFromCart')"
                ></AddCartBtn>

                <LikeBtn
                    :id="item.product_id || item.part_product_id"
                    :apiName="modelName"
                    :value="item.is_favorite"
                    class="market-item-row__like-btn"
                    @changeFavorite="onChangeFavorite"
                ></LikeBtn>

                <ChatBtn
                    :id="(item.shop || {}).id"
                    class="market-item-row__chat-btn"
                ></ChatBtn>
            </div>
        </div>
    </article>
</template>

<script>
import { plural } from '@ui/filters/index.js';
import LikeBtn from './project-buttons/LikeBtn.vue';
import MarketItemRatingReviewsLink from './MarketItemRatingReviewsLink.vue';
import BrandShortInfo from './BrandShortInfo.vue';
import MarketItemName from './MarketItemName.vue';
import MarketItemCover from './MarketItemCover.vue';
import AddCartBtn from './project-buttons/AddCartBtn.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import UKeyValueDottedList from '@ui/components/UKeyValue/UKeyValueDottedList.vue';
import UKeyValueDottedItem from '@ui/components/UKeyValue/UKeyValueDottedItem.vue';
import ChatBtn from '@/components/project-buttons/ChatBtn.vue';
const DeliveryOfficesPopup = () => import('@/components/popups/DeliveryOfficesPopup.vue');

export default {
    name: 'MarketItemRowListItem',

    components: {
        ChatBtn,
        UKeyValueDottedItem,
        UKeyValueDottedList,
        ButtonText,
        AddCartBtn,
        MarketItemCover,
        MarketItemName,
        BrandShortInfo,
        MarketItemRatingReviewsLink,
        LikeBtn,
    },

    filters: {
        plural,
    },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        modelName: {
            type: String,
            validator(value) {
                return ['product', 'part_product', 'contract_part'].includes(value);
            },
            default: 'product',
        },

        withSchemeLink: Boolean,
        reviewsInPopup: Boolean,
    },

    computed: {
        characteristics() {
            if (this.item.show_in_list_characteristics) {
                return this.item.show_in_list_characteristics.reduce((acc, characteristic) => {
                    if (characteristic.values[0].label) {
                        acc.push({
                            ...characteristic,
                            value: characteristic.values[0].label,
                        });
                    }

                    return acc;
                }, []);
            }

            return [];
        },
    },

    methods: {
        onChangeFavorite(value) {
            this.$emit('changeFavorite', value);
        },

        showPartSchemes() {
            this.$emit('callShowPartSchemesPopup');
        },

        handlePointsClick(item) {
            const props = {
                item,
                choosable: false,
            };
            const options = { props };
            this.$popup(DeliveryOfficesPopup, options);
        },
    },
};
</script>

<style scoped>
.market-item-row {
    position: relative;
    padding: var(--gap-micro);
}
@media (min-width: 1280px) {
    .market-item-row {
        display: flex;
    }
}

.market-item-row__product-info {

}
@media (min-width: 1281px) {
    .market-item-row__product-info {
        flex-grow: 1;
    }
}
@media (min-width: 768px) {
    .market-item-row__product-info {
        display: flex;
    }
}
@media (max-width: 767px) {
    .market-item-row__product-info {
        min-height: 112px;
    }

    .market-item-row__product-info_has-rating {
        min-height: 154px;
    }
}

.market-item-row__cover-block {
    margin-right: 20px;
}
@media (max-width: 767px) {
    .market-item-row__cover-block {
        margin-right: 12px;
        margin-bottom: 12px;
        float: left;
    }
}

.market-item-row__cover-wrap {

}

.market-item-row__rating-wrap {

}

.market-item-row__rating-wrap-mobile {
    margin-top: 8px;
    flex-direction: column;
}
@media (min-width: 768px) {
    .market-item-row__rating-wrap-mobile {
        display: none;
    }
}

.market-item-row__rating-wrap-mobile >>> .stars-rating__icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
}

.market-item-row__rating-wrap-mobile >>> .market-item-rating-reviews-link__review {
    margin-top: 4px;
    margin-left: 0;
    font-size: var(--small-fz);
}

.market-item-row__rating-wrap-desktop {
    margin-top: 8px;
}
@media (max-width: 767px) {
    .market-item-row__rating-wrap-desktop {
        display: none;
    }
}

.market-item-row__name-block {
    flex: 1;
}

.market-item-row__characteristics-block {

}

.market-item-row__characteristics-block-desktop {
    margin-top: 20px;
}
@media (max-width: 767px) {
    .market-item-row__characteristics-block-desktop {
        display: none;
    }
}

.market-item-row__characteristics-block-mobile {
    margin-top: 12px;
    display: inline-block;
}
@media (min-width: 768px) {
    .market-item-row__characteristics-block-mobile {
        display: none;
    }
}

.market-item-row__dotted-item {
    align-items: center;
}

.market-item-row__dotted-item >>> .u-key-value-dotted-item__value {
    flex: none;
}

.market-item-row__dotted-list.u-key-value-dotted-list >>> .u-key-value-dotted-item:not(:last-child) {
    margin-bottom: 4px;
}

.market-item-row__brand {

}

.market-item-row__brand-mobile {
    margin-top: 12px;
}
@media (min-width: 768px) {
    .market-item-row__brand-mobile {
        display: none;
    }
}

.market-item-row__brand-mobile >>> .logo {
    margin-right: 8px;
    width: 36px;
}

.market-item-row__brand-mobile >>> .label {
    font-size: var(--small-fz);
}

.market-item-row__offer-info {
    display: flex;
}
@media (min-width: 1281px) {
    .market-item-row__offer-info {
        flex-direction: column;
        margin-left: 36px;
        padding-left: 36px;
        border-left: 1px solid var(--border-light-c);
    }
}
@media (max-width: 1280px) {
    .market-item-row__offer-info {
        align-items: center;
        border-top: 1px solid var(--border-light-c);
    }
}
@media (max-width: 1280px) and (min-width: 768px) {
    .market-item-row__offer-info {
        margin-top: 20px;
        padding-top: 20px;
    }
}
@media (max-width: 767px) {
    .market-item-row__offer-info {
        flex-wrap: wrap;
        margin-top: 16px;
        margin-right: -16px;
        margin-left: -16px;
        padding-top: 16px;
        padding-right: 16px;
        padding-left: 16px;
    }
}

@media (max-width: 1280px) {
    .market-item-row__price-block {
        order: 2;
    }
}
@media (max-width: 767px) and (min-width: 381px) {
    .market-item-row__price-block {
        flex-grow: 1;
    }
}
@media (max-width: 380px) {
    .market-item-row__price-block {
        margin-left: auto;
    }
}

.market-item-row__price {
    display: block;
    font-size: var(--h3-fz);
    font-family: var(--f-bold);
}

.market-item-row__points-info {

}
@media (min-width: 1281px) {
    .market-item-row__points-info {
        margin-top: 20px;
    }
}
@media (max-width: 1280px) {
    .market-item-row__points-info {
        order: 1;
    }
}
@media (max-width: 1280px) and (min-width: 768px) {
    .market-item-row__points-info {
        flex-grow: 1;
    }
}@media (max-width: 767px) {
    .market-item-row__points-info {
        margin-bottom: 12px;
    }
}
@media (max-width: 767px) and (min-width: 381px) {
    .market-item-row__points-info {
        width: 100%;
    }
}

.market-item-row__points {

}
@media (max-width: 767px) {
    .market-item-row__points {
        font-size: var(--small-fz);
    }
}

.market-item-row__delivery {
    margin-top: 4px;
}

.market-item-row__key-value {
    margin-top: 4px;
}
@media (max-width: 767px) {
    .market-item-row__key-value {
        font-size: var(--small-fz);
    }
}

.market-item-row__key {
    color: var(--font-secondary-color);
}

.market-item-row__value {

}

.market-item-row__actions-block {
    display: flex;
    justify-self: flex-end;
}
@media (min-width: 1281px) {
    .market-item-row__actions-block {
        margin-top: auto;
    }
}
@media (max-width: 1280px) {
    .market-item-row__actions-block {
        order: 3;
    }
}
@media (max-width: 1280px) and (min-width: 381px) {
    .market-item-row__actions-block {
        margin-left: 20px;
    }
}
@media (max-width: 380px) {
    .market-item-row__actions-block {
        width: 100%;
    }
}

.market-item-row__action-btn {

}
@media (max-width: 1280px) {
    .market-item-row__action-btn {
        margin-left: 12px;
        order: 3;
    }
}
@media (max-width: 380px) {
    .market-item-row__action-btn {
        flex-grow: 1;
    }
}

.market-item-row__like-btn {
    flex-shrink: 0;
    flex-grow: 0;
}
@media (min-width: 1281px) {
    .market-item-row__like-btn {
        margin-left: 12px;
    }
}
@media (max-width: 1280px) {
    .market-item-row__like-btn {
        order: 1;
    }
}

.market-item-row__chat-btn {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 12px;
}
@media (max-width: 1280px) {
    .market-item-row__chat-btn {
        order: 2;
    }
}
</style>
