<template>
    <PopupWrap
        fromBottomOnXS
        :loading="loading"
        @clickaway="close"
        @close="close"
    >
        <div
            v-if="!loading"
            class="popup-content"
        >
            <div class="popup-content__head">
                <h2 class="shop__name h3 h3_text">
                    {{ shop.company.name }}
                </h2>

                <ShopAddress
                    :shop="shop"
                    :showOnMap="false"
                ></ShopAddress>
            </div>

            <ShopOpenCloseTime
                :shopId="shop.id"
                :status="shop.current_work"
                withoutTooltip
                class="mt-4"
            ></ShopOpenCloseTime>

            <MarketItemRatingReviewsLink
                :item="shop"
                modelName="shop"
                class="mt-16"
            ></MarketItemRatingReviewsLink>

            <div class="actions">
                <LikeBtn
                    :id="shop.id"
                    :value="shop.is_favorite"
                    apiName="shop"
                    class="actions__btn"
                    withText
                    @changeFavorite="onChangeFavorite"
                ></LikeBtn>
                <ChatBtn
                    :id="shop.id"
                    :isOnline="shop.current_work.place.is_active"
                    :disabled="!shop.is_chat_active"
                    withText
                    btnTextAlwaysVisible
                    class="actions__btn"
                >
                    Чат
                    <span class="actions__btn_chat-text">
                        с магазином
                    </span>
                </ChatBtn>
            </div>

            <DeliveryInfo
                :currentWork="shop.current_work"
                :shop="shop"
                :order="order"
                list
                class="mt-16"
            ></DeliveryInfo>

            <ShopWorkSchedule
                :work="shop.work_times"
                :holidays="shop.holidays"
                class="mt-16"
            ></ShopWorkSchedule>

            <PointOnMapPreviewBtn
                :coords="shop.coordinates"
                class="mt-16"
                @click="openMap"
            ></PointOnMapPreviewBtn>

            <ButtonBlock
                :to="{
                    name: 'market-shop-stock',
                    params: {
                        codename: shop.company.codename,
                        id: shop.id,
                    },
                }"
                secondary
                class="footer__btn"
                @click.native="close"
            >
                На страницу магазина
            </ButtonBlock>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import ShopAddress from '@/components/ShopAddress.vue';
import ShopOpenCloseTime from '@/components/ShopOpenCloseTime.vue';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';
import ChatBtn from '@/components/project-buttons/ChatBtn.vue';
import ShopWorkSchedule from '@/components/ShopWorkSchedule.vue';
import DeliveryInfo from '@/components/DeliveryInfo.vue';
import PointOnMapPreviewBtn from '@/components/project-buttons/PointOnMapPreviewBtn.vue';
const PointOnMapPopup = () => import('@/components/popups/PointOnMapPopup.vue');

export default {
    name: 'ShopInfoPopup',

    components: {
        PointOnMapPreviewBtn,
        DeliveryInfo,
        ShopWorkSchedule,
        ChatBtn,
        MarketItemRatingReviewsLink,
        ShopOpenCloseTime,
        ShopAddress,
        LikeBtn,
        ButtonBlock,
        PopupWrap,
    },

    mixins: [popup],

    props: {
        id: {
            type: [String, Number],
            default: '',
        },

        order: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            shop: {},
            loading: true,
        };
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.loading = true;
            this.shop = await this.$api.shops.get(this.id);
            this.loading = false;
        },

        onChangeFavorite(value) {
            this.shop.is_favorite = value;
        },

        openMap() {
            const props = {
                shop: this.shop,
                coords: this.shop.coordinates,
            };
            const options = { props };
            this.$popup(PointOnMapPopup, options);
        },
    },
};
</script>

<style scoped>
@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.popup-content__head {
    padding-right: 48px;
}

.actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
}

.actions__btn {
    flex-shrink: 0;
}
.actions__btn:not(:first-child) {
    margin-left: 12px;
}
@media (max-width: 767px) {
    .actions__btn_chat-text {
        display: none;
    }
}

@media (min-width: 768px) {
    .footer__btn {
        margin-top: 36px;
    }
}
@media (max-width: 767px) {
    .footer__btn {
        margin-top: 16px;
        width: 100%;
    }
}
</style>