export default {
    namespaced: true,

    state: () => ({
        items: [],
        count: 0,
        hasSearchHistory: false,
    }),

    mutations: {
        set(state, { results }) {
            let items = [];
            results.forEach(item => {
                if (item[item.item_type]) {
                    items.push(item);
                }
            });
            state.items = items;
            state.count = items.length;
        },

        setHasSearchHistory(state, value) {
            state.hasSearchHistory = value;
        },

        reset(state) {
            state.items = [];
            state.count = 0;
            state.hasSearchHistory = false;
        },
    },

    actions: {
        async get({ commit }, { selectedCarId }) {
            const params = {
                limit: 6,
            };
            if (selectedCarId) params.car_id = selectedCarId;

            await this.$api.searchHistory.get()
                .then(response => {
                    commit('set', response);
                    commit('setHasSearchHistory', true);
                });
        },

        async set({ commit }, data) {
            await this.$api.searchHistory.addToHistory(data)
                .then(() => {
                    commit('setHasSearchHistory', false);
                });
        },
    },
};