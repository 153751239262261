<template>
    <div class="u-checkboxes__wrap">
        <UCheckbox
            v-for="option in options"
            :key="getOptionKey(option)"
            :value="isChecked(value, option)"
            :label="getOptionLabel(option)"
            class="u-checkboxes__checkbox"
            :disabled="isDisabled(option)"
            @change="onChange($event, option)"
        >
            <template #label>
                <slot name="label" v-bind="{ option }"></slot>
            </template>
        </UCheckbox>
    </div>
</template>

<script>
import options from '@ui/mixins/options.js';
import UCheckbox from '@ui/components/UCheckbox/UCheckbox.vue';


export default {
    name: 'UCheckboxes',

    components: { UCheckbox },

    mixins: [
        options,
    ],

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        options: {
            type: Array,
            required: true,
        },

        value: {
            type: Array,
            required: true,
        },

        isChecked: {
            type: Function,
            default(value, option) {
                return !!value.some(v => this.getOptionKey(v) === this.getOptionKey(option));
            },
        },

        isDisabled: {
            type: Function,
            default(option) {
                return false;
            },
        },
    },

    methods: {
        onChange({ value: checked }, option) {
            const value = this.reduce(option);

            if (checked) {
                this.$emit('change', [...this.value, value]);
            }
            else {
                this.$emit(
                    'change',
                    this.value.filter(item => {
                        return this.getOptionKey(item) !== this.getOptionKey(value);
                    }),
                );
            }
        },
    },
};
</script>

<style>
.u-checkboxes__checkbox:not(:last-child) {
    margin-bottom: 16px;
}
</style>