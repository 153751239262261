<script>
import sortAndStringify from '@/lib/sortAndStringify.js';
import FormCollectionItem from '@/components/_form/FormCollectionItem.vue';
import useFormWatch from '@/components/_form/useFormWatch.js';
import Vue from 'vue';
import equals from '@/lib/equals.js';
import setId from '@/components/_form/utils/setId.js';


export default {
    name: 'FormCollection',

    components: {
        FormCollectionItem,
    },

    inject: {
        register: { default: undefined },
        unregister: { default: undefined },
        updateOriginalErrors: { default: undefined },
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        getItemKey: {
            type: Function,
            default(item) {
                if (item == null) {
                    return '';
                }
                else if (typeof item === 'object') {
                    if (item.id !== undefined) {
                        return item.id;
                    }

                    if (item.uuid !== undefined) {
                        return item.uuid;
                    }

                    return sortAndStringify(item);
                }
                else {
                    return item;
                }
            },
        },
    },

    data() {
        return {
            fields: {},
            innerValues: [],
            originalValues: [],
            innerErrors: [],
            originalErrors: [],
        };
    },

    computed: {
        isCollection() {
            return true;
        },

        initialValue() {
            return [];
        },

        items() {
            return this.originalValues.map(item => setId(item, item.__id));
        },

        children() {
            const slot = this.$slots.default[0];
            const scopeAttr = slot.context.$options._scopeId;
            const items = this.items;

            const { scopedSlots, ...data } = slot.data;
            const children = scopedSlots.default;

            return items.map((item, index) => {
                if (!data.props) data.props = {};
                if (!data.attrs) data.attrs = {};

                if (scopeAttr) {
                    data.attrs[scopeAttr] = '';
                }

                data.props.name = index;
                data.props.item = item;
                data.props.register = this.registerField;
                data.props.unregister = this.unregisterField;
                data.props.updateParentValue = this.updateValue;
                data.props.updateOriginalErrors = this.updateErrors;
                data.props.originalErrors = this.innerErrors[index];
                data.key = item.__id;

                return this.$createElement(
                    FormCollectionItem,
                    data,
                    [children({ index, item })],
                );
            });
        },
    },

    watch: {
        ...useFormWatch(),
    },

    created() {
        if (this.register && typeof this.register === 'function') {
            this.register(this);
        }
    },

    beforeDestroy() {
        if (this.unregister && typeof this.unregister === 'function') {
            this.unregister(this);
        }
    },

    methods: {
        registerField(component) {
            const { name } = component;
            this.$set(this.fields, 'item' + name, component);
        },

        unregisterField({ name }) {
            Vue.delete(this.fields, 'item' + name);
        },

        updateValue({ name, value }) {
            const newVal = value;
            const oldVal = this.innerValues[name];

            if (!equals(newVal, oldVal)) {
                this.innerValues[name] = newVal;
            }
        },

        runRules() {
            const keys = Object.keys(this.fields);

            keys.forEach(key => {
                const field = this.fields[key];
                field.runRules();
            });
        },

        getInvalid() {
            const keys = Object.keys(this.fields);

            return keys.map(key => {
                const field = this.fields[key];
                return field.getInvalid();
            }).reduce((acc, cur) => acc ? acc : cur, false);
        },

        updateErrors({ name, errors }) {
            const newErrors = errors;
            const oldErrors = this.innerErrors[name];

            if (!equals(newErrors, oldErrors)) {
                if (!this.innerErrors[name]) {
                    this.$set(this.innerErrors, name, newErrors);
                }
                else {
                    this.innerErrors[name] = newErrors;
                }
            }
        },

        show() {
            const keys = Object.keys(this.fields);

            keys.forEach(key => {
                const field = this.fields[key];
                if (field.show) field.show();
            });
        },

        setErrors(errors) {
            // тут errors = массив
            if (errors && Array.isArray(errors) && errors.length) {
                errors.forEach((err, index) => {
                    if (err && typeof err === 'object' && Object.keys(err).length) {
                        const field = this.fields['item' + index];
                        field.setErrors(err);
                    }
                });
            }
        },
    },

    render(h) {
        return h(
            'div',
            {},
            this.children,
        );
    },
};
</script>