class Context {
    constructor() {
        this.counters = {
            yandex_count: '',
            google_count: '',
            roistat_count: '',
        };
    }
}

module.exports = Context;