<template>
    <div class="u-timeline">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'UTimeline',

    provide() {
        return {
            timeline: this ,
        };
    },
};
</script>

<style>
.u-timeline {}

.u-timeline-item {
    position: relative;
    display: flex;
    align-items: flex-start;
}
.u-timeline-item:not(:last-child) {
    padding-bottom: 20px;
}

.u-timeline-item__body {}

.u-timeline-item__divider {
    position: absolute;
    width: 1px;
    height: calc(100% - 24px - 8px);
    top: 26px;
    left: 7px;
    background-color: var(--grey-color);
}
.u-timeline-item:last-child .u-timeline-item__divider {
    display: none;
}

.u-timeline-item__dot {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    min-height: 16px;
    aspect-ratio: 1/1;
    flex-shrink: 0;
    margin-top: 2px;
    margin-right: 12px;
    padding: 2px;
    border-radius: 50%;
}
.u-timeline-item__dot--green {
    background-color: var(--accent-green);
}
.u-timeline-item__dot--grey {
    background-color: var(--grey-color);
}
</style>