<template>
    <div class="order layer-1">
        <RouterLink
            :to="{
                name: 'account-order-item',
                params: {
                    uuid: order.uuid
                }
            }"
            class="order-main"
        >
            <div class="order-head">
                <div class="order-head__main">
                    <h3 class="h3 order__title">
                        Заказ № {{ order.number }}
                        <b class="text_secondary text_adaptive-big-fz ml-4">
                            от {{ order.create_date | dayMonth }}
                        </b>
                    </h3>

                    <Status
                        :status="dictionary.orders.obj[order.status]"
                        class="mt-8"
                    ></Status>
                </div>

                <div class="order-head__aside">
                    <b class="d-block text_big-fz">{{ order.total_price | price }}</b>
                    <p
                        v-if="order.payment"
                        class="secondary-info"
                    >
                        Оплачен {{ order.payment.create_date | dayMonth }}
                    </p>
                </div>
            </div>

            <div
                v-if="hasFooter"
                class="order-footer"
            >
                <p
                    v-if="canPay"
                    class="order__actions-warning"
                >
                    Оплатите заказ, иначе он будет автоматически отменён через:&nbsp;<BackTimerText
                        :seconds="order.seconds_to_pay_expiration"
                        @timeOver="handlePaymentTimeOver"
                    ></BackTimerText>
                </p>

                <p
                    v-if="payTimeExpired"
                    class="order__actions-warning"
                >
                    Время на оплату истекло, скоро заказ будет автоматически отменён.
                </p>

                <p
                    v-if="order.status === 'partially_confirmed'"
                    class="order__actions-warning"
                >
                    Заказ был частично подтверждён, так как не все товары есть в наличии.
                    Вы можете отменить заказ или изменить состав заказа в рамках подтвержденных остатков.
                </p>

                <p
                    v-if="somePlacesInPochtaDelivering"
                    class="order__actions-warning"
                >
                    Заказ укомплектован в несколько упаковок и был отправлен Почтой России отдельными посылками.
                </p>

                <p
                    v-if="canGetDelivery"
                    class="order__actions-warning"
                >
                    Вам необходимо оформить доставку до {{ orderCancelDate | dayMonth }},
                    иначе заказ будет автоматически отменён.
                </p>

<!--                <template v-if="order.status === 'ready_pickup_shop'">-->
<!--                    <p-->
<!--                        v-if="!order.shop.current_work.place.is_active"-->
<!--                        class="order__actions-warning"-->
<!--                    >-->
<!--                        <template v-if="order.shop.is_delivery_yandex">-->
<!--                            Сейчас магазин закрыт, вы можете забрать заказ самостоятельно или <ButtonText-->
<!--                                secondary-->
<!--                                dark-->
<!--                                dashed-->
<!--                                @click="showDeliveryPopup"-->
<!--                            >-->
<!--                                оформить доставку-->
<!--                            </ButtonText> в другой день в рабочее время магазина.-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                            Сейчас магазин закрыт,-->
<!--                            вы можете забрать заказ самостоятельно в другой день в рабочее время магазина.-->
<!--                        </template>-->
<!--                        Обратите внимание, заказ необходимо получить до {{ orderCancelDate | dayMonth }},-->
<!--                        иначе он будет автоматически отменён.-->
<!--                    </p>-->
<!--                    <p-->
<!--                        v-else-if="isShopCloseSoon"-->
<!--                        class="order__actions-warning"-->
<!--                    >-->
<!--                        <template v-if="order.shop.is_delivery_yandex">-->
<!--                            Магазин скоро закроется, вы можете забрать заказ самостоятельно или <ButtonText-->
<!--                                secondary-->
<!--                                dark-->
<!--                                dashed-->
<!--                                @click="showDeliveryPopup"-->
<!--                            >-->
<!--                                оформить доставку-->
<!--                            </ButtonText> в другой день в рабочее время магазина.-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                            Магазин скоро закроется,-->
<!--                            вы можете забрать заказ самостоятельно в другой день в рабочее время магазина.-->
<!--                        </template>-->
<!--                        Обратите внимание, заказ необходимо получить до {{ orderCancelDate | dayMonth }},-->
<!--                        иначе он будет автоматически отменён.-->
<!--                    </p>-->
<!--                    <p-->
<!--                        v-else-->
<!--                        class="order__actions-warning"-->
<!--                    >-->
<!--                        Вам необходимо получить заказ до {{ orderCancelDate | dayMonth }},-->
<!--                        иначе он будет автоматически отменён.-->
<!--                        <template-->
<!--                            v-if="order.shop.is_delivery_yandex-->
<!--                                && order.shop.current_work.place.is_active-->
<!--                                && !isShopCloseSoon"-->
<!--                        >-->
<!--                            Вы можете <ButtonText-->
<!--                                secondary-->
<!--                                dark-->
<!--                                dashed-->
<!--                                @click="showDeliveryPopup"-->
<!--                            >-->
<!--                                оформить доставку-->
<!--                            </ButtonText> по городу, курьер привезёт заказ в течение двух часов.-->
<!--                        </template>-->
<!--                    </p>-->
<!--                </template>-->

<!--                <template v-if="order.status === 'wait_reg_delivery' && order.delivery_type === 'city_delivery'">-->
<!--                    <p-->
<!--                        v-if="!order.shop.current_work.place.is_active"-->
<!--                        class="order__actions-warning"-->
<!--                    >-->
<!--                        <template v-if="order.shop.is_delivery_pickup">-->
<!--                            Сейчас магазин закрыт, вы можете оформить доставку или <ButtonText-->
<!--                                secondary-->
<!--                                dark-->
<!--                                dashed-->
<!--                                @click="showPickupOrderPopup"-->
<!--                            >-->
<!--                                забрать заказ самостоятельно-->
<!--                            </ButtonText> в другой день в рабочее время магазина.-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                            Сейчас магазин закрыт, вы можете оформить доставку в другой день в рабочее время магазина.-->
<!--                        </template>-->
<!--                        Обратите внимание, заказ необходимо получить до {{ orderCancelDate | dayMonth }},-->
<!--                        иначе он будет автоматически отменён.-->
<!--                    </p>-->
<!--                    <p-->
<!--                        v-else-if="isShopCloseSoon"-->
<!--                        class="order__actions-warning"-->
<!--                    >-->
<!--                        <template v-if="order.shop.is_delivery_pickup">-->
<!--                            Магазин скоро закроется,-->
<!--                            вы можете оформить доставку в другой день в рабочее время магазина или <ButtonText-->
<!--                                secondary-->
<!--                                dark-->
<!--                                dashed-->
<!--                                @click="showPickupOrderPopup"-->
<!--                            >-->
<!--                                забрать заказ самостоятельно-->
<!--                            </ButtonText>.-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                            Магазин скоро закроется, вы можете оформить доставку в другой день в рабочее время магазина.-->
<!--                        </template>-->
<!--                        Обратите внимание, заказ необходимо получить до {{ orderCancelDate | dayMonth }},-->
<!--                        иначе он будет автоматически отменён.-->
<!--                    </p>-->
<!--                    <p-->
<!--                        v-else-->
<!--                        class="order__actions-warning"-->
<!--                    >-->
<!--                        <template v-if="order.shop.is_delivery_pickup">-->
<!--                            Вам необходимо оформить доставку или <ButtonText-->
<!--                                secondary-->
<!--                                dark-->
<!--                                dashed-->
<!--                                @click="showPickupOrderPopup"-->
<!--                            >-->
<!--                                забрать заказ самостоятельно-->
<!--                            </ButtonText> до {{ orderCancelDate | dayMonth }}, иначе он будет автоматически отменён.-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                            Вам необходимо оформить доставку до {{ orderCancelDate | dayMonth }},-->
<!--                            иначе он будет автоматически отменён.-->
<!--                        </template>-->
<!--                    </p>-->
<!--                </template>-->

                <div
                    v-if="hasActions"
                    class="order__actions"
                >
                    <ButtonBlock
                        v-if="canPay"
                        primary
                        low
                        fixed
                        class="order__action-btn_block"
                        :loading="paymentLoading"
                        @click.prevent="createPayment"
                    >
                        Оплатить
                    </ButtonBlock>

                    <ButtonBlock
                        v-if="order.status === 'partially_confirmed'"
                        primary
                        low
                        class="order__action-btn_block"
                        @click.prevent="showEditOrderPopup"
                    >
                        Изменить состав заказа
                    </ButtonBlock>

                    <ButtonBlock
                        v-if="canGetDelivery"
                        primary
                        low
                        :disabled="order.delivery_type === 'city_delivery'
                            && (isShopCloseSoon || !order.shop.current_work.place.is_active)"
                        class="order__action-btn_block"
                        @click.prevent="showDeliveryPopup"
                    >
                        Оформить доставку
                    </ButtonBlock>

                    <ButtonText
                        v-if="canCancelOrder"
                        dashed
                        secondary
                        dark
                        class="order__action-btn_block"
                        @click.prevent="showCancelOrderPopup"
                    >
                        Отменить заказ
                    </ButtonText>
                </div>
            </div>
        </RouterLink>

        <div
            v-if="showPlaces"
            class="order-places"
        >
            <div
                v-for="(place, placeIndex) in order.delivery_service_orders"
                :key="'place' + placeIndex"
                class="order-place flex align-items-center justify-content-between"
            >
                <div class="flex-1">
                    <b>
                        Отправление {{ placeIndex + 1 }}
                    </b>

                    <Chip
                        v-if="['partially_ready_pickup_point', 'partially_completed'].includes(order.status)"
                        :color="(dictionary.order_place.obj[place.status] || {}).color"
                        :value="(dictionary.order_place.obj[place.status] || {}).name"
                        outline
                        class="mt-8 hidden-xl hidden-l hidden-m"
                    ></Chip>

                    <p
                        v-if="place.status === 'ready_pickup_point'"
                        class="order-place__description"
                    >
                        Вам необходимо забрать эту часть заказа до 30 июня,
                        иначе она будет отправлена обратно отправителю.
                    </p>
                </div>

                <Chip
                    v-if="['partially_ready_pickup_point', 'partially_completed'].includes(order.status)"
                    :color="(dictionary.order_place.obj[place.status] || {}).color"
                    :value="(dictionary.order_place.obj[place.status] || {}).name"
                    outline
                    class="hidden-s ml-20 flex-initial"
                ></Chip>
            </div>
        </div>
    </div>
</template>

<script>
import { DAYS_TO_RECEIVE_ORDER, HOURS_TO_SHOP_CLOSE } from '@/settings';
import { mapMutations } from 'vuex';
import dictionary from '@/dictionary.js';
import { getErrorCode } from '@/lib/errors.js';
import { changeDays, changeHours } from '@/lib/dateTime.js';
import Status from '@/components/Status.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import Chip from '@/components/Chip.vue';
const BackTimerText = () => import('@/components/BackTimerText.vue');
const EditOrderPopup = () => import('./EditOrderPopup.vue');
const GetYandexDeliveryPopup = () => import('./GetYandexDeliveryPopup.vue');
const PickupOrderPopup = () => import('./PickupOrderPopup.vue');
const GetCountryDeliveryPopup = () => import('./GetCountryDeliveryPopup.vue');
const CdekDeliveryPopup = () => import('./CdekDeliveryPopup.vue');
const CancelOrderPopup = () => import('./CancelOrderPopup.vue');

export default {
    name: 'ActiveOrder',

    components: {
        Chip,
        ButtonBlock,
        ButtonText,
        BackTimerText,
        Status,
    },

    props: {
        initialOrder: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            dictionary,
            order: {},

            paymentLoading: false,
        };
    },

    computed: {
        hasFooter() {
            return this.canPay
                || this.payTimeExpired
                || this.canGetDelivery
                || this.canCancelOrder
                || this.somePlacesInPochtaDelivering
                || this.order.status === 'partially_confirmed';
                // || this.order.status === 'ready_pickup_shop';
        },

        payTimeExpired() {
            return this.order.status === 'new'
                && !this.order.payment
                && this.order.seconds_to_pay_expiration === 0;
        },

        somePlacesInPochtaDelivering() {
            return this.order.status === 'delivering'
                && this.order.delivery_type === 'russian_delivery'
                && this.order.delivery_service_orders.length > 1
                && this.order.delivery_claim
                && this.order.delivery_claim.service.codename === 'pochtaru';
        },

        hasActions() {
            return this.canPay
                || this.order.status === 'partially_confirmed'
                || this.canGetDelivery
                || this.canCancelOrder;
        },

        canPay() {
            return this.order.status === 'new' && !this.order.payment && this.order.seconds_to_pay_expiration > 0;
        },

        orderCancelDate() {
            return changeDays(this.order.collected_at, DAYS_TO_RECEIVE_ORDER);
        },

        isShopCloseSoon() {
            const shopCloseTimeSplit = this.order.shop.current_work.place.text.split(':');
            // TODO: переделать на серверное время #servertime
            const shopCloseTime = new Date();
            shopCloseTime.setHours(shopCloseTimeSplit[0], shopCloseTimeSplit[1], 0, 0);

            const shopCloseTimeEarly = changeHours(shopCloseTime, -HOURS_TO_SHOP_CLOSE);

            const currentTime = new Date().getTime();

            return currentTime > shopCloseTimeEarly.getTime();
        },

        canGetDelivery() {
            // if (this.order.delivery_type === 'city_delivery') {
            //     if (this.order.status === 'wait_reg_delivery') return true;
            // }
            // else if (this.order.delivery_type === 'russian_delivery') {
            //     if (this.order.status === 'wait_reg_delivery') return true;
            // }
            // return false;

            return this.order.delivery_service.codename === 'cdek' && this.order.status === 'wait_reg_delivery';
        },

        showPlaces() {
            const statuses = [
                'partially_ready_pickup_point',
                'ready_pickup_point',
                'partially_completed',
            ];

            return this.order.delivery_type === 'russian_delivery'
                && this.order.delivery_service_orders.length
                && statuses.includes(this.order.status);
        },

        canCancelOrder() {
            const statuses = ['new', 'wait_confirm', 'partially_confirmed'];
            return statuses.includes(this.order.status);
        },
    },

    created() {
        this.order = Object.assign({}, this.initialOrder);
    },

    methods: {
        ...mapMutations({
            CHANGE_DELIVERY_TYPE: 'profileOrders/changeOrderDeliveryType',
            CHANGE_ORDER_STATUS: 'profileOrders/changeOrderStatus',
        }),

        async createPayment() {
            this.paymentLoading = true;
            try {
                const { payment_uuid } = await this.$api.orders.pay(this.order.uuid);
                this.$router.push({
                    name: 'before-payment',
                    params: {
                        uuid: payment_uuid,
                    },
                });
            }
            catch (error) {
                const code = getErrorCode(error);
                const data = error.response.data;

                if (code === 400) {
                    const { non_field_errors } = data;
                    if (non_field_errors) {
                        this.$error(non_field_errors[0].message);
                    }
                    else {
                        this.$error('Не удалось получить ссылку на оплату.');
                    }
                }
                else if (code === 404) {
                    this.$error('Запрашиваемый адрес не существует.');
                }
                else if (code === 500) {
                    this.$error(data.detail);
                }
                this.paymentLoading = false;
            }
        },

        showEditOrderPopup() {
            const props = {
                order: this.order,
                callback: response => {
                    if (response) {
                        this.order = response;
                    }
                    else {
                        this.$emit('cancel');
                    }
                },
            };
            const options = { props };
            this.$popup(EditOrderPopup, options);
        },

        showDeliveryPopup() {
            this.order.delivery_type === 'russian_delivery'
                ? this.order.delivery_service.codename === 'cdek'
                    ? this.showCdekDeliveryPopup()
                    : this.showCountryDeliveryPopup()
                : this.showYandexDeliveryPopup();
        },

        showCountryDeliveryPopup() {
            const props = {
                order: this.order,
            };
            const options = { props };
            this.$popup(GetCountryDeliveryPopup, options);
        },

        showCdekDeliveryPopup() {
            const props = {
                order: this.order,
            };
            const options = { props };
            this.$popup(CdekDeliveryPopup, options);
        },

        showYandexDeliveryPopup() {
            const props = {
                order: this.order,
                canOrder: !this.isShopCloseSoon && this.order.shop.current_work.place.is_active,
                isShopCloseSoon: this.isShopCloseSoon,
            };
            const options = { props };
            this.$popup(GetYandexDeliveryPopup, options);
        },

        showPickupOrderPopup() {
            const props = {
                order: this.order,
                callback: () => {
                    this.order.delivery_type = 'pickup';
                    this.order.status = 'ready_pickup_shop';
                    this.order.delivery_yandex_claim = {};
                },
            };
            const options = { props };
            this.$popup(PickupOrderPopup, options);
        },

        handlePaymentTimeOver() {
            this.order.seconds_to_pay_expiration = 0;
        },

        showCancelOrderPopup() {
            const props = {
                uuid: this.order.uuid,
                callback: response => {
                    if (response) {
                        this.order = response;
                    }
                    else {
                        this.$emit('cancel');
                    }
                },
            };
            const options = { props };
            this.$popup(CancelOrderPopup, options);
        },
    },
};
</script>

<style scoped src="../../../../assets/css/order.css"></style>