var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AccountLayout',{attrs:{"title":"Заказы"}},[_c('section',[(!_vm.initialized)?_c('div',{staticClass:"spinner-wrap"},[_c('USpinner',{attrs:{"center":"","big":""}})],1):[(_vm.activeOrdersCount !== 0 || _vm.archivedOrdersCount !== 0)?_c('MarketItemNavigation',{staticClass:"layer-1 bottom-gap_grid",attrs:{"small":"","items":[
                    {
                        to: {
                            name: 'account-orders-active'
                        },
                        label: 'Активные',
                        amount: _vm.activeOrdersCount
                    },
                    {
                        to: {
                            name: 'account-orders-archive'
                        },
                        label: 'Завершённые',
                        amount: _vm.archivedOrdersCount
                    } ]}}):_vm._e(),_vm._v(" "),(_vm.activeOrdersCount === 0 && _vm.archivedOrdersCount === 0)?_c('EmptyBlock',{attrs:{"icon":"cart-stroke","title":"Нет заказов","text":"Здесь будут отображаться все заказы, оформленные вами в маркетплейсе.","hasBtn":false}}):_c('transition',{attrs:{"name":"fade-page","mode":"out-in"}},[_c('RouterView')],1)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }