<template>
    <div
        :class="[
            'post-editor__block_' + widget.type,
        ]"
    >
        <div
            :class="[
                widget.marginTop ? 'indent-wrap_mt' + widget.marginTop : '',
                widget.marginBottom ? 'indent-wrap_mb' + widget.marginBottom : '',
                widget.paddingTop ? 'indent-wrap_pt' + widget.paddingTop : '',
                widget.paddingBottom ? 'indent-wrap_pb' + widget.paddingBottom : '',
            ]"
            class="indent-wrap"
        >
            <h3
                v-if="widget.title"
                class="h3 h3_block"
            >
                {{ widget.title }}
            </h3>
            <Accordion :questions="widget.accordion.items"></Accordion>
        </div>
    </div>
</template>

<script>
import Accordion from '../Accordion.vue';

export default {
    name: "PostEditorAccordion",

    components: {
        Accordion,
    },

    props: {
        widget: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>