<template>
    <PreviewComponent>
        <template #trigger="{ isOpen }">
            <button class="preview-trigger">
                <UserAvatar
                    size="xs"
                    icon
                    class="preview-trigger__icon"
                    :class="{
                        'preview-trigger_active': isOpen
                    }"
                ></UserAvatar>

                <span v-if="name" class="preview-trigger__text">{{ profile.first_name }}</span>
            </button>
        </template>

        <template #content>
            <div class="dropdown">
                <div class="dropdown__head">
                    <UserAvatar
                        size="m"
                        class="dropdown__avatar"
                    ></UserAvatar>
                    <div>
                        <p class="dropdown__name">
                            {{ profile.first_name }}
                        </p>
                        <p class="dropdown__phone">
                            {{ profile.phone }}
                        </p>
                    </div>
                </div>

                <ul class="dropdown__menu-section menu">
                    <li
                        v-for="link in sectionsMap"
                        class="menu__item"
                    >
                        <RouterLink
                            :to="{
                                name: link.route
                            }"
                            class="menu__link link-primary-hover"
                        >
                            {{ link.title }}
                        </RouterLink>
                    </li>
                </ul>

                <div class="dropdown__menu-section">
                    <button
                        class="menu__link link-primary-hover"
                        @click="logout"
                    >
                        Выйти
                    </button>
                </div>
            </div>
        </template>
    </PreviewComponent>
</template>

<script>
import { mapState } from "vuex";
import UserAvatar from '@/components/UserAvatar.vue';
import PreviewComponent from '@/components/header/PreviewComponent.vue';

export default {
    name: 'UserBar',

    components: { PreviewComponent, UserAvatar },

    props: {
        name: Boolean,
    },

    data() {
        return {
            sectionsMap: [
                {
                    route: 'account-orders',
                    title: 'Заказы',
                },
                {
                    route: 'account-favorites',
                    title: 'Избранное',
                },
                {
                    route: 'account-notifications',
                    title: 'Уведомления',
                },
                {
                    route: 'account-garage',
                    title: 'Гараж',
                },
                {
                    route: 'account-insurance',
                    title: 'Страховые полисы',
                },
                {
                    route: 'account-reviews',
                    title: 'Отзывы',
                },
                {
                    route: 'account-settings',
                    title: 'Настройки профиля',
                },
            ],
        };
    },

    computed: {
        ...mapState({
            profile: state => state.profile.profile,
        }),
    },

    methods: {
        logout() {
            this.$store.dispatch('profile/logout')
                .then(() => {
                    this.$store.dispatch('cart/getCart');
                    if (this.$route.name.includes('account-')) {
                        this.$router.push({ name: 'signin' });
                    }
                    else {
                        document.location.reload();
                    }
                });
        },
    },
};
</script>

<style scoped>
.preview-trigger {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    transition: color var(--transition);
}

.preview-trigger_active,
.preview-trigger:hover,
.preview-trigger:focus {
    color: var(--primary-color);
}

.preview-trigger__icon {
    display: block;
    fill: currentColor;
}

.preview-trigger__text {
    display: block;
    margin-left: 8px;
    font-size: var(--base-fz);
    font-family: var(--f-semi-bold);
}

.dropdown {
    width: 280px;
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
    border-radius: var(--border-radius-x2);
}

.dropdown__head {
    display: flex;
    align-items: center;
    padding: 20px 24px;
}

.dropdown__avatar {
    margin-right: 16px;
}

.dropdown__name {
    font-size: var(--big-fz);
    font-family: var(--f-bold);
}

.dropdown__phone {
    margin-top: 2px;
    font-size: var(--small-fz);
    font-family: var(--f-semi-bold);
    color: var(--font-secondary-color);
}

.dropdown__menu-section {
    padding: 8px 0;
    border-top: 1px solid var(--border-light-c);
}

.menu__link {
    display: block;
    width: 100%;
    padding: 12px 24px;
    font-family: var(--f-semi-bold);
    text-align: left;
}
</style>