import bus from '@/bus.js';
import { HTTP } from '@/http.js';
import { YANDEX_API_KEY } from '@/settings.js';
import getErrorCode from '@/lib/getErrorCode.js';
import Selector from '@/components/api-inputs/SelectorApi.vue';
import Rating from '@/components/Rating.vue';
const ImageCropperPopup = () => import('@/components/ImageCropperPopup.vue');


const address = ({ options }) => {
    options.address.yandexApiKey = YANDEX_API_KEY;
    options.address.axios = HTTP;
};

const geo = ({ options }) => {
    options.geo.yandexApiKey = YANDEX_API_KEY;
};

const rating = ({ options }) => {
    options.widgets.rating = Rating;
};

const loader = ({ options }) => {
    options.loader.instanceAxios = HTTP;
    options.loader.cropper = ImageCropperPopup;
};

const handleHttpError = ({ options }) => {
    options.handleHttpError = (error, component) => {
        const code = getErrorCode(error);

        if (code === 400) {
            const data = error.response.data;

            // eslint-disable-next-line no-unused-vars
            const { non_field_errors, ...errors } = data;

            if (errors) {
                component.setErrors(errors);
            }
        }
    };
};

const handleFormValidateErrors = ({ options }) => {
    options.handleFormValidateErrors = errors => {
        const checkRequiredError = errors => {
            return Object.keys(errors).reduce((acc, key) => {
                if (acc) return acc;

                const err = errors[key];

                if (err && Array.isArray(err)) {
                    const isError = err.every(obj => obj.code && obj.message);

                    if (isError) {
                        acc = err.some(obj => {
                            return obj.code === 'required';
                        });
                    }
                    else {
                        acc = err.some(e => checkRequiredError(e));
                    }
                }
                else if (typeof err === 'object') {
                    acc = checkRequiredError(err);
                }

                return acc;
            }, false);
        };

        const hasRequiredError = checkRequiredError(errors);

        if (hasRequiredError) {
            bus.$emit('notify', { type: 'error', message: 'Заполнены не все обязательные поля' });
        }
        else {
            bus.$emit('notify', { type: 'error', message: 'Одно или несколько полей содержат ошибки' });
        }
    };
};

const handleLoaderErrors = ({ options }) => {
    options.field.handleInputErrors.loader = (errors, field) => {
        const error = errors[0];

        if (error && error.code === 'max_length') {
            const message = error.message;
            bus.$emit('notify', { type: 'error', message });
        }
        else {
            field.updateErrors(errors);
        }
    };
};

const handleHttpLoaderErrors = ({ options }) => {
    options.field.handleHttpErrors.loader = errors => {
        const { message } = errors[0];

        if (message) {
            bus.$emit('notify', { type: 'error', message });
        }
    };
};

const selector = ({ options }) => {
    options.widgets.select = Selector;
};

export default {
    plugins: [
        geo,
        address,
        rating,
        loader,
        selector,
        handleHttpError,
        handleFormValidateErrors,
        handleLoaderErrors,
        handleHttpLoaderErrors,
    ],
};