<template>
    <PopupWrap
        :loading="loadedImages < 3"
        noPadding
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="main">
                <VueSlickCarousel
                    ref="imgSlider"
                    class="slider"
                    :arrows="false"
                    dotsClass="slider__dots"
                    :slidesToShow="1"
                    :slidesToScroll="1"
                    :infinite="false"
                    @beforeChange="onChange"
                >
                    <div class="slide">
                        <img
                            srcset="../../assets/images/market/search-capability-1.jpg,
                                    ../../assets/images/market/search-capability-1-x2.jpg 2x"
                            src="../../assets/images/market/search-capability-1-x2.jpg"
                            alt=""
                            class="slide__img"
                            @load="loadedImages++"
                        >
                    </div>
                    <div class="slide">
                        <img
                            srcset="../../assets/images/market/search-capability-2.jpg,
                                    ../../assets/images/market/search-capability-2-x2.jpg 2x"
                            src="../../assets/images/market/search-capability-2-x2.jpg"
                            alt=""
                            class="slide__img"
                            @load="loadedImages++"
                        >
                    </div>
                    <div class="slide">
                        <img
                            srcset="../../assets/images/market/search-capability-3.jpg,
                                    ../../assets/images/market/search-capability-3-x2.jpg 2x"
                            src="../../assets/images/market/search-capability-3-x2.jpg"
                            alt=""
                            class="slide__img"
                            @load="loadedImages++"
                        >
                    </div>
                </VueSlickCarousel>

                <VueSlickCarousel
                    ref="textSlider"
                    class="slider slider_text"
                    :arrows="false"
                    fade
                    :slidesToShow="1"
                    :slidesToScroll="1"
                    :infinite="false"
                    cssEase="ease-out"
                    :speed="400"
                    @beforeChange="onChange"
                >
                    <div class="text">
                        <h2 class="h2 h2_text slide__title">
                            Выберите автомобиль
                        </h2>
                        <p class="slide__text">
                            Просто введите гос. номер, VIN/Frame или выберите машину из&nbsp;Гаража.
                        </p>
                    </div>

                    <div class="text">
                        <h2 class="h2 h2_text slide__title">
                            Напишите, что вы ищете
                        </h2>
                        <p class="slide__text">
                            Введите в поле поиска название или номер необходимой запчасти и&nbsp;переходите к&nbsp;предложениям магазинов.
                        </p>
                    </div>

                    <div class="text">
                        <h2 class="h2 h2_text slide__title">
                            Выберите предложение
                        </h2>
                        <p class="slide__text">
                            С&nbsp;помощью фильтров выберите подходящий вариант, добавьте запчасть в&nbsp;корзину и&nbsp;переходите к&nbsp;оформлению заказа.
                        </p>
                    </div>
                </VueSlickCarousel>
            </div>

            <div class="footer">
                <ButtonBlock
                    primary
                    class="slide__btn"
                    @click="slideIndex === 0 || slideIndex === 1 ? next() : close()"
                >
                    <TransitionFade>
                        <span v-if="slideIndex === 0" key="button-text-0">Далее</span>
                        <span v-if="slideIndex === 1" key="button-text-1">Далее</span>
                        <span v-if="slideIndex === 2" key="button-text-2">Всё понятно</span>
                    </TransitionFade>
                </ButtonBlock>

                <ul class="slider__dots">
                    <li>
                        <button
                            value="0"
                            :class="{
                                active: slideIndex === 0
                            }"
                            @click="goTo"
                        >0</button>
                    </li>
                    <li>
                        <button
                            value="1"
                            :class="{
                                active: slideIndex === 1
                            }"
                            @click="goTo"
                        >1</button>
                    </li>
                    <li>
                        <button
                            value="2"
                            :class="{
                                active: slideIndex === 2
                            }"
                            @click="goTo"
                        >2</button>
                    </li>
                </ul>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import TransitionFade from '@/components/_transitions/TransitionFade.vue';


export default {
    name: 'SearchCapabilitiesPopup',

    components: {
        TransitionFade,
        ButtonBlock,
        PopupWrap,
        VueSlickCarousel,
    },

    mixins: [popup],

    data() {
        return {
            loadedImages: 0,
            slideIndex: 0,
        };
    },

    methods: {
        next() {
            this.$refs.imgSlider.next();
            this.$refs.textSlider.next();
        },

        goTo($event) {
            const index = Number($event.target.value);
            this.$refs.imgSlider.goTo(index);
            this.$refs.textSlider.goTo(index);
        },

        onChange(oldIndex, newIndex) {
            this.slideIndex = newIndex;
        },
    },
};
</script>

<style scoped>
.popup-content {
    padding: var(--popup-paddings-y) 0;
}
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

.slider {
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
}

.slide {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.slide__img {
    margin: 0 auto 24px;
}
@media (min-width: 768px) {
    .slide__img {
        width: 400px;
    }
}
@media (min-width: 376px) and (max-width: 767px) {
    .slide__img {
        width: 290px;
    }
}
@media (max-width: 375px) {
    .slide__img {
        width: 200px;
    }
}

.text {
    padding: 0 var(--popup-paddings-x);
    text-align: center;
}

.slide__title {
    margin-bottom: var(--gap-nano);
}
@media (min-width: 768px) {
    .slide__title {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
    }
}

.slide__text {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    line-height: var(--base-lh);
}

@media (max-width: 767px) {
    .main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .slider_text {
        padding-bottom: var(--popup-paddings-y);
    }
}

.footer {
    display: flex;
    flex-direction: column;
    padding: 0 var(--popup-paddings-x);
}
@media (min-width: 768px) {
    .footer {
        margin-top: var(--gap-y-small);
    }
}
@media (max-width: 767px) {
    .footer {
        flex-direction: column-reverse;
    }
}

.slide__btn {
    display: flex;
}
@media (min-width: 768px) {
    .slide__btn {
        width: 160px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: var(--gap-y-medium);
    }
}
@media (max-width: 767px) {
    .slide__btn {
        width: 100%;
        margin-top: var(--gap-y-medium);
    }
}
</style>