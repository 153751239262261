<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <div class="popup__head">
                <h2 class="h2 h2_block">
                    Создать запрос
                </h2>

                <StepBar
                    class="step-bar"
                    :step="step"
                    :items="['Выбор автомобиля', 'Описание запчастей']"
                ></StepBar>
            </div>

            <div class="steps-content">
                <TransitionHeightGroup class="steps-content__animation-wrap">
                    <div
                        v-if="step === 1"
                        key="step-1"
                    >
                        <SelectCarForm
                            ref="selectCarForm"
                            @select="selectCar"
                        ></SelectCarForm>
                    </div>

                    <FormManager
                        v-if="step === 2"
                        key="step-2"
                        ref="form"
                        v-model="formData"
                        class="form"
                        @submit="save"
                    >
                        <div class="selected-car-block">
                            <div class="selected-car">
                                <CarManufacturerLogo
                                    :manufacturer="selectedCar.manufacturer"
                                    mono
                                    class="selected-car__manufacturer-logo-wrap"
                                ></CarManufacturerLogo>

                                <CarName
                                    :car="selectedCar"
                                    noManufacturerName
                                    class="selected-car__name"
                                ></CarName>

                                <CarCharacteristicsWithTooltip
                                    :car="selectedCar"
                                    class="selected-car__characteristics"
                                ></CarCharacteristicsWithTooltip>
                            </div>

                            <ButtonText
                                dashed
                                primary
                                class="selected-car-block__change-btn"
                                @click="changeCar"
                            >
                                Изменить
                            </ButtonText>
                        </div>

                        <h3 class="h4">
                            Опишите необходимую запчасть или запчасти
                        </h3>

                        <p class="form__description">
                            Чем подробнее, тем лучше. Укажите OEM-коды, если вы&nbsp;их&nbsp;знаете. При&nbsp;необходимости, прикрепите фотографии.
                        </p>

                        <FormField
                            type="text"
                            name="description"
                            label="Описание"
                            validations="required"
                            class="form-field-gap_bottom"
                        ></FormField>

                        <FormField
                            name="photos"
                            type="loader"
                            label="Фотографии"
                            :accept="['jpg', 'jpeg']"
                            :maxItems="3"
                            sizeKey="file_size"
                            idKey="uuid"
                            api="/mediafiles/upload/"
                        ></FormField>

                        <div class="form__actions">
                            <FormAgreement
                                text="Отправить"
                                class="form__agreement"
                            ></FormAgreement>

                            <ButtonBlock
                                submit
                                primary
                                class="form__submit"
                                :loading="loading"
                            >
                                Отправить
                            </ButtonBlock>
                        </div>
                    </FormManager>
                </TransitionHeightGroup>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import TransitionHeightGroup from "../_transitions/TransitionHeightGroup.vue";
import StepBar from '../StepBar.vue';
import SelectCarForm from "./SelectCarForm.vue";
import CarManufacturerLogo from "../CarManufacturerLogo.vue";
import CarName from "../CarName.vue";
import CarCharacteristicsWithTooltip from "../CarCharacteristicsWithTooltip.vue";
import FormAgreement from '../FormAgreement.vue';
import FormManager from "@/components/_form/FormManager.vue";
import FormField from "@/components/_form/FormField.vue";
import handleFormErrorMixin from "@/mixins/handleFormErrorMixin.js";
import ButtonBlock from "@/components/_buttons/ButtonBlock.vue";
import ButtonText from '@/components/_buttons/ButtonText.vue';


export default {
    name: "CreateRequestPartsOffersPopup",

    components: {
        ButtonText,
        ButtonBlock,
        FormField,
        FormManager,
        PopupWrap,
        TransitionHeightGroup,
        StepBar,
        SelectCarForm,
        CarManufacturerLogo,
        CarName,
        CarCharacteristicsWithTooltip,
        FormAgreement,
    },

    mixins: [popup, handleFormErrorMixin],

    data() {
        return {
            formData: {
                description: '',
                photos: [],
            },
            step: 1,
            selectedCar: {},
            loading: false,
        };
    },

    methods: {
        selectCar(car) {
            this.selectedCar = car;
            this.step = 2;
        },

        changeCar() {
            this.selectedCar = {};
            this.showFindForm = true;
            this.step = 1;
        },

        async save() {
            const errors = await this.$refs.form.validate();

            if (errors) {
                this.$handleFormErrors(errors);
            }
            else {
                this.loading = true;
                const data = Object.assign({
                    car: this.selectedCar.id,
                }, this.formData);

                this.$api.auctionRequests.addRequest(data)
                    .then(() => {
                        this.callback();
                        this.close();
                        this.$success('Запрос отправлен, мы оповестим вас, когда появятся предложения');
                    })
                    .catch(error => {
                        this.$refs.form.handle(error);
                        this.$store.commit('handleCommonHttpError', error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

@media (min-width: 768px) {
    .step-bar {
        margin-bottom: 36px;
    }
}
@media (max-width: 767px) {
    .step-bar {
        margin-bottom: 28px;
    }
}

.selected-car-block {
    position: relative;
    margin-bottom: var(--gap-y-small);
    background-color: var(--bright-bg);
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius);
}
@media (min-width: 768px) {
    .selected-car-block {
        display: flex;
        align-items: flex-start;
        padding: 20px 24px;
    }
}
@media (max-width: 767px) {
    .selected-car-block {
        padding: 12px 16px;
    }
}

.selected-car {
    display: flex;
    flex-wrap: wrap;
}
@media (min-width: 768px) {
    .selected-car {
        margin-right: 20px;
    }
}

.selected-car__manufacturer-logo-wrap {
    margin-right: 12px;
}
@media (max-width: 767px) {
    .selected-car__manufacturer-logo-wrap {
        display: none;
    }
}

.selected-car__name {
    display: block;
    width: calc(100% - 42px);
}

.selected-car__characteristics {
    width: 100%;
    margin-top: 4px;
}
@media (min-width: 768px) {
    .selected-car__characteristics {
        margin-left: 42px;
    }
}

@media (min-width: 768px) {
    .selected-car-block__change-btn {
        margin-left: auto;
    }
}
@media (max-width: 767px) {
    .selected-car-block__change-btn {
        margin-top: 8px;
    }
}

.form__description {
    margin-top: 8px;
    margin-bottom: 24px;
    line-height: var(--base-lh);
}

.form__actions {
    padding-top: 36px;
}
@media (min-width: 768px) {
    .form__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
@media (max-width: 767px) {
    .form__actions {
        margin-top: auto;
    }
}

@media (max-width: 767px) {
    .form__submit {
        width: 100%;
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .form__agreement {
        margin-right: 20px;
    }
}

@media (max-width: 767px) {
    .steps-content,
    .steps-content__animation-wrap,
    .form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
</style>