<template>
    <InputRadioBase
        :id="id"
        :options="options"
        :value="valueComputed"
        :label="label"
        :optionKey="optionKey"
        :getOptionKey="getOptionKey"
        :optionLabel="optionLabel"
        :getOptionLabel="getOptionLabel"
        :optionComparator="optionComparator"
        :isOptionSelected="isOptionSelected"
        :reduce="reduce"
        :wrapClasses="[
            'input-radio-tabs__wrap',
            {
                'input-radio-tabs__wrap_no-padding': noPadding,
                'input-radio-tabs__wrap_flex': flex,
            }
        ]"
        itemClasses="input-radio-tabs__item"
        inputClasses="input-radio-tabs__input"
        labelClasses="input-radio-tabs__label"
        v-on="listeners"
    ></InputRadioBase>
</template>

<script>
import inputRadioMixin from '@/components/_inputs/inputRadioMixin.js';


export default {
    name: "InputRadioTabs",

    mixins: [
        inputRadioMixin,
    ],

    props: {
        noPadding: Boolean,
        flex: Boolean,
    },
};
</script>

<style>
.input-radio-tabs__wrap {
    display: inline-flex;
    border: 1px solid var(--border-dark-c);
    border-radius: var(--border-radius);
}

.input-radio-tabs__wrap.input-radio-tabs__wrap_no-padding .input-radio-tabs__label {
    padding: 11px 0;
}

.input-radio-tabs__wrap.input-radio-tabs__wrap_flex {
    display: flex;
}

.input-radio-tabs__item {
    flex: 1 1 0;
    text-align: center;
    height: 100%;
}

.input-radio-tabs__item:first-child .input-radio-tabs__label {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.input-radio-tabs__item:not(:last-child) .input-radio-tabs__label {
    border-right-color: var(--border-dark-c);
}

.input-radio-tabs__item:last-child .input-radio-tabs__label {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.input-radio-tabs__input {
    transition: box-shadow var(--transition), border-color var(--transition);
}

.input-radio-tabs__input:checked + .input-radio-tabs__label {
    color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
    border-color: var(--primary-color);
}

.input-radio-tabs__label {
    padding: 10px 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    font-size: var(--base-fz);
    line-height: var(--small-lh);
    white-space: nowrap;
    transition: color var(--transition), fill var(--transition);
}

.input-radio-tabs__wrap.input-radio-tabs__wrap_small .input-radio-tabs__label {
    padding: 8px 20px;
}

.input-radio-tabs__label:hover {
    color: var(--primary-color);
    fill: var(--primary-color);
}
</style>