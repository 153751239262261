<template>
    <PopupWrap
        @close="close"
    >
        <FormTemplate class="popup-content">
            <template #body>
                <FormManager
                    ref="form"
                    v-model="formData"
                    :formTag="false"
                    @error="onError"
                >
                    <h2 class="h2 h2_block owner-title">
                        Собственник
                    </h2>

                    <div class="form-grid owner-grid">
                        <FormField
                            name="owner_last_name"
                            label="Фамилия"
                            validations="required"
                            class="owner_last_name"
                        ></FormField>

                        <FormField
                            name="owner_first_name"
                            label="Имя"
                            validations="required"
                            class="owner_first_name"
                        ></FormField>

                        <FormField
                            name="owner_middle_name"
                            label="Отчество"
                            validations="required"
                            class="owner_middle_name"
                        ></FormField>

                        <!-- min 1900 max -14 -->
                        <FormField
                            name="owner_birth_date"
                            type="calendar"
                            label="Дата рождения"
                            stepByStep
                            :min="minBirthDate"
                            :max="maxBirthDate"
                            :validations="`required|min:${ minBirthDateIso }|max:${ maxBirthDateIso }`"
                            class="owner_birth_date"
                        ></FormField>

                        <FormField
                            name="owner_doc_number"
                            label="Серия и номер паспорта"
                            mask="0000 000000"
                            validations="required|re"
                            :validationRules="{
                                re: ({ value }) => {
                                    return value ? !/(\d{4})\s(\d{6})/g.test(value) : false;
                                },
                            }"
                            class="owner_doc_number"
                        ></FormField>

                        <!-- min 1900|birth + 14 max now -->
                        <FormField
                            name="owner_doc_date"
                            type="date"
                            visibleMask
                            label="Дата выдачи паспорта"
                            :validations="`required|min:${ minOwnerDocDateIso }|max:${ maxDocDateIso }`"
                            class="owner_doc_date"
                        ></FormField>

                        <FormField
                            name="owner_address_obj"
                            type="address"
                            label="Адрес регистрации"
                            validations="required|house"
                            :validationRules="{
                                house: ({ value }) => !(value || {}).house
                            }"
                            :validationMessages="{
                                house: address.hint
                            }"
                            :hint="address.hint"
                            :queryOptions="{ ignore_flat: true }"
                            class="owner_address_obj"
                        ></FormField>

                        <FormField
                            name="owner_address_apartment_number"
                            label="Квартира"
                            maxLength="4"
                            class="owner_address_apartment_number flat-field"
                        ></FormField>
                    </div>

                    <FormField
                        name="owner_is_insurer"
                        type="checkbox"
                        class="mr-8 form-field-gap_top owner_is_insurer"
                    >
                        <template #label>
                            Собственник является <span class="text-nowrap">страхователем<UTooltip>
                                <template #trigger="{ value, attrs, handlers }">
                                    <UTooltipIcon
                                        :active="value"
                                        class="ml-8"
                                        v-bind="attrs"
                                        v-on="handlers"
                                    ></UTooltipIcon>
                                </template>

                                Физическое лицо, которое заключает договор ОСАГО
                                и имеет право вносить в него изменения.
                            </UTooltip></span>
                        </template>
                    </FormField>

                    <template v-if="!formData.owner_is_insurer">
                        <h2 class="h2 mt-36 insurer-title">
                            Страхователь
                        </h2>

                        <div class="form-grid insurer-grid">
                            <FormField
                                name="insurer_last_name"
                                label="Фамилия"
                                validations="required"
                                class="insurer_last_name"
                            ></FormField>

                            <FormField
                                name="insurer_first_name"
                                label="Имя"
                                validations="required"
                                class="insurer_first_name"
                            ></FormField>

                            <FormField
                                name="insurer_middle_name"
                                label="Отчество"
                                validations="required"
                                class="insurer_middle_name"
                            ></FormField>

                            <!-- min 1900 max -14 -->
                            <FormField
                                name="insurer_birth_date"
                                type="calendar"
                                label="Дата рождения"
                                stepByStep
                                :min="minBirthDate"
                                :max="maxBirthDate"
                                :validations="`required|min:${ minBirthDateIso }|max:${ maxBirthDateIso }`"
                                class="insurer_birth_date"
                            ></FormField>

                            <FormField
                                name="insurer_doc_number"
                                label="Серия и номер паспорта"
                                mask="0000 000000"
                                validations="required|re"
                                :validationRules="{
                                    re: ({ value }) => {
                                        return value ? !/(\d{4})\s(\d{6})/g.test(value) : false;
                                    },
                                }"
                                class="insurer_doc_number"
                            ></FormField>

                            <!-- min 1900 max now -->
                            <FormField
                                name="insurer_doc_date"
                                type="date"
                                visibleMask
                                label="Дата выдачи паспорта"
                                :validations="`required|min:${ minInsurerDocDateIso }|max:${ maxDocDateIso }`"
                                class="insurer_doc_date"
                            ></FormField>

                            <FormField
                                name="insurer_address_obj"
                                type="address"
                                label="Адрес регистрации"
                                validations="required|house"
                                :validationRules="{
                                    house: ({ value }) => !(value || {}).house,
                                }"
                                :validationMessages="{
                                    house: address.hint,
                                }"
                                :hint="address.hint"
                                class="insurer_address_obj"
                            ></FormField>

                            <FormField
                                name="insurer_address_apartment_number"
                                label="Квартира"
                                maxLength="4"
                                class="insurer_address_apartment_number flat-field"
                            ></FormField>
                        </div>
                    </template>

                    <h2 class="h2 mt-36 contacts-title">
                        Контактные данные
                    </h2>

                    <div
                        class="form-grid contacts-grid"
                        :class="{
                            expanded: phoneStatus === 'initial'
                        }"
                    >
                        <FormField
                            name="insurer_email"
                            type="email"
                            label="Email"
                            validations="required"
                            class="insurer_email"
                            @blur="patch('insurer_email')"
                        ></FormField>

                        <FormField
                            name="insurer_phone"
                            type="phone"
                            label="Телефон"
                            visibleMask
                            validations="required"
                            v-bind="phoneIsDone ? {
                                appendIcon: {
                                    name: 'done',
                                    small: true,
                                    green: true,
                                },
                            } : {}"
                            :disabled="phoneStatus === 'initial'"
                            class="insurer_phone"
                        ></FormField>

                        <template v-if="phoneStatus === 'initial'">
                            <div class="change-phone-button">
                                <ButtonText
                                    secondary
                                    dark
                                    dashed
                                    @click="cancelConfirm"
                                >
                                    Изменить телефон
                                </ButtonText>
                            </div>

                            <InputText
                                v-model="sms_code.value"
                                label="Код из СМС *"
                                class="sms_code"
                                :invalid="sms_code.invalid"
                                :error="sms_code.error"
                                @enter="confirmCode"
                            ></InputText>

                            <div class="seconds">
                                <span v-if="seconds > 0">Отправить ещё раз через {{ seconds }} сек</span>

                                <ButtonText
                                    v-else
                                    secondary
                                    dark
                                    dashed
                                    @click="sendCode"
                                >
                                    Отправить код повторно
                                </ButtonText>
                            </div>
                        </template>
                    </div>
                </FormManager>
            </template>

            <template #footer>
                <ButtonBlock
                    secondary
                    class="button-secondary"
                    @click="cancel"
                >
                    Отмена
                </ButtonBlock>
                <ButtonBlock
                    v-if="isAuthorized || (phoneConfirmed && phoneEqual) || phoneStatus === 'initial'"
                    primary
                    class="button-primary"
                    :disabled="!isAuthorized && !phoneConfirmed"
                    @click="confirm"
                >
                    Сохранить
                </ButtonBlock>

                <ButtonBlock
                    v-else
                    primary
                    class="button-primary"
                    :loading="sendingLoading"
                    @click="sendCode"
                >
                    Получить код по СМС
                </ButtonBlock>
            </template>
        </FormTemplate>
    </PopupWrap>
</template>

<script>
// utils
import deepClone from '@/lib/deepClone.js';
// mixins
import popup from '@/mixins/popup.js';
// use
import useChangePopupData from '@/pages/insurance/osago/_uuid/useChangePopupData.js';
import useOwnerData from '@/pages/insurance/osago/_uuid/useOwnerData.js';
import useOwnerComputed from '@/pages/insurance/osago/_uuid/useOwnerComputed.js';
import useOwnerWatch from '@/pages/insurance/osago/_uuid/useOwnerWatch.js';
import useChangePopupMethods from '@/pages/insurance/osago/_uuid/useChangePopupMethods.js';
import useChangePopupProps from '@/pages/insurance/osago/_uuid/useChangePopupProps.js';
import useChangePopupComputed from '@/pages/insurance/osago/_uuid/useChangePopupComputed.js';
import useOwnerMethods from '@/pages/insurance/osago/_uuid/useOwnerMethods.js';
// components
import PopupWrap from '@/components/popups/PopupWrap.vue';
import FormTemplate from './FormTemplate.vue';
import FormField from '@/components/_form/FormField.vue';
import FormManager from '@/components/_form/FormManager.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import InputText from '@/components/_inputs/InputText.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import UTooltipIcon from '@ui/components/UTooltip/UTooltipIcon.vue';


export default {
    name: 'InsurerOsagoChangeOwnerAndInsurerPopup',

    components: {
        UTooltipIcon,
        UTooltip,
        InputText,
        ButtonText,
        ButtonBlock,
        FormManager,
        PopupWrap,
        FormTemplate,
        FormField,
    },

    mixins: [
        popup,
    ],

    props: {
        ...useChangePopupProps(),
    },

    data() {
        return {
            ...useChangePopupData(),
            ...useOwnerData(),
        };
    },

    computed: {
        ...useOwnerComputed(),
        ...useChangePopupComputed(),
    },

    watch: {
        ...useOwnerWatch({ isPopup: true }),
    },

    mounted() {
        this.formData = deepClone(this.initFormData);
    },

    methods: {
        ...useChangePopupMethods(),
        ...useOwnerMethods({ isPopup: true }),
    },
};
</script>

<style scoped>
.owner_last_name {
    grid-area: last_name;
}

.owner_first_name {
    grid-area: first_name;
}

.owner_middle_name {
    grid-area: middle_name;
}

.owner_birth_date {
    grid-area: birth_date;
}

.owner_doc_number {
    grid-area: doc_number;
}

.owner_doc_date {
    grid-area: doc_date;
}

.owner_address_obj {
    grid-area: address_obj;
}

.owner_address_apartment_number {
    grid-area: address_apartment_number;
}

.owner_is_insurer {
    grid-area: owner_is_insurer;
}

.insurer_last_name {
    grid-area: last_name;
}

.insurer_first_name {
    grid-area: first_name;
}

.insurer_middle_name {
    grid-area: middle_name;
}

.insurer_birth_date {
    grid-area: birth_date;
}

.insurer_doc_number {
    grid-area: doc_number;
}

.insurer_doc_date {
    grid-area: doc_date;
}

.insurer_address_obj {
    grid-area: address_obj;
}

.insurer_address_apartment_number {
    grid-area: address_apartment_number;
}

.insurer_email {
    grid-area: insurer_email;
}

.insurer_phone {
    grid-area: insurer_phone;
}

.sms_code {
    grid-area: sms_code;
}

.seconds {
    grid-area: seconds;
}

.change-phone-button {
    grid-area: change-phone-button;
}

@media (min-width: 1040px) {
    .popup-content {
        width: 876px;
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .popup-content {
        width: 708px;
    }
}

.owner-title,
.contacts-title,
.insurer-title {
    margin-bottom: var(--gap-y-mini);
}

@media (min-width: 1040px) {
    .owner-grid,
    .insurer-grid {
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
        "last_name last_name first_name first_name middle_name middle_name"
        "birth_date birth_date doc_number doc_number doc_date doc_date"
        "address_obj address_obj address_obj address_obj address_apartment_number .";
    }

    .contacts-grid {
        align-items: center;
        grid-template-areas:
        "insurer_email insurer_email insurer_phone";
    }

    .contacts-grid.expanded {
        grid-template-areas:
        "insurer_email insurer_email insurer_phone"
        "sms_code seconds change-phone-button";
    }
}
@media (min-width: 768px) and (max-width: 1039px) {
    .owner-grid,
    .insurer-grid {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
        "last_name last_name first_name first_name"
        "middle_name middle_name birth_date birth_date"
        "doc_number doc_number doc_date doc_date"
        "address_obj address_obj address_obj address_apartment_number"
    }

    .contacts-grid {
        align-items: center;
        grid-template-columns: 178px 178px 240px;
        grid-template-areas:
        "insurer_email insurer_email insurer_phone";
    }

    .contacts-grid.expanded {
        grid-template-areas:
        "insurer_email insurer_email insurer_phone"
        "sms_code seconds change-phone-button";
    }

    .seconds {
        grid-column: 2/4;
    }

    .change-phone-button {
        text-align: right;
    }
}

@media (max-width: 767px) {
    .popup-content >>> .footer {
        margin-top: auto;
        padding-top: 28px;
    }
}
</style>