<template>
    <MarketPartApplicability
        :part="newPart"
    ></MarketPartApplicability>
</template>

<script>
import { mapState } from 'vuex';
import MarketPartApplicability from '@/components/MarketPartApplicability.vue';

export default {
    name: 'MarketNewPartApplicabilityPage',

    components: {
        MarketPartApplicability,
    },

    computed: {
        ...mapState({
            newPart: state => state.marketNewPart.newPart,
        }),
    },
};
</script>