export default {
    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'change:model',
    },

    props: {
        value: String,
        hint: String,
    },

    computed: {
        listeners() {
            // eslint-disable-next-line no-unused-vars
            const { input, ...listeners } = this.$listeners;

            return {
                ...listeners,
                input: this.onInput,
            };
        },

        attrs() {
            const value = this.value;
            return { ...this.$attrs, value };
        },
    },

    methods: {
        onInput($event) {
            this.$emit('change:model', $event.value);
            this.$emit('input', $event);
        },
    },
};