<template>
    <PopupWrap
        @clickaway="close"
        @close="close"
    >
        <div class="popup-content">
            <h2 class="h2 h2_block popup-title">
                <CarName
                    :car="selectedCar"
                    :block="false"
                    noFrame
                ></CarName>
            </h2>

            <CarCharacteristicsTable
                :car="selectedCar"
                big
            ></CarCharacteristicsTable>

            <div class="actions">
                <ButtonBlock
                    v-if="isAuthorized && !isSelectedCarFromGarage"
                    primary
                    :loading="addGarageLoading"
                    class="action-btn action-btn_add-garage"
                    @click="addSelectedCarToGarage"
                >
                    Добавить в гараж
                </ButtonBlock>

                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="showSelectCarPopup"
                >
                    Изменить
                </ButtonBlock>
                <ButtonBlock
                    secondary
                    class="action-btn"
                    @click="resetSelectedCar"
                >
                    Сбросить
                </ButtonBlock>
                <p
                    v-if="isAuthorized && isSelectedCarFromGarage"
                    class="text_secondary in-garage-text"
                >
                    Автомобиль добавлен в <ButtonText
                        underline
                        secondary
                        :to="{ name: 'account-garage' }"
                    >гараж</ButtonText>
                </p>

                <p
                    v-if="!isAuthorized"
                    class="signin-text"
                >
                    <UButtonText
                        underline
                        primary
                        @click="openSignInPopup"
                    >Войдите</UButtonText>, чтобы добавить автомобиль в&nbsp;гараж
                </p>
            </div>

            <div
                v-if="false"
                class="subactions"
            >
                <ButtonText
                    v-if="$route.name === 'market-parts-catalogs-manufacturer-modification'
                        && $route.params.manufacturer === selectedCar.manufacturer.codename
                        && $route.params.modification === selectedCar.id"
                    dashed
                    secondary
                    dark
                    @click="close"
                >
                    Подобрать запчасти по схемам
                </ButtonText>
                <ButtonText
                    v-else
                    :to="{
                        name: 'market-parts-catalogs-manufacturer-modification',
                        params: {
                            manufacturer: selectedCar.manufacturer.codename,
                            modification: selectedCar.id
                        },
                    }"
                    dashed
                    secondary
                    dark
                >
                    Подобрать запчасти по схемам
                </ButtonText>
            </div>
        </div>
    </PopupWrap>
</template>

<script>
import bus from '@/bus';
import { mapActions, mapMutations, mapState } from 'vuex';
import { getErrorCode } from '@/lib/errors.js';
import popup from '@/mixins/popup.js';
import PopupWrap from '@/components/popups/PopupWrap.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import ButtonText from '@/components/_buttons/ButtonText.vue';
import CarName from '@/components/CarName.vue';
import CarCharacteristicsTable from '@/components/CarCharacteristicsTable.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const SelectCarPopup = () => import('@/components/popups/SelectCarPopup.vue');
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');

export default {
    name: 'SelectedSearchCarPopup',

    components: {UButtonText, CarCharacteristicsTable, CarName, ButtonText, ButtonBlock, PopupWrap },

    mixins: [popup],

    data() {
        return {
            addGarageLoading: false,
        };
    },

    computed: {
        ...mapState({
            isAuthorized: state => state.profile.isAuthorized,
            garageCars: state => state.profileCars.cars,
            selectedCar: state => state.searchWidget.car,
        }),

        isSelectedCarFromGarage() {
            if (this.isAuthorized) {
                let foundCar = this.garageCars.find(item => {
                    if (item.car.id === this.selectedCar.id) return item;
                    else return false;
                });
                return ((foundCar || {}).car || {}).id || false;
            }
            else {
                return false;
            }
        },
    },

    methods: {
        ...mapMutations({
            setCar: 'searchWidget/setCar',
            addCar: 'profileCars/addCar',
        }),

        ...mapActions({
            saveCar: 'profileCars/saveCar',
        }),

        showSelectCarPopup() {
            this.close();
            const props = {
                modeOptions: [
                    {
                        label: 'По гос. номеру',
                        value: 'grz',
                    },
                    {
                        label: 'VIN/Frame',
                        value: 'vin_frame',
                    },
                    // {
                    //     label: 'По модели',
                    //     value: 'catalog',
                    // },
                    {
                        label: 'Из гаража',
                        value: 'garage',
                    },
                ],
                outerAddGarageMode: false,
            };
            const options = { props };
            this.$popup(SelectCarPopup, options);
        },

        resetSelectedCar() {
            this.close();
            this.setCar({});
            bus.$emit('changeCar');
        },

        async addSelectedCarToGarage() {
            this.addGarageLoading = true;
            try {
                const data = {
                    car: this.selectedCar.id,
                    grz: this.selectedCar.grz || '',
                    vin_frame: this.selectedCar.vin_frame || '',
                    prod_date: this.selectedCar.prod_date || null,
                };

                const response = await this.saveCar(data);
                this.addCar({ car: response });
                this.$notify({
                    type: 'success',
                    message: 'Автомобиль добавлен в гараж',
                });
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);

                const code = getErrorCode(error);
                const data = error.response.data;

                if (code === 400) {
                    const { car } = data;
                    if (car) {
                        this.$error('Произошла ошибка, попробуйте позже');
                    }
                }
            }
            finally {
                this.addGarageLoading = false;
            }
        },

        openSignInPopup() {
            this.$popup(SignInPopup);
        },
    },
};
</script>

<style scoped>
@media (min-width: 768px) {
    .popup-content {
        width: 676px;
    }
}

@media (min-width: 768px) {
    .actions {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 20px;
        align-items: center;
    }

    .action-btn_add-garage {
        grid-row: 1/2;
        grid-column: 3/4;
    }
}
@media (max-width: 767px) {
    .action-btn {
        display: block;
        width: 100%;
    }

    .action-btn:not(:first-child) {
        margin-bottom: 16px;
    }
}

.actions {
    padding-top: 36px;
}
@media (max-width: 767px) {
    .actions {
        display: flex;
        flex-direction: column-reverse;
        margin-top: auto;
    }
}

.subactions {
    margin-top: 36px;
}

@media (max-width: 767px) {
    .in-garage-text {
        display: none;
    }

    .signin-text,
    .subactions {
        text-align: center;
    }

    .signin-text {
        margin-bottom: 20px;
    }
}
</style>