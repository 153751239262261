import isValidDate from '@ui/utils/isValidDate.js';
import pad from '@ui/utils/pad.js';


export default (options = {}) => {
    if (!options.isPopup) options.isPopup = false;

    return {
        'formData.action_start_date': {
            handler(value) {
                if (value) {
                    const date = new Date(value);
                    const isValid = isValidDate(date);

                    if (isValid) {
                        const newValue = `${ date.getFullYear() + 1 }-${ pad(date.getMonth() + 1, 2) }-${ pad(date.getDate(), 2) }`;

                        if (options.isPopup) {
                            this.formData.action_end_date = newValue;
                        }
                        else {
                            this.update({ action_end_date: newValue });
                        }
                    }
                }
            },
        },
    };
};