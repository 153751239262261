<template>
    <PreviewComponent
        ref="preview"
        @show="onShow"
        @hide="onHide"
    >
        <template #trigger="{ isOpen }">
            <component
                :is="trigger.tag"
                v-bind="trigger.attrs"
                class="preview-trigger"
                :class="{
                    'preview-trigger_active': isOpen
                }"
            >
                <UIcon
                    name="delivery"
                    big
                    class="preview-trigger__icon"
                ></UIcon>

                <UCircleLabelCounter
                    :count="ordersTotalCount"
                    class="preview-trigger__counter"
                ></UCircleLabelCounter>

                <span v-if="!noTriggerText" class="preview-trigger__text">Заказы</span>
            </component>
        </template>

        <template #content>
            <div class="preview-content layer-2">
                <div v-if="!isAuthorized" class="placeholder">
                    <PlaceholderIcon
                        icon="delivery"
                        size="small"
                        class="mr-24"
                    ></PlaceholderIcon>

                    <div>
                        <h4 class="text_bold mb-4">
                            Вы не авторизованы
                        </h4>
                        <p class="text_base-lh">Чтобы увидеть свои заказы, необходимо <UButtonText
                            primary
                            underline
                            @click="openSignInPopup"
                        >авторизоваться</UButtonText>.</p>
                    </div>
                </div>

                <div v-if="!ordersTotalCount && isAuthorized" class="placeholder">
                    <PlaceholderIcon
                        icon="delivery"
                        size="small"
                        class="mr-24"
                    ></PlaceholderIcon>

                    <div>
                        <h4 class="text_bold mb-4">
                            Нет заказов
                        </h4>
                        <p class="text_base-lh">
                            В настоящий момент у вас нет активных заказов.
                        </p>
                    </div>
                </div>

                <div v-if="ordersTotalCount && isAuthorized" class="preview__body">
                    <ul class="preview__content orders-list">
                        <li
                            v-for="order in orders"
                            :key="'order' + order.uuid + order.status"
                            class="order"
                        >
                            <ActiveOrderInPreview
                                :initialOrder="order"
                            ></ActiveOrderInPreview>
                        </li>
                    </ul>
                    <div class="preview__footer">
                        <ButtonBlock
                            :to="{
                                name: 'account-orders-active'
                            }"
                            secondary
                            low
                        >Перейти к заказам</ButtonBlock>
                    </div>
                </div>
            </div>
        </template>
    </PreviewComponent>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import breakpointKey from '@/mixins/breakpointKey.js';
import PreviewComponent from '@/components/header/PreviewComponent.vue';
import ActiveOrderInPreview from '@/pages/account/orders/components/ActiveOrderInPreview.vue';
import PlaceholderIcon from '@/components/PlaceholderIcon.vue';
import UIcon from '@ui/components/UIcon/UIcon.vue';
import UCircleLabelCounter from '@ui/components/UBadge/UBadge.vue';
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import UButtonText from '@ui/components/UButton/UButtonText.vue';
const SignInPopup = () => import('@/components/popups/SignInPopup.vue');

export default {
    name: 'OrdersPreview',

    components: {
        UButtonText,
        ButtonBlock,
        UCircleLabelCounter,
        UIcon,
        PlaceholderIcon,
        ActiveOrderInPreview,
        PreviewComponent,
    },

    mixins: [breakpointKey],

    props: {
        noTriggerText: Boolean,
    },

    data() {
        return {
            fetchTimeout: false,
        };
    },

    computed: {
        ...mapState({
            isAuthorized: state => state.profile.isAuthorized,
            ordersTotalCount: state => state.profileOrders.activeOrders.current_count,
            orders: state => state.profileOrders.activeOrders.items,
        }),

        trigger() {
            if (this.breakpointKey === 'm') {
                if (this.ordersTotalCount || !this.isAuthorized) {
                    return {
                        tag: 'button',
                        attrs: {},
                    };
                }
                else {
                    return {
                        tag: 'RouterLink',
                        attrs: {
                            to: {
                                name: 'account-orders-active',
                            },
                            'aria-label': 'Перейти в заказы',
                        },
                    };
                }
            }
            else {
                if (!this.isAuthorized) {
                    return {
                        tag: 'button',
                        attrs: {},
                    };
                }
                else {
                    return {
                        tag: 'RouterLink',
                        attrs: {
                            to: {
                                name: 'account-orders-active',
                            },
                            'aria-label': 'Перейти в заказы',
                        },
                    };
                }
            }
        },
    },

    methods: {
        ...mapActions({
            fetchActiveOrdersCount: 'profileOrders/fetchActiveOrdersCount',
        }),

        onShow() {
            this.fetchTimeout = setTimeout(() => {
                this.fetch();
            }, 500);
        },

        onHide() {
            if (this.fetchTimeout) {
                clearTimeout(this.fetchTimeout);
            }
        },

        async fetch() {
            await this.fetchActiveOrdersCount();
            this.$nextTick(() => {
                this.$refs.preview && this.$refs.preview.setContentPosition();
            });
        },

        openSignInPopup() {
            this.$popup(SignInPopup);
        },
    },
};
</script>

<style scoped>
.spinner-wrap {
    width: 380px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview__body {
    width: 480px;
    max-height: 600px;
    overflow: auto;
}

.preview__content {
    padding: 20px 24px;
    overflow: auto;
}

.order:not(:first-child) {
    margin-top: 20px;
}
.order:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light-c);
}

.preview__footer {
    display: none;
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 20px 24px;
    background-color: var(--light-c);
    border-top: 1px solid var(--border-light-c);
}
@media (min-width: 768px) and (max-width: 1039px) {
    .preview__footer {
        display: block;
    }
}

.placeholder {
    width: 380px;
    padding: 20px 24px;
    display: flex;
    align-items: center;
}

.preview-trigger {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    transition: color var(--transition);
}

.preview-trigger_active,
.preview-trigger:hover,
.preview-trigger:focus {
    color: var(--primary-color);
}

.preview-trigger__icon {
    display: block;
    fill: currentColor;
}

.preview-trigger__text {
    display: block;
    margin-left: 8px;
    font-size: var(--small-fz);
    font-family: var(--f-semi-bold);
}

.preview-trigger__counter {
    position: absolute;
    top: 0;
    left: 18px;
}
</style>