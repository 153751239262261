<template>
    <UTooltip
        ref="tooltip"
        noDetach
        showOnClick
        maxWidth="380"
        class="shop-tooltip-wrap ml-8"
        @open="init"
    >
        <template #cloud="{ shift, placement }">
            <UTooltipCloud
                :shift="shift"
                :placement="placement"
                color="white"
                class="tooltip-cloud"
            >
                <div class="shop-tooltip">
                    <ShopAddress
                        :shop="shop"
                        :showOnMap="false"
                    ></ShopAddress>

                    <ShopOpenCloseTime
                        :shopId="shop.id"
                        :status="shop.current_work"
                        withoutTooltip
                        class="mt-4"
                    ></ShopOpenCloseTime>

                    <MarketItemRatingReviewsLink
                        :item="shop"
                        modelName="shop"
                        class="mt-16"
                    ></MarketItemRatingReviewsLink>

                    <div class="actions">
                        <LikeBtn
                            :id="shop.id"
                            :value="shop.is_favorite"
                            apiName="shop"
                            class="actions__btn"
                            withText
                        ></LikeBtn>
                        <ChatBtn
                            :id="shop.id"
                            :isOnline="shop.current_work.place.is_active"
                            :disabled="!shop.is_chat_active"
                            withText
                            btnTextAlwaysVisible
                            class="actions__btn"
                        ></ChatBtn>
                    </div>

                    <TransitionExpand>
                        <ShopWorkSchedule
                            v-if="shopSchedule"
                            key="schedule"
                            :work="shopSchedule.work_times"
                            :holidays="shopSchedule.holidays"
                            class="mt-16"
                        ></ShopWorkSchedule>
                    </TransitionExpand>

                    <PointOnMapPreviewBtn
                        :coords="shop.coordinates"
                        class="mt-16"
                        @click="openMap"
                    ></PointOnMapPreviewBtn>

                    <ButtonBlock
                        :to="{
                            name: 'market-shop-stock',
                            params: {
                                codename: shop.company.codename,
                                id: shop.id,
                            }
                        }"
                        secondary
                        w100
                        class="mt-16"
                    >На страницу магазина</ButtonBlock>
                </div>
            </UTooltipCloud>
        </template>
    </UTooltip>
</template>

<script>
import ButtonBlock from '@/components/_buttons/ButtonBlock.vue';
import LikeBtn from '@/components/project-buttons/LikeBtn.vue';
import ShopAddress from '@/components/ShopAddress.vue';
import ShopOpenCloseTime from '@/components/ShopOpenCloseTime.vue';
import MarketItemRatingReviewsLink from '@/components/MarketItemRatingReviewsLink.vue';
import ChatBtn from '@/components/project-buttons/ChatBtn.vue';
import ShopWorkSchedule from '@/components/ShopWorkSchedule.vue';
import PointOnMapPreviewBtn from '@/components/project-buttons/PointOnMapPreviewBtn.vue';
import UTooltip from '@ui/components/UTooltip/UTooltip.vue';
import TransitionExpand from '@/components/_transitions/TransitionExpand.vue';
import UTooltipCloud from '@ui/components/UTooltip/UTooltipCloud.vue';
const PointOnMapPopup = () => import('@/components/popups/PointOnMapPopup.vue');


export default {
    name: 'ShopInfoTooltip',

    components: {
        UTooltipCloud,
        TransitionExpand,
        UTooltip,
        ButtonBlock,
        ShopWorkSchedule,
        ShopAddress,
        ShopOpenCloseTime,
        PointOnMapPreviewBtn,
        ChatBtn,
        MarketItemRatingReviewsLink,
        LikeBtn,
    },

    props: {
        shop: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            loading: false,
            shopSchedule: null,
        };
    },

    methods: {
        async init() {
            if (this.shopSchedule) return;

            this.loading = true;

            try {
                this.shopSchedule = await this.$api.shops.get(this.shop.id);
            }
            catch (error) {
                this.$store.commit('handleCommonHttpError', error);
            }
            finally {
                this.loading = false;
            }
        },

        openMap() {
            this.$refs.tooltip.hide();
            const props = {
                shop: this.shop,
                coords: this.shop.coordinates,
            };
            const options = { props };
            this.$popup(PointOnMapPopup, options);
        },
    },
};
</script>

<style scoped>
.shop-tooltip-wrap {
    display: inline;
    font-size: 0;
    vertical-align: middle;
}

.shop-tooltip {
    width: 340px;
    padding: 20px;
    font-size: var(--base-fz);
    font-family: var(--f-base);
}

.tooltip-cloud >>> .u-tooltip-cloud__content {
    border-radius: var(--border-radius-x2);
}

.actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
}

.actions__btn {
    flex-shrink: 0;
}
.actions__btn:not(:first-child) {
    margin-left: 12px;
}
</style>