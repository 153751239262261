export default {
    mounted() {
        // Везде, где есть patch, есть и contract
        if (this.$route.meta.lastPath && this.contract && this.contract.uuid) {
            try {
                this.$api.insuranceContract.change(this.contract.uuid, { last_path: this.$route.meta.lastPath });
            }
            catch (error) {
                //
            }
        }
    },
};