import optionsPropsMixin from '@/mixins/options.js';
import genId from '@ui/utils/genId.js';
import filterObj from '@/lib/filterObj.js';
import InputRadioBase from '@/components/_inputs/InputRadioBase.vue';


export default {
    components: {
        InputRadioBase,
    },

    mixins: [
        optionsPropsMixin,
    ],

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        id: {
            type: String,
            default: () => genId(),
        },

        value: {
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        options: {
            type: Array,
            required: true,
        },

        wrapClasses: [String, Array, Object],
        itemClasses: [String, Array, Object],
        inputClasses: [String, Array, Object],
        labelClasses: [String, Array, Object],

        reduce: {
            type: Function,
            default(value) {
                return this.getOptionKey(value);
            },
        },
    },

    computed: {
        valueComputed: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('change', value);
            },
        },

        listeners() {
            return filterObj(this.$listeners, ['change']);
        },
    },
};